var i18n_private={
  "en": {
    "my_mind_map": "My Mind Map",
    "h_products": "Products",
    "h_diagram_tool": "Diagram Tool",
    "image_tool": "Image Tool",
    "diagrams": "Diagrams",
    "individual_use": "Individual Use",
    "other_use": "Other Use",
    "pricing": "Pricing",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Free Background Remover",
    "product_3": "Free Image Upscaler",
    "product_4": "Free Watermark Remover",
    "product_5": "Free Image Compressor",
    "product_6": "Free JPG Word Converter",
    "product_7": "Free PDF PNG Converter",
    "product_8": "Free HEIC to JPG Converter",
    "product_9": "Free Image Converter",
    "product_10": "Free PDF JPG Converter",
    "product_11": "Flowchart",
    "product_12": "Org Chart",
    "product_13": "Gantt Chart",
    "product_14": "PERT Chart",
    "product_15": "ORM Diagram",
    "product_16": "Timeline",
    "product_17": "Concept Map",
    "product_18": "Tree Diagram",
    "product_19": "Fishbone Diagram",
    "product_20": "ER Diagram",
    "product_21": "Genogram",
    "product_22": "UML Diagram",
    "business_use": "Business Use",
  },
  "ar": {
    "my_mind_map": "خريطة ذهني",
    "h_products": "منتجات",
    "h_diagram_tool": "أداة الرسم البياني",
    "image_tool": "أداة الصورة",
    "diagrams": "المخططات",
    "individual_use": "الاستخدام الفردي",
    "other_use": "استخدام آخر",
    "pricing": "التسعير",
    "tutorial": "درس تعليمي",
    "faq": "التعليمات",
    "blog": "مدونة",
    "product_1": "صانع الخرائط الذهنية",
    "product_2": "مزيل الخلفية المجاني",
    "product_3": "صورة مجانية الراقي",
    "product_4": "مزيل العلامة المائية مجانا",
    "product_5": "ضاغط الصور المجاني",
    "product_6": "محول JPG Word مجاني",
    "product_7": "محول PDF PNG مجاني",
    "product_8": "محول HEIC إلى JPG مجاني",
    "product_9": "محول الصور المجاني",
    "product_10": "محول PDF JPG مجاني",
    "product_11": "مخطط",
    "product_12": "المخطط الهيكلي",
    "product_13": "مخطط جانت",
    "product_14": "مخطط بيرت",
    "product_15": "مخطط ORM",
    "product_16": "الجدول الزمني",
    "product_17": "خريطة المفهوم",
    "product_18": "مخطط الشجرة",
    "product_19": "مخطط هيكل السمكة",
    "product_20": "مخطط ER",
    "product_21": "شجرة العائلة",
    "product_22": "مخطط UML",
    "business_use": "استخدام التجاري",
  },
  "af": {
    "my_mind_map": "My breinkaart",
    "h_products": "Produkte",
    "h_diagram_tool": "Diagram Tool",
    "image_tool": "Beeld Gereedskap",
    "diagrams": "Diagramme",
    "individual_use": "Individuele gebruik",
    "other_use": "Ander gebruik",
    "pricing": "Pryse",
    "tutorial": "Tutoriaal",
    "faq": "Gereelde vrae",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Gratis agtergrondverwyderaar",
    "product_3": "Gratis Image Upscaler",
    "product_4": "Gratis watermerkverwyderaar",
    "product_5": "Gratis beeldkompressor",
    "product_6": "Gratis JPG-woordomskakelaar",
    "product_7": "Gratis PDF PNG Converter",
    "product_8": "Gratis HEIC na JPG-omskakelaar",
    "product_9": "Gratis beeldomskakelaar",
    "product_10": "Gratis PDF JPG-omskakelaar",
    "product_11": "Vloeidiagram",
    "product_12": "Organisasie grafiek",
    "product_13": "Gantt-kaart",
    "product_14": "PERT grafiek",
    "product_15": "ORM Diagram",
    "product_16": "Tydlyn",
    "product_17": "Konsepkaart",
    "product_18": "Boomdiagram",
    "product_19": "Visgraatdiagram",
    "product_20": "ER Diagram",
    "product_21": "Genogram",
    "product_22": "UML-diagram",
    "business_use": "Besigheidsgebruik",
  },
  "az": {
    "my_mind_map": "Fikrim Xəritə",
    "h_products": "Məhsullar",
    "h_diagram_tool": "Diaqram Aləti",
    "image_tool": "Şəkil Aləti",
    "diagrams": "Diaqramlar",
    "individual_use": "Fərdi istifadə",
    "other_use": "Digər İstifadə",
    "pricing": "Qiymətləndirmə",
    "tutorial": "Dərslik",
    "faq": "Tez-tez verilən suallar",
    "blog": "Bloq",
    "product_1": "Mind Map Maker",
    "product_2": "Pulsuz fon təmizləyicisi",
    "product_3": "Pulsuz şəkil genişləndirici",
    "product_4": "Pulsuz su nişanı təmizləyicisi",
    "product_5": "Pulsuz şəkil kompressor",
    "product_6": "Pulsuz jpg söz çevirici",
    "product_7": "Pulsuz pdf PNG çevirici",
    "product_8": "Pulsuz HEIC-dən JPG-yə çevirici",
    "product_9": "Pulsuz şəkil çevirici",
    "product_10": "Pulsuz pdf jpg çevirici",
    "product_11": "Blok diaqramı",
    "product_12": "Təşkilat Diaqramı",
    "product_13": "Gantt Diaqramı",
    "product_14": "PERT Diaqramı",
    "product_15": "ORM diaqramı",
    "product_16": "Zaman qrafiki",
    "product_17": "Konsepsiya xəritəsi",
    "product_18": "Ağac diaqramı",
    "product_19": "Balıq sümüyü diaqramı",
    "product_20": "ER diaqramı",
    "product_21": "Genoqramma",
    "product_22": "UML diaqramı",
    "business_use": "Biznes İstifadəsi",
  },
  "bg": {
    "my_mind_map": "Моята мисловна карта",
    "h_products": "Продукти",
    "h_diagram_tool": "Инструмент за диаграма",
    "image_tool": "Инструмент за изображения",
    "diagrams": "Диаграми",
    "individual_use": "Индивидуална употреба",
    "other_use": "Друга употреба",
    "pricing": "Ценообразуване",
    "tutorial": "Урок",
    "faq": "ЧЗВ",
    "blog": "Блог",
    "product_1": "Създател на мисловни карти",
    "product_2": "Безплатно премахване на фона",
    "product_3": "Безплатен инструмент за увеличаване на изображението",
    "product_4": "Безплатно средство за премахване на воден знак",
    "product_5": "Безплатен компресор на изображения",
    "product_6": "Безплатен конвертор на JPG Word",
    "product_7": "Безплатен PDF PNG конвертор",
    "product_8": "Безплатен HEIC към JPG конвертор",
    "product_9": "Безплатен конвертор на изображения",
    "product_10": "Безплатен PDF JPG конвертор",
    "product_11": "Блок-схема",
    "product_12": "Организационна диаграма",
    "product_13": "Диаграма на Гант",
    "product_14": "Диаграма PERT",
    "product_15": "ORM диаграма",
    "product_16": "Хронология",
    "product_17": "Концептуална карта",
    "product_18": "Дървовидна диаграма",
    "product_19": "Диаграма на рибена кост",
    "product_20": "ER диаграма",
    "product_21": "Генограма",
    "product_22": "UML диаграма",
    "business_use": "Бизнес употреба",
  },
  "bn": {
    "my_mind_map": "আমার মনের মানচিত্র",
    "h_products": "পণ্য",
    "h_diagram_tool": "ডায়াগ্রাম টুল",
    "image_tool": "ইমেজ টুল",
    "diagrams": "ডায়াগ্রাম",
    "individual_use": "ব্যক্তিগত ব্যবহার",
    "other_use": "অন্যান্য ব্যবহার",
    "pricing": "মূল্য নির্ধারণ",
    "tutorial": "টিউটোরিয়াল",
    "faq": "FAQ",
    "blog": "ব্লগ",
    "product_1": "মাইন্ড ম্যাপ মেকার",
    "product_2": "বিনামূল্যে ব্যাকগ্রাউন্ড রিমুভার",
    "product_3": "বিনামূল্যে ইমেজ Upscaler",
    "product_4": "বিনামূল্যে ওয়াটারমার্ক রিমুভার",
    "product_5": "বিনামূল্যে ইমেজ কম্প্রেসার",
    "product_6": "বিনামূল্যে JPG শব্দ রূপান্তরকারী",
    "product_7": "বিনামূল্যে পিডিএফ পিএনজি কনভার্টার",
    "product_8": "বিনামূল্যে HEIC থেকে JPG কনভার্টার",
    "product_9": "বিনামূল্যে ইমেজ কনভার্টার",
    "product_10": "বিনামূল্যে PDF JPG কনভার্টার",
    "product_11": "ফ্লোচার্ট",
    "product_12": "সংস্থার তালিকা",
    "product_13": "গ্যান্ট চার্ট",
    "product_14": "PERT চার্ট",
    "product_15": "ORM ডায়াগ্রাম",
    "product_16": "টাইমলাইন",
    "product_17": "ধারণা মানচিত্র",
    "product_18": "গাছের নকশা",
    "product_19": "ফিশবোন ডায়াগ্রাম",
    "product_20": "ইআর ডায়াগ্রাম",
    "product_21": "জিনোগ্রাম",
    "product_22": "ইউএমএল ডায়াগ্রাম",
    "business_use": "ব্যবসায়িক ব্যবহার",
  },
  "bs": {
    "my_mind_map": "Moja mapa uma",
    "h_products": "Proizvodi",
    "h_diagram_tool": "Diagram Tool",
    "image_tool": "Alat za slike",
    "diagrams": "Dijagrami",
    "individual_use": "Individual Use",
    "other_use": "Druga upotreba",
    "pricing": "Cijene",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Izrađivač mapa uma",
    "product_2": "Besplatno uklanjanje pozadine",
    "product_3": "Free Image Upscaler",
    "product_4": "Besplatno uklanjanje vodenih žigova",
    "product_5": "Free Image Compressor",
    "product_6": "Besplatni JPG Word Converter",
    "product_7": "Besplatan PDF PNG Converter",
    "product_8": "Besplatan HEIC u JPG pretvarač",
    "product_9": "Free Image Converter",
    "product_10": "Besplatni PDF JPG Converter",
    "product_11": "Dijagram toka",
    "product_12": "Org Chart",
    "product_13": "Ganttov grafikon",
    "product_14": "PERT grafikon",
    "product_15": "ORM dijagram",
    "product_16": "Vremenska linija",
    "product_17": "Koncept Map",
    "product_18": "Dijagram stabla",
    "product_19": "Dijagram riblje kosti",
    "product_20": "ER dijagram",
    "product_21": "Genogram",
    "product_22": "UML dijagram",
    "business_use": "Poslovna upotreba",
  },
  "ca": {
    "my_mind_map": "El meu mapa mental",
    "h_products": "Productes",
    "h_diagram_tool": "Eina de diagrama",
    "image_tool": "Eina d'imatge",
    "diagrams": "Esquemes",
    "individual_use": "Ús individual",
    "other_use": "Un altre ús",
    "pricing": "Preus",
    "tutorial": "Tutorial",
    "faq": "PMF",
    "blog": "Bloc",
    "product_1": "Creador de mapes mentals",
    "product_2": "Eliminador de fons gratuït",
    "product_3": "Upscaler d'imatges gratuït",
    "product_4": "Eliminador de filigrana gratuït",
    "product_5": "Compressor d'imatges gratuït",
    "product_6": "Convertidor de paraules JPG gratuït",
    "product_7": "Convertidor de PDF PNG gratuït",
    "product_8": "Convertidor gratuït de HEIC a JPG",
    "product_9": "Convertidor d'imatges gratuït",
    "product_10": "Convertidor de PDF JPG gratuït",
    "product_11": "Diagrama de flux",
    "product_12": "Organigrama",
    "product_13": "Diagrama de Gantt",
    "product_14": "Gràfic PERT",
    "product_15": "Diagrama ORM",
    "product_16": "Cronologia",
    "product_17": "Mapa conceptual",
    "product_18": "Diagrama d'arbre",
    "product_19": "Diagrama d'espina de peix",
    "product_20": "Diagrama ER",
    "product_21": "Genograma",
    "product_22": "Diagrama UML",
    "business_use": "Ús empresarial",
  },
  "cs": {
    "my_mind_map": "Moje myšlenková mapa",
    "h_products": "produkty",
    "h_diagram_tool": "Nástroj Diagram",
    "image_tool": "Nástroj Obrázek",
    "diagrams": "Diagramy",
    "individual_use": "Individuální použití",
    "other_use": "Jiné použití",
    "pricing": "Ceny",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Odstraňovač pozadí zdarma",
    "product_3": "Zdarma Image Upscaler",
    "product_4": "Odstraňovač vodoznaků zdarma",
    "product_5": "Kompresor obrázků zdarma",
    "product_6": "Zdarma JPG Word Converter",
    "product_7": "Zdarma PDF PNG Converter",
    "product_8": "Zdarma převodník HEIC na JPG",
    "product_9": "Převodník obrázků zdarma",
    "product_10": "Zdarma PDF JPG Converter",
    "product_11": "Vývojový diagram",
    "product_12": "Organizační schéma",
    "product_13": "Ganttův diagram",
    "product_14": "Graf PERT",
    "product_15": "Diagram ORM",
    "product_16": "Časová osa",
    "product_17": "Pojmová mapa",
    "product_18": "Stromový diagram",
    "product_19": "Diagram rybí kosti",
    "product_20": "ER diagram",
    "product_21": "Genogram",
    "product_22": "Diagram UML",
    "business_use": "Obchodní využití",
  },
  "cy": {
    "my_mind_map": "Fy Map Meddwl",
    "h_products": "Cynhyrchion",
    "h_diagram_tool": "Offeryn Diagram",
    "image_tool": "Offeryn Delwedd",
    "diagrams": "Diagramau",
    "individual_use": "Defnydd Unigol",
    "other_use": "Defnydd Arall",
    "pricing": "Prisio",
    "tutorial": "Tiwtorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Gwneuthurwr Mapiau Meddwl",
    "product_2": "Symudwr Cefndir Am Ddim",
    "product_3": "Upscaler Delwedd am ddim",
    "product_4": "Gwaredwr Dyfrnod Am Ddim",
    "product_5": "Cywasgydd Delwedd Am Ddim",
    "product_6": "Am ddim JPG Word Converter",
    "product_7": "Am ddim PDF PNG Converter",
    "product_8": "Trawsnewidydd HEIC i JPG am ddim",
    "product_9": "Trawsnewidydd Delwedd Am Ddim",
    "product_10": "Trawsnewidydd JPG PDF am ddim",
    "product_11": "Siart llif",
    "product_12": "Siart org",
    "product_13": "Siart Gantt",
    "product_14": "Siart PERT",
    "product_15": "Diagram ORM",
    "product_16": "Llinell Amser",
    "product_17": "Map Cysyniad",
    "product_18": "Diagram Coed",
    "product_19": "Diagram Asgwrn Pysgod",
    "product_20": "Diagram ER",
    "product_21": "Genogram",
    "product_22": "Diagram UML",
    "business_use": "Defnydd Busnes",
  },
  "da": {
    "my_mind_map": "Mit tankekort",
    "h_products": "Produkter",
    "h_diagram_tool": "Diagramværktøj",
    "image_tool": "Billedværktøj",
    "diagrams": "Diagrammer",
    "individual_use": "Individuel brug",
    "other_use": "Anden brug",
    "pricing": "Prissætning",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Gratis baggrundsfjerner",
    "product_3": "Gratis billedopskalering",
    "product_4": "Gratis fjernelse af vandmærker",
    "product_5": "Gratis billedkompressor",
    "product_6": "Gratis JPG Word Converter",
    "product_7": "Gratis PDF PNG Converter",
    "product_8": "Gratis HEIC til JPG-konverter",
    "product_9": "Gratis billedkonvertering",
    "product_10": "Gratis PDF JPG Converter",
    "product_11": "Flowchart",
    "product_12": "Organisationsdiagram",
    "product_13": "Gantt kort",
    "product_14": "PERT diagram",
    "product_15": "ORM-diagram",
    "product_16": "Tidslinje",
    "product_17": "Koncept kort",
    "product_18": "Trædiagram",
    "product_19": "Fiskebensdiagram",
    "product_20": "ER diagram",
    "product_21": "Genogram",
    "product_22": "UML-diagram",
    "business_use": "Forretningsbrug",
  },
  "de": {
    "my_mind_map": "Meine Mindmap",
    "h_products": "Produkte",
    "h_diagram_tool": "Diagramm-Tool",
    "image_tool": "Bild-Tool",
    "diagrams": "Diagramme",
    "individual_use": "Individuelle Nutzung",
    "other_use": "Andere Nutzung",
    "pricing": "Preise",
    "tutorial": "Anleitungen",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Free Background Remover",
    "product_3": "Free Image Upscaler",
    "product_4": "Free Watermark Remover",
    "product_5": "Free Image Compressor",
    "product_6": "Free JPG Word Converter",
    "product_7": "Free PDF PNG Converter",
    "product_8": "Free HEIC to JPG Converter",
    "product_9": "Free Image Converter",
    "product_10": "Free PDF JPG Converter",
    "product_11": "Flussdiagramm",
    "product_12": "Organigramm",
    "product_13": "Gantt-Diagramm",
    "product_14": "PERT-Diagramm",
    "product_15": "ORM-Diagramm",
    "product_16": "Zeitleiste",
    "product_17": "Konzeptkarte",
    "product_18": "Baumdiagramm",
    "product_19": "Ishikawa-Diagramm",
    "product_20": "ER-Diagramm",
    "product_21": "Genogramm",
    "product_22": "UML-Diagramm",
    "business_use": "Geschäftliche Nutzung",
  },
  "el": {
    "my_mind_map": "My Mind Map",
    "h_products": "Προϊόντα",
    "h_diagram_tool": "Εργαλείο διαγράμματος",
    "image_tool": "Εργαλείο εικόνας",
    "diagrams": "Διαγράμματα",
    "individual_use": "Ατομική χρήση",
    "other_use": "Άλλη χρήση",
    "pricing": "Τιμολόγηση",
    "tutorial": "Φροντιστήριο",
    "faq": "FAQ",
    "blog": "Ιστολόγιο",
    "product_1": "Mind Map Maker",
    "product_2": "Δωρεάν αφαίρεση φόντου",
    "product_3": "Δωρεάν αναβάθμιση εικόνας",
    "product_4": "Δωρεάν αφαίρεση υδατογραφημάτων",
    "product_5": "Δωρεάν Συμπιεστής Εικόνας",
    "product_6": "Δωρεάν μετατροπέας λέξεων JPG",
    "product_7": "Δωρεάν μετατροπέας PDF PNG",
    "product_8": "Δωρεάν μετατροπέας HEIC σε JPG",
    "product_9": "Δωρεάν μετατροπέας εικόνας",
    "product_10": "Δωρεάν μετατροπέας PDF JPG",
    "product_11": "ΔΙΑΓΡΑΜΜΑ ΡΟΗΣ",
    "product_12": "Οργανόγραμμα",
    "product_13": "Gantt διάγραμμα",
    "product_14": "Διάγραμμα PERT",
    "product_15": "Διάγραμμα ORM",
    "product_16": "Χρονοδιάγραμμα",
    "product_17": "Εννοιολογικός χάρτης",
    "product_18": "Δέντρο διάγραμμα",
    "product_19": "Διάγραμμα Fishbone",
    "product_20": "Διάγραμμα ER",
    "product_21": "Γονιδόγραμμα",
    "product_22": "Διάγραμμα UML",
    "business_use": "Επιχειρηματική χρήση",
  },
  "es": {
    "my_mind_map": "Mi mapa mental",
    "h_products": "Productos",
    "h_diagram_tool": "Herramienta de diagrama",
    "image_tool": "Herramienta de imagen",
    "diagrams": "Diagramas",
    "individual_use": "Uso individual",
    "other_use": "Otro uso",
    "pricing": "Precios",
    "tutorial": "Tutorial",
    "faq": "Preguntas más frecuentes",
    "blog": "Blog",
    "product_1": "Creador de mapas mentales",
    "product_2": "Eliminador de fondo gratuito",
    "product_3": "Mejorador de imágenes gratuito",
    "product_4": "Eliminador de marcas de agua gratuito",
    "product_5": "Compresor de imágenes gratuito",
    "product_6": "Convertidor de Word JPG gratuito",
    "product_7": "Convertidor PDF PNG gratuito",
    "product_8": "Convertidor HEIC a JPG gratuito",
    "product_9": "Convertidor de imágenes gratuito",
    "product_10": "Convertidor PDF JPG gratuito",
    "product_11": "Diagrama de flujo",
    "product_12": "Organigráma",
    "product_13": "Gráfico de gantt",
    "product_14": "Gráfico PERT",
    "product_15": "Diagrama ORM",
    "product_16": "Línea de tiempo",
    "product_17": "Mapa conceptual",
    "product_18": "Diagrama de árbol",
    "product_19": "Diagrama de espina de pescado",
    "product_20": "Diagrama ER",
    "product_21": "Genograma",
    "product_22": "Diagrama UML",
    "business_use": "Uso para negocios",
  },
  "et": {
    "my_mind_map": "Minu mõttekaart",
    "h_products": "Tooted",
    "h_diagram_tool": "Diagrammi tööriist",
    "image_tool": "Pilditööriist",
    "diagrams": "Diagrammid",
    "individual_use": "Individuaalne kasutamine",
    "other_use": "Muu kasutamine",
    "pricing": "Hinnakujundus",
    "tutorial": "Õpetus",
    "faq": "KKK",
    "blog": "Blogi",
    "product_1": "Mõttekaardi koostaja",
    "product_2": "Tasuta tausta eemaldaja",
    "product_3": "Tasuta pildi suurendaja",
    "product_4": "Tasuta vesimärkide eemaldaja",
    "product_5": "Tasuta pildikompressor",
    "product_6": "Tasuta JPG Word Converter",
    "product_7": "Tasuta PDF-i PNG-muundur",
    "product_8": "Tasuta HEIC-JPG-muundur",
    "product_9": "Tasuta pildimuundur",
    "product_10": "Tasuta PDF JPG-muundur",
    "product_11": "Vooskeem",
    "product_12": "Organisatsiooni diagramm",
    "product_13": "Gantti diagramm",
    "product_14": "PERT diagramm",
    "product_15": "ORM-i diagramm",
    "product_16": "Ajaskaala",
    "product_17": "Kontseptsioonikaart",
    "product_18": "Puu diagramm",
    "product_19": "Kalalaba diagramm",
    "product_20": "ER diagramm",
    "product_21": "Genogramm",
    "product_22": "UML diagramm",
    "business_use": "Ärikasutus",
  },
  "eu": {
    "my_mind_map": "Nire Buru Mapa",
    "h_products": "Produktuak",
    "h_diagram_tool": "Diagrama tresna",
    "image_tool": "Irudi tresna",
    "diagrams": "Diagramak",
    "individual_use": "Banakako Erabilera",
    "other_use": "Beste erabilera batzuk",
    "pricing": "Prezioak",
    "tutorial": "Tutoretza",
    "faq": "ohiko galderak",
    "blog": "Bloga",
    "product_1": "Mind Map Maker",
    "product_2": "Doako atzeko planoa kentzeko",
    "product_3": "Doako Irudien Upscaler",
    "product_4": "Doako Ur-markak kentzeko",
    "product_5": "Doako Irudi Konpresorea",
    "product_6": "Doako JPG Word bihurgailua",
    "product_7": "Doako PDF PNG bihurgailua",
    "product_8": "Doako HEIC JPG bihurgailua",
    "product_9": "Doako Irudi Bihurtzailea",
    "product_10": "Doako PDF JPG bihurgailua",
    "product_11": "Fluxu-diagrama",
    "product_12": "Organigrama",
    "product_13": "Gantt Diagrama",
    "product_14": "PERT Diagrama",
    "product_15": "ORM Diagrama",
    "product_16": "Denbora-lerroa",
    "product_17": "Kontzeptu mapa",
    "product_18": "Zuhaitz Diagrama",
    "product_19": "Arrain-hezurren diagrama",
    "product_20": "ER Diagrama",
    "product_21": "Genograma",
    "product_22": "UML Diagrama",
    "business_use": "Enpresa Erabilera",
  },
  "fa": {
    "my_mind_map": "نقشه ذهنی من",
    "h_products": "محصولات",
    "h_diagram_tool": "ابزار نمودار",
    "image_tool": "ابزار تصویر",
    "diagrams": "نمودارها",
    "individual_use": "استفاده فردی",
    "other_use": "استفاده دیگر",
    "pricing": "قیمت گذاری",
    "tutorial": "آموزش",
    "faq": "سوالات متداول",
    "blog": "وبلاگ",
    "product_1": "ذهن نقشه ساز",
    "product_2": "پاک کننده پس زمینه رایگان",
    "product_3": "ارتقاء تصویر رایگان",
    "product_4": "حذف واترمارک رایگان",
    "product_5": "کمپرسور تصویر رایگان",
    "product_6": "تبدیل رایگان JPG Word",
    "product_7": "تبدیل رایگان PDF PNG",
    "product_8": "مبدل رایگان HEIC به JPG",
    "product_9": "مبدل تصویر رایگان",
    "product_10": "مبدل PDF JPG رایگان",
    "product_11": "فلوچارت",
    "product_12": "چارت سازمانی",
    "product_13": "نمودار گانت",
    "product_14": "نمودار PERT",
    "product_15": "نمودار ORM",
    "product_16": "جدول زمانی",
    "product_17": "نقشه مفهومی",
    "product_18": "نمودار درختی",
    "product_19": "نمودار استخوان ماهی",
    "product_20": "نمودار ER",
    "product_21": "ژنوگرام",
    "product_22": "نمودار UML",
    "business_use": "استفاده تجاری",
  },
  "fi": {
    "my_mind_map": "Minun mieleni kartta",
    "h_products": "Tuotteet",
    "h_diagram_tool": "Kaaviotyökalu",
    "image_tool": "Kuvatyökalu",
    "diagrams": "Kaaviot",
    "individual_use": "Yksilöllinen käyttö",
    "other_use": "Muu käyttö",
    "pricing": "Hinnoittelu",
    "tutorial": "Opetusohjelma",
    "faq": "FAQ",
    "blog": "Blogi",
    "product_1": "Mind Map Maker",
    "product_2": "Ilmainen taustanpoistoaine",
    "product_3": "Ilmainen Image Upscaler",
    "product_4": "Ilmainen vesileimanpoistoaine",
    "product_5": "Ilmainen kuvan kompressori",
    "product_6": "Ilmainen JPG Word Converter",
    "product_7": "Ilmainen PDF PNG Converter",
    "product_8": "Ilmainen HEIC-JPG-muunnin",
    "product_9": "Ilmainen kuvanmuunnin",
    "product_10": "Ilmainen PDF JPG Converter",
    "product_11": "Vuokaavio",
    "product_12": "Organisaatiokaavio",
    "product_13": "Gantt-kaavio",
    "product_14": "PERT-kaavio",
    "product_15": "ORM-kaavio",
    "product_16": "Aikajana",
    "product_17": "Konseptikartta",
    "product_18": "Puun kaavio",
    "product_19": "Kalanruotokaavio",
    "product_20": "ER-kaavio",
    "product_21": "Genogrammi",
    "product_22": "UML-kaavio",
    "business_use": "Yrityskäyttö",
  },
  "fr": {
    "my_mind_map": "Ma Carte Mentale",
    "h_products": "Produits",
    "h_diagram_tool": "Outil de Diagramme",
    "image_tool": "Outil Image",
    "diagrams": "Diagrammes",
    "individual_use": "Utilisation individuelle",
    "other_use": "Autre utilisation",
    "pricing": "Tarifs",
    "tutorial": "Tutoriel",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Free Background Remover",
    "product_3": "Free Image Upscaler",
    "product_4": "Free Watermark Remover",
    "product_5": "Free Image Compressor",
    "product_6": "Free JPG Word Converter",
    "product_7": "Free PDF PNG Converter",
    "product_8": "Free HEIC to JPG Converter",
    "product_9": "Free Image Converter",
    "product_10": "Free PDF JPG Converter",
    "product_11": "Diagramme Logique",
    "product_12": "Organigramme",
    "product_13": "Diagramme de Gantt",
    "product_14": "PERT Graphique",
    "product_15": "Diagramme ORM",
    "product_16": "Chronologie",
    "product_17": "Carte Conceptuelle",
    "product_18": "Diagramme en Arbre",
    "product_19": "Diagramme en Arête de Poisson",
    "product_20": "Diagramme ER",
    "product_21": "Génogramme",
    "product_22": "Diagramme UML",
    "business_use": "Utilisation Commerciale",
  },
  "gl": {
    "my_mind_map": "O meu mapa mental",
    "h_products": "Produtos",
    "h_diagram_tool": "Ferramenta Diagrama",
    "image_tool": "Ferramenta de imaxe",
    "diagrams": "Diagramas",
    "individual_use": "Uso Individual",
    "other_use": "Outro uso",
    "pricing": "Prezos",
    "tutorial": "Titoría",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Creador de mapas mentais",
    "product_2": "Eliminador de fondo gratuíto",
    "product_3": "Upscaler de imaxes gratuíto",
    "product_4": "Eliminador de marcas de auga gratuíto",
    "product_5": "Compresor de imaxes gratuíto",
    "product_6": "Conversor de palabras JPG gratuíto",
    "product_7": "Converter PDF PNG gratuíto",
    "product_8": "Conversor gratuíto de HEIC a JPG",
    "product_9": "Conversor de imaxes gratuíto",
    "product_10": "Converter PDF JPG gratuíto",
    "product_11": "Diagrama de fluxo",
    "product_12": "Organigrama",
    "product_13": "Diagrama de Gantt",
    "product_14": "Gráfico PERT",
    "product_15": "Diagrama ORM",
    "product_16": "Cronoloxía",
    "product_17": "Mapa Conceptual",
    "product_18": "Diagrama de árbore",
    "product_19": "Diagrama de espiña de peixe",
    "product_20": "Diagrama ER",
    "product_21": "Xenograma",
    "product_22": "Diagrama UML",
    "business_use": "Uso empresarial",
  },
  "gu": {
    "my_mind_map": "માય માઇન્ડ મેપ",
    "h_products": "ઉત્પાદનો",
    "h_diagram_tool": "ડાયાગ્રામ ટૂલ",
    "image_tool": "છબી સાધન",
    "diagrams": "આકૃતિઓ",
    "individual_use": "વ્યક્તિગત ઉપયોગ",
    "other_use": "અન્ય ઉપયોગ",
    "pricing": "કિંમત નિર્ધારણ",
    "tutorial": "ટ્યુટોરીયલ",
    "faq": "FAQ",
    "blog": "બ્લોગ",
    "product_1": "માઇન્ડ મેપ મેકર",
    "product_2": "મફત પૃષ્ઠભૂમિ રીમુવર",
    "product_3": "મફત છબી અપસ્કેલર",
    "product_4": "મફત વોટરમાર્ક રીમુવર",
    "product_5": "મફત છબી કોમ્પ્રેસર",
    "product_6": "મફત JPG વર્ડ કન્વર્ટર",
    "product_7": "મફત PDF PNG કન્વર્ટર",
    "product_8": "મફત HEIC થી JPG કન્વર્ટર",
    "product_9": "મફત છબી કન્વર્ટર",
    "product_10": "મફત પીડીએફ જેપીજી કન્વર્ટર",
    "product_11": "ફ્લોચાર્ટ",
    "product_12": "સંસ્થા ચાર્ટ",
    "product_13": "ગેંટ ચાર્ટ",
    "product_14": "PERT ચાર્ટ",
    "product_15": "ORM ડાયાગ્રામ",
    "product_16": "સમયરેખા",
    "product_17": "કન્સેપ્ટ મેપ",
    "product_18": "વૃક્ષ રેખાકૃતિ",
    "product_19": "ફિશબોન ડાયાગ્રામ",
    "product_20": "ER ડાયાગ્રામ",
    "product_21": "જીનોગ્રામ",
    "product_22": "UML ડાયાગ્રામ",
    "business_use": "વ્યાપાર ઉપયોગ",
  },
  "he": {
    "my_mind_map": "מפת המוח שלי",
    "h_products": "מוצרים",
    "h_diagram_tool": "כלי דיאגרמות",
    "image_tool": "כלי תמונה",
    "diagrams": "דיאגרמות",
    "individual_use": "שימוש אישי",
    "other_use": "שימוש אחר",
    "pricing": "תמחור",
    "tutorial": "הדרכה",
    "faq": "שאלות נפוצות",
    "blog": "בלוג",
    "product_1": "יוצר מפות חשיבה",
    "product_2": "מסיר רקע בחינם",
    "product_3": "חינם תמונה Upscaler",
    "product_4": "מסיר סימני מים בחינם",
    "product_5": "מדחס תמונה בחינם",
    "product_6": "ממיר מילים JPG בחינם",
    "product_7": "ממיר PDF PNG בחינם",
    "product_8": "ממיר HEIC ל-JPG בחינם",
    "product_9": "ממיר תמונות בחינם",
    "product_10": "ממיר PDF JPG בחינם",
    "product_11": "תרשים זרימה",
    "product_12": "תרשים ארגוני",
    "product_13": "תרשים גנט",
    "product_14": "תרשים PERT",
    "product_15": "דיאגרמת ORM",
    "product_16": "ציר זמן",
    "product_17": "מפת קונספט",
    "product_18": "דיאגרמת עץ",
    "product_19": "דיאגרמת עצם הדג",
    "product_20": "דיאגרמת ER",
    "product_21": "גנוגרמה",
    "product_22": "דיאגרמת UML",
    "business_use": "שימוש עסקי",
  },
  "hi": {
    "my_mind_map": "मेरा माइंड मैप",
    "h_products": "उत्पादों",
    "h_diagram_tool": "आरेख उपकरण",
    "image_tool": "छवि उपकरण",
    "diagrams": "चित्र",
    "individual_use": "व्यक्तिगत उपयोग",
    "other_use": "अन्य उपयोग",
    "pricing": "मूल्य निर्धारण",
    "tutorial": "ट्यूटोरियल",
    "faq": "सामान्य प्रश्न",
    "blog": "ब्लॉग",
    "product_1": "माइंड मैप मेकर",
    "product_2": "निःशुल्क बैकग्राउंड रिमूवर",
    "product_3": "निःशुल्क छवि अपस्केलर",
    "product_4": "निःशुल्क वॉटरमार्क रिमूवर",
    "product_5": "निःशुल्क छवि संपीड़क",
    "product_6": "निःशुल्क JPG वर्ड कनवर्टर",
    "product_7": "निःशुल्क पीडीएफ पीएनजी कनवर्टर",
    "product_8": "निःशुल्क HEIC से JPG कनवर्टर",
    "product_9": "निःशुल्क छवि कनवर्टर",
    "product_10": "निःशुल्क पीडीएफ जेपीजी कनवर्टर",
    "product_11": "फ़्लोचार्ट",
    "product_12": "नियमावली",
    "product_13": "गैंट चार्ट",
    "product_14": "PERT चार्ट",
    "product_15": "ORM आरेख",
    "product_16": "समय",
    "product_17": "अवधारणा नक्शे",
    "product_18": "वृक्षारेख",
    "product_19": "फ़िशबोन चित्र",
    "product_20": "ईआर आरेख",
    "product_21": "जेनोग्राम",
    "product_22": "यूएमएल आरेख",
    "business_use": "व्यावसायिक उपयोग",
  },
  "hr": {
    "my_mind_map": "Moja mapa uma",
    "h_products": "Proizvodi",
    "h_diagram_tool": "Alat za dijagrame",
    "image_tool": "Alat za slike",
    "diagrams": "Dijagrami",
    "individual_use": "Individualna upotreba",
    "other_use": "Druga uporaba",
    "pricing": "Cijene",
    "tutorial": "Tutorial",
    "faq": "Pitanja",
    "blog": "Blog",
    "product_1": "Izrađivač umnih mapa",
    "product_2": "Besplatni alat za uklanjanje pozadine",
    "product_3": "Besplatni Image Upscaler",
    "product_4": "Besplatni alat za uklanjanje vodenog žiga",
    "product_5": "Besplatni kompresor slika",
    "product_6": "Besplatni JPG Word Converter",
    "product_7": "Besplatni PDF PNG pretvarač",
    "product_8": "Besplatni pretvarač HEIC u JPG",
    "product_9": "Besplatni pretvarač slika",
    "product_10": "Besplatni PDF pretvarač u JPG",
    "product_11": "Dijagram toka",
    "product_12": "Organizacijska shema",
    "product_13": "Gantogram",
    "product_14": "PERT grafikon",
    "product_15": "ORM dijagram",
    "product_16": "Vremenska Crta",
    "product_17": "Konceptna karta",
    "product_18": "Dijagram stabla",
    "product_19": "Dijagram riblje kosti",
    "product_20": "ER dijagram",
    "product_21": "genogram",
    "product_22": "UML dijagram",
    "business_use": "Poslovna upotreba",
  },
  "hu": {
    "my_mind_map": "Saját gondolattérkép",
    "h_products": "Termékek",
    "h_diagram_tool": "Diagram eszköz",
    "image_tool": "Kép eszköz",
    "diagrams": "Diagramok",
    "individual_use": "Egyéni használat",
    "other_use": "Egyéb felhasználás",
    "pricing": "Árazás",
    "tutorial": "Oktatóanyag",
    "faq": "GYIK",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Ingyenes háttéreltávolító",
    "product_3": "Ingyenes Image Upscaler",
    "product_4": "Ingyenes vízjel eltávolító",
    "product_5": "Ingyenes képkompresszor",
    "product_6": "Ingyenes JPG Word Converter",
    "product_7": "Ingyenes PDF PNG konverter",
    "product_8": "Ingyenes HEIC-JPG konverter",
    "product_9": "Ingyenes Képkonverter",
    "product_10": "Ingyenes PDF JPG konverter",
    "product_11": "Folyamatábra",
    "product_12": "Szervezeti ábra",
    "product_13": "Gantt-diagram",
    "product_14": "PERT diagram",
    "product_15": "ORM diagram",
    "product_16": "Idővonal",
    "product_17": "koncepció térkép",
    "product_18": "Fa diagram",
    "product_19": "Halcsont diagram",
    "product_20": "ER diagram",
    "product_21": "Genogram",
    "product_22": "UML diagram",
    "business_use": "Üzleti felhasználás",
  },
  "hy": {
    "my_mind_map": "Իմ մտքի քարտեզը",
    "h_products": "Ապրանքներ",
    "h_diagram_tool": "Դիագրամի գործիք",
    "image_tool": "Պատկերի գործիք",
    "diagrams": "Դիագրամներ",
    "individual_use": "Անհատական Օգտագործում",
    "other_use": "Այլ Օգտագործում",
    "pricing": "Գնագոյացում",
    "tutorial": "Ուսուցողական",
    "faq": "ՀՏՀ",
    "blog": "Բլոգ",
    "product_1": "Mind Map Maker",
    "product_2": "Անվճար ֆոնային ապակարիչ",
    "product_3": "Անվճար պատկերի բարձրացում",
    "product_4": "Անվճար ջրի մակարդակի նշագիծ հեռացնող միջոց",
    "product_5": "Անվճար պատկերի կոմպրեսոր",
    "product_6": "Անվճար JPG Word Converter",
    "product_7": "Անվճար PDF PNG փոխարկիչ",
    "product_8": "Անվճար HEIC-ից JPG փոխարկիչ",
    "product_9": "Անվճար պատկերի փոխարկիչ",
    "product_10": "Անվճար PDF JPG փոխարկիչ",
    "product_11": "Հոսքերի գծապատկեր",
    "product_12": "Օրգ աղյուսակ",
    "product_13": "Gantt աղյուսակ",
    "product_14": "PERT գծապատկեր",
    "product_15": "ORM դիագրամ",
    "product_16": "Ժամանակացույց",
    "product_17": "Հայեցակարգային քարտեզ",
    "product_18": "Ծառի դիագրամ",
    "product_19": "Ձկան ոսկորների դիագրամ",
    "product_20": "ER դիագրամ",
    "product_21": "Գենոգրամ",
    "product_22": "UML դիագրամ",
    "business_use": "Բիզնեսի օգտագործում",
  },
  "id": {
    "my_mind_map": "Peta Pikiran Saya",
    "h_products": "Produk",
    "h_diagram_tool": "Alat Diagram",
    "image_tool": "Alat Gambar",
    "diagrams": "Diagram",
    "individual_use": "Penggunaan Individu",
    "other_use": "Penggunaan Lainnya",
    "pricing": "Harga",
    "tutorial": "tutorial",
    "faq": "Pertanyaan Umum",
    "blog": "Blog",
    "product_1": "Pembuat Peta Pikiran",
    "product_2": "Penghapus Latar Belakang Gratis",
    "product_3": "Peningkatan Gambar Gratis",
    "product_4": "Penghilang Tanda Air Gratis",
    "product_5": "Kompresor Gambar Gratis",
    "product_6": "Konverter Kata JPG Gratis",
    "product_7": "Konverter PDF PNG Gratis",
    "product_8": "Konverter HEIC ke JPG gratis",
    "product_9": "Pengonversi Gambar Gratis",
    "product_10": "Konverter PDF JPG Gratis",
    "product_11": "Flow chart",
    "product_12": "Bagan Organisasi",
    "product_13": "Bagan Gantt",
    "product_14": "Bagan PERT",
    "product_15": "Diagram ORM",
    "product_16": "Linimasa",
    "product_17": "Peta konsep",
    "product_18": "Diagram pohon",
    "product_19": "Diagram Tulang Ikan",
    "product_20": "Diagram ER",
    "product_21": "Genogram",
    "product_22": "Diagram UML",
    "business_use": "Penggunaan Bisnis",
  },
  "it": {
    "my_mind_map": "La mia mappa mentale",
    "h_products": "Prodotti",
    "h_diagram_tool": "Strumento diagramma",
    "image_tool": "Strumento immagine",
    "diagrams": "Diagrammi",
    "individual_use": "Uso individuale",
    "other_use": "Altro uso",
    "pricing": "Prezzi",
    "tutorial": "Esercitazione",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Creatore di mappe mentali",
    "product_2": "Rimozione sfondo gratuita",
    "product_3": "Miglioratore di immagini gratuito",
    "product_4": "Rimozione filigrana gratuita",
    "product_5": "Compressore di immagini gratuito",
    "product_6": "Convertitore di parole JPG gratuito",
    "product_7": "Convertitore PDF PNG gratuito",
    "product_8": "Convertitore gratuito da HEIC a JPG",
    "product_9": "Convertitore di immagini gratuito",
    "product_10": "Convertitore PDF JPG gratuito",
    "product_11": "Diagramma di flusso",
    "product_12": "Organigramma",
    "product_13": "Diagramma di Gantt",
    "product_14": "Grafico PERT",
    "product_15": "Diagramma ORM",
    "product_16": "Sequenza temporale",
    "product_17": "Mappa concettuale",
    "product_18": "Diagramma ad albero",
    "product_19": "Diagramma a lisca di pesce",
    "product_20": "Diagramma ER",
    "product_21": "Genogramma",
    "product_22": "Diagramma UML",
    "business_use": "Uso aziendale",
  },
  "ja": {
    "my_mind_map": "私のマインドマップ",
    "h_products": "製品",
    "h_diagram_tool": "ダイアグラムツール",
    "image_tool": "画像ツール",
    "diagrams": "ダイアグラム",
    "individual_use": "個人使用",
    "other_use": "その他の用途",
    "pricing": "価格",
    "tutorial": "チュートリアル",
    "faq": "よくある質問",
    "blog": "ブログ",
    "product_1": "マインドマップメーカー",
    "product_2": "無料の背景削除ツール",
    "product_3": "無料の画像アップスケーラー",
    "product_4": "無料の透かし除去ツール",
    "product_5": "無料画像圧縮ソフト",
    "product_6": "無料のJPG Wordコンバーター",
    "product_7": "無料のPDF PNGコンバータ",
    "product_8": "無料のHEICからJPGへのコンバーター",
    "product_9": "無料画像コンバーター",
    "product_10": "無料のPDF JPGコンバーター",
    "product_11": "フローチャート",
    "product_12": "組織図",
    "product_13": "ガントチャート",
    "product_14": "PERTチャート",
    "product_15": "ORM ダイアグラム",
    "product_16": "タイムライン",
    "product_17": "コンセプトマップ",
    "product_18": "樹形図",
    "product_19": "フィッシュボーンダイアグラム",
    "product_20": "ER ダイアグラム",
    "product_21": "ジェノグラム",
    "product_22": "UMLダイアグラム",
    "business_use": "商用",
  },
  "ka": {
    "my_mind_map": "ჩემი გონების რუკა",
    "h_products": "პროდუქტები",
    "h_diagram_tool": "დიაგრამის ხელსაწყო",
    "image_tool": "გამოსახულების ხელსაწყო",
    "diagrams": "დიაგრამები",
    "individual_use": "ინდივიდუალური გამოყენება",
    "other_use": "სხვა გამოყენება",
    "pricing": "ფასი",
    "tutorial": "სახელმძღვანელო",
    "faq": "FAQ",
    "blog": "ბლოგი",
    "product_1": "Mind Map Maker",
    "product_2": "უფასო ფონის მოსაშორებელი",
    "product_3": "უფასო გამოსახულების გამაძლიერებელი",
    "product_4": "უფასო Watermark Remover",
    "product_5": "უფასო გამოსახულების კომპრესორი",
    "product_6": "უფასო JPG Word Converter",
    "product_7": "უფასო PDF PNG კონვერტორი",
    "product_8": "უფასო HEIC-დან JPG-ის კონვერტორი",
    "product_9": "უფასო გამოსახულების გადამყვანი",
    "product_10": "უფასო PDF JPG კონვერტორი",
    "product_11": "ნაკადის სქემა",
    "product_12": "Org Chart",
    "product_13": "Განტის დიაგრამა",
    "product_14": "PERT დიაგრამა",
    "product_15": "ORM დიაგრამა",
    "product_16": "Ვადები",
    "product_17": "კონცეფციის რუკა",
    "product_18": "ხის დიაგრამა",
    "product_19": "თევზის ძვლის დიაგრამა",
    "product_20": "ER დიაგრამა",
    "product_21": "გენოგრამა",
    "product_22": "UML დიაგრამა",
    "business_use": "ბიზნეს გამოყენება",
  },
  "kk": {
    "my_mind_map": "Менің ақыл картасы",
    "h_products": "Өнімдер",
    "h_diagram_tool": "Диаграмма құралы",
    "image_tool": "Кескін құралы",
    "diagrams": "Диаграммалар",
    "individual_use": "Жеке пайдалану",
    "other_use": "Басқа қолдану",
    "pricing": "Баға белгілеу",
    "tutorial": "Оқулық",
    "faq": "Жиі қойылатын сұрақтар",
    "blog": "Блог",
    "product_1": "Mind Map Maker",
    "product_2": "Тегін фонды кетіргіш",
    "product_3": "Тегін кескінді кеңейткіш",
    "product_4": "Тегін су таңбасын кетіргіш",
    "product_5": "Тегін кескін компрессоры",
    "product_6": "Тегін JPG Word түрлендіргіші",
    "product_7": "Тегін PDF PNG түрлендіргіші",
    "product_8": "HEIC-тен JPG-ге тегін түрлендіргіш",
    "product_9": "Тегін кескін түрлендіргіші",
    "product_10": "Тегін PDF JPG түрлендіргіші",
    "product_11": "Блок-схема",
    "product_12": "Ұйым диаграммасы",
    "product_13": "Гант диаграммасы",
    "product_14": "PERT диаграммасы",
    "product_15": "ORM диаграммасы",
    "product_16": "Хронология",
    "product_17": "Тұжырымдама картасы",
    "product_18": "Ағаш диаграммасы",
    "product_19": "Балық сүйектерінің диаграммасы",
    "product_20": "ER диаграммасы",
    "product_21": "Генограмма",
    "product_22": "UML диаграммасы",
    "business_use": "Іскерлік пайдалану",
  },
  "km": {
    "my_mind_map": "ផែនទីគំនិតរបស់ខ្ញុំ",
    "h_products": "ផលិតផល",
    "h_diagram_tool": "ឧបករណ៍ដ្យាក្រាម",
    "image_tool": "ឧបករណ៍រូបភាព",
    "diagrams": "ដ្យាក្រាម",
    "individual_use": "ការប្រើប្រាស់ផ្ទាល់ខ្លួន",
    "other_use": "ការប្រើប្រាស់ផ្សេងទៀត។",
    "pricing": "តម្លៃ",
    "tutorial": "ការបង្រៀន",
    "faq": "សំណួរគេសួរញឹកញាប់",
    "blog": "ប្លុក",
    "product_1": "អ្នកបង្កើតផែនទីគំនិត",
    "product_2": "កម្មវិធីលុបផ្ទៃខាងក្រោយឥតគិតថ្លៃ",
    "product_3": "Upscaler រូបភាពឥតគិតថ្លៃ",
    "product_4": "ឧបករណ៍លុបស្នាមទឹកដោយឥតគិតថ្លៃ",
    "product_5": "ម៉ាស៊ីនបង្ហាប់រូបភាពឥតគិតថ្លៃ",
    "product_6": "កម្មវិធីបម្លែងពាក្យ JPG ឥតគិតថ្លៃ",
    "product_7": "កម្មវិធីបម្លែង PDF PNG ឥតគិតថ្លៃ",
    "product_8": "កម្មវិធីបំលែង HEIC ទៅ JPG ដោយឥតគិតថ្លៃ",
    "product_9": "កម្មវិធីបម្លែងរូបភាពឥតគិតថ្លៃ",
    "product_10": "កម្មវិធីបំលែង PDF JPG ឥតគិតថ្លៃ",
    "product_11": "គំនូសតាងលំហូរ",
    "product_12": "គំនូសតាងអង្គការ",
    "product_13": "គំនូសតាង Gantt",
    "product_14": "តារាង PERT",
    "product_15": "ដ្យាក្រាម ORM",
    "product_16": "បន្ទាត់ពេលវេលា",
    "product_17": "ផែនទីគំនិត",
    "product_18": "ដ្យាក្រាមដើមឈើ",
    "product_19": "ដ្យាក្រាមឆ្អឹងត្រី",
    "product_20": "ដ្យាក្រាម ER",
    "product_21": "ហ្សែន",
    "product_22": "ដ្យាក្រាម UML",
    "business_use": "ការប្រើប្រាស់អាជីវកម្ម",
  },
  "kn": {
    "my_mind_map": "ನನ್ನ ಮೈಂಡ್ ಮ್ಯಾಪ್",
    "h_products": "ಉತ್ಪನ್ನಗಳು",
    "h_diagram_tool": "ರೇಖಾಚಿತ್ರ ಉಪಕರಣ",
    "image_tool": "ಇಮೇಜ್ ಟೂಲ್",
    "diagrams": "ರೇಖಾಚಿತ್ರಗಳು",
    "individual_use": "ವೈಯಕ್ತಿಕ ಬಳಕೆ",
    "other_use": "ಇತರೆ ಬಳಕೆ",
    "pricing": "ಬೆಲೆ ನಿಗದಿ",
    "tutorial": "ಟ್ಯುಟೋರಿಯಲ್",
    "faq": "FAQ",
    "blog": "ಬ್ಲಾಗ್",
    "product_1": "ಮೈಂಡ್ ಮ್ಯಾಪ್ ಮೇಕರ್",
    "product_2": "ಉಚಿತ ಹಿನ್ನೆಲೆ ಹೋಗಲಾಡಿಸುವವನು",
    "product_3": "ಉಚಿತ ಇಮೇಜ್ ಅಪ್‌ಸ್ಕೇಲರ್",
    "product_4": "ಉಚಿತ ವಾಟರ್‌ಮಾರ್ಕ್ ಹೋಗಲಾಡಿಸುವವನು",
    "product_5": "ಉಚಿತ ಇಮೇಜ್ ಸಂಕೋಚಕ",
    "product_6": "ಉಚಿತ JPG ವರ್ಡ್ ಪರಿವರ್ತಕ",
    "product_7": "ಉಚಿತ PDF PNG ಪರಿವರ್ತಕ",
    "product_8": "ಉಚಿತ HEIC ಗೆ JPG ಪರಿವರ್ತಕ",
    "product_9": "ಉಚಿತ ಚಿತ್ರ ಪರಿವರ್ತಕ",
    "product_10": "ಉಚಿತ PDF JPG ಪರಿವರ್ತಕ",
    "product_11": "ಫ್ಲೋಚಾರ್ಟ್",
    "product_12": "ಆರ್ಗ್ ಚಾರ್ಟ್",
    "product_13": "ಗ್ಯಾಂಟ್ ಚಾರ್ಟ್",
    "product_14": "PERT ಚಾರ್ಟ್",
    "product_15": "ORM ರೇಖಾಚಿತ್ರ",
    "product_16": "ಟೈಮ್‌ಲೈನ್",
    "product_17": "ಪರಿಕಲ್ಪನೆ ನಕ್ಷೆ",
    "product_18": "ಮರದ ರೇಖಾಚಿತ್ರ",
    "product_19": "ಫಿಶ್ಬೋನ್ ರೇಖಾಚಿತ್ರ",
    "product_20": "ಇಆರ್ ರೇಖಾಚಿತ್ರ",
    "product_21": "ಜಿನೋಗ್ರಾಮ್",
    "product_22": "UML ರೇಖಾಚಿತ್ರ",
    "business_use": "ವ್ಯಾಪಾರ ಬಳಕೆ",
  },
  "ko": {
    "my_mind_map": "내 마인드맵",
    "h_products": "제품",
    "h_diagram_tool": "다이어그램 도구",
    "image_tool": "이미지 도구",
    "diagrams": "다이어그램",
    "individual_use": "개인 사용",
    "other_use": "기타 용도",
    "pricing": "가격",
    "tutorial": "지도 시간",
    "faq": "자주하는 질문",
    "blog": "블로그",
    "product_1": "마인드맵 메이커",
    "product_2": "무료 배경 제거제",
    "product_3": "무료 이미지 업스케일러",
    "product_4": "무료 워터마크 제거제",
    "product_5": "무료 이미지 압축기",
    "product_6": "무료 JPG 워드 변환기",
    "product_7": "무료 PDF PNG 변환기",
    "product_8": "무료 HEIC를 JPG로 변환하는 변환기",
    "product_9": "무료 이미지 변환기",
    "product_10": "무료 PDF JPG 변환기",
    "product_11": "흐름도",
    "product_12": "조직도",
    "product_13": "간트 차트",
    "product_14": "PERT 차트",
    "product_15": "ORM 다이어그램",
    "product_16": "타임라인",
    "product_17": "컨셉 맵",
    "product_18": "트리 다이어그램",
    "product_19": "생선뼈 다이어그램",
    "product_20": "응급실 다이어그램",
    "product_21": "가계도",
    "product_22": "UML 다이어그램",
    "business_use": "업무용",
  },
  "ku": {
    "my_mind_map": "Nexşeya Hişê Min",
    "h_products": "Products",
    "h_diagram_tool": "Diagram Tool",
    "image_tool": "Amûra Wêne",
    "diagrams": "Diagrams",
    "individual_use": "Bikaranîna Kesane",
    "other_use": "Bikaranîna din",
    "pricing": "Pricing",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Remover Background Belaş",
    "product_3": "Belaş Upscaler Wêne",
    "product_4": "Free Watermark Remover",
    "product_5": "Compressor Wêne Belaş",
    "product_6": "JPG Peyv Converter",
    "product_7": "Veguhezkarê PDF PNG-a belaş",
    "product_8": "Veguhezkarê belaş HEIC bo JPG",
    "product_9": "Converter Wêne Belaş",
    "product_10": "Veguherkera PDF JPG ya belaş",
    "product_11": "Flowchart",
    "product_12": "Org Chart",
    "product_13": "Gantt Chart",
    "product_14": "PERT Chart",
    "product_15": "ORM Diagram",
    "product_16": "Timeline",
    "product_17": "Nexşeya konseptê",
    "product_18": "Diagrama Darê",
    "product_19": "Diagrama Bone Masî",
    "product_20": "Diagrama ER",
    "product_21": "Genogram",
    "product_22": "Diagram UML",
    "business_use": "Karanîna Karsaziyê",
  },
  "lt": {
    "my_mind_map": "Mano minčių žemėlapis",
    "h_products": "Produktai",
    "h_diagram_tool": "Diagramos įrankis",
    "image_tool": "Vaizdo įrankis",
    "diagrams": "Diagramos",
    "individual_use": "Individualus naudojimas",
    "other_use": "Kitas naudojimas",
    "pricing": "Kainodara",
    "tutorial": "Pamoka",
    "faq": "DUK",
    "blog": "Dienoraštis",
    "product_1": "Minčių žemėlapių kūrėjas",
    "product_2": "Nemokamas fono valiklis",
    "product_3": "Nemokama vaizdo padidinimo priemonė",
    "product_4": "Nemokamas vandens ženklų valiklis",
    "product_5": "Vaizdo kompresorius nemokamai",
    "product_6": "Nemokamas JPG Word konverteris",
    "product_7": "Nemokamas PDF PNG konverteris",
    "product_8": "Nemokamas HEIC konverteris į JPG",
    "product_9": "Vaizdo keitiklis nemokamai",
    "product_10": "Nemokamas PDF JPG konverteris",
    "product_11": "Struktūrinė schema",
    "product_12": "Organizacijos diagrama",
    "product_13": "Ganto diagramos",
    "product_14": "PERT diagrama",
    "product_15": "ORM diagrama",
    "product_16": "Laiko juosta",
    "product_17": "Koncepcinis žemėlapis",
    "product_18": "Medžio diagrama",
    "product_19": "Žuvies kaulo diagrama",
    "product_20": "ER diagrama",
    "product_21": "Genograma",
    "product_22": "UML diagrama",
    "business_use": "Verslo naudojimas",
  },
  "lv": {
    "my_mind_map": "Mana domu karte",
    "h_products": "Produkti",
    "h_diagram_tool": "Diagrammas rīks",
    "image_tool": "Attēlu rīks",
    "diagrams": "Diagrammas",
    "individual_use": "Individuāla lietošana",
    "other_use": "Cita izmantošana",
    "pricing": "Cenu noteikšana",
    "tutorial": "Apmācība",
    "faq": "FAQ",
    "blog": "Emuārs",
    "product_1": "Domu karšu veidotājs",
    "product_2": "Bezmaksas fona noņemšanas līdzeklis",
    "product_3": "Bezmaksas attēlu uzlabotājs",
    "product_4": "Bezmaksas ūdenszīmju noņemšanas līdzeklis",
    "product_5": "Bezmaksas attēlu kompresors",
    "product_6": "Bezmaksas JPG Word Converter",
    "product_7": "Bezmaksas PDF PNG pārveidotājs",
    "product_8": "Bezmaksas HEIC uz JPG pārveidotājs",
    "product_9": "Bezmaksas attēlu pārveidotājs",
    "product_10": "Bezmaksas PDF JPG pārveidotājs",
    "product_11": "Blokshēma",
    "product_12": "Organizācijas diagramma",
    "product_13": "Ganta diagramma",
    "product_14": "PERT diagramma",
    "product_15": "ORM diagramma",
    "product_16": "Laika skala",
    "product_17": "Koncepcijas karte",
    "product_18": "Koka diagramma",
    "product_19": "Zivju kaula diagramma",
    "product_20": "ER diagramma",
    "product_21": "Genogramma",
    "product_22": "UML diagramma",
    "business_use": "Uzņēmējdarbības izmantošana",
  },
  "mk": {
    "my_mind_map": "Мојата умствена мапа",
    "h_products": "Производи",
    "h_diagram_tool": "Алатка за дијаграм",
    "image_tool": "Алатка за слика",
    "diagrams": "Дијаграми",
    "individual_use": "Индивидуална употреба",
    "other_use": "Друга употреба",
    "pricing": "Цените",
    "tutorial": "Упатство",
    "faq": "Најчесто поставувани прашања",
    "blog": "Блог",
    "product_1": "Создавач на мапи на умот",
    "product_2": "Бесплатен отстранувач на позадина",
    "product_3": "Бесплатно подобрување на сликата",
    "product_4": "Бесплатен отстранувач на воден печат",
    "product_5": "Бесплатен компресор за слика",
    "product_6": "Бесплатен JPG конвертор на зборови",
    "product_7": "Бесплатен PDF PNG конвертор",
    "product_8": "Бесплатен HEIC во JPG конвертор",
    "product_9": "Бесплатен конвертор на слики",
    "product_10": "Бесплатен PDF JPG конвертор",
    "product_11": "Графикон на текови",
    "product_12": "Органска табела",
    "product_13": "Гант графикон",
    "product_14": "Графикон PERT",
    "product_15": "ORM дијаграм",
    "product_16": "Времеплов",
    "product_17": "Концептна карта",
    "product_18": "Дијаграм на дрво",
    "product_19": "Дијаграм на рибина коска",
    "product_20": "ЕР дијаграм",
    "product_21": "Генограм",
    "product_22": "UML дијаграм",
    "business_use": "Деловно користење",
  },
  "mn": {
    "my_mind_map": "Миний оюун ухааны газрын зураг",
    "h_products": "Бүтээгдэхүүн",
    "h_diagram_tool": "Диаграмын хэрэгсэл",
    "image_tool": "Зургийн хэрэгсэл",
    "diagrams": "Диаграммууд",
    "individual_use": "Хувь хүний хэрэглээ",
    "other_use": "Бусад хэрэглээ",
    "pricing": "Үнэ тогтоох",
    "tutorial": "Заавар",
    "faq": "Түгээмэл асуултууд",
    "blog": "Блог",
    "product_1": "Оюун санааны газрын зураг бүтээгч",
    "product_2": "Үнэгүй дэвсгэр арилгагч",
    "product_3": "Үнэгүй дүрс сайжруулагч",
    "product_4": "Үнэгүй усан тэмдэг арилгагч",
    "product_5": "Үнэгүй зураг компрессор",
    "product_6": "Үнэгүй JPG Word хөрвүүлэгч",
    "product_7": "Үнэгүй PDF PNG хөрвүүлэгч",
    "product_8": "Үнэгүй HEIC-ээс JPG хөрвүүлэгч",
    "product_9": "Үнэгүй зураг хөрвүүлэгч",
    "product_10": "Үнэгүй PDF JPG хөрвүүлэгч",
    "product_11": "Урсгал диаграм",
    "product_12": "Байгууллагын график",
    "product_13": "Гант график",
    "product_14": "PERT график",
    "product_15": "ORM диаграм",
    "product_16": "Он цагийн хэлхээс",
    "product_17": "Үзэл баримтлалын газрын зураг",
    "product_18": "Модны диаграм",
    "product_19": "Загасны ясны диаграм",
    "product_20": "ER диаграм",
    "product_21": "Генограмм",
    "product_22": "UML диаграм",
    "business_use": "Бизнесийн хэрэглээ",
  },
  "mr": {
    "my_mind_map": "माझ्या मनाचा नकाशा",
    "h_products": "उत्पादने",
    "h_diagram_tool": "डायग्राम टूल",
    "image_tool": "प्रतिमा साधन",
    "diagrams": "आकृत्या",
    "individual_use": "वैयक्तिक वापर",
    "other_use": "इतर वापर",
    "pricing": "किंमत",
    "tutorial": "ट्यूटोरियल",
    "faq": "वारंवार विचारले जाणारे प्रश्न",
    "blog": "ब्लॉग",
    "product_1": "माइंड मॅप मेकर",
    "product_2": "मोफत पार्श्वभूमी रिमूव्हर",
    "product_3": "मोफत इमेज अपस्केलर",
    "product_4": "मोफत वॉटरमार्क रिमूव्हर",
    "product_5": "विनामूल्य प्रतिमा कंप्रेसर",
    "product_6": "मोफत JPG शब्द कनवर्टर",
    "product_7": "मोफत PDF PNG कनवर्टर",
    "product_8": "मोफत HEIC ते JPG कनव्हर्टर",
    "product_9": "विनामूल्य प्रतिमा कनवर्टर",
    "product_10": "मोफत PDF JPG कनवर्टर",
    "product_11": "फ्लोचार्ट",
    "product_12": "ऑर्ग चार्ट",
    "product_13": "Gantt चार्ट",
    "product_14": "पीईआरटी चार्ट",
    "product_15": "ORM आकृती",
    "product_16": "टाइमलाइन",
    "product_17": "संकल्पना नकाशा",
    "product_18": "वृक्ष आकृती",
    "product_19": "फिशबोन डायग्राम",
    "product_20": "ER आकृती",
    "product_21": "जीनोग्राम",
    "product_22": "UML आकृती",
    "business_use": "व्यवसाय वापर",
  },
  "ms": {
    "my_mind_map": "Peta Minda Saya",
    "h_products": "Produk",
    "h_diagram_tool": "Alat Rajah",
    "image_tool": "Alat Imej",
    "diagrams": "Gambar rajah",
    "individual_use": "Penggunaan Individu",
    "other_use": "Kegunaan Lain",
    "pricing": "penetapan harga",
    "tutorial": "Tutorial",
    "faq": "Soalan Lazim",
    "blog": "Blog",
    "product_1": "Pembuat Peta Minda",
    "product_2": "Pembuang Latar Belakang Percuma",
    "product_3": "Penskala Imej Percuma",
    "product_4": "Pembuang Tera Air Percuma",
    "product_5": "Pemampat Imej Percuma",
    "product_6": "JPG Word Converter Percuma",
    "product_7": "Penukar PDF PNG Percuma",
    "product_8": "Penukar HEIC ke JPG percuma",
    "product_9": "Penukar Imej Percuma",
    "product_10": "Penukar PDF JPG Percuma",
    "product_11": "Carta alir",
    "product_12": "Carta Org",
    "product_13": "Carta Gantt",
    "product_14": "Carta PERT",
    "product_15": "Rajah ORM",
    "product_16": "Garis masa",
    "product_17": "Peta Konsep",
    "product_18": "Rajah Pokok",
    "product_19": "Gambarajah Tulang Ikan",
    "product_20": "Rajah ER",
    "product_21": "Genogram",
    "product_22": "Rajah UML",
    "business_use": "Penggunaan Perniagaan",
  },
  "my": {
    "my_mind_map": "ငါ့စိတ်မြေပုံ",
    "h_products": "ထုတ်ကုန်များ",
    "h_diagram_tool": "Diagram Tool",
    "image_tool": "ရုပ်ပုံတူးလ်",
    "diagrams": "ပုံကြမ်းများ",
    "individual_use": "တစ်ဦးချင်းအသုံးပြုမှု",
    "other_use": "အခြားအသုံးပြုမှု",
    "pricing": "ဈေးနှုန်း",
    "tutorial": "ကျူတိုရီရယ်",
    "faq": "အမြဲမေးလေ့ရှိသောမေးခွန်းများ",
    "blog": "ဘလော့",
    "product_1": "Mind Map ဖန်တီးသူ",
    "product_2": "အခမဲ့နောက်ခံဖယ်ရှားရေး",
    "product_3": "အခမဲ့ Image Upscaler",
    "product_4": "အခမဲ့ Watermark Remover",
    "product_5": "အခမဲ့ရုပ်ပုံကွန်ပရက်ဆာ",
    "product_6": "အခမဲ့ JPG Word Converter",
    "product_7": "အခမဲ့ PDF PNG Converter",
    "product_8": "အခမဲ့ HEIC သို့ JPG ပြောင်းပေးသည်။",
    "product_9": "အခမဲ့ Image Converter",
    "product_10": "အခမဲ့ PDF JPG Converter",
    "product_11": "ပုံပြဇယား",
    "product_12": "အဖွဲ့အစည်းဇယား",
    "product_13": "Gantt ဇယား",
    "product_14": "PERT ဇယား",
    "product_15": "ORM ပုံကြမ်း",
    "product_16": "အချိန်ဇယား",
    "product_17": "အယူအဆမြေပုံ",
    "product_18": "သစ်ပင်မျဉ်း",
    "product_19": "ငါးရိုးမျဉ်း",
    "product_20": "ER ပုံကြမ်း",
    "product_21": "မျိုးရိုးစဉ်ဆက်",
    "product_22": "UML ပုံကြမ်း",
    "business_use": "လုပ်ငန်းသုံး",
  },
  "ne": {
    "my_mind_map": "मेरो मन नक्सा",
    "h_products": "उत्पादनहरू",
    "h_diagram_tool": "रेखाचित्र उपकरण",
    "image_tool": "छवि उपकरण",
    "diagrams": "रेखाचित्र",
    "individual_use": "व्यक्तिगत प्रयोग",
    "other_use": "अन्य प्रयोग",
    "pricing": "मूल्य निर्धारण",
    "tutorial": "ट्यूटोरियल",
    "faq": "FAQ",
    "blog": "ब्लग",
    "product_1": "मन नक्सा निर्माता",
    "product_2": "नि: शुल्क पृष्ठभूमि हटाउने",
    "product_3": "नि: शुल्क छवि अपस्केलर",
    "product_4": "नि: शुल्क वाटरमार्क रिमूभर",
    "product_5": "नि: शुल्क छवि कम्प्रेसर",
    "product_6": "नि: शुल्क JPG शब्द कनवर्टर",
    "product_7": "नि: शुल्क PDF PNG कनवर्टर",
    "product_8": "नि: शुल्क HEIC देखि JPG कनवर्टर",
    "product_9": "नि: शुल्क छवि कनवर्टर",
    "product_10": "नि: शुल्क PDF JPG कनवर्टर",
    "product_11": "फ्लोचार्ट",
    "product_12": "संगठन चार्ट",
    "product_13": "Gantt चार्ट",
    "product_14": "PERT चार्ट",
    "product_15": "ORM रेखाचित्र",
    "product_16": "समय रेखा",
    "product_17": "अवधारणा नक्शा",
    "product_18": "रूख रेखाचित्र",
    "product_19": "फिशबोन रेखाचित्र",
    "product_20": "ER रेखाचित्र",
    "product_21": "जेनोग्राम",
    "product_22": "UML रेखाचित्र",
    "business_use": "व्यापार प्रयोग",
  },
  "nl": {
    "my_mind_map": "Mijn Mindmap",
    "h_products": "Producten",
    "h_diagram_tool": "Diagramhulpmiddel",
    "image_tool": "Afbeeldingshulpmiddel",
    "diagrams": "Diagrammen",
    "individual_use": "Individueel gebruik",
    "other_use": "Ander gebruik",
    "pricing": "Prijzen",
    "tutorial": "Handleiding",
    "faq": "FAQ",
    "blog": "Bloggen",
    "product_1": "Mindmapmaker",
    "product_2": "Gratis achtergrondverwijderaar",
    "product_3": "Gratis beeldupscaler",
    "product_4": "Gratis watermerkverwijderaar",
    "product_5": "Gratis afbeeldingscompressor",
    "product_6": "Gratis JPG Word-converter",
    "product_7": "Gratis PDF PNG-converter",
    "product_8": "Gratis HEIC naar JPG-converter",
    "product_9": "Gratis beeldconverter",
    "product_10": "Gratis PDF JPG-converter",
    "product_11": "Stroomdiagram",
    "product_12": "Organigram",
    "product_13": "Gantt-diagram",
    "product_14": "PERT-grafiek",
    "product_15": "ORM-diagram",
    "product_16": "Tijdlijn",
    "product_17": "Conceptkaart",
    "product_18": "Boomdiagram",
    "product_19": "Visgraatdiagram",
    "product_20": "ER-diagram",
    "product_21": "Genogram",
    "product_22": "UML-diagram",
    "business_use": "Zakelijk gebruik",
  },
  "pa": {
    "my_mind_map": "ਮੇਰੇ ਮਨ ਦਾ ਨਕਸ਼ਾ",
    "h_products": "ਉਤਪਾਦ",
    "h_diagram_tool": "ਡਾਇਗ੍ਰਾਮ ਟੂਲ",
    "image_tool": "ਚਿੱਤਰ ਟੂਲ",
    "diagrams": "ਚਿੱਤਰ",
    "individual_use": "ਵਿਅਕਤੀਗਤ ਵਰਤੋਂ",
    "other_use": "ਹੋਰ ਵਰਤੋਂ",
    "pricing": "ਕੀਮਤ",
    "tutorial": "ਟਿਊਟੋਰਿਅਲ",
    "faq": "FAQ",
    "blog": "ਬਲੌਗ",
    "product_1": "ਮਨ ਦਾ ਨਕਸ਼ਾ ਮੇਕਰ",
    "product_2": "ਮੁਫਤ ਬੈਕਗ੍ਰਾਉਂਡ ਰੀਮੂਵਰ",
    "product_3": "ਮੁਫ਼ਤ ਚਿੱਤਰ ਅੱਪਸਕੇਲਰ",
    "product_4": "ਮੁਫਤ ਵਾਟਰਮਾਰਕ ਰੀਮੂਵਰ",
    "product_5": "ਮੁਫ਼ਤ ਚਿੱਤਰ ਕੰਪ੍ਰੈਸਰ",
    "product_6": "ਮੁਫਤ JPG ਵਰਡ ਕਨਵਰਟਰ",
    "product_7": "ਮੁਫਤ PDF PNG ਕਨਵਰਟਰ",
    "product_8": "ਮੁਫ਼ਤ HEIC ਤੋਂ JPG ਪਰਿਵਰਤਕ",
    "product_9": "ਮੁਫ਼ਤ ਚਿੱਤਰ ਪਰਿਵਰਤਕ",
    "product_10": "ਮੁਫਤ PDF JPG ਕਨਵਰਟਰ",
    "product_11": "ਫਲੋਚਾਰਟ",
    "product_12": "ਸੰਗਠਨ ਚਾਰਟ",
    "product_13": "ਗੈਂਟ ਚਾਰਟ",
    "product_14": "PERT ਚਾਰਟ",
    "product_15": "ORM ਚਿੱਤਰ",
    "product_16": "ਸਮਾਂਰੇਖਾ",
    "product_17": "ਸੰਕਲਪ ਨਕਸ਼ਾ",
    "product_18": "ਰੁੱਖ ਚਿੱਤਰ",
    "product_19": "ਫਿਸ਼ਬੋਨ ਡਾਇਗ੍ਰਾਮ",
    "product_20": "ER ਚਿੱਤਰ",
    "product_21": "ਜੀਨੋਗ੍ਰਾਮ",
    "product_22": "UML ਚਿੱਤਰ",
    "business_use": "ਕਾਰੋਬਾਰੀ ਵਰਤੋਂ",
  },
  "pl": {
    "my_mind_map": "Moja mapa myśli",
    "h_products": "Produkty",
    "h_diagram_tool": "Narzędzie do tworzenia diagramów",
    "image_tool": "Narzędzie obrazu",
    "diagrams": "Schematy",
    "individual_use": "Indywidualne zastosowanie",
    "other_use": "Inne zastosowanie",
    "pricing": "cennik",
    "tutorial": "Instruktaż",
    "faq": "Często zadawane pytania",
    "blog": "Bloga",
    "product_1": "Kreator Map Myśli",
    "product_2": "Bezpłatne usuwanie tła",
    "product_3": "Bezpłatny moduł skalowania obrazu",
    "product_4": "Bezpłatny program do usuwania znaków wodnych",
    "product_5": "Darmowy kompresor obrazu",
    "product_6": "Darmowy konwerter słów JPG",
    "product_7": "Darmowy konwerter PDF PNG",
    "product_8": "Darmowy konwerter HEIC na JPG",
    "product_9": "Darmowy konwerter obrazów",
    "product_10": "Darmowy konwerter PDF JPG",
    "product_11": "Schemat blokowy",
    "product_12": "Schemat organizacyjny",
    "product_13": "Wykres Gantta",
    "product_14": "Wykres PERT",
    "product_15": "Schemat ORMA",
    "product_16": "Oś czasu",
    "product_17": "Mapa koncepcyjna",
    "product_18": "Schemat drzewa",
    "product_19": "Schemat rybiej ości",
    "product_20": "Schemat ER",
    "product_21": "Genogram",
    "product_22": "Schemat UML-a",
    "business_use": "Zastosowanie biznesowe",
  },
  "pt": {
    "my_mind_map": "Meu mapa mental",
    "h_products": "Produtos",
    "h_diagram_tool": "Ferramenta Diagrama",
    "image_tool": "Ferramenta de imagem",
    "diagrams": "Diagramas",
    "individual_use": "Uso individual",
    "other_use": "Outro uso",
    "pricing": "Preços",
    "tutorial": "Tutorial",
    "faq": "Perguntas frequentes",
    "blog": "Blogue",
    "product_1": "Criador de mapas mentais",
    "product_2": "Removedor de fundo grátis",
    "product_3": "Upscaler de imagem grátis",
    "product_4": "Removedor de marca d'água grátis",
    "product_5": "Compressor de imagem grátis",
    "product_6": "Conversor de palavras JPG grátis",
    "product_7": "Conversor PDF PNG grátis",
    "product_8": "Conversor HEIC para JPG grátis",
    "product_9": "Conversor de imagens grátis",
    "product_10": "Conversor PDF JPG Gratuito",
    "product_11": "Fluxograma",
    "product_12": "Quadro organizacional",
    "product_13": "Gráfico de Gantt",
    "product_14": "Gráfico PERT",
    "product_15": "Diagrama ORM",
    "product_16": "Linha do tempo",
    "product_17": "Mapa conceitual",
    "product_18": "Diagrama de árvore",
    "product_19": "Diagrama de espinha de peixe",
    "product_20": "Diagrama ER",
    "product_21": "Genograma",
    "product_22": "Diagrama UML",
    "business_use": "Uso comercial",
  },
  "ro": {
    "my_mind_map": "Harta Mintea mea",
    "h_products": "Produse",
    "h_diagram_tool": "Instrument de diagramă",
    "image_tool": "Instrumentul de imagine",
    "diagrams": "Diagrame",
    "individual_use": "Utilizare individuală",
    "other_use": "Altă utilizare",
    "pricing": "Prețuri",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Creator de hărți mentale",
    "product_2": "Eliminator de fundal gratuit",
    "product_3": "Upscaler de imagini gratuit",
    "product_4": "Eliminator de filigran gratuit",
    "product_5": "Compresor de imagine gratuit",
    "product_6": "Convertor de cuvinte JPG gratuit",
    "product_7": "Convertor PDF PNG gratuit",
    "product_8": "Convertor gratuit HEIC în JPG",
    "product_9": "Convertor de imagini gratuit",
    "product_10": "Convertor PDF JPG gratuit",
    "product_11": "Diagramă",
    "product_12": "Organigrama",
    "product_13": "Diagrama Gantt",
    "product_14": "Diagrama PERT",
    "product_15": "Diagrama ORM",
    "product_16": "Cronologie",
    "product_17": "Harta conceptuală",
    "product_18": "Diagrama arborelui",
    "product_19": "Diagrama oaselor de pește",
    "product_20": "Diagrama ER",
    "product_21": "Genograma",
    "product_22": "Diagrama UML",
    "business_use": "Utilizare în afaceri",
  },
  "ru": {
    "my_mind_map": "Моя карта разума",
    "h_products": "Продукты",
    "h_diagram_tool": "Инструмент «Диаграмма»",
    "image_tool": "Инструмент изображения",
    "diagrams": "Диаграммы",
    "individual_use": "Индивидуальное использование",
    "other_use": "Другое использование",
    "pricing": "Цены",
    "tutorial": "Руководство",
    "faq": "Часто задаваемые вопросы",
    "blog": "Блог",
    "product_1": "Создатель интеллект-карт",
    "product_2": "Бесплатное удаление фона",
    "product_3": "Бесплатная программа масштабирования изображений",
    "product_4": "Бесплатное средство для удаления водяных знаков",
    "product_5": "Бесплатный компрессор изображений",
    "product_6": "Бесплатный конвертер слов JPG",
    "product_7": "Бесплатный конвертер PDF PNG",
    "product_8": "Бесплатный конвертер HEIC в JPG",
    "product_9": "Бесплатный конвертер изображений",
    "product_10": "Бесплатный конвертер PDF JPG",
    "product_11": "Блок-схема",
    "product_12": "Организационная структура",
    "product_13": "Диаграмма Ганта",
    "product_14": "Диаграмма PERT",
    "product_15": "Диаграмма ОРМ",
    "product_16": "График",
    "product_17": "Диаграмма связей",
    "product_18": "Древовидная диаграмма",
    "product_19": "Диаграмма «рыбий кости»",
    "product_20": "Диаграмма ER",
    "product_21": "Генограмма",
    "product_22": "UML-диаграмма",
    "business_use": "Использование в бизнесе",
  },
  "si": {
    "my_mind_map": "මගේ මනස සිතියම",
    "h_products": "නිෂ්පාදන",
    "h_diagram_tool": "රූප සටහන් මෙවලම",
    "image_tool": "රූප මෙවලම",
    "diagrams": "රූප සටහන්",
    "individual_use": "තනි භාවිතය",
    "other_use": "වෙනත් භාවිතය",
    "pricing": "මිල නියම කිරීම",
    "tutorial": "නිබන්ධනය",
    "faq": "නිති අසන පැණ",
    "blog": "බ්ලොග්",
    "product_1": "මනස සිතියම් සාදන්නා",
    "product_2": "නොමිලේ පසුබිම් ඉවත් කරන්නා",
    "product_3": "නොමිලේ රූප ඉහළ නැංවීම",
    "product_4": "නොමිලේ ජල සලකුණු ඉවත් කරන්නා",
    "product_5": "නොමිලේ රූප සම්පීඩකය",
    "product_6": "නොමිලේ JPG වචන පරිවර්තකය",
    "product_7": "නොමිලේ PDF PNG පරිවර්තකය",
    "product_8": "නොමිලේ HEIC සිට JPG පරිවර්තකය",
    "product_9": "නොමිලේ රූප පරිවර්තකය",
    "product_10": "නොමිලේ PDF JPG පරිවර්තකය",
    "product_11": "ගැලීම් සටහන",
    "product_12": "සංවිධාන වගුව",
    "product_13": "Gantt Chart",
    "product_14": "PERT ප්‍රස්ථාරය",
    "product_15": "ORM රූප සටහන",
    "product_16": "කාලරේඛාව",
    "product_17": "සංකල්ප සිතියම",
    "product_18": "ගස් රූප සටහන",
    "product_19": "Fishbone රූප සටහන",
    "product_20": "ER රූප සටහන",
    "product_21": "Genogram",
    "product_22": "UML රූප සටහන",
    "business_use": "ව්යාපාර භාවිතය",
  },
  "sk": {
    "my_mind_map": "Moja myšlienková mapa",
    "h_products": "Produkty",
    "h_diagram_tool": "Nástroj Diagram",
    "image_tool": "Image Tool",
    "diagrams": "Diagramy",
    "individual_use": "Individuálne použitie",
    "other_use": "Iné použitie",
    "pricing": "Stanovenie cien",
    "tutorial": "Návod",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Bezplatný odstraňovač pozadia",
    "product_3": "Upscaler obrázkov zadarmo",
    "product_4": "Bezplatný odstraňovač vodoznakov",
    "product_5": "Kompresor obrázkov zadarmo",
    "product_6": "Bezplatný konvertor slov JPG",
    "product_7": "Bezplatný prevodník PDF PNG",
    "product_8": "Bezplatný prevodník HEIC na JPG",
    "product_9": "Bezplatný prevodník obrázkov",
    "product_10": "Bezplatný prevodník PDF JPG",
    "product_11": "Vývojový diagram",
    "product_12": "Organizačná schéma",
    "product_13": "Ganttov diagram",
    "product_14": "Graf PERT",
    "product_15": "Diagram ORM",
    "product_16": "Časová os",
    "product_17": "Pojmová mapa",
    "product_18": "Stromový diagram",
    "product_19": "Diagram rybej kosti",
    "product_20": "ER diagram",
    "product_21": "Genogram",
    "product_22": "UML diagram",
    "business_use": "Obchodné využitie",
  },
  "sq": {
    "my_mind_map": "Harta ime e mendjes",
    "h_products": "Produktet",
    "h_diagram_tool": "Mjeti i Diagramit",
    "image_tool": "Mjeti i imazhit",
    "diagrams": "Diagramet",
    "individual_use": "Përdorimi Individual",
    "other_use": "Përdorim Tjetër",
    "pricing": "Çmimet",
    "tutorial": "Tutorial",
    "faq": "FAQ",
    "blog": "Blog",
    "product_1": "Krijuesi i hartave mendore",
    "product_2": "Heqësi falas i sfondit",
    "product_3": "Përmirësues i imazhit falas",
    "product_4": "Pa pagesë heqës filigranësh",
    "product_5": "Kompresor imazhi pa pagesë",
    "product_6": "Konvertuesi falas i fjalëve JPG",
    "product_7": "Konvertuesi pa pagesë PDF PNG",
    "product_8": "Konvertuesi falas HEIC në JPG",
    "product_9": "Konvertuesi i imazhit falas",
    "product_10": "Konvertuesi pa pagesë PDF JPG",
    "product_11": "Diagrami i rrjedhës",
    "product_12": "Org Grafiku",
    "product_13": "Grafiku Gantt",
    "product_14": "Grafiku PERT",
    "product_15": "Diagrami ORM",
    "product_16": "Afati kohor",
    "product_17": "Harta e konceptit",
    "product_18": "Diagrami i pemës",
    "product_19": "Diagrami i kockave të peshkut",
    "product_20": "Diagrami ER",
    "product_21": "Gjenogrami",
    "product_22": "Diagrami UML",
    "business_use": "Përdorimi i biznesit",
  },
  "sv": {
    "my_mind_map": "Min tankekarta",
    "h_products": "Produkter",
    "h_diagram_tool": "Diagramverktyg",
    "image_tool": "Bildverktyg",
    "diagrams": "Diagram",
    "individual_use": "Individuell användning",
    "other_use": "Annan användning",
    "pricing": "Prissättning",
    "tutorial": "Handledning",
    "faq": "FAQ",
    "blog": "Blogg",
    "product_1": "Mind Map Maker",
    "product_2": "Gratis Background Remover",
    "product_3": "Gratis bilduppskalare",
    "product_4": "Gratis vattenstämpelborttagare",
    "product_5": "Gratis bildkompressor",
    "product_6": "Gratis JPG Word Converter",
    "product_7": "Gratis PDF PNG Converter",
    "product_8": "Gratis HEIC till JPG-konverterare",
    "product_9": "Gratis bildkonverterare",
    "product_10": "Gratis PDF JPG-konverterare",
    "product_11": "Flödesschema",
    "product_12": "Organisationsdiagram",
    "product_13": "Gantt-diagram",
    "product_14": "PERT-diagram",
    "product_15": "ORM-diagram",
    "product_16": "Tidslinje",
    "product_17": "Konceptkarta",
    "product_18": "Träddiagram",
    "product_19": "Fiskbensdiagram",
    "product_20": "ER-diagram",
    "product_21": "Genogram",
    "product_22": "UML-diagram",
    "business_use": "Affärsanvändning",
  },
  "ta": {
    "my_mind_map": "என் மன வரைபடம்",
    "h_products": "தயாரிப்புகள்",
    "h_diagram_tool": "வரைபடக் கருவி",
    "image_tool": "படக் கருவி",
    "diagrams": "வரைபடங்கள்",
    "individual_use": "தனிப்பட்ட பயன்பாடு",
    "other_use": "பிற பயன்பாடு",
    "pricing": "விலை நிர்ணயம்",
    "tutorial": "பயிற்சி",
    "faq": "அடிக்கடி கேட்கப்படும் கேள்விகள்",
    "blog": "வலைப்பதிவு",
    "product_1": "மைண்ட் மேப் மேக்கர்",
    "product_2": "இலவச பின்னணி நீக்கி",
    "product_3": "இலவச இமேஜ் அப்ஸ்கேலர்",
    "product_4": "இலவச வாட்டர்மார்க் ரிமூவர்",
    "product_5": "இலவச பட அமுக்கி",
    "product_6": "இலவச JPG வேர்ட் மாற்றி",
    "product_7": "இலவச PDF PNG மாற்றி",
    "product_8": "இலவச HEIC முதல் JPG மாற்றி",
    "product_9": "இலவச பட மாற்றி",
    "product_10": "இலவச PDF JPG மாற்றி",
    "product_11": "ஃப்ளோசார்ட்",
    "product_12": "அமைப்பு விளக்கப்படம்",
    "product_13": "Gantt விளக்கப்படம்",
    "product_14": "PERT விளக்கப்படம்",
    "product_15": "ORM வரைபடம்",
    "product_16": "காலவரிசை",
    "product_17": "கருத்து வரைபடம்",
    "product_18": "மரத்தின் வரைபடம்",
    "product_19": "மீன் எலும்பு வரைபடம்",
    "product_20": "ER வரைபடம்",
    "product_21": "ஜெனோகிராம்",
    "product_22": "UML வரைபடம்",
    "business_use": "வணிக பயன்பாடு",
  },
  "te": {
    "my_mind_map": "నా మైండ్ మ్యాప్",
    "h_products": "ఉత్పత్తులు",
    "h_diagram_tool": "రేఖాచిత్రం సాధనం",
    "image_tool": "చిత్ర సాధనం",
    "diagrams": "రేఖాచిత్రాలు",
    "individual_use": "వ్యక్తిగత ఉపయోగం",
    "other_use": "ఇతర ఉపయోగం",
    "pricing": "ధర నిర్ణయించడం",
    "tutorial": "ట్యుటోరియల్",
    "faq": "ఎఫ్ ఎ క్యూ",
    "blog": "బ్లాగు",
    "product_1": "మైండ్ మ్యాప్ మేకర్",
    "product_2": "ఉచిత బ్యాక్‌గ్రౌండ్ రిమూవర్",
    "product_3": "ఉచిత ఇమేజ్ అప్‌స్కేలర్",
    "product_4": "ఉచిత వాటర్‌మార్క్ రిమూవర్",
    "product_5": "ఉచిత ఇమేజ్ కంప్రెసర్",
    "product_6": "ఉచిత JPG వర్డ్ కన్వర్టర్",
    "product_7": "ఉచిత PDF PNG కన్వర్టర్",
    "product_8": "ఉచిత HEIC నుండి JPG కన్వర్టర్",
    "product_9": "ఉచిత చిత్రం కన్వర్టర్",
    "product_10": "ఉచిత PDF JPG కన్వర్టర్",
    "product_11": "ఫ్లోచార్ట్",
    "product_12": "ఆర్గ్ చార్ట్",
    "product_13": "గాంట్ చార్ట్",
    "product_14": "PERT చార్ట్",
    "product_15": "ORM రేఖాచిత్రం",
    "product_16": "కాలక్రమం",
    "product_17": "కాన్సెప్ట్ మ్యాప్",
    "product_18": "చెట్టు రేఖాచిత్రం",
    "product_19": "ఫిష్బోన్ రేఖాచిత్రం",
    "product_20": "ER రేఖాచిత్రం",
    "product_21": "జెనోగ్రామ్",
    "product_22": "UML రేఖాచిత్రం",
    "business_use": "వ్యాపార ఉపయోగం",
  },
  "th": {
    "my_mind_map": "แผนที่ความคิดของฉัน",
    "h_products": "สินค้า",
    "h_diagram_tool": "เครื่องมือไดอะแกรม",
    "image_tool": "เครื่องมือรูปภาพ",
    "diagrams": "ไดอะแกรม",
    "individual_use": "การใช้งานส่วนบุคคล",
    "other_use": "การใช้งานอื่น ๆ",
    "pricing": "ราคา",
    "tutorial": "บทช่วยสอน",
    "faq": "คำถามที่พบบ่อย",
    "blog": "บล็อก",
    "product_1": "เครื่องสร้างแผนที่ความคิด",
    "product_2": "เครื่องมือลบพื้นหลังฟรี",
    "product_3": "โปรแกรมเพิ่มสเกลรูปภาพฟรี",
    "product_4": "ลบลายน้ำฟรี",
    "product_5": "โปรแกรมบีบอัดรูปภาพฟรี",
    "product_6": "โปรแกรมแปลงคำ JPG ฟรี",
    "product_7": "แปลง PDF PNG ฟรี",
    "product_8": "ฟรีตัวแปลง HEIC เป็น JPG",
    "product_9": "แปลงรูปภาพฟรี",
    "product_10": "โปรแกรมแปลงไฟล์ PDF JPG ฟรี",
    "product_11": "ผังงาน",
    "product_12": "แผนภูมิองค์กร",
    "product_13": "แผนภูมิแกนต์",
    "product_14": "แผนภูมิ PERT",
    "product_15": "แผนภาพโออาร์เอ็ม",
    "product_16": "เส้นเวลา",
    "product_17": "แผนที่แนวคิด",
    "product_18": "แผนผังต้นไม้",
    "product_19": "แผนภาพก้างปลา",
    "product_20": "แผนภาพฉุกเฉิน",
    "product_21": "จีโนแกรม",
    "product_22": "ไดอะแกรม UML",
    "business_use": "การใช้งานทางธุรกิจ",
  },
  "tr": {
    "my_mind_map": "Zihin Haritam",
    "h_products": "Ürünler",
    "h_diagram_tool": "Diyagram Aracı",
    "image_tool": "Görüntü Aracı",
    "diagrams": "Diyagramlar",
    "individual_use": "Bireysel Kullanım",
    "other_use": "Diğer Kullanım",
    "pricing": "Fiyatlandırma",
    "tutorial": "öğretici",
    "faq": "SSS",
    "blog": "Blog",
    "product_1": "Zihin Haritası Oluşturucu",
    "product_2": "Ücretsiz Arka Plan Sökücü",
    "product_3": "Ücretsiz Resim Yükseltici",
    "product_4": "Ücretsiz Filigran Sökücü",
    "product_5": "Ücretsiz Resim Kompresör",
    "product_6": "Ücretsiz JPG Kelime Dönüştürücü",
    "product_7": "Ücretsiz PDF PNG Dönüştürücü",
    "product_8": "Ücretsiz HEIC'den JPG'ye Dönüştürücü",
    "product_9": "Ücretsiz Resim Dönüştürücü",
    "product_10": "Ücretsiz PDF JPG Dönüştürücü",
    "product_11": "Akış şeması",
    "product_12": "Organizasyon Şeması",
    "product_13": "Gantt şeması",
    "product_14": "PERT Tablosu",
    "product_15": "ORM Şeması",
    "product_16": "Zaman çizelgesi",
    "product_17": "Konsept harita",
    "product_18": "Ağaç diyagramı",
    "product_19": "Kılçık diyagramı",
    "product_20": "ER Şeması",
    "product_21": "Genogram",
    "product_22": "UML Diyagramı",
    "business_use": "İş Kullanımı",
  },
  "uk": {
    "my_mind_map": "Моя розумова карта",
    "h_products": "Продукти",
    "h_diagram_tool": "Інструмент діаграми",
    "image_tool": "Інструмент зображення",
    "diagrams": "Діаграми",
    "individual_use": "Індивідуальне використання",
    "other_use": "Інше використання",
    "pricing": "Ціноутворення",
    "tutorial": "Підручник",
    "faq": "FAQ",
    "blog": "Блог",
    "product_1": "Mind Map Maker",
    "product_2": "Безкоштовний засіб для видалення фону",
    "product_3": "Free Image Upscaler",
    "product_4": "Безкоштовний засіб для видалення водяних знаків",
    "product_5": "Безкоштовний компресор зображень",
    "product_6": "Безкоштовний конвертер JPG Word",
    "product_7": "Безкоштовний конвертер PDF PNG",
    "product_8": "Безкоштовний конвертер HEIC у JPG",
    "product_9": "Безкоштовний конвертер зображень",
    "product_10": "Безкоштовний конвертер PDF у JPG",
    "product_11": "Блок-схема",
    "product_12": "Організаційна діаграма",
    "product_13": "Діаграма Ганта",
    "product_14": "Діаграма PERT",
    "product_15": "Діаграма ORM",
    "product_16": "Хронологія",
    "product_17": "Концептуальна карта",
    "product_18": "Деревоподібна діаграма",
    "product_19": "Діаграма риб'ячої кістки",
    "product_20": "ER діаграма",
    "product_21": "Генограма",
    "product_22": "Діаграма UML",
    "business_use": "Ділове використання",
  },
  "ur": {
    "my_mind_map": "میرے دماغ کا نقشہ",
    "h_products": "مصنوعات",
    "h_diagram_tool": "ڈایاگرام ٹول",
    "image_tool": "تصویری ٹول",
    "diagrams": "خاکے",
    "individual_use": "انفرادی استعمال",
    "other_use": "دیگر استعمال",
    "pricing": "قیمتوں کا تعین",
    "tutorial": "ٹیوٹوریل",
    "faq": "عمومی سوالات",
    "blog": "بلاگ",
    "product_1": "دماغ کا نقشہ بنانے والا",
    "product_2": "مفت پس منظر ہٹانے والا",
    "product_3": "مفت امیج اپ اسکیلر",
    "product_4": "مفت واٹر مارک ہٹانے والا",
    "product_5": "مفت امیج کمپریسر",
    "product_6": "مفت JPG ورڈ کنورٹر",
    "product_7": "مفت پی ڈی ایف پی این جی کنورٹر",
    "product_8": "مفت HEIC سے JPG کنورٹر",
    "product_9": "مفت امیج کنورٹر",
    "product_10": "مفت پی ڈی ایف جے پی جی کنورٹر",
    "product_11": "فلو چارٹ",
    "product_12": "تنظیمی چارٹ",
    "product_13": "Gantt چارٹ",
    "product_14": "PERT چارٹ",
    "product_15": "ORM ڈایاگرام",
    "product_16": "ٹائم لائن",
    "product_17": "تصور کا نقشہ",
    "product_18": "درخت کا خاکہ",
    "product_19": "فش بون ڈایاگرام",
    "product_20": "ER ڈایاگرام",
    "product_21": "جینوگرام",
    "product_22": "یو ایم ایل ڈایاگرام",
    "business_use": "کاروباری استعمال",
  },
  "uz": {
    "my_mind_map": "Mening fikrim xaritasi",
    "h_products": "Mahsulotlar",
    "h_diagram_tool": "Diagramma vositasi",
    "image_tool": "Tasvir vositasi",
    "diagrams": "Diagrammalar",
    "individual_use": "Shaxsiy foydalanish",
    "other_use": "Boshqa foydalanish",
    "pricing": "Narxlash",
    "tutorial": "Oʻquv qoʻllanma",
    "faq": "TSS",
    "blog": "Blog",
    "product_1": "Mind Map Maker",
    "product_2": "Bepul fon olib tashlash",
    "product_3": "Bepul tasvirni oshirish",
    "product_4": "Bepul suv belgilarini olib tashlash",
    "product_5": "Bepul tasvir kompressor",
    "product_6": "Bepul JPG so'z konvertori",
    "product_7": "Bepul PDF PNG konvertori",
    "product_8": "Bepul HEIC dan JPGga konvertor",
    "product_9": "Bepul tasvir konvertori",
    "product_10": "Bepul PDF jpg konvertori",
    "product_11": "Blok-sxema",
    "product_12": "Tashkilotlar jadvali",
    "product_13": "Gantt diagrammasi",
    "product_14": "PERT diagrammasi",
    "product_15": "ORM diagrammasi",
    "product_16": "Vaqt jadvali",
    "product_17": "Kontseptsiya xaritasi",
    "product_18": "Daraxt diagrammasi",
    "product_19": "Baliq suyagi diagrammasi",
    "product_20": "ER diagrammasi",
    "product_21": "Genogramma",
    "product_22": "UML diagrammasi",
    "business_use": "Biznes foydalanish",
  },
  "vi": {
    "my_mind_map": "Bản đồ tư duy của tôi",
    "h_products": "Các sản phẩm",
    "h_diagram_tool": "Công cụ sơ đồ",
    "image_tool": "Công cụ hình ảnh",
    "diagrams": "Sơ đồ",
    "individual_use": "Sử dụng cá nhân",
    "other_use": "Sử dụng khác",
    "pricing": "Định giá",
    "tutorial": "Hướng dẫn",
    "faq": "Câu hỏi thường gặp",
    "blog": "Blog",
    "product_1": "Trình tạo bản đồ tư duy",
    "product_2": "Xóa nền miễn phí",
    "product_3": "Công cụ nâng cấp hình ảnh miễn phí",
    "product_4": "Xóa hình mờ miễn phí",
    "product_5": "Máy nén hình ảnh miễn phí",
    "product_6": "Trình chuyển đổi từ JPG miễn phí",
    "product_7": "Trình chuyển đổi PDF PNG miễn phí",
    "product_8": "Trình chuyển đổi HEIC sang JPG miễn phí",
    "product_9": "Chuyển đổi hình ảnh miễn phí",
    "product_10": "Trình chuyển đổi PDF JPG miễn phí",
    "product_11": "Sơ đồ",
    "product_12": "Sơ đồ tổ chức",
    "product_13": "Biểu đồ Gantt",
    "product_14": "Biểu đồ PERT",
    "product_15": "Sơ đồ ORM",
    "product_16": "Mốc thời gian",
    "product_17": "Bản đồ khái niệm",
    "product_18": "Sơ đồ cây",
    "product_19": "Sơ đồ xương cá",
    "product_20": "Sơ đồ ER",
    "product_21": "biểu đồ gen",
    "product_22": "Sơ đồ UML",
    "business_use": "Doanh nghiệp sử dụng"
  },
  "zh-cn": {
    "my_mind_map": "我的思维导图",
    "h_products": "产品",
    "h_diagram_tool": "图表工具",
    "image_tool": "图像工具",
    "diagrams": "图表",
    "individual_use": "个人使用",
    "other_use": "其他用途",
    "pricing": "定价",
    "tutorial": "教程",
    "faq": "常问问题",
    "blog": "博客",
    "product_1": "思维导图制作",
    "product_2": "免费消除背景",
    "product_3": "免费图像放大",
    "product_4": "免费消除水印",
    "product_5": "免费图像压缩",
    "product_6": "免费 JPG Word 转换",
    "product_7": "免费 PDF PNG 转换",
    "product_8": "免费 HEIC JPG 转换",
    "product_9": "免费图像转换",
    "product_10": "免费 PDF JPG 转换",
    "product_11": "流程图",
    "product_12": "组织结构图",
    "product_13": "甘特图",
    "product_14": "PERT 图",
    "product_15": "ORM 图",
    "product_16": "时间线",
    "product_17": "概念图",
    "product_18": "树形图",
    "product_19": "鱼骨图",
    "product_20": "ER 图",
    "product_21": "家系图",
    "product_22": "UML 图",
    "business_use": "商业用途",
  },
  "zh-tw": {
    "my_mind_map": "我的思維導圖",
    "h_products": "產品",
    "h_diagram_tool": "圖表工具",
    "image_tool": "圖像工具",
    "diagrams": "圖表",
    "individual_use": "個人使用",
    "other_use": "其他用途",
    "pricing": "定價",
    "tutorial": "教程",
    "faq": "常見問題",
    "blog": "博客",
    "product_1": "思維導圖製作",
    "product_2": "免費消除背景",
    "product_3": "免費圖像放大",
    "product_4": "免費消除浮水印",
    "product_5": "免費圖像壓縮",
    "product_6": "免費JPG Word轉換",
    "product_7": "免費PDF PNG轉換",
    "product_8": "免費HEIC JPG轉換",
    "product_9": "免費圖像轉換",
    "product_10": "免費PDF JPG轉換",
    "product_11": "流程圖",
    "product_12": "組織結構圖",
    "product_13": "甘特圖",
    "product_14": "PERT圖",
    "product_15": "ORM圖",
    "product_16": "時間線",
    "product_17": "概念圖",
    "product_18": "樹狀圖",
    "product_19": "魚骨圖",
    "product_20": "ER圖",
    "product_21": "家系圖",
    "product_22": "UML圖",
    "business_use": "商業用途",
  }
}

for(var i18n_key in i18n){
    for(var i18n_private_key in i18n_private[i18n_key]){
        i18n[i18n_key][i18n_private_key]=i18n_private[i18n_key][i18n_private_key]
    }
}