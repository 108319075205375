var i18n={
  "en": {
    "overtime": "Error code: {%}, plesase retry it",
    "isnetwork": "Internet error. Please check and retry",
    "email_placeholder": "Email",
    "email_empty": "Please input email",
    "email_not_valid": "Email is not valid",
    "email_not_valid_1": "Please input your email",
    "email_not_valid_2": "Email is not valid, please use a different address.",
    "email_not_valid_3": "No email input",
    "password_placeholder": "Password",
    "password_empty": "Please input password",
    "password_not_valid": "Incorrect account or password",
    "password_not_valid_1": "Password above 8 characters is required",
    "password_not_valid_2": "Please create a password",
    "password_placeholder_1": "Create your password",
    "password_placeholder_2": "Confirm your Password",
    "password_placeholder_3": "Create new password",
    "password_placeholder_4": "Confirm new password",
    "password_placeholder_5": "Input old password",
    "copy_password_empty": "Please confirm password",
    "copy_password_not_valid": "Please confirm your password",
    "copy_passwords_inconsistent": "Your password confirmation does not match",
    "code_empty": "Please input verification code",
    "code_not_valid": "Invalid verification code",
    "code_placeholder": "Verification Code",
    "not_received_code": "If your mailbox has not received the verification code for a long time, please get the verification code again.",
    "get_first_code": "Please get the verification code first.",
    "last_name_placeholder": "Please enter your last name",
    "first_name_placeholder": "Please enter your first name",
    "address_placeholder": "Please enter your address",
    "no_code_text": "We've sent a verification code. Please enter your code. <span class='tips'>Didn't receive a code?",
    "no_code_text_1": "1. Please make sure the email address is valid and it can receive emails.",
    "no_code_text_2": "2. For the email is sent by system automatically, it may be flagged as the spam or junk email. Please check whether the email is in the Trash folder.",
    "no_code_text_3": "3. Cannot solve your problem? ",
    "no_code_text_3_span": "Then click here to contact us.",
    "order_no": "You have not purchased any product,If you have any question, please <a href=\"https://www.mindonmap.com/contact-us/\">contact us</a>.",
    "error_24901": "Current account has no email linked, and cannot find the orders. Please connect an email.",
    "user_guide": "User Guide>>",
    "download": "Download",
    "order_number": "Order Number:",
    "Refund": "Refund",
    "Disabled": "Disabled",
    "Normal": "Normal",
    "Modify": "Modify",
    "Modify_1": "Modify>>",
    "Connect": "Connect",
    "unlink_success": "Unlink successfully",
    "connect_success": "Connect successfully",
    "feedback_title": "Thanks for your feedback. Please leave your problem and we will respond to you within 24 hours.",
    "feedback_thank_you": "Thank You!<br />Your Feedback has been submitted successfully.",
    "feedback_email": "Enter your email here!",
    "feedback_content": "Leave any problem or suggestion you encountered here.",
    "feedback_submit": "Submit",
    "form_contents": "You have not entered any description. Please enter it and submit again.",
    "old_password": "Please input old password",
    "new_password": "Please create a new password",
    "old_new_password": "The new password cannot be the same as the old one",
    "incorrect_password": "Incorrect password",
    "delete_no": "Delete Now",
    "Caps": "Caps lock is on",
    "Get": "Get",
    "Done": "Done",
    "error_20001": "Error: 20001. Please log in again.",
    "error_20002": "Error: 20002. Please log in again.",
    "error_20003": "Error: 20003. Please log in again.",
    "error_20004": "Request failed (error code: 20004). Please try it again.",
    "error_20005": "Login session has expired (Error: 20005). Please log in again.",
    "error_20006": "Request failed (error code: 20006). Please try it again.",
    "error_20007": "Login session has expired (Error: 20007). Please log in again.",
    "error_20008": "Login session has expired (Error: 20008). Please log in again.",
    "error_20009": "Login session has expired (Error: 20009). Please log in again.",
    "error_20101": "Please input your email (error code: 20101)",
    "error_20102": "Email is not valid (error code: 20102)",
    "error_20103": "Request failed (error code: 20103). Please try it again",
    "error_20104": "Email is already in use, please <a href=\"https://account.mindonmap.com/login/\">log in</a> or register with a new one",
    "error_20105": "Request failed (error code: 20105). Please try it again",
    "error_20106": "Failed to send email, please retry it",
    "error_20201": "Please input your email (error code: 20201)",
    "error_20202": "Please input your password (error code: 20202)",
    "error_20203": "Please input verification code (error code: 20203)",
    "error_20204": "Email is not valid (error code: 20204)",
    "error_20205": "Password above 8 characters is required (error code: 20205)",
    "error_20206": "Request failed (error code: 20206). Please try it again",
    "error_20207": "Invalid verification code",
    "error_20208": "Request failed (error code: 20208). Please try it again",
    "error_20209": "Request failed (error code: 20209). Please try it again",
    "error_20301": "Please input your email (error code: 20301)",
    "error_20302": "Error: 20302. Please contact us",
    "error_20303": "Email is not valid (error code: 20303)",
    "error_20304": "Request failed (error code: 20304). Please try it again",
    "error_20305": "Account does not exist. Please re-enter or <a href=\"https://account.mindonmap.com/register/\">Create it</a> first.",
    "error_20306": "No password yet. Use <a href=\"https://account.mindonmap.com/passwordless-login/\">Passwordless login</a> or <a href=\"https://account.mindonmap.com/create-password/\">set a password</a> and log in.",
    "error_20308": "Request failed (error code: 20308). Please try it again",
    "error_20401": "Failed to log out (error code: 20401). Please try it again",
    "error_20501": "Please input your email (error code: 20501)",
    "error_20502": "Email is not valid (error code: 20502)",
    "error_20503": "Request failed (error code: 20503). Please try it again",
    "error_20504": "Failed to send email. Please try it again.",
    "error_20601": "Please input your email (error code: 20601)",
    "error_20602": "Please input verification code (error code: 20602)",
    "error_20603": "Email is not valid (error code: 20603)",
    "error_20604": "Request failed (error code: 20604). Please try it again",
    "error_20606": "Request failed (error code: 20606). Please try it again",
    "error_20607": "Request failed (error code: 20607). Please try it again",
    "error_20608": "Request failed (error code: 20608). Please try it again",
    "error_20701": "Please input your email (error code: 20701)",
    "error_20702": "Email is not valid (error code: 20702)",
    "error_20703": "Request failed (error code: 20703). Please try it again",
    "error_20704": "Account does not exist. Please re-enter or <a href=\"https://account.mindonmap.com/register/\">Create it</a> first.",
    "error_20705": "Request failed (error code: 20705). Please try it again",
    "error_20706": "Failed to send email. Please try it again",
    "error_20801": "Please input your email (error code: 20801)",
    "error_20802": "Error: 20802. Please contact us",
    "error_20803": "Please input verification code (error code: 20803)",
    "error_20804": "Email is not valid (error code: 20804)",
    "error_20805": "Password above 8 characters is required (error code: 20805)",
    "error_20806": "Request failed (error code: 20806). Please try it again",
    "error_20808": "Request failed (error code: 20808). Please try it again",
    "error_20901": "Request failed (error code: 20901). Please try it again",
    "error_20902": "Request failed (error code: 20902). Please try it again",
    "error_21000": "Changes are saved",
    "error_21001": "No information is submitted (error code: 21001)",
    "error_21002": "Request failed (error code: 21002). Please try it again",
    "error_21101": "Please input your email (error code: 21101)",
    "error_21102": "Email is not valid (error code: 21102)",
    "error_21103": "Request failed (error code: 21103), plesase retry it again",
    "error_21104": "Email is already connected, please use a new one",
    "error_21105": "Request failed (error code: 21105), plesase retry it again",
    "error_21106": "Failed to send email. Please try it again",
    "error_21201": "Please input your email (error code: 21201)",
    "error_21202": "Please input verification code (error code: 21202)",
    "error_21203": "Email is not valid (error code: 21203)",
    "error_21204": "Error: 21204. Please contact us",
    "error_21205": "Error: 21205. Please contact us",
    "error_21206": "Password above 8 characters is required (error code: 21206)",
    "error_21207": "Request failed (error code: 21207). Please try it again",
    "error_21209": "Request failed (error code: 21209). Please try it again",
    "error_21301": "Please input old password (error code: 21301)",
    "error_21302": "Please create a new password (error code: 21302)",
    "error_21303": "The new password cannot be the same as the old one. (Error: 21303)",
    "error_21304": "Password above 8 characters is required (error code: 21304)",
    "error_21306": "Request failed (error code: 21306). Please try it again",
    "error_21402": "Request failed (error code: 21402). Please try it again",
    "error_21403": "Failed to send verification code. Please resend it",
    "error_21500": "The account has been deleted",
    "error_21501": "Please input verification code (error code: 21501)",
    "error_21502": "Login session has expired (Error: 21502). Please log in again.",
    "error_21503": "Request failed (error code: 21503). Please try it again",
    "error_21505": "Request failed (error code: 21505), plesase retry it",
    "error_21601": "Error: 20601. Please contact us",
    "error_21602": "Invalid Verification (Error: 20602). Please try it again.",
    "error_21603": "Error: 20603. Please try it again",
    "error_21604": "Request failed (error code: 21604). Please try it again",
    "error_21606": "Request failed (error code: 21606). Please try it again",
    "error_21611": "Request failed (error code: 21611). Please try it again",
    "error_21801": "Error: 21801. Please contact us",
    "error_21802": "Request failed (Error: 21802). Please try it again",
    "error_21803": "Error: 21803. Please try it again",
    "error_21804": "Request failed (error code: 21804). Please try it again",
    "error_21806": "Error: 21806. Please try it again",
    "error_21807": "Error: 21807. Please contact us",
    "error_21808": "Error: 21808. Please contact us",
    "error_21809": "Error: 21809. Please contact us",
    "error_21810": "Error: 21810. Please contact us",
    "error_21811": "Error: 21811. Please contact us",
    "error_21812": "Error: 21812. Please contact us",
    "error_21813": "Request failed (error code: 21813). Please try it again",
    "error_21814": "Error: 21814. Please contact us",
    "error_21815": "Request failed (error code: 21815). Please try it again",
    "error_21816": "Error: 21816. Please contact us",
    "error_21817": "Error: 21817. Please contact us",
    "error_21818": "Error: 21818. Please contact us",
    "error_21819": "Request failed (error code: 21819). Please try it again",
    "error_21820": "Error: 21820. Please contact us",
    "error_21821": "Error: 21821. Please contact us",
    "error_21822": "Error: 21822. Please contact us",
    "error_21823": "Request failed (error code: 21823). Please try it again",
    "error_21824": "Request failed (error code: 21824). Please try it again",
    "error_21825": "Request failed (error code: 21825). Please try it again",
    "error_21826": "Request failed (error code: 21826). Please try it again",
    "error_21901": "Error: 21901. Please contact us",
    "error_21902": "Request failed (error code: 21902). Please try it again",
    "error_21903": "The account status has changed (error code: 21903), please refresh the page and try again",
    "error_21904": "Error: 21904. Please try it again",
    "error_21905": "Error: 21905. Please try it again",
    "error_21906": "Request failed (error code: 21906). Please try it again",
    "error_21907": "Google account has been linked to other account",
    "error_21908": "Request failed (error code: 21908). Please try it again",
    "error_22001": "Request failed (error code: 22001). Please try it again",
    "error_22002": "Unlink failed with no additional login",
    "error_22003": "Request failed (error code: 22003). Please try it again",
    "error_22101": "Error: 22101. Please contact us",
    "error_22102": "The account status has changed (error code: 22102), please refresh the page and try again",
    "error_22103": "Request failed (error code: 22103). Please try it again",
    "error_22104": "The account status has changed (error code: 22104), please refresh the page and try again",
    "error_22105": "Error: 22105. Please try it again",
    "error_22106": "Error: 22106. Please try it again",
    "error_22107": "Error: 22107. Please contact us",
    "error_22108": "Request failed (error code: 22108). Please try it again",
    "error_22201": "Error: 22201. Please contact us",
    "error_22202": "Invalid Verification (Error: 22202). Please try it again.",
    "error_22203": "Error: 22203. Please try it again\"",
    "error_22204": "Request failed (error code: 22204). Please try it again",
    "error_22206": "Request failed (error code: 22206). Please try it again",
    "error_22401": "Error: 22401. Please contact us",
    "error_22402": "Invalid Verification (Error: 22402). Please try it again.",
    "error_22403": "Error: 22403. Please try it again",
    "error_22404": "Request failed (error code: 22404). Please try it again",
    "error_22405": "Facebook account has been linked to other email",
    "error_22406": "Error: 22406. Please try it again",
    "error_22407": "Error: 22407. Please contact us",
    "error_22408": "Error: 22408. Please contact us",
    "error_22409": "Error: 22409. Please contact us",
    "error_22410": "Error: 224010. Please contact us",
    "error_22411": "Error: 224011. Please contact us",
    "error_22412": "Error: 224012. Please contact us",
    "error_22413": "Error: 22413. Please contact us",
    "error_22414": "Request failed (error code: 22414). Please try it again",
    "error_22415": "Error: 22415. Please contact us",
    "error_22416": "Error: 22416. Please contact us",
    "error_22417": "Error: 22417. Please contact us",
    "error_22418": "Request failed (error code: 22418). Please try it again",
    "error_22419": "Error: 22419. Please contact us",
    "error_22420": "Error: 22420. Please contact us",
    "error_22421": "Error: 22421. Please contact us",
    "error_22422": "Request failed (error code: 22422). Please try it again",
    "error_22423": "Request failed (error code: 22423). Please try it again",
    "error_22424": "Request failed (error code: 22424). Please try it again",
    "error_22425": "Request failed (error code: 22425). Please try it again",
    "error_20098": "Error code: 20098. If you are browsing in Private mode, please switch to Normal mode and try again.",
    "error_22298": "Request for Google failed (Error: 22298). Please try it again.",
    "error_22498": "Request for Facebook failed (Error: 22498). Please try it again.",
    "error_24902": "Request failed (error code: 24902). Please try it again",
    "error_24903": "Request failed (error code: 24903). Please try it again",
    "error_24904": "Request failed (error code: 24904). Please try it again",
    "error_24905": "Request failed (error code: 24905). Please try it again",
    "login_title": "Log in to MindOnMap",
    "log_in": "Log In",
    "no_account": "No account?",
    "create_it": "Create it",
    "or_log_in_with": "Or log in with",
    "passwordless_login": "Passwordless login",
    "log_in_done": "Log In Done",
    "three_rd_account_connect_info": "Congratulations! You have logged in successfully. Now you can connect one email account that is used to log in in the future.",
    "see_my_account": "See my account",
    "three_rd_login_merge_account": "The third-party account email address has been signed up, do you want to connect and log in with this email address directly?",
    "connect_log_in": "Connect & Log In",
    "create_an_account": "Create an account",
    "back_to_log_in": "Back to log in",
    "create_password": "Create Password",
    "create_now": "Create Now",
    "password_login_subtitle": "Passwordless login with email",
    "account_login": "Account Login",
    "rights": "By creating this account, you agree with to <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Terms of Service</a> and <a href=\"https://www.mindonmap.com/privacy-policy/\">Privacy Policy</a>",
    "passwordless_login_done": "Passwordless login done",
    "passwordless_login_info": "Congratulations, you have completed passwordless login successfully. You can create a password for this account and log in with the account and password in the future. <a href=\"/create-password\" style=\"display: initial;\">Create Now</a>",
    "sign_up": "Sign Up",
    "register_info": "Create your account",
    "reset_now": "Reset Now",
    "forgot_password": "Forgot Password",
    "reset_password_subtitle": "Use your account email to reset password",
    "plan_products": "Plans & Products",
    "nick_name": "Username:",
    "email": "Email:",
    "my_products": "My Products",
    "my_orders": "My Orders",
    "unlink": "Unlink",
    "link": "Link",
    "connected_accounts": "Connected accounts",
    "last_name": "Last name:",
    "first_name": "First name:",
    "Gender": "Gender:",
    "Birth": "Birth:",
    "Month": "Month",
    "Year": "Year",
    "Country_Region": "Country/Region:",
    "Address": "Address:",
    "Save": "Save",
    "Date": "Date",
    "Male": "Male",
    "Female": "Female",
    "Unspecified": "Unspecified",
    "Security": "Security",
    "change_password": "Change Password",
    "change_now": "Change Now",
    "connect_email": "Connect email",
    "delete_account": "Delete Account",
    "delete_account_info": "When your account is deleted, all the data in My Account associated with your account will be permanently deleted, and you might not be able to recover it. We recommend you tread warily.",
    "Delete": "Delete",
    "Logout": "Logout",
    "my_profile": "My Profile",
    "guides_faqs": "Guides & FAQs",
    "More": "More",
    "guides": "Guides",
    "register": "register",
    "hot_faq": "Hot FAQ",
    "Contents": "Contents:",
    "contact_us": "Contact us>>",
    "plan": "Plan",
    "unregistered": "Unregistered",
    "buy_more": "Purchase More",
    "buy_again": "Buy Again",
    "buy_now": "Buy Now",
    "free_no_limit": "Free & No limit",
    "expired": "Expired",
    "lifetime": "Lifetime",
    "remain": "Remain",
    "day_s": "Day(s)",
    "error_24801": "Request failed (error code: 24801). Please try it again",
    "no_app_found": "No app found！<a href=\"/\">Refresh</a> or <a href=\"https://www.mindonmap.com/\">Go to the official website</a>",
    "get_more": "Get More >>",
    "edit_photo": "Edit Photo",
    "select_photo": "Select photo",
    "change_photo": "Change photo",
    "cancel": "Cancel",
    "hide_password": "Hide password",
    "show_password": "Show password",
    "zoom_in": "Zoom in",
    "zoom_out": "Zoom out",
    "rotate": "Rotate",
    "horizontal_flip": "Horizontal flip",
    "vertical_flip": "Vertical flip",
    "country": "Country",
    "country_1": "Select your country/region",
    "country_2": "Åaland Island",
    "country_3": "Afghanistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "American Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarctica",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaijan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarus",
    "country_22": "Belgium",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia & Herzegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet Island",
    "country_31": "Brazil",
    "country_32": "British Indian Ocean Territory",
    "country_33": "British Virgin Islands",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodia",
    "country_39": "Cameroon",
    "country_40": "Canada",
    "country_41": "Cape Verde",
    "country_42": "Caribbean Netherlands",
    "country_43": "Cayman Islands",
    "country_44": "Central African Republic",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Christmas Island",
    "country_49": "Cocos (Keeling) Islands",
    "country_50": "Colombia",
    "country_51": "Cook Islands",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Croatia",
    "country_55": "Cuba",
    "country_56": "Cyprus",
    "country_57": "Czech Republic",
    "country_58": "Democratic Republic of the Congo",
    "country_59": "Denmark",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominican Republic",
    "country_63": "Ecuador",
    "country_64": "Egypt",
    "country_65": "El Salvador",
    "country_66": "Equatorial Guinea",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Ethiopia",
    "country_70": "Falkland Islands",
    "country_71": "Faroe Islands",
    "country_72": "Federated States of Micronesia",
    "country_73": "Fiji",
    "country_74": "Finland",
    "country_75": "France",
    "country_76": "French Guiana",
    "country_77": "French polynesia",
    "country_78": "French Southern Territories",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Germany",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Great Britain (United Kingdom; England)",
    "country_86": "Greece",
    "country_87": "Greenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Island and McDonald Islands",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungary",
    "country_101": "Iceland",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Iraq",
    "country_106": "Ireland",
    "country_107": "Isle of Man",
    "country_108": "Israel",
    "country_109": "Italy",
    "country_110": "Jamaica",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordan",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kyrgyzstan",
    "country_119": "Laos",
    "country_120": "Latvia",
    "country_121": "Lebanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libya",
    "country_125": "Liechtenstein",
    "country_126": "Lithuania",
    "country_127": "Luxembourg",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall islands",
    "country_136": "Martinique",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexico",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Morocco",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Netherlands",
    "country_153": "New Caledonia",
    "country_154": "New Zealand",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolk Island",
    "country_160": "North Korea",
    "country_161": "Northern Mariana Islands",
    "country_162": "Norway",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestinian territories",
    "country_167": "Panama",
    "country_168": "Papua New Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn Islands",
    "country_172": "Poland",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Republic of Macedonia (FYROM)",
    "country_177": "Republic of the Congo",
    "country_178": "Réunion",
    "country_179": "Romania",
    "country_180": "Russian Federation",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (France)",
    "country_184": "Saint-Pierre and Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Saudi Arabia",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovakia",
    "country_195": "Slovenia",
    "country_196": "Solomon Islands",
    "country_197": "Somalia",
    "country_198": "South Africa",
    "country_199": "South Georgia and the South Sandwich Isla",
    "country_200": "South Korea",
    "country_201": "South Sudan",
    "country_202": "Spain",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena & Dependencies",
    "country_205": "St. Kitts & Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent & the Grenadines",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Sweden",
    "country_212": "Switzerland",
    "country_213": "Syria",
    "country_214": "Taiwan",
    "country_215": "Tajikistan",
    "country_216": "Tanzania",
    "country_217": "Template:Country data SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "The Bahamas",
    "country_220": "The Comoros",
    "country_221": "The Philippines",
    "country_222": "Timor-Leste (East Timor)",
    "country_223": "Togo",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Turkey",
    "country_229": "Turkmenistan",
    "country_230": "Turks & Caicos Islands",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraine",
    "country_234": "United Arab Emirates",
    "country_235": "United States Minor Outlying Islands",
    "country_236": "United States of America (USA)",
    "country_237": "United States Virgin Islands",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatican City (The Holy See)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis and Futuna",
    "country_245": "Western Sahara",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Sign in with Google",
    "State": "State",
    "Activation_code": "Activation code",
    "Question": "List all the apps you have logged in",
    "Copy_complete": "Copy complete",
    "footer": "Copyright © 2024 MindOnMap Studio. All rights reserved.",
    "change_password_success": "Password changed successfully",
    "successful_login_title": "Login Success",
    "product_page": "Product Page>>",
    "successful_login_info": "Login completed. Please close the current page and go back to the original tab to continue with the following process. The current page will be closed automatically in 5 seconds. If auto-closing or closing by clicking the \"Done\" button fails, please close this tab directly.",
    "successful_login_info_firefox": "Login completed. Please close the current page and go back to the original tab to continue with the following process.",
    "my_account": "My Account",
    "my_history": "My History",
    "remove_watermark": "Remove Watermark",
    "no_history": "No History",
    "history_all": "Select all",
    "history_open": "Open",
    "history_down": "Download",
    "history_delete": "Delete",
    "history_clear": "Clear invalid",
    "images": "image(s)",
    "use_this_function": "Use This Product>>",
    "hd_downloading": "HD original images downloading benefits:",
    "lifetimeRemaining": "Lifetime-Remaining",
    "Remaining": "Remaining",
    "email_verification": "Email Verification",
    "email_verification_info": "We have sent the verification message to your email <span class=\"email\"></span>, and please finish the verification. After verifying, the benefits will be in sync automatically.",
    "wrong_email": "Wrong email address?",
    "click_here_to_modify": "Click here to modify",
    "get_more_help": "Get more help?",
    "click_here": "Click here",
    "email_verification_info_success": "Congratulations for verifying your email account.",
    "email_verification_info_error": "Verification failed because of the expired link.",
    "registration_succeeded": "Registration Succeeded",
    "registration_succeeded_info_1": "Congratulations! You have registered successfully. We have sent the verification message to your email <span class=\"email\"></span>, and please finish verifying to use the benefits of this email.",
    "registration_succeeded_info_2": "Click \"Done\" to return to the Home page and use this product.",
    "registration_succeeded_info_3": "Click \"Done\" to close the current page and return to the Home page for the following operations. If closing fails, please manually close the tab.",
    "verify_email": "Verify Email",
    "registered_email_not_verified": "The registered email has not been verified, please verify it at once.",
    "email_verification_time_1": "Didn't receive a verification email?",
    "email_verification_time_2": "After",
    "email_verification_time_3": "Click here to resend it",
    "error_26301": "Error code: 26301, please contact us",
    "error_26302": "Error code: 26302, please contact us",
    "error_26303": "Email format error (error code: 26303). Please enter it again",
    "error_26304": "Password above 8 characters is recommended (error code: 26304)",
    "error_26305": "Reuqest failed (error code: 26305). Please retry it",
    "error_26306": "Email has been registered, please <a href=\"https://account.mindonmap.com/login/\">go to log in</a>",
    "error_26307": "Reuqest failed (error code: 26307). Please retry it",
    "error_26308": "Reuqest failed (error code: 26308). Please retry it",
    "error_26401": "Error code: 26401, please retry it",
    "error_26402": "Email has been verified (error code: 26402), please retry it",
    "error_26403": "Reuqest failed (error code: 26403). Please retry it",
    "error_26404": "Request failed (error code: 26404). Please retry it",
    "error_26501": "Error code: 26501, please contact us",
    "error_26502": "Error code: 26502, please contact us",
    "error_26503": "Email format error (error code: 26503). Please enter it again",
    "error_26504": "Reuqest failed (error code: 26504). Please retry it",
    "error_26505": "Email has not been registered, please <a href=\"https://account.mindonmap.com/register/\">register it first</a>",
    "error_26506": "Email has been verified.",
    "error_26507": "Reuqest failed (error code: 26507). Please retry it",
    "error_26508": "Verification fialed (error code: 26508), please retry it",
    "error_26509": "Request failed (error code: 26509), please retry it",
    "error_26510": "Error code: 26510, please contact us",
    "error_26601": "Error code: 26601, please contact us",
    "error_26602": "Error code: 26602, please contact us",
    "error_26603": "Reuqest failed (error code: 26603). Please retry it",
    "error_26604": "Error code: 26604, please contact us",
    "error_26605": "Error code: 26605, please contact us",
    "error_26701": "Error code: 26701, please contact us",
    "error_26702": "Reuqest failed (error code: 26702). Please retry it",
    "error_26703": "Error code: 26703, please contact us",
    "error_26704": "Error code: 26704, please contact us",
    "error_26705": "Wait for logging in (error code: 26705). Please retry it",
    "no_cookie": "You have turned on the Block all cookies function in your browser, so you cannot log in. Please go to Settings to check the box of Allow all cookies.",
    "error_26801": "Error code: 26801, please contact us",
    "error_26802": "Error code: 26802, please contact us",
    "error_26803": "Request failed (error code: 26803). Please retry it",
    "error_26804": "Request failed (error code: 26804). Please retry it",
    "error_order": "Request failed (error code: 27098), please try it again!",
    "error_order1": "The order query is incomplete（error code: ",
    "error_order2": "）, please refresh and try it again.",
    "modify_email_title": "Modify Email",
    "modify_email_info": "You can use the modified email to log into your account.",
    "images_per": "Images per",
    "error_26101": "Error: 26101. Please contact us",
    "error_26102": "Error: 26102. Please contact us",
    "error_26103": "Request failed (error code:26103). Please try it again",
    "error_26104": "Error code: 26104, plesase retry it",
    "error_26105": "Error code: 26105, plesase retry it",
    "error_26106": "Delete failed (error code: 26106). Please try it again",
    "error_26201": "Error: 26201. Please contact us",
    "error_26202": "Request failed (error code:26202). Please try it again",
    "error_26001": "Error: 26001. Please contact us",
    "error_26002": "Error: 26002. Please contact us",
    "error_26003": "Error: 26003. Please contact us",
    "error_26004": "Error: 26004. Please contact us",
    "error_26005": "Request failed (error code:26005). Please try it again",
    "error_26006": "Error code: 26006, plesase retry it",
    "error_26008": "Error: 26008. Please contact us",
    "go_to_the_home_page": "Go to the Home page",
    "error_27101": "Request failed (error code: 27101). Please retry ist",
    "error_27201": "Error code: 27201, please contact us",
    "error_27202": "Error code: 27202, please retry it",
    "error_27203": "Request failed (error code: 27203). Please retry it",
    "error_27204": "Invalid code (error code: 27204).",
    "error_27205": "Request failed (error code: 27205). Please retry it",
    "error_27206": "Request failed (error code: 27206). Please retry it",
    "error_27207": "Request failed (error code: 27207). Please retry it",
    "no_history_found": "You haven't used any tool! <a href=\"/\">Refresh</a> or <a href=\"https://www.mindonmap.com/\">Go to the official website</a>",
    "error_25301": "Error: 25301. Please contact us",
    "error_25302": "Error: 25302. Please contact us",
    "error_25303": "Request failed (error code: 25303). Please try it again",
    "error_25304": "Request failed (error code: 25304). Please try it again",
    "error_25305": "Request failed (error code: 25305). Please try it again",
    "error_25306": "Request failed (error code: 25306). Please try it again",
    "image_upscaler_p": "Image without watermark downloading benefits:",
    "Available_for": "Available for:",
    "credit_per": "%s credit(s) per HD image",
    "still_valid": "Purchased plan(s) is still valid",
    "credit": "credit(s)",
    "pc_3rd_info": "Login successfully. Please go to the application for further operation.",
    "use_online": "Use the online service",
    "use_download": "Use the desktop program",
    "use_immediately": "Use Immediately",
    "Use_in_browser": "Use in Browser",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Credits per Month",
    "expire": "Expiration Time:",
    "viewDetails": "View details",
    "viewHistory": "View history benefits>>",
    "viewDetailsInfo": "Notice: If the subscription benefits are renewed within 7 days after expiration, the unused benefits can continue to be used. Also, the expiration time will be automatically updated to the expiration time of the new subscription. If there is no new subscription after 7 days of expiration time, all expired benefits will be cleared.",
    "connect_account": "Bind Email to Your Account",
    "connect_account_info": "After binding, you can log in with this email address.",
    "connect_now": "Bind Now",
    "no_email_bind": "No email bind",
    "bind_email": "Bind Email",
    "connect_your_email_placeholder": "Please enter your email address",
    "bind_an_email": "Bind an Email",
    "bind_info": "You have logged in successfully. Please bind an email to your account to activate your benefits.",
    "bind_later": "Bind later",
    "hi": "Hi, %s!",
    "Personal_Information": "Personal Information",
    "Access": "Access",
    "Subscription_Plan": "(Subscription Plan)",
    "No_orders": "No orders found.",
    "No_data": "No data",
    "Featured_Products": "Featured Products",
    "More_Products": "More Products",
    "Free_Download": "Free Download",
    "Get_Started": "Get Started",
    "Subscribe": "Subscribe",
    "Verified": "Verified",
    "back_to_account_center": "Back to Account Center",
    "success": "Success!",
    "successfully": "You have successfully registered an account.",
    "Continue": "Continue",
    "Already": "Already have an account?",
    "loading_verification": "Checking verification status...",
    "email_no_verification": "Sorry, the registered email address has not been verified. Please complete the verification according to the above instructions and click the \"Done\" button again to complete the registration.",
    "will_expire_after": "Will expire after",
    "hours": "hours",
  },
  "af": {
    "overtime": "Foutkode: {%}, probeer dit asseblief weer",
    "isnetwork": "Internet fout. Kontroleer asseblief en probeer weer",
    "email_placeholder": "E-pos",
    "email_empty": "Voer asseblief e-pos in",
    "email_not_valid": "E-pos is nie geldig nie",
    "email_not_valid_1": "Voer asseblief jou e-pos in",
    "email_not_valid_2": "E-pos is nie geldig nie, gebruik asseblief 'n ander adres.",
    "email_not_valid_3": "Geen e-posinvoer nie",
    "password_placeholder": "Wagwoord",
    "password_empty": "Voer asseblief wagwoord in",
    "password_not_valid": "Verkeerde rekening of wagwoord",
    "password_not_valid_1": "Wagwoord bo 8 karakters word vereis",
    "password_not_valid_2": "Skep asseblief 'n wagwoord",
    "password_placeholder_1": "Skep jou wagwoord",
    "password_placeholder_2": "Bevestig jou wagwoord",
    "password_placeholder_3": "Skep nuwe wagwoord",
    "password_placeholder_4": "Bevestig nuwe wagwoord",
    "password_placeholder_5": "Voer ou wagwoord in",
    "copy_password_empty": "Bevestig asseblief wagwoord",
    "copy_password_not_valid": "Bevestig asseblief jou wagwoord",
    "copy_passwords_inconsistent": "Jou wagwoordbevestiging stem nie ooreen nie",
    "code_empty": "Voer asseblief verifikasiekode in",
    "code_not_valid": "Ongeldige verifikasiekode",
    "code_placeholder": "Verifikasie kode",
    "not_received_code": "As jou posbus vir 'n lang tyd nie die verifikasiekode ontvang het nie, kry asseblief weer die verifikasiekode.",
    "get_first_code": "Kry asseblief eers die verifikasiekode.",
    "last_name_placeholder": "Voer asseblief jou van in",
    "first_name_placeholder": "Voer asseblief jou voornaam in",
    "address_placeholder": "Voer asseblief jou adres in",
    "no_code_text": "Ons het 'n verifikasiekode gestuur. Voer asseblief jou kode in. <span class='tips'>Het jy nie 'n kode ontvang nie?",
    "no_code_text_1": "1. Maak asseblief seker die e-posadres is geldig en dit kan e-posse ontvang.",
    "no_code_text_2": "2. Omdat die e-pos outomaties deur die stelsel gestuur word, kan dit as die strooipos of gemors-e-pos gemerk word. Kontroleer asseblief of die e-pos in die asblikvouer is.",
    "no_code_text_3": "3. Kan jy nie jou probleem oplos nie? ",
    "no_code_text_3_span": "Klik dan hier om ons te kontak.",
    "order_no": "Jy het geen produk gekoop nie, as jy enige vrae het, <a href=\"https://www.mindonmap.com/contact-us/\">kontak ons asseblief</a>.",
    "error_24901": "Huidige rekening het geen e-pos gekoppel nie, en kan nie die bestellings vind nie. Koppel asseblief 'n e-pos.",
    "user_guide": "Gebruikersgids >>",
    "download": "Aflaai",
    "order_number": "Bestellingnommer:",
    "Refund": "Terugbetaling",
    "Disabled": "Gestrem",
    "Normal": "Normaal",
    "Modify": "Verander",
    "Modify_1": "Verander >>",
    "Connect": "Koppel",
    "unlink_success": "Ontkoppel suksesvol",
    "connect_success": "Koppel suksesvol",
    "feedback_title": "Dankie vir jou terugvoer. Los asseblief jou probleem en ons sal binne 24 uur op jou reageer.",
    "feedback_thank_you": "Dankie!<br />Jou terugvoer is suksesvol ingedien.",
    "feedback_email": "Voer jou e-pos hier in!",
    "feedback_content": "Los enige probleem of voorstel wat jy hier teëgekom het.",
    "feedback_submit": "Indien",
    "form_contents": "Jy het geen beskrywing ingevoer nie. Voer dit asseblief in en dien weer in.",
    "old_password": "Voer asseblief ou wagwoord in",
    "new_password": "Skep asseblief 'n nuwe wagwoord",
    "old_new_password": "Die nuwe wagwoord kan nie dieselfde as die ou een wees nie",
    "incorrect_password": "Verkeerde wagwoord",
    "delete_no": "Vee nou uit",
    "Caps": "Caps Lock is aan",
    "Get": "Kry",
    "Done": "Klaar",
    "error_20001": "Fout: 20001. Meld asseblief weer aan.",
    "error_20002": "Fout: 20002. Meld asseblief weer aan.",
    "error_20003": "Fout: 20003. Meld asseblief weer aan.",
    "error_20004": "Versoek het misluk (foutkode: 20004). Probeer dit asseblief weer.",
    "error_20005": "Aanmeldingsessie het verval (Fout: 20005). Meld asseblief weer aan.",
    "error_20006": "Versoek het misluk (foutkode: 20006). Probeer dit asseblief weer.",
    "error_20007": "Aanmeldingsessie het verval (Fout: 20007). Meld asseblief weer aan.",
    "error_20008": "Aanmeldingsessie het verval (Fout: 20008). Meld asseblief weer aan.",
    "error_20009": "Aanmeldingsessie het verval (Fout: 20009). Meld asseblief weer aan.",
    "error_20101": "Voer asseblief jou e-pos in (foutkode: 20101)",
    "error_20102": "E-pos is nie geldig nie (foutkode: 20102)",
    "error_20103": "Versoek het misluk (foutkode: 20103). Probeer dit asseblief weer",
    "error_20104": "E-pos is reeds in gebruik, <a href=\"https://account.mindonmap.com/login/\">teken aan</a> of registreer met 'n nuwe een",
    "error_20105": "Versoek het misluk (foutkode: 20105). Probeer dit asseblief weer",
    "error_20106": "Kon nie e-pos stuur nie, probeer dit asseblief weer",
    "error_20201": "Voer asseblief jou e-pos in (foutkode: 20201)",
    "error_20202": "Voer asseblief jou wagwoord in (foutkode: 20202)",
    "error_20203": "Voer asseblief verifikasiekode in (foutkode: 20203)",
    "error_20204": "E-pos is nie geldig nie (foutkode: 20204)",
    "error_20205": "Wagwoord bo 8 karakters word vereis (foutkode: 20205)",
    "error_20206": "Versoek het misluk (foutkode: 20206). Probeer dit asseblief weer",
    "error_20207": "Ongeldige verifikasiekode",
    "error_20208": "Versoek het misluk (foutkode: 20208). Probeer dit asseblief weer",
    "error_20209": "Versoek het misluk (foutkode: 20209). Probeer dit asseblief weer",
    "error_20301": "Voer asseblief jou e-pos in (foutkode: 20301)",
    "error_20302": "Fout: 20302. Kontak ons asseblief",
    "error_20303": "E-pos is nie geldig nie (foutkode: 20303)",
    "error_20304": "Versoek het misluk (foutkode: 20304). Probeer dit asseblief weer",
    "error_20305": "Rekening bestaan nie. Voer asseblief weer in of <a href=\"https://account.mindonmap.com/register/\">Skep dit</a> eers.",
    "error_20306": "Nog geen wagwoord nie. Gebruik <a href=\"https://account.mindonmap.com/passwordless-login/\">Wagwoordlose aanmelding</a> of <a href=\"https://account.mindonmap.com/create-password/\"> stel 'n wagwoord</a> en meld aan.",
    "error_20308": "Versoek het misluk (foutkode: 20308). Probeer dit asseblief weer",
    "error_20401": "Kon nie afmeld nie (foutkode: 20401). Probeer dit asseblief weer",
    "error_20501": "Voer asseblief jou e-pos in (foutkode: 20501)",
    "error_20502": "E-pos is nie geldig nie (foutkode: 20502)",
    "error_20503": "Versoek het misluk (foutkode: 20503). Probeer dit asseblief weer",
    "error_20504": "Kon nie e-pos stuur nie. Probeer dit asseblief weer.",
    "error_20601": "Voer asseblief jou e-pos in (foutkode: 20601)",
    "error_20602": "Voer asseblief verifikasiekode in (foutkode: 20602)",
    "error_20603": "E-pos is nie geldig nie (foutkode: 20603)",
    "error_20604": "Versoek het misluk (foutkode: 20604). Probeer dit asseblief weer",
    "error_20606": "Versoek het misluk (foutkode: 20606). Probeer dit asseblief weer",
    "error_20607": "Versoek het misluk (foutkode: 20607). Probeer dit asseblief weer",
    "error_20608": "Versoek het misluk (foutkode: 20608). Probeer dit asseblief weer",
    "error_20701": "Voer asseblief jou e-pos in (foutkode: 20701)",
    "error_20702": "E-pos is nie geldig nie (foutkode: 20702)",
    "error_20703": "Versoek het misluk (foutkode: 20703). Probeer dit asseblief weer",
    "error_20704": "Rekening bestaan nie. Voer asseblief weer in of <a href=\"https://account.mindonmap.com/register/\">Skep dit</a> eers.",
    "error_20705": "Versoek het misluk (foutkode: 20705). Probeer dit asseblief weer",
    "error_20706": "Kon nie e-pos stuur nie. Probeer dit asseblief weer",
    "error_20801": "Voer asseblief jou e-pos in (foutkode: 20801)",
    "error_20802": "Fout: 20802. Kontak ons asseblief",
    "error_20803": "Voer asseblief verifikasiekode in (foutkode: 20803)",
    "error_20804": "E-pos is nie geldig nie (foutkode: 20804)",
    "error_20805": "Wagwoord bo 8 karakters word vereis (foutkode: 20805)",
    "error_20806": "Versoek het misluk (foutkode: 20806). Probeer dit asseblief weer",
    "error_20808": "Versoek het misluk (foutkode: 20808). Probeer dit asseblief weer",
    "error_20901": "Versoek het misluk (foutkode: 20901). Probeer dit asseblief weer",
    "error_20902": "Versoek het misluk (foutkode: 20902). Probeer dit asseblief weer",
    "error_21000": "Veranderinge word gestoor",
    "error_21001": "Geen inligting word ingedien nie (foutkode: 21001)",
    "error_21002": "Versoek het misluk (foutkode: 21002). Probeer dit asseblief weer",
    "error_21101": "Voer asseblief jou e-pos in (foutkode: 21101)",
    "error_21102": "E-pos is nie geldig nie (foutkode: 21102)",
    "error_21103": "Versoek het misluk (foutkode: 21103), probeer dit asseblief weer",
    "error_21104": "E-pos is reeds gekoppel, gebruik asseblief 'n nuwe een",
    "error_21105": "Versoek het misluk (foutkode: 21105), probeer dit asseblief weer",
    "error_21106": "Kon nie e-pos stuur nie. Probeer dit asseblief weer",
    "error_21201": "Voer asseblief jou e-pos in (foutkode: 21201)",
    "error_21202": "Voer asseblief verifikasiekode in (foutkode: 21202)",
    "error_21203": "E-pos is nie geldig nie (foutkode: 21203)",
    "error_21204": "Fout: 21204. Kontak ons asseblief",
    "error_21205": "Fout: 21205. Kontak ons asseblief",
    "error_21206": "Wagwoord bo 8 karakters word vereis (foutkode: 21206)",
    "error_21207": "Versoek het misluk (foutkode: 21207). Probeer dit asseblief weer",
    "error_21209": "Versoek het misluk (foutkode: 21209). Probeer dit asseblief weer",
    "error_21301": "Voer asseblief ou wagwoord in (foutkode: 21301)",
    "error_21302": "Skep asseblief 'n nuwe wagwoord (foutkode: 21302)",
    "error_21303": "Die nuwe wagwoord kan nie dieselfde as die ou een wees nie. (Fout: 21303)",
    "error_21304": "Wagwoord bo 8 karakters word vereis (foutkode: 21304)",
    "error_21306": "Versoek het misluk (foutkode: 21306). Probeer dit asseblief weer",
    "error_21402": "Versoek het misluk (foutkode: 21402). Probeer dit asseblief weer",
    "error_21403": "Kon nie verifikasiekode stuur nie. Stuur dit asseblief weer",
    "error_21500": "Die rekening is uitgevee",
    "error_21501": "Voer asseblief verifikasiekode in (foutkode: 21501)",
    "error_21502": "Aanmeldingsessie het verval (Fout: 21502). Meld asseblief weer aan.",
    "error_21503": "Versoek het misluk (foutkode: 21503). Probeer dit asseblief weer",
    "error_21505": "Versoek het misluk (foutkode: 21505), probeer dit asseblief weer",
    "error_21601": "Fout: 20601. Kontak ons asseblief",
    "error_21602": "Ongeldige verifikasie (Fout: 20602). Probeer dit asseblief weer.",
    "error_21603": "Fout: 20603. Probeer dit asseblief weer",
    "error_21604": "Versoek het misluk (foutkode: 21604). Probeer dit asseblief weer",
    "error_21606": "Versoek het misluk (foutkode: 21606). Probeer dit asseblief weer",
    "error_21611": "Versoek het misluk (foutkode: 21611). Probeer dit asseblief weer",
    "error_21801": "Fout: 21801. Kontak ons asseblief",
    "error_21802": "Versoek het misluk (Fout: 21802). Probeer dit asseblief weer",
    "error_21803": "Fout: 21803. Probeer dit asseblief weer",
    "error_21804": "Versoek het misluk (foutkode: 21804). Probeer dit asseblief weer",
    "error_21806": "Fout: 21806. Probeer dit asseblief weer",
    "error_21807": "Fout: 21807. Kontak ons asseblief",
    "error_21808": "Fout: 21808. Kontak ons asseblief",
    "error_21809": "Fout: 21809. Kontak ons asseblief",
    "error_21810": "Fout: 21810. Kontak ons asseblief",
    "error_21811": "Fout: 21811. Kontak ons asseblief",
    "error_21812": "Fout: 21812. Kontak ons asseblief",
    "error_21813": "Versoek het misluk (foutkode: 21813). Probeer dit asseblief weer",
    "error_21814": "Fout: 21814. Kontak ons asseblief",
    "error_21815": "Versoek het misluk (foutkode: 21815). Probeer dit asseblief weer",
    "error_21816": "Fout: 21816. Kontak ons asseblief",
    "error_21817": "Fout: 21817. Kontak ons asseblief",
    "error_21818": "Fout: 21818. Kontak ons asseblief",
    "error_21819": "Versoek het misluk (foutkode: 21819). Probeer dit asseblief weer",
    "error_21820": "Fout: 21820. Kontak ons asseblief",
    "error_21821": "Fout: 21821. Kontak ons asseblief",
    "error_21822": "Fout: 21822. Kontak ons asseblief",
    "error_21823": "Versoek het misluk (foutkode: 21823). Probeer dit asseblief weer",
    "error_21824": "Versoek het misluk (foutkode: 21824). Probeer dit asseblief weer",
    "error_21825": "Versoek het misluk (foutkode: 21825). Probeer dit asseblief weer",
    "error_21826": "Versoek het misluk (foutkode: 21826). Probeer dit asseblief weer",
    "error_21901": "Fout: 21901. Kontak ons asseblief",
    "error_21902": "Versoek het misluk (foutkode: 21902). Probeer dit asseblief weer",
    "error_21903": "Die rekeningstatus het verander (foutkode: 21903), verfris asseblief die bladsy en probeer weer",
    "error_21904": "Fout: 21904. Probeer dit asseblief weer",
    "error_21905": "Fout: 21905. Probeer dit asseblief weer",
    "error_21906": "Versoek het misluk (foutkode: 21906). Probeer dit asseblief weer",
    "error_21907": "Google-rekening is aan 'n ander rekening gekoppel",
    "error_21908": "Versoek het misluk (foutkode: 21908). Probeer dit asseblief weer",
    "error_22001": "Versoek het misluk (foutkode: 22001). Probeer dit asseblief weer",
    "error_22002": "Ontkoppel het misluk met geen bykomende aanmelding nie",
    "error_22003": "Versoek het misluk (foutkode: 22003). Probeer dit asseblief weer",
    "error_22101": "Fout: 22101. Kontak ons asseblief",
    "error_22102": "Die rekeningstatus het verander (foutkode: 22102), verfris asseblief die bladsy en probeer weer",
    "error_22103": "Versoek het misluk (foutkode: 22103). Probeer dit asseblief weer",
    "error_22104": "Die rekeningstatus het verander (foutkode: 22104), verfris asseblief die bladsy en probeer weer",
    "error_22105": "Fout: 22105. Probeer dit asseblief weer",
    "error_22106": "Fout: 22106. Probeer dit asseblief weer",
    "error_22107": "Fout: 22107. Kontak ons asseblief",
    "error_22108": "Versoek het misluk (foutkode: 22108). Probeer dit asseblief weer",
    "error_22201": "Fout: 22201. Kontak ons asseblief",
    "error_22202": "Ongeldige verifikasie (Fout: 22202). Probeer dit asseblief weer.",
    "error_22203": "Fout: 22203. Probeer dit asseblief weer\"",
    "error_22204": "Versoek het misluk (foutkode: 22204). Probeer dit asseblief weer",
    "error_22206": "Versoek het misluk (foutkode: 22206). Probeer dit asseblief weer",
    "error_22401": "Fout: 22401. Kontak ons asseblief",
    "error_22402": "Ongeldige verifikasie (Fout: 22402). Probeer dit asseblief weer.",
    "error_22403": "Fout: 22403. Probeer dit asseblief weer",
    "error_22404": "Versoek het misluk (foutkode: 22404). Probeer dit asseblief weer",
    "error_22405": "Facebook-rekening is aan ander e-posadres gekoppel",
    "error_22406": "Fout: 22406. Probeer dit asseblief weer",
    "error_22407": "Fout: 22407. Kontak ons asseblief",
    "error_22408": "Fout: 22408. Kontak ons asseblief",
    "error_22409": "Fout: 22409. Kontak ons asseblief",
    "error_22410": "Fout: 224010. Kontak ons asseblief",
    "error_22411": "Fout: 224011. Kontak ons asseblief",
    "error_22412": "Fout: 224012. Kontak ons asseblief",
    "error_22413": "Fout: 22413. Kontak ons asseblief",
    "error_22414": "Versoek het misluk (foutkode: 22414). Probeer dit asseblief weer",
    "error_22415": "Fout: 22415. Kontak ons asseblief",
    "error_22416": "Fout: 22416. Kontak ons asseblief",
    "error_22417": "Fout: 22417. Kontak ons asseblief",
    "error_22418": "Versoek het misluk (foutkode: 22418). Probeer dit asseblief weer",
    "error_22419": "Fout: 22419. Kontak ons asseblief",
    "error_22420": "Fout: 22420. Kontak ons asseblief",
    "error_22421": "Fout: 22421. Kontak ons asseblief",
    "error_22422": "Versoek het misluk (foutkode: 22422). Probeer dit asseblief weer",
    "error_22423": "Versoek het misluk (foutkode: 22423). Probeer dit asseblief weer",
    "error_22424": "Versoek het misluk (foutkode: 22424). Probeer dit asseblief weer",
    "error_22425": "Versoek het misluk (foutkode: 22425). Probeer dit asseblief weer",
    "error_20098": "Foutkode: 20098. As jy in Privaatmodus blaai, skakel asseblief oor na Normale modus en probeer weer.",
    "error_22298": "Versoek vir Google het misluk (Fout: 22298). Probeer dit asseblief weer.",
    "error_22498": "Versoek vir Facebook het misluk (Fout: 22498). Probeer dit asseblief weer.",
    "error_24902": "Versoek het misluk (foutkode: 24902). Probeer dit asseblief weer",
    "error_24903": "Versoek het misluk (foutkode: 24903). Probeer dit asseblief weer",
    "error_24904": "Versoek het misluk (foutkode: 24904). Probeer dit asseblief weer",
    "error_24905": "Versoek het misluk (foutkode: 24905). Probeer dit asseblief weer",
    "login_title": "Meld aan by MindOnMap",
    "log_in": "Teken aan",
    "no_account": "Geen rekening nie?",
    "create_it": "Skep dit",
    "or_log_in_with": "Of meld aan met",
    "passwordless_login": "Wagwoordlose aanmelding",
    "log_in_done": "Teken in Klaar",
    "three_rd_account_connect_info": "Baie geluk! Jy het suksesvol aangemeld. Nou kan jy een e-posrekening koppel wat gebruik word om in die toekoms aan te meld.",
    "see_my_account": "Sien my rekening",
    "three_rd_login_merge_account": "Die derdeparty-rekening se e-posadres is aangemeld, wil jy direk met hierdie e-posadres koppel en aanmeld?",
    "connect_log_in": "Koppel en meld aan",
    "create_an_account": "Maak 'n rekening oop",
    "back_to_log_in": "Terug om aan te meld",
    "create_password": "Skep wagwoord",
    "create_now": "Skep nou",
    "password_login_subtitle": "Wagwoordlose aanmelding met e-pos",
    "account_login": "Rekeningaanmelding",
    "rights": "Deur hierdie rekening te skep, stem jy in tot <a href=\"https://www.mindonmap.com/terms-and-conditions/\">diensbepalings</a> en <a href=\"https://www.mindonmap.com/privacy-policy/\">Privaatheidsbeleid</a>",
    "passwordless_login_done": "Wagwoordlose aanmelding gedoen",
    "passwordless_login_info": "Baie geluk, jy het wagwoordlose aanmelding suksesvol voltooi. Jy kan 'n wagwoord vir hierdie rekening skep en in die toekoms met die rekening en wagwoord aanmeld. <a href=\"/create-password\" style=\"display: initial;\">Skep nou</a>",
    "sign_up": "Teken aan",
    "register_info": "Skep jou rekening",
    "reset_now": "Stel nou terug",
    "forgot_password": "Wagwoord vergeet",
    "reset_password_subtitle": "Gebruik jou rekening-e-posadres om wagwoord terug te stel",
    "plan_products": "Planne en produkte",
    "nick_name": "Gebruikersnaam:",
    "email": "E-pos:",
    "my_products": "My Produkte",
    "my_orders": "My bestellings",
    "unlink": "Ontkoppel",
    "link": "Skakel",
    "connected_accounts": "Gekoppelde rekeninge",
    "last_name": "Van:",
    "first_name": "Eerste naam:",
    "Gender": "Geslag:",
    "Birth": "Geboorte:",
    "Month": "Maand",
    "Year": "Jaar",
    "Country_Region": "Land/streek:",
    "Address": "Adres:",
    "Save": "Stoor",
    "Date": "Datum",
    "Male": "Manlik",
    "Female": "Vroulik",
    "Unspecified": "Ongespesifiseer",
    "Security": "Sekuriteit",
    "change_password": "Verander wagwoord",
    "change_now": "Verander nou",
    "connect_email": "Koppel e-pos",
    "delete_account": "Skrap rekening",
    "delete_account_info": "Wanneer jou rekening uitgevee word, sal al die data in My Rekening wat met jou rekening geassosieer word, permanent uitgevee word, en jy sal dit dalk nie kan herstel nie. Ons beveel aan dat jy versigtig trap.",
    "Delete": "Vee uit",
    "Logout": "Teken uit",
    "my_profile": "My profiel",
    "guides_faqs": "Gidse en Gereelde Vrae",
    "More": "Meer",
    "guides": "Gidse",
    "register": "registreer",
    "hot_faq": "Warm Gereelde Vrae",
    "Contents": "Inhoud:",
    "contact_us": "Kontak ons >>",
    "plan": "Beplan",
    "unregistered": "Ongeregistreer",
    "buy_more": "Koop Meer",
    "buy_again": "Koop weer",
    "buy_now": "Koop nou",
    "free_no_limit": "Gratis & Geen limiet",
    "expired": "Verval",
    "lifetime": "Lewensduur",
    "remain": "Bly",
    "day_s": "Dag(e)",
    "error_24801": "Versoek het misluk (foutkode: 24801). Probeer dit asseblief weer",
    "no_app_found": "Geen toepassing gevind nie!<a href=\"/\">Verfris</a> of <a href=\"https://www.mindonmap.com/\">Gaan na die amptelike webwerf</a>",
    "get_more": "Kry meer >>",
    "edit_photo": "Redigeer foto",
    "select_photo": "Kies foto",
    "change_photo": "Verander foto",
    "cancel": "Kanselleer",
    "hide_password": "Versteek wagwoord",
    "show_password": "Wys wagwoord",
    "zoom_in": "Zoem in",
    "zoom_out": "Zoem uit",
    "rotate": "Draai",
    "horizontal_flip": "Horisontale flip",
    "vertical_flip": "Vertikale flip",
    "country": "Land",
    "country_1": "Kies jou land/streek",
    "country_2": "Åaland-eiland",
    "country_3": "Afghanistan",
    "country_4": "Albanië",
    "country_5": "Algerië",
    "country_6": "Amerikaanse Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktika",
    "country_11": "Antigua en Barbuda",
    "country_12": "Argentinië",
    "country_13": "Armenië",
    "country_14": "Aruba",
    "country_15": "Australië",
    "country_16": "Oostenryk",
    "country_17": "Azerbeidjan",
    "country_18": "Bahrein",
    "country_19": "Bangladesj",
    "country_20": "Barbados",
    "country_21": "Wit-Rusland",
    "country_22": "België",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhoetan",
    "country_27": "Bolivia",
    "country_28": "Bosnië en Herzegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet-eiland",
    "country_31": "Brasilië",
    "country_32": "Britse Indiese Oseaan-gebied",
    "country_33": "Britse Maagde-eilande",
    "country_34": "Brunei",
    "country_35": "Bulgarye",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodja",
    "country_39": "Kameroen",
    "country_40": "Kanada",
    "country_41": "Kaap Verde",
    "country_42": "Karibiese Nederland",
    "country_43": "Cayman Eilande",
    "country_44": "Sentraal-Afrikaanse Republiek",
    "country_45": "Tsjad",
    "country_46": "Chili",
    "country_47": "Sjina",
    "country_48": "Kersfees-eiland",
    "country_49": "Cocos (Keeling) -eilande",
    "country_50": "Colombia",
    "country_51": "Cook-eilande",
    "country_52": "Costa Rica",
    "country_53": "Ivoorkus",
    "country_54": "Kroasië",
    "country_55": "Kuba",
    "country_56": "Ciprus",
    "country_57": "Tsjeggiese Republiek",
    "country_58": "Demokratiese Republiek van die Kongo",
    "country_59": "Denemarke",
    "country_60": "Djiboeti",
    "country_61": "Dominica",
    "country_62": "Dominikaanse Republiek",
    "country_63": "Ecuador",
    "country_64": "Egipte",
    "country_65": "El Salvador",
    "country_66": "Ekwatoriaal-Guinee",
    "country_67": "Eritrea",
    "country_68": "Estland",
    "country_69": "Ethiopië",
    "country_70": "Falkland-eilande",
    "country_71": "Faroëreilande",
    "country_72": "Gefedereerde state van Mikronesië",
    "country_73": "Fidji",
    "country_74": "Finland",
    "country_75": "Frankryk",
    "country_76": "Frans Guyana",
    "country_77": "Frans-Polynesië",
    "country_78": "Franse Suidelike Gebiede",
    "country_79": "Gaboen",
    "country_80": "Gambië",
    "country_81": "Georgië",
    "country_82": "Duitsland",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Groot Brittanje (Verenigde Koninkryk; Engeland)",
    "country_86": "Griekeland",
    "country_87": "Groenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinee",
    "country_94": "Guinee-Bissau",
    "country_95": "Guyana",
    "country_96": "Haïti",
    "country_97": "Heard Eiland en McDonald eilande",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hongarye",
    "country_101": "Ysland",
    "country_102": "Indië",
    "country_103": "Indonesië",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Ierland",
    "country_107": "Eiland Man",
    "country_108": "Israel",
    "country_109": "Italië",
    "country_110": "Jamaika",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordaan",
    "country_114": "Kasakstan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Koeweit",
    "country_118": "Kirgisië",
    "country_119": "Laos",
    "country_120": "Letland",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberië",
    "country_124": "Libië",
    "country_125": "Liechtenstein",
    "country_126": "Litaue",
    "country_127": "Luxemburg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Maleisië",
    "country_132": "Maldive",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall-eilande",
    "country_136": "Martinique",
    "country_137": "Mauritanië",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldawië",
    "country_142": "Monaco",
    "country_143": "Mongolië",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marokko",
    "country_147": "Mosambiek",
    "country_148": "Myanmar (Birma)",
    "country_149": "Namibië",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Nederland",
    "country_153": "Nieu-Kaledonië",
    "country_154": "Nieu-Seeland",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigerië",
    "country_158": "Niue",
    "country_159": "Norfolk-eiland",
    "country_160": "Noord-Korea",
    "country_161": "Noordelike Mariana-eilande",
    "country_162": "Noorweë",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestynse gebiede",
    "country_167": "Panama",
    "country_168": "Papoea-Nieu-Guinee",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn-eilande",
    "country_172": "Pole",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Republiek van Masedonië (FYROM)",
    "country_177": "Republiek van die Kongo",
    "country_178": "Réunion",
    "country_179": "Roemenië",
    "country_180": "Russiese Federasie",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Frankryk)",
    "country_184": "Saint-Pierre en Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tomé en Principe",
    "country_188": "Saoedi-Arabië",
    "country_189": "Senegal",
    "country_190": "Serwië",
    "country_191": "Seychelle",
    "country_192": "Sierra Leone",
    "country_193": "Singapoer",
    "country_194": "Slowakye",
    "country_195": "Slowenië",
    "country_196": "Salomonseilande",
    "country_197": "Somalië",
    "country_198": "Suid-Afrika",
    "country_199": "Suid Georgië en die South Sandwich Isla",
    "country_200": "Suid-Korea",
    "country_201": "Suid-Soedan",
    "country_202": "Spanje",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena en afhanklikhede",
    "country_205": "St. Kitts en Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent en die Grenadiene",
    "country_208": "Soedan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Swede",
    "country_212": "Switserland",
    "country_213": "Sirië",
    "country_214": "Taiwan",
    "country_215": "Tadjikistan",
    "country_216": "Tanzanië",
    "country_217": "Sjabloon:Landdata SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "Die Bahamas",
    "country_220": "Die Comore",
    "country_221": "Die Filippyne",
    "country_222": "Timor-Leste (Oos-Timor)",
    "country_223": "Togo",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad en Tobago",
    "country_227": "Tunisië",
    "country_228": "Turkye",
    "country_229": "Turkmenistan",
    "country_230": "Turks- en Caicos-eilande",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Oekraïne",
    "country_234": "Verenigde Arabiese Emirate",
    "country_235": "Verenigde State klein afgeleë eilande",
    "country_236": "Verenigde State van Amerika (VSA)",
    "country_237": "Verenigde State Maagde-eilande",
    "country_238": "Uruguay",
    "country_239": "Oesbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikaanstad (Die Heilige Stoel)",
    "country_242": "Venezuela",
    "country_243": "Viëtnam",
    "country_244": "Wallis en Futuna",
    "country_245": "Wes-Sahara",
    "country_246": "Jemen",
    "country_247": "Zambië",
    "country_248": "Zimbabwe",
    "google_login": "Meld aan met Google",
    "State": "Staat",
    "Activation_code": "Aktiverings kode",
    "Question": "Lys al die programme wat jy aangemeld het",
    "Copy_complete": "Kopie voltooi",
    "footer": "Kopiereg © 2024 MindOnMap Studio. Alle regte voorbehou.",
    "change_password_success": "Wagwoord suksesvol verander",
    "successful_login_title": "Aanmelding Sukses",
    "product_page": "Produk Bladsy >>",
    "successful_login_info": "Aanmelding voltooi. Maak asseblief die huidige bladsy toe en gaan terug na die oorspronklike oortjie om voort te gaan met die volgende proses. Die huidige bladsy sal outomaties binne 5 sekondes gesluit word. As outomatiese sluiting of sluiting deur op die \"Klaar\"-knoppie te klik misluk, maak asseblief hierdie oortjie direk toe.",
    "successful_login_info_firefox": "Aanmelding voltooi. Maak asseblief die huidige bladsy toe en gaan terug na die oorspronklike oortjie om voort te gaan met die volgende proses.",
    "my_account": "My rekening",
    "my_history": "My Geskiedenis",
    "remove_watermark": "Verwyder watermerk",
    "no_history": "Geen geskiedenis nie",
    "history_all": "Kies alles",
    "history_open": "Maak oop",
    "history_down": "Aflaai",
    "history_delete": "Vee uit",
    "history_clear": "Vee ongeldig uit",
    "images": "beeld(e)",
    "use_this_function": "Gebruik hierdie produk >>",
    "hd_downloading": "HD oorspronklike beelde aflaai voordele:",
    "lifetimeRemaining": "Lewenslange-oorblywende",
    "Remaining": "Oorblywende",
    "email_verification": "E-posverifikasie",
    "email_verification_info": "Ons het die verifikasieboodskap na jou e-pos gestuur <span class=\"email\"></span>, en voltooi asseblief die verifikasie. Nadat dit geverifieer is, sal die voordele outomaties gesinkroniseer word.",
    "wrong_email": "Verkeerde e-posadres?",
    "click_here_to_modify": "Klik hier om te wysig",
    "get_more_help": "Kry meer hulp?",
    "click_here": "Klik hier",
    "email_verification_info_success": "Baie geluk met die verifikasie van jou e-posrekening.",
    "email_verification_info_error": "Verifikasie het misluk as gevolg van die skakel wat verval het.",
    "registration_succeeded": "Registrasie geslaag",
    "registration_succeeded_info_1": "Baie geluk! Jy het suksesvol geregistreer. Ons het die verifikasieboodskap na jou e-pos gestuur <span class=\"email\"></span>, en voltooi asseblief die verifikasie om die voordele van hierdie e-pos te gebruik.",
    "registration_succeeded_info_2": "Klik \"Klaar\" om terug te keer na die tuisblad en gebruik hierdie produk.",
    "registration_succeeded_info_3": "Klik \"Klaar\" om die huidige bladsy toe te maak en terug te keer na die tuisblad vir die volgende bewerkings. As sluiting misluk, maak asseblief die oortjie met die hand toe.",
    "verify_email": "verifieer e-pos",
    "registered_email_not_verified": "Die geregistreerde e-pos is nie geverifieer nie, verifieer dit asseblief dadelik.",
    "email_verification_time_1": "Het jy nie 'n verifikasie-e-pos ontvang nie?",
    "email_verification_time_2": "Na",
    "email_verification_time_3": "Klik hier om dit weer te stuur",
    "error_26301": "Foutkode: 26301, kontak ons asseblief",
    "error_26302": "Foutkode: 26302, kontak ons asseblief",
    "error_26303": "E-posformaatfout (foutkode: 26303). Voer dit asseblief weer in",
    "error_26304": "Wagwoord bo 8 karakters word aanbeveel (foutkode: 26304)",
    "error_26305": "Reuqest het misluk (foutkode: 26305). Probeer dit asseblief weer",
    "error_26306": "E-pos is geregistreer, <a href=\"https://account.mindonmap.com/login/\">gaan asseblief om aan te meld</a>",
    "error_26307": "Reuqest het misluk (foutkode: 26307). Probeer dit asseblief weer",
    "error_26308": "Reuqest het misluk (foutkode: 26308). Probeer dit asseblief weer",
    "error_26401": "Foutkode: 26401, probeer dit asseblief weer",
    "error_26402": "E-pos is geverifieer (foutkode: 26402), probeer dit asseblief weer",
    "error_26403": "Reuqest het misluk (foutkode: 26403). Probeer dit asseblief weer",
    "error_26404": "Versoek het misluk (foutkode: 26404). Probeer dit asseblief weer",
    "error_26501": "Foutkode: 26501, kontak ons asseblief",
    "error_26502": "Foutkode: 26502, kontak ons asseblief",
    "error_26503": "E-posformaatfout (foutkode: 26503). Voer dit asseblief weer in",
    "error_26504": "Reuqest het misluk (foutkode: 26504). Probeer dit asseblief weer",
    "error_26505": "E-pos is nie geregistreer nie, asseblief <a href=\"https://account.mindonmap.com/register/\">registreer dit eers</a>",
    "error_26506": "E-pos is geverifieer.",
    "error_26507": "Reuqest het misluk (foutkode: 26507). Probeer dit asseblief weer",
    "error_26508": "Verifikasie misluk (foutkode: 26508), probeer dit asseblief weer",
    "error_26509": "Versoek het misluk (foutkode: 26509), probeer dit asseblief weer",
    "error_26510": "Foutkode: 26510, kontak ons asseblief",
    "error_26601": "Foutkode: 26601, kontak ons asseblief",
    "error_26602": "Foutkode: 26602, kontak ons asseblief",
    "error_26603": "Reuqest het misluk (foutkode: 26603). Probeer dit asseblief weer",
    "error_26604": "Foutkode: 26604, kontak ons asseblief",
    "error_26605": "Foutkode: 26605, kontak ons asseblief",
    "error_26701": "Foutkode: 26701, kontak ons asseblief",
    "error_26702": "Reuqest het misluk (foutkode: 26702). Probeer dit asseblief weer",
    "error_26703": "Foutkode: 26703, kontak ons asseblief",
    "error_26704": "Foutkode: 26704, kontak ons asseblief",
    "error_26705": "Wag vir aanmelding (foutkode: 26705). Probeer dit asseblief weer",
    "no_cookie": "Jy het die Blokkeer alle koekies-funksie in jou blaaier aangeskakel, so jy kan nie aanmeld nie. Gaan asseblief na Instellings om die blokkie van Laat alle koekies toe te merk.",
    "error_26801": "Foutkode: 26801, kontak ons asseblief",
    "error_26802": "Foutkode: 26802, kontak ons asseblief",
    "error_26803": "Versoek het misluk (foutkode: 26803). Probeer dit asseblief weer",
    "error_26804": "Versoek het misluk (foutkode: 26804). Probeer dit asseblief weer",
    "error_order": "Versoek het misluk (foutkode: 27098), probeer dit asseblief weer!",
    "error_order1": "Die bestellingsnavraag is onvolledig (foutkode: ",
    "error_order2": "）, verfris asseblief en probeer dit weer.",
    "modify_email_title": "Wysig e-pos",
    "modify_email_info": "Jy kan die gewysigde e-pos gebruik om by jou rekening aan te meld.",
    "images_per": "Beelde per",
    "error_26101": "Fout: 26101. Kontak ons asseblief",
    "error_26102": "Fout: 26102. Kontak ons asseblief",
    "error_26103": "Versoek misluk (foutkode:26103). Probeer dit asseblief weer",
    "error_26104": "Foutkode: 26104, probeer dit asseblief weer",
    "error_26105": "Foutkode: 26105, probeer dit asseblief weer",
    "error_26106": "Kon nie uitvee nie (foutkode: 26106). Probeer dit asseblief weer",
    "error_26201": "Fout: 26201. Kontak ons asseblief",
    "error_26202": "Versoek misluk (foutkode:26202). Probeer dit asseblief weer",
    "error_26001": "Fout: 26001. Kontak ons asseblief",
    "error_26002": "Fout: 26002. Kontak ons asseblief",
    "error_26003": "Fout: 26003. Kontak ons asseblief",
    "error_26004": "Fout: 26004. Kontak ons asseblief",
    "error_26005": "Versoek misluk (foutkode:26005). Probeer dit asseblief weer",
    "error_26006": "Foutkode: 26006, probeer dit asseblief weer",
    "error_26008": "Fout: 26008. Kontak ons asseblief",
    "go_to_the_home_page": "Gaan na die tuisblad",
    "error_27101": "Versoek het misluk (foutkode: 27101). Probeer asseblief weer ist",
    "error_27201": "Foutkode: 27201, kontak ons asseblief",
    "error_27202": "Foutkode: 27202, probeer dit asseblief weer",
    "error_27203": "Versoek het misluk (foutkode: 27203). Probeer dit asseblief weer",
    "error_27204": "Ongeldige kode (foutkode: 27204).",
    "error_27205": "Versoek het misluk (foutkode: 27205). Probeer dit asseblief weer",
    "error_27206": "Versoek het misluk (foutkode: 27206). Probeer dit asseblief weer",
    "error_27207": "Versoek het misluk (foutkode: 27207). Probeer dit asseblief weer",
    "no_history_found": "Jy het geen instrument gebruik nie! <a href=\"/\">Verfris</a> of <a href=\"https://www.mindonmap.com/\">Gaan na die amptelike webwerf</a>",
    "error_25301": "Fout: 25301. Kontak ons asseblief",
    "error_25302": "Fout: 25302. Kontak ons asseblief",
    "error_25303": "Versoek misluk (foutkode: 25303). Probeer dit asseblief weer",
    "error_25304": "Versoek misluk (foutkode: 25304). Probeer dit asseblief weer",
    "error_25305": "Versoek misluk (foutkode: 25305). Probeer dit asseblief weer",
    "error_25306": "Versoek misluk (foutkode: 25306). Probeer dit asseblief weer",
    "image_upscaler_p": "Beeld sonder watermerk aflaai voordele:",
    "Available_for": "Beskikbaar vir:",
    "credit_per": "%s krediet(e) per HD-prent",
    "still_valid": "Gekoopte plan(ne) is steeds geldig",
    "credit": "krediet(e)",
    "pc_3rd_info": "Teken suksesvol aan. Gaan asseblief na die aansoek vir verdere operasie.",
    "use_online": "Gebruik die aanlyn diens",
    "use_download": "Gebruik die lessenaarprogram",
    "use_immediately": "Gebruik Onmiddellik",
    "Use_in_browser": "Gebruik in blaaier",
    "win_desktop": "Vensters",
    "Mac_desktop": "Mac",
    "credits_per": "{%} krediete per maand",
    "expire": "Vervaltyd:",
    "viewDetails": "Sien besonderhede",
    "viewHistory": "Bekyk geskiedenisvoordele >>",
    "viewDetailsInfo": "Kennisgewing: Indien die intekeningvoordele binne 7 dae na verstryking hernu word, kan die ongebruikte voordele steeds gebruik word. Die vervaltyd sal ook outomaties opgedateer word na die vervaltyd van die nuwe intekening. As daar geen nuwe intekening is na 7 dae van vervaltyd nie, sal alle verval voordele uitgewis word.",
    "connect_account": "Bind e-pos aan jou rekening",
    "connect_account_info": "Nadat u gebind is, kan u met hierdie e-posadres aanmeld.",
    "connect_now": "Bind nou",
    "no_email_bind": "Geen e-posbinding nie",
    "bind_email": "Bind e-pos",
    "connect_your_email_placeholder": "Voer asseblief jou e-posadres in",
    "bind_an_email": "Bind 'n e-pos",
    "bind_info": "Jy het suksesvol aangemeld. Bind asseblief 'n e-pos aan jou rekening om jou voordele te aktiveer.",
    "bind_later": "Bind later",
    "hi": "Hallo, %s!",
    "Personal_Information": "Persoonlike inligting",
    "Access": "Toegang",
    "Subscription_Plan": "(Intekeningplan)",
    "No_orders": "Geen bestellings gevind nie.",
    "No_data": "Geen data",
    "Featured_Products": "Uitgestalde produkte",
    "More_Products": "Meer produkte",
    "Free_Download": "Gratis aflaai",
    "Get_Started": "Begin",
    "Subscribe": "Teken in",
    "Verified": "Geverifieer",
    "back_to_account_center": "Terug na Rekeningsentrum",
    "success": "Sukses!",
    "successfully": "Jy het 'n rekening suksesvol geregistreer.",
    "Continue": "Aanhou",
    "Already": "Reeds 'n rekening?",
    "loading_verification": "Kontroleer tans verifikasiestatus...",
    "email_no_verification": "Jammer, die geregistreerde e-posadres is nie geverifieer nie. Voltooi asseblief die verifikasie volgens die bogenoemde instruksies en klik weer op die \"Klaar\"-knoppie om die registrasie te voltooi.",
    "will_expire_after": "Sal daarna verval",
    "hours": "ure",
  },
  "ar": {
    "overtime": "رمز الخطأ: {%}، يرجى إعادة المحاولة",
    "isnetwork": "خطأ في الإنترنت. يرجى التحقق وإعادة المحاولة",
    "email_placeholder": "بريد إلكتروني",
    "email_empty": "الرجاء إدخال البريد الإلكتروني",
    "email_not_valid": "البريد الإلكتروني غير صالح",
    "email_not_valid_1": "الرجاء إدخال البريد الإلكتروني الخاص بك",
    "email_not_valid_2": "البريد الإلكتروني غير صالح، يرجى استخدام عنوان مختلف.",
    "email_not_valid_3": "لا يوجد إدخال البريد الإلكتروني",
    "password_placeholder": "كلمة المرور",
    "password_empty": "يرجى إدخال كلمة السر",
    "password_not_valid": "الحساب أو كلمة المرور غير صحيحة",
    "password_not_valid_1": "مطلوب كلمة مرور أعلى من 8 أحرف",
    "password_not_valid_2": "الرجاء إنشاء كلمة مرور",
    "password_placeholder_1": "إنشاء كلمة مرورك",
    "password_placeholder_2": "أكد رقمك السري",
    "password_placeholder_3": "إنشاء كلمة مرور جديدة",
    "password_placeholder_4": "تأكيد كلمة المرور الجديدة",
    "password_placeholder_5": "إدخال كلمة المرور القديمة",
    "copy_password_empty": "الرجاء تأكيد كلمة المرور",
    "copy_password_not_valid": "يرجى التأكد من صحة كلمة المرور الخاصة بك",
    "copy_passwords_inconsistent": "تأكيد كلمة المرور الخاصة بك غير متطابق",
    "code_empty": "الرجاء إدخال رمز التحقق",
    "code_not_valid": "رمز التحقق غير صالح",
    "code_placeholder": "رمز التحقق",
    "not_received_code": "إذا لم يتلق صندوق البريد الخاص بك رمز التحقق لفترة طويلة، فيرجى الحصول على رمز التحقق مرة أخرى.",
    "get_first_code": "يرجى الحصول على رمز التحقق أولا.",
    "last_name_placeholder": "الرجاء إدخال اسمك الأخير",
    "first_name_placeholder": "يرجى ادخال الاسم الاول",
    "address_placeholder": "الرجاء إدخال عنوانك",
    "no_code_text": "لقد أرسلنا رمز التحقق. الرجاء إدخال الرمز الخاص بك. <span class='tips'>ألم تتلق الرمز؟",
    "no_code_text_1": "1. يرجى التأكد من صحة عنوان البريد الإلكتروني وأنه يمكنه استقبال رسائل البريد الإلكتروني.",
    "no_code_text_2": "2. بالنسبة إلى البريد الإلكتروني الذي يرسله النظام تلقائيًا، فقد يتم وضع علامة عليه كبريد عشوائي أو بريد إلكتروني غير هام. يرجى التحقق مما إذا كان البريد الإلكتروني موجودًا في مجلد سلة المهملات.",
    "no_code_text_3": "3. لا تستطيع حل مشكلتك؟ ",
    "no_code_text_3_span": "ثم انقر هنا للاتصال بنا.",
    "order_no": "لم تقم بشراء أي منتج، إذا كان لديك أي سؤال، يرجى <a href=\"https://www.mindonmap.com/contact-us/\">الاتصال بنا</a>.",
    "error_24901": "الحساب الجاري لا يحتوي على بريد إلكتروني مرتبط، ولا يمكن العثور على الطلبات. يرجى الاتصال بالبريد الإلكتروني.",
    "user_guide": "دليل المستخدم >>",
    "download": "تحميل",
    "order_number": "رقم الأمر:",
    "Refund": "استرداد",
    "Disabled": "عاجز",
    "Normal": "طبيعي",
    "Modify": "يُعدِّل",
    "Modify_1": "تعديل >>",
    "Connect": "يتصل",
    "unlink_success": "تم إلغاء الارتباط بنجاح",
    "connect_success": "الاتصال بنجاح",
    "feedback_title": "شكرا لملاحظاتك. يرجى ترك مشكلتك وسوف نقوم بالرد عليك خلال 24 ساعة.",
    "feedback_thank_you": "شكرًا لك!<br />تم إرسال تعليقاتك بنجاح.",
    "feedback_email": "أدخل عنوان بريدك الإلكتروني هنا!",
    "feedback_content": "اترك أي مشكلة أو اقتراح واجهتك هنا.",
    "feedback_submit": "يُقدِّم",
    "form_contents": "لم تقم بإدخال أي وصف. الرجاء إدخاله وإرساله مرة أخرى.",
    "old_password": "الرجاء إدخال كلمة المرور القديمة",
    "new_password": "الرجاء إنشاء كلمة مرور جديدة",
    "old_new_password": "لا يمكن أن تكون كلمة المرور الجديدة هي نفسها القديمة",
    "incorrect_password": "كلمة سر خاطئة",
    "delete_no": "احذف الان",
    "Caps": "الحروف الكبيرة مفعلة",
    "Get": "يحصل",
    "Done": "منتهي",
    "error_20001": "خطأ: 20001. الرجاء تسجيل الدخول مرة أخرى.",
    "error_20002": "خطأ: 20002. الرجاء تسجيل الدخول مرة أخرى.",
    "error_20003": "خطأ: 20003. الرجاء تسجيل الدخول مرة أخرى.",
    "error_20004": "فشل الطلب (رمز الخطأ: 20004). من فضلك حاول مرة أخرى.",
    "error_20005": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20005). الرجاد الدخول على الحساب من جديد.",
    "error_20006": "فشل الطلب (رمز الخطأ: 20006). من فضلك حاول مرة أخرى.",
    "error_20007": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20007). الرجاد الدخول على الحساب من جديد.",
    "error_20008": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20008). الرجاد الدخول على الحساب من جديد.",
    "error_20009": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20009). الرجاد الدخول على الحساب من جديد.",
    "error_20101": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20101)",
    "error_20102": "البريد الإلكتروني غير صالح (رمز الخطأ: 20102)",
    "error_20103": "فشل الطلب (رمز الخطأ: 20103). من فضلك حاول مرة أخرى",
    "error_20104": "البريد الإلكتروني قيد الاستخدام بالفعل، يرجى <a href=\"https://account.mindonmap.com/login/\">تسجيل الدخول</a> أو التسجيل باستخدام بريد إلكتروني جديد",
    "error_20105": "فشل الطلب (رمز الخطأ: 20105). من فضلك حاول مرة أخرى",
    "error_20106": "فشل إرسال البريد الإلكتروني، يرجى إعادة المحاولة",
    "error_20201": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20201)",
    "error_20202": "الرجاء إدخال كلمة المرور الخاصة بك (رمز الخطأ: 20202)",
    "error_20203": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20203)",
    "error_20204": "البريد الإلكتروني غير صالح (رمز الخطأ: 20204)",
    "error_20205": "مطلوب كلمة مرور تزيد عن 8 أحرف (رمز الخطأ: 20205)",
    "error_20206": "فشل الطلب (رمز الخطأ: 20206). من فضلك حاول مرة أخرى",
    "error_20207": "رمز التحقق غير صالح",
    "error_20208": "فشل الطلب (رمز الخطأ: 20208). من فضلك حاول مرة أخرى",
    "error_20209": "فشل الطلب (رمز الخطأ: 20209). من فضلك حاول مرة أخرى",
    "error_20301": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20301)",
    "error_20302": "خطأ: 20302. يرجى الاتصال بنا",
    "error_20303": "البريد الإلكتروني غير صالح (رمز الخطأ: 20303)",
    "error_20304": "فشل الطلب (رمز الخطأ: 20304). من فضلك حاول مرة أخرى",
    "error_20305": "الحساب غير موجود. الرجاء إعادة إدخاله أو <a href=\"https://account.mindonmap.com/register/\">إنشاءه</a> أولاً.",
    "error_20306": "لا توجد كلمة مرور حتى الآن. استخدم <a href=\"https://account.mindonmap.com/passwordless-login/\">تسجيل الدخول بدون كلمة مرور</a> أو <a href=\"https://account.mindonmap.com/create-password/\"> قم بتعيين كلمة المرور</a> وقم بتسجيل الدخول.",
    "error_20308": "فشل الطلب (رمز الخطأ: 20308). من فضلك حاول مرة أخرى",
    "error_20401": "فشل تسجيل الخروج (رمز الخطأ: 20401). من فضلك حاول مرة أخرى",
    "error_20501": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20501)",
    "error_20502": "البريد الإلكتروني غير صالح (رمز الخطأ: 20502)",
    "error_20503": "فشل الطلب (رمز الخطأ: 20503). من فضلك حاول مرة أخرى",
    "error_20504": "فشل في إرسال البريد الإلكتروني. من فضلك حاول مرة أخرى.",
    "error_20601": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20601)",
    "error_20602": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20602)",
    "error_20603": "البريد الإلكتروني غير صالح (رمز الخطأ: 20603)",
    "error_20604": "فشل الطلب (رمز الخطأ: 20604). من فضلك حاول مرة أخرى",
    "error_20606": "فشل الطلب (رمز الخطأ: 20606). من فضلك حاول مرة أخرى",
    "error_20607": "فشل الطلب (رمز الخطأ: 20607). من فضلك حاول مرة أخرى",
    "error_20608": "فشل الطلب (رمز الخطأ: 20608). من فضلك حاول مرة أخرى",
    "error_20701": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20701)",
    "error_20702": "البريد الإلكتروني غير صالح (رمز الخطأ: 20702)",
    "error_20703": "فشل الطلب (رمز الخطأ: 20703). من فضلك حاول مرة أخرى",
    "error_20704": "الحساب غير موجود. الرجاء إعادة إدخاله أو <a href=\"https://account.mindonmap.com/register/\">إنشاءه</a> أولاً.",
    "error_20705": "فشل الطلب (رمز الخطأ: 20705). من فضلك حاول مرة أخرى",
    "error_20706": "فشل في إرسال البريد الإلكتروني. من فضلك حاول مرة أخرى",
    "error_20801": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20801)",
    "error_20802": "خطأ: 20802. برجاء الاتصال بنا",
    "error_20803": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20803)",
    "error_20804": "البريد الإلكتروني غير صالح (رمز الخطأ: 20804)",
    "error_20805": "مطلوب كلمة مرور تزيد عن 8 أحرف (رمز الخطأ: 20805)",
    "error_20806": "فشل الطلب (رمز الخطأ: 20806). من فضلك حاول مرة أخرى",
    "error_20808": "فشل الطلب (رمز الخطأ: 20808). من فضلك حاول مرة أخرى",
    "error_20901": "فشل الطلب (رمز الخطأ: 20901). من فضلك حاول مرة أخرى",
    "error_20902": "فشل الطلب (رمز الخطأ: 20902). من فضلك حاول مرة أخرى",
    "error_21000": "تم حفظ التغييرات",
    "error_21001": "لم يتم تقديم أي معلومات (رمز الخطأ: 21001)",
    "error_21002": "فشل الطلب (رمز الخطأ: 21002). من فضلك حاول مرة أخرى",
    "error_21101": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 21101)",
    "error_21102": "البريد الإلكتروني غير صالح (رمز الخطأ: 21102)",
    "error_21103": "فشل الطلب (رمز الخطأ: 21103)، يرجى إعادة المحاولة مرة أخرى",
    "error_21104": "البريد الإلكتروني متصل بالفعل، يرجى استخدام بريد إلكتروني جديد",
    "error_21105": "فشل الطلب (رمز الخطأ: 21105)، يرجى إعادة المحاولة مرة أخرى",
    "error_21106": "فشل في إرسال البريد الإلكتروني. من فضلك حاول مرة أخرى",
    "error_21201": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 21201)",
    "error_21202": "الرجاء إدخال رمز التحقق (رمز الخطأ: 21202)",
    "error_21203": "البريد الإلكتروني غير صالح (رمز الخطأ: 21203)",
    "error_21204": "خطأ: 21204. يرجى الاتصال بنا",
    "error_21205": "خطأ: 21205. برجاء الاتصال بنا",
    "error_21206": "مطلوب كلمة مرور تزيد عن 8 أحرف (رمز الخطأ: 21206)",
    "error_21207": "فشل الطلب (رمز الخطأ: 21207). من فضلك حاول مرة أخرى",
    "error_21209": "فشل الطلب (رمز الخطأ: 21209). من فضلك حاول مرة أخرى",
    "error_21301": "الرجاء إدخال كلمة المرور القديمة (رمز الخطأ: 21301)",
    "error_21302": "الرجاء إنشاء كلمة مرور جديدة (رمز الخطأ: 21302)",
    "error_21303": "لا يمكن أن تكون كلمة المرور الجديدة هي نفسها القديمة. (خطأ: 21303)",
    "error_21304": "مطلوب كلمة مرور تزيد عن 8 أحرف (رمز الخطأ: 21304)",
    "error_21306": "فشل الطلب (رمز الخطأ: 21306). من فضلك حاول مرة أخرى",
    "error_21402": "فشل الطلب (رمز الخطأ: 21402). من فضلك حاول مرة أخرى",
    "error_21403": "فشل في إرسال رمز التحقق. يرجى إعادة إرساله",
    "error_21500": "تم حذف الحساب",
    "error_21501": "الرجاء إدخال رمز التحقق (رمز الخطأ: 21501)",
    "error_21502": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 21502). الرجاد الدخول على الحساب من جديد.",
    "error_21503": "فشل الطلب (رمز الخطأ: 21503). من فضلك حاول مرة أخرى",
    "error_21505": "فشل الطلب (رمز الخطأ: 21505)، يرجى إعادة المحاولة",
    "error_21601": "خطأ: 20601. يرجى الاتصال بنا",
    "error_21602": "التحقق غير صالح (خطأ: 20602). من فضلك حاول مرة أخرى.",
    "error_21603": "خطأ: 20603. يرجى المحاولة مرة أخرى",
    "error_21604": "فشل الطلب (رمز الخطأ: 21604). من فضلك حاول مرة أخرى",
    "error_21606": "فشل الطلب (رمز الخطأ: 21606). من فضلك حاول مرة أخرى",
    "error_21611": "فشل الطلب (رمز الخطأ: 21611). من فضلك حاول مرة أخرى",
    "error_21801": "خطأ: 21801. برجاء الاتصال بنا",
    "error_21802": "فشل الطلب (خطأ: 21802). من فضلك حاول مرة أخرى",
    "error_21803": "خطأ: 21803. الرجاء المحاولة مرة أخرى",
    "error_21804": "فشل الطلب (رمز الخطأ: 21804). من فضلك حاول مرة أخرى",
    "error_21806": "خطأ: 21806. الرجاء المحاولة مرة أخرى",
    "error_21807": "خطأ: 21807. برجاء الاتصال بنا",
    "error_21808": "خطأ: 21808. برجاء الاتصال بنا",
    "error_21809": "خطأ: 21809. برجاء الاتصال بنا",
    "error_21810": "خطأ: 21810. يرجى الاتصال بنا",
    "error_21811": "خطأ: 21811. برجاء الاتصال بنا",
    "error_21812": "خطأ: 21812. برجاء الاتصال بنا",
    "error_21813": "فشل الطلب (رمز الخطأ: 21813). من فضلك حاول مرة أخرى",
    "error_21814": "خطأ: 21814. برجاء الاتصال بنا",
    "error_21815": "فشل الطلب (رمز الخطأ: 21815). من فضلك حاول مرة أخرى",
    "error_21816": "خطأ: 21816. برجاء الاتصال بنا",
    "error_21817": "خطأ: 21817. برجاء الاتصال بنا",
    "error_21818": "خطأ: 21818. برجاء الاتصال بنا",
    "error_21819": "فشل الطلب (رمز الخطأ: 21819). من فضلك حاول مرة أخرى",
    "error_21820": "خطأ: 21820. برجاء الاتصال بنا",
    "error_21821": "خطأ: 21821. برجاء الاتصال بنا",
    "error_21822": "خطأ: 21822. برجاء الاتصال بنا",
    "error_21823": "فشل الطلب (رمز الخطأ: 21823). من فضلك حاول مرة أخرى",
    "error_21824": "فشل الطلب (رمز الخطأ: 21824). من فضلك حاول مرة أخرى",
    "error_21825": "فشل الطلب (رمز الخطأ: 21825). من فضلك حاول مرة أخرى",
    "error_21826": "فشل الطلب (رمز الخطأ: 21826). من فضلك حاول مرة أخرى",
    "error_21901": "خطأ: 21901. يرجى الاتصال بنا",
    "error_21902": "فشل الطلب (رمز الخطأ: 21902). من فضلك حاول مرة أخرى",
    "error_21903": "لقد تغيرت حالة الحساب (رمز الخطأ: 21903)، يرجى تحديث الصفحة والمحاولة مرة أخرى",
    "error_21904": "خطأ: 21904. الرجاء المحاولة مرة أخرى",
    "error_21905": "خطأ: 21905. الرجاء المحاولة مرة أخرى",
    "error_21906": "فشل الطلب (رمز الخطأ: 21906). من فضلك حاول مرة أخرى",
    "error_21907": "تم ربط حساب Google بحساب آخر",
    "error_21908": "فشل الطلب (رمز الخطأ: 21908). من فضلك حاول مرة أخرى",
    "error_22001": "فشل الطلب (رمز الخطأ: 22001). من فضلك حاول مرة أخرى",
    "error_22002": "فشل إلغاء الارتباط بدون تسجيل دخول إضافي",
    "error_22003": "فشل الطلب (رمز الخطأ: 22003). من فضلك حاول مرة أخرى",
    "error_22101": "خطأ: 22101. يرجى الاتصال بنا",
    "error_22102": "لقد تغيرت حالة الحساب (رمز الخطأ: 22102)، يرجى تحديث الصفحة والمحاولة مرة أخرى",
    "error_22103": "فشل الطلب (رمز الخطأ: 22103). من فضلك حاول مرة أخرى",
    "error_22104": "لقد تغيرت حالة الحساب (رمز الخطأ: 22104)، يرجى تحديث الصفحة والمحاولة مرة أخرى",
    "error_22105": "خطأ: 22105. الرجاء المحاولة مرة أخرى",
    "error_22106": "خطأ: 22106. الرجاء المحاولة مرة أخرى",
    "error_22107": "خطأ: 22107. يرجى الاتصال بنا",
    "error_22108": "فشل الطلب (رمز الخطأ: 22108). من فضلك حاول مرة أخرى",
    "error_22201": "خطأ: 22201. يرجى الاتصال بنا",
    "error_22202": "التحقق غير صالح (خطأ: 22202). من فضلك حاول مرة أخرى.",
    "error_22203": "خطأ: 22203. الرجاء المحاولة مرة أخرى\"",
    "error_22204": "فشل الطلب (رمز الخطأ: 22204). من فضلك حاول مرة أخرى",
    "error_22206": "فشل الطلب (رمز الخطأ: 22206). من فضلك حاول مرة أخرى",
    "error_22401": "خطأ: 22401. يرجى الاتصال بنا",
    "error_22402": "التحقق غير صالح (خطأ: 22402). من فضلك حاول مرة أخرى.",
    "error_22403": "خطأ: 22403. الرجاء المحاولة مرة أخرى",
    "error_22404": "فشل الطلب (رمز الخطأ: 22404). من فضلك حاول مرة أخرى",
    "error_22405": "تم ربط حساب الفيسبوك ببريد إلكتروني آخر",
    "error_22406": "خطأ: 22406. الرجاء المحاولة مرة أخرى",
    "error_22407": "خطأ: 22407. يرجى الاتصال بنا",
    "error_22408": "خطأ: 22408. يرجى الاتصال بنا",
    "error_22409": "خطأ: 22409. يرجى الاتصال بنا",
    "error_22410": "خطأ: 224010. يرجى الاتصال بنا",
    "error_22411": "خطأ: 224011. يرجى الاتصال بنا",
    "error_22412": "خطأ: 224012. يرجى الاتصال بنا",
    "error_22413": "خطأ: 22413. يرجى الاتصال بنا",
    "error_22414": "فشل الطلب (رمز الخطأ: 22414). من فضلك حاول مرة أخرى",
    "error_22415": "خطأ: 22415. يرجى الاتصال بنا",
    "error_22416": "خطأ: 22416. يرجى الاتصال بنا",
    "error_22417": "خطأ: 22417. يرجى الاتصال بنا",
    "error_22418": "فشل الطلب (رمز الخطأ: 22418). من فضلك حاول مرة أخرى",
    "error_22419": "خطأ: 22419. يرجى الاتصال بنا",
    "error_22420": "خطأ: 22420. يرجى الاتصال بنا",
    "error_22421": "خطأ: 22421. يرجى الاتصال بنا",
    "error_22422": "فشل الطلب (رمز الخطأ: 22422). من فضلك حاول مرة أخرى",
    "error_22423": "فشل الطلب (رمز الخطأ: 22423). من فضلك حاول مرة أخرى",
    "error_22424": "فشل الطلب (رمز الخطأ: 22424). من فضلك حاول مرة أخرى",
    "error_22425": "فشل الطلب (رمز الخطأ: 22425). من فضلك حاول مرة أخرى",
    "error_20098": "رمز الخطأ: 20098. إذا كنت تتصفح في الوضع الخاص، فيرجى التبديل إلى الوضع العادي والمحاولة مرة أخرى.",
    "error_22298": "فشل طلب Google (الخطأ: 22298). من فضلك حاول مرة أخرى.",
    "error_22498": "فشل طلب Facebook (خطأ: 22498). من فضلك حاول مرة أخرى.",
    "error_24902": "فشل الطلب (رمز الخطأ: 24902). من فضلك حاول مرة أخرى",
    "error_24903": "فشل الطلب (رمز الخطأ: 24903). من فضلك حاول مرة أخرى",
    "error_24904": "فشل الطلب (رمز الخطأ: 24904). من فضلك حاول مرة أخرى",
    "error_24905": "فشل الطلب (رمز الخطأ: 24905). من فضلك حاول مرة أخرى",
    "login_title": "قم بتسجيل الدخول إلى MindOnMap",
    "log_in": "تسجيل الدخول",
    "no_account": "لا حساب؟",
    "create_it": "اصنعها",
    "or_log_in_with": "أو قم بتسجيل الدخول باستخدام",
    "passwordless_login": "تسجيل الدخول بدون كلمة مرور",
    "log_in_done": "تسجيل الدخول تم",
    "three_rd_account_connect_info": "تهانينا! لقد قمت بتسجيل الدخول بنجاح. يمكنك الآن ربط حساب بريد إلكتروني واحد يُستخدم لتسجيل الدخول في المستقبل.",
    "see_my_account": "انظر حسابي",
    "three_rd_login_merge_account": "تم تسجيل عنوان البريد الإلكتروني لحساب الطرف الثالث، هل تريد الاتصال وتسجيل الدخول باستخدام عنوان البريد الإلكتروني هذا مباشرة؟",
    "connect_log_in": "الاتصال وتسجيل الدخول",
    "create_an_account": "إنشاء حساب",
    "back_to_log_in": "العودة لتسجيل الدخول",
    "create_password": "إنشاء كلمة المرور",
    "create_now": "اصنع الان",
    "password_login_subtitle": "تسجيل الدخول بدون كلمة مرور مع البريد الإلكتروني",
    "account_login": "تسجل الدخول",
    "rights": "من خلال إنشاء هذا الحساب، فإنك توافق على <a href=\"https://www.mindonmap.com/terms-and-conditions/\">شروط الخدمة</a> و<a href=\"https://www.mindonmap.com/privacy-policy/\">سياسة الخصوصية</a>",
    "passwordless_login_done": "تم تسجيل الدخول بدون كلمة مرور",
    "passwordless_login_info": "تهانينا، لقد أكملت تسجيل الدخول بدون كلمة مرور بنجاح. يمكنك إنشاء كلمة مرور لهذا الحساب وتسجيل الدخول باستخدام الحساب وكلمة المرور في المستقبل. <a href=\"/create-password\" style=\"display: initial;\">إنشاء الآن</a>",
    "sign_up": "اشتراك",
    "register_info": "أنشئ حسابك",
    "reset_now": "إعادة الضبط الآن",
    "forgot_password": "هل نسيت كلمة السر",
    "reset_password_subtitle": "استخدم البريد الإلكتروني الخاص بحسابك لإعادة تعيين كلمة المرور",
    "plan_products": "الخطط والمنتجات",
    "nick_name": "اسم المستخدم:",
    "email": "بريد إلكتروني:",
    "my_products": "منتجاتي",
    "my_orders": "طلباتي",
    "unlink": "إلغاء الارتباط",
    "link": "وصلة",
    "connected_accounts": "الحسابات المتصلة",
    "last_name": "اسم العائلة:",
    "first_name": "الاسم الأول:",
    "Gender": "جنس:",
    "Birth": "ولادة:",
    "Month": "شهر",
    "Year": "سنة",
    "Country_Region": "البلد/المنطقة:",
    "Address": "عنوان:",
    "Save": "يحفظ",
    "Date": "تاريخ",
    "Male": "ذكر",
    "Female": "أنثى",
    "Unspecified": "غير محدد",
    "Security": "حماية",
    "change_password": "تغيير كلمة المرور",
    "change_now": "تغيير الآن",
    "connect_email": "ربط البريد الإلكتروني",
    "delete_account": "حذف الحساب",
    "delete_account_info": "عندما يتم حذف حسابك، سيتم حذف جميع البيانات الموجودة في \"حسابي\" المرتبطة بحسابك نهائيًا، وقد لا تتمكن من استعادتها. ننصحك بالتحرك بحذر.",
    "Delete": "يمسح",
    "Logout": "تسجيل خروج",
    "my_profile": "ملفي",
    "guides_faqs": "الأدلة والأسئلة الشائعة",
    "More": "أكثر",
    "guides": "خطوط إرشاد",
    "register": "يسجل",
    "hot_faq": "الأسئلة الشائعة الساخنة",
    "Contents": "محتويات:",
    "contact_us": "اتصل بنا >>",
    "plan": "يخطط",
    "unregistered": "غير مسجل",
    "buy_more": "شراء المزيد",
    "buy_again": "شراء مرة أخرى",
    "buy_now": "اشتري الآن",
    "free_no_limit": "مجاني ولا يوجد حد",
    "expired": "منتهي الصلاحية",
    "lifetime": "حياة",
    "remain": "يبقى",
    "day_s": "أيام)",
    "error_24801": "فشل الطلب (رمز الخطأ: 24801). من فضلك حاول مرة أخرى",
    "no_app_found": "لم يتم العثور على تطبيق!<a href=\"/\">تحديث</a> أو <a href=\"https://www.mindonmap.com/\">انتقل إلى الموقع الرسمي</a>",
    "get_more": "احصل على المزيد >>",
    "edit_photo": "تعديل الصورة",
    "select_photo": "اختر الصور",
    "change_photo": "غير الصوره",
    "cancel": "يلغي",
    "hide_password": "اخفاء كلمة المرور",
    "show_password": "عرض كلمة المرور",
    "zoom_in": "تكبير",
    "zoom_out": "تصغير",
    "rotate": "استدارة",
    "horizontal_flip": "الوجه الأفقي",
    "vertical_flip": "الوجه العمودي",
    "country": "دولة",
    "country_1": "حدد بلدك/منطقتك",
    "country_2": "جزيرة آلاند",
    "country_3": "أفغانستان",
    "country_4": "ألبانيا",
    "country_5": "الجزائر",
    "country_6": "ساموا الأمريكية",
    "country_7": "أندورا",
    "country_8": "أنغولا",
    "country_9": "أنغيلا",
    "country_10": "القارة القطبية الجنوبية",
    "country_11": "أنتيغوا وبربودا",
    "country_12": "الأرجنتين",
    "country_13": "أرمينيا",
    "country_14": "أروبا",
    "country_15": "أستراليا",
    "country_16": "النمسا",
    "country_17": "أذربيجان",
    "country_18": "البحرين",
    "country_19": "بنغلاديش",
    "country_20": "بربادوس",
    "country_21": "بيلاروسيا",
    "country_22": "بلجيكا",
    "country_23": "بليز",
    "country_24": "بنين",
    "country_25": "برمودا",
    "country_26": "بوتان",
    "country_27": "بوليفيا",
    "country_28": "البوسنة والهرسك",
    "country_29": "بوتسوانا",
    "country_30": "جزيرة بوفيه",
    "country_31": "البرازيل",
    "country_32": "إقليم المحيط البريطاني الهندي",
    "country_33": "جزر فيرجن البريطانية",
    "country_34": "بروناي",
    "country_35": "بلغاريا",
    "country_36": "بوركينا فاسو",
    "country_37": "بوروندي",
    "country_38": "كمبوديا",
    "country_39": "الكاميرون",
    "country_40": "كندا",
    "country_41": "الرأس الأخضر",
    "country_42": "منطقة البحر الكاريبي وهولندا",
    "country_43": "جزر كايمان",
    "country_44": "جمهورية افريقيا الوسطى",
    "country_45": "تشاد",
    "country_46": "شيلي",
    "country_47": "الصين",
    "country_48": "جزيرة عيد الميلاد",
    "country_49": "جزر كوكوس (كيلينج).",
    "country_50": "كولومبيا",
    "country_51": "جزر كوك",
    "country_52": "كوستا ريكا",
    "country_53": "كوت ديفوار",
    "country_54": "كرواتيا",
    "country_55": "كوبا",
    "country_56": "قبرص",
    "country_57": "الجمهورية التشيكية",
    "country_58": "جمهورية الكونغو الديموقراطية",
    "country_59": "الدنمارك",
    "country_60": "جيبوتي",
    "country_61": "دومينيكا",
    "country_62": "جمهورية الدومينيكان",
    "country_63": "الاكوادور",
    "country_64": "مصر",
    "country_65": "السلفادور",
    "country_66": "غينيا الإستوائية",
    "country_67": "إريتريا",
    "country_68": "إستونيا",
    "country_69": "أثيوبيا",
    "country_70": "جزر فوكلاند",
    "country_71": "جزر فاروس",
    "country_72": "ولايات ميكرونيزيا الموحدة",
    "country_73": "فيجي",
    "country_74": "فنلندا",
    "country_75": "فرنسا",
    "country_76": "غيانا الفرنسية",
    "country_77": "بولينيزيا الفرنسية",
    "country_78": "المناطق الجنوبية لفرنسا",
    "country_79": "الجابون",
    "country_80": "غامبيا",
    "country_81": "جورجيا",
    "country_82": "ألمانيا",
    "country_83": "غانا",
    "country_84": "جبل طارق",
    "country_85": "بريطانيا العظمى (المملكة المتحدة، إنجلترا)",
    "country_86": "اليونان",
    "country_87": "الأرض الخضراء",
    "country_88": "غرينادا",
    "country_89": "جوادلوب",
    "country_90": "غوام",
    "country_91": "غواتيمالا",
    "country_92": "غيرنسي",
    "country_93": "غينيا",
    "country_94": "غينيا بيساو",
    "country_95": "غيانا",
    "country_96": "هايتي",
    "country_97": "قلب الجزيرة وجزر ماكدونالز",
    "country_98": "هندوراس",
    "country_99": "هونغ كونغ",
    "country_100": "هنغاريا",
    "country_101": "أيسلندا",
    "country_102": "الهند",
    "country_103": "إندونيسيا",
    "country_104": "إيران",
    "country_105": "العراق",
    "country_106": "أيرلندا",
    "country_107": "جزيرة آيل أوف مان",
    "country_108": "إسرائيل",
    "country_109": "إيطاليا",
    "country_110": "جامايكا",
    "country_111": "اليابان",
    "country_112": "جيرسي",
    "country_113": "الأردن",
    "country_114": "كازاخستان",
    "country_115": "كينيا",
    "country_116": "كيريباتي",
    "country_117": "الكويت",
    "country_118": "قيرغيزستان",
    "country_119": "لاوس",
    "country_120": "لاتفيا",
    "country_121": "لبنان",
    "country_122": "ليسوتو",
    "country_123": "ليبيريا",
    "country_124": "ليبيا",
    "country_125": "ليختنشتاين",
    "country_126": "ليتوانيا",
    "country_127": "لوكسمبورغ",
    "country_128": "ماكاو",
    "country_129": "مدغشقر",
    "country_130": "ملاوي",
    "country_131": "ماليزيا",
    "country_132": "جزر المالديف",
    "country_133": "مالي",
    "country_134": "مالطا",
    "country_135": "جزر مارشال",
    "country_136": "مارتينيك",
    "country_137": "موريتانيا",
    "country_138": "موريشيوس",
    "country_139": "مايوت",
    "country_140": "المكسيك",
    "country_141": "مولدوفا",
    "country_142": "موناكو",
    "country_143": "منغوليا",
    "country_144": "الجبل الأسود",
    "country_145": "مونتسيرات",
    "country_146": "المغرب",
    "country_147": "موزمبيق",
    "country_148": "ميانمار (بورما)",
    "country_149": "ناميبيا",
    "country_150": "ناورو",
    "country_151": "نيبال",
    "country_152": "هولندا",
    "country_153": "كاليدونيا الجديدة",
    "country_154": "نيوزيلندا",
    "country_155": "نيكاراغوا",
    "country_156": "النيجر",
    "country_157": "نيجيريا",
    "country_158": "نيوي",
    "country_159": "جزيرة نورفولك",
    "country_160": "كوريا الشمالية",
    "country_161": "جزر مريانا الشمالية",
    "country_162": "النرويج",
    "country_163": "سلطنة عمان",
    "country_164": "باكستان",
    "country_165": "بالاو",
    "country_166": "الاراضي الفلسطينية",
    "country_167": "بنما",
    "country_168": "بابوا غينيا الجديدة",
    "country_169": "باراجواي",
    "country_170": "بيرو",
    "country_171": "جزر بيتكيرن",
    "country_172": "بولندا",
    "country_173": "البرتغال",
    "country_174": "بورتوريكو",
    "country_175": "دولة قطر",
    "country_176": "جمهورية مقدونيا (جمهورية مقدونيا اليوغوسلافية السابقة)",
    "country_177": "جمهورية الكونغو",
    "country_178": "جمع شمل",
    "country_179": "رومانيا",
    "country_180": "الاتحاد الروسي",
    "country_181": "رواندا",
    "country_182": "سانت بارتيليمي",
    "country_183": "سانت مارتن (فرنسا)",
    "country_184": "سانت بيير وميكلون",
    "country_185": "ساموا",
    "country_186": "سان مارينو",
    "country_187": "سان تومي وبرينسيبي",
    "country_188": "المملكة العربية السعودية",
    "country_189": "السنغال",
    "country_190": "صربيا",
    "country_191": "سيشيل",
    "country_192": "سيرا ليون",
    "country_193": "سنغافورة",
    "country_194": "سلوفاكيا",
    "country_195": "سلوفينيا",
    "country_196": "جزر سليمان",
    "country_197": "الصومال",
    "country_198": "جنوب أفريقيا",
    "country_199": "جنوب جورجيا وجزيرة ساندويتش الجنوبية",
    "country_200": "كوريا الجنوبية",
    "country_201": "جنوب السودان",
    "country_202": "إسبانيا",
    "country_203": "سيريلانكا",
    "country_204": "سانت هيلينا وتوابعها",
    "country_205": "سانت كيتس ونيفيس",
    "country_206": "شارع لوسيا",
    "country_207": "سانت فنسنت وجزر غرينادين",
    "country_208": "السودان",
    "country_209": "سورينام",
    "country_210": "سوازيلاند",
    "country_211": "السويد",
    "country_212": "سويسرا",
    "country_213": "سوريا",
    "country_214": "تايوان",
    "country_215": "طاجيكستان",
    "country_216": "تنزانيا",
    "country_217": "قالب:بيانات بلد SJM سفالبارد",
    "country_218": "تايلاند",
    "country_219": "جزر البهاما",
    "country_220": "جزر القمر",
    "country_221": "الفلبينيين",
    "country_222": "تيمور الشرقية (تيمور الشرقية)",
    "country_223": "توجو",
    "country_224": "توكيلاو",
    "country_225": "تونغا",
    "country_226": "ترينيداد وتوباجو",
    "country_227": "تونس",
    "country_228": "ديك رومى",
    "country_229": "تركمانستان",
    "country_230": "جزر تركس وكايكوس",
    "country_231": "توفالو",
    "country_232": "أوغندا",
    "country_233": "أوكرانيا",
    "country_234": "الإمارات العربية المتحدة",
    "country_235": "جزر الولايات المتحدة البعيدة الصغرى",
    "country_236": "الولايات المتحدة الأمريكية (الولايات المتحدة الأمريكية)",
    "country_237": "جزر فيرجن التابعة للولايات المتحدة",
    "country_238": "أوروغواي",
    "country_239": "أوزبكستان",
    "country_240": "فانواتو",
    "country_241": "مدينة الفاتيكان (الكرسي الرسولي)",
    "country_242": "فنزويلا",
    "country_243": "فيتنام",
    "country_244": "واليس وفوتونا",
    "country_245": "الصحراء الغربية",
    "country_246": "اليمن",
    "country_247": "زامبيا",
    "country_248": "زيمبابوي",
    "google_login": "الدخول مع جوجل",
    "State": "ولاية",
    "Activation_code": "رمز التفعيل",
    "Question": "قم بإدراج جميع التطبيقات التي قمت بتسجيل الدخول إليها",
    "Copy_complete": "اكتمل النسخ",
    "footer": "حقوق الطبع والنشر © 2024 MindOnMap Studio. كل الحقوق محفوظة.",
    "change_password_success": "تم تغيير الرقم السري بنجاح",
    "successful_login_title": "النجاح في تسجيل الدخول",
    "product_page": "صفحة المنتج >>",
    "successful_login_info": "اكتمل تسجيل الدخول. الرجاء إغلاق الصفحة الحالية والعودة إلى علامة التبويب الأصلية لمتابعة العملية التالية. سيتم إغلاق الصفحة الحالية تلقائيًا خلال 5 ثوانٍ. إذا فشل الإغلاق التلقائي أو الإغلاق بالنقر فوق الزر \"تم\"، فيرجى إغلاق علامة التبويب هذه مباشرةً.",
    "successful_login_info_firefox": "اكتمل تسجيل الدخول. الرجاء إغلاق الصفحة الحالية والعودة إلى علامة التبويب الأصلية لمتابعة العملية التالية.",
    "my_account": "حسابي",
    "my_history": "تاريخي",
    "remove_watermark": "إزالة العلامة المائية",
    "no_history": "لا تاريخ",
    "history_all": "اختر الكل",
    "history_open": "يفتح",
    "history_down": "تحميل",
    "history_delete": "يمسح",
    "history_clear": "مسح غير صالح",
    "images": "الصورة (الصور)",
    "use_this_function": "استخدم هذا المنتج >>",
    "hd_downloading": "فوائد تنزيل الصور الأصلية عالية الدقة:",
    "lifetimeRemaining": "مدى الحياة المتبقية",
    "Remaining": "متبقي",
    "email_verification": "تأكيد بواسطة البريد الالكتروني",
    "email_verification_info": "لقد أرسلنا رسالة التحقق إلى بريدك الإلكتروني <span class=\"email\"></span>، ويرجى إنهاء عملية التحقق. بعد التحقق، ستتم مزامنة المزايا تلقائيًا.",
    "wrong_email": "عنوان بريد إلكتروني خاطئ؟",
    "click_here_to_modify": "انقر هنا للتعديل",
    "get_more_help": "الحصول على مزيد من المساعدة؟",
    "click_here": "انقر هنا",
    "email_verification_info_success": "تهانينا على التحقق من حساب البريد الإلكتروني الخاص بك.",
    "email_verification_info_error": "فشل التحقق بسبب انتهاء صلاحية الرابط.",
    "registration_succeeded": "نجح التسجيل",
    "registration_succeeded_info_1": "تهانينا! لقد سجلت بنجاح. لقد أرسلنا رسالة التحقق إلى بريدك الإلكتروني <span class=\"email\"></span>، ويرجى إنهاء عملية التحقق للاستفادة من مزايا هذا البريد الإلكتروني.",
    "registration_succeeded_info_2": "انقر فوق \"تم\" للعودة إلى الصفحة الرئيسية واستخدام هذا المنتج.",
    "registration_succeeded_info_3": "انقر فوق \"تم\" لإغلاق الصفحة الحالية والعودة إلى الصفحة الرئيسية لإجراء العمليات التالية. إذا فشل الإغلاق، يرجى إغلاق علامة التبويب يدويًا.",
    "verify_email": "التحقق من البريد الإلكتروني",
    "registered_email_not_verified": "لم يتم التحقق من البريد الإلكتروني المسجل، يرجى التحقق منه فورا.",
    "email_verification_time_1": "لم تتلق رسالة التحقق عبر البريد الإلكتروني؟",
    "email_verification_time_2": "بعد",
    "email_verification_time_3": "انقر هنا لإعادة إرساله",
    "error_26301": "رمز الخطأ: 26301، يرجى الاتصال بنا",
    "error_26302": "رمز الخطأ: 26302، يرجى الاتصال بنا",
    "error_26303": "خطأ في تنسيق البريد الإلكتروني (رمز الخطأ: 26303). الرجاء إدخاله مرة أخرى",
    "error_26304": "يوصى بكلمة مرور أعلى من 8 أحرف (رمز الخطأ: 26304)",
    "error_26305": "فشل Reuqest (رمز الخطأ: 26305). يرجى إعادة المحاولة",
    "error_26306": "تم تسجيل البريد الإلكتروني، يرجى <a href=\"https://account.mindonmap.com/login/\">الانتقال لتسجيل الدخول</a>",
    "error_26307": "فشل Reuqest (رمز الخطأ: 26307). يرجى إعادة المحاولة",
    "error_26308": "فشل Reuqest (رمز الخطأ: 26308). يرجى إعادة المحاولة",
    "error_26401": "رمز الخطأ: 26401، يرجى إعادة المحاولة",
    "error_26402": "تم التحقق من البريد الإلكتروني (رمز الخطأ: 26402)، يرجى إعادة المحاولة",
    "error_26403": "فشل Reuqest (رمز الخطأ: 26403). يرجى إعادة المحاولة",
    "error_26404": "فشل الطلب (رمز الخطأ: 26404). يرجى إعادة المحاولة",
    "error_26501": "رمز الخطأ: 26501، يرجى الاتصال بنا",
    "error_26502": "رمز الخطأ: 26502، يرجى الاتصال بنا",
    "error_26503": "خطأ في تنسيق البريد الإلكتروني (رمز الخطأ: 26503). الرجاء إدخاله مرة أخرى",
    "error_26504": "فشل Reuqest (رمز الخطأ: 26504). يرجى إعادة المحاولة",
    "error_26505": "لم يتم تسجيل البريد الإلكتروني، يرجى <a href=\"https://account.mindonmap.com/register/\">تسجيله أولاً</a>",
    "error_26506": "تم التحقق من البريد الإلكتروني.",
    "error_26507": "فشل Reuqest (رمز الخطأ: 26507). يرجى إعادة المحاولة",
    "error_26508": "تم التحقق (رمز الخطأ: 26508)، يرجى إعادة المحاولة",
    "error_26509": "فشل الطلب (رمز الخطأ: 26509)، يرجى إعادة المحاولة",
    "error_26510": "رمز الخطأ: 26510، يرجى الاتصال بنا",
    "error_26601": "رمز الخطأ: 26601، يرجى الاتصال بنا",
    "error_26602": "رمز الخطأ: 26602، يرجى الاتصال بنا",
    "error_26603": "فشل Reuqest (رمز الخطأ: 26603). يرجى إعادة المحاولة",
    "error_26604": "رمز الخطأ: 26604، يرجى الاتصال بنا",
    "error_26605": "رمز الخطأ: 26605، يرجى الاتصال بنا",
    "error_26701": "رمز الخطأ: 26701، يرجى الاتصال بنا",
    "error_26702": "فشل Reuqest (رمز الخطأ: 26702). يرجى إعادة المحاولة",
    "error_26703": "رمز الخطأ: 26703، يرجى الاتصال بنا",
    "error_26704": "رمز الخطأ: 26704، يرجى الاتصال بنا",
    "error_26705": "انتظر تسجيل الدخول (رمز الخطأ: 26705). يرجى إعادة المحاولة",
    "no_cookie": "لقد قمت بتشغيل وظيفة حظر جميع ملفات تعريف الارتباط في متصفحك، لذا لا يمكنك تسجيل الدخول. يرجى الانتقال إلى الإعدادات لتحديد مربع السماح لجميع ملفات تعريف الارتباط.",
    "error_26801": "رمز الخطأ: 26801، يرجى الاتصال بنا",
    "error_26802": "رمز الخطأ: 26802، يرجى الاتصال بنا",
    "error_26803": "فشل الطلب (رمز الخطأ: 26803). يرجى إعادة المحاولة",
    "error_26804": "فشل الطلب (رمز الخطأ: 26804). يرجى إعادة المحاولة",
    "error_order": "فشل الطلب (رمز الخطأ: 27098)، يرجى المحاولة مرة أخرى!",
    "error_order1": "استعلام الطلب غير مكتمل (رمز الخطأ: ",
    "error_order2": ")، يرجى التحديث والمحاولة مرة أخرى.",
    "modify_email_title": "تعديل البريد الإلكتروني",
    "modify_email_info": "يمكنك استخدام البريد الإلكتروني المعدل لتسجيل الدخول إلى حسابك.",
    "images_per": "الصور لكل",
    "error_26101": "خطأ: 26101. يرجى الاتصال بنا",
    "error_26102": "خطأ: 26102. يرجى الاتصال بنا",
    "error_26103": "فشل الطلب (رمز الخطأ: 26103). من فضلك حاول مرة أخرى",
    "error_26104": "رمز الخطأ: 26104، يرجى إعادة المحاولة",
    "error_26105": "رمز الخطأ: 26105، يرجى إعادة المحاولة",
    "error_26106": "فشل الحذف (رمز الخطأ: 26106). من فضلك حاول مرة أخرى",
    "error_26201": "خطأ: 26201. يرجى الاتصال بنا",
    "error_26202": "فشل الطلب (رمز الخطأ: 26202). من فضلك حاول مرة أخرى",
    "error_26001": "خطأ: 26001. يرجى الاتصال بنا",
    "error_26002": "خطأ: 26002. يرجى الاتصال بنا",
    "error_26003": "خطأ: 26003. يرجى الاتصال بنا",
    "error_26004": "خطأ: 26004. يرجى الاتصال بنا",
    "error_26005": "فشل الطلب (رمز الخطأ: 26005). من فضلك حاول مرة أخرى",
    "error_26006": "رمز الخطأ: 26006، يرجى إعادة المحاولة",
    "error_26008": "خطأ: 26008. يرجى الاتصال بنا",
    "go_to_the_home_page": "اذهب الى الصفحة الرئيسية",
    "error_27101": "فشل الطلب (رمز الخطأ: 27101). يرجى إعادة المحاولة",
    "error_27201": "رمز الخطأ: 27201، يرجى الاتصال بنا",
    "error_27202": "رمز الخطأ: 27202، يرجى إعادة المحاولة",
    "error_27203": "فشل الطلب (رمز الخطأ: 27203). يرجى إعادة المحاولة",
    "error_27204": "رمز غير صالح (رمز الخطأ: 27204).",
    "error_27205": "فشل الطلب (رمز الخطأ: 27205). يرجى إعادة المحاولة",
    "error_27206": "فشل الطلب (رمز الخطأ: 27206). يرجى إعادة المحاولة",
    "error_27207": "فشل الطلب (رمز الخطأ: 27207). يرجى إعادة المحاولة",
    "no_history_found": "لم تستخدم أي أداة! <a href=\"/\">تحديث</a> أو <a href=\"https://www.mindonmap.com/\">انتقل إلى الموقع الرسمي</a>",
    "error_25301": "خطأ: 25301. يرجى الاتصال بنا",
    "error_25302": "خطأ: 25302. يرجى الاتصال بنا",
    "error_25303": "فشل الطلب (رمز الخطأ: 25303). من فضلك حاول مرة أخرى",
    "error_25304": "فشل الطلب (رمز الخطأ: 25304). من فضلك حاول مرة أخرى",
    "error_25305": "فشل الطلب (رمز الخطأ: 25305). من فضلك حاول مرة أخرى",
    "error_25306": "فشل الطلب (رمز الخطأ: 25306). من فضلك حاول مرة أخرى",
    "image_upscaler_p": "فوائد تنزيل الصورة بدون علامة مائية:",
    "Available_for": "متاح لى:",
    "credit_per": "رصيد (أرصدة) %s لكل صورة عالية الدقة",
    "still_valid": "الخطة (الخطط) التي تم شراؤها لا تزال صالحة",
    "credit": "الائتمان (الائتمانات)",
    "pc_3rd_info": "تسجيل الدخول بنجاح. يرجى الذهاب إلى التطبيق لمزيد من العملية.",
    "use_online": "استخدم الخدمة عبر الإنترنت",
    "use_download": "استخدم برنامج سطح المكتب",
    "use_immediately": "استخدم على الفور",
    "Use_in_browser": "استخدامها في المتصفح",
    "win_desktop": "شبابيك",
    "Mac_desktop": "ماك",
    "credits_per": "{%} رصيد شهري",
    "expire": "تاريخ انتهاء الصلاحية:",
    "viewDetails": "عرض التفاصيل",
    "viewHistory": "عرض فوائد التاريخ >>",
    "viewDetailsInfo": "ملاحظة: إذا تم تجديد مزايا الاشتراك خلال 7 أيام بعد انتهاء الصلاحية، فيمكن الاستمرار في استخدام المزايا غير المستخدمة. كما سيتم تحديث وقت انتهاء الصلاحية تلقائيًا إلى وقت انتهاء الاشتراك الجديد. إذا لم يكن هناك اشتراك جديد بعد 7 أيام من انتهاء الصلاحية، فسيتم مسح جميع المزايا منتهية الصلاحية.",
    "connect_account": "ربط البريد الإلكتروني بحسابك",
    "connect_account_info": "بعد الربط، يمكنك تسجيل الدخول باستخدام عنوان البريد الإلكتروني هذا.",
    "connect_now": "ربط الآن",
    "no_email_bind": "لا يوجد ربط البريد الإلكتروني",
    "bind_email": "ربط البريد الإلكتروني",
    "connect_your_email_placeholder": "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك",
    "bind_an_email": "ربط البريد الإلكتروني",
    "bind_info": "لقد قمت بتسجيل الدخول بنجاح. يرجى ربط بريد إلكتروني بحسابك لتفعيل المزايا الخاصة بك.",
    "bind_later": "ربط في وقت لاحق",
    "hi": "له %s!",
    "Personal_Information": "معلومات شخصية",
    "Access": "وصول",
    "Subscription_Plan": "(خطة الاشتراك)",
    "No_orders": "لم يتم العثور على أية طلبات.",
    "No_data": "لايوجد بيانات",
    "Featured_Products": "منتجات مميزة",
    "More_Products": "المزيد من المنتجات",
    "Free_Download": "تحميل مجاني",
    "Get_Started": "البدء",
    "Subscribe": "يشترك",
    "Verified": "تم التحقق",
    "back_to_account_center": "العودة إلى مركز الحساب",
    "success": "نجاح!",
    "successfully": "لقد قمت بتسجيل حساب بنجاح.",
    "Continue": "يكمل",
    "Already": "هل لديك حساب؟",
    "loading_verification": "التحقق من حالة التحقق...",
    "email_no_verification": "عذرًا، لم يتم التحقق من عنوان البريد الإلكتروني المسجل. يرجى إكمال عملية التحقق وفقًا للتعليمات المذكورة أعلاه ثم النقر فوق الزر \"تم\" مرة أخرى لإكمال التسجيل.",
    "will_expire_after": "سوف تنتهي بعد",
    "hours": "ساعات",
  },
  "az": {
    "overtime": "Xəta kodu: {%}, yenidən cəhd edin",
    "isnetwork": "İnternet xətası. Zəhmət olmasa yoxlayın və yenidən cəhd edin",
    "email_placeholder": "E-poçt",
    "email_empty": "Zəhmət olmasa e-poçtu daxil edin",
    "email_not_valid": "E-poçt etibarlı deyil",
    "email_not_valid_1": "Zəhmət olmasa e-poçtunuzu daxil edin",
    "email_not_valid_2": "E-poçt etibarlı deyil, başqa ünvandan istifadə edin.",
    "email_not_valid_3": "E-poçt girişi yoxdur",
    "password_placeholder": "parol",
    "password_empty": "Zəhmət olmasa parol daxil edin",
    "password_not_valid": "Yanlış hesab və ya parol",
    "password_not_valid_1": "8 simvoldan yuxarı parol tələb olunur",
    "password_not_valid_2": "Zəhmət olmasa parol yaradın",
    "password_placeholder_1": "Parolunuzu yaradın",
    "password_placeholder_2": "Şifrənizi təsdiq edin",
    "password_placeholder_3": "Yeni parol yaradın",
    "password_placeholder_4": "Yeni Şifrəni təsdiq",
    "password_placeholder_5": "Köhnə parolu daxil edin",
    "copy_password_empty": "Zəhmət olmasa parolu təsdiqləyin",
    "copy_password_not_valid": "Zəhmət olmasa parolunuzu təsdiqləyin",
    "copy_passwords_inconsistent": "Parol təsdiqiniz uyğun gəlmir",
    "code_empty": "Doğrulama kodunu daxil edin",
    "code_not_valid": "Yanlış doğrulama kodu",
    "code_placeholder": "Yoxlama kodu",
    "not_received_code": "Əgər poçt qutunuz uzun müddət ərzində doğrulama kodunu almayıbsa, lütfən, doğrulama kodunu yenidən əldə edin.",
    "get_first_code": "Əvvəlcə doğrulama kodunu əldə edin.",
    "last_name_placeholder": "Zəhmət olmasa soyadınızı daxil edin",
    "first_name_placeholder": "Zəhmət olmasa adınızı daxil edin",
    "address_placeholder": "Zəhmət olmasa ünvanınızı daxil edin",
    "no_code_text": "Doğrulama kodu göndərdik. Zəhmət olmasa kodunuzu daxil edin. <span class='tips'>Kod almadınız?",
    "no_code_text_1": "1. E-poçt ünvanının etibarlı olduğuna və e-poçtları qəbul edə biləcəyinə əmin olun.",
    "no_code_text_2": "2. E-poçt sistem tərəfindən avtomatik göndərildiyi üçün o, spam və ya lazımsız e-poçt kimi qeyd oluna bilər. E-poçtun Zibil qutusunda olub olmadığını yoxlayın.",
    "no_code_text_3": "3. Probleminizi həll edə bilmirsiniz? ",
    "no_code_text_3_span": "Sonra bizimlə əlaqə saxlamaq üçün bura klikləyin.",
    "order_no": "Siz heç bir məhsul almamısınız, hər hansı bir sualınız varsa, <a href=\"https://www.mindonmap.com/contact-us/\">bizimlə əlaqə saxlayın</a>.",
    "error_24901": "Cari hesabda e-poçt əlaqəsi yoxdur və sifarişləri tapa bilmir. Zəhmət olmasa e-poçtu birləşdirin.",
    "user_guide": "İstifadəçi təlimatı>>",
    "download": "Yüklə",
    "order_number": "Sifariş nömrəsi:",
    "Refund": "Geri qaytarma",
    "Disabled": "Əlil",
    "Normal": "Normal",
    "Modify": "Dəyişdirin",
    "Modify_1": "Dəyişdirin>>",
    "Connect": "Qoşun",
    "unlink_success": "Əlaqəni uğurla kəsin",
    "connect_success": "Uğurla qoşulun",
    "feedback_title": "Rəyiniz üçün təşəkkür edirik. Zəhmət olmasa probleminizi buraxın və 24 saat ərzində sizə cavab verəcəyik.",
    "feedback_thank_you": "Təşəkkür edirik!<br />Rəyiniz uğurla təqdim edildi.",
    "feedback_email": "E-poçtunuzu bura daxil edin!",
    "feedback_content": "Qarşılaşdığınız hər hansı bir problem və ya təklifi burada buraxın.",
    "feedback_submit": "təqdim",
    "form_contents": "Siz heç bir təsvir daxil etməmisiniz. Zəhmət olmasa daxil edin və yenidən təqdim edin.",
    "old_password": "Zəhmət olmasa köhnə parolu daxil edin",
    "new_password": "Zəhmət olmasa yeni parol yaradın",
    "old_new_password": "Yeni parol köhnəsi ilə eyni ola bilməz",
    "incorrect_password": "Yanlış parol",
    "delete_no": "İndi Sil",
    "Caps": "Caps lock aktivdir",
    "Get": "alın",
    "Done": "Bitdi",
    "error_20001": "Xəta: 20001. Lütfən, yenidən daxil olun.",
    "error_20002": "Xəta: 20002. Lütfən, yenidən daxil olun.",
    "error_20003": "Xəta: 20003. Lütfən, yenidən daxil olun.",
    "error_20004": "Sorğu alınmadı (xəta kodu: 20004). Lütfən, yenidən cəhd edin.",
    "error_20005": "Giriş sessiyasının müddəti bitdi (Xəta: 20005). Zəhmət olmasa yenidən daxil olun.",
    "error_20006": "Sorğu alınmadı (xəta kodu: 20006). Lütfən, yenidən cəhd edin.",
    "error_20007": "Giriş sessiyasının müddəti bitdi (Xəta: 20007). Zəhmət olmasa yenidən daxil olun.",
    "error_20008": "Giriş sessiyasının vaxtı bitdi (Xəta: 20008). Zəhmət olmasa yenidən daxil olun.",
    "error_20009": "Giriş sessiyasının müddəti bitdi (Xəta: 20009). Zəhmət olmasa yenidən daxil olun.",
    "error_20101": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20101)",
    "error_20102": "E-poçt etibarlı deyil (xəta kodu: 20102)",
    "error_20103": "Sorğu alınmadı (xəta kodu: 20103). Lütfən, yenidən cəhd edin",
    "error_20104": "E-poçt artıq istifadə olunur, lütfən, <a href=\"https://account.mindonmap.com/login/\">daxil olun</a> və ya yenisi ilə qeydiyyatdan keçin",
    "error_20105": "Sorğu alınmadı (xəta kodu: 20105). Lütfən, yenidən cəhd edin",
    "error_20106": "E-poçt göndərmək alınmadı, lütfən, yenidən cəhd edin",
    "error_20201": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20201)",
    "error_20202": "Zəhmət olmasa parolunuzu daxil edin (xəta kodu: 20202)",
    "error_20203": "Doğrulama kodunu daxil edin (xəta kodu: 20203)",
    "error_20204": "E-poçt etibarlı deyil (xəta kodu: 20204)",
    "error_20205": "8 simvoldan yuxarı parol tələb olunur (xəta kodu: 20205)",
    "error_20206": "Sorğu alınmadı (xəta kodu: 20206). Lütfən, yenidən cəhd edin",
    "error_20207": "Yanlış doğrulama kodu",
    "error_20208": "Sorğu alınmadı (xəta kodu: 20208). Lütfən, yenidən cəhd edin",
    "error_20209": "Sorğu alınmadı (xəta kodu: 20209). Lütfən, yenidən cəhd edin",
    "error_20301": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20301)",
    "error_20302": "Xəta: 20302. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_20303": "E-poçt etibarlı deyil (xəta kodu: 20303)",
    "error_20304": "Sorğu alınmadı (xəta kodu: 20304). Lütfən, yenidən cəhd edin",
    "error_20305": "Hesab mövcud deyil. Lütfən, yenidən daxil edin və ya əvvəlcə <a href=\"https://account.mindonmap.com/register/\">onu yaradın</a>.",
    "error_20306": "Hələ parol yoxdur. <a href=\"https://account.mindonmap.com/passwordless-login/\">Parolsuz giriş</a> və ya <a href=\"https://account.mindonmap.com/create-password/\"> istifadə edin parol təyin edin</a> və daxil olun.",
    "error_20308": "Sorğu alınmadı (xəta kodu: 20308). Lütfən, yenidən cəhd edin",
    "error_20401": "Sistemdən çıxmaq alınmadı (xəta kodu: 20401). Lütfən, yenidən cəhd edin",
    "error_20501": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20501)",
    "error_20502": "E-poçt etibarlı deyil (xəta kodu: 20502)",
    "error_20503": "Sorğu alınmadı (xəta kodu: 20503). Lütfən, yenidən cəhd edin",
    "error_20504": "E-poçt göndərmək alınmadı. Lütfən, yenidən cəhd edin.",
    "error_20601": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20601)",
    "error_20602": "Doğrulama kodunu daxil edin (xəta kodu: 20602)",
    "error_20603": "E-poçt etibarlı deyil (xəta kodu: 20603)",
    "error_20604": "Sorğu alınmadı (xəta kodu: 20604). Yenidən cəhd edin",
    "error_20606": "Sorğu alınmadı (xəta kodu: 20606). Lütfən, yenidən cəhd edin",
    "error_20607": "Sorğu alınmadı (xəta kodu: 20607). Lütfən, yenidən cəhd edin",
    "error_20608": "Sorğu alınmadı (xəta kodu: 20608). Lütfən, yenidən cəhd edin",
    "error_20701": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20701)",
    "error_20702": "E-poçt etibarlı deyil (xəta kodu: 20702)",
    "error_20703": "Sorğu alınmadı (xəta kodu: 20703). Lütfən, yenidən cəhd edin",
    "error_20704": "Hesab mövcud deyil. Lütfən, yenidən daxil edin və ya əvvəlcə <a href=\"https://account.mindonmap.com/register/\">onu yaradın</a>.",
    "error_20705": "Sorğu alınmadı (xəta kodu: 20705). Lütfən, yenidən cəhd edin",
    "error_20706": "E-poçt göndərmək alınmadı. Lütfən, yenidən cəhd edin",
    "error_20801": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 20801)",
    "error_20802": "Xəta: 20802. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_20803": "Doğrulama kodunu daxil edin (xəta kodu: 20803)",
    "error_20804": "E-poçt etibarlı deyil (xəta kodu: 20804)",
    "error_20805": "8 simvoldan yuxarı parol tələb olunur (xəta kodu: 20805)",
    "error_20806": "Sorğu alınmadı (xəta kodu: 20806). Lütfən, yenidən cəhd edin",
    "error_20808": "Sorğu alınmadı (xəta kodu: 20808). Lütfən, yenidən cəhd edin",
    "error_20901": "Sorğu alınmadı (xəta kodu: 20901). Lütfən, yenidən cəhd edin",
    "error_20902": "Sorğu alınmadı (xəta kodu: 20902). Lütfən, yenidən cəhd edin",
    "error_21000": "Dəyişikliklər saxlanılır",
    "error_21001": "Heç bir məlumat təqdim edilməyib (xəta kodu: 21001)",
    "error_21002": "Sorğu alınmadı (xəta kodu: 21002). Lütfən, yenidən cəhd edin",
    "error_21101": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 21101)",
    "error_21102": "E-poçt etibarlı deyil (xəta kodu: 21102)",
    "error_21103": "Sorğu alınmadı (xəta kodu: 21103), yenidən cəhd edin",
    "error_21104": "E-poçt artıq bağlıdır, yenisini istifadə edin",
    "error_21105": "Sorğu alınmadı (xəta kodu: 21105), yenidən cəhd edin",
    "error_21106": "E-poçt göndərmək alınmadı. Lütfən, yenidən cəhd edin",
    "error_21201": "Zəhmət olmasa e-poçtunuzu daxil edin (xəta kodu: 21201)",
    "error_21202": "Doğrulama kodunu daxil edin (xəta kodu: 21202)",
    "error_21203": "E-poçt etibarlı deyil (xəta kodu: 21203)",
    "error_21204": "Xəta: 21204. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21205": "Xəta: 21205. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21206": "8 simvoldan yuxarı parol tələb olunur (xəta kodu: 21206)",
    "error_21207": "Sorğu alınmadı (xəta kodu: 21207). Lütfən, yenidən cəhd edin",
    "error_21209": "Sorğu alınmadı (xəta kodu: 21209). Lütfən, yenidən cəhd edin",
    "error_21301": "Zəhmət olmasa köhnə parolu daxil edin (xəta kodu: 21301)",
    "error_21302": "Zəhmət olmasa yeni parol yaradın (xəta kodu: 21302)",
    "error_21303": "Yeni parol köhnəsi ilə eyni ola bilməz. (Səhv: 21303)",
    "error_21304": "8 simvoldan yuxarı parol tələb olunur (xəta kodu: 21304)",
    "error_21306": "Sorğu alınmadı (xəta kodu: 21306). Lütfən, yenidən cəhd edin",
    "error_21402": "Sorğu alınmadı (xəta kodu: 21402). Lütfən, yenidən cəhd edin",
    "error_21403": "Doğrulama kodunu göndərmək alınmadı. Zəhmət olmasa, yenidən göndərin",
    "error_21500": "Hesab silindi",
    "error_21501": "Doğrulama kodunu daxil edin (xəta kodu: 21501)",
    "error_21502": "Giriş sessiyasının müddəti bitdi (Xəta: 21502). Zəhmət olmasa yenidən daxil olun.",
    "error_21503": "Sorğu alınmadı (xəta kodu: 21503). Lütfən, yenidən cəhd edin",
    "error_21505": "Sorğu alınmadı (xəta kodu: 21505), lütfən, yenidən cəhd edin",
    "error_21601": "Xəta: 20601. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21602": "Yanlış Doğrulama (Xəta: 20602). Lütfən, yenidən cəhd edin.",
    "error_21603": "Xəta: 20603. Lütfən, yenidən cəhd edin",
    "error_21604": "Sorğu alınmadı (xəta kodu: 21604). Lütfən, yenidən cəhd edin",
    "error_21606": "Sorğu alınmadı (xəta kodu: 21606). Lütfən, yenidən cəhd edin",
    "error_21611": "Sorğu alınmadı (xəta kodu: 21611). Yenidən cəhd edin",
    "error_21801": "Xəta: 21801. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21802": "Sorğu uğursuz oldu (Xəta: 21802). Lütfən, yenidən cəhd edin",
    "error_21803": "Xəta: 21803. Lütfən, yenidən cəhd edin",
    "error_21804": "Sorğu alınmadı (xəta kodu: 21804). Lütfən, yenidən cəhd edin",
    "error_21806": "Xəta: 21806. Lütfən, yenidən cəhd edin",
    "error_21807": "Xəta: 21807. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21808": "Xəta: 21808. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21809": "Xəta: 21809. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21810": "Xəta: 21810. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21811": "Xəta: 21811. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21812": "Xəta: 21812. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21813": "Sorğu alınmadı (xəta kodu: 21813). Lütfən, yenidən cəhd edin",
    "error_21814": "Xəta: 21814. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21815": "Sorğu alınmadı (xəta kodu: 21815). Lütfən, yenidən cəhd edin",
    "error_21816": "Xəta: 21816. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21817": "Xəta: 21817. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21818": "Xəta: 21818. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21819": "Sorğu alınmadı (xəta kodu: 21819). Lütfən, yenidən cəhd edin",
    "error_21820": "Xəta: 21820. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21821": "Xəta: 21821. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21822": "Xəta: 21822. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21823": "Sorğu alınmadı (xəta kodu: 21823). Lütfən, yenidən cəhd edin",
    "error_21824": "Sorğu alınmadı (xəta kodu: 21824). Lütfən, yenidən cəhd edin",
    "error_21825": "Sorğu alınmadı (xəta kodu: 21825). Lütfən, yenidən cəhd edin",
    "error_21826": "Sorğu alınmadı (xəta kodu: 21826). Lütfən, yenidən cəhd edin",
    "error_21901": "Xəta: 21901. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_21902": "Sorğu alınmadı (xəta kodu: 21902). Yenidən cəhd edin",
    "error_21903": "Hesab statusu dəyişdi (xəta kodu: 21903), lütfən, səhifəni yeniləyin və yenidən cəhd edin",
    "error_21904": "Xəta: 21904. Lütfən, yenidən cəhd edin",
    "error_21905": "Xəta: 21905. Lütfən, yenidən cəhd edin",
    "error_21906": "Sorğu alınmadı (xəta kodu: 21906). Lütfən, yenidən cəhd edin",
    "error_21907": "Google hesabı başqa hesabla əlaqələndirilib",
    "error_21908": "Sorğu alınmadı (xəta kodu: 21908). Lütfən, yenidən cəhd edin",
    "error_22001": "Sorğu alınmadı (xəta kodu: 22001). Lütfən, yenidən cəhd edin",
    "error_22002": "Əlavə giriş olmadan əlaqəni kəsmək uğursuz oldu",
    "error_22003": "Sorğu alınmadı (xəta kodu: 22003). Lütfən, yenidən cəhd edin",
    "error_22101": "Xəta: 22101. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22102": "Hesab statusu dəyişdi (xəta kodu: 22102), lütfən, səhifəni yeniləyin və yenidən cəhd edin",
    "error_22103": "Sorğu alınmadı (xəta kodu: 22103). Lütfən, yenidən cəhd edin",
    "error_22104": "Hesab statusu dəyişdi (xəta kodu: 22104), lütfən, səhifəni yeniləyin və yenidən cəhd edin",
    "error_22105": "Xəta: 22105. Lütfən, yenidən cəhd edin",
    "error_22106": "Xəta: 22106. Lütfən, yenidən cəhd edin",
    "error_22107": "Xəta: 22107. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22108": "Sorğu alınmadı (xəta kodu: 22108). Lütfən, yenidən cəhd edin",
    "error_22201": "Xəta: 22201. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22202": "Yanlış Doğrulama (Xəta: 22202). Lütfən, yenidən cəhd edin.",
    "error_22203": "Xəta: 22203. Lütfən, yenidən cəhd edin\"",
    "error_22204": "Sorğu alınmadı (xəta kodu: 22204). Lütfən, yenidən cəhd edin",
    "error_22206": "Sorğu alınmadı (xəta kodu: 22206). Lütfən, yenidən cəhd edin",
    "error_22401": "Xəta: 22401. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22402": "Yanlış Doğrulama (Xəta: 22402). Lütfən, yenidən cəhd edin.",
    "error_22403": "Xəta: 22403. Lütfən, yenidən cəhd edin",
    "error_22404": "Sorğu alınmadı (xəta kodu: 22404). Lütfən, yenidən cəhd edin",
    "error_22405": "Facebook hesabı başqa e-poçtla əlaqələndirilib",
    "error_22406": "Xəta: 22406. Lütfən, yenidən cəhd edin",
    "error_22407": "Xəta: 22407. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22408": "Xəta: 22408. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22409": "Xəta: 22409. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22410": "Xəta: 224010. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22411": "Xəta: 224011. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22412": "Xəta: 224012. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22413": "Xəta: 22413. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22414": "Sorğu alınmadı (xəta kodu: 22414). Lütfən, yenidən cəhd edin",
    "error_22415": "Xəta: 22415. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22416": "Xəta: 22416. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22417": "Xəta: 22417. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22418": "Sorğu alınmadı (xəta kodu: 22418). Lütfən, yenidən cəhd edin",
    "error_22419": "Xəta: 22419. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22420": "Xəta: 22420. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22421": "Xəta: 22421. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_22422": "Sorğu alınmadı (xəta kodu: 22422). Lütfən, yenidən cəhd edin",
    "error_22423": "Sorğu alınmadı (xəta kodu: 22423). Lütfən, yenidən cəhd edin",
    "error_22424": "Sorğu alınmadı (xəta kodu: 22424). Lütfən, yenidən cəhd edin",
    "error_22425": "Sorğu alınmadı (xəta kodu: 22425). Lütfən, yenidən cəhd edin",
    "error_20098": "Xəta kodu: 20098. Şəxsi rejimdə gəzirsinizsə, Normal rejimə keçin və yenidən cəhd edin.",
    "error_22298": "Google sorğusu alınmadı (Xəta: 22298). Lütfən, yenidən cəhd edin.",
    "error_22498": "Facebook üçün sorğu uğursuz oldu (Xəta: 22498). Lütfən, yenidən cəhd edin.",
    "error_24902": "Sorğu alınmadı (xəta kodu: 24902). Lütfən, yenidən cəhd edin",
    "error_24903": "Sorğu alınmadı (xəta kodu: 24903). Lütfən, yenidən cəhd edin",
    "error_24904": "Sorğu alınmadı (xəta kodu: 24904). Lütfən, yenidən cəhd edin",
    "error_24905": "Sorğu alınmadı (xəta kodu: 24905). Lütfən, yenidən cəhd edin",
    "login_title": "MindOnMap-a daxil olun",
    "log_in": "Daxil ol",
    "no_account": "Hesab yoxdur?",
    "create_it": "Yaradın",
    "or_log_in_with": "Və ya ilə daxil olun",
    "passwordless_login": "Parolsuz giriş",
    "log_in_done": "Daxil olmaq Bitdi",
    "three_rd_account_connect_info": "Təbrik edirik! Siz uğurla daxil oldunuz. İndi siz gələcəkdə daxil olmaq üçün istifadə olunan bir e-poçt hesabını birləşdirə bilərsiniz.",
    "see_my_account": "Hesabıma baxın",
    "three_rd_login_merge_account": "Üçüncü tərəf hesabının e-poçt ünvanı qeydə alınıb, siz birbaşa bu e-poçt ünvanı ilə qoşulmaq və daxil olmaq istəyirsiniz?",
    "connect_log_in": "Qoşulun və Daxil olun",
    "create_an_account": "Hesab yarat",
    "back_to_log_in": "Daxil olmaq üçün geri qayıdın",
    "create_password": "Şifrə Yarat",
    "create_now": "İndi Yaradın",
    "password_login_subtitle": "E-poçt ilə parolsuz giriş",
    "account_login": "Hesaba Giriş",
    "rights": "Bu hesabı yaratmaqla siz <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Xidmət Şərtləri</a> və <a href=\"https://www.mindonmap.com/privacy-policy/\">Məxfilik Siyasəti</a>",
    "passwordless_login_done": "Parolsuz giriş tamamlandı",
    "passwordless_login_info": "Təbrik edirik, parolsuz girişi uğurla tamamladınız. Siz bu hesab üçün parol yarada və gələcəkdə hesab və parol ilə daxil ola bilərsiniz. <a href=\"/create-password\" style=\"display: initial;\">İndi yaradın</a>",
    "sign_up": "Abunə olun",
    "register_info": "Hesabınızı yaradın",
    "reset_now": "İndi Sıfırlayın",
    "forgot_password": "Şifrəni unutmusunuz",
    "reset_password_subtitle": "Parolu sıfırlamaq üçün hesab e-poçtunuzdan istifadə edin",
    "plan_products": "Planlar və Məhsullar",
    "nick_name": "İstifadəçi adı:",
    "email": "E-poçt:",
    "my_products": "Məhsullarım",
    "my_orders": "Sifarişlərim",
    "unlink": "Əlaqəni kəsin",
    "link": "Link",
    "connected_accounts": "Qoşulmuş hesablar",
    "last_name": "Soyad:",
    "first_name": "Ad:",
    "Gender": "Cins:",
    "Birth": "Doğum:",
    "Month": "ay",
    "Year": "il",
    "Country_Region": "Ölkə/Region:",
    "Address": "Ünvan:",
    "Save": "Yadda saxla",
    "Date": "Tarix",
    "Male": "Kişi",
    "Female": "Qadın",
    "Unspecified": "Dəqiqləşdirilməmiş, naməlum",
    "Security": "Təhlükəsizlik",
    "change_password": "Parolun dəyişdirilməsi",
    "change_now": "İndi Dəyişin",
    "connect_email": "E-poçtu birləşdirin",
    "delete_account": "Hesabı Sil",
    "delete_account_info": "Hesabınız silindikdə, hesabınızla əlaqəli Hesabımdakı bütün məlumatlar həmişəlik silinəcək və siz onu bərpa edə bilməyəcəksiniz. Ehtiyatla addımlamağınızı tövsiyə edirik.",
    "Delete": "Sil",
    "Logout": "Çıxış",
    "my_profile": "Mənim Hesabım",
    "guides_faqs": "Bələdçilər və Tez-tez verilən suallar",
    "More": "Daha çox",
    "guides": "Bələdçilər",
    "register": "qeydiyyatdan keçin",
    "hot_faq": "İsti tez-tez verilən suallar",
    "Contents": "İçindəkilər:",
    "contact_us": "Bizimlə əlaqə saxlayın>>",
    "plan": "Plan",
    "unregistered": "Qeydiyyatsız",
    "buy_more": "Daha çox satın alın",
    "buy_again": "Yenidən Alın",
    "buy_now": "İndi al",
    "free_no_limit": "Pulsuz və Limitsiz",
    "expired": "İstifadə müddəti bitdi",
    "lifetime": "Ömür boyu",
    "remain": "Qalmaq",
    "day_s": "gün(lər)",
    "error_24801": "Sorğu alınmadı (xəta kodu: 24801). Lütfən, yenidən cəhd edin",
    "no_app_found": "Heç bir proqram tapılmadı!<a href=\"/\">Yeniləyin</a> və ya <a href=\"https://www.mindonmap.com/\">Rəsmi vebsayta keçin</a>",
    "get_more": "Daha çox əldə edin >>",
    "edit_photo": "Fotonu redaktə et",
    "select_photo": "Foto seçin",
    "change_photo": "Şəkli dəyişdirin",
    "cancel": "Ləğv et",
    "hide_password": "Parolu gizlədin",
    "show_password": "Parol göstərin",
    "zoom_in": "Yaxınlaşdırma",
    "zoom_out": "Küçült",
    "rotate": "Döndürün",
    "horizontal_flip": "Üfüqi çevirmə",
    "vertical_flip": "Şaquli çevirmə",
    "country": "ölkə",
    "country_1": "Ölkənizi/regionunuzu seçin",
    "country_2": "Aland adası",
    "country_3": "Əfqanıstan",
    "country_4": "Albaniya",
    "country_5": "Əlcəzair",
    "country_6": "Amerika Samoası",
    "country_7": "Andorra",
    "country_8": "Anqola",
    "country_9": "Anguilla",
    "country_10": "Antarktida",
    "country_11": "Antiqua və Barbuda",
    "country_12": "Argentina",
    "country_13": "Ermənistan",
    "country_14": "Aruba",
    "country_15": "Avstraliya",
    "country_16": "Avstriya",
    "country_17": "Azərbaycan",
    "country_18": "Bəhreyn",
    "country_19": "Banqladeş",
    "country_20": "Barbados",
    "country_21": "Belarusiya",
    "country_22": "Belçika",
    "country_23": "Beliz",
    "country_24": "Benin",
    "country_25": "Bermud adaları",
    "country_26": "Butan",
    "country_27": "Boliviya",
    "country_28": "Bosniya və Herseqovina",
    "country_29": "Botsvana",
    "country_30": "Buvet Adası",
    "country_31": "Braziliya",
    "country_32": "Britaniya Hind Okeanı Ərazisi",
    "country_33": "Britaniya Virgin Adaları",
    "country_34": "Bruney",
    "country_35": "Bolqarıstan",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kamboca",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kabo-Verde",
    "country_42": "Karib hövzəsi Hollandiya",
    "country_43": "Kayman adaları",
    "country_44": "Mərkəzi Afrika Respublikası",
    "country_45": "Çad",
    "country_46": "Çili",
    "country_47": "Çin",
    "country_48": "Milad adası",
    "country_49": "Kokos (Kiling) adaları",
    "country_50": "Kolumbiya",
    "country_51": "Kuk adaları",
    "country_52": "Kosta Rika",
    "country_53": "Kot-d'İvuar",
    "country_54": "Xorvatiya",
    "country_55": "Kuba",
    "country_56": "Kipr",
    "country_57": "Çexiya Respublikası",
    "country_58": "Konqo Demokratik Respublikası",
    "country_59": "Danimarka",
    "country_60": "Cibuti",
    "country_61": "Dominika",
    "country_62": "Dominik respublikası",
    "country_63": "Ekvador",
    "country_64": "Misir",
    "country_65": "El Salvador",
    "country_66": "Ekvatorial Qvineya",
    "country_67": "Eritreya",
    "country_68": "Estoniya",
    "country_69": "Efiopiya",
    "country_70": "Folklend adaları",
    "country_71": "Farer adaları",
    "country_72": "Mikroneziya Federativ Dövlətləri",
    "country_73": "Fici",
    "country_74": "Finlandiya",
    "country_75": "Fransa",
    "country_76": "Fransız Qvianası",
    "country_77": "Fransız Polineziyası",
    "country_78": "Fransız Cənub Əraziləri",
    "country_79": "Qabon",
    "country_80": "Qambiya",
    "country_81": "Gürcüstan",
    "country_82": "Almaniya",
    "country_83": "Qana",
    "country_84": "Cəbəllütariq",
    "country_85": "Böyük Britaniya (Birləşmiş Krallıq; İngiltərə)",
    "country_86": "Yunanıstan",
    "country_87": "Qrenlandiya",
    "country_88": "Qrenada",
    "country_89": "Qvadelupa",
    "country_90": "Quam",
    "country_91": "Qvatemala",
    "country_92": "Gernsey",
    "country_93": "Qvineya",
    "country_94": "Qvineya-Bisau",
    "country_95": "Qayana",
    "country_96": "Haiti",
    "country_97": "Heard adası və McDonald adaları",
    "country_98": "Honduras",
    "country_99": "Honq Konq",
    "country_100": "Macarıstan",
    "country_101": "İslandiya",
    "country_102": "Hindistan",
    "country_103": "İndoneziya",
    "country_104": "İran",
    "country_105": "İraq",
    "country_106": "İrlandiya",
    "country_107": " İnsan adası",
    "country_108": "İsrail",
    "country_109": "İtaliya",
    "country_110": "Yamayka",
    "country_111": "Yaponiya",
    "country_112": "Cersi",
    "country_113": "İordaniya",
    "country_114": "Qazaxıstan",
    "country_115": "Keniya",
    "country_116": "Kiribati",
    "country_117": "Küveyt",
    "country_118": "Qırğızıstan",
    "country_119": "Laos",
    "country_120": "Latviya",
    "country_121": "Livan",
    "country_122": "Lesoto",
    "country_123": "Liberiya",
    "country_124": "Liviya",
    "country_125": "Lixtenşteyn",
    "country_126": "Litva",
    "country_127": "Lüksemburq",
    "country_128": "Makao",
    "country_129": "Madaqaskar",
    "country_130": "Malavi",
    "country_131": "Malayziya",
    "country_132": "Maldiv adaları",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marşal adaları",
    "country_136": "Martinika",
    "country_137": "Mavritaniya",
    "country_138": "Mavrikiy",
    "country_139": "Mayotte",
    "country_140": "Meksika",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Monqolustan",
    "country_144": "Monteneqro",
    "country_145": "Montserrat",
    "country_146": "Mərakeş",
    "country_147": "Mozambik",
    "country_148": "Myanma (Birma)",
    "country_149": "Namibiya",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Hollandiya",
    "country_153": "Yeni Kaledoniya",
    "country_154": "Yeni Zelandiya",
    "country_155": "Nikaraqua",
    "country_156": "Niger",
    "country_157": "Nigeriya",
    "country_158": "Niue",
    "country_159": "Norfolk Adası",
    "country_160": "Simali Koreya",
    "country_161": "Şimali Mariana adaları",
    "country_162": "Norveç",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Fələstin əraziləri",
    "country_167": "Panama",
    "country_168": "Papua Yeni Qvineya",
    "country_169": "Paraqvay",
    "country_170": "Peru",
    "country_171": "Pitkern Adaları",
    "country_172": "Polşa",
    "country_173": "Portuqaliya",
    "country_174": "Puerto Riko",
    "country_175": "Qətər",
    "country_176": "Makedoniya Respublikası (FYROM)",
    "country_177": "Konqo Respublikası",
    "country_178": "Reunion",
    "country_179": "Rumıniya",
    "country_180": "Rusiya Federasiyası",
    "country_181": "Ruanda",
    "country_182": "Sent-Bartelemi",
    "country_183": "Saint Martin (Fransa)",
    "country_184": "Saint-Pierre və Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome və Prinsipi",
    "country_188": "Səudiyyə Ərəbistanı",
    "country_189": "Seneqal",
    "country_190": "Serbiya",
    "country_191": "Seyşel adaları",
    "country_192": "Sierra Leone",
    "country_193": "Sinqapur",
    "country_194": "Slovakiya",
    "country_195": "Sloveniya",
    "country_196": "Solomon Adaları",
    "country_197": "Somali",
    "country_198": "Cənubi Afrika",
    "country_199": "Cənubi Gürcüstan və Cənub Sendviç Adası",
    "country_200": "Cənubi Koreya",
    "country_201": "Cənubi Sudan",
    "country_202": "İspaniya",
    "country_203": "Şri Lanka",
    "country_204": "Müqəddəs Yelena və Asılılıqlar",
    "country_205": "Sent Kitts və Nevis",
    "country_206": "Müqəddəs Lucia",
    "country_207": "Sent-Vinsent və Qrenadinlər",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Svazilend",
    "country_211": "İsveç",
    "country_212": "İsveçrə",
    "country_213": "Suriya",
    "country_214": "Tayvan",
    "country_215": "Tacikistan",
    "country_216": "Tanzaniya",
    "country_217": "Şablon:Ölkə data SJM Svalbard",
    "country_218": "Tayland",
    "country_219": " Baham adaları",
    "country_220": " Komor adaları",
    "country_221": "Filippin",
    "country_222": "Şərqi Timor (Şərqi Timor)",
    "country_223": "Getmək",
    "country_224": "Tokelau",
    "country_225": "Tonqa",
    "country_226": "Trinidad və Tobaqo",
    "country_227": "Tunis",
    "country_228": "Türkiyə",
    "country_229": "Türkmənistan",
    "country_230": "Turks və Kaikos Adaları",
    "country_231": "tuvalu",
    "country_232": "Uqanda",
    "country_233": "Ukrayna",
    "country_234": "Birləşmiş Ərəb Əmirlikləri",
    "country_235": "Birləşmiş Ştatlar Kiçik Xarici Adalar",
    "country_236": "Amerika Birləşmiş Ştatları (ABŞ)",
    "country_237": "ABŞ Virgin Adaları",
    "country_238": "Uruqvay",
    "country_239": "Özbəkistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikan Şəhər ( Müqəddəs Taxt )",
    "country_242": "Venesuela",
    "country_243": "Vyetnam",
    "country_244": "Wallis və Futuna",
    "country_245": "Qərbi Saxara",
    "country_246": "Yəmən",
    "country_247": "Zambiya",
    "country_248": "Zimbabve",
    "google_login": "Google ilə daxil olun",
    "State": "dövlət",
    "Activation_code": "Aktivasiya kodu",
    "Question": "Daxil olduğunuz bütün proqramları sadalayın",
    "Copy_complete": "Kopyalama tamamlandı",
    "footer": "Copyright © 2024 MindOnMap Studio. Bütün hüquqlar qorunur.",
    "change_password_success": "Parol uğurla dəyişdirildi",
    "successful_login_title": "Giriş Uğurlu",
    "product_page": "Məhsul Səhifəsi>>",
    "successful_login_info": "Giriş tamamlandı. Lütfən, cari səhifəni bağlayın və növbəti prosesə davam etmək üçün orijinal nişana qayıdın. Cari səhifə 5 saniyə ərzində avtomatik bağlanacaq. \"Bitti\" düyməsini klikləməklə avtomatik bağlama və ya bağlama uğursuz olarsa, lütfən, bu tabı birbaşa bağlayın.",
    "successful_login_info_firefox": "Giriş tamamlandı. Lütfən, cari səhifəni bağlayın və növbəti prosesə davam etmək üçün orijinal nişana qayıdın.",
    "my_account": "Mənim Hesabım",
    "my_history": "Mənim Tarixim",
    "remove_watermark": "Su nişanını silin",
    "no_history": "Tarix yoxdur",
    "history_all": "Hamısını seç",
    "history_open": "Açıq",
    "history_down": "Yüklə",
    "history_delete": "Sil",
    "history_clear": "Yanlış silin",
    "images": "şəkil(lər)",
    "use_this_function": "Bu məhsuldan istifadə edin>>",
    "hd_downloading": "HD orijinal şəkillərin yüklənməsinin üstünlükləri:",
    "lifetimeRemaining": "Ömür boyu - Qalan",
    "Remaining": "Qalan",
    "email_verification": "E-poçt Doğrulaması",
    "email_verification_info": "Doğrulama mesajını <span class=\"email\"></span> e-poçtunuza göndərdik və lütfən, yoxlamanı tamamlayın. Doğruladıqdan sonra üstünlüklər avtomatik olaraq sinxronlaşdırılacaq.",
    "wrong_email": "Səhv e-poçt ünvanı?",
    "click_here_to_modify": "Dəyişiklik etmək üçün bura klikləyin",
    "get_more_help": "Daha çox yardım alın?",
    "click_here": "Bura basın",
    "email_verification_info_success": "E-poçt hesabınızı doğruladığınız üçün təbrik edirik.",
    "email_verification_info_error": "Keçmiş linkə görə doğrulama alınmadı.",
    "registration_succeeded": "Qeydiyyat Uğurlu Oldu",
    "registration_succeeded_info_1": "Təbrik edirik! Siz uğurla qeydiyyatdan keçdiniz. Doğrulama mesajını <span class=\"email\"></span> e-poçtunuza göndərdik və lütfən, bu e-poçtun üstünlüklərindən istifadə etmək üçün doğrulamanı tamamlayın.",
    "registration_succeeded_info_2": "Əsas səhifəyə qayıtmaq və bu məhsuldan istifadə etmək üçün \"Bitti\" üzərinə klikləyin.",
    "registration_succeeded_info_3": "Cari səhifəni bağlamaq və aşağıdakı əməliyyatlar üçün Əsas səhifəyə qayıtmaq üçün \"Bitti\" üzərinə klikləyin. Bağlama uğursuz olarsa, tabı əl ilə bağlayın.",
    "verify_email": "E-poçtu təsdiqləyin",
    "registered_email_not_verified": "Qeydiyyatdan keçmiş e-poçt təsdiqlənməyib, lütfən, onu dərhal yoxlayın.",
    "email_verification_time_1": "Doğrulama e-poçtu almadınız?",
    "email_verification_time_2": "sonra",
    "email_verification_time_3": "Yenidən göndərmək üçün bura klikləyin",
    "error_26301": "Səhv kodu: 26301, bizimlə əlaqə saxlayın",
    "error_26302": "Səhv kodu: 26302, bizimlə əlaqə saxlayın",
    "error_26303": "E-poçt formatı xətası (xəta kodu: 26303). Lütfən, yenidən daxil edin",
    "error_26304": "8 simvoldan yuxarı parol tövsiyə olunur (xəta kodu: 26304)",
    "error_26305": "Reuqest uğursuz oldu (xəta kodu: 26305). Lütfən, yenidən cəhd edin",
    "error_26306": "E-poçt qeydiyyatdan keçdi, lütfən, <a href=\"https://account.mindonmap.com/login/\">daxil olun</a>",
    "error_26307": "Reuqest uğursuz oldu (xəta kodu: 26307). Lütfən, yenidən cəhd edin",
    "error_26308": "Reuqest uğursuz oldu (xəta kodu: 26308). Lütfən, yenidən cəhd edin",
    "error_26401": "Xəta kodu: 26401, yenidən cəhd edin",
    "error_26402": "E-poçt təsdiqləndi (xəta kodu: 26402), lütfən, yenidən cəhd edin",
    "error_26403": "Reuqest uğursuz oldu (xəta kodu: 26403). Lütfən, yenidən cəhd edin",
    "error_26404": "Sorğu alınmadı (xəta kodu: 26404). Lütfən, yenidən cəhd edin",
    "error_26501": "Səhv kodu: 26501, bizimlə əlaqə saxlayın",
    "error_26502": "Səhv kodu: 26502, bizimlə əlaqə saxlayın",
    "error_26503": "E-poçt formatı xətası (xəta kodu: 26503). Lütfən, yenidən daxil edin",
    "error_26504": "Reuqest uğursuz oldu (xəta kodu: 26504). Lütfən, yenidən cəhd edin",
    "error_26505": "E-poçt qeydiyyatdan keçməyib, lütfən, <a href=\"https://account.mindonmap.com/register/\">öncə onu qeydiyyatdan keçirin</a>",
    "error_26506": "E-poçt təsdiqləndi.",
    "error_26507": "Reuqest uğursuz oldu (xəta kodu: 26507). Lütfən, yenidən cəhd edin",
    "error_26508": "Doğrulama təsdiqləndi (xəta kodu: 26508), lütfən, yenidən cəhd edin",
    "error_26509": "Sorğu alınmadı (xəta kodu: 26509), yenidən cəhd edin",
    "error_26510": "Səhv kodu: 26510, bizimlə əlaqə saxlayın",
    "error_26601": "Səhv kodu: 26601, bizimlə əlaqə saxlayın",
    "error_26602": "Səhv kodu: 26602, bizimlə əlaqə saxlayın",
    "error_26603": "Reuqest uğursuz oldu (xəta kodu: 26603). Lütfən, yenidən cəhd edin",
    "error_26604": "Səhv kodu: 26604, bizimlə əlaqə saxlayın",
    "error_26605": "Səhv kodu: 26605, bizimlə əlaqə saxlayın",
    "error_26701": "Səhv kodu: 26701, bizimlə əlaqə saxlayın",
    "error_26702": "Reuqest uğursuz oldu (xəta kodu: 26702). Lütfən, yenidən cəhd edin",
    "error_26703": "Səhv kodu: 26703, bizimlə əlaqə saxlayın",
    "error_26704": "Səhv kodu: 26704, bizimlə əlaqə saxlayın",
    "error_26705": "Daxil olmağı gözləyin (xəta kodu: 26705). Lütfən, yenidən cəhd edin",
    "no_cookie": "Siz brauzerinizdə \"Bütün kukiləri blokla\" funksiyasını yandırmısınız, ona görə də daxil ola bilməyəcəksiniz. Zəhmət olmasa, \"Bütün kukilərə icazə verin\" qutusunu yoxlamaq üçün Parametrlərə daxil olun.",
    "error_26801": "Səhv kodu: 26801, bizimlə əlaqə saxlayın",
    "error_26802": "Səhv kodu: 26802, bizimlə əlaqə saxlayın",
    "error_26803": "Sorğu alınmadı (xəta kodu: 26803). Lütfən, yenidən cəhd edin",
    "error_26804": "Sorğu alınmadı (xəta kodu: 26804). Lütfən, yenidən cəhd edin",
    "error_order": "Sorğu alınmadı (xəta kodu: 27098), lütfən, yenidən cəhd edin!",
    "error_order1": "Sifariş sorğusu natamamdır（xəta kodu: ",
    "error_order2": "）, lütfən, yeniləyin və yenidən cəhd edin.",
    "modify_email_title": "E-poçtu dəyişdirin",
    "modify_email_info": "Hesabınıza daxil olmaq üçün dəyişdirilmiş e-poçtdan istifadə edə bilərsiniz.",
    "images_per": "Şəkillər başına",
    "error_26101": "Xəta: 26101. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26102": "Xəta: 26102. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26103": "Sorğu alınmadı (xəta kodu:26103). Lütfən, yenidən cəhd edin",
    "error_26104": "Xəta kodu: 26104, yenidən cəhd edin",
    "error_26105": "Xəta kodu: 26105, yenidən cəhd edin",
    "error_26106": "Silinmə  uğursuz (xəta kodu: 26106). Lütfən, yenidən cəhd edin",
    "error_26201": "Xəta: 26201. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26202": "Sorğu alınmadı (xəta kodu:26202). Lütfən, yenidən cəhd edin",
    "error_26001": "Xəta: 26001. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26002": "Xəta: 26002. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26003": "Xəta: 26003. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26004": "Xəta: 26004. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_26005": "Sorğu alınmadı (xəta kodu:26005). Lütfən, yenidən cəhd edin",
    "error_26006": "Xəta kodu: 26006, lütfən, yenidən cəhd edin",
    "error_26008": "Xəta: 26008. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "go_to_the_home_page": "Əsas səhifəyə keçin",
    "error_27101": "Sorğu alınmadı (xəta kodu: 27101). Zəhmət olmasa yenidən cəhd edin",
    "error_27201": "Səhv kodu: 27201, bizimlə əlaqə saxlayın",
    "error_27202": "Xəta kodu: 27202, yenidən cəhd edin",
    "error_27203": "Sorğu alınmadı (xəta kodu: 27203). Lütfən, yenidən cəhd edin",
    "error_27204": "Yanlış kod (xəta kodu: 27204).",
    "error_27205": "Sorğu alınmadı (xəta kodu: 27205). Lütfən, yenidən cəhd edin",
    "error_27206": "Sorğu alınmadı (xəta kodu: 27206). Lütfən, yenidən cəhd edin",
    "error_27207": "Sorğu alınmadı (xəta kodu: 27207). Lütfən, yenidən cəhd edin",
    "no_history_found": "Heç bir alətdən istifadə etməmisiniz! <a href=\"/\">Yeniləyin</a> və ya <a href=\"https://www.mindonmap.com/\">Rəsmi vebsayta keçin</a>",
    "error_25301": "Xəta: 25301. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_25302": "Xəta: 25302. Zəhmət olmasa bizimlə əlaqə saxlayın",
    "error_25303": "Sorğu alınmadı (xəta kodu: 25303). Lütfən, yenidən cəhd edin",
    "error_25304": "Sorğu alınmadı (xəta kodu: 25304). Lütfən, yenidən cəhd edin",
    "error_25305": "Sorğu alınmadı (xəta kodu: 25305). Lütfən, yenidən cəhd edin",
    "error_25306": "Sorğu alınmadı (xəta kodu: 25306). Lütfən, yenidən cəhd edin",
    "image_upscaler_p": "Su nişanı olmadan şəkil yükləmək üstünlükləri:",
    "Available_for": "Üçün əlçatandır:",
    "credit_per": "Hər HD təsvir üçün %s kredit",
    "still_valid": "Alınmış plan(lar) hələ də qüvvədədir",
    "credit": "kredit(lər)",
    "pc_3rd_info": "Uğurla daxil olun. Zəhmət olmasa əlavə əməliyyat üçün tətbiqə keçin.",
    "use_online": "Onlayn xidmətdən istifadə edin",
    "use_download": "İş masası proqramından istifadə edin",
    "use_immediately": "Dərhal istifadə edin",
    "Use_in_browser": "Brauzerdə istifadə edin",
    "win_desktop": "Windows",
    "Mac_desktop": "Makintoş",
    "credits_per": "Ayda {%} Kredit",
    "expire": "İstifadə müddəti:",
    "viewDetails": "Detallara baxın",
    "viewHistory": "Tarixin faydalarına baxın>>",
    "viewDetailsInfo": "Qeyd: Abunəlik faydaları müddəti bitdikdən sonra 7 gün ərzində yenilənərsə, istifadə olunmamış faydalar istifadə olunmağa davam edə bilər. Həmçinin, bitmə vaxtı avtomatik olaraq yeni abunəliyin bitmə vaxtına qədər yenilənəcək. 7 gün müddətinin bitməsindən sonra yeni abunə olmadıqda, vaxtı keçmiş bütün faydalar silinəcək.",
    "connect_account": "E-poçtu Hesabınıza Bağlayın",
    "connect_account_info": "Bağladıqdan sonra bu e-poçt ünvanı ilə daxil ola bilərsiniz.",
    "connect_now": "İndi Bağlayın",
    "no_email_bind": "E-poçt bağlaması yoxdur",
    "bind_email": "E-poçtu bağlayın",
    "connect_your_email_placeholder": "E-poçt ünvanınızı daxil edin",
    "bind_an_email": "E-poçtu bağlayın",
    "bind_info": "Siz uğurla daxil oldunuz. Faydalarınızı aktivləşdirmək üçün hesabınıza e-poçt göndərin.",
    "bind_later": "Daha sonra bağlayın",
    "hi": "Salam, %s!",
    "Personal_Information": "Şəxsi məlumat",
    "Access": "Giriş",
    "Subscription_Plan": "(Abunəlik Planı)",
    "No_orders": "Sifariş tapılmadı.",
    "No_data": "Məlumat yoxdur",
    "Featured_Products": "Seçilmiş Məhsullar",
    "More_Products": "Daha çox məhsul",
    "Free_Download": "Pulsuz Yüklə",
    "Get_Started": "Başlamaq",
    "Subscribe": "Abunə ol",
    "Verified": "Doğrulanıb",
    "back_to_account_center": "Hesab Mərkəzinə qayıt",
    "success": "Uğurlar!",
    "successfully": "Siz hesabı uğurla qeydiyyatdan keçirdiniz.",
    "Continue": "Davam et",
    "Already": "Artıq bir hesabınız var mı?",
    "loading_verification": "Doğrulama statusu yoxlanılır...",
    "email_no_verification": "Üzr istəyirik, qeydiyyatdan keçmiş e-poçt ünvanı təsdiqlənməyib. Yuxarıdakı təlimatlara uyğun olaraq yoxlamanı tamamlayın və qeydiyyatı tamamlamaq üçün yenidən \"Tamamlandı\" düyməsini klikləyin.",
    "will_expire_after": "sonra bitəcək",
    "hours": "saat",
  },
  "bg": {
    "overtime": "Код на грешка: {%}, моля, опитайте отново",
    "isnetwork": "Интернет грешка. Моля, проверете и опитайте отново",
    "email_placeholder": "електронна поща",
    "email_empty": "Моля, въведете имейл",
    "email_not_valid": "Имейлът не е валиден",
    "email_not_valid_1": "Моля, въведете вашия имейл",
    "email_not_valid_2": "Имейлът не е валиден, моля, използвайте друг адрес.",
    "email_not_valid_3": "Без въвеждане на имейл",
    "password_placeholder": "Парола",
    "password_empty": "Моля, въведете парола",
    "password_not_valid": "Неправилен акаунт или парола",
    "password_not_valid_1": "Изисква се парола над 8 знака",
    "password_not_valid_2": "Моля, създайте парола",
    "password_placeholder_1": "Създайте вашата парола",
    "password_placeholder_2": "Потвърдите паролата",
    "password_placeholder_3": "Създайте нова парола",
    "password_placeholder_4": "Потвърждение на новата парола",
    "password_placeholder_5": "Въведете стара парола",
    "copy_password_empty": "Моля, потвърдете паролата",
    "copy_password_not_valid": "Моля, потвърдете паролата си",
    "copy_passwords_inconsistent": "Потвърждението на вашата парола не съвпада",
    "code_empty": "Моля, въведете код за потвърждение",
    "code_not_valid": "невалиден код за верификация",
    "code_placeholder": "Код за потвърждение",
    "not_received_code": "Ако вашата пощенска кутия не е получавала кода за потвърждение дълго време, моля, получете кода за потвърждение отново.",
    "get_first_code": "Моля, първо вземете кода за потвърждение.",
    "last_name_placeholder": "Моля, въведете вашето фамилно име",
    "first_name_placeholder": "Моля въведете собственото си име",
    "address_placeholder": "Моля, въведете вашия адрес",
    "no_code_text": "Изпратихме код за потвърждение. Моля, въведете своя код. <span class='tips'>Не сте получили код?",
    "no_code_text_1": "1. Моля, уверете се, че имейл адресът е валиден и може да получава имейли.",
    "no_code_text_2": "2. Тъй като имейлът се изпраща автоматично от системата, той може да бъде маркиран като спам или нежелана поща. Моля, проверете дали имейлът е в папката Кошче.",
    "no_code_text_3": "3. Не можете да разрешите проблема си? ",
    "no_code_text_3_span": "След това щракнете тук, за да се свържете с нас.",
    "order_no": "Не сте закупили никакъв продукт, ако имате въпроси, моля, <a href=\"https://www.mindonmap.com/contact-us/\">свържете се с нас</a>.",
    "error_24901": "Текущият акаунт няма свързан имейл и не може да намери поръчките. Моля, свържете имейл.",
    "user_guide": "Ръководство за потребителя>>",
    "download": "Изтегли",
    "order_number": "Номер на поръчка:",
    "Refund": "Възстановяване",
    "Disabled": "хора с увреждания",
    "Normal": "нормално",
    "Modify": "Променете",
    "Modify_1": "Промяна>>",
    "Connect": "Свържете се",
    "unlink_success": "Прекратете връзката успешно",
    "connect_success": "Свържете се успешно",
    "feedback_title": "Благодаря за обратната връзка. Моля, оставете проблема си и ние ще ви отговорим в рамките на 24 часа.",
    "feedback_thank_you": "Благодарим ви!<br />Вашите отзиви бяха изпратени успешно.",
    "feedback_email": "Въведете своя имейл тук!",
    "feedback_content": "Оставете всеки проблем или предложение, които сте срещнали тук.",
    "feedback_submit": "Изпращане",
    "form_contents": "Не сте въвели описание. Моля, въведете го и изпратете отново.",
    "old_password": "Моля, въведете стара парола",
    "new_password": "Моля, създайте нова парола",
    "old_new_password": "Новата парола не може да бъде същата като старата",
    "incorrect_password": "грешна парола",
    "delete_no": "Изтрий сега",
    "Caps": "Главните букви са включени",
    "Get": "Вземете",
    "Done": "Свършен",
    "error_20001": "Грешка: 20001. Моля, влезте отново.",
    "error_20002": "Грешка: 20002. Моля, влезте отново.",
    "error_20003": "Грешка: 20003. Моля, влезте отново.",
    "error_20004": "Неуспешна заявка (код на грешка: 20004). Моля, опитайте отново.",
    "error_20005": "Сесията за влизане е изтекла (Грешка: 20005). Моля, влезте отново.",
    "error_20006": "Неуспешна заявка (код на грешка: 20006). Моля, опитайте отново.",
    "error_20007": "Сесията за влизане е изтекла (Грешка: 20007). Моля, влезте отново.",
    "error_20008": "Сесията за влизане е изтекла (Грешка: 20008). Моля, влезте отново.",
    "error_20009": "Сесията за влизане е изтекла (Грешка: 20009). Моля, влезте отново.",
    "error_20101": "Моля, въведете своя имейл (код на грешка: 20101)",
    "error_20102": "Имейлът не е валиден (код на грешка: 20102)",
    "error_20103": "Неуспешна заявка (код на грешка: 20103). Моля, опитайте отново",
    "error_20104": "Имейлът вече се използва, моля <a href=\"https://account.mindonmap.com/login/\">влезте</a> или се регистрирайте с нов",
    "error_20105": "Неуспешна заявка (код на грешка: 20105). Моля, опитайте отново",
    "error_20106": "Неуспешно изпращане на имейл, моля, опитайте отново",
    "error_20201": "Моля, въведете вашия имейл (код на грешка: 20201)",
    "error_20202": "Моля, въведете вашата парола (код на грешка: 20202)",
    "error_20203": "Моля, въведете код за потвърждение (код на грешка: 20203)",
    "error_20204": "Имейлът не е валиден (код на грешка: 20204)",
    "error_20205": "Изисква се парола над 8 знака (код на грешка: 20205)",
    "error_20206": "Неуспешна заявка (код на грешка: 20206). Моля, опитайте отново",
    "error_20207": "невалиден код за верификация",
    "error_20208": "Неуспешна заявка (код на грешка: 20208). Моля, опитайте отново",
    "error_20209": "Неуспешна заявка (код на грешка: 20209). Моля, опитайте отново",
    "error_20301": "Моля, въведете своя имейл (код на грешка: 20301)",
    "error_20302": "Грешка: 20302. Моля, свържете се с нас",
    "error_20303": "Имейлът не е валиден (код на грешка: 20303)",
    "error_20304": "Неуспешна заявка (код на грешка: 20304). Моля, опитайте отново",
    "error_20305": "Акаунтът не съществува. Моля, въведете отново или първо го <a href=\"https://account.mindonmap.com/register/\">създайте</a>.",
    "error_20306": "Все още няма парола. Използвайте <a href=\"https://account.mindonmap.com/passwordless-login/\">Вход без парола</a> или <a href=\"https://account.mindonmap.com/create-password/\"> задайте парола</a> и влезте.",
    "error_20308": "Неуспешна заявка (код на грешка: 20308). Моля, опитайте отново",
    "error_20401": "Неуспешно излизане (код на грешка: 20401). Моля, опитайте отново",
    "error_20501": "Моля, въведете своя имейл (код на грешка: 20501)",
    "error_20502": "Имейлът не е валиден (код на грешка: 20502)",
    "error_20503": "Заявката е неуспешна (код на грешка: 20503). Моля, опитайте отново",
    "error_20504": "Неуспешно изпращане на имейл. Моля, опитайте отново.",
    "error_20601": "Моля, въведете своя имейл (код на грешка: 20601)",
    "error_20602": "Моля, въведете код за потвърждение (код на грешка: 20602)",
    "error_20603": "Имейлът не е валиден (код на грешка: 20603)",
    "error_20604": "Заявката е неуспешна (код на грешка: 20604). Моля, опитайте отново",
    "error_20606": "Неуспешна заявка (код на грешка: 20606). Моля, опитайте отново",
    "error_20607": "Неуспешна заявка (код на грешка: 20607). Моля, опитайте отново",
    "error_20608": "Неуспешна заявка (код на грешка: 20608). Моля, опитайте отново",
    "error_20701": "Моля, въведете своя имейл (код на грешка: 20701)",
    "error_20702": "Имейлът не е валиден (код на грешка: 20702)",
    "error_20703": "Неуспешна заявка (код на грешка: 20703). Моля, опитайте отново",
    "error_20704": "Акаунтът не съществува. Моля, въведете отново или първо го <a href=\"https://account.mindonmap.com/register/\">създайте</a>.",
    "error_20705": "Неуспешна заявка (код на грешка: 20705). Моля, опитайте отново",
    "error_20706": "Неуспешно изпращане на имейл. Моля, опитайте отново",
    "error_20801": "Моля, въведете вашия имейл (код на грешка: 20801)",
    "error_20802": "Грешка: 20802. Моля, свържете се с нас",
    "error_20803": "Моля, въведете код за потвърждение (код на грешка: 20803)",
    "error_20804": "Имейлът не е валиден (код на грешка: 20804)",
    "error_20805": "Изисква се парола над 8 знака (код на грешка: 20805)",
    "error_20806": "Неуспешна заявка (код на грешка: 20806). Моля, опитайте отново",
    "error_20808": "Неуспешна заявка (код на грешка: 20808). Моля, опитайте отново",
    "error_20901": "Неуспешна заявка (код на грешка: 20901). Моля, опитайте отново",
    "error_20902": "Неуспешна заявка (код на грешка: 20902). Моля, опитайте отново",
    "error_21000": "Промените се запазват",
    "error_21001": "Не е подадена информация (код на грешка: 21001)",
    "error_21002": "Неуспешна заявка (код на грешка: 21002). Моля, опитайте отново",
    "error_21101": "Моля, въведете своя имейл (код на грешка: 21101)",
    "error_21102": "Имейлът не е валиден (код на грешка: 21102)",
    "error_21103": "Заявката е неуспешна (код на грешка: 21103), моля, опитайте отново",
    "error_21104": "Имейлът вече е свързан, моля, използвайте нов",
    "error_21105": "Заявката е неуспешна (код на грешка: 21105), моля, опитайте отново",
    "error_21106": "Неуспешно изпращане на имейл. Моля, опитайте отново",
    "error_21201": "Моля, въведете своя имейл (код на грешка: 21201)",
    "error_21202": "Моля, въведете код за потвърждение (код на грешка: 21202)",
    "error_21203": "Имейлът не е валиден (код на грешка: 21203)",
    "error_21204": "Грешка: 21204. Моля, свържете се с нас",
    "error_21205": "Грешка: 21205. Моля, свържете се с нас",
    "error_21206": "Изисква се парола над 8 знака (код на грешка: 21206)",
    "error_21207": "Неуспешна заявка (код на грешка: 21207). Моля, опитайте отново",
    "error_21209": "Неуспешна заявка (код на грешка: 21209). Моля, опитайте отново",
    "error_21301": "Моля, въведете стара парола (код на грешка: 21301)",
    "error_21302": "Моля, създайте нова парола (код на грешка: 21302)",
    "error_21303": "Новата парола не може да бъде същата като старата. (Грешка: 21303)",
    "error_21304": "Изисква се парола над 8 знака (код на грешка: 21304)",
    "error_21306": "Неуспешна заявка (код на грешка: 21306). Моля, опитайте отново",
    "error_21402": "Заявката е неуспешна (код на грешка: 21402). Моля, опитайте отново",
    "error_21403": "Неуспешно изпращане на код за потвърждение. Моля, изпратете го отново",
    "error_21500": "Акаунтът е изтрит",
    "error_21501": "Моля, въведете код за потвърждение (код на грешка: 21501)",
    "error_21502": "Сесията за влизане е изтекла (Грешка: 21502). Моля, влезте отново.",
    "error_21503": "Неуспешна заявка (код на грешка: 21503). Моля, опитайте отново",
    "error_21505": "Заявката е неуспешна (код на грешка: 21505), моля, опитайте отново",
    "error_21601": "Грешка: 20601. Моля, свържете се с нас",
    "error_21602": "Невалидна проверка (грешка: 20602). Моля, опитайте отново.",
    "error_21603": "Грешка: 20603. Моля, опитайте отново",
    "error_21604": "Неуспешна заявка (код на грешка: 21604). Моля, опитайте отново",
    "error_21606": "Неуспешна заявка (код на грешка: 21606). Моля, опитайте отново",
    "error_21611": "Неуспешна заявка (код на грешка: 21611). Моля, опитайте отново",
    "error_21801": "Грешка: 21801. Моля, свържете се с нас",
    "error_21802": "Заявката е неуспешна (Грешка: 21802). Моля, опитайте отново",
    "error_21803": "Грешка: 21803. Моля, опитайте отново",
    "error_21804": "Неуспешна заявка (код на грешка: 21804). Моля, опитайте отново",
    "error_21806": "Грешка: 21806. Моля, опитайте отново",
    "error_21807": "Грешка: 21807. Моля, свържете се с нас",
    "error_21808": "Грешка: 21808. Моля, свържете се с нас",
    "error_21809": "Грешка: 21809. Моля, свържете се с нас",
    "error_21810": "Грешка: 21810. Моля, свържете се с нас",
    "error_21811": "Грешка: 21811. Моля, свържете се с нас",
    "error_21812": "Грешка: 21812. Моля, свържете се с нас",
    "error_21813": "Неуспешна заявка (код на грешка: 21813). Моля, опитайте отново",
    "error_21814": "Грешка: 21814. Моля, свържете се с нас",
    "error_21815": "Неуспешна заявка (код на грешка: 21815). Моля, опитайте отново",
    "error_21816": "Грешка: 21816. Моля, свържете се с нас",
    "error_21817": "Грешка: 21817. Моля, свържете се с нас",
    "error_21818": "Грешка: 21818. Моля, свържете се с нас",
    "error_21819": "Неуспешна заявка (код на грешка: 21819). Моля, опитайте отново",
    "error_21820": "Грешка: 21820. Моля, свържете се с нас",
    "error_21821": "Грешка: 21821. Моля, свържете се с нас",
    "error_21822": "Грешка: 21822. Моля, свържете се с нас",
    "error_21823": "Неуспешна заявка (код на грешка: 21823). Моля, опитайте отново",
    "error_21824": "Неуспешна заявка (код на грешка: 21824). Моля, опитайте отново",
    "error_21825": "Неуспешна заявка (код на грешка: 21825). Моля, опитайте отново",
    "error_21826": "Неуспешна заявка (код на грешка: 21826). Моля, опитайте отново",
    "error_21901": "Грешка: 21901. Моля, свържете се с нас",
    "error_21902": "Неуспешна заявка (код на грешка: 21902). Моля, опитайте отново",
    "error_21903": "Състоянието на акаунта е променено (код на грешка: 21903), моля, опреснете страницата и опитайте отново",
    "error_21904": "Грешка: 21904. Моля, опитайте отново",
    "error_21905": "Грешка: 21905. Моля, опитайте отново",
    "error_21906": "Заявката е неуспешна (код на грешка: 21906). Моля, опитайте отново",
    "error_21907": "Профилът в Google е свързан с друг акаунт",
    "error_21908": "Неуспешна заявка (код на грешка: 21908). Моля, опитайте отново",
    "error_22001": "Неуспешна заявка (код на грешка: 22001). Моля, опитайте отново",
    "error_22002": "Прекратяването на връзката не бе успешно без допълнително влизане",
    "error_22003": "Неуспешна заявка (код на грешка: 22003). Моля, опитайте отново",
    "error_22101": "Грешка: 22101. Моля, свържете се с нас",
    "error_22102": "Състоянието на акаунта е променено (код на грешка: 22102), моля, опреснете страницата и опитайте отново",
    "error_22103": "Заявката е неуспешна (код на грешка: 22103). Моля, опитайте отново",
    "error_22104": "Състоянието на акаунта е променено (код на грешка: 22104), моля, опреснете страницата и опитайте отново",
    "error_22105": "Грешка: 22105. Моля, опитайте отново",
    "error_22106": "Грешка: 22106. Моля, опитайте отново",
    "error_22107": "Грешка: 22107. Моля, свържете се с нас",
    "error_22108": "Неуспешна заявка (код на грешка: 22108). Моля, опитайте отново",
    "error_22201": "Грешка: 22201. Моля, свържете се с нас",
    "error_22202": "Невалидна проверка (грешка: 22202). Моля, опитайте отново.",
    "error_22203": "Грешка: 22203. Моля, опитайте отново\"",
    "error_22204": "Неуспешна заявка (код на грешка: 22204). Моля, опитайте отново",
    "error_22206": "Неуспешна заявка (код на грешка: 22206). Моля, опитайте отново",
    "error_22401": "Грешка: 22401. Моля, свържете се с нас",
    "error_22402": "Невалидна проверка (грешка: 22402). Моля, опитайте отново.",
    "error_22403": "Грешка: 22403. Моля, опитайте отново",
    "error_22404": "Неуспешна заявка (код на грешка: 22404). Моля, опитайте отново",
    "error_22405": "Facebook акаунтът е свързан с друг имейл",
    "error_22406": "Грешка: 22406. Моля, опитайте отново",
    "error_22407": "Грешка: 22407. Моля, свържете се с нас",
    "error_22408": "Грешка: 22408. Моля, свържете се с нас",
    "error_22409": "Грешка: 22409. Моля, свържете се с нас",
    "error_22410": "Грешка: 224010. Моля, свържете се с нас",
    "error_22411": "Грешка: 224011. Моля, свържете се с нас",
    "error_22412": "Грешка: 224012. Моля, свържете се с нас",
    "error_22413": "Грешка: 22413. Моля, свържете се с нас",
    "error_22414": "Неуспешна заявка (код на грешка: 22414). Моля, опитайте отново",
    "error_22415": "Грешка: 22415. Моля, свържете се с нас",
    "error_22416": "Грешка: 22416. Моля, свържете се с нас",
    "error_22417": "Грешка: 22417. Моля, свържете се с нас",
    "error_22418": "Неуспешна заявка (код на грешка: 22418). Моля, опитайте отново",
    "error_22419": "Грешка: 22419. Моля, свържете се с нас",
    "error_22420": "Грешка: 22420. Моля, свържете се с нас",
    "error_22421": "Грешка: 22421. Моля, свържете се с нас",
    "error_22422": "Неуспешна заявка (код на грешка: 22422). Моля, опитайте отново",
    "error_22423": "Неуспешна заявка (код на грешка: 22423). Моля, опитайте отново",
    "error_22424": "Неуспешна заявка (код на грешка: 22424). Моля, опитайте отново",
    "error_22425": "Неуспешна заявка (код на грешка: 22425). Моля, опитайте отново",
    "error_20098": "Код на грешка: 20098. Ако сърфирате в личен режим, моля, превключете на нормален режим и опитайте отново.",
    "error_22298": "Неуспешна заявка за Google (Грешка: 22298). Моля, опитайте отново.",
    "error_22498": "Неуспешна заявка за Facebook (Грешка: 22498). Моля, опитайте отново.",
    "error_24902": "Заявката е неуспешна (код на грешка: 24902). Моля, опитайте отново",
    "error_24903": "Неуспешна заявка (код на грешка: 24903). Моля, опитайте отново",
    "error_24904": "Неуспешна заявка (код на грешка: 24904). Моля, опитайте отново",
    "error_24905": "Неуспешна заявка (код на грешка: 24905). Моля, опитайте отново",
    "login_title": "Влезте в MindOnMap",
    "log_in": "Влизам",
    "no_account": "Нямате акаунт?",
    "create_it": "Създайте го",
    "or_log_in_with": "Или влезте с",
    "passwordless_login": "Вход без парола",
    "log_in_done": "Входът е готов",
    "three_rd_account_connect_info": "Честито! Влязохте успешно. Сега можете да свържете един имейл акаунт, който ще се използва за влизане в бъдеще.",
    "see_my_account": "Вижте акаунта ми",
    "three_rd_login_merge_account": "Имейл адресът на акаунта на трета страна е регистриран, искате ли да се свържете и да влезете директно с този имейл адрес?",
    "connect_log_in": "Свържете се и влезте",
    "create_an_account": "Създай профил",
    "back_to_log_in": "Назад към влизане",
    "create_password": "Създай парола",
    "create_now": "Създайте сега",
    "password_login_subtitle": "Вход без парола с имейл",
    "account_login": "Влизане в акаунта",
    "rights": "Създавайки този акаунт, вие се съгласявате с <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Общите условия</a> и <a href=\"https://www.mindonmap.com/privacy-policy/\">Правила за поверителност</a>",
    "passwordless_login_done": "Входът без парола е извършен",
    "passwordless_login_info": "Поздравления, завършихте успешно влизането без парола. Можете да създадете парола за този акаунт и да влизате с акаунта и паролата в бъдеще. <a href=\"/create-password\" style=\"display: initial;\">Създайте сега</a>",
    "sign_up": "Регистрирай се",
    "register_info": "Създайте своя акаунт",
    "reset_now": "Нулирайте сега",
    "forgot_password": "Забравена парола",
    "reset_password_subtitle": "Използвайте имейла на акаунта си, за да нулирате паролата",
    "plan_products": "Планове и продукти",
    "nick_name": "потребителско име:",
    "email": "Електронна поща:",
    "my_products": "Моите продукти",
    "my_orders": "Моите поръчки",
    "unlink": "Прекратете връзката",
    "link": "Връзка",
    "connected_accounts": "Свързани акаунти",
    "last_name": "Фамилия:",
    "first_name": "Първо име:",
    "Gender": "Пол:",
    "Birth": "раждане:",
    "Month": "месец",
    "Year": "година",
    "Country_Region": "Държавен регион:",
    "Address": "адрес:",
    "Save": "Запазване",
    "Date": "Дата",
    "Male": "Мъжки",
    "Female": "Женски пол",
    "Unspecified": "Неуточнено",
    "Security": "Сигурност",
    "change_password": "Промяна на паролата",
    "change_now": "Промени сега",
    "connect_email": "Свържете имейл",
    "delete_account": "Изтриване на акаунт",
    "delete_account_info": "Когато вашият акаунт бъде изтрит, всички данни в Моят акаунт, свързани с вашия акаунт, ще бъдат изтрити за постоянно и е възможно да не успеете да ги възстановите. Препоръчваме ви да действате предпазливо.",
    "Delete": "Изтрий",
    "Logout": "Излез от профила си",
    "my_profile": "Моят профил",
    "guides_faqs": "Ръководства и ЧЗВ",
    "More": "| Повече ▼",
    "guides": "Ръководства",
    "register": "регистрирам",
    "hot_faq": "Горещи ЧЗВ",
    "Contents": "Съдържание:",
    "contact_us": "Свържете се с нас>>",
    "plan": "Планирайте",
    "unregistered": "Нерегистриран",
    "buy_more": "Купете повече",
    "buy_again": "Купете отново",
    "buy_now": "Купи сега",
    "free_no_limit": "Безплатно и без ограничение",
    "expired": "Просрочен",
    "lifetime": "Живот",
    "remain": "Останете",
    "day_s": "ден (дни)",
    "error_24801": "Неуспешна заявка (код на грешка: 24801). Моля, опитайте отново",
    "no_app_found": "Няма намерено приложение！<a href=\"/\">Опреснете</a> или <a href=\"https://www.mindonmap.com/\">Отидете на официалния уебсайт</a>",
    "get_more": "Получете повече >>",
    "edit_photo": "Редактиране на снимка",
    "select_photo": "Изберете снимка",
    "change_photo": "Смени снимката",
    "cancel": "Отказ",
    "hide_password": "Скриване на паролата",
    "show_password": "Покажи парола",
    "zoom_in": "Увеличавам",
    "zoom_out": "Отдалечавам",
    "rotate": "Завъртете",
    "horizontal_flip": "Хоризонтално обръщане",
    "vertical_flip": "Вертикално обръщане",
    "country": "Държава",
    "country_1": "Изберете вашата държава/регион",
    "country_2": "Остров Ааланд",
    "country_3": "Афганистан",
    "country_4": "Албания",
    "country_5": "Алжир",
    "country_6": "Американска Самоа",
    "country_7": "Андора",
    "country_8": "Ангола",
    "country_9": "Ангуила",
    "country_10": "Антарктика",
    "country_11": "Антигуа и Барбуда",
    "country_12": "Аржентина",
    "country_13": "Армения",
    "country_14": "Аруба",
    "country_15": "Австралия",
    "country_16": "Австрия",
    "country_17": "Азербайджан",
    "country_18": "Бахрейн",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Беларус",
    "country_22": "Белгия",
    "country_23": "Белиз",
    "country_24": "Бенин",
    "country_25": "Бермудите",
    "country_26": "Бутан",
    "country_27": "Боливия",
    "country_28": "Босна и Херцеговина",
    "country_29": "Ботсвана",
    "country_30": "Остров Буве",
    "country_31": "Бразилия",
    "country_32": "Британска територия в Индийския океан",
    "country_33": "Британски Вирджински острови",
    "country_34": "Бруней",
    "country_35": "България",
    "country_36": "Буркина",
    "country_37": "Бурунди",
    "country_38": "Камбоджа",
    "country_39": "Камерун",
    "country_40": "Канада",
    "country_41": "Кабо Верде",
    "country_42": "Карибска Нидерландия",
    "country_43": "Кайманови острови",
    "country_44": "Централноафриканска република",
    "country_45": "Чад",
    "country_46": "Чили",
    "country_47": "Китай",
    "country_48": "Коледен остров",
    "country_49": "Кокосови (Кийлинг) острови",
    "country_50": "Колумбия",
    "country_51": "Острови Кук",
    "country_52": "Коста Рика",
    "country_53": "Кот д'Ивоар",
    "country_54": "Хърватия",
    "country_55": "Куба",
    "country_56": "Кипър",
    "country_57": "Чехия",
    "country_58": "Демократична република Конго",
    "country_59": "Дания",
    "country_60": "Джибути",
    "country_61": "Доминика",
    "country_62": "Доминиканска република",
    "country_63": "Еквадор",
    "country_64": "Египет",
    "country_65": "Ел Салвадор",
    "country_66": "Екваториална Гвинея",
    "country_67": "Еритрея",
    "country_68": "Естония",
    "country_69": "Етиопия",
    "country_70": "Фолкландски острови",
    "country_71": "Фарьорските острови",
    "country_72": "Федеративни щати Микронезия",
    "country_73": "Фиджи",
    "country_74": "Финландия",
    "country_75": "Франция",
    "country_76": "Френска Гвиана",
    "country_77": "Френска полинезия",
    "country_78": "Френски южни територии",
    "country_79": "Габон",
    "country_80": "Гамбия",
    "country_81": "Грузия",
    "country_82": "Германия",
    "country_83": "Гана",
    "country_84": "Гибралтар",
    "country_85": "Великобритания (Обединено кралство; Англия)",
    "country_86": "Гърция",
    "country_87": "Гренландия",
    "country_88": "Гренада",
    "country_89": "Гваделупа",
    "country_90": "Гуам",
    "country_91": "Гватемала",
    "country_92": "Гърнси",
    "country_93": "Гвинея",
    "country_94": "Гвинея-Бисау",
    "country_95": "Гвиана",
    "country_96": "Хаити",
    "country_97": "Остров Хърд и Макдоналд",
    "country_98": "Хондурас",
    "country_99": "Хонг Конг",
    "country_100": "Унгария",
    "country_101": "Исландия",
    "country_102": "Индия",
    "country_103": "Индонезия",
    "country_104": "Иран",
    "country_105": "Ирак",
    "country_106": "Ирландия",
    "country_107": "Остров Ман",
    "country_108": "Израел",
    "country_109": "Италия",
    "country_110": "Ямайка",
    "country_111": "Япония",
    "country_112": "Джърси",
    "country_113": "Джордан",
    "country_114": "Казахстан",
    "country_115": "Кения",
    "country_116": "Кирибати",
    "country_117": "Кувейт",
    "country_118": "Киргизстан",
    "country_119": "Лаос",
    "country_120": "Латвия",
    "country_121": "Ливан",
    "country_122": "Лесото",
    "country_123": "Либерия",
    "country_124": "Либия",
    "country_125": "Лихтенщайн",
    "country_126": "Литва",
    "country_127": "Люксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малави",
    "country_131": "Малайзия",
    "country_132": "Малдивите",
    "country_133": "Мали",
    "country_134": "Малта",
    "country_135": "Маршалови острови",
    "country_136": "Мартиника",
    "country_137": "Мавритания",
    "country_138": "Мавриций",
    "country_139": "Майот",
    "country_140": "Мексико",
    "country_141": "Молдова",
    "country_142": "Монако",
    "country_143": "Монголия",
    "country_144": "Черна гора",
    "country_145": "Монсерат",
    "country_146": "Мароко",
    "country_147": "Мозамбик",
    "country_148": "Мианмар (Бирма)",
    "country_149": "Намибия",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Холандия",
    "country_153": "Нова Каледония",
    "country_154": "Нова Зеландия",
    "country_155": "Никарагуа",
    "country_156": "Нигер",
    "country_157": "Нигерия",
    "country_158": "Ниуе",
    "country_159": "Остров Норфолк",
    "country_160": "Северна Корея",
    "country_161": "Северни Мариански острови",
    "country_162": "Норвегия",
    "country_163": "Оман",
    "country_164": "Пакистан",
    "country_165": "Палау",
    "country_166": "палестински територии",
    "country_167": "Панама",
    "country_168": "Папуа-Нова Гвинея",
    "country_169": "Парагвай",
    "country_170": "Перу",
    "country_171": "Острови Питкерн",
    "country_172": "Полша",
    "country_173": "Португалия",
    "country_174": "Пуерто Рико",
    "country_175": "Катар",
    "country_176": "Република Македония (БЮРМ)",
    "country_177": "Република Конго",
    "country_178": "Реюнион",
    "country_179": "Румъния",
    "country_180": "Руска федерация",
    "country_181": "Руанда",
    "country_182": "Сен Бартелеми",
    "country_183": "Сен Мартен (Франция)",
    "country_184": "Сен Пиер и Микелон",
    "country_185": "Самоа",
    "country_186": "Сан Марино",
    "country_187": "Сао Томе и Принсипи",
    "country_188": "Саудитска Арабия",
    "country_189": "Сенегал",
    "country_190": "Сърбия",
    "country_191": "Сейшели",
    "country_192": "Сиера Леоне",
    "country_193": "Сингапур",
    "country_194": "Словакия",
    "country_195": "Словения",
    "country_196": "Соломонови острови",
    "country_197": "Сомалия",
    "country_198": "Южна Африка",
    "country_199": "Южна Джорджия и Южен Сандвичев остров",
    "country_200": "Южна Кореа",
    "country_201": "Южен Судан",
    "country_202": "Испания",
    "country_203": "Шри Ланка",
    "country_204": "Св. Елена и зависимости",
    "country_205": "Сейнт Китс и Невис",
    "country_206": "Сейнт Лусия",
    "country_207": "Сейнт Винсент и Гренадини",
    "country_208": "Судан",
    "country_209": "Суринам",
    "country_210": "Свазиленд",
    "country_211": "Швеция",
    "country_212": "Швейцария",
    "country_213": "Сирия",
    "country_214": "Тайван",
    "country_215": "Таджикистан",
    "country_216": "Танзания",
    "country_217": "Шаблон:Данни за държава SJM Svalbard",
    "country_218": "Тайланд",
    "country_219": "Бахамите",
    "country_220": "Коморските острови",
    "country_221": "Филипините",
    "country_222": "Източен Тимор (Източен Тимор)",
    "country_223": "Да отида",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринидад и Тобаго",
    "country_227": "Тунис",
    "country_228": "Турция",
    "country_229": "Туркменистан",
    "country_230": "Острови Търкс и Кайкос",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Украйна",
    "country_234": "Обединени арабски емирства",
    "country_235": "Малки отдалечени острови на Съединените щати",
    "country_236": "Съединени американски щати (САЩ)",
    "country_237": "Вирджински острови на Съединените щати",
    "country_238": "Уругвай",
    "country_239": "Узбекистан",
    "country_240": "Вануату",
    "country_241": "Ватикана (Светият престол)",
    "country_242": "Венецуела",
    "country_243": "Виетнам",
    "country_244": "Уолис и Футуна",
    "country_245": "Западна Сахара",
    "country_246": "Йемен",
    "country_247": "Замбия",
    "country_248": "Зимбабве",
    "google_login": "Влезте с Google",
    "State": "състояние",
    "Activation_code": "Код за активиране",
    "Question": "Избройте всички приложения, в които сте влезли",
    "Copy_complete": "Копирането е завършено",
    "footer": "Авторско право © 2024 MindOnMap Studio. Всички права запазени.",
    "change_password_success": "Паролата е променена успешно",
    "successful_login_title": "Успешно влизане",
    "product_page": "Продуктова страница>>",
    "successful_login_info": "Входът е завършен. Моля, затворете текущата страница и се върнете към оригиналния раздел, за да продължите със следния процес. Текущата страница ще се затвори автоматично след 5 секунди. Ако автоматичното затваряне или затварянето чрез щракване върху бутона „Готово“ е неуспешно, моля, затворете директно този раздел.",
    "successful_login_info_firefox": "Входът е завършен. Моля, затворете текущата страница и се върнете към оригиналния раздел, за да продължите със следния процес.",
    "my_account": "Моята сметка",
    "my_history": "Моята история",
    "remove_watermark": "Премахване на воден знак",
    "no_history": "Няма история",
    "history_all": "Избери всички",
    "history_open": "отворено",
    "history_down": "Изтегли",
    "history_delete": "Изтрий",
    "history_clear": "Изчистване на невалидното",
    "images": "изображение(я)",
    "use_this_function": "Използвайте този продукт>>",
    "hd_downloading": "Предимства при изтегляне на HD оригинални изображения:",
    "lifetimeRemaining": "Оставащ доживот",
    "Remaining": "оставащи",
    "email_verification": "Потвърждение на имейла",
    "email_verification_info": "Изпратихме съобщението за потвърждение на вашия имейл <span class=\"email\"></span> и моля, завършете потвърждаването. След потвърждаване предимствата ще се синхронизират автоматично.",
    "wrong_email": "Грешен имейл адрес?",
    "click_here_to_modify": "Щракнете тук, за да промените",
    "get_more_help": "Получаване на още помощ?",
    "click_here": "Натисни тук",
    "email_verification_info_success": "Поздравления за потвърждаването на вашия имейл акаунт.",
    "email_verification_info_error": "Проверката не бе успешна поради изтеклата връзка.",
    "registration_succeeded": "Регистрацията е успешна",
    "registration_succeeded_info_1": "Честито! Регистрирахте се успешно. Изпратихме съобщението за потвърждение на вашия имейл <span class=\"email\"></span> и моля, завършете потвърждаването, за да използвате предимствата на този имейл.",
    "registration_succeeded_info_2": "Щракнете върху „Готово“, за да се върнете на началната страница и да използвате този продукт.",
    "registration_succeeded_info_3": "Щракнете върху „Готово“, за да затворите текущата страница и да се върнете към началната страница за следните операции. Ако затварянето не успее, моля, затворете раздела ръчно.",
    "verify_email": "Потвърди Имейл",
    "registered_email_not_verified": "Регистрираният имейл не е потвърден, моля, потвърдете го веднага.",
    "email_verification_time_1": "Не сте получили имейл за потвърждение?",
    "email_verification_time_2": "След",
    "email_verification_time_3": "Щракнете тук, за да го изпратите отново",
    "error_26301": "Код на грешка: 26301, моля свържете се с нас",
    "error_26302": "Код на грешка: 26302, моля свържете се с нас",
    "error_26303": "Грешка във формата на имейла (код на грешка: 26303). Моля, въведете го отново",
    "error_26304": "Препоръчва се парола над 8 знака (код на грешка: 26304)",
    "error_26305": "Reuqest е неуспешно (код на грешка: 26305). Моля, опитайте отново",
    "error_26306": "Имейлът е регистриран, моля, <a href=\"https://account.mindonmap.com/login/\">отидете, за да влезете</a>",
    "error_26307": "Reuqest е неуспешно (код на грешка: 26307). Моля, опитайте отново",
    "error_26308": "Reuqest е неуспешно (код на грешка: 26308). Моля, опитайте отново",
    "error_26401": "Код на грешка: 26401, моля, опитайте отново",
    "error_26402": "Имейлът е потвърден (код на грешка: 26402), моля, опитайте отново",
    "error_26403": "Reuqest е неуспешно (код на грешка: 26403). Моля, опитайте отново",
    "error_26404": "Неуспешна заявка (код на грешка: 26404). Моля, опитайте отново",
    "error_26501": "Код на грешка: 26501, моля свържете се с нас",
    "error_26502": "Код на грешка: 26502, моля свържете се с нас",
    "error_26503": "Грешка във формата на имейла (код на грешка: 26503). Моля, въведете го отново",
    "error_26504": "Reuqest е неуспешно (код на грешка: 26504). Моля, опитайте отново",
    "error_26505": "Имейлът не е регистриран, моля, <a href=\"https://account.mindonmap.com/register/\">първо го регистрирайте</a>",
    "error_26506": "Имейлът е потвърден.",
    "error_26507": "Reuqest е неуспешно (код на грешка: 26507). Моля, опитайте отново",
    "error_26508": "Проверката е подадена (код на грешка: 26508), моля, опитайте отново",
    "error_26509": "Заявката е неуспешна (код на грешка: 26509), моля, опитайте отново",
    "error_26510": "Код на грешка: 26510, моля свържете се с нас",
    "error_26601": "Код на грешка: 26601, моля свържете се с нас",
    "error_26602": "Код на грешка: 26602, моля свържете се с нас",
    "error_26603": "Reuqest е неуспешно (код на грешка: 26603). Моля, опитайте отново",
    "error_26604": "Код на грешка: 26604, моля свържете се с нас",
    "error_26605": "Код на грешка: 26605, моля свържете се с нас",
    "error_26701": "Код на грешка: 26701, моля свържете се с нас",
    "error_26702": "Reuqest е неуспешно (код на грешка: 26702). Моля, опитайте отново",
    "error_26703": "Код на грешка: 26703, моля свържете се с нас",
    "error_26704": "Код на грешка: 26704, моля свържете се с нас",
    "error_26705": "Изчакайте за влизане (код на грешка: 26705). Моля, опитайте отново",
    "no_cookie": "Вие сте включили функцията Блокиране на всички бисквитки във вашия браузър, така че не можете да влезете. Моля, отидете в Настройки, за да поставите отметка в квадратчето Разрешаване на всички бисквитки.",
    "error_26801": "Код на грешка: 26801, моля свържете се с нас",
    "error_26802": "Код на грешка: 26802, моля свържете се с нас",
    "error_26803": "Неуспешна заявка (код на грешка: 26803). Моля, опитайте отново",
    "error_26804": "Неуспешна заявка (код на грешка: 26804). Моля, опитайте отново",
    "error_order": "Заявката е неуспешна (код на грешка: 27098), моля, опитайте отново!",
    "error_order1": "Заявката за поръчка е непълна（код на грешка: ",
    "error_order2": "）, моля, опреснете и опитайте отново.",
    "modify_email_title": "Промяна на имейл",
    "modify_email_info": "Можете да използвате променения имейл, за да влезете в акаунта си.",
    "images_per": "Изображения по",
    "error_26101": "Грешка: 26101. Моля, свържете се с нас",
    "error_26102": "Грешка: 26102. Моля, свържете се с нас",
    "error_26103": "Неуспешна заявка (код на грешка: 26103). Моля, опитайте отново",
    "error_26104": "Код на грешка: 26104, моля, опитайте отново",
    "error_26105": "Код на грешка: 26105, моля, опитайте отново",
    "error_26106": "Неуспешно изтриване (код на грешка: 26106). Моля, опитайте отново",
    "error_26201": "Грешка: 26201. Моля, свържете се с нас",
    "error_26202": "Неуспешна заявка (код на грешка: 26202). Моля, опитайте отново",
    "error_26001": "Грешка: 26001. Моля, свържете се с нас",
    "error_26002": "Грешка: 26002. Моля, свържете се с нас",
    "error_26003": "Грешка: 26003. Моля, свържете се с нас",
    "error_26004": "Грешка: 26004. Моля, свържете се с нас",
    "error_26005": "Заявката е неуспешна (код на грешка: 26005). Моля, опитайте отново",
    "error_26006": "Код на грешка: 26006, моля, опитайте отново",
    "error_26008": "Грешка: 26008. Моля, свържете се с нас",
    "go_to_the_home_page": "Отидете на началната страница",
    "error_27101": "Неуспешна заявка (код на грешка: 27101). Моля, опитайте отново",
    "error_27201": "Код на грешка: 27201, моля свържете се с нас",
    "error_27202": "Код на грешка: 27202, моля, опитайте отново",
    "error_27203": "Неуспешна заявка (код на грешка: 27203). Моля, опитайте отново",
    "error_27204": "Невалиден код (код на грешка: 27204).",
    "error_27205": "Неуспешна заявка (код на грешка: 27205). Моля, опитайте отново",
    "error_27206": "Неуспешна заявка (код на грешка: 27206). Моля, опитайте отново",
    "error_27207": "Неуспешна заявка (код на грешка: 27207). Моля, опитайте отново",
    "no_history_found": "Не сте използвали никакъв инструмент! <a href=\"/\">Опреснете</a> или <a href=\"https://www.mindonmap.com/\">Отидете на официалния уебсайт</a>",
    "error_25301": "Грешка: 25301. Моля, свържете се с нас",
    "error_25302": "Грешка: 25302. Моля, свържете се с нас",
    "error_25303": "Заявката е неуспешна (код на грешка: 25303). Моля, опитайте отново",
    "error_25304": "Заявката е неуспешна (код на грешка: 25304). Моля, опитайте отново",
    "error_25305": "Неуспешна заявка (код на грешка: 25305). Моля, опитайте отново",
    "error_25306": "Неуспешна заявка (код на грешка: 25306). Моля, опитайте отново",
    "image_upscaler_p": "Предимства при изтегляне на изображение без воден знак:",
    "Available_for": "Достъпен за:",
    "credit_per": "%s кредит(а) за HD изображение",
    "still_valid": "Закупеният план(ове) все още е валиден",
    "credit": "кредит(и)",
    "pc_3rd_info": "Влезте успешно. Моля, отидете на приложението за по-нататъшна работа.",
    "use_online": "Използвайте онлайн услугата",
    "use_download": "Използвайте настолната програма",
    "use_immediately": "Използвайте Веднага",
    "Use_in_browser": "Използвайте в браузъра",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} кредита на месец",
    "expire": "Срок на годност:",
    "viewDetails": "Виж детайлите",
    "viewHistory": "Вижте ползите от историята>>",
    "viewDetailsInfo": "Забележка: Ако предимствата на абонамента се подновят в рамките на 7 дни след изтичане, неизползваните предимства могат да продължат да се използват. Освен това времето на изтичане ще се актуализира автоматично до времето на изтичане на новия абонамент. Ако няма нов абонамент след 7 дни от времето на изтичане, всички изтекли предимства ще бъдат изчистени.",
    "connect_account": "Свържете имейл с вашия акаунт",
    "connect_account_info": "След обвързване можете да влезете с този имейл адрес.",
    "connect_now": "Свържете сега",
    "no_email_bind": "Без обвързване по имейл",
    "bind_email": "Обвързване на имейл",
    "connect_your_email_placeholder": "Моля, въведете вашия имейл адрес",
    "bind_an_email": "Свържете имейл",
    "bind_info": "Влязохте успешно. Моля, свържете имейл с вашия акаунт, за да активирате вашите предимства.",
    "bind_later": "Свържете по-късно",
    "hi": "Здравей, %s!",
    "Personal_Information": "Лична информация",
    "Access": "Достъп",
    "Subscription_Plan": "(Абонаментен план)",
    "No_orders": "Няма намерени поръчки.",
    "No_data": "Няма данни",
    "Featured_Products": "Специални продукти",
    "More_Products": "Още продукти",
    "Free_Download": "Безплатно сваляне",
    "Get_Started": "Първи стъпки",
    "Subscribe": "Абонирай се",
    "Verified": "Проверен",
    "back_to_account_center": "Назад към Центъра за акаунти",
    "success": "Успех!",
    "successfully": "Успешно регистрирахте акаунт.",
    "Continue": "продължи",
    "Already": "Вече имате акаунт?",
    "loading_verification": "Проверка на статуса на проверка...",
    "email_no_verification": "За съжаление регистрираният имейл адрес не е потвърден. Моля, завършете проверката съгласно инструкциите по-горе и щракнете отново върху бутона „Готово“, за да завършите регистрацията.",
    "will_expire_after": "Ще изтече след",
    "hours": "часа",
  },
  "bn": {
    "overtime": "ত্রুটি কোড: {%}, দয়া করে আবার চেষ্টা করুন",
    "isnetwork": "ইন্টারনেট ত্রুটি। অনুগ্রহ করে চেক করুন এবং পুনরায় চেষ্টা করুন",
    "email_placeholder": "ইমেইল",
    "email_empty": "অনুগ্রহ করে ইনপুট ইমেল",
    "email_not_valid": "ইমেল বৈধ নয়",
    "email_not_valid_1": "আপনার ইমেল ইনপুট করুন",
    "email_not_valid_2": "ইমেল বৈধ নয়, একটি ভিন্ন ঠিকানা ব্যবহার করুন.",
    "email_not_valid_3": "কোনো ইমেল ইনপুট নেই",
    "password_placeholder": "পাসওয়ার্ড",
    "password_empty": "পাসওয়ার্ড ইনপুট করুন",
    "password_not_valid": "ভুল অ্যাকাউন্ট বা পাসওয়ার্ড",
    "password_not_valid_1": "8 অক্ষরের উপরে পাসওয়ার্ড প্রয়োজন",
    "password_not_valid_2": "একটি পাসওয়ার্ড তৈরি করুন",
    "password_placeholder_1": "আপনার পাসওয়ার্ড তৈরি করুন",
    "password_placeholder_2": "আপনার পাসওয়ার্ড নিশ্চিত",
    "password_placeholder_3": "নতুন পাসওয়ার্ড তৈরি করুন",
    "password_placeholder_4": "নিশ্চিত কর নতুন গোপননম্বর",
    "password_placeholder_5": "পুরানো পাসওয়ার্ড ইনপুট করুন",
    "copy_password_empty": "পাসওয়ার্ড নিশ্চিত করুন",
    "copy_password_not_valid": "আপনার পাসওয়ার্ড নিশ্চিত করুন",
    "copy_passwords_inconsistent": "আপনার পাসওয়ার্ড নিশ্চিতকরণ মেলে না",
    "code_empty": "অনুগ্রহ করে যাচাইকরণ ক",
    "code_not_valid": "অবৈধ যাচাই কোড",
    "code_placeholder": "যাচাইকরণ কোড",
    "not_received_code": "যদি আপনার মেইলবক্স দীর্ঘদিন ধরে যাচাইকরণ কোড না পায়, তাহলে অনুগ্রহ করে আবার যাচাইকরণ কোডটি পান।",
    "get_first_code": "অনুগ্রহ করে প্রথমে যাচাইকরণ কোডটি পান।",
    "last_name_placeholder": "তোমার নামের শেষ অংশ দাও",
    "first_name_placeholder": "আপনার প্রথম নাম লিখুন",
    "address_placeholder": "আপনার ঠিকানা লিখুন",
    "no_code_text": "আমরা একটি যাচাইকরণ কোড পাঠিয়েছি। আপনার কোড লিখুন. <span class='tips'>কোড পাননি?",
    "no_code_text_1": "1. দয়া করে নিশ্চিত করুন যে ইমেল ঠিকানাটি বৈধ এবং এটি ইমেলগুলি গ্রহণ করতে পারে৷",
    "no_code_text_2": "2. সিস্টেম দ্বারা স্বয়ংক্রিয়ভাবে ইমেল পাঠানো হয়, এটি স্প্যাম বা জাঙ্ক ইমেল হিসাবে পতাকাঙ্কিত হতে পারে। ইমেলটি ট্র্যাশ ফোল্ডারে আছে কিনা দয়া করে পরীক্ষা করুন৷",
    "no_code_text_3": "3. আপনার সমস্যা সমাধান করতে পারেন না? ",
    "no_code_text_3_span": "তারপর আমাদের সাথে যোগাযোগ করতে এখানে ক্লিক করুন.",
    "order_no": "আপনি কোনো পণ্য ক্রয় করেননি, আপনার যদি কোনো প্রশ্ন থাকে, অনুগ্রহ করে <a href=\"https://www.mindonmap.com/contact-us/\">আমাদের সাথে যোগাযোগ করুন</a>।",
    "error_24901": "বর্তমান অ্যাকাউন্টে কোনো ইমেল লিঙ্ক করা নেই, এবং অর্ডারগুলি খুঁজে পাচ্ছি না৷ একটি ইমেল সংযোগ করুন.",
    "user_guide": "ব্যবহারকারীর নির্দেশিকা >>",
    "download": "ডাউনলোড করুন",
    "order_number": "অর্ডার নম্বর:",
    "Refund": "ফেরত",
    "Disabled": "অক্ষম",
    "Normal": "স্বাভাবিক",
    "Modify": "পরিবর্তন করুন",
    "Modify_1": "পরিবর্তন করুন>>",
    "Connect": "সংযোগ করুন",
    "unlink_success": "সফলভাবে লিঙ্কমুক্ত করুন৷",
    "connect_success": "সফলভাবে সংযোগ করুন",
    "feedback_title": "আপনার প্রতিক্রিয়ার জন্য ধন্যবাদ। আপনার সমস্যাটি ছেড়ে দিন এবং আমরা 24 ঘন্টার মধ্যে আপনাকে প্রতিক্রিয়া জানাব।",
    "feedback_thank_you": "ধন্যবাদ!<br />আপনার প্রতিক্রিয়া সফলভাবে জমা দেওয়া হয়েছে।",
    "feedback_email": "এখানে আপনার ইমেল ঠিকানা লিখুন!",
    "feedback_content": "আপনার সম্মুখীন কোনো সমস্যা বা পরামর্শ এখানে ছেড়ে দিন.",
    "feedback_submit": "জমা দিন",
    "form_contents": "আপনি কোন বিবরণ লিখুন না. এটি লিখুন এবং আবার জমা দিন.",
    "old_password": "পুরানো পাসওয়ার্ড ইনপুট করুন",
    "new_password": "একটি নতুন পাসওয়ার্ড তৈরি করুন",
    "old_new_password": "নতুন পাসওয়ার্ড পুরানো পাসওয়ার্ডের মতো হতে পারে না",
    "incorrect_password": "ভুল পাসওয়ার্ড৤",
    "delete_no": "এক্ষুণি মুছে ফেলো",
    "Caps": "ক্যাপস লক চালু আছে",
    "Get": "পাওয়া",
    "Done": "সম্পন্ন",
    "error_20001": "ত্রুটি: 20001। অনুগ্রহ করে আবার লগ ইন করুন।",
    "error_20002": "ত্রুটি: 20002। অনুগ্রহ করে আবার লগ ইন করুন।",
    "error_20003": "ত্রুটি: 20003। অনুগ্রহ করে আবার লগ ইন করুন।",
    "error_20004": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি কোড: 20004)। আবার চেষ্টা করুন.",
    "error_20005": "লগইন সেশনের মেয়াদ শেষ হয়েছে (ত্রুটি: 20005)। অনুগ্রহ পূর্বক আরো একবার প্রবেশ করুন।",
    "error_20006": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি কোড: 20006)। আবার চেষ্টা করুন.",
    "error_20007": "লগইন সেশনের মেয়াদ শেষ হয়েছে (ত্রুটি: 20007)। অনুগ্রহ পূর্বক আরো একবার প্রবেশ করুন।",
    "error_20008": "লগইন সেশনের মেয়াদ শেষ হয়েছে (ত্রুটি: 20008)। অনুগ্রহ পূর্বক আরো একবার প্রবেশ করুন।",
    "error_20009": "লগইন সেশনের মেয়াদ শেষ হয়েছে (ত্রুটি: 20009)। অনুগ্রহ পূর্বক আরো একবার প্রবেশ করুন।",
    "error_20101": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20101)",
    "error_20102": "ইমেল বৈধ নয় (ত্রুটির কোড: 20102)",
    "error_20103": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি কোড: 20103)। আবার চেষ্টা করুন",
    "error_20104": "ইমেল ইতিমধ্যেই ব্যবহার করা হচ্ছে, অনুগ্রহ করে <a href=\"https://account.mindonmap.com/login/\">লগ ইন করুন</a> অথবা একটি নতুন দিয়ে নিবন্ধন করুন",
    "error_20105": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20105)। আবার চেষ্টা করুন",
    "error_20106": "ইমেল পাঠাতে ব্যর্থ হয়েছে, এটি আবার চেষ্টা করুন",
    "error_20201": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20201)",
    "error_20202": "অনুগ্রহ করে আপনার পাসওয়ার্ড ইনপুট করুন (ত্রুটির কোড: 20202)",
    "error_20203": "অনুগ্রহ করে যাচাইকরণ কোড ইনপুট করুন (ত্রুটির কোড: 20203)",
    "error_20204": "ইমেল বৈধ নয় (ত্রুটির কোড: 20204)",
    "error_20205": "8 অক্ষরের উপরে পাসওয়ার্ড প্রয়োজন (ত্রুটির কোড: 20205)",
    "error_20206": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20206)। আবার চেষ্টা করুন",
    "error_20207": "অবৈধ যাচাই কোড",
    "error_20208": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20208)। আবার চেষ্টা করুন",
    "error_20209": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20209)। আবার চেষ্টা করুন",
    "error_20301": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20301)",
    "error_20302": "ত্রুটি: 20302. আমাদের সাথে যোগাযোগ করুন",
    "error_20303": "ইমেল বৈধ নয় (ত্রুটির কোড: 20303)",
    "error_20304": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20304)। আবার চেষ্টা করুন",
    "error_20305": "অ্যাকাউন্ট এর অস্তিত্ব নেই। অনুগ্রহ করে আবার লিখুন বা প্রথমে <a href=\"https://account.mindonmap.com/register/\">এটি তৈরি করুন</a>৷",
    "error_20306": "এখনো কোনো পাসওয়ার্ড নেই। <a href=\"https://account.mindonmap.com/passwordless-login/\">পাসওয়ার্ডহীন লগইন</a> বা <a href=\"https://account.mindonmap.com/create-password/\"> ব্যবহার করুন একটি পাসওয়ার্ড সেট করুন</a> এবং লগ ইন করুন।",
    "error_20308": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20308)। আবার চেষ্টা করুন",
    "error_20401": "লগ আউট করতে ব্যর্থ (ত্রুটির কোড: 20401)। আবার চেষ্টা করুন",
    "error_20501": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20501)",
    "error_20502": "ইমেল বৈধ নয় (ত্রুটির কোড: 20502)",
    "error_20503": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20503)। আবার চেষ্টা করুন",
    "error_20504": "ইমেল পাঠাতে ব্যর্থ হয়েছে. আবার চেষ্টা করুন.",
    "error_20601": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20601)",
    "error_20602": "অনুগ্রহ করে যাচাইকরণ কোড ইনপুট করুন (ত্রুটির কোড: 20602)",
    "error_20603": "ইমেল বৈধ নয় (ত্রুটির কোড: 20603)",
    "error_20604": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20604)। আবার চেষ্টা করুন",
    "error_20606": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20606)। আবার চেষ্টা করুন",
    "error_20607": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20607)। আবার চেষ্টা করুন",
    "error_20608": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20608)। আবার চেষ্টা করুন",
    "error_20701": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20701)",
    "error_20702": "ইমেল বৈধ নয় (ত্রুটির কোড: 20702)",
    "error_20703": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20703)। আবার চেষ্টা করুন",
    "error_20704": "অ্যাকাউন্ট এর অস্তিত্ব নেই। অনুগ্রহ করে আবার লিখুন বা প্রথমে <a href=\"https://account.mindonmap.com/register/\">এটি তৈরি করুন</a>৷",
    "error_20705": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20705)। আবার চেষ্টা করুন",
    "error_20706": "ইমেল পাঠাতে ব্যর্থ হয়েছে. আবার চেষ্টা করুন",
    "error_20801": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 20801)",
    "error_20802": "ত্রুটি: 20802. আমাদের সাথে যোগাযোগ করুন",
    "error_20803": "অনুগ্রহ করে যাচাইকরণ কোড ইনপুট করুন (ত্রুটির কোড: 20803)",
    "error_20804": "ইমেল বৈধ নয় (ত্রুটির কোড: 20804)",
    "error_20805": "8 অক্ষরের উপরে পাসওয়ার্ড প্রয়োজন (ত্রুটির কোড: 20805)",
    "error_20806": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20806)। আবার চেষ্টা করুন",
    "error_20808": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20808)। আবার চেষ্টা করুন",
    "error_20901": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20901)। আবার চেষ্টা করুন",
    "error_20902": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 20902)। আবার চেষ্টা করুন",
    "error_21000": "পরিবর্তন সংরক্ষিত হয়",
    "error_21001": "কোন তথ্য জমা দেওয়া হয় না (ত্রুটির কোড: 21001)",
    "error_21002": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21002)। আবার চেষ্টা করুন",
    "error_21101": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 21101)",
    "error_21102": "ইমেল বৈধ নয় (ত্রুটির কোড: 21102)",
    "error_21103": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21103), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21104": "ইমেল ইতিমধ্যে সংযুক্ত আছে, একটি নতুন ব্যবহার করুন",
    "error_21105": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21105), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21106": "ইমেল পাঠাতে ব্যর্থ হয়েছে. আবার চেষ্টা করুন",
    "error_21201": "অনুগ্রহ করে আপনার ইমেল ইনপুট করুন (ত্রুটির কোড: 21201)",
    "error_21202": "অনুগ্রহ করে যাচাইকরণ কোড ইনপুট করুন (ত্রুটির কোড: 21202)",
    "error_21203": "ইমেল বৈধ নয় (ত্রুটির কোড: 21203)",
    "error_21204": "ত্রুটি: 21204. আমাদের সাথে যোগাযোগ করুন",
    "error_21205": "ত্রুটি: 21205. আমাদের সাথে যোগাযোগ করুন",
    "error_21206": "8 অক্ষরের উপরে পাসওয়ার্ড প্রয়োজন (ত্রুটির কোড: 21206)",
    "error_21207": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21207)। আবার চেষ্টা করুন",
    "error_21209": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21209)। আবার চেষ্টা করুন",
    "error_21301": "অনুগ্রহ করে পুরানো পাসওয়ার্ড ইনপুট করুন (ত্রুটির কোড: 21301)",
    "error_21302": "অনুগ্রহ করে একটি নতুন পাসওয়ার্ড তৈরি করুন (ত্রুটির কোড: 21302)",
    "error_21303": "নতুন পাসওয়ার্ড পুরানো পাসওয়ার্ডের মতো হতে পারে না। (ত্রুটি: 21303)",
    "error_21304": "8 অক্ষরের উপরে পাসওয়ার্ড প্রয়োজন (ত্রুটির কোড: 21304)",
    "error_21306": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21306)। আবার চেষ্টা করুন",
    "error_21402": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21402)। আবার চেষ্টা করুন",
    "error_21403": "যাচাইকরণ কোড পাঠাতে ব্যর্থ হয়েছে৷ দয়া করে আবার পাঠান",
    "error_21500": "অ্যাকাউন্ট মুছে ফেলা হয়েছে",
    "error_21501": "অনুগ্রহ করে যাচাইকরণ কোড ইনপুট করুন (ত্রুটির কোড: 21501)",
    "error_21502": "লগইন সেশনের মেয়াদ শেষ হয়েছে (ত্রুটি: 21502)। অনুগ্রহ পূর্বক আরো একবার প্রবেশ করুন।",
    "error_21503": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21503)। আবার চেষ্টা করুন",
    "error_21505": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21505), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21601": "ত্রুটি: 20601. আমাদের সাথে যোগাযোগ করুন",
    "error_21602": "অবৈধ যাচাইকরণ (ত্রুটি: 20602)। আবার চেষ্টা করুন.",
    "error_21603": "ত্রুটি: 20603। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21604": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21604)। আবার চেষ্টা করুন",
    "error_21606": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21606)। আবার চেষ্টা করুন",
    "error_21611": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21611)। আবার চেষ্টা করুন",
    "error_21801": "ত্রুটি: 21801. আমাদের সাথে যোগাযোগ করুন",
    "error_21802": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি: 21802)। আবার চেষ্টা করুন",
    "error_21803": "ত্রুটি: 21803। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21804": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21804)। আবার চেষ্টা করুন",
    "error_21806": "ত্রুটি: 21806। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21807": "ত্রুটি: 21807. আমাদের সাথে যোগাযোগ করুন",
    "error_21808": "ত্রুটি: 21808. আমাদের সাথে যোগাযোগ করুন",
    "error_21809": "ত্রুটি: 21809. আমাদের সাথে যোগাযোগ করুন",
    "error_21810": "ত্রুটি: 21810. আমাদের সাথে যোগাযোগ করুন",
    "error_21811": "ত্রুটি: 21811. আমাদের সাথে যোগাযোগ করুন",
    "error_21812": "ত্রুটি: 21812. আমাদের সাথে যোগাযোগ করুন",
    "error_21813": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21813)। আবার চেষ্টা করুন",
    "error_21814": "ত্রুটি: 21814. আমাদের সাথে যোগাযোগ করুন",
    "error_21815": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21815)। আবার চেষ্টা করুন",
    "error_21816": "ত্রুটি: 21816. আমাদের সাথে যোগাযোগ করুন",
    "error_21817": "ত্রুটি: 21817. আমাদের সাথে যোগাযোগ করুন",
    "error_21818": "ত্রুটি: 21818. আমাদের সাথে যোগাযোগ করুন",
    "error_21819": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21819)। আবার চেষ্টা করুন",
    "error_21820": "ত্রুটি: 21820. আমাদের সাথে যোগাযোগ করুন",
    "error_21821": "ত্রুটি: 21821. আমাদের সাথে যোগাযোগ করুন",
    "error_21822": "ত্রুটি: 21822. আমাদের সাথে যোগাযোগ করুন",
    "error_21823": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21823)। আবার চেষ্টা করুন",
    "error_21824": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21824)। আবার চেষ্টা করুন",
    "error_21825": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21825)। আবার চেষ্টা করুন",
    "error_21826": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21826)। আবার চেষ্টা করুন",
    "error_21901": "ত্রুটি: 21901. আমাদের সাথে যোগাযোগ করুন",
    "error_21902": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21902)। আবার চেষ্টা করুন",
    "error_21903": "অ্যাকাউন্টের স্থিতি পরিবর্তিত হয়েছে (ত্রুটির কোড: 21903), অনুগ্রহ করে পৃষ্ঠাটি রিফ্রেশ করুন এবং আবার চেষ্টা করুন",
    "error_21904": "ত্রুটি: 21904। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21905": "ত্রুটি: 21905। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_21906": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21906)। আবার চেষ্টা করুন",
    "error_21907": "Google অ্যাকাউন্ট অন্য অ্যাকাউন্টের সাথে লিঙ্ক করা হয়েছে",
    "error_21908": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 21908)। আবার চেষ্টা করুন",
    "error_22001": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22001)। আবার চেষ্টা করুন",
    "error_22002": "কোন অতিরিক্ত লগইন ছাড়া লিঙ্ক আনুন ব্যর্থ হয়েছে",
    "error_22003": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি কোড: 22003)। আবার চেষ্টা করুন",
    "error_22101": "ত্রুটি: 22101. আমাদের সাথে যোগাযোগ করুন",
    "error_22102": "অ্যাকাউন্টের স্থিতি পরিবর্তিত হয়েছে (ত্রুটির কোড: 22102), অনুগ্রহ করে পৃষ্ঠাটি রিফ্রেশ করুন এবং আবার চেষ্টা করুন",
    "error_22103": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22103)। আবার চেষ্টা করুন",
    "error_22104": "অ্যাকাউন্টের স্থিতি পরিবর্তিত হয়েছে (ত্রুটির কোড: 22104), অনুগ্রহ করে পৃষ্ঠাটি রিফ্রেশ করুন এবং আবার চেষ্টা করুন",
    "error_22105": "ত্রুটি: 22105। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_22106": "ত্রুটি: 22106। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_22107": "ত্রুটি: 22107. আমাদের সাথে যোগাযোগ করুন",
    "error_22108": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22108)। আবার চেষ্টা করুন",
    "error_22201": "ত্রুটি: 22201. আমাদের সাথে যোগাযোগ করুন",
    "error_22202": "অবৈধ যাচাইকরণ (ত্রুটি: 22202)। আবার চেষ্টা করুন.",
    "error_22203": "ত্রুটি: 22203. অনুগ্রহ করে আবার চেষ্টা করুন\"",
    "error_22204": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22204)। আবার চেষ্টা করুন",
    "error_22206": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22206)। আবার চেষ্টা করুন",
    "error_22401": "ত্রুটি: 22401. আমাদের সাথে যোগাযোগ করুন",
    "error_22402": "অবৈধ যাচাইকরণ (ত্রুটি: 22402)। আবার চেষ্টা করুন.",
    "error_22403": "ত্রুটি: 22403। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_22404": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22404)। আবার চেষ্টা করুন",
    "error_22405": "ফেসবুক অ্যাকাউন্টটি অন্য ইমেলের সাথে লিঙ্ক করা হয়েছে",
    "error_22406": "ত্রুটি: 22406। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_22407": "ত্রুটি: 22407. আমাদের সাথে যোগাযোগ করুন",
    "error_22408": "ত্রুটি: 22408. আমাদের সাথে যোগাযোগ করুন",
    "error_22409": "ত্রুটি: 22409. আমাদের সাথে যোগাযোগ করুন",
    "error_22410": "ত্রুটি: 224010. আমাদের সাথে যোগাযোগ করুন",
    "error_22411": "ত্রুটি: 224011. আমাদের সাথে যোগাযোগ করুন",
    "error_22412": "ত্রুটি: 224012. আমাদের সাথে যোগাযোগ করুন",
    "error_22413": "ত্রুটি: 22413. আমাদের সাথে যোগাযোগ করুন",
    "error_22414": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22414)। আবার চেষ্টা করুন",
    "error_22415": "ত্রুটি: 22415. আমাদের সাথে যোগাযোগ করুন",
    "error_22416": "ত্রুটি: 22416. আমাদের সাথে যোগাযোগ করুন",
    "error_22417": "ত্রুটি: 22417. আমাদের সাথে যোগাযোগ করুন",
    "error_22418": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22418)। আবার চেষ্টা করুন",
    "error_22419": "ত্রুটি: 22419. আমাদের সাথে যোগাযোগ করুন",
    "error_22420": "ত্রুটি: 22420. আমাদের সাথে যোগাযোগ করুন",
    "error_22421": "ত্রুটি: 22421. আমাদের সাথে যোগাযোগ করুন",
    "error_22422": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22422)। আবার চেষ্টা করুন",
    "error_22423": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22423)। আবার চেষ্টা করুন",
    "error_22424": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22424)। আবার চেষ্টা করুন",
    "error_22425": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 22425)। আবার চেষ্টা করুন",
    "error_20098": "ত্রুটি কোড: 20098. আপনি যদি ব্যক্তিগত মোডে ব্রাউজ করছেন, দয়া করে সাধারণ মোডে স্যুইচ করুন এবং আবার চেষ্টা করুন৷",
    "error_22298": "Google-এর জন্য অনুরোধ ব্যর্থ হয়েছে (ত্রুটি: 22298)। আবার চেষ্টা করুন.",
    "error_22498": "ফেসবুকের জন্য অনুরোধ ব্যর্থ হয়েছে (ত্রুটি: 22498)। আবার চেষ্টা করুন.",
    "error_24902": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 24902)। আবার চেষ্টা করুন",
    "error_24903": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 24903)। আবার চেষ্টা করুন",
    "error_24904": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 24904)। আবার চেষ্টা করুন",
    "error_24905": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 24905)। আবার চেষ্টা করুন",
    "login_title": "MindOnMap এ লগ ইন করুন",
    "log_in": "প্রবেশ করুন",
    "no_account": "কোন হিসাব নেই?",
    "create_it": "এটি তৈরি করুন",
    "or_log_in_with": "অথবা দিয়ে লগ ইন করুন",
    "passwordless_login": "পাসওয়ার্ডহীন লগইন",
    "log_in_done": "লগ ইন সম্পন্ন",
    "three_rd_account_connect_info": "অভিনন্দন! আপনি সফলভাবে লগ ইন করেছেন. এখন আপনি একটি ইমেল অ্যাকাউন্ট সংযুক্ত করতে পারেন যা ভবিষ্যতে লগ ইন করতে ব্যবহৃত হয়৷",
    "see_my_account": "আমার অ্যাকাউন্ট দেখুন",
    "three_rd_login_merge_account": "তৃতীয় পক্ষের অ্যাকাউন্টের ইমেল ঠিকানাটি সাইন আপ করা হয়েছে, আপনি কি সরাসরি এই ইমেল ঠিকানার সাথে সংযোগ করতে এবং লগ ইন করতে চান?",
    "connect_log_in": "সংযোগ করুন এবং লগ ইন করুন",
    "create_an_account": "একটি অ্যাকাউন্ট তৈরি করুন",
    "back_to_log_in": "প্রবেশ করতে পেছান",
    "create_password": "গুপ্ত সংকেত সৃষ্টি করুন",
    "create_now": "এখনই তৈরি করুন",
    "password_login_subtitle": "ইমেল দিয়ে পাসওয়ার্ডহীন লগইন",
    "account_login": "অ্যাকাউন্টে লগইন",
    "rights": "এই অ্যাকাউন্ট তৈরি করে, আপনি <a href=\"https://www.mindonmap.com/terms-and-conditions/\">পরিষেবার শর্তাবলী</a> এবং <a href=\"https://www.mindonmap.com/privacy-policy/\">গোপনীয়তা নীতি</a>",
    "passwordless_login_done": "পাসওয়ার্ডহীন লগইন সম্পন্ন হয়েছে",
    "passwordless_login_info": "অভিনন্দন, আপনি পাসওয়ার্ডহীন লগইন সফলভাবে সম্পন্ন করেছেন। আপনি এই অ্যাকাউন্টের জন্য একটি পাসওয়ার্ড তৈরি করতে পারেন এবং ভবিষ্যতে অ্যাকাউন্ট এবং পাসওয়ার্ড দিয়ে লগ ইন করতে পারেন। <a href=\"/create-password\" style=\"display: initial;\">এখনই তৈরি করুন</a>",
    "sign_up": "নিবন্ধন করুন",
    "register_info": "আপনার একাউন্ট তৈরী করুন",
    "reset_now": "এখন রিসেট করুন",
    "forgot_password": "পাসওয়ার্ড ভুলে গেছেন",
    "reset_password_subtitle": "পাসওয়ার্ড রিসেট করতে আপনার অ্যাকাউন্ট ইমেল ব্যবহার করুন",
    "plan_products": "পরিকল্পনা ও পণ্য",
    "nick_name": "ব্যবহারকারীর নাম:",
    "email": "ইমেইল:",
    "my_products": "আমার পণ্য",
    "my_orders": "আমার আদেশ",
    "unlink": "লিঙ্কমুক্ত করুন",
    "link": "লিঙ্ক",
    "connected_accounts": "সংযুক্ত অ্যাকাউন্ট",
    "last_name": "নামের শেষাংশ:",
    "first_name": "নামের প্রথম অংশ:",
    "Gender": "লিঙ্গ:",
    "Birth": "জন্ম:",
    "Month": "মাস",
    "Year": "বছর",
    "Country_Region": "দেশ/অঞ্চল:",
    "Address": "ঠিকানা:",
    "Save": "সংরক্ষণ",
    "Date": "তারিখ",
    "Male": "পুরুষ",
    "Female": "মহিলা",
    "Unspecified": "অনির্দিষ্ট",
    "Security": "নিরাপত্তা",
    "change_password": "পাসওয়ার্ড পরিবর্তন করুন",
    "change_now": "এখনই পরিবর্তন করুন",
    "connect_email": "ইমেল সংযোগ করুন",
    "delete_account": "হিসাব মুছে ফেলা",
    "delete_account_info": "আপনার অ্যাকাউন্ট মুছে ফেলা হলে, আপনার অ্যাকাউন্টের সাথে যুক্ত আমার অ্যাকাউন্টের সমস্ত ডেটা স্থায়ীভাবে মুছে যাবে এবং আপনি এটি পুনরুদ্ধার করতে পারবেন না। আমরা আপনাকে সতর্কতার সাথে চলার পরামর্শ দিই।",
    "Delete": "মুছে ফেলা",
    "Logout": "প্রস্থান",
    "my_profile": "আমার প্রোফাইল",
    "guides_faqs": "গাইড এবং প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী",
    "More": "আরও",
    "guides": "গাইড",
    "register": "নিবন্ধন",
    "hot_faq": "হট FAQ",
    "Contents": "বিষয়বস্তু:",
    "contact_us": "আমাদের সাথে যোগাযোগ করুন >>",
    "plan": "পরিকল্পনা",
    "unregistered": "অনিবন্ধিত",
    "buy_more": "আরো কিনুন",
    "buy_again": "আবার কিনুন",
    "buy_now": "এখন কেন",
    "free_no_limit": "বিনামূল্যে এবং কোন সীমা নেই",
    "expired": "মেয়াদোত্তীর্ণ",
    "lifetime": "আজীবন",
    "remain": "থাকে",
    "day_s": "দিন(গুলি)",
    "error_24801": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 24801)। আবার চেষ্টা করুন",
    "no_app_found": "কোনো অ্যাপ পাওয়া যায়নি!<a href=\"/\">রিফ্রেশ</a> অথবা <a href=\"https://www.mindonmap.com/\">অফিসিয়াল ওয়েবসাইটে যান</a>",
    "get_more": "আরো পান >>",
    "edit_photo": "ছবি সংস্কার",
    "select_photo": "ছবি নির্বাচন করুন",
    "change_photo": "ছবি পরিবর্তন",
    "cancel": "বাতিল করুন",
    "hide_password": "পাসওয়ার্ড লুকান",
    "show_password": "পাসওয়ার্ড দেখাও",
    "zoom_in": "প্রসারিত করো",
    "zoom_out": "ছোট করা",
    "rotate": "আবর্তিত",
    "horizontal_flip": "অনুভূমিক উল্টানো",
    "vertical_flip": "উল্লম্ব উল্টানো",
    "country": "দেশ",
    "country_1": "আপনার দেশ/অঞ্চল বেছে নিন",
    "country_2": "আল্যান্ড দ্বীপ",
    "country_3": "আফগানিস্তান",
    "country_4": "আলবেনিয়া",
    "country_5": "আলজেরিয়া",
    "country_6": "আমেরিকান সামোয়া",
    "country_7": "এন্ডোরা",
    "country_8": "অ্যাঙ্গোলা",
    "country_9": "অ্যাঙ্গুইলা",
    "country_10": "অ্যান্টার্কটিকা",
    "country_11": "অ্যান্টিগুয়া ও বারবুডা",
    "country_12": "আর্জেন্টিনা",
    "country_13": "আর্মেনিয়া",
    "country_14": "আরুবা",
    "country_15": "অস্ট্রেলিয়া",
    "country_16": "অস্ট্রিয়া",
    "country_17": "আজারবাইজান",
    "country_18": "বাহরাইন",
    "country_19": "বাংলাদেশ",
    "country_20": "বার্বাডোজ",
    "country_21": "বেলারুশ",
    "country_22": "বেলজিয়াম",
    "country_23": "বেলিজ",
    "country_24": "বেনিন",
    "country_25": "বারমুডা",
    "country_26": "ভুটান",
    "country_27": "বলিভিয়া",
    "country_28": "বসনিয়া ও হার্জেগোভিনা",
    "country_29": "বতসোয়ানা",
    "country_30": "বুভেট দ্বীপ",
    "country_31": "ব্রাজিল",
    "country_32": "ব্রিটিশ ভারত মহাসাগরীয় অঞ্চল",
    "country_33": "ব্রিটিশ ভার্জিন দ্বীপপুঞ্জ",
    "country_34": "ব্রুনাই",
    "country_35": "বুলগেরিয়া",
    "country_36": "বুরকিনা",
    "country_37": "বুরুন্ডি",
    "country_38": "কম্বোডিয়া",
    "country_39": "ক্যামেরুন",
    "country_40": "কানাডা",
    "country_41": "কেপ ভার্দে",
    "country_42": "ক্যারিবিয়ান নেদারল্যান্ডস",
    "country_43": "কেম্যান দ্বীপপুঞ্জ",
    "country_44": "মধ্য আফ্রিকান প্রজাতন্ত্র",
    "country_45": "চাদ",
    "country_46": "চিলি",
    "country_47": "চীন",
    "country_48": "ক্রিস্টমাস দ্বীপ",
    "country_49": "কোকোস (কিলিং) দ্বীপপুঞ্জ",
    "country_50": "কলম্বিয়া",
    "country_51": "কুক দ্বীপপুঞ্জ",
    "country_52": "কোস্টারিকা",
    "country_53": "আইভরি কোট",
    "country_54": "ক্রোয়েশিয়া",
    "country_55": "কিউবা",
    "country_56": "সাইপ্রাস",
    "country_57": "চেক প্রজাতন্ত্র",
    "country_58": "গণপ্রজাতান্ত্রিক কঙ্গো",
    "country_59": "ডেনমার্ক",
    "country_60": "জিবুতি",
    "country_61": "ডমিনিকা",
    "country_62": "ডোমিনিকান রিপাবলিক",
    "country_63": "ইকুয়েডর",
    "country_64": "মিশর",
    "country_65": "এল সালভাদর",
    "country_66": "নিরক্ষীয় গিনি",
    "country_67": "ইরিত্রিয়া",
    "country_68": "এস্তোনিয়া",
    "country_69": "ইথিওপিয়া",
    "country_70": "ফকল্যান্ড দ্বীপপুঞ্জ",
    "country_71": "ফারো দ্বীপপুঞ্জ",
    "country_72": "ফেডারেটেড স্টেটস অফ মাইক্রোনেশিয়া",
    "country_73": "ফিজি",
    "country_74": "ফিনল্যান্ড",
    "country_75": "ফ্রান্স",
    "country_76": "একটি দেশের নাম",
    "country_77": "ফরাসি পলিনেশিয়া",
    "country_78": "ফরাসি সাউদার্ন টেরিটোরিজ",
    "country_79": "গ্যাবন",
    "country_80": "গাম্বিয়া",
    "country_81": "জর্জিয়া",
    "country_82": "জার্মানি",
    "country_83": "ঘানা",
    "country_84": "জিব্রাল্টার",
    "country_85": "গ্রেট ব্রিটেন (যুক্তরাজ্য; ইংল্যান্ড)",
    "country_86": "গ্রীস",
    "country_87": "গ্রীনল্যান্ড",
    "country_88": "গ্রেনাডা",
    "country_89": "গুয়াদেলুপ",
    "country_90": "গুয়াম",
    "country_91": "গুয়াতেমালা",
    "country_92": "গার্নসি",
    "country_93": "গিনি",
    "country_94": "গিনি-বিসাউ",
    "country_95": "গায়ানা",
    "country_96": "হাইতি",
    "country_97": "হার্ড দ্বীপ এবং ম্যাকডোনাল্ড দ্বীপ",
    "country_98": "হন্ডুরাস",
    "country_99": "হংকং",
    "country_100": "হাঙ্গেরি",
    "country_101": "আইসল্যান্ড",
    "country_102": "ভারত",
    "country_103": "ইন্দোনেশিয়া",
    "country_104": "ইরান",
    "country_105": "ইরাক",
    "country_106": "আয়ারল্যান্ড",
    "country_107": "আইল অফ ম্যান",
    "country_108": "ইজরায়েল",
    "country_109": "ইতালি",
    "country_110": "জ্যামাইকা",
    "country_111": "জাপান",
    "country_112": "জার্সি",
    "country_113": "জর্ডান",
    "country_114": "কাজাখস্তান",
    "country_115": "কেনিয়া",
    "country_116": "কিরিবাতি",
    "country_117": "কুয়েত",
    "country_118": "কিরগিজস্তান",
    "country_119": "লাওস",
    "country_120": "লাটভিয়া",
    "country_121": "লেবানন",
    "country_122": "লেসোথো",
    "country_123": "লাইবেরিয়া",
    "country_124": "লিবিয়া",
    "country_125": "লিচেনস্টাইন",
    "country_126": "লিথুয়ানিয়া",
    "country_127": "লুক্সেমবার্গ",
    "country_128": "ম্যাকাও",
    "country_129": "মাদাগাস্কার",
    "country_130": "মালাউই",
    "country_131": "মালয়েশিয়া",
    "country_132": "মালদ্বীপ",
    "country_133": "মালি",
    "country_134": "মাল্টা",
    "country_135": "মার্শাল দ্বীপপুঞ্জ",
    "country_136": "মার্টিনিক",
    "country_137": "মৌরিতানিয়া",
    "country_138": "মরিশাস",
    "country_139": "মায়োট",
    "country_140": "মেক্সিকো",
    "country_141": "মলদোভা",
    "country_142": "মোনাকো",
    "country_143": "মঙ্গোলিয়া",
    "country_144": "মন্টিনিগ্রো",
    "country_145": "মন্টসেরাট",
    "country_146": "মরক্কো",
    "country_147": "মোজাম্বিক",
    "country_148": "মায়ানমার (বার্মা)",
    "country_149": "নামিবিয়া",
    "country_150": "নাউরু",
    "country_151": "নেপাল",
    "country_152": "নেদারল্যান্ডস",
    "country_153": "নতুন ক্যালেডোনিয়া",
    "country_154": "নিউজিল্যান্ড",
    "country_155": "নিকারাগুয়া",
    "country_156": "নাইজার",
    "country_157": "নাইজেরিয়া",
    "country_158": "নিউ",
    "country_159": "নরফোক দ্বীপ",
    "country_160": "উত্তর কোরিয়া",
    "country_161": "উত্তর মারিয়ানা দ্বীপপুঞ্জ",
    "country_162": "নরওয়ে",
    "country_163": "ওমান",
    "country_164": "পাকিস্তান",
    "country_165": "পালাউ",
    "country_166": "ফিলিস্তিনি অঞ্চল",
    "country_167": "পানামা",
    "country_168": "পাপুয়া নিউ গিনি",
    "country_169": "প্যারাগুয়ে",
    "country_170": "পেরু",
    "country_171": "পিটকেয়ার্ন দ্বীপপুঞ্জ",
    "country_172": "পোল্যান্ড",
    "country_173": "পর্তুগাল",
    "country_174": "পুয়ের্তো রিকো",
    "country_175": "কাতার",
    "country_176": "ম্যাসেডোনিয়া প্রজাতন্ত্র (FYROM)",
    "country_177": "কঙ্গো প্রজাতন্ত্র",
    "country_178": "রিইউনিয়ন",
    "country_179": "রোমানিয়া",
    "country_180": "রাশিয়ান ফেডারেশন",
    "country_181": "রুয়ান্ডা",
    "country_182": "সেন্ট বার্থেলেমি",
    "country_183": "সেন্ট মার্টিন (ফ্রান্স)",
    "country_184": "সেন্ট-পিয়ের এবং মিকেলন",
    "country_185": "সামোয়া",
    "country_186": "সান মারিনো",
    "country_187": "সাও টোমে এবং প্রিন্সিপে",
    "country_188": "সৌদি আরব",
    "country_189": "সেনেগাল",
    "country_190": "সার্বিয়া",
    "country_191": "সেশেলস",
    "country_192": "সিয়েরা লিওন",
    "country_193": "সিঙ্গাপুর",
    "country_194": "স্লোভাকিয়া",
    "country_195": "স্লোভেনিয়া",
    "country_196": "সলোমান দ্বীপপুঞ্জ",
    "country_197": "সোমালিয়া",
    "country_198": "দক্ষিন আফ্রিকা",
    "country_199": "দক্ষিণ জর্জিয়া এবং দক্ষিণ স্যান্ডউইচ ইসলা",
    "country_200": "দক্ষিণ কোরিয়া",
    "country_201": "দক্ষিণ সুদান",
    "country_202": "স্পেন",
    "country_203": "শ্রীলংকা",
    "country_204": "সেন্ট হেলেনা ও নির্ভরতা",
    "country_205": "সেন্ট কিটস ও নেভিস",
    "country_206": "সেন্ট লুসিয়া",
    "country_207": "সেন্ট ভিনসেন্ট এবং গ্রেনাডাইনস",
    "country_208": "সুদান",
    "country_209": "সুরিনাম",
    "country_210": "সোয়াজিল্যান্ড",
    "country_211": "সুইডেন",
    "country_212": "সুইজারল্যান্ড",
    "country_213": "সিরিয়া",
    "country_214": "তাইওয়ান",
    "country_215": "তাজিকিস্তান",
    "country_216": "তানজানিয়া",
    "country_217": "টেমপ্লেট:দেশের তথ্য SJM Svalbard",
    "country_218": "থাইল্যান্ড",
    "country_219": "বাহামা",
    "country_220": "কোমোরোস",
    "country_221": "ফিলিপাইনগণ",
    "country_222": "তিমুর-লেস্তে (পূর্ব তিমুর)",
    "country_223": "যাও",
    "country_224": "টোকেলাউ",
    "country_225": "টোঙ্গা",
    "country_226": "ত্রিনিদাদ ও টোবাগো",
    "country_227": "তিউনিসিয়া",
    "country_228": "তুরস্ক",
    "country_229": "তুর্কমেনিস্তান",
    "country_230": "তুর্কি ও কাইকোস দ্বীপপুঞ্জ",
    "country_231": "টুভালু",
    "country_232": "উগান্ডা",
    "country_233": "ইউক্রেন",
    "country_234": "সংযুক্ত আরব আমিরাত",
    "country_235": "মার্কিন যুক্তরাষ্ট্র ক্ষুদ্র ও পার্শ্ববর্তী দ্বীপপুঞ্জ",
    "country_236": "মার্কিন যুক্তরাষ্ট্র (মার্কিন যুক্তরাষ্ট্র)",
    "country_237": "মার্কিন যুক্তরাষ্ট্র ভার্জিন দ্বীপপুঞ্জ",
    "country_238": "উরুগুয়ে",
    "country_239": "উজবেকিস্তান",
    "country_240": "ভানুয়াতু",
    "country_241": "ভ্যাটিকান সিটি (The Holy See)",
    "country_242": "ভেনেজুয়েলা",
    "country_243": "ভিয়েতনাম",
    "country_244": "ওয়ালিস এবং ফুটুনা",
    "country_245": "পশ্চিম সাহারা",
    "country_246": "ইয়েমেন",
    "country_247": "জাম্বিয়া",
    "country_248": "জিম্বাবুয়ে",
    "google_login": "Google দিয়ে সাইন ইন করুন",
    "State": "অবস্থা",
    "Activation_code": "অ্যাক্টিভেশন কোড",
    "Question": "আপনি লগ ইন করেছেন এমন সমস্ত অ্যাপের তালিকা করুন",
    "Copy_complete": "কপি সম্পূর্ণ",
    "footer": "কপিরাইট © 2024 MindOnMap স্টুডিও। সমস্ত অধিকার সংরক্ষিত।",
    "change_password_success": "পাসওয়ার্ড সফলভাবে পরিবর্তন",
    "successful_login_title": "লগইন সফল",
    "product_page": "পণ্য পাতা >>",
    "successful_login_info": "লগইন সম্পন্ন হয়েছে। অনুগ্রহ করে বর্তমান পৃষ্ঠাটি বন্ধ করুন এবং নিম্নলিখিত প্রক্রিয়াটি চালিয়ে যেতে মূল ট্যাবে ফিরে যান। বর্তমান পৃষ্ঠাটি 5 সেকেন্ডের মধ্যে স্বয়ংক্রিয়ভাবে বন্ধ হয়ে যাবে। \"সম্পন্ন\" বোতামে ক্লিক করে স্বয়ংক্রিয়ভাবে বন্ধ বা বন্ধ করা ব্যর্থ হলে, দয়া করে সরাসরি এই ট্যাবটি বন্ধ করুন৷",
    "successful_login_info_firefox": "লগইন সম্পন্ন হয়েছে। অনুগ্রহ করে বর্তমান পৃষ্ঠাটি বন্ধ করুন এবং নিম্নলিখিত প্রক্রিয়াটি চালিয়ে যেতে মূল ট্যাবে ফিরে যান।",
    "my_account": "আমার অ্যাকাউন্ট",
    "my_history": "আমার ইতিহাস",
    "remove_watermark": "ওয়াটারমার্ক সরান",
    "no_history": "কোন ইতিহাস নেই",
    "history_all": "সব নির্বাচন করুন",
    "history_open": "খোলা",
    "history_down": "ডাউনলোড করুন",
    "history_delete": "মুছে ফেলা",
    "history_clear": "সাফ অবৈধ",
    "images": "ছবি(গুলি)",
    "use_this_function": "এই পণ্যটি ব্যবহার করুন >>",
    "hd_downloading": "এইচডি অরিজিনাল ছবি ডাউনলোড করার সুবিধা:",
    "lifetimeRemaining": "লাইফটাইম-বাকি",
    "Remaining": "অবশিষ্ট",
    "email_verification": "ইমেইলের সত্যতা যাচাই",
    "email_verification_info": "আমরা আপনার ইমেল <span class=\"email\"></span> এ যাচাইকরণ বার্তা পাঠিয়েছি, এবং দয়া করে যাচাইকরণ শেষ করুন৷ যাচাই করার পরে, সুবিধাগুলি স্বয়ংক্রিয়ভাবে সিঙ্ক হবে।",
    "wrong_email": "ভুল ইমেল ঠিকানা?",
    "click_here_to_modify": "পরিবর্তন করতে এখানে ক্লিক করুন",
    "get_more_help": "আরও সাহায্য পান?",
    "click_here": "এখানে ক্লিক করুন",
    "email_verification_info_success": "আপনার ইমেল অ্যাকাউন্ট যাচাই করার জন্য অভিনন্দন.",
    "email_verification_info_error": "মেয়াদোত্তীর্ণ লিঙ্কের কারণে যাচাইকরণ ব্যর্থ হয়েছে।",
    "registration_succeeded": "নিবন্ধন সফল হয়েছে",
    "registration_succeeded_info_1": "অভিনন্দন! আপনি সফলভাবে নিবন্ধন করেছেন. আমরা আপনার ইমেল <span class=\"email\"></span> এ যাচাইকরণ বার্তা পাঠিয়েছি, এবং এই ইমেলের সুবিধাগুলি ব্যবহার করার জন্য দয়া করে যাচাই করা শেষ করুন৷",
    "registration_succeeded_info_2": "হোম পেজে ফিরে যেতে এবং এই পণ্যটি ব্যবহার করতে \"সম্পন্ন\" এ ক্লিক করুন।",
    "registration_succeeded_info_3": "বর্তমান পৃষ্ঠাটি বন্ধ করতে \"সম্পন্ন\" ক্লিক করুন এবং নিম্নলিখিত ক্রিয়াকলাপগুলির জন্য হোম পেজে ফিরে আসুন৷ বন্ধ করা ব্যর্থ হলে, ট্যাবটি ম্যানুয়ালি বন্ধ করুন।",
    "verify_email": "ইমেল যাচাই করুন",
    "registered_email_not_verified": "নিবন্ধিত ইমেলটি যাচাই করা হয়নি, দয়া করে একবারে যাচাই করুন৷",
    "email_verification_time_1": "একটি যাচাইকরণ ইমেল পাননি?",
    "email_verification_time_2": "পরে",
    "email_verification_time_3": "এটি পুনরায় পাঠাতে এখানে ক্লিক করুন",
    "error_26301": "ত্রুটি কোড: 26301, আমাদের সাথে যোগাযোগ করুন",
    "error_26302": "ত্রুটি কোড: 26302, আমাদের সাথে যোগাযোগ করুন",
    "error_26303": "ইমেল বিন্যাস ত্রুটি (ত্রুটির কোড: 26303)। দয়া করে আবার লিখুন",
    "error_26304": "8 অক্ষরের উপরে পাসওয়ার্ড সুপারিশ করা হয় (ত্রুটি কোড: 26304)",
    "error_26305": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26305)। এটি আবার চেষ্টা করুন",
    "error_26306": "ইমেল নিবন্ধিত হয়েছে, অনুগ্রহ করে <a href=\"https://account.mindonmap.com/login/\">লগ ইন করতে যান</a>",
    "error_26307": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26307)। এটি আবার চেষ্টা করুন",
    "error_26308": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26308)। এটি আবার চেষ্টা করুন",
    "error_26401": "ত্রুটি কোড: 26401, এটি আবার চেষ্টা করুন",
    "error_26402": "ইমেল যাচাই করা হয়েছে (ত্রুটির কোড: 26402), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26403": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26403)। এটি আবার চেষ্টা করুন",
    "error_26404": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 26404)। এটা আবার চেষ্টা করুন",
    "error_26501": "ত্রুটি কোড: 26501, আমাদের সাথে যোগাযোগ করুন",
    "error_26502": "ত্রুটি কোড: 26502, আমাদের সাথে যোগাযোগ করুন",
    "error_26503": "ইমেল বিন্যাস ত্রুটি (ত্রুটির কোড: 26503)। দয়া করে আবার লিখুন",
    "error_26504": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26504)। এটি আবার চেষ্টা করুন",
    "error_26505": "ইমেল নিবন্ধিত করা হয়নি, অনুগ্রহ করে <a href=\"https://account.mindonmap.com/register/\">প্রথমে এটি নিবন্ধন করুন</a>",
    "error_26506": "ইমেল যাচাই করা হয়েছে.",
    "error_26507": "Reuqest ব্যর্থ হয়েছে (ত্রুটির কোড: 26507)। এটা আবার চেষ্টা করুন",
    "error_26508": "যাচাইকরণ করা হয়েছে (ত্রুটি কোড: 26508), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26509": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 26509), অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26510": "ত্রুটি কোড: 26510, আমাদের সাথে যোগাযোগ করুন",
    "error_26601": "ত্রুটি কোড: 26601, আমাদের সাথে যোগাযোগ করুন",
    "error_26602": "ত্রুটি কোড: 26602, আমাদের সাথে যোগাযোগ করুন",
    "error_26603": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26603)। এটি আবার চেষ্টা করুন",
    "error_26604": "ত্রুটি কোড: 26604, আমাদের সাথে যোগাযোগ করুন",
    "error_26605": "ত্রুটি কোড: 26605, আমাদের সাথে যোগাযোগ করুন",
    "error_26701": "ত্রুটি কোড: 26701, আমাদের সাথে যোগাযোগ করুন",
    "error_26702": "Reuqest ব্যর্থ হয়েছে (ত্রুটি কোড: 26702)। এটা আবার চেষ্টা করুন",
    "error_26703": "ত্রুটি কোড: 26703, আমাদের সাথে যোগাযোগ করুন",
    "error_26704": "ত্রুটি কোড: 26704, আমাদের সাথে যোগাযোগ করুন",
    "error_26705": "লগ ইন করার জন্য অপেক্ষা করুন (ত্রুটি কোড: 26705)। এটি আবার চেষ্টা করুন",
    "no_cookie": "আপনি আপনার ব্রাউজারে ব্লক সমস্ত কুকি ফাংশনটি চালু করেছেন, তাই আপনি লগ ইন করতে পারবেন না। অনুগ্রহ করে সমস্ত কুকিজকে অনুমতি দিন এর বাক্সটি চেক করতে সেটিংসে যান।",
    "error_26801": "ত্রুটি কোড: 26801, আমাদের সাথে যোগাযোগ করুন",
    "error_26802": "ত্রুটি কোড: 26802, আমাদের সাথে যোগাযোগ করুন",
    "error_26803": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 26803)। এটি আবার চেষ্টা করুন",
    "error_26804": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 26804)। এটা আবার চেষ্টা করুন",
    "error_order": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটি কোড: 27098), অনুগ্রহ করে আবার চেষ্টা করুন!",
    "error_order1": "অর্ডার ক্যোয়ারী অসম্পূর্ণ (ত্রুটি কোড: ",
    "error_order2": "）, অনুগ্রহ করে রিফ্রেশ করুন এবং আবার চেষ্টা করুন।",
    "modify_email_title": "ইমেল পরিবর্তন করুন",
    "modify_email_info": "আপনি আপনার অ্যাকাউন্টে লগ ইন করতে পরিবর্তিত ইমেল ব্যবহার করতে পারেন।",
    "images_per": "প্রতি ছবি",
    "error_26101": "ত্রুটি: 26101. আমাদের সাথে যোগাযোগ করুন",
    "error_26102": "ত্রুটি: 26102. আমাদের সাথে যোগাযোগ করুন",
    "error_26103": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড:26103)। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26104": "ত্রুটি কোড: 26104, এটি আবার চেষ্টা করুন",
    "error_26105": "ত্রুটি কোড: 26105, এটি আবার চেষ্টা করুন",
    "error_26106": "মুছে ফেলতে ব্যর্থ (ত্রুটির কোড: 26106)। আবার চেষ্টা করুন",
    "error_26201": "ত্রুটি: 26201. আমাদের সাথে যোগাযোগ করুন",
    "error_26202": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড:26202)। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26001": "ত্রুটি: 26001. আমাদের সাথে যোগাযোগ করুন",
    "error_26002": "ত্রুটি: 26002. আমাদের সাথে যোগাযোগ করুন",
    "error_26003": "ত্রুটি: 26003. আমাদের সাথে যোগাযোগ করুন",
    "error_26004": "ত্রুটি: 26004. আমাদের সাথে যোগাযোগ করুন",
    "error_26005": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড:26005)। অনুগ্রহ করে আবার চেষ্টা করুন",
    "error_26006": "ত্রুটি কোড: 26006, এটি আবার চেষ্টা করুন",
    "error_26008": "ত্রুটি: 26008. আমাদের সাথে যোগাযোগ করুন",
    "go_to_the_home_page": "হোম পেজে যান",
    "error_27101": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 27101)। আবার চেষ্টা করুন",
    "error_27201": "ত্রুটি কোড: 27201, আমাদের সাথে যোগাযোগ করুন",
    "error_27202": "ত্রুটি কোড: 27202, এটি আবার চেষ্টা করুন",
    "error_27203": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 27203)। এটি আবার চেষ্টা করুন",
    "error_27204": "অবৈধ কোড (ত্রুটির কোড: 27204)।",
    "error_27205": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 27205)। এটি আবার চেষ্টা করুন",
    "error_27206": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 27206)। এটি আবার চেষ্টা করুন",
    "error_27207": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 27207)। এটি আবার চেষ্টা করুন",
    "no_history_found": "আপনি কোন টুল ব্যবহার করেননি! <a href=\"/\">রিফ্রেশ করুন</a> অথবা <a href=\"https://www.mindonmap.com/\">অফিসিয়াল ওয়েবসাইটে যান</a>",
    "error_25301": "ত্রুটি: 25301. আমাদের সাথে যোগাযোগ করুন",
    "error_25302": "ত্রুটি: 25302. আমাদের সাথে যোগাযোগ করুন",
    "error_25303": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 25303)। আবার চেষ্টা করুন",
    "error_25304": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 25304)। আবার চেষ্টা করুন",
    "error_25305": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 25305)। আবার চেষ্টা করুন",
    "error_25306": "অনুরোধ ব্যর্থ হয়েছে (ত্রুটির কোড: 25306)। আবার চেষ্টা করুন",
    "image_upscaler_p": "ওয়াটারমার্ক ছাড়া ছবি ডাউনলোডের সুবিধা:",
    "Available_for": "সহজলভ্যের জন্যে:",
    "credit_per": "প্রতি HD ছবি %s ক্রেডিট(গুলি)",
    "still_valid": "কেনা প্ল্যান(গুলি) এখনও বৈধ",
    "credit": "ক্রেডিট(গুলি)",
    "pc_3rd_info": "সফলভাবে লগ ইন করুন। অনুগ্রহ করে আরও অপারেশনের জন্য অ্যাপ্লিকেশনটিতে যান।",
    "use_online": "অনলাইন পরিষেবা ব্যবহার করুন",
    "use_download": "ডেস্কটপ প্রোগ্রাম ব্যবহার করুন",
    "use_immediately": "অবিলম্বে ব্যবহার করুন",
    "Use_in_browser": "ব্রাউজারে ব্যবহার করুন",
    "win_desktop": "উইন্ডোজ",
    "Mac_desktop": "ম্যাক",
    "credits_per": "প্রতি মাসে {%} ক্রেডিট",
    "expire": "মেয়াদ অতিক্রান্ত হওয়ার সময়:",
    "viewDetails": "বিস্তারিত দেখুন",
    "viewHistory": "ইতিহাস সুবিধা দেখুন >>",
    "viewDetailsInfo": "বিজ্ঞপ্তি: মেয়াদ শেষ হওয়ার 7 দিনের মধ্যে সাবস্ক্রিপশন সুবিধাগুলি পুনর্নবীকরণ করা হলে, অব্যবহৃত সুবিধাগুলি ব্যবহার করা চালিয়ে যেতে পারে। এছাড়াও, মেয়াদ শেষ হওয়ার সময়টি নতুন সাবস্ক্রিপশনের মেয়াদ শেষ হওয়ার সময় স্বয়ংক্রিয়ভাবে আপডেট হবে। মেয়াদ শেষ হওয়ার 7 দিনের পরে যদি কোনও নতুন সাবস্ক্রিপশন না থাকে তবে মেয়াদ শেষ হয়ে যাওয়া সমস্ত সুবিধা সাফ হয়ে যাবে।",
    "connect_account": "আপনার অ্যাকাউন্টে ইমেল আবদ্ধ করুন",
    "connect_account_info": "বাঁধাই করার পরে, আপনি এই ইমেল ঠিকানা দিয়ে লগ ইন করতে পারেন।",
    "connect_now": "এখনই বাঁধুন",
    "no_email_bind": "কোনো ইমেল বাঁধাই নেই",
    "bind_email": "ইমেল বাঁধুন",
    "connect_your_email_placeholder": "আপনার ইমেইল ঠিকানা লিখুন",
    "bind_an_email": "একটি ইমেল আবদ্ধ করুন",
    "bind_info": "আপনি সফলভাবে লগ ইন করেছেন. আপনার সুবিধা সক্রিয় করতে আপনার অ্যাকাউন্টে একটি ইমেল আবদ্ধ করুন.",
    "bind_later": "পরে বাঁধুন",
    "hi": "হাই, %s!",
    "Personal_Information": "ব্যক্তিগত তথ্য",
    "Access": "অ্যাক্সেস",
    "Subscription_Plan": "(সাবস্ক্রিপশন প্ল্যান)",
    "No_orders": "কোন আদেশ পাওয়া যায়নি.",
    "No_data": "কোন তথ্য নেই",
    "Featured_Products": "বৈশিষ্ট্যযুক্ত পণ্য",
    "More_Products": "আরো পণ্য",
    "Free_Download": "বিনামুল্যে ডাউনলোড",
    "Get_Started": "এবার শুরু করা যাক",
    "Subscribe": "সাবস্ক্রাইব",
    "Verified": "যাচাই",
    "back_to_account_center": "অ্যাকাউন্ট সেন্টারে ফিরে যান",
    "success": "সফলতার !",
    "successfully": "আপনি সফলভাবে একটি অ্যাকাউন্ট নিবন্ধন করেছেন৷",
    "Continue": "চালিয়ে যান",
    "Already": "ইতিমধ্যে একটি সদস্যপদ আছে?",
    "loading_verification": "যাচাইকরণের স্থিতি পরীক্ষা করা হচ্ছে...",
    "email_no_verification": "দুঃখিত, নিবন্ধিত ইমেল ঠিকানা যাচাই করা হয়নি. অনুগ্রহ করে উপরের নির্দেশাবলী অনুযায়ী যাচাইকরণ সম্পূর্ণ করুন এবং নিবন্ধন সম্পূর্ণ করতে আবার \"সম্পন্ন\" বোতামে ক্লিক করুন।",
    "will_expire_after": "পরে মেয়াদ শেষ হবে",
    "hours": "ঘন্টার",
  },
  "bs": {
    "overtime": "Šifra greške: {%}, pokušajte ponovo",
    "isnetwork": "Internet greška. Provjerite i pokušajte ponovo",
    "email_placeholder": "Email",
    "email_empty": "Molimo unesite email",
    "email_not_valid": "E-pošta nije važeća",
    "email_not_valid_1": "Molimo unesite svoju e-poštu",
    "email_not_valid_2": "E-pošta nije važeća, molimo koristite drugu adresu.",
    "email_not_valid_3": "Nema unosa e-pošte",
    "password_placeholder": "Lozinka",
    "password_empty": "Unesite lozinku",
    "password_not_valid": "Netačan nalog ili lozinka",
    "password_not_valid_1": "Potrebna je lozinka duža od 8 znakova",
    "password_not_valid_2": "Molimo kreirajte lozinku",
    "password_placeholder_1": "Kreirajte svoju lozinku",
    "password_placeholder_2": "Potvrdite lozinku",
    "password_placeholder_3": "Kreirajte novu lozinku",
    "password_placeholder_4": "Potvrdili novu lozinku",
    "password_placeholder_5": "Unesite staru lozinku",
    "copy_password_empty": "Molimo potvrdite lozinku",
    "copy_password_not_valid": "Molimo potvrdite svoju lozinku",
    "copy_passwords_inconsistent": "Vaša potvrda lozinke ne odgovara",
    "code_empty": "Unesite verifikacioni kod",
    "code_not_valid": "Nevažeći verifikacioni kod",
    "code_placeholder": "Verifikacijski kod",
    "not_received_code": "Ako vaše poštansko sanduče dugo nije primilo verifikacioni kod, ponovo ga primite.",
    "get_first_code": "Prvo uzmite verifikacioni kod.",
    "last_name_placeholder": "Molimo unesite svoje prezime",
    "first_name_placeholder": "Molimo unesite svoje ime",
    "address_placeholder": "Molimo unesite svoju adresu",
    "no_code_text": "Poslali smo verifikacioni kod. Molimo unesite svoj kod. <span class='tips'>Niste primili kod?",
    "no_code_text_1": "1. Uvjerite se da je adresa e-pošte važeća i da može primati e-poštu.",
    "no_code_text_2": "2. Pošto sistem automatski šalje e-poštu, može biti označena kao neželjena pošta ili neželjena e-pošta. Molimo provjerite da li je e-mail u folderu Otpad.",
    "no_code_text_3": "3. Ne možete riješiti svoj problem? ",
    "no_code_text_3_span": "Zatim kliknite ovdje da nas kontaktirate.",
    "order_no": "Niste kupili nijedan proizvod, ako imate bilo kakvo pitanje, molimo <a href=\"https://www.mindonmap.com/contact-us/\">kontaktirajte nas</a>.",
    "error_24901": "Trenutni račun nema povezanu e-poštu i ne može pronaći narudžbe. Molimo povežite email.",
    "user_guide": "Uputstvo za upotrebu>>",
    "download": "Skinuti",
    "order_number": "Broj narudžbe:",
    "Refund": "Povrat novca",
    "Disabled": "Onemogućeno",
    "Normal": "Normalno",
    "Modify": "Modify",
    "Modify_1": "Izmijeni>>",
    "Connect": "Povežite se",
    "unlink_success": "Uspješno prekinuti vezu",
    "connect_success": "Povežite se uspješno",
    "feedback_title": "Hvala na povratnim informacijama. Molimo ostavite svoj problem i mi ćemo vam odgovoriti u roku od 24 sata.",
    "feedback_thank_you": "Hvala!<br />Vaše povratne informacije su uspješno poslane.",
    "feedback_email": "Unesite svoju e-poštu ovdje!",
    "feedback_content": "Ovdje ostavite svaki problem ili prijedlog na koji ste naišli.",
    "feedback_submit": "Submit",
    "form_contents": "Niste unijeli nikakav opis. Molimo unesite ga i pošaljite ponovo.",
    "old_password": "Molimo unesite staru lozinku",
    "new_password": "Molimo kreirajte novu lozinku",
    "old_new_password": "Nova lozinka ne može biti ista kao stara",
    "incorrect_password": "Neispravna lozinka",
    "delete_no": "Izbriši odmah",
    "Caps": "Caps lock je uključen",
    "Get": "Get",
    "Done": "Gotovo",
    "error_20001": "Greška: 20001. Molimo prijavite se ponovo.",
    "error_20002": "Greška: 20002. Molimo prijavite se ponovo.",
    "error_20003": "Greška: 20003. Molimo prijavite se ponovo.",
    "error_20004": "Zahtjev nije uspio (šifra greške: 20004). Molimo pokušajte ponovo.",
    "error_20005": "Sesija prijave je istekla (Greška: 20005). Molimo prijavite se ponovo.",
    "error_20006": "Zahtjev nije uspio (šifra greške: 20006). Molimo pokušajte ponovo.",
    "error_20007": "Sesija prijave je istekla (Greška: 20007). Molimo prijavite se ponovo.",
    "error_20008": "Sesija prijave je istekla (Greška: 20008). Molimo prijavite se ponovo.",
    "error_20009": "Sesija prijave je istekla (Greška: 20009). Molimo prijavite se ponovo.",
    "error_20101": "Molimo unesite svoju e-poštu (kod greške: 20101)",
    "error_20102": "E-pošta nije važeća (šifra greške: 20102)",
    "error_20103": "Zahtjev nije uspio (šifra greške: 20103). Molimo pokušajte ponovo",
    "error_20104": "E-pošta je već u upotrebi, molimo <a href=\"https://account.mindonmap.com/login/\">prijavite se</a> ili se registrirajte s novom",
    "error_20105": "Zahtjev nije uspio (šifra greške: 20105). Molimo pokušajte ponovo",
    "error_20106": "Slanje e-pošte nije uspjelo, pokušajte ponovo",
    "error_20201": "Molimo unesite svoju e-poštu (šifra greške: 20201)",
    "error_20202": "Unesite svoju lozinku (šifra greške: 20202)",
    "error_20203": "Unesite verifikacioni kod (šifra greške: 20203)",
    "error_20204": "E-pošta nije važeća (šifra greške: 20204)",
    "error_20205": "Potrebna je lozinka duža od 8 znakova (šifra greške: 20205)",
    "error_20206": "Zahtjev nije uspio (šifra greške: 20206). Molimo pokušajte ponovo",
    "error_20207": "Nevažeći verifikacioni kod",
    "error_20208": "Zahtjev nije uspio (šifra greške: 20208). Molimo pokušajte ponovo",
    "error_20209": "Zahtjev nije uspio (šifra greške: 20209). Molimo pokušajte ponovo",
    "error_20301": "Molimo unesite svoju e-poštu (kod greške: 20301)",
    "error_20302": "Greška: 20302. Kontaktirajte nas",
    "error_20303": "E-pošta nije važeća (šifra greške: 20303)",
    "error_20304": "Zahtjev nije uspio (šifra greške: 20304). Molimo pokušajte ponovo",
    "error_20305": "Račun ne postoji. Molimo vas ponovo unesite ili prvo <a href=\"https://account.mindonmap.com/register/\">Kreirajte</a>.",
    "error_20306": "Još nema lozinke. Koristite <a href=\"https://account.mindonmap.com/passwordless-login/\">prijavu bez lozinke</a> ili <a href=\"https://account.mindonmap.com/create-password/\"> postavite lozinku</a> i prijavite se.",
    "error_20308": "Zahtjev nije uspio (šifra greške: 20308). Molimo pokušajte ponovo",
    "error_20401": "Odjava nije uspjela (šifra greške: 20401). Molimo pokušajte ponovo",
    "error_20501": "Molimo unesite svoju e-poštu (šifra greške: 20501)",
    "error_20502": "E-pošta nije važeća (šifra greške: 20502)",
    "error_20503": "Zahtjev nije uspio (šifra greške: 20503). Molimo pokušajte ponovo",
    "error_20504": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo.",
    "error_20601": "Molimo unesite svoju e-poštu (šifra greške: 20601)",
    "error_20602": "Unesite verifikacioni kod (šifra greške: 20602)",
    "error_20603": "E-pošta nije važeća (šifra greške: 20603)",
    "error_20604": "Zahtjev nije uspio (šifra greške: 20604). Molimo pokušajte ponovo",
    "error_20606": "Zahtjev nije uspio (šifra greške: 20606). Molimo pokušajte ponovo",
    "error_20607": "Zahtjev nije uspio (šifra greške: 20607). Molimo pokušajte ponovo",
    "error_20608": "Zahtjev nije uspio (šifra greške: 20608). Molimo pokušajte ponovo",
    "error_20701": "Molimo unesite svoju e-poštu (šifra greške: 20701)",
    "error_20702": "E-pošta nije važeća (šifra greške: 20702)",
    "error_20703": "Zahtjev nije uspio (šifra greške: 20703). Molimo pokušajte ponovo",
    "error_20704": "Račun ne postoji. Molimo vas ponovo unesite ili prvo <a href=\"https://account.mindonmap.com/register/\">Kreirajte</a>.",
    "error_20705": "Zahtjev nije uspio (šifra greške: 20705). Molimo pokušajte ponovo",
    "error_20706": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo",
    "error_20801": "Molimo unesite svoju e-poštu (šifra greške: 20801)",
    "error_20802": "Greška: 20802. Kontaktirajte nas",
    "error_20803": "Unesite verifikacioni kod (šifra greške: 20803)",
    "error_20804": "E-pošta nije važeća (šifra greške: 20804)",
    "error_20805": "Potrebna je lozinka duža od 8 znakova (šifra greške: 20805)",
    "error_20806": "Zahtjev nije uspio (šifra greške: 20806). Molimo pokušajte ponovo",
    "error_20808": "Zahtjev nije uspio (šifra greške: 20808). Molimo pokušajte ponovo",
    "error_20901": "Zahtjev nije uspio (šifra greške: 20901). Molimo pokušajte ponovo",
    "error_20902": "Zahtjev nije uspio (šifra greške: 20902). Molimo pokušajte ponovo",
    "error_21000": "Promjene su sačuvane",
    "error_21001": "Nisu dostavljene nikakve informacije (šifra greške: 21001)",
    "error_21002": "Zahtjev nije uspio (šifra greške: 21002). Molimo pokušajte ponovo",
    "error_21101": "Molimo unesite svoju e-poštu (šifra greške: 21101)",
    "error_21102": "E-pošta nije važeća (šifra greške: 21102)",
    "error_21103": "Zahtjev nije uspio (šifra greške: 21103), pokušajte ponovo",
    "error_21104": "E-pošta je već povezana, molimo koristite novu",
    "error_21105": "Zahtjev nije uspio (šifra greške: 21105), pokušajte ponovo",
    "error_21106": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo",
    "error_21201": "Molimo unesite svoju e-poštu (šifra greške: 21201)",
    "error_21202": "Unesite verifikacioni kod (kod greške: 21202)",
    "error_21203": "E-pošta nije važeća (šifra greške: 21203)",
    "error_21204": "Greška: 21204. Kontaktirajte nas",
    "error_21205": "Greška: 21205. Kontaktirajte nas",
    "error_21206": "Potrebna je lozinka duža od 8 znakova (šifra greške: 21206)",
    "error_21207": "Zahtjev nije uspio (šifra greške: 21207). Molimo pokušajte ponovo",
    "error_21209": "Zahtjev nije uspio (šifra greške: 21209). Molimo pokušajte ponovo",
    "error_21301": "Molimo unesite staru lozinku (šifra greške: 21301)",
    "error_21302": "Molimo kreirajte novu lozinku (šifra greške: 21302)",
    "error_21303": "Nova lozinka ne može biti ista kao stara. (Greška: 21303)",
    "error_21304": "Potrebna je lozinka duža od 8 znakova (šifra greške: 21304)",
    "error_21306": "Zahtjev nije uspio (šifra greške: 21306). Molimo pokušajte ponovo",
    "error_21402": "Zahtjev nije uspio (šifra greške: 21402). Molimo pokušajte ponovo",
    "error_21403": "Slanje verifikacionog koda nije uspjelo. Molimo pošaljite ponovo",
    "error_21500": "Račun je izbrisan",
    "error_21501": "Unesite verifikacioni kod (šifra greške: 21501)",
    "error_21502": "Sesija prijave je istekla (Greška: 21502). Molimo prijavite se ponovo.",
    "error_21503": "Zahtjev nije uspio (šifra greške: 21503). Molimo pokušajte ponovo",
    "error_21505": "Zahtjev nije uspio (šifra greške: 21505), pokušajte ponovo",
    "error_21601": "Greška: 20601. Kontaktirajte nas",
    "error_21602": "Nevažeća verifikacija (Greška: 20602). Molimo pokušajte ponovo.",
    "error_21603": "Greška: 20603. Pokušajte ponovo",
    "error_21604": "Zahtjev nije uspio (šifra greške: 21604). Molimo pokušajte ponovo",
    "error_21606": "Zahtjev nije uspio (šifra greške: 21606). Molimo pokušajte ponovo",
    "error_21611": "Zahtjev nije uspio (šifra greške: 21611). Molimo pokušajte ponovo",
    "error_21801": "Greška: 21801. Kontaktirajte nas",
    "error_21802": "Zahtjev nije uspio (Greška: 21802). Molimo pokušajte ponovo",
    "error_21803": "Greška: 21803. Pokušajte ponovo",
    "error_21804": "Zahtjev nije uspio (šifra greške: 21804). Molimo pokušajte ponovo",
    "error_21806": "Greška: 21806. Pokušajte ponovo",
    "error_21807": "Greška: 21807. Kontaktirajte nas",
    "error_21808": "Greška: 21808. Kontaktirajte nas",
    "error_21809": "Greška: 21809. Kontaktirajte nas",
    "error_21810": "Greška: 21810. Kontaktirajte nas",
    "error_21811": "Greška: 21811. Kontaktirajte nas",
    "error_21812": "Greška: 21812. Molimo kontaktirajte nas",
    "error_21813": "Zahtjev nije uspio (šifra greške: 21813). Molimo pokušajte ponovo",
    "error_21814": "Greška: 21814. Kontaktirajte nas",
    "error_21815": "Zahtjev nije uspio (šifra greške: 21815). Molimo pokušajte ponovo",
    "error_21816": "Greška: 21816. Kontaktirajte nas",
    "error_21817": "Greška: 21817. Kontaktirajte nas",
    "error_21818": "Greška: 21818. Kontaktirajte nas",
    "error_21819": "Zahtjev nije uspio (šifra greške: 21819). Molimo pokušajte ponovo",
    "error_21820": "Greška: 21820. Kontaktirajte nas",
    "error_21821": "Greška: 21821. Kontaktirajte nas",
    "error_21822": "Greška: 21822. Kontaktirajte nas",
    "error_21823": "Zahtjev nije uspio (šifra greške: 21823). Molimo pokušajte ponovo",
    "error_21824": "Zahtjev nije uspio (šifra greške: 21824). Molimo pokušajte ponovo",
    "error_21825": "Zahtjev nije uspio (šifra greške: 21825). Molimo pokušajte ponovo",
    "error_21826": "Zahtjev nije uspio (šifra greške: 21826). Molimo pokušajte ponovo",
    "error_21901": "Greška: 21901. Kontaktirajte nas",
    "error_21902": "Zahtjev nije uspio (šifra greške: 21902). Molimo pokušajte ponovo",
    "error_21903": "Status računa se promijenio (šifra greške: 21903), osvježite stranicu i pokušajte ponovo",
    "error_21904": "Greška: 21904. Pokušajte ponovo",
    "error_21905": "Greška: 21905. Pokušajte ponovo",
    "error_21906": "Zahtjev nije uspio (šifra greške: 21906). Molimo pokušajte ponovo",
    "error_21907": "Google račun je povezan s drugim računom",
    "error_21908": "Zahtjev nije uspio (šifra greške: 21908). Molimo pokušajte ponovo",
    "error_22001": "Zahtjev nije uspio (šifra greške: 22001). Molimo pokušajte ponovo",
    "error_22002": "Prekidanje veze nije uspjelo bez dodatne prijave",
    "error_22003": "Zahtjev nije uspio (šifra greške: 22003). Molimo pokušajte ponovo",
    "error_22101": "Greška: 22101. Kontaktirajte nas",
    "error_22102": "Status računa se promijenio (šifra greške: 22102), osvježite stranicu i pokušajte ponovo",
    "error_22103": "Zahtjev nije uspio (šifra greške: 22103). Molimo pokušajte ponovo",
    "error_22104": "Status računa se promijenio (šifra greške: 22104), osvježite stranicu i pokušajte ponovo",
    "error_22105": "Greška: 22105. Pokušajte ponovo",
    "error_22106": "Greška: 22106. Pokušajte ponovo",
    "error_22107": "Greška: 22107. Kontaktirajte nas",
    "error_22108": "Zahtjev nije uspio (šifra greške: 22108). Molimo pokušajte ponovo",
    "error_22201": "Greška: 22201. Kontaktirajte nas",
    "error_22202": "Nevažeća verifikacija (Greška: 22202). Molimo pokušajte ponovo.",
    "error_22203": "Greška: 22203. Molimo pokušajte ponovo\"",
    "error_22204": "Zahtjev nije uspio (šifra greške: 22204). Molimo pokušajte ponovo",
    "error_22206": "Zahtjev nije uspio (šifra greške: 22206). Molimo pokušajte ponovo",
    "error_22401": "Greška: 22401. Molimo kontaktirajte nas",
    "error_22402": "Nevažeća verifikacija (Greška: 22402). Molimo pokušajte ponovo.",
    "error_22403": "Greška: 22403. Pokušajte ponovo",
    "error_22404": "Zahtjev nije uspio (šifra greške: 22404). Molimo pokušajte ponovo",
    "error_22405": "Facebook nalog je povezan sa drugom e-poštom",
    "error_22406": "Greška: 22406. Pokušajte ponovo",
    "error_22407": "Greška: 22407. Kontaktirajte nas",
    "error_22408": "Greška: 22408. Kontaktirajte nas",
    "error_22409": "Greška: 22409. Kontaktirajte nas",
    "error_22410": "Greška: 224010. Kontaktirajte nas",
    "error_22411": "Greška: 224011. Kontaktirajte nas",
    "error_22412": "Greška: 224012. Kontaktirajte nas",
    "error_22413": "Greška: 22413. Kontaktirajte nas",
    "error_22414": "Zahtjev nije uspio (šifra greške: 22414). Molimo pokušajte ponovo",
    "error_22415": "Greška: 22415. Kontaktirajte nas",
    "error_22416": "Greška: 22416. Kontaktirajte nas",
    "error_22417": "Greška: 22417. Kontaktirajte nas",
    "error_22418": "Zahtjev nije uspio (šifra greške: 22418). Molimo pokušajte ponovo",
    "error_22419": "Greška: 22419. Kontaktirajte nas",
    "error_22420": "Greška: 22420. Kontaktirajte nas",
    "error_22421": "Greška: 22421. Kontaktirajte nas",
    "error_22422": "Zahtjev nije uspio (šifra greške: 22422). Molimo pokušajte ponovo",
    "error_22423": "Zahtjev nije uspio (šifra greške: 22423). Molimo pokušajte ponovo",
    "error_22424": "Zahtjev nije uspio (šifra greške: 22424). Molimo pokušajte ponovo",
    "error_22425": "Zahtjev nije uspio (šifra greške: 22425). Molimo pokušajte ponovo",
    "error_20098": "Šifra greške: 20098. Ako pretražujete u privatnom načinu rada, prijeđite na normalan način rada i pokušajte ponovo.",
    "error_22298": "Zahtjev za Google nije uspio (Greška: 22298). Molimo pokušajte ponovo.",
    "error_22498": "Zahtjev za Facebook nije uspio (Greška: 22498). Molimo pokušajte ponovo.",
    "error_24902": "Zahtjev nije uspio (šifra greške: 24902). Molimo pokušajte ponovo",
    "error_24903": "Zahtjev nije uspio (šifra greške: 24903). Molimo pokušajte ponovo",
    "error_24904": "Zahtjev nije uspio (šifra greške: 24904). Molimo pokušajte ponovo",
    "error_24905": "Zahtjev nije uspio (šifra greške: 24905). Molimo pokušajte ponovo",
    "login_title": "Prijavite se na MindOnMap",
    "log_in": "Ulogovati se",
    "no_account": "Nemate nalog?",
    "create_it": "Kreiraj ga",
    "or_log_in_with": "Ili se prijavite sa",
    "passwordless_login": "Prijava bez lozinke",
    "log_in_done": "Prijava Gotovo",
    "three_rd_account_connect_info": "Čestitamo! Uspješno ste se prijavili. Sada možete povezati jedan nalog e-pošte koji će se koristiti za prijavu u budućnosti.",
    "see_my_account": "Vidi moj nalog",
    "three_rd_login_merge_account": "Adresa e-pošte računa treće strane je prijavljena, želite li se direktno povezati i prijaviti sa ovom adresom e-pošte?",
    "connect_log_in": "Povežite se i prijavite se",
    "create_an_account": "Kreirajte nalog",
    "back_to_log_in": "Nazad na prijavu",
    "create_password": "Napravi sifru",
    "create_now": "Kreiraj sada",
    "password_login_subtitle": "Prijava bez lozinke putem e-pošte",
    "account_login": "Prijava na račun",
    "rights": "Kreiranjem ovog računa slažete se sa <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Uslovima korištenja usluge</a> i <a href=\"https://www.mindonmap.com/privacy-policy/\">Politika privatnosti</a>",
    "passwordless_login_done": "Prijava bez lozinke obavljena",
    "passwordless_login_info": "Čestitamo, uspješno ste završili prijavu bez lozinke. Možete kreirati lozinku za ovaj nalog i ubuduće se prijaviti sa nalogom i lozinkom. <a href=\"/create-password\" style=\"display: initial;\">Kreiraj sada</a>",
    "sign_up": "Prijaviti se",
    "register_info": "Kreirajte svoj račun",
    "reset_now": "Resetirajte sada",
    "forgot_password": "Zaboravili ste lozinku",
    "reset_password_subtitle": "Koristite e-poštu svog naloga da poništite lozinku",
    "plan_products": "Planovi i proizvodi",
    "nick_name": "Korisničko ime:",
    "email": "Email:",
    "my_products": "Moji proizvodi",
    "my_orders": "My Orders",
    "unlink": "Prekini vezu",
    "link": "Veza",
    "connected_accounts": "Povezani računi",
    "last_name": "Prezime:",
    "first_name": "Ime:",
    "Gender": "spol:",
    "Birth": "Rođenje:",
    "Month": "Mjesec",
    "Year": "Godina",
    "Country_Region": "Država/regija:",
    "Address": "Adresa:",
    "Save": "Sačuvaj",
    "Date": "Datum",
    "Male": "Muško",
    "Female": "Žensko",
    "Unspecified": "Nespecificirano",
    "Security": "Sigurnost",
    "change_password": "Promijeni lozinku",
    "change_now": "Promijeni odmah",
    "connect_email": "Povežite email",
    "delete_account": "Obriši račun",
    "delete_account_info": "Kada se vaš račun izbriše, svi podaci u Moj nalog povezani s vašim računom bit će trajno izbrisani i možda ih nećete moći oporaviti. Preporučujemo da gazite oprezno.",
    "Delete": "Izbriši",
    "Logout": "Odjaviti se",
    "my_profile": "Moj profil",
    "guides_faqs": "Vodiči i često postavljana pitanja",
    "More": "Više",
    "guides": "Vodiči",
    "register": "registar",
    "hot_faq": "Hot FAQ",
    "Contents": "Sadržaj:",
    "contact_us": "Kontaktirajte nas>>",
    "plan": "Plan",
    "unregistered": "Neregistrovan",
    "buy_more": "Kupite više",
    "buy_again": "Kupi ponovo",
    "buy_now": "Kupi sada",
    "free_no_limit": "Besplatno i bez ograničenja",
    "expired": "Istekao",
    "lifetime": "Životni vijek",
    "remain": "Ostani",
    "day_s": "dan(i)",
    "error_24801": "Zahtjev nije uspio (šifra greške: 24801). Molimo pokušajte ponovo",
    "no_app_found": "Nije pronađena nijedna aplikacija！<a href=\"/\">Osvježi</a> ili <a href=\"https://www.mindonmap.com/\">Idi na službenu web stranicu</a>",
    "get_more": "Saznajte više >>",
    "edit_photo": "Uredi fotografiju",
    "select_photo": "Odaberite fotografiju",
    "change_photo": "Promjena slike",
    "cancel": "Otkaži",
    "hide_password": "Sakrij lozinku",
    "show_password": "Prikaži lozinku",
    "zoom_in": "Priblizi",
    "zoom_out": "Umanji",
    "rotate": "Rotirajte",
    "horizontal_flip": "Horizontalno okretanje",
    "vertical_flip": "Vertikalni flip",
    "country": "Država",
    "country_1": "Odaberite svoju zemlju/regiju",
    "country_2": "Ostrvo Åaland",
    "country_3": "Afganistan",
    "country_4": "Albanija",
    "country_5": "Alžir",
    "country_6": "Američka Samoa",
    "country_7": "Andora",
    "country_8": "Angola",
    "country_9": "Angvila",
    "country_10": "Antarktika",
    "country_11": "Antigva i Barbuda",
    "country_12": "Argentina",
    "country_13": "Jermenija",
    "country_14": "Aruba",
    "country_15": "Australija",
    "country_16": "Austrija",
    "country_17": "Azerbejdžan",
    "country_18": "Bahrein",
    "country_19": "Bangladeš",
    "country_20": "Barbados",
    "country_21": "Bjelorusija",
    "country_22": "Belgija",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Butan",
    "country_27": "Bolivija",
    "country_28": "Bosna i Hercegovina",
    "country_29": "Bocvana",
    "country_30": "Ostrvo Bouvet",
    "country_31": "Brazil",
    "country_32": "Britanski indijanski okeanski teritorij",
    "country_33": "Britanska Djevičanska Ostrva",
    "country_34": "Brunej",
    "country_35": "Bugarska",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Zelenortska ostrva",
    "country_42": "Karibi Holandija",
    "country_43": "Kajmanska ostrva",
    "country_44": "Centralno afrička Republika",
    "country_45": "Čad",
    "country_46": "Čile",
    "country_47": "kina",
    "country_48": "Božićno ostrvo",
    "country_49": "Kokosova (Keeling) Ostrva",
    "country_50": "Kolumbija",
    "country_51": "Cook Ostrva",
    "country_52": "Kosta rika",
    "country_53": "Obala Slonovače",
    "country_54": "Hrvatska",
    "country_55": "Kuba",
    "country_56": "Kipar",
    "country_57": "Češka Republika",
    "country_58": "Demokratska Republika Kongo",
    "country_59": "Danska",
    "country_60": "Djibouti",
    "country_61": "Dominika",
    "country_62": "Dominikanska republika",
    "country_63": "Ekvador",
    "country_64": "Egipat",
    "country_65": "El Salvador",
    "country_66": "Ekvatorijalna Gvineja",
    "country_67": "Eritreja",
    "country_68": "Estonija",
    "country_69": "Etiopija",
    "country_70": "Falklandska Ostrva",
    "country_71": "Farska Ostrva",
    "country_72": "Savezne Države Mikronezije",
    "country_73": "Fiji",
    "country_74": "Finska",
    "country_75": "Francuska",
    "country_76": "Francuska Gvajana",
    "country_77": "Francuska Polinezija",
    "country_78": "Francuske južne teritorije",
    "country_79": "Gabon",
    "country_80": "Gambija",
    "country_81": "Georgia",
    "country_82": "Njemačka",
    "country_83": "Gana",
    "country_84": "Gibraltar",
    "country_85": "Velika Britanija (Ujedinjeno Kraljevstvo; Engleska)",
    "country_86": "Grčka",
    "country_87": "Grenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Gvatemala",
    "country_92": "Guernsey",
    "country_93": "Gvineja",
    "country_94": "Gvineja Bisau",
    "country_95": "Gvajana",
    "country_96": "Haiti",
    "country_97": "Ostrvo Heard i McDonald Ostrva",
    "country_98": "Honduras",
    "country_99": "Hong kong",
    "country_100": "mađarska",
    "country_101": "Island",
    "country_102": "Indija",
    "country_103": "Indonezija",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irska",
    "country_107": "Ostrvo Man",
    "country_108": "Izrael",
    "country_109": "Italija",
    "country_110": "Jamajka",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordan",
    "country_114": "Kazahstan",
    "country_115": "Kenija",
    "country_116": "Kiribati",
    "country_117": "Kuvajt",
    "country_118": "Kirgistan",
    "country_119": "Laos",
    "country_120": "Latvija",
    "country_121": "Liban",
    "country_122": "Lesoto",
    "country_123": "Liberija",
    "country_124": "Libija",
    "country_125": "Lihtenštajn",
    "country_126": "Litvanija",
    "country_127": "Luksemburg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malezija",
    "country_132": "Maldivi",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall Ostrva",
    "country_136": "Martinik",
    "country_137": "Mauritanija",
    "country_138": "Mauricijus",
    "country_139": "Mayotte",
    "country_140": "Meksiko",
    "country_141": "Moldavija",
    "country_142": "Monako",
    "country_143": "Mongolija",
    "country_144": "Crna Gora",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambik",
    "country_148": "Mjanmar (Burma)",
    "country_149": "Namibija",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Holandija",
    "country_153": "Nova Kaledonija",
    "country_154": "Novi Zeland",
    "country_155": "Nikaragva",
    "country_156": "Niger",
    "country_157": "Nigerija",
    "country_158": "Niue",
    "country_159": "Ostrvo Norfolk",
    "country_160": "Sjeverna Koreja",
    "country_161": "Sjeverna Marianska Ostrva",
    "country_162": "Norveška",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "palestinske teritorije",
    "country_167": "Panama",
    "country_168": "Papua Nova Gvineja",
    "country_169": "Paragvaj",
    "country_170": "Peru",
    "country_171": "Pitcairn Ostrva",
    "country_172": "Poljska",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Republika Makedonija (BJRM)",
    "country_177": "Republika Kongo",
    "country_178": "Réunion",
    "country_179": "Rumunija",
    "country_180": "Ruska Federacija",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Francuska)",
    "country_184": "Saint-Pierre i Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Saudijska Arabija",
    "country_189": "Senegal",
    "country_190": "Srbija",
    "country_191": "Sejšeli",
    "country_192": "Sijera Leone",
    "country_193": "Singapur",
    "country_194": "Slovakia",
    "country_195": "Slovenija",
    "country_196": "Solomonova Ostrva",
    "country_197": "Somalija",
    "country_198": "Južna Afrika",
    "country_199": "Južna Georgija i Južni Sendvič Isla",
    "country_200": "Sjeverna koreja",
    "country_201": "Južni Sudan",
    "country_202": "Španija",
    "country_203": "Šri Lanka",
    "country_204": "Sveta Helena i ovisnosti",
    "country_205": "St. Kitts i Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent i Grenadini",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Svazilend",
    "country_211": "Švedska",
    "country_212": "Switzerland",
    "country_213": "Sirija",
    "country_214": "Tajvan",
    "country_215": "Tadžikistan",
    "country_216": "Tanzanija",
    "country_217": "Predložak:Podaci o državi SJM Svalbard",
    "country_218": "Tajland",
    "country_219": " Bahami",
    "country_220": "Komori",
    "country_221": "Filipini",
    "country_222": "Istočni Timor (Istočni Timor)",
    "country_223": "Ići",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad i Tobago",
    "country_227": "Tunis",
    "country_228": "Turska",
    "country_229": "Turkmenistan",
    "country_230": "Ostrva Turks i Kajkos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrajina",
    "country_234": "Ujedinjeni Arapski Emirati",
    "country_235": "Mala vanjska ostrva Sjedinjenih Država",
    "country_236": "Sjedinjene Države Amerike (SAD)",
    "country_237": "Djevičanska Ostrva Sjedinjenih Država",
    "country_238": "Urugvaj",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikan (Sveta stolica)",
    "country_242": "Venecuela",
    "country_243": "Vijetnam",
    "country_244": "Wallis i Futuna",
    "country_245": "Zapadna Sahara",
    "country_246": "Jemen",
    "country_247": "Zambija",
    "country_248": "Zimbabve",
    "google_login": "Prijavite se putem Googlea",
    "State": "Država",
    "Activation_code": "Aktivacijski kod",
    "Question": "Navedite sve aplikacije na koje ste se prijavili",
    "Copy_complete": "Kopiranje je završeno",
    "footer": "Autorska prava © 2024 MindOnMap Studio. Sva prava zadržana.",
    "change_password_success": "Lozinka je uspješno promijenjena",
    "successful_login_title": "Prijava je uspjela",
    "product_page": "Stranica proizvoda>>",
    "successful_login_info": "Prijava je završena. Zatvorite trenutnu stranicu i vratite se na originalnu karticu da nastavite sa sljedećim procesom. Trenutna stranica će se automatski zatvoriti za 5 sekundi. Ako automatsko zatvaranje ili zatvaranje klikom na dugme \"Gotovo\" ne uspije, zatvorite ovu karticu direktno.",
    "successful_login_info_firefox": "Prijava je završena. Zatvorite trenutnu stranicu i vratite se na originalnu karticu da nastavite sa sljedećim procesom.",
    "my_account": "Moj račun",
    "my_history": "Moja istorija",
    "remove_watermark": "Ukloni vodeni žig",
    "no_history": "No History",
    "history_all": "Označi sve",
    "history_open": "Otvori",
    "history_down": "Skinuti",
    "history_delete": "Izbriši",
    "history_clear": "Obriši nevažeće",
    "images": "slika(e)",
    "use_this_function": "Koristite ovaj proizvod>>",
    "hd_downloading": "Prednosti preuzimanja HD originalnih slika:",
    "lifetimeRemaining": "Doživotno-preostalo",
    "Remaining": "Preostalo",
    "email_verification": "Potvrda e-pošte",
    "email_verification_info": "Poslali smo poruku za potvrdu na vašu e-poštu <span class=\"email\"></span> i molimo vas da završite verifikaciju. Nakon verifikacije, pogodnosti će biti automatski sinhronizovane.",
    "wrong_email": "Pogrešna adresa e-pošte?",
    "click_here_to_modify": "Kliknite ovdje za izmjenu",
    "get_more_help": "Dobiti dodatnu pomoć?",
    "click_here": "Kliknite ovdje",
    "email_verification_info_success": "Čestitamo na verifikaciji vašeg naloga e-pošte.",
    "email_verification_info_error": "Provjera nije uspjela zbog istekle veze.",
    "registration_succeeded": "Registracija je uspjela",
    "registration_succeeded_info_1": "Čestitamo! Uspješno ste se registrirali. Poslali smo poruku za potvrdu na vašu adresu e-pošte <span class=\"email\"></span> i molimo završite verifikaciju da biste koristili prednosti ove e-pošte.",
    "registration_succeeded_info_2": "Kliknite na \"Gotovo\" da se vratite na početnu stranicu i koristite ovaj proizvod.",
    "registration_succeeded_info_3": "Kliknite na \"Gotovo\" da zatvorite trenutnu stranicu i vratite se na početnu stranicu za sljedeće operacije. Ako zatvaranje ne uspije, ručno zatvorite karticu.",
    "verify_email": "Potvrdi Email",
    "registered_email_not_verified": "Registrirani e-mail nije verificiran, potvrdite ga odmah.",
    "email_verification_time_1": "Niste primili e-poruku za potvrdu?",
    "email_verification_time_2": "Poslije",
    "email_verification_time_3": "Kliknite ovdje da ga ponovo pošaljete",
    "error_26301": "Šifra greške: 26301, kontaktirajte nas",
    "error_26302": "Šifra greške: 26302, kontaktirajte nas",
    "error_26303": "Greška u formatu e-pošte (šifra greške: 26303). Unesite ponovo",
    "error_26304": "Preporučuje se lozinka duža od 8 znakova (šifra greške: 26304)",
    "error_26305": "Reuqest nije uspio (šifra greške: 26305). Molimo pokušajte ponovo",
    "error_26306": "E-mail je registriran, molimo <a href=\"https://account.mindonmap.com/login/\">idite da se prijavite</a>",
    "error_26307": "Reuqest nije uspio (šifra greške: 26307). Molimo pokušajte ponovo",
    "error_26308": "Reuqest nije uspio (šifra greške: 26308). Molimo pokušajte ponovo",
    "error_26401": "Šifra greške: 26401, pokušajte ponovo",
    "error_26402": "E-pošta je potvrđena (šifra greške: 26402), pokušajte ponovo",
    "error_26403": "Reuqest nije uspio (šifra greške: 26403). Molimo pokušajte ponovo",
    "error_26404": "Zahtjev nije uspio (šifra greške: 26404). Molimo pokušajte ponovo",
    "error_26501": "Šifra greške: 26501, kontaktirajte nas",
    "error_26502": "Šifra greške: 26502, kontaktirajte nas",
    "error_26503": "Greška u formatu e-pošte (šifra greške: 26503). Unesite ponovo",
    "error_26504": "Reuqest nije uspio (šifra greške: 26504). Molimo pokušajte ponovo",
    "error_26505": "Email nije registriran, molimo <a href=\"https://account.mindonmap.com/register/\">prvo ga registrirajte</a>",
    "error_26506": "Email je potvrđen.",
    "error_26507": "Reuqest nije uspio (šifra greške: 26507). Molimo pokušajte ponovo",
    "error_26508": "Potvrda je izvršena (šifra greške: 26508), pokušajte ponovo",
    "error_26509": "Zahtjev nije uspio (šifra greške: 26509), pokušajte ponovo",
    "error_26510": "Šifra greške: 26510, kontaktirajte nas",
    "error_26601": "Šifra greške: 26601, kontaktirajte nas",
    "error_26602": "Šifra greške: 26602, kontaktirajte nas",
    "error_26603": "Reuqest nije uspio (šifra greške: 26603). Molimo pokušajte ponovo",
    "error_26604": "Šifra greške: 26604, kontaktirajte nas",
    "error_26605": "Šifra greške: 26605, kontaktirajte nas",
    "error_26701": "Šifra greške: 26701, kontaktirajte nas",
    "error_26702": "Reuqest nije uspio (šifra greške: 26702). Molimo pokušajte ponovo",
    "error_26703": "Šifra greške: 26703, kontaktirajte nas",
    "error_26704": "Šifra greške: 26704, kontaktirajte nas",
    "error_26705": "Pričekajte da se prijavite (šifra greške: 26705). Molimo pokušajte ponovo",
    "no_cookie": "Uključili ste funkciju Blokiraj sve kolačiće u svom pretraživaču, tako da se ne možete prijaviti. Molimo idite na Postavke da potvrdite okvir Dozvoli sve kolačiće.",
    "error_26801": "Šifra greške: 26801, kontaktirajte nas",
    "error_26802": "Šifra greške: 26802, kontaktirajte nas",
    "error_26803": "Zahtjev nije uspio (šifra greške: 26803). Molimo pokušajte ponovo",
    "error_26804": "Zahtjev nije uspio (šifra greške: 26804). Molimo pokušajte ponovo",
    "error_order": "Zahtjev nije uspio (šifra greške: 27098), pokušajte ponovo!",
    "error_order1": "Upit za narudžbu je nepotpun (šifra greške: ",
    "error_order2": "）, osvježite i pokušajte ponovo.",
    "modify_email_title": "Izmijenite e-poštu",
    "modify_email_info": "Možete koristiti izmijenjenu adresu e-pošte za prijavu na svoj račun.",
    "images_per": "Slike per",
    "error_26101": "Greška: 26101. Kontaktirajte nas",
    "error_26102": "Greška: 26102. Kontaktirajte nas",
    "error_26103": "Zahtjev nije uspio (kôd greške:26103). Molimo probajte ponovo",
    "error_26104": "Šifra greške: 26104, pokušajte ponovo",
    "error_26105": "Šifra greške: 26105, pokušajte ponovo",
    "error_26106": "Brisanje nije uspjelo (kôd greške: 26106). Molimo pokušajte ponovo",
    "error_26201": "Greška: 26201. Kontaktirajte nas",
    "error_26202": "Zahtjev nije uspio (kôd greške:26202). Molimo probajte ponovo",
    "error_26001": "Greška: 26001. Molimo kontaktirajte nas",
    "error_26002": "Greška: 26002. Kontaktirajte nas",
    "error_26003": "Greška: 26003. Molimo kontaktirajte nas",
    "error_26004": "Greška: 26004. Kontaktirajte nas",
    "error_26005": "Zahtjev nije uspio (kôd greške:26005). Molimo probajte ponovo",
    "error_26006": "Šifra greške: 26006, pokušajte ponovo",
    "error_26008": "Greška: 26008. Molimo kontaktirajte nas",
    "go_to_the_home_page": "Idite na početnu stranicu",
    "error_27101": "Zahtjev nije uspio (šifra greške: 27101). Molimo pokušajte ponovo ist",
    "error_27201": "Šifra greške: 27201, kontaktirajte nas",
    "error_27202": "Šifra greške: 27202, pokušajte ponovo",
    "error_27203": "Zahtjev nije uspio (šifra greške: 27203). Molimo pokušajte ponovo",
    "error_27204": "Nevažeći kod (šifra greške: 27204).",
    "error_27205": "Zahtjev nije uspio (šifra greške: 27205). Molimo pokušajte ponovo",
    "error_27206": "Zahtjev nije uspio (šifra greške: 27206). Molimo pokušajte ponovo",
    "error_27207": "Zahtjev nije uspio (šifra greške: 27207). Molimo pokušajte ponovo",
    "no_history_found": "Niste koristili nikakav alat! <a href=\"/\">Osvježi</a> ili <a href=\"https://www.mindonmap.com/\">Idi na službenu web stranicu</a>",
    "error_25301": "Greška: 25301. Kontaktirajte nas",
    "error_25302": "Greška: 25302. Kontaktirajte nas",
    "error_25303": "Zahtjev nije uspio (kôd greške: 25303). Molimo pokušajte ponovo",
    "error_25304": "Zahtjev nije uspio (kôd greške: 25304). Molimo pokušajte ponovo",
    "error_25305": "Zahtjev nije uspio (kôd greške: 25305). Molimo pokušajte ponovo",
    "error_25306": "Zahtjev nije uspio (kôd greške: 25306). Molimo pokušajte ponovo",
    "image_upscaler_p": "Prednosti preuzimanja slike bez vodenog žiga:",
    "Available_for": "Dostupno za:",
    "credit_per": "%s kredit(a) po HD slici",
    "still_valid": "Kupljeni plan(i) još uvijek vrijedi",
    "credit": "kredit(i)",
    "pc_3rd_info": "Uspješno se prijavite. Molimo idite na aplikaciju za dalji rad.",
    "use_online": "Koristite online uslugu",
    "use_download": "Koristite desktop program",
    "use_immediately": "Koristite odmah",
    "Use_in_browser": "Koristite u pretraživaču",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kredita mjesečno",
    "expire": "Vrijeme isteka:",
    "viewDetails": "Detaljnije",
    "viewHistory": "Pogledajte pogodnosti istorije>>",
    "viewDetailsInfo": "Napomena: Ako se pogodnosti pretplate obnove u roku od 7 dana nakon isteka, neiskorištene pogodnosti se mogu nastaviti koristiti. Također, vrijeme isteka će se automatski ažurirati na vrijeme isteka nove pretplate. Ako nema nove pretplate nakon 7 dana isteka, sve istekle pogodnosti će biti izbrisane.",
    "connect_account": "Povežite e-poštu na svoj račun",
    "connect_account_info": "Nakon uvezivanja, možete se prijaviti sa ovom adresom e-pošte.",
    "connect_now": "Vezi sada",
    "no_email_bind": "Nema veza e-pošte",
    "bind_email": "Vezi e-poštu",
    "connect_your_email_placeholder": "Unesite svoju email adresu",
    "bind_an_email": "Vezati e-poštu",
    "bind_info": "Uspješno ste se prijavili. Povežite e-poštu sa svojim računom da aktivirate svoje pogodnosti.",
    "bind_later": "Vežite kasnije",
    "hi": "Zdravo, %s!",
    "Personal_Information": "Lična informacija",
    "Access": "Pristup",
    "Subscription_Plan": "(Plan pretplate)",
    "No_orders": "Nisu pronađene narudžbe.",
    "No_data": "Nema podataka",
    "Featured_Products": "Istaknuti proizvodi",
    "More_Products": "Više proizvoda",
    "Free_Download": "Besplatno preuzimanje",
    "Get_Started": "Započnite",
    "Subscribe": "Pretplatite se",
    "Verified": "Verified",
    "back_to_account_center": "Povratak u Account Center",
    "success": "Uspjeh!",
    "successfully": "Uspješno ste registrirali račun.",
    "Continue": "Nastavi",
    "Already": "Već imate račun?",
    "loading_verification": "Provjera statusa verifikacije...",
    "email_no_verification": "Nažalost, registrovana adresa e-pošte nije potvrđena. Molimo dovršite verifikaciju prema gornjim uputama i ponovo kliknite na dugme \"Gotovo\" da dovršite registraciju.",
    "will_expire_after": "Ističe nakon",
    "hours": "sati",
  },
  "ca": {
    "overtime": "Codi d'error: {%}, si us plau, torneu-ho a provar",
    "isnetwork": "Error d'Internet. Comprova i torna-ho a provar",
    "email_placeholder": "Correu electrònic",
    "email_empty": "Introduïu el correu electrònic",
    "email_not_valid": "El correu electrònic no és vàlid",
    "email_not_valid_1": "Introduïu el vostre correu electrònic",
    "email_not_valid_2": "El correu electrònic no és vàlid, utilitzeu una adreça diferent.",
    "email_not_valid_3": "No hi ha entrada de correu electrònic",
    "password_placeholder": "Contrasenya",
    "password_empty": "Introduïu la contrasenya",
    "password_not_valid": "Compte o contrasenya incorrectes",
    "password_not_valid_1": "Cal una contrasenya de més de 8 caràcters",
    "password_not_valid_2": "Creeu una contrasenya",
    "password_placeholder_1": "Crea la teva contrasenya",
    "password_placeholder_2": "Confirmar la contrasenya",
    "password_placeholder_3": "Crea una contrasenya nova",
    "password_placeholder_4": "Confirmar nova contrasenya",
    "password_placeholder_5": "Introduïu la contrasenya antiga",
    "copy_password_empty": "Si us plau, confirmeu la contrasenya",
    "copy_password_not_valid": "Si us plau, confirmeu la vostra contrasenya",
    "copy_passwords_inconsistent": "La confirmació de la contrasenya no coincideix",
    "code_empty": "Introduïu el codi de verificació",
    "code_not_valid": "Codi de verificació no vàlid",
    "code_placeholder": "Codi de verificació",
    "not_received_code": "Si la vostra bústia de correu no ha rebut el codi de verificació durant molt de temps, torneu a obtenir el codi de verificació.",
    "get_first_code": "Obteniu primer el codi de verificació.",
    "last_name_placeholder": "Introduïu el vostre cognom",
    "first_name_placeholder": "Introduïu el vostre nom",
    "address_placeholder": "Introduïu la vostra adreça",
    "no_code_text": "Hem enviat un codi de verificació. Introduïu el vostre codi. <span class='tips'>No heu rebut cap codi?",
    "no_code_text_1": "1. Assegureu-vos que l'adreça electrònica sigui vàlida i que pugui rebre correus electrònics.",
    "no_code_text_2": "2. Si el sistema envia el correu electrònic automàticament, es pot marcar com a correu brossa o no desitjat. Comproveu si el correu electrònic es troba a la carpeta Paperera.",
    "no_code_text_3": "3. No pots resoldre el teu problema? ",
    "no_code_text_3_span": "Llavors feu clic aquí per contactar amb nosaltres.",
    "order_no": "No heu comprat cap producte, si teniu cap pregunta, <a href=\"https://www.mindonmap.com/contact-us/\">contacteu amb nosaltres</a>.",
    "error_24901": "El compte actual no té cap correu electrònic enllaçat i no pot trobar les comandes. Si us plau, connecteu un correu electrònic.",
    "user_guide": "Guia de l'usuari >>",
    "download": "descarregar",
    "order_number": "Número d'ordre:",
    "Refund": "Devolució",
    "Disabled": "Inhabilitat",
    "Normal": "Normal",
    "Modify": "Modificar",
    "Modify_1": "Modificar>>",
    "Connect": "Connecta't",
    "unlink_success": "Desenllaça correctament",
    "connect_success": "Connecteu-vos correctament",
    "feedback_title": "Gràcies pels teus suggeriments. Si us plau, deixeu el vostre problema i us respondrem en 24 hores.",
    "feedback_thank_you": "Gràcies!<br />Els vostres comentaris s'han enviat correctament.",
    "feedback_email": "Introdueix el teu correu electrònic aquí!",
    "feedback_content": "Deixa aquí qualsevol problema o suggeriment que hagis trobat.",
    "feedback_submit": "Presentar",
    "form_contents": "No heu introduït cap descripció. Introduïu-lo i torneu a enviar-lo.",
    "old_password": "Introduïu la contrasenya antiga",
    "new_password": "Creeu una contrasenya nova",
    "old_new_password": "La nova contrasenya no pot ser la mateixa que l'antiga",
    "incorrect_password": "contrasenya incorrecta",
    "delete_no": "Suprimeix ara",
    "Caps": "Les majúscules estan activades",
    "Get": "Aconseguir",
    "Done": "Fet",
    "error_20001": "Error: 20001. Si us plau, torneu a iniciar sessió.",
    "error_20002": "Error: 20002. Torneu a iniciar sessió.",
    "error_20003": "Error: 20003. Si us plau, torneu a iniciar sessió.",
    "error_20004": "La sol·licitud ha fallat (codi d'error: 20004). Si us plau, torna-ho a provar.",
    "error_20005": "La sessió d'inici de sessió ha caducat (Error: 20005). Si us plau, torneu a iniciar sessió.",
    "error_20006": "La sol·licitud ha fallat (codi d'error: 20006). Si us plau, torna-ho a provar.",
    "error_20007": "La sessió d'inici de sessió ha caducat (Error: 20007). Si us plau, torneu a iniciar sessió.",
    "error_20008": "La sessió d'inici de sessió ha caducat (Error: 20008). Si us plau, torneu a iniciar sessió.",
    "error_20009": "La sessió d'inici de sessió ha caducat (Error: 20009). Si us plau, torneu a iniciar sessió.",
    "error_20101": "Introduïu el vostre correu electrònic (codi d'error: 20101)",
    "error_20102": "El correu electrònic no és vàlid (codi d'error: 20102)",
    "error_20103": "La sol·licitud ha fallat (codi d'error: 20103). Si us plau, torna-ho a provar",
    "error_20104": "El correu electrònic ja està en ús, <a href=\"https://account.mindonmap.com/login/\">inicieu la sessió</a> o registreu-vos-en amb un de nou",
    "error_20105": "La sol·licitud ha fallat (codi d'error: 20105). Si us plau, torna-ho a provar",
    "error_20106": "No s'ha pogut enviar el correu electrònic, torneu-ho a provar",
    "error_20201": "Introduïu el vostre correu electrònic (codi d'error: 20201)",
    "error_20202": "Introduïu la vostra contrasenya (codi d'error: 20202)",
    "error_20203": "Introduïu el codi de verificació (codi d'error: 20203)",
    "error_20204": "El correu electrònic no és vàlid (codi d'error: 20204)",
    "error_20205": "Cal una contrasenya de més de 8 caràcters (codi d'error: 20205)",
    "error_20206": "La sol·licitud ha fallat (codi d'error: 20206). Si us plau, torna-ho a provar",
    "error_20207": "Codi de verificació no vàlid",
    "error_20208": "La sol·licitud ha fallat (codi d'error: 20208). Si us plau, torna-ho a provar",
    "error_20209": "La sol·licitud ha fallat (codi d'error: 20209). Si us plau, torna-ho a provar",
    "error_20301": "Introduïu el vostre correu electrònic (codi d'error: 20301)",
    "error_20302": "Error: 20302. Contacteu-nos",
    "error_20303": "El correu electrònic no és vàlid (codi d'error: 20303)",
    "error_20304": "La sol·licitud ha fallat (codi d'error: 20304). Si us plau, torna-ho a provar",
    "error_20305": "El compte no existeix. Torneu a introduir-lo o <a href=\"https://account.mindonmap.com/register/\">Creeu-lo</a> primer.",
    "error_20306": "Encara no hi ha contrasenya. Utilitzeu <a href=\"https://account.mindonmap.com/passwordless-login/\">Inici de sessió sense contrasenya</a> o <a href=\"https://account.mindonmap.com/create-password/\"> establiu una contrasenya</a> i inicieu la sessió.",
    "error_20308": "La sol·licitud ha fallat (codi d'error: 20308). Si us plau, torna-ho a provar",
    "error_20401": "No s'ha pogut tancar la sessió (codi d'error: 20401). Si us plau, torna-ho a provar",
    "error_20501": "Introduïu el vostre correu electrònic (codi d'error: 20501)",
    "error_20502": "El correu electrònic no és vàlid (codi d'error: 20502)",
    "error_20503": "La sol·licitud ha fallat (codi d'error: 20503). Si us plau, torna-ho a provar",
    "error_20504": "No s'ha pogut enviar el correu electrònic. Si us plau, torna-ho a provar.",
    "error_20601": "Introduïu el vostre correu electrònic (codi d'error: 20601)",
    "error_20602": "Introduïu el codi de verificació (codi d'error: 20602)",
    "error_20603": "El correu electrònic no és vàlid (codi d'error: 20603)",
    "error_20604": "La sol·licitud ha fallat (codi d'error: 20604). Si us plau, torna-ho a provar",
    "error_20606": "La sol·licitud ha fallat (codi d'error: 20606). Si us plau, torna-ho a provar",
    "error_20607": "La sol·licitud ha fallat (codi d'error: 20607). Si us plau, torna-ho a provar",
    "error_20608": "La sol·licitud ha fallat (codi d'error: 20608). Si us plau, torna-ho a provar",
    "error_20701": "Introduïu el vostre correu electrònic (codi d'error: 20701)",
    "error_20702": "El correu electrònic no és vàlid (codi d'error: 20702)",
    "error_20703": "La sol·licitud ha fallat (codi d'error: 20703). Si us plau, torna-ho a provar",
    "error_20704": "El compte no existeix. Torneu a introduir-lo o <a href=\"https://account.mindonmap.com/register/\">Creeu-lo</a> primer.",
    "error_20705": "La sol·licitud ha fallat (codi d'error: 20705). Si us plau, torna-ho a provar",
    "error_20706": "No s'ha pogut enviar el correu electrònic. Si us plau, torna-ho a provar",
    "error_20801": "Introduïu el vostre correu electrònic (codi d'error: 20801)",
    "error_20802": "Error: 20802. Contacteu-nos",
    "error_20803": "Introduïu el codi de verificació (codi d'error: 20803)",
    "error_20804": "El correu electrònic no és vàlid (codi d'error: 20804)",
    "error_20805": "Cal una contrasenya de més de 8 caràcters (codi d'error: 20805)",
    "error_20806": "La sol·licitud ha fallat (codi d'error: 20806). Si us plau, torna-ho a provar",
    "error_20808": "La sol·licitud ha fallat (codi d'error: 20808). Si us plau, torna-ho a provar",
    "error_20901": "La sol·licitud ha fallat (codi d'error: 20901). Si us plau, torna-ho a provar",
    "error_20902": "La sol·licitud ha fallat (codi d'error: 20902). Si us plau, torna-ho a provar",
    "error_21000": "Els canvis es guarden",
    "error_21001": "No s'ha enviat cap informació (codi d'error: 21001)",
    "error_21002": "La sol·licitud ha fallat (codi d'error: 21002). Si us plau, torna-ho a provar",
    "error_21101": "Introduïu el vostre correu electrònic (codi d'error: 21101)",
    "error_21102": "El correu electrònic no és vàlid (codi d'error: 21102)",
    "error_21103": "La sol·licitud ha fallat (codi d'error: 21103), si us plau, torneu-ho a provar",
    "error_21104": "El correu electrònic ja està connectat, utilitzeu-ne un de nou",
    "error_21105": "La sol·licitud ha fallat (codi d'error: 21105), si us plau, torneu-ho a provar",
    "error_21106": "No s'ha pogut enviar el correu electrònic. Si us plau, torna-ho a provar",
    "error_21201": "Introduïu el vostre correu electrònic (codi d'error: 21201)",
    "error_21202": "Introduïu el codi de verificació (codi d'error: 21202)",
    "error_21203": "El correu electrònic no és vàlid (codi d'error: 21203)",
    "error_21204": "Error: 21204. Contacteu-nos",
    "error_21205": "Error: 21205. Contacteu-nos",
    "error_21206": "Cal una contrasenya de més de 8 caràcters (codi d'error: 21206)",
    "error_21207": "La sol·licitud ha fallat (codi d'error: 21207). Si us plau, torna-ho a provar",
    "error_21209": "La sol·licitud ha fallat (codi d'error: 21209). Si us plau, torna-ho a provar",
    "error_21301": "Introduïu la contrasenya antiga (codi d'error: 21301)",
    "error_21302": "Creeu una contrasenya nova (codi d'error: 21302)",
    "error_21303": "La nova contrasenya no pot ser la mateixa que l'antiga. (Error: 21303)",
    "error_21304": "Cal una contrasenya de més de 8 caràcters (codi d'error: 21304)",
    "error_21306": "La sol·licitud ha fallat (codi d'error: 21306). Si us plau, torna-ho a provar",
    "error_21402": "La sol·licitud ha fallat (codi d'error: 21402). Si us plau, torna-ho a provar",
    "error_21403": "No s'ha pogut enviar el codi de verificació. Si us plau, torna a enviar-ho",
    "error_21500": "El compte s'ha suprimit",
    "error_21501": "Introduïu el codi de verificació (codi d'error: 21501)",
    "error_21502": "La sessió d'inici de sessió ha caducat (Error: 21502). Si us plau, torneu a iniciar sessió.",
    "error_21503": "La sol·licitud ha fallat (codi d'error: 21503). Si us plau, torna-ho a provar",
    "error_21505": "La sol·licitud ha fallat (codi d'error: 21505), si us plau, torneu-ho a provar",
    "error_21601": "Error: 20601. Contacteu-nos",
    "error_21602": "Verificació no vàlida (Error: 20602). Si us plau, torna-ho a provar.",
    "error_21603": "Error: 20603. Torneu-ho a provar",
    "error_21604": "La sol·licitud ha fallat (codi d'error: 21604). Si us plau, torna-ho a provar",
    "error_21606": "La sol·licitud ha fallat (codi d'error: 21606). Si us plau, torna-ho a provar",
    "error_21611": "La sol·licitud ha fallat (codi d'error: 21611). Si us plau, torna-ho a provar",
    "error_21801": "Error: 21801. Contacteu-nos",
    "error_21802": "La sol·licitud ha fallat (Error: 21802). Si us plau, torna-ho a provar",
    "error_21803": "Error: 21803. Torneu-ho a provar",
    "error_21804": "La sol·licitud ha fallat (codi d'error: 21804). Si us plau, torna-ho a provar",
    "error_21806": "Error: 21806. Torneu-ho a provar",
    "error_21807": "Error: 21807. Contacteu-nos",
    "error_21808": "Error: 21808. Contacteu-nos",
    "error_21809": "Error: 21809. Contacteu-nos",
    "error_21810": "Error: 21810. Contacteu-nos",
    "error_21811": "Error: 21811. Contacteu-nos",
    "error_21812": "Error: 21812. Contacteu-nos",
    "error_21813": "La sol·licitud ha fallat (codi d'error: 21813). Si us plau, torna-ho a provar",
    "error_21814": "Error: 21814. Contacteu-nos",
    "error_21815": "La sol·licitud ha fallat (codi d'error: 21815). Si us plau, torna-ho a provar",
    "error_21816": "Error: 21816. Contacteu-nos",
    "error_21817": "Error: 21817. Contacteu-nos",
    "error_21818": "Error: 21818. Contacteu-nos",
    "error_21819": "La sol·licitud ha fallat (codi d'error: 21819). Si us plau, torna-ho a provar",
    "error_21820": "Error: 21820. Contacteu-nos",
    "error_21821": "Error: 21821. Contacteu-nos",
    "error_21822": "Error: 21822. Contacteu-nos",
    "error_21823": "La sol·licitud ha fallat (codi d'error: 21823). Si us plau, torna-ho a provar",
    "error_21824": "La sol·licitud ha fallat (codi d'error: 21824). Si us plau, torna-ho a provar",
    "error_21825": "La sol·licitud ha fallat (codi d'error: 21825). Si us plau, torna-ho a provar",
    "error_21826": "La sol·licitud ha fallat (codi d'error: 21826). Si us plau, torna-ho a provar",
    "error_21901": "Error: 21901. Contacteu-nos",
    "error_21902": "La sol·licitud ha fallat (codi d'error: 21902). Si us plau, torna-ho a provar",
    "error_21903": "L'estat del compte ha canviat (codi d'error: 21903), actualitzeu la pàgina i torneu-ho a provar",
    "error_21904": "Error: 21904. Torneu-ho a provar",
    "error_21905": "Error: 21905. Torneu-ho a provar",
    "error_21906": "La sol·licitud ha fallat (codi d'error: 21906). Si us plau, torna-ho a provar",
    "error_21907": "El compte de Google s'ha enllaçat a un altre compte",
    "error_21908": "La sol·licitud ha fallat (codi d'error: 21908). Si us plau, torna-ho a provar",
    "error_22001": "La sol·licitud ha fallat (codi d'error: 22001). Si us plau, torna-ho a provar",
    "error_22002": "No s'ha pogut desenllaçar sense cap inici de sessió addicional",
    "error_22003": "La sol·licitud ha fallat (codi d'error: 22003). Si us plau, torna-ho a provar",
    "error_22101": "Error: 22101. Contacteu-nos",
    "error_22102": "L'estat del compte ha canviat (codi d'error: 22102), actualitzeu la pàgina i torneu-ho a provar",
    "error_22103": "La sol·licitud ha fallat (codi d'error: 22103). Si us plau, torna-ho a provar",
    "error_22104": "L'estat del compte ha canviat (codi d'error: 22104), actualitzeu la pàgina i torneu-ho a provar",
    "error_22105": "Error: 22105. Torneu-ho a provar",
    "error_22106": "Error: 22106. Torneu-ho a provar",
    "error_22107": "Error: 22107. Contacteu-nos",
    "error_22108": "La sol·licitud ha fallat (codi d'error: 22108). Si us plau, torna-ho a provar",
    "error_22201": "Error: 22201. Contacteu-nos",
    "error_22202": "Verificació no vàlida (Error: 22202). Si us plau, torna-ho a provar.",
    "error_22203": "Error: 22203. Torneu-ho a provar\"",
    "error_22204": "La sol·licitud ha fallat (codi d'error: 22204). Si us plau, torna-ho a provar",
    "error_22206": "La sol·licitud ha fallat (codi d'error: 22206). Si us plau, torna-ho a provar",
    "error_22401": "Error: 22401. Contacteu-nos",
    "error_22402": "Verificació no vàlida (error: 22402). Si us plau, torna-ho a provar.",
    "error_22403": "Error: 22403. Torneu-ho a provar",
    "error_22404": "La sol·licitud ha fallat (codi d'error: 22404). Si us plau, torna-ho a provar",
    "error_22405": "El compte de Facebook s'ha enllaçat a un altre correu electrònic",
    "error_22406": "Error: 22406. Torneu-ho a provar",
    "error_22407": "Error: 22407. Contacteu amb nosaltres",
    "error_22408": "Error: 22408. Contacteu-nos",
    "error_22409": "Error: 22409. Contacteu-nos",
    "error_22410": "Error: 224010. Contacteu-nos",
    "error_22411": "Error: 224011. Contacteu-nos",
    "error_22412": "Error: 224012. Contacteu-nos",
    "error_22413": "Error: 22413. Contacteu-nos",
    "error_22414": "La sol·licitud ha fallat (codi d'error: 22414). Si us plau, torna-ho a provar",
    "error_22415": "Error: 22415. Contacteu-nos",
    "error_22416": "Error: 22416. Contacteu-nos",
    "error_22417": "Error: 22417. Contacteu-nos",
    "error_22418": "La sol·licitud ha fallat (codi d'error: 22418). Si us plau, torna-ho a provar",
    "error_22419": "Error: 22419. Contacteu-nos",
    "error_22420": "Error: 22420. Contacteu-nos",
    "error_22421": "Error: 22421. Contacteu-nos",
    "error_22422": "La sol·licitud ha fallat (codi d'error: 22422). Si us plau, torna-ho a provar",
    "error_22423": "La sol·licitud ha fallat (codi d'error: 22423). Si us plau, torna-ho a provar",
    "error_22424": "La sol·licitud ha fallat (codi d'error: 22424). Si us plau, torna-ho a provar",
    "error_22425": "La sol·licitud ha fallat (codi d'error: 22425). Si us plau, torna-ho a provar",
    "error_20098": "Codi d'error: 20098. Si estàs navegant en mode privat, canvia al mode normal i torna-ho a provar.",
    "error_22298": "La sol·licitud de Google ha fallat (Error: 22298). Si us plau, torna-ho a provar.",
    "error_22498": "La sol·licitud de Facebook ha fallat (Error: 22498). Si us plau, torna-ho a provar.",
    "error_24902": "La sol·licitud ha fallat (codi d'error: 24902). Si us plau, torna-ho a provar",
    "error_24903": "La sol·licitud ha fallat (codi d'error: 24903). Si us plau, torna-ho a provar",
    "error_24904": "La sol·licitud ha fallat (codi d'error: 24904). Si us plau, torna-ho a provar",
    "error_24905": "La sol·licitud ha fallat (codi d'error: 24905). Si us plau, torna-ho a provar",
    "login_title": "Inicieu sessió a MindOnMap",
    "log_in": "Iniciar Sessió",
    "no_account": "Sense compte?",
    "create_it": "Crea'l",
    "or_log_in_with": "O inicieu sessió amb",
    "passwordless_login": "Inici de sessió sense contrasenya",
    "log_in_done": "Inici de sessió Fet",
    "three_rd_account_connect_info": "Felicitats! Heu iniciat sessió correctament. Ara podeu connectar un compte de correu electrònic que s'utilitza per iniciar sessió en el futur.",
    "see_my_account": "Veure el meu compte",
    "three_rd_login_merge_account": "S'ha registrat l'adreça de correu electrònic del compte de tercers, voleu connectar-vos i iniciar sessió directament amb aquesta adreça electrònica?",
    "connect_log_in": "Connecteu-vos i inicieu sessió",
    "create_an_account": "Crear un compte",
    "back_to_log_in": "Torna per iniciar sessió",
    "create_password": "Crea una contrasenya",
    "create_now": "Crea ara",
    "password_login_subtitle": "Inici de sessió sense contrasenya amb correu electrònic",
    "account_login": "Inici de sessió al compte",
    "rights": "En crear aquest compte, accepteu les <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Condicions del servei</a> i les <a href=\"https://www.mindonmap.com/privacy-policy/\">Política de privadesa</a>",
    "passwordless_login_done": "Inici de sessió sense contrasenya fet",
    "passwordless_login_info": "Enhorabona, heu completat l'inici de sessió sense contrasenya correctament. Podeu crear una contrasenya per a aquest compte i iniciar sessió amb el compte i la contrasenya en el futur. <a href=\"/create-password\" style=\"display: initial;\">Crea ara</a>",
    "sign_up": "Registra't",
    "register_info": "crea el teu compte",
    "reset_now": "Restableix ara",
    "forgot_password": "Has oblidat la contrasenya",
    "reset_password_subtitle": "Utilitzeu el correu electrònic del vostre compte per restablir la contrasenya",
    "plan_products": "Plans i Productes",
    "nick_name": "Nom d'usuari:",
    "email": "Correu electrònic:",
    "my_products": "Els meus productes",
    "my_orders": "Les meves ordres",
    "unlink": "Desenllaça",
    "link": "Enllaç",
    "connected_accounts": "Comptes connectats",
    "last_name": "Cognom:",
    "first_name": "Nom:",
    "Gender": "Gènere:",
    "Birth": "Naixement:",
    "Month": "Mes",
    "Year": "Curs",
    "Country_Region": "País/Regió:",
    "Address": "Adreça:",
    "Save": "Desa",
    "Date": "Data",
    "Male": "Mascle",
    "Female": "Dona",
    "Unspecified": "Sense especificar",
    "Security": "Seguretat",
    "change_password": "Canvia la contrasenya",
    "change_now": "Canvia ara",
    "connect_email": "Connecteu el correu electrònic",
    "delete_account": "Esborrar compte",
    "delete_account_info": "Quan se suprimeixi el vostre compte, totes les dades d'El meu compte associades al vostre compte se suprimiran permanentment i és possible que no pugueu recuperar-les. Us recomanem que trepitgeu amb cautela.",
    "Delete": "Suprimeix",
    "Logout": "Tancar sessió",
    "my_profile": "El meu perfil",
    "guides_faqs": "Guies i preguntes freqüents",
    "More": "Més",
    "guides": "Guies",
    "register": "registrar-se",
    "hot_faq": "Preguntes freqüents",
    "Contents": "Continguts:",
    "contact_us": "Contacta amb nosaltres >>",
    "plan": "Pla",
    "unregistered": "No registrat",
    "buy_more": "Compra més",
    "buy_again": "Comprar de nou",
    "buy_now": "Compra ara",
    "free_no_limit": "Gratuït i sense límit",
    "expired": "Caducat",
    "lifetime": "Tota una vida",
    "remain": "Queda't",
    "day_s": "Dies",
    "error_24801": "La sol·licitud ha fallat (codi d'error: 24801). Si us plau, torna-ho a provar",
    "no_app_found": "No s'ha trobat cap aplicació!<a href=\"/\">Actualitza</a> o <a href=\"https://www.mindonmap.com/\">Vés al lloc web oficial</a>",
    "get_more": "Obteniu més >>",
    "edit_photo": "Edita la foto",
    "select_photo": "Seleccioneu la foto",
    "change_photo": "Canvia la foto",
    "cancel": "Cancel · lar",
    "hide_password": "Amaga la contrasenya",
    "show_password": "Ensenya la contrasenya",
    "zoom_in": "Ampliar",
    "zoom_out": "Disminuir el zoom",
    "rotate": "Gira",
    "horizontal_flip": "Flip horitzontal",
    "vertical_flip": "Flip vertical",
    "country": "País",
    "country_1": "Seleccioneu el vostre país/regió",
    "country_2": "Illa Åaland",
    "country_3": "Afganistan",
    "country_4": "Albània",
    "country_5": "Algèria",
    "country_6": "Samoa americana",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antàrtida",
    "country_11": "Antigua i Barbuda",
    "country_12": "Argentina",
    "country_13": "Armènia",
    "country_14": "Aruba",
    "country_15": "Austràlia",
    "country_16": "Àustria",
    "country_17": "Azerbaidjan",
    "country_18": "Bahrain",
    "country_19": "Bangla Desh",
    "country_20": "Barbados",
    "country_21": "Bielorússia",
    "country_22": "Bèlgica",
    "country_23": "Belize",
    "country_24": "Benín",
    "country_25": "Bermudes",
    "country_26": "Butan",
    "country_27": "Bolívia",
    "country_28": "Bòsnia i Hercegovina",
    "country_29": "Botswana",
    "country_30": "Illa Bouvet",
    "country_31": "Brasil",
    "country_32": "Territori britànic de l'oceà Índic",
    "country_33": "Illes Verges Britàniques",
    "country_34": "Brunei",
    "country_35": "Bulgària",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodja",
    "country_39": "Camerun",
    "country_40": "Canadà",
    "country_41": "Cap Verd",
    "country_42": "Carib Països Baixos",
    "country_43": "Illes Caiman",
    "country_44": "República Centreafricana",
    "country_45": "Txad",
    "country_46": "Xile",
    "country_47": "Xina",
    "country_48": "Illa de Nadal",
    "country_49": "Illes Cocos (Keeling).",
    "country_50": "Colòmbia",
    "country_51": "Illes Cook",
    "country_52": "Costa Rica",
    "country_53": "Costa d'Ivori",
    "country_54": "Croàcia",
    "country_55": "Cuba",
    "country_56": "Xipre",
    "country_57": "República Txeca",
    "country_58": "República Democràtica del Congo",
    "country_59": "Dinamarca",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "República Dominicana",
    "country_63": "Equador",
    "country_64": "Egipte",
    "country_65": "El Salvador",
    "country_66": "Guinea Equatorial",
    "country_67": "Eritrea",
    "country_68": "Estònia",
    "country_69": "Etiòpia",
    "country_70": "Illes Malvines",
    "country_71": "Illes Faroe",
    "country_72": "Estats federats de Micronèsia",
    "country_73": "Fiji",
    "country_74": "Finlàndia",
    "country_75": "França",
    "country_76": "Guaiana Francesa",
    "country_77": "Polinèsia Francesa",
    "country_78": "Territoris del Sud francesos",
    "country_79": "Gabon",
    "country_80": "Gàmbia",
    "country_81": "Geòrgia",
    "country_82": "Alemanya",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Gran Bretanya (Regne Unit; Anglaterra)",
    "country_86": "Grècia",
    "country_87": "Groenlàndia",
    "country_88": "Granada",
    "country_89": "Guadalupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea Bissau",
    "country_95": "Guyana",
    "country_96": "Haití",
    "country_97": "Illa Heard i Illes McDonald",
    "country_98": "Hondures",
    "country_99": "Hong Kong",
    "country_100": "Hongria",
    "country_101": "Islàndia",
    "country_102": "Índia",
    "country_103": "Indonèsia",
    "country_104": "Iran",
    "country_105": "Iraq",
    "country_106": "Irlanda",
    "country_107": "Illa de l'home",
    "country_108": "Israel",
    "country_109": "Itàlia",
    "country_110": "Jamaica",
    "country_111": "Japó",
    "country_112": "Jersey",
    "country_113": "Jordània",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirguizistan",
    "country_119": "Laos",
    "country_120": "Letònia",
    "country_121": "Líban",
    "country_122": "Lesotho",
    "country_123": "Libèria",
    "country_124": "Líbia",
    "country_125": "Liechtenstein",
    "country_126": "Lituània",
    "country_127": "Luxemburg",
    "country_128": "Macau",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malàisia",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Illes Marshall",
    "country_136": "Martinica",
    "country_137": "Mauritània",
    "country_138": "Maurici",
    "country_139": "Mayotte",
    "country_140": "Mèxic",
    "country_141": "Moldàvia",
    "country_142": "Mònaco",
    "country_143": "Mongòlia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "el Marroc",
    "country_147": "Moçambic",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namíbia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Països Baixos",
    "country_153": "Nova Caledònia",
    "country_154": "Nova Zelanda",
    "country_155": "Nicaragua",
    "country_156": "Níger",
    "country_157": "Nigèria",
    "country_158": "Niue",
    "country_159": "Illa Norfolk",
    "country_160": "Corea del Nord",
    "country_161": "Illes Mariannes del Nord",
    "country_162": "Noruega",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "territoris palestins",
    "country_167": "Panamà",
    "country_168": "Papua Nova Guinea",
    "country_169": "Paraguai",
    "country_170": "Perú",
    "country_171": "Illes Pitcairn",
    "country_172": "Polònia",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "República de Macedònia (ARYM)",
    "country_177": "República del Congo",
    "country_178": "Reunió",
    "country_179": "Romania",
    "country_180": "Federació Russa",
    "country_181": "Ruanda",
    "country_182": "Sant Barthélemy",
    "country_183": "Saint Martin (França)",
    "country_184": "Saint-Pierre i Miqueló",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sant Tomé i Príncipe",
    "country_188": "Aràbia Saudita",
    "country_189": "Senegal",
    "country_190": "Sèrbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Eslovàquia",
    "country_195": "Eslovènia",
    "country_196": "Illes Salomó",
    "country_197": "Somàlia",
    "country_198": "Sud-Àfrica",
    "country_199": " South Geòrgia i la South Sandwich Isla",
    "country_200": "Corea del Sud",
    "country_201": "Sudan del Sud",
    "country_202": "Espanya",
    "country_203": "Sri Lanka",
    "country_204": "Santa Helena i dependències",
    "country_205": "St. Kitts i Nevis",
    "country_206": "Santa Llúcia",
    "country_207": "Sant Vicent i les Granadines",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swazilàndia",
    "country_211": "Suècia",
    "country_212": "Suïssa",
    "country_213": "Síria",
    "country_214": "Taiwan",
    "country_215": "Tadjikistan",
    "country_216": "Tanzània",
    "country_217": "Plantilla:Dades del país SJM Svalbard",
    "country_218": "Tailàndia",
    "country_219": "Les Bahames",
    "country_220": "Les Comores",
    "country_221": "Les Filipines",
    "country_222": "Timor-Leste (Timor oriental)",
    "country_223": "Anar",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinitat i Tobago",
    "country_227": "Tunísia",
    "country_228": "Turquia",
    "country_229": "Turkmenistan",
    "country_230": "Illes Turks i Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucraïna",
    "country_234": "Emirats Àrabs Units",
    "country_235": "Illes perifèriques menors dels Estats Units",
    "country_236": "Estats Units d'Amèrica (EUA)",
    "country_237": "Illes Verges dels Estats Units",
    "country_238": "Uruguai",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Ciutat del Vaticà (Santa Seu)",
    "country_242": "Veneçuela",
    "country_243": "Vietnam",
    "country_244": "Wallis i Futuna",
    "country_245": "Sàhara occidental",
    "country_246": "Iemen",
    "country_247": "Zàmbia",
    "country_248": "Zimbabwe",
    "google_login": "Inicieu la sessió amb Google",
    "State": "Estat",
    "Activation_code": "Codi d'activació",
    "Question": "Llista totes les aplicacions amb què has iniciat sessió",
    "Copy_complete": "Còpia completa",
    "footer": "Copyright © 2024 MindOnMap Studio. Tots els drets reservats.",
    "change_password_success": "La contrasenya s'ha canviat correctament",
    "successful_login_title": "Èxit d'inici de sessió",
    "product_page": "Pàgina del producte>>",
    "successful_login_info": "Inici de sessió completat. Tanqueu la pàgina actual i torneu a la pestanya original per continuar amb el procés següent. La pàgina actual es tancarà automàticament en 5 segons. Si el tancament automàtic o el tancament fent clic al botó \"Fet\" falla, tanqueu aquesta pestanya directament.",
    "successful_login_info_firefox": "Inici de sessió completat. Tanqueu la pàgina actual i torneu a la pestanya original per continuar amb el procés següent.",
    "my_account": "El meu compte",
    "my_history": "La meva història",
    "remove_watermark": "Elimina la marca d'aigua",
    "no_history": "Sense Història",
    "history_all": "Seleccionar tot",
    "history_open": "Obert",
    "history_down": "descarregar",
    "history_delete": "Suprimeix",
    "history_clear": "Esborra no vàlid",
    "images": "imatge(s)",
    "use_this_function": "Utilitzeu aquest producte>>",
    "hd_downloading": "Beneficis de baixada d'imatges originals en HD:",
    "lifetimeRemaining": "Vida restant",
    "Remaining": "Restant",
    "email_verification": "Verificació del correu electrònic",
    "email_verification_info": "Hem enviat el missatge de verificació al vostre correu electrònic <span class=\"email\"></span> i, si us plau, finalitzeu la verificació. Després de verificar, els avantatges es sincronitzaran automàticament.",
    "wrong_email": "Adreça electrònica incorrecta?",
    "click_here_to_modify": "Feu clic aquí per modificar",
    "get_more_help": "Obteniu més ajuda?",
    "click_here": "Clica aquí",
    "email_verification_info_success": "Enhorabona per verificar el vostre compte de correu electrònic.",
    "email_verification_info_error": "La verificació ha fallat a causa de l'enllaç caducat.",
    "registration_succeeded": "Registre reeixit",
    "registration_succeeded_info_1": "Felicitats! Us heu registrat correctament. Hem enviat el missatge de verificació al vostre correu electrònic <span class=\"email\"></span> i, si us plau, acabeu de verificar per utilitzar els avantatges d'aquest correu electrònic.",
    "registration_succeeded_info_2": "Feu clic a \"Fet\" per tornar a la pàgina d'inici i utilitzar aquest producte.",
    "registration_succeeded_info_3": "Feu clic a \"Fet\" per tancar la pàgina actual i tornar a la pàgina d'inici per a les operacions següents. Si el tancament falla, tanqueu manualment la pestanya.",
    "verify_email": "verificar Correu",
    "registered_email_not_verified": "El correu electrònic registrat no s'ha verificat, si us plau, verifiqueu-lo immediatament.",
    "email_verification_time_1": "No heu rebut cap correu electrònic de verificació?",
    "email_verification_time_2": "Després",
    "email_verification_time_3": "Feu clic aquí per tornar-lo a enviar",
    "error_26301": "Codi d'error: 26301, poseu-vos en contacte amb nosaltres",
    "error_26302": "Codi d'error: 26302, poseu-vos en contacte amb nosaltres",
    "error_26303": "Error de format de correu electrònic (codi d'error: 26303). Si us plau, torneu a introduir-lo",
    "error_26304": "Es recomana una contrasenya de més de 8 caràcters (codi d'error: 26304)",
    "error_26305": "La sol·licitud ha fallat (codi d'error: 26305). Si us plau, torna-ho a provar",
    "error_26306": "El correu electrònic s'ha registrat, <a href=\"https://account.mindonmap.com/login/\">aneu a iniciar sessió</a>",
    "error_26307": "La sol·licitud ha fallat (codi d'error: 26307). Si us plau, torna-ho a provar",
    "error_26308": "La sol·licitud ha fallat (codi d'error: 26308). Si us plau, torna-ho a provar",
    "error_26401": "Codi d'error: 26401, torneu-ho a provar",
    "error_26402": "El correu electrònic s'ha verificat (codi d'error: 26402), torneu-ho a provar",
    "error_26403": "La sol·licitud ha fallat (codi d'error: 26403). Si us plau, torna-ho a provar",
    "error_26404": "La sol·licitud ha fallat (codi d'error: 26404). Si us plau, torna-ho a provar",
    "error_26501": "Codi d'error: 26501, poseu-vos en contacte amb nosaltres",
    "error_26502": "Codi d'error: 26502, poseu-vos en contacte amb nosaltres",
    "error_26503": "Error de format de correu electrònic (codi d'error: 26503). Si us plau, torneu a introduir-lo",
    "error_26504": "La sol·licitud ha fallat (codi d'error: 26504). Si us plau, torna-ho a provar",
    "error_26505": "El correu electrònic no s'ha registrat, <a href=\"https://account.mindonmap.com/register/\">registreu-lo primer</a>",
    "error_26506": "El correu electrònic s'ha verificat.",
    "error_26507": "La sol·licitud ha fallat (codi d'error: 26507). Si us plau, torna-ho a provar",
    "error_26508": "S'ha enviat la verificació (codi d'error: 26508), torneu-ho a provar",
    "error_26509": "La sol·licitud ha fallat (codi d'error: 26509), torneu-ho a provar",
    "error_26510": "Codi d'error: 26510, poseu-vos en contacte amb nosaltres",
    "error_26601": "Codi d'error: 26601, poseu-vos en contacte amb nosaltres",
    "error_26602": "Codi d'error: 26602, poseu-vos en contacte amb nosaltres",
    "error_26603": "La sol·licitud ha fallat (codi d'error: 26603). Si us plau, torna-ho a provar",
    "error_26604": "Codi d'error: 26604, poseu-vos en contacte amb nosaltres",
    "error_26605": "Codi d'error: 26605, poseu-vos en contacte amb nosaltres",
    "error_26701": "Codi d'error: 26701, poseu-vos en contacte amb nosaltres",
    "error_26702": "La sol·licitud ha fallat (codi d'error: 26702). Si us plau, torna-ho a provar",
    "error_26703": "Codi d'error: 26703, poseu-vos en contacte amb nosaltres",
    "error_26704": "Codi d'error: 26704, poseu-vos en contacte amb nosaltres",
    "error_26705": "Espereu a iniciar sessió (codi d'error: 26705). Si us plau, torna-ho a provar",
    "no_cookie": "Heu activat la funció Bloqueja totes les galetes al vostre navegador, de manera que no podeu iniciar sessió. Aneu a Configuració per marcar la casella Permet totes les galetes.",
    "error_26801": "Codi d'error: 26801, poseu-vos en contacte amb nosaltres",
    "error_26802": "Codi d'error: 26802, poseu-vos en contacte amb nosaltres",
    "error_26803": "La sol·licitud ha fallat (codi d'error: 26803). Si us plau, torna-ho a provar",
    "error_26804": "La sol·licitud ha fallat (codi d'error: 26804). Si us plau, torna-ho a provar",
    "error_order": "La sol·licitud ha fallat (codi d'error: 27098), torneu-ho a provar!",
    "error_order1": "La consulta de comanda és incompleta (codi d'error: ",
    "error_order2": "）, actualitzeu-lo i torneu-ho a provar.",
    "modify_email_title": "Modifica el correu electrònic",
    "modify_email_info": "Podeu utilitzar el correu electrònic modificat per iniciar sessió al vostre compte.",
    "images_per": "Imatges per",
    "error_26101": "Error: 26101. Contacteu-nos",
    "error_26102": "Error: 26102. Contacteu-nos",
    "error_26103": "La sol·licitud ha fallat (codi d'error: 26103). Si us plau, torna-ho a provar",
    "error_26104": "Codi d'error: 26104, si us plau, torneu-ho a provar",
    "error_26105": "Codi d'error: 26105, si us plau, torneu-ho a provar",
    "error_26106": "No s'ha pogut suprimir (codi d'error: 26106). Si us plau, torna-ho a provar",
    "error_26201": "Error: 26201. Contacteu-nos",
    "error_26202": "La sol·licitud ha fallat (codi d'error: 26202). Si us plau, torna-ho a provar",
    "error_26001": "Error: 26001. Contacteu-nos",
    "error_26002": "Error: 26002. Contacteu-nos",
    "error_26003": "Error: 26003. Contacteu-nos",
    "error_26004": "Error: 26004. Contacteu-nos",
    "error_26005": "La sol·licitud ha fallat (codi d'error: 26005). Si us plau, torna-ho a provar",
    "error_26006": "Codi d'error: 26006, si us plau, torneu-ho a provar",
    "error_26008": "Error: 26008. Contacteu-nos",
    "go_to_the_home_page": "Aneu a la pàgina d'inici",
    "error_27101": "La sol·licitud ha fallat (codi d'error: 27101). Si us plau, torna-ho a provar",
    "error_27201": "Codi d'error: 27201, poseu-vos en contacte amb nosaltres",
    "error_27202": "Codi d'error: 27202, torneu-ho a provar",
    "error_27203": "La sol·licitud ha fallat (codi d'error: 27203). Si us plau, torna-ho a provar",
    "error_27204": "Codi no vàlid (codi d'error: 27204).",
    "error_27205": "La sol·licitud ha fallat (codi d'error: 27205). Si us plau, torna-ho a provar",
    "error_27206": "La sol·licitud ha fallat (codi d'error: 27206). Si us plau, torna-ho a provar",
    "error_27207": "La sol·licitud ha fallat (codi d'error: 27207). Si us plau, torna-ho a provar",
    "no_history_found": "No heu utilitzat cap eina! <a href=\"/\">Actualitza</a> o <a href=\"https://www.mindonmap.com/\">Vés al lloc web oficial</a>",
    "error_25301": "Error: 25301. Contacteu-nos",
    "error_25302": "Error: 25302. Contacteu-nos",
    "error_25303": "La sol·licitud ha fallat (codi d'error: 25303). Si us plau, torna-ho a provar",
    "error_25304": "La sol·licitud ha fallat (codi d'error: 25304). Si us plau, torna-ho a provar",
    "error_25305": "La sol·licitud ha fallat (codi d'error: 25305). Si us plau, torna-ho a provar",
    "error_25306": "La sol·licitud ha fallat (codi d'error: 25306). Si us plau, torna-ho a provar",
    "image_upscaler_p": "Imatge sense avantatges de baixada de marca d'aigua:",
    "Available_for": "Disponible per:",
    "credit_per": "%s crèdit(s) per imatge HD",
    "still_valid": "Els plans comprats encara són vàlids",
    "credit": "crèdit(s)",
    "pc_3rd_info": "Inicieu sessió correctament. Si us plau, aneu a l'aplicació per a més operacions.",
    "use_online": "Utilitzeu el servei en línia",
    "use_download": "Utilitzeu el programa d'escriptori",
    "use_immediately": "Utilitzeu immediatament",
    "Use_in_browser": "Ús al navegador",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} crèdits al mes",
    "expire": "Temps de caducitat:",
    "viewDetails": "Veure detalls",
    "viewHistory": "Veure els avantatges de l'historial >>",
    "viewDetailsInfo": "Avís: si els avantatges de la subscripció es renoven dins dels 7 dies posteriors al venciment, es poden continuar utilitzant els avantatges no utilitzats. A més, el temps de caducitat s'actualitzarà automàticament al temps de caducitat de la nova subscripció. Si no hi ha cap subscripció nova després de 7 dies de caducitat, s'esborraran tots els avantatges caducats.",
    "connect_account": "Enllaça el correu electrònic al teu compte",
    "connect_account_info": "Després de l'enllaç, podeu iniciar sessió amb aquesta adreça de correu electrònic.",
    "connect_now": "Enllaçar ara",
    "no_email_bind": "Sense enllaç de correu electrònic",
    "bind_email": "Enllaçar correu electrònic",
    "connect_your_email_placeholder": "Si us plau, ingressi el seu correu electrònic",
    "bind_an_email": "Enllaçar un correu electrònic",
    "bind_info": "Heu iniciat sessió correctament. Enllaceu un correu electrònic al vostre compte per activar els vostres avantatges.",
    "bind_later": "Enllaçar més tard",
    "hi": "Seva,%s!",
    "Personal_Information": "Informació personal",
    "Access": "Accés",
    "Subscription_Plan": "(Pla de subscripció)",
    "No_orders": "No s'han trobat comandes.",
    "No_data": "No hi ha dades",
    "Featured_Products": "productes destacats",
    "More_Products": "Més Productes",
    "Free_Download": "Descàrrega gratuita",
    "Get_Started": "Començar",
    "Subscribe": "Subscriu-te",
    "Verified": "Verificat",
    "back_to_account_center": "Torna al Centre de comptes",
    "success": "Èxit!",
    "successfully": "Heu registrat correctament un compte.",
    "Continue": "Continua",
    "Already": "Ja tens un compte?",
    "loading_verification": "Comprovació de l'estat de verificació...",
    "email_no_verification": "Ho sentim, l'adreça de correu electrònic registrada no s'ha verificat. Completeu la verificació segons les instruccions anteriors i feu clic al botó \"Fet\" de nou per completar el registre.",
    "will_expire_after": "Caducarà després",
    "hours": "hores",
  },
  "cs": {
    "overtime": "Kód chyby: {%}, zkuste to prosím znovu",
    "isnetwork": "Chyba internetu. Zkontrolujte prosím a zkuste to znovu",
    "email_placeholder": "E-mailem",
    "email_empty": "Zadejte prosím email",
    "email_not_valid": "E-mail není platný",
    "email_not_valid_1": "Zadejte prosím svůj email",
    "email_not_valid_2": "E-mail není platný, použijte prosím jinou adresu.",
    "email_not_valid_3": "Žádné zadávání e-mailů",
    "password_placeholder": "Heslo",
    "password_empty": "Zadejte prosím heslo",
    "password_not_valid": "Nesprávný účet nebo heslo",
    "password_not_valid_1": "Je vyžadováno heslo delší než 8 znaků",
    "password_not_valid_2": "Vytvořte si prosím heslo",
    "password_placeholder_1": "Vytvořte vaše heslo",
    "password_placeholder_2": "Potvrzení hesla",
    "password_placeholder_3": "Vytvořte nové heslo",
    "password_placeholder_4": "Potvrďte nové heslo",
    "password_placeholder_5": "Zadejte staré heslo",
    "copy_password_empty": "Prosím potvrďte heslo",
    "copy_password_not_valid": "Potvrďte prosím své heslo",
    "copy_passwords_inconsistent": "Vaše potvrzení hesla se neshoduje",
    "code_empty": "Zadejte prosím ověřovací kód",
    "code_not_valid": "neplatný ověřovací kód",
    "code_placeholder": "Ověřovací kód",
    "not_received_code": "Pokud vaše poštovní schránka dlouho neobdržela ověřovací kód, získejte ověřovací kód znovu.",
    "get_first_code": "Nejprve prosím získejte ověřovací kód.",
    "last_name_placeholder": "Prosím zadejte své příjmení",
    "first_name_placeholder": "Prosím zadej své křestní jméno",
    "address_placeholder": "Zadejte prosím svou adresu",
    "no_code_text": "Odeslali jsme ověřovací kód. Zadejte prosím svůj kód. <span class='tips'>Neobdrželi jste kód?",
    "no_code_text_1": "1. Ujistěte se, že je e-mailová adresa platná a může přijímat e-maily.",
    "no_code_text_2": "2. E-mail je odesílán systémem automaticky, může být označen jako spam nebo nevyžádaný e-mail. Zkontrolujte, zda je e-mail ve složce Koš.",
    "no_code_text_3": "3. Nemůžete vyřešit svůj problém? ",
    "no_code_text_3_span": "Pak nás kontaktujte kliknutím sem.",
    "order_no": "Nezakoupili jste žádný produkt. Máte-li jakékoli dotazy, <a href=\"https://www.mindonmap.com/contact-us/\">kontaktujte nás</a>.",
    "error_24901": "Aktuální účet nemá propojený žádný e-mail a nemůže najít objednávky. Připojte prosím e-mail.",
    "user_guide": "Uživatelská příručka >>",
    "download": "Stažení",
    "order_number": "Číslo objednávky:",
    "Refund": "Vrácení peněz",
    "Disabled": "Zakázáno",
    "Normal": "Normální",
    "Modify": "Modifikovat",
    "Modify_1": "Upravit >>",
    "Connect": "Připojit",
    "unlink_success": "Odpojení bylo úspěšné",
    "connect_success": "Připojte se úspěšně",
    "feedback_title": "Děkujeme za vaši odezvu. Zanechte prosím svůj problém a my vám odpovíme do 24 hodin.",
    "feedback_thank_you": "Děkujeme!<br />Vaše zpětná vazba byla úspěšně odeslána.",
    "feedback_email": "Zde zadejte svůj email!",
    "feedback_content": "Zanechte zde jakýkoli problém nebo návrh, se kterým jste se setkali.",
    "feedback_submit": "Předložit",
    "form_contents": "Nezadali jste žádný popis. Zadejte jej a odešlete znovu.",
    "old_password": "Zadejte staré heslo",
    "new_password": "Vytvořte si prosím nové heslo",
    "old_new_password": "Nové heslo nemůže být stejné jako staré",
    "incorrect_password": "Nesprávné heslo",
    "delete_no": "Smazat nyní",
    "Caps": "Caps Lock je zapnutý",
    "Get": "Dostat",
    "Done": "Hotovo",
    "error_20001": "Chyba: 20001. Přihlaste se prosím znovu.",
    "error_20002": "Chyba: 20002. Přihlaste se prosím znovu.",
    "error_20003": "Chyba: 20003. Přihlaste se prosím znovu.",
    "error_20004": "Požadavek se nezdařil (kód chyby: 20004). Zkuste to znovu.",
    "error_20005": "Přihlašovací relace vypršela (Chyba: 20005). Přihlaste se prosím znovu.",
    "error_20006": "Požadavek se nezdařil (kód chyby: 20006). Zkuste to znovu.",
    "error_20007": "Přihlašovací relace vypršela (Chyba: 20007). Přihlaste se prosím znovu.",
    "error_20008": "Přihlašovací relace vypršela (Chyba: 20008). Přihlaste se prosím znovu.",
    "error_20009": "Přihlašovací relace vypršela (Chyba: 20009). Přihlaste se prosím znovu.",
    "error_20101": "Zadejte svůj e-mail (kód chyby: 20101)",
    "error_20102": "E-mail není platný (kód chyby: 20102)",
    "error_20103": "Požadavek se nezdařil (kód chyby: 20103). Zkuste to znovu",
    "error_20104": "E-mail se již používá, <a href=\"https://account.mindonmap.com/login/\">přihlaste se</a> nebo se zaregistrujte s novým",
    "error_20105": "Požadavek se nezdařil (kód chyby: 20105). Zkuste to znovu",
    "error_20106": "Odeslání e-mailu se nezdařilo, zkuste to znovu",
    "error_20201": "Zadejte svůj e-mail (kód chyby: 20201)",
    "error_20202": "Zadejte prosím své heslo (kód chyby: 20202)",
    "error_20203": "Zadejte ověřovací kód (kód chyby: 20203)",
    "error_20204": "E-mail není platný (kód chyby: 20204)",
    "error_20205": "Je vyžadováno heslo delší než 8 znaků (kód chyby: 20205)",
    "error_20206": "Požadavek se nezdařil (kód chyby: 20206). Zkuste to znovu",
    "error_20207": "neplatný ověřovací kód",
    "error_20208": "Požadavek se nezdařil (kód chyby: 20208). Zkuste to znovu",
    "error_20209": "Požadavek se nezdařil (kód chyby: 20209). Zkuste to znovu",
    "error_20301": "Zadejte svůj e-mail (kód chyby: 20301)",
    "error_20302": "Chyba: 20302. Kontaktujte nás",
    "error_20303": "E-mail není platný (kód chyby: 20303)",
    "error_20304": "Požadavek se nezdařil (kód chyby: 20304). Zkuste to znovu",
    "error_20305": "Účet neexistuje. Nejprve jej prosím zadejte znovu nebo <a href=\"https://account.mindonmap.com/register/\">vytvořte</a>.",
    "error_20306": "Zatím žádné heslo. Použijte <a href=\"https://account.mindonmap.com/passwordless-login/\">Přihlášení bez hesla</a> nebo <a href=\"https://account.mindonmap.com/create-password/\"> nastavte heslo</a> a přihlaste se.",
    "error_20308": "Požadavek se nezdařil (kód chyby: 20308). Zkuste to znovu",
    "error_20401": "Odhlášení se nezdařilo (kód chyby: 20401). Zkuste to znovu",
    "error_20501": "Zadejte svůj e-mail (kód chyby: 20501)",
    "error_20502": "E-mail není platný (kód chyby: 20502)",
    "error_20503": "Požadavek se nezdařil (kód chyby: 20503). Zkuste to znovu",
    "error_20504": "Odeslání e-mailu se nezdařilo. Zkuste to znovu.",
    "error_20601": "Zadejte svůj e-mail (kód chyby: 20601)",
    "error_20602": "Zadejte prosím ověřovací kód (kód chyby: 20602)",
    "error_20603": "E-mail není platný (kód chyby: 20603)",
    "error_20604": "Požadavek se nezdařil (kód chyby: 20604). Zkuste to znovu",
    "error_20606": "Požadavek se nezdařil (kód chyby: 20606). Zkuste to znovu",
    "error_20607": "Požadavek se nezdařil (kód chyby: 20607). Zkuste to znovu",
    "error_20608": "Požadavek se nezdařil (kód chyby: 20608). Zkuste to znovu",
    "error_20701": "Zadejte svůj e-mail (kód chyby: 20701)",
    "error_20702": "E-mail není platný (kód chyby: 20702)",
    "error_20703": "Požadavek se nezdařil (kód chyby: 20703). Zkuste to znovu",
    "error_20704": "Účet neexistuje. Nejprve jej prosím zadejte znovu nebo <a href=\"https://account.mindonmap.com/register/\">vytvořte</a>.",
    "error_20705": "Požadavek se nezdařil (kód chyby: 20705). Zkuste to znovu",
    "error_20706": "Odeslání e-mailu se nezdařilo. Zkuste to znovu",
    "error_20801": "Zadejte svůj e-mail (kód chyby: 20801)",
    "error_20802": "Chyba: 20802. Kontaktujte nás",
    "error_20803": "Zadejte ověřovací kód (kód chyby: 20803)",
    "error_20804": "E-mail není platný (kód chyby: 20804)",
    "error_20805": "Je vyžadováno heslo delší než 8 znaků (kód chyby: 20805)",
    "error_20806": "Požadavek se nezdařil (kód chyby: 20806). Zkuste to znovu",
    "error_20808": "Požadavek se nezdařil (kód chyby: 20808). Zkuste to znovu",
    "error_20901": "Požadavek se nezdařil (kód chyby: 20901). Zkuste to znovu",
    "error_20902": "Požadavek se nezdařil (kód chyby: 20902). Zkuste to znovu",
    "error_21000": "Změny se uloží",
    "error_21001": "Nejsou odeslány žádné informace (kód chyby: 21001)",
    "error_21002": "Požadavek se nezdařil (kód chyby: 21002). Zkuste to znovu",
    "error_21101": "Zadejte svůj e-mail (kód chyby: 21101)",
    "error_21102": "E-mail není platný (kód chyby: 21102)",
    "error_21103": "Požadavek se nezdařil (kód chyby: 21103), zkuste to prosím znovu",
    "error_21104": "E-mail je již připojen, použijte prosím nový",
    "error_21105": "Požadavek se nezdařil (kód chyby: 21105), zkuste to prosím znovu",
    "error_21106": "Odeslání e-mailu se nezdařilo. Zkuste to znovu",
    "error_21201": "Zadejte svůj e-mail (kód chyby: 21201)",
    "error_21202": "Zadejte prosím ověřovací kód (kód chyby: 21202)",
    "error_21203": "E-mail není platný (kód chyby: 21203)",
    "error_21204": "Chyba: 21204. Kontaktujte nás",
    "error_21205": "Chyba: 21205. Kontaktujte nás",
    "error_21206": "Je vyžadováno heslo delší než 8 znaků (kód chyby: 21206)",
    "error_21207": "Požadavek se nezdařil (kód chyby: 21207). Zkuste to znovu",
    "error_21209": "Požadavek se nezdařil (kód chyby: 21209). Zkuste to znovu",
    "error_21301": "Zadejte staré heslo (kód chyby: 21301)",
    "error_21302": "Vytvořte si prosím nové heslo (kód chyby: 21302)",
    "error_21303": "Nové heslo nemůže být stejné jako staré. (Chyba: 21303)",
    "error_21304": "Je vyžadováno heslo delší než 8 znaků (kód chyby: 21304)",
    "error_21306": "Požadavek se nezdařil (kód chyby: 21306). Zkuste to znovu",
    "error_21402": "Požadavek se nezdařil (kód chyby: 21402). Zkuste to znovu",
    "error_21403": "Odeslání ověřovacího kódu se nezdařilo. Odešlete jej prosím znovu",
    "error_21500": "Účet byl smazán",
    "error_21501": "Zadejte ověřovací kód (kód chyby: 21501)",
    "error_21502": "Přihlašovací relace vypršela (Chyba: 21502). Přihlaste se prosím znovu.",
    "error_21503": "Požadavek se nezdařil (kód chyby: 21503). Zkuste to znovu",
    "error_21505": "Požadavek se nezdařil (kód chyby: 21505), zkuste to prosím znovu",
    "error_21601": "Chyba: 20601. Kontaktujte nás",
    "error_21602": "Neplatné ověření (chyba: 20602). Zkuste to znovu.",
    "error_21603": "Chyba: 20603. Zkuste to prosím znovu",
    "error_21604": "Požadavek se nezdařil (kód chyby: 21604). Zkuste to znovu",
    "error_21606": "Požadavek se nezdařil (kód chyby: 21606). Zkuste to znovu",
    "error_21611": "Požadavek se nezdařil (kód chyby: 21611). Zkuste to znovu",
    "error_21801": "Chyba: 21801. Kontaktujte nás",
    "error_21802": "Požadavek se nezdařil (Chyba: 21802). Zkuste to znovu",
    "error_21803": "Chyba: 21803. Zkuste to prosím znovu",
    "error_21804": "Požadavek se nezdařil (kód chyby: 21804). Zkuste to znovu",
    "error_21806": "Chyba: 21806. Zkuste to prosím znovu",
    "error_21807": "Chyba: 21807. Kontaktujte nás",
    "error_21808": "Chyba: 21808. Kontaktujte nás",
    "error_21809": "Chyba: 21809. Kontaktujte nás",
    "error_21810": "Chyba: 21810. Kontaktujte nás",
    "error_21811": "Chyba: 21811. Kontaktujte nás",
    "error_21812": "Chyba: 21812. Kontaktujte nás",
    "error_21813": "Požadavek se nezdařil (kód chyby: 21813). Zkuste to znovu",
    "error_21814": "Chyba: 21814. Kontaktujte nás",
    "error_21815": "Požadavek se nezdařil (kód chyby: 21815). Zkuste to znovu",
    "error_21816": "Chyba: 21816. Kontaktujte nás",
    "error_21817": "Chyba: 21817. Kontaktujte nás",
    "error_21818": "Chyba: 21818. Kontaktujte nás",
    "error_21819": "Požadavek se nezdařil (kód chyby: 21819). Zkuste to znovu",
    "error_21820": "Chyba: 21820. Kontaktujte nás",
    "error_21821": "Chyba: 21821. Kontaktujte nás",
    "error_21822": "Chyba: 21822. Kontaktujte nás",
    "error_21823": "Požadavek se nezdařil (kód chyby: 21823). Zkuste to znovu",
    "error_21824": "Požadavek se nezdařil (kód chyby: 21824). Zkuste to znovu",
    "error_21825": "Požadavek se nezdařil (kód chyby: 21825). Zkuste to znovu",
    "error_21826": "Požadavek se nezdařil (kód chyby: 21826). Zkuste to znovu",
    "error_21901": "Chyba: 21901. Kontaktujte nás",
    "error_21902": "Požadavek se nezdařil (kód chyby: 21902). Zkuste to znovu",
    "error_21903": "Stav účtu se změnil (kód chyby: 21903), obnovte stránku a zkuste to znovu",
    "error_21904": "Chyba: 21904. Zkuste to prosím znovu",
    "error_21905": "Chyba: 21905. Zkuste to prosím znovu",
    "error_21906": "Požadavek se nezdařil (kód chyby: 21906). Zkuste to znovu",
    "error_21907": "Účet Google byl propojen s jiným účtem",
    "error_21908": "Požadavek se nezdařil (kód chyby: 21908). Zkuste to znovu",
    "error_22001": "Požadavek se nezdařil (kód chyby: 22001). Zkuste to znovu",
    "error_22002": "Odpojení se nezdařilo bez dalšího přihlášení",
    "error_22003": "Požadavek se nezdařil (kód chyby: 22003). Zkuste to znovu",
    "error_22101": "Chyba: 22101. Kontaktujte nás",
    "error_22102": "Stav účtu se změnil (kód chyby: 22102), obnovte stránku a zkuste to znovu",
    "error_22103": "Požadavek se nezdařil (kód chyby: 22103). Zkuste to znovu",
    "error_22104": "Stav účtu se změnil (kód chyby: 22104), obnovte stránku a zkuste to znovu",
    "error_22105": "Chyba: 22105. Zkuste to prosím znovu",
    "error_22106": "Chyba: 22106. Zkuste to prosím znovu",
    "error_22107": "Chyba: 22107. Kontaktujte nás",
    "error_22108": "Požadavek se nezdařil (kód chyby: 22108). Zkuste to znovu",
    "error_22201": "Chyba: 22201. Kontaktujte nás",
    "error_22202": "Neplatné ověření (chyba: 22202). Zkuste to znovu.",
    "error_22203": "Chyba: 22203. Zkuste to prosím znovu\"",
    "error_22204": "Požadavek se nezdařil (kód chyby: 22204). Zkuste to znovu",
    "error_22206": "Požadavek se nezdařil (kód chyby: 22206). Zkuste to znovu",
    "error_22401": "Chyba: 22401. Kontaktujte nás",
    "error_22402": "Neplatné ověření (chyba: 22402). Zkuste to znovu.",
    "error_22403": "Chyba: 22403. Zkuste to prosím znovu",
    "error_22404": "Požadavek se nezdařil (kód chyby: 22404). Zkuste to znovu",
    "error_22405": "Účet na Facebooku byl propojen s jiným e-mailem",
    "error_22406": "Chyba: 22406. Zkuste to prosím znovu",
    "error_22407": "Chyba: 22407. Kontaktujte nás",
    "error_22408": "Chyba: 22408. Kontaktujte nás",
    "error_22409": "Chyba: 22409. Kontaktujte nás",
    "error_22410": "Chyba: 224010. Kontaktujte nás",
    "error_22411": "Chyba: 224011. Kontaktujte nás",
    "error_22412": "Chyba: 224012. Kontaktujte nás",
    "error_22413": "Chyba: 22413. Kontaktujte nás",
    "error_22414": "Požadavek se nezdařil (kód chyby: 22414). Zkuste to znovu",
    "error_22415": "Chyba: 22415. Kontaktujte nás",
    "error_22416": "Chyba: 22416. Kontaktujte nás",
    "error_22417": "Chyba: 22417. Kontaktujte nás",
    "error_22418": "Požadavek se nezdařil (kód chyby: 22418). Zkuste to znovu",
    "error_22419": "Chyba: 22419. Kontaktujte nás",
    "error_22420": "Chyba: 22420. Kontaktujte nás",
    "error_22421": "Chyba: 22421. Kontaktujte nás",
    "error_22422": "Požadavek se nezdařil (kód chyby: 22422). Zkuste to znovu",
    "error_22423": "Požadavek se nezdařil (kód chyby: 22423). Zkuste to znovu",
    "error_22424": "Požadavek se nezdařil (kód chyby: 22424). Zkuste to znovu",
    "error_22425": "Požadavek se nezdařil (kód chyby: 22425). Zkuste to znovu",
    "error_20098": "Kód chyby: 20098. Pokud prohlížíte stránku v soukromém režimu, přepněte do normálního režimu a zkuste to znovu.",
    "error_22298": "Požadavek na Google se nezdařil (chyba: 22298). Zkuste to znovu.",
    "error_22498": "Požadavek na Facebook se nezdařil (Chyba: 22498). Zkuste to znovu.",
    "error_24902": "Požadavek se nezdařil (kód chyby: 24902). Zkuste to znovu",
    "error_24903": "Požadavek se nezdařil (kód chyby: 24903). Zkuste to znovu",
    "error_24904": "Požadavek se nezdařil (kód chyby: 24904). Zkuste to znovu",
    "error_24905": "Požadavek se nezdařil (kód chyby: 24905). Zkuste to znovu",
    "login_title": "Přihlaste se do MindOnMap",
    "log_in": "Přihlásit se",
    "no_account": "Žádný účet?",
    "create_it": "Vytvořit to",
    "or_log_in_with": "Nebo se přihlaste pomocí",
    "passwordless_login": "Přihlašování bez hesla",
    "log_in_done": "Přihlásit se Hotovo",
    "three_rd_account_connect_info": "Gratulujeme! Úspěšně jste se přihlásili. Nyní můžete připojit jeden e-mailový účet, který se bude v budoucnu používat k přihlášení.",
    "see_my_account": "Viz můj účet",
    "three_rd_login_merge_account": "E-mailová adresa účtu třetí strany byla zaregistrována, chcete se připojit a přihlásit se přímo pomocí této e-mailové adresy?",
    "connect_log_in": "Připojit a přihlásit se",
    "create_an_account": "Vytvořit účet",
    "back_to_log_in": "Zpět k přihlášení",
    "create_password": "Vytvořit heslo",
    "create_now": "Vytvořit nyní",
    "password_login_subtitle": "Přihlášení bez hesla pomocí e-mailu",
    "account_login": "Přihlášení k účtu",
    "rights": "Vytvořením tohoto účtu souhlasíte se <a href=\"https://www.mindonmap.com/terms-and-conditions/\">smluvními podmínkami</a> a <a href=\"https://www.mindonmap.com/privacy-policy/\">Zásady ochrany osobních údajů</a>",
    "passwordless_login_done": "Přihlášení bez hesla provedeno",
    "passwordless_login_info": "Gratulujeme, úspěšně jste dokončili přihlášení bez hesla. Pro tento účet si můžete vytvořit heslo a v budoucnu se tímto účtem a heslem přihlásit. <a href=\"/create-password\" style=\"display: initial;\">Vytvořit nyní</a>",
    "sign_up": "Přihlásit se",
    "register_info": "Vytvořte si účet",
    "reset_now": "Resetovat nyní",
    "forgot_password": "Zapomenuté heslo",
    "reset_password_subtitle": "Pro resetování hesla použijte e-mail svého účtu",
    "plan_products": "Plány a produkty",
    "nick_name": "uživatelské jméno:",
    "email": "E-mailem:",
    "my_products": "Moje produkty",
    "my_orders": "Mé objednávky",
    "unlink": "Odpojit",
    "link": "Odkaz",
    "connected_accounts": "Propojené účty",
    "last_name": "Příjmení:",
    "first_name": "Jméno:",
    "Gender": "Rod:",
    "Birth": "Narození:",
    "Month": "Měsíc",
    "Year": "Rok",
    "Country_Region": "Země/oblast:",
    "Address": "Adresa:",
    "Save": "Uložit",
    "Date": "datum",
    "Male": "mužský",
    "Female": "ženský",
    "Unspecified": "Nespecifikováno",
    "Security": "Bezpečnostní",
    "change_password": "Změnit heslo",
    "change_now": "Změnit hned",
    "connect_email": "Připojte e-mail",
    "delete_account": "Smazat účet",
    "delete_account_info": "Když je váš účet smazán, všechna data v Můj účet přidružená k vašemu účtu budou trvale smazána a možná je nebudete moci obnovit. Doporučujeme postupovat opatrně.",
    "Delete": "Vymazat",
    "Logout": "Odhlásit se",
    "my_profile": "Můj profil",
    "guides_faqs": "Návody a časté dotazy",
    "More": "Více",
    "guides": "Průvodci",
    "register": "Registrovat",
    "hot_faq": "Nejčastější dotazy",
    "Contents": "Obsah:",
    "contact_us": "Kontaktujte nás >>",
    "plan": "Plán",
    "unregistered": "Neregistrovaný",
    "buy_more": "Koupit více",
    "buy_again": "Koupit znovu",
    "buy_now": "Kup nyní",
    "free_no_limit": "Zdarma a bez omezení",
    "expired": "Platnost vypršela",
    "lifetime": "Život",
    "remain": "Zůstat",
    "day_s": "dny",
    "error_24801": "Požadavek se nezdařil (kód chyby: 24801). Zkuste to znovu",
    "no_app_found": "Nebyla nalezena žádná aplikace! <a href=\"/\">Obnovte</a> nebo <a href=\"https://www.mindonmap.com/\">Přejděte na oficiální web</a>",
    "get_more": "Získejte více >>",
    "edit_photo": "Upravit fotografii",
    "select_photo": "Vyberte fotografii",
    "change_photo": "Změnit fotku",
    "cancel": "zrušení",
    "hide_password": "Skrýt heslo",
    "show_password": "Zobrazit heslo",
    "zoom_in": "Přiblížit",
    "zoom_out": "Oddálit",
    "rotate": "Točit se",
    "horizontal_flip": "Horizontální překlopení",
    "vertical_flip": "Vertikální překlopení",
    "country": "Země",
    "country_1": "Vyberte svou zemi/oblast",
    "country_2": "Ostrov Åaland",
    "country_3": "Afghánistán",
    "country_4": "Albánie",
    "country_5": "Alžírsko",
    "country_6": "Americká Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktida",
    "country_11": "Antigua a Barbuda",
    "country_12": "Argentina",
    "country_13": "Arménie",
    "country_14": "Aruba",
    "country_15": "Austrálie",
    "country_16": "Rakousko",
    "country_17": "Ázerbajdžán",
    "country_18": "Bahrajn",
    "country_19": "Bangladéš",
    "country_20": "Barbados",
    "country_21": "Bělorusko",
    "country_22": "Belgie",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermudy",
    "country_26": "Bhútán",
    "country_27": "Bolívie",
    "country_28": "Bosna a Hercegovina",
    "country_29": "Botswana",
    "country_30": "Bouvetův ostrov",
    "country_31": "Brazílie",
    "country_32": "Britské indickooceánské území",
    "country_33": "Britské Panenské ostrovy",
    "country_34": "Brunej",
    "country_35": "Bulharsko",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kapverdy",
    "country_42": "Karibské Nizozemsko",
    "country_43": "Kajmanské ostrovy",
    "country_44": "Středoafrická republika",
    "country_45": "Čad",
    "country_46": "Chile",
    "country_47": "Čína",
    "country_48": "Vánoční ostrov",
    "country_49": "Kokosové (Keelingovy) ostrovy",
    "country_50": "Kolumbie",
    "country_51": "Cookovy ostrovy",
    "country_52": "Kostarika",
    "country_53": "Pobřeží slonoviny",
    "country_54": "Chorvatsko",
    "country_55": "Kuba",
    "country_56": "Kypr",
    "country_57": "Česká republika",
    "country_58": "Demokratická republika Kongo",
    "country_59": "Dánsko",
    "country_60": "Džibutsko",
    "country_61": "Dominika",
    "country_62": "Dominikánská republika",
    "country_63": "Ekvádor",
    "country_64": "Egypt",
    "country_65": "El Salvador",
    "country_66": "Rovníková Guinea",
    "country_67": "Eritrea",
    "country_68": "Estonsko",
    "country_69": "Etiopie",
    "country_70": "Falklandy",
    "country_71": "Faerské ostrovy",
    "country_72": "Federativní státy Mikronésie",
    "country_73": "Fidži",
    "country_74": "Finsko",
    "country_75": "Francie",
    "country_76": "Francouzská Guyana",
    "country_77": "Francouzská Polynésie",
    "country_78": "Francouzská jižní území",
    "country_79": "Gabon",
    "country_80": "Gambie",
    "country_81": "Gruzie",
    "country_82": "Německo",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Velká Británie (Spojené království; Anglie)",
    "country_86": "Řecko",
    "country_87": "Grónsko",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heardův ostrov a McDonaldovy ostrovy",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Maďarsko",
    "country_101": "Island",
    "country_102": "Indie",
    "country_103": "Indonésie",
    "country_104": "Írán",
    "country_105": "Irák",
    "country_106": "Irsko",
    "country_107": "Ostrov Man",
    "country_108": "Izrael",
    "country_109": "Itálie",
    "country_110": "Jamaica",
    "country_111": "Japonsko",
    "country_112": "Trikot",
    "country_113": "Jordán",
    "country_114": "Kazachstán",
    "country_115": "Keňa",
    "country_116": "Kiribati",
    "country_117": "Kuvajt",
    "country_118": "Kyrgyzstán",
    "country_119": "Laos",
    "country_120": "Lotyšsko",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Libérie",
    "country_124": "Libye",
    "country_125": "Lichtenštejnsko",
    "country_126": "Litva",
    "country_127": "Lucembursko",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malajsie",
    "country_132": "Maledivy",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshallovy ostrovy",
    "country_136": "Martinik",
    "country_137": "Mauritánie",
    "country_138": "Mauricius",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldavsko",
    "country_142": "Monako",
    "country_143": "Mongolsko",
    "country_144": "Černá Hora",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mosambik",
    "country_148": "Myanmar (Barma)",
    "country_149": "Namibie",
    "country_150": "Nauru",
    "country_151": "Nepál",
    "country_152": "Holandsko",
    "country_153": "Nová Kaledonie",
    "country_154": "Nový Zéland",
    "country_155": "Nikaragua",
    "country_156": "Niger",
    "country_157": "Nigérie",
    "country_158": "Niue",
    "country_159": "Ostrov Norfolk",
    "country_160": "Severní Korea",
    "country_161": "Severní Mariánské ostrovy",
    "country_162": "Norsko",
    "country_163": "Omán",
    "country_164": "Pákistán",
    "country_165": "Palau",
    "country_166": "Palestinská území",
    "country_167": "Panama",
    "country_168": "Papua-Nová Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairnovy ostrovy",
    "country_172": "Polsko",
    "country_173": "Portugalsko",
    "country_174": "Portoriko",
    "country_175": "Katar",
    "country_176": "Republika Makedonie (FYROM)",
    "country_177": "Konžská republika",
    "country_178": "Shledání",
    "country_179": "Rumunsko",
    "country_180": "Ruská Federace",
    "country_181": "Rwanda",
    "country_182": "Svatý Bartoloměj",
    "country_183": "Saint Martin (Francie)",
    "country_184": "Saint-Pierre a Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Svatý Tomáš a princ",
    "country_188": "Saudská arábie",
    "country_189": "Senegal",
    "country_190": "Srbsko",
    "country_191": "Seychely",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Slovensko",
    "country_195": "Slovinsko",
    "country_196": "Solomonovy ostrovy",
    "country_197": "Somálsko",
    "country_198": "Jižní Afrika",
    "country_199": "Jižní Georgie a South Sandwich Isla",
    "country_200": "Jižní Korea",
    "country_201": "Jižní Súdán",
    "country_202": "Španělsko",
    "country_203": "Srí Lanka",
    "country_204": "Svatá Helena a závislosti",
    "country_205": "Svatý Kryštof a Nevis",
    "country_206": "Svatá Lucie",
    "country_207": "Svatý Vincent a Grenadiny",
    "country_208": "Súdán",
    "country_209": "Surinam",
    "country_210": "Svazijsko",
    "country_211": "Švédsko",
    "country_212": "Švýcarsko",
    "country_213": "Sýrie",
    "country_214": "Tchaj-wan",
    "country_215": "Tádžikistán",
    "country_216": "Tanzanie",
    "country_217": "Šablona: Údaje o zemi SJM Svalbard",
    "country_218": "Thajsko",
    "country_219": "Bahamy",
    "country_220": "Komory",
    "country_221": "Filipíny",
    "country_222": "Východní Timor (Východní Timor)",
    "country_223": "Jít",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad a Tobago",
    "country_227": "Tunisko",
    "country_228": "krocan",
    "country_229": "Turkmenistán",
    "country_230": "Ostrovy Turks a Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrajina",
    "country_234": "Spojené arabské emiráty",
    "country_235": "Spojené Státy Malé odlehlé ostrovy",
    "country_236": "Spojené státy americké (USA)",
    "country_237": "Spojené státy Panenské ostrovy",
    "country_238": "Uruguay",
    "country_239": "Uzbekistán",
    "country_240": "Vanuatu",
    "country_241": "Vatikán (Svatý stolec)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis a Futuna",
    "country_245": "Západní Sahara",
    "country_246": "Jemen",
    "country_247": "Zambie",
    "country_248": "Zimbabwe",
    "google_login": "Přihlaste se pomocí Google",
    "State": "Stát",
    "Activation_code": "Aktivační kód",
    "Question": "Seznam všech aplikací, ke kterým jste přihlášeni",
    "Copy_complete": "Kopírování dokončeno",
    "footer": "Copyright © 2024 MindOnMap Studio. Všechna práva vyhrazena.",
    "change_password_success": "heslo bylo úspěšně změněno",
    "successful_login_title": "Přihlášení bylo úspěšné",
    "product_page": "Stránka produktu >>",
    "successful_login_info": "Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu a pokračujte v následujícím procesu. Aktuální stránka se automaticky zavře za 5 sekund. Pokud se automatické zavření nebo zavření kliknutím na tlačítko \"Hotovo\" nezdaří, zavřete prosím přímo tuto kartu.",
    "successful_login_info_firefox": "Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu, abyste mohli pokračovat v následujícím procesu.",
    "my_account": "Můj účet",
    "my_history": "Moje historie",
    "remove_watermark": "Odstraňte vodoznak",
    "no_history": "Žádná historie",
    "history_all": "Vybrat vše",
    "history_open": "OTEVŘENO",
    "history_down": "Stažení",
    "history_delete": "Vymazat",
    "history_clear": "Vymazat neplatné",
    "images": "snímky",
    "use_this_function": "Použít tento produkt >>",
    "hd_downloading": "Výhody stahování originálních HD obrázků:",
    "lifetimeRemaining": "Doživotí-Zbývající",
    "Remaining": "Zbývající",
    "email_verification": "potvrzení e-mailu",
    "email_verification_info": "Ověřovací zprávu jsme odeslali na váš e-mail <span class=\"email\"></span> a dokončete prosím ověření. Po ověření budou výhody automaticky synchronizovány.",
    "wrong_email": "Špatná e-mailová adresa?",
    "click_here_to_modify": "Klikněte zde pro úpravu",
    "get_more_help": "Získat další pomoc?",
    "click_here": "Klikněte zde",
    "email_verification_info_success": "Gratulujeme k ověření vašeho e-mailového účtu.",
    "email_verification_info_error": "Ověření se nezdařilo z důvodu vypršení platnosti odkazu.",
    "registration_succeeded": "Registrace byla úspěšná",
    "registration_succeeded_info_1": "Gratulujeme! Úspěšně jste se zaregistrovali. Ověřovací zprávu jsme odeslali na váš e-mail <span class=\"email\"></span> a dokončete ověření, abyste mohli využívat výhod tohoto e-mailu.",
    "registration_succeeded_info_2": "Kliknutím na „Hotovo“ se vraťte na domovskou stránku a použijte tento produkt.",
    "registration_succeeded_info_3": "Kliknutím na „Hotovo“ zavřete aktuální stránku a vrátíte se na domovskou stránku pro následující operace. Pokud se zavření nezdaří, zavřete kartu ručně.",
    "verify_email": "ověřovací email",
    "registered_email_not_verified": "Registrovaný e-mail nebyl ověřen, prosím jej ihned ověřte.",
    "email_verification_time_1": "Neobdrželi jste ověřovací e-mail?",
    "email_verification_time_2": "Po",
    "email_verification_time_3": "Kliknutím sem jej znovu odešlete",
    "error_26301": "Kód chyby: 26301, kontaktujte nás",
    "error_26302": "Kód chyby: 26302, kontaktujte nás",
    "error_26303": "Chyba formátu e-mailu (kód chyby: 26303). Zadejte jej znovu",
    "error_26304": "Doporučuje se heslo delší než 8 znaků (kód chyby: 26304)",
    "error_26305": "Požadavek se nezdařil (kód chyby: 26305). Zkuste to znovu",
    "error_26306": "E-mail byl zaregistrován, <a href=\"https://account.mindonmap.com/login/\">přejděte k přihlášení</a>",
    "error_26307": "Požadavek se nezdařil (kód chyby: 26307). Zkuste to znovu",
    "error_26308": "Požadavek se nezdařil (kód chyby: 26308). Zkuste to znovu",
    "error_26401": "Kód chyby: 26401, zkuste to znovu",
    "error_26402": "E-mail byl ověřen (kód chyby: 26402), zkuste to prosím znovu",
    "error_26403": "Požadavek se nezdařil (kód chyby: 26403). Zkuste to znovu",
    "error_26404": "Požadavek se nezdařil (kód chyby: 26404). Zkuste to znovu",
    "error_26501": "Kód chyby: 26501, kontaktujte nás",
    "error_26502": "Kód chyby: 26502, kontaktujte nás",
    "error_26503": "Chyba formátu e-mailu (kód chyby: 26503). Zadejte jej znovu",
    "error_26504": "Požadavek se nezdařil (kód chyby: 26504). Zkuste to znovu",
    "error_26505": "E-mail nebyl zaregistrován, prosím <a href=\"https://account.mindonmap.com/register/\">nejprve jej zaregistrujte</a>",
    "error_26506": "E-mail byl ověřen.",
    "error_26507": "Požadavek se nezdařil (kód chyby: 26507). Zkuste to znovu",
    "error_26508": "Ověření proběhlo úspěšně (kód chyby: 26508), zkuste to znovu",
    "error_26509": "Požadavek se nezdařil (kód chyby: 26509), zkuste to znovu",
    "error_26510": "Kód chyby: 26510, kontaktujte nás",
    "error_26601": "Kód chyby: 26601, kontaktujte nás prosím",
    "error_26602": "Kód chyby: 26602, kontaktujte nás",
    "error_26603": "Požadavek se nezdařil (kód chyby: 26603). Zkuste to znovu",
    "error_26604": "Kód chyby: 26604, kontaktujte nás",
    "error_26605": "Kód chyby: 26605, kontaktujte nás",
    "error_26701": "Kód chyby: 26701, kontaktujte nás prosím",
    "error_26702": "Požadavek se nezdařil (kód chyby: 26702). Zkuste to znovu",
    "error_26703": "Kód chyby: 26703, kontaktujte nás",
    "error_26704": "Kód chyby: 26704, kontaktujte nás",
    "error_26705": "Počkejte na přihlášení (kód chyby: 26705). Zkuste to znovu",
    "no_cookie": "Ve svém prohlížeči jste zapnuli funkci Blokovat všechny soubory cookie, takže se nemůžete přihlásit. Přejděte prosím do Nastavení a zaškrtněte políčko Povolit všechny soubory cookie.",
    "error_26801": "Kód chyby: 26801, kontaktujte nás",
    "error_26802": "Kód chyby: 26802, kontaktujte nás",
    "error_26803": "Požadavek se nezdařil (kód chyby: 26803). Zkuste to znovu",
    "error_26804": "Požadavek se nezdařil (kód chyby: 26804). Zkuste to znovu",
    "error_order": "Požadavek se nezdařil (kód chyby: 27098), zkuste to prosím znovu!",
    "error_order1": "Dotaz na objednávku je neúplný (kód chyby: ",
    "error_order2": "), obnovte stránku a zkuste to znovu.",
    "modify_email_title": "Upravit e-mail",
    "modify_email_info": "Upravený e-mail můžete použít k přihlášení ke svému účtu.",
    "images_per": "Obrázky za",
    "error_26101": "Chyba: 26101. Kontaktujte nás",
    "error_26102": "Chyba: 26102. Kontaktujte nás",
    "error_26103": "Požadavek se nezdařil (kód chyby: 26103). Zkuste to znovu",
    "error_26104": "Kód chyby: 26104, zkuste to prosím znovu",
    "error_26105": "Kód chyby: 26105, zkuste to prosím znovu",
    "error_26106": "Smazání se nezdařilo (kód chyby: 26106). Zkuste to znovu",
    "error_26201": "Chyba: 26201. Kontaktujte nás",
    "error_26202": "Požadavek se nezdařil (kód chyby: 26202). Zkuste to znovu",
    "error_26001": "Chyba: 26001. Kontaktujte nás",
    "error_26002": "Chyba: 26002. Kontaktujte nás",
    "error_26003": "Chyba: 26003. Kontaktujte nás",
    "error_26004": "Chyba: 26004. Kontaktujte nás",
    "error_26005": "Požadavek se nezdařil (kód chyby: 26005). Zkuste to znovu",
    "error_26006": "Kód chyby: 26006, zkuste to prosím znovu",
    "error_26008": "Chyba: 26008. Kontaktujte nás",
    "go_to_the_home_page": "Přejděte na domovskou stránku",
    "error_27101": "Požadavek se nezdařil (kód chyby: 27101). Zkuste to znovu ist",
    "error_27201": "Kód chyby: 27201, kontaktujte nás",
    "error_27202": "Kód chyby: 27202, zkuste to znovu",
    "error_27203": "Požadavek se nezdařil (kód chyby: 27203). Zkuste to znovu",
    "error_27204": "Neplatný kód (kód chyby: 27204).",
    "error_27205": "Požadavek se nezdařil (kód chyby: 27205). Zkuste to znovu",
    "error_27206": "Požadavek se nezdařil (kód chyby: 27206). Zkuste to znovu",
    "error_27207": "Požadavek se nezdařil (kód chyby: 27207). Zkuste to znovu",
    "no_history_found": "Nepoužili jste žádný nástroj! <a href=\"/\">Obnovit</a> nebo <a href=\"https://www.mindonmap.com/\">Přejít na oficiální web</a>",
    "error_25301": "Chyba: 25301. Kontaktujte nás",
    "error_25302": "Chyba: 25302. Kontaktujte nás",
    "error_25303": "Požadavek se nezdařil (kód chyby: 25303). Zkuste to znovu",
    "error_25304": "Požadavek se nezdařil (kód chyby: 25304). Zkuste to znovu",
    "error_25305": "Požadavek se nezdařil (kód chyby: 25305). Zkuste to znovu",
    "error_25306": "Požadavek se nezdařil (kód chyby: 25306). Zkuste to znovu",
    "image_upscaler_p": "Výhody stahování obrázku bez vodoznaku:",
    "Available_for": "Dostupné pro:",
    "credit_per": "%s kreditů za HD obrázek",
    "still_valid": "Zakoupený plán (plány) je stále platný",
    "credit": "kredit(y)",
    "pc_3rd_info": "Přihlášení proběhlo úspěšně. Pro další operaci přejděte do aplikace.",
    "use_online": "Použijte online službu",
    "use_download": "Použijte desktopový program",
    "use_immediately": "Použijte Ihned",
    "Use_in_browser": "Použijte v prohlížeči",
    "win_desktop": "Okna",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kreditů za měsíc",
    "expire": "Doba platnosti:",
    "viewDetails": "Zobrazit podrobnosti",
    "viewHistory": "Zobrazit výhody historie >>",
    "viewDetailsInfo": "Upozornění: Pokud jsou výhody předplatného obnoveny do 7 dnů po vypršení platnosti, lze nevyužité výhody nadále používat. Doba vypršení platnosti bude také automaticky aktualizována na dobu vypršení platnosti nového předplatného. Pokud do 7 dnů po uplynutí doby platnosti nedojde k žádnému novému předplatnému, všechny výhody, jejichž platnost vypršela, budou vymazány.",
    "connect_account": "Svázat e-mail se svým účtem",
    "connect_account_info": "Po vázání se můžete přihlásit pomocí této e-mailové adresy.",
    "connect_now": "Svázat nyní",
    "no_email_bind": "Žádná vazba na e-mail",
    "bind_email": "Svázat email",
    "connect_your_email_placeholder": "prosím zadejte svou e-mailovou adresu",
    "bind_an_email": "Svažte e-mail",
    "bind_info": "Úspěšně jste se přihlásili. Pro aktivaci výhod připojte e-mail ke svému účtu.",
    "bind_later": "Svázat později",
    "hi": "Jeho, %s!",
    "Personal_Information": "Osobní informace",
    "Access": "Přístup",
    "Subscription_Plan": "(Tarif předplatného)",
    "No_orders": "Nebyly nalezeny žádné objednávky.",
    "No_data": "Žádná data",
    "Featured_Products": "představované výrobky",
    "More_Products": "Další produkty",
    "Free_Download": "Stažení zdarma",
    "Get_Started": "Začít",
    "Subscribe": "předplatit",
    "Verified": "Ověřeno",
    "back_to_account_center": "Zpět do Account Center",
    "success": "Úspěch!",
    "successfully": "Úspěšně jste zaregistrovali účet.",
    "Continue": "Pokračovat",
    "Already": "Máte již účet?",
    "loading_verification": "Kontrola stavu ověření...",
    "email_no_verification": "Litujeme, registrovaná e-mailová adresa nebyla ověřena. Dokončete prosím ověření podle výše uvedených pokynů a opětovným kliknutím na tlačítko „Hotovo“ dokončete registraci.",
    "will_expire_after": "Vyprší poté",
    "hours": "hodin",
  },
  "cy": {
    "overtime": "Cod gwall: {%}, rhowch gynnig arall arni",
    "isnetwork": "Gwall rhyngrwyd. Gwiriwch ac ailgynnig",
    "email_placeholder": "Ebost",
    "email_empty": "Mewnbynnu e-bost",
    "email_not_valid": "Nid yw e-bost yn ddilys",
    "email_not_valid_1": "Mewnbynnwch eich e-bost",
    "email_not_valid_2": "Nid yw e-bost yn ddilys, defnyddiwch gyfeiriad gwahanol.",
    "email_not_valid_3": "Dim mewnbwn e-bost",
    "password_placeholder": "Cyfrinair",
    "password_empty": "Mewnbynnu cyfrinair",
    "password_not_valid": "Cyfrinair neu gyfrif anghywir",
    "password_not_valid_1": "Mae angen cyfrinair dros 8 nod",
    "password_not_valid_2": "Crëwch gyfrinair",
    "password_placeholder_1": "Creu eich cyfrinair",
    "password_placeholder_2": "Cadarnhewch eich Cyfrinair",
    "password_placeholder_3": "Creu cyfrinair newydd",
    "password_placeholder_4": "Cadarnhau cyfrinair Newydd",
    "password_placeholder_5": "Mewnbynnu hen gyfrinair",
    "copy_password_empty": "Cadarnhewch y cyfrinair",
    "copy_password_not_valid": "Cadarnhewch eich cyfrinair",
    "copy_passwords_inconsistent": "Nid yw eich cadarnhad cyfrinair yn cyfateb",
    "code_empty": "Mewnbynnu cod dilysu",
    "code_not_valid": "Cod dilysu annilys",
    "code_placeholder": "Cod Dilysu",
    "not_received_code": "Os nad yw eich blwch post wedi derbyn y cod dilysu ers amser maith, a fyddech cystal â chael y cod dilysu eto.",
    "get_first_code": "Sicrhewch y cod dilysu yn gyntaf.",
    "last_name_placeholder": "Rhowch eich enw olaf",
    "first_name_placeholder": "Rhowch eich enw cyntaf",
    "address_placeholder": "Rhowch eich cyfeiriad",
    "no_code_text": "Rydym wedi anfon cod dilysu. Rhowch eich cod. <span class='tips'>Heb dderbyn cod?",
    "no_code_text_1": "1. Gwnewch yn siŵr bod y cyfeiriad e-bost yn ddilys a gall dderbyn e-byst.",
    "no_code_text_2": "2. Ar gyfer yr e-bost yn cael ei anfon gan system yn awtomatig, efallai y bydd yn cael ei fflagio fel y sbam neu e-bost sothach. Gwiriwch a yw'r e-bost yn y ffolder Sbwriel.",
    "no_code_text_3": "3. Methu datrys eich problem? ",
    "no_code_text_3_span": "Yna cliciwch yma i gysylltu â ni.",
    "order_no": "Nid ydych wedi prynu unrhyw gynnyrch, Os oes gennych unrhyw gwestiwn, <a href=\"https://www.mindonmap.com/contact-us/\">cysylltwch â ni</a>.",
    "error_24901": "Nid oes gan y cyfrif cyfredol unrhyw e-bost wedi'i gysylltu, ac ni all ddod o hyd i'r archebion. Cysylltwch e-bost.",
    "user_guide": "Canllaw Defnyddiwr >>",
    "download": "Lawrlwythwch",
    "order_number": "Rhif archeb:",
    "Refund": "Ad-daliad",
    "Disabled": "Anabl",
    "Normal": "Arferol",
    "Modify": "Addasu",
    "Modify_1": "Addasu >>",
    "Connect": "Cyswllt",
    "unlink_success": "Datgysylltu yn llwyddiannus",
    "connect_success": "Cysylltwch yn llwyddiannus",
    "feedback_title": "Diolch am eich adborth. Gadewch eich problem a byddwn yn ymateb i chi o fewn 24 awr.",
    "feedback_thank_you": "Diolch!<br />Mae'ch Adborth wedi'i gyflwyno'n llwyddiannus.",
    "feedback_email": "Rhowch eich e-bost yma!",
    "feedback_content": "Gadewch unrhyw broblem neu awgrym y daethoch chi ar eu traws yma.",
    "feedback_submit": "Cyflwyno",
    "form_contents": "Nid ydych wedi nodi unrhyw ddisgrifiad. Rhowch ef a'i gyflwyno eto.",
    "old_password": "Rhowch hen gyfrinair",
    "new_password": "Crëwch gyfrinair newydd",
    "old_new_password": "Ni all y cyfrinair newydd fod yr un fath â'r hen un",
    "incorrect_password": "cyfrinair anghywir",
    "delete_no": "Dileu Nawr",
    "Caps": "Mae clo capiau ymlaen",
    "Get": "Cael",
    "Done": "Wedi'i wneud",
    "error_20001": "Gwall: 20001. Mewngofnodwch eto.",
    "error_20002": "Gwall: 20002. Mewngofnodwch eto.",
    "error_20003": "Gwall: 20003. Mewngofnodwch eto.",
    "error_20004": "Methodd y cais (cod gwall: 20004). Rhowch gynnig arall arni.",
    "error_20005": "Sesiwn mewngofnodi wedi dod i ben (Gwall: 20005). Mewngofnodwch eto.",
    "error_20006": "Methodd y cais (cod gwall: 20006). Rhowch gynnig arall arni.",
    "error_20007": "Sesiwn mewngofnodi wedi dod i ben (Gwall: 20007). Mewngofnodwch eto.",
    "error_20008": "Sesiwn mewngofnodi wedi dod i ben (Gwall: 20008). Mewngofnodwch eto.",
    "error_20009": "Sesiwn mewngofnodi wedi dod i ben (Gwall: 20009). Mewngofnodwch eto.",
    "error_20101": "Rhowch eich e-bost (cod gwall: 20101)",
    "error_20102": "Nid yw'r e-bost yn ddilys (cod gwall: 20102)",
    "error_20103": "Methodd y cais (cod gwall: 20103). Rhowch gynnig arall arni",
    "error_20104": "Mae'r e-bost eisoes yn cael ei ddefnyddio, <a href=\"https://account.mindonmap.com/login/\">mewngofnodwch</a> neu cofrestrwch gydag un newydd",
    "error_20105": "Methodd y cais (cod gwall: 20105). Rhowch gynnig arall arni",
    "error_20106": "Wedi methu ag anfon e-bost, rhowch gynnig arall arni",
    "error_20201": "Rhowch eich e-bost (cod gwall: 20201)",
    "error_20202": "Rhowch eich cyfrinair (cod gwall: 20202)",
    "error_20203": "Mewnbynnwch y cod dilysu (cod gwall: 20203)",
    "error_20204": "Nid yw'r e-bost yn ddilys (cod gwall: 20204)",
    "error_20205": "Mae angen cyfrinair dros 8 nod (cod gwall: 20205)",
    "error_20206": "Methodd y cais (cod gwall: 20206). Rhowch gynnig arall arni",
    "error_20207": "Cod dilysu annilys",
    "error_20208": "Methodd y cais (cod gwall: 20208). Rhowch gynnig arall arni",
    "error_20209": "Methodd y cais (cod gwall: 20209). Rhowch gynnig arall arni",
    "error_20301": "Rhowch eich e-bost (cod gwall: 20301)",
    "error_20302": "Gwall: 20302. Cysylltwch â ni",
    "error_20303": "Nid yw'r e-bost yn ddilys (cod gwall: 20303)",
    "error_20304": "Methodd y cais (cod gwall: 20304). Rhowch gynnig arall arni",
    "error_20305": "Nid yw'r cyfrif yn bodoli. Rhowch eto neu <a href=\"https://account.mindonmap.com/register/\">Crëwch ef</a> yn gyntaf.",
    "error_20306": "Dim cyfrinair eto. Defnyddiwch <a href=\"https://account.mindonmap.com/passwordless-login/\">Mewngofnodi heb gyfrinair</a> neu <a href=\"https://account.mindonmap.com/create-password/\"> gosod cyfrinair</a> a mewngofnodi.",
    "error_20308": "Methodd y cais (cod gwall: 20308). Rhowch gynnig arall arni",
    "error_20401": "Wedi methu ag allgofnodi (cod gwall: 20401). Rhowch gynnig arall arni",
    "error_20501": "Rhowch eich e-bost (cod gwall: 20501)",
    "error_20502": "Nid yw'r e-bost yn ddilys (cod gwall: 20502)",
    "error_20503": "Methodd y cais (cod gwall: 20503). Rhowch gynnig arall arni",
    "error_20504": "Wedi methu ag anfon e-bost. Rhowch gynnig arall arni.",
    "error_20601": "Rhowch eich e-bost (cod gwall: 20601)",
    "error_20602": "Mewnbynnwch y cod dilysu (cod gwall: 20602)",
    "error_20603": "Nid yw'r e-bost yn ddilys (cod gwall: 20603)",
    "error_20604": "Methodd y cais (cod gwall: 20604). Rhowch gynnig arall arni",
    "error_20606": "Methodd y cais (cod gwall: 20606). Rhowch gynnig arall arni",
    "error_20607": "Methodd y cais (cod gwall: 20607). Rhowch gynnig arall arni",
    "error_20608": "Methodd y cais (cod gwall: 20608). Rhowch gynnig arall arni",
    "error_20701": "Rhowch eich e-bost (cod gwall: 20701)",
    "error_20702": "Nid yw'r e-bost yn ddilys (cod gwall: 20702)",
    "error_20703": "Methodd y cais (cod gwall: 20703). Rhowch gynnig arall arni",
    "error_20704": "Nid yw'r cyfrif yn bodoli. Rhowch eto neu <a href=\"https://account.mindonmap.com/register/\">Crëwch ef</a> yn gyntaf.",
    "error_20705": "Methodd y cais (cod gwall: 20705). Rhowch gynnig arall arni",
    "error_20706": "Wedi methu ag anfon e-bost. Rhowch gynnig arall arni",
    "error_20801": "Rhowch eich e-bost (cod gwall: 20801)",
    "error_20802": "Gwall: 20802. Cysylltwch â ni",
    "error_20803": "Mewnbynnwch y cod dilysu (cod gwall: 20803)",
    "error_20804": "Nid yw'r e-bost yn ddilys (cod gwall: 20804)",
    "error_20805": "Mae angen cyfrinair dros 8 nod (cod gwall: 20805)",
    "error_20806": "Methodd y cais (cod gwall: 20806). Rhowch gynnig arall arni",
    "error_20808": "Methodd y cais (cod gwall: 20808). Rhowch gynnig arall arni",
    "error_20901": "Methodd y cais (cod gwall: 20901). Rhowch gynnig arall arni",
    "error_20902": "Methodd y cais (cod gwall: 20902). Rhowch gynnig arall arni",
    "error_21000": "Newidiadau yn cael eu cadw",
    "error_21001": "Ni chyflwynir unrhyw wybodaeth (cod gwall: 21001)",
    "error_21002": "Methodd y cais (cod gwall: 21002). Rhowch gynnig arall arni",
    "error_21101": "Rhowch eich e-bost (cod gwall: 21101)",
    "error_21102": "Nid yw'r e-bost yn ddilys (cod gwall: 21102)",
    "error_21103": "Methodd y cais (cod gwall: 21103), rhowch gynnig arall arni",
    "error_21104": "Mae'r e-bost eisoes wedi'i gysylltu, defnyddiwch un newydd",
    "error_21105": "Methodd y cais (cod gwall: 21105), rhowch gynnig arall arni",
    "error_21106": "Wedi methu ag anfon e-bost. Rhowch gynnig arall arni",
    "error_21201": "Rhowch eich e-bost (cod gwall: 21201)",
    "error_21202": "Mewnbynnwch y cod dilysu (cod gwall: 21202)",
    "error_21203": "Nid yw'r e-bost yn ddilys (cod gwall: 21203)",
    "error_21204": "Gwall: 21204. Cysylltwch â ni",
    "error_21205": "Gwall: 21205. Cysylltwch â ni",
    "error_21206": "Mae angen cyfrinair dros 8 nod (cod gwall: 21206)",
    "error_21207": "Methodd y cais (cod gwall: 21207). Rhowch gynnig arall arni",
    "error_21209": "Methodd y cais (cod gwall: 21209). Rhowch gynnig arall arni",
    "error_21301": "Rhowch hen gyfrinair (cod gwall: 21301)",
    "error_21302": "Crëwch gyfrinair newydd (cod gwall: 21302)",
    "error_21303": "Ni all y cyfrinair newydd fod yr un fath â'r hen un. (Gwall: 21303)",
    "error_21304": "Mae angen cyfrinair dros 8 nod (cod gwall: 21304)",
    "error_21306": "Methodd y cais (cod gwall: 21306). Rhowch gynnig arall arni",
    "error_21402": "Methodd y cais (cod gwall: 21402). Rhowch gynnig arall arni",
    "error_21403": "Wedi methu ag anfon cod dilysu. Ail-anfonwch ef",
    "error_21500": "Mae'r cyfrif wedi'i ddileu",
    "error_21501": "Mewnbynnu cod dilysu (cod gwall: 21501)",
    "error_21502": "Sesiwn mewngofnodi wedi dod i ben (Gwall: 21502). Mewngofnodwch eto.",
    "error_21503": "Methodd y cais (cod gwall: 21503). Rhowch gynnig arall arni",
    "error_21505": "Methodd y cais (cod gwall: 21505), rhowch gynnig arall arni",
    "error_21601": "Gwall: 20601. Cysylltwch â ni",
    "error_21602": "Dilysiad Annilys (Gwall: 20602). Rhowch gynnig arall arni.",
    "error_21603": "Gwall: 20603. Rhowch gynnig arall arni",
    "error_21604": "Methodd y cais (cod gwall: 21604). Rhowch gynnig arall arni",
    "error_21606": "Methodd y cais (cod gwall: 21606). Rhowch gynnig arall arni",
    "error_21611": "Methodd y cais (cod gwall: 21611). Rhowch gynnig arall arni",
    "error_21801": "Gwall: 21801. Cysylltwch â ni",
    "error_21802": "Methodd y cais (Gwall: 21802). Rhowch gynnig arall arni",
    "error_21803": "Gwall: 21803. Rhowch gynnig arall arni",
    "error_21804": "Methodd y cais (cod gwall: 21804). Rhowch gynnig arall arni",
    "error_21806": "Gwall: 21806. Rhowch gynnig arall arni",
    "error_21807": "Gwall: 21807. Cysylltwch â ni",
    "error_21808": "Gwall: 21808. Cysylltwch â ni",
    "error_21809": "Gwall: 21809. Cysylltwch â ni",
    "error_21810": "Gwall: 21810. Cysylltwch â ni",
    "error_21811": "Gwall: 21811. Cysylltwch â ni",
    "error_21812": "Gwall: 21812. Cysylltwch â ni",
    "error_21813": "Methodd y cais (cod gwall: 21813). Rhowch gynnig arall arni",
    "error_21814": "Gwall: 21814. Cysylltwch â ni",
    "error_21815": "Methodd y cais (cod gwall: 21815). Rhowch gynnig arall arni",
    "error_21816": "Gwall: 21816. Cysylltwch â ni",
    "error_21817": "Gwall: 21817. Cysylltwch â ni",
    "error_21818": "Gwall: 21818. Cysylltwch â ni",
    "error_21819": "Methodd y cais (cod gwall: 21819). Rhowch gynnig arall arni",
    "error_21820": "Gwall: 21820. Cysylltwch â ni",
    "error_21821": "Gwall: 21821. Cysylltwch â ni",
    "error_21822": "Gwall: 21822. Cysylltwch â ni",
    "error_21823": "Methodd y cais (cod gwall: 21823). Rhowch gynnig arall arni",
    "error_21824": "Methodd y cais (cod gwall: 21824). Rhowch gynnig arall arni",
    "error_21825": "Methodd y cais (cod gwall: 21825). Rhowch gynnig arall arni",
    "error_21826": "Methodd y cais (cod gwall: 21826). Rhowch gynnig arall arni",
    "error_21901": "Gwall: 21901. Cysylltwch â ni",
    "error_21902": "Methodd y cais (cod gwall: 21902). Rhowch gynnig arall arni",
    "error_21903": "Mae statws y cyfrif wedi newid (cod gwall: 21903), adnewyddwch y dudalen a cheisiwch eto",
    "error_21904": "Gwall: 21904. Rhowch gynnig arall arni",
    "error_21905": "Gwall: 21905. Rhowch gynnig arall arni",
    "error_21906": "Methodd y cais (cod gwall: 21906). Rhowch gynnig arall arni",
    "error_21907": "Mae cyfrif Google wedi'i gysylltu â chyfrif arall",
    "error_21908": "Methodd y cais (cod gwall: 21908). Rhowch gynnig arall arni",
    "error_22001": "Methodd y cais (cod gwall: 22001). Rhowch gynnig arall arni",
    "error_22002": "Methodd y datgysylltu heb unrhyw fewngofnodi ychwanegol",
    "error_22003": "Methodd y cais (cod gwall: 22003). Rhowch gynnig arall arni",
    "error_22101": "Gwall: 22101. Cysylltwch â ni",
    "error_22102": "Mae statws y cyfrif wedi newid (cod gwall: 22102), adnewyddwch y dudalen a cheisiwch eto",
    "error_22103": "Methodd y cais (cod gwall: 22103). Rhowch gynnig arall arni",
    "error_22104": "Mae statws y cyfrif wedi newid (cod gwall: 22104), adnewyddwch y dudalen a cheisiwch eto",
    "error_22105": "Gwall: 22105. Rhowch gynnig arall arni",
    "error_22106": "Gwall: 22106. Rhowch gynnig arall arni",
    "error_22107": "Gwall: 22107. Cysylltwch â ni",
    "error_22108": "Methodd y cais (cod gwall: 22108). Rhowch gynnig arall arni",
    "error_22201": "Gwall: 22201. Cysylltwch â ni",
    "error_22202": "Dilysiad Annilys (Gwall: 22202). Rhowch gynnig arall arni.",
    "error_22203": "Gwall: 22203. Rhowch gynnig arall arni\"",
    "error_22204": "Methodd y cais (cod gwall: 22204). Rhowch gynnig arall arni",
    "error_22206": "Methodd y cais (cod gwall: 22206). Rhowch gynnig arall arni",
    "error_22401": "Gwall: 22401. Cysylltwch â ni",
    "error_22402": "Dilysiad Annilys (Gwall: 22402). Rhowch gynnig arall arni.",
    "error_22403": "Gwall: 22403. Rhowch gynnig arall arni",
    "error_22404": "Methodd y cais (cod gwall: 22404). Rhowch gynnig arall arni",
    "error_22405": "Mae cyfrif Facebook wedi'i gysylltu ag e-bost arall",
    "error_22406": "Gwall: 22406. Rhowch gynnig arall arni",
    "error_22407": "Gwall: 22407. Cysylltwch â ni",
    "error_22408": "Gwall: 22408. Cysylltwch â ni",
    "error_22409": "Gwall: 22409. Cysylltwch â ni",
    "error_22410": "Gwall: 224010. Cysylltwch â ni",
    "error_22411": "Gwall: 224011. Cysylltwch â ni",
    "error_22412": "Gwall: 224012. Cysylltwch â ni",
    "error_22413": "Gwall: 22413. Cysylltwch â ni",
    "error_22414": "Methodd y cais (cod gwall: 22414). Rhowch gynnig arall arni",
    "error_22415": "Gwall: 22415. Cysylltwch â ni",
    "error_22416": "Gwall: 22416. Cysylltwch â ni",
    "error_22417": "Gwall: 22417. Cysylltwch â ni",
    "error_22418": "Methodd y cais (cod gwall: 22418). Rhowch gynnig arall arni",
    "error_22419": "Gwall: 22419. Cysylltwch â ni",
    "error_22420": "Gwall: 22420. Cysylltwch â ni",
    "error_22421": "Gwall: 22421. Cysylltwch â ni",
    "error_22422": "Methodd y cais (cod gwall: 22422). Rhowch gynnig arall arni",
    "error_22423": "Methodd y cais (cod gwall: 22423). Rhowch gynnig arall arni",
    "error_22424": "Methodd y cais (cod gwall: 22424). Rhowch gynnig arall arni",
    "error_22425": "Methodd y cais (cod gwall: 22425). Rhowch gynnig arall arni",
    "error_20098": "Cod gwall: 20098. Os ydych yn pori yn y modd Preifat, newidiwch i'r modd Normal a cheisiwch eto.",
    "error_22298": "Methodd y cais am Google (Gwall: 22298). Rhowch gynnig arall arni.",
    "error_22498": "Methodd y cais am Facebook (Gwall: 22498). Rhowch gynnig arall arni.",
    "error_24902": "Methodd y cais (cod gwall: 24902). Rhowch gynnig arall arni",
    "error_24903": "Methodd y cais (cod gwall: 24903). Rhowch gynnig arall arni",
    "error_24904": "Methodd y cais (cod gwall: 24904). Rhowch gynnig arall arni",
    "error_24905": "Methodd y cais (cod gwall: 24905). Rhowch gynnig arall arni",
    "login_title": "Mewngofnodwch i MindOnMap",
    "log_in": "Mewngofnodi",
    "no_account": "Dim cyfrif?",
    "create_it": "Creu",
    "or_log_in_with": "Neu mewngofnodwch gyda",
    "passwordless_login": "Mewngofnodi heb gyfrinair",
    "log_in_done": "Mewngofnodi Wedi'i Wneud",
    "three_rd_account_connect_info": "Llongyfarchiadau! Rydych chi wedi mewngofnodi'n llwyddiannus. Nawr gallwch chi gysylltu un cyfrif e-bost a ddefnyddir i fewngofnodi yn y dyfodol.",
    "see_my_account": "Gweler fy nghyfrif",
    "three_rd_login_merge_account": "Mae cyfeiriad e-bost y cyfrif trydydd parti wedi'i gofrestru, a ydych chi am gysylltu a mewngofnodi gyda'r cyfeiriad e-bost hwn yn uniongyrchol?",
    "connect_log_in": "Cysylltu a Mewngofnodi",
    "create_an_account": "Creu cyfrif",
    "back_to_log_in": "Yn ôl i fewngofnodi",
    "create_password": "Creu cyfrinair",
    "create_now": "Creu Nawr",
    "password_login_subtitle": "Mewngofnodi heb gyfrinair gydag e-bost",
    "account_login": "Mewngofnodi Cyfrif",
    "rights": "Drwy greu'r cyfrif hwn, rydych yn cytuno â <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Telerau Gwasanaeth</a> a <a href=\"https://www.mindonmap.com/privacy-policy/\">Polisi Preifatrwydd</a>",
    "passwordless_login_done": "Mewngofnodi heb gyfrinair wedi'i wneud",
    "passwordless_login_info": "Llongyfarchiadau, rydych chi wedi cwblhau mewngofnodi heb gyfrinair yn llwyddiannus. Gallwch greu cyfrinair ar gyfer y cyfrif hwn a mewngofnodi gyda'r cyfrif a'r cyfrinair yn y dyfodol. <a href=\"/create-password\" style=\"display: initial;\">Creu Nawr</a>",
    "sign_up": "Cofrestru",
    "register_info": "Creu eich cyfrif",
    "reset_now": "Ailosod Nawr",
    "forgot_password": "Wedi anghofio Cyfrinair",
    "reset_password_subtitle": "Defnyddiwch e-bost eich cyfrif i ailosod cyfrinair",
    "plan_products": "Cynlluniau a Chynhyrchion",
    "nick_name": "Enw defnyddiwr:",
    "email": "E-bost:",
    "my_products": "Fy Cynhyrchion",
    "my_orders": "Fy Ngorchmynion",
    "unlink": "Datgysylltu",
    "link": "Cyswllt",
    "connected_accounts": "Cyfrifon cysylltiedig",
    "last_name": "Enw olaf:",
    "first_name": "Enw cyntaf:",
    "Gender": "Rhyw:",
    "Birth": "Genedigaeth:",
    "Month": "Mis",
    "Year": "Blwyddyn",
    "Country_Region": "Gwlad/Rhanbarth:",
    "Address": "Cyfeiriad:",
    "Save": "Arbed",
    "Date": "Dyddiad",
    "Male": "Gwryw",
    "Female": "Benyw",
    "Unspecified": "Amhenodol",
    "Security": "Diogelwch",
    "change_password": "Newid cyfrinair",
    "change_now": "Newidiwch Nawr",
    "connect_email": "Cyswllt e-bost",
    "delete_account": "Dileu Cyfrif",
    "delete_account_info": "Pan fydd eich cyfrif yn cael ei ddileu, bydd yr holl ddata yn Fy Nghyfrif sy'n gysylltiedig â'ch cyfrif yn cael ei ddileu yn barhaol, ac efallai na fyddwch yn gallu ei adennill. Rydym yn argymell eich bod yn troedio'n wyliadwrus.",
    "Delete": "Dileu",
    "Logout": "Allgofnodi",
    "my_profile": "Fy mhroffil",
    "guides_faqs": "Canllawiau a Chwestiynau Cyffredin",
    "More": "Mwy",
    "guides": "Tywyswyr",
    "register": "cofrestr",
    "hot_faq": "Cwestiynau Cyffredin poeth",
    "Contents": "Cynnwys:",
    "contact_us": "Cysylltwch â ni >>",
    "plan": "Cynllun",
    "unregistered": "Heb gofrestru",
    "buy_more": "Prynu Mwy",
    "buy_again": "Prynwch Eto",
    "buy_now": "Prynwch Nawr",
    "free_no_limit": "Am ddim a Dim terfyn",
    "expired": "Wedi dod i ben",
    "lifetime": "Oes",
    "remain": "Aros",
    "day_s": "diwrnod(au)",
    "error_24801": "Methodd y cais (cod gwall: 24801). Rhowch gynnig arall arni",
    "no_app_found": "Heb ganfod ap！ <a href=\"/\">Adnewyddu</a> neu <a href=\"https://www.mindonmap.com/\">Ewch i'r wefan swyddogol</a>",
    "get_more": "Cael Mwy >>",
    "edit_photo": "Golygu Llun",
    "select_photo": "Dewiswch lun",
    "change_photo": "Newid llun",
    "cancel": "Canslo",
    "hide_password": "Cuddio cyfrinair",
    "show_password": "Dangos cyfrinair",
    "zoom_in": "Chwyddo i mewn",
    "zoom_out": "Chwyddo allan",
    "rotate": "Cylchdroi",
    "horizontal_flip": "Fflip llorweddol",
    "vertical_flip": "Fflip fertigol",
    "country": "Gwlad",
    "country_1": "Dewiswch eich gwlad/rhanbarth",
    "country_2": "Åaland Ynys",
    "country_3": "Afghanistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "American Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarctica",
    "country_11": "Antigua & Barbuda",
    "country_12": "Ariannin",
    "country_13": "Armenia",
    "country_14": "Arwba",
    "country_15": "Awstralia",
    "country_16": "Awstria",
    "country_17": "Azerbaijan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarws",
    "country_22": "Gwlad Belg",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolifia",
    "country_28": "Bosnia a Herzegovina",
    "country_29": "Botswana",
    "country_30": "Ynys Bouvet",
    "country_31": "Brasil",
    "country_32": "Tiriogaeth Brydeinig Indiaidd Ocean",
    "country_33": "Ynysoedd Prydain",
    "country_34": "Brunei",
    "country_35": "Bwlgaria",
    "country_36": "Burcina",
    "country_37": "Burundi",
    "country_38": "Cambodia",
    "country_39": "Camerŵn",
    "country_40": "Canada",
    "country_41": "Cape Verde",
    "country_42": "Caribïaidd yr Iseldiroedd",
    "country_43": "Ynysoedd y Cayman",
    "country_44": "Gweriniaeth ganol Affrican",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "Tsieina",
    "country_48": "Ynys Nadolig",
    "country_49": "Cocos (Keeling) Ynysoedd",
    "country_50": "Colombia",
    "country_51": "Ynysoedd Coginio",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Croatia",
    "country_55": "Ciwba",
    "country_56": "Cyprus",
    "country_57": "Gweriniaeth Tsiec",
    "country_58": "Gweriniaeth ddemocrataidd y Congo",
    "country_59": "Denmarc",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Gweriniaeth Dominicaidd",
    "country_63": "Ecuador",
    "country_64": "yr Aifft",
    "country_65": "El Salfador",
    "country_66": "Gyhydeddol Gini",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Ethiopia",
    "country_70": "Ynysoedd y Falkland",
    "country_71": "Ynysoedd Faroe",
    "country_72": "Taleithiau Ffederal Micronesia",
    "country_73": "Ffiji",
    "country_74": "Ffindir",
    "country_75": "Ffrainc",
    "country_76": "Ffrangeg Guiana",
    "country_77": "Ffrangeg polynesia",
    "country_78": "Tiriogaethau De Ffrainc",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Almaen",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Prydain Fawr (Teyrnas Unedig; Lloegr)",
    "country_86": "Groeg",
    "country_87": "Yr Ynys Las",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Gwatemala",
    "country_92": "Guernsey",
    "country_93": "Gini",
    "country_94": "Gini-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Ynysoedd Heard ac McDonald  Islands",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hwngari",
    "country_101": "Gwlad yr Iâ",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Irac",
    "country_106": "Iwerddon",
    "country_107": "Ynys Man",
    "country_108": "Israel",
    "country_109": "Eidal",
    "country_110": "Jamaica",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Iorddonen",
    "country_114": "Casachstan",
    "country_115": "Cenia",
    "country_116": "Ciribati",
    "country_117": "Kuwait",
    "country_118": "Kyrgyzstan",
    "country_119": "Laos",
    "country_120": "Latfia",
    "country_121": "Libanus",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libya",
    "country_125": "Liechtenstein",
    "country_126": "Lithwania",
    "country_127": "Lwcsembwrg",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "ynysoedd Marshall",
    "country_136": "Martinique",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mecsico",
    "country_141": "Moldofa",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Morocco",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Iseldiroedd",
    "country_153": "Caledonia Newydd",
    "country_154": "Seland Newydd",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Ynys Norfolk",
    "country_160": "Gogledd Corea",
    "country_161": "Ynysoedd Gogledd Mariana",
    "country_162": "Norwy",
    "country_163": "Oman",
    "country_164": "Pacistan",
    "country_165": "Palau",
    "country_166": "tiriogaethau Palestina",
    "country_167": "Panama",
    "country_168": "Papwa Gini Newydd",
    "country_169": "Paraguay",
    "country_170": "Periw",
    "country_171": "Ynysoedd Pitcairn",
    "country_172": "Gwlad Pwyl",
    "country_173": "Portiwgal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Gweriniaeth Macedonia (FYROM)",
    "country_177": "Gweriniaeth y Congo",
    "country_178": "Réunion",
    "country_179": "Rwmania",
    "country_180": "Ffederasiwn Rwseg",
    "country_181": "Rwanda",
    "country_182": "Sant Barthélemy",
    "country_183": "Sant Martin (Ffrainc)",
    "country_184": "Saint-Pierre a Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Saudi Arabia",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapôr",
    "country_194": "Slofacia",
    "country_195": "Slofenia",
    "country_196": "Ynysoedd Solomon",
    "country_197": "Somalia",
    "country_198": "De Affrica",
    "country_199": "De Georgia a Phrandwich De Isla",
    "country_200": "De Corea",
    "country_201": "De Swdan",
    "country_202": "Sbaen",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena & Dibyniaethau",
    "country_205": "St. Kitts a Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent a y Grenadines",
    "country_208": "Swdan",
    "country_209": "Suriname",
    "country_210": "Gwlad Swazi",
    "country_211": "Sweden",
    "country_212": "Swistir",
    "country_213": "Syria",
    "country_214": "Taiwan",
    "country_215": "Tajicistan",
    "country_216": "Tanzania",
    "country_217": "Templed:data gwlad SJM Svalbard",
    "country_218": "Gwlad Thai",
    "country_219": "Y Bahamas",
    "country_220": "Y Comoros",
    "country_221": "Y Philippiaid",
    "country_222": "Timor-Leste (Dwyrain Timor)",
    "country_223": "I fynd",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad a Tobago",
    "country_227": "Tiwnisia",
    "country_228": "Twrci",
    "country_229": "Tyrcmenistan",
    "country_230": "Ynysoedd Tyrciaid & Caicos",
    "country_231": "Twfalw",
    "country_232": "Uganda",
    "country_233": "Wcráin",
    "country_234": "Emiradau Arabaidd Unedig",
    "country_235": "Ynysoedd pellach yr Unol Dalaethau",
    "country_236": "Taleithiau Unedig America (UDA)",
    "country_237": "Ynysoedd y Wyryf yn yr Unol Daleithiau",
    "country_238": "Uruguay",
    "country_239": "Wsbecistan",
    "country_240": "Vanuatu",
    "country_241": "Dinas y Fatican (Y Gwel Sanctaidd)",
    "country_242": "Feneswela",
    "country_243": "Fietnam",
    "country_244": "Wallis a Futuna",
    "country_245": "Gorllewin Sahara",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Mewngofnodwch gyda Google",
    "State": "Cyflwr",
    "Activation_code": "Cod actifadu",
    "Question": "Rhestrwch yr holl apiau rydych chi wedi mewngofnodi",
    "Copy_complete": "Copi wedi'i gwblhau",
    "footer": "Hawlfraint © 2024 MindOnMap Studio. Cedwir pob hawl.",
    "change_password_success": "Cyfrinair wedi newid yn llwyddiannus",
    "successful_login_title": "Llwyddiant Mewngofnodi",
    "product_page": "Tudalen Cynnyrch >>",
    "successful_login_info": "Cwblhawyd y mewngofnodi. Caewch y dudalen gyfredol ac ewch yn ôl i'r tab gwreiddiol i barhau â'r broses ganlynol. Bydd y dudalen gyfredol yn cael ei chau'n awtomatig mewn 5 eiliad. Os bydd cau'n awtomatig neu gau trwy glicio ar y botwm \"Gwneud\" yn methu, caewch y tab hwn yn uniongyrchol.",
    "successful_login_info_firefox": "Cwblhawyd y mewngofnodi. Caewch y dudalen gyfredol ac ewch yn ôl i'r tab gwreiddiol i barhau â'r broses ganlynol.",
    "my_account": "Fy nghyfrif",
    "my_history": "Fy Hanes",
    "remove_watermark": "Dileu Dyfrnod",
    "no_history": "Dim Hanes",
    "history_all": "Dewiswch bob un",
    "history_open": "Agor",
    "history_down": "Lawrlwythwch",
    "history_delete": "Dileu",
    "history_clear": "Clirio annilys",
    "images": "delwedd(au)",
    "use_this_function": "Defnyddiwch y Cynnyrch hwn >>",
    "hd_downloading": "Buddiannau lawrlwytho delweddau gwreiddiol HD:",
    "lifetimeRemaining": "Oes-Gweddill",
    "Remaining": "Gweddill",
    "email_verification": "Dilysu E-bost",
    "email_verification_info": "Rydym wedi anfon y neges dilysu i'ch e-bost <span class=\"email\"></span>, a gorffennwch y dilysiad. Ar ôl dilysu, bydd y buddion yn cael eu cysoni'n awtomatig.",
    "wrong_email": "Cyfeiriad e-bost anghywir?",
    "click_here_to_modify": "Cliciwch yma i addasu",
    "get_more_help": "Cael mwy o help?",
    "click_here": "Cliciwch yma",
    "email_verification_info_success": "Llongyfarchiadau ar wirio eich cyfrif e-bost.",
    "email_verification_info_error": "Methodd y dilysu oherwydd bod y ddolen wedi dod i ben.",
    "registration_succeeded": "Llwyddwyd i gofrestru",
    "registration_succeeded_info_1": "Llongyfarchiadau! Rydych chi wedi cofrestru'n llwyddiannus. Rydym wedi anfon y neges ddilysu i'ch e-bost <span class=\"email\"></span>, a gorffennwch y dilysu i ddefnyddio manteision yr e-bost hwn.",
    "registration_succeeded_info_2": "Cliciwch \"Done\" i ddychwelyd i'r dudalen Hafan a defnyddio'r cynnyrch hwn.",
    "registration_succeeded_info_3": "Cliciwch \"Done\" i gau'r dudalen gyfredol a dychwelyd i'r dudalen Hafan ar gyfer y gweithrediadau canlynol. Os bydd cau'n methu, caewch y tab â llaw.",
    "verify_email": "Dilysu E-bost",
    "registered_email_not_verified": "Nid yw'r e-bost cofrestredig wedi'i wirio, gwiriwch ef ar unwaith.",
    "email_verification_time_1": "Heb dderbyn e-bost dilysu?",
    "email_verification_time_2": "Wedi",
    "email_verification_time_3": "Cliciwch yma i'w ail-anfon",
    "error_26301": "Cod gwall: 26301, cysylltwch â ni",
    "error_26302": "Cod gwall: 26302, cysylltwch â ni",
    "error_26303": "Gwall fformat e-bost (cod gwall: 26303). Rhowch ef eto",
    "error_26304": "Argymhellir cyfrinair dros 8 nod (cod gwall: 26304)",
    "error_26305": "Methodd Reuqest (cod gwall: 26305). Rhowch gynnig arall arni",
    "error_26306": "Mae'r e-bost wedi'i gofrestru, <a href=\"https://account.mindonmap.com/login/\">ewch i fewngofnodi</a>",
    "error_26307": "Methodd Reuqest (cod gwall: 26307). Rhowch gynnig arall arni",
    "error_26308": "Methodd Reuqest (cod gwall: 26308). Rhowch gynnig arall arni",
    "error_26401": "Cod gwall: 26401, rhowch gynnig arall arni",
    "error_26402": "Mae'r e-bost wedi'i wirio (cod gwall: 26402), rhowch gynnig arall arni",
    "error_26403": "Methodd Reuqest (cod gwall: 26403). Rhowch gynnig arall arni",
    "error_26404": "Methodd y cais (cod gwall: 26404). Rhowch gynnig arall arni",
    "error_26501": "Cod gwall: 26501, cysylltwch â ni",
    "error_26502": "Cod gwall: 26502, cysylltwch â ni",
    "error_26503": "Gwall fformat e-bost (cod gwall: 26503). Rhowch ef eto",
    "error_26504": "Methodd Reuqest (cod gwall: 26504). Rhowch gynnig arall arni",
    "error_26505": "Nid yw'r e-bost wedi'i gofrestru, <a href=\"https://account.mindonmap.com/register/\">cofrestrwch yn gyntaf</a>",
    "error_26506": "Mae'r e-bost wedi'i wirio.",
    "error_26507": "Methodd Reuqest (cod gwall: 26507). Rhowch gynnig arall arni",
    "error_26508": "Gwiriad wedi'i ffeilio (cod gwall: 26508), rhowch gynnig arall arni",
    "error_26509": "Methodd y cais (cod gwall: 26509), rhowch gynnig arall arni",
    "error_26510": "Cod gwall: 26510, cysylltwch â ni",
    "error_26601": "Cod gwall: 26601, cysylltwch â ni",
    "error_26602": "Cod gwall: 26602, cysylltwch â ni",
    "error_26603": "Methodd Reuqest (cod gwall: 26603). Rhowch gynnig arall arni",
    "error_26604": "Cod gwall: 26604, cysylltwch â ni",
    "error_26605": "Cod gwall: 26605, cysylltwch â ni",
    "error_26701": "Cod gwall: 26701, cysylltwch â ni",
    "error_26702": "Methodd Reuqest (cod gwall: 26702). Rhowch gynnig arall arni",
    "error_26703": "Cod gwall: 26703, cysylltwch â ni",
    "error_26704": "Cod gwall: 26704, cysylltwch â ni",
    "error_26705": "Arhoswch i fewngofnodi (cod gwall: 26705). Rhowch gynnig arall arni",
    "no_cookie": "Rydych chi wedi troi'r swyddogaeth Blocio pob cwci ymlaen yn eich porwr, felly ni allwch fewngofnodi. Ewch i Gosodiadau i wirio'r blwch o Caniatáu pob cwci.",
    "error_26801": "Cod gwall: 26801, cysylltwch â ni",
    "error_26802": "Cod gwall: 26802, cysylltwch â ni",
    "error_26803": "Methodd y cais (cod gwall: 26803). Rhowch gynnig arall arni",
    "error_26804": "Methodd y cais (cod gwall: 26804). Rhowch gynnig arall arni",
    "error_order": "Methodd y cais (cod gwall: 27098), rhowch gynnig arall arni!",
    "error_order1": "Mae'r ymholiad archeb yn anghyflawn （cod gwall: ",
    "error_order2": "）, adnewyddwch a rhowch gynnig arall arni.",
    "modify_email_title": "Addasu E-bost",
    "modify_email_info": "Gallwch ddefnyddio'r e-bost wedi'i addasu i fewngofnodi i'ch cyfrif.",
    "images_per": "Delweddau fesul",
    "error_26101": "Gwall: 26101. Cysylltwch â ni",
    "error_26102": "Gwall: 26102. Cysylltwch â ni",
    "error_26103": "Methodd y cais (cod gwall:26103). Ceisiwch e eto",
    "error_26104": "Cod gwall: 26104, rhowch gynnig arall arni",
    "error_26105": "Cod gwall: 26105, rhowch gynnig arall arni",
    "error_26106": "Wedi methu dileu (cod gwall: 26106). Rhowch gynnig arall arni",
    "error_26201": "Gwall: 26201. Cysylltwch â ni",
    "error_26202": "Methodd y cais (cod gwall:26202). Ceisiwch e eto",
    "error_26001": "Gwall: 26001. Cysylltwch â ni",
    "error_26002": "Gwall: 26002. Cysylltwch â ni",
    "error_26003": "Gwall: 26003. Cysylltwch â ni",
    "error_26004": "Gwall: 26004. Cysylltwch â ni",
    "error_26005": "Methodd y cais (cod gwall:26005). Ceisiwch e eto",
    "error_26006": "Cod gwall: 26006, rhowch gynnig arall arni",
    "error_26008": "Gwall: 26008. Cysylltwch â ni",
    "go_to_the_home_page": "Ewch i'r dudalen Cartref",
    "error_27101": "Methodd y cais (cod gwall: 27101). Rhowch gynnig arall arni",
    "error_27201": "Cod gwall: 27201, cysylltwch â ni",
    "error_27202": "Cod gwall: 27202, rhowch gynnig arall arni",
    "error_27203": "Methodd y cais (cod gwall: 27203). Rhowch gynnig arall arni",
    "error_27204": "Cod annilys (cod gwall: 27204).",
    "error_27205": "Methodd y cais (cod gwall: 27205). Rhowch gynnig arall arni",
    "error_27206": "Methodd y cais (cod gwall: 27206). Rhowch gynnig arall arni",
    "error_27207": "Methodd y cais (cod gwall: 27207). Rhowch gynnig arall arni",
    "no_history_found": "Nid ydych wedi defnyddio unrhyw declyn! <a href=\"/\">Adnewyddu</a> neu <a href=\"https://www.mindonmap.com/\">Ewch i'r wefan swyddogol</a>",
    "error_25301": "Gwall: 25301. Cysylltwch â ni",
    "error_25302": "Gwall: 25302. Cysylltwch â ni",
    "error_25303": "Methodd y cais (cod gwall: 25303). Rhowch gynnig arall arni",
    "error_25304": "Methodd y cais (cod gwall: 25304). Rhowch gynnig arall arni",
    "error_25305": "Methodd y cais (cod gwall: 25305). Rhowch gynnig arall arni",
    "error_25306": "Methodd y cais (cod gwall: 25306). Rhowch gynnig arall arni",
    "image_upscaler_p": "Delwedd heb fuddion lawrlwytho dyfrnod:",
    "Available_for": "Ar gael ar gyfer:",
    "credit_per": "%s credyd am bob delwedd HD",
    "still_valid": "Mae cynllun(iau) a brynwyd yn dal yn ddilys",
    "credit": "credyd(au)",
    "pc_3rd_info": "Mewngofnodwch yn llwyddiannus. Ewch i'r cais am weithrediad pellach.",
    "use_online": "Defnyddiwch y gwasanaeth ar-lein",
    "use_download": "Defnyddiwch y rhaglen bwrdd gwaith",
    "use_immediately": "Defnyddiwch Ar unwaith",
    "Use_in_browser": "Defnyddiwch yn y Porwr",
    "win_desktop": "Ffenestri",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Credydau y Mis",
    "expire": "Amser dod i ben:",
    "viewDetails": "Gweld manylion",
    "viewHistory": "Gweld manteision hanes >>",
    "viewDetailsInfo": "Hysbysiad: Os caiff y buddion tanysgrifio eu hadnewyddu o fewn 7 diwrnod ar ôl dod i ben, gellir parhau i ddefnyddio'r buddion nas defnyddiwyd. Hefyd, bydd yr amser dod i ben yn cael ei ddiweddaru'n awtomatig i amser dod i ben y tanysgrifiad newydd. Os nad oes tanysgrifiad newydd ar ôl 7 diwrnod o amser dod i ben, bydd yr holl fuddion sydd wedi dod i ben yn cael eu clirio.",
    "connect_account": "Rhwymwch E-bost i'ch Cyfrif",
    "connect_account_info": "Ar ôl rhwymo, gallwch fewngofnodi gyda'r cyfeiriad e-bost hwn.",
    "connect_now": "Rhwymwch Nawr",
    "no_email_bind": "Dim rhwymiad e-bost",
    "bind_email": "Rhwymo E-bost",
    "connect_your_email_placeholder": "Rhowch eich cyfeiriad e-bost",
    "bind_an_email": "Rhwymwch E-bost",
    "bind_info": "Rydych chi wedi mewngofnodi'n llwyddiannus. Rhwymwch e-bost i'ch cyfrif i actifadu eich buddion.",
    "bind_later": "Rhwymwch yn ddiweddarach",
    "hi": "Ei, %s!",
    "Personal_Information": "Gwybodaeth personol",
    "Access": "Mynediad",
    "Subscription_Plan": "(Cynllun Tanysgrifio)",
    "No_orders": "Ni chanfuwyd unrhyw orchmynion.",
    "No_data": "Dim data",
    "Featured_Products": "Cynhyrchion Sylw",
    "More_Products": "Mwy o Gynhyrchion",
    "Free_Download": "Lawrlwythiad Am Ddim",
    "Get_Started": "Dechrau",
    "Subscribe": "Tanysgrifio",
    "Verified": "Wedi'i wirio",
    "back_to_account_center": "Yn ôl i'r Ganolfan Gyfrifon",
    "success": "Llwyddiant!",
    "successfully": "Rydych wedi cofrestru cyfrif yn llwyddiannus.",
    "Continue": "Parhau",
    "Already": "Oes gennych chi gyfrif yn barod?",
    "loading_verification": "Wrthi'n gwirio statws dilysu...",
    "email_no_verification": "Mae'n ddrwg gennym, nid yw'r cyfeiriad e-bost cofrestredig wedi'i wirio. Cwblhewch y dilysiad yn unol â'r cyfarwyddiadau uchod a chliciwch ar y botwm \"Gwneud\" eto i gwblhau'r cofrestriad.",
    "will_expire_after": "Bydd yn dod i ben ar ôl",
    "hours": "oriau",
  },
  "da": {
    "overtime": "Fejlkode: {%}, prøv venligst igen",
    "isnetwork": "Internetfejl. Tjek venligst og prøv igen",
    "email_placeholder": "E-mail",
    "email_empty": "Indtast venligst e-mail",
    "email_not_valid": "E-mail er ikke gyldig",
    "email_not_valid_1": "Indtast venligst din e-mail",
    "email_not_valid_2": "E-mail er ikke gyldig, brug venligst en anden adresse.",
    "email_not_valid_3": "Ingen e-mail input",
    "password_placeholder": "Adgangskode",
    "password_empty": "Indtast venligst adgangskode",
    "password_not_valid": "Forkert konto eller adgangskode",
    "password_not_valid_1": "Adgangskode over 8 tegn er påkrævet",
    "password_not_valid_2": "Opret venligst en adgangskode",
    "password_placeholder_1": "Opret din adgangskode",
    "password_placeholder_2": "Bekræft dit kodeord",
    "password_placeholder_3": "Opret ny adgangskode",
    "password_placeholder_4": "Bekræft ny adgangskode",
    "password_placeholder_5": "Indtast gammel adgangskode",
    "copy_password_empty": "Bekræft venligst adgangskoden",
    "copy_password_not_valid": "Bekræft venligst din adgangskode",
    "copy_passwords_inconsistent": "Din adgangskodebekræftelse stemmer ikke overens",
    "code_empty": "Indtast venligst bekræftelseskode",
    "code_not_valid": "Ugyldig bekræftelseskode",
    "code_placeholder": "Verifikationskode",
    "not_received_code": "Hvis din postkasse ikke har modtaget bekræftelseskoden i lang tid, bedes du få bekræftelseskoden igen.",
    "get_first_code": "Hent venligst bekræftelseskoden først.",
    "last_name_placeholder": "Indtast venligst dit efternavn",
    "first_name_placeholder": "Indtast venligst dit fornavn",
    "address_placeholder": "Indtast venligst din adresse",
    "no_code_text": "Vi har sendt en bekræftelseskode. Indtast venligst din kode. <span class='tips'>Har du ikke modtaget en kode?",
    "no_code_text_1": "1. Sørg for, at e-mailadressen er gyldig, og at den kan modtage e-mails.",
    "no_code_text_2": "2. Da e-mailen sendes automatisk af systemet, kan den blive markeret som spam eller uønsket e-mail. Kontroller, om e-mailen er i papirkurven.",
    "no_code_text_3": "3. Kan du ikke løse dit problem? ",
    "no_code_text_3_span": "Så klik her for at kontakte os.",
    "order_no": "Du har ikke købt noget produkt. Hvis du har spørgsmål, bedes du <a href=\"https://www.mindonmap.com/contact-us/\">kontakte os</a>.",
    "error_24901": "Den nuværende konto har ingen e-mail knyttet og kan ikke finde ordrerne. Tilslut venligst en e-mail.",
    "user_guide": "Brugervejledning >>",
    "download": "Hent",
    "order_number": "Ordrenummer:",
    "Refund": "Tilbagebetale",
    "Disabled": "handicappet",
    "Normal": "Normal",
    "Modify": "Modificere",
    "Modify_1": "Ændre >>",
    "Connect": "Forbinde",
    "unlink_success": "Fjern linket",
    "connect_success": "Forbind med succes",
    "feedback_title": "tak for din tilbagemelding. Forlad venligst dit problem, og vi vil svare dig inden for 24 timer.",
    "feedback_thank_you": "Tak!<br />Din feedback er blevet sendt.",
    "feedback_email": "Indtast din e-mail her!",
    "feedback_content": "Efterlad ethvert problem eller forslag, du stødte på, her.",
    "feedback_submit": "Indsend",
    "form_contents": "Du har ikke indtastet nogen beskrivelse. Indtast den og send den igen.",
    "old_password": "Indtast venligst gammel adgangskode",
    "new_password": "Opret venligst en ny adgangskode",
    "old_new_password": "Den nye adgangskode kan ikke være den samme som den gamle",
    "incorrect_password": "forkert kodeord",
    "delete_no": "Slet nu",
    "Caps": "Caps Lock er på",
    "Get": "Få",
    "Done": "Færdig",
    "error_20001": "Fejl: 20001. Log venligst ind igen.",
    "error_20002": "Fejl: 20002. Log venligst på igen.",
    "error_20003": "Fejl: 20003. Log venligst på igen.",
    "error_20004": "Forespørgsel mislykkedes (fejlkode: 20004). Prøv det igen.",
    "error_20005": "Login-sessionen er udløbet (fejl: 20005). Log venligst ind igen.",
    "error_20006": "Forespørgsel mislykkedes (fejlkode: 20006). Prøv det igen.",
    "error_20007": "Login-session er udløbet (fejl: 20007). Log venligst ind igen.",
    "error_20008": "Login session er udløbet (fejl: 20008). Log venligst ind igen.",
    "error_20009": "Login session er udløbet (fejl: 20009). Log venligst ind igen.",
    "error_20101": "Indtast venligst din e-mail (fejlkode: 20101)",
    "error_20102": "E-mail er ikke gyldig (fejlkode: 20102)",
    "error_20103": "Forespørgsel mislykkedes (fejlkode: 20103). Prøv det igen",
    "error_20104": "E-mail er allerede i brug, venligst <a href=\"https://account.mindonmap.com/login/\">log ind</a> eller tilmeld dig med en ny",
    "error_20105": "Forespørgsel mislykkedes (fejlkode: 20105). Prøv det igen",
    "error_20106": "Kunne ikke sende e-mail. Prøv venligst igen",
    "error_20201": "Indtast venligst din e-mail (fejlkode: 20201)",
    "error_20202": "Indtast venligst din adgangskode (fejlkode: 20202)",
    "error_20203": "Indtast venligst bekræftelseskode (fejlkode: 20203)",
    "error_20204": "E-mail er ikke gyldig (fejlkode: 20204)",
    "error_20205": "Adgangskode over 8 tegn er påkrævet (fejlkode: 20205)",
    "error_20206": "Forespørgsel mislykkedes (fejlkode: 20206). Prøv det igen",
    "error_20207": "Ugyldig bekræftelseskode",
    "error_20208": "Forespørgsel mislykkedes (fejlkode: 20208). Prøv det igen",
    "error_20209": "Forespørgsel mislykkedes (fejlkode: 20209). Prøv det igen",
    "error_20301": "Indtast venligst din e-mail (fejlkode: 20301)",
    "error_20302": "Fejl: 20302. Kontakt os venligst",
    "error_20303": "E-mail er ikke gyldig (fejlkode: 20303)",
    "error_20304": "Forespørgsel mislykkedes (fejlkode: 20304). Prøv det igen",
    "error_20305": "Kontoen findes ikke. Indtast venligst igen eller <a href=\"https://account.mindonmap.com/register/\">Opret det</a> først.",
    "error_20306": "Ingen adgangskode endnu. Brug <a href=\"https://account.mindonmap.com/passwordless-login/\">Login uden adgangskode</a> eller <a href=\"https://account.mindonmap.com/create-password/\"> sæt en adgangskode</a> og log ind.",
    "error_20308": "Forespørgsel mislykkedes (fejlkode: 20308). Prøv det igen",
    "error_20401": "Kunne ikke logge ud (fejlkode: 20401). Prøv det igen",
    "error_20501": "Indtast venligst din e-mail (fejlkode: 20501)",
    "error_20502": "E-mail er ikke gyldig (fejlkode: 20502)",
    "error_20503": "Forespørgsel mislykkedes (fejlkode: 20503). Prøv det igen",
    "error_20504": "Kunne ikke sende e-mail. Prøv det igen.",
    "error_20601": "Indtast venligst din e-mail (fejlkode: 20601)",
    "error_20602": "Indtast venligst bekræftelseskode (fejlkode: 20602)",
    "error_20603": "E-mail er ikke gyldig (fejlkode: 20603)",
    "error_20604": "Forespørgsel mislykkedes (fejlkode: 20604). Prøv det igen",
    "error_20606": "Forespørgsel mislykkedes (fejlkode: 20606). Prøv det igen",
    "error_20607": "Forespørgsel mislykkedes (fejlkode: 20607). Prøv det igen",
    "error_20608": "Forespørgsel mislykkedes (fejlkode: 20608). Prøv det igen",
    "error_20701": "Indtast venligst din e-mail (fejlkode: 20701)",
    "error_20702": "E-mail er ikke gyldig (fejlkode: 20702)",
    "error_20703": "Forespørgsel mislykkedes (fejlkode: 20703). Prøv det igen",
    "error_20704": "Kontoen findes ikke. Indtast venligst igen eller <a href=\"https://account.mindonmap.com/register/\">Opret det</a> først.",
    "error_20705": "Forespørgsel mislykkedes (fejlkode: 20705). Prøv det igen",
    "error_20706": "Kunne ikke sende e-mail. Prøv det igen",
    "error_20801": "Indtast venligst din e-mail (fejlkode: 20801)",
    "error_20802": "Fejl: 20802. Kontakt os venligst",
    "error_20803": "Indtast venligst bekræftelseskode (fejlkode: 20803)",
    "error_20804": "E-mail er ikke gyldig (fejlkode: 20804)",
    "error_20805": "Adgangskode over 8 tegn er påkrævet (fejlkode: 20805)",
    "error_20806": "Forespørgsel mislykkedes (fejlkode: 20806). Prøv det igen",
    "error_20808": "Forespørgsel mislykkedes (fejlkode: 20808). Prøv det igen",
    "error_20901": "Forespørgsel mislykkedes (fejlkode: 20901). Prøv det igen",
    "error_20902": "Forespørgsel mislykkedes (fejlkode: 20902). Prøv det igen",
    "error_21000": "Ændringer gemmes",
    "error_21001": "Ingen oplysninger er indsendt (fejlkode: 21001)",
    "error_21002": "Forespørgsel mislykkedes (fejlkode: 21002). Prøv det igen",
    "error_21101": "Indtast venligst din e-mail (fejlkode: 21101)",
    "error_21102": "E-mail er ikke gyldig (fejlkode: 21102)",
    "error_21103": "Anmodningen mislykkedes (fejlkode: 21103), prøv venligst igen",
    "error_21104": "E-mailen er allerede tilsluttet. Brug venligst en ny",
    "error_21105": "Anmodningen mislykkedes (fejlkode: 21105), prøv venligst igen",
    "error_21106": "Kunne ikke sende e-mail. Prøv det igen",
    "error_21201": "Indtast venligst din e-mail (fejlkode: 21201)",
    "error_21202": "Indtast venligst bekræftelseskode (fejlkode: 21202)",
    "error_21203": "E-mail er ikke gyldig (fejlkode: 21203)",
    "error_21204": "Fejl: 21204. Kontakt os venligst",
    "error_21205": "Fejl: 21205. Kontakt os venligst",
    "error_21206": "Adgangskode over 8 tegn er påkrævet (fejlkode: 21206)",
    "error_21207": "Forespørgsel mislykkedes (fejlkode: 21207). Prøv det igen",
    "error_21209": "Forespørgsel mislykkedes (fejlkode: 21209). Prøv det igen",
    "error_21301": "Indtast venligst gammel adgangskode (fejlkode: 21301)",
    "error_21302": "Opret en ny adgangskode (fejlkode: 21302)",
    "error_21303": "Den nye adgangskode kan ikke være den samme som den gamle. (Fejl: 21303)",
    "error_21304": "Adgangskode over 8 tegn er påkrævet (fejlkode: 21304)",
    "error_21306": "Forespørgsel mislykkedes (fejlkode: 21306). Prøv det igen",
    "error_21402": "Forespørgsel mislykkedes (fejlkode: 21402). Prøv det igen",
    "error_21403": "Kunne ikke sende bekræftelseskoden. Send det venligst igen",
    "error_21500": "Kontoen er blevet slettet",
    "error_21501": "Indtast venligst bekræftelseskode (fejlkode: 21501)",
    "error_21502": "Login-session er udløbet (fejl: 21502). Log venligst ind igen.",
    "error_21503": "Forespørgsel mislykkedes (fejlkode: 21503). Prøv det igen",
    "error_21505": "Anmodningen mislykkedes (fejlkode: 21505), prøv venligst igen",
    "error_21601": "Fejl: 20601. Kontakt os venligst",
    "error_21602": "Ugyldig bekræftelse (fejl: 20602). Prøv det igen.",
    "error_21603": "Fejl: 20603. Prøv det igen",
    "error_21604": "Forespørgsel mislykkedes (fejlkode: 21604). Prøv det igen",
    "error_21606": "Forespørgsel mislykkedes (fejlkode: 21606). Prøv det igen",
    "error_21611": "Forespørgsel mislykkedes (fejlkode: 21611). Prøv det igen",
    "error_21801": "Fejl: 21801. Kontakt os venligst",
    "error_21802": "Forespørgsel mislykkedes (fejl: 21802). Prøv det igen",
    "error_21803": "Fejl: 21803. Prøv det igen",
    "error_21804": "Forespørgsel mislykkedes (fejlkode: 21804). Prøv det igen",
    "error_21806": "Fejl: 21806. Prøv det igen",
    "error_21807": "Fejl: 21807. Kontakt os venligst",
    "error_21808": "Fejl: 21808. Kontakt os venligst",
    "error_21809": "Fejl: 21809. Kontakt os venligst",
    "error_21810": "Fejl: 21810. Kontakt os venligst",
    "error_21811": "Fejl: 21811. Kontakt os venligst",
    "error_21812": "Fejl: 21812. Kontakt os venligst",
    "error_21813": "Forespørgsel mislykkedes (fejlkode: 21813). Prøv det igen",
    "error_21814": "Fejl: 21814. Kontakt os venligst",
    "error_21815": "Forespørgsel mislykkedes (fejlkode: 21815). Prøv det igen",
    "error_21816": "Fejl: 21816. Kontakt os venligst",
    "error_21817": "Fejl: 21817. Kontakt os venligst",
    "error_21818": "Fejl: 21818. Kontakt os venligst",
    "error_21819": "Forespørgsel mislykkedes (fejlkode: 21819). Prøv det igen",
    "error_21820": "Fejl: 21820. Kontakt os venligst",
    "error_21821": "Fejl: 21821. Kontakt os venligst",
    "error_21822": "Fejl: 21822. Kontakt os venligst",
    "error_21823": "Forespørgsel mislykkedes (fejlkode: 21823). Prøv det igen",
    "error_21824": "Forespørgsel mislykkedes (fejlkode: 21824). Prøv det igen",
    "error_21825": "Forespørgsel mislykkedes (fejlkode: 21825). Prøv det igen",
    "error_21826": "Forespørgsel mislykkedes (fejlkode: 21826). Prøv det igen",
    "error_21901": "Fejl: 21901. Kontakt os venligst",
    "error_21902": "Forespørgsel mislykkedes (fejlkode: 21902). Prøv det igen",
    "error_21903": "Kontoens status er ændret (fejlkode: 21903), opdater venligst siden og prøv igen",
    "error_21904": "Fejl: 21904. Prøv det igen",
    "error_21905": "Fejl: 21905. Prøv det igen",
    "error_21906": "Forespørgsel mislykkedes (fejlkode: 21906). Prøv det igen",
    "error_21907": "Google-konto er blevet knyttet til en anden konto",
    "error_21908": "Forespørgsel mislykkedes (fejlkode: 21908). Prøv det igen",
    "error_22001": "Forespørgsel mislykkedes (fejlkode: 22001). Prøv det igen",
    "error_22002": "Afkobling mislykkedes uden yderligere login",
    "error_22003": "Forespørgsel mislykkedes (fejlkode: 22003). Prøv det igen",
    "error_22101": "Fejl: 22101. Kontakt os venligst",
    "error_22102": "Kontoens status er ændret (fejlkode: 22102), opdater venligst siden og prøv igen",
    "error_22103": "Forespørgsel mislykkedes (fejlkode: 22103). Prøv det igen",
    "error_22104": "Kontoens status er ændret (fejlkode: 22104), opdater venligst siden og prøv igen",
    "error_22105": "Fejl: 22105. Prøv det igen",
    "error_22106": "Fejl: 22106. Prøv det igen",
    "error_22107": "Fejl: 22107. Kontakt os venligst",
    "error_22108": "Forespørgsel mislykkedes (fejlkode: 22108). Prøv det igen",
    "error_22201": "Fejl: 22201. Kontakt os venligst",
    "error_22202": "Ugyldig bekræftelse (fejl: 22202). Prøv det igen.",
    "error_22203": "Fejl: 22203. Prøv det igen\"",
    "error_22204": "Forespørgsel mislykkedes (fejlkode: 22204). Prøv det igen",
    "error_22206": "Forespørgsel mislykkedes (fejlkode: 22206). Prøv det igen",
    "error_22401": "Fejl: 22401. Kontakt os venligst",
    "error_22402": "Ugyldig bekræftelse (fejl: 22402). Prøv det igen.",
    "error_22403": "Fejl: 22403. Prøv det igen",
    "error_22404": "Forespørgsel mislykkedes (fejlkode: 22404). Prøv det igen",
    "error_22405": "Facebook-konto er blevet knyttet til anden e-mail",
    "error_22406": "Fejl: 22406. Prøv det igen",
    "error_22407": "Fejl: 22407. Kontakt os venligst",
    "error_22408": "Fejl: 22408. Kontakt os venligst",
    "error_22409": "Fejl: 22409. Kontakt os venligst",
    "error_22410": "Fejl: 224010. Kontakt os venligst",
    "error_22411": "Fejl: 224011. Kontakt os venligst",
    "error_22412": "Fejl: 224012. Kontakt os venligst",
    "error_22413": "Fejl: 22413. Kontakt os venligst",
    "error_22414": "Forespørgsel mislykkedes (fejlkode: 22414). Prøv det igen",
    "error_22415": "Fejl: 22415. Kontakt os venligst",
    "error_22416": "Fejl: 22416. Kontakt os venligst",
    "error_22417": "Fejl: 22417. Kontakt os venligst",
    "error_22418": "Forespørgsel mislykkedes (fejlkode: 22418). Prøv det igen",
    "error_22419": "Fejl: 22419. Kontakt os venligst",
    "error_22420": "Fejl: 22420. Kontakt os venligst",
    "error_22421": "Fejl: 22421. Kontakt os venligst",
    "error_22422": "Forespørgsel mislykkedes (fejlkode: 22422). Prøv det igen",
    "error_22423": "Forespørgsel mislykkedes (fejlkode: 22423). Prøv det igen",
    "error_22424": "Forespørgsel mislykkedes (fejlkode: 22424). Prøv det igen",
    "error_22425": "Forespørgsel mislykkedes (fejlkode: 22425). Prøv det igen",
    "error_20098": "Fejlkode: 20098. Hvis du browser i privat tilstand, skal du skifte til normal tilstand og prøve igen.",
    "error_22298": "Anmodning om Google mislykkedes (fejl: 22298). Prøv det igen.",
    "error_22498": "Anmodning om Facebook mislykkedes (fejl: 22498). Prøv det igen.",
    "error_24902": "Forespørgsel mislykkedes (fejlkode: 24902). Prøv det igen",
    "error_24903": "Forespørgsel mislykkedes (fejlkode: 24903). Prøv det igen",
    "error_24904": "Forespørgsel mislykkedes (fejlkode: 24904). Prøv det igen",
    "error_24905": "Forespørgsel mislykkedes (fejlkode: 24905). Prøv det igen",
    "login_title": "Log ind på MindOnMap",
    "log_in": "Log på",
    "no_account": "Ingen konto?",
    "create_it": "Opret det",
    "or_log_in_with": "Eller log ind med",
    "passwordless_login": "Login uden adgangskode",
    "log_in_done": "Log ind Færdig",
    "three_rd_account_connect_info": "Tillykke! Du har logget ind. Nu kan du tilslutte én e-mail-konto, der bruges til at logge på i fremtiden.",
    "see_my_account": "Se min konto",
    "three_rd_login_merge_account": "Tredjepartskontoens e-mailadresse er blevet tilmeldt, vil du oprette forbindelse og logge ind med denne e-mailadresse direkte?",
    "connect_log_in": "Tilslut og log ind",
    "create_an_account": "Opret en konto",
    "back_to_log_in": "Tilbage for at logge ind",
    "create_password": "Opret adgangskode",
    "create_now": "Opret nu",
    "password_login_subtitle": "Login uden adgangskode med e-mail",
    "account_login": "Konto login",
    "rights": "Ved at oprette denne konto accepterer du <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Servicevilkår</a> og <a href=\"https://www.mindonmap.com/privacy-policy/\">Privatlivspolitik</a>",
    "passwordless_login_done": "Login uden adgangskode udført",
    "passwordless_login_info": "Tillykke, du har gennemført login uden adgangskode. Du kan oprette en adgangskode til denne konto og logge på med kontoen og adgangskoden i fremtiden. <a href=\"/create-password\" style=\"display: initial;\">Opret nu</a>",
    "sign_up": "Tilmelde",
    "register_info": "Opret din konto",
    "reset_now": "Nulstil nu",
    "forgot_password": "Glemt kodeord",
    "reset_password_subtitle": "Brug din konto-e-mail til at nulstille adgangskoden",
    "plan_products": "Planer og produkter",
    "nick_name": "Brugernavn:",
    "email": "E-mail:",
    "my_products": "Mine produkter",
    "my_orders": "Mine Ordrer",
    "unlink": "Fjern linket",
    "link": "Link",
    "connected_accounts": "Tilknyttede konti",
    "last_name": "Efternavn:",
    "first_name": "Fornavn:",
    "Gender": "Køn:",
    "Birth": "Fødsel:",
    "Month": "Måned",
    "Year": "År",
    "Country_Region": "Land/region:",
    "Address": "Adresse:",
    "Save": "Gemme",
    "Date": "Dato",
    "Male": "Han",
    "Female": "Kvinde",
    "Unspecified": "Uspecificeret",
    "Security": "Sikkerhed",
    "change_password": "Skift kodeord",
    "change_now": "Skift nu",
    "connect_email": "Tilslut e-mail",
    "delete_account": "Slet konto",
    "delete_account_info": "Når din konto er slettet, slettes alle data i Min konto, der er knyttet til din konto, permanent, og du kan muligvis ikke gendanne dem. Vi anbefaler, at du træder varsomt.",
    "Delete": "Slet",
    "Logout": "Log ud",
    "my_profile": "Min profil",
    "guides_faqs": "Guider og ofte stillede spørgsmål",
    "More": "Mere",
    "guides": "Vejledninger",
    "register": "Tilmeld",
    "hot_faq": "Hot FAQ",
    "Contents": "Indhold:",
    "contact_us": "Kontakt os >>",
    "plan": "Plan",
    "unregistered": "Uregistreret",
    "buy_more": "Køb mere",
    "buy_again": "Køb igen",
    "buy_now": "Køb nu",
    "free_no_limit": "Gratis & ingen grænse",
    "expired": "Udløbet",
    "lifetime": "Livstid",
    "remain": "Forblive",
    "day_s": "Dage)",
    "error_24801": "Forespørgsel mislykkedes (fejlkode: 24801). Prøv det igen",
    "no_app_found": "Ingen app fundet!<a href=\"/\">Opdater</a> eller <a href=\"https://www.mindonmap.com/\">gå til det officielle websted</a>",
    "get_more": "Få mere >>",
    "edit_photo": "Rediger billede",
    "select_photo": "Vælg billede",
    "change_photo": "Ændr Foto",
    "cancel": "Afbestille",
    "hide_password": "Skjul adgangskode",
    "show_password": "Vis adgangskode",
    "zoom_in": "Zoom ind",
    "zoom_out": "Zoome ud",
    "rotate": "Rotere",
    "horizontal_flip": "Vandret flip",
    "vertical_flip": "Lodret flip",
    "country": "Land",
    "country_1": "Vælg dit land/dit område",
    "country_2": "Åaland",
    "country_3": "Afghanistan",
    "country_4": "Albanien",
    "country_5": "Algeriet",
    "country_6": "Amerikansk Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktis",
    "country_11": "Antigua og Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenien",
    "country_14": "Aruba",
    "country_15": "Australien",
    "country_16": "Østrig",
    "country_17": "Aserbajdsjan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Hviderusland",
    "country_22": "Belgien",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnien og Hercegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet-øen",
    "country_31": "Brasilien",
    "country_32": "Det britiske territorium i det indiske hav",
    "country_33": "Britiske Jomfruøer",
    "country_34": "Brunei",
    "country_35": "Bulgarien",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodja",
    "country_39": "Cameroun",
    "country_40": "Canada",
    "country_41": "Kap Verde",
    "country_42": "Caribiske Holland",
    "country_43": "Caymanøerne",
    "country_44": "Den Centralafrikanske Republik",
    "country_45": "Tchad",
    "country_46": "Chile",
    "country_47": "Kina",
    "country_48": "Juleøen",
    "country_49": "Cocos (Keeling) øerne",
    "country_50": "Colombia",
    "country_51": "Cookøerne",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Kroatien",
    "country_55": "Cuba",
    "country_56": "Cypern",
    "country_57": "Tjekkiet",
    "country_58": "Den demokratiske republik Congo",
    "country_59": "Danmark",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominikanske republik",
    "country_63": "Ecuador",
    "country_64": "Egypten",
    "country_65": "El Salvador",
    "country_66": "Ækvatorial Guinea",
    "country_67": "Eritrea",
    "country_68": "Estland",
    "country_69": "Etiopien",
    "country_70": "Falklandsøerne",
    "country_71": "Færøerne",
    "country_72": "Forenede stater i Mikronesien",
    "country_73": "Fiji",
    "country_74": "Finland",
    "country_75": "Frankrig",
    "country_76": "Fransk Guyana",
    "country_77": "Fransk Polynesien",
    "country_78": "franske sydlige territorier",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgien",
    "country_82": "Tyskland",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Storbritannien (Storbritannien, England)",
    "country_86": "Grækenland",
    "country_87": "Grønland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Island og McDonald Islands",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Ungarn",
    "country_101": "Island",
    "country_102": "Indien",
    "country_103": "Indonesien",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irland",
    "country_107": "Isle of Man",
    "country_108": "Israel",
    "country_109": "Italien",
    "country_110": "Jamaica",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordan",
    "country_114": "Kasakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgisistan",
    "country_119": "Laos",
    "country_120": "Letland",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libyen",
    "country_125": "Liechtenstein",
    "country_126": "Litauen",
    "country_127": "Luxembourg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldiverne",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshalløerne",
    "country_136": "Martinique",
    "country_137": "Mauretanien",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexico",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongoliet",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marokko",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Holland",
    "country_153": "Ny Kaledonien",
    "country_154": "New Zealand",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolk Island",
    "country_160": "Nordkorea",
    "country_161": "Northern Mariana Islands",
    "country_162": "Norge",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "palæstinensiske områder",
    "country_167": "Panama",
    "country_168": "Papua Ny Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn-øerne",
    "country_172": "Polen",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Republikken Makedonien (FYROM)",
    "country_177": "Republikken Congo",
    "country_178": "Genforening",
    "country_179": "Rumænien",
    "country_180": "Den Russiske Føderation",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Frankrig)",
    "country_184": "Saint-Pierre og Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tomé & Principe",
    "country_188": "Saudi Arabien",
    "country_189": "Senegal",
    "country_190": "Serbien",
    "country_191": "Seychellerne",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovakiet",
    "country_195": "Slovenien",
    "country_196": "Salomonøerne",
    "country_197": "Somalia",
    "country_198": "Sydafrika",
    "country_199": "Syd Georgien og Sydlige Sandwich Isla",
    "country_200": "Sydkorea",
    "country_201": "Sydsudan",
    "country_202": "Spanien",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena og afhængigheder",
    "country_205": "St. Kitts og Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent og Grenadinerne",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swaziland",
    "country_211": "Sverige",
    "country_212": "Schweiz",
    "country_213": "Syrien",
    "country_214": "Taiwan",
    "country_215": "Tadsjikistan",
    "country_216": "Tanzania",
    "country_217": "Skabelon:Landedata SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "Bahamas",
    "country_220": "Comorerne",
    "country_221": "Filippinerne",
    "country_222": "Timor-Leste (Østtimor)",
    "country_223": "At gå",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad og Tobago",
    "country_227": "Tunesien",
    "country_228": "Kalkun",
    "country_229": "Turkmenistan",
    "country_230": "Turks- og Caicosøerne",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraine",
    "country_234": "Forenede Arabiske Emirater",
    "country_235": "Forenede Staters mindre øer",
    "country_236": "Amerikas Forenede Stater (USA)",
    "country_237": "De Forenede Staters Jomfruøer",
    "country_238": "Uruguay",
    "country_239": "Usbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikanstaten (Den hellige stol)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis og Futuna",
    "country_245": "Vestsahara",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Log ind med Google",
    "State": "Stat",
    "Activation_code": "Aktiveringskode",
    "Question": "Liste over alle de apps, du har logget på",
    "Copy_complete": "Kopi færdig",
    "footer": "Copyright © 2024 MindOnMap Studio. Alle rettigheder forbeholdes.",
    "change_password_success": "Adgangskoden blev ændret",
    "successful_login_title": "Login succes",
    "product_page": "Produktside >>",
    "successful_login_info": "Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces. Den aktuelle side lukkes automatisk om 5 sekunder. Hvis automatisk lukning eller lukning ved at klikke på knappen \"Udført\" mislykkes, skal du lukke denne fane direkte.",
    "successful_login_info_firefox": "Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces.",
    "my_account": "Min konto",
    "my_history": "Min historie",
    "remove_watermark": "Fjern vandmærke",
    "no_history": "Ingen historie",
    "history_all": "Vælg alle",
    "history_open": "Åben",
    "history_down": "Hent",
    "history_delete": "Slet",
    "history_clear": "Ryd ugyldig",
    "images": "billede(r)",
    "use_this_function": "Brug dette produkt >>",
    "hd_downloading": "Fordele ved download af originale HD-billeder:",
    "lifetimeRemaining": "Levetid tilbage",
    "Remaining": "Tilbage",
    "email_verification": "E-mailbekræftelse",
    "email_verification_info": "Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class=\"email\"></span>, og bedes afslutte bekræftelsen. Efter bekræftelse vil fordelene automatisk blive synkroniseret.",
    "wrong_email": "Forkert e-mailadresse?",
    "click_here_to_modify": "Klik her for at ændre",
    "get_more_help": "Få mere hjælp?",
    "click_here": "Klik her",
    "email_verification_info_success": "Tillykke med bekræftelsen af din e-mail-konto.",
    "email_verification_info_error": "Bekræftelsen mislykkedes på grund af det udløbne link.",
    "registration_succeeded": "Registrering lykkedes",
    "registration_succeeded_info_1": "Tillykke! Du har registreret dig. Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class=\"email\"></span>, og bedes afslutte bekræftelsen for at bruge fordelene ved denne e-mail.",
    "registration_succeeded_info_2": "Klik på \"Udført\" for at vende tilbage til startsiden og bruge dette produkt.",
    "registration_succeeded_info_3": "Klik på \"Udført\" for at lukke den aktuelle side og vende tilbage til startsiden for følgende handlinger. Hvis lukningen mislykkes, skal du lukke fanen manuelt.",
    "verify_email": "Bekræft e-mail",
    "registered_email_not_verified": "Den registrerede e-mail er ikke blevet bekræftet. Bekræft den med det samme.",
    "email_verification_time_1": "Modtog du ikke en bekræftelses-e-mail?",
    "email_verification_time_2": "Efter",
    "email_verification_time_3": "Klik her for at sende den igen",
    "error_26301": "Fejlkode: 26301, kontakt os venligst",
    "error_26302": "Fejlkode: 26302, kontakt os venligst",
    "error_26303": "E-mail-formatfejl (fejlkode: 26303). Indtast det igen",
    "error_26304": "Adgangskode over 8 tegn anbefales (fejlkode: 26304)",
    "error_26305": "Reuqest mislykkedes (fejlkode: 26305). Prøv det igen",
    "error_26306": "E-mail er blevet registreret. <a href=\"https://account.mindonmap.com/login/\">gå til log ind</a>",
    "error_26307": "Reuqest mislykkedes (fejlkode: 26307). Prøv det igen",
    "error_26308": "Reuqest mislykkedes (fejlkode: 26308). Prøv det igen",
    "error_26401": "Fejlkode: 26401, prøv venligst igen",
    "error_26402": "E-mailen er blevet bekræftet (fejlkode: 26402), prøv venligst igen",
    "error_26403": "Reuqest mislykkedes (fejlkode: 26403). Prøv det igen",
    "error_26404": "Forespørgsel mislykkedes (fejlkode: 26404). Prøv det igen",
    "error_26501": "Fejlkode: 26501, kontakt os venligst",
    "error_26502": "Fejlkode: 26502, kontakt os venligst",
    "error_26503": "E-mail-formatfejl (fejlkode: 26503). Indtast det igen",
    "error_26504": "Reuqest mislykkedes (fejlkode: 26504). Prøv det igen",
    "error_26505": "E-mail er ikke blevet registreret. <a href=\"https://account.mindonmap.com/register/\">registrer den først</a>",
    "error_26506": "E-mail er blevet bekræftet.",
    "error_26507": "Reuqest mislykkedes (fejlkode: 26507). Prøv det igen",
    "error_26508": "Bekræftelse mislykkedes (fejlkode: 26508), prøv venligst igen",
    "error_26509": "Anmodningen mislykkedes (fejlkode: 26509), prøv venligst igen",
    "error_26510": "Fejlkode: 26510, kontakt os venligst",
    "error_26601": "Fejlkode: 26601, kontakt os venligst",
    "error_26602": "Fejlkode: 26602, kontakt os venligst",
    "error_26603": "Reuqest mislykkedes (fejlkode: 26603). Prøv det igen",
    "error_26604": "Fejlkode: 26604, kontakt os venligst",
    "error_26605": "Fejlkode: 26605, kontakt os venligst",
    "error_26701": "Fejlkode: 26701, kontakt os venligst",
    "error_26702": "Reuqest mislykkedes (fejlkode: 26702). Prøv det igen",
    "error_26703": "Fejlkode: 26703, kontakt os venligst",
    "error_26704": "Fejlkode: 26704, kontakt os venligst",
    "error_26705": "Vent på at logge ind (fejlkode: 26705). Prøv det igen",
    "no_cookie": "Du har aktiveret funktionen Bloker alle cookies i din browser, så du kan ikke logge ind. Gå til Indstillinger for at markere feltet Tillad alle cookies.",
    "error_26801": "Fejlkode: 26801, kontakt os venligst",
    "error_26802": "Fejlkode: 26802, kontakt os venligst",
    "error_26803": "Forespørgsel mislykkedes (fejlkode: 26803). Prøv det igen",
    "error_26804": "Forespørgsel mislykkedes (fejlkode: 26804). Prøv det igen",
    "error_order": "Forespørgsel mislykkedes (fejlkode: 27098), prøv venligst igen!",
    "error_order1": "Ordreforespørgslen er ufuldstændig (fejlkode: ",
    "error_order2": "）, opdater venligst og prøv det igen.",
    "modify_email_title": "Rediger e-mail",
    "modify_email_info": "Du kan bruge den ændrede e-mail til at logge ind på din konto.",
    "images_per": "Billeder pr",
    "error_26101": "Fejl: 26101. Kontakt os venligst",
    "error_26102": "Fejl: 26102. Kontakt os venligst",
    "error_26103": "Anmodningen mislykkedes (fejlkode:26103). Prøv det igen",
    "error_26104": "Fejlkode: 26104, prøv det igen",
    "error_26105": "Fejlkode: 26105, prøv det igen",
    "error_26106": "Sletning mislykkedes (fejlkode: 26106). Prøv det igen",
    "error_26201": "Fejl: 26201. Kontakt os venligst",
    "error_26202": "Anmodningen mislykkedes (fejlkode:26202). Prøv det igen",
    "error_26001": "Fejl: 26001. Kontakt os venligst",
    "error_26002": "Fejl: 26002. Kontakt os venligst",
    "error_26003": "Fejl: 26003. Kontakt os venligst",
    "error_26004": "Fejl: 26004. Kontakt os venligst",
    "error_26005": "Anmodningen mislykkedes (fejlkode:26005). Prøv det igen",
    "error_26006": "Fejlkode: 26006, prøv det igen",
    "error_26008": "Fejl: 26008. Kontakt os venligst",
    "go_to_the_home_page": "Gå til startsiden",
    "error_27101": "Forespørgsel mislykkedes (fejlkode: 27101). Prøv venligst igen",
    "error_27201": "Fejlkode: 27201, kontakt os venligst",
    "error_27202": "Fejlkode: 27202, prøv venligst igen",
    "error_27203": "Forespørgsel mislykkedes (fejlkode: 27203). Prøv det igen",
    "error_27204": "Ugyldig kode (fejlkode: 27204).",
    "error_27205": "Forespørgsel mislykkedes (fejlkode: 27205). Prøv det igen",
    "error_27206": "Forespørgsel mislykkedes (fejlkode: 27206). Prøv det igen",
    "error_27207": "Forespørgsel mislykkedes (fejlkode: 27207). Prøv det igen",
    "no_history_found": "Du har ikke brugt noget værktøj! <a href=\"/\">Opdater</a> eller <a href=\"https://www.mindonmap.com/\">Gå til det officielle websted</a>",
    "error_25301": "Fejl: 25301. Kontakt os venligst",
    "error_25302": "Fejl: 25302. Kontakt os venligst",
    "error_25303": "Anmodningen mislykkedes (fejlkode: 25303). Prøv det igen",
    "error_25304": "Anmodningen mislykkedes (fejlkode: 25304). Prøv det igen",
    "error_25305": "Anmodningen mislykkedes (fejlkode: 25305). Prøv det igen",
    "error_25306": "Anmodningen mislykkedes (fejlkode: 25306). Prøv det igen",
    "image_upscaler_p": "Fordele ved download af billede uden vandmærke:",
    "Available_for": "Tilgængelig for:",
    "credit_per": "%s kredit(er) pr. HD-billede",
    "still_valid": "Købte abonnement(er) er stadig gyldige",
    "credit": "kredit(er)",
    "pc_3rd_info": "Log ind med succes. Gå til applikationen for yderligere betjening.",
    "use_online": "Brug onlinetjenesten",
    "use_download": "Brug skrivebordsprogrammet",
    "use_immediately": "Brug med det samme",
    "Use_in_browser": "Brug i browser",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kreditter pr. måned",
    "expire": "Udløbstid:",
    "viewDetails": "Se detaljer",
    "viewHistory": "Se historiefordele >>",
    "viewDetailsInfo": "Bemærk: Hvis abonnementsfordelene fornyes inden for 7 dage efter udløb, kan de uudnyttede fordele fortsat bruges. Udløbstiden vil også automatisk blive opdateret til udløbstiden for det nye abonnement. Hvis der ikke er nyt abonnement efter 7 dages udløbstid, vil alle udløbne fordele blive slettet.",
    "connect_account": "Bind e-mail til din konto",
    "connect_account_info": "Efter binding kan du logge ind med denne e-mailadresse.",
    "connect_now": "Bind nu",
    "no_email_bind": "Ingen e-mail-binding",
    "bind_email": "Bind e-mail",
    "connect_your_email_placeholder": "Indtast venligst din e-mailadresse",
    "bind_an_email": "Bind en e-mail",
    "bind_info": "Du har logget ind. Bind venligst en e-mail til din konto for at aktivere dine fordele.",
    "bind_later": "Bind senere",
    "hi": "Hej %s!",
    "Personal_Information": "Personlig information",
    "Access": "Adgang",
    "Subscription_Plan": "(Abonnement)",
    "No_orders": "Ingen ordrer fundet.",
    "No_data": "Ingen data",
    "Featured_Products": "Fremhævede Produkter",
    "More_Products": "Flere produkter",
    "Free_Download": "Gratis download",
    "Get_Started": "Kom igang",
    "Subscribe": "Abonner",
    "Verified": "Verificeret",
    "back_to_account_center": "Tilbage til Account Center",
    "success": "Succes!",
    "successfully": "Du har registreret en konto.",
    "Continue": "Blive ved",
    "Already": "har du allerede en bruger?",
    "loading_verification": "Kontrol af verifikationsstatus...",
    "email_no_verification": "Beklager, den registrerede e-mailadresse er ikke blevet bekræftet. Udfyld venligst bekræftelsen i henhold til ovenstående instruktioner, og klik på knappen \"Udført\" igen for at fuldføre registreringen.",
    "will_expire_after": "Udløber efter",
    "hours": "timer",
  },
  "de": {
    "overtime": "Fehlercode: {%}, bitte versuchen Sie es erneut",
    "isnetwork": "Internetfehler. Bitte überprüfen und erneut versuchen",
    "email_placeholder": "E-Mail",
    "email_empty": "Bitte geben Sie eine E-Mail-Adresse ein",
    "email_not_valid": "Die E-Mail ist ungültig",
    "email_not_valid_1": "Bitte geben Sie Ihre E-Mail ein",
    "email_not_valid_2": "Die E-Mail-Adresse ist ungültig. Bitte verwenden Sie eine andere Adresse.",
    "email_not_valid_3": "Keine E-Mail-Eingabe",
    "password_placeholder": "Passwort",
    "password_empty": "Bitte geben Sie das Passwort ein",
    "password_not_valid": "Falsches Konto oder Passwort",
    "password_not_valid_1": "Das Passwort muss länger als 8 Zeichen sein.",
    "password_not_valid_2": "Bitte erstellen Sie ein Passwort",
    "password_placeholder_1": "Ein Passwort erstellen",
    "password_placeholder_2": "Ihr Passwort bestätigen",
    "password_placeholder_3": "Neues Passwort erstellen",
    "password_placeholder_4": "Neues Passwort bestätigen",
    "password_placeholder_5": "Altes Passwort eingeben",
    "copy_password_empty": "Bitte bestätigen Sie das Passwort",
    "copy_password_not_valid": "Bitte bestätigen Sie Ihr Passwort",
    "copy_passwords_inconsistent": "Ihre Passwortbestätigung stimmt nicht überein",
    "code_empty": "Bitte geben Sie den Verifizierungscode ein",
    "code_not_valid": "Ungültiger Verifizierungscode",
    "code_placeholder": "Verifizierungscode",
    "not_received_code": "Sollte Ihr Postfach über einen längeren Zeitraum keinen Verifizierungscode erhalten haben, fordern Sie den Verifizierungscode bitte erneut an.",
    "get_first_code": "Bitte holen Sie sich zuerst den Verifizierungscode.",
    "last_name_placeholder": "Bitte geben Sie Ihren Nachnamen ein",
    "first_name_placeholder": "Bitte geben Sie Ihren Vornamen ein",
    "address_placeholder": "Bitte geben Sie Ihre Adresse ein",
    "no_code_text": "Wir haben einen Verifizierungscode gesendet. Bitte geben Sie Ihren Code ein. <span class='tips'>Sie haben keinen Code erhalten?",
    "no_code_text_1": "1. Bitte stellen Sie sicher, dass die E-Mail-Adresse gültig ist und E-Mails empfangen kann.",
    "no_code_text_2": "2. Da die E-Mail automatisch vom System gesendet wird, kann sie als Spam oder Junk-E-Mail gekennzeichnet sein. Bitte überprüfen Sie, ob sich die E-Mail im Papierkorb befindet.",
    "no_code_text_3": "3. Ihr Problem lässt sich nicht lösen?",
    "no_code_text_3_span": "Klicken Sie hier, um Kontakt mit uns aufzunehmen.",
    "order_no": "Sie haben kein Produkt gekauft. Wenn Sie Fragen haben, <a href=\"https://www.mindonmap.com/contact-us/\">kontaktieren Sie uns bitte</a>.",
    "error_24901": "Das aktuelle Konto hat keine verknüpfte E-Mail-Adresse und kann die Bestellungen nicht finden. Bitte verknüpfen Sie eine E-Mail-Adresse.",
    "user_guide": "Anleitungen>>",
    "download": "Downloaden",
    "order_number": "Bestellnummer:",
    "Refund": "Rückerstatten",
    "Disabled": "Deaktiviert",
    "Normal": "Normal",
    "Modify": "Ändern",
    "Modify_1": "Ändern>>",
    "Connect": "Verknüpfen",
    "unlink_success": "Verknüpfung erfolgreich aufgehoben",
    "connect_success": "Verknüpfung erfolgreich herstellen",
    "feedback_title": "Vielen Dank für Ihr Feedback. Bitte teilen Sie uns Ihr Problem mit und wir werden Ihnen innerhalb von 24 Stunden antworten.",
    "feedback_thank_you": "Vielen Dank!<br />Ihr Feedback wurde erfolgreich gesendet.",
    "feedback_email": "Geben Sie Ihre E-Mail hier ein!",
    "feedback_content": "Hinterlassen Sie hier alle Probleme oder Vorschläge.",
    "feedback_submit": "Senden",
    "form_contents": "Sie haben keine Beschreibung eingegeben. Bitte geben Sie diese ein und senden Sie sie erneut.",
    "old_password": "Bitte geben Sie das alte Passwort ein",
    "new_password": "Bitte erstellen Sie ein neues Passwort",
    "old_new_password": "Das neue Passwort darf nicht mit dem alten identisch sein.",
    "incorrect_password": "Falsches Passwort",
    "delete_no": "Jetzt löschen",
    "Caps": "Feststelltaste ist aktiviert",
    "Get": "Holen",
    "Done": "Fertig",
    "error_20001": "Fehler: 20001. Bitte melden Sie sich erneut an.",
    "error_20002": "Fehler: 20002. Bitte melden Sie sich erneut an.",
    "error_20003": "Fehler: 20003. Bitte melden Sie sich erneut an.",
    "error_20004": "Anforderung fehlgeschlagen (Fehlercode: 20004). Bitte versuchen Sie es erneut.",
    "error_20005": "Die Anmeldesitzung ist abgelaufen (Fehler: 20005). Bitte melden Sie sich erneut an.",
    "error_20006": "Anforderung fehlgeschlagen (Fehlercode: 20006). Bitte versuchen Sie es erneut.",
    "error_20007": "Die Anmeldesitzung ist abgelaufen (Fehler: 20007). Bitte melden Sie sich erneut an.",
    "error_20008": "Die Anmeldesitzung ist abgelaufen (Fehler: 20008). Bitte melden Sie sich erneut an.",
    "error_20009": "Die Anmeldesitzung ist abgelaufen (Fehler: 20009). Bitte melden Sie sich erneut an.",
    "error_20101": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20101)",
    "error_20102": "E-Mail ist ungültig (Fehlercode: 20102)",
    "error_20103": "Anfrage fehlgeschlagen (Fehlercode: 20103). Bitte versuchen Sie es erneut",
    "error_20104": "Die E-Mail-Adresse wird bereits verwendet. Bitte <a href=\"https://account.mindonmap.com/login/\">melden Sie sich an</a> oder registrieren Sie sich mit einer neuen",
    "error_20105": "Anfrage fehlgeschlagen (Fehlercode: 20105). Bitte versuchen Sie es erneut",
    "error_20106": "E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut.",
    "error_20201": "Bitte geben Sie Ihre E-Mail-Adresse ein (Fehlercode: 20201)",
    "error_20202": "Bitte geben Sie Ihr Passwort ein (Fehlercode: 20202)",
    "error_20203": "Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20203)",
    "error_20204": "E-Mail ist ungültig (Fehlercode: 20204)",
    "error_20205": "Das Passwort muss länger als 8 Zeichen sein (Fehlercode: 20205)",
    "error_20206": "Anfrage fehlgeschlagen (Fehlercode: 20206). Bitte versuchen Sie es erneut",
    "error_20207": "Ungültiger Bestätigungscode",
    "error_20208": "Anfrage fehlgeschlagen (Fehlercode: 20208). Bitte versuchen Sie es erneut",
    "error_20209": "Anfrage fehlgeschlagen (Fehlercode: 20209). Bitte versuchen Sie es erneut",
    "error_20301": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20301)",
    "error_20302": "Fehler: 20302. Bitte kontaktieren Sie uns",
    "error_20303": "E-Mail ist ungültig (Fehlercode: 20303)",
    "error_20304": "Anfrage fehlgeschlagen (Fehlercode: 20304). Bitte versuchen Sie es erneut",
    "error_20305": "Das Konto existiert nicht. Bitte geben Sie es erneut ein oder <a href=\"https://account.mindonmap.com/register/\">erstellen Sie es</a> zuerst.",
    "error_20306": "Noch kein Passwort. Verwenden Sie die <a href=\"https://account.mindonmap.com/passwordless-login/\">Anmeldung ohne Passwort</a> oder <a href=\"https://account.mindonmap.com/create-password/\">legen Sie ein Passwort fest</a> und melden Sie sich an.",
    "error_20308": "Anfrage fehlgeschlagen (Fehlercode: 20308). Bitte versuchen Sie es erneut",
    "error_20401": "Abmeldung fehlgeschlagen (Fehlercode: 20401). Bitte versuchen Sie es erneut",
    "error_20501": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20501)",
    "error_20502": "E-Mail ist ungültig (Fehlercode: 20502)",
    "error_20503": "Anfrage fehlgeschlagen (Fehlercode: 20503). Bitte versuchen Sie es erneut",
    "error_20504": "E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    "error_20601": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20601)",
    "error_20602": "Bitte geben Sie den Verifizierungscode ein (Fehlercode: 20602)",
    "error_20603": "E-Mail ist ungültig (Fehlercode: 20603)",
    "error_20604": "Anfrage fehlgeschlagen (Fehlercode: 20604). Bitte versuchen Sie es erneut",
    "error_20606": "Anfrage fehlgeschlagen (Fehlercode: 20606). Bitte versuchen Sie es erneut",
    "error_20607": "Anfrage fehlgeschlagen (Fehlercode: 20607). Bitte versuchen Sie es erneut",
    "error_20608": "Anfrage fehlgeschlagen (Fehlercode: 20608). Bitte versuchen Sie es erneut",
    "error_20701": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20701)",
    "error_20702": "E-Mail ist ungültig (Fehlercode: 20702)",
    "error_20703": "Anfrage fehlgeschlagen (Fehlercode: 20703). Bitte versuchen Sie es erneut",
    "error_20704": "Das Konto existiert nicht. Bitte geben Sie es erneut ein oder <a href=\"https://account.mindonmap.com/register/\">erstellen Sie es</a> zuerst.",
    "error_20705": "Anfrage fehlgeschlagen (Fehlercode: 20705). Bitte versuchen Sie es erneut",
    "error_20706": "E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut.",
    "error_20801": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20801)",
    "error_20802": "Fehler: 20802. Bitte kontaktieren Sie uns",
    "error_20803": "Bitte geben Sie den Verifizierungscode ein (Fehlercode: 20803)",
    "error_20804": "E-Mail ist ungültig (Fehlercode: 20804)",
    "error_20805": "Das Passwort muss länger als 8 Zeichen sein (Fehlercode: 20805)",
    "error_20806": "Anfrage fehlgeschlagen (Fehlercode: 20806). Bitte versuchen Sie es erneut",
    "error_20808": "Anfrage fehlgeschlagen (Fehlercode: 20808). Bitte versuchen Sie es erneut",
    "error_20901": "Anfrage fehlgeschlagen (Fehlercode: 20901). Bitte versuchen Sie es erneut",
    "error_20902": "Anfrage fehlgeschlagen (Fehlercode: 20902). Bitte versuchen Sie es erneut",
    "error_21000": "Änderungen werden gespeichert",
    "error_21001": "Es wurden keine Informationen gesendet (Fehlercode: 21001)",
    "error_21002": "Anfrage fehlgeschlagen (Fehlercode: 21002). Bitte versuchen Sie es erneut",
    "error_21101": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21101)",
    "error_21102": "E-Mail ist ungültig (Fehlercode: 21102)",
    "error_21103": "Anforderung fehlgeschlagen (Fehlercode: 21103), bitte versuchen Sie es erneut",
    "error_21104": "Die E-Mail-Adresse ist bereits verknüpft. Bitte verwenden Sie eine neue",
    "error_21105": "Anforderung fehlgeschlagen (Fehlercode: 21105), bitte versuchen Sie es erneut",
    "error_21106": "E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    "error_21201": "Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21201)",
    "error_21202": "Bitte geben Sie den Verifizierungscode ein (Fehlercode: 21202)",
    "error_21203": "E-Mail ist ungültig (Fehlercode: 21203)",
    "error_21204": "Fehler: 21204. Bitte kontaktieren Sie uns",
    "error_21205": "Fehler: 21205. Bitte kontaktieren Sie uns",
    "error_21206": "Das Passwort muss länger als 8 Zeichen sein (Fehlercode: 21206)",
    "error_21207": "Anfrage fehlgeschlagen (Fehlercode: 21207). Bitte versuchen Sie es erneut",
    "error_21209": "Anfrage fehlgeschlagen (Fehlercode: 21209). Bitte versuchen Sie es erneut",
    "error_21301": "Bitte geben Sie das alte Passwort ein (Fehlercode: 21301)",
    "error_21302": "Bitte erstellen Sie ein neues Passwort (Fehlercode: 21302)",
    "error_21303": "Das neue Passwort darf nicht mit dem alten identisch sein. (Fehler: 21303)",
    "error_21304": "Das Passwort muss länger als 8 Zeichen sein (Fehlercode: 21304)",
    "error_21306": "Anfrage fehlgeschlagen (Fehlercode: 21306). Bitte versuchen Sie es erneut",
    "error_21402": "Anfrage fehlgeschlagen (Fehlercode: 21402). Bitte versuchen Sie es erneut",
    "error_21403": "Der Verifizierungscode konnte nicht gesendet werden. Bitte senden Sie ihn erneut.",
    "error_21500": "Das Konto wurde gelöscht",
    "error_21501": "Bitte geben Sie den Verifizierungscode ein (Fehlercode: 21501)",
    "error_21502": "Die Anmeldesitzung ist abgelaufen (Fehler: 21502). Bitte melden Sie sich erneut an.",
    "error_21503": "Anfrage fehlgeschlagen (Fehlercode: 21503). Bitte versuchen Sie es erneut",
    "error_21505": "Anforderung fehlgeschlagen (Fehlercode: 21505), bitte versuchen Sie es erneut",
    "error_21601": "Fehler: 20601. Bitte kontaktieren Sie uns",
    "error_21602": "Ungültige Verifizierung (Fehler: 20602). Bitte versuchen Sie es erneut.",
    "error_21603": "Fehler: 20603. Bitte versuchen Sie es erneut",
    "error_21604": "Anfrage fehlgeschlagen (Fehlercode: 21604). Bitte versuchen Sie es erneut",
    "error_21606": "Anfrage fehlgeschlagen (Fehlercode: 21606). Bitte versuchen Sie es erneut",
    "error_21611": "Anfrage fehlgeschlagen (Fehlercode: 21611). Bitte versuchen Sie es erneut",
    "error_21801": "Fehler: 21801. Bitte kontaktieren Sie uns",
    "error_21802": "Anfrage fehlgeschlagen (Fehler: 21802). Bitte versuchen Sie es erneut",
    "error_21803": "Fehler: 21803. Bitte versuchen Sie es erneut",
    "error_21804": "Anfrage fehlgeschlagen (Fehlercode: 21804). Bitte versuchen Sie es erneut",
    "error_21806": "Fehler: 21806. Bitte versuchen Sie es erneut",
    "error_21807": "Fehler: 21807. Bitte kontaktieren Sie uns",
    "error_21808": "Fehler: 21808. Bitte kontaktieren Sie uns",
    "error_21809": "Fehler: 21809. Bitte kontaktieren Sie uns",
    "error_21810": "Fehler: 21810. Bitte kontaktieren Sie uns",
    "error_21811": "Fehler: 21811. Bitte kontaktieren Sie uns",
    "error_21812": "Fehler: 21812. Bitte kontaktieren Sie uns",
    "error_21813": "Anfrage fehlgeschlagen (Fehlercode: 21813). Bitte versuchen Sie es erneut",
    "error_21814": "Fehler: 21814. Bitte kontaktieren Sie uns",
    "error_21815": "Anfrage fehlgeschlagen (Fehlercode: 21815). Bitte versuchen Sie es erneut",
    "error_21816": "Fehler: 21816. Bitte kontaktieren Sie uns",
    "error_21817": "Fehler: 21817. Bitte kontaktieren Sie uns",
    "error_21818": "Fehler: 21818. Bitte kontaktieren Sie uns",
    "error_21819": "Anfrage fehlgeschlagen (Fehlercode: 21819). Bitte versuchen Sie es erneut",
    "error_21820": "Fehler: 21820. Bitte kontaktieren Sie uns",
    "error_21821": "Fehler: 21821. Bitte kontaktieren Sie uns",
    "error_21822": "Fehler: 21822. Bitte kontaktieren Sie uns",
    "error_21823": "Anfrage fehlgeschlagen (Fehlercode: 21823). Bitte versuchen Sie es erneut",
    "error_21824": "Anfrage fehlgeschlagen (Fehlercode: 21824). Bitte versuchen Sie es erneut",
    "error_21825": "Anfrage fehlgeschlagen (Fehlercode: 21825). Bitte versuchen Sie es erneut",
    "error_21826": "Anfrage fehlgeschlagen (Fehlercode: 21826). Bitte versuchen Sie es erneut",
    "error_21901": "Fehler: 21901. Bitte kontaktieren Sie uns",
    "error_21902": "Anfrage fehlgeschlagen (Fehlercode: 21902). Bitte versuchen Sie es erneut",
    "error_21903": "Der Kontostatus hat sich geändert (Fehlercode: 21903). Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "error_21904": "Fehler: 21904. Bitte versuchen Sie es erneut",
    "error_21905": "Fehler: 21905. Bitte versuchen Sie es erneut",
    "error_21906": "Anfrage fehlgeschlagen (Fehlercode: 21906). Bitte versuchen Sie es erneut",
    "error_21907": "Das Google-Konto wurde mit einem anderen Konto verknüpft.",
    "error_21908": "Anfrage fehlgeschlagen (Fehlercode: 21908). Bitte versuchen Sie es erneut",
    "error_22001": "Anfrage fehlgeschlagen (Fehlercode: 22001). Bitte versuchen Sie es erneut",
    "error_22002": "Aufheben der Verknüpfung ohne zusätzliche Anmeldung fehlgeschlagen",
    "error_22003": "Anfrage fehlgeschlagen (Fehlercode: 22003). Bitte versuchen Sie es erneut",
    "error_22101": "Fehler: 22101. Bitte kontaktieren Sie uns",
    "error_22102": "Der Kontostatus hat sich geändert (Fehlercode: 22102). Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "error_22103": "Anfrage fehlgeschlagen (Fehlercode: 22103). Bitte versuchen Sie es erneut",
    "error_22104": "Der Kontostatus hat sich geändert (Fehlercode: 22104). Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "error_22105": "Fehler: 22105. Bitte versuchen Sie es erneut",
    "error_22106": "Fehler: 22106. Bitte versuchen Sie es erneut",
    "error_22107": "Fehler: 22107. Bitte kontaktieren Sie uns",
    "error_22108": "Anfrage fehlgeschlagen (Fehlercode: 22108). Bitte versuchen Sie es erneut",
    "error_22201": "Fehler: 22201. Bitte kontaktieren Sie uns",
    "error_22202": "Ungültige Verifizierung (Fehler: 22202). Bitte versuchen Sie es erneut.",
    "error_22203": "Fehler: 22203. Bitte versuchen Sie es erneut\"",
    "error_22204": "Anfrage fehlgeschlagen (Fehlercode: 22204). Bitte versuchen Sie es erneut",
    "error_22206": "Anfrage fehlgeschlagen (Fehlercode: 22206). Bitte versuchen Sie es erneut",
    "error_22401": "Fehler: 22401. Bitte kontaktieren Sie uns",
    "error_22402": "Ungültige Verifizierung (Fehler: 22402). Bitte versuchen Sie es erneut.",
    "error_22403": "Fehler: 22403. Bitte versuchen Sie es erneut",
    "error_22404": "Anfrage fehlgeschlagen (Fehlercode: 22404). Bitte versuchen Sie es erneut",
    "error_22405": "Das Facebook-Konto wurde mit einer anderen E-Mail verknüpft",
    "error_22406": "Fehler: 22406. Bitte versuchen Sie es erneut",
    "error_22407": "Fehler: 22407. Bitte kontaktieren Sie uns",
    "error_22408": "Fehler: 22408. Bitte kontaktieren Sie uns",
    "error_22409": "Fehler: 22409. Bitte kontaktieren Sie uns",
    "error_22410": "Fehler: 224010. Bitte kontaktieren Sie uns",
    "error_22411": "Fehler: 224011. Bitte kontaktieren Sie uns",
    "error_22412": "Fehler: 224012. Bitte kontaktieren Sie uns",
    "error_22413": "Fehler: 22413. Bitte kontaktieren Sie uns",
    "error_22414": "Anfrage fehlgeschlagen (Fehlercode: 22414). Bitte versuchen Sie es erneut",
    "error_22415": "Fehler: 22415. Bitte kontaktieren Sie uns",
    "error_22416": "Fehler: 22416. Bitte kontaktieren Sie uns",
    "error_22417": "Fehler: 22417. Bitte kontaktieren Sie uns",
    "error_22418": "Anfrage fehlgeschlagen (Fehlercode: 22418). Bitte versuchen Sie es erneut",
    "error_22419": "Fehler: 22419. Bitte kontaktieren Sie uns",
    "error_22420": "Fehler: 22420. Bitte kontaktieren Sie uns",
    "error_22421": "Fehler: 22421. Bitte kontaktieren Sie uns",
    "error_22422": "Anfrage fehlgeschlagen (Fehlercode: 22422). Bitte versuchen Sie es erneut",
    "error_22423": "Anfrage fehlgeschlagen (Fehlercode: 22423). Bitte versuchen Sie es erneut",
    "error_22424": "Anfrage fehlgeschlagen (Fehlercode: 22424). Bitte versuchen Sie es erneut",
    "error_22425": "Anfrage fehlgeschlagen (Fehlercode: 22425). Bitte versuchen Sie es erneut",
    "error_20098": "Fehlercode: 20098. Wenn Sie im Inkognitomodus surfen, wechseln Sie bitte in den normalen Modus und versuchen Sie es erneut.",
    "error_22298": "Anfrage für Google fehlgeschlagen (Fehler: 22298). Bitte versuchen Sie es erneut.",
    "error_22498": "Anfrage für Facebook fehlgeschlagen (Fehler: 22498). Bitte versuchen Sie es erneut.",
    "error_24902": "Anfrage fehlgeschlagen (Fehlercode: 24902). Bitte versuchen Sie es erneut",
    "error_24903": "Anfrage fehlgeschlagen (Fehlercode: 24903). Bitte versuchen Sie es erneut",
    "error_24904": "Anfrage fehlgeschlagen (Fehlercode: 24904). Bitte versuchen Sie es erneut",
    "error_24905": "Anfrage fehlgeschlagen (Fehlercode: 24905). Bitte versuchen Sie es erneut",
    "login_title": "Sich bei MindOnMap anmelden",
    "log_in": "Anmelden",
    "no_account": "Kein Konto?",
    "create_it": "Ein Konto erstellen",
    "or_log_in_with": "Oder melden Sie sich an mit",
    "passwordless_login": "Passwortlose Anmeldung",
    "log_in_done": "Anmelden Fertig",
    "three_rd_account_connect_info": "Herzlichen Glückwunsch! Sie haben sich erfolgreich angemeldet. Jetzt können Sie ein E-Mail-Konto verknüpfen, das in Zukunft zum Anmelden verwendet wird.",
    "see_my_account": "Mein Konto anzeigen",
    "three_rd_login_merge_account": "Die E-Mail-Adresse des Drittanbieterkontos wurde registriert. Möchten Sie sich direkt mit dieser E-Mail-Adresse verknüpfen und anmelden?",
    "connect_log_in": "Verknüpfen und anmelden",
    "create_an_account": "Ein Konto erstellen",
    "back_to_log_in": "Zurück zur Anmeldung",
    "create_password": "Passwort erstellen",
    "create_now": "Jetzt erstellen",
    "password_login_subtitle": "Passwortlose Anmeldung mit E-Mail",
    "account_login": "Anmelden mit Konto",
    "rights": "Indem Sie dieses Konto erstellen, stimmen Sie den <a href=\"https://www.mindonmap.com/terms-and-conditions/\">allgemeine Geschäftsbedingungen</a> und der <a href=\"https://www.mindonmap.com/privacy-policy/\">Datenschutzrichtlinie</a> zu.",
    "passwordless_login_done": "Passwortlose Anmeldung abgeschlossen",
    "passwordless_login_info": "Herzlichen Glückwunsch, Sie haben die passwortlose Anmeldung erfolgreich abgeschlossen. Sie können ein Passwort für dieses Konto erstellen und sich in Zukunft mit diesem Konto und Passwort anmelden. <a href=\"/create-password\" style=\"display: initial;\">Jetzt erstellen</a>",
    "sign_up": "Registrieren",
    "register_info": "Ihr Konto erstellen",
    "reset_now": "Jetzt zurücksetzen",
    "forgot_password": "Passwort vergessen",
    "reset_password_subtitle": "Verwenden Sie die E-Mail-Adresse Ihres Kontos, um das Passwort zurückzusetzen",
    "plan_products": "Pläne & Produkte",
    "nick_name": "Nutzername:",
    "email": "E-Mail:",
    "my_products": "Meine Produkte",
    "my_orders": "Meine Bestellungen",
    "unlink": "Verknüpfung aufheben",
    "link": "Verknüpfen",
    "connected_accounts": "Verknüpfte Konten",
    "last_name": "Nachname:",
    "first_name": "Vorname:",
    "Gender": "Geschlecht:",
    "Birth": "Geburtsdatum:",
    "Month": "Monat",
    "Year": "Jahr",
    "Country_Region": "Land/Region:",
    "Address": "Adresse:",
    "Save": "Speichern",
    "Date": "Datum",
    "Male": "Männlich",
    "Female": "Weiblich",
    "Unspecified": "Nicht spezifiziert",
    "Security": "Sicherheit",
    "change_password": "Passwort ändern",
    "change_now": "Jetzt ändern",
    "connect_email": "E-Mail verknüpfen",
    "delete_account": "Konto löschen",
    "delete_account_info": "Wenn Ihr Konto gelöscht wird, werden alle mit Ihrem Konto verknüpften Daten in \"Mein Konto\" dauerhaft gelöscht und Sie können sie möglicherweise nicht wiederherstellen. Wir empfehlen Ihnen, vorsichtig vorzugehen.",
    "Delete": "Löschen",
    "Logout": "Abmelden",
    "my_profile": "Mein Profil",
    "guides_faqs": "Anleitungen und FAQs",
    "More": "Mehr",
    "guides": "Anleitungen",
    "register": "registrieren",
    "hot_faq": "FAQ",
    "Contents": "Inhalt:",
    "contact_us": "Kontaktieren Sie uns>>",
    "plan": "Plan",
    "unregistered": "Nicht registriert",
    "buy_more": "Mehr kaufen",
    "buy_again": "Noch einmal kaufen",
    "buy_now": "Jetzt kaufen",
    "free_no_limit": "Kostenlos & unbegrenzt",
    "expired": "Abgelaufen",
    "lifetime": "Lebenslang",
    "remain": "verbleibend",
    "day_s": "Tag(e)",
    "error_24801": "Anfrage fehlgeschlagen (Fehlercode: 24801). Bitte versuchen Sie es erneut",
    "no_app_found": "Keine App gefunden! <a href=\"/\">Aktualisieren</a> oder <a href=\"https://www.mindonmap.com/\">zur offiziellen Website gehen</a>",
    "get_more": "Mehr erfahren >>",
    "edit_photo": "Foto bearbeiten",
    "select_photo": "Foto auswählen",
    "change_photo": "Foto ändern",
    "cancel": "Abbrechen",
    "hide_password": "Passwort verbergen",
    "show_password": "Passwort anzeigen",
    "zoom_in": "Vergrößern",
    "zoom_out": "Verkleinern",
    "rotate": "Drehen",
    "horizontal_flip": "Horizontal spiegeln",
    "vertical_flip": "Vertikal spiegeln",
    "country": "Land",
    "country_1": "Wählen Sie Ihr Land/Ihre Region aus",
    "country_2": "Åland-Insel",
    "country_3": "Afghanistan",
    "country_4": "Albanien",
    "country_5": "Algerien",
    "country_6": "Amerikanischen Samoa-Inseln",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktis",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentinien",
    "country_13": "Armenien",
    "country_14": "Aruba",
    "country_15": "Australien",
    "country_16": "Österreich",
    "country_17": "Aserbaidschan",
    "country_18": "Bahrain",
    "country_19": "Bangladesch",
    "country_20": "Barbados",
    "country_21": "Weißrussland",
    "country_22": "Belgien",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivien",
    "country_28": "Bosnien & Herzegowina",
    "country_29": "Botswana",
    "country_30": "Bouvetinsel",
    "country_31": "Brasilien",
    "country_32": "Britisches Territorium des Indischen Ozeans",
    "country_33": "Britische Jungferninseln",
    "country_34": "Brunei",
    "country_35": "Bulgarien",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodscha",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kap Verde",
    "country_42": "Karibische Niederlande",
    "country_43": "Cayman Inseln",
    "country_44": "Zentralafrikanische Republik",
    "country_45": "Tschad",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Weihnachtsinsel",
    "country_49": "Kokosinseln (Keelinginseln)",
    "country_50": "Kolumbien",
    "country_51": "Cookinseln",
    "country_52": "Costa Rica",
    "country_53": "Elfenbeinküste",
    "country_54": "Kroatien",
    "country_55": "Kuba",
    "country_56": "Zypern",
    "country_57": "Tschechien",
    "country_58": "Demokratische Republik Kongo",
    "country_59": "Dänemark",
    "country_60": "Dschibuti",
    "country_61": "Dominica",
    "country_62": "Dominikanische Republik",
    "country_63": "Ecuador",
    "country_64": "Ägypten",
    "country_65": "El Salvador",
    "country_66": "Äquatorialguinea",
    "country_67": "Eritrea",
    "country_68": "Estland",
    "country_69": "Äthiopien",
    "country_70": "Falkland Inseln",
    "country_71": "Färöer Inseln",
    "country_72": "Die Verbündeten Staaten von Micronesia",
    "country_73": "Fidschi",
    "country_74": "Finnland",
    "country_75": "Frankreich",
    "country_76": "Französisch-Guayana",
    "country_77": "Französisch Polynesien",
    "country_78": "Südfranzösische Territorien",
    "country_79": "Gabun",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Deutschland",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Großbritannien (Vereinigtes Königreich; England)",
    "country_86": "Griechenland",
    "country_87": "Grönland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Inseln und McDonald Inseln",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Ungarn",
    "country_101": "Island",
    "country_102": "Indien",
    "country_103": "Indonesien",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irland",
    "country_107": "Isle of Man",
    "country_108": "Israel",
    "country_109": "Italien",
    "country_110": "Jamaika",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordanien",
    "country_114": "Kasachstan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgisistan",
    "country_119": "Laos",
    "country_120": "Lettland",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libyen",
    "country_125": "Liechtenstein",
    "country_126": "Litauen",
    "country_127": "Luxemburg",
    "country_128": "Macau",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Malediven",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshallinseln",
    "country_136": "Martinique",
    "country_137": "Mauretanien",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldawien",
    "country_142": "Monaco",
    "country_143": "Mongolei",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marokko",
    "country_147": "Mosambik",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Niederlande",
    "country_153": "Neu-Kaledonien",
    "country_154": "Neuseeland",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolkinsel",
    "country_160": "Nord Korea",
    "country_161": "Nördliche Marianneninseln",
    "country_162": "Norwegen",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palästinensische Gebiete",
    "country_167": "Panama",
    "country_168": "Papua Neu-Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairninseln",
    "country_172": "Polen",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Republik Mazedonien (FYROM)",
    "country_177": "Republik Kongo",
    "country_178": "Wiedervereinigung",
    "country_179": "Rumänien",
    "country_180": "Russische Föderation",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Frankreich)",
    "country_184": "Saint-Pierre und Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "São Tomé und Príncipe",
    "country_188": "Saudi-Arabien",
    "country_189": "Senegal",
    "country_190": "Serbien",
    "country_191": "Seychellen",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Slowakei",
    "country_195": "Slowenien",
    "country_196": "Salomon-Inseln",
    "country_197": "Somalia",
    "country_198": "Südafrika",
    "country_199": "Südgeorgien und die Südliche Sandwichinsel",
    "country_200": "Südkorea",
    "country_201": "Südsudan",
    "country_202": "Spanien",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena & Nebengebiete",
    "country_205": "St. Kitts & Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent & die Grenadinen",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swasiland",
    "country_211": "Schweden",
    "country_212": "Schweiz",
    "country_213": "Syrien",
    "country_214": "Taiwan",
    "country_215": "Tadschikistan",
    "country_216": "Tansania",
    "country_217": "Vorlage:Länderdaten SJM Spitzbergen",
    "country_218": "Thailand",
    "country_219": "Die Bahamas",
    "country_220": "Die Komoren",
    "country_221": "Die Phillipinen",
    "country_222": "Timor-Leste (Osttimor)",
    "country_223": "Gehen",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunesien",
    "country_228": "Truthahn",
    "country_229": "Turkmenistan",
    "country_230": "Turks- & Caicosinseln",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraine",
    "country_234": "Vereinigte Arabische Emirate",
    "country_235": "Kleinere abgelegene Inseln der Vereinigten Staaten",
    "country_236": "Vereinigte Staaten von Amerika (USA)",
    "country_237": "Amerikanische Jungferninseln",
    "country_238": "Uruguay",
    "country_239": "Usbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikanstadt (der Heilige Stuhl)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis und Futuna",
    "country_245": "Westsahara",
    "country_246": "Jemen",
    "country_247": "Sambia",
    "country_248": "Zimbabwe",
    "google_login": "Anmeldung mit Google",
    "State": "Zustand",
    "Activation_code": "Aktivierungscode",
    "Question": "Alle Apps auflisten, bei denen Sie sich angemeldet haben",
    "Copy_complete": "Kopie abgeschlossen",
    "footer": "Copyright © 2024 MindOnMap Studio. Alle Rechte vorbehalten.",
    "change_password_success": "Das Passwort wurde erfolgreich geändert",
    "successful_login_title": "Erfolgreich angemeldet",
    "product_page": "Produktseite>>",
    "successful_login_info": "Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und gehen Sie zurück zur ursprünglichen Registerkarte, um mit dem folgenden Vorgang fortzufahren. Die aktuelle Seite wird in 5 Sekunden automatisch geschlossen. Wenn das automatische Schließen oder Schließen durch Klicken auf die Schaltfläche \"Fertig\" fehlschlägt, schließen Sie diese Registerkarte bitte direkt.",
    "successful_login_info_firefox": "Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und kehren Sie zur ursprünglichen Registerkarte zurück, um mit dem folgenden Vorgang fortzufahren.",
    "my_account": "Mein Konto",
    "my_history": "Meine Geschichte",
    "remove_watermark": "Wasserzeichen entfernen",
    "no_history": "Keine Geschichte",
    "history_all": "Alle wählen",
    "history_open": "Offen",
    "history_down": "Herunterladen",
    "history_delete": "Löschen",
    "history_clear": "Ungültig löschen",
    "images": "Bild(er)",
    "use_this_function": "Dieses Produkt verwenden>>",
    "hd_downloading": "Vorteile des Herunterladens von HD-Originalbildern:",
    "lifetimeRemaining": "Restlebensdauer",
    "Remaining": "Übrig",
    "email_verification": "E-Mail-Verifizierung",
    "email_verification_info": "Wir haben die Verifizierungs-E-Mail an Ihre E-Mail-Adresse <span class=\"email\"></span> gesendet. Bitte schließen Sie die Verifizierung ab. Danach werden die Vorteile automatisch synchronisiert.",
    "wrong_email": "Falsche E-Mail Adresse?",
    "click_here_to_modify": "Klicken Sie hier, um Änderungen vorzunehmen",
    "get_more_help": "Weitere Hilfe bekommen?",
    "click_here": "Klicken Sie hier",
    "email_verification_info_success": "Herzlichen Glückwunsch zur Verifizierung Ihres E-Mail-Kontos.",
    "email_verification_info_error": "Die Verifizierung ist aufgrund des abgelaufenen Links fehlgeschlagen.",
    "registration_succeeded": "Registrierung erfolgreich",
    "registration_succeeded_info_1": "Herzlichen Glückwunsch! Sie haben sich erfolgreich registriert. Wir haben die Verifizierungsnachricht an Ihre E-Mail-Adresse <span class=\"email\"></span> gesendet. Bitte schließen Sie die Verifizierung ab, um die Vorteile dieser E-Mail zu nutzen.",
    "registration_succeeded_info_2": "Klicken Sie auf \"Fertig\", um zur Startseite zurückzukehren und dieses Produkt zu verwenden.",
    "registration_succeeded_info_3": "Klicken Sie auf \"Fertig\", um die aktuelle Seite zu schließen und für die folgenden Vorgänge zur Startseite zurückzukehren. Wenn das Schließen fehlschlägt, schließen Sie die Registerkarte bitte manuell.",
    "verify_email": "E-Mail verifizieren",
    "registered_email_not_verified": "Die registrierte E-Mail wurde nicht verifiziert. Bitte bestätigen Sie sie sofort.",
    "email_verification_time_1": "Sie haben keine Bestätigungs-E-Mail erhalten?",
    "email_verification_time_2": "Nach",
    "email_verification_time_3": "Klicken Sie hier, um es erneut zu senden",
    "error_26301": "Fehlercode: 26301, bitte kontaktieren Sie uns",
    "error_26302": "Fehlercode: 26302, bitte kontaktieren Sie uns",
    "error_26303": "Fehler beim E-Mail-Format (Fehlercode: 26303). Bitte geben Sie es erneut ein",
    "error_26304": "Das Passwort muss länger als 8 Zeichen sein (Fehlercode: 26304)",
    "error_26305": "Anfrage fehlgeschlagen (Fehlercode: 26305). Bitte versuchen Sie es erneut",
    "error_26306": "Die E-Mail wurde registriert, bitte <a href=\"https://account.mindonmap.com/login/\">gehen Sie zur Anmeldung</a>",
    "error_26307": "Anfrage fehlgeschlagen (Fehlercode: 26307). Bitte versuchen Sie es erneut",
    "error_26308": "Anfrage fehlgeschlagen (Fehlercode: 26308). Bitte versuchen Sie es erneut",
    "error_26401": "Fehlercode: 26401, bitte versuchen Sie es erneut",
    "error_26402": "E-Mail wurde verifiziert (Fehlercode: 26402), bitte versuchen Sie es erneut",
    "error_26403": "Anfrage fehlgeschlagen (Fehlercode: 26403). Bitte versuchen Sie es erneut",
    "error_26404": "Anfrage fehlgeschlagen (Fehlercode: 26404). Bitte versuchen Sie es erneut",
    "error_26501": "Fehlercode: 26501, bitte kontaktieren Sie uns",
    "error_26502": "Fehlercode: 26502, bitte kontaktieren Sie uns",
    "error_26503": "Fehler beim E-Mail-Format (Fehlercode: 26503). Bitte geben Sie es erneut ein",
    "error_26504": "Anfrage fehlgeschlagen (Fehlercode: 26504). Bitte versuchen Sie es erneut",
    "error_26505": "Die E-Mail wurde nicht registriert, bitte <a href=\"https://account.mindonmap.com/register/\">registrieren Sie sie zuerst</a>",
    "error_26506": "Die E-Mail wurde bereits verifiziert.",
    "error_26507": "Anfrage fehlgeschlagen (Fehlercode: 26507). Bitte versuchen Sie es erneut",
    "error_26508": "Verifizierung fehlgeschlagen (Fehlercode: 26508), bitte versuchen Sie es erneut",
    "error_26509": "Anforderung fehlgeschlagen (Fehlercode: 26509), bitte versuchen Sie es erneut",
    "error_26510": "Fehlercode: 26510, bitte kontaktieren Sie uns",
    "error_26601": "Fehlercode: 26601, bitte kontaktieren Sie uns",
    "error_26602": "Fehlercode: 26602, bitte kontaktieren Sie uns",
    "error_26603": "Anfrage fehlgeschlagen (Fehlercode: 26603). Bitte versuchen Sie es erneut",
    "error_26604": "Fehlercode: 26604, bitte kontaktieren Sie uns",
    "error_26605": "Fehlercode: 26605, bitte kontaktieren Sie uns",
    "error_26701": "Fehlercode: 26701, bitte kontaktieren Sie uns",
    "error_26702": "Anfrage fehlgeschlagen (Fehlercode: 26702). Bitte versuchen Sie es erneut",
    "error_26703": "Fehlercode: 26703, bitte kontaktieren Sie uns",
    "error_26704": "Fehlercode: 26704, bitte kontaktieren Sie uns",
    "error_26705": "Auf die Anmeldung warten (Fehlercode: 26705). Bitte versuchen Sie es erneut",
    "no_cookie": "Sie haben in Ihrem Browser die Funktion \"Alle Cookies blockieren\" aktiviert, deshalb können Sie sich nicht anmelden. Gehen Sie bitte zu den Einstellungen, um das Kontrollkästchen \"Alle Cookies zulassen\" zu aktivieren.",
    "error_26801": "Fehlercode: 26801, bitte kontaktieren Sie uns",
    "error_26802": "Fehlercode: 26802, bitte kontaktieren Sie uns",
    "error_26803": "Anforderung fehlgeschlagen (Fehlercode: 26803). Bitte versuchen Sie es erneut",
    "error_26804": "Anfrage fehlgeschlagen (Fehlercode: 26804). Bitte versuchen Sie es erneut",
    "error_order": "Anfrage fehlgeschlagen (Fehlercode: 27098), bitte versuchen Sie es erneut!",
    "error_order1": "Die Bestellanfrage ist nicht abgeschlossen (Fehlercode:",
    "error_order2": "）, aktualisieren Sie bitte und versuchen Sie es erneut.",
    "modify_email_title": "E-Mail ändern",
    "modify_email_info": "Mit der geänderten E-Mail können Sie sich bei Ihrem Konto anmelden.",
    "images_per": "Bilder pro",
    "error_26101": "Fehler: 26101. Bitte kontaktieren Sie uns",
    "error_26102": "Fehler: 26102. Bitte kontaktieren Sie uns",
    "error_26103": "Anfrage fehlgeschlagen (Fehlercode: 26103). Bitte versuchen Sie es erneut.",
    "error_26104": "Fehlercode: 26104, bitte versuchen Sie es erneut",
    "error_26105": "Fehlercode: 26105, bitte versuchen Sie es erneut",
    "error_26106": "Löschen fehlgeschlagen (Fehlercode: 26106). Bitte versuchen Sie es erneut.",
    "error_26201": "Fehler: 26201. Bitte kontaktieren Sie uns",
    "error_26202": "Anfrage fehlgeschlagen (Fehlercode: 26202). Bitte versuchen Sie es erneut.",
    "error_26001": "Fehler: 26001. Bitte kontaktieren Sie uns",
    "error_26002": "Fehler: 26002. Bitte kontaktieren Sie uns",
    "error_26003": "Fehler: 26003. Bitte kontaktieren Sie uns",
    "error_26004": "Fehler: 26004. Bitte kontaktieren Sie uns",
    "error_26005": "Anfrage fehlgeschlagen (Fehlercode: 26005). Bitte versuchen Sie es erneut.",
    "error_26006": "Fehlercode: 26006, bitte versuchen Sie es erneut",
    "error_26008": "Fehler: 26008. Bitte kontaktieren Sie uns",
    "go_to_the_home_page": "Zur Startseite",
    "error_27101": "Anforderung fehlgeschlagen (Fehlercode: 27101). Bitte versuchen Sie es erneut.",
    "error_27201": "Fehlercode: 27201, bitte kontaktieren Sie uns",
    "error_27202": "Fehlercode: 27202, bitte versuchen Sie es erneut",
    "error_27203": "Anforderung fehlgeschlagen (Fehlercode: 27203). Bitte versuchen Sie es erneut",
    "error_27204": "Ungültiger Code (Fehlercode: 27204).",
    "error_27205": "Anforderung fehlgeschlagen (Fehlercode: 27205). Bitte versuchen Sie es erneut",
    "error_27206": "Anfrage fehlgeschlagen (Fehlercode: 27206). Bitte versuchen Sie es erneut",
    "error_27207": "Anfrage fehlgeschlagen (Fehlercode: 27207). Bitte versuchen Sie es erneut",
    "no_history_found": "Sie haben kein Tool verwendet! <a href=\"/\">Aktualisieren</a> oder <a href=\"https://www.mindonmap.com/\">Zur offiziellen Website gehen</a>",
    "error_25301": "Fehler: 25301. Bitte kontaktieren Sie uns",
    "error_25302": "Fehler: 25302. Bitte kontaktieren Sie uns",
    "error_25303": "Anforderung fehlgeschlagen (Fehlercode: 25303). Bitte versuchen Sie es erneut.",
    "error_25304": "Anforderung fehlgeschlagen (Fehlercode: 25304). Bitte versuchen Sie es erneut.",
    "error_25305": "Anforderung fehlgeschlagen (Fehlercode: 25305). Bitte versuchen Sie es erneut.",
    "error_25306": "Anforderung fehlgeschlagen (Fehlercode: 25306). Bitte versuchen Sie es erneut.",
    "image_upscaler_p": "Vorteile des Herunterladens von Bildern ohne Wasserzeichen:",
    "Available_for": "Verfügbar für:",
    "credit_per": "%s Punkt(e) pro HD-Bild",
    "still_valid": "Gekaufte(r) Plan(e) ist/sind noch gültig",
    "credit": "Punkt (e)",
    "pc_3rd_info": "Anmeldung erfolgreich. Für weitere Verwendung gehen Sie bitte zur Desktop-Software.",
    "use_online": "Den Online-Dienst verwenden",
    "use_download": "Die Desktop-Software verwenden",
    "use_immediately": "Sofort verwenden",
    "Use_in_browser": "Im Browser verwenden",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Punkte pro Monat",
    "expire": "Ablaufzeit:",
    "viewDetails": "Details anzeigen",
    "viewHistory": "Vorteileverlauf anzeigen>>",
    "viewDetailsInfo": "Hinweis: Werden die Abonnementvorteile innerhalb von 7 Tagen nach Ablauf verlängert, können die nicht genutzten Vorteile weiterhin genutzt werden. Zudem wird der Ablaufzeitpunkt automatisch auf den Ablaufzeitpunkt des neuen Abonnements aktualisiert. Wird innerhalb von 7 Tagen nach Ablaufzeit kein neues Abonnement abgeschlossen, werden alle abgelaufenen Vorteile gelöscht.",
    "connect_account": "E-Mail mit Ihrem Konto verknüpfen",
    "connect_account_info": "Nach der Verknüpfung können Sie sich mit dieser E-Mail-Adresse anmelden.",
    "connect_now": "Jetzt verknüpfen",
    "no_email_bind": "Keine E-Mail verknüpft",
    "bind_email": "E-Mail verknüpfen",
    "connect_your_email_placeholder": "Geben Sie bitte Ihre Email-Adresse ein",
    "bind_an_email": "Eine E-Mail verknüpfen",
    "bind_info": "Sie haben sich erfolgreich angemeldet. Bitte verknüpfen Sie eine E-Mail mit Ihrem Konto, um Ihre Vorteile zu aktivieren.",
    "bind_later": "Später verknüpfen",
    "hi": "Hallo, %s!",
    "Personal_Information": "Persönliche informationen",
    "Access": "Zugang",
    "Subscription_Plan": "(Abonnementplan)",
    "No_orders": "Keine Bestellungen gefunden.",
    "No_data": "Keine Daten",
    "Featured_Products": "Empfohlene Produkte",
    "More_Products": "Mehr Produkte",
    "Free_Download": "Free Download",
    "Get_Started": "Starten",
    "Subscribe": "Abonnieren",
    "Verified": "Verifiziert",
    "back_to_account_center": "Zurück zum Konto-Zentrum",
    "success": "Erfolgreich!",
    "successfully": "Sie haben erfolgreich ein Konto registriert.",
    "Continue": "Weiter",
    "Already": "Sie haben bereits ein Konto?",
    "loading_verification": "Verifizierungsstatus überprüfen...",
    "email_no_verification": "Leider konnte die registrierte E-Mail-Adresse nicht verifiziert werden. Bitte führen Sie die Verifizierung nach der obigen Anleitung durch und klicken Sie erneut auf \"Fertig\", um die Registrierung abzuschließen.",
    "will_expire_after": "Läuft ab nach",
    "hours": "Stunden",
  },
  "el": {
    "overtime": "Κωδικός σφάλματος: {%}, δοκιμάστε το ξανά",
    "isnetwork": "Σφάλμα Διαδικτύου. Ελέγξτε και δοκιμάστε ξανά",
    "email_placeholder": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    "email_empty": "Παρακαλώ εισάγετε email",
    "email_not_valid": "Το email δεν είναι έγκυρο",
    "email_not_valid_1": "Παρακαλώ εισάγετε το email σας",
    "email_not_valid_2": "Το email δεν είναι έγκυρο, χρησιμοποιήστε διαφορετική διεύθυνση.",
    "email_not_valid_3": "Δεν υπάρχει εισαγωγή email",
    "password_placeholder": "Κωδικός πρόσβασης",
    "password_empty": "Εισαγάγετε κωδικό πρόσβασης",
    "password_not_valid": "Λανθασμένος λογαριασμός ή κωδικός πρόσβασης",
    "password_not_valid_1": "Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων",
    "password_not_valid_2": "Δημιουργήστε έναν κωδικό πρόσβασης",
    "password_placeholder_1": "Δημιούργησε τον κωδικό σου",
    "password_placeholder_2": "Επιβεβαιώστε τον κωδικό πρόσβασής σας",
    "password_placeholder_3": "Δημιουργία νέου κωδικού πρόσβασης",
    "password_placeholder_4": "Επιβεβαιώστε τον καινούριο σας κωδικό",
    "password_placeholder_5": "Εισαγάγετε τον παλιό κωδικό πρόσβασης",
    "copy_password_empty": "Επιβεβαιώστε τον κωδικό πρόσβασης",
    "copy_password_not_valid": "Επιβεβαιώστε τον κωδικό πρόσβασής σας",
    "copy_passwords_inconsistent": "Η επιβεβαίωση του κωδικού πρόσβασής σας δεν ταιριάζει",
    "code_empty": "Εισαγάγετε τον κωδικό επαλήθευσης",
    "code_not_valid": "Μη έγκυρος κωδικός επαλήθευσης",
    "code_placeholder": "Κωδικός επαλήθευσης",
    "not_received_code": "Εάν το γραμματοκιβώτιό σας δεν έχει λάβει τον κωδικό επαλήθευσης για μεγάλο χρονικό διάστημα, λάβετε ξανά τον κωδικό επαλήθευσης.",
    "get_first_code": "Λάβετε πρώτα τον κωδικό επαλήθευσης.",
    "last_name_placeholder": "Παρακαλώ εισάγετε το επώνυμό σας",
    "first_name_placeholder": "Παρακαλώ εισάγετε το ονομα σας",
    "address_placeholder": "Παρακαλώ εισάγετε τη διεύθυνσή σας",
    "no_code_text": "Έχουμε στείλει έναν κωδικό επαλήθευσης. Εισαγάγετε τον κωδικό σας. <span class='tips'>Δεν λάβατε κωδικό;",
    "no_code_text_1": "1. Βεβαιωθείτε ότι η διεύθυνση email είναι έγκυρη και ότι μπορεί να λαμβάνει email.",
    "no_code_text_2": "2. Επειδή το email αποστέλλεται αυτόματα από το σύστημα, μπορεί να επισημανθεί ως ανεπιθύμητο ή ανεπιθύμητο email. Ελέγξτε εάν το email βρίσκεται στον φάκελο Κάδος απορριμμάτων.",
    "no_code_text_3": "3. Δεν μπορείτε να λύσετε το πρόβλημά σας; ",
    "no_code_text_3_span": "Στη συνέχεια, κάντε κλικ εδώ για να επικοινωνήσετε μαζί μας.",
    "order_no": "Δεν έχετε αγοράσει κανένα προϊόν, Εάν έχετε οποιαδήποτε ερώτηση, <a href=\"https://www.mindonmap.com/contact-us/\">επικοινωνήστε μαζί μας</a>.",
    "error_24901": "Ο τρέχων λογαριασμός δεν έχει συνδεδεμένο email και δεν μπορεί να βρει τις παραγγελίες. Συνδέστε ένα email.",
    "user_guide": "Οδηγός χρήσης >>",
    "download": "Κατεβάστε",
    "order_number": "Αριθμός παραγγελίας:",
    "Refund": "Επιστροφή χρημάτων",
    "Disabled": "άτομα με ειδικές ανάγκες",
    "Normal": "Κανονικός",
    "Modify": "Τροποποιώ",
    "Modify_1": "Τροποποίηση >>",
    "Connect": "Συνδέω-συωδεομαι",
    "unlink_success": "Αποσύνδεση με επιτυχία",
    "connect_success": "Συνδεθείτε με επιτυχία",
    "feedback_title": "Ευχαριστούμε για την ανταπόκριση σας. Αφήστε το πρόβλημά σας και θα σας απαντήσουμε εντός 24 ωρών.",
    "feedback_thank_you": "Σας ευχαριστούμε!<br />Τα σχόλιά σας υποβλήθηκαν με επιτυχία.",
    "feedback_email": "Εισαγάγετε το email σας εδώ!",
    "feedback_content": "Αφήστε οποιοδήποτε πρόβλημα ή πρόταση αντιμετωπίσατε εδώ.",
    "feedback_submit": "υποβάλλουν",
    "form_contents": "Δεν έχετε εισαγάγει καμία περιγραφή. Εισαγάγετε το και υποβάλετε ξανά.",
    "old_password": "Εισαγάγετε τον παλιό κωδικό πρόσβασης",
    "new_password": "Δημιουργήστε νέο κωδικό πρόσβασης",
    "old_new_password": "Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό",
    "incorrect_password": "Λάθος κωδικός",
    "delete_no": "Διαγραφή τώρα",
    "Caps": "Το Caps lock είναι ενεργοποιημένο",
    "Get": "Παίρνω",
    "Done": "Εγινε",
    "error_20001": "Σφάλμα: 20001. Συνδεθείτε ξανά.",
    "error_20002": "Σφάλμα: 20002. Συνδεθείτε ξανά.",
    "error_20003": "Σφάλμα: 20003. Συνδεθείτε ξανά.",
    "error_20004": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20004). Δοκιμάστε το ξανά.",
    "error_20005": "Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20005). Παρακαλούμε συνδεθείτε ξανά.",
    "error_20006": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20006). Δοκιμάστε το ξανά.",
    "error_20007": "Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20007). Παρακαλούμε συνδεθείτε ξανά.",
    "error_20008": "Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20008). Παρακαλούμε συνδεθείτε ξανά.",
    "error_20009": "Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20009). Παρακαλούμε συνδεθείτε ξανά.",
    "error_20101": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20101)",
    "error_20102": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20102)",
    "error_20103": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20103). Δοκιμάστε το ξανά",
    "error_20104": "Το email χρησιμοποιείται ήδη, <a href=\"https://account.mindonmap.com/login/\">συνδεθείτε</a> ή εγγραφείτε με νέο",
    "error_20105": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20105). Δοκιμάστε το ξανά",
    "error_20106": "Αποτυχία αποστολής email, δοκιμάστε το ξανά",
    "error_20201": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20201)",
    "error_20202": "Εισαγάγετε τον κωδικό πρόσβασής σας (κωδικός σφάλματος: 20202)",
    "error_20203": "Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20203)",
    "error_20204": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20204)",
    "error_20205": "Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20205)",
    "error_20206": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20206). Δοκιμάστε το ξανά",
    "error_20207": "Μη έγκυρος κωδικός επαλήθευσης",
    "error_20208": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20208). Δοκιμάστε το ξανά",
    "error_20209": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20209). Δοκιμάστε το ξανά",
    "error_20301": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20301)",
    "error_20302": "Σφάλμα: 20302. Επικοινωνήστε μαζί μας",
    "error_20303": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20303)",
    "error_20304": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20304). Δοκιμάστε το ξανά",
    "error_20305": "Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href=\"https://account.mindonmap.com/register/\">Δημιουργήστε το</a> πρώτα.",
    "error_20306": "Δεν υπάρχει κωδικός πρόσβασης ακόμα. Χρησιμοποιήστε <a href=\"https://account.mindonmap.com/passwordless-login/\">Σύνδεση χωρίς κωδικό</a> ή <a href=\"https://account.mindonmap.com/create-password/\"> ορίστε έναν κωδικό πρόσβασης</a> και συνδεθείτε.",
    "error_20308": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20308). Δοκιμάστε το ξανά",
    "error_20401": "Αποτυχία αποσύνδεσης (κωδικός σφάλματος: 20401). Δοκιμάστε το ξανά",
    "error_20501": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20501)",
    "error_20502": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20502)",
    "error_20503": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20503). Δοκιμάστε το ξανά",
    "error_20504": "Αποτυχία αποστολής email. Δοκιμάστε το ξανά.",
    "error_20601": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20601)",
    "error_20602": "Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20602)",
    "error_20603": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20603)",
    "error_20604": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20604). Δοκιμάστε το ξανά",
    "error_20606": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20606). Δοκιμάστε το ξανά",
    "error_20607": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20607). Δοκιμάστε το ξανά",
    "error_20608": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20608). Δοκιμάστε το ξανά",
    "error_20701": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20701)",
    "error_20702": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20702)",
    "error_20703": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20703). Δοκιμάστε το ξανά",
    "error_20704": "Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href=\"https://account.mindonmap.com/register/\">Δημιουργήστε το</a> πρώτα.",
    "error_20705": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20705). Δοκιμάστε το ξανά",
    "error_20706": "Αποτυχία αποστολής email. Δοκιμάστε το ξανά",
    "error_20801": "Εισαγάγετε το email σας (κωδικός σφάλματος: 20801)",
    "error_20802": "Σφάλμα: 20802. Επικοινωνήστε μαζί μας",
    "error_20803": "Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20803)",
    "error_20804": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20804)",
    "error_20805": "Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20805)",
    "error_20806": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20806). Δοκιμάστε το ξανά",
    "error_20808": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20808). Δοκιμάστε το ξανά",
    "error_20901": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20901). Δοκιμάστε το ξανά",
    "error_20902": "Το αίτημα απέτυχε (κωδικός σφάλματος: 20902). Δοκιμάστε το ξανά",
    "error_21000": "Οι αλλαγές αποθηκεύονται",
    "error_21001": "Δεν υποβάλλονται πληροφορίες (κωδικός σφάλματος: 21001)",
    "error_21002": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21002). Δοκιμάστε το ξανά",
    "error_21101": "Εισαγάγετε το email σας (κωδικός σφάλματος: 21101)",
    "error_21102": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21102)",
    "error_21103": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21103), δοκιμάστε το ξανά",
    "error_21104": "Το email είναι ήδη συνδεδεμένο, χρησιμοποιήστε ένα νέο",
    "error_21105": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21105), δοκιμάστε το ξανά",
    "error_21106": "Αποτυχία αποστολής email. Δοκιμάστε το ξανά",
    "error_21201": "Εισαγάγετε το email σας (κωδικός σφάλματος: 21201)",
    "error_21202": "Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21202)",
    "error_21203": "Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21203)",
    "error_21204": "Σφάλμα: 21204. Επικοινωνήστε μαζί μας",
    "error_21205": "Σφάλμα: 21205. Επικοινωνήστε μαζί μας",
    "error_21206": "Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21206)",
    "error_21207": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21207). Δοκιμάστε το ξανά",
    "error_21209": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21209). Δοκιμάστε το ξανά",
    "error_21301": "Εισαγάγετε τον παλιό κωδικό πρόσβασης (κωδικός σφάλματος: 21301)",
    "error_21302": "Δημιουργήστε νέο κωδικό πρόσβασης (κωδικός σφάλματος: 21302)",
    "error_21303": "Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό. (Σφάλμα: 21303)",
    "error_21304": "Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21304)",
    "error_21306": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21306). Δοκιμάστε το ξανά",
    "error_21402": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21402). Δοκιμάστε το ξανά",
    "error_21403": "Αποτυχία αποστολής κωδικού επαλήθευσης. Στείλτε το ξανά",
    "error_21500": "Ο λογαριασμός έχει διαγραφεί",
    "error_21501": "Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21501)",
    "error_21502": "Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 21502). Παρακαλούμε συνδεθείτε ξανά.",
    "error_21503": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21503). Δοκιμάστε το ξανά",
    "error_21505": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21505), δοκιμάστε το ξανά",
    "error_21601": "Σφάλμα: 20601. Επικοινωνήστε μαζί μας",
    "error_21602": "Μη έγκυρη επαλήθευση (Σφάλμα: 20602). Δοκιμάστε το ξανά.",
    "error_21603": "Σφάλμα: 20603. Δοκιμάστε το ξανά",
    "error_21604": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21604). Δοκιμάστε το ξανά",
    "error_21606": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21606). Δοκιμάστε το ξανά",
    "error_21611": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21611). Δοκιμάστε το ξανά",
    "error_21801": "Σφάλμα: 21801. Επικοινωνήστε μαζί μας",
    "error_21802": "Το αίτημα απέτυχε (Σφάλμα: 21802). Δοκιμάστε το ξανά",
    "error_21803": "Σφάλμα: 21803. Δοκιμάστε το ξανά",
    "error_21804": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21804). Δοκιμάστε το ξανά",
    "error_21806": "Σφάλμα: 21806. Δοκιμάστε το ξανά",
    "error_21807": "Σφάλμα: 21807. Επικοινωνήστε μαζί μας",
    "error_21808": "Σφάλμα: 21808. Επικοινωνήστε μαζί μας",
    "error_21809": "Σφάλμα: 21809. Επικοινωνήστε μαζί μας",
    "error_21810": "Σφάλμα: 21810. Επικοινωνήστε μαζί μας",
    "error_21811": "Σφάλμα: 21811. Επικοινωνήστε μαζί μας",
    "error_21812": "Σφάλμα: 21812. Επικοινωνήστε μαζί μας",
    "error_21813": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21813). Δοκιμάστε το ξανά",
    "error_21814": "Σφάλμα: 21814. Επικοινωνήστε μαζί μας",
    "error_21815": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21815). Δοκιμάστε το ξανά",
    "error_21816": "Σφάλμα: 21816. Επικοινωνήστε μαζί μας",
    "error_21817": "Σφάλμα: 21817. Επικοινωνήστε μαζί μας",
    "error_21818": "Σφάλμα: 21818. Επικοινωνήστε μαζί μας",
    "error_21819": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21819). Δοκιμάστε το ξανά",
    "error_21820": "Σφάλμα: 21820. Επικοινωνήστε μαζί μας",
    "error_21821": "Σφάλμα: 21821. Επικοινωνήστε μαζί μας",
    "error_21822": "Σφάλμα: 21822. Επικοινωνήστε μαζί μας",
    "error_21823": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21823). Δοκιμάστε το ξανά",
    "error_21824": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21824). Δοκιμάστε το ξανά",
    "error_21825": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21825). Δοκιμάστε το ξανά",
    "error_21826": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21826). Δοκιμάστε το ξανά",
    "error_21901": "Σφάλμα: 21901. Επικοινωνήστε μαζί μας",
    "error_21902": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21902). Δοκιμάστε το ξανά",
    "error_21903": "Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 21903), ανανεώστε τη σελίδα και δοκιμάστε ξανά",
    "error_21904": "Σφάλμα: 21904. Δοκιμάστε το ξανά",
    "error_21905": "Σφάλμα: 21905. Δοκιμάστε το ξανά",
    "error_21906": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21906). Δοκιμάστε το ξανά",
    "error_21907": "Ο λογαριασμός Google έχει συνδεθεί με άλλον λογαριασμό",
    "error_21908": "Το αίτημα απέτυχε (κωδικός σφάλματος: 21908). Δοκιμάστε το ξανά",
    "error_22001": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22001). Δοκιμάστε το ξανά",
    "error_22002": "Η αποσύνδεση απέτυχε χωρίς πρόσθετη σύνδεση",
    "error_22003": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22003). Δοκιμάστε το ξανά",
    "error_22101": "Σφάλμα: 22101. Επικοινωνήστε μαζί μας",
    "error_22102": "Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22102), ανανεώστε τη σελίδα και δοκιμάστε ξανά",
    "error_22103": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22103). Δοκιμάστε το ξανά",
    "error_22104": "Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22104), ανανεώστε τη σελίδα και δοκιμάστε ξανά",
    "error_22105": "Σφάλμα: 22105. Δοκιμάστε το ξανά",
    "error_22106": "Σφάλμα: 22106. Δοκιμάστε το ξανά",
    "error_22107": "Σφάλμα: 22107. Επικοινωνήστε μαζί μας",
    "error_22108": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22108). Δοκιμάστε το ξανά",
    "error_22201": "Σφάλμα: 22201. Επικοινωνήστε μαζί μας",
    "error_22202": "Μη έγκυρη επαλήθευση (Σφάλμα: 22202). Δοκιμάστε το ξανά.",
    "error_22203": "Σφάλμα: 22203. Δοκιμάστε το ξανά\"",
    "error_22204": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22204). Δοκιμάστε το ξανά",
    "error_22206": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22206). Δοκιμάστε το ξανά",
    "error_22401": "Σφάλμα: 22401. Επικοινωνήστε μαζί μας",
    "error_22402": "Μη έγκυρη επαλήθευση (Σφάλμα: 22402). Δοκιμάστε το ξανά.",
    "error_22403": "Σφάλμα: 22403. Δοκιμάστε το ξανά",
    "error_22404": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22404). Δοκιμάστε το ξανά",
    "error_22405": "Ο λογαριασμός Facebook έχει συνδεθεί με άλλο email",
    "error_22406": "Σφάλμα: 22406. Δοκιμάστε το ξανά",
    "error_22407": "Σφάλμα: 22407. Επικοινωνήστε μαζί μας",
    "error_22408": "Σφάλμα: 22408. Επικοινωνήστε μαζί μας",
    "error_22409": "Σφάλμα: 22409. Επικοινωνήστε μαζί μας",
    "error_22410": "Σφάλμα: 224010. Επικοινωνήστε μαζί μας",
    "error_22411": "Σφάλμα: 224011. Επικοινωνήστε μαζί μας",
    "error_22412": "Σφάλμα: 224012. Επικοινωνήστε μαζί μας",
    "error_22413": "Σφάλμα: 22413. Επικοινωνήστε μαζί μας",
    "error_22414": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22414). Δοκιμάστε το ξανά",
    "error_22415": "Σφάλμα: 22415. Επικοινωνήστε μαζί μας",
    "error_22416": "Σφάλμα: 22416. Επικοινωνήστε μαζί μας",
    "error_22417": "Σφάλμα: 22417. Επικοινωνήστε μαζί μας",
    "error_22418": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22418). Δοκιμάστε το ξανά",
    "error_22419": "Σφάλμα: 22419. Επικοινωνήστε μαζί μας",
    "error_22420": "Σφάλμα: 22420. Επικοινωνήστε μαζί μας",
    "error_22421": "Σφάλμα: 22421. Επικοινωνήστε μαζί μας",
    "error_22422": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22422). Δοκιμάστε το ξανά",
    "error_22423": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22423). Δοκιμάστε το ξανά",
    "error_22424": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22424). Δοκιμάστε το ξανά",
    "error_22425": "Το αίτημα απέτυχε (κωδικός σφάλματος: 22425). Δοκιμάστε το ξανά",
    "error_20098": "Κωδικός σφάλματος: 20098. Εάν κάνετε περιήγηση σε Ιδιωτική λειτουργία, μεταβείτε στην Κανονική λειτουργία και δοκιμάστε ξανά.",
    "error_22298": "Το αίτημα για Google απέτυχε (Σφάλμα: 22298). Δοκιμάστε το ξανά.",
    "error_22498": "Το αίτημα για Facebook απέτυχε (Σφάλμα: 22498). Δοκιμάστε το ξανά.",
    "error_24902": "Το αίτημα απέτυχε (κωδικός σφάλματος: 24902). Δοκιμάστε το ξανά",
    "error_24903": "Το αίτημα απέτυχε (κωδικός σφάλματος: 24903). Δοκιμάστε το ξανά",
    "error_24904": "Το αίτημα απέτυχε (κωδικός σφάλματος: 24904). Δοκιμάστε το ξανά",
    "error_24905": "Το αίτημα απέτυχε (κωδικός σφάλματος: 24905). Δοκιμάστε το ξανά",
    "login_title": "Συνδεθείτε στο MindOnMap",
    "log_in": "Σύνδεση",
    "no_account": "Δεν υπάρχει λογαριασμός;",
    "create_it": "Δημιουργησε το",
    "or_log_in_with": "Ή συνδεθείτε με",
    "passwordless_login": "Είσοδος χωρίς κωδικό πρόσβασης",
    "log_in_done": "Σύνδεση Ολοκληρώθηκε",
    "three_rd_account_connect_info": "Συγχαρητήρια! Έχετε συνδεθεί με επιτυχία. Τώρα μπορείτε να συνδέσετε έναν λογαριασμό email που χρησιμοποιείται για να συνδεθείτε στο μέλλον.",
    "see_my_account": "Δείτε τον λογαριασμό μου",
    "three_rd_login_merge_account": "Η διεύθυνση email του λογαριασμού τρίτου μέρους έχει εγγραφεί, θέλετε να συνδεθείτε και να συνδεθείτε απευθείας με αυτήν τη διεύθυνση email;",
    "connect_log_in": "Συνδεθείτε και συνδεθείτε",
    "create_an_account": "Δημιουργία λογαριασμού",
    "back_to_log_in": "Επιστροφή στην σελίδα εισόδου",
    "create_password": "Δημιούργησε κωδικό",
    "create_now": "Δημιουργία τώρα",
    "password_login_subtitle": "Είσοδος χωρίς κωδικό πρόσβασης με email",
    "account_login": "Είσοδος σε λογαριασμό",
    "rights": "Με τη δημιουργία αυτού του λογαριασμού, συμφωνείτε με τους <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Όρους Παροχής Υπηρεσιών</a> και τους <a href=\"https://www.mindonmap.com/privacy-policy/\">Πολιτική Απορρήτου</a>",
    "passwordless_login_done": "Η σύνδεση χωρίς κωδικό πρόσβασης ολοκληρώθηκε",
    "passwordless_login_info": "Συγχαρητήρια, ολοκληρώσατε με επιτυχία τη σύνδεση χωρίς κωδικό πρόσβασης. Μπορείτε να δημιουργήσετε έναν κωδικό πρόσβασης για αυτόν τον λογαριασμό και να συνδεθείτε με τον λογαριασμό και τον κωδικό πρόσβασης στο μέλλον. <a href=\"/create-password\" style=\"display: initial;\">Δημιουργία τώρα</a>",
    "sign_up": "Εγγραφείτε",
    "register_info": "Δημιουργήστε το λογαριασμό σας",
    "reset_now": "Επαναφορά τώρα",
    "forgot_password": "Ξεχάσατε τον κωδικό",
    "reset_password_subtitle": "Χρησιμοποιήστε το email του λογαριασμού σας για να επαναφέρετε τον κωδικό πρόσβασης",
    "plan_products": "Σχέδια & Προϊόντα",
    "nick_name": "Όνομα χρήστη:",
    "email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ:",
    "my_products": "Τα προϊόντα μου",
    "my_orders": "Οι παραγγελίες μου",
    "unlink": "Αποσύνδεση",
    "link": "Σύνδεσμος",
    "connected_accounts": "Συνδεδεμένοι λογαριασμοί",
    "last_name": "Επίθετο:",
    "first_name": "Ονομα:",
    "Gender": "Γένος:",
    "Birth": "Γέννηση:",
    "Month": "Μήνας",
    "Year": "Ετος",
    "Country_Region": "Χώρα/Περιοχή:",
    "Address": "Διεύθυνση:",
    "Save": "Αποθηκεύσετε",
    "Date": "Ημερομηνία",
    "Male": "Αρσενικός",
    "Female": "Θηλυκός",
    "Unspecified": "Απροσδιόριστος",
    "Security": "Ασφάλεια",
    "change_password": "Άλλαξε κωδικό",
    "change_now": "Άλλαξε τώρα",
    "connect_email": "Σύνδεση email",
    "delete_account": "Διαγραφή λογαριασμού",
    "delete_account_info": "Όταν ο λογαριασμός σας διαγραφεί, όλα τα δεδομένα στο My Account που σχετίζονται με τον λογαριασμό σας θα διαγραφούν οριστικά και ενδέχεται να μην μπορείτε να τα ανακτήσετε. Σας συνιστούμε να περπατάτε προσεκτικά.",
    "Delete": "Διαγράφω",
    "Logout": "Αποσύνδεση",
    "my_profile": "Το ΠΡΟΦΙΛ μου",
    "guides_faqs": "Οδηγοί και συχνές ερωτήσεις",
    "More": "Περισσότερο",
    "guides": "Οδηγοί",
    "register": "κανω ΕΓΓΡΑΦΗ",
    "hot_faq": "Καυτό FAQ",
    "Contents": "Περιεχόμενα:",
    "contact_us": "Επικοινωνήστε μαζί μας >>",
    "plan": "Σχέδιο",
    "unregistered": "Αδήλωτος",
    "buy_more": "Αγοράστε περισσότερα",
    "buy_again": "Αγοράστε ξανά",
    "buy_now": "Αγορασε τωρα",
    "free_no_limit": "Δωρεάν & Χωρίς όριο",
    "expired": "έχει λήξει",
    "lifetime": "Διάρκεια Ζωής",
    "remain": "Παραμένει",
    "day_s": "Ημέρες)",
    "error_24801": "Το αίτημα απέτυχε (κωδικός σφάλματος: 24801). Δοκιμάστε το ξανά",
    "no_app_found": "Δεν βρέθηκε εφαρμογή！<a href=\"/\">Ανανέωση</a> ή <a href=\"https://www.mindonmap.com/\">Μεταβείτε στον επίσημο ιστότοπο</a>",
    "get_more": "Λάβετε περισσότερα >>",
    "edit_photo": "Επεξεργασία φωτογραφίας",
    "select_photo": "Επιλέξτε φωτογραφία",
    "change_photo": "Αλλαξε φωτογραφία",
    "cancel": "Ματαίωση",
    "hide_password": "Απόκρυψη κωδικού πρόσβασης",
    "show_password": "Δείξε τον κωδικό",
    "zoom_in": "Μεγέθυνση",
    "zoom_out": "Σμίκρυνση",
    "rotate": "Γυρίζω",
    "horizontal_flip": "Οριζόντια ανατροπή",
    "vertical_flip": "Κατακόρυφη ανατροπή",
    "country": "Χώρα",
    "country_1": "Επιλέξτε τη χώρα/την περιοχή σας",
    "country_2": "Νήσος Åaland",
    "country_3": "Αφγανιστάν",
    "country_4": "Αλβανία",
    "country_5": "Αλγερία",
    "country_6": "Αμερικάνικη Σαμόα",
    "country_7": "Ανδόρα",
    "country_8": "Αγκόλα",
    "country_9": "Ανγκουίλα",
    "country_10": "Ανταρκτική",
    "country_11": "Αντίγκουα και Μπαρμπούντα",
    "country_12": "Αργεντίνη",
    "country_13": "Αρμενία",
    "country_14": "Αρούμπα",
    "country_15": "Αυστραλία",
    "country_16": "Αυστρία",
    "country_17": "Αζερμπαϊτζάν",
    "country_18": "Μπαχρέιν",
    "country_19": "Μπαγκλαντές",
    "country_20": "Μπαρμπάντος",
    "country_21": "Λευκορωσία",
    "country_22": "Βέλγιο",
    "country_23": "Μπελίζ",
    "country_24": "Μπενίν",
    "country_25": "Βερμούδα",
    "country_26": "Μπουτάν",
    "country_27": "Βολιβία",
    "country_28": "Βοσνία και Ερζεγοβίνη",
    "country_29": "Μποτσουάνα",
    "country_30": "Bouvet Island",
    "country_31": "Βραζιλία",
    "country_32": "Βρετανική Επικράτεια Ινδικού Ωκεανού",
    "country_33": "Βρετανικές Παρθένες Νήσοι",
    "country_34": "Μπρουνέι",
    "country_35": "Βουλγαρία",
    "country_36": "Μπουρκίνα",
    "country_37": "Μπουρούντι",
    "country_38": "Καμπότζη",
    "country_39": "Καμερούν",
    "country_40": "Καναδάς",
    "country_41": "Πράσινο Ακρωτήριο",
    "country_42": "Καραϊβική Ολλανδία",
    "country_43": "Νησιά Κέιμαν",
    "country_44": "Δημοκρατία Κεντρικής Αφρικής",
    "country_45": "Τσαντ",
    "country_46": "χιλή",
    "country_47": "Κίνα",
    "country_48": "Νησί των Χριστουγέννων",
    "country_49": "Νησιά Cocos (Keeling).",
    "country_50": "Κολομβία",
    "country_51": "Νησιά Κουκ",
    "country_52": "Κόστα Ρίκα",
    "country_53": "Ακτή Ελεφαντοστού",
    "country_54": "την Κροατία",
    "country_55": "Κούβα",
    "country_56": "Κύπρος",
    "country_57": "Τσεχική Δημοκρατία",
    "country_58": "Λαϊκή Δημοκρατία του Κονγκό",
    "country_59": "Δανία",
    "country_60": "Τζιμπουτί",
    "country_61": "Δομίνικα",
    "country_62": "Δομινικανή Δημοκρατία",
    "country_63": "Εκουαδόρ",
    "country_64": "Αίγυπτος",
    "country_65": "Ελ Σαλβαδόρ",
    "country_66": "Ισημερινή Γουινέα",
    "country_67": "Ερυθραία",
    "country_68": "Εσθονία",
    "country_69": "Αιθιοπία",
    "country_70": "Νησιά Φώκλαντ",
    "country_71": "Νήσοι Φερόες",
    "country_72": "Ομόσπονδα κράτη της Μικρονησίας",
    "country_73": "Φίτζι",
    "country_74": "Φινλανδία",
    "country_75": "Γαλλία",
    "country_76": "Γαλλική Γουιάνα",
    "country_77": "Γαλλική Πολυνησία",
    "country_78": "Γαλλικά Νότια Εδάφη",
    "country_79": "Γκαμπόν",
    "country_80": "Γκάμπια",
    "country_81": "Γεωργία",
    "country_82": "Γερμανία",
    "country_83": "Γκάνα",
    "country_84": "Γιβραλτάρ",
    "country_85": "Μεγάλη Βρετανία (Ηνωμένο Βασίλειο· Αγγλία)",
    "country_86": "Ελλάδα",
    "country_87": "Γροιλανδία",
    "country_88": "Γρενάδα",
    "country_89": "Γουαδελούπη",
    "country_90": "Γκουάμ",
    "country_91": "Γουατεμάλα",
    "country_92": "Γκέρνσεϊ",
    "country_93": "Γκινέα",
    "country_94": "Γουινέα-Μπισάου",
    "country_95": "Γουιάνα",
    "country_96": "Αΐτη",
    "country_97": "Heard Island και McDonald  Islands",
    "country_98": "Ονδούρα",
    "country_99": "Χονγκ Κονγκ",
    "country_100": "Ουγγαρία",
    "country_101": "Ισλανδία",
    "country_102": "Ινδία",
    "country_103": "Ινδονησία",
    "country_104": "Ιράν",
    "country_105": "Ιράκ",
    "country_106": "Ιρλανδία",
    "country_107": "Isle of Man",
    "country_108": "Ισραήλ",
    "country_109": "Ιταλία",
    "country_110": "Ιαμαϊκή",
    "country_111": "Ιαπωνία",
    "country_112": "Φανέλα",
    "country_113": "Ιορδανία",
    "country_114": "Καζακστάν",
    "country_115": "Κενύα",
    "country_116": "Κιριμπάτι",
    "country_117": "Κουβέιτ",
    "country_118": "Κιργιζιστάν",
    "country_119": "Λάος",
    "country_120": "Λετονία",
    "country_121": "Λίβανος",
    "country_122": "Λεσότο",
    "country_123": "Λιβερία",
    "country_124": "Λιβύη",
    "country_125": "Λιχτενστάιν",
    "country_126": "Λιθουανία",
    "country_127": "Λουξεμβούργο",
    "country_128": "Μακάο",
    "country_129": "Μαδαγασκάρη",
    "country_130": "Μαλάουι",
    "country_131": "Μαλαισία",
    "country_132": "Μαλδίβες",
    "country_133": "Μάλι",
    "country_134": "Μάλτα",
    "country_135": "Νήσοι Μάρσαλ",
    "country_136": "Μαρτινίκα",
    "country_137": "Μαυριτανία",
    "country_138": "Μαυρίκιος",
    "country_139": "Μαγιότ",
    "country_140": "Μεξικό",
    "country_141": "Μολδαβία",
    "country_142": "Μονακό",
    "country_143": "Μογγολία",
    "country_144": "Μαυροβούνιο",
    "country_145": "Μονσεράτ",
    "country_146": "Μαρόκο",
    "country_147": "Μοζαμβίκη",
    "country_148": "Μιανμάρ (Βιρμανία)",
    "country_149": "Ναμίμπια",
    "country_150": "Ναούρου",
    "country_151": "Νεπάλ",
    "country_152": "Ολλανδία",
    "country_153": "Νέα Καληδονία",
    "country_154": "Νέα Ζηλανδία",
    "country_155": "Νικαράγουα",
    "country_156": "Νίγηρας",
    "country_157": "Νιγηρία",
    "country_158": "Niue",
    "country_159": "Νήσος Νόρφολκ",
    "country_160": "Βόρεια Κορέα",
    "country_161": "Βορεια Νησια Μαριανα",
    "country_162": "Νορβηγία",
    "country_163": "Ομάν",
    "country_164": "Πακιστάν",
    "country_165": "Παλάου",
    "country_166": "παλαιστινιακά εδάφη",
    "country_167": "Παναμάς",
    "country_168": "Παπούα Νέα Γουινέα",
    "country_169": "Παραγουάη",
    "country_170": "Περού",
    "country_171": "Νησιά Πίτκερν",
    "country_172": "Πολωνία",
    "country_173": "Πορτογαλία",
    "country_174": "Πουέρτο Ρίκο",
    "country_175": "Κατάρ",
    "country_176": "Δημοκρατία της Μακεδονίας (FYROM)",
    "country_177": "Δημοκρατία του Κονγκό",
    "country_178": "Επανένωση",
    "country_179": "Ρουμανία",
    "country_180": "Ρωσική Ομοσπονδία",
    "country_181": "Ρουάντα",
    "country_182": "Άγιος Βαρθολομαίος",
    "country_183": "Saint Martin (Γαλλία)",
    "country_184": "Saint-Pierre και Miquelon",
    "country_185": "Σαμόα",
    "country_186": "Σαν Μαρίνο",
    "country_187": "Σάο Τομέ και Πρίνσιπε",
    "country_188": "Σαουδική Αραβία",
    "country_189": "Σενεγάλη",
    "country_190": "Σερβία",
    "country_191": "Σεϋχέλλες",
    "country_192": "Σιέρρα Λεόνε",
    "country_193": "Σιγκαπούρη",
    "country_194": "Σλοβακία",
    "country_195": "Σλοβενία",
    "country_196": "Νησιά του Σολομώντα",
    "country_197": "Σομαλία",
    "country_198": "Νότια Αφρική",
    "country_199": "Νότια Γεωργία και το Νότιο Σάντουιτς Isla",
    "country_200": "Νότια Κορέα",
    "country_201": "Νότιο Σουδάν",
    "country_202": "Ισπανία",
    "country_203": "Σρι Λάνκα",
    "country_204": "Αγία Ελένη και Εξαρτήσεις",
    "country_205": "Σεντ Κιτς και Νέβις",
    "country_206": "Αγία Λουκία",
    "country_207": "Ο Άγιος Βικέντιος και οι Γρεναδίνες",
    "country_208": "Σουδάν",
    "country_209": "Σουρινάμ",
    "country_210": "Σουαζιλάνδη",
    "country_211": "Σουηδία",
    "country_212": "Ελβετία",
    "country_213": "Συρία",
    "country_214": "Ταϊβάν",
    "country_215": "Τατζικιστάν",
    "country_216": "Τανζανία",
    "country_217": "Πρότυπο:Δεδομένα χώρας SJM Σβάλμπαρντ",
    "country_218": "Ταϊλάνδη",
    "country_219": "Οι Μπαχάμες",
    "country_220": "Οι Κομόρες",
    "country_221": "Οι Φιλιππίνες",
    "country_222": "Ανατολικό Τιμόρ (Ανατολικό Τιμόρ)",
    "country_223": "Να πάω",
    "country_224": "Τοκελάου",
    "country_225": "Τόνγκα",
    "country_226": "Τρινιδάδ και Τομπάγκο",
    "country_227": "Τυνησία",
    "country_228": "Τουρκία",
    "country_229": "Τουρκμενιστάν",
    "country_230": "Νησιά Τερκς και Κάικος",
    "country_231": "Τουβαλού",
    "country_232": "Ουγκάντα",
    "country_233": "Ουκρανία",
    "country_234": "Ηνωμένα Αραβικά Εμιράτα",
    "country_235": "Μικρά απομακρυσμένα νησιά των Ηνωμένων Πολιτειών",
    "country_236": "Ηνωμένες Πολιτείες Αμερικής (ΗΠΑ)",
    "country_237": "Παρθένοι Νήσοι των Ηνωμένων Πολιτειών",
    "country_238": "Ουρουγουάη",
    "country_239": "Ουζμπεκιστάν",
    "country_240": "Βανουάτου",
    "country_241": "Πόλη του Βατικανού (Η Αγία Έδρα)",
    "country_242": "Βενεζουέλα",
    "country_243": "Βιετνάμ",
    "country_244": "Wallis και Futuna",
    "country_245": "Δυτική Σαχάρα",
    "country_246": "Γέμενη",
    "country_247": "Ζάμπια",
    "country_248": "Ζιμπάμπουε",
    "google_login": "Συνδεθείτε με το Google",
    "State": "κατάσταση",
    "Activation_code": "Κωδικός ενεργοποίησης",
    "Question": "Καταχωρίστε όλες τις εφαρμογές στις οποίες έχετε συνδεθεί",
    "Copy_complete": "Η αντιγραφή ολοκληρώθηκε",
    "footer": "Πνευματικά δικαιώματα © 2024 MindOnMap Studio. Ολα τα δικαιώματα διατηρούνται.",
    "change_password_success": "Ο κωδικός άλλαξε επιτυχώς",
    "successful_login_title": "Επιτυχία σύνδεσης",
    "product_page": "Σελίδα προϊόντος >>",
    "successful_login_info": "Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία. Η τρέχουσα σελίδα θα κλείσει αυτόματα σε 5 δευτερόλεπτα. Εάν το αυτόματο κλείσιμο ή το κλείσιμο κάνοντας κλικ στο κουμπί \"Τέλος\" αποτύχει, κλείστε απευθείας αυτήν την καρτέλα.",
    "successful_login_info_firefox": "Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία.",
    "my_account": "Ο λογαριασμός μου",
    "my_history": "Η Ιστορία μου",
    "remove_watermark": "Αφαιρέστε το υδατογράφημα",
    "no_history": "Χωρίς Ιστορία",
    "history_all": "Επιλογή όλων",
    "history_open": "Ανοιξε",
    "history_down": "Κατεβάστε",
    "history_delete": "Διαγράφω",
    "history_clear": "Διαγραφή μη έγκυρου",
    "images": "εικόνα(εις)",
    "use_this_function": "Χρησιμοποιήστε αυτό το προϊόν>>",
    "hd_downloading": "Πλεονεκτήματα λήψης πρωτότυπων εικόνων HD:",
    "lifetimeRemaining": "Lifetime-Remaining",
    "Remaining": "Παραμένων",
    "email_verification": "Επαλήθευση email",
    "email_verification_info": "Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class=\"email\"></span> και ολοκληρώστε την επαλήθευση. Μετά την επαλήθευση, τα οφέλη θα συγχρονιστούν αυτόματα.",
    "wrong_email": "Λάθος διεύθυνση email;",
    "click_here_to_modify": "Κάντε κλικ εδώ για τροποποίηση",
    "get_more_help": "Λάβετε περισσότερη βοήθεια;",
    "click_here": "Κάντε κλικ ΕΔΩ",
    "email_verification_info_success": "Συγχαρητήρια για την επαλήθευση του λογαριασμού email σας.",
    "email_verification_info_error": "Η επαλήθευση απέτυχε λόγω του ληγμένου συνδέσμου.",
    "registration_succeeded": "Η εγγραφή ολοκληρώθηκε",
    "registration_succeeded_info_1": "Συγχαρητήρια! Έχετε εγγραφεί επιτυχώς. Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class=\"email\"></span> και ολοκληρώστε την επαλήθευση για να χρησιμοποιήσετε τα οφέλη αυτού του μηνύματος ηλεκτρονικού ταχυδρομείου.",
    "registration_succeeded_info_2": "Κάντε κλικ στο \"Τέλος\" για να επιστρέψετε στην Αρχική σελίδα και να χρησιμοποιήσετε αυτό το προϊόν.",
    "registration_succeeded_info_3": "Κάντε κλικ στο \"Τέλος\" για να κλείσετε την τρέχουσα σελίδα και να επιστρέψετε στην Αρχική σελίδα για τις ακόλουθες λειτουργίες. Εάν το κλείσιμο αποτύχει, κλείστε χειροκίνητα την καρτέλα.",
    "verify_email": "Επαλήθευση email",
    "registered_email_not_verified": "Το καταχωρημένο email δεν έχει επαληθευτεί, επαληθεύστε το αμέσως.",
    "email_verification_time_1": "Δεν λάβατε email επαλήθευσης;",
    "email_verification_time_2": "Μετά",
    "email_verification_time_3": "Κάντε κλικ εδώ για να το ξαναστείλετε",
    "error_26301": "Κωδικός σφάλματος: 26301, επικοινωνήστε μαζί μας",
    "error_26302": "Κωδικός σφάλματος: 26302, επικοινωνήστε μαζί μας",
    "error_26303": "Σφάλμα μορφής email (κωδικός σφάλματος: 26303). Παρακαλώ εισάγετέ το ξανά",
    "error_26304": "Συνιστάται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 26304)",
    "error_26305": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26305). Παρακαλώ δοκιμάστε το ξανά",
    "error_26306": "Το email έχει καταχωρηθεί, <a href=\"https://account.mindonmap.com/login/\">μεταβείτε για να συνδεθείτε</a>",
    "error_26307": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26307). Παρακαλώ δοκιμάστε το ξανά",
    "error_26308": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26308). Παρακαλώ δοκιμάστε το ξανά",
    "error_26401": "Κωδικός σφάλματος: 26401, δοκιμάστε ξανά",
    "error_26402": "Το email έχει επαληθευτεί (κωδικός σφάλματος: 26402), δοκιμάστε το ξανά",
    "error_26403": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26403). Παρακαλώ δοκιμάστε το ξανά",
    "error_26404": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26404). Παρακαλώ δοκιμάστε το ξανά",
    "error_26501": "Κωδικός σφάλματος: 26501, επικοινωνήστε μαζί μας",
    "error_26502": "Κωδικός σφάλματος: 26502, επικοινωνήστε μαζί μας",
    "error_26503": "Σφάλμα μορφής email (κωδικός σφάλματος: 26503). Εισαγάγετε το ξανά",
    "error_26504": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26504). Παρακαλώ δοκιμάστε το ξανά",
    "error_26505": "Το email δεν έχει καταχωρηθεί, <a href=\"https://account.mindonmap.com/register/\">εγγραφείτε πρώτα</a>",
    "error_26506": "Το email έχει επαληθευτεί.",
    "error_26507": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26507). Παρακαλώ δοκιμάστε το ξανά",
    "error_26508": "Η επαλήθευση ολοκληρώθηκε (κωδικός σφάλματος: 26508), δοκιμάστε ξανά",
    "error_26509": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26509), δοκιμάστε το ξανά",
    "error_26510": "Κωδικός σφάλματος: 26510, επικοινωνήστε μαζί μας",
    "error_26601": "Κωδικός σφάλματος: 26601, επικοινωνήστε μαζί μας",
    "error_26602": "Κωδικός σφάλματος: 26602, επικοινωνήστε μαζί μας",
    "error_26603": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26603). Παρακαλώ δοκιμάστε το ξανά",
    "error_26604": "Κωδικός σφάλματος: 26604, επικοινωνήστε μαζί μας",
    "error_26605": "Κωδικός σφάλματος: 26605, επικοινωνήστε μαζί μας",
    "error_26701": "Κωδικός σφάλματος: 26701, επικοινωνήστε μαζί μας",
    "error_26702": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26702). Παρακαλώ δοκιμάστε το ξανά",
    "error_26703": "Κωδικός σφάλματος: 26703, επικοινωνήστε μαζί μας",
    "error_26704": "Κωδικός σφάλματος: 26704, επικοινωνήστε μαζί μας",
    "error_26705": "Περιμένετε να συνδεθείτε (κωδικός σφάλματος: 26705). Παρακαλώ δοκιμάστε το ξανά",
    "no_cookie": "Έχετε ενεργοποιήσει τη λειτουργία Αποκλεισμός όλων των cookies στο πρόγραμμα περιήγησής σας, επομένως δεν μπορείτε να συνδεθείτε. Μεταβείτε στις Ρυθμίσεις για να επιλέξετε το πλαίσιο Επιτρέπονται όλα τα cookies.",
    "error_26801": "Κωδικός σφάλματος: 26801, επικοινωνήστε μαζί μας",
    "error_26802": "Κωδικός σφάλματος: 26802, επικοινωνήστε μαζί μας",
    "error_26803": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26803). Παρακαλώ δοκιμάστε το ξανά",
    "error_26804": "Το αίτημα απέτυχε (κωδικός σφάλματος: 26804). Παρακαλώ δοκιμάστε το ξανά",
    "error_order": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27098), δοκιμάστε το ξανά!",
    "error_order1": "Το ερώτημα παραγγελίας είναι ελλιπές (κωδικός σφάλματος: ",
    "error_order2": "）, ανανεώστε και δοκιμάστε το ξανά.",
    "modify_email_title": "Τροποποίηση email",
    "modify_email_info": "Μπορείτε να χρησιμοποιήσετε το τροποποιημένο email για να συνδεθείτε στο λογαριασμό σας.",
    "images_per": "Εικόνες ανά",
    "error_26101": "Σφάλμα: 26101. Επικοινωνήστε μαζί μας",
    "error_26102": "Σφάλμα: 26102. Επικοινωνήστε μαζί μας",
    "error_26103": "Το αίτημα απέτυχε (κωδικός λάθους:26103). Δοκιμάστε το ξανά",
    "error_26104": "Κωδικός σφάλματος: 26104, παρακαλώ δοκιμάστε το ξανά",
    "error_26105": "Κωδικός σφάλματος: 26105, παρακαλώ δοκιμάστε το ξανά",
    "error_26106": "Η διαγραφή απέτυχε (κωδικός σφάλματος: 26106). Δοκιμάστε το ξανά",
    "error_26201": "Σφάλμα: 26201. Επικοινωνήστε μαζί μας",
    "error_26202": "Το αίτημα απέτυχε (κωδικός λάθους:26202). Δοκιμάστε το ξανά",
    "error_26001": "Σφάλμα: 26001. Επικοινωνήστε μαζί μας",
    "error_26002": "Σφάλμα: 26002. Επικοινωνήστε μαζί μας",
    "error_26003": "Σφάλμα: 26003. Επικοινωνήστε μαζί μας",
    "error_26004": "Σφάλμα: 26004. Επικοινωνήστε μαζί μας",
    "error_26005": "Το αίτημα απέτυχε (κωδικός λάθους:26005). Δοκιμάστε το ξανά",
    "error_26006": "Κωδικός σφάλματος: 26006, παρακαλώ δοκιμάστε το ξανά",
    "error_26008": "Σφάλμα: 26008. Επικοινωνήστε μαζί μας",
    "go_to_the_home_page": "Μεταβείτε στην Αρχική σελίδα",
    "error_27101": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27101). Παρακαλώ δοκιμάστε ξανά",
    "error_27201": "Κωδικός σφάλματος: 27201, επικοινωνήστε μαζί μας",
    "error_27202": "Κωδικός σφάλματος: 27202, δοκιμάστε ξανά",
    "error_27203": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27203). Παρακαλώ δοκιμάστε το ξανά",
    "error_27204": "Μη έγκυρος κωδικός (κωδικός σφάλματος: 27204).",
    "error_27205": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27205). Παρακαλώ δοκιμάστε το ξανά",
    "error_27206": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27206). Παρακαλώ δοκιμάστε το ξανά",
    "error_27207": "Το αίτημα απέτυχε (κωδικός σφάλματος: 27207). Παρακαλώ δοκιμάστε το ξανά",
    "no_history_found": "Δεν έχεις χρησιμοποιήσει κανένα εργαλείο! <a href=\"/\">Ανανέωση</a> ή <a href=\"https://www.mindonmap.com/\">Μεταβείτε στον επίσημο ιστότοπο</a>",
    "error_25301": "Σφάλμα: 25301. Επικοινωνήστε μαζί μας",
    "error_25302": "Σφάλμα: 25302. Επικοινωνήστε μαζί μας",
    "error_25303": "Το αίτημα απέτυχε (κωδικός λάθους: 25303). Δοκιμάστε το ξανά",
    "error_25304": "Το αίτημα απέτυχε (κωδικός λάθους: 25304). Δοκιμάστε το ξανά",
    "error_25305": "Το αίτημα απέτυχε (κωδικός λάθους: 25305). Δοκιμάστε το ξανά",
    "error_25306": "Το αίτημα απέτυχε (κωδικός λάθους: 25306). Δοκιμάστε το ξανά",
    "image_upscaler_p": "Πλεονεκτήματα λήψης εικόνας χωρίς υδατογράφημα:",
    "Available_for": "Διαθέσιμο για:",
    "credit_per": "%s πιστώσεις ανά εικόνα HD",
    "still_valid": "Τα αγορασμένα προγράμματα εξακολουθούν να ισχύουν",
    "credit": "πίστωση(ες)",
    "pc_3rd_info": "Συνδεθείτε με επιτυχία. Μεταβείτε στην εφαρμογή για περαιτέρω λειτουργία.",
    "use_online": "Χρησιμοποιήστε την ηλεκτρονική υπηρεσία",
    "use_download": "Χρησιμοποιήστε το πρόγραμμα επιφάνειας εργασίας",
    "use_immediately": "Χρήση Αμέσως",
    "Use_in_browser": "Χρήση στο πρόγραμμα περιήγησης",
    "win_desktop": "Windows",
    "Mac_desktop": "Μακ",
    "credits_per": "{%} Πιστώσεις ανά μήνα",
    "expire": "Χρόνος λήξης:",
    "viewDetails": "Δείτε λεπτομέρειες",
    "viewHistory": "Δείτε τα οφέλη του ιστορικού >>",
    "viewDetailsInfo": "Σημείωση: Εάν τα προνόμια της συνδρομής ανανεωθούν εντός 7 ημερών μετά τη λήξη, τα αχρησιμοποίητα προνόμια μπορούν να συνεχίσουν να χρησιμοποιούνται. Επίσης, ο χρόνος λήξης θα ενημερωθεί αυτόματα στον χρόνο λήξης της νέας συνδρομής. Εάν δεν υπάρξει νέα συνδρομή μετά από 7 ημέρες λήξης, όλα τα ληγμένα οφέλη θα διαγραφούν.",
    "connect_account": "Συνδέστε το email στον λογαριασμό σας",
    "connect_account_info": "Μετά τη δέσμευση, μπορείτε να συνδεθείτε με αυτήν τη διεύθυνση email.",
    "connect_now": "Δέστε τώρα",
    "no_email_bind": "Χωρίς δέσμευση email",
    "bind_email": "Σύνδεση email",
    "connect_your_email_placeholder": "Παρακαλώ εισάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας",
    "bind_an_email": "Συνδέστε ένα email",
    "bind_info": "Έχετε συνδεθεί με επιτυχία. Συνδέστε ένα email στον λογαριασμό σας για να ενεργοποιήσετε τα προνόμιά σας.",
    "bind_later": "Δέστε αργότερα",
    "hi": "Του, %s!",
    "Personal_Information": "Προσωπικές πληροφορίες",
    "Access": "Πρόσβαση",
    "Subscription_Plan": "(Πρόγραμμα συνδρομής)",
    "No_orders": "Δεν βρέθηκαν παραγγελίες.",
    "No_data": "Χωρίς δεδομένα",
    "Featured_Products": "Προτεινόμενα Προϊόντα",
    "More_Products": "Περισσότερα Προϊόντα",
    "Free_Download": "ΔΩΡΕΑΝ Λήψη",
    "Get_Started": "Ξεκίνα",
    "Subscribe": "Εγγραφείτε",
    "Verified": "Επαληθεύτηκε",
    "back_to_account_center": "Επιστροφή στο Κέντρο Λογαριασμού",
    "success": "Επιτυχία!",
    "successfully": "Έχετε καταχωρήσει με επιτυχία έναν λογαριασμό.",
    "Continue": "Να συνεχίσει",
    "Already": "Έχετε ήδη λογαριασμό?",
    "loading_verification": "Έλεγχος της κατάστασης επαλήθευσης...",
    "email_no_verification": "Λυπούμαστε, η καταχωρημένη διεύθυνση ηλεκτρονικού ταχυδρομείου δεν έχει επαληθευτεί. Ολοκληρώστε την επαλήθευση σύμφωνα με τις παραπάνω οδηγίες και κάντε ξανά κλικ στο κουμπί \"Τέλος\" για να ολοκληρώσετε την εγγραφή.",
    "will_expire_after": "Θα λήξει μετά",
    "hours": "ώρες",
  },
  "es": {
    "overtime": "Código de error: {%}, vuelve a intentarlo",
    "isnetwork": "Error de Internet. Por favor verifique y vuelva a intentar",
    "email_placeholder": "Correo electrónico",
    "email_empty": "Por favor ingrese el correo electrónico",
    "email_not_valid": "El correo no es válido",
    "email_not_valid_1": "Por favor ingrese su correo electrónico",
    "email_not_valid_2": "El correo electrónico no es válido, utilice una dirección diferente.",
    "email_not_valid_3": "Sin entrada de correo electrónico",
    "password_placeholder": "Contraseña",
    "password_empty": "Por favor ingrese la contraseña",
    "password_not_valid": "Cuenta o contraseña incorrecta",
    "password_not_valid_1": "Se requiere contraseña de más de 8 caracteres",
    "password_not_valid_2": "Por favor crea una contraseña",
    "password_placeholder_1": "Crea tu contraseña",
    "password_placeholder_2": "Confirmar la contraseña",
    "password_placeholder_3": "Crear nueva contraseña",
    "password_placeholder_4": "Confirmar nueva contraseña",
    "password_placeholder_5": "Ingrese la contraseña anterior",
    "copy_password_empty": "Por favor confirme la contraseña",
    "copy_password_not_valid": "Por favor, confirme su contraseña",
    "copy_passwords_inconsistent": "La confirmación de tu contraseña no coincide",
    "code_empty": "Por favor ingrese el código de verificación",
    "code_not_valid": "código de verificación invalido",
    "code_placeholder": "Código de verificación",
    "not_received_code": "Si su buzón no ha recibido el código de verificación durante mucho tiempo, obtenga el código de verificación nuevamente.",
    "get_first_code": "Primero obtenga el código de verificación.",
    "last_name_placeholder": "Por favor ingrese su apellido",
    "first_name_placeholder": "Por favor, introduzca su nombre de pila",
    "address_placeholder": "Por favor ingrese su dirección",
    "no_code_text": "Hemos enviado un código de verificación. Por favor ingrese su código. <span class='tips'>¿No recibiste un código?",
    "no_code_text_1": "1. Asegúrese de que la dirección de correo electrónico sea válida y pueda recibir correos electrónicos.",
    "no_code_text_2": "2. Dado que el sistema envía el correo electrónico automáticamente, es posible que se marque como correo no deseado o correo no deseado. Verifique si el correo electrónico está en la carpeta Papelera.",
    "no_code_text_3": "3. ¿No puedes resolver tu problema? ",
    "no_code_text_3_span": "Entonces haga clic aquí para contactarnos.",
    "order_no": "No ha comprado ningún producto. Si tiene alguna pregunta, <a href=\"https://www.mindonmap.com/contact-us/\">contáctenos</a>.",
    "error_24901": "La cuenta actual no tiene ningún correo electrónico vinculado y no puede encontrar los pedidos. Por favor conecte un correo electrónico.",
    "user_guide": "Guía del usuario>>",
    "download": "Descargar",
    "order_number": "Número de orden:",
    "Refund": "Reembolso",
    "Disabled": "Desactivado",
    "Normal": "Normal",
    "Modify": "Modificar",
    "Modify_1": "Modificar>>",
    "Connect": "Conectar",
    "unlink_success": "Desvincular con éxito",
    "connect_success": "Conéctese exitosamente",
    "feedback_title": "Gracias por tus comentarios. Por favor deje su problema y le responderemos dentro de las 24 horas.",
    "feedback_thank_you": "¡Gracias!<br />Sus comentarios se han enviado correctamente.",
    "feedback_email": "¡Introduzca su correo electrónico aquí!",
    "feedback_content": "Deje aquí cualquier problema o sugerencia que haya encontrado.",
    "feedback_submit": "Entregar",
    "form_contents": "No has introducido ninguna descripción. Por favor ingréselo y envíelo nuevamente.",
    "old_password": "Por favor ingrese la contraseña anterior",
    "new_password": "Por favor crea una nueva contraseña",
    "old_new_password": "La nueva contraseña no puede ser la misma que la anterior.",
    "incorrect_password": "Contraseña incorrecta",
    "delete_no": "Elimina ahora",
    "Caps": "Bloqueo de mayúsculas está activado",
    "Get": "Conseguir",
    "Done": "Hecho",
    "error_20001": "Error: 20001. Inicie sesión nuevamente.",
    "error_20002": "Error: 20002. Inicie sesión nuevamente.",
    "error_20003": "Error: 20003. Inicie sesión nuevamente.",
    "error_20004": "La solicitud falló (código de error: 20004). Por favor inténtelo de nuevo.",
    "error_20005": "La sesión de inicio de sesión ha caducado (Error: 20005). Por favor inicie sesión nuevamente.",
    "error_20006": "La solicitud falló (código de error: 20006). Por favor inténtelo de nuevo.",
    "error_20007": "La sesión de inicio de sesión ha caducado (Error: 20007). Por favor inicie sesión nuevamente.",
    "error_20008": "La sesión de inicio de sesión ha caducado (Error: 20008). Por favor inicie sesión nuevamente.",
    "error_20009": "La sesión de inicio de sesión ha caducado (Error: 20009). Por favor inicie sesión nuevamente.",
    "error_20101": "Por favor ingrese su correo electrónico (código de error: 20101)",
    "error_20102": "El correo electrónico no es válido (código de error: 20102)",
    "error_20103": "La solicitud falló (código de error: 20103). Por favor inténtalo de nuevo",
    "error_20104": "El correo electrónico ya está en uso, <a href=\"https://account.mindonmap.com/login/\">inicie sesión</a> o regístrese con uno nuevo.",
    "error_20105": "La solicitud falló (código de error: 20105). Por favor inténtalo de nuevo",
    "error_20106": "No se pudo enviar el correo electrónico. Vuelva a intentarlo.",
    "error_20201": "Por favor ingrese su correo electrónico (código de error: 20201)",
    "error_20202": "Por favor ingrese su contraseña (código de error: 20202)",
    "error_20203": "Ingrese el código de verificación (código de error: 20203)",
    "error_20204": "El correo electrónico no es válido (código de error: 20204)",
    "error_20205": "Se requiere una contraseña de más de 8 caracteres (código de error: 20205)",
    "error_20206": "La solicitud falló (código de error: 20206). Por favor inténtalo de nuevo",
    "error_20207": "código de verificación invalido",
    "error_20208": "La solicitud falló (código de error: 20208). Por favor inténtalo de nuevo",
    "error_20209": "La solicitud falló (código de error: 20209). Por favor inténtalo de nuevo",
    "error_20301": "Por favor ingrese su correo electrónico (código de error: 20301)",
    "error_20302": "Error: 20302. Por favor contáctenos",
    "error_20303": "El correo electrónico no es válido (código de error: 20303)",
    "error_20304": "La solicitud falló (código de error: 20304). Por favor inténtalo de nuevo",
    "error_20305": "La cuenta no existe. Vuelva a ingresar o <a href=\"https://account.mindonmap.com/register/\">créelo</a> primero.",
    "error_20306": "Aún no hay contraseña. Utilice <a href=\"https://account.mindonmap.com/passwordless-login/\">iniciar sesión sin contraseña</a> o <a href=\"https://account.mindonmap.com/create-password/\"> establezca una contraseña</a> e inicie sesión.",
    "error_20308": "La solicitud falló (código de error: 20308). Por favor inténtalo de nuevo",
    "error_20401": "No se pudo cerrar sesión (código de error: 20401). Por favor inténtalo de nuevo",
    "error_20501": "Por favor ingrese su correo electrónico (código de error: 20501)",
    "error_20502": "El correo electrónico no es válido (código de error: 20502)",
    "error_20503": "La solicitud falló (código de error: 20503). Por favor inténtalo de nuevo",
    "error_20504": "No se pudo enviar el correo electrónico. Por favor inténtelo de nuevo.",
    "error_20601": "Por favor ingrese su correo electrónico (código de error: 20601)",
    "error_20602": "Ingrese el código de verificación (código de error: 20602)",
    "error_20603": "El correo electrónico no es válido (código de error: 20603)",
    "error_20604": "La solicitud falló (código de error: 20604). Por favor inténtalo de nuevo",
    "error_20606": "La solicitud falló (código de error: 20606). Por favor inténtalo de nuevo",
    "error_20607": "La solicitud falló (código de error: 20607). Por favor inténtalo de nuevo",
    "error_20608": "La solicitud falló (código de error: 20608). Por favor inténtalo de nuevo",
    "error_20701": "Por favor ingrese su correo electrónico (código de error: 20701)",
    "error_20702": "El correo electrónico no es válido (código de error: 20702)",
    "error_20703": "La solicitud falló (código de error: 20703). Por favor inténtalo de nuevo",
    "error_20704": "La cuenta no existe. Vuelva a ingresar o <a href=\"https://account.mindonmap.com/register/\">créelo</a> primero.",
    "error_20705": "La solicitud falló (código de error: 20705). Por favor inténtalo de nuevo",
    "error_20706": "No se pudo enviar el correo electrónico. Por favor inténtalo de nuevo",
    "error_20801": "Por favor ingrese su correo electrónico (código de error: 20801)",
    "error_20802": "Error: 20802. Por favor contáctenos",
    "error_20803": "Ingrese el código de verificación (código de error: 20803)",
    "error_20804": "El correo electrónico no es válido (código de error: 20804)",
    "error_20805": "Se requiere una contraseña de más de 8 caracteres (código de error: 20805)",
    "error_20806": "La solicitud falló (código de error: 20806). Por favor inténtalo de nuevo",
    "error_20808": "La solicitud falló (código de error: 20808). Por favor inténtalo de nuevo",
    "error_20901": "La solicitud falló (código de error: 20901). Por favor inténtalo de nuevo",
    "error_20902": "La solicitud falló (código de error: 20902). Por favor inténtalo de nuevo",
    "error_21000": "Los cambios se guardan",
    "error_21001": "No se envía información (código de error: 21001)",
    "error_21002": "La solicitud falló (código de error: 21002). Por favor inténtalo de nuevo",
    "error_21101": "Por favor ingrese su correo electrónico (código de error: 21101)",
    "error_21102": "El correo electrónico no es válido (código de error: 21102)",
    "error_21103": "La solicitud falló (código de error: 21103), vuelva a intentarlo",
    "error_21104": "El correo electrónico ya está conectado, utilice uno nuevo.",
    "error_21105": "La solicitud falló (código de error: 21105), vuelva a intentarlo",
    "error_21106": "No se pudo enviar el correo electrónico. Por favor inténtalo de nuevo",
    "error_21201": "Por favor ingrese su correo electrónico (código de error: 21201)",
    "error_21202": "Ingrese el código de verificación (código de error: 21202)",
    "error_21203": "El correo electrónico no es válido (código de error: 21203)",
    "error_21204": "Error: 21204. Por favor contáctenos",
    "error_21205": "Error: 21205. Por favor contáctenos",
    "error_21206": "Se requiere una contraseña de más de 8 caracteres (código de error: 21206)",
    "error_21207": "La solicitud falló (código de error: 21207). Por favor inténtalo de nuevo",
    "error_21209": "La solicitud falló (código de error: 21209). Por favor inténtalo de nuevo",
    "error_21301": "Ingrese la contraseña anterior (código de error: 21301)",
    "error_21302": "Cree una nueva contraseña (código de error: 21302)",
    "error_21303": "La nueva contraseña no puede ser la misma que la anterior. (Error: 21303)",
    "error_21304": "Se requiere una contraseña de más de 8 caracteres (código de error: 21304)",
    "error_21306": "La solicitud falló (código de error: 21306). Por favor inténtalo de nuevo",
    "error_21402": "La solicitud falló (código de error: 21402). Por favor inténtalo de nuevo",
    "error_21403": "No se pudo enviar el código de verificación. Por favor reenvialo",
    "error_21500": "La cuenta ha sido eliminada.",
    "error_21501": "Ingrese el código de verificación (código de error: 21501)",
    "error_21502": "La sesión de inicio de sesión ha caducado (Error: 21502). Por favor inicie sesión nuevamente.",
    "error_21503": "La solicitud falló (código de error: 21503). Por favor inténtalo de nuevo",
    "error_21505": "La solicitud falló (código de error: 21505), vuelva a intentarlo",
    "error_21601": "Error: 20601. Por favor contáctenos",
    "error_21602": "Verificación no válida (Error: 20602). Por favor inténtelo de nuevo.",
    "error_21603": "Error: 20603. Inténtalo de nuevo.",
    "error_21604": "La solicitud falló (código de error: 21604). Por favor inténtalo de nuevo",
    "error_21606": "La solicitud falló (código de error: 21606). Por favor inténtalo de nuevo",
    "error_21611": "La solicitud falló (código de error: 21611). Por favor inténtalo de nuevo",
    "error_21801": "Error: 21801. Por favor contáctenos",
    "error_21802": "La solicitud falló (Error: 21802). Por favor inténtalo de nuevo",
    "error_21803": "Error: 21803. Inténtalo de nuevo.",
    "error_21804": "La solicitud falló (código de error: 21804). Por favor inténtalo de nuevo",
    "error_21806": "Error: 21806. Inténtalo de nuevo.",
    "error_21807": "Error: 21807. Por favor contáctenos",
    "error_21808": "Error: 21808. Por favor contáctenos",
    "error_21809": "Error: 21809. Por favor contáctenos",
    "error_21810": "Error: 21810. Por favor contáctenos",
    "error_21811": "Error: 21811. Por favor contáctenos",
    "error_21812": "Error: 21812. Por favor contáctenos",
    "error_21813": "La solicitud falló (código de error: 21813). Por favor inténtalo de nuevo",
    "error_21814": "Error: 21814. Por favor contáctenos",
    "error_21815": "La solicitud falló (código de error: 21815). Por favor inténtalo de nuevo",
    "error_21816": "Error: 21816. Por favor contáctenos",
    "error_21817": "Error: 21817. Por favor contáctenos",
    "error_21818": "Error: 21818. Por favor contáctenos",
    "error_21819": "La solicitud falló (código de error: 21819). Por favor inténtalo de nuevo",
    "error_21820": "Error: 21820. Por favor contáctenos",
    "error_21821": "Error: 21821. Por favor contáctenos",
    "error_21822": "Error: 21822. Por favor contáctenos",
    "error_21823": "La solicitud falló (código de error: 21823). Por favor inténtalo de nuevo",
    "error_21824": "La solicitud falló (código de error: 21824). Por favor inténtalo de nuevo",
    "error_21825": "La solicitud falló (código de error: 21825). Por favor inténtalo de nuevo",
    "error_21826": "La solicitud falló (código de error: 21826). Por favor inténtalo de nuevo",
    "error_21901": "Error: 21901. Por favor contáctenos",
    "error_21902": "La solicitud falló (código de error: 21902). Por favor inténtalo de nuevo",
    "error_21903": "El estado de la cuenta ha cambiado (código de error: 21903), actualice la página e inténtelo de nuevo",
    "error_21904": "Error: 21904. Inténtalo de nuevo.",
    "error_21905": "Error: 21905. Inténtalo de nuevo.",
    "error_21906": "La solicitud falló (código de error: 21906). Por favor inténtalo de nuevo",
    "error_21907": "La cuenta de Google ha sido vinculada a otra cuenta.",
    "error_21908": "La solicitud falló (código de error: 21908). Por favor inténtalo de nuevo",
    "error_22001": "La solicitud falló (código de error: 22001). Por favor inténtalo de nuevo",
    "error_22002": "Error al desvincular sin iniciar sesión adicionalmente",
    "error_22003": "La solicitud falló (código de error: 22003). Por favor inténtalo de nuevo",
    "error_22101": "Error: 22101. Por favor contáctenos",
    "error_22102": "El estado de la cuenta ha cambiado (código de error: 22102), actualice la página e inténtelo de nuevo",
    "error_22103": "La solicitud falló (código de error: 22103). Por favor inténtalo de nuevo",
    "error_22104": "El estado de la cuenta ha cambiado (código de error: 22104), actualice la página e inténtelo de nuevo",
    "error_22105": "Error: 22105. Inténtalo de nuevo.",
    "error_22106": "Error: 22106. Inténtalo de nuevo.",
    "error_22107": "Error: 22107. Por favor contáctenos",
    "error_22108": "La solicitud falló (código de error: 22108). Por favor inténtalo de nuevo",
    "error_22201": "Error: 22201. Por favor contáctenos",
    "error_22202": "Verificación no válida (Error: 22202). Por favor inténtelo de nuevo.",
    "error_22203": "Error: 22203. Inténtelo de nuevo\"",
    "error_22204": "La solicitud falló (código de error: 22204). Por favor inténtalo de nuevo",
    "error_22206": "La solicitud falló (código de error: 22206). Por favor inténtalo de nuevo",
    "error_22401": "Error: 22401. Por favor contáctenos",
    "error_22402": "Verificación no válida (Error: 22402). Por favor inténtelo de nuevo.",
    "error_22403": "Error: 22403. Inténtalo de nuevo.",
    "error_22404": "La solicitud falló (código de error: 22404). Por favor inténtalo de nuevo",
    "error_22405": "La cuenta de Facebook ha sido vinculada a otro correo electrónico.",
    "error_22406": "Error: 22406. Inténtalo de nuevo.",
    "error_22407": "Error: 22407. Por favor contáctenos",
    "error_22408": "Error: 22408. Por favor contáctenos",
    "error_22409": "Error: 22409. Por favor contáctenos",
    "error_22410": "Error: 224010. Por favor contáctenos",
    "error_22411": "Error: 224011. Por favor contáctenos",
    "error_22412": "Error: 224012. Por favor contáctenos",
    "error_22413": "Error: 22413. Por favor contáctenos",
    "error_22414": "La solicitud falló (código de error: 22414). Por favor inténtalo de nuevo",
    "error_22415": "Error: 22415. Por favor contáctenos",
    "error_22416": "Error: 22416. Por favor contáctenos",
    "error_22417": "Error: 22417. Por favor contáctenos",
    "error_22418": "La solicitud falló (código de error: 22418). Por favor inténtalo de nuevo",
    "error_22419": "Error: 22419. Por favor contáctenos",
    "error_22420": "Error: 22420. Por favor contáctenos",
    "error_22421": "Error: 22421. Por favor contáctenos",
    "error_22422": "La solicitud falló (código de error: 22422). Por favor inténtalo de nuevo",
    "error_22423": "La solicitud falló (código de error: 22423). Por favor inténtalo de nuevo",
    "error_22424": "La solicitud falló (código de error: 22424). Por favor inténtalo de nuevo",
    "error_22425": "La solicitud falló (código de error: 22425). Por favor inténtalo de nuevo",
    "error_20098": "Código de error: 20098. Si estás navegando en modo Privado, cambia al modo Normal e inténtalo nuevamente.",
    "error_22298": "La solicitud de Google falló (Error: 22298). Por favor inténtelo de nuevo.",
    "error_22498": "La solicitud de Facebook falló (Error: 22498). Por favor inténtelo de nuevo.",
    "error_24902": "La solicitud falló (código de error: 24902). Por favor inténtalo de nuevo",
    "error_24903": "La solicitud falló (código de error: 24903). Por favor inténtalo de nuevo",
    "error_24904": "La solicitud falló (código de error: 24904). Por favor inténtalo de nuevo",
    "error_24905": "La solicitud falló (código de error: 24905). Por favor inténtalo de nuevo",
    "login_title": "Iniciar sesión en MindOnMap",
    "log_in": "Acceso",
    "no_account": "¿Sin cuenta?",
    "create_it": "crearlo",
    "or_log_in_with": "O inicia sesión con",
    "passwordless_login": "Inicio de sesión sin contraseña",
    "log_in_done": "Iniciar sesión hecho",
    "three_rd_account_connect_info": "¡Felicidades! Has iniciado sesión correctamente. Ahora puede conectar una cuenta de correo electrónico que utilizará para iniciar sesión en el futuro.",
    "see_my_account": "ver mi cuenta",
    "three_rd_login_merge_account": "La dirección de correo electrónico de la cuenta de terceros se ha registrado. ¿Desea conectarse e iniciar sesión con esta dirección de correo electrónico directamente?",
    "connect_log_in": "Conéctese e inicie sesión",
    "create_an_account": "Crea una cuenta",
    "back_to_log_in": "Atrás para iniciar sesión",
    "create_password": "Crear contraseña",
    "create_now": "Crea ahora",
    "password_login_subtitle": "Inicio de sesión sin contraseña con correo electrónico",
    "account_login": "Cuenta de Ingreso",
    "rights": "Al crear esta cuenta, aceptas los <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Términos de servicio</a> y <a href=\"https://www.mindonmap.com/privacy-policy/\">Política de privacidad</a>",
    "passwordless_login_done": "Inicio de sesión sin contraseña realizado",
    "passwordless_login_info": "Felicitaciones, ha completado exitosamente el inicio de sesión sin contraseña. Puede crear una contraseña para esta cuenta e iniciar sesión con la cuenta y la contraseña en el futuro. <a href=\"/create-password\" style=\"display: initial;\">Crear ahora</a>",
    "sign_up": "Inscribirse",
    "register_info": "Crea tu cuenta",
    "reset_now": "Reiniciar ahora",
    "forgot_password": "Has olvidado tu contraseña",
    "reset_password_subtitle": "Utilice el correo electrónico de su cuenta para restablecer la contraseña",
    "plan_products": "Planes y productos",
    "nick_name": "Nombre de usuario:",
    "email": "Correo electrónico:",
    "my_products": "Mis productos",
    "my_orders": "Mis ordenes",
    "unlink": "Desconectar",
    "link": "Enlace",
    "connected_accounts": "Cuentas conectadas",
    "last_name": "Apellido:",
    "first_name": "Nombre de pila:",
    "Gender": "Género:",
    "Birth": "Nacimiento:",
    "Month": "Mes",
    "Year": "Año",
    "Country_Region": "País/Región:",
    "Address": "DIRECCIÓN:",
    "Save": "Ahorrar",
    "Date": "Fecha",
    "Male": "Masculino",
    "Female": "Femenino",
    "Unspecified": "No especificado",
    "Security": "Seguridad",
    "change_password": "Cambiar la contraseña",
    "change_now": "Cambia ahora",
    "connect_email": "Conectar correo electrónico",
    "delete_account": "Borrar cuenta",
    "delete_account_info": "Cuando se elimina su cuenta, todos los datos en Mi cuenta asociados con su cuenta se eliminarán permanentemente y es posible que no pueda recuperarlos. Le recomendamos andar con cautela.",
    "Delete": "Borrar",
    "Logout": "Cerrar sesión",
    "my_profile": "Mi perfil",
    "guides_faqs": "Guías y preguntas frecuentes",
    "More": "Más",
    "guides": "Guías",
    "register": "registro",
    "hot_faq": "Preguntas frecuentes calientes",
    "Contents": "Contenido:",
    "contact_us": "Contáctanos>>",
    "plan": "Plan",
    "unregistered": "No registrado",
    "buy_more": "Comprar más",
    "buy_again": "Compra otra vez",
    "buy_now": "Comprar ahora",
    "free_no_limit": "Gratis y sin límite",
    "expired": "Venció",
    "lifetime": "Toda la vida",
    "remain": "Permanecer",
    "day_s": "Días)",
    "error_24801": "La solicitud falló (código de error: 24801). Por favor inténtalo de nuevo",
    "no_app_found": "No se encontró ninguna aplicación！<a href=\"/\">Actualizar</a> o <a href=\"https://www.mindonmap.com/\">Ir al sitio web oficial</a>",
    "get_more": "Obtener más >>",
    "edit_photo": "Editar foto",
    "select_photo": "Seleccione Foto",
    "change_photo": "Cambiar foto",
    "cancel": "Cancelar",
    "hide_password": "Contraseña oculta",
    "show_password": "Mostrar contraseña",
    "zoom_in": "Acercarse",
    "zoom_out": "Disminuir el zoom",
    "rotate": "Girar",
    "horizontal_flip": "volteo horizontal",
    "vertical_flip": "volteo vertical",
    "country": "País",
    "country_1": "Seleccione su país/región",
    "country_2": "Isla de Åaland",
    "country_3": "Afganistán",
    "country_4": "Albania",
    "country_5": "Argelia",
    "country_6": "Samoa Americana",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguila",
    "country_10": "Antártida",
    "country_11": "Antigua y Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaiyán",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorrusia",
    "country_22": "Bélgica",
    "country_23": "Belice",
    "country_24": "Benín",
    "country_25": "islas Bermudas",
    "country_26": "Bután",
    "country_27": "bolivia",
    "country_28": "Bosnia y Herzegovina",
    "country_29": "Botsuana",
    "country_30": "Isla Bouvet",
    "country_31": "Brasil",
    "country_32": "Territorio Británico del Océano Índico",
    "country_33": "Islas Vírgenes Británicas",
    "country_34": "Brunéi",
    "country_35": "Bulgaria",
    "country_36": "Burkina Faso",
    "country_37": "Burundi",
    "country_38": "Camboya",
    "country_39": "Camerún",
    "country_40": "Canadá",
    "country_41": "Cabo Verde",
    "country_42": "Caribe Países Bajos",
    "country_43": "Islas Caimán",
    "country_44": "República Centroafricana",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "Porcelana",
    "country_48": "Isla de Navidad",
    "country_49": "Islas Cocos (Keeling)",
    "country_50": "Colombia",
    "country_51": "Islas Cook",
    "country_52": "Costa Rica",
    "country_53": "Costa de Marfil",
    "country_54": "Croacia",
    "country_55": "Cuba",
    "country_56": "Chipre",
    "country_57": "República Checa",
    "country_58": "República Democrática del Congo",
    "country_59": "Dinamarca",
    "country_60": "Yibuti",
    "country_61": "Dominica",
    "country_62": "República Dominicana",
    "country_63": "Ecuador",
    "country_64": "Egipto",
    "country_65": "El Salvador",
    "country_66": "Guinea Ecuatorial",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopía",
    "country_70": "Islas Malvinas",
    "country_71": "Islas Faroe",
    "country_72": "Estados Federados de Micronesia",
    "country_73": "Fiyi",
    "country_74": "Finlandia",
    "country_75": "Francia",
    "country_76": "Guayana Francesa",
    "country_77": "Polinesia francés",
    "country_78": "Territorios Franceses del Sur",
    "country_79": "Gabón",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Alemania",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Gran Bretaña (Reino Unido; Inglaterra)",
    "country_86": "Grecia",
    "country_87": "Groenlandia",
    "country_88": "Granada",
    "country_89": "Guadalupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernesey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bisáu",
    "country_95": "Guayana",
    "country_96": "Haití",
    "country_97": "Isla Heard e Islas McDonald",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungría",
    "country_101": "Islandia",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Irán",
    "country_105": "Irak",
    "country_106": "Irlanda",
    "country_107": "Isla del hombre",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Jamaica",
    "country_111": "Japón",
    "country_112": "Jersey",
    "country_113": "Jordán",
    "country_114": "Kazajstán",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirguistán",
    "country_119": "Laos",
    "country_120": "Letonia",
    "country_121": "Líbano",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Lituania",
    "country_127": "Luxemburgo",
    "country_128": "macao",
    "country_129": "Madagascar",
    "country_130": "Malaui",
    "country_131": "Malasia",
    "country_132": "Maldivas",
    "country_133": "Malí",
    "country_134": "Malta",
    "country_135": "Islas Marshall",
    "country_136": "Martinica",
    "country_137": "Mauritania",
    "country_138": "Mauricio",
    "country_139": "Mayotte",
    "country_140": "México",
    "country_141": "Moldavia",
    "country_142": "Mónaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marruecos",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Países Bajos",
    "country_153": "Nueva Caledonia",
    "country_154": "Nueva Zelanda",
    "country_155": "Nicaragua",
    "country_156": "Níger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Isla Norfolk",
    "country_160": "Corea del Norte",
    "country_161": "Islas Marianas del Norte",
    "country_162": "Noruega",
    "country_163": "Omán",
    "country_164": "Pakistán",
    "country_165": "Palaos",
    "country_166": "Territorios palestinos",
    "country_167": "Panamá",
    "country_168": "Papúa Nueva Guinea",
    "country_169": "Paraguay",
    "country_170": "Perú",
    "country_171": "Islas Pitcairn",
    "country_172": "Polonia",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "República de Macedonia (ARYM)",
    "country_177": "República del Congo",
    "country_178": "Reunión",
    "country_179": "Rumania",
    "country_180": "Federación Rusa",
    "country_181": "Ruanda",
    "country_182": "San Bartolomé",
    "country_183": "San Martín (Francia)",
    "country_184": "San Pedro y Miquelón",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Santo Tomé y Príncipe",
    "country_188": "Arabia Saudita",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leona",
    "country_193": "Singapur",
    "country_194": "Eslovaquia",
    "country_195": "Eslovenia",
    "country_196": "Islas Salomón",
    "country_197": "Somalia",
    "country_198": "Sudáfrica",
    "country_199": "Georgia del Sur y la Isla Sandwich del Sur",
    "country_200": "Corea del Sur",
    "country_201": "Sudán del Sur",
    "country_202": "España",
    "country_203": "Sri Lanka",
    "country_204": "Santa Helena y Dependencias",
    "country_205": "San Cristóbal y Nieves",
    "country_206": "Santa Lucía",
    "country_207": "San Vicente y las Granadinas",
    "country_208": "Sudán",
    "country_209": "Surinam",
    "country_210": "Suazilandia",
    "country_211": "Suecia",
    "country_212": "Suiza",
    "country_213": "Siria",
    "country_214": "Taiwán",
    "country_215": "Tayikistán",
    "country_216": "Tanzania",
    "country_217": "Plantilla:Datos del país SJM Svalbard",
    "country_218": "Tailandia",
    "country_219": "Las Bahamas",
    "country_220": "Las Comoras",
    "country_221": "Las Filipinas",
    "country_222": "Timor Oriental (Timor Oriental)",
    "country_223": "Ir",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad y Tobago",
    "country_227": "Túnez",
    "country_228": "Pavo",
    "country_229": "Turkmenistán",
    "country_230": "Islas Turcas y Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucrania",
    "country_234": "Emiratos Árabes Unidos",
    "country_235": "Islas periféricas menores de los Estados Unidos",
    "country_236": "Estados Unidos de América (EE.UU.)",
    "country_237": "Islas Vírgenes de los Estados Unidos",
    "country_238": "Uruguay",
    "country_239": "Uzbekistán",
    "country_240": "Vanuatu",
    "country_241": "Ciudad del Vaticano (La Santa Sede)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis y Futuna",
    "country_245": "Sahara Occidental",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabue",
    "google_login": "Inicia sesión con Google",
    "State": "Estado",
    "Activation_code": "Código de activación",
    "Question": "Enumere todas las aplicaciones en las que ha iniciado sesión",
    "Copy_complete": "Copia completa",
    "footer": "Copyright © 2024 MindOnMap Estudio. Reservados todos los derechos.",
    "change_password_success": "Contraseña cambiada con éxito",
    "successful_login_title": "Acceso exitoso",
    "product_page": "Página del producto>>",
    "successful_login_info": "Inicio de sesión completado. Cierre la página actual y regrese a la pestaña original para continuar con el siguiente proceso. La página actual se cerrará automáticamente en 5 segundos. Si falla el cierre automático o el cierre al hacer clic en el botón \"Listo\", cierre esta pestaña directamente.",
    "successful_login_info_firefox": "Inicio de sesión completado. Cierre la página actual y regrese a la pestaña original para continuar con el siguiente proceso.",
    "my_account": "Mi cuenta",
    "my_history": "Mi historia",
    "remove_watermark": "Quitar marca de agua",
    "no_history": "No historia",
    "history_all": "Seleccionar todo",
    "history_open": "Abierto",
    "history_down": "Descargar",
    "history_delete": "Borrar",
    "history_clear": "Borrar inválido",
    "images": "imagen(es)",
    "use_this_function": "Utilice este producto>>",
    "hd_downloading": "Beneficios de la descarga de imágenes originales en HD:",
    "lifetimeRemaining": "Restante de por vida",
    "Remaining": "Restante",
    "email_verification": "verificacion de email",
    "email_verification_info": "Hemos enviado el mensaje de verificación a su correo electrónico <span class=\"email\"></span> y finalice la verificación. Después de la verificación, los beneficios se sincronizarán automáticamente.",
    "wrong_email": "¿Dirección de correo errónea?",
    "click_here_to_modify": "Haga clic aquí para modificar",
    "get_more_help": "¿Obtener más ayuda?",
    "click_here": "haga clic aquí",
    "email_verification_info_success": "Felicitaciones por verificar su cuenta de correo electrónico.",
    "email_verification_info_error": "La verificación falló debido al enlace caducado.",
    "registration_succeeded": "Registro exitoso",
    "registration_succeeded_info_1": "¡Felicidades! Te has registrado exitosamente. Hemos enviado el mensaje de verificación a su correo electrónico <span class=\"email\"></span> y finalice la verificación para utilizar los beneficios de este correo electrónico.",
    "registration_succeeded_info_2": "Haga clic en \"Listo\" para regresar a la página de inicio y utilizar este producto.",
    "registration_succeeded_info_3": "Haga clic en \"Listo\" para cerrar la página actual y regresar a la página de inicio para las siguientes operaciones. Si el cierre falla, cierre manualmente la pestaña.",
    "verify_email": "Verificar correo electrónico",
    "registered_email_not_verified": "El correo electrónico registrado no ha sido verificado, verifíquelo de inmediato.",
    "email_verification_time_1": "¿No recibiste un correo electrónico de verificación?",
    "email_verification_time_2": "Después",
    "email_verification_time_3": "Haga clic aquí para reenviarlo",
    "error_26301": "Código de error: 26301, por favor contáctenos",
    "error_26302": "Código de error: 26302, por favor contáctenos",
    "error_26303": "Error de formato de correo electrónico (código de error: 26303). Por favor ingresalo nuevamente",
    "error_26304": "Se recomienda una contraseña de más de 8 caracteres (código de error: 26304)",
    "error_26305": "La solicitud falló (código de error: 26305). Por favor vuelve a intentarlo",
    "error_26306": "El correo electrónico ha sido registrado, <a href=\"https://account.mindonmap.com/login/\">vaya a iniciar sesión</a>",
    "error_26307": "La solicitud falló (código de error: 26307). Por favor vuelve a intentarlo",
    "error_26308": "La solicitud falló (código de error: 26308). Por favor vuelve a intentarlo",
    "error_26401": "Código de error: 26401, vuelva a intentarlo",
    "error_26402": "El correo electrónico ha sido verificado (código de error: 26402), vuelva a intentarlo",
    "error_26403": "La solicitud falló (código de error: 26403). Por favor vuelve a intentarlo",
    "error_26404": "La solicitud falló (código de error: 26404). Por favor vuelve a intentarlo",
    "error_26501": "Código de error: 26501, por favor contáctenos",
    "error_26502": "Código de error: 26502, por favor contáctenos",
    "error_26503": "Error de formato de correo electrónico (código de error: 26503). Por favor ingresalo nuevamente",
    "error_26504": "La solicitud falló (código de error: 26504). Por favor vuelve a intentarlo",
    "error_26505": "El correo electrónico no se ha registrado, <a href=\"https://account.mindonmap.com/register/\">regístrelo primero</a>",
    "error_26506": "El correo electrónico ha sido verificado.",
    "error_26507": "La solicitud falló (código de error: 26507). Por favor vuelve a intentarlo",
    "error_26508": "Verificación fallida (código de error: 26508), vuelva a intentarlo",
    "error_26509": "La solicitud falló (código de error: 26509), vuelva a intentarlo",
    "error_26510": "Código de error: 26510, por favor contáctenos",
    "error_26601": "Código de error: 26601, por favor contáctenos",
    "error_26602": "Código de error: 26602, por favor contáctenos",
    "error_26603": "La solicitud falló (código de error: 26603). Por favor vuelve a intentarlo",
    "error_26604": "Código de error: 26604, por favor contáctenos",
    "error_26605": "Código de error: 26605, por favor contáctenos",
    "error_26701": "Código de error: 26701, por favor contáctenos",
    "error_26702": "La solicitud falló (código de error: 26702). Por favor vuelve a intentarlo",
    "error_26703": "Código de error: 26703, por favor contáctenos",
    "error_26704": "Código de error: 26704, por favor contáctenos",
    "error_26705": "Espere a iniciar sesión (código de error: 26705). Por favor vuelve a intentarlo",
    "no_cookie": "Ha activado la función Bloquear todas las cookies en su navegador, por lo que no puede iniciar sesión. Vaya a Configuración para marcar la casilla Permitir todas las cookies.",
    "error_26801": "Código de error: 26801, por favor contáctenos",
    "error_26802": "Código de error: 26802, por favor contáctenos",
    "error_26803": "La solicitud falló (código de error: 26803). Por favor vuelve a intentarlo",
    "error_26804": "La solicitud falló (código de error: 26804). Por favor vuelve a intentarlo",
    "error_order": "La solicitud falló (código de error: 27098), ¡inténtelo de nuevo!",
    "error_order1": "La consulta de pedido está incompleta (código de error: ",
    "error_order2": "), actualice e inténtelo de nuevo.",
    "modify_email_title": "Modificar correo electrónico",
    "modify_email_info": "Puede utilizar el correo electrónico modificado para iniciar sesión en su cuenta.",
    "images_per": "Imágenes por",
    "error_26101": "Error: 26101. Por favor contáctenos",
    "error_26102": "Error: 26102. Por favor contáctenos",
    "error_26103": "La solicitud falló (código de error: 26103). Por favor, inténtalo de nuevo",
    "error_26104": "Código de error: 26104, vuelva a intentarlo",
    "error_26105": "Código de error: 26105, vuelva a intentarlo",
    "error_26106": "Error al eliminar (código de error: 26106). Por favor inténtalo de nuevo",
    "error_26201": "Error: 26201. Por favor contáctenos",
    "error_26202": "La solicitud falló (código de error: 26202). Por favor, inténtalo de nuevo",
    "error_26001": "Error: 26001. Por favor contáctenos",
    "error_26002": "Error: 26002. Por favor contáctenos",
    "error_26003": "Error: 26003. Por favor contáctenos",
    "error_26004": "Error: 26004. Por favor contáctenos",
    "error_26005": "La solicitud falló (código de error: 26005). Por favor, inténtalo de nuevo",
    "error_26006": "Código de error: 26006, vuelva a intentarlo",
    "error_26008": "Error: 26008. Por favor contáctenos",
    "go_to_the_home_page": "Ir a la pagina principal",
    "error_27101": "La solicitud falló (código de error: 27101). Por favor, vuelve a intentarlo.",
    "error_27201": "Código de error: 27201, por favor contáctenos",
    "error_27202": "Código de error: 27202, vuelva a intentarlo",
    "error_27203": "La solicitud falló (código de error: 27203). Por favor vuelve a intentarlo",
    "error_27204": "Código no válido (código de error: 27204).",
    "error_27205": "La solicitud falló (código de error: 27205). Por favor vuelve a intentarlo",
    "error_27206": "La solicitud falló (código de error: 27206). Por favor vuelve a intentarlo",
    "error_27207": "La solicitud falló (código de error: 27207). Por favor vuelve a intentarlo",
    "no_history_found": "¡No has usado ninguna herramienta! <a href=\"/\">Actualizar</a> o <a href=\"https://www.mindonmap.com/\">Ir al sitio web oficial</a>",
    "error_25301": "Error: 25301. Por favor contáctenos",
    "error_25302": "Error: 25302. Por favor contáctenos",
    "error_25303": "La solicitud falló (código de error: 25303). Por favor inténtalo de nuevo",
    "error_25304": "La solicitud falló (código de error: 25304). Por favor inténtalo de nuevo",
    "error_25305": "La solicitud falló (código de error: 25305). Por favor inténtalo de nuevo",
    "error_25306": "La solicitud falló (código de error: 25306). Por favor inténtalo de nuevo",
    "image_upscaler_p": "Beneficios de descarga de imágenes sin marca de agua:",
    "Available_for": "Disponible para:",
    "credit_per": "%s crédito(s) por imagen HD",
    "still_valid": "Los planes adquiridos siguen siendo válidos",
    "credit": "crédito(s)",
    "pc_3rd_info": "Inicie sesión exitosamente. Vaya a la aplicación para realizar más operaciones.",
    "use_online": "Utilice el servicio en línea",
    "use_download": "Utilice el programa de escritorio",
    "use_immediately": "Usar inmediatamente",
    "Use_in_browser": "Usar en el navegador",
    "win_desktop": "ventanas",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Créditos por mes",
    "expire": "Tiempo de expiración:",
    "viewDetails": "Ver detalles",
    "viewHistory": "Ver historial de beneficios>>",
    "viewDetailsInfo": "Aviso: si los beneficios de la suscripción se renuevan dentro de los 7 días posteriores al vencimiento, los beneficios no utilizados se pueden seguir utilizando. Además, el tiempo de vencimiento se actualizará automáticamente al tiempo de vencimiento de la nueva suscripción. Si no hay una nueva suscripción después de 7 días de vencimiento, se borrarán todos los beneficios vencidos.",
    "connect_account": "Vincular correo electrónico a su cuenta",
    "connect_account_info": "Después de vincular, puede iniciar sesión con esta dirección de correo electrónico.",
    "connect_now": "Vincular ahora",
    "no_email_bind": "Sin enlace de correo electrónico",
    "bind_email": "Vincular correo electrónico",
    "connect_your_email_placeholder": "Por favor, introduzca su dirección de correo electrónico",
    "bind_an_email": "Vincular un correo electrónico",
    "bind_info": "Has iniciado sesión correctamente. Vincula un correo electrónico a tu cuenta para activar tus beneficios.",
    "bind_later": "Enlazar más tarde",
    "hi": "¡Su, %s!",
    "Personal_Information": "Informacion personal",
    "Access": "Acceso",
    "Subscription_Plan": "(Plan de suscripción)",
    "No_orders": "No se encontraron pedidos.",
    "No_data": "Sin datos",
    "Featured_Products": "Productos Destacados",
    "More_Products": "Más productos",
    "Free_Download": "Descarga gratis",
    "Get_Started": "Empezar",
    "Subscribe": "Suscribir",
    "Verified": "Verificado",
    "back_to_account_center": "Volver al centro de cuentas",
    "success": "¡Éxito!",
    "successfully": "Ha registrado exitosamente una cuenta.",
    "Continue": "Continuar",
    "Already": "¿Ya tienes una cuenta?",
    "loading_verification": "Comprobando el estado de verificación...",
    "email_no_verification": "Lo sentimos, la dirección de correo electrónico registrada no ha sido verificada. Complete la verificación de acuerdo con las instrucciones anteriores y haga clic en el botón \"Listo\" nuevamente para completar el registro.",
    "will_expire_after": "Caducará después",
    "hours": "horas",
  },
  "et": {
    "overtime": "Veakood: {%}, proovige uuesti",
    "isnetwork": "Interneti viga. Kontrollige ja proovige uuesti",
    "email_placeholder": "Meil",
    "email_empty": "Palun sisesta email",
    "email_not_valid": "E-post ei kehti",
    "email_not_valid_1": "Palun sisesta oma email",
    "email_not_valid_2": "E-post ei kehti, kasutage teist aadressi.",
    "email_not_valid_3": "E-posti sisestamine puudub",
    "password_placeholder": "Parool",
    "password_empty": "Palun sisesta parool",
    "password_not_valid": "Vale konto või parool",
    "password_not_valid_1": "Parool on üle 8 tähemärgi nõutav",
    "password_not_valid_2": "Looge parool",
    "password_placeholder_1": "Looge endale parool",
    "password_placeholder_2": "Kinnita oma salasõna",
    "password_placeholder_3": "Loo uus parool",
    "password_placeholder_4": "Kinnita Uus salasõna",
    "password_placeholder_5": "Sisestage vana parool",
    "copy_password_empty": "Kinnitage parool",
    "copy_password_not_valid": "Palun kinnitage oma parool",
    "copy_passwords_inconsistent": "Teie parooli kinnitus ei ühti",
    "code_empty": "Sisestage kinnituskood",
    "code_not_valid": "Kehtetu kinnituskood",
    "code_placeholder": "Kinnituskood",
    "not_received_code": "Kui teie postkasti pole kinnituskoodi pikka aega saanud, hankige kinnituskood uuesti.",
    "get_first_code": "Palun hankige esmalt kinnituskood.",
    "last_name_placeholder": "Palun sisesta oma perekonnanimi",
    "first_name_placeholder": "Palun sisesta oma eesnimi",
    "address_placeholder": "Palun sisesta oma aadress",
    "no_code_text": "Saatsime kinnituskoodi. Palun sisestage oma kood. <span class='tips'>Kas te ei saanud koodi?",
    "no_code_text_1": "1. Veenduge, et e-posti aadress on kehtiv ja see võib meile vastu võtta.",
    "no_code_text_2": "2. Kui süsteem saadab meili automaatselt, võidakse see märgistada rämpsposti või rämpspostina. Kontrollige, kas e-kiri on prügikastis.",
    "no_code_text_3": "3. Kas te ei suuda oma probleemi lahendada? ",
    "no_code_text_3_span": "Seejärel klõpsake meiega ühenduse võtmiseks siin.",
    "order_no": "Te pole ühtegi toodet ostnud. Küsimuste korral <a href=\"https://www.mindonmap.com/contact-us/\">võtke meiega ühendust</a>.",
    "error_24901": "Praegusel kontol pole lingitud e-posti ja tellimusi ei leia. Ühendage meiliaadress.",
    "user_guide": "Kasutusjuhend >>",
    "download": "Lae alla",
    "order_number": "Tellimuse number:",
    "Refund": "Tagasimakse",
    "Disabled": "Keelatud",
    "Normal": "Tavaline",
    "Modify": "Muutma",
    "Modify_1": "Muuda >>",
    "Connect": "Ühendage",
    "unlink_success": "Linkimise tühistamine õnnestus",
    "connect_success": "Ühenda edukalt",
    "feedback_title": "Täname tagasiside eest. Jätke oma probleem ja me vastame teile 24 tunni jooksul.",
    "feedback_thank_you": "Täname!<br />Teie tagasiside edastamine õnnestus.",
    "feedback_email": "Sisesta oma email siia!",
    "feedback_content": "Jätke kõik tekkinud probleemid või soovitused siia.",
    "feedback_submit": "Esita",
    "form_contents": "Te pole kirjeldust sisestanud. Sisestage see ja esitage uuesti.",
    "old_password": "Palun sisesta vana parool",
    "new_password": "Looge uus parool",
    "old_new_password": "Uus parool ei saa olla sama, mis vana",
    "incorrect_password": "vale salasõna",
    "delete_no": "Kustuta kohe",
    "Caps": "Suurtähelukk on sisse lülitatud",
    "Get": "Hangi",
    "Done": "Valmis",
    "error_20001": "Viga: 20001. Palun logi uuesti sisse.",
    "error_20002": "Viga: 20002. Palun logi uuesti sisse.",
    "error_20003": "Viga: 20003. Palun logi uuesti sisse.",
    "error_20004": "Taotlus ebaõnnestus (veakood: 20004). Palun proovi uuesti.",
    "error_20005": "Sisselogimisseanss on aegunud (viga: 20005). Palun logi uuesti sisse.",
    "error_20006": "Taotlus ebaõnnestus (veakood: 20006). Palun proovi uuesti.",
    "error_20007": "Sisselogimisseanss on aegunud (viga: 20007). Palun logi uuesti sisse.",
    "error_20008": "Sisselogimisseanss on aegunud (viga: 20008). Palun logi uuesti sisse.",
    "error_20009": "Sisselogimisseanss on aegunud (viga: 20009). Palun logi uuesti sisse.",
    "error_20101": "Palun sisestage oma e-posti aadress (veakood: 20101)",
    "error_20102": "E-post ei kehti (veakood: 20102)",
    "error_20103": "Taotlus ebaõnnestus (veakood: 20103). Palun proovi uuesti",
    "error_20104": "E-post on juba kasutusel, palun <a href=\"https://account.mindonmap.com/login/\">logige sisse</a> või registreeruge uuega",
    "error_20105": "Taotlus ebaõnnestus (veakood: 20105). Palun proovi uuesti",
    "error_20106": "Meili saatmine ebaõnnestus, proovige uuesti",
    "error_20201": "Palun sisestage oma email (veakood: 20201)",
    "error_20202": "Palun sisestage oma parool (veakood: 20202)",
    "error_20203": "Sisestage kinnituskood (veakood: 20203)",
    "error_20204": "E-post ei kehti (veakood: 20204)",
    "error_20205": "Parool on nõutav üle 8 tähemärgi (veakood: 20205)",
    "error_20206": "Taotlus ebaõnnestus (veakood: 20206). Palun proovi uuesti",
    "error_20207": "Kehtetu kinnituskood",
    "error_20208": "Taotlus ebaõnnestus (veakood: 20208). Palun proovi uuesti",
    "error_20209": "Taotlus ebaõnnestus (veakood: 20209). Palun proovi uuesti",
    "error_20301": "Palun sisestage oma email (veakood: 20301)",
    "error_20302": "Viga: 20302. Palun võtke meiega ühendust",
    "error_20303": "E-post ei kehti (veakood: 20303)",
    "error_20304": "Taotlus ebaõnnestus (veakood: 20304). Palun proovi uuesti",
    "error_20305": "Kontot pole olemas. Sisestage uuesti või <a href=\"https://account.mindonmap.com/register/\">looge see esmalt</a>.",
    "error_20306": "Parooli pole veel. Kasutage <a href=\"https://account.mindonmap.com/passwordless-login/\">paroolita sisselogimist</a> või <a href=\"https://account.mindonmap.com/create-password/\"> määrake parool</a> ja logige sisse.",
    "error_20308": "Taotlus ebaõnnestus (veakood: 20308). Palun proovi uuesti",
    "error_20401": "Väljalogimine ebaõnnestus (veakood: 20401). Palun proovi uuesti",
    "error_20501": "Palun sisestage oma email (veakood: 20501)",
    "error_20502": "E-post ei kehti (veakood: 20502)",
    "error_20503": "Taotlus ebaõnnestus (veakood: 20503). Palun proovi uuesti",
    "error_20504": "Meili saatmine ebaõnnestus. Palun proovi uuesti.",
    "error_20601": "Palun sisestage oma email (veakood: 20601)",
    "error_20602": "Sisestage kinnituskood (veakood: 20602)",
    "error_20603": "E-post ei kehti (veakood: 20603)",
    "error_20604": "Taotlus ebaõnnestus (veakood: 20604). Palun proovi uuesti",
    "error_20606": "Taotlus ebaõnnestus (veakood: 20606). Palun proovi uuesti",
    "error_20607": "Taotlus ebaõnnestus (veakood: 20607). Palun proovi uuesti",
    "error_20608": "Taotlus ebaõnnestus (veakood: 20608). Palun proovi uuesti",
    "error_20701": "Palun sisestage oma email (veakood: 20701)",
    "error_20702": "E-post ei kehti (veakood: 20702)",
    "error_20703": "Taotlus ebaõnnestus (veakood: 20703). Palun proovi uuesti",
    "error_20704": "Kontot pole olemas. Sisestage uuesti või <a href=\"https://account.mindonmap.com/register/\">looge see esmalt</a>.",
    "error_20705": "Taotlus ebaõnnestus (veakood: 20705). Palun proovi uuesti",
    "error_20706": "Meili saatmine ebaõnnestus. Palun proovi uuesti",
    "error_20801": "Palun sisestage oma email (veakood: 20801)",
    "error_20802": "Viga: 20802. Palun võtke meiega ühendust",
    "error_20803": "Sisestage kinnituskood (veakood: 20803)",
    "error_20804": "E-post ei kehti (veakood: 20804)",
    "error_20805": "Parool on nõutav üle 8 tähemärgi (veakood: 20805)",
    "error_20806": "Taotlus ebaõnnestus (veakood: 20806). Palun proovi uuesti",
    "error_20808": "Taotlus ebaõnnestus (veakood: 20808). Palun proovi uuesti",
    "error_20901": "Taotlus ebaõnnestus (veakood: 20901). Palun proovi uuesti",
    "error_20902": "Taotlus ebaõnnestus (veakood: 20902). Palun proovi uuesti",
    "error_21000": "Muudatused salvestatakse",
    "error_21001": "Teavet ei esitata (veakood: 21001)",
    "error_21002": "Taotlus ebaõnnestus (veakood: 21002). Palun proovi uuesti",
    "error_21101": "Palun sisestage oma email (veakood: 21101)",
    "error_21102": "E-post ei kehti (veakood: 21102)",
    "error_21103": "Taotlus ebaõnnestus (veakood: 21103), proovige uuesti",
    "error_21104": "E-post on juba ühendatud, palun kasutage uut",
    "error_21105": "Taotlus ebaõnnestus (veakood: 21105), palun proovige uuesti",
    "error_21106": "Meili saatmine ebaõnnestus. Palun proovi uuesti",
    "error_21201": "Palun sisesta oma email (veakood: 21201)",
    "error_21202": "Sisestage kinnituskood (veakood: 21202)",
    "error_21203": "E-post ei kehti (veakood: 21203)",
    "error_21204": "Viga: 21204. Palun võtke meiega ühendust",
    "error_21205": "Viga: 21205. Palun võtke meiega ühendust",
    "error_21206": "Parool on nõutav üle 8 tähemärgi (veakood: 21206)",
    "error_21207": "Taotlus ebaõnnestus (veakood: 21207). Palun proovi uuesti",
    "error_21209": "Taotlus ebaõnnestus (veakood: 21209). Palun proovi uuesti",
    "error_21301": "Sisestage vana parool (veakood: 21301)",
    "error_21302": "Looge uus parool (veakood: 21302)",
    "error_21303": "Uus parool ei saa olla sama, mis vana. (Viga: 21303)",
    "error_21304": "Parool on nõutav üle 8 tähemärgi (veakood: 21304)",
    "error_21306": "Taotlus ebaõnnestus (veakood: 21306). Palun proovi uuesti",
    "error_21402": "Taotlus ebaõnnestus (veakood: 21402). Palun proovi uuesti",
    "error_21403": "Kinnituskoodi saatmine ebaõnnestus. Palun saatke see uuesti",
    "error_21500": "Konto on kustutatud",
    "error_21501": "Sisestage kinnituskood (veakood: 21501)",
    "error_21502": "Sisselogimisseanss on aegunud (viga: 21502). Palun logi uuesti sisse.",
    "error_21503": "Taotlus ebaõnnestus (veakood: 21503). Palun proovi uuesti",
    "error_21505": "Taotlus ebaõnnestus (veakood: 21505), palun proovige uuesti",
    "error_21601": "Viga: 20601. Palun võtke meiega ühendust",
    "error_21602": "Kehtetu kinnitus (viga: 20602). Palun proovi uuesti.",
    "error_21603": "Viga: 20603. Proovige uuesti",
    "error_21604": "Taotlus ebaõnnestus (veakood: 21604). Palun proovi uuesti",
    "error_21606": "Taotlus ebaõnnestus (veakood: 21606). Palun proovi uuesti",
    "error_21611": "Taotlus ebaõnnestus (veakood: 21611). Palun proovi uuesti",
    "error_21801": "Viga: 21801. Palun võtke meiega ühendust",
    "error_21802": "Taotlus ebaõnnestus (viga: 21802). Palun proovi uuesti",
    "error_21803": "Viga: 21803. Proovige uuesti",
    "error_21804": "Taotlus ebaõnnestus (veakood: 21804). Palun proovi uuesti",
    "error_21806": "Viga: 21806. Proovige uuesti",
    "error_21807": "Viga: 21807. Palun võtke meiega ühendust",
    "error_21808": "Viga: 21808. Palun võtke meiega ühendust",
    "error_21809": "Viga: 21809. Palun võtke meiega ühendust",
    "error_21810": "Viga: 21810. Palun võtke meiega ühendust",
    "error_21811": "Viga: 21811. Palun võtke meiega ühendust",
    "error_21812": "Viga: 21812. Palun võtke meiega ühendust",
    "error_21813": "Taotlus ebaõnnestus (veakood: 21813). Palun proovi uuesti",
    "error_21814": "Viga: 21814. Palun võtke meiega ühendust",
    "error_21815": "Taotlus ebaõnnestus (veakood: 21815). Palun proovi uuesti",
    "error_21816": "Viga: 21816. Palun võtke meiega ühendust",
    "error_21817": "Viga: 21817. Palun võtke meiega ühendust",
    "error_21818": "Viga: 21818. Palun võtke meiega ühendust",
    "error_21819": "Taotlus ebaõnnestus (veakood: 21819). Palun proovi uuesti",
    "error_21820": "Viga: 21820. Palun võtke meiega ühendust",
    "error_21821": "Viga: 21821. Palun võtke meiega ühendust",
    "error_21822": "Viga: 21822. Palun võtke meiega ühendust",
    "error_21823": "Taotlus ebaõnnestus (veakood: 21823). Palun proovi uuesti",
    "error_21824": "Taotlus ebaõnnestus (veakood: 21824). Palun proovi uuesti",
    "error_21825": "Taotlus ebaõnnestus (veakood: 21825). Palun proovi uuesti",
    "error_21826": "Taotlus ebaõnnestus (veakood: 21826). Palun proovi uuesti",
    "error_21901": "Viga: 21901. Palun võtke meiega ühendust",
    "error_21902": "Taotlus ebaõnnestus (veakood: 21902). Palun proovi uuesti",
    "error_21903": "Konto olek on muutunud (veakood: 21903), värskendage lehte ja proovige uuesti",
    "error_21904": "Viga: 21904. Proovige uuesti",
    "error_21905": "Viga: 21905. Proovige uuesti",
    "error_21906": "Taotlus ebaõnnestus (veakood: 21906). Palun proovi uuesti",
    "error_21907": "Google'i konto on lingitud teise kontoga",
    "error_21908": "Taotlus ebaõnnestus (veakood: 21908). Palun proovi uuesti",
    "error_22001": "Taotlus ebaõnnestus (veakood: 22001). Palun proovi uuesti",
    "error_22002": "Linkimise tühistamine ebaõnnestus ilma täiendava sisselogimiseta",
    "error_22003": "Taotlus ebaõnnestus (veakood: 22003). Palun proovi uuesti",
    "error_22101": "Viga: 22101. Palun võtke meiega ühendust",
    "error_22102": "Konto olek on muutunud (veakood: 22102), värskendage lehte ja proovige uuesti",
    "error_22103": "Taotlus ebaõnnestus (veakood: 22103). Palun proovi uuesti",
    "error_22104": "Konto olek on muutunud (veakood: 22104), värskendage lehte ja proovige uuesti",
    "error_22105": "Viga: 22105. Proovige uuesti",
    "error_22106": "Viga: 22106. Proovige uuesti",
    "error_22107": "Viga: 22107. Palun võtke meiega ühendust",
    "error_22108": "Taotlus ebaõnnestus (veakood: 22108). Palun proovi uuesti",
    "error_22201": "Viga: 22201. Palun võtke meiega ühendust",
    "error_22202": "Kehtetu kinnitus (viga: 22202). Palun proovi uuesti.",
    "error_22203": "Viga: 22203. Proovige uuesti\"",
    "error_22204": "Taotlus ebaõnnestus (veakood: 22204). Palun proovi uuesti",
    "error_22206": "Taotlus ebaõnnestus (veakood: 22206). Palun proovi uuesti",
    "error_22401": "Viga: 22401. Palun võtke meiega ühendust",
    "error_22402": "Kehtetu kinnitus (viga: 22402). Palun proovi uuesti.",
    "error_22403": "Viga: 22403. Proovige uuesti",
    "error_22404": "Taotlus ebaõnnestus (veakood: 22404). Palun proovi uuesti",
    "error_22405": "Facebooki konto on lingitud teise meiliga",
    "error_22406": "Viga: 22406. Proovige uuesti",
    "error_22407": "Viga: 22407. Palun võtke meiega ühendust",
    "error_22408": "Viga: 22408. Palun võtke meiega ühendust",
    "error_22409": "Viga: 22409. Palun võtke meiega ühendust",
    "error_22410": "Viga: 224010. Palun võtke meiega ühendust",
    "error_22411": "Viga: 224011. Palun võtke meiega ühendust",
    "error_22412": "Viga: 224012. Palun võtke meiega ühendust",
    "error_22413": "Viga: 22413. Palun võtke meiega ühendust",
    "error_22414": "Taotlus ebaõnnestus (veakood: 22414). Palun proovi uuesti",
    "error_22415": "Viga: 22415. Palun võtke meiega ühendust",
    "error_22416": "Viga: 22416. Palun võtke meiega ühendust",
    "error_22417": "Viga: 22417. Palun võtke meiega ühendust",
    "error_22418": "Taotlus ebaõnnestus (veakood: 22418). Palun proovi uuesti",
    "error_22419": "Viga: 22419. Palun võtke meiega ühendust",
    "error_22420": "Viga: 22420. Palun võtke meiega ühendust",
    "error_22421": "Viga: 22421. Palun võtke meiega ühendust",
    "error_22422": "Taotlus ebaõnnestus (veakood: 22422). Palun proovi uuesti",
    "error_22423": "Taotlus ebaõnnestus (veakood: 22423). Palun proovi uuesti",
    "error_22424": "Taotlus ebaõnnestus (veakood: 22424). Palun proovi uuesti",
    "error_22425": "Taotlus ebaõnnestus (veakood: 22425). Palun proovi uuesti",
    "error_20098": "Veakood: 20098. Kui sirvite privaatrežiimis, lülituge tavarežiimile ja proovige uuesti.",
    "error_22298": "Google'i taotlus nurjus (viga: 22298). Palun proovi uuesti.",
    "error_22498": "Facebooki taotlus nurjus (viga: 22498). Palun proovi uuesti.",
    "error_24902": "Taotlus ebaõnnestus (veakood: 24902). Palun proovi uuesti",
    "error_24903": "Taotlus ebaõnnestus (veakood: 24903). Palun proovi uuesti",
    "error_24904": "Taotlus ebaõnnestus (veakood: 24904). Palun proovi uuesti",
    "error_24905": "Taotlus ebaõnnestus (veakood: 24905). Palun proovi uuesti",
    "login_title": "Logige MindOnMapi sisse",
    "log_in": "Logi sisse",
    "no_account": "Pole kontot?",
    "create_it": "Loo see",
    "or_log_in_with": "Või logige sisse",
    "passwordless_login": "Paroolita sisselogimine",
    "log_in_done": "Sisselogimine Valmis",
    "three_rd_account_connect_info": "Palju õnne! Olete edukalt sisse loginud. Nüüd saate ühendada ühe meilikonto, mida kasutatakse edaspidi sisselogimiseks.",
    "see_my_account": "Vaadake minu kontot",
    "three_rd_login_merge_account": "Kolmanda osapoole konto e-posti aadress on registreeritud. Kas soovite selle e-posti aadressiga otse ühenduse luua ja sisse logida?",
    "connect_log_in": "Ühendage ja logige sisse",
    "create_an_account": "Kontot looma",
    "back_to_log_in": "Tagasi sisselogimiseks",
    "create_password": "Loo salasõna",
    "create_now": "Loo kohe",
    "password_login_subtitle": "Paroolita sisselogimine e-postiga",
    "account_login": "Konto sisselogimine",
    "rights": "Selle konto loomisega nõustute <a href=\"https://www.mindonmap.com/terms-and-conditions/\">teenusetingimuste</a> ja <a href=\"https://www.mindonmap.com/privacy-policy/\">Privaatsuspoliitika</a>",
    "passwordless_login_done": "Paroolita sisselogimine tehtud",
    "passwordless_login_info": "Õnnitleme, olete paroolita sisselogimise edukalt lõpetanud. Selle konto jaoks saate luua parooli ning edaspidi selle konto ja parooliga sisse logida. <a href=\"/create-password\" style=\"display: initial;\">Loo kohe</a>",
    "sign_up": "Registreeri",
    "register_info": "Loo enda konto",
    "reset_now": "Lähtestage kohe",
    "forgot_password": "Unustasid parooli",
    "reset_password_subtitle": "Kasutage parooli lähtestamiseks oma konto e-posti",
    "plan_products": "Plaanid ja tooted",
    "nick_name": "Kasutajanimi:",
    "email": "Meil:",
    "my_products": "Minu tooted",
    "my_orders": "Minu tellimused",
    "unlink": "Tühista linkimine",
    "link": "Link",
    "connected_accounts": "Ühendatud kontod",
    "last_name": "Perekonnanimi:",
    "first_name": "Eesnimi:",
    "Gender": "Sugu:",
    "Birth": "Sünd:",
    "Month": "Kuu",
    "Year": "aasta",
    "Country_Region": "Riik/piirkond:",
    "Address": "Aadress:",
    "Save": "Salvesta",
    "Date": "Kuupäev",
    "Male": "Mees",
    "Female": "Naine",
    "Unspecified": "Täpsustamata",
    "Security": "Turvalisus",
    "change_password": "Muuda salasõna",
    "change_now": "Muuda kohe",
    "connect_email": "Ühendage meil",
    "delete_account": "Kustuta konto",
    "delete_account_info": "Kui teie konto kustutatakse, kustutatakse kõik teie kontoga seotud Minu konto andmed jäädavalt ja te ei pruugi neid taastada. Soovitame liikuda ettevaatlikult.",
    "Delete": "Kustuta",
    "Logout": "Logi välja",
    "my_profile": "Minu profiil",
    "guides_faqs": "Juhendid ja KKK",
    "More": "Rohkem",
    "guides": "Juhendid",
    "register": "Registreeri",
    "hot_faq": "Kuumad KKK",
    "Contents": "Sisu:",
    "contact_us": "Võtke meiega ühendust >>",
    "plan": "Plaan",
    "unregistered": "Registreerimata",
    "buy_more": "Osta rohkem",
    "buy_again": "Osta uuesti",
    "buy_now": "Osta kohe",
    "free_no_limit": "Tasuta ja piiranguteta",
    "expired": "Aegunud",
    "lifetime": "Eluaeg",
    "remain": "Jääda",
    "day_s": "päev(ad)",
    "error_24801": "Taotlus ebaõnnestus (veakood: 24801). Palun proovi uuesti",
    "no_app_found": "Rakendust ei leitud!<a href=\"/\">Värskendage</a> või <a href=\"https://www.mindonmap.com/\">Minge ametlikule veebisaidile</a>",
    "get_more": "Hankige rohkem >>",
    "edit_photo": "Redigeeri fotot",
    "select_photo": "Valige foto",
    "change_photo": "Muuda fotot",
    "cancel": "Tühista",
    "hide_password": "Peida parool",
    "show_password": "Näita salasõna",
    "zoom_in": "Suurenda",
    "zoom_out": "Suumi välja",
    "rotate": "Pöörake",
    "horizontal_flip": "Horisontaalne klapp",
    "vertical_flip": "Vertikaalne klapp",
    "country": "Riik",
    "country_1": "Valige oma riik/piirkond",
    "country_2": "Ahvenamaa saar",
    "country_3": "Afganistan",
    "country_4": "Albaania",
    "country_5": "Alžeeria",
    "country_6": "Ameerika Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktika",
    "country_11": "Antigua ja Barbuda",
    "country_12": "Argentina",
    "country_13": "Armeenia",
    "country_14": "Aruba",
    "country_15": "Austraalia",
    "country_16": "Austria",
    "country_17": "Aserbaidžaan",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Valgevene",
    "country_22": "Belgia",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Boliivia",
    "country_28": "Bosnia ja Hertsegoviina",
    "country_29": "Botswana",
    "country_30": "Bouvet’ saar",
    "country_31": "Brasiilia",
    "country_32": "Briti India ookeani territoorium",
    "country_33": "Briti Neitsisaared",
    "country_34": "Brunei",
    "country_35": "Bulgaaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Roheneemesaared",
    "country_42": "Kariibi mere Madalmaad",
    "country_43": "Kaimani saared",
    "country_44": "Kesk-Aafrika Vabariik",
    "country_45": "Tšaad",
    "country_46": "Tšiili",
    "country_47": "Hiina",
    "country_48": "Jõulusaar",
    "country_49": "Kookose (Keelingi) saared",
    "country_50": "Kolumbia",
    "country_51": "Cooki saared",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Horvaatia",
    "country_55": "Kuuba",
    "country_56": "Küpros",
    "country_57": "Tšehhi Vabariik",
    "country_58": "Kongo Demokraatlik Vabariik",
    "country_59": "Taani",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominikaani Vabariik",
    "country_63": "Ecuador",
    "country_64": "Egiptus",
    "country_65": "El Salvador",
    "country_66": "Ekvatoriaalne Guinea",
    "country_67": "Eritrea",
    "country_68": "Eesti",
    "country_69": "Etioopia",
    "country_70": "Falklandi saared",
    "country_71": "Fääri saared",
    "country_72": "Mikroneesia liitriigid",
    "country_73": "Fidži",
    "country_74": "Soome",
    "country_75": "Prantsusmaa",
    "country_76": "Prantsuse Guajaana",
    "country_77": "Prantsuse Polüneesia",
    "country_78": "Prantsuse lõunapoolsed territooriumid",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Gruusia",
    "country_82": "Saksamaa",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Suurbritannia (Ühendkuningriik; Inglismaa)",
    "country_86": "Kreeka",
    "country_87": "Gröönimaa",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guajaana",
    "country_96": "Haiti",
    "country_97": "Heardi saar ja McDonaldi saared",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Ungari",
    "country_101": "Island",
    "country_102": "India",
    "country_103": "Indoneesia",
    "country_104": "Iraan",
    "country_105": "Iraak",
    "country_106": "Iirimaa",
    "country_107": "Mani saar",
    "country_108": "Iisrael",
    "country_109": "Itaalia",
    "country_110": "Jamaica",
    "country_111": "Jaapan",
    "country_112": "Jersey",
    "country_113": "Jordaania",
    "country_114": "Kasahstan",
    "country_115": "Keenia",
    "country_116": "Kiribati",
    "country_117": "Kuveit",
    "country_118": "Kõrgõzstan",
    "country_119": "Laos",
    "country_120": "Läti",
    "country_121": "Liibanon",
    "country_122": "Lesotho",
    "country_123": "Libeeria",
    "country_124": "Liibüa",
    "country_125": "Liechtenstein",
    "country_126": "Leedu",
    "country_127": "Luksemburg",
    "country_128": "Aomeni",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaisia",
    "country_132": "Maldiivid",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshalli saared",
    "country_136": "Martinique",
    "country_137": "Mauritaania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mehhiko",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongoolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mosambiik",
    "country_148": "Myanmar (Birma)",
    "country_149": "Namiibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Holland",
    "country_153": "Uus-Kaledoonia",
    "country_154": "Uus-Meremaa",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeeria",
    "country_158": "Niue",
    "country_159": "Norfolki saar",
    "country_160": "Põhja-Korea",
    "country_161": "Põhja-Mariana saared",
    "country_162": "Norra",
    "country_163": "Omaan",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestiina territooriumid",
    "country_167": "Panama",
    "country_168": "Paapua Uus-Guinea",
    "country_169": "Paraguay",
    "country_170": "Peruu",
    "country_171": "Pitcairni saared",
    "country_172": "Poola",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Makedoonia Vabariik (FYROM)",
    "country_177": "Kongo Vabariik",
    "country_178": "Réunion",
    "country_179": "Rumeenia",
    "country_180": "Venemaa Föderatsioon",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Prantsusmaa)",
    "country_184": "Saint-Pierre ja Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome ja printsiip",
    "country_188": "Saudi Araabia",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seišellid",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Slovakkia",
    "country_195": "Sloveenia",
    "country_196": "Saalomoni saared",
    "country_197": "Somaalia",
    "country_198": "Lõuna-Aafrika",
    "country_199": "Lõuna-Gruusia ja Lõuna-Sandwichi saar",
    "country_200": "Lõuna-Korea",
    "country_201": "Lõuna-Sudaan",
    "country_202": "Hispaania",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena ja sõltuvused",
    "country_205": "St. Kitts ja Nevis",
    "country_206": "Püha Lucia",
    "country_207": "St. Vincent ja Grenadiinid",
    "country_208": "Sudaan",
    "country_209": "Suriname",
    "country_210": "Svaasimaa",
    "country_211": "Rootsi",
    "country_212": "Šveits",
    "country_213": "Süüria",
    "country_214": "Taiwan",
    "country_215": "Tadžikistan",
    "country_216": "Tansaania",
    "country_217": "Mall: riigi andmed SJM Svalbard",
    "country_218": "Tai",
    "country_219": "Bahama saared",
    "country_220": "Komoorid",
    "country_221": "Filipiinid",
    "country_222": "Ida-Timor (Ida-Timor)",
    "country_223": "Minema",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad ja Tobago",
    "country_227": "Tuneesia",
    "country_228": "Türgi",
    "country_229": "Türkmenistan",
    "country_230": "Turksi ja Caicose saared",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Araabia Ühendemiraadid",
    "country_235": "Ameerika Ühendriikide kõrvalsaared",
    "country_236": "Ameerika Ühendriigid (USA)",
    "country_237": "Ameerika Ühendriigid Neitsisaared",
    "country_238": "Uruguay",
    "country_239": "Usbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikan (Püha Tool)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis ja Futuna",
    "country_245": "Lääne-Sahara",
    "country_246": "Jeemen",
    "country_247": "Sambia",
    "country_248": "Zimbabwe",
    "google_login": "Logige sisse Google'iga",
    "State": "osariik",
    "Activation_code": "Aktiveerimis kood",
    "Question": "Loetlege kõik rakendused, mille olete sisse loginud",
    "Copy_complete": "Kopeerimine on lõpetatud",
    "footer": "Autoriõigus © 2024 MindOnMap Studio. Kõik õigused kaitstud.",
    "change_password_success": "Parooli muutmine õnnestus",
    "successful_login_title": "Sisselogimine õnnestus",
    "product_page": "Tooteleht >>",
    "successful_login_info": "Sisselogimine lõpetatud. Järgmise protsessi jätkamiseks sulgege praegune leht ja minge tagasi algsele vahekaardile. Praegune leht suletakse automaatselt 5 sekundi pärast. Kui automaatne sulgemine või sulgemine nupul \"Valmis\" klõpsates ebaõnnestub, sulgege see vahekaart otse.",
    "successful_login_info_firefox": "Sisselogimine lõpetatud. Järgmise protsessi jätkamiseks sulgege praegune leht ja minge tagasi algsele vahekaardile.",
    "my_account": "Minu konto",
    "my_history": "Minu ajalugu",
    "remove_watermark": "Eemalda vesimärk",
    "no_history": "Ajalugu pole",
    "history_all": "Vali kõik",
    "history_open": "Avatud",
    "history_down": "Lae alla",
    "history_delete": "Kustuta",
    "history_clear": "Tühjenda kehtetu",
    "images": "pilt(id)",
    "use_this_function": "Kasutage seda toodet >>",
    "hd_downloading": "HD originaalpiltide allalaadimise eelised:",
    "lifetimeRemaining": "Eluaegne järelejäänud",
    "Remaining": "Ülejäänud",
    "email_verification": "E-posti kinnitamine",
    "email_verification_info": "Saatsime kinnitussõnumi teie e-posti aadressile <span class=\"email\"></span> ja palun viige kinnitamine lõpule. Pärast kinnitamist sünkroonitakse eelised automaatselt.",
    "wrong_email": "Vale meiliaadress?",
    "click_here_to_modify": "Muutmiseks klõpsake siin",
    "get_more_help": "Kas saada rohkem abi?",
    "click_here": "Kliki siia",
    "email_verification_info_success": "Õnnitleme oma meilikonto kinnitamise puhul.",
    "email_verification_info_error": "Kinnitamine ebaõnnestus aegunud lingi tõttu.",
    "registration_succeeded": "Registreerimine õnnestus",
    "registration_succeeded_info_1": "Palju õnne! Registreerusite edukalt. Saatsime kinnitussõnumi teie e-posti aadressile <span class=\"email\"></span> ja selle meili eeliste kasutamiseks viige kinnitamine lõpule.",
    "registration_succeeded_info_2": "Avalehele naasmiseks ja selle toote kasutamiseks klõpsake \"Valmis\".",
    "registration_succeeded_info_3": "Praeguse lehe sulgemiseks ja avalehele naasmiseks järgmiste toimingute tegemiseks klõpsake \"Valmis\". Kui sulgemine ebaõnnestub, sulgege vahekaart käsitsi.",
    "verify_email": "E-posti kinnitamine",
    "registered_email_not_verified": "Registreeritud e-posti aadressi ei ole kinnitatud, palun kinnitage see kohe.",
    "email_verification_time_1": "Kas te ei saanud kinnitusmeili?",
    "email_verification_time_2": "Pärast",
    "email_verification_time_3": "Uuesti saatmiseks klõpsake siin",
    "error_26301": "Veakood: 26301, võtke meiega ühendust",
    "error_26302": "Veakood: 26302, võtke meiega ühendust",
    "error_26303": "Meili vormingu viga (veakood: 26303). Palun sisestage see uuesti",
    "error_26304": "Parool on soovitatav üle 8 tähemärgi (veakood: 26304)",
    "error_26305": "Reuquest ebaõnnestus (veakood: 26305). Palun proovi uuesti",
    "error_26306": "E-post on registreeritud, palun <a href=\"https://account.mindonmap.com/login/\">mine sisse logimiseks</a>",
    "error_26307": "Reuquest ebaõnnestus (veakood: 26307). Palun proovi uuesti",
    "error_26308": "Reuquest ebaõnnestus (veakood: 26308). Palun proovi uuesti",
    "error_26401": "Veakood: 26401, proovige uuesti",
    "error_26402": "E-post on kinnitatud (veakood: 26402), proovige uuesti",
    "error_26403": "Reuquest ebaõnnestus (veakood: 26403). Palun proovi uuesti",
    "error_26404": "Taotlus ebaõnnestus (veakood: 26404). Palun proovi uuesti",
    "error_26501": "Veakood: 26501, võtke meiega ühendust",
    "error_26502": "Veakood: 26502, võtke meiega ühendust",
    "error_26503": "Meili vormingu viga (veakood: 26503). Palun sisestage see uuesti",
    "error_26504": "Reuquest ebaõnnestus (veakood: 26504). Palun proovi uuesti",
    "error_26505": "Meil ei ole registreeritud, palun <a href=\"https://account.mindonmap.com/register/\">registreerige see esmalt</a>",
    "error_26506": "E-post on kinnitatud.",
    "error_26507": "Reuquest ebaõnnestus (veakood: 26507). Palun proovi uuesti",
    "error_26508": "Kinnitus sooritati (veakood: 26508), proovige uuesti",
    "error_26509": "Taotlus ebaõnnestus (veakood: 26509), proovige uuesti",
    "error_26510": "Veakood: 26510, võtke meiega ühendust",
    "error_26601": "Veakood: 26601, võtke meiega ühendust",
    "error_26602": "Veakood: 26602, võtke meiega ühendust",
    "error_26603": "Reuquest ebaõnnestus (veakood: 26603). Palun proovi uuesti",
    "error_26604": "Veakood: 26604, võtke meiega ühendust",
    "error_26605": "Veakood: 26605, võtke meiega ühendust",
    "error_26701": "Veakood: 26701, võtke meiega ühendust",
    "error_26702": "Reuquest ebaõnnestus (veakood: 26702). Palun proovi uuesti",
    "error_26703": "Veakood: 26703, võtke meiega ühendust",
    "error_26704": "Veakood: 26704, võtke meiega ühendust",
    "error_26705": "Oodake sisselogimist (veakood: 26705). Palun proovi uuesti",
    "no_cookie": "Olete oma brauseris sisse lülitanud funktsiooni Blokeeri kõik küpsised, mistõttu te ei saa sisse logida. Avage Seaded, et märkida ruut Luba kõik küpsised.",
    "error_26801": "Veakood: 26801, võtke meiega ühendust",
    "error_26802": "Veakood: 26802, võtke meiega ühendust",
    "error_26803": "Taotlus ebaõnnestus (veakood: 26803). Palun proovi uuesti",
    "error_26804": "Taotlus ebaõnnestus (veakood: 26804). Palun proovi uuesti",
    "error_order": "Taotlus ebaõnnestus (veakood: 27098), proovige uuesti!",
    "error_order1": "Tellimuse päring on mittetäielik (veakood: ",
    "error_order2": "）, värskendage ja proovige uuesti.",
    "modify_email_title": "Muuda meili",
    "modify_email_info": "Saate oma kontole sisselogimiseks kasutada muudetud e-posti aadressi.",
    "images_per": "Pildid per",
    "error_26101": "Viga: 26101. Palun võtke meiega ühendust",
    "error_26102": "Viga: 26102. Palun võtke meiega ühendust",
    "error_26103": "Taotlus ebaõnnestus (veakood: 26103). Proovige uuesti",
    "error_26104": "Veakood: 26104, proovige uuesti",
    "error_26105": "Veakood: 26105, palun proovige uuesti",
    "error_26106": "Kustutamine ebaõnnestus (veakood: 26106). Palun proovi uuesti",
    "error_26201": "Viga: 26201. Palun võtke meiega ühendust",
    "error_26202": "Taotlus nurjus (veakood: 26202). Proovige uuesti",
    "error_26001": "Viga: 26001. Palun võtke meiega ühendust",
    "error_26002": "Viga: 26002. Palun võtke meiega ühendust",
    "error_26003": "Viga: 26003. Palun võtke meiega ühendust",
    "error_26004": "Viga: 26004. Palun võtke meiega ühendust",
    "error_26005": "Taotlus nurjus (veakood: 26005). Proovige uuesti",
    "error_26006": "Veakood: 26006, palun proovige uuesti",
    "error_26008": "Viga: 26008. Palun võtke meiega ühendust",
    "go_to_the_home_page": "Minge avalehele",
    "error_27101": "Taotlus ebaõnnestus (veakood: 27101). Palun proovi ist uuesti",
    "error_27201": "Veakood: 27201, võtke meiega ühendust",
    "error_27202": "Veakood: 27202, proovige uuesti",
    "error_27203": "Taotlus ebaõnnestus (veakood: 27203). Palun proovi uuesti",
    "error_27204": "Vale kood (veakood: 27204).",
    "error_27205": "Taotlus ebaõnnestus (veakood: 27205). Palun proovi uuesti",
    "error_27206": "Taotlus ebaõnnestus (veakood: 27206). Palun proovi uuesti",
    "error_27207": "Taotlus ebaõnnestus (veakood: 27207). Palun proovi uuesti",
    "no_history_found": "Te pole ühtegi tööriista kasutanud! <a href=\"/\">Värskendage</a> või <a href=\"https://www.mindonmap.com/\">Minge ametlikule veebisaidile</a>",
    "error_25301": "Viga: 25301. Palun võtke meiega ühendust",
    "error_25302": "Viga: 25302. Palun võtke meiega ühendust",
    "error_25303": "Taotlus ebaõnnestus (veakood: 25303). Palun proovi uuesti",
    "error_25304": "Taotlus ebaõnnestus (veakood: 25304). Palun proovi uuesti",
    "error_25305": "Taotlus nurjus (veakood: 25305). Palun proovi uuesti",
    "error_25306": "Taotlus ebaõnnestus (veakood: 25306). Palun proovi uuesti",
    "image_upscaler_p": "Pilt ilma vesimärgi allalaadimise eelised:",
    "Available_for": "Saadaval:",
    "credit_per": "%s krediiti HD-pildi kohta",
    "still_valid": "Ostetud kava(d) kehtivad endiselt",
    "credit": "krediit(id)",
    "pc_3rd_info": "Sisselogimine õnnestus. Edasiseks toimimiseks avage rakendus.",
    "use_online": "Kasutage võrguteenust",
    "use_download": "Kasutage töölauaprogrammi",
    "use_immediately": "Kasutage kohe",
    "Use_in_browser": "Kasutage brauseris",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} krediiti kuus",
    "expire": "Aegumisaeg:",
    "viewDetails": "Vaata detaile",
    "viewHistory": "Vaadake ajaloo eeliseid>>",
    "viewDetailsInfo": "Märkus. Kui tellimuse soodustusi uuendatakse 7 päeva jooksul pärast aegumist, saab kasutamata soodustusi edasi kasutada. Samuti värskendatakse aegumisaega automaatselt uue tellimuse aegumisaja järgi. Kui 7-päevase aegumisaja möödudes uut tellimust pole, kustutatakse kõik aegunud hüved.",
    "connect_account": "Siduge e-kiri oma kontoga",
    "connect_account_info": "Pärast sidumist saate selle e-posti aadressiga sisse logida.",
    "connect_now": "Seo kohe",
    "no_email_bind": "Meili ei siduta",
    "bind_email": "E-posti sidumine",
    "connect_your_email_placeholder": "Palun sisestage oma e-posti aadress",
    "bind_an_email": "Siduge e-kiri",
    "bind_info": "Olete edukalt sisse loginud. Hüvitiste aktiveerimiseks siduge oma kontoga e-kiri.",
    "bind_later": "Siduge hiljem",
    "hi": "Tere, %s!",
    "Personal_Information": "Isiklik informatsioon",
    "Access": "Juurdepääs",
    "Subscription_Plan": "(Tellimisplaan)",
    "No_orders": "Tellimusi ei leitud.",
    "No_data": "Andmed puuduvad",
    "Featured_Products": "Soovitatavad tooted",
    "More_Products": "Rohkem tooteid",
    "Free_Download": "Tasuta allalaadimine",
    "Get_Started": "Alustama",
    "Subscribe": "Telli",
    "Verified": "Kinnitatud",
    "back_to_account_center": "Tagasi kontokeskusesse",
    "success": "Edu!",
    "successfully": "Olete edukalt konto registreerinud.",
    "Continue": "Jätka",
    "Already": "On juba konto?",
    "loading_verification": "Kontrollimise oleku kontrollimine...",
    "email_no_verification": "Kahjuks ei ole registreeritud e-posti aadressi kinnitatud. Lõpetage kinnitus vastavalt ülaltoodud juhistele ja klõpsake registreerimise lõpetamiseks uuesti nuppu \"Valmis\".",
    "will_expire_after": "Aegub pärast",
    "hours": "tundi",
  },
  "eu": {
    "overtime": "Errore-kodea: {%}, saiatu berriro",
    "isnetwork": "Interneteko errorea. Mesedez, egiaztatu eta saiatu berriro",
    "email_placeholder": "Posta elektronikoa",
    "email_empty": "Mesedez, idatzi helbide elektronikoa",
    "email_not_valid": "Posta elektronikoa ez da baliozkoa",
    "email_not_valid_1": "Mesedez, sartu zure helbide elektronikoa",
    "email_not_valid_2": "Posta elektronikoa ez da baliozkoa, mesedez erabili beste helbide bat.",
    "email_not_valid_3": "Ez dago posta elektronikoko sarrerarik",
    "password_placeholder": "Pasahitza",
    "password_empty": "Mesedez, idatzi pasahitza",
    "password_not_valid": "Kontu edo pasahitz okerra",
    "password_not_valid_1": "8 karaktere baino gehiagoko pasahitza beharrezkoa da",
    "password_not_valid_2": "Mesedez, sortu pasahitza",
    "password_placeholder_1": "Sortu zure pasahitza",
    "password_placeholder_2": "Berretsi pasahitza",
    "password_placeholder_3": "Sortu pasahitz berria",
    "password_placeholder_4": "Berretsi pasahitz berria",
    "password_placeholder_5": "Idatzi pasahitz zaharra",
    "copy_password_empty": "Mesedez, berretsi pasahitza",
    "copy_password_not_valid": "Mesedez, berretsi pasahitza",
    "copy_passwords_inconsistent": "Zure pasahitzaren berrespena ez dator bat",
    "code_empty": "Mesedez, idatzi egiaztapen-kodea",
    "code_not_valid": "Egiaztapen-kode baliogabea",
    "code_placeholder": "Egiaztapen Kodea",
    "not_received_code": "Zure postontziak denbora luzez egiaztapen-kodea jaso ez badu, mesedez, eskuratu berriro egiaztapen-kodea.",
    "get_first_code": "Mesedez, lortu egiaztapen-kodea lehenik.",
    "last_name_placeholder": "Mesedez, idatzi zure abizena",
    "first_name_placeholder": "Mesedez, idatzi zure izena",
    "address_placeholder": "Mesedez, idatzi zure helbidea",
    "no_code_text": "Egiaztapen-kode bat bidali dugu. Mesedez, sartu zure kodea. <span class='tips'>Ez al duzu koderik jaso?",
    "no_code_text_1": "1. Mesedez, ziurtatu helbide elektronikoa baliozkoa dela eta mezu elektronikoak jaso ditzakeela.",
    "no_code_text_2": "2. Sistemak automatikoki bidaltzen duen mezu elektronikoa, baliteke spam edo zabor mezu gisa markatzea. Mesedez, egiaztatu posta elektronikoa zakarrontziko karpetan dagoen.",
    "no_code_text_3": "3. Ezin duzu zure arazoa konpondu? ",
    "no_code_text_3_span": "Ondoren, egin klik hemen gurekin harremanetan jartzeko.",
    "order_no": "Ez duzu produkturik erosi, Galderarik baduzu, <a href=\"https://www.mindonmap.com/contact-us/\">jarri gurekin harremanetan</a>.",
    "error_24901": "Uneko kontuak ez du posta elektronikorik loturik, eta ezin ditu eskaerak aurkitu. Mesedez, konektatu posta elektroniko bat.",
    "user_guide": "Erabiltzailearen Gida>>",
    "download": "Deskargatu",
    "order_number": "Eskaera zenbakia:",
    "Refund": "Itzulketa",
    "Disabled": "Desgaituta",
    "Normal": "Normala",
    "Modify": "Aldatu",
    "Modify_1": "Aldatu >>",
    "Connect": "Konektatu",
    "unlink_success": "Deskonektatu behar bezala",
    "connect_success": "Konektatu behar bezala",
    "feedback_title": "Eskerrik asko zure iritziarengatik. Mesedez, utzi zure arazoa eta 24 orduko epean erantzungo dizugu.",
    "feedback_thank_you": "Eskerrik asko!<br />Zure Iritzia behar bezala bidali da.",
    "feedback_email": "Sartu zure helbide elektronikoa hemen!",
    "feedback_content": "Utzi hemen aurkitu duzun edozein arazo edo iradokizun.",
    "feedback_submit": "Bidali",
    "form_contents": "Ez duzu deskribapenik sartu. Mesedez, sartu eta bidali berriro.",
    "old_password": "Mesedez, idatzi pasahitz zaharra",
    "new_password": "Mesedez, sortu pasahitz berri bat",
    "old_new_password": "Pasahitz berria ezin da izan zaharraren berdina",
    "incorrect_password": "Pasahitz okerra",
    "delete_no": "Ezabatu orain",
    "Caps": "Maiuskulak aktibatuta daude",
    "Get": "Lortu",
    "Done": "Eginda",
    "error_20001": "Errorea: 20001. Hasi saioa berriro.",
    "error_20002": "Errorea: 20002. Hasi saioa berriro.",
    "error_20003": "Errorea: 20003. Hasi saioa berriro.",
    "error_20004": "Eskaerak huts egin du (error-kodea: 20004). Mesedez, saiatu berriro.",
    "error_20005": "Saioa hasteko iraungi da (Errorea: 20005). Hasi saioa berriro.",
    "error_20006": "Eskaerak huts egin du (error-kodea: 20006). Mesedez, saiatu berriro.",
    "error_20007": "Saioa hasteko iraungi da (Errorea: 20007). Hasi saioa berriro.",
    "error_20008": "Saioa hasteko saioa iraungi da (Errorea: 20008). Hasi saioa berriro.",
    "error_20009": "Saioa hasteko saioa iraungi da (Errorea: 20009). Hasi saioa berriro.",
    "error_20101": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20101)",
    "error_20102": "Posta elektronikoa ez da baliozkoa (error-kodea: 20102)",
    "error_20103": "Eskaerak huts egin du (error-kodea: 20103). Mesedez, saiatu berriro",
    "error_20104": "Posta elektronikoa erabiltzen ari da dagoeneko, mesedez <a href=\"https://account.mindonmap.com/login/\">hasi saioa</a> edo erregistratu berri batekin",
    "error_20105": "Eskaerak huts egin du (error-kodea: 20105). Mesedez, saiatu berriro",
    "error_20106": "Ezin izan da mezu elektronikoa bidali. Saiatu berriro",
    "error_20201": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20201)",
    "error_20202": "Idatzi pasahitza (error-kodea: 20202)",
    "error_20203": "Mesedez, idatzi egiaztapen-kodea (error-kodea: 20203)",
    "error_20204": "Posta elektronikoa ez da baliozkoa (error-kodea: 20204)",
    "error_20205": "8 karaktere baino gehiagoko pasahitza behar da (error-kodea: 20205)",
    "error_20206": "Eskaerak huts egin du (error-kodea: 20206). Mesedez, saiatu berriro",
    "error_20207": "Egiaztapen-kode baliogabea",
    "error_20208": "Eskaerak huts egin du (error-kodea: 20208). Mesedez, saiatu berriro",
    "error_20209": "Eskaerak huts egin du (error-kodea: 20209). Mesedez, saiatu berriro",
    "error_20301": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20301)",
    "error_20302": "Errorea: 20302. Jarri gurekin harremanetan",
    "error_20303": "Posta elektronikoa ez da baliozkoa (error-kodea: 20303)",
    "error_20304": "Eskaerak huts egin du (error-kodea: 20304). Mesedez, saiatu berriro",
    "error_20305": "Kontua ez da existitzen. Mesedez, sartu berriro edo <a href=\"https://account.mindonmap.com/register/\">Sortu</a> lehenik.",
    "error_20306": "Pasahitzik ez dago oraindik. Erabili <a href=\"https://account.mindonmap.com/passwordless-login/\">Pasahitzik gabeko saioa</a> edo <a href=\"https://account.mindonmap.com/create-password/\"> ezarri pasahitza</a> eta hasi saioa.",
    "error_20308": "Eskaerak huts egin du (error-kodea: 20308). Mesedez, saiatu berriro",
    "error_20401": "Ezin izan da saioa amaitu (error-kodea: 20401). Mesedez, saiatu berriro",
    "error_20501": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20501)",
    "error_20502": "Posta elektronikoa ez da baliozkoa (error-kodea: 20502)",
    "error_20503": "Eskaerak huts egin du (error-kodea: 20503). Mesedez, saiatu berriro",
    "error_20504": "Ezin izan da mezu elektronikoa bidali. Mesedez, saiatu berriro.",
    "error_20601": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20601)",
    "error_20602": "Mesedez, idatzi egiaztapen-kodea (error-kodea: 20602)",
    "error_20603": "Posta elektronikoa ez da baliozkoa (error-kodea: 20603)",
    "error_20604": "Eskaerak huts egin du (error-kodea: 20604). Mesedez, saiatu berriro",
    "error_20606": "Eskaerak huts egin du (error-kodea: 20606). Mesedez, saiatu berriro",
    "error_20607": "Eskaerak huts egin du (error-kodea: 20607). Mesedez, saiatu berriro",
    "error_20608": "Eskaerak huts egin du (error-kodea: 20608). Mesedez, saiatu berriro",
    "error_20701": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20701)",
    "error_20702": "Posta elektronikoa ez da baliozkoa (error-kodea: 20702)",
    "error_20703": "Eskaerak huts egin du (error-kodea: 20703). Mesedez, saiatu berriro",
    "error_20704": "Kontua ez da existitzen. Mesedez, sartu berriro edo <a href=\"https://account.mindonmap.com/register/\">Sortu</a> lehenik.",
    "error_20705": "Eskaerak huts egin du (error-kodea: 20705). Mesedez, saiatu berriro",
    "error_20706": "Ezin izan da mezu elektronikoa bidali. Mesedez, saiatu berriro",
    "error_20801": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 20801)",
    "error_20802": "Errorea: 20802. Jarri gurekin harremanetan",
    "error_20803": "Mesedez, idatzi egiaztapen-kodea (error-kodea: 20803)",
    "error_20804": "Posta elektronikoa ez da baliozkoa (error-kodea: 20804)",
    "error_20805": "8 karaktere baino gehiagoko pasahitza behar da (error-kodea: 20805)",
    "error_20806": "Eskaerak huts egin du (error-kodea: 20806). Mesedez, saiatu berriro",
    "error_20808": "Eskaerak huts egin du (error-kodea: 20808). Mesedez, saiatu berriro",
    "error_20901": "Eskaerak huts egin du (error-kodea: 20901). Mesedez, saiatu berriro",
    "error_20902": "Eskaerak huts egin du (error-kodea: 20902). Mesedez, saiatu berriro",
    "error_21000": "Aldaketak gordetzen dira",
    "error_21001": "Ez da informaziorik bidali (error-kodea: 21001)",
    "error_21002": "Eskaerak huts egin du (error-kodea: 21002). Mesedez, saiatu berriro",
    "error_21101": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 21101)",
    "error_21102": "Posta elektronikoa ez da baliozkoa (error-kodea: 21102)",
    "error_21103": "Eskaerak huts egin du (errore-kodea: 21103), saiatu berriro berriro",
    "error_21104": "Posta elektronikoa dagoeneko konektatuta dago, erabili berri bat",
    "error_21105": "Eskaerak huts egin du (error-kodea: 21105), saiatu berriro berriro",
    "error_21106": "Ezin izan da mezu elektronikoa bidali. Mesedez, saiatu berriro",
    "error_21201": "Mesedez, idatzi zure helbide elektronikoa (error-kodea: 21201)",
    "error_21202": "Mesedez, idatzi egiaztapen-kodea (error-kodea: 21202)",
    "error_21203": "Posta elektronikoa ez da baliozkoa (error-kodea: 21203)",
    "error_21204": "Errorea: 21204. Jarri gurekin harremanetan",
    "error_21205": "Errorea: 21205. Jarri gurekin harremanetan",
    "error_21206": "8 karaktere baino gehiagoko pasahitza behar da (error-kodea: 21206)",
    "error_21207": "Eskaerak huts egin du (error-kodea: 21207). Mesedez, saiatu berriro",
    "error_21209": "Eskaerak huts egin du (error-kodea: 21209). Mesedez, saiatu berriro",
    "error_21301": "Mesedez, idatzi pasahitz zaharra (error-kodea: 21301)",
    "error_21302": "Mesedez, sortu pasahitz berri bat (error-kodea: 21302)",
    "error_21303": "Pasahitz berria ezin da izan zaharraren berdina. (Errorea: 21303)",
    "error_21304": "8 karaktere baino gehiagoko pasahitza behar da (error-kodea: 21304)",
    "error_21306": "Eskaerak huts egin du (error-kodea: 21306). Mesedez, saiatu berriro",
    "error_21402": "Eskaerak huts egin du (error-kodea: 21402). Mesedez, saiatu berriro",
    "error_21403": "Ezin izan da bidali egiaztapen-kodea. Mesedez, berriro bidali",
    "error_21500": "Kontua ezabatu da",
    "error_21501": "Mesedez, idatzi egiaztapen-kodea (error-kodea: 21501)",
    "error_21502": "Saioa hasteko saioa iraungi da (Errorea: 21502). Hasi saioa berriro.",
    "error_21503": "Eskaerak huts egin du (error-kodea: 21503). Mesedez, saiatu berriro",
    "error_21505": "Eskaerak huts egin du (error-kodea: 21505), saiatu berriro",
    "error_21601": "Errorea: 20601. Jarri gurekin harremanetan",
    "error_21602": "Egiaztapen baliogabea (Errorea: 20602). Mesedez, saiatu berriro.",
    "error_21603": "Errorea: 20603. Saiatu berriro",
    "error_21604": "Eskaerak huts egin du (error-kodea: 21604). Mesedez, saiatu berriro",
    "error_21606": "Eskaerak huts egin du (error-kodea: 21606). Mesedez, saiatu berriro",
    "error_21611": "Eskaerak huts egin du (error-kodea: 21611). Mesedez, saiatu berriro",
    "error_21801": "Errorea: 21801. Jarri gurekin harremanetan",
    "error_21802": "Eskaerak huts egin du (Errorea: 21802). Mesedez, saiatu berriro",
    "error_21803": "Errorea: 21803. Saiatu berriro",
    "error_21804": "Eskaerak huts egin du (error-kodea: 21804). Mesedez, saiatu berriro",
    "error_21806": "Errorea: 21806. Saiatu berriro",
    "error_21807": "Errorea: 21807. Jarri gurekin harremanetan",
    "error_21808": "Errorea: 21808. Jarri gurekin harremanetan",
    "error_21809": "Errorea: 21809. Jarri gurekin harremanetan",
    "error_21810": "Errorea: 21810. Jarri gurekin harremanetan",
    "error_21811": "Errorea: 21811. Jarri gurekin harremanetan",
    "error_21812": "Errorea: 21812. Jarri gurekin harremanetan",
    "error_21813": "Eskaerak huts egin du (error-kodea: 21813). Mesedez, saiatu berriro",
    "error_21814": "Errorea: 21814. Jar zaitez gurekin harremanetan",
    "error_21815": "Eskaerak huts egin du (error-kodea: 21815). Mesedez, saiatu berriro",
    "error_21816": "Errorea: 21816. Jar zaitez gurekin harremanetan",
    "error_21817": "Errorea: 21817. Jarri gurekin harremanetan",
    "error_21818": "Errorea: 21818. Jar zaitez gurekin harremanetan",
    "error_21819": "Eskaerak huts egin du (error-kodea: 21819). Mesedez, saiatu berriro",
    "error_21820": "Errorea: 21820. Jarri gurekin harremanetan",
    "error_21821": "Errorea: 21821. Jar zaitez gurekin harremanetan",
    "error_21822": "Errorea: 21822. Jarri gurekin harremanetan",
    "error_21823": "Eskaerak huts egin du (error-kodea: 21823). Mesedez, saiatu berriro",
    "error_21824": "Eskaerak huts egin du (error-kodea: 21824). Mesedez, saiatu berriro",
    "error_21825": "Eskaerak huts egin du (error-kodea: 21825). Mesedez, saiatu berriro",
    "error_21826": "Eskaerak huts egin du (error-kodea: 21826). Mesedez, saiatu berriro",
    "error_21901": "Errorea: 21901. Jarri gurekin harremanetan",
    "error_21902": "Eskaerak huts egin du (error-kodea: 21902). Mesedez, saiatu berriro",
    "error_21903": "Kontuaren egoera aldatu da (errore-kodea: 21903), freskatu orria eta saiatu berriro",
    "error_21904": "Errorea: 21904. Saiatu berriro",
    "error_21905": "Errorea: 21905. Saiatu berriro",
    "error_21906": "Eskaerak huts egin du (error-kodea: 21906). Mesedez, saiatu berriro",
    "error_21907": "Google kontua beste kontu batekin lotu da",
    "error_21908": "Eskaerak huts egin du (error-kodea: 21908). Mesedez, saiatu berriro",
    "error_22001": "Eskaerak huts egin du (error-kodea: 22001). Mesedez, saiatu berriro",
    "error_22002": "Ezin izan da kendu loturak saio-hasiera gehigarririk gabe",
    "error_22003": "Eskaerak huts egin du (error-kodea: 22003). Mesedez, saiatu berriro",
    "error_22101": "Errorea: 22101. Jarri gurekin harremanetan",
    "error_22102": "Kontuaren egoera aldatu da (errore-kodea: 22102), freskatu orria eta saiatu berriro",
    "error_22103": "Eskaerak huts egin du (error-kodea: 22103). Mesedez, saiatu berriro",
    "error_22104": "Kontuaren egoera aldatu da (errore-kodea: 22104), freskatu orria eta saiatu berriro",
    "error_22105": "Errorea: 22105. Saiatu berriro",
    "error_22106": "Errorea: 22106. Saiatu berriro",
    "error_22107": "Errorea: 22107. Jarri gurekin harremanetan",
    "error_22108": "Eskaerak huts egin du (error-kodea: 22108). Mesedez, saiatu berriro",
    "error_22201": "Errorea: 22201. Jarri gurekin harremanetan",
    "error_22202": "Egiaztapen baliogabea (Errorea: 22202). Mesedez, saiatu berriro.",
    "error_22203": "Errorea: 22203. Saiatu berriro\"",
    "error_22204": "Eskaerak huts egin du (error-kodea: 22204). Mesedez, saiatu berriro",
    "error_22206": "Eskaerak huts egin du (error-kodea: 22206). Mesedez, saiatu berriro",
    "error_22401": "Errorea: 22401. Jarri gurekin harremanetan",
    "error_22402": "Egiaztapen baliogabea (Errorea: 22402). Mesedez, saiatu berriro.",
    "error_22403": "Errorea: 22403. Saiatu berriro",
    "error_22404": "Eskaerak huts egin du (error-kodea: 22404). Mesedez, saiatu berriro",
    "error_22405": "Facebook kontua beste posta elektroniko batekin lotu da",
    "error_22406": "Errorea: 22406. Saiatu berriro",
    "error_22407": "Errorea: 22407. Jarri gurekin harremanetan",
    "error_22408": "Errorea: 22408. Jarri gurekin harremanetan",
    "error_22409": "Errorea: 22409. Jarri gurekin harremanetan",
    "error_22410": "Errorea: 224010. Jarri gurekin harremanetan",
    "error_22411": "Errorea: 224011. Jarri gurekin harremanetan",
    "error_22412": "Errorea: 224012. Jarri gurekin harremanetan",
    "error_22413": "Errorea: 22413. Jarri gurekin harremanetan",
    "error_22414": "Eskaerak huts egin du (error-kodea: 22414). Mesedez, saiatu berriro",
    "error_22415": "Errorea: 22415. Jar zaitez gurekin harremanetan",
    "error_22416": "Errorea: 22416. Jarri gurekin harremanetan",
    "error_22417": "Errorea: 22417. Jarri gurekin harremanetan",
    "error_22418": "Eskaerak huts egin du (error-kodea: 22418). Mesedez, saiatu berriro",
    "error_22419": "Errorea: 22419. Jarri gurekin harremanetan",
    "error_22420": "Errorea: 22420. Jarri gurekin harremanetan",
    "error_22421": "Errorea: 22421. Jarri gurekin harremanetan",
    "error_22422": "Eskaerak huts egin du (error-kodea: 22422). Mesedez, saiatu berriro",
    "error_22423": "Eskaerak huts egin du (error-kodea: 22423). Mesedez, saiatu berriro",
    "error_22424": "Eskaerak huts egin du (error-kodea: 22424). Mesedez, saiatu berriro",
    "error_22425": "Eskaerak huts egin du (error-kodea: 22425). Mesedez, saiatu berriro",
    "error_20098": "Errore-kodea: 20098. Modu pribatuan arakatzen ari bazara, aldatu modu Normalera eta saiatu berriro.",
    "error_22298": "Google-ren eskaerak huts egin du (Errorea: 22298). Mesedez, saiatu berriro.",
    "error_22498": "Facebook-en eskaerak huts egin du (Errorea: 22498). Mesedez, saiatu berriro.",
    "error_24902": "Eskaerak huts egin du (error-kodea: 24902). Mesedez, saiatu berriro",
    "error_24903": "Eskaerak huts egin du (error-kodea: 24903). Mesedez, saiatu berriro",
    "error_24904": "Eskaerak huts egin du (error-kodea: 24904). Mesedez, saiatu berriro",
    "error_24905": "Eskaerak huts egin du (error-kodea: 24905). Mesedez, saiatu berriro",
    "login_title": "Hasi saioa MindOnMap-en",
    "log_in": "Saioa hasi",
    "no_account": "Ez dago konturik?",
    "create_it": "Sortu",
    "or_log_in_with": "Edo hasi saioa honekin",
    "passwordless_login": "Pasahitzik gabeko saioa",
    "log_in_done": "Hasi saioa Eginda",
    "three_rd_account_connect_info": "Zorionak! Arrakastaz hasi zara saioa. Orain, etorkizunean saioa hasteko erabiltzen den posta elektronikoko kontu bat konekta dezakezu.",
    "see_my_account": "Ikusi nire kontua",
    "three_rd_login_merge_account": "Hirugarrenen kontuko helbide elektronikoa erregistratu da, helbide elektroniko honekin zuzenean konektatu eta saioa hasi nahi duzu?",
    "connect_log_in": "Konektatu eta Hasi saioa",
    "create_an_account": "Kontu bat sortu",
    "back_to_log_in": "Itzuli saioa hasteko",
    "create_password": "Pasahitza sortu",
    "create_now": "Sortu orain",
    "password_login_subtitle": "Pasahitzik gabeko saioa posta elektronikoarekin",
    "account_login": "Kontuaren saioa",
    "rights": "Kontu hau sortuz gero, <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Zerbitzu-baldintzak</a> eta <a href=\"https://www.mindonmap.com/privacy-policy/\">Pribatutasun politika</a>",
    "passwordless_login_done": "Pasahitzik gabeko saioa egin da",
    "passwordless_login_info": "Zorionak, pasahitz gabeko saioa behar bezala osatu duzu. Kontu honen pasahitza sor dezakezu eta etorkizunean kontuarekin eta pasahitzarekin saioa hasi dezakezu. <a href=\"/create-password\" style=\"display: initial;\">Sortu orain</a>",
    "sign_up": "Izena eman",
    "register_info": "Sortu zure kontua",
    "reset_now": "Berrezarri orain",
    "forgot_password": "Pasahitza ahaztua",
    "reset_password_subtitle": "Erabili zure kontuko helbide elektronikoa pasahitza berrezartzeko",
    "plan_products": "Planak eta Produktuak",
    "nick_name": "Erabiltzaile izena:",
    "email": "Posta elektronikoa:",
    "my_products": "Nire produktuak",
    "my_orders": "Nire Aginduak",
    "unlink": "Kendu",
    "link": "Esteka",
    "connected_accounts": "Konektatutako kontuak",
    "last_name": "Abizena:",
    "first_name": "Izena:",
    "Gender": "Generoa:",
    "Birth": "Jaiotza:",
    "Month": "Hilabetea",
    "Year": "Urtea",
    "Country_Region": "Herrialdea/Eskualdea:",
    "Address": "Helbidea:",
    "Save": "Gorde",
    "Date": "Data",
    "Male": "Gizonezkoa",
    "Female": "Emakumezkoa",
    "Unspecified": "Zehaztu gabe",
    "Security": "Segurtasuna",
    "change_password": "Aldatu pasahitza",
    "change_now": "Aldatu orain",
    "connect_email": "Konektatu posta elektronikoa",
    "delete_account": "Ezabatu kontua",
    "delete_account_info": "Zure kontua ezabatzen denean, zure kontuarekin erlazionatutako Nire kontuko datu guztiak betiko ezabatuko dira, eta baliteke ezin izango dituzu berreskuratu. Kontuz ibiltzea gomendatzen dugu.",
    "Delete": "Ezabatu",
    "Logout": "Saioa amaitu",
    "my_profile": "Nire profila",
    "guides_faqs": "Gidak eta Ohiko galderak",
    "More": "Gehiago",
    "guides": "Gidak",
    "register": "erregistratu",
    "hot_faq": "Ohiko FAQ",
    "Contents": "Edukiak:",
    "contact_us": "Jarri gurekin harremanetan >>",
    "plan": "Plana",
    "unregistered": "Erregistratu gabe",
    "buy_more": "Gehiago erosi",
    "buy_again": "Erosi Berriro",
    "buy_now": "Erosi orain",
    "free_no_limit": "Doan eta mugarik gabe",
    "expired": "Iraungi egin da",
    "lifetime": "Bizitza",
    "remain": "Geratu",
    "day_s": "Eguna(k)",
    "error_24801": "Eskaerak huts egin du (error-kodea: 24801). Mesedez, saiatu berriro",
    "no_app_found": "Ez da aplikaziorik aurkitu!<a href=\"/\">Freskatu</a> edo <a href=\"https://www.mindonmap.com/\">Joan webgune ofizialera</a>",
    "get_more": "Lortu gehiago >>",
    "edit_photo": "Editatu argazkia",
    "select_photo": "Hautatu argazkia",
    "change_photo": "Aldatu argazkia",
    "cancel": "Utzi",
    "hide_password": "Ezkutatu pasahitza",
    "show_password": "Erakutsi pasahitza",
    "zoom_in": "Handitu",
    "zoom_out": "Txikiagotu",
    "rotate": "Biratu",
    "horizontal_flip": "Flip horizontala",
    "vertical_flip": "Flip bertikala",
    "country": "Herrialdea",
    "country_1": "Hautatu zure herrialdea/eskualdea",
    "country_2": "Åaland uhartea",
    "country_3": "Afganistan",
    "country_4": "Albania",
    "country_5": "Aljeria",
    "country_6": "Amerikako Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antartika",
    "country_11": "Antigua eta Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaijango",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorrusia",
    "country_22": "Belgika",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermudak",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia eta Herzegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet uhartea",
    "country_31": "Brasil",
    "country_32": "Britainiar India Ozeano Lurraldea",
    "country_33": "Britainiar birjinak",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kanbodia",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Cabo Verde",
    "country_42": "Karibeko Herbehereak",
    "country_43": "Kaiman uharteak",
    "country_44": "Afrika Erdiko Errepublika",
    "country_45": "Txad",
    "country_46": "Txile",
    "country_47": "Txina",
    "country_48": "Gabonetako uhartea",
    "country_49": "Cocos (Keeling) uharteak",
    "country_50": "Kolonbia",
    "country_51": "Cook uharteak",
    "country_52": "Costa Rika",
    "country_53": "Boli Kosta",
    "country_54": "Kroazia",
    "country_55": "Kuba",
    "country_56": "Zipre",
    "country_57": "Txekiar Errepublika",
    "country_58": "Kongoko Errepublika Demokratikoa",
    "country_59": "Danimarka",
    "country_60": "Djibutin",
    "country_61": "Dominika",
    "country_62": "Dominikar Errepublika",
    "country_63": "Ekuador",
    "country_64": "Egipto",
    "country_65": "El Salvador",
    "country_66": " Guinea Ekuatorea",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Falkland uharteak",
    "country_71": "Faroe uharteak",
    "country_72": "Mikronesiako Estatu Federatuak",
    "country_73": "Fiji",
    "country_74": "Finlandia",
    "country_75": "Frantzia",
    "country_76": "Guyana Frantziarra",
    "country_77": "Frantses polinesia",
    "country_78": "Frantziako hegoaldeko lurraldeak",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Alemania",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": " Britainia Handia (Erresuma Batua; Ingalaterra)",
    "country_86": "Grezia",
    "country_87": "Groenlandia",
    "country_88": "Grenada",
    "country_89": "Guadalupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Ginea",
    "country_94": "Ginea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard uharteak eta McDonald uharteak",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungaria",
    "country_101": "Islandia",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irlanda",
    "country_107": "Man uhartea",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Jamaika",
    "country_111": "Japonia",
    "country_112": "Jersey",
    "country_113": "Jordania",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgizistan",
    "country_119": "Laos",
    "country_120": "Letonia",
    "country_121": "Libano",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Lituania",
    "country_127": "Luxenburgo",
    "country_128": "Makao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldibak",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall uharteak",
    "country_136": "Martinika",
    "country_137": "Mauritania",
    "country_138": "Mauricio",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldavia",
    "country_142": "Monako",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambike",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Herbehereak",
    "country_153": " Kaledonia Berria",
    "country_154": "Zelanda Berria",
    "country_155": "Nikaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolk uhartea",
    "country_160": "Iparraldeko Korea",
    "country_161": "Ipar Mariana uharteak",
    "country_162": "Norvegia",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestinako lurraldeak",
    "country_167": "Panama",
    "country_168": "Papua Guinea Berria",
    "country_169": "Paraguai",
    "country_170": "Peru",
    "country_171": "Pitcairn uharteak",
    "country_172": "Polonia",
    "country_173": "Portugal",
    "country_174": "Puerto Riko",
    "country_175": "Qatar",
    "country_176": "Mazedonia errepublika (ARYM)",
    "country_177": " Kongo errepublika",
    "country_178": "Bilera",
    "country_179": "Errumania",
    "country_180": "Errusiako Federazioa",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Frantzia)",
    "country_184": "Saint-Pierre eta Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome eta Principe",
    "country_188": "Saudi Arabia",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leona",
    "country_193": "Singapur",
    "country_194": "Eslovakia",
    "country_195": "Eslovenia",
    "country_196": "Salomon Uharteak",
    "country_197": "Somalia",
    "country_198": "Hegoafrika",
    "country_199": "Hego Georgia eta Hego Sandwich uhartea",
    "country_200": "Hego Korea",
    "country_201": "Hego Sudan",
    "country_202": "Espainia",
    "country_203": "Sri Lanka",
    "country_204": "Santa Helena eta mendekotasunak",
    "country_205": "San Kitts eta Nevis",
    "country_206": "Santa Luzia",
    "country_207": "San Vicente eta Grenadinak",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swazilandia",
    "country_211": "Suedia",
    "country_212": "Suitza",
    "country_213": "Siria",
    "country_214": "Taiwan",
    "country_215": "Tadjikistan",
    "country_216": "Tanzania",
    "country_217": "Txantiloia:Herrialde datuak SJM Svalbard",
    "country_218": "Thailandia",
    "country_219": " Bahamak",
    "country_220": " Komorrak",
    "country_221": " Filipinak",
    "country_222": "Ekialdeko Timor (Ekialdeko Timor)",
    "country_223": "Joan",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad eta Tobago",
    "country_227": "Tunisia",
    "country_228": "Turkia",
    "country_229": "Turkmenistan",
    "country_230": "Turks eta Caicos uharteak",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Arabiar Emirerri Batuak",
    "country_235": "Estatu Batuetako urruneko uharte txikiak",
    "country_236": "Amerikako Estatu Batuak (AEB)",
    "country_237": "Estatu Batuetako Uharte Birjinak",
    "country_238": "Uruguai",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikano Hiria ( Egoitza Santua)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis eta Futuna",
    "country_245": "Mendebaldeko Sahara",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Hasi saioa Google-rekin",
    "State": "Estatu",
    "Activation_code": "Aktibazio kodea",
    "Question": "Zerrendatu saioa hasi duzun aplikazio guztiak",
    "Copy_complete": "Kopia osatuta",
    "footer": "Copyright © 2024 MindOnMap Studio. Eskubide guztiak erreserbatuak.",
    "change_password_success": "Pasahitza behar bezala aldatu da",
    "successful_login_title": "Saioa arrakastatsua",
    "product_page": "Produktuen Orria>>",
    "successful_login_info": "Saioa amaitu da. Mesedez, itxi uneko orria eta itzuli jatorrizko fitxara hurrengo prozesuarekin jarraitzeko. Uneko orria automatikoki itxiko da 5 segundotan. \"Egindako\" botoian sakatuta ixteak automatikoki edo ixteak huts egiten badu, itxi fitxa hau zuzenean.",
    "successful_login_info_firefox": "Saioa amaitu da. Mesedez, itxi uneko orria eta itzuli jatorrizko fitxara hurrengo prozesuarekin jarraitzeko.",
    "my_account": "Nire kontua",
    "my_history": "Nire Historia",
    "remove_watermark": "Kendu Ur-marka",
    "no_history": "Historiarik ez",
    "history_all": "Hautatu guztiak",
    "history_open": "Ireki",
    "history_down": "Deskargatu",
    "history_delete": "Ezabatu",
    "history_clear": "Garbitu baliogabea",
    "images": "irudia(k)",
    "use_this_function": "Erabili produktu hau >>",
    "hd_downloading": "HD jatorrizko irudiak deskargatzeko abantailak:",
    "lifetimeRemaining": "Bizitza-Gainerako",
    "Remaining": "Gainerakoak",
    "email_verification": "Posta elektronikoaren egiaztapena",
    "email_verification_info": "Egiaztapen-mezua zure helbide elektronikora bidali dugu <span class=\"email\"></span>, eta amaitu egiaztapena. Egiaztatu ondoren, abantailak automatikoki sinkronizatuko dira.",
    "wrong_email": "Helbide elektroniko okerra?",
    "click_here_to_modify": "Egin klik hemen aldatzeko",
    "get_more_help": "Laguntza gehiago lortu nahi duzu?",
    "click_here": "Klikatu hemen",
    "email_verification_info_success": "Zorionak zure posta-kontua egiaztatzeagatik.",
    "email_verification_info_error": "Egiaztapenak huts egin du iraungitako esteka dela eta.",
    "registration_succeeded": "Izen-ematea arrakastatsua da",
    "registration_succeeded_info_1": "Zorionak! Behar bezala erregistratu zara. Egiaztapen-mezua bidali dugu zure posta elektronikora <span class=\"email\"></span>, eta amaitu egiaztatzea mezu elektroniko honen abantailak erabiltzeko.",
    "registration_succeeded_info_2": "Sakatu \"Eginda\" hasierako orrira itzultzeko eta produktu hau erabiltzeko.",
    "registration_succeeded_info_3": "Sakatu \"Eginda\" uneko orria ixteko eta hasierako orrira itzultzeko eragiketa hauetarako. Itxik huts egiten badu, itxi fitxa eskuz.",
    "verify_email": "Egiaztatu posta elektronikoa",
    "registered_email_not_verified": "Erregistratutako mezu elektronikoa ez da egiaztatu, mesedez egiaztatu berehala.",
    "email_verification_time_1": "Ez al duzu egiaztapen-mezurik jaso?",
    "email_verification_time_2": "Ondoren",
    "email_verification_time_3": "Egin klik hemen berriro bidaltzeko",
    "error_26301": "Errore kodea: 26301, jar zaitez gurekin harremanetan",
    "error_26302": "Errore kodea: 26302, jar zaitez gurekin harremanetan",
    "error_26303": "Posta elektronikoaren formatuaren errorea (error-kodea: 26303). Mesedez, sartu berriro",
    "error_26304": "8 karaktere baino gehiagoko pasahitza gomendatzen da (error-kodea: 26304)",
    "error_26305": "Huts egin du eskaerak (error-kodea: 26305). Mesedez, saiatu berriro",
    "error_26306": "Posta elektronikoa erregistratu da, mesedez <a href=\"https://account.mindonmap.com/login/\">joan saioa hasteko</a>",
    "error_26307": "Huts egin du eskaerak (error-kodea: 26307). Mesedez, saiatu berriro",
    "error_26308": "Huts egin du eskaerak (error-kodea: 26308). Mesedez, saiatu berriro",
    "error_26401": "Errore kodea: 26401, saiatu berriro",
    "error_26402": "Posta elektronikoa egiaztatu da (errore-kodea: 26402), saiatu berriro",
    "error_26403": "Huts egin du eskaerak (error-kodea: 26403). Mesedez, saiatu berriro",
    "error_26404": "Eskaerak huts egin du (error-kodea: 26404). Mesedez, saiatu berriro",
    "error_26501": "Errore kodea: 26501, jar zaitez gurekin harremanetan",
    "error_26502": "Errore kodea: 26502, jar zaitez gurekin harremanetan",
    "error_26503": "Posta elektronikoaren formatuaren errorea (error-kodea: 26503). Mesedez, sartu berriro",
    "error_26504": "Huts egin du eskaerak (error-kodea: 26504). Mesedez, saiatu berriro",
    "error_26505": "Posta elektronikoa ez da erregistratu, mesedez <a href=\"https://account.mindonmap.com/register/\">erregistratu lehenik</a>",
    "error_26506": "Posta elektronikoa egiaztatu da.",
    "error_26507": "Huts egin du eskaerak (error-kodea: 26507). Mesedez, saiatu berriro",
    "error_26508": "Egiaztapena egin da (errore-kodea: 26508), saiatu berriro",
    "error_26509": "Eskaerak huts egin du (error-kodea: 26509), saiatu berriro",
    "error_26510": "Errore kodea: 26510, jar zaitez gurekin harremanetan",
    "error_26601": "Errore kodea: 26601, jar zaitez gurekin harremanetan",
    "error_26602": "Errore kodea: 26602, jar zaitez gurekin harremanetan",
    "error_26603": "Huts egin du eskaerak (error-kodea: 26603). Mesedez, saiatu berriro",
    "error_26604": "Errore kodea: 26604, jar zaitez gurekin harremanetan",
    "error_26605": "Errore kodea: 26605, jar zaitez gurekin harremanetan",
    "error_26701": "Errore kodea: 26701, jar zaitez gurekin harremanetan",
    "error_26702": "Huts egin du eskaerak (error-kodea: 26702). Mesedez, saiatu berriro",
    "error_26703": "Errore kodea: 26703, jar zaitez gurekin harremanetan",
    "error_26704": "Errore kodea: 26704, jar zaitez gurekin harremanetan",
    "error_26705": "Itxaron saioa hasteko (error-kodea: 26705). Mesedez, saiatu berriro",
    "no_cookie": "Cookie guztiak blokeatu funtzioa aktibatu duzu zure arakatzailean, beraz, ezin duzu saioa hasi. Mesedez, joan Ezarpenak atalera Onartu cookie guztiak laukia markatzeko.",
    "error_26801": "Errore kodea: 26801, jar zaitez gurekin harremanetan",
    "error_26802": "Errore kodea: 26802, jar zaitez gurekin harremanetan",
    "error_26803": "Eskaerak huts egin du (error-kodea: 26803). Mesedez, saiatu berriro",
    "error_26804": "Eskaerak huts egin du (error-kodea: 26804). Mesedez, saiatu berriro",
    "error_order": "Eskaerak huts egin du (error-kodea: 27098), saiatu berriro!",
    "error_order1": "Eskaera-kontsulta osatu gabe dago (error-kodea: ",
    "error_order2": "）, freskatu eta saiatu berriro.",
    "modify_email_title": "Aldatu posta elektronikoa",
    "modify_email_info": "Aldatutako posta elektronikoa erabil dezakezu zure kontuan saioa hasteko.",
    "images_per": "Irudiak bakoitzeko",
    "error_26101": "Errorea: 26101. Jarri gurekin harremanetan",
    "error_26102": "Errorea: 26102. Jar zaitez gurekin harremanetan",
    "error_26103": "Eskaerak huts egin du (errore-kodea: 26103). Mesedez, saiatu berriro",
    "error_26104": "Errore kodea: 26104, saiatu berriro",
    "error_26105": "Errore kodea: 26105, saiatu berriro",
    "error_26106": "Ezabatu huts egin (errore kodea: 26106). Mesedez, saiatu berriro",
    "error_26201": "Errorea: 26201. Jarri gurekin harremanetan",
    "error_26202": "Eskaerak huts egin du (errore-kodea: 26202). Mesedez, saiatu berriro",
    "error_26001": "Errorea: 26001. Jarri gurekin harremanetan",
    "error_26002": "Errorea: 26002. Jarri gurekin harremanetan",
    "error_26003": "Errorea: 26003. Jarri gurekin harremanetan",
    "error_26004": "Errorea: 26004. Jarri gurekin harremanetan",
    "error_26005": "Eskaerak huts egin du (errore-kodea: 26005). Mesedez, saiatu berriro",
    "error_26006": "Errore kodea: 26006, saiatu berriro",
    "error_26008": "Errorea: 26008. Jarri gurekin harremanetan",
    "go_to_the_home_page": "Joan hasierako orrialdera",
    "error_27101": "Eskaerak huts egin du (error-kodea: 27101). Mesedez, saiatu berriro",
    "error_27201": "Errore kodea: 27201, jar zaitez gurekin harremanetan",
    "error_27202": "Errore kodea: 27202, saiatu berriro",
    "error_27203": "Eskaerak huts egin du (error-kodea: 27203). Mesedez, saiatu berriro",
    "error_27204": "Kode baliogabea (error-kodea: 27204).",
    "error_27205": "Eskaerak huts egin du (error-kodea: 27205). Mesedez, saiatu berriro",
    "error_27206": "Eskaerak huts egin du (error-kodea: 27206). Mesedez, saiatu berriro",
    "error_27207": "Eskaerak huts egin du (error-kodea: 27207). Mesedez, saiatu berriro",
    "no_history_found": "Ez duzu tresnarik erabili! <a href=\"/\">Freskatu</a> edo <a href=\"https://www.mindonmap.com/\">Joan webgune ofizialera</a>",
    "error_25301": "Errorea: 25301. Jarri gurekin harremanetan",
    "error_25302": "Errorea: 25302. Jarri gurekin harremanetan",
    "error_25303": "Eskaerak huts egin du (errore kodea: 25303). Mesedez, saiatu berriro",
    "error_25304": "Eskaerak huts egin du (errore kodea: 25304). Mesedez, saiatu berriro",
    "error_25305": "Eskaerak huts egin du (errore kodea: 25305). Mesedez, saiatu berriro",
    "error_25306": "Eskaerak huts egin du (errore kodea: 25306). Mesedez, saiatu berriro",
    "image_upscaler_p": "Ur markarik gabeko irudia deskargatzeko abantailak:",
    "Available_for": "Honetarako eskuragarri:",
    "credit_per": "%s kreditu HD irudi bakoitzeko",
    "still_valid": "Erositako planak oraindik ere balio du",
    "credit": "kreditua(k)",
    "pc_3rd_info": "Hasi saioa arrakastaz. Mesedez, joan aplikaziora funtzionamendu gehiago lortzeko.",
    "use_online": "Erabili lineako zerbitzua",
    "use_download": "Erabili mahaigaineko programa",
    "use_immediately": "Erabili Berehala",
    "Use_in_browser": "Erabili arakatzailean",
    "win_desktop": "Leihoak",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kreditu hilero",
    "expire": "Iraungitze denbora:",
    "viewDetails": "Ikusi xehetasunak",
    "viewHistory": "Ikusi historiako abantailak>>",
    "viewDetailsInfo": "Oharra: harpidetza-onurak iraungi eta 7 eguneko epean berritzen badira, erabili gabeko abantailak erabiltzen jarraitu ahal izango dute. Era berean, iraungitze-denbora automatikoki eguneratuko da harpidetza berriaren iraungitze-orduarekin. Iraungitze-epearen 7 egun igaro ondoren harpidetza berririk ez badago, iraungitako abantaila guztiak garbituko dira.",
    "connect_account": "Lotu posta elektronikoa zure kontuarekin",
    "connect_account_info": "Lotu ondoren, helbide elektroniko honekin saioa hasi dezakezu.",
    "connect_now": "Lotu orain",
    "no_email_bind": "Ez dago posta elektronikoko loturarik",
    "bind_email": "Lotu posta elektronikoa",
    "connect_your_email_placeholder": "Mesedez, sartu zure helbide elektronikoa",
    "bind_an_email": "Lotu mezu elektroniko bat",
    "bind_info": "Arrakastaz hasi zara saioa. Mesedez, lotu mezu elektroniko bat zure kontura zure abantailak aktibatzeko.",
    "bind_later": "Lotu geroago",
    "hi": "Haren, %s!",
    "Personal_Information": "Informazio pertsonala",
    "Access": "Sarbidea",
    "Subscription_Plan": "(Harpidetza plana)",
    "No_orders": "Ez da agindurik aurkitu.",
    "No_data": "Ez dago daturik",
    "Featured_Products": "Produktu aipagarriak",
    "More_Products": "Produktu gehiago",
    "Free_Download": "Doako Deskarga",
    "Get_Started": "Hasi",
    "Subscribe": "Harpidetu",
    "Verified": "Egiaztatuta",
    "back_to_account_center": "Itzuli Kontu Zentrora",
    "success": "Arrakasta!",
    "successfully": "Behar bezala erregistratu duzu kontu bat.",
    "Continue": "Jarraitu",
    "Already": "Kontu bat al duzu dagoeneko?",
    "loading_verification": "Egiaztapen-egoera egiaztatzea...",
    "email_no_verification": "Sentitzen dugu, erregistratutako helbide elektronikoa ez da egiaztatu. Mesedez, osatu egiaztapena goiko argibideen arabera eta egin klik berriro \"Egindako\" botoian erregistroa osatzeko.",
    "will_expire_after": "Ondoren iraungiko da",
    "hours": "orduak",
  },
  "fa": {
    "overtime": "کد خطا: {%}، لطفاً دوباره امتحان کنید",
    "isnetwork": "خطای اینترنتی لطفاً بررسی کنید و دوباره امتحان کنید",
    "email_placeholder": "پست الکترونیک",
    "email_empty": "لطفا ایمیل را وارد کنید",
    "email_not_valid": "ایمیل معتبر نیست",
    "email_not_valid_1": "لطفا ایمیل خود را وارد کنید",
    "email_not_valid_2": "ایمیل معتبر نیست، لطفا از آدرس دیگری استفاده کنید.",
    "email_not_valid_3": "بدون ورودی ایمیل",
    "password_placeholder": "کلمه عبور",
    "password_empty": "لطفا رمز عبور را وارد کنید",
    "password_not_valid": "حساب یا رمز عبور اشتباه است",
    "password_not_valid_1": "رمز عبور بیش از 8 کاراکتر مورد نیاز است",
    "password_not_valid_2": "لطفا یک رمز عبور ایجاد کنید",
    "password_placeholder_1": "رمز عبور خود را ایجاد کنید",
    "password_placeholder_2": "رمز عبور خود را تایید کنید",
    "password_placeholder_3": "رمز عبور جدید ایجاد کنید",
    "password_placeholder_4": "رمز عبور جدید را تایید کنید",
    "password_placeholder_5": "رمز عبور قدیمی را وارد کنید",
    "copy_password_empty": "لطفا رمز عبور را تایید کنید",
    "copy_password_not_valid": "لطفا رمز عبور خود را تایید کنید",
    "copy_passwords_inconsistent": "تایید رمز عبور شما مطابقت ندارد",
    "code_empty": "لطفا کد تأیید را وارد کنید",
    "code_not_valid": "کد تأیید نامعتبر است",
    "code_placeholder": "کد تایید",
    "not_received_code": "اگر صندوق پستی شما برای مدت طولانی کد تأیید را دریافت نکرده است، لطفاً دوباره کد تأیید را دریافت کنید.",
    "get_first_code": "لطفا ابتدا کد تأیید را دریافت کنید.",
    "last_name_placeholder": "لطفا نام خانوادگی خود را وارد کنید",
    "first_name_placeholder": "لطفا نام خود را وارد کنید",
    "address_placeholder": "لطفا آدرس خود را وارد کنید",
    "no_code_text": "ما یک کد تأیید ارسال کرده ایم. لطفا کد خود را وارد کنید. <span class='tips'>کدی دریافت نکردید؟",
    "no_code_text_1": "1. لطفا مطمئن شوید که آدرس ایمیل معتبر است و می تواند ایمیل دریافت کند.",
    "no_code_text_2": "2. برای اینکه ایمیل توسط سیستم به صورت خودکار ارسال می شود، ممکن است به عنوان ایمیل هرزنامه یا ناخواسته علامت گذاری شود. لطفاً بررسی کنید که آیا ایمیل در پوشه حذف شده است یا خیر.",
    "no_code_text_3": "3. نمی توانید مشکل خود را حل کنید؟ ",
    "no_code_text_3_span": "سپس برای تماس با ما اینجا را کلیک کنید.",
    "order_no": "شما هیچ محصولی خریداری نکرده‌اید، اگر سؤالی دارید، لطفاً <a href=\"https://www.mindonmap.com/contact-us/\">با ما تماس بگیرید</a>.",
    "error_24901": "حساب فعلی هیچ ایمیلی مرتبط نیست و نمی‌تواند سفارش‌ها را پیدا کند. لطفا یک ایمیل وصل کنید.",
    "user_guide": "راهنمای کاربر>>",
    "download": "دانلود",
    "order_number": "شماره سفارش:",
    "Refund": "بازپرداخت",
    "Disabled": "معلول",
    "Normal": "طبیعی",
    "Modify": "تغییر",
    "Modify_1": "اصلاح>>",
    "Connect": "اتصال",
    "unlink_success": "با موفقیت لغو پیوند شد",
    "connect_success": "با موفقیت وصل شوید",
    "feedback_title": "با تشکر از بازخورد شما. لطفا مشکل خود را ترک کنید و ما ظرف 24 ساعت به شما پاسخ خواهیم داد.",
    "feedback_thank_you": "با تشکر از شما!<br />بازخورد شما با موفقیت ارسال شد.",
    "feedback_email": "ایمیل خود را اینجا وارد کنید!",
    "feedback_content": "هر مشکل یا پیشنهادی که با آن برخورد کردید اینجا بگذارید.",
    "feedback_submit": "ارسال",
    "form_contents": "شما هیچ توضیحی را وارد نکرده اید. لطفا آن را وارد کنید و دوباره ارسال کنید.",
    "old_password": "لطفا رمز عبور قدیمی را وارد کنید",
    "new_password": "لطفا یک رمز عبور جدید ایجاد کنید",
    "old_new_password": "رمز عبور جدید نمی تواند مانند رمز قبلی باشد",
    "incorrect_password": "رمز عبور نادرست",
    "delete_no": "اکنون حذف کنید",
    "Caps": "Caps Lock روشن است",
    "Get": "گرفتن",
    "Done": "انجام شده",
    "error_20001": "خطا: 20001. لطفا دوباره وارد شوید.",
    "error_20002": "خطا: 20002. لطفا دوباره وارد شوید.",
    "error_20003": "خطا: 20003. لطفا دوباره وارد شوید.",
    "error_20004": "درخواست ناموفق بود (کد خطا: 20004). لطفا دوباره آن را امتحان کنید.",
    "error_20005": "جلسه ورود به سیستم منقضی شده است (خطا: 20005). لطفا دوباره وارد شوید.",
    "error_20006": "درخواست ناموفق بود (کد خطا: 20006). لطفا دوباره آن را امتحان کنید.",
    "error_20007": "جلسه ورود به سیستم منقضی شده است (خطا: 20007). لطفا دوباره وارد شوید.",
    "error_20008": "جلسه ورود به سیستم منقضی شده است (خطا: 20008). لطفا دوباره وارد شوید.",
    "error_20009": "جلسه ورود به سیستم منقضی شده است (خطا: 20009). لطفا دوباره وارد شوید.",
    "error_20101": "لطفا ایمیل خود را وارد کنید (کد خطا: 20101)",
    "error_20102": "ایمیل معتبر نیست (کد خطا: 20102)",
    "error_20103": "درخواست ناموفق بود (کد خطا: 20103). لطفا دوباره آن را امتحان کنید",
    "error_20104": "ایمیل از قبل در حال استفاده است، لطفاً <a href=\"https://account.mindonmap.com/login/\">وارد شوید</a> یا با ایمیل جدیدی ثبت نام کنید",
    "error_20105": "درخواست ناموفق بود (کد خطا: 20105). لطفا دوباره آن را امتحان کنید",
    "error_20106": "ایمیل ارسال نشد، لطفاً دوباره امتحان کنید",
    "error_20201": "لطفا ایمیل خود را وارد کنید (کد خطا: 20201)",
    "error_20202": "لطفا رمز عبور خود را وارد کنید (کد خطا: 20202)",
    "error_20203": "لطفاً کد تأیید را وارد کنید (کد خطا: 20203)",
    "error_20204": "ایمیل معتبر نیست (کد خطا: 20204)",
    "error_20205": "رمز عبور بیش از 8 کاراکتر مورد نیاز است (کد خطا: 20205)",
    "error_20206": "درخواست ناموفق بود (کد خطا: 20206). لطفا دوباره آن را امتحان کنید",
    "error_20207": "کد تأیید نامعتبر است",
    "error_20208": "درخواست ناموفق بود (کد خطا: 20208). لطفا دوباره آن را امتحان کنید",
    "error_20209": "درخواست ناموفق بود (کد خطا: 20209). لطفا دوباره آن را امتحان کنید",
    "error_20301": "لطفا ایمیل خود را وارد کنید (کد خطا: 20301)",
    "error_20302": "خطا: 20302. لطفا با ما تماس بگیرید",
    "error_20303": "ایمیل معتبر نیست (کد خطا: 20303)",
    "error_20304": "درخواست ناموفق بود (کد خطا: 20304). لطفا دوباره آن را امتحان کنید",
    "error_20305": "حساب وجود ندارد. لطفاً دوباره وارد کنید یا ابتدا <a href=\"https://account.mindonmap.com/register/\">آن را ایجاد کنید</a>.",
    "error_20306": "هنوز پسوردی وجود ندارد. از <a href=\"https://account.mindonmap.com/passwordless-login/\">ورود بدون رمز عبور</a> یا <a href=\"https://account.mindonmap.com/create-password/\"> استفاده کنید یک رمز عبور</a> تنظیم کنید و وارد شوید.",
    "error_20308": "درخواست ناموفق بود (کد خطا: 20308). لطفا دوباره آن را امتحان کنید",
    "error_20401": "خروج ناموفق بود (کد خطا: 20401). لطفا دوباره آن را امتحان کنید",
    "error_20501": "لطفا ایمیل خود را وارد کنید (کد خطا: 20501)",
    "error_20502": "ایمیل معتبر نیست (کد خطا: 20502)",
    "error_20503": "درخواست ناموفق بود (کد خطا: 20503). لطفا دوباره آن را امتحان کنید",
    "error_20504": "ایمیل ارسال نشد. لطفا دوباره آن را امتحان کنید.",
    "error_20601": "لطفا ایمیل خود را وارد کنید (کد خطا: 20601)",
    "error_20602": "لطفاً کد تأیید را وارد کنید (کد خطا: 20602)",
    "error_20603": "ایمیل معتبر نیست (کد خطا: 20603)",
    "error_20604": "درخواست ناموفق بود (کد خطا: 20604). لطفا دوباره آن را امتحان کنید",
    "error_20606": "درخواست ناموفق بود (کد خطا: 20606). لطفا دوباره آن را امتحان کنید",
    "error_20607": "درخواست ناموفق بود (کد خطا: 20607). لطفا دوباره آن را امتحان کنید",
    "error_20608": "درخواست ناموفق بود (کد خطا: 20608). لطفا دوباره آن را امتحان کنید",
    "error_20701": "لطفا ایمیل خود را وارد کنید (کد خطا: 20701)",
    "error_20702": "ایمیل معتبر نیست (کد خطا: 20702)",
    "error_20703": "درخواست ناموفق بود (کد خطا: 20703). لطفا دوباره آن را امتحان کنید",
    "error_20704": "حساب وجود ندارد. لطفاً دوباره وارد کنید یا ابتدا <a href=\"https://account.mindonmap.com/register/\">آن را ایجاد کنید</a>.",
    "error_20705": "درخواست ناموفق بود (کد خطا: 20705). لطفا دوباره آن را امتحان کنید",
    "error_20706": "ایمیل ارسال نشد. لطفا دوباره آن را امتحان کنید",
    "error_20801": "لطفا ایمیل خود را وارد کنید (کد خطا: 20801)",
    "error_20802": "خطا: 20802. لطفا با ما تماس بگیرید",
    "error_20803": "لطفاً کد تأیید را وارد کنید (کد خطا: 20803)",
    "error_20804": "ایمیل معتبر نیست (کد خطا: 20804)",
    "error_20805": "رمز عبور بیش از 8 کاراکتر مورد نیاز است (کد خطا: 20805)",
    "error_20806": "درخواست ناموفق بود (کد خطا: 20806). لطفا دوباره آن را امتحان کنید",
    "error_20808": "درخواست ناموفق بود (کد خطا: 20808). لطفا دوباره آن را امتحان کنید",
    "error_20901": "درخواست ناموفق بود (کد خطا: 20901). لطفا دوباره آن را امتحان کنید",
    "error_20902": "درخواست ناموفق بود (کد خطا: 20902). لطفا دوباره آن را امتحان کنید",
    "error_21000": "تغییرات ذخیره می شوند",
    "error_21001": "هیچ اطلاعاتی ارسال نشده است (کد خطا: 21001)",
    "error_21002": "درخواست ناموفق بود (کد خطا: 21002). لطفا دوباره آن را امتحان کنید",
    "error_21101": "لطفا ایمیل خود را وارد کنید (کد خطا: 21101)",
    "error_21102": "ایمیل معتبر نیست (کد خطا: 21102)",
    "error_21103": "درخواست ناموفق بود (کد خطا: 21103)، لطفاً دوباره آن را امتحان کنید",
    "error_21104": "ایمیل قبلاً متصل است، لطفاً از یک ایمیل جدید استفاده کنید",
    "error_21105": "درخواست ناموفق بود (کد خطا: 21105)، لطفاً دوباره آن را امتحان کنید",
    "error_21106": "ایمیل ارسال نشد. لطفا دوباره آن را امتحان کنید",
    "error_21201": "لطفا ایمیل خود را وارد کنید (کد خطا: 21201)",
    "error_21202": "لطفاً کد تأیید را وارد کنید (کد خطا: 21202)",
    "error_21203": "ایمیل معتبر نیست (کد خطا: 21203)",
    "error_21204": "خطا: 21204. لطفا با ما تماس بگیرید",
    "error_21205": "خطا: 21205. لطفا با ما تماس بگیرید",
    "error_21206": "رمز عبور بیش از 8 کاراکتر مورد نیاز است (کد خطا: 21206)",
    "error_21207": "درخواست ناموفق بود (کد خطا: 21207). لطفا دوباره آن را امتحان کنید",
    "error_21209": "درخواست ناموفق بود (کد خطا: 21209). لطفا دوباره آن را امتحان کنید",
    "error_21301": "لطفا رمز عبور قدیمی را وارد کنید (کد خطا: 21301)",
    "error_21302": "لطفا یک رمز عبور جدید ایجاد کنید (کد خطا: 21302)",
    "error_21303": "رمز عبور جدید نمی تواند مانند رمز قبلی باشد. (خطا: 21303)",
    "error_21304": "رمز عبور بیش از 8 کاراکتر مورد نیاز است (کد خطا: 21304)",
    "error_21306": "درخواست ناموفق بود (کد خطا: 21306). لطفا دوباره آن را امتحان کنید",
    "error_21402": "درخواست ناموفق بود (کد خطا: 21402). لطفا دوباره آن را امتحان کنید",
    "error_21403": "کد تأیید ارسال نشد. لطفا دوباره بفرستید",
    "error_21500": "حساب کاربری حذف شده است",
    "error_21501": "لطفاً کد تأیید را وارد کنید (کد خطا: 21501)",
    "error_21502": "جلسه ورود به سیستم منقضی شده است (خطا: 21502). لطفا دوباره وارد شوید.",
    "error_21503": "درخواست ناموفق بود (کد خطا: 21503). لطفا دوباره آن را امتحان کنید",
    "error_21505": "درخواست ناموفق بود (کد خطا: 21505)، لطفاً دوباره امتحان کنید",
    "error_21601": "خطا: 20601. لطفا با ما تماس بگیرید",
    "error_21602": "تأیید نامعتبر (خطا: 20602). لطفا دوباره آن را امتحان کنید.",
    "error_21603": "خطا: 20603. لطفاً دوباره امتحان کنید",
    "error_21604": "درخواست ناموفق بود (کد خطا: 21604). لطفا دوباره آن را امتحان کنید",
    "error_21606": "درخواست ناموفق بود (کد خطا: 21606). لطفا دوباره آن را امتحان کنید",
    "error_21611": "درخواست ناموفق بود (کد خطا: 21611). لطفا دوباره آن را امتحان کنید",
    "error_21801": "خطا: 21801. لطفا با ما تماس بگیرید",
    "error_21802": "درخواست ناموفق بود (خطا: 21802). لطفا دوباره آن را امتحان کنید",
    "error_21803": "خطا: 21803. لطفاً دوباره امتحان کنید",
    "error_21804": "درخواست ناموفق بود (کد خطا: 21804). لطفا دوباره آن را امتحان کنید",
    "error_21806": "خطا: 21806. لطفاً دوباره امتحان کنید",
    "error_21807": "خطا: 21807. لطفا با ما تماس بگیرید",
    "error_21808": "خطا: 21808. لطفا با ما تماس بگیرید",
    "error_21809": "خطا: 21809. لطفا با ما تماس بگیرید",
    "error_21810": "خطا: 21810. لطفا با ما تماس بگیرید",
    "error_21811": "خطا: 21811. لطفا با ما تماس بگیرید",
    "error_21812": "خطا: 21812. لطفا با ما تماس بگیرید",
    "error_21813": "درخواست ناموفق بود (کد خطا: 21813). لطفا دوباره آن را امتحان کنید",
    "error_21814": "خطا: 21814. لطفا با ما تماس بگیرید",
    "error_21815": "درخواست ناموفق بود (کد خطا: 21815). لطفا دوباره آن را امتحان کنید",
    "error_21816": "خطا: 21816. لطفا با ما تماس بگیرید",
    "error_21817": "خطا: 21817. لطفا با ما تماس بگیرید",
    "error_21818": "خطا: 21818. لطفا با ما تماس بگیرید",
    "error_21819": "درخواست ناموفق بود (کد خطا: 21819). لطفا دوباره آن را امتحان کنید",
    "error_21820": "خطا: 21820. لطفا با ما تماس بگیرید",
    "error_21821": "خطا: 21821. لطفا با ما تماس بگیرید",
    "error_21822": "خطا: 21822. لطفا با ما تماس بگیرید",
    "error_21823": "درخواست ناموفق بود (کد خطا: 21823). لطفا دوباره آن را امتحان کنید",
    "error_21824": "درخواست ناموفق بود (کد خطا: 21824). لطفا دوباره آن را امتحان کنید",
    "error_21825": "درخواست ناموفق بود (کد خطا: 21825). لطفا دوباره آن را امتحان کنید",
    "error_21826": "درخواست ناموفق بود (کد خطا: 21826). لطفا دوباره آن را امتحان کنید",
    "error_21901": "خطا: 21901. لطفا با ما تماس بگیرید",
    "error_21902": "درخواست ناموفق بود (کد خطا: 21902). لطفا دوباره آن را امتحان کنید",
    "error_21903": "وضعیت حساب تغییر کرده است (کد خطا: 21903)، لطفاً صفحه را بازخوانی کنید و دوباره امتحان کنید",
    "error_21904": "خطا: 21904. لطفاً دوباره امتحان کنید",
    "error_21905": "خطا: 21905. لطفاً دوباره امتحان کنید",
    "error_21906": "درخواست ناموفق بود (کد خطا: 21906). لطفا دوباره آن را امتحان کنید",
    "error_21907": "حساب Google به حساب دیگری پیوند داده شده است",
    "error_21908": "درخواست ناموفق بود (کد خطا: 21908). لطفا دوباره آن را امتحان کنید",
    "error_22001": "درخواست ناموفق بود (کد خطا: 22001). لطفا دوباره آن را امتحان کنید",
    "error_22002": "بدون ورود اضافی لغو پیوند انجام نشد",
    "error_22003": "درخواست ناموفق بود (کد خطا: 22003). لطفا دوباره آن را امتحان کنید",
    "error_22101": "خطا: 22101. لطفا با ما تماس بگیرید",
    "error_22102": "وضعیت حساب تغییر کرده است (کد خطا: 22102)، لطفاً صفحه را بازخوانی کرده و دوباره امتحان کنید",
    "error_22103": "درخواست ناموفق بود (کد خطا: 22103). لطفا دوباره آن را امتحان کنید",
    "error_22104": "وضعیت حساب تغییر کرده است (کد خطا: 22104)، لطفاً صفحه را بازخوانی کرده و دوباره امتحان کنید",
    "error_22105": "خطا: 22105. لطفاً دوباره امتحان کنید",
    "error_22106": "خطا: 22106. لطفاً دوباره امتحان کنید",
    "error_22107": "خطا: 22107. لطفا با ما تماس بگیرید",
    "error_22108": "درخواست ناموفق بود (کد خطا: 22108). لطفا دوباره آن را امتحان کنید",
    "error_22201": "خطا: 22201. لطفا با ما تماس بگیرید",
    "error_22202": "تأیید نامعتبر (خطا: 22202). لطفا دوباره آن را امتحان کنید.",
    "error_22203": "خطا: 22203. لطفاً دوباره امتحان کنید\"",
    "error_22204": "درخواست ناموفق بود (کد خطا: 22204). لطفا دوباره آن را امتحان کنید",
    "error_22206": "درخواست ناموفق بود (کد خطا: 22206). لطفا دوباره آن را امتحان کنید",
    "error_22401": "خطا: 22401. لطفا با ما تماس بگیرید",
    "error_22402": "تأیید نامعتبر (خطا: 22402). لطفا دوباره آن را امتحان کنید.",
    "error_22403": "خطا: 22403. لطفاً دوباره امتحان کنید",
    "error_22404": "درخواست ناموفق بود (کد خطا: 22404). لطفا دوباره آن را امتحان کنید",
    "error_22405": "حساب فیس بوک به ایمیل های دیگر مرتبط شده است",
    "error_22406": "خطا: 22406. لطفاً دوباره امتحان کنید",
    "error_22407": "خطا: 22407. لطفا با ما تماس بگیرید",
    "error_22408": "خطا: 22408. لطفا با ما تماس بگیرید",
    "error_22409": "خطا: 22409. لطفا با ما تماس بگیرید",
    "error_22410": "خطا: 224010. لطفا با ما تماس بگیرید",
    "error_22411": "خطا: 224011. لطفا با ما تماس بگیرید",
    "error_22412": "خطا: 224012. لطفا با ما تماس بگیرید",
    "error_22413": "خطا: 22413. لطفا با ما تماس بگیرید",
    "error_22414": "درخواست ناموفق بود (کد خطا: 22414). لطفا دوباره آن را امتحان کنید",
    "error_22415": "خطا: 22415. لطفا با ما تماس بگیرید",
    "error_22416": "خطا: 22416. لطفا با ما تماس بگیرید",
    "error_22417": "خطا: 22417. لطفا با ما تماس بگیرید",
    "error_22418": "درخواست ناموفق بود (کد خطا: 22418). لطفا دوباره آن را امتحان کنید",
    "error_22419": "خطا: 22419. لطفا با ما تماس بگیرید",
    "error_22420": "خطا: 22420. لطفا با ما تماس بگیرید",
    "error_22421": "خطا: 22421. لطفا با ما تماس بگیرید",
    "error_22422": "درخواست ناموفق بود (کد خطا: 22422). لطفا دوباره آن را امتحان کنید",
    "error_22423": "درخواست ناموفق بود (کد خطا: 22423). لطفا دوباره آن را امتحان کنید",
    "error_22424": "درخواست ناموفق بود (کد خطا: 22424). لطفا دوباره آن را امتحان کنید",
    "error_22425": "درخواست ناموفق بود (کد خطا: 22425). لطفا دوباره آن را امتحان کنید",
    "error_20098": "کد خطا: 20098. اگر در حالت خصوصی مرور می‌کنید، لطفاً به حالت عادی بروید و دوباره امتحان کنید.",
    "error_22298": "درخواست برای Google ناموفق بود (خطا: 22298). لطفا دوباره آن را امتحان کنید.",
    "error_22498": "درخواست برای فیس بوک ناموفق بود (خطا: 22498). لطفا دوباره آن را امتحان کنید.",
    "error_24902": "درخواست ناموفق بود (کد خطا: 24902). لطفا دوباره آن را امتحان کنید",
    "error_24903": "درخواست ناموفق بود (کد خطا: 24903). لطفا دوباره آن را امتحان کنید",
    "error_24904": "درخواست ناموفق بود (کد خطا: 24904). لطفا دوباره آن را امتحان کنید",
    "error_24905": "درخواست ناموفق بود (کد خطا: 24905). لطفا دوباره آن را امتحان کنید",
    "login_title": "وارد MindOnMap شوید",
    "log_in": "وارد شدن",
    "no_account": "حسابی ندارید؟",
    "create_it": "آن را ایجاد کنید",
    "or_log_in_with": "یا با وارد شوید",
    "passwordless_login": "ورود بدون رمز عبور",
    "log_in_done": "ورود انجام شد",
    "three_rd_account_connect_info": "تبریک می گویم! شما با موفقیت وارد سیستم شدید. اکنون می توانید یک حساب ایمیل را که برای ورود به سیستم در آینده استفاده می شود، متصل کنید.",
    "see_my_account": "اکانت من را ببینید",
    "three_rd_login_merge_account": "آدرس ایمیل حساب شخص ثالث ثبت شده است، آیا می خواهید مستقیماً با این آدرس ایمیل متصل شوید و وارد شوید؟",
    "connect_log_in": "اتصال و ورود به سیستم",
    "create_an_account": "یک حساب کاربری ایجاد کنید",
    "back_to_log_in": "بازگشت به صفحه ورود",
    "create_password": "رمز عبور ایجاد کنید",
    "create_now": "اکنون ایجاد کنید",
    "password_login_subtitle": "ورود بدون رمز عبور با ایمیل",
    "account_login": "ورود به حساب کاربری",
    "rights": "با ایجاد این حساب، با <a href=\"https://www.mindonmap.com/terms-and-conditions/\">شرایط خدمات</a> و <a href=\"https://www.mindonmap.com/privacy-policy/\">خط مشی رازداری</a>",
    "passwordless_login_done": "ورود بدون رمز عبور انجام شد",
    "passwordless_login_info": "تبریک می‌گوییم، ورود بدون رمز عبور را با موفقیت تکمیل کردید. می توانید برای این حساب رمز عبور ایجاد کنید و در آینده با حساب کاربری و رمز عبور وارد شوید. <a href=\"/create-password\" style=\"display: initial;\">اکنون ایجاد کنید</a>",
    "sign_up": "ثبت نام",
    "register_info": "حساب کاربری برای خود بسازید",
    "reset_now": "اکنون بازنشانی کنید",
    "forgot_password": "رمز عبور را فراموش کرده اید",
    "reset_password_subtitle": "از ایمیل حساب خود برای بازنشانی رمز عبور استفاده کنید",
    "plan_products": "طرح ها و محصولات",
    "nick_name": "نام کاربری:",
    "email": "پست الکترونیک:",
    "my_products": "محصولات من",
    "my_orders": "دستورات من",
    "unlink": "لغو پیوند",
    "link": "ارتباط دادن",
    "connected_accounts": "حساب های متصل",
    "last_name": "نام خانوادگی:",
    "first_name": "نام کوچک:",
    "Gender": "جنسیت:",
    "Birth": "تولد:",
    "Month": "ماه",
    "Year": "سال",
    "Country_Region": "کشور/منطقه:",
    "Address": "نشانی:",
    "Save": "صرفه جویی",
    "Date": "تاریخ",
    "Male": "نر",
    "Female": "مونث",
    "Unspecified": "نامشخص",
    "Security": "امنیت",
    "change_password": "رمز عبور را تغییر دهید",
    "change_now": "اکنون تغییر دهید",
    "connect_email": "اتصال ایمیل",
    "delete_account": "حذف حساب کاربری",
    "delete_account_info": "هنگامی که حساب شما حذف می شود، تمام داده های مربوط به حساب من در حساب شما برای همیشه حذف می شود و ممکن است نتوانید آن را بازیابی کنید. توصیه می کنیم با احتیاط قدم بردارید.",
    "Delete": "حذف",
    "Logout": "خروج",
    "my_profile": "پروفایل من",
    "guides_faqs": "راهنماها و سوالات متداول",
    "More": "بیشتر",
    "guides": "راهنماها",
    "register": "ثبت نام",
    "hot_faq": "سوالات متداول داغ",
    "Contents": "فهرست:",
    "contact_us": "تماس با ما >>",
    "plan": "طرح",
    "unregistered": "ثبت نشده",
    "buy_more": "خرید بیشتر",
    "buy_again": "دوباره خرید کنید",
    "buy_now": "هم اکنون خریداری کنید",
    "free_no_limit": "رایگان و بدون محدودیت",
    "expired": "منقضی شده",
    "lifetime": "طول عمر",
    "remain": "ماندن",
    "day_s": "روزها)",
    "error_24801": "درخواست ناموفق بود (کد خطا: 24801). لطفا دوباره آن را امتحان کنید",
    "no_app_found": "هیچ برنامه ای یافت نشد.<a href=\"/\">بازخوانی</a> یا <a href=\"https://www.mindonmap.com/\">رفتن به وب سایت رسمی</a>",
    "get_more": "دریافت بیشتر >>",
    "edit_photo": "ویرایش عکس",
    "select_photo": "عکس را انتخاب کنید",
    "change_photo": "تعویض عکس",
    "cancel": "لغو کنید",
    "hide_password": "پنهان کردن رمز عبور",
    "show_password": "نمایش رمز عبور",
    "zoom_in": "بزرگنمایی",
    "zoom_out": "کوچک نمایی",
    "rotate": "چرخش",
    "horizontal_flip": "تلنگر افقی",
    "vertical_flip": "چرخش عمودی",
    "country": "کشور",
    "country_1": "کشور/منطقه خود را انتخاب کنید",
    "country_2": "جزیره آلند",
    "country_3": "افغانستان",
    "country_4": "آلبانی",
    "country_5": "الجزایر",
    "country_6": "ساموآی آمریکایی",
    "country_7": "آندورا",
    "country_8": "آنگولا",
    "country_9": "آنگویلا",
    "country_10": "جنوبگان",
    "country_11": "آنتیگوا و باربودا",
    "country_12": "آرژانتین",
    "country_13": "ارمنستان",
    "country_14": "آروبا",
    "country_15": "استرالیا",
    "country_16": "اتریش",
    "country_17": "آذربایجان",
    "country_18": "بحرین",
    "country_19": "بنگلادش",
    "country_20": "باربادوس",
    "country_21": "بلاروس",
    "country_22": "بلژیک",
    "country_23": "بلیز",
    "country_24": "بنین",
    "country_25": "برمودا",
    "country_26": "بوتان",
    "country_27": "بولیوی",
    "country_28": "بوسنی و هرزگوین",
    "country_29": "بوتسوانا",
    "country_30": "جزیره بووه",
    "country_31": "برزیل",
    "country_32": "قلمرو اقیانوس هند بریتانیا",
    "country_33": "جزایر ویرجین بریتانیا",
    "country_34": "برونئی",
    "country_35": "بلغارستان",
    "country_36": "بورکینا",
    "country_37": "بوروندی",
    "country_38": "کامبوج",
    "country_39": "کامرون",
    "country_40": "کانادا",
    "country_41": "کیپ ورد",
    "country_42": "کارائیب هلند",
    "country_43": "جزایر کیمن",
    "country_44": "جمهوری آفریقای مرکزی",
    "country_45": "چاد",
    "country_46": "شیلی",
    "country_47": "چین",
    "country_48": "جزیره کریسمس",
    "country_49": "جزایر کوکوس (کیلینگ).",
    "country_50": "کلمبیا",
    "country_51": "جزایر کوک",
    "country_52": "کاستاریکا",
    "country_53": "ساحل عاج",
    "country_54": "کرواسی",
    "country_55": "کوبا",
    "country_56": "قبرس",
    "country_57": "جمهوری چک",
    "country_58": "جمهوری دموکراتیک کنگو",
    "country_59": "دانمارک",
    "country_60": "جیبوتی",
    "country_61": "دومینیکا",
    "country_62": "جمهوری دومینیکن",
    "country_63": "اکوادور",
    "country_64": "مصر",
    "country_65": "السالوادور",
    "country_66": "گینه استوایی",
    "country_67": "اریتره",
    "country_68": "استونی",
    "country_69": "اتیوپی",
    "country_70": "جزایر فالکلند",
    "country_71": "جزایر فارو",
    "country_72": "ایالت های فدرال میکرونزی",
    "country_73": "فیجی",
    "country_74": "فنلاند",
    "country_75": "فرانسه",
    "country_76": "گویان فرانسه",
    "country_77": "پلی‌نزی فرانسه",
    "country_78": "سرزمین‌های جنوبی فرانسه",
    "country_79": "گابن",
    "country_80": "گامبیا",
    "country_81": "گرجستان",
    "country_82": "آلمان",
    "country_83": "غنا",
    "country_84": "جبل الطارق",
    "country_85": "بریتانیای کبیر (بریتانیا، انگلستان)",
    "country_86": "یونان",
    "country_87": "گرینلند",
    "country_88": "گرانادا",
    "country_89": "گوادلوپ",
    "country_90": "گوام",
    "country_91": "گواتمالا",
    "country_92": "گرنزی",
    "country_93": "گینه",
    "country_94": "گینه بیسائو",
    "country_95": "گویان",
    "country_96": "هائیتی",
    "country_97": "جزیره هرد و جزایر مک دونالد",
    "country_98": "هندوراس",
    "country_99": "هنگ کنگ",
    "country_100": "مجارستان",
    "country_101": "ایسلند",
    "country_102": "هندوستان",
    "country_103": "اندونزی",
    "country_104": "ایران",
    "country_105": "عراق",
    "country_106": "ایرلند",
    "country_107": "جزیره من",
    "country_108": "اسرائيل",
    "country_109": "ایتالیا",
    "country_110": "جامائیکا",
    "country_111": "ژاپن",
    "country_112": "پیراهن ورزشی",
    "country_113": "اردن",
    "country_114": "قزاقستان",
    "country_115": "کنیا",
    "country_116": "کیریباتی",
    "country_117": "کویت",
    "country_118": "قرقیزستان",
    "country_119": "لائوس",
    "country_120": "لتونی",
    "country_121": "لبنان",
    "country_122": "لسوتو",
    "country_123": "لیبریا",
    "country_124": "لیبی",
    "country_125": "لیختن اشتاین",
    "country_126": "لیتوانی",
    "country_127": "لوکزامبورگ",
    "country_128": "ماکائو",
    "country_129": "ماداگاسکار",
    "country_130": "مالاوی",
    "country_131": "مالزی",
    "country_132": "مالدیو",
    "country_133": "مالی",
    "country_134": "مالت",
    "country_135": "جزایر مارشال",
    "country_136": "مارتینیک",
    "country_137": "موریتانی",
    "country_138": "موریس",
    "country_139": "مایوت",
    "country_140": "مکزیک",
    "country_141": "مولداوی",
    "country_142": "موناکو",
    "country_143": "مغولستان",
    "country_144": "مونته نگرو",
    "country_145": "مونتسرات",
    "country_146": "مراکش",
    "country_147": "موزامبیک",
    "country_148": "میانمار (برمه)",
    "country_149": "نامیبیا",
    "country_150": "نائورو",
    "country_151": "نپال",
    "country_152": "هلند",
    "country_153": "کالدونیای جدید",
    "country_154": "نیوزلند",
    "country_155": "نیکاراگوئه",
    "country_156": "نیجر",
    "country_157": "نیجریه",
    "country_158": "نیوئه",
    "country_159": "جزیره نورفولک",
    "country_160": "کره شمالی",
    "country_161": "جزایر ماریانای شمالی",
    "country_162": "نروژ",
    "country_163": "عمان",
    "country_164": "پاکستان",
    "country_165": "پالائو",
    "country_166": "سرزمین های فلسطینی",
    "country_167": "پاناما",
    "country_168": "پاپوآ گینه نو",
    "country_169": "پاراگوئه",
    "country_170": "پرو",
    "country_171": "جزایر پیتکرن",
    "country_172": "لهستان",
    "country_173": "کشور پرتغال",
    "country_174": "پورتوریکو",
    "country_175": "قطر",
    "country_176": "جمهوری مقدونیه (FYROM)",
    "country_177": "جمهوری کنگو",
    "country_178": "تجدید دیدار",
    "country_179": "رومانی",
    "country_180": "فدراسیون روسیه",
    "country_181": "رواندا",
    "country_182": "سنت بارتلمی",
    "country_183": "سنت مارتین (فرانسه)",
    "country_184": "سن پیر و میکلون",
    "country_185": "ساموآ",
    "country_186": "سن مارینو",
    "country_187": "سائوتومه و پرنسیپ",
    "country_188": "عربستان سعودی",
    "country_189": "سنگال",
    "country_190": "صربستان",
    "country_191": "سیشل",
    "country_192": "سیرا لئون",
    "country_193": "سنگاپور",
    "country_194": "اسلواکی",
    "country_195": "اسلوونی",
    "country_196": "جزایر سلیمان",
    "country_197": "سومالی",
    "country_198": "آفریقای جنوبی",
    "country_199": "جنوب گرجستان و جزیره ساندویچ جنوبی",
    "country_200": "کره جنوبی",
    "country_201": "سودان جنوبی",
    "country_202": "اسپانیا",
    "country_203": "سری لانکا",
    "country_204": "سنت هلنا و وابستگی ها",
    "country_205": "سنت کیتس و نویس",
    "country_206": "سنت لوسیا",
    "country_207": "سنت وینسنت و گرنادین",
    "country_208": "سودان",
    "country_209": "سورینام",
    "country_210": "سوازیلند",
    "country_211": "سوئد",
    "country_212": "سوئیس",
    "country_213": "سوریه",
    "country_214": "تایوان",
    "country_215": "تاجیکستان",
    "country_216": "تانزانیا",
    "country_217": "الگو:داده‌های کشور SJM Svalbard",
    "country_218": "تایلند",
    "country_219": "باهاما",
    "country_220": "کومور",
    "country_221": "فیلیپین",
    "country_222": "تیمور شرقی (تیمور شرقی)",
    "country_223": "رفتن",
    "country_224": "توکلائو",
    "country_225": "تونگا",
    "country_226": "ترینیداد و توباگو",
    "country_227": "تونس",
    "country_228": "بوقلمون",
    "country_229": "ترکمنستان",
    "country_230": "جزایر ترکز و کایکوس",
    "country_231": "تووالو",
    "country_232": "اوگاندا",
    "country_233": "اوکراین",
    "country_234": "امارات متحده عربی",
    "country_235": "جزایر کوچک حاشیه های ایالات متحده",
    "country_236": "ایالات متحده آمریکا (ایالات متحده آمریکا)",
    "country_237": "جزایر ویرجین ایالات متحده",
    "country_238": "اروگوئه",
    "country_239": "ازبکستان",
    "country_240": "وانواتو",
    "country_241": "شهر واتیکان (مجلس مقدس)",
    "country_242": "ونزوئلا",
    "country_243": "ویتنام",
    "country_244": "والیس و فوتونا",
    "country_245": "صحرای غربی",
    "country_246": "یمن",
    "country_247": "زامبیا",
    "country_248": "زیمبابوه",
    "google_login": "با گوگل وارد شوید",
    "State": "حالت",
    "Activation_code": "کد فعال سازی",
    "Question": "تمام برنامه هایی که وارد شده اید را فهرست کنید",
    "Copy_complete": "کپی کامل شد",
    "footer": "حق چاپ © 2024 MindOnMap Studio. تمامی حقوق محفوظ است.",
    "change_password_success": "رمز عبور با موفقیت تغییر کرد",
    "successful_login_title": "ورود با موفقیت",
    "product_page": "صفحه محصول >>",
    "successful_login_info": "ورود کامل شد. لطفاً صفحه فعلی را ببندید و به برگه اصلی برگردید تا به روند زیر ادامه دهید. صفحه فعلی به طور خودکار در عرض 5 ثانیه بسته می شود. اگر بسته شدن خودکار یا بسته شدن با کلیک روی دکمه \"انجام شد\" انجام نشد، لطفاً مستقیماً این برگه را ببندید.",
    "successful_login_info_firefox": "ورود کامل شد. لطفاً صفحه فعلی را ببندید و به برگه اصلی برگردید تا به روند زیر ادامه دهید.",
    "my_account": "حساب من",
    "my_history": "تاریخچه من",
    "remove_watermark": "حذف واترمارک",
    "no_history": "بدون سابقه",
    "history_all": "انتخاب همه",
    "history_open": "باز کن",
    "history_down": "دانلود",
    "history_delete": "حذف",
    "history_clear": "پاک کردن نامعتبر",
    "images": "تصاویر",
    "use_this_function": "از این محصول استفاده کنید>>",
    "hd_downloading": "مزایای دانلود تصاویر اصلی HD:",
    "lifetimeRemaining": "مادام العمر",
    "Remaining": "باقی مانده است",
    "email_verification": "تایید ایمیل",
    "email_verification_info": "ما پیام تأیید را به ایمیل <span class=\"email\"></span> شما ارسال کرده‌ایم و لطفاً تأیید را تمام کنید. پس از تأیید، مزایا به طور خودکار همگام خواهند شد.",
    "wrong_email": "آدرس ایمیل اشتباه است؟",
    "click_here_to_modify": "برای اصلاح اینجا را کلیک کنید",
    "get_more_help": "کمک بیشتری دریافت کنید؟",
    "click_here": "اینجا کلیک کنید",
    "email_verification_info_success": "برای تأیید حساب ایمیل خود تبریک می گویم.",
    "email_verification_info_error": "تأیید به دلیل پیوند منقضی شده انجام نشد.",
    "registration_succeeded": "ثبت نام با موفقیت انجام شد",
    "registration_succeeded_info_1": "تبریک می گویم! شما با موفقیت ثبت نام کردید. ما پیام تأیید را به ایمیل <span class=\"email\"></span> شما ارسال کرده‌ایم، و لطفاً برای استفاده از مزایای این ایمیل، تأیید را تمام کنید.",
    "registration_succeeded_info_2": "برای بازگشت به صفحه اصلی و استفاده از این محصول، روی \"انجام شد\" کلیک کنید.",
    "registration_succeeded_info_3": "روی \"انجام شد\" کلیک کنید تا صفحه فعلی بسته شود و برای عملیات زیر به صفحه اصلی بازگردید. اگر بستن با شکست مواجه شد، لطفاً به صورت دستی برگه را ببندید.",
    "verify_email": "تأیید ایمیل",
    "registered_email_not_verified": "ایمیل ثبت شده تأیید نشده است، لطفاً فوراً آن را تأیید کنید.",
    "email_verification_time_1": "ایمیل تأیید دریافت نکردید؟",
    "email_verification_time_2": "بعد از",
    "email_verification_time_3": "اینجا کلیک کنید تا دوباره ارسال شود",
    "error_26301": "کد خطا: 26301 لطفا با ما تماس بگیرید",
    "error_26302": "کد خطا: 26302 لطفا با ما تماس بگیرید",
    "error_26303": "خطای فرمت ایمیل (کد خطا: 26303). لطفا دوباره وارد کنید",
    "error_26304": "رمز عبور بیش از 8 کاراکتر توصیه می شود (کد خطا: 26304)",
    "error_26305": "درخواست ناموفق بود (کد خطا: 26305). لطفا دوباره آن را امتحان کنید",
    "error_26306": "ایمیل ثبت شده است، لطفا <a href=\"https://account.mindonmap.com/login/\">برای ورود به سیستم بروید</a>",
    "error_26307": "درخواست ناموفق بود (کد خطا: 26307). لطفا دوباره امتحان کنید",
    "error_26308": "درخواست انجام نشد (کد خطا: 26308). لطفا دوباره امتحان کنید",
    "error_26401": "کد خطا: 26401، لطفا دوباره امتحان کنید",
    "error_26402": "ایمیل تأیید شده است (کد خطا: 26402)، لطفاً دوباره امتحان کنید",
    "error_26403": "درخواست ناموفق بود (کد خطا: 26403). لطفا دوباره آن را امتحان کنید",
    "error_26404": "درخواست ناموفق بود (کد خطا: 26404). لطفا دوباره آن را امتحان کنید",
    "error_26501": "کد خطا: 26501 لطفا با ما تماس بگیرید",
    "error_26502": "کد خطا: 26502 لطفا با ما تماس بگیرید",
    "error_26503": "خطای فرمت ایمیل (کد خطا: 26503). لطفا دوباره وارد کنید",
    "error_26504": "درخواست ناموفق بود (کد خطا: 26504). لطفا دوباره امتحان کنید",
    "error_26505": "ایمیل ثبت نشده است، لطفاً <a href=\"https://account.mindonmap.com/register/\">ابتدا آن را ثبت کنید</a>",
    "error_26506": "ایمیل تایید شده است.",
    "error_26507": "درخواست ناموفق بود (کد خطا: 26507). لطفا دوباره آن را امتحان کنید",
    "error_26508": "تأیید انجام شد (کد خطا: 26508)، لطفاً دوباره امتحان کنید",
    "error_26509": "درخواست ناموفق بود (کد خطا: 26509)، لطفاً دوباره امتحان کنید",
    "error_26510": "کد خطا: 26510 لطفا با ما تماس بگیرید",
    "error_26601": "کد خطا: 26601 لطفا با ما تماس بگیرید",
    "error_26602": "کد خطا: 26602 لطفا با ما تماس بگیرید",
    "error_26603": "درخواست انجام نشد (کد خطا: 26603). لطفا دوباره آن را امتحان کنید",
    "error_26604": "کد خطا: 26604 لطفا با ما تماس بگیرید",
    "error_26605": "کد خطا: 26605 لطفا با ما تماس بگیرید",
    "error_26701": "کد خطا: 26701 لطفا با ما تماس بگیرید",
    "error_26702": "درخواست ناموفق بود (کد خطا: 26702). لطفا دوباره آن را امتحان کنید",
    "error_26703": "کد خطا: 26703 لطفا با ما تماس بگیرید",
    "error_26704": "کد خطا: 26704 لطفا با ما تماس بگیرید",
    "error_26705": "منتظر بمانید تا وارد شوید (کد خطا: 26705). لطفا دوباره آن را امتحان کنید",
    "no_cookie": "شما عملکرد مسدود کردن همه کوکی‌ها را در مرورگر خود روشن کرده‌اید، بنابراین نمی‌توانید وارد شوید. لطفاً به تنظیمات بروید تا کادر مجاز به همه کوکی‌ها را علامت بزنید.",
    "error_26801": "کد خطا: 26801 لطفا با ما تماس بگیرید",
    "error_26802": "کد خطا: 26802 لطفا با ما تماس بگیرید",
    "error_26803": "درخواست ناموفق بود (کد خطا: 26803). لطفا دوباره آن را امتحان کنید",
    "error_26804": "درخواست ناموفق بود (کد خطا: 26804). لطفا دوباره آن را امتحان کنید",
    "error_order": "درخواست ناموفق بود (کد خطا: 27098)، لطفاً دوباره امتحان کنید!",
    "error_order1": "درخواست سفارش ناقص است (کد خطا: ",
    "error_order2": "）، لطفا بازخوانی کنید و دوباره امتحان کنید.",
    "modify_email_title": "ایمیل را اصلاح کنید",
    "modify_email_info": "می توانید از ایمیل اصلاح شده برای ورود به حساب کاربری خود استفاده کنید.",
    "images_per": "تصاویر در هر",
    "error_26101": "خطا: 26101. لطفا با ما تماس بگیرید",
    "error_26102": "خطا: 26102. لطفا با ما تماس بگیرید",
    "error_26103": "درخواست ناموفق بود (کد خطا: 26103). لطفاً دوباره آن را امتحان کنید",
    "error_26104": "کد خطا: 26104، لطفا دوباره امتحان کنید",
    "error_26105": "کد خطا: 26105، لطفا دوباره امتحان کنید",
    "error_26106": "حذف نشد (کد خطا: 26106). لطفا دوباره آن را امتحان کنید",
    "error_26201": "خطا: 26201. لطفا با ما تماس بگیرید",
    "error_26202": "درخواست ناموفق بود (کد خطا: 26202). لطفاً دوباره آن را امتحان کنید",
    "error_26001": "خطا: 26001. لطفا با ما تماس بگیرید",
    "error_26002": "خطا: 26002. لطفا با ما تماس بگیرید",
    "error_26003": "خطا: 26003. لطفا با ما تماس بگیرید",
    "error_26004": "خطا: 26004. لطفا با ما تماس بگیرید",
    "error_26005": "درخواست ناموفق بود (کد خطا: 26005). لطفاً دوباره آن را امتحان کنید",
    "error_26006": "کد خطا: 26006، لطفا دوباره امتحان کنید",
    "error_26008": "خطا: 26008. لطفا با ما تماس بگیرید",
    "go_to_the_home_page": "به صفحه اصلی بروید",
    "error_27101": "درخواست ناموفق بود (کد خطا: 27101). لطفا دوباره امتحان کنید",
    "error_27201": "کد خطا: 27201 لطفا با ما تماس بگیرید",
    "error_27202": "کد خطا: 27202، لطفا دوباره امتحان کنید",
    "error_27203": "درخواست ناموفق بود (کد خطا: 27203). لطفا دوباره آن را امتحان کنید",
    "error_27204": "کد نامعتبر (کد خطا: 27204).",
    "error_27205": "درخواست ناموفق بود (کد خطا: 27205). لطفا دوباره امتحان کنید",
    "error_27206": "درخواست ناموفق بود (کد خطا: 27206). لطفا دوباره امتحان کنید",
    "error_27207": "درخواست ناموفق بود (کد خطا: 27207). لطفا دوباره آن را امتحان کنید",
    "no_history_found": "از هیچ ابزاری استفاده نکردی! <a href=\"/\">بازخوانی</a> یا <a href=\"https://www.mindonmap.com/\">رفتن به وب‌سایت رسمی</a>",
    "error_25301": "خطا: 25301. لطفا با ما تماس بگیرید",
    "error_25302": "خطا: 25302. لطفا با ما تماس بگیرید",
    "error_25303": "درخواست ناموفق بود (کد خطا: 25303). لطفا دوباره آن را امتحان کنید",
    "error_25304": "درخواست ناموفق بود (کد خطا: 25304). لطفا دوباره آن را امتحان کنید",
    "error_25305": "درخواست ناموفق بود (کد خطا: 25305). لطفا دوباره آن را امتحان کنید",
    "error_25306": "درخواست ناموفق بود (کد خطا: 25306). لطفا دوباره آن را امتحان کنید",
    "image_upscaler_p": "مزایای دانلود تصویر بدون واترمارک:",
    "Available_for": "در دسترس برای:",
    "credit_per": "%s اعتبار(ها) در هر تصویر HD",
    "still_valid": "طرح(های) خریداری شده همچنان معتبر است",
    "credit": "وام)",
    "pc_3rd_info": "با موفقیت وارد شوید لطفا برای عملیات بیشتر به برنامه بروید.",
    "use_online": "از خدمات آنلاین استفاده کنید",
    "use_download": "از برنامه دسکتاپ استفاده کنید",
    "use_immediately": "فورا استفاده کنید",
    "Use_in_browser": "استفاده در مرورگر",
    "win_desktop": "پنجره ها",
    "Mac_desktop": "مک",
    "credits_per": "{%} اعتبار در ماه",
    "expire": "زمان انقضا:",
    "viewDetails": "دیدن جزئیات",
    "viewHistory": "مشاهده مزایای تاریخ>>",
    "viewDetailsInfo": "توجه: اگر مزایای اشتراک ظرف 7 روز پس از انقضا تمدید شود، می‌توان از مزایای استفاده نشده استفاده کرد. همچنین زمان انقضا به صورت خودکار به زمان انقضای اشتراک جدید به روز می شود. اگر پس از گذشت 7 روز از زمان انقضا، اشتراک جدیدی وجود نداشته باشد، تمام مزایای منقضی شده پاک می شود.",
    "connect_account": "ایمیل را به حساب خود متصل کنید",
    "connect_account_info": "پس از صحافی، می توانید با این آدرس ایمیل وارد شوید.",
    "connect_now": "اکنون ببندید",
    "no_email_bind": "بدون اتصال ایمیل",
    "bind_email": "اتصال ایمیل",
    "connect_your_email_placeholder": "لطفا آدرس ایمیل خود را وارد کنید",
    "bind_an_email": "پیوند یک ایمیل",
    "bind_info": "شما با موفقیت وارد سیستم شدید. لطفاً یک ایمیل به حساب خود ببندید تا مزایای خود را فعال کنید.",
    "bind_later": "بعداً ببندید",
    "hi": "خود, %s!",
    "Personal_Information": "اطلاعات شخصی",
    "Access": "دسترسی داشته باشید",
    "Subscription_Plan": "(طرح اشتراک)",
    "No_orders": "هیچ سفارشی یافت نشد",
    "No_data": "اطلاعاتی وجود ندارد",
    "Featured_Products": "محصولات ویژه",
    "More_Products": "محصولات بیشتر",
    "Free_Download": "دانلود رایگان",
    "Get_Started": "شروع کنید",
    "Subscribe": "اشتراک در",
    "Verified": "تایید شده است",
    "back_to_account_center": "بازگشت به مرکز حساب",
    "success": "موفقیت!",
    "successfully": "شما با موفقیت یک حساب کاربری ثبت کرده اید.",
    "Continue": "ادامه هید",
    "Already": "از قبل حساب کاربری دارید؟",
    "loading_verification": "بررسی وضعیت تأیید ...",
    "email_no_verification": "با عرض پوزش، آدرس ایمیل ثبت شده تایید نشده است. لطفاً تأیید را طبق دستورالعمل های بالا تکمیل کنید و مجدداً روی دکمه «انجام شد» کلیک کنید تا ثبت نام تکمیل شود.",
    "will_expire_after": "پس از آن منقضی می شود",
    "hours": "ساعت ها",
  },
  "fi": {
    "overtime": "Virhekoodi: {%}, yritä uudelleen",
    "isnetwork": "Internet-virhe. Tarkista ja yritä uudelleen",
    "email_placeholder": "Sähköposti",
    "email_empty": "Syötä sähköpostiosoite",
    "email_not_valid": "Sähköposti ei kelpaa",
    "email_not_valid_1": "Ole hyvä ja kirjoita sähköpostiosoitteesi",
    "email_not_valid_2": "Sähköposti ei kelpaa, käytä toista osoitetta.",
    "email_not_valid_3": "Ei sähköpostin syöttöä",
    "password_placeholder": "Salasana",
    "password_empty": "Syötä salasana",
    "password_not_valid": "Väärä tili tai salasana",
    "password_not_valid_1": "Yli 8 merkkiä pitkä salasana vaaditaan",
    "password_not_valid_2": "Luo salasana",
    "password_placeholder_1": "Luo salasanasi",
    "password_placeholder_2": "Vahvista salasana",
    "password_placeholder_3": "Luo uusi salasana",
    "password_placeholder_4": "Vahvista uusi salasana",
    "password_placeholder_5": "Syötä vanha salasana",
    "copy_password_empty": "Vahvista salasana",
    "copy_password_not_valid": "Vahvista salasanasi",
    "copy_passwords_inconsistent": "Salasanasi vahvistus ei täsmää",
    "code_empty": "Syötä vahvistuskoodi",
    "code_not_valid": "Virheellinen vahvistuskoodi",
    "code_placeholder": "Vahvistuskoodi",
    "not_received_code": "Jos postilaatikkoosi ei ole saapunut vahvistuskoodia pitkään aikaan, pyydä vahvistuskoodi uudelleen.",
    "get_first_code": "Hanki ensin vahvistuskoodi.",
    "last_name_placeholder": "Anna sukunimesi",
    "first_name_placeholder": "Kirjoita etunimesi, ole hyvä",
    "address_placeholder": "Anna osoitteesi",
    "no_code_text": "Olemme lähettäneet vahvistuskoodin. Anna koodisi. <span class='tips'>Etkö saanut koodia?",
    "no_code_text_1": "1. Varmista, että sähköpostiosoite on kelvollinen ja että se voi vastaanottaa sähköposteja.",
    "no_code_text_2": "2. Jos järjestelmä lähettää sähköpostin automaattisesti, se voidaan merkitä roskapostiksi tai roskapostiksi. Tarkista, onko sähköposti Roskakori-kansiossa.",
    "no_code_text_3": "3. Etkö pysty ratkaisemaan ongelmaasi? ",
    "no_code_text_3_span": "Ota sitten meihin yhteyttä napsauttamalla tätä.",
    "order_no": "Et ole ostanut yhtään tuotetta.Jos sinulla on kysyttävää, <a href=\"https://www.mindonmap.com/contact-us/\">ota meihin yhteyttä</a>.",
    "error_24901": "Nykyiseen tiliin ei ole linkitetty sähköpostia, eikä tilauksia löydy. Liitä sähköposti.",
    "user_guide": "Käyttöopas >>",
    "download": "ladata",
    "order_number": "Tilausnumero:",
    "Refund": "Maksun palautus",
    "Disabled": "Liikuntarajoitteinen",
    "Normal": "Normaali",
    "Modify": "Muuttaa",
    "Modify_1": "Muokkaa >>",
    "Connect": "Kytkeä",
    "unlink_success": "Linkin poistaminen onnistui",
    "connect_success": "Yhdistäminen onnistui",
    "feedback_title": "Kiitos palautteestasi. Jätä ongelmasi, niin vastaamme sinulle 24 tunnin kuluessa.",
    "feedback_thank_you": "Kiitos!<br />Palautteesi lähetys onnistui.",
    "feedback_email": "Kirjoita sähköpostiosoitteesi tähän!",
    "feedback_content": "Jätä kohtaamasi ongelmat tai ehdotukset tähän.",
    "feedback_submit": "Lähetä",
    "form_contents": "Et ole antanut kuvausta. Kirjoita se ja lähetä uudelleen.",
    "old_password": "Syötä vanha salasana",
    "new_password": "Luo uusi salasana",
    "old_new_password": "Uusi salasana ei voi olla sama kuin vanha",
    "incorrect_password": "Väärä salasana",
    "delete_no": "Poista nyt",
    "Caps": "CAPS LOCK on päällä",
    "Get": "Saada",
    "Done": "Tehty",
    "error_20001": "Virhe: 20001. Kirjaudu uudelleen.",
    "error_20002": "Virhe: 20002. Kirjaudu uudelleen.",
    "error_20003": "Virhe: 20003. Kirjaudu uudelleen.",
    "error_20004": "Pyyntö epäonnistui (virhekoodi: 20004). Yritä uudelleen.",
    "error_20005": "Kirjautumisistunto on vanhentunut (Virhe: 20005). Kirjaudu uudelleen sisään.",
    "error_20006": "Pyyntö epäonnistui (virhekoodi: 20006). Yritä uudelleen.",
    "error_20007": "Kirjautumisistunto on vanhentunut (Virhe: 20007). Kirjaudu uudelleen sisään.",
    "error_20008": "Kirjautumisistunto on vanhentunut (Virhe: 20008). Kirjaudu uudelleen sisään.",
    "error_20009": "Kirjautumisistunto on vanhentunut (Virhe: 20009). Kirjaudu uudelleen sisään.",
    "error_20101": "Syötä sähköpostiosoitteesi (virhekoodi: 20101)",
    "error_20102": "Sähköpostiosoite ei kelpaa (virhekoodi: 20102)",
    "error_20103": "Pyyntö epäonnistui (virhekoodi: 20103). Yritä uudelleen",
    "error_20104": "Sähköposti on jo käytössä, <a href=\"https://account.mindonmap.com/login/\">kirjaudu sisään</a> tai rekisteröidy uudella.",
    "error_20105": "Pyyntö epäonnistui (virhekoodi: 20105). Yritä uudelleen",
    "error_20106": "Sähköpostin lähetys epäonnistui, yritä uudelleen",
    "error_20201": "Syötä sähköpostiosoitteesi (virhekoodi: 20201)",
    "error_20202": "Syötä salasanasi (virhekoodi: 20202)",
    "error_20203": "Syötä vahvistuskoodi (virhekoodi: 20203)",
    "error_20204": "Sähköpostiosoite ei kelpaa (virhekoodi: 20204)",
    "error_20205": "Yli 8 merkkiä pitkä salasana vaaditaan (virhekoodi: 20205)",
    "error_20206": "Pyyntö epäonnistui (virhekoodi: 20206). Yritä uudelleen",
    "error_20207": "Virheellinen vahvistuskoodi",
    "error_20208": "Pyyntö epäonnistui (virhekoodi: 20208). Yritä uudelleen",
    "error_20209": "Pyyntö epäonnistui (virhekoodi: 20209). Yritä uudelleen",
    "error_20301": "Syötä sähköpostiosoitteesi (virhekoodi: 20301)",
    "error_20302": "Virhe: 20302. Ota yhteyttä",
    "error_20303": "Sähköpostiosoite ei kelpaa (virhekoodi: 20303)",
    "error_20304": "Pyyntö epäonnistui (virhekoodi: 20304). Yritä uudelleen",
    "error_20305": "Tiliä ei ole olemassa. Anna uudelleen tai <a href=\"https://account.mindonmap.com/register/\">luo se</a> ensin.",
    "error_20306": "Ei salasanaa vielä. Käytä <a href=\"https://account.mindonmap.com/passwordless-login/\">salasanatonta kirjautumista</a> tai <a href=\"https://account.mindonmap.com/create-password/\"> aseta salasana</a> ja kirjaudu sisään.",
    "error_20308": "Pyyntö epäonnistui (virhekoodi: 20308). Yritä uudelleen",
    "error_20401": "Uloskirjautuminen epäonnistui (virhekoodi: 20401). Yritä uudelleen",
    "error_20501": "Syötä sähköpostiosoitteesi (virhekoodi: 20501)",
    "error_20502": "Sähköpostiosoite ei kelpaa (virhekoodi: 20502)",
    "error_20503": "Pyyntö epäonnistui (virhekoodi: 20503). Yritä uudelleen",
    "error_20504": "Sähköpostin lähetys epäonnistui. Yritä uudelleen.",
    "error_20601": "Syötä sähköpostiosoitteesi (virhekoodi: 20601)",
    "error_20602": "Syötä vahvistuskoodi (virhekoodi: 20602)",
    "error_20603": "Sähköpostiosoite ei kelpaa (virhekoodi: 20603)",
    "error_20604": "Pyyntö epäonnistui (virhekoodi: 20604). Yritä uudelleen",
    "error_20606": "Pyyntö epäonnistui (virhekoodi: 20606). Yritä uudelleen",
    "error_20607": "Pyyntö epäonnistui (virhekoodi: 20607). Yritä uudelleen",
    "error_20608": "Pyyntö epäonnistui (virhekoodi: 20608). Yritä uudelleen",
    "error_20701": "Syötä sähköpostiosoitteesi (virhekoodi: 20701)",
    "error_20702": "Sähköpostiosoite ei kelpaa (virhekoodi: 20702)",
    "error_20703": "Pyyntö epäonnistui (virhekoodi: 20703). Yritä uudelleen",
    "error_20704": "Tiliä ei ole olemassa. Anna uudelleen tai <a href=\"https://account.mindonmap.com/register/\">luo se</a> ensin.",
    "error_20705": "Pyyntö epäonnistui (virhekoodi: 20705). Yritä uudelleen",
    "error_20706": "Sähköpostin lähetys epäonnistui. Yritä uudelleen",
    "error_20801": "Syötä sähköpostiosoitteesi (virhekoodi: 20801)",
    "error_20802": "Virhe: 20802. Ota yhteyttä",
    "error_20803": "Syötä vahvistuskoodi (virhekoodi: 20803)",
    "error_20804": "Sähköpostiosoite ei kelpaa (virhekoodi: 20804)",
    "error_20805": "Yli 8 merkkiä pitkä salasana vaaditaan (virhekoodi: 20805)",
    "error_20806": "Pyyntö epäonnistui (virhekoodi: 20806). Yritä uudelleen",
    "error_20808": "Pyyntö epäonnistui (virhekoodi: 20808). Yritä uudelleen",
    "error_20901": "Pyyntö epäonnistui (virhekoodi: 20901). Yritä uudelleen",
    "error_20902": "Pyyntö epäonnistui (virhekoodi: 20902). Yritä uudelleen",
    "error_21000": "Muutokset tallennetaan",
    "error_21001": "Tietoja ei ole lähetetty (virhekoodi: 21001)",
    "error_21002": "Pyyntö epäonnistui (virhekoodi: 21002). Yritä uudelleen",
    "error_21101": "Syötä sähköpostiosoitteesi (virhekoodi: 21101)",
    "error_21102": "Sähköpostiosoite ei kelpaa (virhekoodi: 21102)",
    "error_21103": "Pyyntö epäonnistui (virhekoodi: 21103), yritä uudelleen",
    "error_21104": "Sähköposti on jo yhdistetty, käytä uutta",
    "error_21105": "Pyyntö epäonnistui (virhekoodi: 21105), yritä uudelleen",
    "error_21106": "Sähköpostin lähetys epäonnistui. Yritä uudelleen",
    "error_21201": "Syötä sähköpostiosoitteesi (virhekoodi: 21201)",
    "error_21202": "Syötä vahvistuskoodi (virhekoodi: 21202)",
    "error_21203": "Sähköposti ei kelpaa (virhekoodi: 21203)",
    "error_21204": "Virhe: 21204. Ota yhteyttä",
    "error_21205": "Virhe: 21205. Ota yhteyttä",
    "error_21206": "Yli 8 merkkiä pitkä salasana vaaditaan (virhekoodi: 21206)",
    "error_21207": "Pyyntö epäonnistui (virhekoodi: 21207). Yritä uudelleen",
    "error_21209": "Pyyntö epäonnistui (virhekoodi: 21209). Yritä uudelleen",
    "error_21301": "Syötä vanha salasana (virhekoodi: 21301)",
    "error_21302": "Luo uusi salasana (virhekoodi: 21302)",
    "error_21303": "Uusi salasana ei voi olla sama kuin vanha. (Virhe: 21303)",
    "error_21304": "Yli 8 merkkiä pitkä salasana vaaditaan (virhekoodi: 21304)",
    "error_21306": "Pyyntö epäonnistui (virhekoodi: 21306). Yritä uudelleen",
    "error_21402": "Pyyntö epäonnistui (virhekoodi: 21402). Yritä uudelleen",
    "error_21403": "Vahvistuskoodin lähettäminen epäonnistui. Lähetä se uudelleen",
    "error_21500": "Tili on poistettu",
    "error_21501": "Syötä vahvistuskoodi (virhekoodi: 21501)",
    "error_21502": "Kirjautumisistunto on vanhentunut (Virhe: 21502). Kirjaudu uudelleen sisään.",
    "error_21503": "Pyyntö epäonnistui (virhekoodi: 21503). Yritä uudelleen",
    "error_21505": "Pyyntö epäonnistui (virhekoodi: 21505), yritä uudelleen",
    "error_21601": "Virhe: 20601. Ota yhteyttä",
    "error_21602": "Virheellinen vahvistus (Virhe: 20602). Yritä uudelleen.",
    "error_21603": "Virhe: 20603. Yritä uudelleen",
    "error_21604": "Pyyntö epäonnistui (virhekoodi: 21604). Yritä uudelleen",
    "error_21606": "Pyyntö epäonnistui (virhekoodi: 21606). Yritä uudelleen",
    "error_21611": "Pyyntö epäonnistui (virhekoodi: 21611). Yritä uudelleen",
    "error_21801": "Virhe: 21801. Ota yhteyttä",
    "error_21802": "Pyyntö epäonnistui (Virhe: 21802). Yritä uudelleen",
    "error_21803": "Virhe: 21803. Yritä uudelleen",
    "error_21804": "Pyyntö epäonnistui (virhekoodi: 21804). Yritä uudelleen",
    "error_21806": "Virhe: 21806. Yritä uudelleen",
    "error_21807": "Virhe: 21807. Ota yhteyttä",
    "error_21808": "Virhe: 21808. Ota yhteyttä",
    "error_21809": "Virhe: 21809. Ota yhteyttä",
    "error_21810": "Virhe: 21810. Ota yhteyttä",
    "error_21811": "Virhe: 21811. Ota yhteyttä",
    "error_21812": "Virhe: 21812. Ota yhteyttä",
    "error_21813": "Pyyntö epäonnistui (virhekoodi: 21813). Yritä uudelleen",
    "error_21814": "Virhe: 21814. Ota yhteyttä",
    "error_21815": "Pyyntö epäonnistui (virhekoodi: 21815). Yritä uudelleen",
    "error_21816": "Virhe: 21816. Ota yhteyttä",
    "error_21817": "Virhe: 21817. Ota yhteyttä",
    "error_21818": "Virhe: 21818. Ota yhteyttä",
    "error_21819": "Pyyntö epäonnistui (virhekoodi: 21819). Yritä uudelleen",
    "error_21820": "Virhe: 21820. Ota yhteyttä",
    "error_21821": "Virhe: 21821. Ota yhteyttä",
    "error_21822": "Virhe: 21822. Ota yhteyttä",
    "error_21823": "Pyyntö epäonnistui (virhekoodi: 21823). Yritä uudelleen",
    "error_21824": "Pyyntö epäonnistui (virhekoodi: 21824). Yritä uudelleen",
    "error_21825": "Pyyntö epäonnistui (virhekoodi: 21825). Yritä uudelleen",
    "error_21826": "Pyyntö epäonnistui (virhekoodi: 21826). Yritä uudelleen",
    "error_21901": "Virhe: 21901. Ota yhteyttä",
    "error_21902": "Pyyntö epäonnistui (virhekoodi: 21902). Yritä uudelleen",
    "error_21903": "Tilin tila on muuttunut (virhekoodi: 21903), päivitä sivu ja yritä uudelleen",
    "error_21904": "Virhe: 21904. Yritä uudelleen",
    "error_21905": "Virhe: 21905. Yritä uudelleen",
    "error_21906": "Pyyntö epäonnistui (virhekoodi: 21906). Yritä uudelleen",
    "error_21907": "Google-tili on linkitetty toiseen tiliin",
    "error_21908": "Pyyntö epäonnistui (virhekoodi: 21908). Yritä uudelleen",
    "error_22001": "Pyyntö epäonnistui (virhekoodi: 22001). Yritä uudelleen",
    "error_22002": "Linkin poistaminen epäonnistui ilman lisäkirjautumista",
    "error_22003": "Pyyntö epäonnistui (virhekoodi: 22003). Yritä uudelleen",
    "error_22101": "Virhe: 22101. Ota yhteyttä",
    "error_22102": "Tilin tila on muuttunut (virhekoodi: 22102), päivitä sivu ja yritä uudelleen",
    "error_22103": "Pyyntö epäonnistui (virhekoodi: 22103). Yritä uudelleen",
    "error_22104": "Tilin tila on muuttunut (virhekoodi: 22104), päivitä sivu ja yritä uudelleen",
    "error_22105": "Virhe: 22105. Yritä uudelleen",
    "error_22106": "Virhe: 22106. Yritä uudelleen",
    "error_22107": "Virhe: 22107. Ota yhteyttä",
    "error_22108": "Pyyntö epäonnistui (virhekoodi: 22108). Yritä uudelleen",
    "error_22201": "Virhe: 22201. Ota yhteyttä",
    "error_22202": "Virheellinen vahvistus (Virhe: 22202). Yritä uudelleen.",
    "error_22203": "Virhe: 22203. Yritä uudelleen\"",
    "error_22204": "Pyyntö epäonnistui (virhekoodi: 22204). Yritä uudelleen",
    "error_22206": "Pyyntö epäonnistui (virhekoodi: 22206). Yritä uudelleen",
    "error_22401": "Virhe: 22401. Ota yhteyttä",
    "error_22402": "Virheellinen vahvistus (Virhe: 22402). Yritä uudelleen.",
    "error_22403": "Virhe: 22403. Yritä uudelleen",
    "error_22404": "Pyyntö epäonnistui (virhekoodi: 22404). Yritä uudelleen",
    "error_22405": "Facebook-tili on linkitetty toiseen sähköpostiin",
    "error_22406": "Virhe: 22406. Yritä uudelleen",
    "error_22407": "Virhe: 22407. Ota yhteyttä",
    "error_22408": "Virhe: 22408. Ota yhteyttä",
    "error_22409": "Virhe: 22409. Ota yhteyttä",
    "error_22410": "Virhe: 224010. Ota yhteyttä",
    "error_22411": "Virhe: 224011. Ota yhteyttä",
    "error_22412": "Virhe: 224012. Ota yhteyttä",
    "error_22413": "Virhe: 22413. Ota yhteyttä",
    "error_22414": "Pyyntö epäonnistui (virhekoodi: 22414). Yritä uudelleen",
    "error_22415": "Virhe: 22415. Ota yhteyttä",
    "error_22416": "Virhe: 22416. Ota yhteyttä",
    "error_22417": "Virhe: 22417. Ota yhteyttä",
    "error_22418": "Pyyntö epäonnistui (virhekoodi: 22418). Yritä uudelleen",
    "error_22419": "Virhe: 22419. Ota yhteyttä",
    "error_22420": "Virhe: 22420. Ota yhteyttä",
    "error_22421": "Virhe: 22421. Ota yhteyttä",
    "error_22422": "Pyyntö epäonnistui (virhekoodi: 22422). Yritä uudelleen",
    "error_22423": "Pyyntö epäonnistui (virhekoodi: 22423). Yritä uudelleen",
    "error_22424": "Pyyntö epäonnistui (virhekoodi: 22424). Yritä uudelleen",
    "error_22425": "Pyyntö epäonnistui (virhekoodi: 22425). Yritä uudelleen",
    "error_20098": "Virhekoodi: 20098. Jos selaat yksityisessä tilassa, vaihda normaalitilaan ja yritä uudelleen.",
    "error_22298": "Pyyntö Googlelle epäonnistui (Virhe: 22298). Yritä uudelleen.",
    "error_22498": "Facebook-pyyntö epäonnistui (Virhe: 22498). Yritä uudelleen.",
    "error_24902": "Pyyntö epäonnistui (virhekoodi: 24902). Yritä uudelleen",
    "error_24903": "Pyyntö epäonnistui (virhekoodi: 24903). Yritä uudelleen",
    "error_24904": "Pyyntö epäonnistui (virhekoodi: 24904). Yritä uudelleen",
    "error_24905": "Pyyntö epäonnistui (virhekoodi: 24905). Yritä uudelleen",
    "login_title": "Kirjaudu MindOnMapiin",
    "log_in": "Kirjaudu sisään",
    "no_account": "Ei tiliä?",
    "create_it": "Luo se",
    "or_log_in_with": "Tai kirjaudu sisään",
    "passwordless_login": "Salasanaton kirjautuminen",
    "log_in_done": "Kirjaudu sisään Valmis",
    "three_rd_account_connect_info": "Onnittelut! Olet kirjautunut sisään onnistuneesti. Nyt voit yhdistää yhden sähköpostitilin, jota käytetään kirjautumiseen tulevaisuudessa.",
    "see_my_account": "Katso tilini",
    "three_rd_login_merge_account": "Kolmannen osapuolen tilin sähköpostiosoite on rekisteröity. Haluatko muodostaa yhteyden ja kirjautua sisään tällä sähköpostiosoitteella suoraan?",
    "connect_log_in": "Yhdistä ja kirjaudu sisään",
    "create_an_account": "Luo tili",
    "back_to_log_in": "Takaisin sisäänkirjautumiseen",
    "create_password": "Luo salasana",
    "create_now": "Luo nyt",
    "password_login_subtitle": "Salasanaton kirjautuminen sähköpostilla",
    "account_login": "Tilille kirjautuminen",
    "rights": "Luomalla tämän tilin hyväksyt <a href=\"https://www.mindonmap.com/terms-and-conditions/\">käyttöehdot</a> ja <a href=\"https://www.mindonmap.com/privacy-policy/\">Tietosuojakäytäntö</a>",
    "passwordless_login_done": "Salasanaton kirjautuminen tehty",
    "passwordless_login_info": "Onnittelut, olet suorittanut salasanattoman kirjautumisen onnistuneesti. Voit luoda salasanan tälle tilille ja kirjautua sisään tällä tilillä ja salasanalla tulevaisuudessa. <a href=\"/create-password\" style=\"display: initial;\">Luo nyt</a>",
    "sign_up": "Kirjaudu",
    "register_info": "luo tilisi",
    "reset_now": "Palauta nyt",
    "forgot_password": "Unohtuiko salasana",
    "reset_password_subtitle": "Käytä tilisi sähköpostiosoitetta salasanan vaihtamiseen",
    "plan_products": "Suunnitelmat & Tuotteet",
    "nick_name": "Käyttäjätunnus:",
    "email": "Sähköposti:",
    "my_products": "Omat tuotteet",
    "my_orders": "Tilaukseni",
    "unlink": "Poista linkitys",
    "link": "Linkki",
    "connected_accounts": "Yhdistetyt tilit",
    "last_name": "Sukunimi:",
    "first_name": "Etunimi:",
    "Gender": "Sukupuoli:",
    "Birth": "Syntymä:",
    "Month": "Kuukausi",
    "Year": "vuosi",
    "Country_Region": "Maa/alue:",
    "Address": "Osoite:",
    "Save": "Tallentaa",
    "Date": "Päivämäärä",
    "Male": "Uros",
    "Female": "Nainen",
    "Unspecified": "Määrittelemätön",
    "Security": "Turvallisuus",
    "change_password": "Vaihda salasana",
    "change_now": "Vaihda nyt",
    "connect_email": "Yhdistä sähköposti",
    "delete_account": "Poista tili",
    "delete_account_info": "Kun tilisi poistetaan, kaikki tiliisi liittyvät Oma tili -sivun tiedot poistetaan pysyvästi, etkä ehkä voi palauttaa niitä. Suosittelemme, että käytät varovaisuutta.",
    "Delete": "Poistaa",
    "Logout": "Kirjautua ulos",
    "my_profile": "Profiilini",
    "guides_faqs": "Oppaat & UKK",
    "More": "Lisää",
    "guides": "Oppaat",
    "register": "rekisteröidy",
    "hot_faq": "Kuumat UKK",
    "Contents": "Sisällys:",
    "contact_us": "Ota yhteyttä >>",
    "plan": "Suunnitelma",
    "unregistered": "Rekisteröimätön",
    "buy_more": "Osta lisää",
    "buy_again": "Osta uudelleen",
    "buy_now": "Osta nyt",
    "free_no_limit": "Ilmainen & Ei rajoituksia",
    "expired": "Vanhentunut",
    "lifetime": "Elinikä",
    "remain": "Jäädä jäljelle",
    "day_s": "päivä(t)",
    "error_24801": "Pyyntö epäonnistui (virhekoodi: 24801). Yritä uudelleen",
    "no_app_found": "Sovellusta ei löydy!<a href=\"/\">Päivitä</a> tai <a href=\"https://www.mindonmap.com/\">siirry viralliselle verkkosivustolle</a>",
    "get_more": "Hanki lisää >>",
    "edit_photo": "Muokkaa kuvaa",
    "select_photo": "Valitse valokuva",
    "change_photo": "Vaihda kuva",
    "cancel": "Peruuttaa",
    "hide_password": "Piilota salasana",
    "show_password": "Näytä salasana",
    "zoom_in": "Lähennä",
    "zoom_out": "Loitontaa",
    "rotate": "Kiertää",
    "horizontal_flip": "Vaakasuora läppä",
    "vertical_flip": "Pystysuora kääntö",
    "country": "Maa",
    "country_1": "Valitse maasi/alueesi",
    "country_2": "Ahvenanmaan saari",
    "country_3": "Afganistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "Amerikan Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktis",
    "country_11": "Antigua ja Barbuda",
    "country_12": "Argentiina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Itävalta",
    "country_17": "Azerbaidžan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Valko-Venäjä",
    "country_22": "Belgia",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia ja Hertsegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet-saari",
    "country_31": "Brasilia",
    "country_32": "Brittiläinen Intian valtamerialue",
    "country_33": "Britannian Neitsytsaaret",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kap Verde",
    "country_42": "Karibian Alankomaat",
    "country_43": "Caymansaaret",
    "country_44": "Keski-Afrikan tasavalta",
    "country_45": "Tšad",
    "country_46": "Chile",
    "country_47": "Kiina",
    "country_48": "Joulusaari",
    "country_49": "Cocos (Keeling) saaret",
    "country_50": "Kolumbia",
    "country_51": "Cook saaret",
    "country_52": "Costa Rica",
    "country_53": "Norsunluurannikko",
    "country_54": "Kroatia",
    "country_55": "Kuuba",
    "country_56": "Kypros",
    "country_57": "Tšekin tasavalta",
    "country_58": "Kongon demokraattinen tasavalta",
    "country_59": "Tanska",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominikaaninen tasavalta",
    "country_63": "Ecuador",
    "country_64": "Egypti",
    "country_65": "El Salvador",
    "country_66": "Päiväntasaajan Guinea",
    "country_67": "Eritrea",
    "country_68": "Viro",
    "country_69": "Etiopia",
    "country_70": "Falkland saaret",
    "country_71": "Färsaaret",
    "country_72": "Mikronesian liittovaltiot",
    "country_73": "Fidži",
    "country_74": "Suomi",
    "country_75": "Ranska",
    "country_76": "Ranskan Guiana",
    "country_77": "Ranskan Polynesia",
    "country_78": "Ranskan eteläiset alueet",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Saksa",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Iso-Britannia (Yhdistynyt kuningaskunta; Englanti)",
    "country_86": "Kreikka",
    "country_87": "Grönlanti",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernseyssä",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Island ja McDonald Islands",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Unkari",
    "country_101": "Islanti",
    "country_102": "Intia",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irlanti",
    "country_107": "Mansaari",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Jamaika",
    "country_111": "Japani",
    "country_112": "Jersey",
    "country_113": "Jordania",
    "country_114": "Kazakstan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgisia",
    "country_119": "Laos",
    "country_120": "Latvia",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libya",
    "country_125": "Liechtenstein",
    "country_126": "Liettua",
    "country_127": "Luxemburg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malesia",
    "country_132": "Malediivit",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshallsaaret",
    "country_136": "Martinique",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Meksiko",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marokko",
    "country_147": "Mosambik",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Alankomaat",
    "country_153": "Uusi-Caledonia",
    "country_154": "Uusi Seelanti",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolkin saari",
    "country_160": "Pohjois-Korea",
    "country_161": "Pohjois-Mariana-saaret",
    "country_162": "Norja",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "palestiinalaisalueet",
    "country_167": "Panama",
    "country_168": "Papua-Uusi-Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn-saaret",
    "country_172": "Puola",
    "country_173": "Portugali",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Makedonian tasavalta (FYROM)",
    "country_177": "Kongon tasavalta",
    "country_178": "Réunion",
    "country_179": "Romania",
    "country_180": "Venäjän federaatio",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Ranska)",
    "country_184": "Saint-Pierre ja Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome ja Principe",
    "country_188": "Saudi-Arabia",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychellit",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovakia",
    "country_195": "Slovenia",
    "country_196": "Solomonsaaret",
    "country_197": "Somalia",
    "country_198": "Etelä-Afrikka",
    "country_199": "Etelä-Georgia ja Etelä-Sandwich Isla",
    "country_200": "Etelä-Korea",
    "country_201": "Eteläsudan",
    "country_202": "Espanja",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena ja dependencies",
    "country_205": "St. Kitts ja Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent ja Grenadiinit",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swazimaa",
    "country_211": "Ruotsi",
    "country_212": "Sveitsi",
    "country_213": "Syyria",
    "country_214": "Taiwan",
    "country_215": "Tadžikistan",
    "country_216": "Tansania",
    "country_217": "Malli: Maan tiedot SJM Svalbard",
    "country_218": "Thaimaa",
    "country_219": "Bahama-saaret",
    "country_220": "Komorit",
    "country_221": "Filippiinit",
    "country_222": "Itä-Timor (Itä-Timor)",
    "country_223": "Mennä",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad ja Tobago",
    "country_227": "Tunisia",
    "country_228": "Turkki",
    "country_229": "Turkmenistan",
    "country_230": "Turks- ja Caicossaaret",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Yhdistyneet Arabiemiirikunnat",
    "country_235": "Yhdysvaltain pienet syrjäiset saaret",
    "country_236": "Amerikan yhdysvallat (USA)",
    "country_237": "Yhdysvaltain Neitsytsaaret",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikaani (Pyhä istuin)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis ja Futuna",
    "country_245": "Länsi-Sahara",
    "country_246": "Jemen",
    "country_247": "Sambia",
    "country_248": "Zimbabwe",
    "google_login": "Kirjaudu sisään Googlella",
    "State": "Osavaltio",
    "Activation_code": "Aktivointikoodi",
    "Question": "Luettele kaikki sovellukset, jotka olet kirjautunut sisään",
    "Copy_complete": "Kopiointi valmis",
    "footer": "Tekijänoikeus © 2024 MindOnMap Studio. Kaikki oikeudet pidätetään.",
    "change_password_success": "salasana vaihdettu onnistuneesti",
    "successful_login_title": "Kirjautuminen onnistui",
    "product_page": "Tuotesivu >>",
    "successful_login_info": "Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia. Nykyinen sivu sulkeutuu automaattisesti 5 sekunnin kuluttua. Jos automaattinen sulkeminen tai sulkeminen napsauttamalla \"Valmis\"-painiketta epäonnistuu, sulje tämä välilehti suoraan.",
    "successful_login_info_firefox": "Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia.",
    "my_account": "Tilini",
    "my_history": "Minun historiani",
    "remove_watermark": "Poista vesileima",
    "no_history": "Ei historiaa",
    "history_all": "Valitse kaikki",
    "history_open": "Avata",
    "history_down": "ladata",
    "history_delete": "Poistaa",
    "history_clear": "Tyhjennä virheellinen",
    "images": "kuva(t)",
    "use_this_function": "Käytä tätä tuotetta >>",
    "hd_downloading": "Alkuperäisten HD-kuvien lataamisen edut:",
    "lifetimeRemaining": "Elinikäinen jäljellä",
    "Remaining": "Jäljelle jäänyt",
    "email_verification": "sähköpostivarmistus",
    "email_verification_info": "Olemme lähettäneet vahvistusviestin sähköpostiisi <span class=\"email\"></span> ja viimeistele vahvistus. Vahvistuksen jälkeen edut synkronoidaan automaattisesti.",
    "wrong_email": "Väärä sähköpostiosoite?",
    "click_here_to_modify": "Napsauta tästä muokataksesi",
    "get_more_help": "Saatko lisää apua?",
    "click_here": "Klikkaa tästä",
    "email_verification_info_success": "Onnittelut sähköpostitilisi vahvistamisesta.",
    "email_verification_info_error": "Vahvistus epäonnistui vanhentuneen linkin vuoksi.",
    "registration_succeeded": "Ilmoittautuminen Onnistui",
    "registration_succeeded_info_1": "Onnittelut! Olet rekisteröitynyt onnistuneesti. Olemme lähettäneet vahvistusviestin sähköpostiisi <span class=\"email\"></span> ja viimeistele vahvistus, jotta voit hyödyntää tämän sähköpostin edut.",
    "registration_succeeded_info_2": "Napsauta \"Valmis\" palataksesi etusivulle ja käytät tätä tuotetta.",
    "registration_succeeded_info_3": "Napsauta \"Valmis\" sulkeaksesi nykyisen sivun ja palataksesi aloitussivulle seuraavien toimintojen suorittamiseksi. Jos sulkeminen epäonnistuu, sulje välilehti manuaalisesti.",
    "verify_email": "vahvista sähköposti",
    "registered_email_not_verified": "Rekisteröityä sähköpostiosoitetta ei ole vahvistettu, vahvista se heti.",
    "email_verification_time_1": "Etkö saanut vahvistussähköpostia?",
    "email_verification_time_2": "Jälkeen",
    "email_verification_time_3": "Napsauta tätä lähettääksesi sen uudelleen",
    "error_26301": "Virhekoodi: 26301, ota yhteyttä",
    "error_26302": "Virhekoodi: 26302, ota yhteyttä",
    "error_26303": "Sähköpostin muotovirhe (virhekoodi: 26303). Anna se uudelleen",
    "error_26304": "Yli 8 merkkiä pitkä salasana on suositeltavaa (virhekoodi: 26304)",
    "error_26305": "Reuquest epäonnistui (virhekoodi: 26305). Yritä uudelleen",
    "error_26306": "Sähköposti on rekisteröity, <a href=\"https://account.mindonmap.com/login/\">kirjaudu sisään</a>",
    "error_26307": "Reuquest epäonnistui (virhekoodi: 26307). Yritä uudelleen",
    "error_26308": "Reuquest epäonnistui (virhekoodi: 26308). Yritä uudelleen",
    "error_26401": "Virhekoodi: 26401, yritä uudelleen",
    "error_26402": "Sähköposti on vahvistettu (virhekoodi: 26402), yritä uudelleen",
    "error_26403": "Reuquest epäonnistui (virhekoodi: 26403). Yritä uudelleen",
    "error_26404": "Pyyntö epäonnistui (virhekoodi: 26404). Yritä uudelleen",
    "error_26501": "Virhekoodi: 26501, ota yhteyttä",
    "error_26502": "Virhekoodi: 26502, ota yhteyttä",
    "error_26503": "Sähköpostin muotovirhe (virhekoodi: 26503). Anna se uudelleen",
    "error_26504": "Reuquest epäonnistui (virhekoodi: 26504). Yritä uudelleen",
    "error_26505": "Sähköpostiosoitetta ei ole rekisteröity, <a href=\"https://account.mindonmap.com/register/\">rekisteröi se ensin</a>",
    "error_26506": "Sähköposti on vahvistettu.",
    "error_26507": "Reuquest epäonnistui (virhekoodi: 26507). Yritä uudelleen",
    "error_26508": "Vahvistus lähetetty (virhekoodi: 26508), yritä uudelleen",
    "error_26509": "Pyyntö epäonnistui (virhekoodi: 26509), yritä uudelleen",
    "error_26510": "Virhekoodi: 26510, ota yhteyttä",
    "error_26601": "Virhekoodi: 26601, ota yhteyttä",
    "error_26602": "Virhekoodi: 26602, ota yhteyttä",
    "error_26603": "Reuquest epäonnistui (virhekoodi: 26603). Yritä uudelleen",
    "error_26604": "Virhekoodi: 26604, ota yhteyttä",
    "error_26605": "Virhekoodi: 26605, ota yhteyttä",
    "error_26701": "Virhekoodi: 26701, ota yhteyttä",
    "error_26702": "Reuquest epäonnistui (virhekoodi: 26702). Yritä uudelleen",
    "error_26703": "Virhekoodi: 26703, ota yhteyttä",
    "error_26704": "Virhekoodi: 26704, ota yhteyttä",
    "error_26705": "Odota kirjautumista (virhekoodi: 26705). Yritä uudelleen",
    "no_cookie": "Olet ottanut käyttöön Estä kaikki evästeet -toiminnon selaimessasi, joten et voi kirjautua sisään. Mene Asetuksiin ja valitse Salli kaikki evästeet -valintaruutu.",
    "error_26801": "Virhekoodi: 26801, ota yhteyttä",
    "error_26802": "Virhekoodi: 26802, ota yhteyttä",
    "error_26803": "Pyyntö epäonnistui (virhekoodi: 26803). Yritä uudelleen",
    "error_26804": "Pyyntö epäonnistui (virhekoodi: 26804). Yritä uudelleen",
    "error_order": "Pyyntö epäonnistui (virhekoodi: 27098), yritä uudelleen!",
    "error_order1": "Tilauskysely on epätäydellinen (virhekoodi: ",
    "error_order2": "）, päivitä ja yritä uudelleen.",
    "modify_email_title": "Muokkaa sähköpostia",
    "modify_email_info": "Voit käyttää muokattua sähköpostiosoitetta kirjautuaksesi tilillesi.",
    "images_per": "Kuvat per",
    "error_26101": "Virhe: 26101. Ota yhteyttä",
    "error_26102": "Virhe: 26102. Ota yhteyttä",
    "error_26103": "Pyyntö epäonnistui (virhekoodi:26103). Yritä uudelleen",
    "error_26104": "Virhekoodi: 26104, yritä uudelleen",
    "error_26105": "Virhekoodi: 26105, yritä uudelleen",
    "error_26106": "Poistaminen epäonnistui (virhekoodi: 26106). Yritä uudelleen",
    "error_26201": "Virhe: 26201. Ota yhteyttä",
    "error_26202": "Pyyntö epäonnistui (virhekoodi:26202). Yritä uudelleen",
    "error_26001": "Virhe: 26001. Ota yhteyttä",
    "error_26002": "Virhe: 26002. Ota yhteyttä",
    "error_26003": "Virhe: 26003. Ota yhteyttä",
    "error_26004": "Virhe: 26004. Ota yhteyttä",
    "error_26005": "Pyyntö epäonnistui (virhekoodi:26005). Yritä uudelleen",
    "error_26006": "Virhekoodi: 26006, yritä uudelleen",
    "error_26008": "Virhe: 26008. Ota yhteyttä",
    "go_to_the_home_page": "Siirry etusivulle",
    "error_27101": "Pyyntö epäonnistui (virhekoodi: 27101). Yritä uudelleen ist",
    "error_27201": "Virhekoodi: 27201, ota yhteyttä",
    "error_27202": "Virhekoodi: 27202, yritä uudelleen",
    "error_27203": "Pyyntö epäonnistui (virhekoodi: 27203). Yritä uudelleen",
    "error_27204": "Virheellinen koodi (virhekoodi: 27204).",
    "error_27205": "Pyyntö epäonnistui (virhekoodi: 27205). Yritä uudelleen",
    "error_27206": "Pyyntö epäonnistui (virhekoodi: 27206). Yritä uudelleen",
    "error_27207": "Pyyntö epäonnistui (virhekoodi: 27207). Yritä uudelleen",
    "no_history_found": "Et ole käyttänyt mitään työkalua! <a href=\"/\">Päivitä</a> tai <a href=\"https://www.mindonmap.com/\">Siirry viralliselle verkkosivustolle</a>",
    "error_25301": "Virhe: 25301. Ota yhteyttä",
    "error_25302": "Virhe: 25302. Ota yhteyttä",
    "error_25303": "Pyyntö epäonnistui (virhekoodi: 25303). Yritä uudelleen",
    "error_25304": "Pyyntö epäonnistui (virhekoodi: 25304). Yritä uudelleen",
    "error_25305": "Pyyntö epäonnistui (virhekoodi: 25305). Yritä uudelleen",
    "error_25306": "Pyyntö epäonnistui (virhekoodi: 25306). Yritä uudelleen",
    "image_upscaler_p": "Kuva ilman vesileiman latausetuja:",
    "Available_for": "Saatavilla:",
    "credit_per": "%s saldoa HD-kuvaa kohden",
    "still_valid": "Ostetut suunnitelmat ovat edelleen voimassa",
    "credit": "luotto(t)",
    "pc_3rd_info": "Kirjautuminen onnistui. Siirry sovellukseen jatkotoimenpiteitä varten.",
    "use_online": "Käytä verkkopalvelua",
    "use_download": "Käytä työpöytäohjelmaa",
    "use_immediately": "Käytä Välittömästi",
    "Use_in_browser": "Käytä selaimessa",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} hyvitystä kuukaudessa",
    "expire": "Vanhenemisaika:",
    "viewDetails": "Tarkemmat tiedot",
    "viewHistory": "Katso historian edut >>",
    "viewDetailsInfo": "Huomautus: Jos tilauksen edut uusitaan 7 päivän kuluessa voimassaolon päättymisestä, käyttämättömät edut voidaan jatkaa käyttöä. Myös päättymisaika päivitetään automaattisesti uuden tilauksen päättymisaikaan. Jos uutta tilausta ei ole 7 päivän päättymisajan jälkeen, kaikki vanhentuneet edut tyhjennetään.",
    "connect_account": "Sido sähköposti tiliisi",
    "connect_account_info": "Sidonnan jälkeen voit kirjautua sisään tällä sähköpostiosoitteella.",
    "connect_now": "Sido nyt",
    "no_email_bind": "Ei sähköpostisidosta",
    "bind_email": "Sido sähköposti",
    "connect_your_email_placeholder": "Anna sähköpostiosoitteesi",
    "bind_an_email": "Sido sähköposti",
    "bind_info": "Olet kirjautunut sisään onnistuneesti. Aktivoi edut sitomalla sähköposti tiliisi.",
    "bind_later": "Sido myöhemmin",
    "hi": "Hei %s!",
    "Personal_Information": "Henkilökohtaisia tietoja",
    "Access": "Pääsy",
    "Subscription_Plan": "(tilaussopimus)",
    "No_orders": "Tilauksia ei löytynyt.",
    "No_data": "Ei dataa",
    "Featured_Products": "Esittelyssä olevat tuotteet",
    "More_Products": "Lisää tuotteita",
    "Free_Download": "Ilmainen lataus",
    "Get_Started": "Aloittaa",
    "Subscribe": "Tilaa",
    "Verified": "Vahvistettu",
    "back_to_account_center": "Takaisin tilikeskukseen",
    "success": "Menestys!",
    "successfully": "Olet onnistuneesti rekisteröinyt tilin.",
    "Continue": "Jatkaa",
    "Already": "Onko sinulla jo tili?",
    "loading_verification": "Tarkistetaan vahvistuksen tilaa...",
    "email_no_verification": "Valitettavasti rekisteröityä sähköpostiosoitetta ei ole vahvistettu. Suorita vahvistus yllä olevien ohjeiden mukaisesti ja napsauta \"Valmis\"-painiketta uudelleen suorittaaksesi rekisteröinnin.",
    "will_expire_after": "Vanhenee jälkeen",
    "hours": "tuntia",
  },
  "fr": {
    "overtime": "Code d'erreur : {%}, veuillez réessayer",
    "isnetwork": "Erreur Internet. Veuillez vérifier et réessayer",
    "email_placeholder": "E-mail",
    "email_empty": "Veuillez saisir l'e-mail",
    "email_not_valid": "L'e-mail n'est pas valide",
    "email_not_valid_1": "Veuillez saisir votre e-mail",
    "email_not_valid_2": "L'e-mail n'est pas valide, veuillez utiliser une autre adresse.",
    "email_not_valid_3": "Pas de saisie d'e-mail",
    "password_placeholder": "Mot de passe",
    "password_empty": "Veuillez saisir le mot de passe",
    "password_not_valid": "Compte ou mot de passe incorrect",
    "password_not_valid_1": "Un mot de passe supérieur à 8 caractères est requis",
    "password_not_valid_2": "Veuillez créer un mot de passe",
    "password_placeholder_1": "Créez votre mot de passe",
    "password_placeholder_2": "Confirmez votre mot de passe",
    "password_placeholder_3": "Créer un nouveau mot de passe",
    "password_placeholder_4": "Confirmer le nouveau mot de passe",
    "password_placeholder_5": "Saisir l'ancien mot de passe",
    "copy_password_empty": "Veuillez confirmer le mot de passe",
    "copy_password_not_valid": "Veuillez confirmer votre mot de passe",
    "copy_passwords_inconsistent": "Votre confirmation de mot de passe ne correspond pas",
    "code_empty": "Veuillez saisir le code de vérification",
    "code_not_valid": "Code de vérification invalide",
    "code_placeholder": "Code de Vérification",
    "not_received_code": "Si votre boîte aux lettres n'a pas reçu le code de vérification depuis longtemps, veuillez obtenir à nouveau le code de vérification.",
    "get_first_code": "Veuillez d'abord obtenir le code de vérification.",
    "last_name_placeholder": "Veuillez entrer votre nom de famille",
    "first_name_placeholder": "Veuillez entrer votre prénom",
    "address_placeholder": "Veuillez entrer votre adresse",
    "no_code_text": "Nous avons envoyé un code de vérification. Veuillez saisir votre code. <span class='tips'>Vous n'avez pas reçu de code ?",
    "no_code_text_1": "1.Veuillez vous assurer que l'adresse e-mail est valide et qu'elle peut recevoir des e-mails.",
    "no_code_text_2": "2.Pour que l'e-mail soit envoyé automatiquement par le système, il peut être signalé comme spam ou courrier indésirable. Veuillez vérifier si l'e-mail se trouve dans le dossier indésirable.",
    "no_code_text_3": "3.Impossible résoudre votre problème ?",
    "no_code_text_3_span": "Alors cliquez ici pour nous contacter.",
    "order_no": "Vous n'avez acheté aucun produit. Pour toute question, veuillez <a href=\"https://www.mindonmap.com/contact-us/\"> nous contacter</a>",
    "error_24901": "Le compte actuel n'a pas d'adresse e-mail liée et ne trouve pas les commandes. Veuillez ajouter un e-mail.",
    "user_guide": "Guide de l'utilisateur>>",
    "download": "Télécharger",
    "order_number": "Numéro de commande :",
    "Refund": "Remboursement",
    "Disabled": "Désactivée",
    "Normal": "Normal",
    "Modify": "Modifier",
    "Modify_1": "Modifier>>",
    "Connect": "Relier",
    "unlink_success": "Dissocier avec succès",
    "connect_success": "Connectez-vous avec succès",
    "feedback_title": "Merci pour vos commentaires. Veuillez laisser votre problème et nous vous répondrons dans les 24 heures.",
    "feedback_thank_you": "Merci !<br />Vos commentaires ont été soumis avec succès.",
    "feedback_email": "Entrez votre e-mail ici !",
    "feedback_content": "Laissez ici des problèmes ou suggestions que vous avez rencontrés.",
    "feedback_submit": "Soumettre",
    "form_contents": "Vous n'avez entré aucune description. Veuillez le saisir et soumettre à nouveau.",
    "old_password": "Veuillez saisir l'ancien mot de passe",
    "new_password": "Veuillez créer un nouveau mot de passe",
    "old_new_password": "Le nouveau mot de passe ne peut pas être le même que l'ancien",
    "incorrect_password": "Mot de passe incorrect",
    "delete_no": "Effacer Maintenant",
    "Caps": "Touche Maj activée",
    "Get": "Obtenir",
    "Done": "Terminé",
    "error_20001": "Erreur : 20001. Veuillez vous reconnecter.",
    "error_20002": "Erreur : 20002. Veuillez vous reconnecter.",
    "error_20003": "Erreur : 20003. Veuillez vous reconnecter.",
    "error_20004": "Échec de la demande (code d'erreur : 20004). Veuillez réessayer.",
    "error_20005": "La session de connexion a expiré (Erreur : 20005). Veuillez vous reconnecter.",
    "error_20006": "Échec de la demande (code d'erreur : 20006). Veuillez réessayer.",
    "error_20007": "La session de connexion a expiré (Erreur : 20007). Veuillez vous reconnecter.",
    "error_20008": "La session de connexion a expiré (Erreur : 20008). Veuillez vous reconnecter.",
    "error_20009": "La session de connexion a expiré (Erreur : 20009). Veuillez vous reconnecter.",
    "error_20101": "Veuillez saisir votre e-mail (code d'erreur : 20101)",
    "error_20102": "L'e-mail n'est pas valide (code d'erreur : 20102)",
    "error_20103": "Échec de la demande (code d'erreur : 20103). Veuillez réessayer",
    "error_20104": "L'e-mail est déjà utilisé, veuillez <a href=\"https://account.mindonmap.com/login/\">vous connecter</a> ou vous inscrire avec un nouveau",
    "error_20105": "Échec de la demande (code d'erreur : 20105). Veuillez réessayer",
    "error_20106": "Échec de l'envoi de l'e-mail, veuillez réessayer",
    "error_20201": "Veuillez saisir votre e-mail (code d'erreur : 20201)",
    "error_20202": "Veuillez saisir votre mot de passe (code d'erreur : 20202)",
    "error_20203": "Veuillez saisir le code de vérification (code d'erreur : 20203)",
    "error_20204": "L'e-mail n'est pas valide (code d'erreur : 20204)",
    "error_20205": "Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20205)",
    "error_20206": "Échec de la demande (code d'erreur : 20206). Veuillez réessayer",
    "error_20207": "Code de vérification invalide",
    "error_20208": "Échec de la demande (code d'erreur : 20208). Veuillez réessayer",
    "error_20209": "Échec de la demande (code d'erreur : 20209). Veuillez réessayer",
    "error_20301": "Veuillez saisir votre e-mail (code d'erreur : 20301)",
    "error_20302": "Erreur : 20302. Veuillez nous contacter",
    "error_20303": "L'e-mail n'est pas valide (code d'erreur : 20303)",
    "error_20304": "Échec de la demande (code d'erreur : 20304). Veuillez réessayer",
    "error_20305": "Le compte n'existe pas. Veuillez saisir à nouveau ou<a href=\"https://account.mindonmap.com/register/\">Créer un nouveau</a> d'abord.",
    "error_20306": "Pas encore de mot de passe. Utilisez la <a href=\"https://account.mindonmap.com/passwordless-login/\">Connexion sans mot de passe</a> ou <a href=\"https://account.mindonmap.com/create-password/\">définissez un mot de passe</a> et connectez-vous.",
    "error_20308": "Échec de la demande (code d'erreur : 20308). Veuillez réessayer",
    "error_20401": "Échec de la déconnexion (code d'erreur : 20401). Veuillez réessayer",
    "error_20501": "Veuillez saisir votre e-mail (code d'erreur : 20501)",
    "error_20502": "L'e-mail n'est pas valide (code d'erreur : 20502)",
    "error_20503": "Échec de la demande (code d'erreur : 20503). Veuillez réessayer",
    "error_20504": "Échec de l'envoi de l'e-mail. Veuillez réessayer.",
    "error_20601": "Veuillez saisir votre e-mail (code d'erreur : 20601)",
    "error_20602": "Veuillez saisir le code de vérification (code d'erreur : 20602)",
    "error_20603": "L'e-mail n'est pas valide (code d'erreur : 20603)",
    "error_20604": "Échec de la demande (code d'erreur : 20604). Veuillez réessayer",
    "error_20606": "Échec de la demande (code d'erreur : 20606). Veuillez réessayer",
    "error_20607": "Échec de la demande (code d'erreur : 20607). Veuillez réessayer",
    "error_20608": "Échec de la demande (code d'erreur : 20608). Veuillez réessayer",
    "error_20701": "Veuillez saisir votre e-mail (code d'erreur : 20701)",
    "error_20702": "L'e-mail n'est pas valide (code d'erreur : 20702)",
    "error_20703": "Échec de la demande (code d'erreur : 20703). Veuillez réessayer",
    "error_20704": "Le compte n'existe pas. Veuillez saisir à nouveau ou <a href=\"https://account.mindonmap.com/register/\">Créer un nouveau</a> d'abord.",
    "error_20705": "Échec de la demande (code d'erreur : 20705). Veuillez réessayer",
    "error_20706": "Échec de l'envoi de l'e-mail. Veuillez réessayer",
    "error_20801": "Veuillez saisir votre e-mail (code d'erreur : 20801)",
    "error_20802": "Erreur : 20802. Veuillez nous contacter",
    "error_20803": "Veuillez saisir le code de vérification (code d'erreur : 20803)",
    "error_20804": "L'e-mail n'est pas valide (code d'erreur : 20804)",
    "error_20805": "Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20805)",
    "error_20806": "Échec de la demande (code d'erreur : 20806). Veuillez réessayer",
    "error_20808": "Échec de la demande (code d'erreur : 20808). Veuillez réessayer",
    "error_20901": "Échec de la demande (code d'erreur : 20901). Veuillez réessayer",
    "error_20902": "Échec de la demande (code d'erreur : 20902). Veuillez réessayer",
    "error_21000": "Les modifications sont enregistrées",
    "error_21001": "Aucune information n'est soumise (code d'erreur : 21001)",
    "error_21002": "Échec de la demande (code d'erreur : 21002). Veuillez réessayer",
    "error_21101": "Veuillez saisir votre e-mail (code d'erreur : 21101)",
    "error_21102": "L'e-mail n'est pas valide (code d'erreur : 21102)",
    "error_21103": "Échec de la demande (code d'erreur : 21103), veuillez réessayer",
    "error_21104": "L'e-mail est déjà connecté, veuillez en utiliser un nouveau",
    "error_21105": "Échec de la demande (code d'erreur : 21105), veuillez réessayer",
    "error_21106": "Échec de l'envoi de l'e-mail. Veuillez réessayer",
    "error_21201": "Veuillez saisir votre e-mail (code d'erreur : 21201)",
    "error_21202": "Veuillez saisir le code de vérification (code d'erreur : 21202)",
    "error_21203": "L'e-mail n'est pas valide (code d'erreur : 21203)",
    "error_21204": "Erreur : 21204. Veuillez nous contacter",
    "error_21205": "Erreur : 21205. Veuillez nous contacter",
    "error_21206": "Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21206)",
    "error_21207": "Échec de la demande (code d'erreur : 21207). Veuillez réessayer",
    "error_21209": "Échec de la demande (code d'erreur : 21209). Veuillez réessayer",
    "error_21301": "Veuillez saisir l'ancien mot de passe (code d'erreur : 21301)",
    "error_21302": "Veuillez créer un nouveau mot de passe (code d'erreur : 21302)",
    "error_21303": "Le nouveau mot de passe ne peut pas être le même que l'ancien. (Erreur : 21303)",
    "error_21304": "Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21304)",
    "error_21306": "Échec de la demande (code d'erreur : 21306). Veuillez réessayer",
    "error_21402": "Échec de la demande (code d'erreur : 21402). Veuillez réessayer",
    "error_21403": "Échec de l'envoi du code de vérification. Veuillez le renvoyer",
    "error_21500": "Le compte a été supprimé",
    "error_21501": "Veuillez saisir le code de vérification (code d'erreur : 21501)",
    "error_21502": "La session de connexion a expiré (Erreur : 21502). Veuillez vous reconnecter.",
    "error_21503": "Échec de la demande (code d'erreur : 21503). Veuillez réessayer",
    "error_21505": "Échec de la demande (code d'erreur : 21505), veuillez réessayer",
    "error_21601": "Erreur : 20601. Veuillez nous contacter",
    "error_21602": "Vérification non valide (erreur : 20602). Veuillez réessayer.",
    "error_21603": "Erreur : 20603. Veuillez réessayer",
    "error_21604": "Échec de la demande (code d'erreur : 21604). Veuillez réessayer",
    "error_21606": "Échec de la demande (code d'erreur : 21606). Veuillez réessayer",
    "error_21611": "Échec de la demande (code d'erreur : 21611). Veuillez réessayer",
    "error_21801": "Erreur : 21801. Veuillez nous contacter",
    "error_21802": "Échec de la demande (Erreur : 21802). Veuillez réessayer",
    "error_21803": "Erreur : 21803. Veuillez réessayer",
    "error_21804": "Échec de la demande (code d'erreur : 21804). Veuillez réessayer",
    "error_21806": "Erreur : 21806. Veuillez réessayer",
    "error_21807": "Erreur : 21807. Veuillez nous contacter",
    "error_21808": "Erreur : 21808. Veuillez nous contacter",
    "error_21809": "Erreur : 21809. Veuillez nous contacter",
    "error_21810": "Erreur : 21810. Veuillez nous contacter",
    "error_21811": "Erreur : 21811. Veuillez nous contacter",
    "error_21812": "Erreur : 21812. Veuillez nous contacter",
    "error_21813": "Échec de la demande (code d'erreur : 21813). Veuillez réessayer",
    "error_21814": "Erreur : 21814. Veuillez nous contacter",
    "error_21815": "Échec de la demande (code d'erreur : 21815). Veuillez réessayer",
    "error_21816": "Erreur : 21816. Veuillez nous contacter",
    "error_21817": "Erreur : 21817. Veuillez nous contacter",
    "error_21818": "Erreur : 21818. Veuillez nous contacter",
    "error_21819": "Échec de la demande (code d'erreur : 21819). Veuillez réessayer",
    "error_21820": "Erreur : 21820. Veuillez nous contacter",
    "error_21821": "Erreur : 21821. Veuillez nous contacter",
    "error_21822": "Erreur : 21822. Veuillez nous contacter",
    "error_21823": "Échec de la demande (code d'erreur : 21823). Veuillez réessayer",
    "error_21824": "Échec de la demande (code d'erreur : 21824). Veuillez réessayer",
    "error_21825": "Échec de la demande (code d'erreur : 21825). Veuillez réessayer",
    "error_21826": "Échec de la demande (code d'erreur : 21826). Veuillez réessayer",
    "error_21901": "Erreur : 21901. Veuillez nous contacter",
    "error_21902": "Échec de la demande (code d'erreur : 21902). Veuillez réessayer",
    "error_21903": "Le statut du compte a changé (code d'erreur : 21903), veuillez actualiser la page et réessayer",
    "error_21904": "Erreur : 21904. Veuillez réessayer",
    "error_21905": "Erreur : 21905. Veuillez réessayer",
    "error_21906": "Échec de la demande (code d'erreur : 21906). Veuillez réessayer",
    "error_21907": "Le compte Google a été associé à un autre compte",
    "error_21908": "Échec de la demande (code d'erreur : 21908). Veuillez réessayer",
    "error_22001": "Échec de la demande (code d'erreur : 22001). Veuillez réessayer",
    "error_22002": "Échec de la dissociation sans connexion supplémentaire",
    "error_22003": "Échec de la demande (code d'erreur : 22003). Veuillez réessayer",
    "error_22101": "Erreur : 22101. Veuillez nous contacter",
    "error_22102": "Le statut du compte a changé (code d'erreur : 22102), veuillez actualiser la page et réessayer",
    "error_22103": "Échec de la demande (code d'erreur : 22103). Veuillez réessayer",
    "error_22104": "Le statut du compte a changé (code d'erreur : 22104), veuillez actualiser la page et réessayer",
    "error_22105": "Erreur : 22105. Veuillez réessayer",
    "error_22106": "Erreur : 22106. Veuillez réessayer",
    "error_22107": "Erreur : 22107. Veuillez nous contacter",
    "error_22108": "Échec de la demande (code d'erreur : 22 108). Veuillez réessayer",
    "error_22201": "Erreur : 22201. Veuillez nous contacter",
    "error_22202": "Vérification non valide (Erreur : 22202). Veuillez réessayer.",
    "error_22203": "Erreur : 22203. Veuillez réessayer",
    "error_22204": "Échec de la demande (code d'erreur : 22204). Veuillez réessayer",
    "error_22206": "Échec de la demande (code d'erreur : 22206). Veuillez réessayer",
    "error_22401": "Erreur : 22401. Veuillez nous contacter",
    "error_22402": "Vérification non valide (erreur : 22402). Veuillez réessayer.",
    "error_22403": "Erreur : 22403. Veuillez réessayer",
    "error_22404": "Échec de la demande (code d'erreur : 22404). Veuillez réessayer",
    "error_22405": "Le compte Facebook a été lié à d'autres e-mails",
    "error_22406": "Erreur : 22406. Veuillez réessayer",
    "error_22407": "Erreur : 22407. Veuillez nous contacter",
    "error_22408": "Erreur : 22408. Veuillez nous contacter",
    "error_22409": "Erreur : 22409. Veuillez nous contacter",
    "error_22410": "Erreur : 224010. Veuillez nous contacter",
    "error_22411": "Erreur : 224011. Veuillez nous contacter",
    "error_22412": "Erreur : 224012. Veuillez nous contacter",
    "error_22413": "Erreur : 22413. Veuillez nous contacter",
    "error_22414": "Échec de la demande (code d'erreur : 22414). Veuillez réessayer",
    "error_22415": "Erreur : 22415. Veuillez nous contacter",
    "error_22416": "Erreur : 22416. Veuillez nous contacter",
    "error_22417": "Erreur : 22417. Veuillez nous contacter",
    "error_22418": "Échec de la demande (code d'erreur : 22418). Veuillez réessayer",
    "error_22419": "Erreur : 22419. Veuillez nous contacter",
    "error_22420": "Erreur : 22420. Veuillez nous contacter",
    "error_22421": "Erreur : 22421. Veuillez nous contacter",
    "error_22422": "Échec de la demande (code d'erreur : 22422). Veuillez réessayer",
    "error_22423": "Échec de la demande (code d'erreur : 22423). Veuillez réessayer",
    "error_22424": "Échec de la demande (code d'erreur : 22424). Veuillez réessayer",
    "error_22425": "Échec de la demande (code d'erreur : 22425). Veuillez réessayer",
    "error_20098": "Code d'erreur : 20098. Si vous naviguez en mode privé, veuillez passer en mode normal et réessayer.",
    "error_22298": "La demande pour Google a échoué (Erreur : 22298). Veuillez réessayer.",
    "error_22498": "La demande de Facebook a échoué (Erreur : 22498). Veuillez réessayer.",
    "error_24902": "Échec de la demande (code d'erreur : 24902). Veuillez réessayer",
    "error_24903": "Échec de la demande (code d'erreur : 24903). Veuillez réessayer",
    "error_24904": "Échec de la demande (code d'erreur : 24904). Veuillez réessayer",
    "error_24905": "Échec de la demande (code d'erreur : 24905). Veuillez réessayer",
    "login_title": "Connexion à MindOnMap",
    "log_in": "Connexion",
    "no_account": "Pas de compte?",
    "create_it": "Créer un nouveau",
    "or_log_in_with": "Ou connexion avec",
    "passwordless_login": "Connexion sans mot de passe",
    "log_in_done": "Connexion Terminée",
    "three_rd_account_connect_info": "Félicitations! Vous vous êtes connecté avec succès. Vous pouvez maintenant connecter un compte de E-mail qui sera utilisé pour vous connecter à l'avenir.",
    "see_my_account": "Voir mon compte",
    "three_rd_login_merge_account": "L'adresse e-mail du compte tiers a été enregistrée, voulez-vous vous connecter et vous connecter directement avec cette adresse e-mail ?",
    "connect_log_in": "Associer & Se Connecter",
    "create_an_account": "Créer un compte",
    "back_to_log_in": "Retour connexion",
    "create_password": "Créer un mot de passe",
    "create_now": "Créer maintenant",
    "password_login_subtitle": "Connexion sans mot de passe avec e-mail",
    "account_login": "Connexion au compte",
    "rights": "En créant ce compte, vous acceptez les <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Conditions d'utilisation</a> et les <a href=\"https://www.mindonmap.com/privacy-policy/\">Politique de confidentialité</a>",
    "passwordless_login_done": "Connexion sans mot de passe terminée",
    "passwordless_login_info": "Félicitations, vous avez terminé avec succès la connexion sans mot de passe. Vous pouvez créer un mot de passe pour ce compte et vous connecter avec le compte et le mot de passe à l'avenir. <a href=\"/create-password\" style=\"display: initial;\">Créer maintenant</a>",
    "sign_up": "S'inscrire",
    "register_info": "Créez votre compte",
    "reset_now": "Réinitialiser maintenant",
    "forgot_password": "Mot de passe oublié",
    "reset_password_subtitle": "Utilisez l'e-mail de votre compte pour réinitialiser le mot de passe",
    "plan_products": "Plan & Produits",
    "nick_name": "Surnom",
    "email": "E-mail :",
    "my_products": "Mes Produits",
    "my_orders": "Mes Commandes",
    "unlink": "Dissocier",
    "link": "Associer",
    "connected_accounts": "Comptes associés",
    "last_name": "Nom de famille :",
    "first_name": "Prénom :",
    "Gender": "Genre :",
    "Birth": "Naissance :",
    "Month": "Mois",
    "Year": "Année",
    "Country_Region": "Pays/Région :",
    "Address": "Adresse :",
    "Save": "Enregistrer",
    "Date": "Date",
    "Male": "Homme",
    "Female": "Femme",
    "Unspecified": "Non spécifié",
    "Security": "Sécurité",
    "change_password": "Changer le mot de passe",
    "change_now": "Changer maintenant",
    "connect_email": "Connecter e-mail",
    "delete_account": "Supprimer le compte",
    "delete_account_info": "Lorsque votre compte est supprimé, toutes les données de Mon Compte associées à votre compte seront définitivement supprimées et vous ne pourrez peut-être pas les récupérer. Nous vous recommandons d’y aller avec prudence.",
    "Delete": "Supprimer",
    "Logout": "Se Déconnecter",
    "my_profile": "Mon Profil",
    "guides_faqs": "Guides & FAQs",
    "More": "Plus",
    "guides": "Guides",
    "register": "S'inscrire",
    "hot_faq": "FAQ Populaire",
    "Contents": "Contenu :",
    "contact_us": "Contactez-nous>>",
    "plan": "Plan",
    "unregistered": "Non Enregistré",
    "buy_more": "Acheter Plus",
    "buy_again": "Acheter à Nouveau",
    "buy_now": "Acheter Maintenant",
    "free_no_limit": "Gratuit & Sans limite",
    "expired": "Expiré",
    "lifetime": "À Vie",
    "remain": "Rester",
    "day_s": "Jour(s)",
    "error_24801": "Échec de la demande (code d'erreur : 24801). Veuillez réessayer",
    "no_app_found": "Aucune application trouvée！<a href=\"/\">Actualiser</a> ou <a href=\"https://www.mindonmap.com/\">Accéder au site officiel</a>",
    "get_more": "Obtenir Plus >>",
    "edit_photo": "Modifier Photo",
    "select_photo": "Sélectionnez une photo",
    "change_photo": "Changer la photo",
    "cancel": "Annuler",
    "hide_password": "Masquer le mot de passe",
    "show_password": "Afficher le mot de passe",
    "zoom_in": "Zoomer",
    "zoom_out": "Dézoomer",
    "rotate": "Faire pivoter",
    "horizontal_flip": "Retournement horizontal",
    "vertical_flip": "Retournement vertical",
    "country": "Pays",
    "country_1": "Sélectionnez votre pays/région",
    "country_2": "Île de land",
    "country_3": "Afghanistan",
    "country_4": "Albanie",
    "country_5": "Algérie",
    "country_6": "Samoa américaines",
    "country_7": "Andorre",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarctique",
    "country_11": "Antigua-et-Barbuda",
    "country_12": "Argentine",
    "country_13": "Arménie",
    "country_14": "Aruba",
    "country_15": "Australie",
    "country_16": "Autriche",
    "country_17": "Azerbaïdjan",
    "country_18": "Bahreïn",
    "country_19": "Bangladesh",
    "country_20": "Barbade",
    "country_21": "Biélorussie",
    "country_22": "Belgique",
    "country_23": "Belize",
    "country_24": "Bénin",
    "country_25": "Bermudes",
    "country_26": "Bhoutan",
    "country_27": "Bolivie",
    "country_28": "Bosnie-Herzégovine",
    "country_29": "Botswana",
    "country_30": "Île Bouvet",
    "country_31": "Brésil",
    "country_32": "Territoire britannique de l'océan Indien",
    "country_33": "Îles Vierges britanniques",
    "country_34": "Brunéi",
    "country_35": "Bulgarie",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodge",
    "country_39": "Cameroun",
    "country_40": "Canada",
    "country_41": "Cap-Vert",
    "country_42": "Pays-Bas caribéens",
    "country_43": "Îles Caïmans",
    "country_44": "République centrafricaine",
    "country_45": "Tchad",
    "country_46": "Chili",
    "country_47": "Chine",
    "country_48": "Île Christmas",
    "country_49": "Îles Cocos (Keeling)",
    "country_50": "Colombie",
    "country_51": "Îles Cook",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Croatie",
    "country_55": "Cuba",
    "country_56": "Chypre",
    "country_57": "République Tchèque",
    "country_58": "République Démocratique du Congo",
    "country_59": "Danemark",
    "country_60": "Djibouti",
    "country_61": "Dominique",
    "country_62": "République dominicaine",
    "country_63": "Équateur",
    "country_64": "Egypte",
    "country_65": "El Salvador",
    "country_66": "Guinée Équatoriale",
    "country_67": "Érythrée",
    "country_68": "Estonie",
    "country_69": "Ethiopie",
    "country_70": "Îles Malouines",
    "country_71": "Îles Féroé",
    "country_72": "États fédérés de Micronésie",
    "country_73": "Fidji",
    "country_74": "Finlande",
    "country_75": "France",
    "country_76": "Guyane Française",
    "country_77": "Polynésie française",
    "country_78": "Terres Australes Françaises",
    "country_79": "Gabon",
    "country_80": "Gambie",
    "country_81": "Géorgie",
    "country_82": "Allemagne",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Grande-Bretagne (Royaume-Uni, Angleterre)",
    "country_86": "Grèce",
    "country_87": "Groenland",
    "country_88": "Grenade",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernesey",
    "country_93": "Guinée",
    "country_94": "Guinée-Bissau",
    "country_95": "Guyane",
    "country_96": "Haïti",
    "country_97": "Île Heard et Îles McDonald",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hongrie",
    "country_101": "Islande",
    "country_102": "Inde",
    "country_103": "Indonésie",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irlande",
    "country_107": "île de Man",
    "country_108": "Israël",
    "country_109": "Italie",
    "country_110": "Jamaïque",
    "country_111": "Japon",
    "country_112": "Jersey",
    "country_113": "Jordan",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Koweit",
    "country_118": "Kirghizistan",
    "country_119": "Laos",
    "country_120": "Lettonie",
    "country_121": "Liban",
    "country_122": "Lesotho",
    "country_123": "Libéria",
    "country_124": "Libye",
    "country_125": "Liechtenstein",
    "country_126": "Lituanie",
    "country_127": "Luxembourg",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaisie",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malte",
    "country_135": "Iles Marshall",
    "country_136": "Martinique",
    "country_137": "Mauritanie",
    "country_138": "Maurice",
    "country_139": "Mayotte",
    "country_140": "Mexique",
    "country_141": "Moldavie",
    "country_142": "Monaco",
    "country_143": "Mongolie",
    "country_144": "Monténégro",
    "country_145": "Montserrat",
    "country_146": "Maroc",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Birmanie)",
    "country_149": "Namibie",
    "country_150": "Nauru",
    "country_151": "Népal",
    "country_152": "Pays-Bas",
    "country_153": "Nouvelle-Calédonie",
    "country_154": "Nouvelle-Zélande",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Nioué",
    "country_159": "Île de Norfolk",
    "country_160": "Corée du Nord",
    "country_161": "Îles Mariannes du Nord",
    "country_162": "Norvège",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palaos",
    "country_166": "Territoires palestiniens",
    "country_167": "Panama",
    "country_168": "Papouasie Nouvelle Guinée",
    "country_169": "Paraguay",
    "country_170": "Pérou",
    "country_171": "Îles Pitcairn",
    "country_172": "Pologne",
    "country_173": "Portugal",
    "country_174": "Porto Rico",
    "country_175": "Qatar",
    "country_176": "République de Macédoine (ARYM)",
    "country_177": "République du Congo",
    "country_178": "Réunion",
    "country_179": "Roumanie",
    "country_180": "Fédération Russe",
    "country_181": "Rwanda",
    "country_182": "Saint-Barthélemy",
    "country_183": "Saint-Martin (France)",
    "country_184": "Saint-Pierre-et-Miquelon",
    "country_185": "Samoa",
    "country_186": "Saint Marin",
    "country_187": "Sao Tomé et Principe",
    "country_188": "Arabie Saoudite",
    "country_189": "Sénégal",
    "country_190": "Serbie",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapour",
    "country_194": "Slovaquie",
    "country_195": "Slovénie",
    "country_196": "Îles Salomon",
    "country_197": "Somalie",
    "country_198": "Afrique du Sud",
    "country_199": "Géorgie du Sud-et-les îles Sandwich du Sud",
    "country_200": "Corée du Sud",
    "country_201": "Soudan du sud",
    "country_202": "Espagne",
    "country_203": "Sri Lanka",
    "country_204": "Sainte-Hélène & Dépendances",
    "country_205": "Saint-Kitts-et-Nevis",
    "country_206": "Sainte Lucie",
    "country_207": "Saint-Vincent-et-les Grenadines",
    "country_208": "Soudan",
    "country_209": "Surinam",
    "country_210": "Swaziland",
    "country_211": "Suède",
    "country_212": "Suisse",
    "country_213": "Syrie",
    "country_214": "Taïwan",
    "country_215": "Tadjikistan",
    "country_216": "Tanzanie",
    "country_217": "Modèle : Données du pays SJM Svalbard",
    "country_218": "Thaïlande",
    "country_219": "Bahamas",
    "country_220": "Comores",
    "country_221": "Philippines",
    "country_222": "Timor-Leste (Timor oriental)",
    "country_223": "Aller",
    "country_224": "Tokélaou",
    "country_225": "Tonga",
    "country_226": "Trinité-et-Tobago",
    "country_227": "Tunisie",
    "country_228": "Turquie",
    "country_229": "Turkménistan",
    "country_230": "Îles Turques et Caïques",
    "country_231": "Tuvalu",
    "country_232": "Ouganda",
    "country_233": "Ukraine",
    "country_234": "Emirats Arabes Unis",
    "country_235": "Îles mineures éloignées des États-Unis",
    "country_236": "États-Unis d'Amérique (États-Unis)",
    "country_237": "Îles Vierges des États-Unis",
    "country_238": "Uruguay",
    "country_239": "Ouzbékistan",
    "country_240": "Vanuatu",
    "country_241": "Cité du Vatican (Saint-Siège)",
    "country_242": "Venezuela",
    "country_243": "Viêt Nam",
    "country_244": "Wallis et Futuna",
    "country_245": "Sahara occidental",
    "country_246": "Yémen",
    "country_247": "Zambie",
    "country_248": "Zimbabwe",
    "google_login": "Connexion avec Google",
    "State": "État",
    "Activation_code": "Code d'activation",
    "Question": "Répertorier toutes les applications auxquelles vous vous êtes connecté",
    "Copy_complete": "Copie terminée",
    "footer": "Copyright © 2024 MindOnMap Studio.  Tous les droits sont réservés.",
    "change_password_success": "Le mot de passe a été changé avec succès",
    "successful_login_title": "Connexion réussie",
    "product_page": "Page produit>>",
    "successful_login_info": "Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant. La page en cours se fermera automatiquement dans 5 secondes. Si la fermeture automatique ou la fermeture en cliquant sur le bouton \"Terminé\" échoue, veuillez fermer cet onglet directement.",
    "successful_login_info_firefox": "Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant.",
    "my_account": "Mon Compte",
    "my_history": "Mon Histoire",
    "remove_watermark": "Supprimer Filigrane",
    "no_history": "Pas d'Historique",
    "history_all": "Tout sélectionner",
    "history_open": "Ouvrir",
    "history_down": "Télécharger",
    "history_delete": "Supprimer",
    "history_clear": "Effacer invalide",
    "images": "image(s)",
    "use_this_function": "Utiliser ce produit>>",
    "hd_downloading": "Avantages du téléchargement d'images originales HD :",
    "lifetimeRemaining": "Durée de vie restante",
    "Remaining": "Restant",
    "email_verification": "Vérification de l'E-mail",
    "email_verification_info": "Nous avons envoyé le message de vérification à votre adresse e-mail <span class=\"e-mail\"></span>, et veuillez terminer la vérification. Après vérification, les avantages seront synchronisés automatiquement.",
    "wrong_email": "Mauvaise adresse e-mail ?",
    "click_here_to_modify": "Cliquez ici pour modifier",
    "get_more_help": "Obtenir plus d'aide ?",
    "click_here": "Cliquez ici",
    "email_verification_info_success": "Félicitations pour la vérification de votre compte e-mail.",
    "email_verification_info_error": "La vérification a échoué en raison de l'expiration du lien.",
    "registration_succeeded": "Inscription Réussie",
    "registration_succeeded_info_1": "Toutes nos félicitations! Vous vous êtes bien inscrit. Nous avons envoyé le message de vérification à votre adresse e-mail <span class=\"e-mail\"></span>, et veuillez terminer la vérification pour utiliser les avantages de cet e-mail.",
    "registration_succeeded_info_2": "Cliquez sur \"Terminé\" pour revenir à la page d'accueil et utiliser ce produit.",
    "registration_succeeded_info_3": "Cliquez sur \"Terminé\" pour fermer la page en cours et revenir à la page d'accueil pour les opérations suivantes. Si la fermeture échoue, veuillez fermer manuellement l'onglet.",
    "verify_email": "Vérifier l'e-mail",
    "registered_email_not_verified": "L'e-mail enregistré n'a pas été vérifié, veuillez le vérifier immédiatement.",
    "email_verification_time_1": "Vous n'avez pas reçu d'e-mail de vérification ?",
    "email_verification_time_2": "Après",
    "email_verification_time_3": "Cliquez ici pour le renvoyer",
    "error_26301": "Code d'erreur : 26301, veuillez nous contacter",
    "error_26302": "Code d'erreur : 26302, veuillez nous contacter",
    "error_26303": "Erreur de format d'e-mail (code d'erreur : 26303). Veuillez le saisir à nouveau",
    "error_26304": "Un mot de passe supérieur à 8 caractères est recommandé (code d'erreur : 26304)",
    "error_26305": "Échec de la demande (code d'erreur : 26305). Veuillez réessayer",
    "error_26306": "L'e-mail a été enregistré, veuillez <a href=\"https://account.mindonmap.com/login/\">aller à la connexion</a>",
    "error_26307": "Échec de la demande (code d'erreur : 26307). Veuillez réessayer",
    "error_26308": "Échec de la demande (code d'erreur : 26308). Veuillez réessayer",
    "error_26401": "Code d'erreur : 26401, veuillez réessayer",
    "error_26402": "L'e-mail a été vérifié (code d'erreur : 26402), veuillez réessayer",
    "error_26403": "Échec de la demande (code d'erreur : 26403). Veuillez réessayer",
    "error_26404": "Échec de la demande (code d'erreur : 26404). Veuillez réessayer",
    "error_26501": "Code d'erreur : 26501, veuillez nous contacter",
    "error_26502": "Code d'erreur : 26502, veuillez nous contacter",
    "error_26503": "Erreur de format d'e-mail (code d'erreur : 26503). Veuillez le saisir à nouveau",
    "error_26504": "Échec de la demande (code d'erreur : 26504). Veuillez réessayer",
    "error_26505": "L'e-mail n'a pas été enregistré, veuillez <a href=\"https://account.mindonmap.com/register/\">l'enregistrer d'abord</a>",
    "error_26506": "L'e-mail a été vérifié.",
    "error_26507": "Échec de la demande (code d'erreur : 26507). Veuillez réessayer",
    "error_26508": "La vérification a échoué (code d'erreur : 26508), veuillez réessayer",
    "error_26509": "Échec de la demande (code d'erreur : 26509), veuillez réessayer",
    "error_26510": "Code d'erreur : 26510, veuillez nous contacter",
    "error_26601": "Code d'erreur : 26601, veuillez nous contacter",
    "error_26602": "Code d'erreur : 26602, veuillez nous contacter",
    "error_26603": "Échec de la demande (code d'erreur : 26603). Veuillez réessayer",
    "error_26604": "Code d'erreur : 26604, veuillez nous contacter",
    "error_26605": "Code d'erreur : 26605, veuillez nous contacter",
    "error_26701": "Code d'erreur : 26701, veuillez nous contacter",
    "error_26702": "Échec de la demande (code d'erreur : 26702). Veuillez réessayer",
    "error_26703": "Code d'erreur : 26703, veuillez nous contacter",
    "error_26704": "Code d'erreur : 26704, veuillez nous contacter",
    "error_26705": "Attendez la connexion (code d'erreur : 26705). Veuillez réessayer",
    "no_cookie": "Vous avez activé la fonction Bloquer tous les cookies dans votre navigateur, vous ne pouvez donc pas vous connecter. Veuillez vous rendre dans Paramètres pour cocher la case Autoriser tous les cookies.",
    "error_26801": "Code d'erreur : 26801, veuillez nous contacter",
    "error_26802": "Code d'erreur : 26802, veuillez nous contacter",
    "error_26803": "Échec de la demande (code d'erreur : 26803). Veuillez réessayer",
    "error_26804": "Échec de la demande (code d'erreur : 26804). Veuillez réessayer",
    "error_order": "Échec de la demande (code d'erreur : 27098), veuillez réessayer !",
    "error_order1": "La demande de commande est incomplète (code d'erreur :",
    "error_order2": "）, veuillez actualiser et réessayer.",
    "modify_email_title": "Modifier l'e-mail",
    "modify_email_info": "Vous pouvez utiliser l'e-mail modifié pour vous connecter à votre compte.",
    "images_per": "Images par",
    "error_26101": "Erreur : 26101. Veuillez nous contacter",
    "error_26102": "Erreur : 26102. Veuillez nous contacter",
    "error_26103": "Échec de la demande (code d'erreur : 26103). Veuillez réessayer",
    "error_26104": "Code d'erreur : 26104, veuillez réessayer",
    "error_26105": "Code d'erreur : 26105, veuillez réessayer",
    "error_26106": "Échec de la suppression (code d'erreur : 26106). Veuillez réessayer",
    "error_26201": "Erreur : 26201. Veuillez nous contacter",
    "error_26202": "Échec de la demande (code d'erreur : 26202). Veuillez réessayer",
    "error_26001": "Erreur : 26001. Veuillez nous contacter",
    "error_26002": "Erreur : 26002. Veuillez nous contacter",
    "error_26003": "Erreur : 26003. Veuillez nous contacter",
    "error_26004": "Erreur : 26004. Veuillez nous contacter",
    "error_26005": "Échec de la demande (code d'erreur : 26005). Veuillez réessayer",
    "error_26006": "Code d'erreur : 26006, veuillez réessayer",
    "error_26008": "Erreur : 26008. Veuillez nous contacter",
    "go_to_the_home_page": "Aller à la page d'accueil",
    "error_27101": "Échec de la demande (code d'erreur : 27101). Veuillez réessayer",
    "error_27201": "Code d'erreur : 27201, veuillez nous contacter",
    "error_27202": "Code d'erreur : 27202, veuillez réessayer",
    "error_27203": "Échec de la demande (code d'erreur : 27203). Veuillez réessayer",
    "error_27204": "Code invalide (code d'erreur : 27204).",
    "error_27205": "Échec de la demande (code d'erreur : 27205). Veuillez réessayer",
    "error_27206": "Échec de la requête (code d'erreur : 27206). Veuillez réessayer",
    "error_27207": "Échec de la demande (code d'erreur : 27207). Veuillez réessayer",
    "no_history_found": "Vous n'avez utilisé aucun outil ! <a href=\"/\">Actualiser</a> ou <a href=\"https://www.mindonmap.com/\">Accéder au site officiel</a>",
    "error_25301": "Erreur : 25301. Veuillez nous contacter",
    "error_25302": "Erreur : 25302. Veuillez nous contacter",
    "error_25303": "Échec de la demande (code d'erreur : 25303). Veuillez réessayer",
    "error_25304": "Échec de la demande (code d'erreur : 25304). Veuillez réessayer",
    "error_25305": "Échec de la demande (code d'erreur : 25305). Veuillez réessayer",
    "error_25306": "Échec de la demande (code d'erreur : 25306). Veuillez réessayer",
    "image_upscaler_p": "Télécharger des images sans filigrane:",
    "Available_for": "Disponible pour :",
    "credit_per": "%s crédit(s) par image HD",
    "still_valid": "Le(s) plan(s) acheté(s) est (sont) encore valable(s)",
    "credit": "crédit(s)",
    "pc_3rd_info": "Vous vous êtes connecté avec succès, veuillez vous rendre sur l'application pour continuer à l'utiliser.",
    "use_online": "Utiliser le service en ligne",
    "use_download": "Utiliser le programme de bureau",
    "use_immediately": "Utiliser Maintenant",
    "Use_in_browser": "Utiliser dans le Navigateur",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Crédits par Mois",
    "expire": "Date d'expiration :",
    "viewDetails": "Voir les détails",
    "viewHistory": "Voir l'historique des avantages>>",
    "viewDetailsInfo": "Remarque : Si un abonnement est renouvelé dans les 7 jours suivant son expiration, les avantages non utilisés peuvent encore être utilisés. La date d'expiration sera automatiquement mise à jour en fonction de la date d'expiration du nouvel abonnement. Après l'expiration de l'abonnement pendant 7 jours, si aucun nouvel abonnement n'est effectué, tous les avantages expirés seront supprimés.",
    "connect_account": "Lier l'E-mail à Votre Compte",
    "connect_account_info": "Après la liaison, vous pouvez vous connecter avec cette adresse E-mai",
    "connect_now": "Lier maintenant",
    "no_email_bind": "Pas de liaison d'e-mail",
    "bind_email": "Lier l'e-mail",
    "connect_your_email_placeholder": "Veuillez saisir votre adresse e-mail",
    "bind_an_email": "Lier un e-mail",
    "bind_info": "Vous vous êtes connecté avec succès. Veuillez lier un e-mail à votre compte pour activer vos avantages.",
    "bind_later": "Lier plus tard",
    "hi": "Bonjour, %s!",
    "Personal_Information": "Informations personnelles",
    "Access": "Accéder",
    "Subscription_Plan": "(Forfait d'abonnement)",
    "No_orders": "Aucune commande trouvée.",
    "No_data": "Pas de données",
    "Featured_Products": "Produits Populaires",
    "More_Products": "Plus de Produits",
    "Free_Download": "Télécharger Gratuitement",
    "Get_Started": "Commencer",
    "Subscribe": "S'abonner",
    "Verified": "Vérifié",
    "back_to_account_center": "Retour au Centre de comptes",
    "success": "Succès!",
    "successfully": "Vous avez enregistré avec succès un compte.",
    "Continue": "Continuer",
    "Already": "Vous avez déjà un compte?",
    "loading_verification": "Vérification de l'état de vérification...",
    "email_no_verification": "Désolé, l'adresse e-mail enregistrée n'a pas été vérifiée. Veuillez terminer la vérification selon les instructions ci-dessus et cliquez à nouveau sur le bouton \"Terminé\" pour terminer l'enregistrement.",
    "will_expire_after": "expirera après",
    "hours": "heures",
  },
  "gl": {
    "overtime": "Código de erro: {%}, téntao de novo",
    "isnetwork": "Erro de Internet. Comproba e téntao de novo",
    "email_placeholder": "Correo electrónico",
    "email_empty": "Introduce o correo electrónico",
    "email_not_valid": "O correo electrónico non é válido",
    "email_not_valid_1": "Introduce o teu correo electrónico",
    "email_not_valid_2": "O correo electrónico non é válido, utiliza un enderezo diferente.",
    "email_not_valid_3": "Sen entrada de correo electrónico",
    "password_placeholder": "Contrasinal",
    "password_empty": "Introduza o contrasinal",
    "password_not_valid": "Conta ou contrasinal incorrectos",
    "password_not_valid_1": "É necesario un contrasinal de máis de 8 caracteres",
    "password_not_valid_2": "Crea un contrasinal",
    "password_placeholder_1": "Crea o teu contrasinal",
    "password_placeholder_2": "Confirme o seu contrasinal",
    "password_placeholder_3": "Crear un novo contrasinal",
    "password_placeholder_4": "Confirme o novo contrasinal",
    "password_placeholder_5": "Introduza o contrasinal antigo",
    "copy_password_empty": "Confirme o contrasinal",
    "copy_password_not_valid": "Confirma o teu contrasinal",
    "copy_passwords_inconsistent": "A confirmación do teu contrasinal non coincide",
    "code_empty": "Introduce o código de verificación",
    "code_not_valid": "Código de verificación non válido",
    "code_placeholder": "Código de verificación",
    "not_received_code": "Se a túa caixa de correo non recibiu o código de verificación durante moito tempo, obtén o código de verificación de novo.",
    "get_first_code": "Obtén primeiro o código de verificación.",
    "last_name_placeholder": "Introduce o teu apelido",
    "first_name_placeholder": "Introduce o teu nome",
    "address_placeholder": "Introduce o teu enderezo",
    "no_code_text": "Enviamos un código de verificación. Introduce o teu código. <span class='tips'>Non recibiches un código?",
    "no_code_text_1": "1. Asegúrate de que o enderezo de correo electrónico sexa válido e que poida recibir correos electrónicos.",
    "no_code_text_2": "2. Se o sistema envía o correo electrónico automaticamente, pódese marcar como correo lixo ou lixo. Comproba se o correo electrónico está no cartafol do lixo.",
    "no_code_text_3": "3. Non podes resolver o teu problema? ",
    "no_code_text_3_span": "Entón fai clic aquí para contactar connosco.",
    "order_no": "Non compraches ningún produto. Se tes algunha pregunta, <a href=\"https://www.mindonmap.com/contact-us/\">contacta connosco</a>.",
    "error_24901": "A conta actual non ten un correo electrónico ligado e non pode atopar os pedidos. Conecta un correo electrónico.",
    "user_guide": "Guía de usuario >>",
    "download": "Descargar",
    "order_number": "Número de pedido:",
    "Refund": "Reembolso",
    "Disabled": "Desactivado",
    "Normal": "Normal",
    "Modify": "Modificar",
    "Modify_1": "Modificar >>",
    "Connect": "Conectar",
    "unlink_success": "Desligar correctamente",
    "connect_success": "Conectar correctamente",
    "feedback_title": "Grazas polos teus comentarios. Deixe o seu problema e responderémosche nun prazo de 24 horas.",
    "feedback_thank_you": "Grazas!<br />O teu comentario foi enviado correctamente.",
    "feedback_email": "Introduce o teu correo electrónico aquí!",
    "feedback_content": "Deixa aquí calquera problema ou suxestión que atopaches.",
    "feedback_submit": "Enviar",
    "form_contents": "Non introduciches ningunha descrición. Introdúceo e envía de novo.",
    "old_password": "Introduce o contrasinal antigo",
    "new_password": "Crea un novo contrasinal",
    "old_new_password": "O novo contrasinal non pode ser o mesmo que o antigo",
    "incorrect_password": "Contrasinal incorrecto",
    "delete_no": "Eliminar agora",
    "Caps": "O bloqueo de maiúsculas está activado",
    "Get": "Obter",
    "Done": "Feito",
    "error_20001": "Erro: 20001. Inicia sesión de novo.",
    "error_20002": "Erro: 20002. Inicie sesión de novo.",
    "error_20003": "Erro: 20003. Inicia sesión de novo.",
    "error_20004": "Produciuse un erro na solicitude (código de erro: 20004). Téntao de novo.",
    "error_20005": "A sesión de inicio de sesión caducou (Erro: 20005). Inicia sesión de novo.",
    "error_20006": "Produciuse un erro na solicitude (código de erro: 20006). Téntao de novo.",
    "error_20007": "A sesión de inicio de sesión caducou (Erro: 20007). Inicia sesión de novo.",
    "error_20008": "A sesión de inicio de sesión caducou (Erro: 20008). Inicia sesión de novo.",
    "error_20009": "A sesión de inicio de sesión caducou (Erro: 20009). Inicia sesión de novo.",
    "error_20101": "Introduce o teu correo electrónico (código de erro: 20101)",
    "error_20102": "O correo electrónico non é válido (código de erro: 20102)",
    "error_20103": "Produciuse un erro na solicitude (código de erro: 20103). Téntao de novo",
    "error_20104": "O correo electrónico xa está en uso, <a href=\"https://account.mindonmap.com/login/\">inicie sesión</a> ou rexístrese cun novo",
    "error_20105": "Produciuse un erro na solicitude (código de erro: 20105). Téntao de novo",
    "error_20106": "Produciuse un erro ao enviar o correo electrónico. Téntao de novo",
    "error_20201": "Introduce o teu correo electrónico (código de erro: 20201)",
    "error_20202": "Introduce o teu contrasinal (código de erro: 20202)",
    "error_20203": "Introduza o código de verificación (código de erro: 20203)",
    "error_20204": "O correo electrónico non é válido (código de erro: 20204)",
    "error_20205": "Requírese un contrasinal de máis de 8 caracteres (código de erro: 20205)",
    "error_20206": "Produciuse un erro na solicitude (código de erro: 20206). Téntao de novo",
    "error_20207": "Código de verificación non válido",
    "error_20208": "Produciuse un erro na solicitude (código de erro: 20208). Téntao de novo",
    "error_20209": "Produciuse un erro na solicitude (código de erro: 20209). Téntao de novo",
    "error_20301": "Introduce o teu correo electrónico (código de erro: 20301)",
    "error_20302": "Erro: 20302. Póñase en contacto connosco",
    "error_20303": "O correo electrónico non é válido (código de erro: 20303)",
    "error_20304": "Produciuse un erro na solicitude (código de erro: 20304). Téntao de novo",
    "error_20305": "A conta non existe. Volve introducir ou <a href=\"https://account.mindonmap.com/register/\">Creao</a> primeiro.",
    "error_20306": "Aínda non hai contrasinal. Use <a href=\"https://account.mindonmap.com/passwordless-login/\">Acceso sen contrasinal</a> ou <a href=\"https://account.mindonmap.com/create-password/\"> establece un contrasinal</a> e inicia sesión.",
    "error_20308": "Produciuse un erro na solicitude (código de erro: 20308). Téntao de novo",
    "error_20401": "Produciuse un erro ao pechar a sesión (código de erro: 20401). Téntao de novo",
    "error_20501": "Introduce o teu correo electrónico (código de erro: 20501)",
    "error_20502": "O correo electrónico non é válido (código de erro: 20502)",
    "error_20503": "Produciuse un erro na solicitude (código de erro: 20503). Téntao de novo",
    "error_20504": "Produciuse un erro ao enviar o correo electrónico. Téntao de novo.",
    "error_20601": "Introduce o teu correo electrónico (código de erro: 20601)",
    "error_20602": "Introduza o código de verificación (código de erro: 20602)",
    "error_20603": "O correo electrónico non é válido (código de erro: 20603)",
    "error_20604": "Produciuse un erro na solicitude (código de erro: 20604). Téntao de novo",
    "error_20606": "Produciuse un erro na solicitude (código de erro: 20606). Téntao de novo",
    "error_20607": "Produciuse un erro na solicitude (código de erro: 20607). Téntao de novo",
    "error_20608": "Produciuse un erro na solicitude (código de erro: 20608). Téntao de novo",
    "error_20701": "Introduce o teu correo electrónico (código de erro: 20701)",
    "error_20702": "O correo electrónico non é válido (código de erro: 20702)",
    "error_20703": "Produciuse un erro na solicitude (código de erro: 20703). Téntao de novo",
    "error_20704": "A conta non existe. Volve introducir ou <a href=\"https://account.mindonmap.com/register/\">Creao</a> primeiro.",
    "error_20705": "Produciuse un erro na solicitude (código de erro: 20705). Téntao de novo",
    "error_20706": "Produciuse un erro ao enviar o correo electrónico. Téntao de novo",
    "error_20801": "Introduce o teu correo electrónico (código de erro: 20801)",
    "error_20802": "Erro: 20802. Póñase en contacto connosco",
    "error_20803": "Introduza o código de verificación (código de erro: 20803)",
    "error_20804": "O correo electrónico non é válido (código de erro: 20804)",
    "error_20805": "É necesario contrasinal de máis de 8 caracteres (código de erro: 20805)",
    "error_20806": "Produciuse un erro na solicitude (código de erro: 20806). Téntao de novo",
    "error_20808": "Produciuse un erro na solicitude (código de erro: 20808). Téntao de novo",
    "error_20901": "Produciuse un erro na solicitude (código de erro: 20901). Téntao de novo",
    "error_20902": "Produciuse un erro na solicitude (código de erro: 20902). Téntao de novo",
    "error_21000": "Os cambios gárdanse",
    "error_21001": "Non se envía información (código de erro: 21001)",
    "error_21002": "Produciuse un erro na solicitude (código de erro: 21002). Téntao de novo",
    "error_21101": "Introduce o teu correo electrónico (código de erro: 21101)",
    "error_21102": "O correo electrónico non é válido (código de erro: 21102)",
    "error_21103": "Produciuse un erro na solicitude (código de erro: 21103), téntao de novo",
    "error_21104": "O correo electrónico xa está conectado, utiliza un novo",
    "error_21105": "Produciuse un erro na solicitude (código de erro: 21105), téntao de novo",
    "error_21106": "Produciuse un erro ao enviar o correo electrónico. Téntao de novo",
    "error_21201": "Introduce o teu correo electrónico (código de erro: 21201)",
    "error_21202": "Introduza o código de verificación (código de erro: 21202)",
    "error_21203": "O correo electrónico non é válido (código de erro: 21203)",
    "error_21204": "Erro: 21204. Póñase en contacto connosco",
    "error_21205": "Erro: 21205. Póñase en contacto connosco",
    "error_21206": "É necesario contrasinal de máis de 8 caracteres (código de erro: 21206)",
    "error_21207": "Produciuse un erro na solicitude (código de erro: 21207). Téntao de novo",
    "error_21209": "Produciuse un erro na solicitude (código de erro: 21209). Téntao de novo",
    "error_21301": "Introduza o contrasinal antigo (código de erro: 21301)",
    "error_21302": "Crea un novo contrasinal (código de erro: 21302)",
    "error_21303": "O novo contrasinal non pode ser o mesmo que o antigo. (Erro: 21303)",
    "error_21304": "É necesario contrasinal de máis de 8 caracteres (código de erro: 21304)",
    "error_21306": "Produciuse un erro na solicitude (código de erro: 21306). Téntao de novo",
    "error_21402": "Produciuse un erro na solicitude (código de erro: 21402). Téntao de novo",
    "error_21403": "Produciuse un erro ao enviar o código de verificación. Por favor, reenvíoo",
    "error_21500": "A conta foi eliminada",
    "error_21501": "Introduza o código de verificación (código de erro: 21501)",
    "error_21502": "A sesión de inicio de sesión caducou (Erro: 21502). Inicia sesión de novo.",
    "error_21503": "Produciuse un erro na solicitude (código de erro: 21503). Téntao de novo",
    "error_21505": "Produciuse un erro na solicitude (código de erro: 21505), téntao de novo",
    "error_21601": "Erro: 20601. Póñase en contacto connosco",
    "error_21602": "Verificación non válida (erro: 20602). Téntao de novo.",
    "error_21603": "Erro: 20603. Téntao de novo",
    "error_21604": "Produciuse un erro na solicitude (código de erro: 21604). Téntao de novo",
    "error_21606": "Produciuse un erro na solicitude (código de erro: 21606). Téntao de novo",
    "error_21611": "Produciuse un erro na solicitude (código de erro: 21611). Téntao de novo",
    "error_21801": "Erro: 21801. Póñase en contacto connosco",
    "error_21802": "Produciuse un erro na solicitude (erro: 21802). Téntao de novo",
    "error_21803": "Erro: 21803. Téntao de novo",
    "error_21804": "Produciuse un erro na solicitude (código de erro: 21804). Téntao de novo",
    "error_21806": "Erro: 21806. Téntao de novo",
    "error_21807": "Erro: 21807. Póñase en contacto connosco",
    "error_21808": "Erro: 21808. Póñase en contacto connosco",
    "error_21809": "Erro: 21809. Póñase en contacto connosco",
    "error_21810": "Erro: 21810. Póñase en contacto connosco",
    "error_21811": "Erro: 21811. Póñase en contacto connosco",
    "error_21812": "Erro: 21812. Póñase en contacto connosco",
    "error_21813": "Produciuse un erro na solicitude (código de erro: 21813). Téntao de novo",
    "error_21814": "Erro: 21814. Póñase en contacto connosco",
    "error_21815": "Produciuse un erro na solicitude (código de erro: 21815). Téntao de novo",
    "error_21816": "Erro: 21816. Póñase en contacto connosco",
    "error_21817": "Erro: 21817. Póñase en contacto connosco",
    "error_21818": "Erro: 21818. Póñase en contacto connosco",
    "error_21819": "Produciuse un erro na solicitude (código de erro: 21819). Téntao de novo",
    "error_21820": "Erro: 21820. Póñase en contacto connosco",
    "error_21821": "Erro: 21821. Póñase en contacto connosco",
    "error_21822": "Erro: 21822. Póñase en contacto connosco",
    "error_21823": "Produciuse un erro na solicitude (código de erro: 21823). Téntao de novo",
    "error_21824": "Produciuse un erro na solicitude (código de erro: 21824). Téntao de novo",
    "error_21825": "Produciuse un erro na solicitude (código de erro: 21825). Téntao de novo",
    "error_21826": "Produciuse un erro na solicitude (código de erro: 21826). Téntao de novo",
    "error_21901": "Erro: 21901. Póñase en contacto connosco",
    "error_21902": "Produciuse un erro na solicitude (código de erro: 21902). Téntao de novo",
    "error_21903": "O estado da conta cambiou (código de erro: 21903), actualice a páxina e ténteo de novo",
    "error_21904": "Erro: 21904. Téntao de novo",
    "error_21905": "Erro: 21905. Téntao de novo",
    "error_21906": "Produciuse un erro na solicitude (código de erro: 21906). Téntao de novo",
    "error_21907": "A conta de Google ligouse a outra conta",
    "error_21908": "Produciuse un erro na solicitude (código de erro: 21908). Téntao de novo",
    "error_22001": "Produciuse un erro na solicitude (código de erro: 22001). Téntao de novo",
    "error_22002": "Produciuse un erro sen conexión adicional",
    "error_22003": "Produciuse un erro na solicitude (código de erro: 22003). Téntao de novo",
    "error_22101": "Erro: 22101. Póñase en contacto connosco",
    "error_22102": "O estado da conta cambiou (código de erro: 22102), actualice a páxina e ténteo de novo",
    "error_22103": "Produciuse un erro na solicitude (código de erro: 22103). Téntao de novo",
    "error_22104": "O estado da conta cambiou (código de erro: 22104), actualice a páxina e ténteo de novo",
    "error_22105": "Erro: 22105. Téntao de novo",
    "error_22106": "Erro: 22106. Téntao de novo",
    "error_22107": "Erro: 22107. Póñase en contacto connosco",
    "error_22108": "Produciuse un erro na solicitude (código de erro: 22108). Téntao de novo",
    "error_22201": "Erro: 22201. Póñase en contacto connosco",
    "error_22202": "Verificación non válida (erro: 22202). Téntao de novo.",
    "error_22203": "Erro: 22203. Téntao de novo\"",
    "error_22204": "Produciuse un erro na solicitude (código de erro: 22204). Téntao de novo",
    "error_22206": "Produciuse un erro na solicitude (código de erro: 22206). Téntao de novo",
    "error_22401": "Erro: 22401. Póñase en contacto connosco",
    "error_22402": "Verificación non válida (erro: 22402). Téntao de novo.",
    "error_22403": "Erro: 22403. Téntao de novo",
    "error_22404": "Produciuse un erro na solicitude (código de erro: 22404). Téntao de novo",
    "error_22405": "A conta de Facebook ligouse a outro correo electrónico",
    "error_22406": "Erro: 22406. Téntao de novo",
    "error_22407": "Erro: 22407. Póñase en contacto connosco",
    "error_22408": "Erro: 22408. Póñase en contacto connosco",
    "error_22409": "Erro: 22409. Póñase en contacto connosco",
    "error_22410": "Erro: 224010. Póñase en contacto connosco",
    "error_22411": "Erro: 224011. Póñase en contacto connosco",
    "error_22412": "Erro: 224012. Póñase en contacto connosco",
    "error_22413": "Erro: 22413. Póñase en contacto connosco",
    "error_22414": "Produciuse un erro na solicitude (código de erro: 22414). Téntao de novo",
    "error_22415": "Erro: 22415. Póñase en contacto connosco",
    "error_22416": "Erro: 22416. Póñase en contacto connosco",
    "error_22417": "Erro: 22417. Póñase en contacto connosco",
    "error_22418": "Produciuse un erro na solicitude (código de erro: 22418). Téntao de novo",
    "error_22419": "Erro: 22419. Póñase en contacto connosco",
    "error_22420": "Erro: 22420. Póñase en contacto connosco",
    "error_22421": "Erro: 22421. Póñase en contacto connosco",
    "error_22422": "Produciuse un erro na solicitude (código de erro: 22422). Téntao de novo",
    "error_22423": "Produciuse un erro na solicitude (código de erro: 22423). Téntao de novo",
    "error_22424": "Produciuse un erro na solicitude (código de erro: 22424). Téntao de novo",
    "error_22425": "Produciuse un erro na solicitude (código de erro: 22425). Téntao de novo",
    "error_20098": "Código de erro: 20098. Se estás a navegar no modo privado, cambia ao modo Normal e téntao de novo.",
    "error_22298": "Produciuse un erro na solicitude de Google (Erro: 22298). Téntao de novo.",
    "error_22498": "A solicitude de Facebook fallou (erro: 22498). Téntao de novo.",
    "error_24902": "Produciuse un erro na solicitude (código de erro: 24902). Téntao de novo",
    "error_24903": "Produciuse un erro na solicitude (código de erro: 24903). Téntao de novo",
    "error_24904": "Produciuse un erro na solicitude (código de erro: 24904). Téntao de novo",
    "error_24905": "Produciuse un erro na solicitude (código de erro: 24905). Téntao de novo",
    "login_title": "Inicia sesión en MindOnMap",
    "log_in": "Acceder",
    "no_account": "Sen conta?",
    "create_it": "Crealo",
    "or_log_in_with": "Ou inicia sesión con",
    "passwordless_login": "Acceso sen contrasinal",
    "log_in_done": "Iniciar sesión Feito",
    "three_rd_account_connect_info": "Parabéns! Iniciaches sesión correctamente. Agora podes conectar unha conta de correo electrónico que se use para iniciar sesión no futuro.",
    "see_my_account": "Ver a miña conta",
    "three_rd_login_merge_account": "Rexistrouse o enderezo de correo electrónico da conta de terceiros, queres conectarte e iniciar sesión directamente con este enderezo de correo electrónico?",
    "connect_log_in": "Conectar e iniciar sesión",
    "create_an_account": "Crear unha conta",
    "back_to_log_in": "Volver para iniciar sesión",
    "create_password": "Crear contrasinal",
    "create_now": "Crea agora",
    "password_login_subtitle": "Inicio de sesión sen contrasinal con correo electrónico",
    "account_login": "Iniciar sesión na conta",
    "rights": "Ao crear esta conta, aceptas as <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Condicións de servizo</a> e as <a href=\"https://www.mindonmap.com/privacy-policy/\">Política de privacidade</a>",
    "passwordless_login_done": "Inicio de sesión sen contrasinal feito",
    "passwordless_login_info": "Parabéns, completaches o inicio de sesión sen contrasinal correctamente. Podes crear un contrasinal para esta conta e iniciar sesión coa conta e o contrasinal no futuro. <a href=\"/create-password\" style=\"display: initial;\">Crear agora</a>",
    "sign_up": "Incribirse",
    "register_info": "Crea a túa conta",
    "reset_now": "Restablecer agora",
    "forgot_password": "Esqueceu o contrasinal",
    "reset_password_subtitle": "Use o correo electrónico da súa conta para restablecer o contrasinal",
    "plan_products": "Plans e Produtos",
    "nick_name": "Nome de usuario:",
    "email": "Correo electrónico:",
    "my_products": "Os meus produtos",
    "my_orders": "As miñas ordes",
    "unlink": "Desvincular",
    "link": "Ligazón",
    "connected_accounts": "Contas conectadas",
    "last_name": "Apelido:",
    "first_name": "Nome:",
    "Gender": "Sexo:",
    "Birth": "Nacemento:",
    "Month": "Mes",
    "Year": "Ano",
    "Country_Region": "País/Rexión:",
    "Address": "Enderezo:",
    "Save": "Gardar",
    "Date": "Data",
    "Male": "Masculino",
    "Female": "Feminino",
    "Unspecified": "Sen especificar",
    "Security": "Seguridade",
    "change_password": "Cambie o contrasinal",
    "change_now": "Cambia agora",
    "connect_email": "Conectar correo electrónico",
    "delete_account": "Eliminar conta",
    "delete_account_info": "Cando se elimine a túa conta, todos os datos da miña conta asociados á túa conta eliminaranse permanentemente e é posible que non poidas recuperalos. Recomendamos que pisedes con cautela.",
    "Delete": "Eliminar",
    "Logout": "Pechar sesión",
    "my_profile": "O meu perfil",
    "guides_faqs": "Guías e preguntas frecuentes",
    "More": "Máis",
    "guides": "Guías",
    "register": "rexistrarse",
    "hot_faq": "Preguntas frecuentes",
    "Contents": "Contidos:",
    "contact_us": "Contáctanos >>",
    "plan": "Planificar",
    "unregistered": "Non rexistrado",
    "buy_more": "Compra máis",
    "buy_again": "Comprar de novo",
    "buy_now": "Compre agora",
    "free_no_limit": "Gratis e sen límite",
    "expired": "Caducou",
    "lifetime": "De por vida",
    "remain": "Queda",
    "day_s": "Día(s)",
    "error_24801": "Produciuse un erro na solicitude (código de erro: 24801). Téntao de novo",
    "no_app_found": "Non se atopou ningunha aplicación!<a href=\"/\">Actualizar</a> ou <a href=\"https://www.mindonmap.com/\">Vai ao sitio web oficial</a>",
    "get_more": "Obter máis >>",
    "edit_photo": "Editar foto",
    "select_photo": "Selecciona a foto",
    "change_photo": "Cambiar foto",
    "cancel": "Cancelar",
    "hide_password": "Ocultar o contrasinal",
    "show_password": "Mostrar contrasinal",
    "zoom_in": "Facer zoom",
    "zoom_out": "Reducir o zoom",
    "rotate": "Xirar",
    "horizontal_flip": "Flip horizontal",
    "vertical_flip": "Flip vertical",
    "country": "País",
    "country_1": "Selecciona o seu país/rexión",
    "country_2": "Illa Åaland",
    "country_3": "Afganistán",
    "country_4": "Albania",
    "country_5": "Alxeria",
    "country_6": "Samoa americana",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguila",
    "country_10": "Antártida",
    "country_11": "Antiga e Barbuda",
    "country_12": "Arxentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Acerbaixán",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorrusia",
    "country_22": "Bélxica",
    "country_23": "Belice",
    "country_24": "Benin",
    "country_25": "Bermudas",
    "country_26": "Bután",
    "country_27": "Bolivia",
    "country_28": "Bosnia e Hercegovina",
    "country_29": "Botswana",
    "country_30": "Illa Bouvet",
    "country_31": "Brasil",
    "country_32": "Territorio británico del océano Índico",
    "country_33": "Illas Virxes Británicas",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodia",
    "country_39": "Camerún",
    "country_40": "Canadá",
    "country_41": "Cabo Verde",
    "country_42": "Caribe Países Baixos",
    "country_43": "Illas Caimán",
    "country_44": "República Centroafricana",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Illa de Nadal",
    "country_49": "Illas Cocos (Keeling).",
    "country_50": "Colombia",
    "country_51": "Illas Cook",
    "country_52": "Costa Rica",
    "country_53": "Costa de Marfil",
    "country_54": "Croacia",
    "country_55": "Cuba",
    "country_56": "Chipre",
    "country_57": "República Checa",
    "country_58": "República Democrática do Congo",
    "country_59": "Dinamarca",
    "country_60": "Xibutí",
    "country_61": "Dominica",
    "country_62": "República Dominicana",
    "country_63": "Ecuador",
    "country_64": "Exipto",
    "country_65": "El Salvador",
    "country_66": "Guinea Ecuatorial",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopía",
    "country_70": "Illas Malvinas",
    "country_71": "Illas Feroe",
    "country_72": "Estados Federados de Micronesia",
    "country_73": "Fidxi",
    "country_74": "Finlandia",
    "country_75": "Francia",
    "country_76": "Guayana Francesa",
    "country_77": "Polinesia francesa",
    "country_78": "Territorios sur franceses",
    "country_79": "Gabón",
    "country_80": "Gambia",
    "country_81": "Xeorxia",
    "country_82": "Alemaña",
    "country_83": "Gana",
    "country_84": "Xibraltar",
    "country_85": "Gran Bretaña (Reino Unido; Inglaterra)",
    "country_86": "Grecia",
    "country_87": "Groenlandia",
    "country_88": "Granada",
    "country_89": "Guadalupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Güiana",
    "country_96": "Haití",
    "country_97": "Illas Heard e McDonald",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungría",
    "country_101": "Islandia",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Irán",
    "country_105": "Iraq",
    "country_106": "Irlanda",
    "country_107": "Illa de Man",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Xamaica",
    "country_111": "Xapón",
    "country_112": "Xersei",
    "country_113": "Xordania",
    "country_114": "Casaquistán",
    "country_115": "Quenia",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirguizistán",
    "country_119": "Laos",
    "country_120": "Letonia",
    "country_121": "Líbano",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Lituania",
    "country_127": "Luxemburgo",
    "country_128": "Macau",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaisia",
    "country_132": "Maldivas",
    "country_133": "Malí",
    "country_134": "Malta",
    "country_135": "Illas Marshall",
    "country_136": "Martinica",
    "country_137": "Mauritania",
    "country_138": "Mauricio",
    "country_139": "Maiota",
    "country_140": "México",
    "country_141": "Moldavia",
    "country_142": "Mónaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marrocos",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Países Baixos",
    "country_153": "Nova Caledonia",
    "country_154": "Nova Zelandia",
    "country_155": "Nicaragua",
    "country_156": "Níxer",
    "country_157": "Nixeria",
    "country_158": "Niue",
    "country_159": "Illa Norfolk",
    "country_160": "Corea do Norte",
    "country_161": "Illas Marianas do Norte",
    "country_162": "Noruega",
    "country_163": "Omán",
    "country_164": "Paquistán",
    "country_165": "Palau",
    "country_166": "Territorios palestinos",
    "country_167": "Panamá",
    "country_168": "Papúa Nova Guinea",
    "country_169": "Paraguai",
    "country_170": "Perú",
    "country_171": "Illas Pitcairn",
    "country_172": "Polonia",
    "country_173": "Portugal",
    "country_174": "Porto Rico",
    "country_175": "Qatar",
    "country_176": "República de Macedonia (ARYM)",
    "country_177": "República do Congo",
    "country_178": "Reunión",
    "country_179": "Romanía",
    "country_180": "Federación Rusa",
    "country_181": "Ruanda",
    "country_182": "San Bartolomé",
    "country_183": "Saint Martin (Francia)",
    "country_184": "Saint-Pierre e Miquelón",
    "country_185": "Samoa",
    "country_186": "San Mariño",
    "country_187": "Santo Tomé e Príncipe",
    "country_188": "Arabia Saudita",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Serra Leoa",
    "country_193": "Singapur",
    "country_194": "Eslovaquia",
    "country_195": "Eslovenia",
    "country_196": "Illas Salomón",
    "country_197": "Somalia",
    "country_198": "África do Sur",
    "country_199": "Xeorxia del Sur e a Isla Sándwich Sur",
    "country_200": "Corea do sur",
    "country_201": "Sudán do Sur",
    "country_202": "España",
    "country_203": "Sri Lanka",
    "country_204": "Santa Helena e Dependencias",
    "country_205": "St. Kitts e Nevis",
    "country_206": "Santa Lucía",
    "country_207": "San Vicente e as Granadinas",
    "country_208": "Sudán",
    "country_209": "Surinam",
    "country_210": "Suacilandia",
    "country_211": "Suecia",
    "country_212": "Suíza",
    "country_213": "Siria",
    "country_214": "Taiwán",
    "country_215": "Taxiquistán",
    "country_216": "Tanzania",
    "country_217": "Modelo:Datos país SJM Svalbard",
    "country_218": "Tailandia",
    "country_219": "As Bahamas",
    "country_220": "As Comores",
    "country_221": "As Filipinas",
    "country_222": "Timor-Leste (Timor Oriental)",
    "country_223": "Ir",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trindade e Tobago",
    "country_227": "Tunisia",
    "country_228": "Turquía",
    "country_229": "Turkmenistán",
    "country_230": "Illas Turcas e Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucraína",
    "country_234": "Emiratos Árabes Unidos",
    "country_235": "Illas Menores Periféricas de Estados Unidos",
    "country_236": "Estados Unidos de América (EUA)",
    "country_237": "Illas Virxes de Estados Unidos",
    "country_238": "Uruguai",
    "country_239": "Uzbekistán",
    "country_240": "Vanuatu",
    "country_241": "Cidade do Vaticano (Santa Sé)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis e Futuna",
    "country_245": "Sáhara occidental",
    "country_246": "Iemen",
    "country_247": "Zambia",
    "country_248": "Zimbabue",
    "google_login": "Inicia sesión con Google",
    "State": "Estado",
    "Activation_code": "Código de activación",
    "Question": "Lista todas as aplicacións nas que iniciaches sesión",
    "Copy_complete": "Copia completada",
    "footer": "Copyright © 2024 MindOnMap Studio. Todos os dereitos reservados.",
    "change_password_success": "O contrasinal cambiou correctamente",
    "successful_login_title": "Éxito de inicio de sesión",
    "product_page": "Páxina do produto >>",
    "successful_login_info": "O inicio de sesión completouse. Pecha a páxina actual e volve á pestana orixinal para continuar co seguinte proceso. A páxina actual pecharase automaticamente en 5 segundos. Se o peche automático ou o peche ao facer clic no botón \"Feito\" falla, pecha esta pestana directamente.",
    "successful_login_info_firefox": "O inicio de sesión completouse. Pecha a páxina actual e volve á pestana orixinal para continuar co seguinte proceso.",
    "my_account": "A miña conta",
    "my_history": "A miña Historia",
    "remove_watermark": "Eliminar marca de auga",
    "no_history": "Sen Historia",
    "history_all": "Seleccionar todo",
    "history_open": "Aberto",
    "history_down": "Descargar",
    "history_delete": "Eliminar",
    "history_clear": "Borrar non válido",
    "images": "imaxe(s)",
    "use_this_function": "Use este produto >>",
    "hd_downloading": "Beneficios da descarga de imaxes orixinais HD:",
    "lifetimeRemaining": "Vida restante",
    "Remaining": "Restante",
    "email_verification": "Verificación de correo electrónico",
    "email_verification_info": "Enviamos a mensaxe de verificación ao teu correo electrónico <span class=\"email\"></span> e remata a verificación. Despois da verificación, os beneficios sincronizaranse automaticamente.",
    "wrong_email": "Enderezo de correo electrónico incorrecto?",
    "click_here_to_modify": "Fai clic aquí para modificar",
    "get_more_help": "Queres obter máis axuda?",
    "click_here": "Pulse AQUÍ",
    "email_verification_info_success": "Parabéns por verificar a túa conta de correo electrónico.",
    "email_verification_info_error": "Produciuse un erro na verificación porque a ligazón caducou.",
    "registration_succeeded": "Rexistro realizado correctamente",
    "registration_succeeded_info_1": "Parabéns! Rexistrácheste correctamente. Enviamos a mensaxe de verificación ao teu correo electrónico <span class=\"email\"></span> e remata de verificar para utilizar as vantaxes deste correo electrónico.",
    "registration_succeeded_info_2": "Fai clic en \"Feito\" para volver á páxina de inicio e usar este produto.",
    "registration_succeeded_info_3": "Fai clic en \"Feito\" para pechar a páxina actual e volver á páxina de inicio para as seguintes operacións. Se o peche falla, pecha manualmente a pestana.",
    "verify_email": "Verifica o correo electrónico",
    "registered_email_not_verified": "O correo electrónico rexistrado non foi verificado, verifícao inmediatamente.",
    "email_verification_time_1": "Non recibiches un correo electrónico de verificación?",
    "email_verification_time_2": "Despois",
    "email_verification_time_3": "Fai clic aquí para reenvialo",
    "error_26301": "Código de erro: 26301, póñase en contacto connosco",
    "error_26302": "Código de erro: 26302, póñase en contacto connosco",
    "error_26303": "Erro de formato de correo electrónico (código de erro: 26303). Introdúceo de novo",
    "error_26304": "Recoméndase un contrasinal de máis de 8 caracteres (código de erro: 26304)",
    "error_26305": "Fallou a solicitude de solicitude (código de erro: 26305). Téntao de novo",
    "error_26306": "O correo electrónico rexistrouse, <a href=\"https://account.mindonmap.com/login/\">vai a iniciar sesión</a>",
    "error_26307": "Produciuse un erro á solicitude (código de erro: 26307). Téntao de novo",
    "error_26308": "Produciuse un erro á solicitude (código de erro: 26308). Téntao de novo",
    "error_26401": "Código de erro: 26401, téntao de novo",
    "error_26402": "Verificouse o correo electrónico (código de erro: 26402), téntao de novo",
    "error_26403": "Produciuse un erro á solicitude (código de erro: 26403). Téntao de novo",
    "error_26404": "Produciuse un erro na solicitude (código de erro: 26404). Téntao de novo",
    "error_26501": "Código de erro: 26501, póñase en contacto connosco",
    "error_26502": "Código de erro: 26502, póñase en contacto connosco",
    "error_26503": "Erro de formato de correo electrónico (código de erro: 26503). Introdúceo de novo",
    "error_26504": "Produciuse un erro á solicitude (código de erro: 26504). Téntao de novo",
    "error_26505": "O correo electrónico non se rexistrou, <a href=\"https://account.mindonmap.com/register/\">rexistrao primeiro</a>",
    "error_26506": "Verificouse o correo electrónico.",
    "error_26507": "Fallou a solicitude de solicitude (código de erro: 26507). Téntao de novo",
    "error_26508": "A verificación foi enviada (código de erro: 26508), téntao de novo",
    "error_26509": "Produciuse un erro na solicitude (código de erro: 26509), téntao de novo",
    "error_26510": "Código de erro: 26510, póñase en contacto connosco",
    "error_26601": "Código de erro: 26601, póñase en contacto connosco",
    "error_26602": "Código de erro: 26602, póñase en contacto connosco",
    "error_26603": "Produciuse un erro á solicitude (código de erro: 26603). Téntao de novo",
    "error_26604": "Código de erro: 26604, póñase en contacto connosco",
    "error_26605": "Código de erro: 26605, póñase en contacto connosco",
    "error_26701": "Código de erro: 26701, póñase en contacto connosco",
    "error_26702": "Produciuse un erro á solicitude (código de erro: 26702). Téntao de novo",
    "error_26703": "Código de erro: 26703, póñase en contacto connosco",
    "error_26704": "Código de erro: 26704, póñase en contacto connosco",
    "error_26705": "Agarde ata iniciar sesión (código de erro: 26705). Téntao de novo",
    "no_cookie": "Activaches a función Bloquear todas as cookies no teu navegador, polo que non podes iniciar sesión. Vai a Configuración para marcar a caixa de Permitir todas as cookies.",
    "error_26801": "Código de erro: 26801, póñase en contacto connosco",
    "error_26802": "Código de erro: 26802, póñase en contacto connosco",
    "error_26803": "Produciuse un erro na solicitude (código de erro: 26803). Téntao de novo",
    "error_26804": "Produciuse un erro na solicitude (código de erro: 26804). Téntao de novo",
    "error_order": "Fallou a solicitude (código de erro: 27098), téntao de novo.",
    "error_order1": "A consulta de pedido está incompleta (código de erro: ",
    "error_order2": "）, actualízao e téntao de novo.",
    "modify_email_title": "Modificar correo electrónico",
    "modify_email_info": "Podes usar o correo electrónico modificado para iniciar sesión na túa conta.",
    "images_per": "Imaxes por",
    "error_26101": "Erro: 26101. Póñase en contacto connosco",
    "error_26102": "Erro: 26102. Póñase en contacto connosco",
    "error_26103": "A solicitude produciuse (código de erro: 26103). Téntao de novo",
    "error_26104": "Código de erro: 26104, ténteo de novo",
    "error_26105": "Código de erro: 26105, ténteo de novo",
    "error_26106": "Produciuse un error de eliminación (código de erro: 26106). Téntao de novo",
    "error_26201": "Erro: 26201. Póñase en contacto connosco",
    "error_26202": "A solicitude produciuse (código de erro: 26202). Téntao de novo",
    "error_26001": "Erro: 26001. Póñase en contacto connosco",
    "error_26002": "Erro: 26002. Póñase en contacto connosco",
    "error_26003": "Erro: 26003. Póñase en contacto connosco",
    "error_26004": "Erro: 26004. Póñase en contacto connosco",
    "error_26005": "A solicitude produciuse (código de erro: 26005). Téntao de novo",
    "error_26006": "Código de erro: 26006, ténteo de novo",
    "error_26008": "Erro: 26008. Póñase en contacto connosco",
    "go_to_the_home_page": "Vaia á páxina de inicio",
    "error_27101": "Produciuse un erro na solicitude (código de erro: 27101). Téntao de novo",
    "error_27201": "Código de erro: 27201, póñase en contacto connosco",
    "error_27202": "Código de erro: 27202, téntao de novo",
    "error_27203": "Produciuse un erro na solicitude (código de erro: 27203). Téntao de novo",
    "error_27204": "Código non válido (código de erro: 27204).",
    "error_27205": "Produciuse un erro na solicitude (código de erro: 27205). Téntao de novo",
    "error_27206": "Produciuse un erro na solicitude (código de erro: 27206). Téntao de novo",
    "error_27207": "Produciuse un erro na solicitude (código de erro: 27207). Téntao de novo",
    "no_history_found": "Non usaches ningunha ferramenta! <a href=\"/\">Actualizar</a> ou <a href=\"https://www.mindonmap.com/\">Accede ao sitio web oficial</a>",
    "error_25301": "Erro: 25301. Póñase en contacto connosco",
    "error_25302": "Erro: 25302. Póñase en contacto connosco",
    "error_25303": "A solicitude produciuse (código de erro: 25303). Téntao de novo",
    "error_25304": "A solicitude produciuse (código de erro: 25304). Téntao de novo",
    "error_25305": "A solicitude produciuse (código de erro: 25305). Téntao de novo",
    "error_25306": "A solicitude produciuse (código de erro: 25306). Téntao de novo",
    "image_upscaler_p": "Beneficios da descarga de imaxes sen marca de auga:",
    "Available_for": "Dispoñible para:",
    "credit_per": "%s crédito(s) por imaxe HD",
    "still_valid": "Os plans comprados aínda son válidos",
    "credit": "crédito(s)",
    "pc_3rd_info": "Inicia sesión correctamente. Vaia á aplicación para seguir operando.",
    "use_online": "Use o servizo en liña",
    "use_download": "Use o programa de escritorio",
    "use_immediately": "Use Inmediatamente",
    "Use_in_browser": "Usar no navegador",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} créditos ao mes",
    "expire": "Tempo de caducidade:",
    "viewDetails": "Ver detalles",
    "viewHistory": "Ver os beneficios do historial >>",
    "viewDetailsInfo": "Aviso: se os beneficios da subscrición se renovan dentro dos 7 días posteriores ao vencemento, os beneficios non utilizados poden seguir utilizándose. Ademais, o tempo de caducidade actualizarase automaticamente ao tempo de caducidade da nova subscrición. Se non hai ningunha nova subscrición despois de 7 días de vencemento, borraranse todos os beneficios caducados.",
    "connect_account": "Vincula o correo electrónico á túa conta",
    "connect_account_info": "Despois de vincular, pode iniciar sesión con este enderezo de correo electrónico.",
    "connect_now": "Enlazar agora",
    "no_email_bind": "Sen enlace de correo electrónico",
    "bind_email": "Enlazar correo electrónico",
    "connect_your_email_placeholder": "Introduce o teu enderezo de correo electrónico",
    "bind_an_email": "Vincular un correo electrónico",
    "bind_info": "Iniciaches sesión correctamente. Enlaza un correo electrónico á túa conta para activar os teus beneficios.",
    "bind_later": "Enlazar máis tarde",
    "hi": "Ola, %s!",
    "Personal_Information": "Información persoal",
    "Access": "Acceso",
    "Subscription_Plan": "(Plan de subscrición)",
    "No_orders": "Non se atoparon pedidos.",
    "No_data": "Sen datos",
    "Featured_Products": "Produtos destacados",
    "More_Products": "Máis produtos",
    "Free_Download": "Descarga gratuíta",
    "Get_Started": "Comezar",
    "Subscribe": "Subscríbete",
    "Verified": "Verificado",
    "back_to_account_center": "Volver ao Centro de contas",
    "success": "Éxito!",
    "successfully": "Rexistraches unha conta con éxito.",
    "Continue": "Continuar",
    "Already": "Xa ten unha conta?",
    "loading_verification": "Comprobando o estado de verificación...",
    "email_no_verification": "Sentímolo, o enderezo de correo electrónico rexistrado non foi verificado. Complete a verificación segundo as instrucións anteriores e prema de novo no botón \"Feito\" para completar o rexistro.",
    "will_expire_after": "Caducará despois",
    "hours": "horas",
  },
  "gu": {
    "overtime": "ભૂલ કોડ: {%}, કૃપા કરીને ફરી પ્રયાસ કરો",
    "isnetwork": "ઇન્ટરનેટ ભૂલ. કૃપા કરીને તપાસો અને ફરી પ્રયાસ કરો",
    "email_placeholder": "ઈમેલ",
    "email_empty": "કૃપા કરીને ઈમેલ દાખલ કરો",
    "email_not_valid": "ઈમેલ માન્ય નથી",
    "email_not_valid_1": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો",
    "email_not_valid_2": "ઈમેલ માન્ય નથી, કૃપા કરીને કોઈ અલગ સરનામું વાપરો.",
    "email_not_valid_3": "કોઈ ઇમેઇલ ઇનપુટ નથી",
    "password_placeholder": "પાસવર્ડ",
    "password_empty": "કૃપા કરીને પાસવર્ડ દાખલ કરો",
    "password_not_valid": "ખોટો એકાઉન્ટ અથવા પાસવર્ડ",
    "password_not_valid_1": "8 અક્ષરોથી ઉપરનો પાસવર્ડ જરૂરી છે",
    "password_not_valid_2": "કૃપા કરીને પાસવર્ડ બનાવો",
    "password_placeholder_1": "તમારો પાસવર્ડ બનાવો",
    "password_placeholder_2": "તમારા પાસવર્ડની ખાતરી કરો",
    "password_placeholder_3": "નવો પાસવર્ડ બનાવો",
    "password_placeholder_4": "નવાપાસવર્ડની પુષ્ટી કરો",
    "password_placeholder_5": "જૂનો પાસવર્ડ દાખલ કરો",
    "copy_password_empty": "કૃપા કરીને પાસવર્ડની પુષ્ટિ કરો",
    "copy_password_not_valid": "કૃપા કરીને તમારા પાસવર્ડની પુષ્ટિ કરો",
    "copy_passwords_inconsistent": "તમારો પાસવર્ડ કન્ફર્મેશન મેળ ખાતો નથી",
    "code_empty": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો",
    "code_not_valid": "અમાન્ય ચકાસણી કોડ",
    "code_placeholder": "ચકાસણી કોડ",
    "not_received_code": "જો તમારા મેઈલબોક્સને લાંબા સમયથી ચકાસણી કોડ મળ્યો નથી, તો કૃપા કરીને ફરીથી ચકાસણી કોડ મેળવો.",
    "get_first_code": "કૃપા કરીને પહેલા ચકાસણી કોડ મેળવો.",
    "last_name_placeholder": "કૃપા કરીને તમારું છેલ્લું નામ દાખલ કરો",
    "first_name_placeholder": "કૃપા કરીને તમારું પ્રથમ નામ દાખલ કરો",
    "address_placeholder": "કૃપા કરીને તમારું સરનામું દાખલ કરો",
    "no_code_text": "અમે ચકાસણી કોડ મોકલ્યો છે. કૃપા કરીને તમારો કોડ દાખલ કરો. <span class='tips'>કોડ મળ્યો નથી?",
    "no_code_text_1": "1. કૃપા કરીને ખાતરી કરો કે ઇમેઇલ સરનામું માન્ય છે અને તે ઇમેઇલ્સ પ્રાપ્ત કરી શકે છે.",
    "no_code_text_2": "2. ઈમેલ સિસ્ટમ દ્વારા આપમેળે મોકલવામાં આવે છે, તે સ્પામ અથવા જંક ઈમેલ તરીકે ફ્લેગ કરવામાં આવી શકે છે. કૃપા કરીને તપાસો કે ઇમેઇલ ટ્રેશ ફોલ્ડરમાં છે કે કેમ.",
    "no_code_text_3": "3. તમારી સમસ્યા હલ કરી શકતા નથી? ",
    "no_code_text_3_span": "પછી અમારો સંપર્ક કરવા માટે અહીં ક્લિક કરો.",
    "order_no": "તમે કોઈપણ ઉત્પાદન ખરીદ્યું નથી, જો તમને કોઈ પ્રશ્ન હોય, તો કૃપા કરીને <a href=\"https://www.mindonmap.com/contact-us/\">અમારો સંપર્ક કરો</a>.",
    "error_24901": "વર્તમાન ખાતામાં કોઈ ઈમેઈલ લિંક થયેલ નથી, અને ઓર્ડર શોધી શકતા નથી. કૃપા કરીને એક ઇમેઇલ કનેક્ટ કરો.",
    "user_guide": "વપરાશકર્તા માર્ગદર્શિકા >>",
    "download": "ડાઉનલોડ કરો",
    "order_number": "ઓર્ડર નંબર:",
    "Refund": "પરત કરેલી રકમ",
    "Disabled": "અક્ષમ",
    "Normal": "સામાન્ય",
    "Modify": "ફેરફાર કરો",
    "Modify_1": "ફેરફાર કરો>>",
    "Connect": "જોડાવા",
    "unlink_success": "સફળતાપૂર્વક અનલિંક કરો",
    "connect_success": "સફળતાપૂર્વક કનેક્ટ કરો",
    "feedback_title": "તમારા પ્રતિભાવ બદલ આભાર. કૃપા કરીને તમારી સમસ્યા છોડો અને અમે તમને 24 કલાકની અંદર જવાબ આપીશું.",
    "feedback_thank_you": "આભાર!<br />તમારો પ્રતિસાદ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યો છે.",
    "feedback_email": "અહીં તમારું ઇમેઇલ દાખલ કરો!",
    "feedback_content": "તમને આવી હોય તે કોઈપણ સમસ્યા અથવા સૂચન અહીં મૂકો.",
    "feedback_submit": "સબમિટ કરો",
    "form_contents": "તમે કોઈપણ વર્ણન દાખલ કર્યું નથી. કૃપા કરીને તેને દાખલ કરો અને ફરીથી સબમિટ કરો.",
    "old_password": "કૃપા કરીને જૂનો પાસવર્ડ દાખલ કરો",
    "new_password": "કૃપા કરીને નવો પાસવર્ડ બનાવો",
    "old_new_password": "નવો પાસવર્ડ જૂના જેવો હોઈ શકે નહીં",
    "incorrect_password": "ખોટો પાસવર્ડ",
    "delete_no": "હમણાં કાઢી નાખો",
    "Caps": "કેપ્સ લોક ચાલુ છે",
    "Get": "મેળવો",
    "Done": "થઈ ગયું",
    "error_20001": "ભૂલ: 20001. કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20002": "ભૂલ: 20002. કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20003": "ભૂલ: 20003. કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20004": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20004). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_20005": "લોગિન સત્ર સમાપ્ત થઈ ગયું છે (ભૂલ: 20005). કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20006": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20006). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_20007": "લૉગિન સત્ર સમાપ્ત થઈ ગયું છે (ભૂલ: 20007). કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20008": "લોગિન સત્ર સમાપ્ત થઈ ગયું છે (ભૂલ: 20008). કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20009": "લૉગિન સત્ર સમાપ્ત થઈ ગયું છે (ભૂલ: 20009). કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_20101": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20101)",
    "error_20102": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20102)",
    "error_20103": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20103). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20104": "ઈમેલ પહેલેથી ઉપયોગમાં છે, કૃપા કરીને <a href=\"https://account.mindonmap.com/login/\">લોગ ઇન કરો</a> અથવા નવા સાથે નોંધણી કરો",
    "error_20105": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20105). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20106": "ઇમેઇલ મોકલવામાં નિષ્ફળ, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20201": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20201)",
    "error_20202": "કૃપા કરીને તમારો પાસવર્ડ દાખલ કરો (ભૂલ કોડ: 20202)",
    "error_20203": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો (ભૂલ કોડ: 20203)",
    "error_20204": "ઇમેઇલ માન્ય નથી (ભૂલ કોડ: 20204)",
    "error_20205": "8 અક્ષરોથી ઉપરનો પાસવર્ડ જરૂરી છે (ભૂલ કોડ: 20205)",
    "error_20206": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20206). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20207": "અમાન્ય ચકાસણી કોડ",
    "error_20208": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20208). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20209": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20209). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20301": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20301)",
    "error_20302": "ભૂલ: 20302. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_20303": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20303)",
    "error_20304": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20304). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20305": "એકાઉન્ટ અસ્તિત્વમાં નથી. કૃપા કરીને ફરી દાખલ કરો અથવા પહેલા <a href=\"https://account.mindonmap.com/register/\">તે બનાવો</a>.",
    "error_20306": "હજુ સુધી કોઈ પાસવર્ડ નથી. <a href=\"https://account.mindonmap.com/passwordless-login/\">પાસવર્ડલેસ લોગિન</a> અથવા <a href=\"https://account.mindonmap.com/create-password/\"> નો ઉપયોગ કરો પાસવર્ડ સેટ કરો</a> અને લોગ ઇન કરો.",
    "error_20308": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20308). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20401": "લોગ આઉટ કરવામાં નિષ્ફળ (ભૂલ કોડ: 20401). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20501": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20501)",
    "error_20502": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20502)",
    "error_20503": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20503). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20504": "ઇમેઇલ મોકલવામાં નિષ્ફળ. કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_20601": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20601)",
    "error_20602": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો (ભૂલ કોડ: 20602)",
    "error_20603": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20603)",
    "error_20604": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20604). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20606": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20606). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20607": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20607). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20608": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20608). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20701": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20701)",
    "error_20702": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20702)",
    "error_20703": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20703). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20704": "એકાઉન્ટ અસ્તિત્વમાં નથી. કૃપા કરીને ફરી દાખલ કરો અથવા પહેલા <a href=\"https://account.mindonmap.com/register/\">તે બનાવો</a>.",
    "error_20705": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20705). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20706": "ઇમેઇલ મોકલવામાં નિષ્ફળ. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20801": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 20801)",
    "error_20802": "ભૂલ: 20802. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_20803": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો (ભૂલ કોડ: 20803)",
    "error_20804": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 20804)",
    "error_20805": "8 અક્ષરોથી ઉપરનો પાસવર્ડ જરૂરી છે (ભૂલ કોડ: 20805)",
    "error_20806": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20806). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20808": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20808). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20901": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20901). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20902": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 20902). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21000": "ફેરફારો સાચવવામાં આવે છે",
    "error_21001": "કોઈ માહિતી સબમિટ કરવામાં આવી નથી (ભૂલ કોડ: 21001)",
    "error_21002": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21002). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21101": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 21101)",
    "error_21102": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 21102)",
    "error_21103": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21103), કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21104": "ઈમેલ પહેલેથી જ જોડાયેલ છે, કૃપા કરીને એક નવો ઉપયોગ કરો",
    "error_21105": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21105), કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21106": "ઇમેઇલ મોકલવામાં નિષ્ફળ. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21201": "કૃપા કરીને તમારો ઈમેલ દાખલ કરો (ભૂલ કોડ: 21201)",
    "error_21202": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો (ભૂલ કોડ: 21202)",
    "error_21203": "ઈમેલ માન્ય નથી (ભૂલ કોડ: 21203)",
    "error_21204": "ભૂલ: 21204. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21205": "ભૂલ: 21205. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21206": "8 અક્ષરોથી ઉપરનો પાસવર્ડ જરૂરી છે (ભૂલ કોડ: 21206)",
    "error_21207": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21207). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21209": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21209). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21301": "કૃપા કરીને જૂનો પાસવર્ડ દાખલ કરો (ભૂલ કોડ: 21301)",
    "error_21302": "કૃપા કરીને નવો પાસવર્ડ બનાવો (ભૂલ કોડ: 21302)",
    "error_21303": "નવો પાસવર્ડ જૂના જેવો હોઈ શકે નહીં. (ભૂલ: 21303)",
    "error_21304": "8 અક્ષરોથી ઉપરનો પાસવર્ડ જરૂરી છે (ભૂલ કોડ: 21304)",
    "error_21306": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21306). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21402": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21402). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21403": "ચકાસણી કોડ મોકલવામાં નિષ્ફળ. કૃપા કરીને તેને ફરીથી મોકલો",
    "error_21500": "એકાઉન્ટ કાઢી નાખવામાં આવ્યું છે",
    "error_21501": "કૃપા કરીને ચકાસણી કોડ ઇનપુટ કરો (ભૂલ કોડ: 21501)",
    "error_21502": "લોગિન સત્ર સમાપ્ત થઈ ગયું છે (ભૂલ: 21502). કૃપા કરીને ફરીથી લોગ ઇન કરો.",
    "error_21503": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21503). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21505": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21505), કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21601": "ભૂલ: 20601. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21602": "અમાન્ય ચકાસણી (ભૂલ: 20602). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_21603": "ભૂલ: 20603. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21604": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21604). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21606": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21606). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21611": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21611). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21801": "ભૂલ: 21801. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21802": "વિનંતી નિષ્ફળ થઈ (ભૂલ: 21802). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21803": "ભૂલ: 21803. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21804": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21804). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21806": "ભૂલ: 21806. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21807": "ભૂલ: 21807. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21808": "ભૂલ: 21808. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21809": "ભૂલ: 21809. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21810": "ભૂલ: 21810. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21811": "ભૂલ: 21811. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21812": "ભૂલ: 21812. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21813": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21813). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21814": "ભૂલ: 21814. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21815": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21815). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21816": "ભૂલ: 21816. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21817": "ભૂલ: 21817. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21818": "ભૂલ: 21818. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21819": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21819). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21820": "ભૂલ: 21820. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21821": "ભૂલ: 21821. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21822": "ભૂલ: 21822. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21823": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21823). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21824": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21824). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21825": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21825). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21826": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21826). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21901": "ભૂલ: 21901. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_21902": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21902). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21903": "એકાઉન્ટની સ્થિતિ બદલાઈ ગઈ છે (ભૂલ કોડ: 21903), કૃપા કરીને પૃષ્ઠને તાજું કરો અને ફરીથી પ્રયાસ કરો",
    "error_21904": "ભૂલ: 21904. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21905": "ભૂલ: 21905. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21906": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21906). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_21907": "Google એકાઉન્ટ અન્ય એકાઉન્ટ સાથે લિંક કરવામાં આવ્યું છે",
    "error_21908": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 21908). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22001": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22001). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22002": "કોઈ વધારાના લૉગિન વિના અનલિંક નિષ્ફળ થયું",
    "error_22003": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22003). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22101": "ભૂલ: 22101. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22102": "એકાઉન્ટની સ્થિતિ બદલાઈ ગઈ છે (ભૂલ કોડ: 22102), કૃપા કરીને પૃષ્ઠને તાજું કરો અને ફરીથી પ્રયાસ કરો",
    "error_22103": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22103). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22104": "એકાઉન્ટની સ્થિતિ બદલાઈ ગઈ છે (ભૂલ કોડ: 22104), કૃપા કરીને પૃષ્ઠને તાજું કરો અને ફરીથી પ્રયાસ કરો",
    "error_22105": "ભૂલ: 22105. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22106": "ભૂલ: 22106. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22107": "ભૂલ: 22107. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22108": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22108). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22201": "ભૂલ: 22201. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22202": "અમાન્ય ચકાસણી (ભૂલ: 22202). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_22203": "ભૂલ: 22203. કૃપા કરીને ફરી પ્રયાસ કરો\"",
    "error_22204": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22204). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22206": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22206). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22401": "ભૂલ: 22401. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22402": "અમાન્ય ચકાસણી (ભૂલ: 22402). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_22403": "ભૂલ: 22403. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22404": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22404). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22405": "ફેસબુક એકાઉન્ટ અન્ય ઈમેલ સાથે લિંક કરવામાં આવ્યું છે",
    "error_22406": "ભૂલ: 22406. કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22407": "ભૂલ: 22407. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22408": "ભૂલ: 22408. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22409": "ભૂલ: 22409. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22410": "ભૂલ: 224010. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22411": "ભૂલ: 224011. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22412": "ભૂલ: 224012. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22413": "ભૂલ: 22413. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22414": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22414). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22415": "ભૂલ: 22415. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22416": "ભૂલ: 22416. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22417": "ભૂલ: 22417. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22418": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22418). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22419": "ભૂલ: 22419. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22420": "ભૂલ: 22420. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22421": "ભૂલ: 22421. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_22422": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22422). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22423": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22423). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22424": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22424). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_22425": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 22425). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_20098": "ભૂલ કોડ: 20098. જો તમે ખાનગી મોડમાં બ્રાઉઝ કરી રહ્યાં છો, તો કૃપા કરીને સામાન્ય મોડ પર સ્વિચ કરો અને ફરી પ્રયાસ કરો.",
    "error_22298": "Google માટેની વિનંતી નિષ્ફળ થઈ (ભૂલ: 22298). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_22498": "ફેસબુક માટેની વિનંતી નિષ્ફળ થઈ (ભૂલ: 22498). કૃપા કરીને ફરી પ્રયાસ કરો.",
    "error_24902": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 24902). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_24903": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 24903). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_24904": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 24904). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_24905": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 24905). કૃપા કરીને ફરી પ્રયાસ કરો",
    "login_title": "MindOnMap પર લૉગ ઇન કરો",
    "log_in": "પ્રવેશ કરો",
    "no_account": "ખાતું નથી?",
    "create_it": "તેને બનાવો",
    "or_log_in_with": "અથવા સાથે લોગ ઇન કરો",
    "passwordless_login": "પાસવર્ડલેસ લોગિન",
    "log_in_done": "લોગ ઈન થઈ ગયું",
    "three_rd_account_connect_info": "અભિનંદન! તમે સફળતાપૂર્વક લૉગ ઇન થયા છો. હવે તમે એક ઈમેલ એકાઉન્ટ કનેક્ટ કરી શકો છો જેનો ઉપયોગ ભવિષ્યમાં લોગ ઇન કરવા માટે થાય છે.",
    "see_my_account": "મારું એકાઉન્ટ જુઓ",
    "three_rd_login_merge_account": "તૃતીય-પક્ષ એકાઉન્ટ ઇમેઇલ સરનામું સાઇન અપ કરવામાં આવ્યું છે, શું તમે સીધા આ ઇમેઇલ સરનામાં સાથે કનેક્ટ કરવા અને લોગ ઇન કરવા માંગો છો?",
    "connect_log_in": "કનેક્ટ કરો અને લોગ ઇન કરો",
    "create_an_account": "એક ખાતુ બનાવો",
    "back_to_log_in": "લૉગ ઇન પર પાછા જાઓ",
    "create_password": "પાસવર્ડ બનાવો",
    "create_now": "હમણાં બનાવો",
    "password_login_subtitle": "ઈમેલ સાથે પાસવર્ડ વગરનું લોગીન",
    "account_login": "એકાઉન્ટ લૉગિન",
    "rights": "આ એકાઉન્ટ બનાવીને, તમે <a href=\"https://www.mindonmap.com/terms-and-conditions/\">સેવાની શરતો</a> અને <a href=\"https://www.mindonmap.com/privacy-policy/\">ગોપનીયતા નીતિ</a>",
    "passwordless_login_done": "પાસવર્ડલેસ લોગિન થઈ ગયું",
    "passwordless_login_info": "અભિનંદન, તમે સફળતાપૂર્વક પાસવર્ડ રહિત લૉગિન પૂર્ણ કર્યું છે. તમે આ એકાઉન્ટ માટે પાસવર્ડ બનાવી શકો છો અને ભવિષ્યમાં એકાઉન્ટ અને પાસવર્ડ વડે લૉગ ઇન કરી શકો છો. <a href=\"/create-password\" style=\"display: initial;\">હવે બનાવો</a>",
    "sign_up": "સાઇન અપ કરો",
    "register_info": "તમારું એકાઉન્ટ બનાવો",
    "reset_now": "હવે રીસેટ કરો",
    "forgot_password": "પાસવર્ડ ભૂલી ગયા છો",
    "reset_password_subtitle": "પાસવર્ડ રીસેટ કરવા માટે તમારા એકાઉન્ટ ઈમેલનો ઉપયોગ કરો",
    "plan_products": "યોજનાઓ અને ઉત્પાદનો",
    "nick_name": "વપરાશકર્તા નામ:",
    "email": "ઈમેલ:",
    "my_products": "મારા ઉત્પાદનો",
    "my_orders": "મારા ઓર્ડર્સ",
    "unlink": "અનલિંક કરો",
    "link": "લિંક",
    "connected_accounts": "કનેક્ટેડ એકાઉન્ટ્સ",
    "last_name": "છેલ્લું નામ:",
    "first_name": "પ્રથમ નામ:",
    "Gender": "લિંગ:",
    "Birth": "જન્મ:",
    "Month": "માસ",
    "Year": "વર્ષ",
    "Country_Region": "દેશ/પ્રદેશ:",
    "Address": "સરનામું:",
    "Save": "સાચવો",
    "Date": "તારીખ",
    "Male": "પુરુષ",
    "Female": "સ્ત્રી",
    "Unspecified": "અસ્પષ્ટ",
    "Security": "સુરક્ષા",
    "change_password": "પાસવર્ડ બદલો",
    "change_now": "હવે બદલો",
    "connect_email": "ઇમેઇલ કનેક્ટ કરો",
    "delete_account": "એકાઉન્ટ કાઢી નાખો",
    "delete_account_info": "જ્યારે તમારું એકાઉન્ટ કાઢી નાખવામાં આવે છે, ત્યારે તમારા એકાઉન્ટ સાથે સંકળાયેલ મારા એકાઉન્ટમાંનો તમામ ડેટા કાયમ માટે કાઢી નાખવામાં આવશે અને તમે તેને પુનઃપ્રાપ્ત કરી શકશો નહીં. અમે તમને સાવચેતીપૂર્વક ચાલવાની ભલામણ કરીએ છીએ.",
    "Delete": "કાઢી નાખો",
    "Logout": "લૉગ આઉટ",
    "my_profile": "મારી પ્રોફાઈલ",
    "guides_faqs": "માર્ગદર્શિકાઓ અને FAQs",
    "More": "વધુ",
    "guides": "માર્ગદર્શિકાઓ",
    "register": "નોંધણી કરો",
    "hot_faq": "હોટ FAQ",
    "Contents": "સામગ્રી:",
    "contact_us": "અમારો સંપર્ક કરો >>",
    "plan": "યોજના",
    "unregistered": "નોંધાયેલ નથી",
    "buy_more": "વધુ ખરીદો",
    "buy_again": "ફરીથી ખરીદો",
    "buy_now": "હમણાં જ ખરીદો",
    "free_no_limit": "મફત અને કોઈ મર્યાદા નથી",
    "expired": "સમાપ્ત",
    "lifetime": "આજીવન",
    "remain": "રહે",
    "day_s": "દિવસ)",
    "error_24801": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 24801). કૃપા કરીને ફરી પ્રયાસ કરો",
    "no_app_found": "કોઈ ઍપ મળી નથી!<a href=\"/\">તાજું કરો</a> અથવા <a href=\"https://www.mindonmap.com/\">અધિકૃત વેબસાઇટ પર જાઓ</a>",
    "get_more": "વધુ મેળવો >>",
    "edit_photo": "ફોટો સંપાદિત કરો",
    "select_photo": "ફોટો પસંદ કરો",
    "change_photo": "ફોટો બદલ",
    "cancel": "રદ કરો",
    "hide_password": "પાસવર્ડ છુપાવો",
    "show_password": "પાસવર્ડ બતાવો",
    "zoom_in": "મોટું કરો",
    "zoom_out": "ઝૂમ આઉટ કરો",
    "rotate": "ફેરવો",
    "horizontal_flip": "આડી ફ્લિપ",
    "vertical_flip": "વર્ટિકલ ફ્લિપ",
    "country": "દેશ",
    "country_1": "તમારો દેશ/પ્રદેશ પસંદ કરો",
    "country_2": "આલેન્ડ ટાપુ",
    "country_3": "અફઘાનિસ્તાન",
    "country_4": "અલ્બેનિયા",
    "country_5": "અલ્જેરિયા",
    "country_6": "અમેરિકન સમોઆ",
    "country_7": "એન્ડોરા",
    "country_8": "અંગોલા",
    "country_9": "એન્ગ્વિલા",
    "country_10": "એન્ટાર્કટિકા",
    "country_11": "એન્ટિગુઆ અને બાર્બુડા",
    "country_12": "આર્જેન્ટિના",
    "country_13": "આર્મેનિયા",
    "country_14": "અરુબા",
    "country_15": "ઓસ્ટ્રેલિયા",
    "country_16": "ઑસ્ટ્રિયા",
    "country_17": "અઝરબૈજાન",
    "country_18": "બહેરીન",
    "country_19": "બાંગ્લાદેશ",
    "country_20": "બાર્બાડોસ",
    "country_21": "બેલારુસ",
    "country_22": "બેલ્જિયમ",
    "country_23": "બેલીઝ",
    "country_24": "બેનિન",
    "country_25": "બર્મુડા",
    "country_26": "ભુતાન",
    "country_27": "બોલિવિયા",
    "country_28": "બોસ્નિયા અને હર્ઝેગોવિના",
    "country_29": "બોત્સ્વાના",
    "country_30": "બુવેટ ટાપુ",
    "country_31": "બ્રાઝિલ",
    "country_32": "બ્રિટિશ ભારતીય મહાસાગર પ્રદેશ",
    "country_33": "બ્રિટિશ વર્જિન ટાપુઓ",
    "country_34": "બ્રુનેઈ",
    "country_35": "બલ્ગેરિયા",
    "country_36": "બુર્કિના",
    "country_37": "બુરુન્ડી",
    "country_38": "કંબોડિયા",
    "country_39": "કેમરૂન",
    "country_40": "કેનેડા",
    "country_41": "કેપ વર્ડે",
    "country_42": "કેરેબિયન નેધરલેન્ડ",
    "country_43": "કેમેન ટાપુઓ",
    "country_44": "મધ્ય આફ્રિકન રિપબ્લિક",
    "country_45": "ચાડ",
    "country_46": "ચિલી",
    "country_47": "ચીન",
    "country_48": "ક્રિસમસ ટાપુ",
    "country_49": "કોકોસ (કીલિંગ) ટાપુઓ",
    "country_50": "કોલંબિયા",
    "country_51": "કૂક ટાપુઓ",
    "country_52": "કોસ્ટા રિકા",
    "country_53": "કોટ ડી'આઇવોર",
    "country_54": "ક્રોએશિયા",
    "country_55": "ક્યુબા",
    "country_56": "સાયપ્રસ",
    "country_57": "ચેક રિપબ્લિક",
    "country_58": "ડેમોક્રેટિક રિપબ્લિક ઑફ ધ કૉંગો",
    "country_59": "ડેનમાર્ક",
    "country_60": "જીબુટી",
    "country_61": "ડોમિનિકા",
    "country_62": "ડોમિનિકન રિપબ્લિક",
    "country_63": "એક્વાડોર",
    "country_64": "ઇજિપ્ત",
    "country_65": "એલ સાલ્વાડોર",
    "country_66": "વિષુવવૃત્તીય ગિની",
    "country_67": "એરિટ્રિયા",
    "country_68": "એસ્ટોનિયા",
    "country_69": "ઇથોપિયા",
    "country_70": "ફોકલેન્ડ ટાપુઓ",
    "country_71": "ફેરો ટાપુઓ",
    "country_72": "ફેડરેટેડ સ્ટેટ્સ ઑફ માઈક્રોનેશિયા",
    "country_73": "ફીજી",
    "country_74": "ફિનલેન્ડ",
    "country_75": "ફ્રાન્સ",
    "country_76": "ફ્રેન્ચ ગુયાના",
    "country_77": "ફ્રેન્ચ પોલિનેશિયા",
    "country_78": "ફ્રેન્ચ દક્ષિણી પ્રદેશો",
    "country_79": "ગેબોન",
    "country_80": "ગેમ્બિયા",
    "country_81": "જ્યોર્જિયા",
    "country_82": "જર્મની",
    "country_83": "ઘાના",
    "country_84": "જીબ્રાલ્ટર",
    "country_85": "ગ્રેટ બ્રિટન (યુનાઇટેડ કિંગડમ; ઇંગ્લેન્ડ)",
    "country_86": "ગ્રીસ",
    "country_87": "ગ્રીનલેન્ડ",
    "country_88": "ગ્રેનાડા",
    "country_89": "ગ્વાડેલુપ",
    "country_90": "ગુઆમ",
    "country_91": "ગ્વાટેમાલા",
    "country_92": "ગર્નસી",
    "country_93": "ગિની",
    "country_94": "ગિની-બિસાઉ",
    "country_95": "ગયાના",
    "country_96": "હૈતી",
    "country_97": "હર્ડ આઇલેન્ડ અને મેકડોનાલ્ડ આઇલેન્ડ્સ",
    "country_98": "હોન્ડુરાસ",
    "country_99": "હોંગ કોંગ",
    "country_100": "હંગેરી",
    "country_101": "આઇસલેન્ડ",
    "country_102": "ભારત",
    "country_103": "ઈન્ડોનેશિયા",
    "country_104": "ઈરાન",
    "country_105": "ઈરાક",
    "country_106": "આયર્લેન્ડ",
    "country_107": "આઈલ ઓફ મેન",
    "country_108": "ઇઝરાયેલ",
    "country_109": "ઇટાલી",
    "country_110": "જમૈકા",
    "country_111": "જાપાન",
    "country_112": "જર્સી",
    "country_113": "જોર્ડન",
    "country_114": "કઝાકિસ્તાન",
    "country_115": "કેન્યા",
    "country_116": "કિરીબાતી",
    "country_117": "કુવૈત",
    "country_118": "કિર્ગિસ્તાન",
    "country_119": "લાઓસ",
    "country_120": "લાતવિયા",
    "country_121": "લેબનોન",
    "country_122": "લેસોથો",
    "country_123": "લાઇબેરિયા",
    "country_124": "લિબિયા",
    "country_125": "લિક્ટેનસ્ટેઇન",
    "country_126": "લિથુઆનિયા",
    "country_127": "લક્ઝમબર્ગ",
    "country_128": "મકાઉ",
    "country_129": "મેડાગાસ્કર",
    "country_130": "માલાવી",
    "country_131": "મલેશિયા",
    "country_132": "માલદીવ",
    "country_133": "માલી",
    "country_134": "માલ્ટા",
    "country_135": "માર્શલ ટાપુઓ",
    "country_136": "માર્ટીનિક",
    "country_137": "મોરિટાનિયા",
    "country_138": "મોરેશિયસ",
    "country_139": "મેયોટ",
    "country_140": "મેક્સિકો",
    "country_141": "મોલ્ડોવા",
    "country_142": "મોનાકો",
    "country_143": "મંગોલિયા",
    "country_144": "મોન્ટેનેગ્રો",
    "country_145": "મોન્ટસેરાત",
    "country_146": "મોરોક્કો",
    "country_147": "મોઝામ્બિક",
    "country_148": "મ્યાનમાર (બર્મા)",
    "country_149": "નામિબિયા",
    "country_150": "નૌરુ",
    "country_151": "નેપાળ",
    "country_152": "નેધરલેન્ડ",
    "country_153": "ન્યુ કેલેડોનિયા",
    "country_154": "ન્યૂઝીલેન્ડ",
    "country_155": "નિકારાગુઆ",
    "country_156": "નાઇજર",
    "country_157": "નાઇજીરીયા",
    "country_158": "નિયુ",
    "country_159": "નોર્ફોક ટાપુ",
    "country_160": "ઉત્તર કોરીયા",
    "country_161": "ઉત્તરી મારિયાના ટાપુઓ",
    "country_162": "નોર્વે",
    "country_163": "ઓમાન",
    "country_164": "પાકિસ્તાન",
    "country_165": "પલાઉ",
    "country_166": "પેલેસ્ટિનિયન પ્રદેશો",
    "country_167": "પનામા",
    "country_168": "પાપુઆ ન્યુ ગિની",
    "country_169": "પેરાગ્વે",
    "country_170": "પેરુ",
    "country_171": "પિટકેર્ન ટાપુઓ",
    "country_172": "પોલેન્ડ",
    "country_173": "પોર્ટુગલ",
    "country_174": "પ્યુઅર્ટો રિકો",
    "country_175": "કતાર",
    "country_176": "મેસેડોનિયા રિપબ્લિક (FYROM)",
    "country_177": "રિપબ્લિક ઓફ કોંગો",
    "country_178": "રિયુનિયન",
    "country_179": "રોમાનિયા",
    "country_180": "રશિયન ફેડરેશન",
    "country_181": "રવાન્ડા",
    "country_182": "સેન્ટ બાર્થેલેમી",
    "country_183": "સેન્ટ માર્ટિન (ફ્રાન્સ)",
    "country_184": "સેન્ટ-પિયર અને મિકેલન",
    "country_185": "સમોઆ",
    "country_186": "સાન મેરિનો",
    "country_187": "સાઓ ટોમ અને પ્રિન્સિપે",
    "country_188": "સાઉદી અરેબિયા",
    "country_189": "સેનેગલ",
    "country_190": "સર્બિયા",
    "country_191": "સેશેલ્સ",
    "country_192": "સિએરા લિયોન",
    "country_193": "સિંગાપોર",
    "country_194": "સ્લોવેકિયા",
    "country_195": "સ્લોવેનિયા",
    "country_196": "સોલોમન ટાપુઓ",
    "country_197": "સોમાલિયા",
    "country_198": "દક્ષિણ આફ્રિકા",
    "country_199": "દક્ષિણ જ્યોર્જિયા અને દક્ષિણ સેન્ડવિચ ઇસલા",
    "country_200": "દક્ષિણ કોરિયા",
    "country_201": "દક્ષિણ સુદાન",
    "country_202": "સ્પેન",
    "country_203": "શ્રિલંકા",
    "country_204": "સેન્ટ હેલેના અને ડિપેન્ડન્સી",
    "country_205": "સેન્ટ કિટ્સ અને નેવિસ",
    "country_206": "સેન્ટ લુસિયા",
    "country_207": "સેન્ટ વિન્સેન્ટ અને ગ્રેનેડાઈન્સ",
    "country_208": "સુદાન",
    "country_209": "સુરીનામ",
    "country_210": "સ્વાઝીલેન્ડ",
    "country_211": "સ્વીડન",
    "country_212": "સ્વિટ્ઝર્લૅન્ડ",
    "country_213": "સીરિયા",
    "country_214": "તાઈવાન",
    "country_215": "તાજિકિસ્તાન",
    "country_216": "તાન્ઝાનિયા",
    "country_217": "ઢાંચો:દેશનો ડેટા SJM સ્વાલબાર્ડ",
    "country_218": "થાઈલેન્ડ",
    "country_219": "બહામાસ",
    "country_220": "કોમોરો",
    "country_221": "ફિલિપાઇન્સ",
    "country_222": "તિમોર-લેસ્તે (પૂર્વ તિમોર)",
    "country_223": "જાઓ",
    "country_224": "ટોકેલાઉ",
    "country_225": "ટોંગા",
    "country_226": "ત્રિનિદાદ અને ટોબેગો",
    "country_227": "ટ્યુનિશિયા",
    "country_228": "તુર્કી",
    "country_229": "તુર્કમેનિસ્તાન",
    "country_230": "ટર્ક્સ અને કેકોસ ટાપુઓ",
    "country_231": "તુવાલુ",
    "country_232": "યુગાન્ડા",
    "country_233": "યુક્રેન",
    "country_234": "સંયુક્ત આરબ અમીરાત",
    "country_235": "યુનાઇટેડ સ્ટેટ્સ માઇનોર આઉટલાઇંગ ટાપુઓ",
    "country_236": "યુનાઈટેડ સ્ટેટ્સ ઑફ અમેરિકા (યુએસએ)",
    "country_237": "યુનાઇટેડ સ્ટેટ્સ વર્જિન ટાપુઓ",
    "country_238": "ઉરુગ્વે",
    "country_239": "ઉઝબેકિસ્તાન",
    "country_240": "વનુઆતુ",
    "country_241": "વેટિકન સિટી (ધ હોલી સી)",
    "country_242": "વેનેઝુએલા",
    "country_243": "વિયેતનામ",
    "country_244": "વૉલિસ અને ફ્યુટુના",
    "country_245": "પશ્ચિમી સહારા",
    "country_246": "યમન",
    "country_247": "ઝામ્બિયા",
    "country_248": "ઝિમ્બાબ્વે",
    "google_login": "Google સાથે સાઇન ઇન કરો",
    "State": "રાજ્ય",
    "Activation_code": "સક્રિયકરણ કોડ",
    "Question": "તમે લૉગ ઇન કરેલ છે તે તમામ એપ્લિકેશનોની સૂચિ બનાવો",
    "Copy_complete": "કૉપિ પૂર્ણ",
    "footer": "કૉપિરાઇટ © 2024 MindOnMap સ્ટુડિયો. બધા હકો અમારી પાસે રાખેલા છે.",
    "change_password_success": "પાસવર્ડ સફળતાપૂર્વક બદલાયો",
    "successful_login_title": "લૉગિન સફળ",
    "product_page": "ઉત્પાદન પૃષ્ઠ >>",
    "successful_login_info": "લૉગિન પૂર્ણ થયું. કૃપા કરીને વર્તમાન પૃષ્ઠ બંધ કરો અને નીચેની પ્રક્રિયા સાથે ચાલુ રાખવા માટે મૂળ ટેબ પર પાછા જાઓ. વર્તમાન પૃષ્ઠ 5 સેકન્ડમાં આપમેળે બંધ થઈ જશે. જો \"થઈ ગયું\" બટનને ક્લિક કરીને સ્વતઃ-બંધ અથવા બંધ થવાનું નિષ્ફળ જાય, તો કૃપા કરીને આ ટેબને સીધું જ બંધ કરો.",
    "successful_login_info_firefox": "લૉગિન પૂર્ણ થયું. કૃપા કરીને વર્તમાન પૃષ્ઠ બંધ કરો અને નીચેની પ્રક્રિયા સાથે ચાલુ રાખવા માટે મૂળ ટેબ પર પાછા જાઓ.",
    "my_account": "મારું ખાતું",
    "my_history": "મારો ઇતિહાસ",
    "remove_watermark": "વોટરમાર્ક દૂર કરો",
    "no_history": "કોઈ ઇતિહાસ નથી",
    "history_all": "બધા પસંદ કરો",
    "history_open": "ખુલ્લા",
    "history_down": "ડાઉનલોડ કરો",
    "history_delete": "કાઢી નાખો",
    "history_clear": "અમાન્ય સાફ કરો",
    "images": "છબી(ઓ)",
    "use_this_function": "આ ઉત્પાદનનો ઉપયોગ કરો>>",
    "hd_downloading": "HD ઓરિજિનલ ઈમેજો ડાઉનલોડ કરવાના ફાયદા:",
    "lifetimeRemaining": "આજીવન-બાકી",
    "Remaining": "બાકી",
    "email_verification": "ઇમેઇલ ચકાસણી",
    "email_verification_info": "અમે તમારા ઇમેઇલ <span class=\"email\"></span> પર ચકાસણી સંદેશ મોકલ્યો છે અને કૃપા કરીને ચકાસણી પૂર્ણ કરો. ચકાસણી કર્યા પછી, લાભો આપમેળે સમન્વયિત થશે.",
    "wrong_email": "ખોટું ઇમેઇલ સરનામું?",
    "click_here_to_modify": "સંશોધિત કરવા માટે અહીં ક્લિક કરો",
    "get_more_help": "વધુ મદદ મેળવો?",
    "click_here": "અહીં ક્લિક કરો",
    "email_verification_info_success": "તમારું ઈમેલ એકાઉન્ટ ચકાસવા બદલ અભિનંદન.",
    "email_verification_info_error": "સમાપ્ત થયેલ લિંકને કારણે ચકાસણી નિષ્ફળ થઈ.",
    "registration_succeeded": "નોંધણી સફળ",
    "registration_succeeded_info_1": "અભિનંદન! તમે સફળતાપૂર્વક નોંધણી કરાવી છે. અમે તમારા ઇમેઇલ <span class=\"email\"></span> પર ચકાસણી સંદેશ મોકલ્યો છે, અને કૃપા કરીને આ ઇમેઇલના લાભોનો ઉપયોગ કરવા માટે ચકાસણી પૂર્ણ કરો.",
    "registration_succeeded_info_2": "હોમ પેજ પર પાછા ફરવા માટે \"થઈ ગયું\" ક્લિક કરો અને આ પ્રોડક્ટનો ઉપયોગ કરો.",
    "registration_succeeded_info_3": "વર્તમાન પૃષ્ઠને બંધ કરવા માટે \"પૂર્ણ\" પર ક્લિક કરો અને નીચેના ઑપરેશન્સ માટે હોમ પેજ પર પાછા ફરો. જો બંધ કરવાનું નિષ્ફળ જાય, તો કૃપા કરીને ટેબને મેન્યુઅલી બંધ કરો.",
    "verify_email": "ઇમેઇલ ચકાસો",
    "registered_email_not_verified": "રજિસ્ટર્ડ ઈમેલ ચકાસવામાં આવ્યો નથી, કૃપા કરીને તેને એકવારમાં ચકાસો.",
    "email_verification_time_1": "ચકાસણી ઇમેઇલ પ્રાપ્ત નથી થયો?",
    "email_verification_time_2": "પછી",
    "email_verification_time_3": "તેને ફરીથી મોકલવા માટે અહીં ક્લિક કરો",
    "error_26301": "ભૂલ કોડ: 26301, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26302": "ભૂલ કોડ: 26302, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26303": "ઇમેઇલ ફોર્મેટ ભૂલ (ભૂલ કોડ: 26303). કૃપા કરીને તેને ફરીથી દાખલ કરો",
    "error_26304": "8 અક્ષરોથી ઉપરના પાસવર્ડની ભલામણ કરવામાં આવે છે (ભૂલ કોડ: 26304)",
    "error_26305": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26305). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26306": "ઈમેલ નોંધાયેલ છે, કૃપા કરીને <a href=\"https://account.mindonmap.com/login/\">લોગ ઇન કરવા જાઓ</a>",
    "error_26307": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26307). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26308": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26308). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26401": "ભૂલ કોડ: 26401, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26402": "ઇમેઇલની ચકાસણી કરવામાં આવી છે (ભૂલ કોડ: 26402), કૃપા કરીને તેનો ફરીથી પ્રયાસ કરો",
    "error_26403": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26403). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26404": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 26404). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26501": "ભૂલ કોડ: 26501, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26502": "ભૂલ કોડ: 26502, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26503": "ઇમેઇલ ફોર્મેટ ભૂલ (ભૂલ કોડ: 26503). કૃપા કરીને તેને ફરીથી દાખલ કરો",
    "error_26504": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26504). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26505": "ઈમેલ રજીસ્ટર થયેલ નથી, કૃપા કરીને <a href=\"https://account.mindonmap.com/register/\">તેને પહેલા રજીસ્ટર કરો</a>",
    "error_26506": "ઇમેઇલની ચકાસણી કરવામાં આવી છે.",
    "error_26507": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26507). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26508": "વેરિફિકેશન ફાઈલ થયું (ભૂલ કોડ: 26508), કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26509": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 26509), કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26510": "ભૂલ કોડ: 26510, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26601": "ભૂલ કોડ: 26601, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26602": "ભૂલ કોડ: 26602, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26603": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26603). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26604": "ભૂલ કોડ: 26604, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26605": "ભૂલ કોડ: 26605, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26701": "ભૂલ કોડ: 26701, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26702": "Reuqest નિષ્ફળ (ભૂલ કોડ: 26702). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26703": "ભૂલ કોડ: 26703, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26704": "ભૂલ કોડ: 26704, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26705": "લોગ ઇન કરવા માટે રાહ જુઓ (ભૂલ કોડ: 26705). કૃપા કરીને ફરી પ્રયાસ કરો",
    "no_cookie": "તમે તમારા બ્રાઉઝરમાં તમામ કૂકીઝને અવરોધિત કરો કાર્ય ચાલુ કર્યું છે, તેથી તમે લૉગ ઇન કરી શકતા નથી. બધી કૂકીઝને મંજૂરી આપોના બોક્સને ચેક કરવા માટે કૃપા કરીને સેટિંગ્સ પર જાઓ.",
    "error_26801": "ભૂલ કોડ: 26801, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26802": "ભૂલ કોડ: 26802, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26803": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 26803). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26804": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 26804). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_order": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27098), કૃપા કરીને ફરી પ્રયાસ કરો!",
    "error_order1": "ઓર્ડર ક્વેરી અપૂર્ણ છે（ભૂલ કોડ: ",
    "error_order2": "）, કૃપા કરીને તાજું કરો અને ફરીથી પ્રયાસ કરો.",
    "modify_email_title": "ઈમેલમાં ફેરફાર કરો",
    "modify_email_info": "તમે તમારા એકાઉન્ટમાં લૉગ ઇન કરવા માટે સંશોધિત ઇમેઇલનો ઉપયોગ કરી શકો છો.",
    "images_per": "પ્રતિ છબીઓ",
    "error_26101": "ભૂલ: 26101. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26102": "ભૂલ: 26102. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26103": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ:26103). કૃપા કરીને તેને ફરી પ્રયાસ કરો",
    "error_26104": "ભૂલ કોડ: 26104, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26105": "ભૂલ કોડ: 26105, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26106": "કાઢી નાખવામાં નિષ્ફળ (ભૂલ કોડ: 26106). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26201": "ભૂલ: 26201. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26202": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ:26202). કૃપા કરીને તેને ફરી પ્રયાસ કરો",
    "error_26001": "ભૂલ: 26001. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26002": "ભૂલ: 26002. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26003": "ભૂલ: 26003. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26004": "ભૂલ: 26004. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_26005": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ:26005). કૃપા કરીને તેને ફરી પ્રયાસ કરો",
    "error_26006": "ભૂલ કોડ: 26006, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_26008": "ભૂલ: 26008. કૃપા કરીને અમારો સંપર્ક કરો",
    "go_to_the_home_page": "હોમ પેજ પર જાઓ",
    "error_27101": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27101). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_27201": "ભૂલ કોડ: 27201, કૃપા કરીને અમારો સંપર્ક કરો",
    "error_27202": "ભૂલ કોડ: 27202, કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_27203": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27203). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_27204": "અમાન્ય કોડ (ભૂલ કોડ: 27204).",
    "error_27205": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27205). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_27206": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27206). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_27207": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 27207). કૃપા કરીને ફરી પ્રયાસ કરો",
    "no_history_found": "તમે કોઈપણ સાધનનો ઉપયોગ કર્યો નથી! <a href=\"/\">તાજું કરો</a> અથવા <a href=\"https://www.mindonmap.com/\">અધિકૃત વેબસાઇટ પર જાઓ</a>",
    "error_25301": "ભૂલ: 25301. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_25302": "ભૂલ: 25302. કૃપા કરીને અમારો સંપર્ક કરો",
    "error_25303": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 25303). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_25304": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 25304). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_25305": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 25305). કૃપા કરીને ફરી પ્રયાસ કરો",
    "error_25306": "વિનંતી નિષ્ફળ થઈ (ભૂલ કોડ: 25306). કૃપા કરીને ફરી પ્રયાસ કરો",
    "image_upscaler_p": "વોટરમાર્ક વિનાની છબી ડાઉનલોડ કરવાના ફાયદા:",
    "Available_for": "માટે ઉપલબ્ધ:",
    "credit_per": "HD ઈમેજ દીઠ %s ક્રેડિટ",
    "still_valid": "ખરીદેલ પ્લાન(ઓ) હજુ પણ માન્ય છે",
    "credit": "ક્રેડિટ",
    "pc_3rd_info": "સફળતાપૂર્વક લૉગિન. કૃપા કરીને વધુ કામગીરી માટે એપ્લિકેશન પર જાઓ.",
    "use_online": "ઑનલાઇન સેવાનો ઉપયોગ કરો",
    "use_download": "ડેસ્કટોપ પ્રોગ્રામનો ઉપયોગ કરો",
    "use_immediately": "તાત્કાલિક ઉપયોગ કરો",
    "Use_in_browser": "બ્રાઉઝરમાં ઉપયોગ કરો",
    "win_desktop": "વિન્ડોઝ",
    "Mac_desktop": "મેક",
    "credits_per": "દર મહિને {%} ક્રેડિટ",
    "expire": "સમાપ્તિ સમય:",
    "viewDetails": "વિગતો જુઓ",
    "viewHistory": "ઇતિહાસ લાભો જુઓ >>",
    "viewDetailsInfo": "સૂચના: જો સબ્સ્ક્રિપ્શન લાભો સમાપ્ત થયાના 7 દિવસની અંદર નવીકરણ કરવામાં આવે છે, તો ન વપરાયેલ લાભોનો ઉપયોગ ચાલુ રાખી શકાય છે. ઉપરાંત, સમાપ્તિ સમય નવા સબ્સ્ક્રિપ્શનના સમાપ્તિ સમય પર આપમેળે અપડેટ કરવામાં આવશે. જો સમાપ્તિ સમયના 7 દિવસ પછી કોઈ નવું સબ્સ્ક્રિપ્શન ન હોય, તો બધા સમાપ્ત થયેલા લાભો સાફ થઈ જશે.",
    "connect_account": "તમારા એકાઉન્ટમાં ઈમેલ બાંધો",
    "connect_account_info": "બાઈન્ડિંગ કર્યા પછી, તમે આ ઈમેલ એડ્રેસ વડે લૉગ ઇન કરી શકો છો.",
    "connect_now": "હવે બાંધો",
    "no_email_bind": "કોઈ ઈમેલ બાઇન્ડ નથી",
    "bind_email": "ઈમેલ બાંધો",
    "connect_your_email_placeholder": "કૃપા કરીને તમારું ઇમેઇલ સરનામું દાખલ કરો",
    "bind_an_email": "એક ઈમેલ બાંધો",
    "bind_info": "તમે સફળતાપૂર્વક લૉગ ઇન થયા છો. તમારા લાભો સક્રિય કરવા માટે કૃપા કરીને તમારા એકાઉન્ટમાં એક ઈમેલ જોડો.",
    "bind_later": "પાછળથી બાંધો",
    "hi": "હાય, %s!",
    "Personal_Information": "વ્યક્તિગત માહિતી",
    "Access": "એક્સેસ",
    "Subscription_Plan": "(સબ્સ્ક્રિપ્શન પ્લાન)",
    "No_orders": "કોઈ ઓર્ડર મળ્યા નથી.",
    "No_data": "કોઈ ડેટા નથી",
    "Featured_Products": "ફીચર્ડ પ્રોડક્ટ્સ",
    "More_Products": "વધુ પ્રોડક્ટ્સ",
    "Free_Download": "મફત ડાઉનલોડ કરો",
    "Get_Started": "શરૂ કરો",
    "Subscribe": "સબ્સ્ક્રાઇબ કરો",
    "Verified": "ચકાસણી",
    "back_to_account_center": "એકાઉન્ટ સેન્ટર પર પાછા જાઓ",
    "success": "સફળતા!",
    "successfully": "તમે સફળતાપૂર્વક એકાઉન્ટ રજીસ્ટર કર્યું છે.",
    "Continue": "ચાલુ રાખો",
    "Already": "પહેલેથી એકાઉન્ટ છે?",
    "loading_verification": "ચકાસણી સ્થિતિ તપાસી રહ્યું છે...",
    "email_no_verification": "માફ કરશો, નોંધાયેલ ઇમેઇલ સરનામું ચકાસાયેલ નથી. કૃપા કરીને ઉપરોક્ત સૂચનાઓ અનુસાર ચકાસણી પૂર્ણ કરો અને નોંધણી પૂર્ણ કરવા માટે ફરીથી \"થઈ ગયું\" બટન પર ક્લિક કરો.",
    "will_expire_after": "પછી સમાપ્ત થશે",
    "hours": "કલાક",
  },
  "he": {
    "overtime": "קוד שגיאה: {%}, נא לנסות שוב",
    "isnetwork": "שגיאת אינטרנט. אנא בדוק ונסה שוב",
    "email_placeholder": "אימייל",
    "email_empty": "נא להזין אימייל",
    "email_not_valid": "האימייל אינו חוקי",
    "email_not_valid_1": "אנא הזן את האימייל שלך",
    "email_not_valid_2": "האימייל אינו חוקי, אנא השתמש בכתובת אחרת.",
    "email_not_valid_3": "אין קלט אימייל",
    "password_placeholder": "סיסמה",
    "password_empty": "נא להזין סיסמה",
    "password_not_valid": "חשבון או סיסמה שגויים",
    "password_not_valid_1": "נדרשת סיסמה מעל 8 תווים",
    "password_not_valid_2": "נא ליצור סיסמה",
    "password_placeholder_1": "צור את הסיסמה שלך",
    "password_placeholder_2": "אמת סיסמתך",
    "password_placeholder_3": "צור סיסמה חדשה",
    "password_placeholder_4": "תאשר סיסמא חדשה",
    "password_placeholder_5": "הזן סיסמה ישנה",
    "copy_password_empty": "נא לאשר את הסיסמה",
    "copy_password_not_valid": "אנא אשר את הסיסמה שלך",
    "copy_passwords_inconsistent": "אישור הסיסמה שלך אינו תואם",
    "code_empty": "נא להזין קוד אימות",
    "code_not_valid": "קוד אימות לא תקף",
    "code_placeholder": "קוד אימות",
    "not_received_code": "אם תיבת הדואר שלך לא קיבלה את קוד האימות במשך זמן רב, אנא קבל את קוד האימות שוב.",
    "get_first_code": "קבל קודם את קוד האימות.",
    "last_name_placeholder": "נא להזין את שם המשפחה שלך",
    "first_name_placeholder": "נא להזין את שמך הפרטי",
    "address_placeholder": "אנא הכנס את כתובתך",
    "no_code_text": "שלחנו קוד אימות. נא להזין את הקוד שלך. <span class='tips'>לא קיבלת קוד?",
    "no_code_text_1": "1. אנא ודא שכתובת האימייל תקפה והיא יכולה לקבל מיילים.",
    "no_code_text_2": "2. מאחר שהמייל נשלח על ידי המערכת באופן אוטומטי, הוא עשוי להיות מסומן כדואר זבל או כדואר זבל. אנא בדוק אם האימייל נמצא בתיקיית האשפה.",
    "no_code_text_3": "3. לא מצליח לפתור את הבעיה שלך? ",
    "no_code_text_3_span": "לאחר מכן לחץ כאן כדי ליצור איתנו קשר.",
    "order_no": "לא רכשת שום מוצר, אם יש לך שאלה כלשהי, אנא <a href=\"https://www.mindonmap.com/contact-us/\">צור קשר</a>.",
    "error_24901": "לחשבון הנוכחי אין אימייל מקושר, ואינו יכול למצוא את ההזמנות. אנא חבר דוא\"ל.",
    "user_guide": "מדריך למשתמש >>",
    "download": "הורד",
    "order_number": "מספר הזמנה:",
    "Refund": "הֶחזֵר",
    "Disabled": "נָכֶה",
    "Normal": "נוֹרמָלִי",
    "Modify": "לְשַׁנוֹת",
    "Modify_1": "שנה >>",
    "Connect": "לְחַבֵּר",
    "unlink_success": "בטל את הקישור בהצלחה",
    "connect_success": "התחבר בהצלחה",
    "feedback_title": "תודה על המשוב שלך. אנא השאר את הבעיה שלך ואנו נענה לך תוך 24 שעות.",
    "feedback_thank_you": "תודה!<br />המשוב שלך נשלח בהצלחה.",
    "feedback_email": "הכנס את המייל שלך כאן!",
    "feedback_content": "השאר כאן כל בעיה או הצעה שנתקלת בה.",
    "feedback_submit": "שלח",
    "form_contents": "לא הזנת שום תיאור. אנא הכנס אותו ושלח שוב.",
    "old_password": "נא להזין סיסמה ישנה",
    "new_password": "נא ליצור סיסמה חדשה",
    "old_new_password": "הסיסמה החדשה לא יכולה להיות זהה לזו הישנה",
    "incorrect_password": "סיסמא לא נכונה",
    "delete_no": "תמחק עכשיו",
    "Caps": "Caps Lock פועל",
    "Get": "לקבל",
    "Done": "בוצע",
    "error_20001": "שגיאה: 20001. אנא היכנס שוב.",
    "error_20002": "שגיאה: 20002. אנא היכנס שוב.",
    "error_20003": "שגיאה: 20003. אנא היכנס שוב.",
    "error_20004": "הבקשה נכשלה (קוד שגיאה: 20004). אנא נסה זאת שוב.",
    "error_20005": "פג תוקף הפעלת הכניסה (שגיאה: 20005). נא להיכנס שוב.",
    "error_20006": "הבקשה נכשלה (קוד שגיאה: 20006). אנא נסה זאת שוב.",
    "error_20007": "פג תוקף הפעלת הכניסה (שגיאה: 20007). נא להיכנס שוב.",
    "error_20008": "פג תוקף הפעלת הכניסה (שגיאה: 20008). נא להיכנס שוב.",
    "error_20009": "פג תוקף הפעלת הכניסה (שגיאה: 20009). נא להיכנס שוב.",
    "error_20101": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20101)",
    "error_20102": "האימייל אינו חוקי (קוד שגיאה: 20102)",
    "error_20103": "הבקשה נכשלה (קוד שגיאה: 20103). אנא נסה זאת שוב",
    "error_20104": "האימייל כבר בשימוש, אנא <a href=\"https://account.mindonmap.com/login/\">התחבר</a> או הירשם עם אחד חדש",
    "error_20105": "הבקשה נכשלה (קוד שגיאה: 20105). אנא נסה זאת שוב",
    "error_20106": "שליחת האימייל נכשלה, אנא נסה זאת שוב",
    "error_20201": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20201)",
    "error_20202": "אנא הזן את הסיסמה שלך (קוד שגיאה: 20202)",
    "error_20203": "נא להזין קוד אימות (קוד שגיאה: 20203)",
    "error_20204": "האימייל אינו חוקי (קוד שגיאה: 20204)",
    "error_20205": "נדרשת סיסמה מעל 8 תווים (קוד שגיאה: 20205)",
    "error_20206": "הבקשה נכשלה (קוד שגיאה: 20206). אנא נסה זאת שוב",
    "error_20207": "קוד אימות לא תקף",
    "error_20208": "הבקשה נכשלה (קוד שגיאה: 20208). אנא נסה זאת שוב",
    "error_20209": "הבקשה נכשלה (קוד שגיאה: 20209). אנא נסה זאת שוב",
    "error_20301": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20301)",
    "error_20302": "שגיאה: 20302. אנא צור איתנו קשר",
    "error_20303": "האימייל אינו חוקי (קוד שגיאה: 20303)",
    "error_20304": "הבקשה נכשלה (קוד שגיאה: 20304). אנא נסה זאת שוב",
    "error_20305": "המשתמש לא קיים. אנא הכנס מחדש או <a href=\"https://account.mindonmap.com/register/\">צור אותו</a> תחילה.",
    "error_20306": "אין עדיין סיסמה. השתמש ב<a href=\"https://account.mindonmap.com/passwordless-login/\">כניסה ללא סיסמה</a> או ב<a href=\"https://account.mindonmap.com/create-password/\"> הגדר סיסמה</a> והיכנס.",
    "error_20308": "הבקשה נכשלה (קוד שגיאה: 20308). אנא נסה זאת שוב",
    "error_20401": "היציאה נכשלה (קוד שגיאה: 20401). אנא נסה זאת שוב",
    "error_20501": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20501)",
    "error_20502": "האימייל אינו חוקי (קוד שגיאה: 20502)",
    "error_20503": "הבקשה נכשלה (קוד שגיאה: 20503). אנא נסה זאת שוב",
    "error_20504": "שליחת האימייל נכשלה. אנא נסה זאת שוב.",
    "error_20601": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20601)",
    "error_20602": "נא להזין קוד אימות (קוד שגיאה: 20602)",
    "error_20603": "האימייל אינו חוקי (קוד שגיאה: 20603)",
    "error_20604": "הבקשה נכשלה (קוד שגיאה: 20604). אנא נסה זאת שוב",
    "error_20606": "הבקשה נכשלה (קוד שגיאה: 20606). אנא נסה זאת שוב",
    "error_20607": "הבקשה נכשלה (קוד שגיאה: 20607). אנא נסה זאת שוב",
    "error_20608": "הבקשה נכשלה (קוד שגיאה: 20608). אנא נסה זאת שוב",
    "error_20701": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20701)",
    "error_20702": "האימייל אינו חוקי (קוד שגיאה: 20702)",
    "error_20703": "הבקשה נכשלה (קוד שגיאה: 20703). אנא נסה זאת שוב",
    "error_20704": "המשתמש לא קיים. אנא הכנס מחדש או <a href=\"https://account.mindonmap.com/register/\">צור אותו</a> תחילה.",
    "error_20705": "הבקשה נכשלה (קוד שגיאה: 20705). אנא נסה זאת שוב",
    "error_20706": "שליחת האימייל נכשלה. אנא נסה זאת שוב",
    "error_20801": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 20801)",
    "error_20802": "שגיאה: 20802. אנא צור איתנו קשר",
    "error_20803": "נא להזין קוד אימות (קוד שגיאה: 20803)",
    "error_20804": "האימייל אינו חוקי (קוד שגיאה: 20804)",
    "error_20805": "נדרשת סיסמה מעל 8 תווים (קוד שגיאה: 20805)",
    "error_20806": "הבקשה נכשלה (קוד שגיאה: 20806). אנא נסה זאת שוב",
    "error_20808": "הבקשה נכשלה (קוד שגיאה: 20808). אנא נסה זאת שוב",
    "error_20901": "הבקשה נכשלה (קוד שגיאה: 20901). אנא נסה זאת שוב",
    "error_20902": "הבקשה נכשלה (קוד שגיאה: 20902). אנא נסה זאת שוב",
    "error_21000": "השינויים נשמרים",
    "error_21001": "לא נמסר מידע (קוד שגיאה: 21001)",
    "error_21002": "הבקשה נכשלה (קוד שגיאה: 21002). אנא נסה זאת שוב",
    "error_21101": "אנא הזן את הדוא\"ל שלך (קוד שגיאה: 21101)",
    "error_21102": "האימייל אינו חוקי (קוד שגיאה: 21102)",
    "error_21103": "הבקשה נכשלה (קוד שגיאה: 21103), אנא נסה אותה שוב",
    "error_21104": "האימייל כבר מחובר, אנא השתמש באימייל חדש",
    "error_21105": "הבקשה נכשלה (קוד שגיאה: 21105), אנא נסה אותה שוב",
    "error_21106": "שליחת האימייל נכשלה. אנא נסה זאת שוב",
    "error_21201": "אנא הזן את האימייל שלך (קוד שגיאה: 21201)",
    "error_21202": "נא להזין קוד אימות (קוד שגיאה: 21202)",
    "error_21203": "האימייל אינו חוקי (קוד שגיאה: 21203)",
    "error_21204": "שגיאה: 21204. אנא צור איתנו קשר",
    "error_21205": "שגיאה: 21205. אנא צור איתנו קשר",
    "error_21206": "נדרשת סיסמה מעל 8 תווים (קוד שגיאה: 21206)",
    "error_21207": "הבקשה נכשלה (קוד שגיאה: 21207). אנא נסה זאת שוב",
    "error_21209": "הבקשה נכשלה (קוד שגיאה: 21209). אנא נסה זאת שוב",
    "error_21301": "אנא הזן סיסמה ישנה (קוד שגיאה: 21301)",
    "error_21302": "נא ליצור סיסמה חדשה (קוד שגיאה: 21302)",
    "error_21303": "הסיסמה החדשה לא יכולה להיות זהה לזו הישנה. (שגיאה: 21303)",
    "error_21304": "נדרשת סיסמה מעל 8 תווים (קוד שגיאה: 21304)",
    "error_21306": "הבקשה נכשלה (קוד שגיאה: 21306). אנא נסה זאת שוב",
    "error_21402": "הבקשה נכשלה (קוד שגיאה: 21402). אנא נסה זאת שוב",
    "error_21403": "שליחת קוד האימות נכשלה. נא לשלוח אותו שוב",
    "error_21500": "החשבון נמחק",
    "error_21501": "נא להזין קוד אימות (קוד שגיאה: 21501)",
    "error_21502": "פג תוקף הפעלת הכניסה (שגיאה: 21502). נא להיכנס שוב.",
    "error_21503": "הבקשה נכשלה (קוד שגיאה: 21503). אנא נסה זאת שוב",
    "error_21505": "הבקשה נכשלה (קוד שגיאה: 21505), אנא נסה אותה שוב",
    "error_21601": "שגיאה: 20601. אנא צור איתנו קשר",
    "error_21602": "אימות לא חוקי (שגיאה: 20602). אנא נסה זאת שוב.",
    "error_21603": "שגיאה: 20603. אנא נסה זאת שוב",
    "error_21604": "הבקשה נכשלה (קוד שגיאה: 21604). אנא נסה זאת שוב",
    "error_21606": "הבקשה נכשלה (קוד שגיאה: 21606). אנא נסה זאת שוב",
    "error_21611": "הבקשה נכשלה (קוד שגיאה: 21611). אנא נסה זאת שוב",
    "error_21801": "שגיאה: 21801. אנא צור איתנו קשר",
    "error_21802": "הבקשה נכשלה (שגיאה: 21802). אנא נסה זאת שוב",
    "error_21803": "שגיאה: 21803. אנא נסה זאת שוב",
    "error_21804": "הבקשה נכשלה (קוד שגיאה: 21804). אנא נסה זאת שוב",
    "error_21806": "שגיאה: 21806. אנא נסה זאת שוב",
    "error_21807": "שגיאה: 21807. אנא צור איתנו קשר",
    "error_21808": "שגיאה: 21808. אנא צור איתנו קשר",
    "error_21809": "שגיאה: 21809. אנא צור איתנו קשר",
    "error_21810": "שגיאה: 21810. אנא צור איתנו קשר",
    "error_21811": "שגיאה: 21811. אנא צור איתנו קשר",
    "error_21812": "שגיאה: 21812. אנא צור איתנו קשר",
    "error_21813": "הבקשה נכשלה (קוד שגיאה: 21813). אנא נסה זאת שוב",
    "error_21814": "שגיאה: 21814. אנא צור איתנו קשר",
    "error_21815": "הבקשה נכשלה (קוד שגיאה: 21815). אנא נסה זאת שוב",
    "error_21816": "שגיאה: 21816. אנא צור איתנו קשר",
    "error_21817": "שגיאה: 21817. אנא צור איתנו קשר",
    "error_21818": "שגיאה: 21818. אנא צור איתנו קשר",
    "error_21819": "הבקשה נכשלה (קוד שגיאה: 21819). אנא נסה זאת שוב",
    "error_21820": "שגיאה: 21820. אנא צור איתנו קשר",
    "error_21821": "שגיאה: 21821. אנא צור איתנו קשר",
    "error_21822": "שגיאה: 21822. אנא צור איתנו קשר",
    "error_21823": "הבקשה נכשלה (קוד שגיאה: 21823). אנא נסה זאת שוב",
    "error_21824": "הבקשה נכשלה (קוד שגיאה: 21824). אנא נסה זאת שוב",
    "error_21825": "הבקשה נכשלה (קוד שגיאה: 21825). אנא נסה זאת שוב",
    "error_21826": "הבקשה נכשלה (קוד שגיאה: 21826). אנא נסה זאת שוב",
    "error_21901": "שגיאה: 21901. אנא צור איתנו קשר",
    "error_21902": "הבקשה נכשלה (קוד שגיאה: 21902). אנא נסה זאת שוב",
    "error_21903": "סטטוס החשבון השתנה (קוד שגיאה: 21903), אנא רענן את הדף ונסה שוב",
    "error_21904": "שגיאה: 21904. אנא נסה זאת שוב",
    "error_21905": "שגיאה: 21905. אנא נסה זאת שוב",
    "error_21906": "הבקשה נכשלה (קוד שגיאה: 21906). אנא נסה זאת שוב",
    "error_21907": "חשבון Google קושר לחשבון אחר",
    "error_21908": "הבקשה נכשלה (קוד שגיאה: 21908). אנא נסה זאת שוב",
    "error_22001": "הבקשה נכשלה (קוד שגיאה: 22001). אנא נסה זאת שוב",
    "error_22002": "ביטול הקישור נכשל ללא כניסה נוספת",
    "error_22003": "הבקשה נכשלה (קוד שגיאה: 22003). אנא נסה זאת שוב",
    "error_22101": "שגיאה: 22101. אנא צור איתנו קשר",
    "error_22102": "סטטוס החשבון השתנה (קוד שגיאה: 22102), אנא רענן את הדף ונסה שוב",
    "error_22103": "הבקשה נכשלה (קוד שגיאה: 22103). אנא נסה זאת שוב",
    "error_22104": "סטטוס החשבון השתנה (קוד שגיאה: 22104), אנא רענן את הדף ונסה שוב",
    "error_22105": "שגיאה: 22105. אנא נסה זאת שוב",
    "error_22106": "שגיאה: 22106. אנא נסה זאת שוב",
    "error_22107": "שגיאה: 22107. אנא צור איתנו קשר",
    "error_22108": "הבקשה נכשלה (קוד שגיאה: 22108). אנא נסה זאת שוב",
    "error_22201": "שגיאה: 22201. אנא צור איתנו קשר",
    "error_22202": "אימות לא חוקי (שגיאה: 22202). אנא נסה זאת שוב.",
    "error_22203": "שגיאה: 22203. אנא נסה זאת שוב\"",
    "error_22204": "הבקשה נכשלה (קוד שגיאה: 22204). אנא נסה זאת שוב",
    "error_22206": "הבקשה נכשלה (קוד שגיאה: 22206). אנא נסה זאת שוב",
    "error_22401": "שגיאה: 22401. אנא צור איתנו קשר",
    "error_22402": "אימות לא חוקי (שגיאה: 22402). אנא נסה זאת שוב.",
    "error_22403": "שגיאה: 22403. אנא נסה זאת שוב",
    "error_22404": "הבקשה נכשלה (קוד שגיאה: 22404). אנא נסה זאת שוב",
    "error_22405": "חשבון פייסבוק נקשר לאימייל אחר",
    "error_22406": "שגיאה: 22406. אנא נסה זאת שוב",
    "error_22407": "שגיאה: 22407. אנא צור איתנו קשר",
    "error_22408": "שגיאה: 22408. אנא צור איתנו קשר",
    "error_22409": "שגיאה: 22409. אנא צור איתנו קשר",
    "error_22410": "שגיאה: 224010. אנא צור איתנו קשר",
    "error_22411": "שגיאה: 224011. אנא צור איתנו קשר",
    "error_22412": "שגיאה: 224012. אנא צור איתנו קשר",
    "error_22413": "שגיאה: 22413. אנא צור איתנו קשר",
    "error_22414": "הבקשה נכשלה (קוד שגיאה: 22414). אנא נסה זאת שוב",
    "error_22415": "שגיאה: 22415. אנא צור איתנו קשר",
    "error_22416": "שגיאה: 22416. אנא צור איתנו קשר",
    "error_22417": "שגיאה: 22417. אנא צור איתנו קשר",
    "error_22418": "הבקשה נכשלה (קוד שגיאה: 22418). אנא נסה זאת שוב",
    "error_22419": "שגיאה: 22419. אנא צור איתנו קשר",
    "error_22420": "שגיאה: 22420. אנא צור איתנו קשר",
    "error_22421": "שגיאה: 22421. אנא צור איתנו קשר",
    "error_22422": "הבקשה נכשלה (קוד שגיאה: 22422). אנא נסה זאת שוב",
    "error_22423": "הבקשה נכשלה (קוד שגיאה: 22423). אנא נסה זאת שוב",
    "error_22424": "הבקשה נכשלה (קוד שגיאה: 22424). אנא נסה זאת שוב",
    "error_22425": "הבקשה נכשלה (קוד שגיאה: 22425). אנא נסה זאת שוב",
    "error_20098": "קוד שגיאה: 20098. אם אתה גולש במצב פרטי, אנא עבור למצב רגיל ונסה שוב.",
    "error_22298": "הבקשה ל-Google נכשלה (שגיאה: 22298). אנא נסה זאת שוב.",
    "error_22498": "הבקשה לפייסבוק נכשלה (שגיאה: 22498). אנא נסה זאת שוב.",
    "error_24902": "הבקשה נכשלה (קוד שגיאה: 24902). אנא נסה זאת שוב",
    "error_24903": "הבקשה נכשלה (קוד שגיאה: 24903). אנא נסה זאת שוב",
    "error_24904": "הבקשה נכשלה (קוד שגיאה: 24904). אנא נסה זאת שוב",
    "error_24905": "הבקשה נכשלה (קוד שגיאה: 24905). אנא נסה זאת שוב",
    "login_title": "היכנס אל MindOnMap",
    "log_in": "התחברות",
    "no_account": "אין חשבון?",
    "create_it": "צור אותו",
    "or_log_in_with": "או היכנס עם",
    "passwordless_login": "כניסה ללא סיסמה",
    "log_in_done": "כניסה בוצעה",
    "three_rd_account_connect_info": "מזל טוב! התחברת בהצלחה. כעת תוכל לחבר חשבון דוא\"ל אחד המשמש לכניסה בעתיד.",
    "see_my_account": "ראה את החשבון שלי",
    "three_rd_login_merge_account": "כתובת הדוא\"ל של חשבון הצד השלישי נרשמה, האם ברצונך להתחבר ולהתחבר ישירות עם כתובת הדוא\"ל הזו?",
    "connect_log_in": "התחבר והתחבר",
    "create_an_account": "צור חשבון",
    "back_to_log_in": "חזרה לכניסה",
    "create_password": "צור סיסמה",
    "create_now": "צור עכשיו",
    "password_login_subtitle": "כניסה ללא סיסמה עם דואר אלקטרוני",
    "account_login": "כניסה לחשבון",
    "rights": "על ידי יצירת חשבון זה, אתה מסכים ל<a href=\"https://www.mindonmap.com/terms-and-conditions/\">תנאים וההגבלות</a> ו<a href=\"https://www.mindonmap.com/privacy-policy/\">מדיניות הפרטיות</a>",
    "passwordless_login_done": "התחברות ללא סיסמה בוצעה",
    "passwordless_login_info": "מזל טוב, השלמת כניסה ללא סיסמה בהצלחה. תוכל ליצור סיסמה לחשבון זה ולהיכנס עם החשבון והסיסמה בעתיד. <a href=\"/create-password\" style=\"display: initial;\">צור עכשיו</a>",
    "sign_up": "הירשם",
    "register_info": "תיצור את המשתמש שלך",
    "reset_now": "אפס עכשיו",
    "forgot_password": "שכחת את הסיסמא",
    "reset_password_subtitle": "השתמש באימייל החשבון שלך כדי לאפס את הסיסמה",
    "plan_products": "תוכניות ומוצרים",
    "nick_name": "שם משתמש:",
    "email": "אימייל:",
    "my_products": "המוצרים שלי",
    "my_orders": "ההזמנות שלי",
    "unlink": "בטל את הקישור",
    "link": "קישור",
    "connected_accounts": "חשבונות מחוברים",
    "last_name": "שם משפחה:",
    "first_name": "שם פרטי:",
    "Gender": "מִין:",
    "Birth": "הוּלֶדֶת:",
    "Month": "חוֹדֶשׁ",
    "Year": "שָׁנָה",
    "Country_Region": "מדינה/אזור:",
    "Address": "כתובת:",
    "Save": "להציל",
    "Date": "תַאֲרִיך",
    "Male": "זָכָר",
    "Female": "נְקֵבָה",
    "Unspecified": "לא מצוין",
    "Security": "בִּטָחוֹן",
    "change_password": "שנה סיסמא",
    "change_now": "החלף עכשיו",
    "connect_email": "חבר אימייל",
    "delete_account": "מחק חשבון",
    "delete_account_info": "כאשר החשבון שלך יימחק, כל הנתונים בחשבון שלי המשויכים לחשבון שלך יימחקו לצמיתות, וייתכן שלא תוכל לשחזר אותם. אנו ממליצים לך לנהוג בזהירות.",
    "Delete": "לִמְחוֹק",
    "Logout": "להתנתק",
    "my_profile": "הפרופיל שלי",
    "guides_faqs": "מדריכים ושאלות נפוצות",
    "More": "יותר",
    "guides": "מדריכים",
    "register": "הירשם",
    "hot_faq": "שאלות נפוצות חמות",
    "Contents": "תוכן:",
    "contact_us": "צרו קשר >>",
    "plan": "לְתַכְנֵן",
    "unregistered": "לא רשום",
    "buy_more": "קנה עוד",
    "buy_again": "קנה שוב",
    "buy_now": "קנה עכשיו",
    "free_no_limit": "חינם וללא הגבלה",
    "expired": "לא בתוקף",
    "lifetime": "לכל החיים",
    "remain": "לְהִשָׁאֵר",
    "day_s": "ימים",
    "error_24801": "הבקשה נכשלה (קוד שגיאה: 24801). אנא נסה זאת שוב",
    "no_app_found": "לא נמצאה אפליקציה!<a href=\"/\">רענן</a> או <a href=\"https://www.mindonmap.com/\">עבור לאתר הרשמי</a>",
    "get_more": "קבל עוד >>",
    "edit_photo": "ערוך תמונה",
    "select_photo": "בחר תמונה",
    "change_photo": "שנה תמונה",
    "cancel": "לְבַטֵל",
    "hide_password": "הסתר סיסמא",
    "show_password": "הראה סיסמה",
    "zoom_in": "לְהִתְמַקֵד",
    "zoom_out": "להקטין את התצוגה",
    "rotate": "להתחלף",
    "horizontal_flip": "היפוך אופקי",
    "vertical_flip": "היפוך אנכי",
    "country": "מדינה",
    "country_1": "בחר את המדינה/האזור שלך",
    "country_2": "האי אולנד",
    "country_3": "אפגניסטן",
    "country_4": "אלבניה",
    "country_5": "אלג'יריה",
    "country_6": "סמואה האמריקנית",
    "country_7": "אנדורה",
    "country_8": "אנגולה",
    "country_9": "אנגווילה",
    "country_10": "אנטארקטיקה",
    "country_11": "אנטיגואה וברבודה",
    "country_12": "ארגנטינה",
    "country_13": "אַרְמֶנִיָה",
    "country_14": "ארובה",
    "country_15": "אוֹסטְרַלִיָה",
    "country_16": "אוֹסְטְרֵיָה",
    "country_17": "אזרבייג'ן",
    "country_18": "בחריין",
    "country_19": "בנגלדש",
    "country_20": "ברבדוס",
    "country_21": "בלארוס",
    "country_22": "בלגיה",
    "country_23": "בליז",
    "country_24": "בנין",
    "country_25": "ברמודה",
    "country_26": "בהוטן",
    "country_27": "בוליביה",
    "country_28": "בוסניה ו הרצגובינה",
    "country_29": "בוצואנה",
    "country_30": "האי Bouvet",
    "country_31": "בְּרָזִיל",
    "country_32": "טריטוריית האוקיינוס ההודי הבריטי",
    "country_33": "איי בתולה בריטיים",
    "country_34": "ברוניי",
    "country_35": "בולגריה",
    "country_36": "בורקינה",
    "country_37": "בורונדי",
    "country_38": "קמבודיה",
    "country_39": "קמרון",
    "country_40": "קנדה",
    "country_41": "כף ורדה",
    "country_42": "הקאריביים הולנד",
    "country_43": "איי קיימן",
    "country_44": "הרפובליקה המרכז - אפריקאית",
    "country_45": "צ'אד",
    "country_46": "צ'ילה",
    "country_47": "חרסינה",
    "country_48": "אי חג המולד",
    "country_49": "איי קוקוס (קילינג).",
    "country_50": "קולומביה",
    "country_51": "איי קוק",
    "country_52": "קוסטה ריקה",
    "country_53": "חוף השנהב",
    "country_54": "קרואטיה",
    "country_55": "קובה",
    "country_56": "קַפרִיסִין",
    "country_57": "הרפובליקה הצ'כית",
    "country_58": "הרפובליקה הדמוקרטית של קונגו",
    "country_59": "דנמרק",
    "country_60": "ג'יבוטי",
    "country_61": "דומיניקה",
    "country_62": "הרפובליקה הדומיניקנית",
    "country_63": "אקוודור",
    "country_64": "מִצְרַיִם",
    "country_65": "אל סלבדור",
    "country_66": "גיניאה המשוונית",
    "country_67": "אריתריאה",
    "country_68": "אסטוניה",
    "country_69": "אֶתִיוֹפִּיָה",
    "country_70": "איי פוקלנד",
    "country_71": "איי פרו",
    "country_72": "מדינות פדרציות של מיקרונזיה",
    "country_73": "פיג'י",
    "country_74": "פינלנד",
    "country_75": "צָרְפַת",
    "country_76": "גיאנה הצרפתית",
    "country_77": "פולינזיה הצרפתית",
    "country_78": "הטריטוריות הדרומיות של צרפת",
    "country_79": "גאבון",
    "country_80": "גמביה",
    "country_81": "גאורגיה",
    "country_82": "גֶרמָנִיָה",
    "country_83": "גאנה",
    "country_84": "גיברלטר",
    "country_85": "בריטניה (בריטניה ; אנגליה)",
    "country_86": "יָוָן",
    "country_87": "גרינלנד",
    "country_88": "גרנדה",
    "country_89": "גוואדלופ",
    "country_90": "גואם",
    "country_91": "גואטמלה",
    "country_92": "גרנזי",
    "country_93": "גינאה",
    "country_94": "גינאה-ביסאו",
    "country_95": "גיאנה",
    "country_96": "האיטי",
    "country_97": "Heard Island ו-McDonald Islands",
    "country_98": "הונדורס",
    "country_99": "הונג קונג",
    "country_100": "הונגריה",
    "country_101": "אִיסלַנד",
    "country_102": "הוֹדוּ",
    "country_103": "אִינדוֹנֵזִיָה",
    "country_104": "איראן",
    "country_105": "עִירַאק",
    "country_106": "אירלנד",
    "country_107": "האי מאן",
    "country_108": "ישראל",
    "country_109": "אִיטַלִיָה",
    "country_110": "ג'מייקה",
    "country_111": "יפן",
    "country_112": "ג'רזי",
    "country_113": "יַרדֵן",
    "country_114": "קזחסטן",
    "country_115": "קניה",
    "country_116": "קיריבטי",
    "country_117": "כווית",
    "country_118": "קירגיזסטן",
    "country_119": "לאוס",
    "country_120": "לטביה",
    "country_121": "לבנון",
    "country_122": "לסוטו",
    "country_123": "ליבריה",
    "country_124": "לוב",
    "country_125": "ליכטנשטיין",
    "country_126": "ליטא",
    "country_127": "לוקסמבורג",
    "country_128": "מקאו",
    "country_129": "מדגסקר",
    "country_130": "מלאווי",
    "country_131": "מלזיה",
    "country_132": "המלדיביים",
    "country_133": "מלי",
    "country_134": "מלטה",
    "country_135": "איי מרשל",
    "country_136": "מרטיניק",
    "country_137": "מאוריטניה",
    "country_138": "מאוריציוס",
    "country_139": "מיוט",
    "country_140": "מקסיקו",
    "country_141": "מולדובה",
    "country_142": "מונקו",
    "country_143": "מונגוליה",
    "country_144": "מונטנגרו",
    "country_145": "מונטסראט",
    "country_146": "מָרוֹקוֹ",
    "country_147": "מוזמביק",
    "country_148": "מיאנמר (בורמה)",
    "country_149": "נמיביה",
    "country_150": "נאורו",
    "country_151": "נפאל",
    "country_152": "הולנד",
    "country_153": "קלדוניה החדשה",
    "country_154": "ניו זילנד",
    "country_155": "ניקרגואה",
    "country_156": "ניז'ר",
    "country_157": "ניגריה",
    "country_158": "ניואה",
    "country_159": "האי נורפוק",
    "country_160": "צפון קוריאה",
    "country_161": "איי מריאנה הצפוניים",
    "country_162": "נורווגיה",
    "country_163": "עומאן",
    "country_164": "פקיסטן",
    "country_165": "פלאו",
    "country_166": "שטחים פלסטיניים",
    "country_167": "פנמה",
    "country_168": "פפואה גינאה החדשה",
    "country_169": "פרגוואי",
    "country_170": "פרו",
    "country_171": "איי פיטקרן",
    "country_172": "פּוֹלִין",
    "country_173": "פּוֹרטוּגָל",
    "country_174": "פוארטו ריקו",
    "country_175": "קטאר",
    "country_176": "הרפובליקה של מקדוניה (FYROM)",
    "country_177": "הרפובליקה של קונגו",
    "country_178": "איחוד",
    "country_179": "רומניה",
    "country_180": "הפדרציה הרוסית",
    "country_181": "רואנדה",
    "country_182": "סנט ברת'למי",
    "country_183": "סן מרטין (צרפת)",
    "country_184": "סנט פייר ומיקלון",
    "country_185": "סמואה",
    "country_186": "סן מרינו",
    "country_187": "סאו טומה ופרינסיפ",
    "country_188": "ערב הסעודית",
    "country_189": "סנגל",
    "country_190": "סרביה",
    "country_191": "איי סיישל",
    "country_192": "סיירה לאון",
    "country_193": "סינגפור",
    "country_194": "סלובקיה",
    "country_195": "סלובניה",
    "country_196": "איי שלמה",
    "country_197": "סומליה",
    "country_198": "דרום אפריקה",
    "country_199": "דרום ג'ורג'יה ו איי דרום סנדוויץ’",
    "country_200": "דרום קוריאה",
    "country_201": "דרום סודן",
    "country_202": "סְפָרַד",
    "country_203": "סרי לנקה",
    "country_204": "סנט הלנה ותלויות",
    "country_205": "St. Kitts & Nevis",
    "country_206": "סנט לוסיה",
    "country_207": "סנט וינסנט והגרנדינים",
    "country_208": "סודן",
    "country_209": "סורינאם",
    "country_210": "סווזילנד",
    "country_211": "שבדיה",
    "country_212": "שוויץ",
    "country_213": "סוּריָה",
    "country_214": "טייוואן",
    "country_215": "טג'יקיסטן",
    "country_216": "טנזניה",
    "country_217": "תבנית:נתוני מדינה SJM סבלברד",
    "country_218": "תאילנד",
    "country_219": "איי הבהאמה",
    "country_220": "קומורו",
    "country_221": "הפיליפינים",
    "country_222": "טימור-לסטה (מזרח-טימור)",
    "country_223": "ללכת",
    "country_224": "טוקלאו",
    "country_225": "טונגה",
    "country_226": "טרינידד וטובגו",
    "country_227": "תוניסיה",
    "country_228": "טורקיה",
    "country_229": "טורקמניסטן",
    "country_230": "איי טורקס וקאיקוס",
    "country_231": "טובאלו",
    "country_232": "אוגנדה",
    "country_233": "אוקראינה",
    "country_234": "איחוד האמירויות הערביות",
    "country_235": "האיים הקטנים של ארצות הברית",
    "country_236": "ארצות הברית של אמריקה (ארה\"ב)",
    "country_237": "איי הבתולה של ארצות הברית",
    "country_238": "אורוגוואי",
    "country_239": "אוזבקיסטן",
    "country_240": "ונואטו",
    "country_241": "עיר הוותיקן (הכס הקדוש)",
    "country_242": "ונצואלה",
    "country_243": "וייטנאם",
    "country_244": "וואליס ופוטונה",
    "country_245": "סהרה המערבית",
    "country_246": "תֵימָן",
    "country_247": "זמביה",
    "country_248": "זימבבואה",
    "google_login": "היכנס באמצעות גוגל",
    "State": "מדינה",
    "Activation_code": "קוד הפעלה",
    "Question": "רשום את כל האפליקציות שנכנסת",
    "Copy_complete": "ההעתקה הושלמה",
    "footer": "זכויות יוצרים © 2024 MindOnMap Studio. כל הזכויות שמורות.",
    "change_password_success": "סיסמה שונתה בהצלחה",
    "successful_login_title": "כניסה בהצלחה",
    "product_page": "עמוד המוצר >>",
    "successful_login_info": "הכניסה הושלמה. אנא סגור את הדף הנוכחי וחזור לכרטיסייה המקורית כדי להמשיך בתהליך הבא. הדף הנוכחי ייסגר אוטומטית תוך 5 שניות. אם סגירה אוטומטית או סגירה על ידי לחיצה על כפתור \"סיום\" נכשלת, אנא סגור כרטיסייה זו ישירות.",
    "successful_login_info_firefox": "הכניסה הושלמה. אנא סגור את הדף הנוכחי וחזור לכרטיסייה המקורית כדי להמשיך בתהליך הבא.",
    "my_account": "החשבון שלי",
    "my_history": "ההיסטוריה שלי",
    "remove_watermark": "הסר סימן מים",
    "no_history": "אין היסטוריה",
    "history_all": "בחר הכל",
    "history_open": "לִפְתוֹחַ",
    "history_down": "הורד",
    "history_delete": "לִמְחוֹק",
    "history_clear": "נקה לא חוקי",
    "images": "תמונות",
    "use_this_function": "השתמש במוצר זה >>",
    "hd_downloading": "יתרונות הורדת תמונות מקוריות באיכות HD:",
    "lifetimeRemaining": "כל החיים",
    "Remaining": "נוֹתָר",
    "email_verification": "אימות אימייל",
    "email_verification_info": "שלחנו את הודעת האימות לדוא\"ל שלך <span class=\"email\"></span>, ובבקשה סיים את האימות. לאחר האימות, ההטבות יסתנכרנו אוטומטית.",
    "wrong_email": "כתובת מייל שגויה?",
    "click_here_to_modify": "לחץ כאן כדי לשנות",
    "get_more_help": "לקבל עזרה נוספת?",
    "click_here": "לחץ כאן",
    "email_verification_info_success": "ברכות על אימות חשבון הדוא\"ל שלך.",
    "email_verification_info_error": "האימות נכשל בגלל הקישור שפג תוקפו.",
    "registration_succeeded": "ההרשמה הצליחה",
    "registration_succeeded_info_1": "מזל טוב! נרשמת בהצלחה. שלחנו את הודעת האימות לדוא\"ל שלך <span class=\"email\"></span>, ובבקשה סיים את האימות כדי להשתמש ביתרונות של דוא\"ל זה.",
    "registration_succeeded_info_2": "לחץ על \"סיום\" כדי לחזור לדף הבית ולהשתמש במוצר זה.",
    "registration_succeeded_info_3": "לחץ על \"סיום\" כדי לסגור את הדף הנוכחי ולחזור לדף הבית עבור הפעולות הבאות. אם הסגירה נכשלת, אנא סגור את הכרטיסייה באופן ידני.",
    "verify_email": "וודא כתובת אימייל",
    "registered_email_not_verified": "הדוא\"ל הרשום לא אומת, אנא אמת אותו מיד.",
    "email_verification_time_1": "לא קיבלת אימייל אימות?",
    "email_verification_time_2": "לאחר",
    "email_verification_time_3": "לחץ כאן כדי לשלוח אותו מחדש",
    "error_26301": "קוד שגיאה: 26301, אנא צור איתנו קשר",
    "error_26302": "קוד שגיאה: 26302, אנא צור איתנו קשר",
    "error_26303": "שגיאת פורמט אימייל (קוד שגיאה: 26303). נא להזין אותו שוב",
    "error_26304": "מומלצת סיסמה מעל 8 תווים (קוד שגיאה: 26304)",
    "error_26305": "Reuqest נכשל (קוד שגיאה: 26305). אנא נסה זאת שוב",
    "error_26306": "דוא\"ל נרשם, אנא <a href=\"https://account.mindonmap.com/login/\">עבור לכניסה</a>",
    "error_26307": "Reuqest נכשל (קוד שגיאה: 26307). אנא נסה זאת שוב",
    "error_26308": "Reuqest נכשל (קוד שגיאה: 26308). אנא נסה זאת שוב",
    "error_26401": "קוד שגיאה: 26401, אנא נסה זאת שוב",
    "error_26402": "האימייל אומת (קוד שגיאה: 26402), אנא נסה זאת שוב",
    "error_26403": "Reuqest נכשל (קוד שגיאה: 26403). אנא נסה זאת שוב",
    "error_26404": "הבקשה נכשלה (קוד שגיאה: 26404). אנא נסה זאת שוב",
    "error_26501": "קוד שגיאה: 26501, אנא צור איתנו קשר",
    "error_26502": "קוד שגיאה: 26502, אנא צור איתנו קשר",
    "error_26503": "שגיאת פורמט אימייל (קוד שגיאה: 26503). נא להזין אותו שוב",
    "error_26504": "Reuqest נכשל (קוד שגיאה: 26504). אנא נסה זאת שוב",
    "error_26505": "דוא\"ל לא נרשם, אנא <a href=\"https://account.mindonmap.com/register/\">רשום אותו תחילה</a>",
    "error_26506": "האימייל אומת.",
    "error_26507": "Reuqest נכשל (קוד שגיאה: 26507). אנא נסה זאת שוב",
    "error_26508": "האימות נכשל (קוד שגיאה: 26508), אנא נסה זאת שוב",
    "error_26509": "הבקשה נכשלה (קוד שגיאה: 26509), אנא נסה אותה שוב",
    "error_26510": "קוד שגיאה: 26510, אנא צור איתנו קשר",
    "error_26601": "קוד שגיאה: 26601, אנא צור איתנו קשר",
    "error_26602": "קוד שגיאה: 26602, אנא צור איתנו קשר",
    "error_26603": "Reuqest נכשל (קוד שגיאה: 26603). אנא נסה זאת שוב",
    "error_26604": "קוד שגיאה: 26604, אנא צור איתנו קשר",
    "error_26605": "קוד שגיאה: 26605, אנא צור איתנו קשר",
    "error_26701": "קוד שגיאה: 26701, אנא צור איתנו קשר",
    "error_26702": "Reuqest נכשל (קוד שגיאה: 26702). אנא נסה זאת שוב",
    "error_26703": "קוד שגיאה: 26703, אנא צור איתנו קשר",
    "error_26704": "קוד שגיאה: 26704, אנא צור איתנו קשר",
    "error_26705": "המתן לכניסה (קוד שגיאה: 26705). אנא נסה זאת שוב",
    "no_cookie": "הפעלת את הפונקציה חסום את כל העוגיות בדפדפן שלך, כך שאינך יכול להתחבר. נא עבור אל הגדרות כדי לסמן את התיבה אפשר את כל העוגיות.",
    "error_26801": "קוד שגיאה: 26801, אנא צור איתנו קשר",
    "error_26802": "קוד שגיאה: 26802, אנא צור איתנו קשר",
    "error_26803": "הבקשה נכשלה (קוד שגיאה: 26803). אנא נסה זאת שוב",
    "error_26804": "הבקשה נכשלה (קוד שגיאה: 26804). אנא נסה זאת שוב",
    "error_order": "הבקשה נכשלה (קוד שגיאה: 27098), אנא נסה זאת שוב!",
    "error_order1": "שאילתת ההזמנה אינה שלמה (קוד שגיאה: ",
    "error_order2": "）, אנא רענן ונסה זאת שוב.",
    "modify_email_title": "שנה אימייל",
    "modify_email_info": "אתה יכול להשתמש באימייל שהשתנה כדי להיכנס לחשבון שלך.",
    "images_per": "תמונות לכל",
    "error_26101": "שגיאה: 26101. אנא צור איתנו קשר",
    "error_26102": "שגיאה: 26102. אנא צור איתנו קשר",
    "error_26103": "הבקשה נכשלה (קוד שגיאה:26103). אנא נסה שוב",
    "error_26104": "קוד שגיאה: 26104, נא לנסות שוב",
    "error_26105": "קוד שגיאה: 26105, נא לנסות שוב",
    "error_26106": "המחיקה נכשלה (קוד שגיאה: 26106). אנא נסה זאת שוב",
    "error_26201": "שגיאה: 26201. אנא צור איתנו קשר",
    "error_26202": "הבקשה נכשלה (קוד שגיאה:26202). אנא נסה שוב",
    "error_26001": "שגיאה: 26001. אנא צור איתנו קשר",
    "error_26002": "שגיאה: 26002. אנא צור איתנו קשר",
    "error_26003": "שגיאה: 26003. אנא צור איתנו קשר",
    "error_26004": "שגיאה: 26004. אנא צור איתנו קשר",
    "error_26005": "הבקשה נכשלה (קוד שגיאה:26005). אנא נסה שוב",
    "error_26006": "קוד שגיאה: 26006, נא לנסות שוב",
    "error_26008": "שגיאה: 26008. אנא צור איתנו קשר",
    "go_to_the_home_page": "עבור לדף הבית",
    "error_27101": "הבקשה נכשלה (קוד שגיאה: 27101). אנא נסה שוב",
    "error_27201": "קוד שגיאה: 27201, אנא צור איתנו קשר",
    "error_27202": "קוד שגיאה: 27202, אנא נסה זאת שוב",
    "error_27203": "הבקשה נכשלה (קוד שגיאה: 27203). אנא נסה זאת שוב",
    "error_27204": "קוד לא חוקי (קוד שגיאה: 27204).",
    "error_27205": "הבקשה נכשלה (קוד שגיאה: 27205). אנא נסה זאת שוב",
    "error_27206": "הבקשה נכשלה (קוד שגיאה: 27206). אנא נסה זאת שוב",
    "error_27207": "הבקשה נכשלה (קוד שגיאה: 27207). אנא נסה זאת שוב",
    "no_history_found": "לא השתמשת באף כלי! <a href=\"/\">רענן</a> או <a href=\"https://www.mindonmap.com/\">עבור לאתר הרשמי</a>",
    "error_25301": "שגיאה: 25301. אנא צור איתנו קשר",
    "error_25302": "שגיאה: 25302. אנא צור איתנו קשר",
    "error_25303": "הבקשה נכשלה (קוד שגיאה: 25303). אנא נסה זאת שוב",
    "error_25304": "הבקשה נכשלה (קוד שגיאה: 25304). אנא נסה זאת שוב",
    "error_25305": "הבקשה נכשלה (קוד שגיאה: 25305). אנא נסה זאת שוב",
    "error_25306": "הבקשה נכשלה (קוד שגיאה: 25306). אנא נסה זאת שוב",
    "image_upscaler_p": "יתרונות תמונה ללא הורדת סימן מים:",
    "Available_for": "פנוי ל:",
    "credit_per": "%s קרדיט/ים לכל תמונת HD",
    "still_valid": "התוכניות שנרכשו עדיין בתוקף",
    "credit": "נקודות זכות)",
    "pc_3rd_info": "התחבר בהצלחה. אנא עברו לאפליקציה להמשך הפעלה.",
    "use_online": "השתמש בשירות המקוון",
    "use_download": "השתמש בתוכנת שולחן העבודה",
    "use_immediately": "השתמש באופן מיידי",
    "Use_in_browser": "השתמש בדפדפן",
    "win_desktop": "חלונות",
    "Mac_desktop": "מק",
    "credits_per": "{%} זיכויים לחודש",
    "expire": "תאריך תפוגה:",
    "viewDetails": "הצג פרטים",
    "viewHistory": "צפה בהטבות בהיסטוריה >>",
    "viewDetailsInfo": "שים לב: אם הטבות המנוי יחודשו תוך 7 ימים לאחר פקיעת התוקף, ניתן להמשיך ולנצל את ההטבות שלא נוצלו. כמו כן, זמן התפוגה יעודכן אוטומטית לזמן התפוגה של המנוי החדש. אם לא יהיה מנוי חדש לאחר 7 ימים של מועד התפוגה, כל ההטבות שפג תוקפן ימוקו.",
    "connect_account": "קשר דואר אלקטרוני לחשבון שלך",
    "connect_account_info": "לאחר הכריכה, תוכל להיכנס עם כתובת הדוא\"ל הזו.",
    "connect_now": "אגד עכשיו",
    "no_email_bind": "אין קשר למייל",
    "bind_email": "קשר דואר אלקטרוני",
    "connect_your_email_placeholder": "אנא הכנס את כתובת הדוא\"ל שלך",
    "bind_an_email": "קשר אימייל",
    "bind_info": "התחברת בהצלחה. נא קשר אימייל לחשבון שלך כדי להפעיל את ההטבות שלך.",
    "bind_later": "כרוך מאוחר יותר",
    "hi": "היי, %s!",
    "Personal_Information": "מידע אישי",
    "Access": "גִישָׁה",
    "Subscription_Plan": "(תוכנית מנוי)",
    "No_orders": "לא נמצאו הזמנות.",
    "No_data": "אין מידע",
    "Featured_Products": "מוצרים מומלצים",
    "More_Products": "עוד מוצרים",
    "Free_Download": "הורדה חינמית",
    "Get_Started": "להתחיל",
    "Subscribe": "הירשם",
    "Verified": "מְאוּמָת",
    "back_to_account_center": "חזרה למרכז החשבונות",
    "success": "הַצלָחָה!",
    "successfully": "רשמת בהצלחה חשבון.",
    "Continue": "לְהַמשִׁיך",
    "Already": "כבר יש לך חשבון?",
    "loading_verification": "בדיקת סטטוס אימות...",
    "email_no_verification": "מצטערים, כתובת האימייל הרשומה לא אומתה. אנא השלם את האימות בהתאם להוראות לעיל ולחץ שוב על כפתור \"בוצע\" כדי להשלים את ההרשמה.",
    "will_expire_after": "יפוג לאחר מכן",
    "hours": "שעה (ות",
  },
  "hi": {
    "overtime": "त्रुटि कोड: {%}, कृपया पुनः प्रयास करें",
    "isnetwork": "इंटरनेट त्रुटि। कृपया जाँच करें और पुनः प्रयास करें",
    "email_placeholder": "ईमेल",
    "email_empty": "कृपया ईमेल इनपुट करें",
    "email_not_valid": "विद्युतडाक मान्य नहीं है",
    "email_not_valid_1": "कृपया अपना ईमेल डालें",
    "email_not_valid_2": "ईमेल मान्य नहीं है, कृपया कोई दूसरा पता उपयोग करें.",
    "email_not_valid_3": "कोई ईमेल इनपुट नहीं",
    "password_placeholder": "पासवर्ड",
    "password_empty": "कृपया पासवर्ड डालें",
    "password_not_valid": "गलत खाता या पासवर्ड",
    "password_not_valid_1": "पासवर्ड 8 अक्षरों से अधिक होना आवश्यक है",
    "password_not_valid_2": "कृपया पासवर्ड बनाएं",
    "password_placeholder_1": "अपना पासवर्ड बनाएं",
    "password_placeholder_2": "अपने पासवर्ड की पुष्टि करें",
    "password_placeholder_3": "नया पासवर्ड बनाएं",
    "password_placeholder_4": "नए पासवर्ड की पुष्टि करें",
    "password_placeholder_5": "पुराना पासवर्ड डालें",
    "copy_password_empty": "कृपया पासवर्ड की पुष्टि करें",
    "copy_password_not_valid": "कृपया अपने पासवर्ड की पुष्टि करें",
    "copy_passwords_inconsistent": "आपका पासवर्ड पुष्टिकरण मेल नहीं खाता",
    "code_empty": "कृपया सत्यापन कोड डालें",
    "code_not_valid": "अवैध सत्यापन संकेत",
    "code_placeholder": "सत्यापन कोड",
    "not_received_code": "यदि आपके मेलबॉक्स को लंबे समय से सत्यापन कोड प्राप्त नहीं हुआ है, तो कृपया पुनः सत्यापन कोड प्राप्त करें।",
    "get_first_code": "कृपया पहले सत्यापन कोड प्राप्त करें।",
    "last_name_placeholder": "कृपया अपना अंतिम नाम दर्ज करें",
    "first_name_placeholder": "कृपया अपना पहला नाम दें",
    "address_placeholder": "कृपया अपना पता दर्ज करें",
    "no_code_text": "हमने एक सत्यापन कोड भेजा है। कृपया अपना कोड दर्ज करें। <span class='tips'>क्या आपको कोड नहीं मिला?",
    "no_code_text_1": "1. कृपया सुनिश्चित करें कि ईमेल पता वैध है और उस पर ईमेल प्राप्त किए जा सकते हैं।",
    "no_code_text_2": "2. चूंकि ईमेल सिस्टम द्वारा स्वचालित रूप से भेजा जाता है, इसलिए इसे स्पैम या जंक ईमेल के रूप में चिह्नित किया जा सकता है। कृपया जाँच करें कि ईमेल ट्रैश फ़ोल्डर में है या नहीं।",
    "no_code_text_3": "3. आपकी समस्या का समाधान नहीं हो पा रहा?",
    "no_code_text_3_span": "तो फिर हमसे संपर्क करने के लिए यहां क्लिक करें।",
    "order_no": "आपने कोई उत्पाद नहीं खरीदा है, यदि आपके कोई प्रश्न हैं, तो कृपया <a href=\"https://www.mindonmap.com/contact-us/\">हमसे संपर्क करें</a>।",
    "error_24901": "चालू खाते में कोई ईमेल लिंक नहीं है, और ऑर्डर नहीं मिल पा रहे हैं। कृपया ईमेल लिंक करें।",
    "user_guide": "उपयोगकर्ता गाइड>>",
    "download": "डाउनलोड करना",
    "order_number": "क्रम संख्या:",
    "Refund": "धनवापसी",
    "Disabled": "अक्षम",
    "Normal": "सामान्य",
    "Modify": "संशोधित",
    "Modify_1": "संशोधित करें>>",
    "Connect": "जोड़ना",
    "unlink_success": "सफलतापूर्वक अनलिंक करें",
    "connect_success": "सफलतापूर्वक कनेक्ट करें",
    "feedback_title": "आपकी प्रतिक्रिया के लिए धन्यवाद। कृपया अपनी समस्या छोड़ दें और हम 24 घंटे के भीतर आपको जवाब देंगे।",
    "feedback_thank_you": "धन्यवाद!<br />आपका फीडबैक सफलतापूर्वक सबमिट कर दिया गया है।",
    "feedback_email": "अपना ई मेल यहा भरे!",
    "feedback_content": "आपके सामने आने वाली कोई भी समस्या या सुझाव यहां छोड़ें।",
    "feedback_submit": "जमा करना",
    "form_contents": "आपने कोई विवरण दर्ज नहीं किया है। कृपया इसे दर्ज करें और पुनः सबमिट करें।",
    "old_password": "कृपया पुराना पासवर्ड डालें",
    "new_password": "कृपया नया पासवर्ड बनाएं",
    "old_new_password": "नया पासवर्ड पुराने पासवर्ड जैसा नहीं हो सकता",
    "incorrect_password": "गलत पासवर्ड",
    "delete_no": "इसे अभी मिटाओ",
    "Caps": "कैप्स लॉक ऑन है",
    "Get": "पाना",
    "Done": "हो गया",
    "error_20001": "त्रुटि: 20001. कृपया पुनः लॉग इन करें.",
    "error_20002": "त्रुटि: 20002. कृपया पुनः लॉग इन करें.",
    "error_20003": "त्रुटि: 20003. कृपया पुनः लॉग इन करें.",
    "error_20004": "अनुरोध विफल हुआ (त्रुटि कोड: 20004)। कृपया पुनः प्रयास करें।",
    "error_20005": "लॉगिन सत्र समाप्त हो गया है (त्रुटि: 20005)। कृपया पुनः लॉग इन करें।",
    "error_20006": "अनुरोध विफल हुआ (त्रुटि कोड: 20006)। कृपया पुनः प्रयास करें।",
    "error_20007": "लॉगिन सत्र समाप्त हो गया है (त्रुटि: 20007)। कृपया पुनः लॉग इन करें।",
    "error_20008": "लॉगिन सत्र समाप्त हो गया है (त्रुटि: 20008)। कृपया पुनः लॉग इन करें।",
    "error_20009": "लॉगिन सत्र समाप्त हो गया है (त्रुटि: 20009)। कृपया पुनः लॉग इन करें।",
    "error_20101": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20101)",
    "error_20102": "ईमेल मान्य नहीं है (त्रुटि कोड: 20102)",
    "error_20103": "अनुरोध विफल हुआ (त्रुटि कोड: 20103)। कृपया इसे फिर से प्रयास करें",
    "error_20104": "ईमेल पहले से ही उपयोग में है, कृपया <a href=\"https://account.mindonmap.com/login/\">लॉग इन करें</a> या नए ईमेल से पंजीकरण करें",
    "error_20105": "अनुरोध विफल हुआ (त्रुटि कोड: 20105)। कृपया इसे फिर से प्रयास करें",
    "error_20106": "ईमेल भेजने में विफल, कृपया पुनः प्रयास करें",
    "error_20201": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20201)",
    "error_20202": "कृपया अपना पासवर्ड डालें (त्रुटि कोड: 20202)",
    "error_20203": "कृपया सत्यापन कोड दर्ज करें (त्रुटि कोड: 20203)",
    "error_20204": "ईमेल मान्य नहीं है (त्रुटि कोड: 20204)",
    "error_20205": "पासवर्ड 8 अक्षरों से अधिक होना आवश्यक है (त्रुटि कोड: 20205)",
    "error_20206": "अनुरोध विफल हुआ (त्रुटि कोड: 20206)। कृपया इसे फिर से प्रयास करें",
    "error_20207": "अवैध सत्यापन संकेत",
    "error_20208": "अनुरोध विफल हुआ (त्रुटि कोड: 20208)। कृपया इसे फिर से प्रयास करें",
    "error_20209": "अनुरोध विफल हुआ (त्रुटि कोड: 20209)। कृपया इसे फिर से प्रयास करें",
    "error_20301": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20301)",
    "error_20302": "त्रुटि: 20302. कृपया हमसे संपर्क करें",
    "error_20303": "ईमेल मान्य नहीं है (त्रुटि कोड: 20303)",
    "error_20304": "अनुरोध विफल हुआ (त्रुटि कोड: 20304)। कृपया इसे फिर से प्रयास करें",
    "error_20305": "खाता मौजूद नहीं है। कृपया पहले पुनः दर्ज करें या <a href=\"https://account.mindonmap.com/register/\">इसे बनाएँ</a>।",
    "error_20306": "अभी तक कोई पासवर्ड नहीं है। <a href=\"https://account.mindonmap.com/passwordless-login/\">पासवर्ड रहित लॉगिन</a> का उपयोग करें या <a href=\"https://account.mindonmap.com/create-password/\">पासवर्ड सेट करें</a> और लॉग इन करें।",
    "error_20308": "अनुरोध विफल हुआ (त्रुटि कोड: 20308)। कृपया इसे फिर से प्रयास करें",
    "error_20401": "लॉग आउट करने में विफल (त्रुटि कोड: 20401)। कृपया इसे फिर से आज़माएँ",
    "error_20501": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20501)",
    "error_20502": "ईमेल मान्य नहीं है (त्रुटि कोड: 20502)",
    "error_20503": "अनुरोध विफल हुआ (त्रुटि कोड: 20503)। कृपया इसे फिर से प्रयास करें",
    "error_20504": "ईमेल भेजने में विफल। कृपया पुनः प्रयास करें।",
    "error_20601": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20601)",
    "error_20602": "कृपया सत्यापन कोड दर्ज करें (त्रुटि कोड: 20602)",
    "error_20603": "ईमेल मान्य नहीं है (त्रुटि कोड: 20603)",
    "error_20604": "अनुरोध विफल हुआ (त्रुटि कोड: 20604)। कृपया इसे फिर से प्रयास करें",
    "error_20606": "अनुरोध विफल हुआ (त्रुटि कोड: 20606)। कृपया इसे फिर से प्रयास करें",
    "error_20607": "अनुरोध विफल हुआ (त्रुटि कोड: 20607)। कृपया इसे फिर से प्रयास करें",
    "error_20608": "अनुरोध विफल हुआ (त्रुटि कोड: 20608)। कृपया इसे फिर से प्रयास करें",
    "error_20701": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20701)",
    "error_20702": "ईमेल मान्य नहीं है (त्रुटि कोड: 20702)",
    "error_20703": "अनुरोध विफल हुआ (त्रुटि कोड: 20703)। कृपया इसे फिर से प्रयास करें",
    "error_20704": "खाता मौजूद नहीं है। कृपया पहले पुनः दर्ज करें या <a href=\"https://account.mindonmap.com/register/\">इसे बनाएँ</a>।",
    "error_20705": "अनुरोध विफल हुआ (त्रुटि कोड: 20705)। कृपया इसे फिर से प्रयास करें",
    "error_20706": "ईमेल भेजने में विफल। कृपया पुनः प्रयास करें",
    "error_20801": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 20801)",
    "error_20802": "त्रुटि: 20802. कृपया हमसे संपर्क करें",
    "error_20803": "कृपया सत्यापन कोड दर्ज करें (त्रुटि कोड: 20803)",
    "error_20804": "ईमेल मान्य नहीं है (त्रुटि कोड: 20804)",
    "error_20805": "8 अक्षरों से अधिक का पासवर्ड आवश्यक है (त्रुटि कोड: 20805)",
    "error_20806": "अनुरोध विफल हुआ (त्रुटि कोड: 20806)। कृपया इसे फिर से प्रयास करें",
    "error_20808": "अनुरोध विफल हुआ (त्रुटि कोड: 20808)। कृपया इसे फिर से प्रयास करें",
    "error_20901": "अनुरोध विफल हुआ (त्रुटि कोड: 20901)। कृपया इसे फिर से प्रयास करें",
    "error_20902": "अनुरोध विफल हुआ (त्रुटि कोड: 20902)। कृपया इसे फिर से प्रयास करें",
    "error_21000": "परिवर्तन सहेजे गए हैं",
    "error_21001": "कोई जानकारी प्रस्तुत नहीं की गई (त्रुटि कोड: 21001)",
    "error_21002": "अनुरोध विफल हुआ (त्रुटि कोड: 21002)। कृपया इसे फिर से प्रयास करें",
    "error_21101": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 21101)",
    "error_21102": "ईमेल मान्य नहीं है (त्रुटि कोड: 21102)",
    "error_21103": "अनुरोध विफल (त्रुटि कोड: 21103), कृपया पुनः प्रयास करें",
    "error_21104": "ईमेल पहले से ही कनेक्ट है, कृपया नया ईमेल इस्तेमाल करें",
    "error_21105": "अनुरोध विफल (त्रुटि कोड: 21105), कृपया पुनः प्रयास करें",
    "error_21106": "ईमेल भेजने में विफल। कृपया पुनः प्रयास करें",
    "error_21201": "कृपया अपना ईमेल दर्ज करें (त्रुटि कोड: 21201)",
    "error_21202": "कृपया सत्यापन कोड दर्ज करें (त्रुटि कोड: 21202)",
    "error_21203": "ईमेल मान्य नहीं है (त्रुटि कोड: 21203)",
    "error_21204": "त्रुटि: 21204. कृपया हमसे संपर्क करें",
    "error_21205": "त्रुटि: 21205. कृपया हमसे संपर्क करें",
    "error_21206": "8 अक्षरों से अधिक का पासवर्ड आवश्यक है (त्रुटि कोड: 21206)",
    "error_21207": "अनुरोध विफल हुआ (त्रुटि कोड: 21207)। कृपया इसे फिर से प्रयास करें",
    "error_21209": "अनुरोध विफल हुआ (त्रुटि कोड: 21209)। कृपया इसे फिर से प्रयास करें",
    "error_21301": "कृपया पुराना पासवर्ड डालें (त्रुटि कोड: 21301)",
    "error_21302": "कृपया नया पासवर्ड बनाएं (त्रुटि कोड: 21302)",
    "error_21303": "नया पासवर्ड पुराने पासवर्ड के समान नहीं हो सकता। (त्रुटि: 21303)",
    "error_21304": "8 अक्षरों से अधिक का पासवर्ड आवश्यक है (त्रुटि कोड: 21304)",
    "error_21306": "अनुरोध विफल हुआ (त्रुटि कोड: 21306)। कृपया इसे फिर से प्रयास करें",
    "error_21402": "अनुरोध विफल हुआ (त्रुटि कोड: 21402)। कृपया इसे फिर से प्रयास करें",
    "error_21403": "सत्यापन कोड भेजने में विफल। कृपया इसे पुनः भेजें",
    "error_21500": "खाता हटा दिया गया है",
    "error_21501": "कृपया सत्यापन कोड दर्ज करें (त्रुटि कोड: 21501)",
    "error_21502": "लॉगिन सत्र समाप्त हो गया है (त्रुटि: 21502)। कृपया पुनः लॉग इन करें।",
    "error_21503": "अनुरोध विफल हुआ (त्रुटि कोड: 21503)। कृपया इसे फिर से प्रयास करें",
    "error_21505": "अनुरोध विफल (त्रुटि कोड: 21505), कृपया पुनः प्रयास करें",
    "error_21601": "त्रुटि: 20601. कृपया हमसे संपर्क करें",
    "error_21602": "अमान्य सत्यापन (त्रुटि: 20602)। कृपया इसे फिर से प्रयास करें।",
    "error_21603": "त्रुटि: 20603. कृपया पुनः प्रयास करें",
    "error_21604": "अनुरोध विफल हुआ (त्रुटि कोड: 21604)। कृपया इसे फिर से प्रयास करें",
    "error_21606": "अनुरोध विफल हुआ (त्रुटि कोड: 21606)। कृपया इसे फिर से प्रयास करें",
    "error_21611": "अनुरोध विफल हुआ (त्रुटि कोड: 21611)। कृपया इसे फिर से प्रयास करें",
    "error_21801": "त्रुटि: 21801. कृपया हमसे संपर्क करें",
    "error_21802": "अनुरोध विफल हुआ (त्रुटि: 21802)। कृपया पुनः प्रयास करें",
    "error_21803": "त्रुटि: 21803. कृपया पुनः प्रयास करें",
    "error_21804": "अनुरोध विफल हुआ (त्रुटि कोड: 21804)। कृपया इसे फिर से प्रयास करें",
    "error_21806": "त्रुटि: 21806. कृपया पुनः प्रयास करें",
    "error_21807": "त्रुटि: 21807. कृपया हमसे संपर्क करें",
    "error_21808": "त्रुटि: 21808. कृपया हमसे संपर्क करें",
    "error_21809": "त्रुटि: 21809. कृपया हमसे संपर्क करें",
    "error_21810": "त्रुटि: 21810. कृपया हमसे संपर्क करें",
    "error_21811": "त्रुटि: 21811. कृपया हमसे संपर्क करें",
    "error_21812": "त्रुटि: 21812. कृपया हमसे संपर्क करें",
    "error_21813": "अनुरोध विफल हुआ (त्रुटि कोड: 21813)। कृपया इसे फिर से प्रयास करें",
    "error_21814": "त्रुटि: 21814. कृपया हमसे संपर्क करें",
    "error_21815": "अनुरोध विफल हुआ (त्रुटि कोड: 21815)। कृपया इसे फिर से प्रयास करें",
    "error_21816": "त्रुटि: 21816. कृपया हमसे संपर्क करें",
    "error_21817": "त्रुटि: 21817. कृपया हमसे संपर्क करें",
    "error_21818": "त्रुटि: 21818. कृपया हमसे संपर्क करें",
    "error_21819": "अनुरोध विफल हुआ (त्रुटि कोड: 21819)। कृपया इसे फिर से प्रयास करें",
    "error_21820": "त्रुटि: 21820. कृपया हमसे संपर्क करें",
    "error_21821": "त्रुटि: 21821. कृपया हमसे संपर्क करें",
    "error_21822": "त्रुटि: 21822. कृपया हमसे संपर्क करें",
    "error_21823": "अनुरोध विफल हुआ (त्रुटि कोड: 21823)। कृपया इसे फिर से प्रयास करें",
    "error_21824": "अनुरोध विफल हुआ (त्रुटि कोड: 21824)। कृपया इसे फिर से प्रयास करें",
    "error_21825": "अनुरोध विफल हुआ (त्रुटि कोड: 21825)। कृपया इसे फिर से प्रयास करें",
    "error_21826": "अनुरोध विफल हुआ (त्रुटि कोड: 21826)। कृपया इसे फिर से प्रयास करें",
    "error_21901": "त्रुटि: 21901. कृपया हमसे संपर्क करें",
    "error_21902": "अनुरोध विफल हुआ (त्रुटि कोड: 21902)। कृपया इसे फिर से प्रयास करें",
    "error_21903": "खाते की स्थिति बदल गई है (त्रुटि कोड: 21903), कृपया पृष्ठ को ताज़ा करें और पुनः प्रयास करें",
    "error_21904": "त्रुटि: 21904. कृपया पुनः प्रयास करें",
    "error_21905": "त्रुटि: 21905. कृपया पुनः प्रयास करें",
    "error_21906": "अनुरोध विफल हुआ (त्रुटि कोड: 21906)। कृपया इसे फिर से प्रयास करें",
    "error_21907": "Google खाते को अन्य खाते से लिंक कर दिया गया है",
    "error_21908": "अनुरोध विफल हुआ (त्रुटि कोड: 21908)। कृपया इसे फिर से प्रयास करें",
    "error_22001": "अनुरोध विफल हुआ (त्रुटि कोड: 22001)। कृपया इसे फिर से प्रयास करें",
    "error_22002": "बिना किसी अतिरिक्त लॉगिन के अनलिंक विफल हुआ",
    "error_22003": "अनुरोध विफल हुआ (त्रुटि कोड: 22003)। कृपया इसे फिर से प्रयास करें",
    "error_22101": "त्रुटि: 22101. कृपया हमसे संपर्क करें",
    "error_22102": "खाते की स्थिति बदल गई है (त्रुटि कोड: 22102), कृपया पृष्ठ को ताज़ा करें और पुनः प्रयास करें",
    "error_22103": "अनुरोध विफल हुआ (त्रुटि कोड: 22103)। कृपया इसे फिर से प्रयास करें",
    "error_22104": "खाते की स्थिति बदल गई है (त्रुटि कोड: 22104), कृपया पृष्ठ को ताज़ा करें और पुनः प्रयास करें",
    "error_22105": "त्रुटि: 22105. कृपया पुनः प्रयास करें",
    "error_22106": "त्रुटि: 22106. कृपया पुनः प्रयास करें",
    "error_22107": "त्रुटि: 22107. कृपया हमसे संपर्क करें",
    "error_22108": "अनुरोध विफल हुआ (त्रुटि कोड: 22108)। कृपया इसे फिर से प्रयास करें",
    "error_22201": "त्रुटि: 22201. कृपया हमसे संपर्क करें",
    "error_22202": "अमान्य सत्यापन (त्रुटि: 22202)। कृपया इसे फिर से प्रयास करें।",
    "error_22203": "त्रुटि: 22203. कृपया पुनः प्रयास करें\"",
    "error_22204": "अनुरोध विफल हुआ (त्रुटि कोड: 22204)। कृपया इसे फिर से प्रयास करें",
    "error_22206": "अनुरोध विफल हुआ (त्रुटि कोड: 22206)। कृपया इसे फिर से प्रयास करें",
    "error_22401": "त्रुटि: 22401. कृपया हमसे संपर्क करें",
    "error_22402": "अमान्य सत्यापन (त्रुटि: 22402)। कृपया इसे फिर से प्रयास करें।",
    "error_22403": "त्रुटि: 22403. कृपया पुनः प्रयास करें",
    "error_22404": "अनुरोध विफल हुआ (त्रुटि कोड: 22404)। कृपया इसे फिर से प्रयास करें",
    "error_22405": "फेसबुक अकाउंट को अन्य ईमेल से लिंक कर दिया गया है",
    "error_22406": "त्रुटि: 22406. कृपया पुनः प्रयास करें",
    "error_22407": "त्रुटि: 22407. कृपया हमसे संपर्क करें",
    "error_22408": "त्रुटि: 22408. कृपया हमसे संपर्क करें",
    "error_22409": "त्रुटि: 22409. कृपया हमसे संपर्क करें",
    "error_22410": "त्रुटि: 224010. कृपया हमसे संपर्क करें",
    "error_22411": "त्रुटि: 224011. कृपया हमसे संपर्क करें",
    "error_22412": "त्रुटि: 224012. कृपया हमसे संपर्क करें",
    "error_22413": "त्रुटि: 22413. कृपया हमसे संपर्क करें",
    "error_22414": "अनुरोध विफल हुआ (त्रुटि कोड: 22414)। कृपया इसे फिर से प्रयास करें",
    "error_22415": "त्रुटि: 22415. कृपया हमसे संपर्क करें",
    "error_22416": "त्रुटि: 22416. कृपया हमसे संपर्क करें",
    "error_22417": "त्रुटि: 22417. कृपया हमसे संपर्क करें",
    "error_22418": "अनुरोध विफल हुआ (त्रुटि कोड: 22418)। कृपया इसे फिर से प्रयास करें",
    "error_22419": "त्रुटि: 22419. कृपया हमसे संपर्क करें",
    "error_22420": "त्रुटि: 22420. कृपया हमसे संपर्क करें",
    "error_22421": "त्रुटि: 22421. कृपया हमसे संपर्क करें",
    "error_22422": "अनुरोध विफल हुआ (त्रुटि कोड: 22422)। कृपया इसे फिर से प्रयास करें",
    "error_22423": "अनुरोध विफल हुआ (त्रुटि कोड: 22423)। कृपया इसे फिर से प्रयास करें",
    "error_22424": "अनुरोध विफल हुआ (त्रुटि कोड: 22424)। कृपया इसे फिर से प्रयास करें",
    "error_22425": "अनुरोध विफल हुआ (त्रुटि कोड: 22425)। कृपया इसे फिर से प्रयास करें",
    "error_20098": "त्रुटि कोड: 20098. यदि आप निजी मोड में ब्राउज़ कर रहे हैं, तो कृपया सामान्य मोड पर स्विच करें और पुनः प्रयास करें।",
    "error_22298": "Google के लिए अनुरोध विफल हुआ (त्रुटि: 22298)। कृपया इसे फिर से प्रयास करें।",
    "error_22498": "फेसबुक के लिए अनुरोध विफल (त्रुटि: 22498)। कृपया इसे फिर से प्रयास करें।",
    "error_24902": "अनुरोध विफल हुआ (त्रुटि कोड: 24902)। कृपया इसे फिर से प्रयास करें",
    "error_24903": "अनुरोध विफल हुआ (त्रुटि कोड: 24903)। कृपया इसे फिर से प्रयास करें",
    "error_24904": "अनुरोध विफल हुआ (त्रुटि कोड: 24904)। कृपया इसे फिर से प्रयास करें",
    "error_24905": "अनुरोध विफल हुआ (त्रुटि कोड: 24905)। कृपया इसे फिर से प्रयास करें",
    "login_title": "माइंडऑनमैप पर लॉग इन करें",
    "log_in": "लॉग इन करें",
    "no_account": "खाता नहीं?",
    "create_it": "इसे बनाओ",
    "or_log_in_with": "या लॉग इन करें",
    "passwordless_login": "पासवर्ड रहित लॉगिन",
    "log_in_done": "लॉग इन हो गया",
    "three_rd_account_connect_info": "बधाई हो! आपने सफलतापूर्वक लॉग इन कर लिया है। अब आप एक ईमेल अकाउंट कनेक्ट कर सकते हैं जिसका उपयोग भविष्य में लॉग इन करने के लिए किया जाएगा।",
    "see_my_account": "मेरा खाता देखें",
    "three_rd_login_merge_account": "तीसरे पक्ष के खाते का ईमेल पता साइन अप कर दिया गया है, क्या आप सीधे इस ईमेल पते से कनेक्ट और लॉग इन करना चाहते हैं?",
    "connect_log_in": "कनेक्ट करें और लॉग इन करें",
    "create_an_account": "खाता बनाएं",
    "back_to_log_in": "लॉगिन पर वापस जाएं",
    "create_password": "पासवर्ड बनाएं",
    "create_now": "अब बनाओ",
    "password_login_subtitle": "ईमेल द्वारा पासवर्ड रहित लॉगिन",
    "account_login": "खाते में प्रवेश",
    "rights": "यह खाता बनाकर, आप <a href=\"https://www.mindonmap.com/terms-and-conditions/\">सेवा की शर्तों</a> और <a href=\"https://www.mindonmap.com/privacy-policy/\">गोपनीयता नीति</a> से सहमत होते हैं",
    "passwordless_login_done": "पासवर्ड रहित लॉगिन हो गया",
    "passwordless_login_info": "बधाई हो, आपने पासवर्ड रहित लॉगिन सफलतापूर्वक पूरा कर लिया है। आप इस खाते के लिए पासवर्ड बना सकते हैं और भविष्य में खाते और पासवर्ड से लॉग इन कर सकते हैं। <a href=\"/create-password\" style=\"display: initial;\">अभी बनाएँ</a>",
    "sign_up": "साइन अप करें",
    "register_info": "अपना खाता बनाएं",
    "reset_now": "अभी रीसेट करें",
    "forgot_password": "पासवर्ड भूल गए",
    "reset_password_subtitle": "पासवर्ड रीसेट करने के लिए अपने खाते के ईमेल का उपयोग करें",
    "plan_products": "योजनाएँ और उत्पाद",
    "nick_name": "उपयोगकर्ता नाम:",
    "email": "ईमेल:",
    "my_products": "मेरे उत्पाद",
    "my_orders": "मेरे आदेश",
    "unlink": "अनलिंक",
    "link": "जोड़ना",
    "connected_accounts": "जुड़े हुए खाते",
    "last_name": "उपनाम:",
    "first_name": "पहला नाम:",
    "Gender": "लिंग:",
    "Birth": "जन्म:",
    "Month": "महीना",
    "Year": "वर्ष",
    "Country_Region": "देश/क्षेत्र:",
    "Address": "पता:",
    "Save": "बचाना",
    "Date": "तारीख",
    "Male": "पुरुष",
    "Female": "महिला",
    "Unspecified": "अनिर्दिष्ट",
    "Security": "सुरक्षा",
    "change_password": "पासवर्ड बदलें",
    "change_now": "अभी बदलें",
    "connect_email": "ईमेल कनेक्ट करें",
    "delete_account": "खाता हटा दो",
    "delete_account_info": "जब आपका अकाउंट डिलीट हो जाता है, तो आपके अकाउंट से जुड़ा मेरा अकाउंट का सारा डेटा हमेशा के लिए डिलीट हो जाएगा और हो सकता है कि आप उसे रिकवर न कर पाएं। हम आपको सलाह देते हैं कि आप सावधानी से काम लें।",
    "Delete": "मिटाना",
    "Logout": "लॉग आउट",
    "my_profile": "मेरी प्रोफाइल",
    "guides_faqs": "मार्गदर्शिकाएँ एवं अक्सर पूछे जाने वाले प्रश्न",
    "More": "अधिक",
    "guides": "गाइड",
    "register": "पंजीकरण करवाना",
    "hot_faq": "गर्म पूछे जाने वाले प्रश्न",
    "Contents": "विषय-सूची:",
    "contact_us": "हमसे संपर्क करें>>",
    "plan": "योजना",
    "unregistered": "अपंजीकृत",
    "buy_more": "अधिक खरीदें",
    "buy_again": "फिर से खरीदें",
    "buy_now": "अभी खरीदें",
    "free_no_limit": "निःशुल्क एवं कोई सीमा नहीं",
    "expired": "खत्म हो चुका",
    "lifetime": "जीवनभर",
    "remain": "अवशेष",
    "day_s": "दिन",
    "error_24801": "अनुरोध विफल हुआ (त्रुटि कोड: 24801)। कृपया इसे फिर से प्रयास करें",
    "no_app_found": "कोई ऐप नहीं मिला! <a href=\"/\">रिफ्रेश करें</a> या <a href=\"https://www.mindonmap.com/\">आधिकारिक वेबसाइट पर जाएं</a>",
    "get_more": "अधिक जानकारी प्राप्त करें >>",
    "edit_photo": "फ़ोटो संपादित करें",
    "select_photo": "फोटो चुनें",
    "change_photo": "छवि बदलो",
    "cancel": "रद्द करना",
    "hide_password": "पासवर्ड छिपाएं",
    "show_password": "पासवर्ड दिखाए",
    "zoom_in": "ज़ूम इन",
    "zoom_out": "ज़ूम आउट",
    "rotate": "घुमाएँ",
    "horizontal_flip": "क्षैतिज फ़्लिप",
    "vertical_flip": "ऊर्ध्वाधर फ्लिप",
    "country": "देश",
    "country_1": "अपना देश/क्षेत्र चुनें",
    "country_2": "आलैंड द्वीप",
    "country_3": "अफ़ग़ानिस्तान",
    "country_4": "अल्बानिया",
    "country_5": "एलजीरिया",
    "country_6": "अमेरिकी समोआ",
    "country_7": "एंडोरा",
    "country_8": "अंगोला",
    "country_9": "एंगुइला",
    "country_10": "अंटार्कटिका",
    "country_11": "एंटीगुआ और बारबुडा",
    "country_12": "अर्जेंटीना",
    "country_13": "आर्मीनिया",
    "country_14": "अरूबा",
    "country_15": "ऑस्ट्रेलिया",
    "country_16": "ऑस्ट्रिया",
    "country_17": "आज़रबाइजान",
    "country_18": "बहरीन",
    "country_19": "बांग्लादेश",
    "country_20": "बारबाडोस",
    "country_21": "बेलोरूस",
    "country_22": "बेल्जियम",
    "country_23": "बेलीज़",
    "country_24": "बेनिन",
    "country_25": "बरमूडा",
    "country_26": "भूटान",
    "country_27": "बोलीविया",
    "country_28": "बोस्निया और हर्जेगोविना",
    "country_29": "बोत्सवाना",
    "country_30": "बौवेट द्वीप",
    "country_31": "ब्राज़िल",
    "country_32": "ब्रिटेन और भारतीय समुद्री क्षेत्र",
    "country_33": "ब्रिटिश वर्जिन आइसलैण्ड्स",
    "country_34": "ब्रुनेई",
    "country_35": "बुल्गारिया",
    "country_36": "बुर्किना",
    "country_37": "बुस्र्न्दी",
    "country_38": "कंबोडिया",
    "country_39": "कैमरून",
    "country_40": "कनाडा",
    "country_41": "केप वर्ड",
    "country_42": "कैरिबियन नीदरलैंड",
    "country_43": "केमन द्वीपसमूह",
    "country_44": "केन्द्रीय अफ़्रीकी गणराज्य",
    "country_45": "काग़ज़ का टुकड़ा",
    "country_46": "चिली",
    "country_47": "चीन",
    "country_48": "क्रिसमस द्वीप",
    "country_49": "कोकोस (कीलिंग) द्वीप",
    "country_50": "कोलंबिया",
    "country_51": "कुक द्वीपसमूह",
    "country_52": "कोस्टा रिका",
    "country_53": "कोटे डी आइवर",
    "country_54": "क्रोएशिया",
    "country_55": "क्यूबा",
    "country_56": "साइप्रस",
    "country_57": "चेक रिपब्लिक",
    "country_58": "कांगो लोकतांत्रिक गणराज्य",
    "country_59": "डेनमार्क",
    "country_60": "ज़िबूटी",
    "country_61": "डोमिनिका",
    "country_62": "डोमिनिकन गणराज्य",
    "country_63": "इक्वेडोर",
    "country_64": "मिस्र",
    "country_65": "अल साल्वाडोर",
    "country_66": "भूमध्यवर्ती गिनी",
    "country_67": "इरिट्रिया",
    "country_68": "एस्तोनिया",
    "country_69": "इथियोपिया",
    "country_70": "फ़ॉकलैंड आइलैंड",
    "country_71": "फ़ैरो द्वीप",
    "country_72": "माइक्रोनेशिया के संघीय राज्य",
    "country_73": "फ़िजी",
    "country_74": "फिनलैंड",
    "country_75": "फ्रांस",
    "country_76": "फ्रेंच गयाना",
    "country_77": "फ़्रेंच पोलिनेशिया",
    "country_78": "दक्षिणी फ्राँसिसी क्षेत्र",
    "country_79": "गैबॉन",
    "country_80": "गाम्बिया",
    "country_81": "जॉर्जिया",
    "country_82": "जर्मनी",
    "country_83": "घाना",
    "country_84": "जिब्राल्टर",
    "country_85": "ग्रेट ब्रिटेन (यूनाइटेड किंगडम; इंग्लैंड)",
    "country_86": "यूनान",
    "country_87": "ग्रीनलैंड",
    "country_88": "ग्रेनेडा",
    "country_89": "ग्वाडेलोप",
    "country_90": "गुआम",
    "country_91": "ग्वाटेमाला",
    "country_92": "ग्वेर्नसे",
    "country_93": "गिनी",
    "country_94": "गिनी-बिसाऊ",
    "country_95": "गुयाना",
    "country_96": "हैती",
    "country_97": "हर्ड द्वीप और मैकडोनाल्ड द्वीप",
    "country_98": "होंडुरस",
    "country_99": "हांगकांग",
    "country_100": "हंगरी",
    "country_101": "आइसलैंड",
    "country_102": "भारत",
    "country_103": "इंडोनेशिया",
    "country_104": "ईरान",
    "country_105": "इराक",
    "country_106": "आयरलैंड",
    "country_107": "मैन द्वीप",
    "country_108": "इजराइल",
    "country_109": "इटली",
    "country_110": "जमैका",
    "country_111": "जापान",
    "country_112": "जर्सी",
    "country_113": "जॉर्डन",
    "country_114": "कजाखस्तान",
    "country_115": "केन्या",
    "country_116": "किरिबाती",
    "country_117": "कुवैट",
    "country_118": "किर्गिज़स्तान",
    "country_119": "लाओस",
    "country_120": "लातविया",
    "country_121": "लेबनान",
    "country_122": "लिसोटो",
    "country_123": "लाइबेरिया",
    "country_124": "लीबिया",
    "country_125": "लिकटेंस्टाइन",
    "country_126": "लिथुआनिया",
    "country_127": "लक्समबर्ग",
    "country_128": "मकाओ",
    "country_129": "मेडागास्कर",
    "country_130": "मलावी",
    "country_131": "मलेशिया",
    "country_132": "मालदीव",
    "country_133": "माली",
    "country_134": "माल्टा",
    "country_135": "मार्शल द्वीपसमूह",
    "country_136": "मार्टीनिक",
    "country_137": "मॉरिटानिया",
    "country_138": "मॉरीशस",
    "country_139": "मैयट",
    "country_140": "मेक्सिको",
    "country_141": "मोलदोवा",
    "country_142": "मोनाको",
    "country_143": "मंगोलिया",
    "country_144": "मोंटेनेग्रो",
    "country_145": "मोंटेसेराट",
    "country_146": "मोरक्को",
    "country_147": "मोज़ाम्बिक",
    "country_148": "म्यांमार (बर्मा)",
    "country_149": "नामिबिया",
    "country_150": "नाउरू",
    "country_151": "नेपाल",
    "country_152": "नीदरलैंड",
    "country_153": "नया केलडोनिया",
    "country_154": "न्यूज़ीलैंड",
    "country_155": "निकारागुआ",
    "country_156": "नाइजर",
    "country_157": "नाइजीरिया",
    "country_158": "नियू",
    "country_159": "नॉरफ़ॉक द्वीप",
    "country_160": "उत्तर कोरिया",
    "country_161": "उत्तरी मरीयाना द्वीप समूह",
    "country_162": "नॉर्वे",
    "country_163": "ओमान",
    "country_164": "पाकिस्तान",
    "country_165": "पलाउ",
    "country_166": "फिलीस्तीनी इलाके",
    "country_167": "पनामा",
    "country_168": "पापुआ न्यू गिनी",
    "country_169": "परागुआ",
    "country_170": "पेरू",
    "country_171": "पिटकेर्न द्वीप समूह",
    "country_172": "पोलैंड",
    "country_173": "पुर्तगाल",
    "country_174": "प्यूर्टो रिको",
    "country_175": "कतर",
    "country_176": "मैसेडोनिया गणराज्य (FYROM)",
    "country_177": "कांगो गणराज्य",
    "country_178": "रियूनियन",
    "country_179": "रोमानिया",
    "country_180": "रूसी संघ",
    "country_181": "रवांडा",
    "country_182": "सेंट बार्थेलेमी",
    "country_183": "सेंट मार्टिन (फ्रांस)",
    "country_184": "सेंट-पियरे और मिकेलॉन",
    "country_185": "समोआ",
    "country_186": "सैन मारिनो",
    "country_187": "साओ टोम और प्रिंसिपे",
    "country_188": "सऊदी अरब",
    "country_189": "सेनेगल",
    "country_190": "सर्बिया",
    "country_191": "सेशल्स",
    "country_192": "सेरा लिओन",
    "country_193": "सिंगापुर",
    "country_194": "स्लोवाकिया",
    "country_195": "स्लोवेनिया",
    "country_196": "सोलोमन इस्लैंडस",
    "country_197": "सोमालिया",
    "country_198": "दक्षिण अफ्रीका",
    "country_199": "दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीप",
    "country_200": "दक्षिण कोरिया",
    "country_201": "दक्षिण सूडान",
    "country_202": "स्पेन",
    "country_203": "श्रीलंका",
    "country_204": "सेंट हेलेना और डिपेंडेंसी",
    "country_205": "सेंट किट्स और नेविस",
    "country_206": "सेंट लूसिया",
    "country_207": "सेंट विंसेंट और ग्रेनेडाइंस",
    "country_208": "सूडान",
    "country_209": "सूरीनाम",
    "country_210": "स्वाजीलैंड",
    "country_211": "स्वीडन",
    "country_212": "स्विट्ज़रलैंड",
    "country_213": "सीरिया",
    "country_214": "ताइवान",
    "country_215": "तजाकिस्तान",
    "country_216": "तंजानिया",
    "country_217": "साँचा:देश डेटा SJM स्वालबार्ड",
    "country_218": "थाईलैंड",
    "country_219": "बहामास",
    "country_220": "कोमोरोस",
    "country_221": "फिलीपींस",
    "country_222": "तिमोर-लेस्ते (पूर्वी तिमोर)",
    "country_223": "चल देना",
    "country_224": "टोकेलाऊ",
    "country_225": "टोंगा",
    "country_226": "ट्रिनिडाड और टोबैगो",
    "country_227": "ट्यूनीशिया",
    "country_228": "टर्की",
    "country_229": "तुर्कमेनिस्तान",
    "country_230": "तुर्क और कैकोस द्वीप समूह",
    "country_231": "तुवालू",
    "country_232": "युगांडा",
    "country_233": "यूक्रेन",
    "country_234": "संयुक्त अरब अमीरात",
    "country_235": "संयुक्त राज्य अमेरिका के छोटे दूरस्थ द्वीपसमूह",
    "country_236": "युनाइटेड स्टेट्स ऑफ़ अमेरिका, यूएसए)",
    "country_237": "संयुक्त राज्य अमेरिका वर्जिन द्वीप समूह",
    "country_238": "उरुग्वे",
    "country_239": "उज़्बेकिस्तान",
    "country_240": "वानुअतु",
    "country_241": "वेटिकन सिटी (होली सी)",
    "country_242": "वेनेज़ुएला",
    "country_243": "वियतनाम",
    "country_244": "वाली और फ़्युटुना",
    "country_245": "पश्चिमी सहारा",
    "country_246": "यमन",
    "country_247": "जाम्बिया",
    "country_248": "ज़िम्बाब्वे",
    "google_login": "Google के साथ साइन इन करें",
    "State": "राज्य",
    "Activation_code": "एक्टिवेशन कोड",
    "Question": "उन सभी ऐप्स की सूची बनाएं जिनमें आपने लॉग इन किया है",
    "Copy_complete": "प्रतिलिपि पूर्ण",
    "footer": "कॉपीराइट © 2024 माइंडऑनमैप स्टूडियो। सभी अधिकार सुरक्षित।",
    "change_password_success": "पासवर्ड सफलतापूर्वक बदला गया",
    "successful_login_title": "लॉगिन की सफलता",
    "product_page": "उत्पाद पृष्ठ>>",
    "successful_login_info": "लॉगिन पूरा हो गया। कृपया वर्तमान पृष्ठ को बंद करें और निम्नलिखित प्रक्रिया को जारी रखने के लिए मूल टैब पर वापस जाएँ। वर्तमान पृष्ठ 5 सेकंड में स्वचालित रूप से बंद हो जाएगा। यदि स्वचालित रूप से बंद करना या \"संपन्न\" बटन पर क्लिक करके बंद करना विफल हो जाता है, तो कृपया इस टैब को सीधे बंद करें।",
    "successful_login_info_firefox": "लॉगिन पूरा हो गया। कृपया वर्तमान पृष्ठ को बंद करें और निम्नलिखित प्रक्रिया जारी रखने के लिए मूल टैब पर वापस जाएँ।",
    "my_account": "मेरा खाता",
    "my_history": "मेरा इतिहास",
    "remove_watermark": "पानी के निशान हटाएं",
    "no_history": "कोई इतिहास नहीं",
    "history_all": "सबका चयन करें",
    "history_open": "खुला",
    "history_down": "डाउनलोड करना",
    "history_delete": "मिटाना",
    "history_clear": "अमान्य हटाएं",
    "images": "इमेजिस",
    "use_this_function": "इस उत्पाद का उपयोग करें>>",
    "hd_downloading": "HD मूल चित्र डाउनलोड करने के लाभ:",
    "lifetimeRemaining": "जीवनकाल-शेष",
    "Remaining": "शेष",
    "email_verification": "ईमेल सत्यापन",
    "email_verification_info": "हमने आपके ईमेल <span class=\"email\"></span> पर सत्यापन संदेश भेज दिया है, और कृपया सत्यापन पूरा करें। सत्यापन के बाद, लाभ स्वचालित रूप से सिंक हो जाएँगे।",
    "wrong_email": "गलत ई - मेल पता?",
    "click_here_to_modify": "संशोधित करने के लिए यहां क्लिक करें",
    "get_more_help": "अधिक सहायता प्राप्त करें?",
    "click_here": "यहाँ क्लिक करें",
    "email_verification_info_success": "अपना ईमेल खाता सत्यापित करने के लिए बधाई।",
    "email_verification_info_error": "लिंक समाप्त हो जाने के कारण सत्यापन विफल हुआ.",
    "registration_succeeded": "पंजीकरण सफल हुआ",
    "registration_succeeded_info_1": "बधाई हो! आपने सफलतापूर्वक पंजीकरण कर लिया है। हमने आपके ईमेल <span class=\"email\"></span> पर सत्यापन संदेश भेज दिया है, और कृपया इस ईमेल का लाभ उठाने के लिए सत्यापन पूरा करें।",
    "registration_succeeded_info_2": "होम पेज पर वापस लौटने और इस उत्पाद का उपयोग करने के लिए \"संपन्न\" पर क्लिक करें।",
    "registration_succeeded_info_3": "वर्तमान पृष्ठ को बंद करने के लिए \"संपन्न\" पर क्लिक करें और निम्नलिखित कार्यों के लिए होम पेज पर वापस लौटें। यदि बंद करना विफल हो जाता है, तो कृपया टैब को मैन्युअल रूप से बंद करें।",
    "verify_email": "ईमेल सत्यापित करें",
    "registered_email_not_verified": "पंजीकृत ईमेल सत्यापित नहीं हुआ है, कृपया इसे एक बार सत्यापित करें।",
    "email_verification_time_1": "क्या आपको सत्यापन ईमेल प्राप्त नहीं हुआ?",
    "email_verification_time_2": "बाद",
    "email_verification_time_3": "इसे पुनः भेजने के लिए यहां क्लिक करें",
    "error_26301": "त्रुटि कोड: 26301, कृपया हमसे संपर्क करें",
    "error_26302": "त्रुटि कोड: 26302, कृपया हमसे संपर्क करें",
    "error_26303": "ईमेल प्रारूप त्रुटि (त्रुटि कोड: 26303)। कृपया इसे फिर से दर्ज करें",
    "error_26304": "8 अक्षरों से अधिक का पासवर्ड अनुशंसित है (त्रुटि कोड: 26304)",
    "error_26305": "अनुरोध विफल हुआ (त्रुटि कोड: 26305)। कृपया पुनः प्रयास करें",
    "error_26306": "ईमेल पंजीकृत हो गया है, कृपया <a href=\"https://account.mindonmap.com/login/\">लॉग इन पर जाएँ</a>",
    "error_26307": "अनुरोध विफल हुआ (त्रुटि कोड: 26307)। कृपया पुनः प्रयास करें",
    "error_26308": "अनुरोध विफल हुआ (त्रुटि कोड: 26308)। कृपया पुनः प्रयास करें",
    "error_26401": "त्रुटि कोड: 26401, कृपया पुनः प्रयास करें",
    "error_26402": "ईमेल सत्यापित हो गया है (त्रुटि कोड: 26402), कृपया पुनः प्रयास करें",
    "error_26403": "अनुरोध विफल हुआ (त्रुटि कोड: 26403)। कृपया पुनः प्रयास करें",
    "error_26404": "अनुरोध विफल हुआ (त्रुटि कोड: 26404)। कृपया पुनः प्रयास करें",
    "error_26501": "त्रुटि कोड: 26501, कृपया हमसे संपर्क करें",
    "error_26502": "त्रुटि कोड: 26502, कृपया हमसे संपर्क करें",
    "error_26503": "ईमेल प्रारूप त्रुटि (त्रुटि कोड: 26503)। कृपया इसे फिर से दर्ज करें",
    "error_26504": "अनुरोध विफल हुआ (त्रुटि कोड: 26504)। कृपया पुनः प्रयास करें",
    "error_26505": "ईमेल पंजीकृत नहीं किया गया है, कृपया पहले इसे <a href=\"https://account.mindonmap.com/register/\">पंजीकृत करें</a>",
    "error_26506": "ईमेल सत्यापित हो गया है.",
    "error_26507": "अनुरोध विफल हुआ (त्रुटि कोड: 26507)। कृपया पुनः प्रयास करें",
    "error_26508": "सत्यापन विफल (त्रुटि कोड: 26508), कृपया पुनः प्रयास करें",
    "error_26509": "अनुरोध विफल हुआ (त्रुटि कोड: 26509), कृपया पुनः प्रयास करें",
    "error_26510": "त्रुटि कोड: 26510, कृपया हमसे संपर्क करें",
    "error_26601": "त्रुटि कोड: 26601, कृपया हमसे संपर्क करें",
    "error_26602": "त्रुटि कोड: 26602, कृपया हमसे संपर्क करें",
    "error_26603": "अनुरोध विफल हुआ (त्रुटि कोड: 26603)। कृपया पुनः प्रयास करें",
    "error_26604": "त्रुटि कोड: 26604, कृपया हमसे संपर्क करें",
    "error_26605": "त्रुटि कोड: 26605, कृपया हमसे संपर्क करें",
    "error_26701": "त्रुटि कोड: 26701, कृपया हमसे संपर्क करें",
    "error_26702": "अनुरोध विफल हुआ (त्रुटि कोड: 26702)। कृपया पुनः प्रयास करें",
    "error_26703": "त्रुटि कोड: 26703, कृपया हमसे संपर्क करें",
    "error_26704": "त्रुटि कोड: 26704, कृपया हमसे संपर्क करें",
    "error_26705": "लॉग इन करने के लिए प्रतीक्षा करें (त्रुटि कोड: 26705)। कृपया पुनः प्रयास करें",
    "no_cookie": "आपने अपने ब्राउज़र में सभी कुकीज़ ब्लॉक करें फ़ंक्शन चालू कर दिया है, इसलिए आप लॉग इन नहीं कर सकते। कृपया सेटिंग्स में जाकर सभी कुकीज़ की अनुमति दें बॉक्स को चेक करें।",
    "error_26801": "त्रुटि कोड: 26801, कृपया हमसे संपर्क करें",
    "error_26802": "त्रुटि कोड: 26802, कृपया हमसे संपर्क करें",
    "error_26803": "अनुरोध विफल हुआ (त्रुटि कोड: 26803)। कृपया पुनः प्रयास करें",
    "error_26804": "अनुरोध विफल हुआ (त्रुटि कोड: 26804)। कृपया पुनः प्रयास करें",
    "error_order": "अनुरोध विफल (त्रुटि कोड: 27098), कृपया पुनः प्रयास करें!",
    "error_order1": "ऑर्डर क्वेरी अपूर्ण है（त्रुटि कोड:",
    "error_order2": "）, कृपया ताज़ा करें और पुनः प्रयास करें।",
    "modify_email_title": "ईमेल संशोधित करें",
    "modify_email_info": "आप अपने खाते में लॉग इन करने के लिए संशोधित ईमेल का उपयोग कर सकते हैं।",
    "images_per": "छवियाँ प्रति",
    "error_26101": "त्रुटि: 26101. कृपया हमसे संपर्क करें",
    "error_26102": "त्रुटि: 26102. कृपया हमसे संपर्क करें",
    "error_26103": "अनुरोध विफल (त्रुटि कोड: 26103)। कृपया इसे फिर से प्रयास करें",
    "error_26104": "त्रुटि कोड: 26104, कृपया पुनः प्रयास करें",
    "error_26105": "त्रुटि कोड: 26105, कृपया पुनः प्रयास करें",
    "error_26106": "हटाना विफल (त्रुटि कोड: 26106)। कृपया इसे फिर से प्रयास करें",
    "error_26201": "त्रुटि: 26201. कृपया हमसे संपर्क करें",
    "error_26202": "अनुरोध विफल (त्रुटि कोड: 26202). कृपया इसे पुनः प्रयास करें",
    "error_26001": "त्रुटि: 26001. कृपया हमसे संपर्क करें",
    "error_26002": "त्रुटि: 26002. कृपया हमसे संपर्क करें",
    "error_26003": "त्रुटि: 26003. कृपया हमसे संपर्क करें",
    "error_26004": "त्रुटि: 26004. कृपया हमसे संपर्क करें",
    "error_26005": "अनुरोध विफल (त्रुटि कोड: 26005). कृपया पुनः प्रयास करें",
    "error_26006": "त्रुटि कोड: 26006, कृपया पुनः प्रयास करें",
    "error_26008": "त्रुटि: 26008. कृपया हमसे संपर्क करें",
    "go_to_the_home_page": "होम पेज़ पर जाएं",
    "error_27101": "अनुरोध विफल हुआ (त्रुटि कोड: 27101)। कृपया पुनः प्रयास करें",
    "error_27201": "त्रुटि कोड: 27201, कृपया हमसे संपर्क करें",
    "error_27202": "त्रुटि कोड: 27202, कृपया पुनः प्रयास करें",
    "error_27203": "अनुरोध विफल हुआ (त्रुटि कोड: 27203)। कृपया पुनः प्रयास करें",
    "error_27204": "अमान्य कोड (त्रुटि कोड: 27204).",
    "error_27205": "अनुरोध विफल हुआ (त्रुटि कोड: 27205)। कृपया पुनः प्रयास करें",
    "error_27206": "अनुरोध विफल हुआ (त्रुटि कोड: 27206)। कृपया पुनः प्रयास करें",
    "error_27207": "अनुरोध विफल हुआ (त्रुटि कोड: 27207)। कृपया पुनः प्रयास करें",
    "no_history_found": "आपने कोई टूल इस्तेमाल नहीं किया है! <a href=\"/\">रिफ्रेश करें</a> या <a href=\"https://www.mindonmap.com/\">आधिकारिक वेबसाइट पर जाएँ</a>",
    "error_25301": "त्रुटि: 25301. कृपया हमसे संपर्क करें",
    "error_25302": "त्रुटि: 25302. कृपया हमसे संपर्क करें",
    "error_25303": "अनुरोध विफल (त्रुटि कोड: 25303)। कृपया पुनः प्रयास करें",
    "error_25304": "अनुरोध विफल (त्रुटि कोड: 25304)। कृपया पुनः प्रयास करें",
    "error_25305": "अनुरोध विफल (त्रुटि कोड: 25305)। कृपया पुनः प्रयास करें",
    "error_25306": "अनुरोध विफल (त्रुटि कोड: 25306)। कृपया पुनः प्रयास करें",
    "image_upscaler_p": "वॉटरमार्क रहित छवि डाउनलोड करने के लाभ:",
    "Available_for": "के लिए उपलब्ध है:",
    "credit_per": "प्रति HD छवि %s क्रेडिट",
    "still_valid": "खरीदी गई योजना(या योजनाएं) अभी भी वैध है",
    "credit": "क्रेडिट",
    "pc_3rd_info": "लॉगिन सफलतापूर्वक हो गया। कृपया आगे की कार्रवाई के लिए एप्लीकेशन पर जाएँ।",
    "use_online": "ऑनलाइन सेवा का उपयोग करें",
    "use_download": "डेस्कटॉप प्रोग्राम का उपयोग करें",
    "use_immediately": "तुरंत उपयोग करें",
    "Use_in_browser": "ब्राउज़र में उपयोग करें",
    "win_desktop": "खिड़कियाँ",
    "Mac_desktop": "मैक",
    "credits_per": "{%} क्रेडिट प्रति माह",
    "expire": "समय सीमा समाप्ति समय:",
    "viewDetails": "विवरण देखें",
    "viewHistory": "लाभ का इतिहास देखें>>",
    "viewDetailsInfo": "सूचना: यदि सदस्यता लाभ समाप्ति के 7 दिनों के भीतर नवीनीकृत किए जाते हैं, तो अप्रयुक्त लाभों का उपयोग जारी रखा जा सकता है। साथ ही, समाप्ति समय स्वचालित रूप से नई सदस्यता के समाप्ति समय पर अपडेट हो जाएगा। यदि समाप्ति समय के 7 दिनों के बाद कोई नई सदस्यता नहीं है, तो सभी समाप्त हो चुके लाभ साफ़ कर दिए जाएँगे।",
    "connect_account": "ईमेल को अपने खाते से जोड़ें",
    "connect_account_info": "बाइंडिंग के बाद, आप इस ईमेल पते से लॉग इन कर सकते हैं।",
    "connect_now": "अभी बाँधें",
    "no_email_bind": "कोई ईमेल बाध्यता नहीं",
    "bind_email": "ईमेल बाँधें",
    "connect_your_email_placeholder": "कृपया अपना ईमेल एड्रेस इंटर करें",
    "bind_an_email": "ईमेल बाँधें",
    "bind_info": "आपने सफलतापूर्वक लॉग इन कर लिया है। कृपया अपने लाभ सक्रिय करने के लिए अपने खाते से एक ईमेल लिंक करें।",
    "bind_later": "बाद में बाँधें",
    "hi": "उसका, %s!",
    "Personal_Information": "व्यक्तिगत जानकारी",
    "Access": "पहुँच",
    "Subscription_Plan": "(सदस्यता योजना)",
    "No_orders": "कोई आदेश नहीं मिला.",
    "No_data": "कोई डेटा नहीं",
    "Featured_Products": "विशेष रुप से प्रदर्शित प्रोडक्टस",
    "More_Products": "और उत्पाद",
    "Free_Download": "मुफ्त डाउनलोड",
    "Get_Started": "शुरू हो जाओ",
    "Subscribe": "सदस्यता लें",
    "Verified": "सत्यापित",
    "back_to_account_center": "खाता केंद्र पर वापस जाएं",
    "success": "सफलता!",
    "successfully": "आपने सफलतापूर्वक खाता पंजीकृत कर लिया है।",
    "Continue": "जारी रखना",
    "Already": "क्या आपके पास पहले से एक खाता मौजूद है?",
    "loading_verification": "सत्यापन स्थिति की जाँच की जा रही है...",
    "email_no_verification": "क्षमा करें, पंजीकृत ईमेल पता सत्यापित नहीं किया गया है। कृपया ऊपर दिए गए निर्देशों के अनुसार सत्यापन पूरा करें और पंजीकरण पूरा करने के लिए फिर से \"संपन्न\" बटन पर क्लिक करें।",
    "will_expire_after": "के बाद समाप्त हो जाएगा",
    "hours": "घंटे",
  },
  "hr": {
    "overtime": "Kôd pogreške: {%}, pokušajte ponovno",
    "isnetwork": "Internet greška. Provjerite i pokušajte ponovno",
    "email_placeholder": "E-mail",
    "email_empty": "Molimo unesite email",
    "email_not_valid": "Email nije valjan",
    "email_not_valid_1": "Molimo unesite svoju e-poštu",
    "email_not_valid_2": "E-pošta nije valjana, upotrijebite drugu adresu.",
    "email_not_valid_3": "Nema unosa e-pošte",
    "password_placeholder": "Lozinka",
    "password_empty": "Molimo unesite lozinku",
    "password_not_valid": "Netočan račun ili lozinka",
    "password_not_valid_1": "Potrebna je lozinka duža od 8 znakova",
    "password_not_valid_2": "Molimo kreirajte lozinku",
    "password_placeholder_1": "Napravite svoju zaporku",
    "password_placeholder_2": "Potvrdi svoju lozinku",
    "password_placeholder_3": "Stvorite novu lozinku",
    "password_placeholder_4": "Potvrdi novu lozinku",
    "password_placeholder_5": "Unesite staru lozinku",
    "copy_password_empty": "Molimo potvrdite lozinku",
    "copy_password_not_valid": "Molimo potvrdite svoju lozinku",
    "copy_passwords_inconsistent": "Vaša potvrda lozinke ne odgovara",
    "code_empty": "Unesite kontrolni kod",
    "code_not_valid": "Nevažeći kontrolni kod",
    "code_placeholder": "Kontrolni kod",
    "not_received_code": "Ako vaš poštanski sandučić dugo nije primio kontrolni kod, ponovno ga primite.",
    "get_first_code": "Prvo uzmite kontrolni kod.",
    "last_name_placeholder": "Molimo unesite svoje prezime",
    "first_name_placeholder": "Molimo napišite svoje ime",
    "address_placeholder": "Molimo unesite svoju adresu",
    "no_code_text": "Poslali smo kod za provjeru. Molimo unesite svoj kod. <span class='tips'>Niste primili kôd?",
    "no_code_text_1": "1. Provjerite je li adresa e-pošte važeća i može li primati e-poštu.",
    "no_code_text_2": "2. Budući da e-poštu šalje sustav automatski, ona može biti označena kao neželjena ili neželjena pošta. Provjerite je li e-pošta u mapi Otpad.",
    "no_code_text_3": "3. Ne možete riješiti svoj problem? ",
    "no_code_text_3_span": "Zatim kliknite ovdje da nas kontaktirate.",
    "order_no": "Niste kupili nijedan proizvod, ako imate bilo kakvih pitanja, <a href=\"https://www.mindonmap.com/contact-us/\">kontaktirajte nas</a>.",
    "error_24901": "Trenutačni račun nema povezanu e-poštu i ne može pronaći narudžbe. Povežite e-poštu.",
    "user_guide": "Korisnički priručnik>>",
    "download": "preuzimanje datoteka",
    "order_number": "Broj narudžbe:",
    "Refund": "Povrat novca",
    "Disabled": "Onemogućeno",
    "Normal": "Normalan",
    "Modify": "Izmijeniti",
    "Modify_1": "Izmijeni>>",
    "Connect": "Spojiti",
    "unlink_success": "Poništite vezu uspješno",
    "connect_success": "Povežite se uspješno",
    "feedback_title": "Hvala na odgovoru. Ostavite svoj problem i mi ćemo vam odgovoriti u roku od 24 sata.",
    "feedback_thank_you": "Hvala!<br />Vaše povratne informacije su uspješno poslane.",
    "feedback_email": "Unesite svoj email ovdje!",
    "feedback_content": "Ovdje ostavite sve probleme ili prijedloge na koje ste naišli.",
    "feedback_submit": "podnijeti",
    "form_contents": "Niste unijeli nikakav opis. Unesite ga i pošaljite ponovno.",
    "old_password": "Molimo unesite staru lozinku",
    "new_password": "Molimo kreirajte novu lozinku",
    "old_new_password": "Nova lozinka ne može biti ista kao stara",
    "incorrect_password": "Netočna lozinka",
    "delete_no": "Obriši sada",
    "Caps": "Caps Lock je uključen",
    "Get": "Dobiti",
    "Done": "Gotovo",
    "error_20001": "Greška: 20001. Prijavite se ponovo.",
    "error_20002": "Greška: 20002. Molimo prijavite se ponovo.",
    "error_20003": "Greška: 20003. Molimo prijavite se ponovo.",
    "error_20004": "Zahtjev nije uspio (šifra pogreške: 20004). Molimo pokušajte ponovo.",
    "error_20005": "Sesija prijave je istekla (pogreška: 20005). Molimo prijavite se ponovo.",
    "error_20006": "Zahtjev nije uspio (šifra pogreške: 20006). Molimo pokušajte ponovo.",
    "error_20007": "Sesija prijave je istekla (pogreška: 20007). Molimo prijavite se ponovo.",
    "error_20008": "Sesija prijave je istekla (pogreška: 20008). Molimo prijavite se ponovo.",
    "error_20009": "Sesija prijave je istekla (pogreška: 20009). Molimo prijavite se ponovo.",
    "error_20101": "Unesite svoju e-poštu (kod greške: 20101)",
    "error_20102": "E-pošta nije važeća (kod greške: 20102)",
    "error_20103": "Zahtjev nije uspio (šifra pogreške: 20103). Molimo pokušajte ponovo",
    "error_20104": "Email je već u upotrebi, <a href=\"https://account.mindonmap.com/login/\">prijavite se</a> ili se registrirajte s novim",
    "error_20105": "Zahtjev nije uspio (šifra pogreške: 20105). Molimo pokušajte ponovo",
    "error_20106": "Slanje e-pošte nije uspjelo, pokušajte ponovno",
    "error_20201": "Unesite svoju e-poštu (kod greške: 20201)",
    "error_20202": "Unesite svoju lozinku (kod greške: 20202)",
    "error_20203": "Unesite kontrolni kod (kôd pogreške: 20203)",
    "error_20204": "E-pošta nije važeća (šifra greške: 20204)",
    "error_20205": "Potrebna je lozinka duža od 8 znakova (šifra greške: 20205)",
    "error_20206": "Zahtjev nije uspio (šifra pogreške: 20206). Molimo pokušajte ponovo",
    "error_20207": "Nevažeći kontrolni kod",
    "error_20208": "Zahtjev nije uspio (šifra pogreške: 20208). Molimo pokušajte ponovo",
    "error_20209": "Zahtjev nije uspio (šifra pogreške: 20209). Molimo pokušajte ponovo",
    "error_20301": "Unesite svoju e-poštu (kod greške: 20301)",
    "error_20302": "Pogreška: 20302. Kontaktirajte nas",
    "error_20303": "E-pošta nije važeća (šifra greške: 20303)",
    "error_20304": "Zahtjev nije uspio (šifra pogreške: 20304). Molimo pokušajte ponovo",
    "error_20305": "Račun ne postoji. Prvo ga ponovo unesite ili <a href=\"https://account.mindonmap.com/register/\">Kreirajte</a>.",
    "error_20306": "Još nema zaporke. Koristite <a href=\"https://account.mindonmap.com/passwordless-login/\">prijavu bez lozinke</a> ili <a href=\"https://account.mindonmap.com/create-password/\"> postavite lozinku</a> i prijavite se.",
    "error_20308": "Zahtjev nije uspio (šifra pogreške: 20308). Molimo pokušajte ponovo",
    "error_20401": "Odjava nije uspjela (šifra pogreške: 20401). Molimo pokušajte ponovo",
    "error_20501": "Unesite svoju e-poštu (kod greške: 20501)",
    "error_20502": "E-pošta nije važeća (šifra greške: 20502)",
    "error_20503": "Zahtjev nije uspio (šifra pogreške: 20503). Molimo pokušajte ponovo",
    "error_20504": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo.",
    "error_20601": "Unesite svoju e-poštu (kod greške: 20601)",
    "error_20602": "Unesite kontrolni kod (kôd pogreške: 20602)",
    "error_20603": "E-pošta nije važeća (šifra greške: 20603)",
    "error_20604": "Zahtjev nije uspio (šifra pogreške: 20604). Molimo pokušajte ponovo",
    "error_20606": "Zahtjev nije uspio (šifra pogreške: 20606). Molimo pokušajte ponovo",
    "error_20607": "Zahtjev nije uspio (šifra pogreške: 20607). Molimo pokušajte ponovo",
    "error_20608": "Zahtjev nije uspio (šifra pogreške: 20608). Molimo pokušajte ponovo",
    "error_20701": "Unesite svoju e-poštu (kod greške: 20701)",
    "error_20702": "E-pošta nije važeća (šifra greške: 20702)",
    "error_20703": "Zahtjev nije uspio (šifra pogreške: 20703). Molimo pokušajte ponovo",
    "error_20704": "Račun ne postoji. Prvo ga ponovo unesite ili <a href=\"https://account.mindonmap.com/register/\">Kreirajte</a>.",
    "error_20705": "Zahtjev nije uspio (šifra pogreške: 20705). Molimo pokušajte ponovo",
    "error_20706": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo",
    "error_20801": "Unesite svoju e-poštu (kod greške: 20801)",
    "error_20802": "Pogreška: 20802. Kontaktirajte nas",
    "error_20803": "Unesite kontrolni kod (kôd pogreške: 20803)",
    "error_20804": "E-pošta nije važeća (šifra greške: 20804)",
    "error_20805": "Potrebna je lozinka duža od 8 znakova (šifra greške: 20805)",
    "error_20806": "Zahtjev nije uspio (šifra pogreške: 20806). Molimo pokušajte ponovo",
    "error_20808": "Zahtjev nije uspio (šifra pogreške: 20808). Molimo pokušajte ponovo",
    "error_20901": "Zahtjev nije uspio (šifra pogreške: 20901). Pokušajte ponovno",
    "error_20902": "Zahtjev nije uspio (šifra pogreške: 20902). Molimo pokušajte ponovo",
    "error_21000": "Promjene su spremljene",
    "error_21001": "Nema dostavljenih informacija (šifra pogreške: 21001)",
    "error_21002": "Zahtjev nije uspio (šifra pogreške: 21002). Molimo pokušajte ponovo",
    "error_21101": "Unesite svoju e-poštu (kod greške: 21101)",
    "error_21102": "E-pošta nije važeća (kod greške: 21102)",
    "error_21103": "Zahtjev nije uspio (šifra greške: 21103), pokušajte ponovno",
    "error_21104": "E-pošta je već povezana, upotrijebite novu",
    "error_21105": "Zahtjev nije uspio (kôd greške: 21105), pokušajte ponovno",
    "error_21106": "Slanje e-pošte nije uspjelo. Molimo pokušajte ponovo",
    "error_21201": "Unesite svoju e-poštu (kod greške: 21201)",
    "error_21202": "Unesite kontrolni kod (kôd pogreške: 21202)",
    "error_21203": "E-pošta nije važeća (šifra greške: 21203)",
    "error_21204": "Pogreška: 21204. Kontaktirajte nas",
    "error_21205": "Pogreška: 21205. Kontaktirajte nas",
    "error_21206": "Potrebna je lozinka duža od 8 znakova (šifra greške: 21206)",
    "error_21207": "Zahtjev nije uspio (šifra pogreške: 21207). Molimo pokušajte ponovo",
    "error_21209": "Zahtjev nije uspio (šifra pogreške: 21209). Molimo pokušajte ponovo",
    "error_21301": "Unesite staru lozinku (kod greške: 21301)",
    "error_21302": "Molimo kreirajte novu lozinku (šifra greške: 21302)",
    "error_21303": "Nova lozinka ne može biti ista kao stara. (Pogreška: 21303)",
    "error_21304": "Potrebna je lozinka duža od 8 znakova (šifra greške: 21304)",
    "error_21306": "Zahtjev nije uspio (šifra pogreške: 21306). Molimo pokušajte ponovo",
    "error_21402": "Zahtjev nije uspio (šifra pogreške: 21402). Molimo pokušajte ponovo",
    "error_21403": "Slanje kontrolnog koda nije uspjelo. Pošaljite ga ponovno",
    "error_21500": "Račun je izbrisan",
    "error_21501": "Unesite kontrolni kod (kôd pogreške: 21501)",
    "error_21502": "Sesija prijave je istekla (pogreška: 21502). Molimo prijavite se ponovo.",
    "error_21503": "Zahtjev nije uspio (šifra pogreške: 21503). Molimo pokušajte ponovo",
    "error_21505": "Zahtjev nije uspio (šifra greške: 21505), pokušajte ponovno",
    "error_21601": "Pogreška: 20601. Kontaktirajte nas",
    "error_21602": "Nevažeća potvrda (pogreška: 20602). Molimo pokušajte ponovo.",
    "error_21603": "Pogreška: 20603. Pokušajte ponovno",
    "error_21604": "Zahtjev nije uspio (šifra pogreške: 21604). Molimo pokušajte ponovo",
    "error_21606": "Zahtjev nije uspio (šifra pogreške: 21606). Molimo pokušajte ponovo",
    "error_21611": "Zahtjev nije uspio (šifra pogreške: 21611). Molimo pokušajte ponovo",
    "error_21801": "Pogreška: 21801. Kontaktirajte nas",
    "error_21802": "Zahtjev nije uspio (pogreška: 21802). Molimo pokušajte ponovo",
    "error_21803": "Pogreška: 21803. Pokušajte ponovno",
    "error_21804": "Zahtjev nije uspio (šifra pogreške: 21804). Molimo pokušajte ponovo",
    "error_21806": "Greška: 21806. Pokušajte ponovo",
    "error_21807": "Pogreška: 21807. Kontaktirajte nas",
    "error_21808": "Pogreška: 21808. Kontaktirajte nas",
    "error_21809": "Pogreška: 21809. Kontaktirajte nas",
    "error_21810": "Pogreška: 21810. Kontaktirajte nas",
    "error_21811": "Pogreška: 21811. Kontaktirajte nas",
    "error_21812": "Pogreška: 21812. Kontaktirajte nas",
    "error_21813": "Zahtjev nije uspio (šifra pogreške: 21813). Molimo pokušajte ponovo",
    "error_21814": "Pogreška: 21814. Kontaktirajte nas",
    "error_21815": "Zahtjev nije uspio (šifra pogreške: 21815). Molimo pokušajte ponovo",
    "error_21816": "Pogreška: 21816. Kontaktirajte nas",
    "error_21817": "Pogreška: 21817. Kontaktirajte nas",
    "error_21818": "Pogreška: 21818. Kontaktirajte nas",
    "error_21819": "Zahtjev nije uspio (šifra pogreške: 21819). Molimo pokušajte ponovo",
    "error_21820": "Pogreška: 21820. Kontaktirajte nas",
    "error_21821": "Pogreška: 21821. Kontaktirajte nas",
    "error_21822": "Pogreška: 21822. Kontaktirajte nas",
    "error_21823": "Zahtjev nije uspio (šifra pogreške: 21823). Molimo pokušajte ponovo",
    "error_21824": "Zahtjev nije uspio (šifra pogreške: 21824). Molimo pokušajte ponovo",
    "error_21825": "Zahtjev nije uspio (šifra pogreške: 21825). Molimo pokušajte ponovo",
    "error_21826": "Zahtjev nije uspio (šifra pogreške: 21826). Pokušajte ponovno",
    "error_21901": "Pogreška: 21901. Kontaktirajte nas",
    "error_21902": "Zahtjev nije uspio (šifra pogreške: 21902). Molimo pokušajte ponovo",
    "error_21903": "Status računa je promijenjen (kôd greške: 21903), osvježite stranicu i pokušajte ponovno",
    "error_21904": "Pogreška: 21904. Pokušajte ponovno",
    "error_21905": "Pogreška: 21905. Pokušajte ponovno",
    "error_21906": "Zahtjev nije uspio (šifra pogreške: 21906). Molimo pokušajte ponovo",
    "error_21907": "Google račun je povezan s drugim računom",
    "error_21908": "Zahtjev nije uspio (šifra pogreške: 21908). Molimo pokušajte ponovo",
    "error_22001": "Zahtjev nije uspio (šifra pogreške: 22001). Molimo pokušajte ponovo",
    "error_22002": "Prekid veze nije uspio bez dodatne prijave",
    "error_22003": "Zahtjev nije uspio (šifra pogreške: 22003). Molimo pokušajte ponovo",
    "error_22101": "Pogreška: 22101. Kontaktirajte nas",
    "error_22102": "Status računa je promijenjen (kôd greške: 22102), molimo osvježite stranicu i pokušajte ponovno",
    "error_22103": "Zahtjev nije uspio (šifra pogreške: 22103). Molimo pokušajte ponovo",
    "error_22104": "Status računa je promijenjen (kôd pogreške: 22104), molimo osvježite stranicu i pokušajte ponovno",
    "error_22105": "Pogreška: 22105. Pokušajte ponovno",
    "error_22106": "Pogreška: 22106. Pokušajte ponovno",
    "error_22107": "Pogreška: 22107. Kontaktirajte nas",
    "error_22108": "Zahtjev nije uspio (šifra pogreške: 22108). Molimo pokušajte ponovo",
    "error_22201": "Pogreška: 22201. Kontaktirajte nas",
    "error_22202": "Nevažeća potvrda (pogreška: 22202). Molimo pokušajte ponovo.",
    "error_22203": "Pogreška: 22203. Pokušajte ponovo\"",
    "error_22204": "Zahtjev nije uspio (šifra pogreške: 22204). Molimo pokušajte ponovo",
    "error_22206": "Zahtjev nije uspio (šifra pogreške: 22206). Molimo pokušajte ponovo",
    "error_22401": "Pogreška: 22401. Kontaktirajte nas",
    "error_22402": "Nevažeća potvrda (pogreška: 22402). Molimo pokušajte ponovo.",
    "error_22403": "Pogreška: 22403. Pokušajte ponovno",
    "error_22404": "Zahtjev nije uspio (šifra pogreške: 22404). Molimo pokušajte ponovo",
    "error_22405": "Facebook račun je povezan s drugom e-poštom",
    "error_22406": "Pogreška: 22406. Pokušajte ponovno",
    "error_22407": "Pogreška: 22407. Kontaktirajte nas",
    "error_22408": "Pogreška: 22408. Kontaktirajte nas",
    "error_22409": "Pogreška: 22409. Kontaktirajte nas",
    "error_22410": "Greška: 224010. Kontaktirajte nas",
    "error_22411": "Greška: 224011. Kontaktirajte nas",
    "error_22412": "Greška: 224012. Kontaktirajte nas",
    "error_22413": "Pogreška: 22413. Kontaktirajte nas",
    "error_22414": "Zahtjev nije uspio (šifra pogreške: 22414). Molimo pokušajte ponovo",
    "error_22415": "Pogreška: 22415. Kontaktirajte nas",
    "error_22416": "Pogreška: 22416. Kontaktirajte nas",
    "error_22417": "Pogreška: 22417. Kontaktirajte nas",
    "error_22418": "Zahtjev nije uspio (šifra pogreške: 22418). Molimo pokušajte ponovo",
    "error_22419": "Pogreška: 22419. Kontaktirajte nas",
    "error_22420": "Pogreška: 22420. Kontaktirajte nas",
    "error_22421": "Pogreška: 22421. Kontaktirajte nas",
    "error_22422": "Zahtjev nije uspio (šifra pogreške: 22422). Molimo pokušajte ponovo",
    "error_22423": "Zahtjev nije uspio (šifra pogreške: 22423). Molimo pokušajte ponovo",
    "error_22424": "Zahtjev nije uspio (šifra pogreške: 22424). Molimo pokušajte ponovo",
    "error_22425": "Zahtjev nije uspio (šifra pogreške: 22425). Molimo pokušajte ponovo",
    "error_20098": "Šifra pogreške: 20098. Ako pregledavate u privatnom načinu rada, prijeđite na normalni način rada i pokušajte ponovno.",
    "error_22298": "Zahtjev za Google nije uspio (pogreška: 22298). Molimo pokušajte ponovo.",
    "error_22498": "Zahtjev za Facebook nije uspio (pogreška: 22498). Molimo pokušajte ponovo.",
    "error_24902": "Zahtjev nije uspio (šifra pogreške: 24902). Molimo pokušajte ponovo",
    "error_24903": "Zahtjev nije uspio (šifra pogreške: 24903). Molimo pokušajte ponovo",
    "error_24904": "Zahtjev nije uspio (šifra pogreške: 24904). Molimo pokušajte ponovo",
    "error_24905": "Zahtjev nije uspio (šifra pogreške: 24905). Molimo pokušajte ponovo",
    "login_title": "Prijavite se na MindOnMap",
    "log_in": "Prijaviti se",
    "no_account": "Nemate račun?",
    "create_it": "Stvorite ga",
    "or_log_in_with": "Ili se prijavite sa",
    "passwordless_login": "Prijava bez lozinke",
    "log_in_done": "Prijava gotova",
    "three_rd_account_connect_info": "Čestitamo! Uspješno ste se prijavili. Sada možete povezati jedan račun e-pošte koji će se koristiti za prijavu u budućnosti.",
    "see_my_account": "Pogledaj moj račun",
    "three_rd_login_merge_account": "Adresa e-pošte računa treće strane je prijavljena, želite li se izravno povezati i prijaviti s ovom adresom e-pošte?",
    "connect_log_in": "Povežite se i prijavite se",
    "create_an_account": "Stvorite račun",
    "back_to_log_in": "Natrag na prijavu",
    "create_password": "Stvori lozinku",
    "create_now": "Stvorite sada",
    "password_login_subtitle": "Prijava bez lozinke putem e-pošte",
    "account_login": "Prijava na račun",
    "rights": "Stvaranjem ovog računa slažete se s <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Uvjetima pružanja usluge</a> i <a href=\"https://www.mindonmap.com/privacy-policy/\">Pravila o privatnosti</a>",
    "passwordless_login_done": "Prijava bez lozinke obavljena",
    "passwordless_login_info": "Čestitamo, uspješno ste završili prijavu bez lozinke. Možete stvoriti lozinku za ovaj račun i prijaviti se s računom i lozinkom u budućnosti. <a href=\"/create-password\" style=\"display: initial;\">Stvori sada</a>",
    "sign_up": "Prijavite se",
    "register_info": "kreiraj svoj račun",
    "reset_now": "Resetiraj sada",
    "forgot_password": "Zaboravili ste lozinku",
    "reset_password_subtitle": "Upotrijebite svoju e-poštu za poništavanje lozinke",
    "plan_products": "Planovi i proizvodi",
    "nick_name": "Korisničko ime:",
    "email": "Email:",
    "my_products": "Moji proizvodi",
    "my_orders": "Moje narudžbe",
    "unlink": "Prekini vezu",
    "link": "Veza",
    "connected_accounts": "Povezani računi",
    "last_name": "Prezime:",
    "first_name": "Ime:",
    "Gender": "Spol:",
    "Birth": "Rođenje:",
    "Month": "Mjesec",
    "Year": "Godina",
    "Country_Region": "Država/regija:",
    "Address": "Adresa:",
    "Save": "Uštedjeti",
    "Date": "Datum",
    "Male": "Muški",
    "Female": "Žena",
    "Unspecified": "Neodređeno",
    "Security": "Sigurnost",
    "change_password": "Promjena lozinke",
    "change_now": "Promijeni sada",
    "connect_email": "Povežite e-poštu",
    "delete_account": "Izbriši račun",
    "delete_account_info": "Kada se vaš račun izbriše, svi podaci u Moj račun povezani s vašim računom bit će trajno izbrisani i možda ih nećete moći oporaviti. Preporučujemo da budete oprezni.",
    "Delete": "Izbrisati",
    "Logout": "Odjavite se",
    "my_profile": "Moj profil",
    "guides_faqs": "Vodiči i često postavljana pitanja",
    "More": "Više",
    "guides": "Vodiči",
    "register": "Registar",
    "hot_faq": "Hot FAQ",
    "Contents": "Sadržaj:",
    "contact_us": "Kontaktirajte nas>>",
    "plan": "Plan",
    "unregistered": "Neregistriran",
    "buy_more": "Kupi više",
    "buy_again": "Kupi ponovno",
    "buy_now": "Kupi sada",
    "free_no_limit": "Besplatno i bez ograničenja",
    "expired": "Istekao",
    "lifetime": "Doživotno",
    "remain": "Ostati",
    "day_s": "Dan(i)",
    "error_24801": "Zahtjev nije uspio (šifra pogreške: 24801). Molimo pokušajte ponovo",
    "no_app_found": "Nije pronađena aplikacija！<a href=\"/\">Osvježi</a> ili <a href=\"https://www.mindonmap.com/\">Idi na službenu web stranicu</a>",
    "get_more": "Saznajte više >>",
    "edit_photo": "Uredi fotografiju",
    "select_photo": "Odaberite fotografiju",
    "change_photo": "Promijeni sliku",
    "cancel": "Otkazati",
    "hide_password": "Sakrij lozinku",
    "show_password": "Pokaži lozinku",
    "zoom_in": "Povećaj",
    "zoom_out": "Smanji",
    "rotate": "Rotirati",
    "horizontal_flip": "Horizontalni flip",
    "vertical_flip": "Okomito okretanje",
    "country": "Zemlja",
    "country_1": "Odaberite svoju državu/regiju",
    "country_2": "Otok Åaland",
    "country_3": "Afganistan",
    "country_4": "Albanija",
    "country_5": "Alžir",
    "country_6": "Američka Samoa",
    "country_7": "Andora",
    "country_8": "Angola",
    "country_9": "Angvila",
    "country_10": "Antarktik",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenija",
    "country_14": "Aruba",
    "country_15": "Australija",
    "country_16": "Austrija",
    "country_17": "Azerbejdžan",
    "country_18": "Bahrein",
    "country_19": "Bangladeš",
    "country_20": "Barbados",
    "country_21": "Bjelorusija",
    "country_22": "Belgija",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Butan",
    "country_27": "Bolivija",
    "country_28": "Bosna i Hercegovina",
    "country_29": "Bocvana",
    "country_30": "Otok Bouvet",
    "country_31": "Brazil",
    "country_32": "Britanski teritorij Indijskog oceana",
    "country_33": "Britanski djevičanski otoci",
    "country_34": "Brunej",
    "country_35": "Bugarska",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kabo Verde",
    "country_42": "Karipska Nizozemska",
    "country_43": "Kajmanski otoci",
    "country_44": "Centralna Afrička Republika",
    "country_45": "Čad",
    "country_46": "Čile",
    "country_47": "Kina",
    "country_48": "Božićni otok",
    "country_49": "Kokosovi (Keeling) otoci",
    "country_50": "Kolumbija",
    "country_51": "Cookovi otoci",
    "country_52": "Kostarika",
    "country_53": "Côte d'Ivoire",
    "country_54": "Hrvatska",
    "country_55": "Kuba",
    "country_56": "Cipar",
    "country_57": "Češka Republika",
    "country_58": "Demokratska Republika Kongo",
    "country_59": "Danska",
    "country_60": "Džibuti",
    "country_61": "Dominika",
    "country_62": "Dominikanska Republika",
    "country_63": "Ekvador",
    "country_64": "Egipat",
    "country_65": "El Salvador",
    "country_66": "Ekvatorijalna Gvineja",
    "country_67": "Eritreja",
    "country_68": "Estonija",
    "country_69": "Etiopija",
    "country_70": "Falklandsko otočje",
    "country_71": "Farski otoci",
    "country_72": "Savezne Države Mikronezije",
    "country_73": "Fidži",
    "country_74": "Finska",
    "country_75": "Francuska",
    "country_76": "Francuska Gvajana",
    "country_77": "Francuska Polinezija",
    "country_78": "Francuski južni teritoriji",
    "country_79": "Gabon",
    "country_80": "Gambija",
    "country_81": "Gruzija",
    "country_82": "Njemačka",
    "country_83": "Gana",
    "country_84": "Gibraltar",
    "country_85": "Velika Britanija (Ujedinjeno Kraljevstvo; Engleska)",
    "country_86": "Grčka",
    "country_87": "Grenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Gvatemala",
    "country_92": "Guernsey",
    "country_93": "Gvineja",
    "country_94": "Gvineja Bisau",
    "country_95": "Gvajana",
    "country_96": "Haiti",
    "country_97": "Otok Heard i McDonald otoci",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Mađarska",
    "country_101": "Island",
    "country_102": "Indija",
    "country_103": "Indonezija",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irska",
    "country_107": "Otok Man",
    "country_108": "Izrael",
    "country_109": "Italija",
    "country_110": "Jamajka",
    "country_111": "Japan",
    "country_112": "Dres",
    "country_113": "Jordan",
    "country_114": "Kazahstan",
    "country_115": "Kenija",
    "country_116": "Kiribati",
    "country_117": "Kuvajt",
    "country_118": "Kirgistan",
    "country_119": "Laos",
    "country_120": "Latvija",
    "country_121": "Libanon",
    "country_122": "Lesoto",
    "country_123": "Liberija",
    "country_124": "Libija",
    "country_125": "Lihtenštajn",
    "country_126": "Litva",
    "country_127": "Luksemburg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malavi",
    "country_131": "Malezija",
    "country_132": "Maldivi",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Maršalovi otoci",
    "country_136": "Martinik",
    "country_137": "Mauritanija",
    "country_138": "Mauricijus",
    "country_139": "Mayotte",
    "country_140": "Meksiko",
    "country_141": "Moldavija",
    "country_142": "Monako",
    "country_143": "Mongolija",
    "country_144": "Crne Gore",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambik",
    "country_148": "Mianmar (Burma)",
    "country_149": "Namibija",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Nizozemska",
    "country_153": "Nova Kaledonija",
    "country_154": "Novi Zeland",
    "country_155": "Nikaragva",
    "country_156": "Niger",
    "country_157": "Nigerija",
    "country_158": "Niue",
    "country_159": "Otok Norfolk",
    "country_160": "Sjeverna Koreja",
    "country_161": "Sjeverno Marijansko otočje",
    "country_162": "Norveška",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "palestinski teritorij",
    "country_167": "Panama",
    "country_168": "Papua Nova Gvineja",
    "country_169": "Paragvaj",
    "country_170": "Peru",
    "country_171": "Otoci Pitcairn",
    "country_172": "Poljska",
    "country_173": "Portugal",
    "country_174": "Portoriko",
    "country_175": "Katar",
    "country_176": "Republika Makedonija (BJRM)",
    "country_177": "Republika Kongo",
    "country_178": "Ponovno sjedinjenje",
    "country_179": "Rumunjska",
    "country_180": "Ruska Federacija",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Francuska)",
    "country_184": "Saint-Pierre i Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome i Principe",
    "country_188": "Saudijska Arabija",
    "country_189": "Senegal",
    "country_190": "Srbija",
    "country_191": "Sejšeli",
    "country_192": "Sijera Leone",
    "country_193": "Singapur",
    "country_194": "Slovačka",
    "country_195": "Slovenija",
    "country_196": "Salomonski Otoci",
    "country_197": "Somalija",
    "country_198": "Južna Afrika",
    "country_199": "Južna Georgia i South Sandwich Isla",
    "country_200": "Južna Korea",
    "country_201": "Južni Sudan",
    "country_202": "Španjolska",
    "country_203": "Šri Lanka",
    "country_204": "Sveta Helena i zavisnosti",
    "country_205": "Sveti Kristofor i Nevis",
    "country_206": "Sveta Lucija",
    "country_207": "St. Vincent i Grenadini",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swaziland",
    "country_211": "Švedska",
    "country_212": "Švicarska",
    "country_213": "Sirija",
    "country_214": "Tajvan",
    "country_215": "Tadžikistan",
    "country_216": "Tanzanija",
    "country_217": "Predložak:Podaci o državi SJM Svalbard",
    "country_218": "Tajland",
    "country_219": "Bahami",
    "country_220": "Komori",
    "country_221": "Filipini",
    "country_222": "Timor-Leste (Istočni Timor)",
    "country_223": "Ići",
    "country_224": "Tokelau",
    "country_225": "Laka dvokolica",
    "country_226": "Trinidad i Tobago",
    "country_227": "Tunis",
    "country_228": "purica",
    "country_229": "Turkmenistan",
    "country_230": "Otoci Turks & Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrajina",
    "country_234": "Ujedinjeni Arapski Emirati",
    "country_235": "Mali udaljeni otoci Sjedinjenih Država",
    "country_236": "Sjedinjene Američke Države (SAD)",
    "country_237": "Djevičanski otoci Sjedinjenih Država",
    "country_238": "Urugvaj",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikan (Sveta stolica)",
    "country_242": "Venezuela",
    "country_243": "Vijetnam",
    "country_244": "Wallis i Futuna",
    "country_245": "Zapadna Sahara",
    "country_246": "Jemen",
    "country_247": "Zambija",
    "country_248": "Zimbabve",
    "google_login": "Prijavite se s Googleom",
    "State": "država",
    "Activation_code": "Aktivacijski kod",
    "Question": "Navedite sve aplikacije u koje ste prijavljeni",
    "Copy_complete": "Kopiranje dovršeno",
    "footer": "Autorska prava © 2024 MindOnMap Studio. Sva prava pridržana.",
    "change_password_success": "Lozinka je uspješno promijenjena",
    "successful_login_title": "Uspješna prijava",
    "product_page": "Stranica proizvoda>>",
    "successful_login_info": "Prijava završena. Zatvorite trenutnu stranicu i vratite se na izvornu karticu kako biste nastavili sa sljedećim postupkom. Trenutna stranica će se automatski zatvoriti za 5 sekundi. Ako automatsko zatvaranje ili zatvaranje klikom na gumb \"Gotovo\" ne uspije, zatvorite ovu karticu izravno.",
    "successful_login_info_firefox": "Prijava završena. Zatvorite trenutnu stranicu i vratite se na izvornu karticu kako biste nastavili sa sljedećim postupkom.",
    "my_account": "Moj račun",
    "my_history": "Moja povijest",
    "remove_watermark": "Ukloni vodeni žig",
    "no_history": "Nema povijesti",
    "history_all": "Odaberi sve",
    "history_open": "Otvoren",
    "history_down": "preuzimanje datoteka",
    "history_delete": "Izbrisati",
    "history_clear": "Brisanje nevažećeg",
    "images": "slika(e)",
    "use_this_function": "Koristite ovaj proizvod>>",
    "hd_downloading": "Prednosti preuzimanja originalnih HD slika:",
    "lifetimeRemaining": "Doživotno-preostalo",
    "Remaining": "Preostalo",
    "email_verification": "Potvrda e-pošte",
    "email_verification_info": "Poslali smo poruku za potvrdu na vašu e-poštu <span class=\"email\"></span> i dovršite provjeru. Nakon potvrde, pogodnosti će se automatski sinkronizirati.",
    "wrong_email": "Pogrešna adresa e-pošte?",
    "click_here_to_modify": "Kliknite ovdje za izmjenu",
    "get_more_help": "Želite li još pomoći?",
    "click_here": "Kliknite ovdje",
    "email_verification_info_success": "Čestitamo što ste potvrdili svoj račun e-pošte.",
    "email_verification_info_error": "Provjera nije uspjela zbog istekle veze.",
    "registration_succeeded": "Registracija uspjela",
    "registration_succeeded_info_1": "Čestitamo! Uspješno ste se registrirali. Poslali smo poruku za potvrdu na vašu e-poštu <span class=\"email\"></span> i dovršite provjeru kako biste koristili prednosti ove e-pošte.",
    "registration_succeeded_info_2": "Kliknite \"Gotovo\" za povratak na početnu stranicu i korištenje ovog proizvoda.",
    "registration_succeeded_info_3": "Kliknite \"Gotovo\" za zatvaranje trenutne stranice i povratak na početnu stranicu za sljedeće radnje. Ako zatvaranje ne uspije, ručno zatvorite karticu.",
    "verify_email": "Potvrditi email",
    "registered_email_not_verified": "Registrirana e-pošta nije potvrđena, odmah je potvrdite.",
    "email_verification_time_1": "Niste primili e-poruku za potvrdu?",
    "email_verification_time_2": "Nakon",
    "email_verification_time_3": "Kliknite ovdje za ponovno slanje",
    "error_26301": "Šifra pogreške: 26301, kontaktirajte nas",
    "error_26302": "Šifra pogreške: 26302, kontaktirajte nas",
    "error_26303": "Pogreška formata e-pošte (šifra pogreške: 26303). Unesite ga ponovno",
    "error_26304": "Preporučuje se lozinka duža od 8 znakova (šifra pogreške: 26304)",
    "error_26305": "Reuqest nije uspio (šifra pogreške: 26305). Pokušajte ponovo",
    "error_26306": "Email je registriran, <a href=\"https://account.mindonmap.com/login/\">idite na prijavu</a>",
    "error_26307": "Reuqest nije uspio (šifra pogreške: 26307). Pokušajte ponovo",
    "error_26308": "Reuqest nije uspio (šifra pogreške: 26308). Pokušajte ponovo",
    "error_26401": "Šifra pogreške: 26401, pokušajte ponovno",
    "error_26402": "E-pošta je potvrđena (šifra pogreške: 26402), pokušajte ponovno",
    "error_26403": "Reuqest nije uspio (šifra pogreške: 26403). Pokušajte ponovno",
    "error_26404": "Zahtjev nije uspio (šifra pogreške: 26404). Pokušajte ponovno",
    "error_26501": "Šifra pogreške: 26501, kontaktirajte nas",
    "error_26502": "Šifra pogreške: 26502, kontaktirajte nas",
    "error_26503": "Pogreška formata e-pošte (šifra pogreške: 26503). Unesite ga ponovno",
    "error_26504": "Reuqest nije uspio (šifra pogreške: 26504). Pokušajte ponovno",
    "error_26505": "E-pošta nije registrirana, molimo <a href=\"https://account.mindonmap.com/register/\">najprije je registrirajte</a>",
    "error_26506": "Email je potvrđen.",
    "error_26507": "Reuqest nije uspio (šifra pogreške: 26507). Pokušajte ponovno",
    "error_26508": "Potvrda je uspjela (kôd pogreške: 26508), pokušajte ponovno",
    "error_26509": "Zahtjev nije uspio (šifra pogreške: 26509), pokušajte ponovno",
    "error_26510": "Šifra pogreške: 26510, kontaktirajte nas",
    "error_26601": "Šifra pogreške: 26601, kontaktirajte nas",
    "error_26602": "Šifra pogreške: 26602, kontaktirajte nas",
    "error_26603": "Reuqest nije uspio (šifra pogreške: 26603). Pokušajte ponovno",
    "error_26604": "Šifra pogreške: 26604, kontaktirajte nas",
    "error_26605": "Šifra pogreške: 26605, kontaktirajte nas",
    "error_26701": "Šifra pogreške: 26701, kontaktirajte nas",
    "error_26702": "Reuqest nije uspio (šifra pogreške: 26702). Pokušajte ponovno",
    "error_26703": "Šifra pogreške: 26703, kontaktirajte nas",
    "error_26704": "Šifra pogreške: 26704, kontaktirajte nas",
    "error_26705": "Pričekajte da se prijavite (kod greške: 26705). Pokušajte ponovno",
    "no_cookie": "Uključili ste funkciju Blokiraj sve kolačiće u svom pregledniku, tako da se ne možete prijaviti. Idite na Postavke kako biste potvrdili kućicu Dopusti sve kolačiće.",
    "error_26801": "Šifra pogreške: 26801, kontaktirajte nas",
    "error_26802": "Šifra pogreške: 26802, kontaktirajte nas",
    "error_26803": "Zahtjev nije uspio (šifra pogreške: 26803). Pokušajte ponovno",
    "error_26804": "Zahtjev nije uspio (šifra pogreške: 26804). Pokušajte ponovno",
    "error_order": "Zahtjev nije uspio (šifra pogreške: 27098), pokušajte ponovo!",
    "error_order1": "Upit o narudžbi nije potpun（kôd pogreške: ",
    "error_order2": "）, osvježite i pokušajte ponovno.",
    "modify_email_title": "Izmjena e-pošte",
    "modify_email_info": "Možete koristiti izmijenjenu e-poštu za prijavu na svoj račun.",
    "images_per": "Slike po",
    "error_26101": "Pogreška: 26101. Kontaktirajte nas",
    "error_26102": "Pogreška: 26102. Kontaktirajte nas",
    "error_26103": "Zahtjev nije uspio (šifra pogreške: 26103). Pokušajte ponovno",
    "error_26104": "Šifra pogreške: 26104, pokušajte ponovno",
    "error_26105": "Kôd pogreške: 26105, pokušajte ponovno",
    "error_26106": "Brisanje nije uspjelo (kôd pogreške: 26106). Molimo pokušajte ponovo",
    "error_26201": "Pogreška: 26201. Kontaktirajte nas",
    "error_26202": "Zahtjev nije uspio (šifra pogreške: 26202). Pokušajte ponovno",
    "error_26001": "Greška: 26001. Kontaktirajte nas",
    "error_26002": "Pogreška: 26002. Kontaktirajte nas",
    "error_26003": "Pogreška: 26003. Kontaktirajte nas",
    "error_26004": "Pogreška: 26004. Kontaktirajte nas",
    "error_26005": "Zahtjev nije uspio (šifra pogreške: 26005). Pokušajte ponovno",
    "error_26006": "Šifra pogreške: 26006, pokušajte ponovno",
    "error_26008": "Greška: 26008. Kontaktirajte nas",
    "go_to_the_home_page": "Idite na početnu stranicu",
    "error_27101": "Zahtjev nije uspio (šifra pogreške: 27101). Molimo pokušajte ponovno",
    "error_27201": "Šifra pogreške: 27201, kontaktirajte nas",
    "error_27202": "Šifra pogreške: 27202, pokušajte ponovno",
    "error_27203": "Zahtjev nije uspio (šifra pogreške: 27203). Pokušajte ponovo",
    "error_27204": "Nevažeći kôd (šifra pogreške: 27204).",
    "error_27205": "Zahtjev nije uspio (šifra pogreške: 27205). Pokušajte ponovo",
    "error_27206": "Zahtjev nije uspio (šifra pogreške: 27206). Pokušajte ponovno",
    "error_27207": "Zahtjev nije uspio (šifra pogreške: 27207). Pokušajte ponovo",
    "no_history_found": "Niste koristili nikakav alat! <a href=\"/\">Osvježi</a> ili <a href=\"https://www.mindonmap.com/\">idi na službenu web stranicu</a>",
    "error_25301": "Pogreška: 25301. Kontaktirajte nas",
    "error_25302": "Pogreška: 25302. Kontaktirajte nas",
    "error_25303": "Zahtjev nije uspio (kôd pogreške: 25303). Molimo pokušajte ponovo",
    "error_25304": "Zahtjev nije uspio (kôd pogreške: 25304). Molimo pokušajte ponovo",
    "error_25305": "Zahtjev nije uspio (kôd pogreške: 25305). Molimo pokušajte ponovo",
    "error_25306": "Zahtjev nije uspio (kôd pogreške: 25306). Molimo pokušajte ponovo",
    "image_upscaler_p": "Prednosti preuzimanja slike bez vodenog žiga:",
    "Available_for": "Dostupan za:",
    "credit_per": "%s kredit(a) po HD slici",
    "still_valid": "Kupljeni plan(ovi) još uvijek vrijedi",
    "credit": "kredit(i)",
    "pc_3rd_info": "Uspješno se prijavite. Molimo idite na aplikaciju za daljnji rad.",
    "use_online": "Koristite online uslugu",
    "use_download": "Koristite desktop program",
    "use_immediately": "Upotrijebite odmah",
    "Use_in_browser": "Upotreba u pregledniku",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kredita mjesečno",
    "expire": "Vrijeme isteka:",
    "viewDetails": "Pregledavati pojedinosti",
    "viewHistory": "Pregledajte prednosti povijesti>>",
    "viewDetailsInfo": "Napomena: Ako se pogodnosti pretplate obnove unutar 7 dana nakon isteka, neiskorištene pogodnosti mogu se nastaviti koristiti. Također, vrijeme isteka će se automatski ažurirati na vrijeme isteka nove pretplate. Ako nema nove pretplate nakon 7 dana od isteka vremena, sve istekle pogodnosti bit će obrisane.",
    "connect_account": "Povežite e-poštu sa svojim računom",
    "connect_account_info": "Nakon povezivanja, možete se prijaviti s ovom adresom e-pošte.",
    "connect_now": "Veži sada",
    "no_email_bind": "Nema veza e-pošte",
    "bind_email": "Poveži e-poštu",
    "connect_your_email_placeholder": "Molim upišite svoju adresu e-pošte",
    "bind_an_email": "Uveži e-poštu",
    "bind_info": "Uspješno ste se prijavili. Povežite e-poštu sa svojim računom kako biste aktivirali svoje pogodnosti.",
    "bind_later": "Vezati kasnije",
    "hi": "Bok, %s!",
    "Personal_Information": "Osobne informacije",
    "Access": "Pristup",
    "Subscription_Plan": "(Plan pretplate)",
    "No_orders": "Nisu pronađene narudžbe.",
    "No_data": "Nema podataka",
    "Featured_Products": "Istaknuti Proizvodi",
    "More_Products": "Više proizvoda",
    "Free_Download": "Besplatno preuzimanje",
    "Get_Started": "Započnite",
    "Subscribe": "Pretplatite se",
    "Verified": "Provjereno",
    "back_to_account_center": "Povratak na Centar za račune",
    "success": "Uspjeh!",
    "successfully": "Uspješno ste registrirali račun.",
    "Continue": "Nastaviti",
    "Already": "Već imate račun?",
    "loading_verification": "Provjera statusa provjere...",
    "email_no_verification": "Nažalost, registrirana adresa e-pošte nije potvrđena. Dovršite provjeru u skladu s gornjim uputama i ponovno kliknite gumb \"Gotovo\" kako biste dovršili registraciju.",
    "will_expire_after": "Istječe nakon",
    "hours": "sati",
  },
  "hu": {
    "overtime": "Hibakód: {%}, kérjük, próbálja újra",
    "isnetwork": "Internet hiba. Kérjük, ellenőrizze, és próbálja újra",
    "email_placeholder": "Email",
    "email_empty": "Kérjük, adja meg az e-mail címet",
    "email_not_valid": "az e-mail nem érvényes",
    "email_not_valid_1": "Kérjük, adja meg e-mail címét",
    "email_not_valid_2": "Az e-mail cím érvénytelen, kérjük, használjon másik címet.",
    "email_not_valid_3": "Nincs e-mail bevitel",
    "password_placeholder": "Jelszó",
    "password_empty": "Kérjük, adja meg a jelszót",
    "password_not_valid": "Helytelen fiók vagy jelszó",
    "password_not_valid_1": "8 karakternél hosszabb jelszó szükséges",
    "password_not_valid_2": "Kérjük, hozzon létre egy jelszót",
    "password_placeholder_1": "Hozd létre a jelszavad",
    "password_placeholder_2": "Erősítse meg a jelszót",
    "password_placeholder_3": "Hozzon létre új jelszót",
    "password_placeholder_4": "Erősítsd meg az új jelszót",
    "password_placeholder_5": "Írja be a régi jelszót",
    "copy_password_empty": "Kérjük, erősítse meg a jelszót",
    "copy_password_not_valid": "Kérjük, erősítse meg jelszavát",
    "copy_passwords_inconsistent": "A jelszó megerősítése nem egyezik",
    "code_empty": "Kérjük, adja meg az ellenőrző kódot",
    "code_not_valid": "Érvénytelen ellenörző kód",
    "code_placeholder": "Ellenőrző kód",
    "not_received_code": "Ha a postafiókja hosszú ideig nem kapta meg az ellenőrző kódot, kérjük, kapja meg újra az ellenőrző kódot.",
    "get_first_code": "Kérjük, először szerezze be az ellenőrző kódot.",
    "last_name_placeholder": "Kérlek írd be a vezetékneved",
    "first_name_placeholder": "Kérjük, adja meg keresztnevét",
    "address_placeholder": "Kérjük, adja meg a címét",
    "no_code_text": "Ellenőrző kódot küldtünk. Kérjük, adja meg kódját. <span class='tips'>Nem kapott kódot?",
    "no_code_text_1": "1. Győződjön meg arról, hogy az e-mail cím érvényes, és képes e-maileket fogadni.",
    "no_code_text_2": "2. Mivel az e-mailt a rendszer automatikusan küldi, előfordulhat, hogy spamként vagy kéretlen e-mailként jelöli meg. Kérjük, ellenőrizze, hogy az e-mail a Kuka mappában van-e.",
    "no_code_text_3": "3. Nem tudja megoldani a problémáját? ",
    "no_code_text_3_span": "Ezután kattintson ide, és lépjen kapcsolatba velünk.",
    "order_no": "Még nem vásárolt terméket. Ha kérdése van, kérjük, <a href=\"https://www.mindonmap.com/contact-us/\">vegye fel velünk a kapcsolatot</a>.",
    "error_24901": "A jelenlegi fiókhoz nem kapcsolódik e-mail, és nem találja a rendeléseket. Kérjük, kapcsoljon egy e-mailt.",
    "user_guide": "Felhasználói útmutató >>",
    "download": "Letöltés",
    "order_number": "Rendelésszám:",
    "Refund": "Visszatérítés",
    "Disabled": "Tiltva",
    "Normal": "Normál",
    "Modify": "Módosít",
    "Modify_1": "Módosít>>",
    "Connect": "Csatlakozás",
    "unlink_success": "Sikeres leválasztás",
    "connect_success": "Sikeres csatlakozás",
    "feedback_title": "Köszönjük a visszajelzést. Kérjük, hagyja el problémáját, és 24 órán belül válaszolunk.",
    "feedback_thank_you": "Köszönjük!<br />Visszajelzését sikeresen elküldtük.",
    "feedback_email": "Ide írd be az email címed!",
    "feedback_content": "Hagyja itt az Ön által tapasztalt problémát vagy javaslatot.",
    "feedback_submit": "Beküldés",
    "form_contents": "Nem adott meg leírást. Kérjük, írja be, és küldje be újra.",
    "old_password": "Kérjük, adja meg a régi jelszót",
    "new_password": "Kérjük, hozzon létre egy új jelszót",
    "old_new_password": "Az új jelszó nem lehet ugyanaz, mint a régi",
    "incorrect_password": "hibás jelszó",
    "delete_no": "Törölje most",
    "Caps": "A Caps lock be van kapcsolva",
    "Get": "Kap",
    "Done": "Kész",
    "error_20001": "Hiba: 20001. Kérjük, jelentkezzen be újra.",
    "error_20002": "Hiba: 20002. Kérjük, jelentkezzen be újra.",
    "error_20003": "Hiba: 20003. Kérjük, jelentkezzen be újra.",
    "error_20004": "A kérés sikertelen (hibakód: 20004). Kérjük, próbálja meg újra.",
    "error_20005": "A bejelentkezési munkamenet lejárt (hiba: 20005). Kérjük, jelentkezzen be újra.",
    "error_20006": "A kérés sikertelen (hibakód: 20006). Kérjük, próbálja meg újra.",
    "error_20007": "A bejelentkezési munkamenet lejárt (hiba: 20007). Kérjük, jelentkezzen be újra.",
    "error_20008": "A bejelentkezési munkamenet lejárt (hiba: 20008). Kérjük, jelentkezzen be újra.",
    "error_20009": "A bejelentkezési munkamenet lejárt (hiba: 20009). Kérjük, jelentkezzen be újra.",
    "error_20101": "Kérjük, adja meg e-mail címét (hibakód: 20101)",
    "error_20102": "Az e-mail cím érvénytelen (hibakód: 20102)",
    "error_20103": "A kérés sikertelen (hibakód: 20103). Kérjük, próbálja meg újra",
    "error_20104": "Az e-mail cím már használatban van, kérjük, <a href=\"https://account.mindonmap.com/login/\">jelentkezzen be</a>, vagy regisztráljon egy újat",
    "error_20105": "A kérés sikertelen (hibakód: 20105). Kérjük, próbálja meg újra",
    "error_20106": "Nem sikerült elküldeni az e-mailt, kérjük, próbálja újra",
    "error_20201": "Kérjük, adja meg e-mail címét (hibakód: 20201)",
    "error_20202": "Kérjük, adja meg jelszavát (hibakód: 20202)",
    "error_20203": "Kérjük, adja meg az ellenőrző kódot (hibakód: 20203)",
    "error_20204": "Az e-mail cím érvénytelen (hibakód: 20204)",
    "error_20205": "8 karakternél hosszabb jelszó szükséges (hibakód: 20205)",
    "error_20206": "A kérés sikertelen (hibakód: 20206). Kérjük, próbálja meg újra",
    "error_20207": "Érvénytelen ellenörző kód",
    "error_20208": "A kérés sikertelen (hibakód: 20208). Kérjük, próbálja meg újra",
    "error_20209": "A kérés sikertelen (hibakód: 20209). Kérjük, próbálja meg újra",
    "error_20301": "Kérjük, adja meg e-mail címét (hibakód: 20301)",
    "error_20302": "Hiba: 20302. Kérjük, lépjen kapcsolatba velünk",
    "error_20303": "Az e-mail cím érvénytelen (hibakód: 20303)",
    "error_20304": "A kérés sikertelen (hibakód: 20304). Kérjük, próbálja meg újra",
    "error_20305": "Fiók nem létezik. Kérjük, adja meg újra, vagy először <a href=\"https://account.mindonmap.com/register/\">hozza létre</a>.",
    "error_20306": "Még nincs jelszó. Használja a <a href=\"https://account.mindonmap.com/passwordless-login/\">jelszó nélküli bejelentkezést</a> vagy a <a href=\"https://account.mindonmap.com/create-password/\"> állítson be jelszót</a> és jelentkezzen be.",
    "error_20308": "A kérés sikertelen (hibakód: 20308). Kérjük, próbálja meg újra",
    "error_20401": "Nem sikerült kijelentkezni (hibakód: 20401). Kérjük, próbálja meg újra",
    "error_20501": "Kérjük, adja meg e-mail címét (hibakód: 20501)",
    "error_20502": "Az e-mail cím érvénytelen (hibakód: 20502)",
    "error_20503": "A kérés sikertelen (hibakód: 20503). Kérjük, próbálja meg újra",
    "error_20504": "Nem sikerült elküldeni az e-mailt. Kérjük, próbálja meg újra.",
    "error_20601": "Kérjük, adja meg e-mail címét (hibakód: 20601)",
    "error_20602": "Kérjük, adja meg az ellenőrző kódot (hibakód: 20602)",
    "error_20603": "Az e-mail cím érvénytelen (hibakód: 20603)",
    "error_20604": "A kérés sikertelen (hibakód: 20604). Kérjük, próbálja meg újra",
    "error_20606": "A kérés sikertelen (hibakód: 20606). Kérjük, próbálja meg újra",
    "error_20607": "A kérés sikertelen (hibakód: 20607). Kérjük, próbálja meg újra",
    "error_20608": "A kérés sikertelen (hibakód: 20608). Kérjük, próbálja meg újra",
    "error_20701": "Kérjük, adja meg e-mail címét (hibakód: 20701)",
    "error_20702": "Az e-mail cím érvénytelen (hibakód: 20702)",
    "error_20703": "A kérés sikertelen (hibakód: 20703). Kérjük, próbálja meg újra",
    "error_20704": "Fiók nem létezik. Kérjük, adja meg újra, vagy először <a href=\"https://account.mindonmap.com/register/\">hozza létre</a>.",
    "error_20705": "A kérés sikertelen (hibakód: 20705). Kérjük, próbálja meg újra",
    "error_20706": "Nem sikerült elküldeni az e-mailt. Kérjük, próbálja meg újra",
    "error_20801": "Kérjük, adja meg e-mail címét (hibakód: 20801)",
    "error_20802": "Hiba: 20802. Kérjük, lépjen kapcsolatba velünk",
    "error_20803": "Kérjük, adja meg az ellenőrző kódot (hibakód: 20803)",
    "error_20804": "Az e-mail cím érvénytelen (hibakód: 20804)",
    "error_20805": "8 karakternél hosszabb jelszó szükséges (hibakód: 20805)",
    "error_20806": "A kérés sikertelen (hibakód: 20806). Kérjük, próbálja meg újra",
    "error_20808": "A kérés sikertelen (hibakód: 20808). Kérjük, próbálja meg újra",
    "error_20901": "A kérés sikertelen (hibakód: 20901). Kérjük, próbálja meg újra",
    "error_20902": "A kérés sikertelen (hibakód: 20902). Kérjük, próbálja meg újra",
    "error_21000": "A változtatások mentésre kerülnek",
    "error_21001": "Nincs információ beküldve (hibakód: 21001)",
    "error_21002": "A kérés sikertelen (hibakód: 21002). Kérjük, próbálja meg újra",
    "error_21101": "Kérjük, adja meg e-mail címét (hibakód: 21101)",
    "error_21102": "Az e-mail cím érvénytelen (hibakód: 21102)",
    "error_21103": "A kérés sikertelen (hibakód: 21103), kérjük, próbálja újra",
    "error_21104": "Az e-mail már csatlakoztatva van, kérjük, használjon újat",
    "error_21105": "A kérés sikertelen (hibakód: 21105), kérjük, próbálja újra",
    "error_21106": "Nem sikerült elküldeni az e-mailt. Kérjük, próbálja meg újra",
    "error_21201": "Kérjük, adja meg e-mail címét (hibakód: 21201)",
    "error_21202": "Kérjük, adja meg az ellenőrző kódot (hibakód: 21202)",
    "error_21203": "Az e-mail cím érvénytelen (hibakód: 21203)",
    "error_21204": "Hiba: 21204. Kérjük, lépjen kapcsolatba velünk",
    "error_21205": "Hiba: 21205. Kérjük, lépjen kapcsolatba velünk",
    "error_21206": "8 karakternél hosszabb jelszó szükséges (hibakód: 21206)",
    "error_21207": "A kérés sikertelen (hibakód: 21207). Kérjük, próbálja meg újra",
    "error_21209": "A kérés sikertelen (hibakód: 21209). Kérjük, próbálja meg újra",
    "error_21301": "Kérjük, adja meg a régi jelszót (hibakód: 21301)",
    "error_21302": "Kérjük, hozzon létre egy új jelszót (hibakód: 21302)",
    "error_21303": "Az új jelszó nem lehet ugyanaz, mint a régi. (Hiba: 21303)",
    "error_21304": "8 karakternél hosszabb jelszó szükséges (hibakód: 21304)",
    "error_21306": "A kérés sikertelen (hibakód: 21306). Kérjük, próbálja meg újra",
    "error_21402": "A kérés sikertelen (hibakód: 21402). Kérjük, próbálja meg újra",
    "error_21403": "Nem sikerült elküldeni az ellenőrző kódot. Kérjük, küldje el újra",
    "error_21500": "A fiókot törölték",
    "error_21501": "Kérjük, adja meg az ellenőrző kódot (hibakód: 21501)",
    "error_21502": "A bejelentkezési munkamenet lejárt (hiba: 21502). Kérjük, jelentkezzen be újra.",
    "error_21503": "A kérés sikertelen (hibakód: 21503). Kérjük, próbálja meg újra",
    "error_21505": "A kérés sikertelen (hibakód: 21505), kérjük, próbálja meg újra",
    "error_21601": "Hiba: 20601. Kérjük, lépjen kapcsolatba velünk",
    "error_21602": "Érvénytelen ellenőrzés (Hiba: 20602). Kérjük, próbálja meg újra.",
    "error_21603": "Hiba: 20603. Kérjük, próbálja újra",
    "error_21604": "A kérés sikertelen (hibakód: 21604). Kérjük, próbálja meg újra",
    "error_21606": "A kérés sikertelen (hibakód: 21606). Kérjük, próbálja meg újra",
    "error_21611": "A kérés sikertelen (hibakód: 21611). Kérjük, próbálja meg újra",
    "error_21801": "Hiba: 21801. Kérjük, lépjen kapcsolatba velünk",
    "error_21802": "A kérés sikertelen (Hiba: 21802). Kérjük, próbálja meg újra",
    "error_21803": "Hiba: 21803. Kérjük, próbálja újra",
    "error_21804": "A kérés sikertelen (hibakód: 21804). Kérjük, próbálja meg újra",
    "error_21806": "Hiba: 21806. Kérjük, próbálja újra",
    "error_21807": "Hiba: 21807. Kérjük, lépjen kapcsolatba velünk",
    "error_21808": "Hiba: 21808. Kérjük, lépjen kapcsolatba velünk",
    "error_21809": "Hiba: 21809. Kérjük, lépjen kapcsolatba velünk",
    "error_21810": "Hiba: 21810. Kérjük, lépjen kapcsolatba velünk",
    "error_21811": "Hiba: 21811. Kérjük, lépjen kapcsolatba velünk",
    "error_21812": "Hiba: 21812. Kérjük, lépjen kapcsolatba velünk",
    "error_21813": "A kérés sikertelen (hibakód: 21813). Kérjük, próbálja meg újra",
    "error_21814": "Hiba: 21814. Kérjük, lépjen kapcsolatba velünk",
    "error_21815": "A kérés sikertelen (hibakód: 21815). Kérjük, próbálja meg újra",
    "error_21816": "Hiba: 21816. Kérjük, lépjen kapcsolatba velünk",
    "error_21817": "Hiba: 21817. Kérjük, lépjen kapcsolatba velünk",
    "error_21818": "Hiba: 21818. Kérjük, lépjen kapcsolatba velünk",
    "error_21819": "A kérés sikertelen (hibakód: 21819). Kérjük, próbálja meg újra",
    "error_21820": "Hiba: 21820. Kérjük, lépjen kapcsolatba velünk",
    "error_21821": "Hiba: 21821. Kérjük, lépjen kapcsolatba velünk",
    "error_21822": "Hiba: 21822. Kérjük, lépjen kapcsolatba velünk",
    "error_21823": "A kérés sikertelen (hibakód: 21823). Kérjük, próbálja meg újra",
    "error_21824": "A kérés sikertelen (hibakód: 21824). Kérjük, próbálja meg újra",
    "error_21825": "A kérés sikertelen (hibakód: 21825). Kérjük, próbálja meg újra",
    "error_21826": "A kérés sikertelen (hibakód: 21826). Kérjük, próbálja meg újra",
    "error_21901": "Hiba: 21901. Kérjük, lépjen kapcsolatba velünk",
    "error_21902": "A kérés sikertelen (hibakód: 21902). Kérjük, próbálja meg újra",
    "error_21903": "A fiók állapota megváltozott (hibakód: 21903), kérjük, frissítse az oldalt, és próbálja újra",
    "error_21904": "Hiba: 21904. Kérjük, próbálja újra",
    "error_21905": "Hiba: 21905. Kérjük, próbálja újra",
    "error_21906": "A kérés sikertelen (hibakód: 21906). Kérjük, próbálja meg újra",
    "error_21907": "A Google-fiók egy másik fiókhoz lett kapcsolva",
    "error_21908": "A kérés sikertelen (hibakód: 21908). Kérjük, próbálja meg újra",
    "error_22001": "A kérés sikertelen (hibakód: 22001). Kérjük, próbálja meg újra",
    "error_22002": "A leválasztás nem sikerült további bejelentkezés nélkül",
    "error_22003": "A kérés sikertelen (hibakód: 22003). Kérjük, próbálja meg újra",
    "error_22101": "Hiba: 22101. Kérjük, lépjen kapcsolatba velünk",
    "error_22102": "A fiók állapota megváltozott (hibakód: 22102), kérjük, frissítse az oldalt, és próbálja újra",
    "error_22103": "A kérés sikertelen (hibakód: 22103). Kérjük, próbálja meg újra",
    "error_22104": "A fiók állapota megváltozott (hibakód: 22104), kérjük, frissítse az oldalt, és próbálja újra",
    "error_22105": "Hiba: 22105. Kérjük, próbálja újra",
    "error_22106": "Hiba: 22106. Kérjük, próbálja újra",
    "error_22107": "Hiba: 22107. Kérjük, lépjen kapcsolatba velünk",
    "error_22108": "A kérés sikertelen (hibakód: 22108). Kérjük, próbálja meg újra",
    "error_22201": "Hiba: 22201. Kérjük, lépjen kapcsolatba velünk",
    "error_22202": "Érvénytelen ellenőrzés (Hiba: 22202). Kérjük, próbálja meg újra.",
    "error_22203": "Hiba: 22203. Kérjük, próbálja újra\"",
    "error_22204": "A kérés sikertelen (hibakód: 22204). Kérjük, próbálja meg újra",
    "error_22206": "A kérés sikertelen (hibakód: 22206). Kérjük, próbálja meg újra",
    "error_22401": "Hiba: 22401. Kérjük, lépjen kapcsolatba velünk",
    "error_22402": "Érvénytelen ellenőrzés (Hiba: 22402). Kérjük, próbálja meg újra.",
    "error_22403": "Hiba: 22403. Kérjük, próbálja újra",
    "error_22404": "A kérés sikertelen (hibakód: 22404). Kérjük, próbálja meg újra",
    "error_22405": "A Facebook-fiók egy másik e-mailhez lett kapcsolva",
    "error_22406": "Hiba: 22406. Kérjük, próbálja újra",
    "error_22407": "Hiba: 22407. Kérjük, lépjen kapcsolatba velünk",
    "error_22408": "Hiba: 22408. Kérjük, lépjen kapcsolatba velünk",
    "error_22409": "Hiba: 22409. Kérjük, lépjen kapcsolatba velünk",
    "error_22410": "Hiba: 224010. Kérjük, lépjen kapcsolatba velünk",
    "error_22411": "Hiba: 224011. Kérjük, lépjen kapcsolatba velünk",
    "error_22412": "Hiba: 224012. Kérjük, lépjen kapcsolatba velünk",
    "error_22413": "Hiba: 22413. Kérjük, lépjen kapcsolatba velünk",
    "error_22414": "A kérés sikertelen (hibakód: 22414). Kérjük, próbálja meg újra",
    "error_22415": "Hiba: 22415. Kérjük, lépjen kapcsolatba velünk",
    "error_22416": "Hiba: 22416. Kérjük, lépjen kapcsolatba velünk",
    "error_22417": "Hiba: 22417. Kérjük, lépjen kapcsolatba velünk",
    "error_22418": "A kérés sikertelen (hibakód: 22418). Kérjük, próbálja meg újra",
    "error_22419": "Hiba: 22419. Kérjük, lépjen kapcsolatba velünk",
    "error_22420": "Hiba: 22420. Kérjük, lépjen kapcsolatba velünk",
    "error_22421": "Hiba: 22421. Kérjük, lépjen kapcsolatba velünk",
    "error_22422": "A kérés sikertelen (hibakód: 22422). Kérjük, próbálja meg újra",
    "error_22423": "A kérés sikertelen (hibakód: 22423). Kérjük, próbálja meg újra",
    "error_22424": "A kérés sikertelen (hibakód: 22424). Kérjük, próbálja meg újra",
    "error_22425": "A kérés sikertelen (hibakód: 22425). Kérjük, próbálja meg újra",
    "error_20098": "Hibakód: 20098. Ha privát módban böngészik, váltson normál módba, és próbálja újra.",
    "error_22298": "A Google kérése sikertelen (hiba: 22298). Kérjük, próbálja meg újra.",
    "error_22498": "A Facebook-kérés sikertelen (Hiba: 22498). Kérjük, próbálja meg újra.",
    "error_24902": "A kérés sikertelen (hibakód: 24902). Kérjük, próbálja meg újra",
    "error_24903": "A kérés sikertelen (hibakód: 24903). Kérjük, próbálja meg újra",
    "error_24904": "A kérés sikertelen (hibakód: 24904). Kérjük, próbálja meg újra",
    "error_24905": "A kérés sikertelen (hibakód: 24905). Kérjük, próbálja meg újra",
    "login_title": "Jelentkezzen be a MindOnMap szolgáltatásba",
    "log_in": "Belépés",
    "no_account": "Nincs fiók?",
    "create_it": "Létrehozni",
    "or_log_in_with": "Vagy jelentkezzen be",
    "passwordless_login": "Jelszó nélküli bejelentkezés",
    "log_in_done": "Bejelentkezés Kész",
    "three_rd_account_connect_info": "Gratulálunk! Sikeresen bejelentkeztél. Mostantól egyetlen e-mail fiókot is csatlakoztathat, amelyet a jövőbeni bejelentkezéshez használ.",
    "see_my_account": "Lásd a fiókomat",
    "three_rd_login_merge_account": "A harmadik fél fiókjához tartozó e-mail-címet regisztrálták. Szeretne közvetlenül ezzel az e-mail címmel csatlakozni és bejelentkezni?",
    "connect_log_in": "Csatlakozás és bejelentkezés",
    "create_an_account": "Hozzon létre egy fiókot",
    "back_to_log_in": "Vissza a bejelentkezéshez",
    "create_password": "Jelszó létrehozása",
    "create_now": "Hozzon létre most",
    "password_login_subtitle": "Jelszó nélküli bejelentkezés email címmel",
    "account_login": "Fiók Belépés",
    "rights": "A fiók létrehozásával Ön elfogadja az <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Általános Szerződési Feltételeket</a> és a <a href=\"https://www.mindonmap.com/privacy-policy/\">Adatvédelmi irányelvek</a>",
    "passwordless_login_done": "Jelszó nélküli bejelentkezés megtörtént",
    "passwordless_login_info": "Gratulálunk, sikeresen befejezte a jelszó nélküli bejelentkezést. Létrehozhat jelszót ehhez a fiókhoz, és a jövőben bejelentkezhet ezzel a fiókkal és jelszóval. <a href=\"/create-password\" style=\"display: initial;\">Létrehozás most</a>",
    "sign_up": "Regisztrálj",
    "register_info": "Hozd létre fiókodat",
    "reset_now": "Reset Now",
    "forgot_password": "Elfelejtett jelszó",
    "reset_password_subtitle": "Használja fiókja e-mail-címét a jelszó visszaállításához",
    "plan_products": "Tervek és termékek",
    "nick_name": "Felhasználónév:",
    "email": "Email:",
    "my_products": "Termékeim",
    "my_orders": "rendeléseim",
    "unlink": "Leválasztás",
    "link": "Link",
    "connected_accounts": "Kapcsolt fiókok",
    "last_name": "Vezetéknév:",
    "first_name": "Keresztnév:",
    "Gender": "Nem:",
    "Birth": "Születés:",
    "Month": "Hónap",
    "Year": "Év",
    "Country_Region": "Ország/régió:",
    "Address": "Cím:",
    "Save": "Megment",
    "Date": "Dátum",
    "Male": "Férfi",
    "Female": "Női",
    "Unspecified": "Meg nem határozott",
    "Security": "Biztonság",
    "change_password": "Jelszó módosítása",
    "change_now": "Változás most",
    "connect_email": "Csatlakoztassa az e-mailt",
    "delete_account": "Fiók törlése",
    "delete_account_info": "Fiókja törlésekor a Fiókomban a fiókjához társított összes adat véglegesen törlődik, és előfordulhat, hogy nem tudja visszaállítani azokat. Javasoljuk, hogy óvatosan haladjon.",
    "Delete": "Töröl",
    "Logout": "Kijelentkezés",
    "my_profile": "A profilom",
    "guides_faqs": "Útmutatók és GYIK",
    "More": "Több",
    "guides": "Útmutatók",
    "register": "Regisztráció",
    "hot_faq": "Forró GYIK",
    "Contents": "Tartalom:",
    "contact_us": "Vegye fel velünk a kapcsolatot >>",
    "plan": "Terv",
    "unregistered": "Nem regisztrált",
    "buy_more": "Vásároljon többet",
    "buy_again": "Vásároljon újra",
    "buy_now": "Vásárolj most",
    "free_no_limit": "Ingyenes és nincs korlátozás",
    "expired": "Lejárt",
    "lifetime": "Élettartam",
    "remain": "Marad",
    "day_s": "Napok)",
    "error_24801": "A kérés sikertelen (hibakód: 24801). Kérjük, próbálja meg újra",
    "no_app_found": "Nem található alkalmazás!<a href=\"/\">Frissítsen</a> vagy <a href=\"https://www.mindonmap.com/\">Ugorjon a hivatalos webhelyre</a>",
    "get_more": "További információ >>",
    "edit_photo": "Fénykép szerkesztése",
    "select_photo": "Válassza ki a fényképet",
    "change_photo": "Fotó megváltoztatása",
    "cancel": "Megszünteti",
    "hide_password": "Jelszó elrejtése",
    "show_password": "Mutasd a jelszót",
    "zoom_in": "Nagyítás",
    "zoom_out": "Kicsinyítés",
    "rotate": "Forog",
    "horizontal_flip": "Vízszintes flip",
    "vertical_flip": "Függőleges flip",
    "country": "Ország",
    "country_1": "Válassza ki az országot/régiót",
    "country_2": "Åaland-sziget",
    "country_3": "Afganisztán",
    "country_4": "Albánia",
    "country_5": "Algéria",
    "country_6": "Amerikai Szamoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktisz",
    "country_11": "Antigua és Barbuda",
    "country_12": "Argentína",
    "country_13": "Örményország",
    "country_14": "Aruba",
    "country_15": "Ausztrália",
    "country_16": "Ausztria",
    "country_17": "Azerbajdzsán",
    "country_18": "Bahrein",
    "country_19": "Banglades",
    "country_20": "Barbados",
    "country_21": "Fehéroroszország",
    "country_22": "Belgium",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhután",
    "country_27": "Bolívia",
    "country_28": "Bosznia és Hercegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet-sziget",
    "country_31": "Brazília",
    "country_32": "Brit indiai óceáni terület",
    "country_33": "Brit Virgin szigetek",
    "country_34": "Brunei",
    "country_35": "Bulgária",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodzsa",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Zöld-fok",
    "country_42": "Karib-tengeri Hollandia",
    "country_43": "Kajmán-szigetek",
    "country_44": "Közép-Afrikai Köztársaság",
    "country_45": "Csád",
    "country_46": "Chile",
    "country_47": "Kína",
    "country_48": "Karácsony-sziget",
    "country_49": "Kókusz (Keeling) szigetek",
    "country_50": "Colombia",
    "country_51": "Cook-szigetek",
    "country_52": "Costa Rica",
    "country_53": "Elefántcsontpart",
    "country_54": "Horvátország",
    "country_55": "Kuba",
    "country_56": "Ciprus",
    "country_57": "Cseh Köztársaság",
    "country_58": "Kongói Demokratikus Köztársaság",
    "country_59": "Dánia",
    "country_60": "Dzsibuti",
    "country_61": "Dominika",
    "country_62": "Dominikai Köztársaság",
    "country_63": "Ecuador",
    "country_64": "Egyiptom",
    "country_65": "El Salvador",
    "country_66": "Egyenlítői-Guinea",
    "country_67": "Eritrea",
    "country_68": "Észtország",
    "country_69": "Etiópia",
    "country_70": "Falkland-szigetek",
    "country_71": "Faroe Szigetek",
    "country_72": "Mikronéziai Szövetségi Államok",
    "country_73": "Fidzsi-szigetek",
    "country_74": "Finnország",
    "country_75": "Franciaország",
    "country_76": "Francia Guyana",
    "country_77": "Francia Polinézia",
    "country_78": "francia déli területek",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Grúzia",
    "country_82": "Németország",
    "country_83": "Ghána",
    "country_84": "Gibraltár",
    "country_85": "Nagy-Britannia (Egyesült Királyság; Anglia)",
    "country_86": "Görögország",
    "country_87": "Grönland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Bissau-Guinea",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard-sziget és McDonald-szigetek",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Magyarország",
    "country_101": "Izland",
    "country_102": "India",
    "country_103": "Indonézia",
    "country_104": "Irán",
    "country_105": "Irak",
    "country_106": "Írország",
    "country_107": "Man-sziget",
    "country_108": "Izrael",
    "country_109": "Olaszország",
    "country_110": "Jamaica",
    "country_111": "Japán",
    "country_112": "Jersey",
    "country_113": "Jordánia",
    "country_114": "Kazahsztán",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuvait",
    "country_118": "Kirgizisztán",
    "country_119": "Laosz",
    "country_120": "Lettország",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Libéria",
    "country_124": "Líbia",
    "country_125": "Liechtenstein",
    "country_126": "Litvánia",
    "country_127": "Luxemburg",
    "country_128": "Makaó",
    "country_129": "Madagaszkár",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldív-szigetek",
    "country_133": "Mali",
    "country_134": "Málta",
    "country_135": "Marshall-szigetek",
    "country_136": "Martinique",
    "country_137": "Mauritánia",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexikó",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongólia",
    "country_144": "Montenegró",
    "country_145": "Montserrat",
    "country_146": "Marokkó",
    "country_147": "Mozambik",
    "country_148": "Mianmar (Burma)",
    "country_149": "Namíbia",
    "country_150": "Nauru",
    "country_151": "Nepál",
    "country_152": "Hollandia",
    "country_153": "Új-Kaledónia",
    "country_154": "Új Zéland",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigéria",
    "country_158": "Niue",
    "country_159": "Norfolk-sziget",
    "country_160": "Észak Kórea",
    "country_161": "Észak Mariana szigetek",
    "country_162": "Norvégia",
    "country_163": "Omán",
    "country_164": "Pakisztán",
    "country_165": "Palau",
    "country_166": "palesztin területek",
    "country_167": "Panama",
    "country_168": "Pápua Új-Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn-szigetek",
    "country_172": "Lengyelország",
    "country_173": "Portugália",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Macedónia Köztársaság (FYROM)",
    "country_177": "Kongói Köztársaság",
    "country_178": "Réunion",
    "country_179": "Románia",
    "country_180": "Orosz Föderáció",
    "country_181": "Ruanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Franciaország)",
    "country_184": "Saint-Pierre és Miquelon",
    "country_185": "Szamoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome és Principe",
    "country_188": "Szaud-Arábia",
    "country_189": "Szenegál",
    "country_190": "Szerbia",
    "country_191": "Seychelle-szigetek",
    "country_192": "Sierra Leone",
    "country_193": "Szingapúr",
    "country_194": "Szlovákia",
    "country_195": "Szlovénia",
    "country_196": "Salamon-szigetek",
    "country_197": "Szomália",
    "country_198": "Dél-Afrika",
    "country_199": "Dél-Grúzia és a South Sandwich-sziget",
    "country_200": "Dél-Korea",
    "country_201": "Dél Szudán",
    "country_202": "Spanyolország",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena és Dependencies",
    "country_205": "St. Kitts és Nevis",
    "country_206": "Szent Lucia",
    "country_207": "St. Vincent és a Grenadine-szigetek",
    "country_208": "Szudán",
    "country_209": "Suriname",
    "country_210": "Szváziföld",
    "country_211": "Svédország",
    "country_212": "Svájc",
    "country_213": "Szíria",
    "country_214": "Tajvan",
    "country_215": "Tádzsikisztán",
    "country_216": "Tanzánia",
    "country_217": "Sablon: Országadatok SJM Svalbard",
    "country_218": "Thaiföld",
    "country_219": "A Bahamák",
    "country_220": "A Comore-szigetek",
    "country_221": "Fülöp-szigetek",
    "country_222": "Kelet-Timor (Kelet-Timor)",
    "country_223": "Menni",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad és Tobago",
    "country_227": "Tunézia",
    "country_228": "pulyka",
    "country_229": "Türkmenisztán",
    "country_230": "Turks- és Caicos-szigetek",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrajna",
    "country_234": "Egyesült Arab Emírségek",
    "country_235": "Az Egyesült Államok távoli kis szigetei",
    "country_236": "Amerikai Egyesült Államok (USA)",
    "country_237": "Egyesült Államok Szűz-szigetek",
    "country_238": "Uruguay",
    "country_239": "Üzbegisztán",
    "country_240": "Vanuatu",
    "country_241": "Vatikán (a Szentszék)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis és Futuna",
    "country_245": "Nyugat-Szahara",
    "country_246": "Jemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Jelentkezzen be a Google-lal",
    "State": "Állapot",
    "Activation_code": "Aktiváló kód",
    "Question": "Sorolja fel az összes alkalmazást, amellyel bejelentkezett",
    "Copy_complete": "A másolás kész",
    "footer": "Copyright © 2024 MindOnMap Studio. Minden jog fenntartva.",
    "change_password_success": "A jelszó sikeresen megváltozott",
    "successful_login_title": "Sikeres bejelentkezés",
    "product_page": "Termékoldal >>",
    "successful_login_info": "A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához. Az aktuális oldal 5 másodpercen belül automatikusan bezárul. Ha az automatikus bezárás vagy bezárás a „Kész” gombra kattintva nem sikerül, kérjük, zárja be közvetlenül ezt a lapot.",
    "successful_login_info_firefox": "A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához.",
    "my_account": "A fiókom",
    "my_history": "Az én történetem",
    "remove_watermark": "Távolítsa el a vízjelet",
    "no_history": "Nincs előzmény",
    "history_all": "Mindet kiválaszt",
    "history_open": "Nyisd ki",
    "history_down": "Letöltés",
    "history_delete": "Töröl",
    "history_clear": "Törölje az érvénytelen",
    "images": "kép(ek)",
    "use_this_function": "Használja ezt a terméket >>",
    "hd_downloading": "Az eredeti HD képek letöltésének előnyei:",
    "lifetimeRemaining": "Élettartam hátralévő",
    "Remaining": "Többi",
    "email_verification": "Email megerősítés",
    "email_verification_info": "Elküldtük az ellenőrző üzenetet az Ön e-mail címére <span class=\"email\"></span>, és kérjük, fejezze be az ellenőrzést. Az ellenőrzés után az előnyök automatikusan szinkronban lesznek.",
    "wrong_email": "Rossz email cím?",
    "click_here_to_modify": "Kattintson ide a módosításhoz",
    "get_more_help": "További segítséget kér?",
    "click_here": "Kattints ide",
    "email_verification_info_success": "Gratulálunk e-mail fiókjának igazolásához.",
    "email_verification_info_error": "Az ellenőrzés meghiúsult a lejárt link miatt.",
    "registration_succeeded": "Sikerült a regisztráció",
    "registration_succeeded_info_1": "Gratulálunk! Sikeresen regisztráltál. Az ellenőrző üzenetet elküldtük a(z) <span class=\"email\"></span> e-mail címére, és kérjük, fejezze be az ellenőrzést, hogy kihasználhassa ennek az e-mailnek az előnyeit.",
    "registration_succeeded_info_2": "Kattintson a „Kész” gombra, hogy visszatérjen a Kezdőlapra és használja ezt a terméket.",
    "registration_succeeded_info_3": "Kattintson a „Kész” gombra az aktuális oldal bezárásához, és a kezdőlapra való visszatéréshez a következő műveletekhez. Ha a bezárás nem sikerül, manuálisan zárja be a lapot.",
    "verify_email": "E-mail megerősítés",
    "registered_email_not_verified": "A regisztrált e-mail-címet nem ellenőriztük, kérjük, azonnal ellenőrizze.",
    "email_verification_time_1": "Nem kapott ellenőrző e-mailt?",
    "email_verification_time_2": "Után",
    "email_verification_time_3": "Kattintson ide az újraküldéshez",
    "error_26301": "Hibakód: 26301, kérjük lépjen kapcsolatba velünk",
    "error_26302": "Hibakód: 26302, kérjük lépjen kapcsolatba velünk",
    "error_26303": "E-mail formátum hiba (hibakód: 26303). Kérjük, írja be újra",
    "error_26304": "8 karakternél hosszabb jelszó ajánlott (hibakód: 26304)",
    "error_26305": "A visszaküldés sikertelen (hibakód: 26305). Kérjük, próbálja meg újra",
    "error_26306": "Az e-mail cím regisztrálva, kérjük, <a href=\"https://account.mindonmap.com/login/\">lépjen be a bejelentkezéshez</a>",
    "error_26307": "A visszaküldés sikertelen (hibakód: 26307). Kérjük, próbálja meg újra",
    "error_26308": "A visszaküldés sikertelen (hibakód: 26308). Kérjük, próbálja meg újra",
    "error_26401": "Hibakód: 26401, kérjük, próbálja újra",
    "error_26402": "Az e-mail-cím ellenőrzése megtörtént (hibakód: 26402), kérjük, próbálja újra",
    "error_26403": "A visszaküldés sikertelen (hibakód: 26403). Kérjük, próbálja meg újra",
    "error_26404": "A kérés sikertelen (hibakód: 26404). Kérjük, próbálja meg újra",
    "error_26501": "Hibakód: 26501, kérjük lépjen kapcsolatba velünk",
    "error_26502": "Hibakód: 26502, kérjük lépjen kapcsolatba velünk",
    "error_26503": "E-mail formátum hiba (hibakód: 26503). Kérjük, írja be újra",
    "error_26504": "A visszaküldés sikertelen (hibakód: 26504). Kérjük, próbálja meg újra",
    "error_26505": "Az e-mail-cím nincs regisztrálva, kérjük, <a href=\"https://account.mindonmap.com/register/\">először regisztrálja</a>",
    "error_26506": "Az e-mail-cím ellenőrzése megtörtént.",
    "error_26507": "A visszaküldés sikertelen (hibakód: 26507). Kérjük, próbálja meg újra",
    "error_26508": "Az ellenőrzés megtörtént (hibakód: 26508), kérjük, próbálja meg újra",
    "error_26509": "A kérés sikertelen (hibakód: 26509), kérjük, próbálja újra",
    "error_26510": "Hibakód: 26510, kérjük lépjen kapcsolatba velünk",
    "error_26601": "Hibakód: 26601, kérjük lépjen kapcsolatba velünk",
    "error_26602": "Hibakód: 26602, kérjük lépjen kapcsolatba velünk",
    "error_26603": "A visszaküldés sikertelen (hibakód: 26603). Kérjük, próbálja meg újra",
    "error_26604": "Hibakód: 26604, kérjük lépjen kapcsolatba velünk",
    "error_26605": "Hibakód: 26605, kérjük lépjen kapcsolatba velünk",
    "error_26701": "Hibakód: 26701, kérjük lépjen kapcsolatba velünk",
    "error_26702": "A visszaküldés sikertelen (hibakód: 26702). Kérjük, próbálja meg újra",
    "error_26703": "Hibakód: 26703, kérjük lépjen kapcsolatba velünk",
    "error_26704": "Hibakód: 26704, kérjük lépjen kapcsolatba velünk",
    "error_26705": "Várjon a bejelentkezésre (hibakód: 26705). Kérjük, próbálja meg újra",
    "no_cookie": "Böngészőjében bekapcsolta az Összes cookie letiltása funkciót, így nem tud bejelentkezni. Kérjük, lépjen a Beállításokba, és jelölje be az Összes cookie engedélyezése négyzetet.",
    "error_26801": "Hibakód: 26801, kérjük lépjen kapcsolatba velünk",
    "error_26802": "Hibakód: 26802, kérjük lépjen kapcsolatba velünk",
    "error_26803": "A kérés sikertelen (hibakód: 26803). Kérjük, próbálja meg újra",
    "error_26804": "A kérés sikertelen (hibakód: 26804). Kérjük, próbálja meg újra",
    "error_order": "A kérés sikertelen (hibakód: 27098), próbálja újra!",
    "error_order1": "A rendelés lekérdezése hiányos (hibakód: ",
    "error_order2": "）, kérjük, frissítse, és próbálja újra.",
    "modify_email_title": "E-mail módosítása",
    "modify_email_info": "A módosított e-mail címmel bejelentkezhet fiókjába.",
    "images_per": "Képek per",
    "error_26101": "Hiba: 26101. Kérjük, lépjen kapcsolatba velünk",
    "error_26102": "Hiba: 26102. Kérjük, lépjen kapcsolatba velünk",
    "error_26103": "A kérés sikertelen (hibakód: 26103). Kérjük, próbálja újra",
    "error_26104": "Hibakód: 26104, kérjük, próbálja újra",
    "error_26105": "Hibakód: 26105, kérjük, próbálja újra",
    "error_26106": "A törlés sikertelen (hibakód: 26106). Kérjük, próbálja meg újra",
    "error_26201": "Hiba: 26201. Kérjük, lépjen kapcsolatba velünk",
    "error_26202": "A kérés sikertelen (hibakód: 26202). Kérjük, próbálja újra",
    "error_26001": "Hiba: 26001. Kérjük, lépjen kapcsolatba velünk",
    "error_26002": "Hiba: 26002. Kérjük, lépjen kapcsolatba velünk",
    "error_26003": "Hiba: 26003. Kérjük, lépjen kapcsolatba velünk",
    "error_26004": "Hiba: 26004. Kérjük, lépjen kapcsolatba velünk",
    "error_26005": "A kérés sikertelen (hibakód: 26005). Kérjük, próbálja újra",
    "error_26006": "Hibakód: 26006, kérjük, próbálja újra",
    "error_26008": "Hiba: 26008. Kérjük, lépjen kapcsolatba velünk",
    "go_to_the_home_page": "Lépjen a Kezdőlapra",
    "error_27101": "A kérés sikertelen (hibakód: 27101). Kérjük, próbálja újra",
    "error_27201": "Hibakód: 27201, kérjük lépjen kapcsolatba velünk",
    "error_27202": "Hibakód: 27202, kérjük, próbálja újra",
    "error_27203": "A kérés sikertelen (hibakód: 27203). Kérjük, próbálja meg újra",
    "error_27204": "Érvénytelen kód (hibakód: 27204).",
    "error_27205": "A kérés sikertelen (hibakód: 27205). Kérjük, próbálja meg újra",
    "error_27206": "A kérés sikertelen (hibakód: 27206). Kérjük, próbálja meg újra",
    "error_27207": "A kérés sikertelen (hibakód: 27207). Kérjük, próbálja meg újra",
    "no_history_found": "Nem használtál semmilyen eszközt! <a href=\"/\">Frissítés</a> vagy <a href=\"https://www.mindonmap.com/\">Ugrás a hivatalos webhelyre</a>",
    "error_25301": "Hiba: 25301. Kérjük, lépjen kapcsolatba velünk",
    "error_25302": "Hiba: 25302. Kérjük, lépjen kapcsolatba velünk",
    "error_25303": "A kérés sikertelen (hibakód: 25303). Kérjük, próbálja meg újra",
    "error_25304": "A kérés sikertelen (hibakód: 25304). Kérjük, próbálja meg újra",
    "error_25305": "A kérés sikertelen (hibakód: 25305). Kérjük, próbálja meg újra",
    "error_25306": "A kérés sikertelen (hibakód: 25306). Kérjük, próbálja meg újra",
    "image_upscaler_p": "Kép vízjel letöltés nélkül:",
    "Available_for": "Elérhető:",
    "credit_per": "%s kredit HD-képenként",
    "still_valid": "A megvásárolt csomag(ok) továbbra is érvényesek",
    "credit": "hitel(ek)",
    "pc_3rd_info": "Sikeres bejelentkezés. Kérjük, lépjen az alkalmazáshoz a további műveletekhez.",
    "use_online": "Használja az online szolgáltatást",
    "use_download": "Használja az asztali programot",
    "use_immediately": "Azonnal használja",
    "Use_in_browser": "Használd a böngészőben",
    "win_desktop": "ablakok",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kredit havonta",
    "expire": "Lejárati idő:",
    "viewDetails": "Részletek megtekintése",
    "viewHistory": "Tekintse meg az előzmények előnyeit>>",
    "viewDetailsInfo": "Megjegyzés: Ha az előfizetési előnyöket a lejáratot követő 7 napon belül megújítják, a fel nem használt előnyök továbbra is felhasználhatók. Ezenkívül a lejárati idő automatikusan frissül az új előfizetés lejárati idejére. Ha a lejárati idő után 7 napon belül nincs új előfizetés, az összes lejárt előfizetés törlődik.",
    "connect_account": "Kösd az e-mailt a fiókodhoz",
    "connect_account_info": "Kötés után ezen az email címen tudsz bejelentkezni.",
    "connect_now": "Bind Now",
    "no_email_bind": "Nincs e-mail kötés",
    "bind_email": "Bind E-mail",
    "connect_your_email_placeholder": "Kérjük, adja meg e-mail címét",
    "bind_an_email": "Kötsön egy e-mailt",
    "bind_info": "Sikeresen bejelentkeztél. Az előnyök aktiválásához kössön egy e-mailt a fiókjához.",
    "bind_later": "Kötés később",
    "hi": "Övé, %s!",
    "Personal_Information": "Személyes adat",
    "Access": "Hozzáférés",
    "Subscription_Plan": "(Előfizetési csomag)",
    "No_orders": "Nem található megrendelés.",
    "No_data": "Nincs adat",
    "Featured_Products": "Kiemelt Termék",
    "More_Products": "További termékek",
    "Free_Download": "Ingyenes letöltés",
    "Get_Started": "Fogj neki",
    "Subscribe": "Iratkozz fel",
    "Verified": "Ellenőrzött",
    "back_to_account_center": "Vissza a Fiókközpontba",
    "success": "Siker!",
    "successfully": "Sikeresen regisztrált egy fiókot.",
    "Continue": "Folytatni",
    "Already": "Már van fiókja?",
    "loading_verification": "Ellenőrzési állapot ellenőrzése...",
    "email_no_verification": "Sajnáljuk, a regisztrált e-mail címet nem ellenőriztük. Kérjük, végezze el az ellenőrzést a fenti utasítások szerint, majd kattintson ismét a „Kész” gombra a regisztráció befejezéséhez.",
    "will_expire_after": "után lejár",
    "hours": "órák",
  },
  "hy": {
    "overtime": "Սխալի կոդը՝ {%}, խնդրում ենք նորից փորձել",
    "isnetwork": "Ինտերնետի սխալ. Խնդրում ենք ստուգել և նորից փորձել",
    "email_placeholder": "Էլ",
    "email_empty": "Խնդրում ենք մուտքագրել էլ",
    "email_not_valid": "Էլփոստը վավեր չէ",
    "email_not_valid_1": "Խնդրում ենք մուտքագրել ձեր էլ",
    "email_not_valid_2": "Էլփոստը վավեր չէ, խնդրում ենք օգտագործել այլ հասցե:",
    "email_not_valid_3": "Էլփոստի մուտքագրում չկա",
    "password_placeholder": "Գաղտնաբառ",
    "password_empty": "Խնդրում ենք մուտքագրել գաղտնաբառը",
    "password_not_valid": "Սխալ հաշիվ կամ գաղտնաբառ",
    "password_not_valid_1": "Պահանջվում է 8 նիշից բարձր գաղտնաբառ",
    "password_not_valid_2": "Խնդրում ենք ստեղծել գաղտնաբառ",
    "password_placeholder_1": "Ստեղծեք ձեր գաղտնաբառը",
    "password_placeholder_2": "Հաստատեք Ձեր գաղտնաբառը",
    "password_placeholder_3": "Ստեղծեք նոր գաղտնաբառ",
    "password_placeholder_4": "Հաստատեք նոր գաղտնաբառը",
    "password_placeholder_5": "Մուտքագրեք հին գաղտնաբառը",
    "copy_password_empty": "Խնդրում ենք հաստատել գաղտնաբառը",
    "copy_password_not_valid": "Խնդրում ենք հաստատել ձեր գաղտնաբառը",
    "copy_passwords_inconsistent": "Ձեր գաղտնաբառի հաստատումը չի համընկնում",
    "code_empty": "Խնդրում ենք մուտքագրել հաստատման կոդը",
    "code_not_valid": "Անվավեր հաստատման կոդը",
    "code_placeholder": "Ստուգման կոդը",
    "not_received_code": "Եթե ձեր փոստարկղը երկար ժամանակ չի ստացել հաստատման կոդը, խնդրում ենք նորից ստանալ հաստատման կոդը:",
    "get_first_code": "Խնդրում ենք նախ ստանալ հաստատման կոդը:",
    "last_name_placeholder": "Խնդրում ենք մուտքագրել ձեր ազգանունը",
    "first_name_placeholder": "Խնդրում ենք մուտքագրել ձեր անունը",
    "address_placeholder": "Խնդրում ենք մուտքագրել ձեր հասցեն",
    "no_code_text": "Մենք ուղարկել ենք հաստատման կոդը: Խնդրում ենք մուտքագրել ձեր կոդը: <span class='tips'>Կոդ չե՞ք ստացել:",
    "no_code_text_1": "1. Խնդրում ենք համոզվել, որ էլփոստի հասցեն վավեր է, և այն կարող է ստանալ էլ.",
    "no_code_text_2": "2. Քանի որ էլփոստը համակարգով ավտոմատ կերպով ուղարկվում է, այն կարող է նշվել որպես սպամ կամ անպետք էլ. Խնդրում ենք ստուգել, թե արդյոք նամակը աղբարկղում է:",
    "no_code_text_3": "3. Չե՞ք կարողանում լուծել ձեր խնդիրը: ",
    "no_code_text_3_span": "Այնուհետև սեղմեք այստեղ՝ մեզ հետ կապվելու համար:",
    "order_no": "Դուք որևէ ապրանք չեք գնել, եթե որևէ հարց ունեք, խնդրում ենք <a href=\"https://www.mindonmap.com/contact-us/\">կապվեք մեզ հետ</a>:",
    "error_24901": "Ընթացիկ հաշիվը կապ չունի էլփոստի հասցեով և չի կարող գտնել պատվերները: Խնդրում ենք միացնել էլ.",
    "user_guide": "Օգտագործման ուղեցույց>>",
    "download": "Բեռնել",
    "order_number": "Պատվերի ՀԱՄԱՐ:",
    "Refund": "Փոխհատուցում",
    "Disabled": "Անաշխատունակ",
    "Normal": "Նորմալ",
    "Modify": "Փոփոխել",
    "Modify_1": "Փոփոխել>>",
    "Connect": "Միացնել",
    "unlink_success": "Հաջողությամբ չեղարկել կապը",
    "connect_success": "Միացեք հաջողությամբ",
    "feedback_title": "Շնորհակալություն Ձեր արձագանքի համար: Խնդրում ենք թողնել ձեր խնդիրը, և մենք կպատասխանենք ձեզ 24 ժամվա ընթացքում:",
    "feedback_thank_you": "Շնորհակալություն:<br />Ձեր կարծիքը հաջողությամբ ուղարկվել է:",
    "feedback_email": "Մուտքագրեք ձեր էլ.",
    "feedback_content": "Ձեր հանդիպած ցանկացած խնդիր կամ առաջարկ թողեք այստեղ:",
    "feedback_submit": "Ներկայացնել",
    "form_contents": "Դուք որևէ նկարագրություն չեք մուտքագրել։ Խնդրում ենք մուտքագրել այն և կրկին ուղարկել:",
    "old_password": "Խնդրում ենք մուտքագրել հին գաղտնաբառը",
    "new_password": "Խնդրում ենք ստեղծել նոր գաղտնաբառ",
    "old_new_password": "Նոր գաղտնաբառը չի կարող լինել նույնը, ինչ հինը",
    "incorrect_password": "սխալ գաղտնաբառ",
    "delete_no": "Ջնջել հիմա",
    "Caps": "Caps lock-ը միացված է",
    "Get": "Ստացեք",
    "Done": "Կատարած",
    "error_20001": "Սխալ՝ 20001: Խնդրում ենք նորից մուտք գործել:",
    "error_20002": "Սխալ՝ 20002: Խնդրում ենք նորից մուտք գործել:",
    "error_20003": "Սխալ՝ 20003: Խնդրում ենք նորից մուտք գործել:",
    "error_20004": "Հարցումը ձախողվեց (սխալի կոդը՝ 20004): Խնդրում ենք կրկին փորձել:",
    "error_20005": "Մուտքի աշխատաշրջանը սպառվել է (Սխալ՝ 20005): Խնդրում ենք նորից մուտք գործել:",
    "error_20006": "Հարցումը ձախողվեց (սխալի կոդը՝ 20006): Խնդրում ենք կրկին փորձել:",
    "error_20007": "Մուտքի աշխատաշրջանը սպառվել է (Սխալ՝ 20007): Խնդրում ենք նորից մուտք գործել:",
    "error_20008": "Մուտքի աշխատաշրջանը սպառվել է (Սխալ՝ 20008): Խնդրում ենք նորից մուտք գործել:",
    "error_20009": "Մուտքի աշխատաշրջանը սպառվել է (Սխալ՝ 20009): Խնդրում ենք նորից մուտք գործել:",
    "error_20101": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20101)",
    "error_20102": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20102)",
    "error_20103": "Հարցումը ձախողվեց (սխալի կոդը՝ 20103): Խնդրում ենք կրկին փորձել",
    "error_20104": "Էլփոստն արդեն օգտագործվում է, խնդրում ենք <a href=\"https://account.mindonmap.com/login/\">մուտք գործել</a> կամ գրանցվել նորով",
    "error_20105": "Հարցումը ձախողվեց (սխալի կոդը՝ 20105): Խնդրում ենք կրկին փորձել",
    "error_20106": "Չհաջողվեց ուղարկել էլ. նամակ, խնդրում ենք նորից փորձել",
    "error_20201": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20201)",
    "error_20202": "Խնդրում ենք մուտքագրել ձեր գաղտնաբառը (սխալի կոդը՝ 20202)",
    "error_20203": "Խնդրում ենք մուտքագրել հաստատման կոդը (սխալի կոդը՝ 20203)",
    "error_20204": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20204)",
    "error_20205": "8 նիշից բարձր գաղտնաբառ է պահանջվում (սխալի կոդը՝ 20205)",
    "error_20206": "Հարցումը ձախողվեց (սխալի կոդը՝ 20206): Խնդրում ենք կրկին փորձել",
    "error_20207": "Անվավեր հաստատման կոդը",
    "error_20208": "Հարցումը ձախողվեց (սխալի կոդը՝ 20208): Խնդրում ենք կրկին փորձել",
    "error_20209": "Հարցումը ձախողվեց (սխալի կոդը՝ 20209): Խնդրում ենք կրկին փորձել",
    "error_20301": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20301)",
    "error_20302": "Սխալ՝ 20302: Խնդրում ենք կապվել մեզ հետ",
    "error_20303": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20303)",
    "error_20304": "Հարցումը ձախողվեց (սխալի կոդը՝ 20304): Խնդրում ենք կրկին փորձել",
    "error_20305": "Հաշիվը գոյություն չունի: Խնդրում ենք նորից մուտքագրել կամ նախ <a href=\"https://account.mindonmap.com/register/\">Ստեղծել այն</a>:",
    "error_20306": "Գաղտնաբառ դեռ չկա: Օգտագործեք <a href=\"https://account.mindonmap.com/passwordless-login/\">առանց գաղտնաբառ մուտք</a> կամ <a href=\"https://account.mindonmap.com/create-password/\"> սահմանեք գաղտնաբառ</a> և մուտք գործեք:",
    "error_20308": "Հարցումը ձախողվեց (սխալի կոդը՝ 20308): Խնդրում ենք կրկին փորձել",
    "error_20401": "Չհաջողվեց դուրս գալ (սխալի կոդը՝ 20401): Խնդրում ենք կրկին փորձել",
    "error_20501": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20501)",
    "error_20502": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20502)",
    "error_20503": "Հարցումը ձախողվեց (սխալի կոդը՝ 20503): Խնդրում ենք կրկին փորձել",
    "error_20504": "Չհաջողվեց ուղարկել էլ. Խնդրում ենք կրկին փորձել:",
    "error_20601": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20601)",
    "error_20602": "Խնդրում ենք մուտքագրել հաստատման կոդը (սխալի կոդը՝ 20602)",
    "error_20603": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20603)",
    "error_20604": "Հարցումը ձախողվեց (սխալի կոդը՝ 20604): Խնդրում ենք կրկին փորձել",
    "error_20606": "Հարցումը ձախողվեց (սխալի կոդը՝ 20606): Խնդրում ենք կրկին փորձել",
    "error_20607": "Հարցումը ձախողվեց (սխալի կոդը՝ 20607): Խնդրում ենք կրկին փորձել",
    "error_20608": "Հարցումը ձախողվեց (սխալի կոդը՝ 20608): Խնդրում ենք կրկին փորձել",
    "error_20701": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20701)",
    "error_20702": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20702)",
    "error_20703": "Հարցումը ձախողվեց (սխալի կոդը՝ 20703): Խնդրում ենք կրկին փորձել",
    "error_20704": "Հաշիվը գոյություն չունի: Խնդրում ենք նորից մուտքագրել կամ նախ <a href=\"https://account.mindonmap.com/register/\">Ստեղծել այն</a>:",
    "error_20705": "Հարցումը ձախողվեց (սխալի կոդը՝ 20705): Խնդրում ենք կրկին փորձել",
    "error_20706": "Չհաջողվեց ուղարկել էլ. Խնդրում ենք կրկին փորձել",
    "error_20801": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 20801)",
    "error_20802": "Սխալ՝ 20802: Խնդրում ենք կապվել մեզ հետ",
    "error_20803": "Խնդրում ենք մուտքագրել հաստատման կոդը (սխալի կոդը՝ 20803)",
    "error_20804": "Էլփոստը վավեր չէ (սխալի կոդը՝ 20804)",
    "error_20805": "8 նիշից ավելի գաղտնաբառ է պահանջվում (սխալի կոդը՝ 20805)",
    "error_20806": "Հարցումը ձախողվեց (սխալի կոդը՝ 20806): Խնդրում ենք կրկին փորձել",
    "error_20808": "Հարցումը ձախողվեց (սխալի կոդը՝ 20808): Խնդրում ենք կրկին փորձել",
    "error_20901": "Հարցումը ձախողվեց (սխալի կոդը՝ 20901): Խնդրում ենք կրկին փորձել",
    "error_20902": "Հարցումը ձախողվեց (սխալի կոդը՝ 20902): Խնդրում ենք կրկին փորձել",
    "error_21000": "Փոփոխությունները պահպանված են",
    "error_21001": "Տեղեկություններ չեն ներկայացվել (սխալի կոդը՝ 21001)",
    "error_21002": "Հարցումը ձախողվեց (սխալի կոդը՝ 21002): Խնդրում ենք կրկին փորձել",
    "error_21101": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 21101)",
    "error_21102": "Էլփոստը վավեր չէ (սխալի կոդը՝ 21102)",
    "error_21103": "Հարցումը ձախողվեց (սխալի կոդը՝ 21103), խնդրում ենք նորից փորձել",
    "error_21104": "Էլփոստն արդեն միացված է, խնդրում ենք օգտագործել նորը",
    "error_21105": "Հարցումը ձախողվեց (սխալի կոդը՝ 21105), խնդրում ենք նորից փորձել",
    "error_21106": "Չհաջողվեց ուղարկել էլ. Խնդրում ենք կրկին փորձել",
    "error_21201": "Խնդրում ենք մուտքագրել ձեր էլ․ հասցեն (սխալի կոդը՝ 21201)",
    "error_21202": "Խնդրում ենք մուտքագրել հաստատման կոդը (սխալի կոդը՝ 21202)",
    "error_21203": "Էլփոստը վավեր չէ (սխալի կոդը՝ 21203)",
    "error_21204": "Սխալ՝ 21204: Խնդրում ենք կապնվել մեզ հետ",
    "error_21205": "Սխալ՝ 21205: Խնդրում ենք կապվել մեզ հետ",
    "error_21206": "8 նիշից բարձր գաղտնաբառ է պահանջվում (սխալի կոդը՝ 21206)",
    "error_21207": "Հարցումը ձախողվեց (սխալի կոդը՝ 21207): Խնդրում ենք կրկին փորձել",
    "error_21209": "Հարցումը ձախողվեց (սխալի կոդը՝ 21209): Խնդրում ենք կրկին փորձել",
    "error_21301": "Խնդրում ենք մուտքագրել հին գաղտնաբառը (սխալի կոդը՝ 21301)",
    "error_21302": "Խնդրում ենք ստեղծել նոր գաղտնաբառ (սխալի կոդը՝ 21302)",
    "error_21303": "Նոր գաղտնաբառը չի կարող լինել նույնը, ինչ հինը: (Սխալ՝ 21303)",
    "error_21304": "Պահանջվում է 8 նիշից բարձր գաղտնաբառ (սխալի կոդը՝ 21304)",
    "error_21306": "Հարցումը ձախողվեց (սխալի կոդը՝ 21306): Խնդրում ենք կրկին փորձել",
    "error_21402": "Հարցումը ձախողվեց (սխալի կոդը՝ 21402): Խնդրում ենք կրկին փորձել",
    "error_21403": "Չհաջողվեց ուղարկել հաստատման կոդը: Խնդրում եմ նորից ուղարկել",
    "error_21500": "Հաշիվը ջնջվել է",
    "error_21501": "Խնդրում ենք մուտքագրել հաստատման կոդը (սխալի կոդը՝ 21501)",
    "error_21502": "Մուտքի աշխատաշրջանը սպառվել է (Սխալ՝ 21502): Խնդրում ենք նորից մուտք գործել:",
    "error_21503": "Հարցումը ձախողվեց (սխալի կոդը՝ 21503): Խնդրում ենք կրկին փորձել",
    "error_21505": "Հարցումը ձախողվեց (սխալի կոդը՝ 21505), խնդրում ենք նորից փորձել",
    "error_21601": "Սխալ՝ 20601: Խնդրում ենք կապվել մեզ հետ",
    "error_21602": "Անվավեր հաստատում (սխալ՝ 20602): Խնդրում ենք կրկին փորձել:",
    "error_21603": "Սխալ՝ 20603: Խնդրում ենք կրկին փորձել",
    "error_21604": "Հարցումը ձախողվեց (սխալի կոդը՝ 21604): Խնդրում ենք կրկին փորձել",
    "error_21606": "Հարցումը ձախողվեց (սխալի կոդը՝ 21606): Խնդրում ենք կրկին փորձել",
    "error_21611": "Հարցումը ձախողվեց (սխալի կոդը՝ 21611): Խնդրում ենք կրկին փորձել",
    "error_21801": "Սխալ՝ 21801: Խնդրում ենք կապվել մեզ հետ",
    "error_21802": "Հարցումը ձախողվեց (Սխալ՝ 21802): Խնդրում ենք կրկին փորձել",
    "error_21803": "Սխալ՝ 21803: Խնդրում ենք կրկին փորձել",
    "error_21804": "Հարցումը ձախողվեց (սխալի կոդը՝ 21804): Խնդրում ենք կրկին փորձել",
    "error_21806": "Սխալ՝ 21806: Խնդրում ենք կրկին փորձել",
    "error_21807": "Սխալ՝ 21807: Խնդրում ենք կապվել մեզ հետ",
    "error_21808": "Սխալ՝ 21808: Խնդրում ենք կապվել մեզ հետ",
    "error_21809": "Սխալ՝ 21809։ Խնդրում ենք կապվել մեզ հետ",
    "error_21810": "Սխալ՝ 21810: Խնդրում ենք կապվել մեզ հետ",
    "error_21811": "Սխալ՝ 21811: Խնդրում ենք կապվել մեզ հետ",
    "error_21812": "Սխալ՝ 21812: Խնդրում ենք կապվել մեզ հետ",
    "error_21813": "Հարցումը ձախողվեց (սխալի կոդը՝ 21813): Խնդրում ենք կրկին փորձել",
    "error_21814": "Սխալ՝ 21814: Խնդրում ենք կապվել մեզ հետ",
    "error_21815": "Հարցումը ձախողվեց (սխալի կոդը՝ 21815): Խնդրում ենք կրկին փորձել",
    "error_21816": "Սխալ՝ 21816: Խնդրում ենք կապնվել մեզ հետ",
    "error_21817": "Սխալ՝ 21817: Խնդրում ենք կապվել մեզ հետ",
    "error_21818": "Սխալ՝ 21818: Խնդրում ենք կապվել մեզ հետ",
    "error_21819": "Հարցումը ձախողվեց (սխալի կոդը՝ 21819): Խնդրում ենք կրկին փորձել",
    "error_21820": "Սխալ՝ 21820։ Խնդրում ենք կապվել մեզ հետ",
    "error_21821": "Սխալ՝ 21821: Խնդրում ենք կապվել մեզ հետ",
    "error_21822": "Սխալ՝ 21822: Խնդրում ենք կապվել մեզ հետ",
    "error_21823": "Հարցումը ձախողվեց (սխալի կոդը՝ 21823): Խնդրում ենք կրկին փորձել",
    "error_21824": "Հարցումը ձախողվեց (սխալի կոդը՝ 21824): Խնդրում ենք կրկին փորձել",
    "error_21825": "Հարցումը ձախողվեց (սխալի կոդը՝ 21825): Խնդրում ենք կրկին փորձել",
    "error_21826": "Հարցումը ձախողվեց (սխալի կոդը՝ 21826): Խնդրում ենք կրկին փորձել",
    "error_21901": "Սխալ՝ 21901: Խնդրում ենք կապվել մեզ հետ",
    "error_21902": "Հարցումը ձախողվեց (սխալի կոդը՝ 21902): Խնդրում ենք կրկին փորձել",
    "error_21903": "Հաշվի կարգավիճակը փոխվել է (սխալի կոդը՝ 21903), խնդրում ենք թարմացնել էջը և նորից փորձել",
    "error_21904": "Սխալ՝ 21904: Խնդրում ենք կրկին փորձել",
    "error_21905": "Սխալ՝ 21905: Խնդրում ենք կրկին փորձել",
    "error_21906": "Հարցումը ձախողվեց (սխալի կոդը՝ 21906): Խնդրում ենք կրկին փորձել",
    "error_21907": "Google հաշիվը կապված է այլ հաշվի հետ",
    "error_21908": "Հարցումը ձախողվեց (սխալի կոդը՝ 21908): Խնդրում ենք կրկին փորձել",
    "error_22001": "Հարցումը ձախողվեց (սխալի կոդը՝ 22001): Խնդրում ենք կրկին փորձել",
    "error_22002": "Ապակապումը ձախողվեց առանց լրացուցիչ մուտքի",
    "error_22003": "Հարցումը ձախողվեց (սխալի կոդը՝ 22003): Խնդրում ենք կրկին փորձել",
    "error_22101": "Սխալ՝ 22101: Խնդրում ենք կապվել մեզ հետ",
    "error_22102": "Հաշվի կարգավիճակը փոխվել է (սխալի կոդը՝ 22102), խնդրում ենք թարմացնել էջը և նորից փորձել",
    "error_22103": "Հարցումը ձախողվեց (սխալի կոդը՝ 22103): Խնդրում ենք կրկին փորձել",
    "error_22104": "Հաշվի կարգավիճակը փոխվել է (սխալի կոդը՝ 22104), խնդրում ենք թարմացնել էջը և նորից փորձել",
    "error_22105": "Սխալ՝ 22105: Խնդրում ենք կրկին փորձել",
    "error_22106": "Սխալ՝ 22106: Խնդրում ենք կրկին փորձել",
    "error_22107": "Սխալ՝ 22107։ Խնդրում ենք կապվել մեզ հետ",
    "error_22108": "Հարցումը ձախողվեց (սխալի կոդը՝ 22108): Խնդրում ենք կրկին փորձել",
    "error_22201": "Սխալ՝ 22201։ Խնդրում ենք կապվել մեզ հետ",
    "error_22202": "Անվավեր հաստատում (սխալ՝ 22202): Խնդրում ենք կրկին փորձել:",
    "error_22203": "Սխալ՝ 22203։ Խնդրում ենք նորից փորձել\"",
    "error_22204": "Հարցումը ձախողվեց (սխալի կոդը՝ 22204): Խնդրում ենք կրկին փորձել",
    "error_22206": "Հարցումը ձախողվեց (սխալի կոդը՝ 22206): Խնդրում ենք կրկին փորձել",
    "error_22401": "Սխալ՝ 22401: Խնդրում ենք կապվել մեզ հետ",
    "error_22402": "Անվավեր հաստատում (սխալ՝ 22402): Խնդրում ենք կրկին փորձել:",
    "error_22403": "Սխալ՝ 22403: Խնդրում ենք կրկին փորձել",
    "error_22404": "Հարցումը ձախողվեց (սխալի կոդը՝ 22404): Խնդրում ենք կրկին փորձել",
    "error_22405": "Facebook-ի հաշիվը կապված է այլ էլ.փոստի հետ",
    "error_22406": "Սխալ՝ 22406: Խնդրում ենք կրկին փորձել",
    "error_22407": "Սխալ՝ 22407: Խնդրում ենք կապվել մեզ հետ",
    "error_22408": "Սխալ՝ 22408: Խնդրում ենք կապվել մեզ հետ",
    "error_22409": "Սխալ՝ 22409։ Խնդրում ենք կապվել մեզ հետ",
    "error_22410": "Սխալ՝ 224010: Խնդրում ենք կապվել մեզ հետ",
    "error_22411": "Սխալ՝ 224011: Խնդրում ենք կապվել մեզ հետ",
    "error_22412": "Սխալ՝ 224012: Խնդրում ենք կապնվել մեզ հետ",
    "error_22413": "Սխալ՝ 22413: Խնդրում ենք կապվել մեզ հետ",
    "error_22414": "Հարցումը ձախողվեց (սխալի կոդը՝ 22414): Խնդրում ենք կրկին փորձել",
    "error_22415": "Սխալ՝ 22415: Խնդրում ենք կապվել մեզ հետ",
    "error_22416": "Սխալ՝ 22416: Խնդրում ենք կապվել մեզ հետ",
    "error_22417": "Սխալ՝ 22417: Խնդրում ենք կապվել մեզ հետ",
    "error_22418": "Հարցումը ձախողվեց (սխալի կոդը՝ 22418): Խնդրում ենք կրկին փորձել",
    "error_22419": "Սխալ՝ 22419։ Խնդրում ենք կապվել մեզ հետ",
    "error_22420": "Սխալ՝ 22420: Խնդրում ենք կապվել մեզ հետ",
    "error_22421": "Սխալ՝ 22421: Խնդրում ենք կապվել մեզ հետ",
    "error_22422": "Հարցումը ձախողվեց (սխալի կոդը՝ 22422): Խնդրում ենք կրկին փորձել",
    "error_22423": "Հարցումը ձախողվեց (սխալի կոդը՝ 22423): Խնդրում ենք կրկին փորձել",
    "error_22424": "Հարցումը ձախողվեց (սխալի կոդը՝ 22424): Խնդրում ենք կրկին փորձել",
    "error_22425": "Հարցումը ձախողվեց (սխալի կոդը՝ 22425): Խնդրում ենք կրկին փորձել",
    "error_20098": "Սխալի կոդը՝ 20098: Եթե զննարկում եք մասնավոր ռեժիմում, խնդրում ենք անցնել Նորմալ ռեժիմի և նորից փորձեք:",
    "error_22298": "Google-ի հարցումը ձախողվեց (Սխալ՝ 22298): Խնդրում ենք կրկին փորձել:",
    "error_22498": "Facebook-ի հարցումը ձախողվեց (Սխալ՝ 22498): Խնդրում ենք կրկին փորձել:",
    "error_24902": "Հարցումը ձախողվեց (սխալի կոդը՝ 24902): Խնդրում ենք կրկին փորձել",
    "error_24903": "Հարցումը ձախողվեց (սխալի կոդը՝ 24903): Խնդրում ենք կրկին փորձել",
    "error_24904": "Հարցումը ձախողվեց (սխալի կոդը՝ 24904): Խնդրում ենք կրկին փորձել",
    "error_24905": "Հարցումը ձախողվեց (սխալի կոդը՝ 24905): Խնդրում ենք կրկին փորձել",
    "login_title": "Մուտք գործեք MindOnMap",
    "log_in": "Մուտք գործեք",
    "no_account": "Հաշիվ չկա՞",
    "create_it": "Ստեղծեք այն",
    "or_log_in_with": "Կամ մուտք գործեք",
    "passwordless_login": "Մուտք առանց գաղտնաբառի",
    "log_in_done": "Մուտք գործել Կատարված է",
    "three_rd_account_connect_info": "Շնորհավորում եմ: Դուք հաջողությամբ մուտք եք գործել: Այժմ դուք կարող եք միացնել մեկ էլփոստի հաշիվ, որն օգտագործվում է ապագայում մուտք գործելու համար:",
    "see_my_account": "Տես իմ հաշիվը",
    "three_rd_login_merge_account": "Երրորդ կողմի հաշվի էլփոստի հասցեն գրանցվել է, ուզու՞մ եք ուղղակիորեն միանալ և մուտք գործել այս էլփոստի հասցեով:",
    "connect_log_in": "Միացեք և մուտք գործեք",
    "create_an_account": "Ստեղծել հաշիվ",
    "back_to_log_in": "Վերադառնալ մուտք գործելու համար",
    "create_password": "Գաղտնաբառ ստեղծել",
    "create_now": "Ստեղծել հիմա",
    "password_login_subtitle": "Մուտք առանց գաղտնաբառի էլեկտրոնային փոստով",
    "account_login": "Հաշվի մուտք",
    "rights": "Ստեղծելով այս հաշիվը՝ դուք համաձայնում եք <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Ծառայությունների մատուցման պայմաններին</a> և <a href=\"https://www.mindonmap.com/privacy-policy/\">Գաղտնիության քաղաքականություն</a>",
    "passwordless_login_done": "Առանց գաղտնաբառի մուտքն ավարտված է",
    "passwordless_login_info": "Շնորհավորում ենք, դուք հաջողությամբ ավարտեցիք գաղտնաբառի մուտքը: Դուք կարող եք ստեղծել գաղտնաբառ այս հաշվի համար և ապագայում մուտք գործել հաշիվ և գաղտնաբառ: <a href=\"/create-password\" style=\"display: initial;\">Ստեղծել հիմա</a>",
    "sign_up": "Գրանցվել",
    "register_info": "Ստեղծեք ձեր հաշիվը",
    "reset_now": "Վերականգնել հիմա",
    "forgot_password": "Մոռացել եք գաղտնաբառը",
    "reset_password_subtitle": "Գաղտնաբառը վերականգնելու համար օգտագործեք ձեր հաշվի էլ",
    "plan_products": "Պլաններ և ապրանքներ",
    "nick_name": "Օգտագործողի անունը:",
    "email": "Էլ.",
    "my_products": "Իմ արտադրանքը",
    "my_orders": "Իմ Պատվերները",
    "unlink": "Անջատել կապը",
    "link": "Հղում",
    "connected_accounts": "Կապակցված հաշիվներ",
    "last_name": "Ազգանուն:",
    "first_name": "Անուն:",
    "Gender": "Սեռը:",
    "Birth": "Ծնունդ.",
    "Month": "Ամիս",
    "Year": "Տարի",
    "Country_Region": "Երկիր/Տարածաշրջան:",
    "Address": "Հասցե:",
    "Save": "Պահպանել",
    "Date": "Ամսաթիվ",
    "Male": "Արական",
    "Female": "իգական",
    "Unspecified": "Չճշտված",
    "Security": "Անվտանգություն",
    "change_password": "Փոխել գաղտնաբառը",
    "change_now": "Փոխել հիմա",
    "connect_email": "Միացնել էլ",
    "delete_account": "Հաշիվը ջնջել",
    "delete_account_info": "Երբ ձեր հաշիվը ջնջվի, «Իմ հաշիվը» ձեր հաշվի հետ կապված բոլոր տվյալները ընդմիշտ կջնջվեն, և դուք, հնարավոր է, չկարողանաք վերականգնել դրանք: Խորհուրդ ենք տալիս զգույշ քայլել:",
    "Delete": "Ջնջել",
    "Logout": "Դուրս գալ",
    "my_profile": "Իմ էջը",
    "guides_faqs": "Ուղեցույցներ և ՀՏՀ",
    "More": "Ավելին",
    "guides": "Էքսկուրսավարներ",
    "register": "գրանցել",
    "hot_faq": "Թեժ ՀՏՀ",
    "Contents": "Բովանդակություն:",
    "contact_us": "Կապվեք մեզ հետ >>",
    "plan": "Պլանավորել",
    "unregistered": "Չգրանցված",
    "buy_more": "Գնեք ավելին",
    "buy_again": "Գնել Կրկին",
    "buy_now": "Գնիր հիմա",
    "free_no_limit": "Անվճար և առանց սահմանափակումների",
    "expired": "Ժամկետանց",
    "lifetime": "Կյանքի տևողություն",
    "remain": "Մնալ",
    "day_s": "Օր(ներ)",
    "error_24801": "Հարցումը ձախողվեց (սխալի կոդը՝ 24801): Խնդրում ենք կրկին փորձել",
    "no_app_found": "Հավելված չի գտնվել։<a href=\"/\">Թարմացնել</a> կամ <a href=\"https://www.mindonmap.com/\">Գնալ պաշտոնական կայք</a>",
    "get_more": "Ստացեք ավելին >>",
    "edit_photo": "Խմբագրել լուսանկարը",
    "select_photo": "Ընտրեք լուսանկար",
    "change_photo": "Փոխել լուսանկարը",
    "cancel": "Չեղարկել",
    "hide_password": "Թաքցնել գաղտնաբառը",
    "show_password": "Ցույց տալ գաղտնաբառը",
    "zoom_in": "Խոշորացնել. մոտեցնել",
    "zoom_out": "Մանրացնել",
    "rotate": "Պտտել",
    "horizontal_flip": "Հորիզոնական շեղում",
    "vertical_flip": "Ուղղահայաց շեղում",
    "country": "Երկիր",
    "country_1": "Ընտրեք ձեր երկիրը/տարածաշրջանը",
    "country_2": "Ալանդ կղզի",
    "country_3": "Աֆղանստան",
    "country_4": "Ալբանիա",
    "country_5": "Ալժիր",
    "country_6": "Ամերիկյան Սամոա",
    "country_7": "Անդորրա",
    "country_8": "Անգոլա",
    "country_9": "Անգուիլա",
    "country_10": "Անտարկտիկա",
    "country_11": "Անտիգուա և Բարբուդա",
    "country_12": "Արգենտինա",
    "country_13": "Հայաստան",
    "country_14": "Արուբա",
    "country_15": "Ավստրալիա",
    "country_16": "Ավստրիա",
    "country_17": "Ադրբեջան",
    "country_18": "Բահրեյն",
    "country_19": "Բանգլադեշ",
    "country_20": "Բարբադոս",
    "country_21": "Բելառուս",
    "country_22": "Բելգիա",
    "country_23": "Բելիզ",
    "country_24": "Բենին",
    "country_25": "Բերմուդյան կղզիներ",
    "country_26": "Բութան",
    "country_27": "Բոլիվիա",
    "country_28": "Բոսնիա և Հերցեգովինա",
    "country_29": "Բոտսվանա",
    "country_30": "Բուվե կղզի",
    "country_31": "Բրազիլիա",
    "country_32": "Բրիտանական Հնդկական օվկիանոս տարածք",
    "country_33": "Բրիտանական Վիրջինյան կղզիներ",
    "country_34": "Բրունեյ",
    "country_35": "Բուլղարիա",
    "country_36": "Բուրկինա",
    "country_37": "Բուրունդի",
    "country_38": "Կամբոջա",
    "country_39": "Կամերուն",
    "country_40": "Կանադա",
    "country_41": "Կաբո Վերդե",
    "country_42": "Կարիբյան Նիդեռլանդներ",
    "country_43": "Կայման կղզիներ",
    "country_44": "Կենտրոնական Աֆրիկյան Հանրապետություն",
    "country_45": "Չադ",
    "country_46": "Չիլի",
    "country_47": "Չինաստան",
    "country_48": "Սուրբ Ծննդյան կղզի",
    "country_49": "Cocos (Keeling) կղզիներ",
    "country_50": "Կոլումբիա",
    "country_51": "Կուկի կղզիներ",
    "country_52": "Կոստա Ռիկա",
    "country_53": "Կոտ դ'Իվուար",
    "country_54": "Խորվաթիա",
    "country_55": "Կուբա",
    "country_56": "Կիպրոս",
    "country_57": "Չեխիայի Հանրապետություն",
    "country_58": "Կոնգոյի Դեմոկրատական Հանրապետություն",
    "country_59": "Դանիա",
    "country_60": "Ջիբութի",
    "country_61": "Դոմինիկա",
    "country_62": "Դոմինիկյան Հանրապետություն",
    "country_63": "Էկվադոր",
    "country_64": "Եգիպտոս",
    "country_65": "Էլ Սալվադոր",
    "country_66": "Հասարակածային Գվինեա",
    "country_67": "Էրիթրեա",
    "country_68": "Էստոնիա",
    "country_69": "Եթովպիա",
    "country_70": "Ֆոլքլենդյան կղզիներ",
    "country_71": "Ֆարերյան կղզիներ",
    "country_72": "Միկրոնեզիայի դաշնային նահանգներ",
    "country_73": "Ֆիջի",
    "country_74": "Ֆինլանդիա",
    "country_75": "Ֆրանսիա",
    "country_76": "Ֆրանսիական Գվիանա",
    "country_77": "Ֆրանսիական Պոլինեզիա",
    "country_78": "Ֆրանսիայի հարավային տարածքներ",
    "country_79": "Գաբոն",
    "country_80": "Գամբիա",
    "country_81": "Վրաստան",
    "country_82": "Գերմանիա",
    "country_83": "Գանա",
    "country_84": "Ջիբրալթար",
    "country_85": "Մեծ Բրիտանիա (Միացյալ Թագավորություն, Անգլիա)",
    "country_86": "Հունաստան",
    "country_87": "Գրենլանդիա",
    "country_88": "Գրենադա",
    "country_89": "Գվադելուպե",
    "country_90": "Գուամ",
    "country_91": "Գվատեմալա",
    "country_92": "Գերնսի",
    "country_93": "Գվինեա",
    "country_94": "Գվինեա-Բիսաու",
    "country_95": "Գայանա",
    "country_96": "Հաիթի",
    "country_97": "Հերդ կղզի և Մակդոնալդ կղզիներ",
    "country_98": "Հոնդուրաս",
    "country_99": "Հոնգ կոնգ",
    "country_100": "Հունգարիա",
    "country_101": "Իսլանդիա",
    "country_102": "Հնդկաստան",
    "country_103": "Ինդոնեզիա",
    "country_104": "Իրան",
    "country_105": "Իրաք",
    "country_106": "Իռլանդիա",
    "country_107": "Մեն կղզի",
    "country_108": "Իսրայել",
    "country_109": "Իտալիա",
    "country_110": "Ճամայկա",
    "country_111": "Ճապոնիա",
    "country_112": "Ջերսի",
    "country_113": "Հորդանան",
    "country_114": "Ղազախստան",
    "country_115": "Քենիա",
    "country_116": "Կիրիբատի",
    "country_117": "Քուվեյթ",
    "country_118": "Ղրղզստան",
    "country_119": "Լաոս",
    "country_120": "Լատվիա",
    "country_121": "Լիբանան",
    "country_122": "Լեսոտո",
    "country_123": "Լիբերիա",
    "country_124": "Լիբիա",
    "country_125": "Լիխտենշտեյն",
    "country_126": "Լիտվա",
    "country_127": "Լյուքսեմբուրգ",
    "country_128": "Մակաո",
    "country_129": "Մադագասկար",
    "country_130": "Մալավի",
    "country_131": "Մալայզիա",
    "country_132": "Մալդիվներ",
    "country_133": "Մալի",
    "country_134": "Մալթա",
    "country_135": "Մարշալյան կղզիներ",
    "country_136": "Մարտինիկ",
    "country_137": "Մավրիտանիա",
    "country_138": "Մավրիկիոս",
    "country_139": "Մայոթ",
    "country_140": "Մեքսիկա",
    "country_141": "Մոլդովա",
    "country_142": "Մոնակո",
    "country_143": "Մոնղոլիա",
    "country_144": "Մոնտենեգրո",
    "country_145": "Մոնսերատ",
    "country_146": "Մարոկկո",
    "country_147": "Մոզամբիկ",
    "country_148": "Մյանմար (Բիրմա)",
    "country_149": "Նամիբիա",
    "country_150": "Նաուրու",
    "country_151": "Նեպալ",
    "country_152": "Նիդեռլանդներ",
    "country_153": "Նոր Կալեդոնիա",
    "country_154": "Նոր Զելանդիա",
    "country_155": "Նիկարագուա",
    "country_156": "Նիգեր",
    "country_157": "Նիգերիա",
    "country_158": "Նիուե",
    "country_159": "Նորֆոլկ կղզի",
    "country_160": "Հյուսիսային Կորեա",
    "country_161": "Հյուսիսային Մարիանա կղզիներ",
    "country_162": "Նորվեգիա",
    "country_163": "Օման",
    "country_164": "Պակիստան",
    "country_165": "Պալաու",
    "country_166": "պաղեստինյան տարածքներ",
    "country_167": "Պանամա",
    "country_168": "Պապուա Նոր Գվինեա",
    "country_169": "Պարագվայ",
    "country_170": "Պերու",
    "country_171": "Փիթքերն կղզիներ",
    "country_172": "Լեհաստան",
    "country_173": "Պորտուգալիա",
    "country_174": "Պուերտո Ռիկո",
    "country_175": "Քաթար",
    "country_176": "Մակեդոնիայի Հանրապետություն (FYROM)",
    "country_177": "Կոնգոյի Հանրապետություն",
    "country_178": "Ռեյունիոն",
    "country_179": "Ռումինիա",
    "country_180": "Ռուսաստանի Դաշնություն",
    "country_181": "Ռուանդա",
    "country_182": "Սուրբ Բարթելեմի",
    "country_183": "Սեն Մարտին (Ֆրանսիա)",
    "country_184": "Սեն-Պիեռ և Միկելոն",
    "country_185": "Սամոա",
    "country_186": "Սան Մարինո",
    "country_187": "Սան Տոմե և Պրինսիփ",
    "country_188": "Սաուդյան Արաբիա",
    "country_189": "Սենեգալ",
    "country_190": "Սերբիա",
    "country_191": "Սեյշելներ",
    "country_192": "Սիերա Լեոնե",
    "country_193": "Սինգապուր",
    "country_194": "Սլովակիա",
    "country_195": "Սլովենիա",
    "country_196": "Սողոմոնի կղզիներ",
    "country_197": "Սոմալի",
    "country_198": "Հարավային Աֆրիկա",
    "country_199": "Հարավային Վրաստան և Հարավային սենդվիչ կղզին",
    "country_200": "Հարավային Կորեա",
    "country_201": "Հարավային Սուդան",
    "country_202": "Իսպանիա",
    "country_203": "Շրի Լանկա",
    "country_204": "Սուրբ Հելենա և կախվածություն",
    "country_205": "Սենթ Քիթս և Նևիս",
    "country_206": "Սուրբ Լյուսիա",
    "country_207": "Սուրբ Վինսենթ և Գրենադիններ",
    "country_208": "Սուդան",
    "country_209": "Սուրինամ",
    "country_210": "Սվազիլենդ",
    "country_211": "Շվեդիա",
    "country_212": "Շվեյցարիա",
    "country_213": "Սիրիա",
    "country_214": "Թայվան",
    "country_215": "Տաջիկստան",
    "country_216": "Տանզանիա",
    "country_217": "Կաղապար:Երկրի տվյալներ SJM Svalbard",
    "country_218": "Թաիլանդ",
    "country_219": "Բահամյան կղզիներ",
    "country_220": "Կոմորյան կղզիներ",
    "country_221": "Ֆիլիպիններ",
    "country_222": "Թիմոր-Լեստե (Արևելյան Թիմոր)",
    "country_223": "Գնալ",
    "country_224": "Տոկելաու",
    "country_225": "Տոնգա",
    "country_226": "Տրինիդադ և Տոբագո",
    "country_227": "Թունիս",
    "country_228": "հնդկահավ",
    "country_229": "Թուրքմենստան",
    "country_230": "Թերքս և Կայկոս կղզիներ",
    "country_231": "Տուվալու",
    "country_232": "Ուգանդա",
    "country_233": "Ուկրաինա",
    "country_234": "Արաբական Միացյալ Էմիրություններ",
    "country_235": "Միացյալ Նահանգների փոքր ծայրամասային կղզիներ",
    "country_236": "Ամերիկայի Միացյալ Նահանգներ (ԱՄՆ)",
    "country_237": "Միացյալ Նահանգների Վիրջինյան կղզիներ",
    "country_238": "Ուրուգվայ",
    "country_239": "Ուզբեկստան",
    "country_240": "Վանուատու",
    "country_241": "Վատիկան քաղաք (Սուրբ Աթոռ)",
    "country_242": "Վենեսուելա",
    "country_243": "Վիետնամ",
    "country_244": "Ուոլիս և Ֆուտունա",
    "country_245": "Արևմտյան Սահարա",
    "country_246": "Եմեն",
    "country_247": "Զամբիա",
    "country_248": "Զիմբաբվե",
    "google_login": "Մուտք գործեք Google-ով",
    "State": "Պետություն",
    "Activation_code": "Ակտիվացման կոդը",
    "Question": "Նշեք բոլոր հավելվածները, որոնց մուտք եք գործել",
    "Copy_complete": "Պատճենումն ավարտված է",
    "footer": "Հեղինակային իրավունք © 2024 MindOnMap ստուդիա: Բոլոր իրավունքները պաշտպանված են։",
    "change_password_success": "Գաղտնաբառը հաջողությամբ փոխվեց",
    "successful_login_title": "Մուտք գործելու հաջողություն",
    "product_page": "Ապրանքի էջ>>",
    "successful_login_info": "Մուտքն ավարտված է: Խնդրում ենք փակել ընթացիկ էջը և վերադառնալ սկզբնական ներդիր՝ շարունակելու հետևյալ գործընթացը: Ընթացիկ էջը ավտոմատ կերպով կփակվի 5 վայրկյանից: Եթե ավտոմատ փակումը կամ «Կատարված» կոճակը սեղմելով փակելը ձախողվի, խնդրում ենք ուղղակիորեն փակել այս ներդիրը:",
    "successful_login_info_firefox": "Մուտքն ավարտված է: Խնդրում ենք փակել ընթացիկ էջը և վերադառնալ սկզբնական ներդիր՝ շարունակելու հետևյալ գործընթացը:",
    "my_account": "Իմ հաշիվը",
    "my_history": "Իմ պատմությունը",
    "remove_watermark": "Հեռացնել ջրանիշը",
    "no_history": "Պատմություն չկա",
    "history_all": "Ընտրել բոլորը",
    "history_open": "Բաց",
    "history_down": "Բեռնել",
    "history_delete": "Ջնջել",
    "history_clear": "Մաքրել անվավեր",
    "images": "պատկեր(ներ)",
    "use_this_function": "Օգտագործեք այս ապրանքը>>",
    "hd_downloading": "HD բնօրինակ պատկերների ներբեռնման առավելությունները.",
    "lifetimeRemaining": "Կյանքի մնացորդ",
    "Remaining": "Մնացած",
    "email_verification": "Էլփոստի հաստատում",
    "email_verification_info": "Մենք ստուգման հաղորդագրությունն ուղարկել ենք ձեր էլ.փոստին <span class=\"email\"></span>, և խնդրում ենք ավարտել ստուգումը: Հաստատումից հետո առավելությունները ավտոմատ կերպով կհամաժամեցվեն:",
    "wrong_email": "Սխալ էլփոստի հասցե",
    "click_here_to_modify": "Սեղմեք այստեղ՝ փոփոխելու համար",
    "get_more_help": "Լրացուցիչ օգնություն ստանալ:",
    "click_here": "Սեղմեք այստեղ",
    "email_verification_info_success": "Շնորհավորում ենք ձեր էլփոստի հաշիվը հաստատելու համար:",
    "email_verification_info_error": "Ստուգումը ձախողվեց ժամկետանց հղման պատճառով:",
    "registration_succeeded": "Գրանցումը հաջողվեց",
    "registration_succeeded_info_1": "Շնորհավորում եմ: Դուք հաջողությամբ գրանցվել եք: Մենք ստուգման հաղորդագրությունն ուղարկել ենք ձեր էլ.փոստին <span class=\"email\"></span>, և խնդրում ենք ավարտել հաստատումը այս էլփոստի առավելություններից օգտվելու համար:",
    "registration_succeeded_info_2": "Կտտացրեք «Կատարված»՝ Գլխավոր էջ վերադառնալու և այս ապրանքն օգտագործելու համար:",
    "registration_succeeded_info_3": "Սեղմեք «Կատարված»՝ ընթացիկ էջը փակելու և Գլխավոր էջ վերադառնալու համար հետևյալ գործողությունների համար: Եթե փակումը ձախողվի, խնդրում ենք ձեռքով փակել ներդիրը:",
    "verify_email": "Ստուգեք էլ",
    "registered_email_not_verified": "Գրանցված էլ. փոստը չի հաստատվել, խնդրում ենք անմիջապես հաստատել այն:",
    "email_verification_time_1": "Չե՞ք ստացել հաստատման նամակ:",
    "email_verification_time_2": "հետո",
    "email_verification_time_3": "Սեղմեք այստեղ՝ այն նորից ուղարկելու համար",
    "error_26301": "Սխալի կոդը՝ 26301, խնդրում ենք կապվել մեզ հետ",
    "error_26302": "Սխալի կոդը՝ 26302, խնդրում ենք կապվել մեզ հետ",
    "error_26303": "Էլփոստի ձևաչափի սխալ (սխալի կոդը՝ 26303): Խնդրում ենք կրկին մուտքագրել այն",
    "error_26304": "Խորհուրդ է տրվում 8 նիշից բարձր գաղտնաբառ (սխալի կոդը՝ 26304)",
    "error_26305": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26305): Խնդրում ենք նորից փորձել",
    "error_26306": "Էլփոստը գրանցվել է, խնդրում ենք <a href=\"https://account.mindonmap.com/login/\">գնալ մուտք գործելու համար</a>",
    "error_26307": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26307): Խնդրում ենք նորից փորձել",
    "error_26308": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26308): Խնդրում ենք նորից փորձել",
    "error_26401": "Սխալի կոդը՝ 26401, խնդրում ենք նորից փորձել",
    "error_26402": "Էլփոստը հաստատված է (սխալի կոդը՝ 26402), խնդրում ենք նորից փորձել",
    "error_26403": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26403): Խնդրում ենք նորից փորձել",
    "error_26404": "Հարցումը ձախողվեց (սխալի կոդը՝ 26404): Խնդրում ենք նորից փորձել",
    "error_26501": "Սխալի կոդը՝ 26501, խնդրում ենք կապվել մեզ հետ",
    "error_26502": "Սխալի կոդը՝ 26502, խնդրում ենք կապվել մեզ հետ",
    "error_26503": "Էլփոստի ձևաչափի սխալ (սխալի կոդը՝ 26503): Խնդրում ենք կրկին մուտքագրել այն",
    "error_26504": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26504): Խնդրում ենք նորից փորձել",
    "error_26505": "Էլփոստը գրանցված չէ, խնդրում ենք <a href=\"https://account.mindonmap.com/register/\">նախ գրանցել այն</a>",
    "error_26506": "Էլփոստը ստուգված է:",
    "error_26507": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26507): Խնդրում ենք նորից փորձել",
    "error_26508": "Ստուգումն ավարտված է (սխալի կոդը՝ 26508), խնդրում ենք նորից փորձել",
    "error_26509": "Հարցումը ձախողվեց (սխալի կոդը՝ 26509), խնդրում ենք նորից փորձել",
    "error_26510": "Սխալի կոդը՝ 26510, խնդրում ենք կապվել մեզ հետ",
    "error_26601": "Սխալի կոդը՝ 26601, խնդրում ենք կապվել մեզ հետ",
    "error_26602": "Սխալի կոդը՝ 26602, խնդրում ենք կապվել մեզ հետ",
    "error_26603": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26603): Խնդրում ենք նորից փորձել",
    "error_26604": "Սխալի կոդը՝ 26604, խնդրում ենք կապվել մեզ հետ",
    "error_26605": "Սխալի կոդը՝ 26605, խնդրում ենք կապվել մեզ հետ",
    "error_26701": "Սխալի կոդը՝ 26701, խնդրում ենք կապվել մեզ հետ",
    "error_26702": "Reuquest-ը ձախողվեց (սխալի կոդը՝ 26702): Խնդրում ենք նորից փորձել",
    "error_26703": "Սխալի կոդը՝ 26703, խնդրում ենք կապվել մեզ հետ",
    "error_26704": "Սխալի կոդը՝ 26704, խնդրում ենք կապվել մեզ հետ",
    "error_26705": "Սպասեք մուտք գործելուն (սխալի կոդը՝ 26705): Խնդրում ենք նորից փորձել",
    "no_cookie": "Դուք ձեր դիտարկիչում միացրել եք «Արգելափակել բոլոր թխուկները» գործառույթը, ուստի չեք կարող մուտք գործել: Խնդրում ենք գնալ Կարգավորումներ՝ «Թույլատրել բոլոր քուքիները» վանդակը նշելու համար:",
    "error_26801": "Սխալի կոդը՝ 26801, խնդրում ենք կապվել մեզ հետ",
    "error_26802": "Սխալի կոդը՝ 26802, խնդրում ենք կապվել մեզ հետ",
    "error_26803": "Հարցումը ձախողվեց (սխալի կոդը՝ 26803): Խնդրում ենք նորից փորձել",
    "error_26804": "Հարցումը ձախողվեց (սխալի կոդը՝ 26804): Խնդրում ենք նորից փորձել",
    "error_order": "Հարցումը ձախողվեց (սխալի կոդը՝ 27098), խնդրում ենք կրկին փորձել:",
    "error_order1": "Պատվերի հարցումը թերի է (սխալի կոդը՝ ",
    "error_order2": "） խնդրում ենք թարմացնել և նորից փորձել:",
    "modify_email_title": "Փոփոխել էլ",
    "modify_email_info": "Դուք կարող եք օգտագործել փոփոխված էլ.փոստը՝ ձեր հաշիվ մուտք գործելու համար:",
    "images_per": "Պատկերներ ըստ",
    "error_26101": "Սխալ՝ 26101: Խնդրում ենք կապվել մեզ հետ",
    "error_26102": "Սխալ՝ 26102: Խնդրում ենք կապվել մեզ հետ",
    "error_26103": "Հարցումը ձախողվեց (սխալի կոդ՝ 26103): Խնդրում ենք կրկին փորձեք",
    "error_26104": "Սխալի կոդը՝ 26104, խնդրում ենք նորից փորձել",
    "error_26105": "Սխալի կոդը՝ 26105, խնդրում ենք նորից փորձել",
    "error_26106": "Ջնջել չհաջողվեց (սխալի կոդ՝ 26106): Խնդրում ենք կրկին փորձել",
    "error_26201": "Սխալ՝ 26201: Խնդրում ենք կապվել մեզ հետ",
    "error_26202": "Հարցումը չհաջողվեց (սխալի կոդը՝ 26202): Խնդրում ենք կրկին փորձեք",
    "error_26001": "Սխալ՝ 26001: Խնդրում ենք կապվել մեզ հետ",
    "error_26002": "Սխալ՝ 26002։ Խնդրում ենք կապվել մեզ հետ",
    "error_26003": "Սխալ՝ 26003։ Խնդրում ենք կապվել մեզ հետ",
    "error_26004": "Սխալ՝ 26004։ Խնդրում ենք կապվել մեզ հետ",
    "error_26005": "Հարցումը չհաջողվեց (սխալի կոդը՝ 26005): Խնդրում ենք կրկին փորձեք",
    "error_26006": "Սխալի կոդը՝ 26006, խնդրում ենք նորից փորձել",
    "error_26008": "Սխալ՝ 26008: Խնդրում ենք կապնվել մեզ հետ",
    "go_to_the_home_page": "Գնացեք Գլխավոր էջ",
    "error_27101": "Հարցումը ձախողվեց (սխալի կոդը՝ 27101): Խնդրում ենք նորից փորձել",
    "error_27201": "Սխալի կոդը՝ 27201, խնդրում ենք կապվել մեզ հետ",
    "error_27202": "Սխալի կոդը՝ 27202, խնդրում ենք նորից փորձել",
    "error_27203": "Հարցումը ձախողվեց (սխալի կոդը՝ 27203): Խնդրում ենք նորից փորձել",
    "error_27204": "Անվավեր կոդ (սխալի կոդը՝ 27204):",
    "error_27205": "Հարցումը ձախողվեց (սխալի կոդը՝ 27205): Խնդրում ենք նորից փորձել",
    "error_27206": "Հարցումը ձախողվեց (սխալի կոդը՝ 27206): Խնդրում ենք նորից փորձել",
    "error_27207": "Հարցումը ձախողվեց (սխալի կոդը՝ 27207): Խնդրում ենք նորից փորձել",
    "no_history_found": "Դուք ոչ մի գործիք չեք օգտագործել: <a href=\"/\">Թարմացրեք</a> կամ <a href=\"https://www.mindonmap.com/\">Գնացեք պաշտոնական կայք</a>",
    "error_25301": "Սխալ՝ 25301: Խնդրում ենք կապվել մեզ հետ",
    "error_25302": "Սխալ՝ 25302: Խնդրում ենք կապվել մեզ հետ",
    "error_25303": "Հարցումը ձախողվեց (սխալի կոդ՝ 25303): Խնդրում ենք կրկին փորձել",
    "error_25304": "Հարցումը ձախողվեց (սխալի կոդ՝ 25304): Խնդրում ենք կրկին փորձել",
    "error_25305": "Հարցումը ձախողվեց (սխալի կոդ՝ 25305): Խնդրում ենք կրկին փորձել",
    "error_25306": "Հարցումը ձախողվեց (սխալի կոդ՝ 25306): Խնդրում ենք կրկին փորձել",
    "image_upscaler_p": "Պատկեր առանց ջրանիշի ներբեռնման առավելությունների.",
    "Available_for": "Հասանելի է:",
    "credit_per": "%s վարկ(ներ) մեկ HD պատկերի համար",
    "still_valid": "Գնված պլան(ներ)ը դեռ ուժի մեջ է",
    "credit": "վարկ(ներ)",
    "pc_3rd_info": "Մուտք գործեք հաջողությամբ: Խնդրում ենք գնալ դիմում հետագա շահագործման համար:",
    "use_online": "Օգտվե՛ք առցանց ծառայությունից",
    "use_download": "Օգտագործեք աշխատասեղանի ծրագիրը",
    "use_immediately": "Օգտագործեք Անմիջապես",
    "Use_in_browser": "Օգտագործեք դիտարկիչում",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} վարկ ամսական",
    "expire": "Ժամկետի ժամկետը:",
    "viewDetails": "Մանրամասնորեն",
    "viewHistory": "Դիտեք պատմության առավելությունները>>",
    "viewDetailsInfo": "Ծանուցում. Եթե բաժանորդագրության նպաստները երկարաձգվեն ժամկետի ավարտից հետո 7 օրվա ընթացքում, չօգտագործված առավելությունները կարող են շարունակվել օգտագործել: Նաև ժամկետի ավարտը ավտոմատ կերպով կթարմացվի նոր բաժանորդագրության ժամկետի: Եթե ժամկետի ավարտից 7 օր հետո նոր բաժանորդագրություն չլինի, բոլոր ժամկետանց առավելությունները կջնջվեն:",
    "connect_account": "Կապեք էլփոստը ձեր հաշվին",
    "connect_account_info": "Պարտավորելուց հետո կարող եք մուտք գործել այս էլ. հասցեով:",
    "connect_now": "Կապեք հիմա",
    "no_email_bind": "Էլփոստի կապ չկա",
    "bind_email": "Կապել էլ",
    "connect_your_email_placeholder": "Խնդրում ենք մուտքագրել ձեր էլփոստի հասցեն",
    "bind_an_email": "Կապել էլ",
    "bind_info": "Դուք հաջողությամբ մուտք եք գործել: Խնդրում ենք նամակ կապել ձեր հաշվին՝ ձեր առավելություններն ակտիվացնելու համար:",
    "bind_later": "Ավելի ուշ կապեք",
    "hi": "Բարև, %s:",
    "Personal_Information": "Անձնական տվյալներ",
    "Access": "Մուտք",
    "Subscription_Plan": "(Բաժանորդագրության պլան)",
    "No_orders": "Պատվերներ չեն գտնվել:",
    "No_data": "Տվյալներ չկան",
    "Featured_Products": "Առաջարկվող ապրանքներ",
    "More_Products": "Ավելի շատ ապրանքներ",
    "Free_Download": "Անվճար ներբեռնում",
    "Get_Started": "Սկսել",
    "Subscribe": "Բաժանորդագրվել",
    "Verified": "Ստուգված է",
    "back_to_account_center": "Վերադարձ դեպի Հաշվի կենտրոն",
    "success": "Հաջողություն:",
    "successfully": "Դուք հաջողությամբ գրանցել եք հաշիվ:",
    "Continue": "Շարունակել",
    "Already": "Արդեն ունեք հաշիվ?",
    "loading_verification": "Ստուգում է հաստատման կարգավիճակը...",
    "email_no_verification": "Ներեցեք, գրանցված էլփոստի հասցեն չի հաստատվել: Խնդրում ենք լրացնել ստուգումը վերը նշված հրահանգների համաձայն և կրկին սեղմել «Կատարված» կոճակը՝ գրանցումն ավարտելու համար:",
    "will_expire_after": "Ժամկետը կլրանա հետո",
    "hours": "ժամեր",
  },
  "id": {
    "overtime": "Kode kesalahan: {%}, silakan coba lagi",
    "isnetwork": "kesalahan internet. Silakan periksa dan coba lagi",
    "email_placeholder": "Surel",
    "email_empty": "Silakan masukkan email",
    "email_not_valid": "Email tidak valid",
    "email_not_valid_1": "Silakan masukkan email Anda",
    "email_not_valid_2": "Email tidak valid, harap gunakan alamat lain.",
    "email_not_valid_3": "Tidak ada masukan email",
    "password_placeholder": "Kata sandi",
    "password_empty": "Silakan masukkan kata sandi",
    "password_not_valid": "Akun atau kata sandi salah",
    "password_not_valid_1": "Kata sandi di atas 8 karakter diperlukan",
    "password_not_valid_2": "Silakan buat kata sandi",
    "password_placeholder_1": "Buat kata sandimu",
    "password_placeholder_2": "Konfirmasi password Anda",
    "password_placeholder_3": "Buat kata sandi baru",
    "password_placeholder_4": "Konfirmasi password baru",
    "password_placeholder_5": "Masukkan kata sandi lama",
    "copy_password_empty": "Harap konfirmasi kata sandi",
    "copy_password_not_valid": "Harap konfirmasi kata sandi Anda",
    "copy_passwords_inconsistent": "Konfirmasi kata sandi Anda tidak cocok",
    "code_empty": "Silakan masukkan kode verifikasi",
    "code_not_valid": "Kode verifikasi tidak benar",
    "code_placeholder": "Kode verifikasi",
    "not_received_code": "Jika kotak surat Anda sudah lama tidak menerima kode verifikasi, silakan dapatkan kembali kode verifikasi tersebut.",
    "get_first_code": "Silakan dapatkan kode verifikasinya terlebih dahulu.",
    "last_name_placeholder": "Silakan masukkan nama belakang Anda",
    "first_name_placeholder": "Silakan masukkan nama depan Anda",
    "address_placeholder": "Silakan masukkan alamat Anda",
    "no_code_text": "Kami telah mengirimkan kode verifikasi. Silakan masukkan kode Anda. <span class='tips'>Tidak menerima kode?",
    "no_code_text_1": "1. Pastikan alamat email valid dan dapat menerima email.",
    "no_code_text_2": "2. Untuk email yang dikirim oleh sistem secara otomatis, mungkin ditandai sebagai email spam atau sampah. Silakan periksa apakah email tersebut ada di folder Sampah.",
    "no_code_text_3": "3. Tidak dapat menyelesaikan masalah Anda? ",
    "no_code_text_3_span": "Kemudian klik di sini untuk menghubungi kami.",
    "order_no": "Anda belum membeli produk apa pun. Jika Anda memiliki pertanyaan, silakan <a href=\"https://www.mindonmap.com/contact-us/\">hubungi kami</a>.",
    "error_24901": "Akun saat ini tidak memiliki email yang tertaut, dan tidak dapat menemukan pesanan. Silakan sambungkan email.",
    "user_guide": "Panduan Pengguna>>",
    "download": "Unduh",
    "order_number": "Jumlah order:",
    "Refund": "Pengembalian dana",
    "Disabled": "Dengan disabilitas",
    "Normal": "Normal",
    "Modify": "Memodifikasi",
    "Modify_1": "Ubah>>",
    "Connect": "Menghubung",
    "unlink_success": "Berhasil membatalkan tautan",
    "connect_success": "Berhasil terhubung",
    "feedback_title": "Terima kasih atas tanggapan Anda. Silakan tinggalkan masalah Anda dan kami akan merespons Anda dalam waktu 24 jam.",
    "feedback_thank_you": "Terima kasih!<br />Masukan Anda telah berhasil dikirimkan.",
    "feedback_email": "Masukkan email Anda disini!",
    "feedback_content": "Tinggalkan masalah atau saran apa pun yang Anda temui di sini.",
    "feedback_submit": "Kirim",
    "form_contents": "Anda belum memasukkan deskripsi apa pun. Silakan masukkan dan kirimkan lagi.",
    "old_password": "Silakan masukkan kata sandi lama",
    "new_password": "Silakan buat kata sandi baru",
    "old_new_password": "Kata sandi baru tidak boleh sama dengan kata sandi lama",
    "incorrect_password": "kata kunci Salah",
    "delete_no": "Hapus sekarang",
    "Caps": "Kunci huruf kapital aktif",
    "Get": "Mendapatkan",
    "Done": "Selesai",
    "error_20001": "Kesalahan: 20001. Silakan login kembali.",
    "error_20002": "Kesalahan: 20002. Silakan login kembali.",
    "error_20003": "Kesalahan: 20003. Silakan login kembali.",
    "error_20004": "Permintaan gagal (kode kesalahan: 20004). Silakan coba lagi.",
    "error_20005": "Sesi login telah kedaluwarsa (Kesalahan: 20005). Silakan masuk lagi.",
    "error_20006": "Permintaan gagal (kode kesalahan: 20006). Silakan coba lagi.",
    "error_20007": "Sesi login telah kedaluwarsa (Kesalahan: 20007). Silakan masuk lagi.",
    "error_20008": "Sesi login telah kedaluwarsa (Kesalahan: 20008). Silakan masuk lagi.",
    "error_20009": "Sesi login telah kedaluwarsa (Kesalahan: 20009). Silakan masuk lagi.",
    "error_20101": "Silakan masukkan email Anda (kode kesalahan: 20101)",
    "error_20102": "Email tidak valid (kode kesalahan: 20102)",
    "error_20103": "Permintaan gagal (kode kesalahan: 20103). Silakan coba lagi",
    "error_20104": "Email sudah digunakan, silakan <a href=\"https://account.mindonmap.com/login/\">masuk</a> atau daftar dengan yang baru",
    "error_20105": "Permintaan gagal (kode kesalahan: 20105). Silakan coba lagi",
    "error_20106": "Gagal mengirim email, silakan coba lagi",
    "error_20201": "Silakan masukkan email Anda (kode kesalahan: 20201)",
    "error_20202": "Silakan masukkan kata sandi Anda (kode kesalahan: 20202)",
    "error_20203": "Silakan masukkan kode verifikasi (kode kesalahan: 20203)",
    "error_20204": "Email tidak valid (kode kesalahan: 20204)",
    "error_20205": "Diperlukan kata sandi di atas 8 karakter (kode kesalahan: 20205)",
    "error_20206": "Permintaan gagal (kode kesalahan: 20206). Silakan coba lagi",
    "error_20207": "Kode verifikasi tidak benar",
    "error_20208": "Permintaan gagal (kode kesalahan: 20208). Silakan coba lagi",
    "error_20209": "Permintaan gagal (kode kesalahan: 20209). Silakan coba lagi",
    "error_20301": "Silakan masukkan email Anda (kode kesalahan: 20301)",
    "error_20302": "Kesalahan: 20302. Silakan hubungi kami",
    "error_20303": "Email tidak valid (kode kesalahan: 20303)",
    "error_20304": "Permintaan gagal (kode kesalahan: 20304). Silakan coba lagi",
    "error_20305": "Akun tidak ada. Silakan masuk kembali atau <a href=\"https://account.mindonmap.com/register/\">Buat</a> terlebih dahulu.",
    "error_20306": "Belum ada kata sandi. Gunakan <a href=\"https://account.mindonmap.com/passwordless-login/\">Login tanpa kata sandi</a> atau <a href=\"https://account.mindonmap.com/create-password/\"> atur kata sandi</a> dan masuk.",
    "error_20308": "Permintaan gagal (kode kesalahan: 20308). Silakan coba lagi",
    "error_20401": "Gagal keluar (kode kesalahan: 20401). Silakan coba lagi",
    "error_20501": "Silakan masukkan email Anda (kode kesalahan: 20501)",
    "error_20502": "Email tidak valid (kode kesalahan: 20502)",
    "error_20503": "Permintaan gagal (kode kesalahan: 20503). Silakan coba lagi",
    "error_20504": "Gagal mengirim email. Silakan coba lagi.",
    "error_20601": "Silakan masukkan email Anda (kode kesalahan: 20601)",
    "error_20602": "Silakan masukkan kode verifikasi (kode kesalahan: 20602)",
    "error_20603": "Email tidak valid (kode kesalahan: 20603)",
    "error_20604": "Permintaan gagal (kode kesalahan: 20604). Silakan coba lagi",
    "error_20606": "Permintaan gagal (kode kesalahan: 20606). Silakan coba lagi",
    "error_20607": "Permintaan gagal (kode kesalahan: 20607). Silakan coba lagi",
    "error_20608": "Permintaan gagal (kode kesalahan: 20608). Silakan coba lagi",
    "error_20701": "Silakan masukkan email Anda (kode kesalahan: 20701)",
    "error_20702": "Email tidak valid (kode kesalahan: 20702)",
    "error_20703": "Permintaan gagal (kode kesalahan: 20703). Silakan coba lagi",
    "error_20704": "Akun tidak ada. Silakan masuk kembali atau <a href=\"https://account.mindonmap.com/register/\">Buat</a> terlebih dahulu.",
    "error_20705": "Permintaan gagal (kode kesalahan: 20705). Silakan coba lagi",
    "error_20706": "Gagal mengirim email. Silakan coba lagi",
    "error_20801": "Silakan masukkan email Anda (kode kesalahan: 20801)",
    "error_20802": "Kesalahan: 20802. Silakan hubungi kami",
    "error_20803": "Silakan masukkan kode verifikasi (kode kesalahan: 20803)",
    "error_20804": "Email tidak valid (kode kesalahan: 20804)",
    "error_20805": "Diperlukan kata sandi di atas 8 karakter (kode kesalahan: 20805)",
    "error_20806": "Permintaan gagal (kode kesalahan: 20806). Silakan coba lagi",
    "error_20808": "Permintaan gagal (kode kesalahan: 20808). Silakan coba lagi",
    "error_20901": "Permintaan gagal (kode kesalahan: 20901). Silakan coba lagi",
    "error_20902": "Permintaan gagal (kode kesalahan: 20902). Silakan coba lagi",
    "error_21000": "Perubahan disimpan",
    "error_21001": "Tidak ada informasi yang dikirimkan (kode kesalahan: 21001)",
    "error_21002": "Permintaan gagal (kode kesalahan: 21002). Silakan coba lagi",
    "error_21101": "Silakan masukkan email Anda (kode kesalahan: 21101)",
    "error_21102": "Email tidak valid (kode kesalahan: 21102)",
    "error_21103": "Permintaan gagal (kode kesalahan: 21103), silakan coba lagi",
    "error_21104": "Email sudah terhubung, silakan gunakan yang baru",
    "error_21105": "Permintaan gagal (kode kesalahan: 21105), silakan coba lagi",
    "error_21106": "Gagal mengirim email. Silakan coba lagi",
    "error_21201": "Silakan masukkan email Anda (kode kesalahan: 21201)",
    "error_21202": "Silakan masukkan kode verifikasi (kode kesalahan: 21202)",
    "error_21203": "Email tidak valid (kode kesalahan: 21203)",
    "error_21204": "Kesalahan: 21204. Silakan hubungi kami",
    "error_21205": "Kesalahan: 21205. Silakan hubungi kami",
    "error_21206": "Diperlukan kata sandi di atas 8 karakter (kode kesalahan: 21206)",
    "error_21207": "Permintaan gagal (kode kesalahan: 21207). Silakan coba lagi",
    "error_21209": "Permintaan gagal (kode kesalahan: 21209). Silakan coba lagi",
    "error_21301": "Silakan masukkan kata sandi lama (kode kesalahan: 21301)",
    "error_21302": "Silakan buat kata sandi baru (kode kesalahan: 21302)",
    "error_21303": "Kata sandi baru tidak boleh sama dengan kata sandi lama. (Kesalahan: 21303)",
    "error_21304": "Diperlukan kata sandi di atas 8 karakter (kode kesalahan: 21304)",
    "error_21306": "Permintaan gagal (kode kesalahan: 21306). Silakan coba lagi",
    "error_21402": "Permintaan gagal (kode kesalahan: 21402). Silakan coba lagi",
    "error_21403": "Gagal mengirim kode verifikasi. Silakan kirim ulang",
    "error_21500": "Akun telah dihapus",
    "error_21501": "Silakan masukkan kode verifikasi (kode kesalahan: 21501)",
    "error_21502": "Sesi login telah kedaluwarsa (Kesalahan: 21502). Silakan masuk lagi.",
    "error_21503": "Permintaan gagal (kode kesalahan: 21503). Silakan coba lagi",
    "error_21505": "Permintaan gagal (kode kesalahan: 21505), silakan coba lagi",
    "error_21601": "Kesalahan: 20601. Silakan hubungi kami",
    "error_21602": "Verifikasi Tidak Valid (Kesalahan: 20602). Silakan coba lagi.",
    "error_21603": "Kesalahan: 20603. Silakan coba lagi",
    "error_21604": "Permintaan gagal (kode kesalahan: 21604). Silakan coba lagi",
    "error_21606": "Permintaan gagal (kode kesalahan: 21606). Silakan coba lagi",
    "error_21611": "Permintaan gagal (kode kesalahan: 21611). Silakan coba lagi",
    "error_21801": "Kesalahan: 21801. Silakan hubungi kami",
    "error_21802": "Permintaan gagal (Kesalahan: 21802). Silakan coba lagi",
    "error_21803": "Kesalahan: 21803. Silakan coba lagi",
    "error_21804": "Permintaan gagal (kode kesalahan: 21804). Silakan coba lagi",
    "error_21806": "Kesalahan: 21806. Silakan coba lagi",
    "error_21807": "Kesalahan: 21807. Silakan hubungi kami",
    "error_21808": "Kesalahan: 21808. Silakan hubungi kami",
    "error_21809": "Kesalahan: 21809. Silakan hubungi kami",
    "error_21810": "Kesalahan: 21810. Silakan hubungi kami",
    "error_21811": "Kesalahan: 21811. Silakan hubungi kami",
    "error_21812": "Kesalahan: 21812. Silakan hubungi kami",
    "error_21813": "Permintaan gagal (kode kesalahan: 21813). Silakan coba lagi",
    "error_21814": "Kesalahan: 21814. Silakan hubungi kami",
    "error_21815": "Permintaan gagal (kode kesalahan: 21815). Silakan coba lagi",
    "error_21816": "Kesalahan: 21816. Silakan hubungi kami",
    "error_21817": "Kesalahan: 21817. Silakan hubungi kami",
    "error_21818": "Kesalahan: 21818. Silakan hubungi kami",
    "error_21819": "Permintaan gagal (kode kesalahan: 21819). Silakan coba lagi",
    "error_21820": "Kesalahan: 21820. Silakan hubungi kami",
    "error_21821": "Kesalahan: 21821. Silakan hubungi kami",
    "error_21822": "Kesalahan: 21822. Silakan hubungi kami",
    "error_21823": "Permintaan gagal (kode kesalahan: 21823). Silakan coba lagi",
    "error_21824": "Permintaan gagal (kode kesalahan: 21824). Silakan coba lagi",
    "error_21825": "Permintaan gagal (kode kesalahan: 21825). Silakan coba lagi",
    "error_21826": "Permintaan gagal (kode kesalahan: 21826). Silakan coba lagi",
    "error_21901": "Kesalahan: 21901. Silakan hubungi kami",
    "error_21902": "Permintaan gagal (kode kesalahan: 21902). Silakan coba lagi",
    "error_21903": "Status akun telah berubah (kode kesalahan: 21903), harap segarkan halaman dan coba lagi",
    "error_21904": "Kesalahan: 21904. Silakan coba lagi",
    "error_21905": "Kesalahan: 21905. Silakan coba lagi",
    "error_21906": "Permintaan gagal (kode kesalahan: 21906). Silakan coba lagi",
    "error_21907": "Akun Google telah ditautkan ke akun lain",
    "error_21908": "Permintaan gagal (kode kesalahan: 21908). Silakan coba lagi",
    "error_22001": "Permintaan gagal (kode kesalahan: 22001). Silakan coba lagi",
    "error_22002": "Pembatalan tautan gagal tanpa login tambahan",
    "error_22003": "Permintaan gagal (kode kesalahan: 22003). Silakan coba lagi",
    "error_22101": "Kesalahan: 22101. Silakan hubungi kami",
    "error_22102": "Status akun telah berubah (kode kesalahan: 22102), harap segarkan halaman dan coba lagi",
    "error_22103": "Permintaan gagal (kode kesalahan: 22103). Silakan coba lagi",
    "error_22104": "Status akun telah berubah (kode kesalahan: 22104), harap segarkan halaman dan coba lagi",
    "error_22105": "Kesalahan: 22105. Silakan coba lagi",
    "error_22106": "Kesalahan: 22106. Silakan coba lagi",
    "error_22107": "Kesalahan: 22107. Silakan hubungi kami",
    "error_22108": "Permintaan gagal (kode kesalahan: 22108). Silakan coba lagi",
    "error_22201": "Kesalahan: 22201. Silakan hubungi kami",
    "error_22202": "Verifikasi Tidak Valid (Kesalahan: 22202). Silakan coba lagi.",
    "error_22203": "Kesalahan: 22203. Silakan coba lagi\"",
    "error_22204": "Permintaan gagal (kode kesalahan: 22204). Silakan coba lagi",
    "error_22206": "Permintaan gagal (kode kesalahan: 22206). Silakan coba lagi",
    "error_22401": "Kesalahan: 22401. Silakan hubungi kami",
    "error_22402": "Verifikasi Tidak Valid (Kesalahan: 22402). Silakan coba lagi.",
    "error_22403": "Kesalahan: 22403. Silakan coba lagi",
    "error_22404": "Permintaan gagal (kode kesalahan: 22404). Silakan coba lagi",
    "error_22405": "Akun Facebook telah ditautkan ke email lain",
    "error_22406": "Kesalahan: 22406. Silakan coba lagi",
    "error_22407": "Kesalahan: 22407. Silakan hubungi kami",
    "error_22408": "Kesalahan: 22408. Silakan hubungi kami",
    "error_22409": "Kesalahan: 22409. Silakan hubungi kami",
    "error_22410": "Kesalahan: 224010. Silakan hubungi kami",
    "error_22411": "Kesalahan: 224011. Silakan hubungi kami",
    "error_22412": "Kesalahan: 224012. Silakan hubungi kami",
    "error_22413": "Kesalahan: 22413. Silakan hubungi kami",
    "error_22414": "Permintaan gagal (kode kesalahan: 22414). Silakan coba lagi",
    "error_22415": "Kesalahan: 22415. Silakan hubungi kami",
    "error_22416": "Kesalahan: 22416. Silakan hubungi kami",
    "error_22417": "Kesalahan: 22417. Silakan hubungi kami",
    "error_22418": "Permintaan gagal (kode kesalahan: 22418). Silakan coba lagi",
    "error_22419": "Kesalahan: 22419. Silakan hubungi kami",
    "error_22420": "Kesalahan: 22420. Silakan hubungi kami",
    "error_22421": "Kesalahan: 22421. Silakan hubungi kami",
    "error_22422": "Permintaan gagal (kode kesalahan: 22422). Silakan coba lagi",
    "error_22423": "Permintaan gagal (kode kesalahan: 22423). Silakan coba lagi",
    "error_22424": "Permintaan gagal (kode kesalahan: 22424). Silakan coba lagi",
    "error_22425": "Permintaan gagal (kode kesalahan: 22425). Silakan coba lagi",
    "error_20098": "Kode kesalahan: 20098. Jika Anda menjelajah dalam mode Pribadi, harap beralih ke mode Normal dan coba lagi.",
    "error_22298": "Permintaan untuk Google gagal (Kesalahan: 22298). Silakan coba lagi.",
    "error_22498": "Permintaan Facebook gagal (Kesalahan: 22498). Silakan coba lagi.",
    "error_24902": "Permintaan gagal (kode kesalahan: 24902). Silakan coba lagi",
    "error_24903": "Permintaan gagal (kode kesalahan: 24903). Silakan coba lagi",
    "error_24904": "Permintaan gagal (kode kesalahan: 24904). Silakan coba lagi",
    "error_24905": "Permintaan gagal (kode kesalahan: 24905). Silakan coba lagi",
    "login_title": "Masuk ke MindOnMap",
    "log_in": "Gabung",
    "no_account": "Tidak ada akun?",
    "create_it": "Menciptakannya",
    "or_log_in_with": "Atau masuk dengan",
    "passwordless_login": "Login tanpa kata sandi",
    "log_in_done": "Masuk Selesai",
    "three_rd_account_connect_info": "Selamat! Anda telah berhasil login. Sekarang Anda dapat menghubungkan satu akun email yang digunakan untuk login di kemudian hari.",
    "see_my_account": "Lihat akun saya",
    "three_rd_login_merge_account": "Alamat email akun pihak ketiga telah didaftarkan, apakah Anda ingin terhubung dan login langsung dengan alamat email ini?",
    "connect_log_in": "Hubungkan & Masuk",
    "create_an_account": "Buat sebuah akun",
    "back_to_log_in": "Kembali untuk masuk",
    "create_password": "Buat Kata Sandi",
    "create_now": "Buat sekarang",
    "password_login_subtitle": "Login tanpa kata sandi dengan email",
    "account_login": "Akun Masuk",
    "rights": "Dengan membuat akun ini, Anda menyetujui <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Persyaratan Layanan</a> dan <a href=\"https://www.mindonmap.com/privacy-policy/\">Kebijakan Privasi</a>",
    "passwordless_login_done": "Login tanpa kata sandi selesai",
    "passwordless_login_info": "Selamat, Anda telah berhasil menyelesaikan login tanpa kata sandi. Anda dapat membuat kata sandi untuk akun ini dan masuk dengan akun dan kata sandi tersebut di masa mendatang. <a href=\"/create-password\" style=\"display: initial;\">Buat Sekarang</a>",
    "sign_up": "Mendaftar",
    "register_info": "buat akunmu",
    "reset_now": "Setel Ulang Sekarang",
    "forgot_password": "Tidak ingat kata sandi",
    "reset_password_subtitle": "Gunakan email akun Anda untuk mengatur ulang kata sandi",
    "plan_products": "Paket & Produk",
    "nick_name": "Nama belakang:",
    "email": "Surel:",
    "my_products": "Produk Saya",
    "my_orders": "pesananku",
    "unlink": "Putuskan tautan",
    "link": "Tautan",
    "connected_accounts": "Akun yang terhubung",
    "last_name": "Nama keluarga:",
    "first_name": "Nama depan:",
    "Gender": "Jenis kelamin:",
    "Birth": "Kelahiran:",
    "Month": "Bulan",
    "Year": "Tahun",
    "Country_Region": "Negara/Wilayah:",
    "Address": "Alamat:",
    "Save": "Menyimpan",
    "Date": "Tanggal",
    "Male": "Pria",
    "Female": "Perempuan",
    "Unspecified": "Tidak ditentukan",
    "Security": "Keamanan",
    "change_password": "Ganti kata sandi",
    "change_now": "Ubah Sekarang",
    "connect_email": "Hubungkan email",
    "delete_account": "Hapus akun",
    "delete_account_info": "Ketika akun Anda dihapus, semua data di Akun Saya yang terkait dengan akun Anda akan dihapus secara permanen, dan Anda mungkin tidak dapat memulihkannya. Kami menyarankan Anda melangkah dengan hati-hati.",
    "Delete": "Menghapus",
    "Logout": "Keluar",
    "my_profile": "Profil saya",
    "guides_faqs": "Panduan & FAQ",
    "More": "Lagi",
    "guides": "Panduan",
    "register": "daftar",
    "hot_faq": "FAQ menarik",
    "Contents": "Isi:",
    "contact_us": "Hubungi kami>>",
    "plan": "Rencana",
    "unregistered": "Tidak terdaftar",
    "buy_more": "Beli Lebih Banyak",
    "buy_again": "Beli Lagi",
    "buy_now": "Beli sekarang",
    "free_no_limit": "Gratis & Tanpa batas",
    "expired": "Kedaluwarsa",
    "lifetime": "Seumur hidup",
    "remain": "Tetap",
    "day_s": "Hari",
    "error_24801": "Permintaan gagal (kode kesalahan: 24801). Silakan coba lagi",
    "no_app_found": "Tidak ada aplikasi yang ditemukan<a href=\"/\">Segarkan</a> atau <a href=\"https://www.mindonmap.com/\">Buka situs web resmi</a>",
    "get_more": "Dapatkan Lebih Banyak >>",
    "edit_photo": "Edit foto",
    "select_photo": "Pilih foto",
    "change_photo": "Ganti foto",
    "cancel": "Membatalkan",
    "hide_password": "Sembunyikan kata sandi",
    "show_password": "Tampilkan kata sandi",
    "zoom_in": "Perbesar",
    "zoom_out": "Perkecil",
    "rotate": "Memutar",
    "horizontal_flip": "Balik horizontal",
    "vertical_flip": "Balik vertikal",
    "country": "Negara",
    "country_1": "Pilih negara/wilayah Anda",
    "country_2": "Pulau Åaland",
    "country_3": "Afganistan",
    "country_4": "Albania",
    "country_5": "Aljazair",
    "country_6": "Samoa Amerika",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Angola",
    "country_10": "Antartika",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaijan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarusia",
    "country_22": "Belgium",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia & Herzegovina",
    "country_29": "Botswana",
    "country_30": "Pulau Bouvet",
    "country_31": "Brazil",
    "country_32": "Wilayah Samudera Hindia Britania",
    "country_33": "Kepulauan Virgin Inggris",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kamboja",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Tanjung Verde",
    "country_42": "Karibia Belanda",
    "country_43": "Pulau cayman",
    "country_44": "Republik Afrika Tengah",
    "country_45": "anak",
    "country_46": "Chili",
    "country_47": "Cina",
    "country_48": "Pulau Natal",
    "country_49": "Pulau Cocos (Keeling).",
    "country_50": "Kolumbia",
    "country_51": "Kepulauan Cook",
    "country_52": "Kosta Rika",
    "country_53": "Pantai Gading",
    "country_54": "Kroasia",
    "country_55": "Kuba",
    "country_56": "Siprus",
    "country_57": "Republik Ceko",
    "country_58": "Republik Demokrasi Kongo",
    "country_59": "Denmark",
    "country_60": "Djibouti",
    "country_61": "Dominika",
    "country_62": "Republik Dominika",
    "country_63": "Ekuador",
    "country_64": "Mesir",
    "country_65": "El Salvador",
    "country_66": "Guinea ekuator",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Kepulauan Falkland",
    "country_71": "Kepulauan Faroe",
    "country_72": "Negara Federasi Mikronesia",
    "country_73": "Fiji",
    "country_74": "Finlandia",
    "country_75": "Perancis",
    "country_76": "Guyana Perancis",
    "country_77": "Polinesia Perancis",
    "country_78": "Wilayah Selatan Prancis",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Jerman",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Inggris Raya (Britania Raya; Inggris)",
    "country_86": "Yunani",
    "country_87": "Tanah penggembalaan",
    "country_88": "Granada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Pulau Heard dan Pulau McDonald",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Hungaria",
    "country_101": "Islandia",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irlandia",
    "country_107": "Pulau manusia",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Jamaika",
    "country_111": "Jepang",
    "country_112": "baju kaos",
    "country_113": "Yordania",
    "country_114": "Kazakstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgistan",
    "country_119": "Laos",
    "country_120": "Latvia",
    "country_121": "Libanon",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libya",
    "country_125": "Liechtenstein",
    "country_126": "Lithuania",
    "country_127": "Luksemburg",
    "country_128": "Makau",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maladewa",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Pulau Marshall",
    "country_136": "Martinik",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Meksiko",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambik",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Belanda",
    "country_153": "Kaledonia Baru",
    "country_154": "Selandia Baru",
    "country_155": "Nikaragua",
    "country_156": "Nigeria",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Pulau Norfolk",
    "country_160": "Korea Utara",
    "country_161": "Kepulauan Mariana Utara",
    "country_162": "Norway",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Wilayah Palestina",
    "country_167": "Panama",
    "country_168": "Papua Nugini",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Kepulauan Pitcairn",
    "country_172": "Polandia",
    "country_173": "Portugal",
    "country_174": "Puerto Riko",
    "country_175": "Qatar",
    "country_176": "Republik Makedonia (FYROM)",
    "country_177": "Republik Kongo",
    "country_178": "Reuni",
    "country_179": "Rumania",
    "country_180": "Federasi Rusia",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Prancis)",
    "country_184": "Saint-Pierre dan Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Prinsip",
    "country_188": "Arab Saudi",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapura",
    "country_194": "Slowakia",
    "country_195": "Slovenia",
    "country_196": "Pulau Solomon",
    "country_197": "Somalia",
    "country_198": "Afrika Selatan",
    "country_199": "Georgia Selatan dan Pulau Sandwich Selatan",
    "country_200": "Korea Selatan",
    "country_201": "Sudan Selatan",
    "country_202": "Spanyol",
    "country_203": "Srilanka",
    "country_204": "St. Helena & Dependensi",
    "country_205": "St.Kitts&Nevis",
    "country_206": "St.Lucia",
    "country_207": "St.Vincent & Grenadines",
    "country_208": "Sudan",
    "country_209": "nama Suriname",
    "country_210": "Swaziland",
    "country_211": "Swedia",
    "country_212": "Swiss",
    "country_213": "Suriah",
    "country_214": "Taiwan",
    "country_215": "Tajikistan",
    "country_216": "Tanzania",
    "country_217": "Templat:Data negara SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "Bahama",
    "country_220": "Komoro",
    "country_221": "Orang Filipina",
    "country_222": "Timor-Leste (Timor Timur)",
    "country_223": "Untuk pergi",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Turki",
    "country_229": "Turkmenistan",
    "country_230": "Kepulauan Turks & Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Uni Emirat Arab",
    "country_235": "Pulau-Pulau Kecil Terluar Amerika Serikat",
    "country_236": "Amerika Serikat Amerika (AS)",
    "country_237": "Kepulauan Virgin Amerika Serikat",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Kota Vatikan (Tahta Suci)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis dan Futuna",
    "country_245": "Sahara Barat",
    "country_246": "Yaman",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Masuk dengan Google",
    "State": "Negara",
    "Activation_code": "Kode aktivasi",
    "Question": "Cantumkan semua aplikasi yang telah Anda masuki",
    "Copy_complete": "Salinan selesai",
    "footer": "Hak Cipta © 2024 MindOnMap Studio. Seluruh hak cipta.",
    "change_password_success": "Kata sandi berhasil diubah",
    "successful_login_title": "Masuk Berhasil",
    "product_page": "Halaman Produk>>",
    "successful_login_info": "Proses masuk selesai. Silakan tutup halaman saat ini dan kembali ke tab asli untuk melanjutkan proses berikut. Halaman saat ini akan ditutup secara otomatis dalam 5 detik. Jika penutupan otomatis atau penutupan dengan mengklik tombol \"Selesai\" gagal, harap langsung menutup tab ini.",
    "successful_login_info_firefox": "Proses masuk selesai. Silakan tutup halaman saat ini dan kembali ke tab asli untuk melanjutkan proses berikut.",
    "my_account": "Akun saya",
    "my_history": "Sejarahku",
    "remove_watermark": "Hapus Tanda Air",
    "no_history": "Tidak Ada Sejarah",
    "history_all": "Pilih Semua",
    "history_open": "Membuka",
    "history_down": "Unduh",
    "history_delete": "Menghapus",
    "history_clear": "Jelas tidak valid",
    "images": "gambar-gambar",
    "use_this_function": "Gunakan Produk Ini>>",
    "hd_downloading": "Manfaat pengunduhan gambar asli HD:",
    "lifetimeRemaining": "Sisa Seumur Hidup",
    "Remaining": "Tersisa",
    "email_verification": "Verifikasi email",
    "email_verification_info": "Kami telah mengirimkan pesan verifikasi ke email Anda <span class=\"email\"></span>, dan harap selesaikan verifikasinya. Setelah diverifikasi, manfaatnya akan tersinkronisasi secara otomatis.",
    "wrong_email": "Alamat email salah?",
    "click_here_to_modify": "Klik di sini untuk memodifikasi",
    "get_more_help": "Dapatkan bantuan lebih lanjut?",
    "click_here": "klik disini",
    "email_verification_info_success": "Selamat telah memverifikasi akun email Anda.",
    "email_verification_info_error": "Verifikasi gagal karena tautannya kedaluwarsa.",
    "registration_succeeded": "Pendaftaran Berhasil",
    "registration_succeeded_info_1": "Selamat! Anda telah berhasil mendaftar. Kami telah mengirimkan pesan verifikasi ke email Anda <span class=\"email\"></span>, dan harap selesaikan verifikasi untuk menggunakan manfaat email ini.",
    "registration_succeeded_info_2": "Klik \"Selesai\" untuk kembali ke halaman Beranda dan menggunakan produk ini.",
    "registration_succeeded_info_3": "Klik \"Selesai\" untuk menutup halaman saat ini dan kembali ke halaman Beranda untuk operasi berikut. Jika penutupan gagal, harap tutup tab secara manual.",
    "verify_email": "verifikasi email",
    "registered_email_not_verified": "Email yang didaftarkan belum terverifikasi, harap segera verifikasi.",
    "email_verification_time_1": "Tidak menerima email verifikasi?",
    "email_verification_time_2": "Setelah",
    "email_verification_time_3": "klik disini untuk mengirim ulang",
    "error_26301": "Kode kesalahan: 26301, silakan hubungi kami",
    "error_26302": "Kode kesalahan: 26302, silakan hubungi kami",
    "error_26303": "Kesalahan format email (kode kesalahan: 26303). Silakan masukkan lagi",
    "error_26304": "Disarankan kata sandi di atas 8 karakter (kode kesalahan: 26304)",
    "error_26305": "Permintaan ulang gagal (kode kesalahan: 26305). Silakan coba lagi",
    "error_26306": "Email telah terdaftar, silakan <a href=\"https://account.mindonmap.com/login/\">masuk untuk login</a>",
    "error_26307": "Permintaan ulang gagal (kode kesalahan: 26307). Silakan coba lagi",
    "error_26308": "Permintaan ulang gagal (kode kesalahan: 26308). Silakan coba lagi",
    "error_26401": "Kode kesalahan: 26401, silakan coba lagi",
    "error_26402": "Email telah diverifikasi (kode kesalahan: 26402), silakan coba lagi",
    "error_26403": "Permintaan ulang gagal (kode kesalahan: 26403). Silakan coba lagi",
    "error_26404": "Permintaan gagal (kode kesalahan: 26404). Silakan coba lagi",
    "error_26501": "Kode kesalahan: 26501, silakan hubungi kami",
    "error_26502": "Kode kesalahan: 26502, silakan hubungi kami",
    "error_26503": "Kesalahan format email (kode kesalahan: 26503). Silakan masukkan lagi",
    "error_26504": "Permintaan ulang gagal (kode kesalahan: 26504). Silakan coba lagi",
    "error_26505": "Email belum terdaftar, harap <a href=\"https://account.mindonmap.com/register/\">daftarkan terlebih dahulu</a>",
    "error_26506": "Email telah diverifikasi.",
    "error_26507": "Permintaan ulang gagal (kode kesalahan: 26507). Silakan coba lagi",
    "error_26508": "Verifikasi gagal (kode kesalahan: 26508), silakan coba lagi",
    "error_26509": "Permintaan gagal (kode kesalahan: 26509), silakan coba lagi",
    "error_26510": "Kode kesalahan: 26510, silakan hubungi kami",
    "error_26601": "Kode kesalahan: 26601, silakan hubungi kami",
    "error_26602": "Kode kesalahan: 26602, silakan hubungi kami",
    "error_26603": "Permintaan ulang gagal (kode kesalahan: 26603). Silakan coba lagi",
    "error_26604": "Kode kesalahan: 26604, silakan hubungi kami",
    "error_26605": "Kode kesalahan: 26605, silakan hubungi kami",
    "error_26701": "Kode kesalahan: 26701, silakan hubungi kami",
    "error_26702": "Permintaan ulang gagal (kode kesalahan: 26702). Silakan coba lagi",
    "error_26703": "Kode kesalahan: 26703, silakan hubungi kami",
    "error_26704": "Kode kesalahan: 26704, silakan hubungi kami",
    "error_26705": "Tunggu untuk login (kode kesalahan: 26705). Silakan coba lagi",
    "no_cookie": "Anda telah mengaktifkan fungsi Blokir semua cookie di browser Anda, sehingga Anda tidak dapat masuk. Silakan buka Pengaturan untuk mencentang kotak Izinkan semua cookie.",
    "error_26801": "Kode kesalahan: 26801, silakan hubungi kami",
    "error_26802": "Kode kesalahan: 26802, silakan hubungi kami",
    "error_26803": "Permintaan gagal (kode kesalahan: 26803). Silakan coba lagi",
    "error_26804": "Permintaan gagal (kode kesalahan: 26804). Silakan coba lagi",
    "error_order": "Permintaan gagal (kode kesalahan: 27098), coba lagi!",
    "error_order1": "Kueri pesanan tidak lengkap（kode kesalahan: ",
    "error_order2": "）, harap segarkan dan coba lagi.",
    "modify_email_title": "Ubah Email",
    "modify_email_info": "Anda dapat menggunakan email yang dimodifikasi untuk masuk ke akun Anda.",
    "images_per": "Gambar per",
    "error_26101": "Kesalahan: 26101. Silakan hubungi kami",
    "error_26102": "Kesalahan: 26102. Silakan hubungi kami",
    "error_26103": "Permintaan gagal (kode kesalahan:26103). Silakan coba lagi",
    "error_26104": "Kode kesalahan: 26104, silakan coba lagi",
    "error_26105": "Kode kesalahan: 26105, silakan coba lagi",
    "error_26106": "Penghapusan gagal (kode kesalahan: 26106). Silakan coba lagi",
    "error_26201": "Kesalahan: 26201. Silakan hubungi kami",
    "error_26202": "Permintaan gagal (kode kesalahan:26202). Silakan coba lagi",
    "error_26001": "Kesalahan: 26001. Silakan hubungi kami",
    "error_26002": "Kesalahan: 26002. Silakan hubungi kami",
    "error_26003": "Kesalahan: 26003. Silakan hubungi kami",
    "error_26004": "Kesalahan: 26004. Silakan hubungi kami",
    "error_26005": "Permintaan gagal (kode kesalahan:26005). Silakan coba lagi",
    "error_26006": "Kode kesalahan: 26006, silakan coba lagi",
    "error_26008": "Kesalahan: 26008. Silakan hubungi kami",
    "go_to_the_home_page": "Buka halaman Beranda",
    "error_27101": "Permintaan gagal (kode kesalahan: 27101). Silakan coba lagi",
    "error_27201": "Kode kesalahan: 27201, silakan hubungi kami",
    "error_27202": "Kode kesalahan: 27202, silakan coba lagi",
    "error_27203": "Permintaan gagal (kode kesalahan: 27203). Silakan coba lagi",
    "error_27204": "Kode tidak valid (kode kesalahan: 27204).",
    "error_27205": "Permintaan gagal (kode kesalahan: 27205). Silakan coba lagi",
    "error_27206": "Permintaan gagal (kode kesalahan: 27206). Silakan coba lagi",
    "error_27207": "Permintaan gagal (kode kesalahan: 27207). Silakan coba lagi",
    "no_history_found": "Anda belum menggunakan alat apa pun! <a href=\"/\">Segarkan</a> atau <a href=\"https://www.mindonmap.com/\">Buka situs web resmi</a>",
    "error_25301": "Kesalahan: 25301. Silakan hubungi kami",
    "error_25302": "Kesalahan: 25302. Silakan hubungi kami",
    "error_25303": "Permintaan gagal (kode kesalahan: 25303). Silakan coba lagi",
    "error_25304": "Permintaan gagal (kode kesalahan: 25304). Silakan coba lagi",
    "error_25305": "Permintaan gagal (kode kesalahan: 25305). Silakan coba lagi",
    "error_25306": "Permintaan gagal (kode kesalahan: 25306). Silakan coba lagi",
    "image_upscaler_p": "Manfaat pengunduhan gambar tanpa tanda air:",
    "Available_for": "Tersedia untuk:",
    "credit_per": "%s kredit per gambar HD",
    "still_valid": "Paket yang dibeli masih berlaku",
    "credit": "kredit",
    "pc_3rd_info": "Berhasil masuk. Silakan masuk ke aplikasi untuk pengoperasian lebih lanjut.",
    "use_online": "Gunakan layanan online",
    "use_download": "Gunakan program desktop",
    "use_immediately": "Gunakan Segera",
    "Use_in_browser": "Gunakan di Peramban",
    "win_desktop": "jendela",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Kredit per Bulan",
    "expire": "Waktu Kedaluwarsa:",
    "viewDetails": "Melihat rincian",
    "viewHistory": "Lihat manfaat riwayat>>",
    "viewDetailsInfo": "Pemberitahuan: Jika manfaat berlangganan diperpanjang dalam waktu 7 hari setelah habis masa berlakunya, manfaat yang belum terpakai dapat terus digunakan. Selain itu, waktu kedaluwarsa akan diperbarui secara otomatis ke waktu kedaluwarsa langganan baru. Jika tidak ada langganan baru setelah 7 hari masa berlaku habis, semua manfaat yang habis masa berlakunya akan dihapus.",
    "connect_account": "Ikat Email ke Akun Anda",
    "connect_account_info": "Setelah mengikat, Anda dapat login dengan alamat email ini.",
    "connect_now": "Ikat Sekarang",
    "no_email_bind": "Tidak ada ikatan email",
    "bind_email": "Ikat Email",
    "connect_your_email_placeholder": "Silakan masukkan alamat email Anda",
    "bind_an_email": "Ikat Email",
    "bind_info": "Anda telah berhasil login. Silakan ikat email ke akun Anda untuk mengaktifkan manfaat Anda.",
    "bind_later": "Ikat nanti",
    "hi": "Miliknya, %s!",
    "Personal_Information": "Informasi pribadi",
    "Access": "Mengakses",
    "Subscription_Plan": "(Paket Berlangganan)",
    "No_orders": "Tidak ada pesanan yang ditemukan.",
    "No_data": "Tidak ada data",
    "Featured_Products": "Produk Pilihan",
    "More_Products": "Lebih Banyak Produk",
    "Free_Download": "Download Gratis",
    "Get_Started": "Memulai",
    "Subscribe": "Langganan",
    "Verified": "Diverifikasi",
    "back_to_account_center": "Kembali ke Pusat Akun",
    "success": "Kesuksesan!",
    "successfully": "Anda telah berhasil mendaftarkan akun.",
    "Continue": "Melanjutkan",
    "Already": "Sudah memiliki akun?",
    "loading_verification": "Memeriksa status verifikasi...",
    "email_no_verification": "Maaf, alamat email yang terdaftar belum diverifikasi. Silakan selesaikan verifikasi sesuai petunjuk di atas dan klik tombol \"Selesai\" lagi untuk menyelesaikan pendaftaran.",
    "will_expire_after": "Akan kedaluwarsa setelahnya",
    "hours": "jam",
  },
  "it": {
    "overtime": "Codice errore: {%}, riprova",
    "isnetwork": "Errore Internet. Per favore controlla e riprova",
    "email_placeholder": "E-mail",
    "email_empty": "Per favore inserisci l'e-mail",
    "email_not_valid": "L'email non è valida",
    "email_not_valid_1": "Per favore inserisci la tua email",
    "email_not_valid_2": "L'e-mail non è valida, utilizza un indirizzo diverso.",
    "email_not_valid_3": "Nessun input di posta elettronica",
    "password_placeholder": "Parola d'ordine",
    "password_empty": "Per favore inserisci la password",
    "password_not_valid": "Account o password errati",
    "password_not_valid_1": "È richiesta una password superiore a 8 caratteri",
    "password_not_valid_2": "Si prega di creare una password",
    "password_placeholder_1": "crea la tua password",
    "password_placeholder_2": "Conferma la tua password",
    "password_placeholder_3": "Crea una nuova password",
    "password_placeholder_4": "Conferma la nuova password",
    "password_placeholder_5": "Inserisci la vecchia password",
    "copy_password_empty": "Si prega di confermare la password",
    "copy_password_not_valid": "Per favore conferma la tua password",
    "copy_passwords_inconsistent": "La conferma della tua password non corrisponde",
    "code_empty": "Inserisci il codice di verifica",
    "code_not_valid": "Codice di verifica non valido",
    "code_placeholder": "Codice di verifica",
    "not_received_code": "Se la tua casella di posta non ha ricevuto il codice di verifica per molto tempo, richiedilo nuovamente.",
    "get_first_code": "Per favore ottieni prima il codice di verifica.",
    "last_name_placeholder": "Per favore inserisci il tuo cognome",
    "first_name_placeholder": "Per favore immettere il proprio Nome",
    "address_placeholder": "Per favore inserisci il tuo indirizzo",
    "no_code_text": "Abbiamo inviato un codice di verifica. Per favore inserisci il tuo codice <span class='tips'>Non hai ricevuto il codice?",
    "no_code_text_1": "1. Assicurati che l'indirizzo e-mail sia valido e possa ricevere e-mail.",
    "no_code_text_2": "2. Poiché l'e-mail viene inviata automaticamente dal sistema, potrebbe essere contrassegnata come spam o posta indesiderata. Controlla se l'e-mail è nella cartella Cestino.",
    "no_code_text_3": "3. Non riesci a risolvere il tuo problema? ",
    "no_code_text_3_span": "Allora clicca qui per contattarci.",
    "order_no": "Non hai acquistato alcun prodotto. Se hai qualche domanda, <a href=\"https://www.mindonmap.com/contact-us/\">contattaci</a>.",
    "error_24901": "L'account corrente non ha un indirizzo e-mail collegato e non riesce a trovare gli ordini. Si prega di collegare un'e-mail.",
    "user_guide": "Guida per l'utente >>",
    "download": "Scaricamento",
    "order_number": "Numero d'ordine:",
    "Refund": "Rimborso",
    "Disabled": "Disabilitato",
    "Normal": "Normale",
    "Modify": "Modificare",
    "Modify_1": "Modifica >>",
    "Connect": "Collegare",
    "unlink_success": "Scollegamento riuscito",
    "connect_success": "Connettiti con successo",
    "feedback_title": "Grazie per il tuo feedback Per favore lascia il tuo problema e ti risponderemo entro 24 ore.",
    "feedback_thank_you": "Grazie!<br />Il tuo feedback è stato inviato con successo.",
    "feedback_email": "Inserisci la tua email qua!",
    "feedback_content": "Lascia qui qualsiasi problema o suggerimento che hai riscontrato.",
    "feedback_submit": "Invia",
    "form_contents": "Non hai inserito alcuna descrizione. Inseriscilo e invialo nuovamente.",
    "old_password": "Per favore inserisci la vecchia password",
    "new_password": "Per favore crea una nuova password",
    "old_new_password": "La nuova password non può essere uguale a quella vecchia",
    "incorrect_password": "password errata",
    "delete_no": "Cancella ora",
    "Caps": "Blocco maiuscolo attivo",
    "Get": "Ottenere",
    "Done": "Fatto",
    "error_20001": "Errore: 20001. Effettua nuovamente l'accesso.",
    "error_20002": "Errore: 20002. Effettua nuovamente l'accesso.",
    "error_20003": "Errore: 20003. Effettua nuovamente l'accesso.",
    "error_20004": "Richiesta non riuscita (codice errore: 20004). Per favore riprova.",
    "error_20005": "La sessione di accesso è scaduta (errore: 20005). Per favore esegui l'accesso di nuovo.",
    "error_20006": "Richiesta non riuscita (codice errore: 20006). Per favore riprova.",
    "error_20007": "La sessione di accesso è scaduta (errore: 20007). Per favore esegui l'accesso di nuovo.",
    "error_20008": "La sessione di accesso è scaduta (errore: 20008). Per favore esegui l'accesso di nuovo.",
    "error_20009": "La sessione di accesso è scaduta (errore: 20009). Per favore esegui l'accesso di nuovo.",
    "error_20101": "Inserisci la tua email (codice errore: 20101)",
    "error_20102": "L'e-mail non è valida (codice errore: 20102)",
    "error_20103": "Richiesta non riuscita (codice errore: 20103). Per favore riprova",
    "error_20104": "L'e-mail è già in uso, <a href=\"https://account.mindonmap.com/login/\">accedi</a> o registrati con uno nuovo",
    "error_20105": "Richiesta non riuscita (codice errore: 20105). Per favore riprova",
    "error_20106": "Impossibile inviare l'e-mail, riprovare",
    "error_20201": "Inserisci la tua email (codice errore: 20201)",
    "error_20202": "Inserisci la tua password (codice errore: 20202)",
    "error_20203": "Inserisci il codice di verifica (codice errore: 20203)",
    "error_20204": "L'e-mail non è valida (codice errore: 20204)",
    "error_20205": "È richiesta una password superiore a 8 caratteri (codice errore: 20205)",
    "error_20206": "Richiesta non riuscita (codice errore: 20206). Per favore riprova",
    "error_20207": "Codice di verifica non valido",
    "error_20208": "Richiesta non riuscita (codice errore: 20208). Per favore riprova",
    "error_20209": "Richiesta non riuscita (codice errore: 20209). Per favore riprova",
    "error_20301": "Inserisci la tua email (codice errore: 20301)",
    "error_20302": "Errore: 20302. Contattaci",
    "error_20303": "L'e-mail non è valida (codice errore: 20303)",
    "error_20304": "Richiesta non riuscita (codice errore: 20304). Per favore riprova",
    "error_20305": "L'account non esiste. Per favore inseriscilo nuovamente o <a href=\"https://account.mindonmap.com/register/\">Crealo</a> prima.",
    "error_20306": "Nessuna password ancora. Utilizza l'<a href=\"https://account.mindonmap.com/passwordless-login/\">Accesso senza password</a> o <a href=\"https://account.mindonmap.com/create-password/\"> imposta una password</a> e accedi.",
    "error_20308": "Richiesta non riuscita (codice errore: 20308). Per favore riprova",
    "error_20401": "Impossibile disconnettersi (codice errore: 20401). Per favore riprova",
    "error_20501": "Inserisci la tua email (codice errore: 20501)",
    "error_20502": "L'e-mail non è valida (codice errore: 20502)",
    "error_20503": "Richiesta non riuscita (codice errore: 20503). Per favore riprova",
    "error_20504": "Impossibile inviare l'e-mail. Per favore riprova.",
    "error_20601": "Inserisci la tua email (codice errore: 20601)",
    "error_20602": "Inserisci il codice di verifica (codice errore: 20602)",
    "error_20603": "L'e-mail non è valida (codice errore: 20603)",
    "error_20604": "Richiesta non riuscita (codice errore: 20604). Per favore riprova",
    "error_20606": "Richiesta non riuscita (codice errore: 20606). Per favore riprova",
    "error_20607": "Richiesta non riuscita (codice errore: 20607). Per favore riprova",
    "error_20608": "Richiesta non riuscita (codice errore: 20608). Per favore riprova",
    "error_20701": "Inserisci la tua email (codice errore: 20701)",
    "error_20702": "L'e-mail non è valida (codice errore: 20702)",
    "error_20703": "Richiesta non riuscita (codice errore: 20703). Per favore riprova",
    "error_20704": "L'account non esiste. Per favore inseriscilo nuovamente o <a href=\"https://account.mindonmap.com/register/\">Crealo</a> prima.",
    "error_20705": "Richiesta non riuscita (codice errore: 20705). Per favore riprova",
    "error_20706": "Impossibile inviare l'e-mail. Per favore riprova",
    "error_20801": "Inserisci la tua email (codice errore: 20801)",
    "error_20802": "Errore: 20802. Contattaci",
    "error_20803": "Inserisci il codice di verifica (codice errore: 20803)",
    "error_20804": "L'e-mail non è valida (codice errore: 20804)",
    "error_20805": "È richiesta una password superiore a 8 caratteri (codice errore: 20805)",
    "error_20806": "Richiesta non riuscita (codice errore: 20806). Per favore riprova",
    "error_20808": "Richiesta non riuscita (codice errore: 20808). Per favore riprova",
    "error_20901": "Richiesta non riuscita (codice errore: 20901). Per favore riprova",
    "error_20902": "Richiesta non riuscita (codice errore: 20902). Per favore riprova",
    "error_21000": "Le modifiche vengono salvate",
    "error_21001": "Nessuna informazione inviata (codice errore: 21001)",
    "error_21002": "Richiesta non riuscita (codice errore: 21002). Per favore riprova",
    "error_21101": "Inserisci la tua email (codice errore: 21101)",
    "error_21102": "L'e-mail non è valida (codice errore: 21102)",
    "error_21103": "Richiesta non riuscita (codice errore: 21103), riprovare",
    "error_21104": "L'e-mail è già connessa, utilizzane una nuova",
    "error_21105": "Richiesta non riuscita (codice errore: 21105), riprovare",
    "error_21106": "Impossibile inviare l'e-mail. Per favore riprova",
    "error_21201": "Inserisci la tua email (codice errore: 21201)",
    "error_21202": "Inserisci il codice di verifica (codice errore: 21202)",
    "error_21203": "L'e-mail non è valida (codice errore: 21203)",
    "error_21204": "Errore: 21204. Contattaci",
    "error_21205": "Errore: 21205. Contattaci",
    "error_21206": "È richiesta una password superiore a 8 caratteri (codice errore: 21206)",
    "error_21207": "Richiesta non riuscita (codice errore: 21207). Per favore riprova",
    "error_21209": "Richiesta non riuscita (codice errore: 21209). Per favore riprova",
    "error_21301": "Inserisci la vecchia password (codice errore: 21301)",
    "error_21302": "Crea una nuova password (codice errore: 21302)",
    "error_21303": "La nuova password non può essere uguale a quella vecchia. (Errore: 21303)",
    "error_21304": "È richiesta una password superiore a 8 caratteri (codice errore: 21304)",
    "error_21306": "Richiesta non riuscita (codice errore: 21306). Per favore riprova",
    "error_21402": "Richiesta non riuscita (codice errore: 21402). Per favore riprova",
    "error_21403": "Impossibile inviare il codice di verifica. Per favore, invialo di nuovo",
    "error_21500": "L'account è stato eliminato",
    "error_21501": "Inserisci il codice di verifica (codice errore: 21501)",
    "error_21502": "La sessione di accesso è scaduta (errore: 21502). Per favore esegui l'accesso di nuovo.",
    "error_21503": "Richiesta non riuscita (codice errore: 21503). Per favore riprova",
    "error_21505": "Richiesta non riuscita (codice errore: 21505), riprovare",
    "error_21601": "Errore: 20601. Contattaci",
    "error_21602": "Verifica non valida (errore: 20602). Per favore riprova.",
    "error_21603": "Errore: 20603. Riprova",
    "error_21604": "Richiesta non riuscita (codice errore: 21604). Per favore riprova",
    "error_21606": "Richiesta non riuscita (codice errore: 21606). Per favore riprova",
    "error_21611": "Richiesta non riuscita (codice errore: 21611). Per favore riprova",
    "error_21801": "Errore: 21801. Contattaci",
    "error_21802": "Richiesta non riuscita (errore: 21802). Per favore riprova",
    "error_21803": "Errore: 21803. Riprova",
    "error_21804": "Richiesta non riuscita (codice errore: 21804). Per favore riprova",
    "error_21806": "Errore: 21806. Riprova",
    "error_21807": "Errore: 21807. Contattaci",
    "error_21808": "Errore: 21808. Contattaci",
    "error_21809": "Errore: 21809. Contattaci",
    "error_21810": "Errore: 21810. Contattaci",
    "error_21811": "Errore: 21811. Contattaci",
    "error_21812": "Errore: 21812. Contattaci",
    "error_21813": "Richiesta non riuscita (codice errore: 21813). Per favore riprova",
    "error_21814": "Errore: 21814. Contattaci",
    "error_21815": "Richiesta non riuscita (codice errore: 21815). Per favore riprova",
    "error_21816": "Errore: 21816. Contattaci",
    "error_21817": "Errore: 21817. Contattaci",
    "error_21818": "Errore: 21818. Contattaci",
    "error_21819": "Richiesta non riuscita (codice errore: 21819). Per favore riprova",
    "error_21820": "Errore: 21820. Contattaci",
    "error_21821": "Errore: 21821. Contattaci",
    "error_21822": "Errore: 21822. Contattaci",
    "error_21823": "Richiesta non riuscita (codice errore: 21823). Per favore riprova",
    "error_21824": "Richiesta non riuscita (codice errore: 21824). Per favore riprova",
    "error_21825": "Richiesta non riuscita (codice errore: 21825). Per favore riprova",
    "error_21826": "Richiesta non riuscita (codice errore: 21826). Per favore riprova",
    "error_21901": "Errore: 21901. Contattaci",
    "error_21902": "Richiesta non riuscita (codice errore: 21902). Per favore riprova",
    "error_21903": "Lo stato dell'account è cambiato (codice errore: 21903), aggiorna la pagina e riprova",
    "error_21904": "Errore: 21904. Riprova",
    "error_21905": "Errore: 21905. Riprova",
    "error_21906": "Richiesta non riuscita (codice errore: 21906). Per favore riprova",
    "error_21907": "L'account Google è stato collegato ad un altro account",
    "error_21908": "Richiesta non riuscita (codice errore: 21908). Per favore riprova",
    "error_22001": "Richiesta non riuscita (codice errore: 22001). Per favore riprova",
    "error_22002": "Scollegamento non riuscito senza accesso aggiuntivo",
    "error_22003": "Richiesta non riuscita (codice errore: 22003). Per favore riprova",
    "error_22101": "Errore: 22101. Contattaci",
    "error_22102": "Lo stato dell'account è cambiato (codice errore: 22102), aggiorna la pagina e riprova",
    "error_22103": "Richiesta non riuscita (codice errore: 22103). Per favore riprova",
    "error_22104": "Lo stato dell'account è cambiato (codice errore: 22104), aggiorna la pagina e riprova",
    "error_22105": "Errore: 22105. Riprova",
    "error_22106": "Errore: 22106. Riprova",
    "error_22107": "Errore: 22107. Contattaci",
    "error_22108": "Richiesta non riuscita (codice errore: 22108). Per favore riprova",
    "error_22201": "Errore: 22201. Contattaci",
    "error_22202": "Verifica non valida (errore: 22202). Per favore riprova.",
    "error_22203": "Errore: 22203. Riprova\"",
    "error_22204": "Richiesta non riuscita (codice errore: 22204). Per favore riprova",
    "error_22206": "Richiesta non riuscita (codice errore: 22206). Per favore riprova",
    "error_22401": "Errore: 22401. Contattaci",
    "error_22402": "Verifica non valida (errore: 22402). Per favore riprova.",
    "error_22403": "Errore: 22403. Riprova",
    "error_22404": "Richiesta non riuscita (codice errore: 22404). Per favore riprova",
    "error_22405": "L'account Facebook è stato collegato ad un'altra email",
    "error_22406": "Errore: 22406. Riprova",
    "error_22407": "Errore: 22407. Contattaci",
    "error_22408": "Errore: 22408. Contattaci",
    "error_22409": "Errore: 22409. Contattaci",
    "error_22410": "Errore: 224010. Contattaci",
    "error_22411": "Errore: 224011. Contattaci",
    "error_22412": "Errore: 224012. Contattaci",
    "error_22413": "Errore: 22413. Contattaci",
    "error_22414": "Richiesta non riuscita (codice errore: 22414). Per favore riprova",
    "error_22415": "Errore: 22415. Contattaci",
    "error_22416": "Errore: 22416. Contattaci",
    "error_22417": "Errore: 22417. Contattaci",
    "error_22418": "Richiesta non riuscita (codice errore: 22418). Per favore riprova",
    "error_22419": "Errore: 22419. Contattaci",
    "error_22420": "Errore: 22420. Contattaci",
    "error_22421": "Errore: 22421. Contattaci",
    "error_22422": "Richiesta non riuscita (codice errore: 22422). Per favore riprova",
    "error_22423": "Richiesta non riuscita (codice errore: 22423). Per favore riprova",
    "error_22424": "Richiesta non riuscita (codice errore: 22424). Per favore riprova",
    "error_22425": "Richiesta non riuscita (codice errore: 22425). Per favore riprova",
    "error_20098": "Codice errore: 20098. Se stai navigando in modalità privata, passa alla modalità normale e riprova.",
    "error_22298": "Richiesta per Google non riuscita (errore: 22298). Per favore riprova.",
    "error_22498": "Richiesta per Facebook non riuscita (errore: 22498). Per favore riprova.",
    "error_24902": "Richiesta non riuscita (codice errore: 24902). Per favore riprova",
    "error_24903": "Richiesta non riuscita (codice errore: 24903). Per favore riprova",
    "error_24904": "Richiesta non riuscita (codice errore: 24904). Per favore riprova",
    "error_24905": "Richiesta non riuscita (codice errore: 24905). Per favore riprova",
    "login_title": "Accedi a MindOnMap",
    "log_in": "Login",
    "no_account": "Nessun conto?",
    "create_it": "Crealo",
    "or_log_in_with": "Oppure accedi con",
    "passwordless_login": "Accesso senza password",
    "log_in_done": "Accedi Fatto",
    "three_rd_account_connect_info": "Congratulazioni! Hai effettuato l'accesso con successo. Ora puoi connettere un account e-mail che verrà utilizzato per accedere in futuro.",
    "see_my_account": "Vedi il mio account",
    "three_rd_login_merge_account": "L'indirizzo email dell'account di terze parti è stato registrato, vuoi connetterti e accedere direttamente con questo indirizzo email?",
    "connect_log_in": "Connetti e accedi",
    "create_an_account": "Creare un account",
    "back_to_log_in": "Torna al login",
    "create_password": "Crea password",
    "create_now": "Crea ora",
    "password_login_subtitle": "Accesso senza password con e-mail",
    "account_login": "Account login",
    "rights": "Creando questo account, accetti i <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Termini di servizio</a> e i <a href=\"https://www.mindonmap.com/privacy-policy/\">Informativa sulla privacy</a>",
    "passwordless_login_done": "Accesso senza password effettuato",
    "passwordless_login_info": "Congratulazioni, hai completato con successo l'accesso senza password. Puoi creare una password per questo account e accedere con l'account e la password in futuro. <a href=\"/create-password\" style=\"display: initial;\">Crea ora</a>",
    "sign_up": "Iscrizione",
    "register_info": "crea il tuo account",
    "reset_now": "Reimposta ora",
    "forgot_password": "Ha dimenticato la password",
    "reset_password_subtitle": "Utilizza l'e-mail del tuo account per reimpostare la password",
    "plan_products": "Piani e prodotti",
    "nick_name": "Nome utente:",
    "email": "E-mail:",
    "my_products": "I miei prodotti",
    "my_orders": "i miei ordini",
    "unlink": "Scollega",
    "link": "Collegamento",
    "connected_accounts": "Conti collegati",
    "last_name": "Cognome:",
    "first_name": "Nome di battesimo:",
    "Gender": "Genere:",
    "Birth": "Nascita:",
    "Month": "Mese",
    "Year": "Anno",
    "Country_Region": "Paese/regione:",
    "Address": "Indirizzo:",
    "Save": "Salva",
    "Date": "Data",
    "Male": "Maschio",
    "Female": "Femmina",
    "Unspecified": "Non specificato",
    "Security": "Sicurezza",
    "change_password": "Cambiare la password",
    "change_now": "Cambia adesso",
    "connect_email": "Connetti l'e-mail",
    "delete_account": "Eliminare l'account",
    "delete_account_info": "Quando il tuo account viene eliminato, tutti i dati in Account personale associati al tuo account verranno eliminati definitivamente e potresti non essere in grado di recuperarli. Ti consigliamo di procedere con cautela.",
    "Delete": "Eliminare",
    "Logout": "Disconnettersi",
    "my_profile": "Il mio profilo",
    "guides_faqs": "Guide e domande frequenti",
    "More": "Di più",
    "guides": "Guide",
    "register": "Registrati",
    "hot_faq": "Domande frequenti calde",
    "Contents": "Contenuti:",
    "contact_us": "Contattaci >>",
    "plan": "Piano",
    "unregistered": "Non registrato",
    "buy_more": "Acquista di più",
    "buy_again": "Acquista di nuovo",
    "buy_now": "Acquista ora",
    "free_no_limit": "Gratuito e senza limiti",
    "expired": "Scaduto",
    "lifetime": "Tutta la vita",
    "remain": "Rimani",
    "day_s": "Giorno(i)",
    "error_24801": "Richiesta non riuscita (codice errore: 24801). Per favore riprova",
    "no_app_found": "Nessuna app trovata!<a href=\"/\">Aggiorna</a> o <a href=\"https://www.mindonmap.com/\">Vai al sito ufficiale</a>",
    "get_more": "Ottieni di più >>",
    "edit_photo": "Modifica foto",
    "select_photo": "Seleziona foto",
    "change_photo": "Cambia foto",
    "cancel": "Annulla",
    "hide_password": "Nascondi la password",
    "show_password": "Mostra password",
    "zoom_in": "Ingrandire",
    "zoom_out": "Rimpicciolisci",
    "rotate": "Ruotare",
    "horizontal_flip": "Ribaltamento orizzontale",
    "vertical_flip": "Capovolgimento verticale",
    "country": "Paese",
    "country_1": "Seleziona il tuo paese/regione",
    "country_2": "Isola di Åaland",
    "country_3": "Afghanistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "Samoa americane",
    "country_7": "Andorra",
    "country_8": "L'Angola",
    "country_9": "Anguilla",
    "country_10": "Antartide",
    "country_11": "Antigua e Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaigian",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorussia",
    "country_22": "Belgio",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia-Erzegovina",
    "country_29": "Botswana",
    "country_30": "Isola Bouvet",
    "country_31": "Brasile",
    "country_32": "Territorio britannico dell'Oceano Indiano",
    "country_33": "Isole Vergini Britanniche",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambogia",
    "country_39": "Camerun",
    "country_40": "Canada",
    "country_41": "Capo Verde",
    "country_42": "Caraibi Paesi Bassi",
    "country_43": "Isole Cayman",
    "country_44": "Repubblica Centrafricana",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "Cina",
    "country_48": "Isola di Natale",
    "country_49": "Isole Cocos (Keeling).",
    "country_50": "Colombia",
    "country_51": "Isole Cook",
    "country_52": "Costa Rica",
    "country_53": "Costa d'Avorio",
    "country_54": "Croazia",
    "country_55": "Cuba",
    "country_56": "Cipro",
    "country_57": "Repubblica Ceca",
    "country_58": "Repubblica Democratica del Congo",
    "country_59": "Danimarca",
    "country_60": "Gibuti",
    "country_61": "Dominica",
    "country_62": "Repubblica Dominicana",
    "country_63": "Ecuador",
    "country_64": "Egitto",
    "country_65": "El Salvador",
    "country_66": "Guinea Equatoriale",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Isole Falkland",
    "country_71": "Isole Faroe",
    "country_72": "Stati Federati di Micronesia",
    "country_73": "Figi",
    "country_74": "Finlandia",
    "country_75": "Francia",
    "country_76": "Guiana francese",
    "country_77": "Polinesia francese",
    "country_78": "Territori della Francia del sud",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Germania",
    "country_83": "Ghana",
    "country_84": "Gibilterra",
    "country_85": "Gran Bretagna (Regno Unito; Inghilterra)",
    "country_86": "Grecia",
    "country_87": "Groenlandia",
    "country_88": "Grenada",
    "country_89": "Guadalupa",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Isola Heard e Isole McDonald",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Ungheria",
    "country_101": "Islanda",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Iraq",
    "country_106": "Irlanda",
    "country_107": "Isola di Man",
    "country_108": "Israele",
    "country_109": "Italia",
    "country_110": "Giamaica",
    "country_111": "Giappone",
    "country_112": "Maglia",
    "country_113": "Giordania",
    "country_114": "Kazakistan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirghizistan",
    "country_119": "Laos",
    "country_120": "Lettonia",
    "country_121": "Libano",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Lituania",
    "country_127": "Lussemburgo",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldive",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Isole Marshall",
    "country_136": "Martinica",
    "country_137": "La Mauritania",
    "country_138": "Maurizio",
    "country_139": "Mayotte",
    "country_140": "Messico",
    "country_141": "Moldavia",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marocco",
    "country_147": "Mozambico",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Olanda",
    "country_153": "Nuova Caledonia",
    "country_154": "Nuova Zelanda",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Isola Norfolk",
    "country_160": "Corea del nord",
    "country_161": "Isole Marianne settentrionali",
    "country_162": "Norvegia",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Territori palestinesi",
    "country_167": "Panama",
    "country_168": "Papua Nuova Guinea",
    "country_169": "Paraguay",
    "country_170": "Perù",
    "country_171": "Isole Pitcairn",
    "country_172": "Polonia",
    "country_173": "Portogallo",
    "country_174": "Porto Rico",
    "country_175": "Qatar",
    "country_176": "Repubblica di Macedonia (ERIM)",
    "country_177": "Repubblica del Congo",
    "country_178": "Riunione",
    "country_179": "Romania",
    "country_180": "Federazione Russa",
    "country_181": "Ruanda",
    "country_182": "San Bartolomeo",
    "country_183": "Saint Martin (Francia)",
    "country_184": "Saint-Pierre e Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tomé e Principe",
    "country_188": "Arabia Saudita",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovacchia",
    "country_195": "Slovenia",
    "country_196": "Isole Salomone",
    "country_197": "Somalia",
    "country_198": "Sud Africa",
    "country_199": "La Georgia del Sud e l'Isola Sandwich del Sud",
    "country_200": "Corea del Sud",
    "country_201": "Sudan del Sud",
    "country_202": "Spagna",
    "country_203": "Sri Lanka",
    "country_204": "Sant'Elena e dipendenze",
    "country_205": "Saint Kitts e Nevis",
    "country_206": "Santa Lucia",
    "country_207": "Saint Vincent e le Grenadine",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Svezia",
    "country_212": "Svizzera",
    "country_213": "Siria",
    "country_214": "Taiwan",
    "country_215": "Tagikistan",
    "country_216": "Tanzania",
    "country_217": "Modello: dati per paese SJM Svalbard",
    "country_218": "Tailandia",
    "country_219": "Le Bahamas",
    "country_220": "Le Comore",
    "country_221": "Le Filippine",
    "country_222": "Timor Est (Timor Est)",
    "country_223": "Andare",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Tacchino",
    "country_229": "Turkmenistan",
    "country_230": "Isole Turks e Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucraina",
    "country_234": "Emirati Arabi Uniti",
    "country_235": "Isole minori periferiche degli Stati Uniti",
    "country_236": "Stati Uniti d'America (USA)",
    "country_237": "Isole Vergini degli Stati Uniti",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Città del Vaticano (Santa Sede)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis e Futuna",
    "country_245": "Sahara occidentale",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Accedi con Google",
    "State": "Stato",
    "Activation_code": "Codice di attivazione",
    "Question": "Elenca tutte le app a cui hai effettuato l'accesso",
    "Copy_complete": "Copia completata",
    "footer": "Copyright © 2024 MindOnMap Studio. Tutti i diritti riservati.",
    "change_password_success": "password cambiata con successo",
    "successful_login_title": "Accesso riuscito",
    "product_page": "Pagina del prodotto >>",
    "successful_login_info": "Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con la procedura seguente. La pagina corrente verrà chiusa automaticamente entro 5 secondi. Se la chiusura automatica o la chiusura facendo clic sul pulsante \"Fine\" non riesce, chiudi direttamente questa scheda.",
    "successful_login_info_firefox": "Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con la procedura seguente.",
    "my_account": "Il mio conto",
    "my_history": "La mia storia",
    "remove_watermark": "Rimuovi filigrana",
    "no_history": "Nessuna storia",
    "history_all": "Seleziona tutto",
    "history_open": "Aprire",
    "history_down": "Scaricamento",
    "history_delete": "Eliminare",
    "history_clear": "Cancella non valido",
    "images": "immagini",
    "use_this_function": "Usa questo prodotto>>",
    "hd_downloading": "Vantaggi del download di immagini originali HD:",
    "lifetimeRemaining": "Vita residua",
    "Remaining": "Residuo",
    "email_verification": "verifica email",
    "email_verification_info": "Abbiamo inviato il messaggio di verifica al tuo indirizzo email <span class=\"email\"></span> e ti preghiamo di completare la verifica. Dopo la verifica, i vantaggi verranno sincronizzati automaticamente.",
    "wrong_email": "Indirizzo e-mail sbagliato?",
    "click_here_to_modify": "Clicca qui per modificare",
    "get_more_help": "Hai più aiuto?",
    "click_here": "clicca qui",
    "email_verification_info_success": "Congratulazioni per aver verificato il tuo account e-mail.",
    "email_verification_info_error": "La verifica non è riuscita a causa del collegamento scaduto.",
    "registration_succeeded": "Registrazione riuscita",
    "registration_succeeded_info_1": "Congratulazioni! Hai completato la registrazione con successo. Abbiamo inviato il messaggio di verifica al tuo indirizzo email <span class=\"email\"></span> e ti invitiamo a completare la verifica per sfruttare i vantaggi di questa email.",
    "registration_succeeded_info_2": "Fare clic su \"Fine\" per tornare alla home page e utilizzare questo prodotto.",
    "registration_succeeded_info_3": "Fare clic su \"Fine\" per chiudere la pagina corrente e tornare alla Home page per le operazioni successive. Se la chiusura non riesce, chiudere manualmente la scheda.",
    "verify_email": "Verifica Email",
    "registered_email_not_verified": "L'e-mail registrata non è stata verificata, verificala subito.",
    "email_verification_time_1": "Non hai ricevuto un'e-mail di verifica?",
    "email_verification_time_2": "Dopo",
    "email_verification_time_3": "Clicca qui per inviarlo nuovamente",
    "error_26301": "Codice errore: 26301, contattaci",
    "error_26302": "Codice errore: 26302, contattaci",
    "error_26303": "Errore nel formato dell'e-mail (codice errore: 26303). Per favore inseriscilo di nuovo",
    "error_26304": "Si consiglia una password superiore a 8 caratteri (codice errore: 26304)",
    "error_26305": "Richiesta non riuscita (codice errore: 26305). Per favore riprova",
    "error_26306": "L'e-mail è stata registrata, <a href=\"https://account.mindonmap.com/login/\">vai ad accedere</a>",
    "error_26307": "Richiesta non riuscita (codice errore: 26307). Per favore riprova",
    "error_26308": "Richiesta non riuscita (codice errore: 26308). Per favore riprova",
    "error_26401": "Codice errore: 26401, riprova",
    "error_26402": "L'e-mail è stata verificata (codice errore: 26402), riprova",
    "error_26403": "Richiesta non riuscita (codice errore: 26403). Per favore riprova",
    "error_26404": "Richiesta non riuscita (codice errore: 26404). Per favore riprova",
    "error_26501": "Codice errore: 26501, contattaci",
    "error_26502": "Codice errore: 26502, contattaci",
    "error_26503": "Errore nel formato dell'e-mail (codice errore: 26503). Per favore inseriscilo di nuovo",
    "error_26504": "Richiesta non riuscita (codice errore: 26504). Per favore riprova",
    "error_26505": "L'e-mail non è stata registrata, per favore <a href=\"https://account.mindonmap.com/register/\">registrala prima</a>",
    "error_26506": "L'e-mail è stata verificata.",
    "error_26507": "Richiesta non riuscita (codice errore: 26507). Per favore riprova",
    "error_26508": "Verifica non riuscita (codice errore: 26508), riprovare",
    "error_26509": "Richiesta non riuscita (codice errore: 26509), riprovare",
    "error_26510": "Codice errore: 26510, contattaci",
    "error_26601": "Codice errore: 26601, contattaci",
    "error_26602": "Codice errore: 26602, contattaci",
    "error_26603": "Richiesta non riuscita (codice errore: 26603). Per favore riprova",
    "error_26604": "Codice errore: 26604, contattaci",
    "error_26605": "Codice errore: 26605, contattaci",
    "error_26701": "Codice errore: 26701, contattaci",
    "error_26702": "Richiesta non riuscita (codice errore: 26702). Per favore riprova",
    "error_26703": "Codice errore: 26703, contattaci",
    "error_26704": "Codice errore: 26704, contattaci",
    "error_26705": "Attendi l'accesso (codice errore: 26705). Per favore riprova",
    "no_cookie": "Hai attivato la funzione Blocca tutti i cookie nel tuo browser, quindi non puoi accedere. Vai su Impostazioni e seleziona la casella Consenti tutti i cookie.",
    "error_26801": "Codice errore: 26801, contattaci",
    "error_26802": "Codice errore: 26802, contattaci",
    "error_26803": "Richiesta non riuscita (codice errore: 26803). Per favore riprova",
    "error_26804": "Richiesta non riuscita (codice errore: 26804). Per favore riprova",
    "error_order": "Richiesta non riuscita (codice errore: 27098), riprova!",
    "error_order1": "La query dell'ordine è incompleta（codice errore: ",
    "error_order2": "）, aggiorna e riprova.",
    "modify_email_title": "Modifica e-mail",
    "modify_email_info": "Puoi utilizzare l'e-mail modificata per accedere al tuo account.",
    "images_per": "Immagini per",
    "error_26101": "Errore: 26101. Contattaci",
    "error_26102": "Errore: 26102. Contattaci",
    "error_26103": "Richiesta non riuscita (codice errore: 26103). Per favore, riprova",
    "error_26104": "Codice errore: 26104, riprovare",
    "error_26105": "Codice errore: 26105, riprovare",
    "error_26106": "Eliminazione non riuscita (codice errore: 26106). Per favore riprova",
    "error_26201": "Errore: 26201. Contattaci",
    "error_26202": "Richiesta non riuscita (codice errore: 26202). Per favore, riprova",
    "error_26001": "Errore: 26001. Contattaci",
    "error_26002": "Errore: 26002. Contattaci",
    "error_26003": "Errore: 26003. Contattaci",
    "error_26004": "Errore: 26004. Contattaci",
    "error_26005": "Richiesta non riuscita (codice errore: 26005). Per favore, riprova",
    "error_26006": "Codice errore: 26006, riprovare",
    "error_26008": "Errore: 26008. Contattaci",
    "go_to_the_home_page": "Vai alla home page",
    "error_27101": "Richiesta non riuscita (codice errore: 27101). Per favore riprova",
    "error_27201": "Codice errore: 27201, contattaci",
    "error_27202": "Codice errore: 27202, riprova",
    "error_27203": "Richiesta non riuscita (codice errore: 27203). Per favore riprova",
    "error_27204": "Codice non valido (codice errore: 27204).",
    "error_27205": "Richiesta non riuscita (codice errore: 27205). Per favore riprova",
    "error_27206": "Richiesta non riuscita (codice errore: 27206). Per favore riprova",
    "error_27207": "Richiesta non riuscita (codice errore: 27207). Per favore riprova",
    "no_history_found": "Non hai utilizzato nessuno strumento! <a href=\"/\">Aggiorna</a> o <a href=\"https://www.mindonmap.com/\">Vai al sito ufficiale</a>",
    "error_25301": "Errore: 25301. Contattaci",
    "error_25302": "Errore: 25302. Contattaci",
    "error_25303": "Richiesta non riuscita (codice errore: 25303). Per favore riprova",
    "error_25304": "Richiesta non riuscita (codice errore: 25304). Per favore riprova",
    "error_25305": "Richiesta non riuscita (codice errore: 25305). Per favore riprova",
    "error_25306": "Richiesta non riuscita (codice errore: 25306). Per favore riprova",
    "image_upscaler_p": "Vantaggi del download di immagini senza filigrana:",
    "Available_for": "Disponibile per:",
    "credit_per": "%s credito/i per immagine HD",
    "still_valid": "I piani acquistati sono ancora validi",
    "credit": "crediti)",
    "pc_3rd_info": "Accedi con successo. Si prega di andare all'applicazione per ulteriori operazioni.",
    "use_online": "Utilizza il servizio online",
    "use_download": "Utilizzare il programma desktop",
    "use_immediately": "Utilizzare immediatamente",
    "Use_in_browser": "Utilizzare nel browser",
    "win_desktop": "finestre",
    "Mac_desktop": "Mac",
    "credits_per": "{%} crediti al mese",
    "expire": "Data di scadenza:",
    "viewDetails": "Visualizza dettagli",
    "viewHistory": "Visualizza i vantaggi della cronologia>>",
    "viewDetailsInfo": "Avviso: se i vantaggi dell'abbonamento vengono rinnovati entro 7 giorni dalla scadenza, i vantaggi non utilizzati potranno continuare a essere utilizzati. Inoltre, la data di scadenza verrà automaticamente aggiornata alla data di scadenza del nuovo abbonamento. Se non è presente alcun nuovo abbonamento dopo 7 giorni dalla scadenza, tutti i vantaggi scaduti verranno cancellati.",
    "connect_account": "Associa l'e-mail al tuo account",
    "connect_account_info": "Dopo l'associazione, puoi accedere con questo indirizzo email.",
    "connect_now": "Associa ora",
    "no_email_bind": "Nessun vincolo e-mail",
    "bind_email": "Associa e-mail",
    "connect_your_email_placeholder": "Inserisci il tuo indirizzo email",
    "bind_an_email": "Associa un'e-mail",
    "bind_info": "Hai effettuato l'accesso con successo. Associa un'e-mail al tuo account per attivare i vantaggi.",
    "bind_later": "Rilega più tardi",
    "hi": "Il suo, %s!",
    "Personal_Information": "Informazione personale",
    "Access": "Accesso",
    "Subscription_Plan": "(Piano di abbonamento)",
    "No_orders": "Nessun ordine trovato.",
    "No_data": "Nessun dato",
    "Featured_Products": "prodotti sponsorizzati",
    "More_Products": "Più prodotti",
    "Free_Download": "Download gratuito",
    "Get_Started": "Iniziare",
    "Subscribe": "sottoscrivi",
    "Verified": "Verificato",
    "back_to_account_center": "Torna al Centro account",
    "success": "Successo!",
    "successfully": "Hai registrato con successo un account.",
    "Continue": "Continua",
    "Already": "Hai già un account?",
    "loading_verification": "Controllo dello stato di verifica...",
    "email_no_verification": "Siamo spiacenti, l'indirizzo email registrato non è stato verificato. Completa la verifica secondo le istruzioni sopra riportate e fai nuovamente clic sul pulsante \"Fine\" per completare la registrazione.",
    "will_expire_after": "Scadrà dopo",
    "hours": "ore",
  },
  "ja": {
    "overtime": "エラーコード: {%}、再試行してください。",
    "isnetwork": "インターネットエラー。確認して再試行してください。",
    "email_placeholder": "メールアドレス",
    "email_empty": "メールアドレスを入力してください。",
    "email_not_valid": "メールが無効です。",
    "email_not_valid_1": "メールアドレスを入力してください",
    "email_not_valid_2": "メールは無効です。メールを変えて再度入力してください。",
    "email_not_valid_3": "メール入力なし",
    "password_placeholder": "パスワード",
    "password_empty": "パスワードを入力してください。",
    "password_not_valid": "間違えたメールまたはパスワードです。",
    "password_not_valid_1": "8文字以上のパスワードが必要です。",
    "password_not_valid_2": "パスワードを作成してください。",
    "password_placeholder_1": "パスワードを作成する",
    "password_placeholder_2": "パスワードを確認しましょう。",
    "password_placeholder_3": "新しいパスワードを作成します。",
    "password_placeholder_4": "新しいパスワードを確認します。",
    "password_placeholder_5": "古いパスワードを入力してください。",
    "copy_password_empty": "パスワードを確認してください",
    "copy_password_not_valid": "パスワードを確認する",
    "copy_passwords_inconsistent": "パスワードが一致しません。",
    "code_empty": "検証コードを入力してください。",
    "code_not_valid": "無効な検証コード",
    "code_placeholder": "検証コード",
    "not_received_code": "メールボックスに長い間認証コードが届いていない場合は、再度認証コードを取得してください。",
    "get_first_code": "最初に認証コードを取得してください。",
    "last_name_placeholder": "姓を入力してください",
    "first_name_placeholder": "名を入力してください",
    "address_placeholder": "住所を入力してください",
    "no_code_text": "検証コードを送信しました。コードを入力してください。<spanclass='tips'>コードを受け取りませんでしたか？",
    "no_code_text_1": "1.メールアドレスが有効で、メールを受信できることを確認してください。",
    "no_code_text_2": "2.メールはシステムによって自動的に送信されるため、迷惑メールやスパムメールとして認識される場合があります。メールがゴミ箱フォルダにあるかどうかを確認してください。",
    "no_code_text_3": "3.問題を解決できませんか？",
    "no_code_text_3_span": "ここをクリックしてこちらへ連絡してください。",
    "order_no": "まだ製品を購入していません。ご質問がある場合は、<a href=\"https://www.mindonmap.com/contact-us/\">お問い合わせ</a>ください。",
    "error_24901": "現在のアカウントにはメールアドレスがリンクされておらず、注文を見つけることができません。メールアドレスをバインドしてください。",
    "user_guide": "ユーザーガイド>>",
    "download": "ダウンロード",
    "order_number": "注文番号",
    "Refund": "返金",
    "Disabled": "無効",
    "Normal": "正常",
    "Modify": "変更",
    "Modify_1": "変更>>",
    "Connect": "接続",
    "unlink_success": "正常にリンク解除",
    "connect_success": "正常にバインドします",
    "feedback_title": "ご意見をいただきありがとうございます。問題を残してください。24時間以内に対応いたします。",
    "feedback_thank_you": "ありがとうございます！<br/>フィードバックは送信されました。",
    "feedback_email": "メールアドレスをここに入力してください！",
    "feedback_content": "ここでアドバイスとか問題とかを残してください。",
    "feedback_submit": "提出",
    "form_contents": "内容が入力されません。内容を入力してもう一度提出してください。",
    "old_password": "古いパスワードを入力してください。",
    "new_password": "新しいパスワードを作成してください。",
    "old_new_password": "新しいパスワードは古いパスワードと同じにすることはできません。",
    "incorrect_password": "間違ったパスワード",
    "delete_no": "今すぐ削除",
    "Caps": "CapsLockがオンになっています。",
    "Get": "取得する",
    "Done": "完了",
    "error_20001": "エラー：20001。再度ログインしてください。",
    "error_20002": "エラー：20002。再度ログインしてください。",
    "error_20003": "エラー：20003。再度ログインしてください。",
    "error_20004": "請求が失敗しました(エラーコード:20004)。再試行してください。",
    "error_20005": "ログインセッションの有効期限が切れました（エラー：20005）。もう一度ログインしてください。",
    "error_20006": "請求が失敗しました（エラーコード：20006）。もう一度お試しください。",
    "error_20007": "ログインセッションの有効期限が切れました（エラー：20007）。もう一度ログインしてください。",
    "error_20008": "ログインセッションの有効期限が切れました（エラー：20008）。もう一度ログインしてください。",
    "error_20009": "ログインセッションの有効期限が切れました（エラー：20009）。もう一度ログインしてください。",
    "error_20101": "メールアドレスを入力してください（エラーコード：20101）",
    "error_20102": "メールが無効です（エラーコード：20102）",
    "error_20103": "請求が失敗しました（エラーコード：20103）。もう一度お試しください。",
    "error_20104": "メールはすでに使用されています。 <a href=\"https://account.mindonmap.com/login/\">ログイン</a>するか、新しいメールに登録してください。",
    "error_20105": "請求が失敗しました（エラーコード：20105）。もう一度お試しください。",
    "error_20106": "メールの送信に失敗しました。再試行してください。",
    "error_20201": "メールアドレスを入力してください（エラーコード：20201）",
    "error_20202": "パスワードを入力してください（エラーコード：20202）",
    "error_20203": "検証コードを入力してください（エラーコード：20203）",
    "error_20204": "メールが無効です（エラーコード：20204）",
    "error_20205": "8文字以上のパスワードが必要です（エラーコード：20205）",
    "error_20206": "請求が失敗しました（エラーコード：20206）。もう一度お試しください。",
    "error_20207": "無効な検証コード",
    "error_20208": "請求が失敗しました（エラーコード：20208）。もう一度お試しください",
    "error_20209": "請求が失敗しました（エラーコード：20209）。もう一度お試しください",
    "error_20301": "メールアドレスを入力してください（エラーコード：20301）",
    "error_20302": "エラー：20302。お問い合わせください",
    "error_20303": "メールが無効です（エラーコード：20303）",
    "error_20304": "請求が失敗しました（エラーコード：20304）。もう一度お試しください。",
    "error_20305": "アカウントが存在しません。再度入力するか、 <a href=\"https://account.mindonmap.com/register/\">作成する</a>してください。",
    "error_20306": "パスワードはまだありません。<a href=\"https://account.mindonmap.com/passwordless-login/\">パスワードなしでログイン</a>または<a href=\"https://account.mindonmap.com/create-password/\">パスワードを作成</a>てログインします。",
    "error_20308": "請求が失敗しました（エラーコード：20308）。もう一度お試しください。",
    "error_20401": "ログアウトに失敗しました（エラーコード：20401）。もう一度お試しください。",
    "error_20501": "メールアドレスを入力してください（エラーコード：20501）",
    "error_20502": "メールが無効です（エラーコード：20502）",
    "error_20503": "請求が失敗しました（エラーコード：20503）。もう一度お試しください。",
    "error_20504": "メール送信に失敗しました。もう一度お試しください。",
    "error_20601": "メールアドレスを入力してください（エラーコード：20601）",
    "error_20602": "検証コードを入力してください（エラーコード：20602）",
    "error_20603": "メールが無効です（エラーコード：20603）",
    "error_20604": "請求が失敗しました（エラーコード：20604）。もう一度お試しください。",
    "error_20606": "請求が失敗しました（エラーコード：20606）。もう一度お試しください。",
    "error_20607": "請求が失敗しました（エラーコード：20607）。もう一度お試しください。",
    "error_20608": "請求が失敗しました（エラーコード：20608）。もう一度お試しください。",
    "error_20701": "メールアドレスを入力してください（エラーコード：20701）",
    "error_20702": "メールが無効です（エラーコード：20702）",
    "error_20703": "請求が失敗しました（エラーコード：20703）。もう一度お試しください。",
    "error_20704": "アカウントが存在しません。再度入力するか、<a href=\"https://account.mindonmap.com/register/\">作成する</a>してください。",
    "error_20705": "請求が失敗しました（エラーコード：20705）。もう一度お試しください。",
    "error_20706": "メール送信に失敗しました。もう一度お試しください。",
    "error_20801": "メールアドレスを入力してください（エラーコード：20801）",
    "error_20802": "エラー：20802。お問い合わせください",
    "error_20803": "検証コードを入力してください（エラーコード：20803）",
    "error_20804": "メールが無効です（エラーコード：20804）",
    "error_20805": "8文字以上のパスワードが必要です（エラーコード：20805）",
    "error_20806": "請求が失敗しました（エラーコード：20806）。もう一度お試しください。",
    "error_20808": "請求が失敗しました（エラーコード：20808）。もう一度お試しください。",
    "error_20901": "請求が失敗しました（エラーコード：20901）。もう一度お試しください。",
    "error_20902": "請求が失敗しました（エラーコード：20902）。もう一度お試しください。",
    "error_21000": "変更が保存されます。",
    "error_21001": "内容は送信されません（エラーコード：21001）",
    "error_21002": "請求が失敗しました（エラーコード：21002）。もう一度お試しください。",
    "error_21101": "メールアドレスを入力してください（エラーコード：21101）",
    "error_21102": "メールが無効です（エラーコード：21102）",
    "error_21103": "請求が失敗しました（エラーコード：21103）、もう一度やり直してください。",
    "error_21104": "このメールは使用された状態です。新しいメールを使用してください。",
    "error_21105": "請求が失敗しました（エラーコード：21105）、もう一度やり直してください。",
    "error_21106": "メール送信に失敗しました。もう一度お試しください。",
    "error_21201": "メールアドレスを入力してください（エラーコード：21201）",
    "error_21202": "検証コードを入力してください（エラーコード：21202）",
    "error_21203": "メールが無効です（エラーコード：21203）",
    "error_21204": "エラー：21204。お問い合わせください。",
    "error_21205": "エラー：21205。お問い合わせください。",
    "error_21206": "8文字以上のパスワードが必要です（エラーコード：21206）",
    "error_21207": "請求が失敗しました（エラーコード：21207）。もう一度お試しください。",
    "error_21209": "請求が失敗しました（エラーコード：21209）。もう一度お試しください。",
    "error_21301": "古いパスワードを入力してください（エラーコード：21301）",
    "error_21302": "新しいパスワードを作成してください（エラーコード：21302）",
    "error_21303": "新しいパスワードは古いパスワードと同じにすることはできません。（エラー：21303）",
    "error_21304": "8文字以上のパスワードが必要です（エラーコード：21304）",
    "error_21306": "請求が失敗しました（エラーコード：21306）。もう一度お試しください。",
    "error_21402": "請求が失敗しました（エラーコード：21402）。もう一度お試しください。",
    "error_21403": "検証コードは送信に失敗しました。再度送信してください。",
    "error_21500": "アカウントが削除されました。",
    "error_21501": "検証コードを入力してください（エラーコード：21501）",
    "error_21502": "ログインセッションの有効期限が切れました（エラー：21502）。もう一度ログインしてください。",
    "error_21503": "請求が失敗しました（エラーコード：21503）。もう一度お試しください",
    "error_21505": "請求が失敗しました（エラーコード：21505）、もう一度やり直してください",
    "error_21601": "エラー：20601。お問い合わせください。",
    "error_21602": "無効な検証（エラー：20602）。もう一度お試しください。",
    "error_21603": "エラー：20603。もう一度お試しください。",
    "error_21604": "請求が失敗しました（エラーコード：21604）。もう一度お試しください。",
    "error_21606": "請求が失敗しました（エラーコード：21606）。もう一度お試しください。",
    "error_21611": "請求が失敗しました（エラーコード：21611）。もう一度お試しください。",
    "error_21801": "エラー：21801。お問い合わせください。",
    "error_21802": "請求が失敗しました（エラー：21802）。もう一度お試しください。",
    "error_21803": "エラー：21803。もう一度お試しください。",
    "error_21804": "請求が失敗しました（エラーコード：21804）。もう一度お試しください。",
    "error_21806": "エラー：21806。もう一度お試しください。",
    "error_21807": "エラー：21807。お問い合わせください。",
    "error_21808": "エラー：21808。お問い合わせください。",
    "error_21809": "エラー：21809。お問い合わせください。",
    "error_21810": "エラー：21810。お問い合わせください。",
    "error_21811": "エラー：21811。お問い合わせください。",
    "error_21812": "エラー：21812。お問い合わせください。",
    "error_21813": "請求が失敗しました（エラーコード：21813）。もう一度お試しください。",
    "error_21814": "エラー：21814。お問い合わせください。",
    "error_21815": "請求が失敗しました（エラーコード：21815）。もう一度お試しください。",
    "error_21816": "エラー：21816。お問い合わせください。",
    "error_21817": "エラー：21817。お問い合わせください。",
    "error_21818": "エラー：21818。お問い合わせください。",
    "error_21819": "請求が失敗しました（エラーコード：21819）。もう一度お試しください。",
    "error_21820": "エラー：21820。お問い合わせください。",
    "error_21821": "エラー：21821。お問い合わせください。",
    "error_21822": "エラー：21822。お問い合わせください。",
    "error_21823": "請求が失敗しました（エラーコード：21823）。もう一度お試しください。",
    "error_21824": "請求が失敗しました（エラーコード：21824）。もう一度お試しください。",
    "error_21825": "請求が失敗しました（エラーコード：21825）。もう一度お試しください。",
    "error_21826": "請求が失敗しました（エラーコード：21826）。もう一度お試しください。",
    "error_21901": "エラー：21901。お問い合わせください。",
    "error_21902": "請求が失敗しました（エラーコード：21902）。もう一度お試しください。",
    "error_21903": "アカウント状態が変更されました（エラーコード：21903）。ページを更新してもう一度お試しください。",
    "error_21904": "エラー：21904。もう一度お試しください。",
    "error_21905": "エラー：21905。もう一度お試しください。",
    "error_21906": "請求が失敗しました（エラーコード：21906）。もう一度お試しください。",
    "error_21907": "Googleアカウントは他のアカウントにリンクされています。",
    "error_21908": "請求が失敗しました（エラーコード：21908）。もう一度お試しください。",
    "error_22001": "請求が失敗しました（エラーコード：22001）。もう一度お試しください。",
    "error_22002": "ログインなしでリンク解除に失敗しました。",
    "error_22003": "請求が失敗しました（エラーコード：22003）。もう一度お試しください。",
    "error_22101": "エラー：22101。お問い合わせください。",
    "error_22102": "アカウントの状態が変更されました（エラーコード：22102）。ページを更新して再試行してください。",
    "error_22103": "請求が失敗しました（エラーコード：22103）。もう一度お試しください。",
    "error_22104": "アカウントの状態が変更されました（エラーコード：22104）。ページを更新してもう一度お試しください。",
    "error_22105": "エラー：22105。もう一度お試しください。",
    "error_22106": "エラー：22106。もう一度お試しください。",
    "error_22107": "エラー：22107。お問い合わせください。",
    "error_22108": "請求が失敗しました（エラーコード：22108）。もう一度お試しください。",
    "error_22201": "エラー：22201。お問い合わせください。",
    "error_22202": "無効な検証（エラー：22202）。もう一度お試しください。",
    "error_22203": "エラー：22203。もう一度お試しください。",
    "error_22204": "請求が失敗しました（エラーコード：22204）。もう一度お試しください。",
    "error_22206": "請求が失敗しました（エラーコード：22206）。もう一度お試しください。",
    "error_22401": "エラー：22401。お問い合わせください。",
    "error_22402": "無効な検証（エラー：22402）。もう一度お試しください。",
    "error_22403": "エラー：22403。もう一度お試しください。",
    "error_22404": "請求が失敗しました（エラーコード：22404）。もう一度お試しください。",
    "error_22405": "Facebookアカウントは他のメールにリンクされています。",
    "error_22406": "エラー：22406。もう一度お試しください。",
    "error_22407": "エラー：22407。お問い合わせください。",
    "error_22408": "エラー：22408。お問い合わせください。",
    "error_22409": "エラー：22409。お問い合わせください。",
    "error_22410": "エラー：224010。お問い合わせください。",
    "error_22411": "エラー：224011。お問い合わせください。",
    "error_22412": "エラー：224012。お問い合わせください。",
    "error_22413": "エラー：22413。お問い合わせください。",
    "error_22414": "請求が失敗しました（エラーコード：22414）。もう一度お試しください。",
    "error_22415": "エラー：22415。お問い合わせください。",
    "error_22416": "エラー：22416。お問い合わせください。",
    "error_22417": "エラー：22417。お問い合わせください。",
    "error_22418": "請求が失敗しました（エラーコード：22418）。もう一度お試しください。",
    "error_22419": "エラー：22419。お問い合わせください。",
    "error_22420": "エラー：22420。お問い合わせください。",
    "error_22421": "エラー：22421。お問い合わせください。",
    "error_22422": "請求が失敗しました（エラーコード：22422）。もう一度お試しください。",
    "error_22423": "請求が失敗しました（エラーコード：22423）。もう一度お試しください。",
    "error_22424": "請求が失敗しました（エラーコード：22424）。もう一度お試しください。",
    "error_22425": "請求が失敗しました（エラーコード：22425）。もう一度お試しください。",
    "error_20098": "エラーコード：20098。プライベートモードで閲覧している場合は、通常モードに切り替えて再試行してください。",
    "error_22298": "Googleの請求に失敗しました（エラー：22298）。もう一度お試しください。",
    "error_22498": "Facebookの請求に失敗しました（エラー：22498）。もう一度お試しください。",
    "error_24902": "請求が失敗しました（エラーコード：24902）。もう一度お試しください。",
    "error_24903": "請求が失敗しました（エラーコード：24903）。もう一度お試しください。",
    "error_24904": "請求が失敗しました（エラーコード：24904）。もう一度お試しください。",
    "error_24905": "請求が失敗しました（エラーコード：24905）。もう一度お試しください。",
    "login_title": "MindOnMapにログインします",
    "log_in": "ログインする",
    "no_account": "アカウントがありませんか？",
    "create_it": "作成する",
    "or_log_in_with": "またはログイン",
    "passwordless_login": "パスワード不要のログイン",
    "log_in_done": "ログイン完了",
    "three_rd_account_connect_info": "おめでとう！成功にログインしました。これで、ログイン用のメールアカウントに接続できます。",
    "see_my_account": "アカウントを確認",
    "three_rd_login_merge_account": "サードパーティアカウントのメールアドレスがサインアップされました。このメールアドレスを使って直接にログインしますか？",
    "connect_log_in": "接続してログイン",
    "create_an_account": "アカウントを作成する",
    "back_to_log_in": "ログインに戻る",
    "create_password": "パスワードの作成",
    "create_now": "今すぐ作成",
    "password_login_subtitle": "メールアドレスによるパスワードなしでログイン",
    "account_login": "アカウントログイン",
    "rights": "このアカウントを作成することにより、<a href=\"https://www.mindonmap.com/terms-and-conditions/\">利用規約</a>および<a href=\"https://www.mindonmap.com/privacy-policy/\">プライバシーポリシーに同意したことになります</a>。",
    "passwordless_login_done": "パスワードなしログインしました",
    "passwordless_login_info": "おめでとうございます、パスワードなしログインが正常に完了しました。今後、このアカウントのパスワードを作成し、そのアカウントとパスワードでログインすることができます。<a href=\"/create-password\" style=\"display: initial;\">今すぐ作成</a>。",
    "sign_up": "サインアップ",
    "register_info": "アカウントを作成",
    "reset_now": "今すぐリセット",
    "forgot_password": "パスワードをお忘れですか？",
    "reset_password_subtitle": "アカウントのメールアドレスを使用してパスワードをリセットします。",
    "plan_products": "プランと製品",
    "nick_name": "ユーザー名：",
    "email": "メールアドレス：",
    "my_products": "私の製品",
    "my_orders": "私の注文",
    "unlink": "リンクを解除する",
    "link": "リンク",
    "connected_accounts": "接続されたアカウント",
    "last_name": "名：",
    "first_name": "姓：",
    "Gender": "性別：",
    "Birth": "誕生日：",
    "Month": "月",
    "Year": "年",
    "Country_Region": "国/地域：",
    "Address": "住所：",
    "Save": "保存",
    "Date": "日付け",
    "Male": "男",
    "Female": "女",
    "Unspecified": "詳細不明",
    "Security": "安全",
    "change_password": "パスワードを変更する",
    "change_now": "今すぐ変更",
    "connect_email": "メールを接続する",
    "delete_account": "アカウントを削除する",
    "delete_account_info": "アカウントを削除すると、関連されているプランと注文情報のデータがすべて削除され、復元するのはできません。アカウントを削除してもよろしいでしょうか?",
    "Delete": "削除",
    "Logout": "ログアウト",
    "my_profile": "プロフィール",
    "guides_faqs": "ガイドとFAQ",
    "More": "もっと",
    "guides": "ガイド",
    "register": "登録",
    "hot_faq": "人気FAQ",
    "Contents": "コンテンツ：",
    "contact_us": "お問い合わせ>>",
    "plan": "プラン",
    "unregistered": "未登録",
    "buy_more": "もっと買う",
    "buy_again": "もう一度購入する",
    "buy_now": "今すぐ購入",
    "free_no_limit": "無料＆無制限",
    "expired": "期限切れ",
    "lifetime": "一生",
    "remain": "残る",
    "day_s": "日",
    "error_24801": "リクエストが失敗しました（エラーコード：24801）。もう一度お試しください。",
    "no_app_found": "アプリが見つかりません！<a href=\"/\">更新</a> または<a href=\"https://www.mindonmap.com/\">公式サイトに移動します。</a>",
    "get_more": "もっと見る>>",
    "edit_photo": "写真を編集する",
    "select_photo": "写真を選択する",
    "change_photo": "写真を変更する",
    "cancel": "キャンセル",
    "hide_password": "パスワードを隠す",
    "show_password": "パスワードを表示",
    "zoom_in": "ズームイン",
    "zoom_out": "ズームアウト",
    "rotate": "回転する",
    "horizontal_flip": "水平フリップ",
    "vertical_flip": "垂直フリップ",
    "country": "国",
    "country_1": "国/地域を選択してください",
    "country_2": "オーランド諸島",
    "country_3": "アフガニスタン",
    "country_4": "アルバニア",
    "country_5": "アルジェリア",
    "country_6": "アメリカ領サモア",
    "country_7": "アンドラ",
    "country_8": "アンゴラ",
    "country_9": "アンギラ",
    "country_10": "南極大陸",
    "country_11": "アンティグア・バーブーダ",
    "country_12": "アルゼンチン",
    "country_13": "アルメニア",
    "country_14": "アルバ",
    "country_15": "オーストラリア",
    "country_16": "オーストリア",
    "country_17": "アゼルバイジャン",
    "country_18": "バーレーン",
    "country_19": "バングラデシュ",
    "country_20": "バルバドス",
    "country_21": "ベラルーシ",
    "country_22": "ベルギー",
    "country_23": "ベリーズ",
    "country_24": "ベナン",
    "country_25": "バミューダ",
    "country_26": "ブータン",
    "country_27": "ボリビア",
    "country_28": "ボスニア・ヘルツェゴビナ",
    "country_29": "ボツワナ",
    "country_30": "ブーベ島",
    "country_31": "ブラジル",
    "country_32": "イギリス領インド洋地域",
    "country_33": "イギリス領バージン諸島",
    "country_34": "ブルネイ",
    "country_35": "ブルガリア",
    "country_36": "ブルキナファソ",
    "country_37": "ブルンジ",
    "country_38": "カンボジア",
    "country_39": "カメルーン",
    "country_40": "カナダ",
    "country_41": "カーボベルデ",
    "country_42": "カリブ海オランダ",
    "country_43": "ケイマン諸島",
    "country_44": "中央アフリカ共和国",
    "country_45": "チャド",
    "country_46": "チリ",
    "country_47": "中国",
    "country_48": "クリスマス島",
    "country_49": "ココス（キーリング）諸島",
    "country_50": "コロンビア",
    "country_51": "クック諸島",
    "country_52": "コスタリカ",
    "country_53": "「コートジボワール」",
    "country_54": "クロアチア",
    "country_55": "キューバ",
    "country_56": "キプロス",
    "country_57": "チェコ共和国",
    "country_58": "コンゴ民主共和国",
    "country_59": "デンマーク",
    "country_60": "ジブチ",
    "country_61": "ドミニカ",
    "country_62": "ドミニカ共和国",
    "country_63": "エクアドル",
    "country_64": "エジプト",
    "country_65": "エルサルバドル",
    "country_66": "赤道ギニア",
    "country_67": "エリトリア",
    "country_68": "エストニア",
    "country_69": "エチオピア",
    "country_70": "フォークランド諸島",
    "country_71": "フェロー諸島",
    "country_72": "ミクロネシア連邦",
    "country_73": "フィジー",
    "country_74": "フィンランド",
    "country_75": "フランス",
    "country_76": "フランス領ギアナ",
    "country_77": "フランス領ポリネシア",
    "country_78": "フランス領南方南方領土",
    "country_79": "ガボン",
    "country_80": "ガンビア",
    "country_81": "ジョージア",
    "country_82": "ドイツ",
    "country_83": "ガーナ",
    "country_84": "ジブラルタル",
    "country_85": "イギリス（イギリス;イギリス）",
    "country_86": "ギリシャ",
    "country_87": "グリーンランド",
    "country_88": "グレナダ",
    "country_89": "グアドループ",
    "country_90": "グアム",
    "country_91": "グアテマラ",
    "country_92": "ガーンジー",
    "country_93": "ギニア",
    "country_94": "ギニアビサウ",
    "country_95": "ガイアナ",
    "country_96": "ハイチ",
    "country_97": "ハード島とマクドナルド諸島",
    "country_98": "ホンジュラス",
    "country_99": "香港",
    "country_100": "ハンガリー",
    "country_101": "アイスランド",
    "country_102": "インド",
    "country_103": "インドネシア",
    "country_104": "イラン",
    "country_105": "イラク",
    "country_106": "アイルランド",
    "country_107": "マン島",
    "country_108": "イスラエル",
    "country_109": "イタリア",
    "country_110": "ジャマイカ",
    "country_111": "日本",
    "country_112": "ジャージー",
    "country_113": "ヨルダン",
    "country_114": "カザフスタン",
    "country_115": "ケニア",
    "country_116": "キリバス",
    "country_117": "クウェート",
    "country_118": "キルギスタン",
    "country_119": "ラオス",
    "country_120": "ラトビア",
    "country_121": "レバノン",
    "country_122": "レソト",
    "country_123": "リベリア",
    "country_124": "リビア",
    "country_125": "リヒテンシュタイン",
    "country_126": "リトアニア",
    "country_127": "ルクセンブルク",
    "country_128": "マカオ",
    "country_129": "マダガスカル",
    "country_130": "マラウイ",
    "country_131": "マレーシア",
    "country_132": "モルディブ",
    "country_133": "マリ",
    "country_134": "マルタ",
    "country_135": "マーシャル諸島",
    "country_136": "マルティニーク",
    "country_137": "モーリタニア",
    "country_138": "モーリシャス",
    "country_139": "マヨット",
    "country_140": "メキシコ",
    "country_141": "モルドバ",
    "country_142": "モナコ",
    "country_143": "モンゴル",
    "country_144": "モンテネグロ",
    "country_145": "モントセラト",
    "country_146": "モロッコ",
    "country_147": "モザンビーク",
    "country_148": "ミャンマー（ビルマ）",
    "country_149": "ナミビア",
    "country_150": "ナウル",
    "country_151": "ネパール",
    "country_152": "オランダ",
    "country_153": "ニューカレドニア",
    "country_154": "ニュージーランド",
    "country_155": "ニカラグア",
    "country_156": "ニジェール",
    "country_157": "ナイジェリア",
    "country_158": "ニウエ",
    "country_159": "ノーフォーク島",
    "country_160": "北朝鮮",
    "country_161": "北マリアナ諸島",
    "country_162": "ノルウェー",
    "country_163": "オマーン",
    "country_164": "パキスタン",
    "country_165": "パラオ",
    "country_166": "パレスチナ領土",
    "country_167": "パナマ",
    "country_168": "パプアニューギニア",
    "country_169": "パラグアイ",
    "country_170": "ペルー",
    "country_171": "ピトケアン諸島",
    "country_172": "ポーランド",
    "country_173": "ポルトガル",
    "country_174": "プエルトリコ",
    "country_175": "カタール",
    "country_176": "マケドニア共和国（FYROM）",
    "country_177": "コンゴ共和国",
    "country_178": "再会",
    "country_179": "ルーマニア",
    "country_180": "ロシア連邦",
    "country_181": "ルワンダ",
    "country_182": "サンバルテルミー",
    "country_183": "サンマルタン（フランス）",
    "country_184": "サンピエール島とミクロン島",
    "country_185": "サモア",
    "country_186": "サンマリノ",
    "country_187": "サントメ・プリンシペ",
    "country_188": "サウジアラビア",
    "country_189": "セネガル",
    "country_190": "セルビア",
    "country_191": "セイシェル",
    "country_192": "シエラレオネ",
    "country_193": "シンガポール",
    "country_194": "スロバキア",
    "country_195": "スロベニア",
    "country_196": "ソロモン諸島",
    "country_197": "ソマリア",
    "country_198": "南アフリカ",
    "country_199": "サウスジョージア島とサウスサンドイッチ島",
    "country_200": "韓国",
    "country_201": "南スーダン",
    "country_202": "スペイン",
    "country_203": "スリランカ",
    "country_204": "セントヘレナと依存関係",
    "country_205": "セントクリストファーネイビス",
    "country_206": "セントルシア",
    "country_207": "セントビンセントおよびグレナディーン諸島",
    "country_208": "スーダン",
    "country_209": "スリナム",
    "country_210": "スワジランド",
    "country_211": "スウェーデン",
    "country_212": "スイス",
    "country_213": "シリア",
    "country_214": "台湾",
    "country_215": "タジキスタン",
    "country_216": "タンザニア",
    "country_217": "テンプレート：国データSJMスバールバル",
    "country_218": "タイ",
    "country_219": "バハマ",
    "country_220": "コモロ",
    "country_221": "フィリピン",
    "country_222": "東ティモール（東ティモール）",
    "country_223": "トーゴ",
    "country_224": "トケラウ",
    "country_225": "トンガ",
    "country_226": "トリニダード・トバゴ",
    "country_227": "チュニジア",
    "country_228": "七面鳥",
    "country_229": "トルクメニスタン",
    "country_230": "タークス・カイコス諸島",
    "country_231": "ツバル",
    "country_232": "ウガンダ",
    "country_233": "ウクライナ",
    "country_234": "アラブ首長国連邦",
    "country_235": "合衆国領有小島",
    "country_236": "アメリカ合衆国（USA）",
    "country_237": "アメリカ領バージン諸島",
    "country_238": "ウルグアイ",
    "country_239": "ウズベキスタン",
    "country_240": "バヌアツ",
    "country_241": "バチカン市国（聖座）",
    "country_242": "ベネズエラ",
    "country_243": "ベトナム",
    "country_244": "ウォリス・フツナ",
    "country_245": "西サハラ",
    "country_246": "イエメン",
    "country_247": "ザンビア",
    "country_248": "ジンバブエ",
    "google_login": "Googleでサインイン",
    "State": "状態",
    "Activation_code": "アクティベーションコード",
    "Question": "ログインしたすべてのアプリを一覧表示",
    "Copy_complete": "コピー済み",
    "footer": "Copyright © 2024 MindOnMap Studio. All rights reserved.",
    "change_password_success": "パスワードは正常に変更されました。",
    "successful_login_title": "ログイン成功",
    "product_page": "製品ページ>>",
    "successful_login_info": "ログインが完了しました。現在のページを閉じ、元のタブに戻って次の処理を行ってください。現在のページは5秒後に自動的に閉じられます。自動終了や「完了」ボタンによる終了に失敗した場合は、このタブを直接閉じてください。",
    "successful_login_info_firefox": "ログインが完了しました。現在のページを閉じ、元のタブに戻って次の処理を行ってください。",
    "my_account": "アカウント",
    "my_history": "歴史",
    "remove_watermark": "ウォーターマークを消去する",
    "no_history": "履歴がありません",
    "history_all": "すべて選択",
    "history_open": "開ける",
    "history_down": "ダウンロード",
    "history_delete": "消去",
    "history_clear": "無効をクリア",
    "images": "画像",
    "use_this_function": "この製品を使用する>>",
    "hd_downloading": "HDオリジナル画像のダウンロードの特典：",
    "lifetimeRemaining": "永久-残り",
    "Remaining": "残り",
    "email_verification": "メール認証",
    "email_verification_info": "確認メッセージをメール<spanclass=\"email\"></span>に送信しましたので、確認を完了してください。確認後、特典は自動的に同期されます。",
    "wrong_email": "間違ったメールアドレス？",
    "click_here_to_modify": "変更するには、ここをクリックしてください。",
    "get_more_help": "さらにヘルプが必要ですか？",
    "click_here": "ここをクリック",
    "email_verification_info_success": "メールアカウントを確認していただき、ありがとうございます。",
    "email_verification_info_error": "リンクの有効期限が切れているため、検証に失敗しました。",
    "registration_succeeded": "登録に成功しました。",
    "registration_succeeded_info_1": "おめでとう！正常に登録されています。確認メッセージをメール<span class=\"email\"></span>に送信しました。このメールアドレスの特典を利用するには、確認を完了してください。",
    "registration_succeeded_info_2": "「完了」をクリックしてホームページに戻り、この製品を使用してください。",
    "registration_succeeded_info_3": "「完了」をクリックして現在のページを閉じ、ホームページに戻って次の操作を行います。閉じられない場合は、手動でタブを閉じてください。",
    "verify_email": "メールアドレスを確認する",
    "registered_email_not_verified": "登録されたメールアドレスは確認されていません。すぐに確認してください。",
    "email_verification_time_1": "確認メールが届きませんでしたか？",
    "email_verification_time_2": "後",
    "email_verification_time_3": "ここをクリックして再送してください。",
    "error_26301": "エラーコード：26301。お問い合わせください。",
    "error_26302": "エラーコード：26302。お問い合わせください。",
    "error_26303": "メールフォーマットエラー（エラーコード：26303）。もう一度入力してください。",
    "error_26304": "8文字を超えるパスワードをお勧めします（エラーコード：26304）",
    "error_26305": "請求が失敗しました（エラーコード：26305）。再試行してください。",
    "error_26306": "メールアドレスが登録されました。<a href=\"https://account.mindonmap.com/login/\">ログイン</a>してください。",
    "error_26307": "請求が失敗しました（エラーコード：26307）。再試行してください。",
    "error_26308": "請求が失敗しました（エラーコード：26308）。再試行してください。",
    "error_26401": "エラーコード：26401。再試行してください。",
    "error_26402": "メールが確認されました（エラーコード：26402）。再試行してください。",
    "error_26403": "請求が失敗しました（エラーコード：26403）。再試行してください。",
    "error_26404": "請求が失敗しました（エラーコード：26404）。再試行してください。",
    "error_26501": "エラーコード：26501。お問い合わせください。",
    "error_26502": "エラーコード：26502。お問い合わせください。",
    "error_26503": "メールフォーマットエラー（エラーコード：26503）。もう一度入力してください。",
    "error_26504": "請求が失敗しました（エラーコード：26504）。再試行してください。",
    "error_26505": "メールはまだ登録されていません。 <a href=\"https://account.mindonmap.com/register/\">最初に登録</a>してください",
    "error_26506": "メールが確認されました。",
    "error_26507": "請求が失敗しました（エラーコード：26507）。再試行してください。",
    "error_26508": "検証が失敗しました（エラーコード：26508）。再試行してください。",
    "error_26509": "請求が失敗しました（エラーコード：26509）。再試行してください。",
    "error_26510": "エラーコード：26510。お問い合わせください。",
    "error_26601": "エラーコード：26601。お問い合わせください。",
    "error_26602": "エラーコード：26602。お問い合わせください。",
    "error_26603": "請求が失敗しました（エラーコード：26603）。再試行してください。",
    "error_26604": "エラーコード：26604。お問い合わせください。",
    "error_26605": "エラーコード：26605。お問い合わせください。",
    "error_26701": "エラーコード：26701。お問い合わせください。",
    "error_26702": "請求が失敗しました（エラーコード：26702）。再試行してください。",
    "error_26703": "エラーコード：26703。お問い合わせください。",
    "error_26704": "エラーコード：26704。お問い合わせください。",
    "error_26705": "ログインをお待ちください（エラーコード: 26705）。再試行してください。",
    "no_cookie": "ブラウザで「すべてのCookieをブロックする」機能をオンにしているため、ログインできません。「設定」に移動して、「すべてのCookieを許可する」チェックボックスをオンにしてください。",
    "error_26801": "エラーコード：26801。お問い合わせください。",
    "error_26802": "エラーコード：26802。お問い合わせください。",
    "error_26803": "請求が失敗しました（エラーコード：26803）。再試行してください。",
    "error_26804": "請求が失敗しました（エラーコード：26804）。再試行してください。",
    "error_order": "請求が失敗しました（エラーコード：27098）。もう一度やり直してください。",
    "error_order1": "注文クエリが不完全です（エラーコード：",
    "error_order2": "）、更新してもう一度お試しください。",
    "modify_email_title": "メールを変更する",
    "modify_email_info": "変更したメールを使用して、アカウントにログインできます。",
    "images_per": "画像",
    "error_26101": "エラー：26101。お問い合わせください。",
    "error_26102": "エラー：26102。お問い合わせください。",
    "error_26103": "請求が失敗しました（エラーコード：26103）。もう一度お試しください。",
    "error_26104": "エラーコード：26104。もう一度やり直してください。",
    "error_26105": "エラーコード：26105。もう一度やり直してください。",
    "error_26106": "削除に失敗しました（エラーコード：26106）。もう一度お試しください。",
    "error_26201": "エラー：26201。お問い合わせください。",
    "error_26202": "請求が失敗しました（エラーコード：26202）。もう一度お試しください。",
    "error_26001": "エラー：26001。お問い合わせください。",
    "error_26002": "エラー：26002。お問い合わせください。",
    "error_26003": "エラー：26003。お問い合わせください。",
    "error_26004": "エラー：26004。お問い合わせください。",
    "error_26005": "請求が失敗しました（エラーコード：26005）。もう一度お試しください。",
    "error_26006": "エラーコード：26006。もう一度やり直してください。",
    "error_26008": "エラー：26008。お問い合わせください。",
    "go_to_the_home_page": "ホームページに移動します。",
    "error_27101": "請求が失敗しました（エラーコード：27101）。もう一度お試しください。",
    "error_27201": "エラーコード：27201。お問い合わせください。",
    "error_27202": "エラーコード：27202。再試行してください。",
    "error_27203": "請求が失敗しました（エラーコード：27203）。再試行してください。",
    "error_27204": "無効なコード（エラーコード：27204）。",
    "error_27205": "請求が失敗しました（エラーコード：27205）。再試行してください。",
    "error_27206": "請求が失敗しました（エラーコード：27206）。再試行してください。",
    "error_27207": "請求が失敗しました（エラーコード：27207）。再試行してください。",
    "no_history_found": "どんなツールを使っていません!<a href=\"/\">更新</a> または <a href=\"https://www.mindonmap.com/\">公式ウェブサイトに移動</a>します。",
    "error_25301": "エラー：25301。お問い合わせください。",
    "error_25302": "エラー：25302。お問い合わせください。",
    "error_25303": "請求が失敗しました（エラーコード：25303）。もう一度お試しください。",
    "error_25304": "請求が失敗しました（エラーコード：25304）。もう一度お試しください。",
    "error_25305": "請求が失敗しました（エラーコード：25305）。もう一度お試しください。",
    "error_25306": "請求が失敗しました（エラーコード：25306）。もう一度お試しください。",
    "image_upscaler_p": "透かしなし画像ダウンロードの特典:",
    "Available_for": "できることは下記の通り：",
    "credit_per": "HD画像を1枚保存するには%sクレジットずつかかります。",
    "still_valid": "購入したプランは依然として有効である",
    "credit": "クレジット",
    "pc_3rd_info": "登録済み。ソフト内で継続使用してください。",
    "use_online": "オンラインサービスを利用します。",
    "use_download": "デスクトップアプリケーションを利用します。",
    "use_immediately": "今すぐ利用",
    "Use_in_browser": "ブラウザで利用",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%}クレジット/月",
    "expire": "有効期限",
    "viewDetails": "詳細を見る",
    "viewHistory": "特典履歴を見る>>",
    "viewDetailsInfo": "説明：有効期間満了後7日以内に更新された場合、未使用の特典は引き続きご利用いただけます。また、有効期限は自動的に新しいサブスクリプションの有効期限に更新されます。有効期限後7日を経過しても新規のご契約がない場合、失効した特典はすべて消去されます。",
    "connect_account": "アカウントにメールアドレスをバインドする",
    "connect_account_info": "バインド後、このメールアドレスでログインできます。",
    "connect_now": "今すぐバインド",
    "no_email_bind": "メールアドレスをバインドしない",
    "bind_email": "メールアドレスをバインドする",
    "connect_your_email_placeholder": "メールアドレスを入力してください。",
    "bind_an_email": "メールアドレスをバインド",
    "bind_info": "ログインに成功しました。特典を有効にするには、アカウントにメールアドレスをバインドしてください。",
    "bind_later": "後でバインド",
    "hi": "こんにちは、%s!",
    "Personal_Information": "個人情報",
    "Access": "アクセス",
    "Subscription_Plan": "（サブスクリプションプラン）",
    "No_orders": "注文が見つかりません。",
    "No_data": "データなし",
    "Featured_Products": "おすすめ商品",
    "More_Products": "その他の製品",
    "Free_Download": "無料ダウンロード",
    "Get_Started": "開始",
    "Subscribe": "サブスクリプション",
    "Verified": "検証済み",
    "back_to_account_center": "アカウントセンターに戻る",
    "success": "成功！",
    "successfully": "アカウントの登録が完了しました。",
    "Continue": "続く",
    "Already": "すでにアカウントをお持ちですか？",
    "loading_verification": "検証ステータスを確認中…",
    "email_no_verification": "申し訳ございませんが、登録されたメールアドレスが認証されていません。上記の指示に従って認証を完了し、再度「完了」ボタンをクリックして登録を完了してください。",
    "will_expire_after": "有効期限は",
    "hours": "時間",
  },
  "ka": {
    "overtime": "შეცდომის კოდი: {%}, გთხოვთ, ხელახლა სცადოთ",
    "isnetwork": "ინტერნეტის შეცდომა. გთხოვთ, შეამოწმოთ და ხელახლა სცადოთ",
    "email_placeholder": "ფოსტა",
    "email_empty": "გთხოვთ შეიყვანოთ ელფოსტა",
    "email_not_valid": "ელფოსტა არასწორია",
    "email_not_valid_1": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა",
    "email_not_valid_2": "ელფოსტა არასწორია, გთხოვთ გამოიყენოთ სხვა მისამართი.",
    "email_not_valid_3": "ელ.ფოსტის შეყვანა არ არის",
    "password_placeholder": "პაროლი",
    "password_empty": "გთხოვთ შეიყვანოთ პაროლი",
    "password_not_valid": "არასწორი ანგარიში ან პაროლი",
    "password_not_valid_1": "საჭიროა 8 სიმბოლოზე მეტი პაროლი",
    "password_not_valid_2": "გთხოვთ, შექმენით პაროლი",
    "password_placeholder_1": "შექმენი პაროლი",
    "password_placeholder_2": "Დაადასტურეთ თქვენი პაროლი",
    "password_placeholder_3": "შექმენით ახალი პაროლი",
    "password_placeholder_4": "Დაადასტურეთ ახალი პაროლი",
    "password_placeholder_5": "შეიყვანეთ ძველი პაროლი",
    "copy_password_empty": "გთხოვთ დაადასტუროთ პაროლი",
    "copy_password_not_valid": "გთხოვთ დაადასტუროთ თქვენი პაროლი",
    "copy_passwords_inconsistent": "თქვენი პაროლის დადასტურება არ ემთხვევა",
    "code_empty": "გთხოვთ, შეიყვანოთ დამადასტუ",
    "code_not_valid": "არასწორი კოდი",
    "code_placeholder": "Დამადასტურებელი კოდი",
    "not_received_code": "თუ თქვენს საფოსტო ყუთს დიდი ხანია არ მიუღია დამადასტურებელი კოდი, გთხოვთ, კვლავ მიიღოთ დამადასტურებელი კოდი.",
    "get_first_code": "გთხოვთ, ჯერ მიიღეთ დამადასტურებელი კოდი.",
    "last_name_placeholder": "გთხოვთ შეიყვანოთ თქვენი გვარი",
    "first_name_placeholder": "გთხოვთ შეიყვანოთ თქვენი სახელი",
    "address_placeholder": "გთხოვთ შეიყვანოთ თქვენი მისამართი",
    "no_code_text": "ჩვენ გამოგიგზავნეთ დამადასტურებელი კოდი. გთხოვთ შეიყვანოთ თქვენი კოდი. <span class='tips'>კოდი არ მიგიღიათ?",
    "no_code_text_1": "1. გთხოვთ, დარწმუნდეთ, რომ ელ.ფოსტის მისამართი სწორია და მას შეუძლია ელფოსტის მიღება.",
    "no_code_text_2": "2. იმის გამო, რომ ელფოსტა იგზავნება სისტემის მიერ ავტომატურად, ის შეიძლება იყოს მონიშნული, როგორც სპამი ან უსარგებლო ელფოსტა. გთხოვთ, შეამოწმოთ არის თუ არა ელფოსტა ნაგვის საქაღალდეში.",
    "no_code_text_3": "3. ვერ გადაჭრით თქვენს პრობლემას? ",
    "no_code_text_3_span": "შემდეგ დააწკაპუნეთ აქ დაგვიკავშირდით.",
    "order_no": "თქვენ არ გიყიდიათ რაიმე პროდუქტი, თუ თქვენ გაქვთ რაიმე შეკითხვა, გთხოვთ <a href=\"https://www.mindonmap.com/contact-us/\">დაგვიკავშირდეთ</a>.",
    "error_24901": "მიმდინარე ანგარიშს არ აქვს მიბმული ელფოსტა და ვერ პოულობს შეკვეთებს. გთხოვთ, დააკავშიროთ ელ.წერილი.",
    "user_guide": "მომხმარებლის სახელმძღვანელო >>",
    "download": "ჩამოტვირთვა",
    "order_number": "Შეკვეთის ნომერი:",
    "Refund": "თანხის დაბრუნება",
    "Disabled": "გამორთულია",
    "Normal": "ნორმალური",
    "Modify": "მოდიფიცირება",
    "Modify_1": "შეცვლა >>",
    "Connect": "დაკავშირება",
    "unlink_success": "წარმატებით გაუქმდა კავშირი",
    "connect_success": "დაკავშირება წარმატებით",
    "feedback_title": "გმადლობთ თქვენი გამოხმაურებისთვის. გთხოვთ, დატოვეთ თქვენი პრობლემა და ჩვენ გიპასუხებთ 24 საათის განმავლობაში.",
    "feedback_thank_you": "გმადლობთ!<br />თქვენი გამოხმაურება წარმატებით იქნა გაგზავნილი.",
    "feedback_email": "შეიყვანეთ თქვენი ელფოსტა აქ!",
    "feedback_content": "აქ დატოვეთ ნებისმიერი პრობლემა ან წინადადება, რომელიც შეგხვდათ.",
    "feedback_submit": "გაგზავნა",
    "form_contents": "თქვენ არ შეიტანეთ რაიმე აღწერა. გთხოვთ შეიყვანოთ და ხელახლა გაგზავნოთ.",
    "old_password": "გთხოვთ შეიყვანოთ ძველი პაროლი",
    "new_password": "გთხოვთ, შექმენით ახალი პაროლი",
    "old_new_password": "ახალი პაროლი არ შეიძლება იყოს იგივე, რაც ძველი",
    "incorrect_password": "არასწორი პაროლი",
    "delete_no": "წაშალე ახლა",
    "Caps": "Caps lock ჩართულია",
    "Get": "მიიღეთ",
    "Done": "შესრულებულია",
    "error_20001": "შეცდომა: 20001. გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20002": "შეცდომა: 20002. გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20003": "შეცდომა: 20003. გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20004": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20004). გთხოვთ, ისევ სცადოთ.",
    "error_20005": "შესვლის სესიამ ამოიწურა (შეცდომა: 20005). გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20006": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20006). გთხოვთ, ისევ სცადოთ.",
    "error_20007": "შესვლის სესიას ამოიწურა (შეცდომა: 20007). გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20008": "შესვლის სესიას ამოიწურა (შეცდომა: 20008). გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20009": "შესვლის სესიას ამოიწურა (შეცდომა: 20009). გთხოვთ შეხვიდეთ ხელახლა.",
    "error_20101": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20101)",
    "error_20102": "ელფოსტა არასწორია (შეცდომის კოდი: 20102)",
    "error_20103": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20103). გთხოვთ, ისევ სცადოთ",
    "error_20104": "ელფოსტა უკვე გამოიყენება, გთხოვთ <a href=\"https://account.mindonmap.com/login/\">შეხვიდეთ</a> ან დარეგისტრირდეთ ახლით",
    "error_20105": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20105). გთხოვთ, ისევ სცადოთ",
    "error_20106": "ელფოსტის გაგზავნა ვერ მოხერხდა, გთხოვთ, ხელახლა სცადოთ",
    "error_20201": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20201)",
    "error_20202": "გთხოვთ, შეიყვანოთ თქვენი პაროლი (შეცდომის კოდი: 20202)",
    "error_20203": "გთხოვთ, შეიყვანოთ დამადასტურებელი კოდი (შეცდომის კოდი: 20203)",
    "error_20204": "ელფოსტა არასწორია (შეცდომის კოდი: 20204)",
    "error_20205": "საჭიროა 8 სიმბოლოზე მეტი პაროლი (შეცდომის კოდი: 20205)",
    "error_20206": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20206). გთხოვთ, ისევ სცადოთ",
    "error_20207": "არასწორი კოდი",
    "error_20208": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20208). გთხოვთ, ისევ სცადოთ",
    "error_20209": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20209). გთხოვთ, ისევ სცადოთ",
    "error_20301": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20301)",
    "error_20302": "შეცდომა: 20302. გთხოვთ დაგვიკავშირდეთ",
    "error_20303": "ელფოსტა არასწორია (შეცდომის კოდი: 20303)",
    "error_20304": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20304). გთხოვთ, ისევ სცადოთ",
    "error_20305": "ანგარიში არ არსებობს. გთხოვთ, ხელახლა შეიყვანოთ ან <a href=\"https://account.mindonmap.com/register/\">შექმენით</a> ჯერ.",
    "error_20306": "პაროლი ჯერ არ არის. გამოიყენეთ <a href=\"https://account.mindonmap.com/passwordless-login/\">პასუხო შესვლა</a> ან <a href=\"https://account.mindonmap.com/create-password/\"> დააყენეთ პაროლი</a> და შედით სისტემაში.",
    "error_20308": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20308). გთხოვთ, ისევ სცადოთ",
    "error_20401": "გამოსვლა ვერ მოხერხდა (შეცდომის კოდი: 20401). გთხოვთ, ისევ სცადოთ",
    "error_20501": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20501)",
    "error_20502": "ელფოსტა არასწორია (შეცდომის კოდი: 20502)",
    "error_20503": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20503). გთხოვთ, ისევ სცადოთ",
    "error_20504": "ელფოსტის გაგზავნა ვერ მოხერხდა. გთხოვთ, ისევ სცადოთ.",
    "error_20601": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20601)",
    "error_20602": "გთხოვთ, შეიყვანოთ დამადასტურებელი კოდი (შეცდომის კოდი: 20602)",
    "error_20603": "ელფოსტა არასწორია (შეცდომის კოდი: 20603)",
    "error_20604": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20604). გთხოვთ, ისევ სცადოთ",
    "error_20606": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20606). გთხოვთ, ისევ სცადოთ",
    "error_20607": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20607). გთხოვთ, ისევ სცადოთ",
    "error_20608": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20608). გთხოვთ, ისევ სცადოთ",
    "error_20701": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20701)",
    "error_20702": "ელფოსტა არასწორია (შეცდომის კოდი: 20702)",
    "error_20703": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20703). გთხოვთ, ისევ სცადოთ",
    "error_20704": "ანგარიში არ არსებობს. გთხოვთ, ხელახლა შეიყვანოთ ან <a href=\"https://account.mindonmap.com/register/\">შექმენით</a> ჯერ.",
    "error_20705": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20705). გთხოვთ, ისევ სცადოთ",
    "error_20706": "ელფოსტის გაგზავნა ვერ მოხერხდა. გთხოვთ, ისევ სცადოთ",
    "error_20801": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 20801)",
    "error_20802": "შეცდომა: 20802. გთხოვთ დაგვიკავშირდეთ",
    "error_20803": "გთხოვთ, შეიყვანოთ დამადასტურებელი კოდი (შეცდომის კოდი: 20803)",
    "error_20804": "ელფოსტა არასწორია (შეცდომის კოდი: 20804)",
    "error_20805": "საჭიროა 8 სიმბოლოზე მეტი პაროლი (შეცდომის კოდი: 20805)",
    "error_20806": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20806). გთხოვთ, ისევ სცადოთ",
    "error_20808": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20808). გთხოვთ, ისევ სცადოთ",
    "error_20901": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20901). გთხოვთ, ისევ სცადოთ",
    "error_20902": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 20902). გთხოვთ, ისევ სცადოთ",
    "error_21000": "ცვლილებები შენახულია",
    "error_21001": "ინფორმაცია არ არის წარმოდგენილი (შეცდომის კოდი: 21001)",
    "error_21002": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21002). გთხოვთ, ისევ სცადოთ",
    "error_21101": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 21101)",
    "error_21102": "ელფოსტა არასწორია (შეცდომის კოდი: 21102)",
    "error_21103": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21103), გთხოვთ, ხელახლა სცადოთ",
    "error_21104": "ელფოსტა უკვე დაკავშირებულია, გთხოვთ, გამოიყენოთ ახალი",
    "error_21105": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21105), გთხოვთ, ხელახლა სცადოთ",
    "error_21106": "ელფოსტის გაგზავნა ვერ მოხერხდა. გთხოვთ, ისევ სცადოთ",
    "error_21201": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა (შეცდომის კოდი: 21201)",
    "error_21202": "გთხოვთ შეიყვანოთ დამადასტურებელი კოდი (შეცდომის კოდი: 21202)",
    "error_21203": "ელფოსტა არასწორია (შეცდომის კოდი: 21203)",
    "error_21204": "შეცდომა: 21204. გთხოვთ დაგვიკავშირდეთ",
    "error_21205": "შეცდომა: 21205. გთხოვთ დაგვიკავშირდეთ",
    "error_21206": "საჭიროა 8 სიმბოლოზე მეტი პაროლი (შეცდომის კოდი: 21206)",
    "error_21207": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21207). გთხოვთ, ისევ სცადოთ",
    "error_21209": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21209). გთხოვთ, ისევ სცადოთ",
    "error_21301": "გთხოვთ შეიყვანოთ ძველი პაროლი (შეცდომის კოდი: 21301)",
    "error_21302": "გთხოვთ, შექმენით ახალი პაროლი (შეცდომის კოდი: 21302)",
    "error_21303": "ახალი პაროლი არ შეიძლება იყოს იგივე, რაც ძველი. (შეცდომა: 21303)",
    "error_21304": "საჭიროა 8 სიმბოლოზე მეტი პაროლი (შეცდომის კოდი: 21304)",
    "error_21306": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21306). გთხოვთ, ისევ სცადოთ",
    "error_21402": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21402). გთხოვთ, ისევ სცადოთ",
    "error_21403": "დამადასტურებელი კოდის გაგზავნა ვერ მოხერხდა. გთხოვთ ხელახლა გაგზავნოთ",
    "error_21500": "ანგარიში წაიშალა",
    "error_21501": "გთხოვთ, შეიყვანოთ დამადასტურებელი კოდი (შეცდომის კოდი: 21501)",
    "error_21502": "შესვლის სესიას ამოიწურა (შეცდომა: 21502). გთხოვთ შეხვიდეთ ხელახლა.",
    "error_21503": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21503). გთხოვთ, ისევ სცადოთ",
    "error_21505": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21505), გთხოვთ, ხელახლა სცადოთ",
    "error_21601": "შეცდომა: 20601. გთხოვთ დაგვიკავშირდეთ",
    "error_21602": "არასწორი დადასტურება (შეცდომა: 20602). გთხოვთ, ისევ სცადოთ.",
    "error_21603": "შეცდომა: 20603. გთხოვთ, სცადოთ ხელახლა",
    "error_21604": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21604). გთხოვთ, ისევ სცადოთ",
    "error_21606": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21606). გთხოვთ, ისევ სცადოთ",
    "error_21611": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21611). გთხოვთ, ისევ სცადოთ",
    "error_21801": "შეცდომა: 21801. გთხოვთ დაგვიკავშირდეთ",
    "error_21802": "მოთხოვნა ვერ მოხერხდა (შეცდომა: 21802). გთხოვთ, ისევ სცადოთ",
    "error_21803": "შეცდომა: 21803. გთხოვთ, სცადოთ ხელახლა",
    "error_21804": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21804). გთხოვთ, ისევ სცადოთ",
    "error_21806": "შეცდომა: 21806. გთხოვთ, სცადოთ ხელახლა",
    "error_21807": "შეცდომა: 21807. გთხოვთ დაგვიკავშირდეთ",
    "error_21808": "შეცდომა: 21808. გთხოვთ დაგვიკავშირდეთ",
    "error_21809": "შეცდომა: 21809. გთხოვთ დაგვიკავშირდეთ",
    "error_21810": "შეცდომა: 21810. გთხოვთ დაგვიკავშირდეთ",
    "error_21811": "შეცდომა: 21811. გთხოვთ დაგვიკავშირდეთ",
    "error_21812": "შეცდომა: 21812. გთხოვთ დაგვიკავშირდეთ",
    "error_21813": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21813). გთხოვთ, ისევ სცადოთ",
    "error_21814": "შეცდომა: 21814. გთხოვთ დაგვიკავშირდეთ",
    "error_21815": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21815). გთხოვთ, ისევ სცადოთ",
    "error_21816": "შეცდომა: 21816. გთხოვთ დაგვიკავშირდეთ",
    "error_21817": "შეცდომა: 21817. გთხოვთ დაგვიკავშირდეთ",
    "error_21818": "შეცდომა: 21818. გთხოვთ დაგვიკავშირდეთ",
    "error_21819": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21819). გთხოვთ, ისევ სცადოთ",
    "error_21820": "შეცდომა: 21820. გთხოვთ დაგვიკავშირდეთ",
    "error_21821": "შეცდომა: 21821. გთხოვთ დაგვიკავშირდეთ",
    "error_21822": "შეცდომა: 21822. გთხოვთ დაგვიკავშირდეთ",
    "error_21823": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21823). გთხოვთ, ისევ სცადოთ",
    "error_21824": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21824). გთხოვთ, ისევ სცადოთ",
    "error_21825": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21825). გთხოვთ, ისევ სცადოთ",
    "error_21826": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21826). გთხოვთ, ისევ სცადოთ",
    "error_21901": "შეცდომა: 21901. გთხოვთ დაგვიკავშირდეთ",
    "error_21902": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21902). გთხოვთ, ისევ სცადოთ",
    "error_21903": "ანგარიშის სტატუსი შეიცვალა (შეცდომის კოდი: 21903), გთხოვთ, განაახლოთ გვერდი და სცადოთ ხელახლა",
    "error_21904": "შეცდომა: 21904. გთხოვთ, სცადოთ ხელახლა",
    "error_21905": "შეცდომა: 21905. გთხოვთ, სცადოთ ხელახლა",
    "error_21906": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21906). გთხოვთ, ისევ სცადოთ",
    "error_21907": "Google ანგარიში მიბმულია სხვა ანგარიშთან",
    "error_21908": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 21908). გთხოვთ, ისევ სცადოთ",
    "error_22001": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22001). გთხოვთ, ისევ სცადოთ",
    "error_22002": "კავშირის გაუქმება ვერ მოხერხდა დამატებითი შესვლის გარეშე",
    "error_22003": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22003). გთხოვთ, ისევ სცადოთ",
    "error_22101": "შეცდომა: 22101. გთხოვთ დაგვიკავშირდეთ",
    "error_22102": "ანგარიშის სტატუსი შეიცვალა (შეცდომის კოდი: 22102), გთხოვთ, განაახლოთ გვერდი და სცადოთ ხელახლა",
    "error_22103": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22103). გთხოვთ, ისევ სცადოთ",
    "error_22104": "ანგარიშის სტატუსი შეიცვალა (შეცდომის კოდი: 22104), გთხოვთ, განაახლოთ გვერდი და სცადოთ ხელახლა",
    "error_22105": "შეცდომა: 22105. გთხოვთ, სცადოთ ხელახლა",
    "error_22106": "შეცდომა: 22106. გთხოვთ, სცადოთ ხელახლა",
    "error_22107": "შეცდომა: 22107. გთხოვთ დაგვიკავშირდეთ",
    "error_22108": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22108). გთხოვთ, ისევ სცადოთ",
    "error_22201": "შეცდომა: 22201. გთხოვთ დაგვიკავშირდეთ",
    "error_22202": "არასწორი დადასტურება (შეცდომა: 22202). გთხოვთ, ისევ სცადოთ.",
    "error_22203": "შეცდომა: 22203. გთხოვთ სცადოთ ხელახლა\"",
    "error_22204": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22204). გთხოვთ, ისევ სცადოთ",
    "error_22206": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22206). გთხოვთ, ისევ სცადოთ",
    "error_22401": "შეცდომა: 22401. გთხოვთ დაგვიკავშირდეთ",
    "error_22402": "არასწორი დადასტურება (შეცდომა: 22402). გთხოვთ, ისევ სცადოთ.",
    "error_22403": "შეცდომა: 22403. გთხოვთ, სცადოთ ხელახლა",
    "error_22404": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22404). გთხოვთ, ისევ სცადოთ",
    "error_22405": "Facebook-ის ანგარიში მიბმულია სხვა ელფოსტასთან",
    "error_22406": "შეცდომა: 22406. გთხოვთ, სცადოთ ხელახლა",
    "error_22407": "შეცდომა: 22407. გთხოვთ დაგვიკავშირდეთ",
    "error_22408": "შეცდომა: 22408. გთხოვთ დაგვიკავშირდეთ",
    "error_22409": "შეცდომა: 22409. გთხოვთ დაგვიკავშირდეთ",
    "error_22410": "შეცდომა: 224010. გთხოვთ დაგვიკავშირდეთ",
    "error_22411": "შეცდომა: 224011. გთხოვთ დაგვიკავშირდეთ",
    "error_22412": "შეცდომა: 224012. გთხოვთ დაგვიკავშირდეთ",
    "error_22413": "შეცდომა: 22413. გთხოვთ დაგვიკავშირდეთ",
    "error_22414": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22414). გთხოვთ, ისევ სცადოთ",
    "error_22415": "შეცდომა: 22415. გთხოვთ დაგვიკავშირდეთ",
    "error_22416": "შეცდომა: 22416. გთხოვთ დაგვიკავშირდეთ",
    "error_22417": "შეცდომა: 22417. გთხოვთ დაგვიკავშირდეთ",
    "error_22418": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22418). გთხოვთ, ისევ სცადოთ",
    "error_22419": "შეცდომა: 22419. გთხოვთ დაგვიკავშირდეთ",
    "error_22420": "შეცდომა: 22420. გთხოვთ დაგვიკავშირდეთ",
    "error_22421": "შეცდომა: 22421. გთხოვთ დაგვიკავშირდეთ",
    "error_22422": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22422). გთხოვთ, ისევ სცადოთ",
    "error_22423": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22423). გთხოვთ, ისევ სცადოთ",
    "error_22424": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22424). გთხოვთ, ისევ სცადოთ",
    "error_22425": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 22425). გთხოვთ, ისევ სცადოთ",
    "error_20098": "შეცდომის კოდი: 20098. თუ თქვენ ათვალიერებთ პირად რეჟიმში, გთხოვთ გადახვიდეთ ნორმალურ რეჟიმში და სცადოთ ხელახლა.",
    "error_22298": "Google-ის მოთხოვნა ვერ მოხერხდა (შეცდომა: 22298). გთხოვთ, ისევ სცადოთ.",
    "error_22498": "Facebook-ის მოთხოვნა ვერ მოხერხდა (შეცდომა: 22498). გთხოვთ, ისევ სცადოთ.",
    "error_24902": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 24902). გთხოვთ, ისევ სცადოთ",
    "error_24903": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 24903). გთხოვთ, ისევ სცადოთ",
    "error_24904": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 24904). გთხოვთ, ისევ სცადოთ",
    "error_24905": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 24905). გთხოვთ, ისევ სცადოთ",
    "login_title": "შედით MindOnMap-ში",
    "log_in": "Შესვლა",
    "no_account": "ანგარიში არ არის?",
    "create_it": "შექმენით იგი",
    "or_log_in_with": "ან შედით სისტემაში",
    "passwordless_login": "შესვლა პაროლის გარეშე",
    "log_in_done": "შესვლა დასრულდა",
    "three_rd_account_connect_info": "გილოცავ! თქვენ წარმატებით შეხვედით სისტემაში. ახლა თქვენ შეგიძლიათ დააკავშიროთ ერთი ელ.ფოსტის ანგარიში, რომელიც გამოიყენება მომავალში შესასვლელად.",
    "see_my_account": "იხილეთ ჩემი ანგარიში",
    "three_rd_login_merge_account": "მესამე მხარის ანგარიშის ელფოსტის მისამართი დარეგისტრირებულია, გსურთ უშუალოდ ამ ელფოსტის მისამართით დაკავშირება და შესვლა?",
    "connect_log_in": "დაკავშირება და შესვლა",
    "create_an_account": "Შექმენით ანგარიში",
    "back_to_log_in": "უკან შესვლა",
    "create_password": "Შექმენი პაროლი",
    "create_now": "შექმნა ახლა",
    "password_login_subtitle": "პაროლის გარეშე შესვლა ელექტრონული ფოსტით",
    "account_login": "Ანგარიშზე შესვლა",
    "rights": "ამ ანგარიშის შექმნით თქვენ ეთანხმებით <a href=\"https://www.mindonmap.com/terms-and-conditions/\">მომსახურების პირობებს</a> და <a href=\"https://www.mindonmap.com/privacy-policy/\">კონფიდენციალურობის პოლიტიკა</a>",
    "passwordless_login_done": "პაროლის გარეშე შესვლა დასრულდა",
    "passwordless_login_info": "გილოცავთ, თქვენ წარმატებით დაასრულეთ შესვლა პაროლის გარეშე. თქვენ შეგიძლიათ შექმნათ პაროლი ამ ანგარიშისთვის და შეხვიდეთ ანგარიშით და პაროლით მომავალში. <a href=\"/create-password\" style=\"display: initial;\">ახლავე შექმნა</a>",
    "sign_up": "დარეგისტრირდით",
    "register_info": "შექმენით თქვენი ანგარიში",
    "reset_now": "გადატვირთეთ ახლა",
    "forgot_password": "Დაგავიწყდა შენი პაროლი",
    "reset_password_subtitle": "გამოიყენეთ თქვენი ანგარიშის ელფოსტა პაროლის გადასაყენებლად",
    "plan_products": "გეგმები და პროდუქტები",
    "nick_name": "მომხმარებლის სახელი:",
    "email": "ელფოსტა:",
    "my_products": "ჩემი პროდუქტები",
    "my_orders": "ჩემი შეკვეთები",
    "unlink": "კავშირის გაუქმება",
    "link": "Ბმული",
    "connected_accounts": "დაკავშირებული ანგარიშები",
    "last_name": "Გვარი:",
    "first_name": "Სახელი:",
    "Gender": "სქესი:",
    "Birth": "Დაბადების:",
    "Month": "თვე",
    "Year": "წელიწადი",
    "Country_Region": "ქვეყანა/რეგიონი:",
    "Address": "მისამართი:",
    "Save": "Გადარჩენა",
    "Date": "თარიღი",
    "Male": "მამრობითი",
    "Female": "ქალი",
    "Unspecified": "დაუზუსტებელი",
    "Security": "უსაფრთხოება",
    "change_password": "Პაროლის შეცვლა",
    "change_now": "შეცვალეთ ახლა",
    "connect_email": "დააკავშირეთ ელფოსტა",
    "delete_account": "Ანგარიშის წაშლა",
    "delete_account_info": "როდესაც თქვენი ანგარიში წაიშლება, ყველა მონაცემი ჩემს ანგარიშში, რომელიც დაკავშირებულია თქვენს ანგარიშთან, სამუდამოდ წაიშლება და შესაძლოა ვერ შეძლოთ მისი აღდგენა. გირჩევთ, ფრთხილად იაროთ.",
    "Delete": "წაშლა",
    "Logout": "გამოსვლა",
    "my_profile": "Ჩემი პროფილი",
    "guides_faqs": "გიდები და ხშირად დასმული კითხვები",
    "More": "მეტი",
    "guides": "გიდები",
    "register": "რეგისტრაცია",
    "hot_faq": "ცხელი FAQ",
    "Contents": "შინაარსი:",
    "contact_us": "დაგვიკავშირდით >>",
    "plan": "Გეგმა",
    "unregistered": "დაურეგისტრირებელი",
    "buy_more": "შეიძინეთ მეტი",
    "buy_again": "ხელახლა იყიდე",
    "buy_now": "Იყიდე ეხლა",
    "free_no_limit": "უფასო და ლიმიტის გარეშე",
    "expired": "ვადა გაუვიდა",
    "lifetime": "Სიცოცხლის განმავლობაში",
    "remain": "Რჩება",
    "day_s": "დღე(ები)",
    "error_24801": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 24801). გთხოვთ, ისევ სცადოთ",
    "no_app_found": "აპი ვერ მოიძებნა!<a href=\"/\">განახლება</a> ან <a href=\"https://www.mindonmap.com/\">გადადით ოფიციალურ ვებსაიტზე</a>",
    "get_more": "მიიღეთ მეტი >>",
    "edit_photo": "ფოტოს რედაქტირება",
    "select_photo": "აირჩიეთ ფოტო",
    "change_photo": "Შეცვალე სურათი",
    "cancel": "გაუქმება",
    "hide_password": "პაროლის დამალვა",
    "show_password": "Აჩვენე პაროლი",
    "zoom_in": "Მიახლოება",
    "zoom_out": "მასშტაბირება",
    "rotate": "როტაცია",
    "horizontal_flip": "ჰორიზონტალური შემობრუნება",
    "vertical_flip": "ვერტიკალური გადაბრუნება",
    "country": "ქვეყანა",
    "country_1": "აირჩიეთ თქვენი ქვეყანა/რეგიონი",
    "country_2": "ალანდის კუნძული",
    "country_3": "ავღანეთი",
    "country_4": "ალბანეთი",
    "country_5": "ალჟირი",
    "country_6": "Ამერიკის სამოა",
    "country_7": "ანდორა",
    "country_8": "ანგოლა",
    "country_9": "ანგილა",
    "country_10": "ანტარქტიდა",
    "country_11": "ანტიგუა და ბარბუდა",
    "country_12": "არგენტინა",
    "country_13": "სომხეთი",
    "country_14": "არუბა",
    "country_15": "ავსტრალია",
    "country_16": "ავსტრია",
    "country_17": "აზერბაიჯანი",
    "country_18": "ბაჰრეინი",
    "country_19": "ბანგლადეში",
    "country_20": "ბარბადოსი",
    "country_21": "ბელორუსია",
    "country_22": "ბელგია",
    "country_23": "ბელიზი",
    "country_24": "ბენინი",
    "country_25": "ბერმუდის",
    "country_26": "ბუტანი",
    "country_27": "ბოლივია",
    "country_28": "ბოსნია და ჰერცეგოვინა",
    "country_29": "ბოტსვანა",
    "country_30": "ბუვეს კუნძული",
    "country_31": "ბრაზილია",
    "country_32": "ბრიტანეთის ინდოეთის ოკეანის ტერიტორია",
    "country_33": "ბრიტანეთის ვირჯინიის კუნძულები",
    "country_34": "ბრუნეი",
    "country_35": "ბულგარეთი",
    "country_36": "ბურკინა",
    "country_37": "ბურუნდი",
    "country_38": "კამბოჯა",
    "country_39": "კამერუნი",
    "country_40": "კანადა",
    "country_41": "კაბო ვერდე",
    "country_42": "კარიბის ჰოლანდია",
    "country_43": "Კაიმანის კუნძულები",
    "country_44": "Ცენტრალური აფრიკის რესპუბლიკა",
    "country_45": "ჩადი",
    "country_46": "ჩილე",
    "country_47": "ჩინეთი",
    "country_48": "შობის კუნძული",
    "country_49": "Cocos (Keeling) კუნძულები",
    "country_50": "კოლუმბია",
    "country_51": "კუკის კუნძულები",
    "country_52": "Კოსტა - რიკა",
    "country_53": "კოტ დ'ივუარი",
    "country_54": "ხორვატია",
    "country_55": "კუბა",
    "country_56": "კვიპროსი",
    "country_57": "Ჩეხეთის რესპუბლიკა",
    "country_58": "კონგოს დემოკრატიული რესპუბლიკა",
    "country_59": "დანია",
    "country_60": "ჯიბუტი",
    "country_61": "დომინიკა",
    "country_62": "Დომინიკის რესპუბლიკა",
    "country_63": "ეკვადორი",
    "country_64": "ეგვიპტე",
    "country_65": "ელ სალვადორი",
    "country_66": "Ეკვატორული გვინეა",
    "country_67": "ერიტრეა",
    "country_68": "ესტონეთი",
    "country_69": "ეთიოპია",
    "country_70": "ფოლკლენდის კუნძულები",
    "country_71": "Ფარერის კუნძულები",
    "country_72": "მიკრონეზიის ფედერალური სახელმწიფოები",
    "country_73": "ფიჯი",
    "country_74": "ფინეთი",
    "country_75": "საფრანგეთი",
    "country_76": "Ფრანგული გუიანა",
    "country_77": "Ფრანგული პოლინეზია",
    "country_78": "საფრანგეთის სამხრეთ ტერიტორიები",
    "country_79": "გაბონი",
    "country_80": "გამბია",
    "country_81": "საქართველოს",
    "country_82": "გერმანია",
    "country_83": "განა",
    "country_84": "გიბრალტარი",
    "country_85": "დიდი ბრიტანეთი (გაერთიანებული სამეფო; ინგლისი)",
    "country_86": "საბერძნეთი",
    "country_87": "გრენლანდია",
    "country_88": "გრენადა",
    "country_89": "გვადელუპე",
    "country_90": "გუამი",
    "country_91": "გვატემალა",
    "country_92": "გერნსი",
    "country_93": "გვინეა",
    "country_94": "გვინეა-ბისაუ",
    "country_95": "გაიანა",
    "country_96": "ჰაიტი",
    "country_97": "ჰერდის კუნძული და მაკდონალდს კუნძულები",
    "country_98": "ჰონდურასი",
    "country_99": "Ჰონგ კონგი",
    "country_100": "უნგრეთი",
    "country_101": "ისლანდია",
    "country_102": "ინდოეთი",
    "country_103": "ინდონეზია",
    "country_104": "ირანი",
    "country_105": "ერაყი",
    "country_106": "ირლანდია",
    "country_107": "მენის კუნძული",
    "country_108": "ისრაელი",
    "country_109": "იტალია",
    "country_110": "იამაიკა",
    "country_111": "იაპონია",
    "country_112": "ჯერსი",
    "country_113": "ჟორდანია",
    "country_114": "ყაზახეთი",
    "country_115": "კენია",
    "country_116": "კირიბატი",
    "country_117": "ქუვეითი",
    "country_118": "ყირგიზეთი",
    "country_119": "ლაოსი",
    "country_120": "ლატვია",
    "country_121": "ლიბანი",
    "country_122": "ლესოტო",
    "country_123": "ლიბერია",
    "country_124": "ლიბია",
    "country_125": "ლიხტენშტეინი",
    "country_126": "ლიტვა",
    "country_127": "ლუქსემბურგი",
    "country_128": "მაკაო",
    "country_129": "მადაგასკარი",
    "country_130": "მალავი",
    "country_131": "მალაიზია",
    "country_132": "მალდივები",
    "country_133": "მალი",
    "country_134": "მალტა",
    "country_135": "Მარშალის კუნძულები",
    "country_136": "მარტინიკი",
    "country_137": "მავრიტანია",
    "country_138": "მავრიკი",
    "country_139": "მაიოტი",
    "country_140": "მექსიკა",
    "country_141": "მოლდოვა",
    "country_142": "მონაკო",
    "country_143": "მონღოლეთი",
    "country_144": "მონტენეგრო",
    "country_145": "მონსერატი",
    "country_146": "მაროკო",
    "country_147": "მოზამბიკი",
    "country_148": "მიანმარი (ბირმა)",
    "country_149": "ნამიბია",
    "country_150": "ნაურუ",
    "country_151": "ნეპალი",
    "country_152": "ნიდერლანდები",
    "country_153": "Ახალი კალედონია",
    "country_154": "Ახალი ზელანდია",
    "country_155": "ნიკარაგუა",
    "country_156": "ნიგერი",
    "country_157": "ნიგერია",
    "country_158": "ნიუე",
    "country_159": "ნორფოლკის კუნძული",
    "country_160": "Ჩრდილოეთ კორეა",
    "country_161": "ჩრდილოეთ მარიანას კუნძულები",
    "country_162": "ნორვეგია",
    "country_163": "ომანი",
    "country_164": "პაკისტანი",
    "country_165": "პალაუ",
    "country_166": "პალესტინის ტერიტორიები",
    "country_167": "პანამა",
    "country_168": "Პაპუა ახალი გვინეა",
    "country_169": "პარაგვაი",
    "country_170": "პერუს",
    "country_171": "პიტკერნის კუნძულები",
    "country_172": "პოლონეთი",
    "country_173": "პორტუგალია",
    "country_174": "Პუერტო რიკო",
    "country_175": "ყატარი",
    "country_176": "მაკედონიის რესპუბლიკა (FYROM)",
    "country_177": "კონგოს რესპუბლიკა",
    "country_178": "რეიუნიონი",
    "country_179": "რუმინეთი",
    "country_180": "Რუსეთის ფედერაცია",
    "country_181": "რუანდა",
    "country_182": "წმინდა ბართლემე",
    "country_183": "სენტ მარტინი (საფრანგეთი)",
    "country_184": "სენ-პიერი და მიკელონი",
    "country_185": "სამოა",
    "country_186": "Სან მარინო",
    "country_187": "სან ტომე და პრინსიპი",
    "country_188": "Საუდის არაბეთი",
    "country_189": "სენეგალი",
    "country_190": "სერბეთი",
    "country_191": "სეიშელის კუნძულები",
    "country_192": "Სიერა ლეონე",
    "country_193": "სინგაპური",
    "country_194": "სლოვაკეთი",
    "country_195": "სლოვენია",
    "country_196": "სოლომონის კუნძულები",
    "country_197": "სომალი",
    "country_198": "Სამხრეთ აფრიკა",
    "country_199": "სამხრეთ საქართველო და სამხრეთის სენდვიჩი ისლა",
    "country_200": "Სამხრეთ კორეა",
    "country_201": "Სამხრეთ სუდანი",
    "country_202": "ესპანეთი",
    "country_203": "Შრი ლანკა",
    "country_204": "წმინდა ელენა და დამოკიდებულებები",
    "country_205": "სენტ-კიტსი და ნევისი",
    "country_206": "წმინდა ლუსია",
    "country_207": "წმინდა ვინსენტი და გრენადინები",
    "country_208": "სუდანი",
    "country_209": "სურინამი",
    "country_210": "სვაზილენდი",
    "country_211": "შვედეთი",
    "country_212": "შვეიცარია",
    "country_213": "სირია",
    "country_214": "ტაივანი",
    "country_215": "ტაჯიკეთი",
    "country_216": "ტანზანია",
    "country_217": "თარგი:ქვეყნის მონაცემები SJM Svalbard",
    "country_218": "ტაილანდი",
    "country_219": "ბაჰამის კუნძულები",
    "country_220": "კომორის კუნძულები",
    "country_221": "Ფილიპინები",
    "country_222": "ლესტე ტიმორი (აღმოსავლეთი ტიმორი)",
    "country_223": "Წასვლა",
    "country_224": "ტოკელაუ",
    "country_225": "ტონგა",
    "country_226": "ტრინიდადი და ტობაგო",
    "country_227": "ტუნისი",
    "country_228": "თურქეთი",
    "country_229": "თურქმენეთი",
    "country_230": "ტურქსი და კაიკოსის კუნძულები",
    "country_231": "ტუვალუ",
    "country_232": "უგანდა",
    "country_233": "უკრაინა",
    "country_234": "არაბეთის გაერთიანებული საამიროები",
    "country_235": "შეერთებული შტატების მცირე დაშორებული კუნძულები",
    "country_236": "ამერიკის შეერთებული შტატები (აშშ)",
    "country_237": "შეერთებული შტატების ვირჯინიის კუნძულები",
    "country_238": "ურუგვაი",
    "country_239": "უზბეკეთი",
    "country_240": "ვანუატუ",
    "country_241": "ვატიკანი (წმინდა საყდარი)",
    "country_242": "ვენესუელა",
    "country_243": "ვიეტნამი",
    "country_244": "უოლისი და ფუტუნა",
    "country_245": "დასავლეთ საჰარა",
    "country_246": "იემენი",
    "country_247": "ზამბია",
    "country_248": "ზიმბაბვე",
    "google_login": "შედით Google-ით",
    "State": "სახელმწიფო",
    "Activation_code": "Აქტივაციის კოდი",
    "Question": "ჩამოთვალეთ ყველა აპი, რომელშიც შესული ხართ",
    "Copy_complete": "კოპირება დასრულებულია",
    "footer": "საავტორო უფლება © 2024 MindOnMap Studio. Ყველა უფლება დაცულია.",
    "change_password_success": "პაროლი წარმატებით შეიცვალა",
    "successful_login_title": "შესვლა წარმატებით",
    "product_page": "პროდუქტის გვერდი >>",
    "successful_login_info": "შესვლა დასრულდა. გთხოვთ, დახუროთ მიმდინარე გვერდი და დაუბრუნდეთ თავდაპირველ ჩანართს შემდეგი პროცესის გასაგრძელებლად. მიმდინარე გვერდი ავტომატურად დაიხურება 5 წამში. თუ ავტომატური დახურვა ან \"შესრულებულია\" ღილაკზე დაწკაპუნებით დახურვა ვერ მოხერხდა, გთხოვთ, პირდაპირ დახუროთ ეს ჩანართი.",
    "successful_login_info_firefox": "შესვლა დასრულდა. გთხოვთ, დახუროთ მიმდინარე გვერდი და დაუბრუნდეთ თავდაპირველ ჩანართს შემდეგი პროცესის გასაგრძელებლად.",
    "my_account": "Ჩემი ანგარიში",
    "my_history": "ჩემი ისტორია",
    "remove_watermark": "ამოიღეთ Watermark",
    "no_history": "არა ისტორია",
    "history_all": "Მონიშნე ყველა",
    "history_open": "გახსენით",
    "history_down": "ჩამოტვირთვა",
    "history_delete": "წაშლა",
    "history_clear": "არასწორის გასუფთავება",
    "images": "სურათი(ები)",
    "use_this_function": "გამოიყენეთ ეს პროდუქტი >>",
    "hd_downloading": "HD ორიგინალური სურათების ჩამოტვირთვის უპირატესობები:",
    "lifetimeRemaining": "სიცოცხლე-დარჩენილი",
    "Remaining": "დარჩენილი",
    "email_verification": "ელ. ფოსტით ვერიფიკაცია",
    "email_verification_info": "ჩვენ გამოგიგზავნეთ დამადასტურებელი შეტყობინება თქვენს ელფოსტაზე <span class=\"email\"></span> და გთხოვთ, დაასრულოთ დადასტურება. დადასტურების შემდეგ, უპირატესობები ავტომატურად იქნება სინქრონიზებული.",
    "wrong_email": "არასწორი ელფოსტის მისამართი?",
    "click_here_to_modify": "დააწკაპუნეთ აქ შესაცვლელად",
    "get_more_help": "მიიღეთ მეტი დახმარება?",
    "click_here": "Დააკლიკე აქ",
    "email_verification_info_success": "გილოცავთ თქვენი ელ.ფოსტის ანგარიშის დადასტურებას.",
    "email_verification_info_error": "დადასტურება ვერ მოხერხდა ვადაგასული ბმულის გამო.",
    "registration_succeeded": "რეგისტრაცია წარმატებით დასრულდა",
    "registration_succeeded_info_1": "გილოცავ! Თქვენ წარმატებით დარეგისტრირდით. ჩვენ გამოგიგზავნეთ დამადასტურებელი შეტყობინება თქვენს ელფოსტაზე <span class=\"email\"></span> და გთხოვთ, დაასრულოთ დადასტურება ამ ელფოსტის უპირატესობების გამოსაყენებლად.",
    "registration_succeeded_info_2": "დააწკაპუნეთ \"შესრულებულია\", რათა დაბრუნდეთ მთავარ გვერდზე და გამოიყენოთ ეს პროდუქტი.",
    "registration_succeeded_info_3": "დააწკაპუნეთ „შესრულებულია“ მიმდინარე გვერდის დასახურად და შემდეგ ოპერაციებისთვის მთავარ გვერდზე დასაბრუნებლად. თუ დახურვა ვერ მოხერხდა, გთხოვთ, ხელით დახუროთ ჩანართი.",
    "verify_email": "დაადასტურეთ ელფოსტა",
    "registered_email_not_verified": "რეგისტრირებული ელფოსტა არ არის დამოწმებული, გთხოვთ, დაუყოვნებლივ გადაამოწმოთ იგი.",
    "email_verification_time_1": "არ მიგიღიათ დამადასტურებელი ელფოსტა?",
    "email_verification_time_2": "შემდეგ",
    "email_verification_time_3": "დააწკაპუნეთ აქ ხელახლა გასაგზავნად",
    "error_26301": "შეცდომის კოდი: 26301, გთხოვთ დაგვიკავშირდეთ",
    "error_26302": "შეცდომის კოდი: 26302, გთხოვთ დაგვიკავშირდეთ",
    "error_26303": "ელფოსტის ფორმატის შეცდომა (შეცდომის კოდი: 26303). გთხოვთ შეიყვანოთ ხელახლა",
    "error_26304": "რეკომენდირებულია პაროლი 8 სიმბოლოზე მეტი (შეცდომის კოდი: 26304)",
    "error_26305": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26305). გთხოვთ, ხელახლა სცადოთ",
    "error_26306": "ელფოსტა რეგისტრირებულია, გთხოვთ <a href=\"https://account.mindonmap.com/login/\">გადადით სისტემაში</a>",
    "error_26307": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26307). გთხოვთ, ხელახლა სცადოთ",
    "error_26308": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26308). გთხოვთ, ხელახლა სცადოთ",
    "error_26401": "შეცდომის კოდი: 26401, გთხოვთ, ხელახლა სცადოთ",
    "error_26402": "ელფოსტა დადასტურებულია (შეცდომის კოდი: 26402), გთხოვთ, ხელახლა სცადოთ",
    "error_26403": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26403). გთხოვთ, ხელახლა სცადოთ",
    "error_26404": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26404). გთხოვთ, ხელახლა სცადოთ",
    "error_26501": "შეცდომის კოდი: 26501, გთხოვთ დაგვიკავშირდეთ",
    "error_26502": "შეცდომის კოდი: 26502, გთხოვთ დაგვიკავშირდეთ",
    "error_26503": "ელფოსტის ფორმატის შეცდომა (შეცდომის კოდი: 26503). გთხოვთ შეიყვანოთ ხელახლა",
    "error_26504": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26504). გთხოვთ, ხელახლა სცადოთ",
    "error_26505": "ელფოსტა არ არის რეგისტრირებული, გთხოვთ <a href=\"https://account.mindonmap.com/register/\">პირველ რიგში დარეგისტრირდეთ</a>",
    "error_26506": "ელფოსტა დამოწმებულია.",
    "error_26507": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26507). გთხოვთ, ხელახლა სცადოთ",
    "error_26508": "დადასტურება დასრულებულია (შეცდომის კოდი: 26508), გთხოვთ, ხელახლა სცადოთ",
    "error_26509": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26509), გთხოვთ, ხელახლა სცადოთ",
    "error_26510": "შეცდომის კოდი: 26510, გთხოვთ დაგვიკავშირდეთ",
    "error_26601": "შეცდომის კოდი: 26601, გთხოვთ დაგვიკავშირდეთ",
    "error_26602": "შეცდომის კოდი: 26602, გთხოვთ დაგვიკავშირდეთ",
    "error_26603": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26603). გთხოვთ, ხელახლა სცადოთ",
    "error_26604": "შეცდომის კოდი: 26604, გთხოვთ დაგვიკავშირდეთ",
    "error_26605": "შეცდომის კოდი: 26605, გთხოვთ დაგვიკავშირდეთ",
    "error_26701": "შეცდომის კოდი: 26701, გთხოვთ დაგვიკავშირდეთ",
    "error_26702": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26702). გთხოვთ, ხელახლა სცადოთ",
    "error_26703": "შეცდომის კოდი: 26703, გთხოვთ დაგვიკავშირდეთ",
    "error_26704": "შეცდომის კოდი: 26704, გთხოვთ დაგვიკავშირდეთ",
    "error_26705": "დაელოდეთ შესვლას (შეცდომის კოდი: 26705). გთხოვთ, ხელახლა სცადოთ",
    "no_cookie": "თქვენ ჩართეთ ყველა ქუქი-ფაილების დაბლოკვის ფუნქცია თქვენს ბრაუზერში, ასე რომ თქვენ ვერ შეხვალთ სისტემაში. გთხოვთ, გადახვიდეთ პარამეტრებში, რათა მონიშნოთ ყველა ქუქი-ფაილის დაშვების ველი.",
    "error_26801": "შეცდომის კოდი: 26801, გთხოვთ დაგვიკავშირდეთ",
    "error_26802": "შეცდომის კოდი: 26802, გთხოვთ დაგვიკავშირდეთ",
    "error_26803": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26803). გთხოვთ, ხელახლა სცადოთ",
    "error_26804": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26804). გთხოვთ, ხელახლა სცადოთ",
    "error_order": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27098), გთხოვთ სცადოთ ხელახლა!",
    "error_order1": "შეკვეთის მოთხოვნა არასრულია (შეცდომის კოდი: ",
    "error_order2": "） გთხოვთ, განაახლოთ და სცადოთ ხელახლა.",
    "modify_email_title": "შეცვალეთ ელფოსტა",
    "modify_email_info": "თქვენ შეგიძლიათ გამოიყენოთ შეცვლილი ელფოსტა თქვენს ანგარიშში შესასვლელად.",
    "images_per": "სურათები თითო",
    "error_26101": "შეცდომა: 26101. გთხოვთ დაგვიკავშირდეთ",
    "error_26102": "შეცდომა: 26102. გთხოვთ დაგვიკავშირდეთ",
    "error_26103": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 26103). გთხოვთ, სცადოთ ხელახლა",
    "error_26104": "შეცდომის კოდი: 26104, გთხოვთ, ხელახლა სცადოთ",
    "error_26105": "შეცდომის კოდი: 26105, გთხოვთ, ხელახლა სცადოთ",
    "error_26106": "წაშლა ვერ მოხერხდა (შეცდომის კოდი: 26106). გთხოვთ, ისევ სცადოთ",
    "error_26201": "შეცდომა: 26201. გთხოვთ დაგვიკავშირდეთ",
    "error_26202": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 26202). გთხოვთ, სცადოთ ხელახლა",
    "error_26001": "შეცდომა: 26001. გთხოვთ დაგვიკავშირდეთ",
    "error_26002": "შეცდომა: 26002. გთხოვთ დაგვიკავშირდეთ",
    "error_26003": "შეცდომა: 26003. გთხოვთ დაგვიკავშირდეთ",
    "error_26004": "შეცდომა: 26004. გთხოვთ დაგვიკავშირდეთ",
    "error_26005": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 26005). გთხოვთ, სცადოთ ხელახლა",
    "error_26006": "შეცდომის კოდი: 26006, გთხოვთ, ხელახლა სცადოთ",
    "error_26008": "შეცდომა: 26008. გთხოვთ დაგვიკავშირდეთ",
    "go_to_the_home_page": "გადადით მთავარ გვერდზე",
    "error_27101": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27101). გთხოვთ ხელახლა სცადოთ",
    "error_27201": "შეცდომის კოდი: 27201, გთხოვთ დაგვიკავშირდეთ",
    "error_27202": "შეცდომის კოდი: 27202, გთხოვთ, ხელახლა სცადოთ",
    "error_27203": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27203). გთხოვთ, ხელახლა სცადოთ",
    "error_27204": "არასწორი კოდი (შეცდომის კოდი: 27204).",
    "error_27205": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27205). გთხოვთ, ხელახლა სცადოთ",
    "error_27206": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27206). გთხოვთ, ხელახლა სცადოთ",
    "error_27207": "მოთხოვნა ვერ მოხერხდა (შეცდომის კოდი: 27207). გთხოვთ, ხელახლა სცადოთ",
    "no_history_found": "თქვენ არ გამოგიყენებიათ რაიმე ინსტრუმენტი! <a href=\"/\">განახლება</a> ან <a href=\"https://www.mindonmap.com/\">გადადით ოფიციალურ ვებსაიტზე</a>",
    "error_25301": "შეცდომა: 25301. გთხოვთ დაგვიკავშირდეთ",
    "error_25302": "შეცდომა: 25302. გთხოვთ დაგვიკავშირდეთ",
    "error_25303": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 25303). გთხოვთ, ისევ სცადოთ",
    "error_25304": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 25304). გთხოვთ, ისევ სცადოთ",
    "error_25305": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 25305). გთხოვთ, ისევ სცადოთ",
    "error_25306": "მოთხოვნა  ვერ მოხერხდა (შეცდომის კოდი: 25306). გთხოვთ, ისევ სცადოთ",
    "image_upscaler_p": "სურათი წყლის ნიშნის ჩამოტვირთვის უპირატესობების გარეშე:",
    "Available_for": "Ხელმისაწვდომი:",
    "credit_per": "%s კრედიტ(ები) HD სურათზე",
    "still_valid": "შეძენილი გეგმ(ები) კვლავ ძალაშია",
    "credit": "კრედიტ(ებ)ი",
    "pc_3rd_info": "შესვლა წარმატებით. გთხოვთ გადადით განაცხადზე შემდგომი ოპერაციისთვის.",
    "use_online": "ისარგებლეთ ონლაინ სერვისით",
    "use_download": "გამოიყენეთ დესკტოპის პროგრამა",
    "use_immediately": "გამოიყენეთ დაუყოვნებლივ",
    "Use_in_browser": "გამოიყენეთ ბრაუზერში",
    "win_desktop": "ფანჯრები",
    "Mac_desktop": "მაკი",
    "credits_per": "{%} კრედიტი თვეში",
    "expire": "ვადის გასვლის დრო:",
    "viewDetails": "დეტალების ნახვა",
    "viewHistory": "იხილეთ ისტორიის უპირატესობები >>",
    "viewDetailsInfo": "შენიშვნა: თუ გამოწერის უპირატესობები განახლდება ვადის გასვლიდან 7 დღის განმავლობაში, გამოუყენებელი უპირატესობები შეიძლება გაგრძელდეს გამოყენებაზე. ასევე, ვადის გასვლის დრო ავტომატურად განახლდება ახალი გამოწერის ვადის გასვლის დროზე. თუ ვადის გასვლის 7 დღის შემდეგ ახალი გამოწერა არ იქნება, ყველა ვადაგასული სარგებელი წაიშლება.",
    "connect_account": "მიამაგრეთ ელფოსტა თქვენს ანგარიშზე",
    "connect_account_info": "აკინძვის შემდეგ, შეგიძლიათ შეხვიდეთ ამ ელფოსტის მისამართით.",
    "connect_now": "შებოჭე ახლა",
    "no_email_bind": "ელ.ფოსტის შეკვრა არ არის",
    "bind_email": "ელ.ფოსტის დაკავშირება",
    "connect_your_email_placeholder": "გთხოვთ, შეიყვანოთ თქვენი ელ.ფოსტის მისამართი",
    "bind_an_email": "მიამაგრეთ ელ.წერილი",
    "bind_info": "თქვენ წარმატებით შეხვედით სისტემაში. გთხოვთ, დაუკავშიროთ ელფოსტა თქვენს ანგარიშს თქვენი უპირატესობების გასააქტიურებლად.",
    "bind_later": "მოგვიანებით შეკვრა",
    "hi": "გამარჯობა, %s!",
    "Personal_Information": "Პირადი ინფორმაცია",
    "Access": "წვდომა",
    "Subscription_Plan": "(გამოწერის გეგმა)",
    "No_orders": "შეკვეთები ვერ მოიძებნა.",
    "No_data": "Მონაცემები არ არის",
    "Featured_Products": "გამორჩეული პროდუქტები",
    "More_Products": "მეტი პროდუქტი",
    "Free_Download": "Უფასო გადმოწერა",
    "Get_Started": "Დაიწყე",
    "Subscribe": "გამოწერა",
    "Verified": "დამოწმებულია",
    "back_to_account_center": "დაბრუნება ანგარიშის ცენტრში",
    "success": "წარმატებები!",
    "successfully": "თქვენ წარმატებით დაარეგისტრირეთ ანგარიში.",
    "Continue": "განაგრძეთ",
    "Already": "უკვე გაქვთ ანგარიში?",
    "loading_verification": "გადამოწმების სტატუსის შემოწმება...",
    "email_no_verification": "უკაცრავად, რეგისტრირებული ელფოსტის მისამართი დადასტურებული არ არის. გთხოვთ, დაასრულოთ გადამოწმება ზემოაღნიშნული ინსტრუქციების მიხედვით და კვლავ დააწკაპუნეთ ღილაკზე „შესრულებულია“ რეგისტრაციის დასასრულებლად.",
    "will_expire_after": "ვადა ამოიწურება შემდეგ",
    "hours": "საათები",
  },
  "kk": {
    "overtime": "Қате коды: {%}, қайталап көріңіз",
    "isnetwork": "Интернет қатесі. Тексеріп, қайталап көріңіз",
    "email_placeholder": "Электрондық пошта",
    "email_empty": "Электрондық поштаны енгізіңіз",
    "email_not_valid": "Электрондық пошта жарамсыз",
    "email_not_valid_1": "Электрондық поштаңызды енгізіңіз",
    "email_not_valid_2": "Электрондық пошта жарамсыз, басқа мекенжайды пайдаланыңыз.",
    "email_not_valid_3": "Электрондық пошта кірісі жоқ",
    "password_placeholder": "Құпия сөз",
    "password_empty": "Құпия сөзді енгізіңіз",
    "password_not_valid": "Қате тіркелгі немесе құпия сөз",
    "password_not_valid_1": "8 таңбадан жоғары құпия сөз қажет",
    "password_not_valid_2": "Құпия сөз жасаңыз",
    "password_placeholder_1": "Құпия сөзіңізді жасаңыз",
    "password_placeholder_2": "Құпия сөзді растаңыз",
    "password_placeholder_3": "Жаңа құпия сөз жасаңыз",
    "password_placeholder_4": "Жаңа құпиясөзді растаңыз",
    "password_placeholder_5": "Ескі құпия сөзді енгізіңіз",
    "copy_password_empty": "Құпия сөзді растаңыз",
    "copy_password_not_valid": "Құпия сөзіңізді растаңыз",
    "copy_passwords_inconsistent": "Құпия сөзді растау сәйкес емес",
    "code_empty": "Растау кодын енгізіңіз",
    "code_not_valid": "Жарамсыз растау коды",
    "code_placeholder": "Тексеру коды",
    "not_received_code": "Егер сіздің пошта жәшігіңіз ұзақ уақыт бойы растау кодын алмаса, растау кодын қайта алыңыз.",
    "get_first_code": "Алдымен растау кодын алыңыз.",
    "last_name_placeholder": "Фамилияңызды енгізіңіз",
    "first_name_placeholder": "Аты-жөніңізді енгізіңіз",
    "address_placeholder": "Мекенжайыңызды енгізіңіз",
    "no_code_text": "Біз растау кодын жібердік. Кодыңызды енгізіңіз. <span class='tips'>Кодты алған жоқсыз ба?",
    "no_code_text_1": "1. Электрондық пошта мекенжайының жарамды екенін және оның электрондық хаттарды қабылдай алатынын тексеріңіз.",
    "no_code_text_2": "2. Электрондық поштаны жүйе автоматты түрде жіберетіндіктен, ол спам немесе қалаусыз электрондық пошта ретінде белгіленуі мүмкін. Электрондық поштаның Себет қалтасында екенін тексеріңіз.",
    "no_code_text_3": "3. Мәселеңізді шеше алмайсыз ба? ",
    "no_code_text_3_span": "Содан кейін бізбен байланысу үшін осы жерді басыңыз.",
    "order_no": "Сіз ешбір өнімді сатып алған жоқсыз, Егер сізде сұрақтар болса, <a href=\"https://www.mindonmap.com/contact-us/\">бізбен хабарласыңыз</a>.",
    "error_24901": "Ағымдағы тіркелгіде электрондық пошта байланысы жоқ және тапсырыстарды таба алмайды. Электрондық поштаны қосыңыз.",
    "user_guide": "Пайдаланушы нұсқаулығы>>",
    "download": "Жүктеп алу",
    "order_number": "Тапсырыс нөмірі:",
    "Refund": "Қайтару",
    "Disabled": "Өшірілген",
    "Normal": "Қалыпты",
    "Modify": "Өзгерту",
    "Modify_1": "Өзгерту>>",
    "Connect": "Қосылу",
    "unlink_success": "Байланысты жою",
    "connect_success": "Сәтті қосылыңыз",
    "feedback_title": "Пікіріңізге рахмет. Мәселеңізді қалдырыңыз, біз сізге 24 сағат ішінде жауап береміз.",
    "feedback_thank_you": "Рахмет!<br />Пікіріңіз сәтті жіберілді.",
    "feedback_email": "Электрондық поштаңызды осында енгізіңіз!",
    "feedback_content": "Кез келген мәселе немесе ұсынысыңызды осында қалдырыңыз.",
    "feedback_submit": "Жіберу",
    "form_contents": "Ешқандай сипаттаманы енгізген жоқсыз. Оны енгізіп, қайта жіберіңіз.",
    "old_password": "Ескі құпия сөзді енгізіңіз",
    "new_password": "Жаңа құпия сөз жасаңыз",
    "old_new_password": "Жаңа құпия сөз бұрынғымен бірдей болмауы керек",
    "incorrect_password": "Қате құпиясөз",
    "delete_no": "Қазір жою",
    "Caps": "Caps lock қосулы",
    "Get": "Алу",
    "Done": "Дайын",
    "error_20001": "Қате: 20001. Жүйеге қайта кіріңіз.",
    "error_20002": "Қате: 20002. Жүйеге қайта кіріңіз.",
    "error_20003": "Қате: 20003. Жүйеге қайта кіріңіз.",
    "error_20004": "Сұрау орындалмады (қате коды: 20004). Қайталап көріңіз.",
    "error_20005": "Жүйеге кіру сеансының мерзімі аяқталды (Қате: 20005). Жүйеге қайта кіріңіз.",
    "error_20006": "Сұрау орындалмады (қате коды: 20006). Қайталап көріңіз.",
    "error_20007": "Жүйеге кіру сеансының мерзімі аяқталды (Қате: 20007). Жүйеге қайта кіріңіз.",
    "error_20008": "Жүйеге кіру сеансының мерзімі аяқталды (Қате: 20008). Жүйеге қайта кіріңіз.",
    "error_20009": "Жүйеге кіру сеансының мерзімі аяқталды (Қате: 20009). Жүйеге қайта кіріңіз.",
    "error_20101": "Электрондық поштаңызды енгізіңіз (қате коды: 20101)",
    "error_20102": "Электрондық пошта жарамсыз (қате коды: 20102)",
    "error_20103": "Сұрау орындалмады (қате коды: 20103). Қайталап көріңіз",
    "error_20104": "Электрондық пошта бұрыннан қолданыста, <a href=\"https://account.mindonmap.com/login/\">жүйеге кіріңіз</a> немесе жаңасымен тіркеліңіз.",
    "error_20105": "Сұрау орындалмады (қате коды: 20105). Қайталап көріңіз",
    "error_20106": "Электрондық поштаны жіберу мүмкін болмады, қайталап көріңіз",
    "error_20201": "Электрондық поштаңызды енгізіңіз (қате коды: 20201)",
    "error_20202": "Құпия сөзіңізді енгізіңіз (қате коды: 20202)",
    "error_20203": "Растау кодын енгізіңіз (қате коды: 20203)",
    "error_20204": "Электрондық пошта жарамсыз (қате коды: 20204)",
    "error_20205": "8 таңбадан жоғары құпия сөз қажет (қате коды: 20205)",
    "error_20206": "Сұрау орындалмады (қате коды: 20206). Қайталап көріңіз",
    "error_20207": "Жарамсыз растау коды",
    "error_20208": "Сұрау орындалмады (қате коды: 20208). Қайталап көріңіз",
    "error_20209": "Сұрау орындалмады (қате коды: 20209). Қайталап көріңіз",
    "error_20301": "Электрондық поштаңызды енгізіңіз (қате коды: 20301)",
    "error_20302": "Қате: 20302. Бізбен хабарласыңыз",
    "error_20303": "Электрондық пошта жарамсыз (қате коды: 20303)",
    "error_20304": "Сұрау орындалмады (қате коды: 20304). Қайталап көріңіз",
    "error_20305": "Есептік жазба жоқ. Алдымен қайта енгізіңіз немесе <a href=\"https://account.mindonmap.com/register/\">оны жасаңыз</a>.",
    "error_20306": "Әлі құпия сөз жоқ. <a href=\"https://account.mindonmap.com/passwordless-login/\">Құпия сөзсіз кіру</a> немесе <a href=\"https://account.mindonmap.com/create-password/\"> пайдаланыңыз. құпия сөз</a> орнатып, жүйеге кіріңіз.",
    "error_20308": "Сұрау орындалмады (қате коды: 20308). Қайталап көріңіз",
    "error_20401": "Жүйеден шығу мүмкін болмады (қате коды: 20401). Қайталап көріңіз",
    "error_20501": "Электрондық поштаңызды енгізіңіз (қате коды: 20501)",
    "error_20502": "Электрондық пошта жарамсыз (қате коды: 20502)",
    "error_20503": "Сұрау орындалмады (қате коды: 20503). Қайталап көріңіз",
    "error_20504": "Электрондық поштаны жіберу мүмкін болмады. Қайталап көріңіз.",
    "error_20601": "Электрондық поштаңызды енгізіңіз (қате коды: 20601)",
    "error_20602": "Растау кодын енгізіңіз (қате коды: 20602)",
    "error_20603": "Электрондық пошта жарамсыз (қате коды: 20603)",
    "error_20604": "Сұрау орындалмады (қате коды: 20604). Қайталап көріңіз",
    "error_20606": "Сұрау орындалмады (қате коды: 20606). Қайталап көріңіз",
    "error_20607": "Сұрау орындалмады (қате коды: 20607). Қайталап көріңіз",
    "error_20608": "Сұрау орындалмады (қате коды: 20608). Қайталап көріңіз",
    "error_20701": "Электрондық поштаңызды енгізіңіз (қате коды: 20701)",
    "error_20702": "Электрондық пошта жарамсыз (қате коды: 20702)",
    "error_20703": "Сұрау орындалмады (қате коды: 20703). Қайталап көріңіз",
    "error_20704": "Есептік жазба жоқ. Алдымен қайта енгізіңіз немесе <a href=\"https://account.mindonmap.com/register/\">оны жасаңыз</a>.",
    "error_20705": "Сұрау орындалмады (қате коды: 20705). Қайталап көріңіз",
    "error_20706": "Электрондық поштаны жіберу мүмкін болмады. Қайталап көріңіз",
    "error_20801": "Электрондық поштаңызды енгізіңіз (қате коды: 20801)",
    "error_20802": "Қате: 20802. Бізбен хабарласыңыз",
    "error_20803": "Растау кодын енгізіңіз (қате коды: 20803)",
    "error_20804": "Электрондық пошта жарамсыз (қате коды: 20804)",
    "error_20805": "8 таңбадан жоғары құпия сөз қажет (қате коды: 20805)",
    "error_20806": "Сұрау орындалмады (қате коды: 20806). Қайталап көріңіз",
    "error_20808": "Сұрау орындалмады (қате коды: 20808). Қайталап көріңіз",
    "error_20901": "Сұрау орындалмады (қате коды: 20901). Қайталап көріңіз",
    "error_20902": "Сұрау орындалмады (қате коды: 20902). Қайталап көріңіз",
    "error_21000": "Өзгерістер сақталады",
    "error_21001": "Ешқандай ақпарат жіберілмеді (қате коды: 21001)",
    "error_21002": "Сұрау орындалмады (қате коды: 21002). Қайталап көріңіз",
    "error_21101": "Электрондық поштаңызды енгізіңіз (қате коды: 21101)",
    "error_21102": "Электрондық пошта жарамсыз (қате коды: 21102)",
    "error_21103": "Сұрау орындалмады (қате коды: 21103), қайталап көріңіз",
    "error_21104": "Электрондық пошта әлдеқашан қосылған, жаңасын пайдаланыңыз",
    "error_21105": "Сұрау орындалмады (қате коды: 21105), қайталап көріңіз",
    "error_21106": "Электрондық поштаны жіберу мүмкін болмады. Қайталап көріңіз",
    "error_21201": "Электрондық поштаңызды енгізіңіз (қате коды: 21201)",
    "error_21202": "Растау кодын енгізіңіз (қате коды: 21202)",
    "error_21203": "Электрондық пошта жарамсыз (қате коды: 21203)",
    "error_21204": "Қате: 21204. Бізбен хабарласыңыз",
    "error_21205": "Қате: 21205. Бізбен хабарласыңыз",
    "error_21206": "8 таңбадан жоғары құпия сөз қажет (қате коды: 21206)",
    "error_21207": "Сұрау орындалмады (қате коды: 21207). Қайталап көріңіз",
    "error_21209": "Сұрау орындалмады (қате коды: 21209). Қайталап көріңіз",
    "error_21301": "Ескі құпия сөзді енгізіңіз (қате коды: 21301)",
    "error_21302": "Жаңа құпия сөз жасаңыз (қате коды: 21302)",
    "error_21303": "Жаңа құпия сөз бұрынғымен бірдей болмауы керек. (Қате: 21303)",
    "error_21304": "8 таңбадан жоғары құпия сөз қажет (қате коды: 21304)",
    "error_21306": "Сұрау орындалмады (қате коды: 21306). Қайталап көріңіз",
    "error_21402": "Сұрау орындалмады (қате коды: 21402). Қайталап көріңіз",
    "error_21403": "Растау кодын жіберу мүмкін болмады. Қайта жіберіңіз",
    "error_21500": "Есептік жазба жойылды",
    "error_21501": "Растау кодын енгізіңіз (қате коды: 21501)",
    "error_21502": "Жүйеге кіру сеансының мерзімі аяқталды (Қате: 21502). Жүйеге қайта кіріңіз.",
    "error_21503": "Сұрау орындалмады (қате коды: 21503). Қайталап көріңіз",
    "error_21505": "Сұрау орындалмады (қате коды: 21505), қайталап көріңіз",
    "error_21601": "Қате: 20601. Бізбен хабарласыңыз",
    "error_21602": "Жарамсыз растау (Қате: 20602). Қайталап көріңіз.",
    "error_21603": "Қате: 20603. Қайталап көріңіз",
    "error_21604": "Сұрау орындалмады (қате коды: 21604). Қайталап көріңіз",
    "error_21606": "Сұрау орындалмады (қате коды: 21606). Қайталап көріңіз",
    "error_21611": "Сұрау орындалмады (қате коды: 21611). Қайталап көріңіз",
    "error_21801": "Қате: 21801. Бізбен хабарласыңыз",
    "error_21802": "Сұрау орындалмады (Қате: 21802). Қайталап көріңіз",
    "error_21803": "Қате: 21803. Қайталап көріңіз",
    "error_21804": "Сұрау орындалмады (қате коды: 21804). Қайталап көріңіз",
    "error_21806": "Қате: 21806. Қайталап көріңіз",
    "error_21807": "Қате: 21807. Бізбен хабарласыңыз",
    "error_21808": "Қате: 21808. Бізбен хабарласыңыз",
    "error_21809": "Қате: 21809. Бізбен хабарласыңыз",
    "error_21810": "Қате: 21810. Бізбен хабарласыңыз",
    "error_21811": "Қате: 21811. Бізбен хабарласыңыз",
    "error_21812": "Қате: 21812. Бізбен хабарласыңыз",
    "error_21813": "Сұрау орындалмады (қате коды: 21813). Қайталап көріңіз",
    "error_21814": "Қате: 21814. Бізбен хабарласыңыз",
    "error_21815": "Сұрау орындалмады (қате коды: 21815). Қайталап көріңіз",
    "error_21816": "Қате: 21816. Бізбен хабарласыңыз",
    "error_21817": "Қате: 21817. Бізбен хабарласыңыз",
    "error_21818": "Қате: 21818. Бізбен хабарласыңыз",
    "error_21819": "Сұрау орындалмады (қате коды: 21819). Қайталап көріңіз",
    "error_21820": "Қате: 21820. Бізбен хабарласыңыз",
    "error_21821": "Қате: 21821. Бізбен хабарласыңыз",
    "error_21822": "Қате: 21822. Бізбен хабарласыңыз",
    "error_21823": "Сұрау орындалмады (қате коды: 21823). Қайталап көріңіз",
    "error_21824": "Сұрау орындалмады (қате коды: 21824). Қайталап көріңіз",
    "error_21825": "Сұрау орындалмады (қате коды: 21825). Қайталап көріңіз",
    "error_21826": "Сұрау орындалмады (қате коды: 21826). Қайталап көріңіз",
    "error_21901": "Қате: 21901. Бізбен хабарласыңыз",
    "error_21902": "Сұрау орындалмады (қате коды: 21902). Қайталап көріңіз",
    "error_21903": "Тіркелгі күйі өзгерді (қате коды: 21903), бетті жаңартып, әрекетті қайталаңыз",
    "error_21904": "Қате: 21904. Қайталап көріңіз",
    "error_21905": "Қате: 21905. Қайталап көріңіз",
    "error_21906": "Сұрау орындалмады (қате коды: 21906). Қайталап көріңіз",
    "error_21907": "Google есептік жазбасы басқа есептік жазбамен байланыстырылды",
    "error_21908": "Сұрау орындалмады (қате коды: 21908). Қайталап көріңіз",
    "error_22001": "Сұрау орындалмады (қате коды: 22001). Қайталап көріңіз",
    "error_22002": "Қосымша кірусіз байланысты жою сәтсіз аяқталды",
    "error_22003": "Сұрау орындалмады (қате коды: 22003). Қайталап көріңіз",
    "error_22101": "Қате: 22101. Бізбен хабарласыңыз",
    "error_22102": "Тіркелгі күйі өзгерді (қате коды: 22102), бетті жаңартып, әрекетті қайталаңыз",
    "error_22103": "Сұрау орындалмады (қате коды: 22103). Қайталап көріңіз",
    "error_22104": "Тіркелгі күйі өзгерді (қате коды: 22104), бетті жаңартып, әрекетті қайталаңыз",
    "error_22105": "Қате: 22105. Қайталап көріңіз",
    "error_22106": "Қате: 22106. Қайталап көріңіз",
    "error_22107": "Қате: 22107. Бізбен хабарласыңыз",
    "error_22108": "Сұрау орындалмады (қате коды: 22108). Қайталап көріңіз",
    "error_22201": "Қате: 22201. Бізбен хабарласыңыз",
    "error_22202": "Жарамсыз растау (Қате: 22202). Қайталап көріңіз.",
    "error_22203": "Қате: 22203. Қайталап көріңіз\"",
    "error_22204": "Сұрау орындалмады (қате коды: 22204). Қайталап көріңіз",
    "error_22206": "Сұрау орындалмады (қате коды: 22206). Қайталап көріңіз",
    "error_22401": "Қате: 22401. Бізбен хабарласыңыз",
    "error_22402": "Жарамсыз растау (Қате: 22402). Қайталап көріңіз.",
    "error_22403": "Қате: 22403. Қайталап көріңіз",
    "error_22404": "Сұрау орындалмады (қате коды: 22404). Қайталап көріңіз",
    "error_22405": "Facebook тіркелгісі басқа электрондық поштамен байланыстырылды",
    "error_22406": "Қате: 22406. Қайталап көріңіз",
    "error_22407": "Қате: 22407. Бізбен хабарласыңыз",
    "error_22408": "Қате: 22408. Бізбен хабарласыңыз",
    "error_22409": "Қате: 22409. Бізбен хабарласыңыз",
    "error_22410": "Қате: 224010. Бізбен хабарласыңыз",
    "error_22411": "Қате: 224011. Бізбен хабарласыңыз",
    "error_22412": "Қате: 224012. Бізбен хабарласыңыз",
    "error_22413": "Қате: 22413. Бізбен хабарласыңыз",
    "error_22414": "Сұрау орындалмады (қате коды: 22414). Қайталап көріңіз",
    "error_22415": "Қате: 22415. Бізбен хабарласыңыз",
    "error_22416": "Қате: 22416. Бізбен хабарласыңыз",
    "error_22417": "Қате: 22417. Бізбен хабарласыңыз",
    "error_22418": "Сұрау орындалмады (қате коды: 22418). Қайталап көріңіз",
    "error_22419": "Қате: 22419. Бізбен хабарласыңыз",
    "error_22420": "Қате: 22420. Бізбен хабарласыңыз",
    "error_22421": "Қате: 22421. Бізбен хабарласыңыз",
    "error_22422": "Сұрау орындалмады (қате коды: 22422). Қайталап көріңіз",
    "error_22423": "Сұрау орындалмады (қате коды: 22423). Қайталап көріңіз",
    "error_22424": "Сұрау орындалмады (қате коды: 22424). Қайталап көріңіз",
    "error_22425": "Сұрау орындалмады (қате коды: 22425). Қайталап көріңіз",
    "error_20098": "Қате коды: 20098. Жеке режимде шолып жатсаңыз, Қалыпты режимге ауысып, әрекетті қайталаңыз.",
    "error_22298": "Google сұрауы орындалмады (Қате: 22298). Қайталап көріңіз.",
    "error_22498": "Facebook сұрауы орындалмады (Қате: 22498). Қайталап көріңіз.",
    "error_24902": "Сұрау орындалмады (қате коды: 24902). Қайталап көріңіз",
    "error_24903": "Сұрау орындалмады (қате коды: 24903). Қайталап көріңіз",
    "error_24904": "Сұрау орындалмады (қате коды: 24904). Қайталап көріңіз",
    "error_24905": "Сұрау орындалмады (қате коды: 24905). Қайталап көріңіз",
    "login_title": "MindOnMap жүйесіне кіріңіз",
    "log_in": "Кіру",
    "no_account": "Есептік жазба жоқ па?",
    "create_it": "Оны жасаңыз",
    "or_log_in_with": "Немесе арқылы кіріңіз",
    "passwordless_login": "Құпия сөзсіз кіру",
    "log_in_done": "Жүйеге кіру Дайын",
    "three_rd_account_connect_info": "Құттықтаймыз! Сіз жүйеге сәтті кірдіңіз. Енді сіз болашақта жүйеге кіру үшін пайдаланылатын бір электрондық пошта тіркелгісін қоса аласыз.",
    "see_my_account": "Менің тіркелгімді қараңыз",
    "three_rd_login_merge_account": "Үшінші тарап тіркелгісінің электрондық пошта мекенжайы тіркелді, осы электрондық пошта мекенжайымен тікелей қосылып, жүйеге кіргіңіз келе ме?",
    "connect_log_in": "Қосылу және кіру",
    "create_an_account": "Аккаунты құру",
    "back_to_log_in": "Жүйеге кіру үшін оралыңыз",
    "create_password": "Құпия сөз жасау",
    "create_now": "Қазір жасау",
    "password_login_subtitle": "Электрондық пошта арқылы құпия сөзсіз кіру",
    "account_login": "Тіркелгіге кіру",
    "rights": "Осы есептік жазбаны жасау арқылы сіз <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Қызмет көрсету шарттарымен</a> және <a href=\"https://www.mindonmap.com/privacy-policy/\">Құпиялылық саясаты</a>",
    "passwordless_login_done": "Құпия сөзсіз кіру орындалды",
    "passwordless_login_info": "Құттықтаймыз, сіз парольсіз кіруді сәтті аяқтадыңыз. Сіз бұл тіркелгі үшін құпия сөз жасай аласыз және болашақта есептік жазба мен құпия сөзбен кіре аласыз. <a href=\"/create-password\" style=\"display: initial;\">Қазір жасау</a>",
    "sign_up": "Тіркелу",
    "register_info": "Тіркелгіңізді жасаңыз",
    "reset_now": "Қазір қалпына келтіру",
    "forgot_password": "Құпия сөзді ұмыттыңыз ба",
    "reset_password_subtitle": "Құпия сөзді қалпына келтіру үшін тіркелгі электрондық поштаңызды пайдаланыңыз",
    "plan_products": "Жоспарлар және өнімдер",
    "nick_name": "Пайдаланушы аты:",
    "email": "Электрондық пошта:",
    "my_products": "Менің өнімдерім",
    "my_orders": "Менің тапсырыстарым",
    "unlink": "Байланысты жою",
    "link": "Сілтеме",
    "connected_accounts": "Қосылған тіркелгілер",
    "last_name": "Тек:",
    "first_name": "Аты:",
    "Gender": "Жыныс:",
    "Birth": "Туылуы:",
    "Month": "Ай",
    "Year": "Жыл",
    "Country_Region": "Ел/аймақ:",
    "Address": "Мекен-жайы:",
    "Save": "Сақтау",
    "Date": "Күн",
    "Male": "Еркек",
    "Female": "Әйел",
    "Unspecified": "Анықталмаған",
    "Security": "Қауіпсіздік",
    "change_password": "Құпия сөзді өзгерту",
    "change_now": "Қазір өзгерту",
    "connect_email": "Электрондық поштаны қосыңыз",
    "delete_account": "Есептік жазбаны жою",
    "delete_account_info": "Тіркелгіңіз жойылғанда, тіркелгіңізге байланысты Менің тіркелгімдегі барлық деректер біржола жойылады және оны қалпына келтіре алмауыңыз мүмкін. Абайлап жүруге кеңес береміз.",
    "Delete": "Жою",
    "Logout": "Шығу",
    "my_profile": "Менің профилім",
    "guides_faqs": "Нұсқаулар және жиі қойылатын сұрақтар",
    "More": "Көбірек",
    "guides": "Гидтер",
    "register": "тіркелу",
    "hot_faq": "Ыстық жиі қойылатын сұрақтар",
    "Contents": "Мазмұны:",
    "contact_us": "Бізбен байланысыңыз>>",
    "plan": "Жоспар",
    "unregistered": "Тіркелмеген",
    "buy_more": "Көбірек сатып алу",
    "buy_again": "Қайтадан сатып алу",
    "buy_now": "Қазір сатып ал",
    "free_no_limit": "Тегін және шектеусіз",
    "expired": "Жарамдылық мерзімі өткен",
    "lifetime": "Өмір кезеңі",
    "remain": "Қалу",
    "day_s": "Күн(дер)",
    "error_24801": "Сұрау орындалмады (қате коды: 24801). Қайталап көріңіз",
    "no_app_found": "Қолданба табылмады，<a href=\"/\">Жаңарту</a> немесе <a href=\"https://www.mindonmap.com/\">Ресми веб-сайтқа өтіңіз</a>",
    "get_more": "Толығырақ >>",
    "edit_photo": "Фотоны өңдеу",
    "select_photo": "Фотосуретті таңдаңыз",
    "change_photo": "Фотосуретті өзгерту",
    "cancel": "Болдырмау",
    "hide_password": "Құпия сөзді жасыру",
    "show_password": "Құпия сөзді көрсету",
    "zoom_in": "Үлкейту",
    "zoom_out": "Кішірейту",
    "rotate": "Айналдыру",
    "horizontal_flip": "Көлденең айналдыру",
    "vertical_flip": "Тік айналдыру",
    "country": "Ел",
    "country_1": "Елді/аймақты таңдаңыз",
    "country_2": "Аланд аралы",
    "country_3": "Ауғанстан",
    "country_4": "Албания",
    "country_5": "Алжир",
    "country_6": "Америкалық Самоа",
    "country_7": "Андорра",
    "country_8": "Ангола",
    "country_9": "Ангилья",
    "country_10": "Антарктида",
    "country_11": "Антигуа және Барбуда",
    "country_12": "Аргентина",
    "country_13": "Армения",
    "country_14": "Аруба",
    "country_15": "Австралия",
    "country_16": "Австрия",
    "country_17": "Әзірбайжан",
    "country_18": "Бахрейн",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Беларусь",
    "country_22": "Бельгия",
    "country_23": "Белиз",
    "country_24": "Бенин",
    "country_25": "Бермуд",
    "country_26": "Бутан",
    "country_27": "Боливия",
    "country_28": "Босния және Герцеговина",
    "country_29": "Ботсвана",
    "country_30": "Буве аралы",
    "country_31": "Бразилия",
    "country_32": "Британ Үнді мұхиты территориясы",
    "country_33": "Британ Виргин аралдары",
    "country_34": "Бруней",
    "country_35": "Болгария",
    "country_36": "Буркина",
    "country_37": "Бурунди",
    "country_38": "Камбоджа",
    "country_39": "Камерун",
    "country_40": "Канада",
    "country_41": "Кабо-Верде",
    "country_42": "Кариб Нидерланды",
    "country_43": "Кайман аралдары",
    "country_44": "Орталық Африка Республикасы",
    "country_45": "Чад",
    "country_46": "Чили",
    "country_47": "Қытай",
    "country_48": "Рождество аралы",
    "country_49": "Кокос (Килинг) аралдары",
    "country_50": "Колумбия",
    "country_51": "Кук аралдары",
    "country_52": "Коста Рика",
    "country_53": "Кот-д'Ивуар",
    "country_54": "Хорватия",
    "country_55": "Куба",
    "country_56": "Кипр",
    "country_57": "Чех Республикасы",
    "country_58": "Конго Демократиялық Республикасы",
    "country_59": "Дания",
    "country_60": "Джибути",
    "country_61": "Доминика",
    "country_62": "Доминикан республикасы",
    "country_63": "Эквадор",
    "country_64": "Египет",
    "country_65": "Сальвадор",
    "country_66": "Экваторлық Гвинея",
    "country_67": "Эритрея",
    "country_68": "Эстония",
    "country_69": "Эфиопия",
    "country_70": "Фолкленд аралдары",
    "country_71": "Фарер аралдары",
    "country_72": "Микронезия федерация  мемлекеттері",
    "country_73": "Фиджи",
    "country_74": "Финляндия",
    "country_75": "Франция",
    "country_76": "Француз Гвианасы",
    "country_77": "Француз полинезиясы",
    "country_78": "Француз оңтүстік аумақтары",
    "country_79": "Габон",
    "country_80": "Гамбия",
    "country_81": "Грузия",
    "country_82": "Германия",
    "country_83": "Гана",
    "country_84": "Гибралтар",
    "country_85": "Ұлыбритания (Біріккен корольдік; Англия)",
    "country_86": "Греция",
    "country_87": "Гренландия",
    "country_88": "Гренада",
    "country_89": "Гваделупа",
    "country_90": "Гуам",
    "country_91": "Гватемала",
    "country_92": "Гернси",
    "country_93": "Гвинея",
    "country_94": "Гвинея-Бисау",
    "country_95": "Гайана",
    "country_96": "Гаити",
    "country_97": "Херд аралы                       аралдар ",
    "country_98": "Гондурас",
    "country_99": "Гонконг",
    "country_100": "Венгрия",
    "country_101": "Исландия",
    "country_102": "Үндістан",
    "country_103": "Индонезия",
    "country_104": "Иран",
    "country_105": "Ирак",
    "country_106": "Ирландия",
    "country_107": "Мэн аралы",
    "country_108": "Израиль",
    "country_109": "Италия",
    "country_110": "Ямайка",
    "country_111": "Жапония",
    "country_112": "Джерси",
    "country_113": "Иордания",
    "country_114": "Қазақстан",
    "country_115": "Кения",
    "country_116": "Кирибати",
    "country_117": "Кувейт",
    "country_118": "Қырғызстан",
    "country_119": "Лаос",
    "country_120": "Латвия",
    "country_121": "Ливан",
    "country_122": "Лесото",
    "country_123": "Либерия",
    "country_124": "Ливия",
    "country_125": "Лихтенштейн",
    "country_126": "Литва",
    "country_127": "Люксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малави",
    "country_131": "Малайзия",
    "country_132": "Мальдив аралдары",
    "country_133": "Мали",
    "country_134": "Мальта",
    "country_135": "Маршалл аралдары",
    "country_136": "Мартиника",
    "country_137": "Мавритания",
    "country_138": "Маврикий",
    "country_139": "Майотта",
    "country_140": "Мексика",
    "country_141": "Молдова",
    "country_142": "Монако",
    "country_143": "Моңғолия",
    "country_144": "Черногория",
    "country_145": "Монцеррат",
    "country_146": "Марокко",
    "country_147": "Мозамбик",
    "country_148": "Мьянма (Бирма)",
    "country_149": "Намибия",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Нидерланды",
    "country_153": "Жаңа Каледония",
    "country_154": "Жаңа зеландия",
    "country_155": "Никарагуа",
    "country_156": "Нигер",
    "country_157": "Нигерия",
    "country_158": "Ниуэ",
    "country_159": "Норфолк аралы",
    "country_160": "Солтүстік корея",
    "country_161": "Солтүстік Мариана аралдары",
    "country_162": "Норвегия",
    "country_163": "Оман",
    "country_164": "Пәкістан",
    "country_165": "Палау",
    "country_166": "Палестина территориялары",
    "country_167": "Панама",
    "country_168": "Папуа Жаңа Гвинея",
    "country_169": "Парагвай",
    "country_170": "Перу",
    "country_171": "Питкэрн аралдары",
    "country_172": "Польша",
    "country_173": "Португалия",
    "country_174": "Пуэрто Рико",
    "country_175": "Катар",
    "country_176": "Македония Республикасы (FYROM)",
    "country_177": "Конго Республикасы",
    "country_178": "Реюньон",
    "country_179": "Румыния",
    "country_180": "Ресей Федерациясы",
    "country_181": "Руанда",
    "country_182": "Сент-Бартелеми",
    "country_183": "Сент Мартин (Франция)",
    "country_184": "Сен-Пьер және Микелон",
    "country_185": "Самоа",
    "country_186": "Сан Марино",
    "country_187": "Сан-Томе және Принсипи",
    "country_188": "Сауд Арабиясы",
    "country_189": "Сенегал",
    "country_190": "Сербия",
    "country_191": "Сейшель аралдары",
    "country_192": "Сьерра-Леоне",
    "country_193": "Сингапур",
    "country_194": "Словакия",
    "country_195": "Словения",
    "country_196": "Соломон аралдары",
    "country_197": "Сомали",
    "country_198": "Оңтүстік Африка",
    "country_199": "Оңтүстік Грузия және Оңтүстік Сэндвич аралы",
    "country_200": "Оңтүстік Корея",
    "country_201": "Оңтүстік Судан",
    "country_202": "Испания",
    "country_203": "Шри Ланка",
    "country_204": "Әулие Елена және тәуелділіктер",
    "country_205": "Сент-Киттс және Невис",
    "country_206": "Сент-Люсия",
    "country_207": "Сент Винсент және Гренадалар",
    "country_208": "Судан",
    "country_209": "Суринам",
    "country_210": "Свазиленд",
    "country_211": "Швеция",
    "country_212": "Швейцария",
    "country_213": "Сирия",
    "country_214": "Тайвань",
    "country_215": "Тәжікстан",
    "country_216": "Танзания",
    "country_217": "Үлгі:Country data SJM Svalbard",
    "country_218": "Тайланд",
    "country_219": "Багам аралдары",
    "country_220": "Комор аралдары",
    "country_221": " Филиппиндер",
    "country_222": "Тимор-Лесте (Шығыс Тимор)",
    "country_223": "Бару",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринидад және Тобаго",
    "country_227": "Тунис",
    "country_228": "Түйетауық",
    "country_229": "Түрікменстан",
    "country_230": "Теркс және Кайкос аралдары",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Украина",
    "country_234": "Біріккен Араб Әмірліктері",
    "country_235": "Америка Құрама Штаттарының Кіші Шеткі аралдары",
    "country_236": "Америка Құрама Штаттары (АҚШ)",
    "country_237": "Америка Құрама Штаттарының Виргин аралдары",
    "country_238": "Уругвай",
    "country_239": "Өзбекстан",
    "country_240": "Вануату",
    "country_241": "Ватикан қаласы (  Әулие          ",
    "country_242": "Венесуэла",
    "country_243": "Вьетнам",
    "country_244": "Уоллис және Футуна",
    "country_245": "Батыс Сахара",
    "country_246": "Йемен",
    "country_247": "Замбия",
    "country_248": "Зимбабве",
    "google_login": "Google арқылы жүйеге кіріңіз",
    "State": "Мемлекет",
    "Activation_code": "Белсендіру коды",
    "Question": "Жүйеге кірген барлық қолданбаларды тізімдеңіз",
    "Copy_complete": "Көшіру аяқталды",
    "footer": "Авторлық құқық © 2024 MindOnMap Studio. Барлық құқықтар сақталған.",
    "change_password_success": "Құпия сөз сәтті өзгертілді",
    "successful_login_title": "Жүйеге кіру сәтті",
    "product_page": "Өнім беті>>",
    "successful_login_info": "Жүйеге кіру аяқталды. Келесі процесті жалғастыру үшін ағымдағы бетті жауып, бастапқы қойындыға оралыңыз. Ағымдағы бет 5 секундтан кейін автоматты түрде жабылады. «Дайын» түймесін басу арқылы автоматты жабу немесе жабу сәтсіз болса, осы қойындыны тікелей жабыңыз.",
    "successful_login_info_firefox": "Жүйеге кіру аяқталды. Келесі процесті жалғастыру үшін ағымдағы бетті жауып, бастапқы қойындыға оралыңыз.",
    "my_account": "Менің аккаунтым",
    "my_history": "Менің тарихым",
    "remove_watermark": "Су таңбасын жою",
    "no_history": "Тарих жоқ",
    "history_all": "Барлығын таңдаңыз",
    "history_open": "Ашық",
    "history_down": "Жүктеп алу",
    "history_delete": "Жою",
    "history_clear": "Жарамсызды жою",
    "images": "кескін(дер)",
    "use_this_function": "Осы өнімді пайдаланыңыз>>",
    "hd_downloading": "HD түпнұсқа кескіндерді жүктеп алудың артықшылықтары:",
    "lifetimeRemaining": "Өмір бойы-қалған",
    "Remaining": "Қалған",
    "email_verification": "Электрондық поштаны тексеру",
    "email_verification_info": "Растау хабарын <span class=\"email\"></span> электрондық поштаңызға жібердік және растауды аяқтаңыз. Тексеруден кейін артықшылықтар автоматты түрде синхрондалады.",
    "wrong_email": "Қате электрондық пошта мекенжайы?",
    "click_here_to_modify": "Өзгерту үшін осы жерді басыңыз",
    "get_more_help": "Қосымша көмек алу керек пе?",
    "click_here": "Мында басыңыз",
    "email_verification_info_success": "Электрондық пошта тіркелгіңізді растағаныңыз үшін құттықтаймыз.",
    "email_verification_info_error": "Сілтеменің мерзімі өтіп кеткендіктен растау сәтсіз аяқталды.",
    "registration_succeeded": "Тіркелу сәтті аяқталды",
    "registration_succeeded_info_1": "Құттықтаймыз! Сіз сәтті тіркелдіңіз. Растау хабарын <span class=\"email\"></span> электрондық поштаңызға жібердік және осы электрондық поштаның артықшылықтарын пайдалану үшін растауды аяқтаңыз.",
    "registration_succeeded_info_2": "Басты бетке оралу және осы өнімді пайдалану үшін «Дайын» түймесін басыңыз.",
    "registration_succeeded_info_3": "Ағымдағы бетті жабу және келесі әрекеттерді орындау үшін негізгі бетке оралу үшін «Дайын» түймесін басыңыз. Жабу сәтсіз болса, қойындыны қолмен жабыңыз.",
    "verify_email": "Электрондық поштаны растау",
    "registered_email_not_verified": "Тіркелген электрондық пошта расталмады, оны бірден растаңыз.",
    "email_verification_time_1": "Растау электрондық поштасын алған жоқсыз ба?",
    "email_verification_time_2": "Кейін",
    "email_verification_time_3": "Қайта жіберу үшін осы жерді басыңыз",
    "error_26301": "Қате коды: 26301, бізге хабарласыңыз",
    "error_26302": "Қате коды: 26302, бізге хабарласыңыз",
    "error_26303": "Электрондық пошта пішімі қатесі (қате коды: 26303). Оны қайтадан енгізіңіз",
    "error_26304": "8 таңбадан жоғары құпия сөз ұсынылады (қате коды: 26304)",
    "error_26305": "Reuqest орындалмады (қате коды: 26305). Қайталап көріңіз",
    "error_26306": "Электрондық пошта тіркелді, <a href=\"https://account.mindonmap.com/login/\">кіруге</a> өтіңіз.",
    "error_26307": "Reuqest орындалмады (қате коды: 26307). Қайталап көріңіз",
    "error_26308": "Reuqest орындалмады (қате коды: 26308). Қайталап көріңіз",
    "error_26401": "Қате коды: 26401, қайталап көріңіз",
    "error_26402": "Электрондық пошта расталды (қате коды: 26402), қайталап көріңіз",
    "error_26403": "Reuqest орындалмады (қате коды: 26403). Қайталап көріңіз",
    "error_26404": "Сұрау орындалмады (қате коды: 26404). Қайталап көріңіз",
    "error_26501": "Қате коды: 26501, бізге хабарласыңыз",
    "error_26502": "Қате коды: 26502, бізге хабарласыңыз",
    "error_26503": "Электрондық пошта пішімі қатесі (қате коды: 26503). Оны қайтадан енгізіңіз",
    "error_26504": "Reuqest орындалмады (қате коды: 26504). Қайталап көріңіз",
    "error_26505": "Электрондық пошта тіркелмеген, <a href=\"https://account.mindonmap.com/register/\">алдымен оны тіркеңіз</a>",
    "error_26506": "Электрондық пошта расталды.",
    "error_26507": "Reuqest орындалмады (қате коды: 26507). Қайталап көріңіз",
    "error_26508": "Тексеру бұзылды (қате коды: 26508), қайталап көріңіз",
    "error_26509": "Сұрау орындалмады (қате коды: 26509), қайталап көріңіз",
    "error_26510": "Қате коды: 26510, бізге хабарласыңыз",
    "error_26601": "Қате коды: 26601, бізге хабарласыңыз",
    "error_26602": "Қате коды: 26602, бізге хабарласыңыз",
    "error_26603": "Reuqest орындалмады (қате коды: 26603). Қайталап көріңіз",
    "error_26604": "Қате коды: 26604, бізге хабарласыңыз",
    "error_26605": "Қате коды: 26605, бізге хабарласыңыз",
    "error_26701": "Қате коды: 26701, бізге хабарласыңыз",
    "error_26702": "Reuqest орындалмады (қате коды: 26702). Қайталап көріңіз",
    "error_26703": "Қате коды: 26703, бізге хабарласыңыз",
    "error_26704": "Қате коды: 26704, бізге хабарласыңыз",
    "error_26705": "Жүйеге кіруді күтіңіз (қате коды: 26705). Қайталап көріңіз",
    "no_cookie": "Браузеріңізде «Барлық cookie файлдарын блоктау» функциясын қостыңыз, сондықтан жүйеге кіре алмайсыз. «Барлық cookie файлдарына рұқсат беру» құсбелгісін қою үшін «Параметрлер» бөліміне өтіңіз.",
    "error_26801": "Қате коды: 26801, бізге хабарласыңыз",
    "error_26802": "Қате коды: 26802, бізге хабарласыңыз",
    "error_26803": "Сұрау орындалмады (қате коды: 26803). Қайталап көріңіз",
    "error_26804": "Сұрау орындалмады (қате коды: 26804). Қайталап көріңіз",
    "error_order": "Сұрау орындалмады (қате коды: 27098), қайталап көріңіз!",
    "error_order1": "Тапсырыс сұрауы толық емес（қате коды: ",
    "error_order2": "）, жаңартып, қайталап көріңіз.",
    "modify_email_title": "Электрондық поштаны өзгерту",
    "modify_email_info": "Тіркелгіңізге кіру үшін өзгертілген электрондық поштаны пайдалана аласыз.",
    "images_per": "Суреттер",
    "error_26101": "Қате: 26101. Бізбен хабарласыңыз",
    "error_26102": "Қате: 26102. Бізбен хабарласыңыз",
    "error_26103": "Сұрау сәтсіз (қате коды:26103). Қайталап көріңіз",
    "error_26104": "Қате коды: 26104, қайталап көріңіз",
    "error_26105": "Қате коды: 26105, қайталап көріңіз",
    "error_26106": "Жою сәтсіз (қате коды: 26106). Қайталап көріңіз",
    "error_26201": "Қате: 26201. Бізбен хабарласыңыз",
    "error_26202": "Сұрау сәтсіз (қате коды:26202). Қайталап көріңіз",
    "error_26001": "Қате: 26001. Бізбен хабарласыңыз",
    "error_26002": "Қате: 26002. Бізбен хабарласыңыз",
    "error_26003": "Қате: 26003. Бізбен хабарласыңыз",
    "error_26004": "Қате: 26004. Бізбен хабарласыңыз",
    "error_26005": "Сұрау сәтсіз (қате коды:26005). Қайталап көріңіз",
    "error_26006": "Қате коды: 26006, қайталап көріңіз",
    "error_26008": "Қате: 26008. Бізбен хабарласыңыз",
    "go_to_the_home_page": "Негізгі бетке өтіңіз",
    "error_27101": "Сұрау орындалмады (қате коды: 27101). Қайталап көріңіз",
    "error_27201": "Қате коды: 27201, бізге хабарласыңыз",
    "error_27202": "Қате коды: 27202, қайталап көріңіз",
    "error_27203": "Сұрау орындалмады (қате коды: 27203). Қайталап көріңіз",
    "error_27204": "Жарамсыз код (қате коды: 27204).",
    "error_27205": "Сұрау орындалмады (қате коды: 27205). Қайталап көріңіз",
    "error_27206": "Сұрау орындалмады (қате коды: 27206). Қайталап көріңіз",
    "error_27207": "Сұрау орындалмады (қате коды: 27207). Қайталап көріңіз",
    "no_history_found": "Сіз ешқандай құралды пайдаланбағансыз! <a href=\"/\">Жаңарту</a> немесе <a href=\"https://www.mindonmap.com/\">Ресми веб-сайтқа өтіңіз</a>",
    "error_25301": "Қате: 25301. Бізбен хабарласыңыз",
    "error_25302": "Қате: 25302. Бізбен хабарласыңыз",
    "error_25303": "Сұрау сәтсіз (қате коды: 25303). Қайталап көріңіз",
    "error_25304": "Сұрау сәтсіз (қате коды: 25304). Қайталап көріңіз",
    "error_25305": "Сұраныс сәтсіз (қате коды: 25305). Қайталап көріңіз",
    "error_25306": "Сұрау сәтсіз (қате коды: 25306). Қайталап көріңіз",
    "image_upscaler_p": "Су таңбасы жоқ кескінді жүктеп алудың артықшылықтары:",
    "Available_for": "Қолжетімді:",
    "credit_per": "HD кескініне %s несие",
    "still_valid": "Сатып алынған жоспар(лар) әлі де жарамды",
    "credit": "несие(лер)",
    "pc_3rd_info": "Жүйеге сәтті кіріңіз. Одан әрі жұмыс істеу үшін қолданбаға өтіңіз.",
    "use_online": "Онлайн қызметін пайдаланыңыз",
    "use_download": "Жұмыс үстелі бағдарламасын пайдаланыңыз",
    "use_immediately": "Бірден пайдаланыңыз",
    "Use_in_browser": "Браузерде пайдаланыңыз",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "Айына {%} несие",
    "expire": "Жарамдылық мерзімі:",
    "viewDetails": "Мәліметтерді көру",
    "viewHistory": "Тарих артықшылықтарын көру>>",
    "viewDetailsInfo": "Ескерту: Жазылым жеңілдіктері жарамдылық мерзімі аяқталғаннан кейін 7 күн ішінде жаңартылса, пайдаланылмаған артықшылықтарды пайдалануды жалғастыруға болады. Сондай-ақ, жарамдылық мерзімі жаңа жазылымның аяқталу уақытына автоматты түрде жаңартылады. Жарамдылық мерзімі аяқталғаннан кейін 7 күн өткен соң жаңа жазылым болмаса, мерзімі өткен барлық жеңілдіктер жойылады.",
    "connect_account": "Электрондық поштаны тіркелгіңізге байланыстырыңыз",
    "connect_account_info": "Байланыстырғаннан кейін сіз осы электрондық пошта мекенжайымен жүйеге кіре аласыз.",
    "connect_now": "Қазір байлау",
    "no_email_bind": "Электрондық пошта байланысы жоқ",
    "bind_email": "Электрондық поштаны байланыстыру",
    "connect_your_email_placeholder": "Электрондық пошта мекенжайыңызды енгізіңіз",
    "bind_an_email": "Электрондық поштаны байланыстыру",
    "bind_info": "Сіз жүйеге сәтті кірдіңіз. Артықшылықтарды белсендіру үшін тіркелгіңізге электрондық поштаны байланыстырыңыз.",
    "bind_later": "Кейінірек байлаңыз",
    "hi": "Сәлем, %s!",
    "Personal_Information": "Жеке ақпарат",
    "Access": "Қол жеткізу",
    "Subscription_Plan": "(Жазылым жоспары)",
    "No_orders": "Тапсырыс табылмады.",
    "No_data": "Деректер жоқ",
    "Featured_Products": "Таңдаулы өнімдер",
    "More_Products": "Қосымша өнімдер",
    "Free_Download": "Тегін жүктеу",
    "Get_Started": "Жұмысты бастау",
    "Subscribe": "Жазылу",
    "Verified": "Тексерілді",
    "back_to_account_center": "Есептік жазба орталығына оралу",
    "success": "Жетістік!",
    "successfully": "Сіз тіркелгіні сәтті тіркедіңіз.",
    "Continue": "Жалғастыру",
    "Already": "Есептік жазбаңыз бар ма?",
    "loading_verification": "Тексеру күйін тексеру...",
    "email_no_verification": "Кешіріңіз, тіркелген электрондық пошта мекенжайы расталмады. Жоғарыдағы нұсқауларға сәйкес тексеруді аяқтаңыз және тіркеуді аяқтау үшін «Дайын» түймесін қайта басыңыз.",
    "will_expire_after": "Кейін мерзімі бітеді",
    "hours": "сағат",
  },
  "km": {
    "overtime": "លេខកូដកំហុស៖ {%} សូមព្យាយាមម្តងទៀត",
    "isnetwork": "កំហុសអ៊ីនធឺណិត។ សូមពិនិត្យ និងព្យាយាមម្តងទៀត",
    "email_placeholder": "អ៊ីមែល",
    "email_empty": "សូមបញ្ចូលអ៊ីមែល",
    "email_not_valid": "អ៊ីមែលមិនត្រឹមត្រូវទេ។",
    "email_not_valid_1": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក។",
    "email_not_valid_2": "អ៊ីមែលមិនត្រឹមត្រូវទេ សូមប្រើអាសយដ្ឋានផ្សេង។",
    "email_not_valid_3": "គ្មានការបញ្ចូលអ៊ីមែលទេ។",
    "password_placeholder": "ពាក្យសម្ងាត់",
    "password_empty": "សូមបញ្ចូលពាក្យសម្ងាត់",
    "password_not_valid": "គណនី ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
    "password_not_valid_1": "ទាមទារពាក្យសម្ងាត់លើសពី 8 តួអក្សរ",
    "password_not_valid_2": "សូមបង្កើតពាក្យសម្ងាត់",
    "password_placeholder_1": "បង្កើតពាក្យសម្ងាត់របស់អ្នក។",
    "password_placeholder_2": "បញ្ជាក់ពាក្យសម្ងាត់របស់អ្នក។",
    "password_placeholder_3": "បង្កើតពាក្យសម្ងាត់ថ្មី។",
    "password_placeholder_4": "បញ្ជាក់​លេខសំងាត់​ថ្មី",
    "password_placeholder_5": "បញ្ចូលពាក្យសម្ងាត់ចាស់",
    "copy_password_empty": "សូមបញ្ជាក់ពាក្យសម្ងាត់",
    "copy_password_not_valid": "សូមបញ្ជាក់ពាក្យសម្ងាត់របស់អ្នក។",
    "copy_passwords_inconsistent": "ការបញ្ជាក់ពាក្យសម្ងាត់របស់អ្នកមិនត្រូវគ្នាទេ។",
    "code_empty": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់",
    "code_not_valid": "លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ",
    "code_placeholder": "កូដ​ផ្ទៀង​ផ្ទាត់",
    "not_received_code": "ប្រសិនបើប្រអប់សំបុត្ររបស់អ្នកមិនបានទទួលលេខកូដផ្ទៀងផ្ទាត់យូរទេ សូមយកលេខកូដផ្ទៀងផ្ទាត់ម្តងទៀត។",
    "get_first_code": "សូមយកលេខកូដផ្ទៀងផ្ទាត់ជាមុនសិន។",
    "last_name_placeholder": "សូមបញ្ចូលនាមត្រកូលរបស់អ្នក។",
    "first_name_placeholder": "សូមបញ្ចូលឈ្មោះរបស់អ្នក។",
    "address_placeholder": "សូមបញ្ចូលអាសយដ្ឋានរបស់អ្នក។",
    "no_code_text": "យើងបានផ្ញើលេខកូដផ្ទៀងផ្ទាត់។ សូមបញ្ចូលលេខកូដរបស់អ្នក។ <span class='tips'>មិនបានទទួលលេខកូដមែនទេ?",
    "no_code_text_1": "1. សូមប្រាកដថាអាសយដ្ឋានអ៊ីមែលមានសុពលភាព ហើយវាអាចទទួលបានអ៊ីមែល។",
    "no_code_text_2": "2. សម្រាប់អ៊ីមែលត្រូវបានផ្ញើដោយប្រព័ន្ធដោយស្វ័យប្រវត្តិ វាអាចត្រូវបានដាក់ទង់ថាជាសារឥតបានការ ឬអ៊ីមែលឥតបានការ។ សូមពិនិត្យមើលថាតើអ៊ីមែលស្ថិតនៅក្នុងថតធុងសំរាមឬអត់។",
    "no_code_text_3": "3. មិនអាចដោះស្រាយបញ្ហារបស់អ្នកបានទេ? ",
    "no_code_text_3_span": "បន្ទាប់មក សូមចុចទីនេះ ដើម្បីទាក់ទងមកយើងខ្ញុំ។",
    "order_no": "អ្នកមិនទាន់បានទិញផលិតផលណាមួយទេ ប្រសិនបើអ្នកមានចម្ងល់សូម <a href=\"https://www.mindonmap.com/contact-us/\">ទាក់ទងមកយើងខ្ញុំ</a>។",
    "error_24901": "គណនីបច្ចុប្បន្នមិនមានភ្ជាប់អ៊ីមែលទេ ហើយមិនអាចស្វែងរកការបញ្ជាទិញបានទេ។ សូមភ្ជាប់អ៊ីមែល។",
    "user_guide": "ការណែនាំអ្នកប្រើប្រាស់ >>",
    "download": "ទាញយក",
    "order_number": "លេខ​លំដាប់:",
    "Refund": "សងប្រាក់វិញ។",
    "Disabled": "ពិការ",
    "Normal": "ធម្មតា។",
    "Modify": "កែប្រែ",
    "Modify_1": "កែប្រែ >>",
    "Connect": "ភ្ជាប់",
    "unlink_success": "ផ្ដាច់ដោយជោគជ័យ",
    "connect_success": "ភ្ជាប់ដោយជោគជ័យ",
    "feedback_title": "អរគុណសម្រាប់មតិកែលម្អរបស់អ្នក។ សូមទុកបញ្ហារបស់អ្នក ហើយយើងនឹងឆ្លើយតបទៅអ្នកក្នុងរយៈពេល 24 ម៉ោង។",
    "feedback_thank_you": "សូមអរគុណ!<br />មតិរបស់អ្នកត្រូវបានដាក់ជូនដោយជោគជ័យ។",
    "feedback_email": "បញ្ចូលអ៊ីមែលរបស់អ្នកនៅទីនេះ!",
    "feedback_content": "ទុកបញ្ហា ឬការណែនាំដែលអ្នកជួបប្រទះនៅទីនេះ។",
    "feedback_submit": "ដាក់ស្នើ",
    "form_contents": "អ្នកមិនទាន់បានបញ្ចូលការពិពណ៌នាណាមួយទេ។ សូមបញ្ចូលវា ហើយដាក់ស្នើម្តងទៀត។",
    "old_password": "សូមបញ្ចូលពាក្យសម្ងាត់ចាស់",
    "new_password": "សូមបង្កើតពាក្យសម្ងាត់ថ្មី។",
    "old_new_password": "ពាក្យសម្ងាត់ថ្មីមិនអាចដូចគ្នានឹងលេខសម្ងាត់ចាស់ទេ។",
    "incorrect_password": "ពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
    "delete_no": "លុបឥឡូវនេះ",
    "Caps": "Caps Lock ត្រូវបានបើក",
    "Get": "ទទួលបាន",
    "Done": "រួចរាល់",
    "error_20001": "កំហុស៖ 20001។ សូមចូលម្តងទៀត។",
    "error_20002": "កំហុស៖ 20002។ សូមចូលម្តងទៀត។",
    "error_20003": "កំហុស៖ 20003។ សូមចូលម្តងទៀត។",
    "error_20004": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20004)។ សូមព្យាយាមម្តងទៀត។",
    "error_20005": "វគ្គចូលបានផុតកំណត់ (កំហុស៖ 20005)។ សូមចូលម្តងទៀត។",
    "error_20006": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20006)។ សូមព្យាយាមម្តងទៀត។",
    "error_20007": "វគ្គចូលបានផុតកំណត់ (កំហុស៖ 20007)។ សូមចូលម្តងទៀត។",
    "error_20008": "វគ្គចូលបានផុតកំណត់ (កំហុស៖ 20008)។ សូមចូលម្តងទៀត។",
    "error_20009": "វគ្គចូលបានផុតកំណត់ (កំហុស៖ 20009)។ សូមចូលម្តងទៀត។",
    "error_20101": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20101)",
    "error_20102": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20102)",
    "error_20103": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20103)។ សូមព្យាយាមម្តងទៀត",
    "error_20104": "អ៊ីមែលត្រូវបានប្រើប្រាស់រួចហើយ សូម <a href=\"https://account.mindonmap.com/login/\">ចូល</a> ឬចុះឈ្មោះជាមួយថ្មីមួយ",
    "error_20105": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20105)។ សូមព្យាយាមម្តងទៀត",
    "error_20106": "បរាជ័យក្នុងការផ្ញើអ៊ីមែល សូមព្យាយាមម្តងទៀត",
    "error_20201": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20201)",
    "error_20202": "សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នក (លេខកូដកំហុស៖ 20202)",
    "error_20203": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 20203)",
    "error_20204": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20204)",
    "error_20205": "ទាមទារពាក្យសម្ងាត់លើសពី 8 តួអក្សរ (លេខកូដកំហុស៖ 20205)",
    "error_20206": "ការស្នើសុំបានបរាជ័យ (កូដកំហុស៖ 20206)។ សូមព្យាយាមម្តងទៀត",
    "error_20207": "លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ",
    "error_20208": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20208)។ សូមព្យាយាមម្តងទៀត",
    "error_20209": "ការស្នើសុំបានបរាជ័យ (លេខកូដកំហុស៖ 20209)។ សូមព្យាយាមម្តងទៀត",
    "error_20301": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20301)",
    "error_20302": "កំហុស៖ 20302. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_20303": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20303)",
    "error_20304": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20304)។ សូមព្យាយាមម្តងទៀត",
    "error_20305": "គណនីមិនមានទេ។ សូមបញ្ចូលម្តងទៀត ឬ <a href=\"https://account.mindonmap.com/register/\">បង្កើតវា</a> ជាមុនសិន។",
    "error_20306": "មិនទាន់មានពាក្យសម្ងាត់ទេ។ ប្រើ <a href=\"https://account.mindonmap.com/passwordless-login/\">ការចូលដោយគ្មានពាក្យសម្ងាត់</a> ឬ <a href=\"https://account.mindonmap.com/create-password/\"> កំណត់ពាក្យសម្ងាត់</a> ហើយចូល។",
    "error_20308": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20308)។ សូមព្យាយាមម្តងទៀត",
    "error_20401": "បរាជ័យក្នុងការចេញ (កូដកំហុស៖ 20401)។ សូមព្យាយាមម្តងទៀត",
    "error_20501": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20501)",
    "error_20502": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20502)",
    "error_20503": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២០៥០៣)។ សូមព្យាយាមម្តងទៀត",
    "error_20504": "បរាជ័យក្នុងការផ្ញើអ៊ីមែល។ សូមព្យាយាមម្តងទៀត។",
    "error_20601": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20601)",
    "error_20602": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 20602)",
    "error_20603": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20603)",
    "error_20604": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20604)។ សូមព្យាយាមម្តងទៀត",
    "error_20606": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20606)។ សូមព្យាយាមម្តងទៀត",
    "error_20607": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20607)។ សូមព្យាយាមម្តងទៀត",
    "error_20608": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20608)។ សូមព្យាយាមម្តងទៀត",
    "error_20701": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20701)",
    "error_20702": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20702)",
    "error_20703": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20703)។ សូមព្យាយាមម្តងទៀត",
    "error_20704": "គណនីមិនមានទេ។ សូមបញ្ចូលម្តងទៀត ឬ <a href=\"https://account.mindonmap.com/register/\">បង្កើតវា</a> ជាមុនសិន។",
    "error_20705": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20705)។ សូមព្យាយាមម្តងទៀត",
    "error_20706": "បរាជ័យក្នុងការផ្ញើអ៊ីមែល។ សូមព្យាយាមម្តងទៀត",
    "error_20801": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 20801)",
    "error_20802": "កំហុស៖ 20802. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_20803": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 20803)",
    "error_20804": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ 20804)",
    "error_20805": "ពាក្យសម្ងាត់លើសពី 8 តួអក្សរត្រូវបានទាមទារ (លេខកូដកំហុស: 20805)",
    "error_20806": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20806)។ សូមព្យាយាមម្តងទៀត",
    "error_20808": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20808)។ សូមព្យាយាមម្តងទៀត",
    "error_20901": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20901)។ សូមព្យាយាមម្តងទៀត",
    "error_20902": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 20902)។ សូមព្យាយាមម្តងទៀត",
    "error_21000": "ការផ្លាស់ប្តូរត្រូវបានរក្សាទុក",
    "error_21001": "គ្មាន​ព័ត៌មាន​ត្រូវ​បាន​ដាក់​ជូន​ទេ (កូដ​កំហុស៖ 21001)",
    "error_21002": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21002)។ សូមព្យាយាមម្តងទៀត",
    "error_21101": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ ២១១០១)",
    "error_21102": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ ២១១០២)",
    "error_21103": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21103) សូមព្យាយាមម្តងទៀត",
    "error_21104": "អ៊ីមែលត្រូវបានភ្ជាប់រួចហើយ សូមប្រើអ៊ីមែលថ្មី។",
    "error_21105": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21105) សូមព្យាយាមម្តងទៀត",
    "error_21106": "បរាជ័យក្នុងការផ្ញើអ៊ីមែល។ សូមព្យាយាមម្តងទៀត",
    "error_21201": "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក (លេខកូដកំហុស៖ 21201)",
    "error_21202": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 21202)",
    "error_21203": "អ៊ីមែលមិនត្រឹមត្រូវទេ (លេខកូដកំហុស៖ ២១២០៣)",
    "error_21204": "កំហុស៖ 21204. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21205": "កំហុស៖ 21205. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21206": "ពាក្យសម្ងាត់លើសពី 8 តួអក្សរត្រូវបានទាមទារ (លេខកូដកំហុស: 21206)",
    "error_21207": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21207)។ សូមព្យាយាមម្តងទៀត",
    "error_21209": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21209)។ សូមព្យាយាមម្តងទៀត",
    "error_21301": "សូមបញ្ចូលពាក្យសម្ងាត់ចាស់ (លេខកូដកំហុស៖ 21301)",
    "error_21302": "សូមបង្កើតពាក្យសម្ងាត់ថ្មី (លេខកូដកំហុស៖ 21302)",
    "error_21303": "ពាក្យសម្ងាត់ថ្មីមិនអាចដូចគ្នានឹងលេខសម្ងាត់ចាស់ទេ។ (កំហុស៖ ២១៣០៣)",
    "error_21304": "ពាក្យសម្ងាត់លើសពី 8 តួអក្សរត្រូវបានទាមទារ (លេខកូដកំហុស: 21304)",
    "error_21306": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21306)។ សូមព្យាយាមម្តងទៀត",
    "error_21402": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21402)។ សូមព្យាយាមម្តងទៀត",
    "error_21403": "បរាជ័យក្នុងការផ្ញើលេខកូដផ្ទៀងផ្ទាត់។ សូមផ្ញើវាឡើងវិញ",
    "error_21500": "គណនីត្រូវបានលុប",
    "error_21501": "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 21501)",
    "error_21502": "វគ្គចូលបានផុតកំណត់ (កំហុស៖ 21502)។ សូមចូលម្តងទៀត។",
    "error_21503": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21503)។ សូមព្យាយាមម្តងទៀត",
    "error_21505": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21505) សូមព្យាយាមម្តងទៀត",
    "error_21601": "កំហុស៖ 20601. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21602": "ការផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ (កំហុស៖ 20602)។ សូមព្យាយាមម្តងទៀត។",
    "error_21603": "កំហុស៖ 20603។ សូមព្យាយាមម្តងទៀត",
    "error_21604": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21604)។ សូមព្យាយាមម្តងទៀត",
    "error_21606": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21606)។ សូមព្យាយាមម្តងទៀត",
    "error_21611": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21611)។ សូមព្យាយាមម្តងទៀត",
    "error_21801": "កំហុស៖ 21801. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21802": "សំណើបានបរាជ័យ (កំហុស៖ 21802)។ សូមព្យាយាមម្តងទៀត",
    "error_21803": "កំហុស៖ 21803។ សូមព្យាយាមម្តងទៀត",
    "error_21804": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21804)។ សូមព្យាយាមម្តងទៀត",
    "error_21806": "កំហុស៖ 21806។ សូមព្យាយាមម្តងទៀត",
    "error_21807": "កំហុស៖ 21807. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21808": "កំហុស៖ 21808. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21809": "កំហុស៖ 21809. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21810": "កំហុស៖ 21810. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21811": "កំហុស៖ 21811. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21812": "កំហុស៖ 21812. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21813": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21813)។ សូមព្យាយាមម្តងទៀត",
    "error_21814": "កំហុស៖ 21814. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21815": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21815)។ សូមព្យាយាមម្តងទៀត",
    "error_21816": "កំហុស៖ 21816. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21817": "កំហុស៖ 21817. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21818": "កំហុស៖ 21818. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21819": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21819)។ សូមព្យាយាមម្តងទៀត",
    "error_21820": "កំហុស៖ 21820. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21821": "កំហុស៖ 21821. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21822": "កំហុស៖ 21822. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21823": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21823)។ សូមព្យាយាមម្តងទៀត",
    "error_21824": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21824)។ សូមព្យាយាមម្តងទៀត",
    "error_21825": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21825)។ សូមព្យាយាមម្តងទៀត",
    "error_21826": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21826)។ សូមព្យាយាមម្តងទៀត",
    "error_21901": "កំហុស៖ 21901. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_21902": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21902)។ សូមព្យាយាមម្តងទៀត",
    "error_21903": "ស្ថានភាពគណនីបានផ្លាស់ប្តូរ (លេខកូដកំហុស៖ 21903) សូមផ្ទុកទំព័រឡើងវិញ ហើយព្យាយាមម្តងទៀត",
    "error_21904": "កំហុស៖ 21904។ សូមព្យាយាមម្តងទៀត",
    "error_21905": "កំហុស៖ 21905។ សូមព្យាយាមម្តងទៀត",
    "error_21906": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21906)។ សូមព្យាយាមម្តងទៀត",
    "error_21907": "គណនី Google ត្រូវបានភ្ជាប់ទៅគណនីផ្សេងទៀត។",
    "error_21908": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 21908)។ សូមព្យាយាមម្តងទៀត",
    "error_22001": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22001)។ សូមព្យាយាមម្តងទៀត",
    "error_22002": "ការផ្តាច់តំណភ្ជាប់បានបរាជ័យដោយមិនមានការចូលបន្ថែម",
    "error_22003": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22003)។ សូមព្យាយាមម្តងទៀត",
    "error_22101": "កំហុស៖ 22101. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22102": "ស្ថានភាពគណនីបានផ្លាស់ប្តូរ (លេខកូដកំហុស៖ 22102) សូមផ្ទុកទំព័រឡើងវិញ ហើយព្យាយាមម្តងទៀត",
    "error_22103": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២២១០៣)។ សូមព្យាយាមម្តងទៀត",
    "error_22104": "ស្ថានភាពគណនីបានផ្លាស់ប្តូរ (លេខកូដកំហុស៖ 22104) សូមផ្ទុកទំព័រឡើងវិញ ហើយព្យាយាមម្តងទៀត",
    "error_22105": "កំហុស៖ 22105។ សូមព្យាយាមម្តងទៀត",
    "error_22106": "កំហុស៖ 22106។ សូមព្យាយាមម្តងទៀត",
    "error_22107": "កំហុស៖ 22107. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22108": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២២១០៨)។ សូមព្យាយាមម្តងទៀត",
    "error_22201": "កំហុស៖ 22201. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22202": "ការផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ (កំហុស៖ ២២២០២)។ សូមព្យាយាមម្តងទៀត។",
    "error_22203": "កំហុស៖ 22203។ សូមព្យាយាមម្តងទៀត\"",
    "error_22204": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២២២០៤)។ សូមព្យាយាមម្តងទៀត",
    "error_22206": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២២២០៦)។ សូមព្យាយាមម្តងទៀត",
    "error_22401": "កំហុស៖ 22401. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22402": "ការផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ (កំហុស៖ 22402)។ សូមព្យាយាមម្តងទៀត។",
    "error_22403": "កំហុស៖ 22403។ សូមព្យាយាមម្តងទៀត",
    "error_22404": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22404)។ សូមព្យាយាមម្តងទៀត",
    "error_22405": "គណនី Facebook ត្រូវបានភ្ជាប់ទៅអ៊ីមែលផ្សេងទៀត។",
    "error_22406": "កំហុស៖ 22406។ សូមព្យាយាមម្តងទៀត",
    "error_22407": "កំហុស៖ 22407. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22408": "កំហុស៖ 22408. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22409": "កំហុស៖ 22409. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22410": "កំហុស៖ 224010. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22411": "កំហុស៖ 224011. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22412": "កំហុស៖ 224012។ សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22413": "កំហុស៖ 22413. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22414": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22414)។ សូមព្យាយាមម្តងទៀត",
    "error_22415": "កំហុស៖ 22415. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22416": "កំហុស៖ 22416. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22417": "កំហុស៖ 22417. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22418": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22418)។ សូមព្យាយាមម្តងទៀត",
    "error_22419": "កំហុស៖ 22419។ សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22420": "កំហុស៖ 22420. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22421": "កំហុស៖ 22421. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_22422": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22422)។ សូមព្យាយាមម្តងទៀត",
    "error_22423": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22423)។ សូមព្យាយាមម្តងទៀត",
    "error_22424": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22424)។ សូមព្យាយាមម្តងទៀត",
    "error_22425": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 22425)។ សូមព្យាយាមម្តងទៀត",
    "error_20098": "លេខកូដកំហុស៖ 20098. ប្រសិនបើអ្នកកំពុងរុករកក្នុងទម្រង់ឯកជន សូមប្តូរទៅរបៀបធម្មតា ហើយព្យាយាមម្តងទៀត។",
    "error_22298": "សំណើ Google បានបរាជ័យ (កំហុស៖ 22298)។ សូមព្យាយាមម្តងទៀត។",
    "error_22498": "ការស្នើសុំ Facebook បានបរាជ័យ (កំហុស៖ 22498)។ សូមព្យាយាមម្តងទៀត។",
    "error_24902": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 24902)។ សូមព្យាយាមម្តងទៀត",
    "error_24903": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 24903)។ សូមព្យាយាមម្តងទៀត",
    "error_24904": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 24904)។ សូមព្យាយាមម្តងទៀត",
    "error_24905": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 24905)។ សូមព្យាយាមម្តងទៀត",
    "login_title": "ចូលទៅ MindOnMap",
    "log_in": "ចូល",
    "no_account": "គ្មានគណនី?",
    "create_it": "បង្កើតវា។",
    "or_log_in_with": "ឬចូលជាមួយ",
    "passwordless_login": "ការចូលដោយគ្មានពាក្យសម្ងាត់",
    "log_in_done": "ចូល រួចរាល់",
    "three_rd_account_connect_info": "អបអរសាទរ! អ្នកបានចូលដោយជោគជ័យ។ ឥឡូវនេះអ្នកអាចភ្ជាប់គណនីអ៊ីមែលមួយដែលត្រូវបានប្រើដើម្បីចូលនាពេលអនាគត។",
    "see_my_account": "មើលគណនីរបស់ខ្ញុំ",
    "three_rd_login_merge_account": "អាសយដ្ឋានអ៊ីមែលគណនីភាគីទីបីត្រូវបានចុះឈ្មោះ តើអ្នកចង់ភ្ជាប់ និងចូលដោយប្រើអាសយដ្ឋានអ៊ីមែលនេះដោយផ្ទាល់ទេ?",
    "connect_log_in": "ភ្ជាប់ & ចូល",
    "create_an_account": "បង្កើត​គណនី",
    "back_to_log_in": "ត្រឡប់ទៅ ចូល វិញ",
    "create_password": "បង្កើត​ពាក្យ​សម្ងាត់",
    "create_now": "បង្កើតឥឡូវនេះ",
    "password_login_subtitle": "ការចូលដោយគ្មានពាក្យសម្ងាត់ដោយប្រើអ៊ីមែល",
    "account_login": "ការចូលគណនី",
    "rights": "តាមរយៈការបង្កើតគណនីនេះ អ្នកយល់ព្រមជាមួយ <a href=\"https://www.mindonmap.com/terms-and-conditions/\">លក្ខខណ្ឌប្រើប្រាស់</a> និង <a href=\"https://www.mindonmap.com/privacy-policy/\">គោលការណ៍ឯកជនភាព</a>",
    "passwordless_login_done": "ការចូលដោយគ្មានពាក្យសម្ងាត់បានបញ្ចប់",
    "passwordless_login_info": "សូមអបអរសាទរ អ្នកបានបញ្ចប់ការចូលដោយគ្មានពាក្យសម្ងាត់ដោយជោគជ័យ។ អ្នកអាចបង្កើតពាក្យសម្ងាត់សម្រាប់គណនីនេះ ហើយចូលដោយប្រើគណនី និងពាក្យសម្ងាត់នាពេលអនាគត។ <a href=\"/create-password\" style=\"display: initial;\">បង្កើតឥឡូវនេះ</a>",
    "sign_up": "ចុះ​ឈ្មោះ",
    "register_info": "បង្កើតគណនីរបស់អ្នក។",
    "reset_now": "កំណត់ឡើងវិញឥឡូវនេះ",
    "forgot_password": "ភ្លេច​លេខសំងាត់​",
    "reset_password_subtitle": "ប្រើអ៊ីមែលគណនីរបស់អ្នកដើម្បីកំណត់ពាក្យសម្ងាត់ឡើងវិញ",
    "plan_products": "គម្រោង និងផលិតផល",
    "nick_name": "ឈ្មោះ​អ្នកប្រើប្រាស់:",
    "email": "អ៊ីមែល៖",
    "my_products": "ផលិតផលរបស់ខ្ញុំ",
    "my_orders": "ការបញ្ជាទិញរបស់ខ្ញុំ",
    "unlink": "ផ្ដាច់",
    "link": "តំណភ្ជាប់",
    "connected_accounts": "គណនីដែលបានភ្ជាប់",
    "last_name": "នាមត្រកូល:",
    "first_name": "ឈ្មោះដំបូង៖",
    "Gender": "ភេទ៖",
    "Birth": "កំណើត៖",
    "Month": "ខែ",
    "Year": "ឆ្នាំ",
    "Country_Region": "ប្រទេស/តំបន់៖",
    "Address": "អាស័យដ្ឋាន៖",
    "Save": "រក្សាទុក",
    "Date": "កាលបរិច្ឆេទ",
    "Male": "ប្រុស",
    "Female": "ស្រី",
    "Unspecified": "មិនបានបញ្ជាក់",
    "Security": "សន្តិសុខ",
    "change_password": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    "change_now": "ផ្លាស់ប្តូរឥឡូវនេះ",
    "connect_email": "ភ្ជាប់អ៊ីមែល",
    "delete_account": "លុប​គណនី",
    "delete_account_info": "នៅពេលដែលគណនីរបស់អ្នកត្រូវបានលុប ទិន្នន័យទាំងអស់នៅក្នុងគណនីរបស់ខ្ញុំដែលភ្ជាប់ជាមួយគណនីរបស់អ្នកនឹងត្រូវបានលុបជាអចិន្ត្រៃយ៍ ហើយអ្នកប្រហែលជាមិនអាចយកវាមកវិញបានទេ។ យើងណែនាំអ្នកឱ្យដើរដោយប្រយ័ត្នប្រយែង។",
    "Delete": "លុប",
    "Logout": "ចាកចេញ",
    "my_profile": "ប្រវត្តិរូបរបស់ខ្ញុំ",
    "guides_faqs": "មគ្គុទ្ទេសក៍ និងសំណួរគេសួរញឹកញាប់",
    "More": "ច្រើនទៀត",
    "guides": "មគ្គុទ្ទេសក៍",
    "register": "ចុះឈ្មោះ",
    "hot_faq": "សំណួរដែលសួរញឹកញាប់",
    "Contents": "ខ្លឹមសារ៖",
    "contact_us": "ទាក់ទងមកយើង >>",
    "plan": "ផែនការ",
    "unregistered": "មិនបានចុះឈ្មោះ",
    "buy_more": "ទិញបន្ថែម",
    "buy_again": "ទិញម្តងទៀត",
    "buy_now": "ទិញ​ឥឡូវនេះ",
    "free_no_limit": "ឥតគិតថ្លៃ & គ្មានដែនកំណត់",
    "expired": "ផុតកំណត់",
    "lifetime": "ឆាកជីវិត",
    "remain": "នៅសល់",
    "day_s": "ថ្ងៃ",
    "error_24801": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 24801)។ សូមព្យាយាមម្តងទៀត",
    "no_app_found": "រកមិនឃើញកម្មវិធីទេ!<a href=\"/\">ធ្វើឱ្យស្រស់</a> ឬ <a href=\"https://www.mindonmap.com/\">ចូលទៅកាន់គេហទំព័រផ្លូវការ</a>",
    "get_more": "ទទួលបានច្រើនទៀត >>",
    "edit_photo": "កែសម្រួលរូបថត",
    "select_photo": "ជ្រើសរើសរូបថត",
    "change_photo": "ផ្លាស់ប្តូររូបថត",
    "cancel": "បោះបង់",
    "hide_password": "លាក់ពាក្យសម្ងាត់",
    "show_password": "បង្ហាញពាក្យសម្ងាត់",
    "zoom_in": "ពង្រីក",
    "zoom_out": "ពង្រីក",
    "rotate": "បង្វិល",
    "horizontal_flip": "បត់ផ្តេក",
    "vertical_flip": "បត់បញ្ឈរ",
    "country": "ប្រទេស",
    "country_1": "ជ្រើសរើសប្រទេស/តំបន់របស់អ្នក។",
    "country_2": "កោះអេលែន",
    "country_3": "អាហ្វហ្គានីស្ថាន",
    "country_4": "អាល់បានី",
    "country_5": "អាល់ហ្សេរី",
    "country_6": "អាមេរិក សាម័រ",
    "country_7": "អង់ដូរ៉ា",
    "country_8": "អង់ហ្គោឡា",
    "country_9": "អង់ហ្គីឡា",
    "country_10": "អង់តាក់ទិក",
    "country_11": "Antigua & Barbuda",
    "country_12": "អាហ្សង់ទីន",
    "country_13": "អាមេនី",
    "country_14": "អារូបា",
    "country_15": "អូស្ត្រាលី",
    "country_16": "អូទ្រីស",
    "country_17": "អាស៊ែបៃហ្សង់",
    "country_18": "បារ៉ែន",
    "country_19": "បង់ក្លាដែស",
    "country_20": "បាបាដូស",
    "country_21": "បេឡារុស្ស",
    "country_22": "បែលហ្សិក",
    "country_23": "បេលីស",
    "country_24": "បេនីន",
    "country_25": "ប៊ឺមូដា",
    "country_26": "ប៊ូតាន",
    "country_27": "បូលីវី",
    "country_28": "បូស្នៀ & ហឺហ្សេហ្គោវីណា",
    "country_29": "បូតស្វាណា",
    "country_30": "កោះប៊ូវេត",
    "country_31": "ប្រេស៊ីល",
    "country_32": "ដែនដីមហាសមុទ្រឥណ្ឌារបស់អង់គ្លេស",
    "country_33": "កោះ British Virgin ",
    "country_34": "ប្រ៊ុយណេ",
    "country_35": "ប៊ុលហ្គារី",
    "country_36": "បួគីណា",
    "country_37": "ប៊ូរុនឌី",
    "country_38": "កម្ពុជា។",
    "country_39": "កាមេរូន",
    "country_40": "ប្រទេសកាណាដា",
    "country_41": "Cape Verde",
    "country_42": "ការាបៀន ហូឡង់",
    "country_43": "កោះកៃម៉ាន",
    "country_44": "សាធារណរដ្ឋ អាហ្វ្រិកកណ្តាល",
    "country_45": "ឆាដ",
    "country_46": "ឈីលី",
    "country_47": "ចិន",
    "country_48": "កោះណូអែល",
    "country_49": "កោះ Cocos (Keeling) ",
    "country_50": "កូឡុំប៊ី",
    "country_51": "កោះឃុក",
    "country_52": "កូស្តារីកា",
    "country_53": "កូតឌីវ័រ",
    "country_54": "ក្រូអាត",
    "country_55": "គុយបា",
    "country_56": "ស៊ីប",
    "country_57": "សាធារណរដ្ឋ​ឆែក",
    "country_58": "សាធារណរដ្ឋ​ប្រជាធិបតេយ្យ​កុងហ្គោ",
    "country_59": "ដាណឺម៉ាក",
    "country_60": "ជីប៊ូទី",
    "country_61": "ដូមីនីកា",
    "country_62": "សាធារណរដ្ឋ​ដូ​មីនី​កែន",
    "country_63": "អេក្វាឌ័រ",
    "country_64": "អេហ្ស៊ីប",
    "country_65": "អែលសាល់វ៉ាឌ័រ",
    "country_66": "អេក្វាទ័រ ហ្គីណេ",
    "country_67": "អេរីទ្រា",
    "country_68": "អេស្តូនី",
    "country_69": "អេត្យូពី",
    "country_70": "កោះ Falkland",
    "country_71": "កោះហ្វារ៉ូ",
    "country_72": "សហព័ន្ធ រដ្ឋ Micronesia",
    "country_73": "ហ្វីជី",
    "country_74": "ហ្វាំងឡង់",
    "country_75": "ប្រទេសបារាំង",
    "country_76": "French Guiana",
    "country_77": "ប៉ូលីណេស៊ីបារាំង",
    "country_78": "ដែនដីបារាំងភាគខាងត្បូង",
    "country_79": "ហ្គាបុង",
    "country_80": "ហ្គាំប៊ី",
    "country_81": "ហ្សកហ្ស៊ី",
    "country_82": "អាល្លឺម៉ង់",
    "country_83": "ហ្គាណា",
    "country_84": "Gibraltar",
    "country_85": "ចក្រភព​អង់គ្លេស (United Kingdom; England)",
    "country_86": "ប្រទេសក្រិក",
    "country_87": "ហ្គ្រីនឡែន",
    "country_88": "ហ្គ្រេណាដា",
    "country_89": "ហ្គូដលូប",
    "country_90": "កោះហ្គាំ",
    "country_91": "ហ្គាតេម៉ាឡា",
    "country_92": "ហ្គេនស៊ី",
    "country_93": "ហ្គីណេ",
    "country_94": "ហ្គីណេ-ប៊ីសូ",
    "country_95": "ហ្គីយ៉ាណា",
    "country_96": "ហៃទី",
    "country_97": "កោះហេដ និង McDonald កោះ",
    "country_98": "ហុងឌូរ៉ាស",
    "country_99": "ហុងកុង",
    "country_100": "ហុងគ្រី",
    "country_101": "អ៊ីស្លង់",
    "country_102": "ប្រទេសឥណ្ឌា",
    "country_103": "ប្រទេសឥណ្ឌូនេស៊ី",
    "country_104": "អ៊ីរ៉ង់",
    "country_105": "អ៊ីរ៉ាក់",
    "country_106": "អៀរឡង់",
    "country_107": "Isle of Man",
    "country_108": "អ៊ីស្រាអែល",
    "country_109": "ប្រទេសអ៊ីតាលី",
    "country_110": "ហ្សាម៉ាអ៊ីក",
    "country_111": "ជប៉ុន",
    "country_112": "អាវ",
    "country_113": "ហ្ស៊កដានី",
    "country_114": "កាហ្សាក់ស្ថាន",
    "country_115": "កេនយ៉ា",
    "country_116": "គីរីបាទី",
    "country_117": "គុយវ៉ែត",
    "country_118": "កៀហ្ស៊ីស៊ីស្ថាន",
    "country_119": "ឡាវ",
    "country_120": "ឡាតវី",
    "country_121": "លីបង់",
    "country_122": "ឡេសូតូ",
    "country_123": "លីបេរីយ៉ា",
    "country_124": "លីប៊ី",
    "country_125": "លីចតេនស្ទីន",
    "country_126": "លីទុយអានី",
    "country_127": "លុចសំបួរ",
    "country_128": "ម៉ាកាវ",
    "country_129": "ម៉ាដាហ្គាស្ការ",
    "country_130": "ម៉ាឡាវី",
    "country_131": "ម៉ាឡេស៊ី",
    "country_132": "ម៉ាល់ឌីវ",
    "country_133": "ម៉ាលី",
    "country_134": "ម៉ាល់តា",
    "country_135": "កោះ Marshall",
    "country_136": "ម៉ាទីនីក",
    "country_137": "ម៉ូរីតានី",
    "country_138": "ម៉ូរីស",
    "country_139": "ម៉ាយ៉ាត",
    "country_140": "ម៉ិកស៊ិក",
    "country_141": "ម៉ុលដាវី",
    "country_142": "ម៉ូណាកូ",
    "country_143": "ម៉ុងហ្គោលី",
    "country_144": "ម៉ុងតេណេហ្គ្រោ",
    "country_145": "ម៉ុងសឺរ៉ាត់",
    "country_146": "ម៉ារ៉ុក",
    "country_147": "ម៉ូសំប៊ិក",
    "country_148": "មីយ៉ាន់ម៉ា (ភូមា)",
    "country_149": "ណាមីប៊ី",
    "country_150": "ណៅរូ",
    "country_151": "នេប៉ាល់",
    "country_152": "ហូឡង់",
    "country_153": "New Caledonia",
    "country_154": "នូវែលសេឡង់",
    "country_155": "នីការ៉ាហ្គា",
    "country_156": "នីហ្សេរីយ៉ា",
    "country_157": "នីហ្សេរីយ៉ា",
    "country_158": "នីយូ",
    "country_159": "កោះ Norfolk",
    "country_160": "កូរ៉េខាងជើង",
    "country_161": "Northern Mariana កោះ",
    "country_162": "ប្រទេសន័រវេស",
    "country_163": "អូម៉ង់",
    "country_164": "ប៉ាគីស្ថាន",
    "country_165": "ប៉ាឡូ",
    "country_166": "ទឹកដីប៉ាឡេស្ទីន",
    "country_167": "ប៉ាណាម៉ា",
    "country_168": "ប៉ាពួ ញូហ្គីណេ",
    "country_169": "ប៉ារ៉ាហ្គាយ",
    "country_170": "ប្រទេសប៉េរូ",
    "country_171": "កោះ Pitcairn",
    "country_172": "ប្រទេស​ប៉ូឡូញ",
    "country_173": "ព័រទុយហ្គាល់",
    "country_174": "ព័រតូរីកូ",
    "country_175": "កាតា",
    "country_176": "សាធារណរដ្ឋ ម៉ាសេដូនៀ (FYROM)",
    "country_177": "សាធារណរដ្ឋកុងហ្គោ",
    "country_178": "ការរួបរួម",
    "country_179": "រូម៉ានី",
    "country_180": "សហព័ន្ធរុស្ស៊ី",
    "country_181": "រវ៉ាន់ដា",
    "country_182": "សាំង Barthélemy",
    "country_183": "សាំង ម៉ាទីន (បារាំង)",
    "country_184": "Saint-Pierre និង Miquelon",
    "country_185": "សាម័រ",
    "country_186": "សាន់ម៉ារីណូ",
    "country_187": "Sao Tome & Principe",
    "country_188": "អារ៉ាប៊ីសាអូឌីត",
    "country_189": "សេណេហ្គាល់",
    "country_190": "ស៊ែប៊ី",
    "country_191": "សីស្ហែល",
    "country_192": "សៀរ៉ាឡេអូន",
    "country_193": "សិង្ហបុរី",
    "country_194": "ស្លូវ៉ាគី",
    "country_195": "ស្លូវេនី",
    "country_196": "កោះសាឡូម៉ូន",
    "country_197": "សូម៉ាលី",
    "country_198": "អា​ព្រិច​ខាងត្បូង",
    "country_199": "South Georgia និង South Sandwich Isla",
    "country_200": "កូរ៉េខាងត្បូង",
    "country_201": "ស៊ូដង់​ខាងត្បូង",
    "country_202": "អេស្ប៉ាញ",
    "country_203": "ស្រី​លង្កា",
    "country_204": "St. Helena & Dependencies",
    "country_205": "St. Kitts & Nevis",
    "country_206": "សាំង លូសៀ",
    "country_207": "សាំង Vincent & the Grenadines",
    "country_208": "ស៊ូដង់",
    "country_209": "ស៊ូរីណាម",
    "country_210": "ស្វាស៊ីឡង់",
    "country_211": "ស៊ុយអែត",
    "country_212": "ប្រទេស​ស្វីស",
    "country_213": "ស៊ីរី",
    "country_214": "តៃវ៉ាន់",
    "country_215": "តាជីគីស្ថាន",
    "country_216": "តង់ហ្សានី",
    "country_217": "ទំព័រគំរូ៖ទិន្នន័យប្រទេស SJM Svalbard",
    "country_218": "ប្រទេសថៃ",
    "country_219": "បាហាម៉ា",
    "country_220": "កូម៉ូរ៉ូ",
    "country_221": "ប្រទេស​ហ្វីលីពីន",
    "country_222": "ទីម័រខាងកើត (ទីម័រខាងកើត)",
    "country_223": "តូហ្គោ",
    "country_224": "តូក្លូ",
    "country_225": "តុងហ្គា",
    "country_226": "ទ្រីនីដាដ និង តូបាហ្គោ",
    "country_227": "ទុយនីស៊ី",
    "country_228": "តួកគី",
    "country_229": "តូមិននីស្ថាន",
    "country_230": "កោះ Turks & Caicos ",
    "country_231": "ទូវ៉ាលូ",
    "country_232": "អ៊ូហ្គង់ដា",
    "country_233": "អ៊ុយក្រែន",
    "country_234": "សហព័ន្ធ​អារ៉ាប់រួម",
    "country_235": "សហរដ្ឋអាមេរិក Minor Outlying Islands",
    "country_236": "សហរដ្ឋ អាមេរិក (USA)",
    "country_237": "សហរដ្ឋអាមេរិក Virgin Islands",
    "country_238": "អ៊ុយរូហ្គាយ",
    "country_239": "អ៊ូសបេគីស្ថាន",
    "country_240": "វ៉ានូអាទូ",
    "country_241": "ទីក្រុងវ៉ាទីកង់ (The Holy See)",
    "country_242": "វ៉េណេស៊ុយអេឡា",
    "country_243": "ប្រទេសវៀតណាម",
    "country_244": "Wallis និង Futuna",
    "country_245": "សាហារ៉ាខាងលិច",
    "country_246": "យេម៉ែន",
    "country_247": "សំប៊ី",
    "country_248": "ហ្ស៊ីមបាវ៉េ",
    "google_login": "ចូលជាមួយ Google",
    "State": "រដ្ឋ",
    "Activation_code": "លេខកូដធ្វើឱ្យសកម្ម",
    "Question": "រាយកម្មវិធីទាំងអស់ដែលអ្នកបានចូល",
    "Copy_complete": "ចម្លងពេញលេញ",
    "footer": "រក្សាសិទ្ធិ © 2024 MindOnMap Studio ។ រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង។",
    "change_password_success": "បានផ្លាស់ប្តូរពាក្យសម្ងាត់ដោយជោគជ័យ",
    "successful_login_title": "ចូលជោគជ័យ",
    "product_page": "ទំព័រផលិតផល >>",
    "successful_login_info": "ការចូលបានបញ្ចប់។ សូមបិទទំព័របច្ចុប្បន្ន ហើយត្រលប់ទៅផ្ទាំងដើមវិញ ដើម្បីបន្តដំណើរការដូចខាងក្រោម។ ទំព័របច្ចុប្បន្ននឹងត្រូវបានបិទដោយស្វ័យប្រវត្តិក្នុងរយៈពេល 5 វិនាទី។ ប្រសិនបើការបិទ ឬបិទដោយស្វ័យប្រវត្តិដោយចុចប៊ូតុង \"រួចរាល់\" សូមបិទផ្ទាំងនេះដោយផ្ទាល់។",
    "successful_login_info_firefox": "ការចូលបានបញ្ចប់។ សូមបិទទំព័របច្ចុប្បន្ន ហើយត្រលប់ទៅផ្ទាំងដើមវិញ ដើម្បីបន្តដំណើរការដូចខាងក្រោម។",
    "my_account": "គណនី​របស់ខ្ញុំ",
    "my_history": "ប្រវត្តិរបស់ខ្ញុំ",
    "remove_watermark": "យក Watermark ចេញ",
    "no_history": "គ្មានប្រវត្តិ",
    "history_all": "ជ្រើសរើស​ទាំងអស់",
    "history_open": "បើក",
    "history_down": "ទាញយក",
    "history_delete": "លុប",
    "history_clear": "ជម្រះមិនត្រឹមត្រូវ",
    "images": "រូបភាព",
    "use_this_function": "ប្រើផលិតផលនេះ >>",
    "hd_downloading": "អត្ថប្រយោជន៍ទាញយករូបភាព HD ដើម៖",
    "lifetimeRemaining": "ជីវិត - នៅសល់",
    "Remaining": "នៅសល់",
    "email_verification": "ការផ្ទៀងផ្ទាត់អ៊ីមែល",
    "email_verification_info": "យើងបានផ្ញើសារផ្ទៀងផ្ទាត់ទៅកាន់អ៊ីមែលរបស់អ្នក <span class=\"email\"></span> ហើយសូមបញ្ចប់ការផ្ទៀងផ្ទាត់។ បន្ទាប់ពីផ្ទៀងផ្ទាត់ អត្ថប្រយោជន៍នឹងធ្វើសមកាលកម្មដោយស្វ័យប្រវត្តិ។",
    "wrong_email": "អាសយដ្ឋានអ៊ីមែល មិនត្រឹមត្រូវ?",
    "click_here_to_modify": "ចុចទីនេះដើម្បីកែប្រែ",
    "get_more_help": "ទទួលបានជំនួយបន្ថែម?",
    "click_here": "ចុច​ទីនេះ",
    "email_verification_info_success": "សូមអបអរសាទរចំពោះការផ្ទៀងផ្ទាត់គណនីអ៊ីមែលរបស់អ្នក។",
    "email_verification_info_error": "ការផ្ទៀងផ្ទាត់បានបរាជ័យដោយសារតែតំណផុតកំណត់។",
    "registration_succeeded": "ការចុះឈ្មោះបានជោគជ័យ",
    "registration_succeeded_info_1": "អបអរសាទរ! អ្នកបានចុះឈ្មោះដោយជោគជ័យ។ យើងបានផ្ញើសារផ្ទៀងផ្ទាត់ទៅកាន់អ៊ីមែលរបស់អ្នក <span class=\"email\"></span> ហើយសូមបញ្ចប់ការផ្ទៀងផ្ទាត់ ដើម្បីប្រើប្រាស់អត្ថប្រយោជន៍នៃអ៊ីមែលនេះ។",
    "registration_succeeded_info_2": "ចុច \"រួចរាល់\" ដើម្បីត្រឡប់ទៅទំព័រដើម ហើយប្រើផលិតផលនេះ។",
    "registration_succeeded_info_3": "ចុច \"រួចរាល់\" ដើម្បីបិទទំព័របច្ចុប្បន្ន ហើយត្រឡប់ទៅទំព័រដើមវិញសម្រាប់ប្រតិបត្តិការខាងក្រោម។ ប្រសិនបើការបិទបរាជ័យ សូមបិទផ្ទាំងដោយដៃ។",
    "verify_email": "ផ្ទៀងផ្ទាត់អ៊ីមែល",
    "registered_email_not_verified": "អ៊ីមែលដែលបានចុះឈ្មោះមិនទាន់ត្រូវបានផ្ទៀងផ្ទាត់ទេ សូមផ្ទៀងផ្ទាត់វាតែម្តង។",
    "email_verification_time_1": "មិនបានទទួលអ៊ីមែលផ្ទៀងផ្ទាត់មែនទេ?",
    "email_verification_time_2": "បន្ទាប់ពី",
    "email_verification_time_3": "ចុចទីនេះដើម្បីផ្ញើវាឡើងវិញ",
    "error_26301": "លេខកូដកំហុស៖ 26301 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26302": "លេខកូដកំហុស៖ 26302 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26303": "កំហុសទម្រង់អ៊ីមែល (លេខកូដកំហុស៖ 26303) ។ សូមបញ្ចូលវាម្តងទៀត",
    "error_26304": "ពាក្យសម្ងាត់លើសពី 8 តួអក្សរត្រូវបានណែនាំ (លេខកូដកំហុស: 26304)",
    "error_26305": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26305) ។ សូមព្យាយាមម្តងទៀត",
    "error_26306": "អ៊ីមែលត្រូវបានចុះឈ្មោះហើយ សូម <a href=\"https://account.mindonmap.com/login/\">ចូលទៅកាន់គណនី</a>",
    "error_26307": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26307) ។ សូមព្យាយាមម្តងទៀត",
    "error_26308": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26308) ។ សូមព្យាយាមម្តងទៀត",
    "error_26401": "លេខកូដកំហុស៖ 26401 សូមព្យាយាមម្តងទៀត",
    "error_26402": "អ៊ីមែលត្រូវបានផ្ទៀងផ្ទាត់ (លេខកូដកំហុស៖ 26402) សូមព្យាយាមម្តងទៀត",
    "error_26403": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26403) ។ សូមព្យាយាមម្តងទៀត",
    "error_26404": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 26404)។ សូមព្យាយាមម្តងទៀត",
    "error_26501": "លេខកូដកំហុស៖ 26501 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26502": "លេខកូដកំហុស៖ 26502 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26503": "កំហុសទម្រង់អ៊ីមែល (លេខកូដកំហុស៖ 26503) ។ សូមបញ្ចូលវាម្តងទៀត",
    "error_26504": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26504) ។ សូមព្យាយាមម្តងទៀត",
    "error_26505": "អ៊ីមែល​មិន​ទាន់​បាន​ចុះ​ឈ្មោះ​ទេ សូម <a href=\"https://account.mindonmap.com/register/\">ចុះឈ្មោះ​វា​ជាមុន​សិន</a>",
    "error_26506": "អ៊ីមែលត្រូវបានផ្ទៀងផ្ទាត់។",
    "error_26507": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26507) ។ សូមព្យាយាមម្តងទៀត",
    "error_26508": "ការផ្ទៀងផ្ទាត់បានបរាជ័យ (លេខកូដកំហុស៖ 26508) សូមព្យាយាមម្តងទៀត",
    "error_26509": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 26509) សូមព្យាយាមម្តងទៀត",
    "error_26510": "លេខកូដកំហុស៖ 26510 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26601": "លេខកូដកំហុស៖ 26601 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26602": "លេខកូដកំហុស៖ 26602 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26603": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26603) ។ សូមព្យាយាមម្តងទៀត",
    "error_26604": "លេខកូដកំហុស៖ 26604 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26605": "លេខកូដកំហុស៖ 26605 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26701": "លេខកូដកំហុស៖ 26701 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26702": "Reuqest បានបរាជ័យ (លេខកូដកំហុស: 26702) ។ សូមព្យាយាមម្តងទៀត",
    "error_26703": "លេខកូដកំហុស៖ 26703 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26704": "លេខកូដកំហុស៖ 26704 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26705": "រង់ចាំសម្រាប់ការចូល (លេខកូដកំហុស: 26705) ។ សូមព្យាយាមម្តងទៀត",
    "no_cookie": "អ្នកបានបើកមុខងារ Block all cookies នៅក្នុង browser របស់អ្នក ដូច្នេះអ្នកមិនអាច log in បានទេ។ សូមចូលទៅកាន់ Settings ដើម្បីធីកប្រអប់ Allow all cookies។",
    "error_26801": "លេខកូដកំហុស៖ 26801 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26802": "លេខកូដកំហុស៖ 26802 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26803": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 26803)។ សូមព្យាយាមម្តងទៀត",
    "error_26804": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ 26804)។ សូមព្យាយាមម្តងទៀត",
    "error_order": "ការស្នើសុំបានបរាជ័យ (លេខកូដកំហុស៖ 27098) សូមព្យាយាមម្តងទៀត!",
    "error_order1": "សំណួរបញ្ជាទិញមិនពេញលេញ (លេខកូដកំហុស៖ ",
    "error_order2": ") សូមធ្វើបច្ចុប្បន្នភាព ហើយព្យាយាមម្តងទៀត។",
    "modify_email_title": "កែប្រែអ៊ីមែល",
    "modify_email_info": "អ្នកអាចប្រើអ៊ីមែលដែលបានកែប្រែ ដើម្បីចូលទៅក្នុងគណនីរបស់អ្នក។",
    "images_per": "រូបភាពក្នុងមួយ",
    "error_26101": "កំហុស៖ 26101. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26102": "កំហុស៖ 26102. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26103": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 26103)។ សូម​ព្យាយាម​ម្ដងទៀត។",
    "error_26104": "លេខកូដកំហុស៖ 26104 សូមព្យាយាមម្តងទៀត",
    "error_26105": "លេខកូដកំហុស៖ 26105 សូមព្យាយាមម្តងទៀត",
    "error_26106": "ការលុប បានបរាជ័យ (កូដកំហុស៖ 26106)។ សូមព្យាយាមម្តងទៀត",
    "error_26201": "កំហុស៖ 26201. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26202": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 26202)។ សូម​ព្យាយាម​ម្ដងទៀត។",
    "error_26001": "កំហុស៖ 26001. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26002": "កំហុស៖ 26002. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26003": "កំហុស៖ 26003. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26004": "កំហុស៖ 26004. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_26005": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 26005)។ សូម​ព្យាយាម​ម្ដងទៀត។",
    "error_26006": "លេខកូដកំហុស៖ 26006 សូមព្យាយាមម្តងទៀត",
    "error_26008": "កំហុស៖ 26008. សូមទាក់ទងមកយើងខ្ញុំ",
    "go_to_the_home_page": "ចូលទៅកាន់ទំព័រដើម",
    "error_27101": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២៧១០១)។ សូមព្យាយាមម្តងទៀត",
    "error_27201": "លេខកូដកំហុស៖ 27201 សូមទាក់ទងមកយើងខ្ញុំ",
    "error_27202": "លេខកូដកំហុស៖ 27202 សូមព្យាយាមម្តងទៀត",
    "error_27203": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២៧២០៣)។ សូមព្យាយាមម្តងទៀត",
    "error_27204": "លេខកូដមិនត្រឹមត្រូវ (លេខកូដកំហុស៖ ២៧២០៤)។",
    "error_27205": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២៧២០៥)។ សូមព្យាយាមម្តងទៀត",
    "error_27206": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២៧២០៦)។ សូមព្យាយាមម្តងទៀត",
    "error_27207": "សំណើបានបរាជ័យ (លេខកូដកំហុស៖ ២៧២០៧)។ សូមព្យាយាមម្តងទៀត",
    "no_history_found": "អ្នកមិនបានប្រើឧបករណ៍ណាមួយទេ! <a href=\"/\">ធ្វើឱ្យស្រស់</a> ឬ <a href=\"https://www.mindonmap.com/\">ចូលទៅកាន់គេហទំព័រផ្លូវការ</a>",
    "error_25301": "កំហុស៖ 25301. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_25302": "កំហុស៖ 25302. សូមទាក់ទងមកយើងខ្ញុំ",
    "error_25303": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 25303)។ សូមព្យាយាមម្តងទៀត",
    "error_25304": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 25304)។ សូមព្យាយាមម្តងទៀត",
    "error_25305": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 25305)។ សូមព្យាយាមម្តងទៀត",
    "error_25306": "សំណើ បានបរាជ័យ (កូដកំហុស៖ 25306)។ សូមព្យាយាមម្តងទៀត",
    "image_upscaler_p": "អត្ថប្រយោជន៍ទាញយករូបភាពដោយគ្មាន watermark៖",
    "Available_for": "មានសម្រាប់៖",
    "credit_per": "ឥណទាន %s សម្រាប់រូបភាព HD",
    "still_valid": "ផែនការដែលបានទិញនៅតែមានសុពលភាព",
    "credit": "ឥណទាន",
    "pc_3rd_info": "ចូលដោយជោគជ័យ។ សូមចូលទៅកាន់កម្មវិធីសម្រាប់ប្រតិបត្តិការបន្ថែម។",
    "use_online": "ប្រើសេវាកម្មអនឡាញ",
    "use_download": "ប្រើកម្មវិធីកុំព្យូទ័រ",
    "use_immediately": "ប្រើភ្លាមៗ",
    "Use_in_browser": "ប្រើក្នុងកម្មវិធីរុករក",
    "win_desktop": "វីនដូ",
    "Mac_desktop": "ម៉ាក់",
    "credits_per": "ឥណទាន {%} ក្នុងមួយខែ",
    "expire": "ពេលវេលាផុតកំណត់៖",
    "viewDetails": "មើលព័ត៌មានលម្អិត",
    "viewHistory": "មើលអត្ថប្រយោជន៍ប្រវត្តិសាស្រ្ត >>",
    "viewDetailsInfo": "សេចក្តីជូនដំណឹង៖ ប្រសិនបើអត្ថប្រយោជន៍នៃការជាវត្រូវបានបន្តក្នុងរយៈពេល 7 ថ្ងៃបន្ទាប់ពីការផុតកំណត់ អត្ថប្រយោជន៍ដែលមិនប្រើអាចបន្តប្រើប្រាស់បាន។ ដូចគ្នានេះផងដែរ ពេលវេលាផុតកំណត់នឹងត្រូវបានអាប់ដេតដោយស្វ័យប្រវត្តិទៅពេលវេលាផុតកំណត់នៃការជាវថ្មី។ ប្រសិនបើមិនមានការជាវថ្មីបន្ទាប់ពីរយៈពេល 7 ថ្ងៃផុតកំណត់ អត្ថប្រយោជន៍ដែលផុតកំណត់ទាំងអស់នឹងត្រូវបានជម្រះ។",
    "connect_account": "ភ្ជាប់អ៊ីមែលទៅគណនីរបស់អ្នក។",
    "connect_account_info": "បន្ទាប់ពីភ្ជាប់រួច អ្នកអាចចូលដោយប្រើអាសយដ្ឋានអ៊ីមែលនេះ។",
    "connect_now": "ចងឥឡូវនេះ",
    "no_email_bind": "គ្មានការភ្ជាប់អ៊ីមែលទេ។",
    "bind_email": "ភ្ជាប់អ៊ីមែល",
    "connect_your_email_placeholder": "សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។",
    "bind_an_email": "ចងអ៊ីមែល",
    "bind_info": "អ្នកបានចូលដោយជោគជ័យ។ សូមភ្ជាប់អ៊ីមែលទៅគណនីរបស់អ្នក ដើម្បីដំណើរការអត្ថប្រយោជន៍របស់អ្នក។",
    "bind_later": "ចងនៅពេលក្រោយ",
    "hi": "សួស្តី %s!",
    "Personal_Information": "ព័ត៌មាន​ផ្ទាល់ខ្លួន",
    "Access": "ការចូលប្រើ",
    "Subscription_Plan": "(គម្រោងការជាវ)",
    "No_orders": "រកមិនឃើញការបញ្ជាទិញទេ។",
    "No_data": "គ្មាន​ទិន្នន័យ",
    "Featured_Products": "ផលិតផល​ពិសេស",
    "More_Products": "ផលិតផលច្រើនទៀត",
    "Free_Download": "ការ​ទាញ​យក​ដោយ​ឥត​គិត​ថ្លៃ",
    "Get_Started": "ចាប់ផ្តើម",
    "Subscribe": "ជាវ",
    "Verified": "ផ្ទៀងផ្ទាត់",
    "back_to_account_center": "ត្រលប់ទៅមជ្ឈមណ្ឌលគណនី",
    "success": "ជោគជ័យ!",
    "successfully": "អ្នកបានចុះឈ្មោះគណនីដោយជោគជ័យ។",
    "Continue": "បន្ត",
    "Already": "មានគណនីរួចហើយ?",
    "loading_verification": "កំពុងពិនិត្យមើលស្ថានភាពផ្ទៀងផ្ទាត់ ...",
    "email_no_verification": "សូមអភ័យទោស អាសយដ្ឋានអ៊ីមែលដែលបានចុះឈ្មោះមិនត្រូវបានផ្ទៀងផ្ទាត់ទេ។ សូមបំពេញការផ្ទៀងផ្ទាត់តាមការណែនាំខាងលើ ហើយចុចប៊ូតុង \"រួចរាល់\" ម្តងទៀត ដើម្បីបញ្ចប់ការចុះឈ្មោះ។",
    "will_expire_after": "នឹងផុតកំណត់បន្ទាប់ពី",
    "hours": "ម៉ោង",
  },
  "kn": {
    "overtime": "ದೋಷ ಕೋಡ್: {%}, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "isnetwork": "ಇಂಟರ್ನೆಟ್ ದೋಷ. ದಯವಿಟ್ಟು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "email_placeholder": "ಇಮೇಲ್",
    "email_empty": "ದಯವಿಟ್ಟು ಇಮೇಲ್ ನಮೂದಿಸಿ",
    "email_not_valid": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ",
    "email_not_valid_1": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ",
    "email_not_valid_2": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ, ದಯವಿಟ್ಟು ಬೇರೆ ವಿಳಾಸವನ್ನು ಬಳಸಿ.",
    "email_not_valid_3": "ಇಮೇಲ್ ಇನ್‌ಪುಟ್ ಇಲ್ಲ",
    "password_placeholder": "ಗುಪ್ತಪದ",
    "password_empty": "ದಯವಿಟ್ಟು ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    "password_not_valid": "ತಪ್ಪಾದ ಖಾತೆ ಅಥವಾ ಪಾಸ್‌ವರ್ಡ್",
    "password_not_valid_1": "8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿನ ಪಾಸ್‌ವರ್ಡ್ ಅಗತ್ಯವಿದೆ",
    "password_not_valid_2": "ದಯವಿಟ್ಟು ಪಾಸ್‌ವರ್ಡ್ ರಚಿಸಿ",
    "password_placeholder_1": "ನಿಮ್ಮ ಪಾಸ್ವರ್ಡ್ ರಚಿಸಿ",
    "password_placeholder_2": "ನಿಮ್ಮ ಗುಪ್ತಪದವನ್ನು ಖಚಿತಪಡಿಸಿ",
    "password_placeholder_3": "ಹೊಸ ಗುಪ್ತಪದವನ್ನು ರಚಿಸಿ",
    "password_placeholder_4": "ಹೊಸ ಗುಪ್ತಪದವನ್ನು ಖಚಿತಪಡಿಸಿ",
    "password_placeholder_5": "ಹಳೆಯ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ",
    "copy_password_empty": "ದಯವಿಟ್ಟು ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ದೃಢೀಕರಿಸಿ",
    "copy_password_not_valid": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ದೃಢೀಕರಿಸಿ",
    "copy_passwords_inconsistent": "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ದೃಢೀಕರಣವು ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ",
    "code_empty": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    "code_not_valid": "ಅಮಾನ್ಯ ಪರಿಶೀಲನೆ ಕೋಡ್",
    "code_placeholder": "ಪರಿಶೀಲನೆ ಕೋಡ್",
    "not_received_code": "ನಿಮ್ಮ ಅಂಚೆಪೆಟ್ಟಿಗೆಯು ದೀರ್ಘಕಾಲದವರೆಗೆ ಪರಿಶೀಲನಾ ಕೋಡ್ ಅನ್ನು ಸ್ವೀಕರಿಸದಿದ್ದರೆ, ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ಪಡೆದುಕೊಳ್ಳಿ.",
    "get_first_code": "ದಯವಿಟ್ಟು ಮೊದಲು ಪರಿಶೀಲನೆ ಕೋಡ್ ಪಡೆಯಿರಿ.",
    "last_name_placeholder": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    "first_name_placeholder": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    "address_placeholder": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    "no_code_text": "ನಾವು ಪರಿಶೀಲನೆ ಕೋಡ್ ಕಳುಹಿಸಿದ್ದೇವೆ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕೋಡ್ ನಮೂದಿಸಿ. <span class='tips'>ಕೋಡ್ ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?",
    "no_code_text_1": "1. ಇಮೇಲ್ ವಿಳಾಸವು ಮಾನ್ಯವಾಗಿದೆಯೇ ಎಂಬುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಅದು ಇಮೇಲ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸಬಹುದು.",
    "no_code_text_2": "2. ಇಮೇಲ್ ಅನ್ನು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಿಸ್ಟಮ್ ಮೂಲಕ ಕಳುಹಿಸಲಾಗುತ್ತದೆ, ಅದನ್ನು ಸ್ಪ್ಯಾಮ್ ಅಥವಾ ಜಂಕ್ ಇಮೇಲ್ ಎಂದು ಫ್ಲ್ಯಾಗ್ ಮಾಡಬಹುದು. ಇಮೇಲ್ ಅನುಪಯುಕ್ತ ಫೋಲ್ಡರ್‌ನಲ್ಲಿದೆಯೇ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಪರಿಶೀಲಿಸಿ.",
    "no_code_text_3": "3. ನಿಮ್ಮ ಸಮಸ್ಯೆಯನ್ನು ಪರಿಹರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲವೇ? ",
    "no_code_text_3_span": "ನಂತರ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "order_no": "ನೀವು ಯಾವುದೇ ಉತ್ಪನ್ನವನ್ನು ಖರೀದಿಸಿಲ್ಲ, ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಯನ್ನು ಹೊಂದಿದ್ದರೆ, ದಯವಿಟ್ಟು <a href=\"https://www.mindonmap.com/contact-us/\">ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ</a>.",
    "error_24901": "ಪ್ರಸ್ತುತ ಖಾತೆಯು ಯಾವುದೇ ಇಮೇಲ್ ಅನ್ನು ಲಿಂಕ್ ಮಾಡಿಲ್ಲ ಮತ್ತು ಆದೇಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲಾಗುವುದಿಲ್ಲ. ದಯವಿಟ್ಟು ಇಮೇಲ್ ಅನ್ನು ಸಂಪರ್ಕಿಸಿ.",
    "user_guide": "ಬಳಕೆದಾರ ಮಾರ್ಗದರ್ಶಿ >>",
    "download": "ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    "order_number": "ಆದೇಶ ಸಂಖ್ಯೆ:",
    "Refund": "ಮರುಪಾವತಿ",
    "Disabled": "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
    "Normal": "ಸಾಮಾನ್ಯ",
    "Modify": "ಮಾರ್ಪಡಿಸಿ",
    "Modify_1": "ಮಾರ್ಪಡಿಸಿ>>",
    "Connect": "ಸಂಪರ್ಕಿಸಿ",
    "unlink_success": "ಯಶಸ್ವಿಯಾಗಿ ಅನ್‌ಲಿಂಕ್ ಮಾಡಿ",
    "connect_success": "ಯಶಸ್ವಿಯಾಗಿ ಸಂಪರ್ಕಿಸಿ",
    "feedback_title": "ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಗೆ ಧನ್ಯವಾದಗಳು. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸಮಸ್ಯೆಯನ್ನು ಬಿಡಿ ಮತ್ತು 24 ಗಂಟೆಗಳ ಒಳಗೆ ನಾವು ನಿಮಗೆ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತೇವೆ.",
    "feedback_thank_you": "ಧನ್ಯವಾದಗಳು!<br />ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.",
    "feedback_email": "ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ!",
    "feedback_content": "ನೀವು ಎದುರಿಸಿದ ಯಾವುದೇ ಸಮಸ್ಯೆ ಅಥವಾ ಸಲಹೆಯನ್ನು ಇಲ್ಲಿ ಬಿಡಿ.",
    "feedback_submit": "ಸಲ್ಲಿಸು",
    "form_contents": "ನೀವು ಯಾವುದೇ ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿಲ್ಲ. ದಯವಿಟ್ಟು ಅದನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ಮತ್ತೊಮ್ಮೆ ಸಲ್ಲಿಸಿ.",
    "old_password": "ದಯವಿಟ್ಟು ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    "new_password": "ದಯವಿಟ್ಟು ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ರಚಿಸಿ",
    "old_new_password": "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್‌ನಂತೆಯೇ ಇರುವಂತಿಲ್ಲ",
    "incorrect_password": "ತಪ್ಪಾದ ಪಾಸ್ವರ್ಡ್",
    "delete_no": "ಈಗ ಅಳಿಸಿ",
    "Caps": "ಕ್ಯಾಪ್ಸ್ ಲಾಕ್ ಆನ್ ಆಗಿದೆ",
    "Get": "ಪಡೆಯಿರಿ",
    "Done": "ಮುಗಿದಿದೆ",
    "error_20001": "ದೋಷ: 20001. ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20002": "ದೋಷ: 20002. ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20003": "ದೋಷ: 20003. ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20004": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20004). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_20005": "ಲಾಗಿನ್ ಅವಧಿ ಮುಗಿದಿದೆ (ದೋಷ: 20005). ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20006": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20006). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_20007": "ಲಾಗಿನ್ ಅವಧಿ ಮುಗಿದಿದೆ (ದೋಷ: 20007). ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20008": "ಲಾಗಿನ್ ಅವಧಿ ಮುಗಿದಿದೆ (ದೋಷ: 20008). ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20009": "ಲಾಗಿನ್ ಅವಧಿ ಮುಗಿದಿದೆ (ದೋಷ: 20009). ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20101": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20101)",
    "error_20102": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20102)",
    "error_20103": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20103). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20104": "ಇಮೇಲ್ ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ, ದಯವಿಟ್ಟು <a href=\"https://account.mindonmap.com/login/\">ಲಾಗ್ ಇನ್</a> ಅಥವಾ ಹೊಸದರೊಂದಿಗೆ ನೋಂದಾಯಿಸಿ",
    "error_20105": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20105). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20106": "ಇಮೇಲ್ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_20201": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20201)",
    "error_20202": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20202)",
    "error_20203": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20203)",
    "error_20204": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20204)",
    "error_20205": "8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿನ ಪಾಸ್‌ವರ್ಡ್ ಅಗತ್ಯವಿದೆ (ದೋಷ ಕೋಡ್: 20205)",
    "error_20206": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20206). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20207": "ಅಮಾನ್ಯ ಪರಿಶೀಲನೆ ಕೋಡ್",
    "error_20208": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20208). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20209": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20209). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20301": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20301)",
    "error_20302": "ದೋಷ: 20302. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_20303": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20303)",
    "error_20304": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20304). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20305": "ಖಾತೆ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ. ದಯವಿಟ್ಟು ಮರು-ನಮೂದಿಸಿ ಅಥವಾ ಮೊದಲು <a href=\"https://account.mindonmap.com/register/\">ಅದನ್ನು ರಚಿಸಿ</a>.",
    "error_20306": "ಇನ್ನೂ ಯಾವುದೇ ಪಾಸ್‌ವರ್ಡ್ ಇಲ್ಲ. <a href=\"https://account.mindonmap.com/passwordless-login/\">ಪಾಸ್‌ವರ್ಡ್‌ರಹಿತ ಲಾಗಿನ್</a> ಅಥವಾ <a href=\"https://account.mindonmap.com/create-password/\"> ಬಳಸಿ ಗುಪ್ತಪದವನ್ನು ಹೊಂದಿಸಿ</a> ಮತ್ತು ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_20308": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20308). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20401": "ಲಾಗ್ ಔಟ್ ಮಾಡಲು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20401). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20501": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20501)",
    "error_20502": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20502)",
    "error_20503": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20503). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20504": "ಇಮೇಲ್ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_20601": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20601)",
    "error_20602": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20602)",
    "error_20603": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20603)",
    "error_20604": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20604). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20606": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20606). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20607": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20607). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20608": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20608). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20701": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20701)",
    "error_20702": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20702)",
    "error_20703": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20703). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20704": "ಖಾತೆ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ. ದಯವಿಟ್ಟು ಮರು-ನಮೂದಿಸಿ ಅಥವಾ ಮೊದಲು <a href=\"https://account.mindonmap.com/register/\">ಅದನ್ನು ರಚಿಸಿ</a>.",
    "error_20705": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20705). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20706": "ಇಮೇಲ್ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20801": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20801)",
    "error_20802": "ದೋಷ: 20802. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_20803": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 20803)",
    "error_20804": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 20804)",
    "error_20805": "8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿನ ಪಾಸ್‌ವರ್ಡ್ ಅಗತ್ಯವಿದೆ (ದೋಷ ಕೋಡ್: 20805)",
    "error_20806": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20806). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20808": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20808). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20901": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20901). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20902": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 20902). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21000": "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಲಾಗಿದೆ",
    "error_21001": "ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 21001)",
    "error_21002": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21002). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21101": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 21101)",
    "error_21102": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 21102)",
    "error_21103": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21103), ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21104": "ಇಮೇಲ್ ಈಗಾಗಲೇ ಸಂಪರ್ಕಗೊಂಡಿದೆ, ದಯವಿಟ್ಟು ಹೊಸದನ್ನು ಬಳಸಿ",
    "error_21105": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21105), ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21106": "ಇಮೇಲ್ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21201": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 21201)",
    "error_21202": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 21202)",
    "error_21203": "ಇಮೇಲ್ ಮಾನ್ಯವಾಗಿಲ್ಲ (ದೋಷ ಕೋಡ್: 21203)",
    "error_21204": "ದೋಷ: 21204. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21205": "ದೋಷ: 21205. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21206": "8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿನ ಪಾಸ್‌ವರ್ಡ್ ಅಗತ್ಯವಿದೆ (ದೋಷ ಕೋಡ್: 21206)",
    "error_21207": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21207). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21209": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21209). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21301": "ದಯವಿಟ್ಟು ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 21301)",
    "error_21302": "ದಯವಿಟ್ಟು ಹೊಸ ಗುಪ್ತಪದವನ್ನು ರಚಿಸಿ (ದೋಷ ಕೋಡ್: 21302)",
    "error_21303": "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್‌ನಂತೆಯೇ ಇರುವಂತಿಲ್ಲ. (ದೋಷ: 21303)",
    "error_21304": "8 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿನ ಪಾಸ್‌ವರ್ಡ್ ಅಗತ್ಯವಿದೆ (ದೋಷ ಕೋಡ್: 21304)",
    "error_21306": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21306). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21402": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21402). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21403": "ಪರಿಶೀಲನೆ ಕೋಡ್ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಕಳುಹಿಸಿ",
    "error_21500": "ಖಾತೆಯನ್ನು ಅಳಿಸಲಾಗಿದೆ",
    "error_21501": "ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ (ದೋಷ ಕೋಡ್: 21501)",
    "error_21502": "ಲಾಗಿನ್ ಅವಧಿ ಮುಗಿದಿದೆ (ದೋಷ: 21502). ದಯವಿಟ್ಟು ಮತ್ತೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
    "error_21503": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21503). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21505": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21505), ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_21601": "ದೋಷ: 20601. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21602": "ಅಮಾನ್ಯ ಪರಿಶೀಲನೆ (ದೋಷ: 20602). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_21603": "ದೋಷ: 20603. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21604": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21604). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21606": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21606). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21611": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21611). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21801": "ದೋಷ: 21801. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21802": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ: 21802). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21803": "ದೋಷ: 21803. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21804": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21804). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21806": "ದೋಷ: 21806. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21807": "ದೋಷ: 21807. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21808": "ದೋಷ: 21808. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21809": "ದೋಷ: 21809. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21810": "ದೋಷ: 21810. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21811": "ದೋಷ: 21811. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21812": "ದೋಷ: 21812. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21813": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21813). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21814": "ದೋಷ: 21814. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21815": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21815). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21816": "ದೋಷ: 21816. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21817": "ದೋಷ: 21817. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21818": "ದೋಷ: 21818. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21819": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21819). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21820": "ದೋಷ: 21820. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21821": "ದೋಷ: 21821. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21822": "ದೋಷ: 21822. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21823": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21823). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21824": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21824). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21825": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21825). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21826": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21826). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21901": "ದೋಷ: 21901. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_21902": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21902). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21903": "ಖಾತೆಯ ಸ್ಥಿತಿ ಬದಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21903), ದಯವಿಟ್ಟು ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21904": "ದೋಷ: 21904. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21905": "ದೋಷ: 21905. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21906": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21906). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_21907": "Google ಖಾತೆಯನ್ನು ಇತರ ಖಾತೆಗೆ ಲಿಂಕ್ ಮಾಡಲಾಗಿದೆ",
    "error_21908": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 21908). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22001": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22001). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22002": "ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ಲಾಗಿನ್ ಇಲ್ಲದೆ ಅನ್‌ಲಿಂಕ್ ವಿಫಲವಾಗಿದೆ",
    "error_22003": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22003). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22101": "ದೋಷ: 22101. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22102": "ಖಾತೆಯ ಸ್ಥಿತಿ ಬದಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22102), ದಯವಿಟ್ಟು ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22103": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22103). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22104": "ಖಾತೆಯ ಸ್ಥಿತಿ ಬದಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22104), ದಯವಿಟ್ಟು ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22105": "ದೋಷ: 22105. ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22106": "ದೋಷ: 22106. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22107": "ದೋಷ: 22107. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22108": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22108). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22201": "ದೋಷ: 22201. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22202": "ಅಮಾನ್ಯ ಪರಿಶೀಲನೆ (ದೋಷ: 22202). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_22203": "ದೋಷ: 22203. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ\"",
    "error_22204": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22204). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22206": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22206). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22401": "ದೋಷ: 22401. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22402": "ಅಮಾನ್ಯ ಪರಿಶೀಲನೆ (ದೋಷ: 22402). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_22403": "ದೋಷ: 22403. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22404": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22404). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22405": "Facebook ಖಾತೆಯನ್ನು ಇತರ ಇಮೇಲ್‌ಗೆ ಲಿಂಕ್ ಮಾಡಲಾಗಿದೆ",
    "error_22406": "ದೋಷ: 22406. ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22407": "ದೋಷ: 22407. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22408": "ದೋಷ: 22408. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22409": "ದೋಷ: 22409. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22410": "ದೋಷ: 224010. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22411": "ದೋಷ: 224011. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22412": "ದೋಷ: 224012. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22413": "ದೋಷ: 22413. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22414": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22414). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22415": "ದೋಷ: 22415. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22416": "ದೋಷ: 22416. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22417": "ದೋಷ: 22417. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22418": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22418). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22419": "ದೋಷ: 22419. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22420": "ದೋಷ: 22420. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22421": "ದೋಷ: 22421. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_22422": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22422). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22423": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22423). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22424": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22424). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_22425": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 22425). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_20098": "ದೋಷ ಕೋಡ್: 20098. ನೀವು ಖಾಸಗಿ ಮೋಡ್‌ನಲ್ಲಿ ಬ್ರೌಸ್ ಮಾಡುತ್ತಿದ್ದರೆ, ದಯವಿಟ್ಟು ಸಾಮಾನ್ಯ ಮೋಡ್‌ಗೆ ಬದಲಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_22298": "Google ಗಾಗಿ ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ: 22298). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_22498": "Facebook ಗಾಗಿ ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ: 22498). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "error_24902": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 24902). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_24903": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 24903). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_24904": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 24904). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_24905": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 24905). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "login_title": "MindOnMap ಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ",
    "log_in": "ಲಾಗಿನ್ ಮಾಡಿ",
    "no_account": "ಖಾತೆ ಇಲ್ಲವೇ?",
    "create_it": "ಅದನ್ನು ರಚಿಸಿ",
    "or_log_in_with": "ಅಥವಾ ಇದರೊಂದಿಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ",
    "passwordless_login": "ಪಾಸ್ವರ್ಡ್ ರಹಿತ ಲಾಗಿನ್",
    "log_in_done": "ಲಾಗ್ ಇನ್ ಮುಗಿದಿದೆ",
    "three_rd_account_connect_info": "ಅಭಿನಂದನೆಗಳು! ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಲಾಗ್ ಇನ್ ಆಗಿರುವಿರಿ. ಈಗ ನೀವು ಭವಿಷ್ಯದಲ್ಲಿ ಲಾಗ್ ಇನ್ ಮಾಡಲು ಬಳಸಲಾಗುವ ಒಂದು ಇಮೇಲ್ ಖಾತೆಯನ್ನು ಸಂಪರ್ಕಿಸಬಹುದು.",
    "see_my_account": "ನನ್ನ ಖಾತೆಯನ್ನು ನೋಡಿ",
    "three_rd_login_merge_account": "ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಖಾತೆಯ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ಸೈನ್ ಅಪ್ ಮಾಡಲಾಗಿದೆ, ನೀವು ನೇರವಾಗಿ ಈ ಇಮೇಲ್ ವಿಳಾಸದೊಂದಿಗೆ ಸಂಪರ್ಕಿಸಲು ಮತ್ತು ಲಾಗ್ ಇನ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
    "connect_log_in": "ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಲಾಗ್ ಇನ್ ಮಾಡಿ",
    "create_an_account": "ಖಾತೆಯನ್ನು ತೆರೆಯಿರಿ",
    "back_to_log_in": "ಲಾಗ್ ಇನ್ ಮಾಡಲು ಹಿಂತಿರುಗಿ",
    "create_password": "ಪಾಸ್ವರ್ಡ್ ರಚಿಸಿ",
    "create_now": "ಈಗ ರಚಿಸಿ",
    "password_login_subtitle": "ಇಮೇಲ್‌ನೊಂದಿಗೆ ಪಾಸ್‌ವರ್ಡ್‌ರಹಿತ ಲಾಗಿನ್",
    "account_login": "ಖಾತೆ ಲಾಗಿನ್",
    "rights": "ಈ ಖಾತೆಯನ್ನು ರಚಿಸುವ ಮೂಲಕ, ನೀವು <a href=\"https://www.mindonmap.com/terms-and-conditions/\">ಸೇವಾ ನಿಯಮಗಳು</a> ಮತ್ತು <a href=\"https://www.mindonmap.com/privacy-policy/\">ಗೌಪ್ಯತೆ ನೀತಿ</a>",
    "passwordless_login_done": "ಪಾಸ್ವರ್ಡ್ ರಹಿತ ಲಾಗಿನ್ ಮಾಡಲಾಗಿದೆ",
    "passwordless_login_info": "ಅಭಿನಂದನೆಗಳು, ನೀವು ಪಾಸ್‌ವರ್ಡ್ ರಹಿತ ಲಾಗಿನ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ. ನೀವು ಈ ಖಾತೆಗೆ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ರಚಿಸಬಹುದು ಮತ್ತು ಭವಿಷ್ಯದಲ್ಲಿ ಖಾತೆ ಮತ್ತು ಪಾಸ್‌ವರ್ಡ್‌ನೊಂದಿಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಬಹುದು. <a href=\"/create-password\" style=\"display: initial;\">ಈಗಲೇ ರಚಿಸಿ</a>",
    "sign_up": "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
    "register_info": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸಿ",
    "reset_now": "ಈಗ ಮರುಹೊಂದಿಸಿ",
    "forgot_password": "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ",
    "reset_password_subtitle": "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಲು ನಿಮ್ಮ ಖಾತೆಯ ಇಮೇಲ್ ಬಳಸಿ",
    "plan_products": "ಯೋಜನೆಗಳು ಮತ್ತು ಉತ್ಪನ್ನಗಳು",
    "nick_name": "ಬಳಕೆದಾರ ಹೆಸರು:",
    "email": "ಇಮೇಲ್:",
    "my_products": "ನನ್ನ ಉತ್ಪನ್ನಗಳು",
    "my_orders": "ನನ್ನ ಆಜ್ಞೆಗಳು",
    "unlink": "ಅನ್‌ಲಿಂಕ್ ಮಾಡಿ",
    "link": "ಲಿಂಕ್",
    "connected_accounts": "ಸಂಪರ್ಕಿತ ಖಾತೆಗಳು",
    "last_name": "ಕೊನೆಯ ಹೆಸರು:",
    "first_name": "ಮೊದಲ ಹೆಸರು:",
    "Gender": "ಲಿಂಗ:",
    "Birth": "ಜನನ:",
    "Month": "ತಿಂಗಳು",
    "Year": "ವರ್ಷ",
    "Country_Region": "ದೇಶ/ಪ್ರದೇಶ:",
    "Address": "ವಿಳಾಸ:",
    "Save": "ಉಳಿಸಿ",
    "Date": "ದಿನಾಂಕ",
    "Male": "ಪುರುಷ",
    "Female": "ಹೆಣ್ಣು",
    "Unspecified": "ಅನಿರ್ದಿಷ್ಟ",
    "Security": "ಭದ್ರತೆ",
    "change_password": "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
    "change_now": "ಈಗ ಬದಲಿಸಿ",
    "connect_email": "ಇಮೇಲ್ ಸಂಪರ್ಕಿಸಿ",
    "delete_account": "ಖಾತೆಯನ್ನು ಅಳಿಸಿ",
    "delete_account_info": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಳಿಸಿದಾಗ, ನಿಮ್ಮ ಖಾತೆಯೊಂದಿಗೆ ಸಂಯೋಜಿತವಾಗಿರುವ ನನ್ನ ಖಾತೆಯಲ್ಲಿರುವ ಎಲ್ಲಾ ಡೇಟಾವನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಅದನ್ನು ಮರುಪಡೆಯಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗದಿರಬಹುದು. ನೀವು ಎಚ್ಚರಿಕೆಯಿಂದ ನಡೆಯಲು ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ.",
    "Delete": "ಅಳಿಸಿ",
    "Logout": "ಲಾಗ್ ಔಟ್",
    "my_profile": "ಸ್ವ ಭೂಮಿಕೆ",
    "guides_faqs": "ಮಾರ್ಗದರ್ಶಿಗಳು ಮತ್ತು FAQ ಗಳು",
    "More": "ಇನ್ನಷ್ಟು",
    "guides": "ಮಾರ್ಗದರ್ಶಕರು",
    "register": "ನೋಂದಣಿ",
    "hot_faq": "ಬಿಸಿ FAQ",
    "Contents": "ಪರಿವಿಡಿ:",
    "contact_us": "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ>>",
    "plan": "ಯೋಜನೆ",
    "unregistered": "ನೋಂದಾಯಿಸಲಾಗಿಲ್ಲ",
    "buy_more": "ಇನ್ನಷ್ಟು ಖರೀದಿಸಿ",
    "buy_again": "ಮತ್ತೆ ಖರೀದಿಸಿ",
    "buy_now": "ಈಗ ಖರೀದಿಸು",
    "free_no_limit": "ಉಚಿತ ಮತ್ತು ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲ",
    "expired": "ಅವಧಿ ಮೀರಿದೆ",
    "lifetime": "ಜೀವಮಾನ",
    "remain": "ಉಳಿಯಿರಿ",
    "day_s": "ದಿನ(ಗಳು)",
    "error_24801": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 24801). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "no_app_found": "ಯಾವುದೇ ಅಪ್ಲಿಕೇಶನ್ ಕಂಡುಬಂದಿಲ್ಲ!<a href=\"/\">ರಿಫ್ರೆಶ್</a> ಅಥವಾ <a href=\"https://www.mindonmap.com/\">ಅಧಿಕೃತ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಹೋಗಿ</a>",
    "get_more": "ಇನ್ನಷ್ಟು ಪಡೆಯಿರಿ >>",
    "edit_photo": "ಫೋಟೋ ಸಂಪಾದಿಸಿ",
    "select_photo": "ಫೋಟೋ ಆಯ್ಕೆಮಾಡಿ",
    "change_photo": "ಫೋಟೋ ಬದಲಾಯಿಸಿ",
    "cancel": "ರದ್ದುಮಾಡಿ",
    "hide_password": "ಗುಪ್ತಪದವನ್ನು ಮರೆಮಾಡಿ",
    "show_password": "ಗುಪ್ತ ಪದ ತೋರಿಸು",
    "zoom_in": "ಇನ್ನು ಹತ್ತಿರವಾಗಿಸಿ",
    "zoom_out": "ಜೂಮ್ ಔಟ್",
    "rotate": "ತಿರುಗಿಸಿ",
    "horizontal_flip": "ಸಮತಲ ಫ್ಲಿಪ್",
    "vertical_flip": "ಲಂಬ ಫ್ಲಿಪ್",
    "country": "ದೇಶ",
    "country_1": "ನಿಮ್ಮ ದೇಶ/ಪ್ರದೇಶವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "country_2": "ಆಲ್ಯಾಂಡ್ ದ್ವೀಪ",
    "country_3": "ಅಫ್ಘಾನಿಸ್ತಾನ",
    "country_4": "ಅಲ್ಬೇನಿಯಾ",
    "country_5": "ಅಲ್ಜೀರಿಯಾ",
    "country_6": "ಅಮೆರಿಕನ್ ಸಮೋವಾ",
    "country_7": "ಅಂಡೋರಾ",
    "country_8": "ಅಂಗೋಲಾ",
    "country_9": "ಅಂಗುಯಿಲಾ",
    "country_10": "ಅಂಟಾರ್ಟಿಕಾ",
    "country_11": "ಆಂಟಿಗುವಾ & ಬಾರ್ಬುಡಾ",
    "country_12": "ಅರ್ಜೆಂಟೀನಾ",
    "country_13": "ಅರ್ಮೇನಿಯಾ",
    "country_14": "ಅರುಬಾ",
    "country_15": "ಆಸ್ಟ್ರೇಲಿಯಾ",
    "country_16": "ಆಸ್ಟ್ರಿಯಾ",
    "country_17": "ಅಜೆರ್ಬೈಜಾನ್",
    "country_18": "ಬಹ್ರೇನ್",
    "country_19": "ಬಾಂಗ್ಲಾದೇಶ",
    "country_20": "ಬಾರ್ಬಡೋಸ್",
    "country_21": "ಬೆಲಾರಸ್",
    "country_22": "ಬೆಲ್ಜಿಯಂ",
    "country_23": "ಬೆಲೀಜ್",
    "country_24": "ಬೆನಿನ್",
    "country_25": "ಬರ್ಮುಡಾ",
    "country_26": "ಭೂತಾನ್",
    "country_27": "ಬೊಲಿವಿಯಾ",
    "country_28": "ಬೋಸ್ನಿಯಾ ಮತ್ತು ಹರ್ಜೆಗೋವಿನಾ",
    "country_29": "ಬೋಟ್ಸ್ವಾನ",
    "country_30": "ಬೌವೆಟ್ ದ್ವೀಪ",
    "country_31": "ಬ್ರೆಜಿಲ್",
    "country_32": "ಬ್ರಿಟಿಷ್ ಭಾರತೀಯ ಸಾಗರ ಪ್ರದೇಶ",
    "country_33": "ಬ್ರಿಟಿಷ್ ವರ್ಜಿನ್ ದ್ವೀಪಗಳು",
    "country_34": "ಬ್ರೂನಿ",
    "country_35": "ಬಲ್ಗೇರಿಯಾ",
    "country_36": "ಬುರ್ಕಿನಾ",
    "country_37": "ಬುರುಂಡಿ",
    "country_38": "ಕಾಂಬೋಡಿಯಾ",
    "country_39": "ಕ್ಯಾಮರೂನ್",
    "country_40": "ಕೆನಡಾ",
    "country_41": "ಕೇಪ್ ವರ್ಡೆ",
    "country_42": "ಕೆರಿಬಿಯನ್ ನೆದರ್ಲ್ಯಾಂಡ್ಸ್",
    "country_43": "ಕೇಮನ್ ದ್ವೀಪಗಳು",
    "country_44": "ಮಧ್ಯ ಆಫ್ರಿಕಾದ ಗಣರಾಜ್ಯ",
    "country_45": "ಚಾಡ್",
    "country_46": "ಚಿಲಿ",
    "country_47": "ಚೀನಾ",
    "country_48": "ಕ್ರಿಸ್ಮಸ್ ದ್ವೀಪ",
    "country_49": "ಕೊಕೊಸ್ (ಕೀಲಿಂಗ್) ದ್ವೀಪಗಳು",
    "country_50": "ಕೊಲಂಬಿಯಾ",
    "country_51": "ಕುಕ್ ದ್ವೀಪಗಳು",
    "country_52": "ಕೋಸ್ಟ ರಿಕಾ",
    "country_53": "ಕೋಟ್ ಡಿ'ಐವರಿ",
    "country_54": "ಕ್ರೊಯೇಷಿಯಾ",
    "country_55": "ಕ್ಯೂಬಾ",
    "country_56": "ಸೈಪ್ರಸ್",
    "country_57": "ಜೆಕ್ ಗಣರಾಜ್ಯ",
    "country_58": "ಕಾಂಗೋದ ಡೆಮಾಕ್ರಟಿಕ್ ರಿಪಬ್ಲಿಕ್",
    "country_59": "ಡೆನ್ಮಾರ್ಕ್",
    "country_60": "ಜಿಬೌಟಿ",
    "country_61": "ಡೊಮಿನಿಕಾ",
    "country_62": "ಡೊಮಿನಿಕನ್ ರಿಪಬ್ಲಿಕ್",
    "country_63": "ಈಕ್ವೆಡಾರ್",
    "country_64": "ಈಜಿಪ್ಟ್",
    "country_65": "ಎಲ್ ಸಾಲ್ವಡಾರ್",
    "country_66": "ಈಕ್ವಟೋರಿಯಲ್ ಗಿನಿ",
    "country_67": "ಎರಿಟ್ರಿಯಾ",
    "country_68": "ಎಸ್ಟೋನಿಯಾ",
    "country_69": "ಇಥಿಯೋಪಿಯಾ",
    "country_70": "ಫಾಕ್ಲ್ಯಾಂಡ್ ದ್ವೀಪಗಳು",
    "country_71": "ಫರೋ ದ್ವೀಪಗಳು",
    "country_72": "ಫೆಡರೇಟೆಡ್ ಸ್ಟೇಟ್ಸ್ ಆಫ್ ಮೈಕ್ರೋನೇಷಿಯಾ",
    "country_73": "ಫಿಜಿ",
    "country_74": "ಫಿನ್ಲ್ಯಾಂಡ್",
    "country_75": "ಫ್ರಾನ್ಸ್",
    "country_76": "ಫ್ರೆಂಚ್ ಗಯಾನಾ",
    "country_77": "ಫ್ರೆಂಚ್ ಪಾಲಿನೇಷ್ಯಾ",
    "country_78": "ಫ್ರೆಂಚ್ ದಕ್ಷಿಣ ಪ್ರದೇಶಗಳು",
    "country_79": "ಗ್ಯಾಬೊನ್",
    "country_80": "ಗ್ಯಾಂಬಿಯಾ",
    "country_81": "ಜಾರ್ಜಿಯಾ",
    "country_82": "ಜರ್ಮನಿ",
    "country_83": "ಘಾನಾ",
    "country_84": "ಜಿಬ್ರಾಲ್ಟರ್",
    "country_85": "ಗ್ರೇಟ್ ಬ್ರಿಟನ್ (ಯುನೈಟೆಡ್ ಕಿಂಗ್‌ಡಮ್; ಇಂಗ್ಲೆಂಡ್)",
    "country_86": "ಗ್ರೀಸ್",
    "country_87": "ಗ್ರೀನ್ಲ್ಯಾಂಡ್",
    "country_88": "ಗ್ರೆನಡಾ",
    "country_89": "ಗ್ವಾಡೆಲೋಪ್",
    "country_90": "ಗುವಾಮ್",
    "country_91": "ಗ್ವಾಟೆಮಾಲಾ",
    "country_92": "ಗುರ್ನಸಿ",
    "country_93": "ಗಿನಿಯಾ",
    "country_94": "ಗಿನಿ-ಬಿಸ್ಸೌ",
    "country_95": "ಗಯಾನಾ",
    "country_96": "ಹೈಟಿ",
    "country_97": "ಹರ್ಡ್ ಐಲ್ಯಾಂಡ್ ಮತ್ತು ಮ್ಯಾಕ್‌ಡೊನಾಲ್ಡ್ ದ್ವೀಪಗಳು",
    "country_98": "ಹೊಂಡುರಾಸ್",
    "country_99": "ಹಾಂಗ್ ಕಾಂಗ್",
    "country_100": "ಹಂಗೇರಿ",
    "country_101": "ಐಸ್ಲ್ಯಾಂಡ್",
    "country_102": "ಭಾರತ",
    "country_103": "ಇಂಡೋನೇಷ್ಯಾ",
    "country_104": "ಇರಾನ್",
    "country_105": "ಇರಾಕ್",
    "country_106": "ಐರ್ಲೆಂಡ್",
    "country_107": "ಐಲ್ ಆಫ್ ಮ್ಯಾನ್",
    "country_108": "ಇಸ್ರೇಲ್",
    "country_109": "ಇಟಲಿ",
    "country_110": "ಜಮೈಕಾ",
    "country_111": "ಜಪಾನ್",
    "country_112": "ಜರ್ಸಿ",
    "country_113": "ಜೋರ್ಡಾನ್",
    "country_114": "ಕಝಾಕಿಸ್ತಾನ್",
    "country_115": "ಕೀನ್ಯಾ",
    "country_116": "ಕಿರಿಬಾಟಿ",
    "country_117": "ಕುವೈತ್",
    "country_118": "ಕಿರ್ಗಿಸ್ತಾನ್",
    "country_119": "ಲಾವೋಸ್",
    "country_120": "ಲಾಟ್ವಿಯಾ",
    "country_121": "ಲೆಬನಾನ್",
    "country_122": "ಲೆಸೊಥೊ",
    "country_123": "ಲೈಬೀರಿಯಾ",
    "country_124": "ಲಿಬಿಯಾ",
    "country_125": "ಲಿಚ್ಟೆನ್‌ಸ್ಟೈನ್",
    "country_126": "ಲಿಥುವೇನಿಯಾ",
    "country_127": "ಲಕ್ಸೆಂಬರ್ಗ್",
    "country_128": "ಮಕಾವೊ",
    "country_129": "ಮಡಗಾಸ್ಕರ್",
    "country_130": "ಮಲಾವಿ",
    "country_131": "ಮಲೇಷ್ಯಾ",
    "country_132": "ಮಾಲ್ಡೀವ್ಸ್",
    "country_133": "ಮಾಲಿ",
    "country_134": "ಮಾಲ್ಟಾ",
    "country_135": "ಮಾರ್ಷಲ್ ದ್ವೀಪಗಳು",
    "country_136": "ಮಾರ್ಟಿನಿಕ್",
    "country_137": "ಮಾರಿಟಾನಿಯ",
    "country_138": "ಮಾರಿಷಸ್",
    "country_139": "ಮಾಯೊಟ್ಟೆ",
    "country_140": "ಮೆಕ್ಸಿಕೋ",
    "country_141": "ಮೊಲ್ಡೊವಾ",
    "country_142": "ಮೊನಾಕೊ",
    "country_143": "ಮಂಗೋಲಿಯಾ",
    "country_144": "ಮಾಂಟೆನೆಗ್ರೊ",
    "country_145": "ಮಾಂಟ್ಸೆರಾಟ್",
    "country_146": "ಮೊರಾಕೊ",
    "country_147": "ಮೊಜಾಂಬಿಕ್",
    "country_148": "ಮ್ಯಾನ್ಮಾರ್ (ಬರ್ಮಾ)",
    "country_149": "ನಮೀಬಿಯಾ",
    "country_150": "ನೌರು",
    "country_151": "ನೇಪಾಳ",
    "country_152": "ನೆದರ್ಲ್ಯಾಂಡ್ಸ್",
    "country_153": "ನ್ಯೂ ಕ್ಯಾಲೆಡೋನಿಯಾ",
    "country_154": "ನ್ಯೂಜಿಲ್ಯಾಂಡ್",
    "country_155": "ನಿಕರಾಗುವಾ",
    "country_156": "ನೈಜರ್",
    "country_157": "ನೈಜೀರಿಯಾ",
    "country_158": "ನಿಯು",
    "country_159": "ನಾರ್ಫೋಕ್ ದ್ವೀಪ",
    "country_160": "ಉತ್ತರ ಕೊರಿಯಾ",
    "country_161": "ಉತ್ತರ ಮರಿಯಾನಾ ದ್ವೀಪಗಳು",
    "country_162": "ನಾರ್ವೆ",
    "country_163": "ಓಮನ್",
    "country_164": "ಪಾಕಿಸ್ತಾನ",
    "country_165": "ಪಲಾವ್",
    "country_166": "ಪ್ಯಾಲೇಸ್ಟಿನಿಯನ್ ಪ್ರದೇಶಗಳು",
    "country_167": "ಪನಾಮ",
    "country_168": "ಪಪುವಾ ನ್ಯೂ ಗಿನಿಯಾ",
    "country_169": "ಪರಾಗ್ವೆ",
    "country_170": "ಪೆರು",
    "country_171": "ಪಿಟ್‌ಕೈರ್ನ್ ದ್ವೀಪಗಳು",
    "country_172": "ಪೋಲೆಂಡ್",
    "country_173": "ಪೋರ್ಚುಗಲ್",
    "country_174": "ಪೋರ್ಟೊ ರಿಕೊ",
    "country_175": "ಕತಾರ್",
    "country_176": "ರಿಪಬ್ಲಿಕ್ ಆಫ್ ಮ್ಯಾಸಿಡೋನಿಯಾ (FYROM)",
    "country_177": "ಕಾಂಗೋ ಗಣರಾಜ್ಯ",
    "country_178": "ರಿಯೂನಿಯನ್",
    "country_179": "ರೊಮೇನಿಯಾ",
    "country_180": "ರಷ್ಯ ಒಕ್ಕೂಟ",
    "country_181": "ರುವಾಂಡಾ",
    "country_182": "ಸೇಂಟ್ ಬಾರ್ತೆಲೆಮಿ",
    "country_183": "ಸೇಂಟ್ ಮಾರ್ಟಿನ್ (ಫ್ರಾನ್ಸ್)",
    "country_184": "ಸೇಂಟ್-ಪಿಯರ್ ಮತ್ತು ಮಿಕ್ವೆಲಾನ್",
    "country_185": "ಸಮೋವಾ",
    "country_186": "ಸ್ಯಾನ್ ಮರಿನೋ",
    "country_187": "ಸಾವೊ ಟೋಮ್ & ಪ್ರಿನ್ಸಿಪಿ",
    "country_188": "ಸೌದಿ ಅರೇಬಿಯಾ",
    "country_189": "ಸೆನೆಗಲ್",
    "country_190": "ಸರ್ಬಿಯಾ",
    "country_191": "ಸೀಶೆಲ್ಸ್",
    "country_192": "ಸಿಯೆರಾ ಲಿಯೋನ್",
    "country_193": "ಸಿಂಗಾಪುರ",
    "country_194": "ಸ್ಲೋವಾಕಿಯಾ",
    "country_195": "ಸ್ಲೊವೇನಿಯಾ",
    "country_196": "ಸೊಲೊಮನ್ ದ್ವೀಪಗಳು",
    "country_197": "ಸೊಮಾಲಿಯಾ",
    "country_198": "ದಕ್ಷಿಣ ಆಫ್ರಿಕಾ",
    "country_199": "ದಕ್ಷಿಣ ಜಾರ್ಜಿಯಾ ಮತ್ತು ದಕ್ಷಿಣ ಸ್ಯಾಂಡ್‌ವಿಚ್ ಇಸ್ಲಾ",
    "country_200": "ದಕ್ಷಿಣ ಕೊರಿಯಾ",
    "country_201": "ದಕ್ಷಿಣ ಸುಡಾನ್",
    "country_202": "ಸ್ಪೇನ್",
    "country_203": "ಶ್ರೀಲಂಕಾ",
    "country_204": "ಸೇಂಟ್ ಹೆಲೆನಾ ಮತ್ತು ಅವಲಂಬನೆಗಳು",
    "country_205": "ಸೇಂಟ್ ಕಿಟ್ಸ್ & ನೆವಿಸ್",
    "country_206": "ಸೇಂಟ್ ಲೂಸಿಯಾ",
    "country_207": "ಸೇಂಟ್ ವಿನ್ಸೆಂಟ್ ಮತ್ತು ಗ್ರೆನಡೈನ್ಸ್",
    "country_208": "ಸುಡಾನ್",
    "country_209": "ಸುರಿನಾಮ್",
    "country_210": "ಸ್ವಾಜಿಲ್ಯಾಂಡ್",
    "country_211": "ಸ್ವೀಡನ್",
    "country_212": "ಸ್ವಿಟ್ಜರ್ಲೆಂಡ್",
    "country_213": "ಸಿರಿಯಾ",
    "country_214": "ತೈವಾನ್",
    "country_215": "ತಜಕಿಸ್ತಾನ್",
    "country_216": "ತಾಂಜಾನಿಯಾ",
    "country_217": "ಟೆಂಪ್ಲೇಟು:Country data SJM Svalbard",
    "country_218": "ಥೈಲ್ಯಾಂಡ್",
    "country_219": "ಬಹಾಮಾಸ್",
    "country_220": "ಕೊಮೊರೊಸ್",
    "country_221": "ಫಿಲಿಪೈನ್ಸ್",
    "country_222": "ಟಿಮೋರ್-ಲೆಸ್ಟೆ (ಪೂರ್ವ ಟಿಮೋರ್)",
    "country_223": "ಹೋಗಲು",
    "country_224": "ಟೊಕೆಲಾವ್",
    "country_225": "ಟಾಂಗಾ",
    "country_226": "ಟ್ರಿನಿಡಾಡ್ & ಟೊಬಾಗೊ",
    "country_227": "ಟುನೀಶಿಯಾ",
    "country_228": "ಟರ್ಕಿ",
    "country_229": "ತುರ್ಕಮೆನಿಸ್ತಾನ್",
    "country_230": "ಟರ್ಕ್ಸ್ ಮತ್ತು ಕೈಕೋಸ್ ದ್ವೀಪಗಳು",
    "country_231": "ಟುವಾಲು",
    "country_232": "ಉಗಾಂಡಾ",
    "country_233": "ಉಕ್ರೇನ್",
    "country_234": "ಸಂಯುಕ್ತ ಅರಬ್ ಸಂಸ್ಥಾಪನೆಗಳು",
    "country_235": "ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್ ಮೈನರ್ ಹೊರಗಿನ ದ್ವೀಪಗಳು",
    "country_236": "ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್ ಆಫ್ ಅಮೇರಿಕಾ (USA)",
    "country_237": "ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್ ವರ್ಜಿನ್ ದ್ವೀಪಗಳು",
    "country_238": "ಉರುಗ್ವೆ",
    "country_239": "ಉಜ್ಬೇಕಿಸ್ತಾನ್",
    "country_240": "ವನವಾಟು",
    "country_241": "ವ್ಯಾಟಿಕನ್ ನಗರ (ಪವಿತ್ರ ನೋಡಿ)",
    "country_242": "ವೆನೆಜುವೆಲಾ",
    "country_243": "ವಿಯೆಟ್ನಾಂ",
    "country_244": "ವಾಲಿಸ್ ಮತ್ತು ಫುಟುನಾ",
    "country_245": "ಪಶ್ಚಿಮ ಸಹಾರಾ",
    "country_246": "ಯೆಮೆನ್",
    "country_247": "ಜಾಂಬಿಯಾ",
    "country_248": "ಜಿಂಬಾಬ್ವೆ",
    "google_login": "Google ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ",
    "State": "ರಾಜ್ಯ",
    "Activation_code": "ಕ್ರಿಯಾತ್ಮಕಗೊಳಿಸುವ ಕೋಡ್",
    "Question": "ನೀವು ಲಾಗ್ ಇನ್ ಮಾಡಿದ ಎಲ್ಲಾ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಪಟ್ಟಿ ಮಾಡಿ",
    "Copy_complete": "ನಕಲು ಪೂರ್ಣಗೊಂಡಿದೆ",
    "footer": "ಕೃತಿಸ್ವಾಮ್ಯ © 2024 MindOnMap Studio. ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ.",
    "change_password_success": "ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಬದಲಾಯಿಸಲಾಗಿದೆ",
    "successful_login_title": "ಲಾಗಿನ್ ಯಶಸ್ವಿಯಾಗಿದೆ",
    "product_page": "ಉತ್ಪನ್ನ ಪುಟ >>",
    "successful_login_info": "ಲಾಗಿನ್ ಪೂರ್ಣಗೊಂಡಿದೆ. ದಯವಿಟ್ಟು ಪ್ರಸ್ತುತ ಪುಟವನ್ನು ಮುಚ್ಚಿ ಮತ್ತು ಕೆಳಗಿನ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಮುಂದುವರಿಸಲು ಮೂಲ ಟ್ಯಾಬ್‌ಗೆ ಹಿಂತಿರುಗಿ. ಪ್ರಸ್ತುತ ಪುಟವು 5 ಸೆಕೆಂಡುಗಳಲ್ಲಿ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಮುಚ್ಚಲ್ಪಡುತ್ತದೆ. \"ಮುಗಿದಿದೆ\" ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಸ್ವಯಂ-ಮುಚ್ಚುವಿಕೆ ಅಥವಾ ಮುಚ್ಚುವಿಕೆಯು ವಿಫಲವಾದರೆ, ದಯವಿಟ್ಟು ಈ ಟ್ಯಾಬ್ ಅನ್ನು ನೇರವಾಗಿ ಮುಚ್ಚಿ.",
    "successful_login_info_firefox": "ಲಾಗಿನ್ ಪೂರ್ಣಗೊಂಡಿದೆ. ದಯವಿಟ್ಟು ಪ್ರಸ್ತುತ ಪುಟವನ್ನು ಮುಚ್ಚಿ ಮತ್ತು ಕೆಳಗಿನ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಮುಂದುವರಿಸಲು ಮೂಲ ಟ್ಯಾಬ್‌ಗೆ ಹಿಂತಿರುಗಿ.",
    "my_account": "ನನ್ನ ಖಾತೆ",
    "my_history": "ನನ್ನ ಇತಿಹಾಸ",
    "remove_watermark": "ವಾಟರ್‌ಮಾರ್ಕ್ ತೆಗೆದುಹಾಕಿ",
    "no_history": "ಇತಿಹಾಸವಿಲ್ಲ",
    "history_all": "ಎಲ್ಲವನ್ನು ಆರಿಸು",
    "history_open": "ತೆರೆಯಿರಿ",
    "history_down": "ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    "history_delete": "ಅಳಿಸಿ",
    "history_clear": "ಅಮಾನ್ಯವನ್ನು ತೆರವುಗೊಳಿಸಿ",
    "images": "ಚಿತ್ರ(ಗಳು)",
    "use_this_function": "ಈ ಉತ್ಪನ್ನವನ್ನು ಬಳಸಿ >>",
    "hd_downloading": "HD ಮೂಲ ಚಿತ್ರಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡುವ ಪ್ರಯೋಜನಗಳು:",
    "lifetimeRemaining": "ಜೀವಮಾನ-ಉಳಿದಿರುವ",
    "Remaining": "ಉಳಿದ",
    "email_verification": "ಇಮೇಲ್ ಪರಿಶೀಲನೆ",
    "email_verification_info": "ನಾವು ನಿಮ್ಮ ಇಮೇಲ್ <span class=\"email\"></span> ಗೆ ಪರಿಶೀಲನೆ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಿದ್ದೇವೆ ಮತ್ತು ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ. ಪರಿಶೀಲಿಸಿದ ನಂತರ, ಪ್ರಯೋಜನಗಳು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಿಂಕ್ ಆಗುತ್ತವೆ.",
    "wrong_email": "ತಪ್ಪಾದ ಇಮೇಲ್ ವಿಳಾಸವೇ?",
    "click_here_to_modify": "ಮಾರ್ಪಡಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
    "get_more_help": "ಹೆಚ್ಚಿನ ಸಹಾಯ ಪಡೆಯುವುದೇ?",
    "click_here": "ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
    "email_verification_info_success": "ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಿದ್ದಕ್ಕಾಗಿ ಅಭಿನಂದನೆಗಳು.",
    "email_verification_info_error": "ಅವಧಿ ಮುಗಿದ ಲಿಂಕ್‌ನಿಂದಾಗಿ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ.",
    "registration_succeeded": "ನೋಂದಣಿ ಯಶಸ್ವಿಯಾಗಿದೆ",
    "registration_succeeded_info_1": "ಅಭಿನಂದನೆಗಳು! ನೀವು ಯಶಸ್ವಿಯಾಗಿ ನೋಂದಾಯಿಸಿರುವಿರಿ. ನಿಮ್ಮ ಇಮೇಲ್ <span class=\"email\"></span> ಗೆ ನಾವು ಪರಿಶೀಲನೆ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಿದ್ದೇವೆ ಮತ್ತು ಈ ಇಮೇಲ್‌ನ ಪ್ರಯೋಜನಗಳನ್ನು ಬಳಸಲು ದಯವಿಟ್ಟು ಪರಿಶೀಲಿಸುವುದನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
    "registration_succeeded_info_2": "ಮುಖಪುಟಕ್ಕೆ ಹಿಂತಿರುಗಲು ಮತ್ತು ಈ ಉತ್ಪನ್ನವನ್ನು ಬಳಸಲು \"ಮುಗಿದಿದೆ\" ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "registration_succeeded_info_3": "ಪ್ರಸ್ತುತ ಪುಟವನ್ನು ಮುಚ್ಚಲು \"ಮುಗಿದಿದೆ\" ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಕೆಳಗಿನ ಕಾರ್ಯಾಚರಣೆಗಳಿಗಾಗಿ ಮುಖಪುಟಕ್ಕೆ ಹಿಂತಿರುಗಿ. ಮುಚ್ಚುವಿಕೆಯು ವಿಫಲವಾದರೆ, ದಯವಿಟ್ಟು ಟ್ಯಾಬ್ ಅನ್ನು ಹಸ್ತಚಾಲಿತವಾಗಿ ಮುಚ್ಚಿ.",
    "verify_email": "ಇಮೇಲ್ ಪರಿಶೀಲಿಸಿ",
    "registered_email_not_verified": "ನೋಂದಾಯಿತ ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ, ದಯವಿಟ್ಟು ಒಮ್ಮೆ ಅದನ್ನು ಪರಿಶೀಲಿಸಿ.",
    "email_verification_time_1": "ಪರಿಶೀಲನೆ ಇಮೇಲ್ ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?",
    "email_verification_time_2": "ನಂತರ",
    "email_verification_time_3": "ಅದನ್ನು ಮರುಕಳುಹಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
    "error_26301": "ದೋಷ ಕೋಡ್: 26301, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26302": "ದೋಷ ಕೋಡ್: 26302, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26303": "ಇಮೇಲ್ ಫಾರ್ಮ್ಯಾಟ್ ದೋಷ (ದೋಷ ಕೋಡ್: 26303). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ನಮೂದಿಸಿ",
    "error_26304": "8 ಅಕ್ಷರಗಳ ಮೇಲಿನ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26304)",
    "error_26305": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26305). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26306": "ಇಮೇಲ್ ಅನ್ನು ನೋಂದಾಯಿಸಲಾಗಿದೆ, ದಯವಿಟ್ಟು <a href=\"https://account.mindonmap.com/login/\">ಲಾಗಿನ್ ಮಾಡಲು ಹೋಗಿ</a>",
    "error_26307": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26307). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26308": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26308). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26401": "ದೋಷ ಕೋಡ್: 26401, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26402": "ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26402), ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26403": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26403). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26404": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26404). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26501": "ದೋಷ ಕೋಡ್: 26501, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26502": "ದೋಷ ಕೋಡ್: 26502, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26503": "ಇಮೇಲ್ ಫಾರ್ಮ್ಯಾಟ್ ದೋಷ (ದೋಷ ಕೋಡ್: 26503). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೆ ನಮೂದಿಸಿ",
    "error_26504": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26504). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26505": "ಇಮೇಲ್ ಅನ್ನು ನೋಂದಾಯಿಸಲಾಗಿಲ್ಲ, ದಯವಿಟ್ಟು <a href=\"https://account.mindonmap.com/register/\">ಅದನ್ನು ಮೊದಲು ನೋಂದಾಯಿಸಿ</a>",
    "error_26506": "ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿದೆ.",
    "error_26507": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26507). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26508": "ಪರಿಶೀಲನೆಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26508), ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26509": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26509), ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26510": "ದೋಷ ಕೋಡ್: 26510, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26601": "ದೋಷ ಕೋಡ್: 26601, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26602": "ದೋಷ ಕೋಡ್: 26602, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26603": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26603). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26604": "ದೋಷ ಕೋಡ್: 26604, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26605": "ದೋಷ ಕೋಡ್: 26605, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26701": "ದೋಷ ಕೋಡ್: 26701, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26702": "Reuqest ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26702). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26703": "ದೋಷ ಕೋಡ್: 26703, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26704": "ದೋಷ ಕೋಡ್: 26704, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26705": "ಲಾಗ್ ಇನ್ ಮಾಡಲು ನಿರೀಕ್ಷಿಸಿ (ದೋಷ ಕೋಡ್: 26705). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "no_cookie": "ನಿಮ್ಮ ಬ್ರೌಸರ್‌ನಲ್ಲಿ ಎಲ್ಲಾ ಕುಕೀಗಳನ್ನು ನಿರ್ಬಂಧಿಸು ಕಾರ್ಯವನ್ನು ನೀವು ಆನ್ ಮಾಡಿರುವಿರಿ, ಆದ್ದರಿಂದ ನೀವು ಲಾಗ್ ಇನ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ಎಲ್ಲಾ ಕುಕೀಗಳನ್ನು ಅನುಮತಿಸು ಬಾಕ್ಸ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲು ಸೆಟ್ಟಿಂಗ್‌ಗಳಿಗೆ ಹೋಗಿ.",
    "error_26801": "ದೋಷ ಕೋಡ್: 26801, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26802": "ದೋಷ ಕೋಡ್: 26802, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26803": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26803). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26804": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26804). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_order": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27098), ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ!",
    "error_order1": "ಆದೇಶದ ಪ್ರಶ್ನೆಯು ಅಪೂರ್ಣವಾಗಿದೆ (ದೋಷ ಕೋಡ್: ",
    "error_order2": "), ದಯವಿಟ್ಟು ರಿಫ್ರೆಶ್ ಮಾಡಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "modify_email_title": "ಇಮೇಲ್ ಮಾರ್ಪಡಿಸಿ",
    "modify_email_info": "ನಿಮ್ಮ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಲು ನೀವು ಮಾರ್ಪಡಿಸಿದ ಇಮೇಲ್ ಅನ್ನು ಬಳಸಬಹುದು.",
    "images_per": "ಪ್ರತಿ ಚಿತ್ರಗಳು",
    "error_26101": "ದೋಷ: 26101. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26102": "ದೋಷ: 26102. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26103": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್:26103). ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_26104": "ದೋಷ ಕೋಡ್: 26104, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26105": "ದೋಷ ಕೋಡ್: 26105, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26106": "ಅಳಿಸಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 26106). ದಯವಿಟ್ಟು ಅದನ್ನು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_26201": "ದೋಷ: 26201. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26202": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್:26202). ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_26001": "ದೋಷ: 26001. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26002": "ದೋಷ: 26002. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26003": "ದೋಷ: 26003. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26004": "ದೋಷ: 26004. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_26005": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್:26005). ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_26006": "ದೋಷ ಕೋಡ್: 26006, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_26008": "ದೋಷ: 26008. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "go_to_the_home_page": "ಮುಖಪುಟಕ್ಕೆ ಹೋಗಿ",
    "error_27101": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27101). ದಯವಿಟ್ಟು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_27201": "ದೋಷ ಕೋಡ್: 27201, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_27202": "ದೋಷ ಕೋಡ್: 27202, ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_27203": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27203). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_27204": "ಅಮಾನ್ಯ ಕೋಡ್ (ದೋಷ ಕೋಡ್: 27204).",
    "error_27205": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27205). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_27206": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27206). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "error_27207": "ವಿನಂತಿ ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 27207). ದಯವಿಟ್ಟು ಅದನ್ನು ಮರುಪ್ರಯತ್ನಿಸಿ",
    "no_history_found": "ನೀವು ಯಾವುದೇ ಉಪಕರಣವನ್ನು ಬಳಸಿಲ್ಲ! <a href=\"/\">ರಿಫ್ರೆಶ್ ಮಾಡಿ</a> ಅಥವಾ <a href=\"https://www.mindonmap.com/\">ಅಧಿಕೃತ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಹೋಗಿ</a>",
    "error_25301": "ದೋಷ: 25301. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_25302": "ದೋಷ: 25302. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
    "error_25303": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 25303). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_25304": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 25304). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_25305": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 25305). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "error_25306": "ವಿನಂತಿಯು ವಿಫಲವಾಗಿದೆ (ದೋಷ ಕೋಡ್: 25306). ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    "image_upscaler_p": "ವಾಟರ್‌ಮಾರ್ಕ್ ಇಲ್ಲದ ಚಿತ್ರ ಡೌನ್‌ಲೋಡ್ ಪ್ರಯೋಜನಗಳು:",
    "Available_for": "ಲಭ್ಯವಿರುವ:",
    "credit_per": "ಪ್ರತಿ HD ಚಿತ್ರಕ್ಕೆ %s ಕ್ರೆಡಿಟ್(ಗಳು).",
    "still_valid": "ಖರೀದಿಸಿದ ಯೋಜನೆ(ಗಳು) ಇನ್ನೂ ಮಾನ್ಯವಾಗಿದೆ",
    "credit": "ಕ್ರೆಡಿಟ್(ಗಳು)",
    "pc_3rd_info": "ಯಶಸ್ವಿಯಾಗಿ ಲಾಗಿನ್ ಮಾಡಿ. ಹೆಚ್ಚಿನ ಕಾರ್ಯಾಚರಣೆಗಾಗಿ ದಯವಿಟ್ಟು ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಹೋಗಿ.",
    "use_online": "ಆನ್‌ಲೈನ್ ಸೇವೆಯನ್ನು ಬಳಸಿ",
    "use_download": "ಡೆಸ್ಕ್ಟಾಪ್ ಪ್ರೋಗ್ರಾಂ ಬಳಸಿ",
    "use_immediately": "ತಕ್ಷಣ ಬಳಸಿ",
    "Use_in_browser": "ಬ್ರೌಸರ್‌ನಲ್ಲಿ ಬಳಸಿ",
    "win_desktop": "ವಿಂಡೋಸ್",
    "Mac_desktop": "ಮ್ಯಾಕ್",
    "credits_per": "ಪ್ರತಿ ತಿಂಗಳಿಗೆ {%} ಕ್ರೆಡಿಟ್‌ಗಳು",
    "expire": "ಮುಕ್ತಾಯ ಸಮಯ:",
    "viewDetails": "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    "viewHistory": "ಇತಿಹಾಸದ ಪ್ರಯೋಜನಗಳನ್ನು ವೀಕ್ಷಿಸಿ>>",
    "viewDetailsInfo": "ಸೂಚನೆ: ಮುಕ್ತಾಯದ ನಂತರ 7 ದಿನಗಳಲ್ಲಿ ಚಂದಾದಾರಿಕೆ ಪ್ರಯೋಜನಗಳನ್ನು ನವೀಕರಿಸಿದರೆ, ಬಳಕೆಯಾಗದ ಪ್ರಯೋಜನಗಳನ್ನು ಬಳಸುವುದನ್ನು ಮುಂದುವರಿಸಬಹುದು. ಅಲ್ಲದೆ, ಮುಕ್ತಾಯ ಸಮಯವನ್ನು ಹೊಸ ಚಂದಾದಾರಿಕೆಯ ಮುಕ್ತಾಯ ಸಮಯಕ್ಕೆ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನವೀಕರಿಸಲಾಗುತ್ತದೆ. 7 ದಿನಗಳ ಮುಕ್ತಾಯ ಸಮಯದ ನಂತರ ಯಾವುದೇ ಹೊಸ ಚಂದಾದಾರಿಕೆ ಇಲ್ಲದಿದ್ದರೆ, ಎಲ್ಲಾ ಅವಧಿ ಮೀರಿದ ಪ್ರಯೋಜನಗಳನ್ನು ತೆರವುಗೊಳಿಸಲಾಗುತ್ತದೆ.",
    "connect_account": "ನಿಮ್ಮ ಖಾತೆಗೆ ಇಮೇಲ್ ಅನ್ನು ಬಂಧಿಸಿ",
    "connect_account_info": "ಬೈಂಡಿಂಗ್ ನಂತರ, ನೀವು ಈ ಇಮೇಲ್ ವಿಳಾಸದೊಂದಿಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಬಹುದು.",
    "connect_now": "ಈಗ ಬಂಧಿಸಿ",
    "no_email_bind": "ಇಮೇಲ್ ಬೈಂಡ್ ಇಲ್ಲ",
    "bind_email": "ಬೈಂಡ್ ಇಮೇಲ್",
    "connect_your_email_placeholder": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸ ನಮೂದಿಸಿ",
    "bind_an_email": "ಇಮೇಲ್ ಅನ್ನು ಬಂಧಿಸಿ",
    "bind_info": "ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಲಾಗ್ ಇನ್ ಆಗಿರುವಿರಿ. ನಿಮ್ಮ ಪ್ರಯೋಜನಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಖಾತೆಗೆ ಇಮೇಲ್ ಅನ್ನು ಬಂಧಿಸಿ.",
    "bind_later": "ನಂತರ ಬಂಧಿಸಿ",
    "hi": "ಅವನ!",
    "Personal_Information": "ವಯಕ್ತಿಕ ಮಾಹಿತಿ",
    "Access": "ಪ್ರವೇಶ",
    "Subscription_Plan": "(ಚಂದಾದಾರಿಕೆ ಯೋಜನೆ)",
    "No_orders": "ಯಾವುದೇ ಆದೇಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ.",
    "No_data": "ಮಾಹಿತಿ ಇಲ್ಲ",
    "Featured_Products": "ವೈಶಿಷ್ಟ್ಯಗೊಳಿಸಿದ ಉತ್ಪನ್ನಗಳು",
    "More_Products": "ಇನ್ನಷ್ಟು ಉತ್ಪನ್ನಗಳು",
    "Free_Download": "ಉಚಿತ ಡೌನ್ಲೋಡ್",
    "Get_Started": "ಪ್ರಾರಂಭಿಸಿ",
    "Subscribe": "ಚಂದಾದಾರರಾಗಿ",
    "Verified": "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
    "back_to_account_center": "ಖಾತೆ ಕೇಂದ್ರಕ್ಕೆ ಹಿಂತಿರುಗಿ",
    "success": "ಯಶಸ್ಸು!",
    "successfully": "ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಖಾತೆಯನ್ನು ನೋಂದಾಯಿಸಿರುವಿರಿ.",
    "Continue": "ಮುಂದುವರಿಸಿ",
    "Already": "ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ?",
    "loading_verification": "ಪರಿಶೀಲನೆ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ...",
    "email_no_verification": "ಕ್ಷಮಿಸಿ, ನೋಂದಾಯಿತ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ. ದಯವಿಟ್ಟು ಮೇಲಿನ ಸೂಚನೆಗಳ ಪ್ರಕಾರ ಪರಿಶೀಲನೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ ಮತ್ತು ನೋಂದಣಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು \"ಮುಗಿದಿದೆ\" ಬಟನ್ ಅನ್ನು ಮತ್ತೊಮ್ಮೆ ಕ್ಲಿಕ್ ಮಾಡಿ.",
    "will_expire_after": "ನಂತರ ಅವಧಿ ಮುಗಿಯುತ್ತದೆ",
    "hours": "ಗಂಟೆಗಳು",
  },
  "ko": {
    "overtime": "오류 코드: {%}, 다시 시도해 주세요.",
    "isnetwork": "인터넷 오류입니다. 확인하고 다시 시도해 주세요.",
    "email_placeholder": "이메일",
    "email_empty": "이메일을 입력해주세요",
    "email_not_valid": "이메일이 유효하지 않습니다",
    "email_not_valid_1": "이메일을 입력해주세요",
    "email_not_valid_2": "이메일이 유효하지 않습니다. 다른 주소를 사용해 주세요.",
    "email_not_valid_3": "이메일 입력이 없습니다",
    "password_placeholder": "비밀번호",
    "password_empty": "비밀번호를 입력해주세요",
    "password_not_valid": "잘못된 계정 또는 비밀번호",
    "password_not_valid_1": "8자 이상의 비밀번호가 필요합니다",
    "password_not_valid_2": "비밀번호를 생성해주세요",
    "password_placeholder_1": "비밀번호를 만드세요",
    "password_placeholder_2": "암호를 확인",
    "password_placeholder_3": "새 비밀번호 만들기",
    "password_placeholder_4": "새 암호를 확인합니다",
    "password_placeholder_5": "이전 비밀번호를 입력하세요",
    "copy_password_empty": "비밀번호를 확인해 주세요",
    "copy_password_not_valid": "비밀번호를 확인해 주세요",
    "copy_passwords_inconsistent": "비밀번호 확인이 일치하지 않습니다",
    "code_empty": "인증번호를 입력해주세요",
    "code_not_valid": "잘못된 인증 코드",
    "code_placeholder": "확인 코드",
    "not_received_code": "오랫동안 메일함이 인증번호를 받지 못했다면 인증코드를 다시 받아보세요.",
    "get_first_code": "먼저 인증번호를 받아주세요.",
    "last_name_placeholder": "당신의 성을 입력하세요",
    "first_name_placeholder": "이름을 입력하세요",
    "address_placeholder": "주소를 입력해주세요",
    "no_code_text": "인증 코드를 보냈습니다. 코드를 입력하세요. <span class='tips'>코드를 받지 못하셨나요?",
    "no_code_text_1": "1. 이메일 주소가 유효한지, 이메일을 받을 수 있는지 확인하세요.",
    "no_code_text_2": "2. 이메일이 시스템에 의해 자동으로 전송되는 경우 스팸 또는 정크 이메일로 표시될 수 있습니다. 이메일이 휴지통에 있는지 확인해주세요.",
    "no_code_text_3": "3. 문제를 해결할 수 없나요? ",
    "no_code_text_3_span": "그런 다음 여기를 클릭하여 문의해 주세요.",
    "order_no": "아직 구매한 제품이 없습니다. 질문이 있는 경우 <a href=\"https://www.mindonmap.com/contact-us/\">저희에게 연락</a>해 주세요.",
    "error_24901": "현재 계정에는 연결된 이메일이 없으며 주문을 찾을 수 없습니다. 이메일을 연결해주세요.",
    "user_guide": "이용안내>>",
    "download": "다운로드",
    "order_number": "주문 번호:",
    "Refund": "환불하다",
    "Disabled": "장애가 있는",
    "Normal": "정상",
    "Modify": "수정하다",
    "Modify_1": "수정>>",
    "Connect": "연결하다",
    "unlink_success": "연결이 해제되었습니다.",
    "connect_success": "성공적으로 연결되었습니다",
    "feedback_title": "피드백을 보내주셔서 감사합니다. 문제를 남겨주시면 24시간 이내에 답변해드리겠습니다.",
    "feedback_thank_you": "감사합니다!<br />귀하의 의견이 성공적으로 제출되었습니다.",
    "feedback_email": "여기에 이메일을 입력하세요!",
    "feedback_content": "발생한 문제나 제안 사항을 여기에 남겨주세요.",
    "feedback_submit": "제출하다",
    "form_contents": "설명을 입력하지 않았습니다. 입력하신 후 다시 제출해 주세요.",
    "old_password": "이전 비밀번호를 입력해주세요",
    "new_password": "새로운 비밀번호를 만들어주세요",
    "old_new_password": "새 비밀번호는 이전 비밀번호와 같을 수 없습니다.",
    "incorrect_password": "잘못된 비밀번호",
    "delete_no": "지금 삭제",
    "Caps": "Caps Lock이 켜져 있습니다.",
    "Get": "얻다",
    "Done": "완료",
    "error_20001": "오류: 20001. 다시 로그인하십시오.",
    "error_20002": "오류: 20002. 다시 로그인하십시오.",
    "error_20003": "오류: 20003. 다시 로그인하십시오.",
    "error_20004": "요청이 실패했습니다(오류 코드: 20004). 다시 시도해 주세요.",
    "error_20005": "로그인 세션이 만료되었습니다(오류: 20005). 다시 로그인해주세요.",
    "error_20006": "요청이 실패했습니다(오류 코드: 20006). 다시 시도해 주세요.",
    "error_20007": "로그인 세션이 만료되었습니다(오류: 20007). 다시 로그인해주세요.",
    "error_20008": "로그인 세션이 만료되었습니다(오류: 20008). 다시 로그인해주세요.",
    "error_20009": "로그인 세션이 만료되었습니다(오류: 20009). 다시 로그인해주세요.",
    "error_20101": "이메일을 입력해주세요. (오류코드: 20101)",
    "error_20102": "이메일이 유효하지 않습니다(오류 코드: 20102).",
    "error_20103": "요청이 실패했습니다(오류 코드: 20103). 다시 시도해 주세요",
    "error_20104": "이메일이 이미 사용 중입니다. <a href=\"https://account.mindonmap.com/login/\">로그인</a>하거나 새 이메일로 등록하세요.",
    "error_20105": "요청이 실패했습니다(오류 코드: 20105). 다시 시도해 주세요",
    "error_20106": "이메일을 보내지 못했습니다. 다시 시도해 주세요.",
    "error_20201": "이메일을 입력해주세요. (오류코드: 20201)",
    "error_20202": "비밀번호를 입력해주세요. (오류코드: 20202)",
    "error_20203": "인증코드를 입력해주세요. (오류코드: 20203)",
    "error_20204": "이메일이 유효하지 않습니다(오류 코드: 20204).",
    "error_20205": "8자 이상의 비밀번호가 필요합니다. (에러코드: 20205)",
    "error_20206": "요청이 실패했습니다(오류 코드: 20206). 다시 시도해 주세요",
    "error_20207": "잘못된 인증 코드",
    "error_20208": "요청이 실패했습니다(오류 코드: 20208). 다시 시도해 주세요",
    "error_20209": "요청이 실패했습니다(오류 코드: 20209). 다시 시도해 주세요",
    "error_20301": "이메일을 입력해주세요. (오류코드: 20301)",
    "error_20302": "오류: 20302. 문의해 주세요.",
    "error_20303": "이메일이 유효하지 않습니다(오류 코드: 20303).",
    "error_20304": "요청이 실패했습니다(오류 코드: 20304). 다시 시도해 주세요",
    "error_20305": "계정이 존재하지 않습니다. 다시 입력하거나 먼저 <a href=\"https://account.mindonmap.com/register/\">만들기</a>하세요.",
    "error_20306": "아직 비밀번호가 없습니다. <a href=\"https://account.mindonmap.com/passwordless-login/\">비밀번호 없는 로그인</a> 또는 <a href=\"https://account.mindonmap.com/create-password/\">를 사용하세요. 비밀번호를 설정</a>하고 로그인하세요.",
    "error_20308": "요청이 실패했습니다(오류 코드: 20308). 다시 시도해 주세요",
    "error_20401": "로그아웃에 실패했습니다(오류 코드: 20401). 다시 시도해 주세요",
    "error_20501": "이메일을 입력해주세요. (오류코드: 20501)",
    "error_20502": "이메일이 유효하지 않습니다(오류 코드: 20502).",
    "error_20503": "요청이 실패했습니다(오류 코드: 20503). 다시 시도해 주세요",
    "error_20504": "이메일을 보내지 못했습니다. 다시 시도해 주세요.",
    "error_20601": "이메일을 입력해주세요. (오류코드: 20601)",
    "error_20602": "인증코드를 입력해주세요. (오류코드: 20602)",
    "error_20603": "이메일이 유효하지 않습니다(오류 코드: 20603).",
    "error_20604": "요청이 실패했습니다(오류 코드: 20604). 다시 시도해 주세요",
    "error_20606": "요청이 실패했습니다(오류 코드: 20606). 다시 시도해 주세요",
    "error_20607": "요청이 실패했습니다(오류 코드: 20607). 다시 시도해 주세요",
    "error_20608": "요청이 실패했습니다(오류 코드: 20608). 다시 시도해 주세요",
    "error_20701": "이메일을 입력해주세요. (오류코드: 20701)",
    "error_20702": "이메일이 유효하지 않습니다(오류 코드: 20702).",
    "error_20703": "요청이 실패했습니다(오류 코드: 20703). 다시 시도해 주세요",
    "error_20704": "계정이 존재하지 않습니다. 다시 입력하거나 먼저 <a href=\"https://account.mindonmap.com/register/\">만들기</a>하세요.",
    "error_20705": "요청이 실패했습니다(오류 코드: 20705). 다시 시도해 주세요",
    "error_20706": "이메일을 보내지 못했습니다. 다시 시도해 주세요",
    "error_20801": "이메일을 입력해주세요. (오류코드: 20801)",
    "error_20802": "오류: 20802. 문의해 주세요.",
    "error_20803": "인증번호를 입력해주세요. (오류코드: 20803)",
    "error_20804": "이메일이 유효하지 않습니다(오류 코드: 20804).",
    "error_20805": "8자 이상의 비밀번호가 필요합니다. (에러코드: 20805)",
    "error_20806": "요청이 실패했습니다(오류 코드: 20806). 다시 시도해 주세요",
    "error_20808": "요청이 실패했습니다(오류 코드: 20808). 다시 시도해 주세요",
    "error_20901": "요청이 실패했습니다(오류 코드: 20901). 다시 시도해 주세요",
    "error_20902": "요청이 실패했습니다(오류 코드: 20902). 다시 시도해 주세요",
    "error_21000": "변경사항이 저장되었습니다.",
    "error_21001": "제출된 정보가 없습니다(오류 코드: 21001).",
    "error_21002": "요청이 실패했습니다(오류 코드: 21002). 다시 시도해 주세요",
    "error_21101": "이메일을 입력해주세요. (오류코드: 21101)",
    "error_21102": "이메일이 유효하지 않습니다(오류 코드: 21102).",
    "error_21103": "요청이 실패했습니다(오류 코드: 21103). 다시 시도해 주세요.",
    "error_21104": "이메일이 이미 연결되어 있습니다. 새 이메일을 사용해 주세요.",
    "error_21105": "요청이 실패했습니다(오류 코드: 21105). 다시 시도해 주세요.",
    "error_21106": "이메일을 보내지 못했습니다. 다시 시도해 주세요",
    "error_21201": "이메일을 입력해주세요. (오류코드: 21201)",
    "error_21202": "인증코드를 입력해주세요. (오류코드: 21202)",
    "error_21203": "이메일이 유효하지 않습니다(오류 코드: 21203).",
    "error_21204": "오류: 21204. 문의해 주세요.",
    "error_21205": "오류: 21205. 문의해 주세요.",
    "error_21206": "8자 이상의 비밀번호가 필요합니다. (에러코드: 21206)",
    "error_21207": "요청이 실패했습니다(오류 코드: 21207). 다시 시도해 주세요",
    "error_21209": "요청이 실패했습니다(오류 코드: 21209). 다시 시도해 주세요",
    "error_21301": "이전 비밀번호를 입력해주세요. (오류코드: 21301)",
    "error_21302": "새로운 비밀번호를 만드세요. (오류코드: 21302)",
    "error_21303": "새 비밀번호는 이전 비밀번호와 같을 수 없습니다. (오류: 21303)",
    "error_21304": "8자 이상의 비밀번호가 필요합니다. (에러코드: 21304)",
    "error_21306": "요청이 실패했습니다(오류 코드: 21306). 다시 시도해 주세요",
    "error_21402": "요청이 실패했습니다(오류 코드: 21402). 다시 시도해 주세요",
    "error_21403": "인증코드 전송에 실패했습니다. 다시 보내주세요",
    "error_21500": "계정이 삭제되었습니다",
    "error_21501": "인증코드를 입력해주세요. (오류코드: 21501)",
    "error_21502": "로그인 세션이 만료되었습니다(오류: 21502). 다시 로그인해주세요.",
    "error_21503": "요청이 실패했습니다(오류 코드: 21503). 다시 시도해 주세요",
    "error_21505": "요청이 실패했습니다(오류 코드: 21505). 다시 시도해 주세요.",
    "error_21601": "오류: 20601. 문의해 주세요.",
    "error_21602": "잘못된 검증(오류: 20602). 다시 시도해 주세요.",
    "error_21603": "오류: 20603. 다시 시도해 주세요.",
    "error_21604": "요청이 실패했습니다(오류 코드: 21604). 다시 시도해 주세요",
    "error_21606": "요청이 실패했습니다(오류 코드: 21606). 다시 시도해 주세요",
    "error_21611": "요청이 실패했습니다(오류 코드: 21611). 다시 시도해 주세요",
    "error_21801": "오류: 21801. 문의해 주세요.",
    "error_21802": "요청이 실패했습니다(오류: 21802). 다시 시도해 주세요",
    "error_21803": "오류: 21803. 다시 시도해 주세요.",
    "error_21804": "요청이 실패했습니다(오류 코드: 21804). 다시 시도해 주세요",
    "error_21806": "오류: 21806. 다시 시도해 주세요.",
    "error_21807": "오류: 21807. 문의해 주세요.",
    "error_21808": "오류: 21808. 문의해 주세요.",
    "error_21809": "오류: 21809. 문의해 주세요.",
    "error_21810": "오류: 21810. 문의해 주세요.",
    "error_21811": "오류: 21811. 문의해 주세요.",
    "error_21812": "오류: 21812. 문의해 주세요.",
    "error_21813": "요청이 실패했습니다(오류 코드: 21813). 다시 시도해 주세요",
    "error_21814": "오류: 21814. 문의해 주세요.",
    "error_21815": "요청이 실패했습니다(오류 코드: 21815). 다시 시도해 주세요",
    "error_21816": "오류: 21816. 문의해 주세요.",
    "error_21817": "오류: 21817. 문의해 주세요.",
    "error_21818": "오류: 21818. 문의해 주세요.",
    "error_21819": "요청이 실패했습니다(오류 코드: 21819). 다시 시도해 주세요",
    "error_21820": "오류: 21820. 문의해 주세요.",
    "error_21821": "오류: 21821. 문의해 주세요.",
    "error_21822": "오류: 21822. 문의해 주세요.",
    "error_21823": "요청이 실패했습니다(오류 코드: 21823). 다시 시도해 주세요",
    "error_21824": "요청이 실패했습니다(오류 코드: 21824). 다시 시도해 주세요",
    "error_21825": "요청이 실패했습니다(오류 코드: 21825). 다시 시도해 주세요",
    "error_21826": "요청이 실패했습니다(오류 코드: 21826). 다시 시도해 주세요",
    "error_21901": "오류: 21901. 문의해 주세요.",
    "error_21902": "요청이 실패했습니다(오류 코드: 21902). 다시 시도해 주세요",
    "error_21903": "계정 상태가 변경되었습니다(오류 코드: 21903). 페이지를 새로 고친 후 다시 시도해 주세요.",
    "error_21904": "오류: 21904. 다시 시도해 주세요.",
    "error_21905": "오류: 21905. 다시 시도해 주세요.",
    "error_21906": "요청이 실패했습니다(오류 코드: 21906). 다시 시도해 주세요",
    "error_21907": "Google 계정이 다른 계정에 연결되었습니다",
    "error_21908": "요청이 실패했습니다(오류 코드: 21908). 다시 시도해 주세요",
    "error_22001": "요청이 실패했습니다(오류 코드: 22001). 다시 시도해 주세요",
    "error_22002": "추가 로그인 없이 연결 해제에 실패했습니다.",
    "error_22003": "요청이 실패했습니다(오류 코드: 22003). 다시 시도해 주세요",
    "error_22101": "오류: 22101. 문의해 주세요.",
    "error_22102": "계정 상태가 변경되었습니다(오류 코드: 22102). 페이지를 새로 고친 후 다시 시도해 주세요.",
    "error_22103": "요청이 실패했습니다(오류 코드: 22103). 다시 시도해 주세요",
    "error_22104": "계정 상태가 변경되었습니다(오류 코드: 22104). 페이지를 새로 고친 후 다시 시도해 주세요.",
    "error_22105": "오류: 22105. 다시 시도해 주세요.",
    "error_22106": "오류: 22106. 다시 시도해 주세요.",
    "error_22107": "오류: 22107. 문의해 주세요.",
    "error_22108": "요청이 실패했습니다(오류 코드: 22108). 다시 시도해 주세요",
    "error_22201": "오류: 22201. 문의해 주세요.",
    "error_22202": "잘못된 검증(오류: 22202). 다시 시도해 주세요.",
    "error_22203": "오류: 22203. 다시 시도해 주세요.\"",
    "error_22204": "요청이 실패했습니다(오류 코드: 22204). 다시 시도해 주세요",
    "error_22206": "요청이 실패했습니다(오류 코드: 22206). 다시 시도해 주세요",
    "error_22401": "오류: 22401. 문의해 주세요.",
    "error_22402": "잘못된 검증(오류: 22402). 다시 시도해 주세요.",
    "error_22403": "오류: 22403. 다시 시도해 주세요.",
    "error_22404": "요청이 실패했습니다(오류 코드: 22404). 다시 시도해 주세요",
    "error_22405": "Facebook 계정이 다른 이메일에 연결되었습니다",
    "error_22406": "오류: 22406. 다시 시도해 주세요.",
    "error_22407": "오류: 22407. 문의해 주세요.",
    "error_22408": "오류: 22408. 문의해 주세요.",
    "error_22409": "오류: 22409. 문의해 주세요.",
    "error_22410": "오류: 224010. 문의해 주세요.",
    "error_22411": "오류: 224011. 문의해 주세요.",
    "error_22412": "오류: 224012. 문의해 주세요.",
    "error_22413": "오류: 22413. 문의해 주세요.",
    "error_22414": "요청이 실패했습니다(오류 코드: 22414). 다시 시도해 주세요",
    "error_22415": "오류: 22415. 문의해 주세요.",
    "error_22416": "오류: 22416. 문의해 주세요.",
    "error_22417": "오류: 22417. 문의해 주세요.",
    "error_22418": "요청이 실패했습니다(오류 코드: 22418). 다시 시도해 주세요",
    "error_22419": "오류: 22419. 문의해 주세요.",
    "error_22420": "오류: 22420. 문의해 주세요.",
    "error_22421": "오류: 22421. 문의해 주세요.",
    "error_22422": "요청이 실패했습니다(오류 코드: 22422). 다시 시도해 주세요",
    "error_22423": "요청이 실패했습니다(오류 코드: 22423). 다시 시도해 주세요",
    "error_22424": "요청이 실패했습니다(오류 코드: 22424). 다시 시도해 주세요",
    "error_22425": "요청이 실패했습니다(오류 코드: 22425). 다시 시도해 주세요",
    "error_20098": "오류 코드: 20098. 비공개 모드에서 검색하는 경우 일반 모드로 전환한 후 다시 시도하세요.",
    "error_22298": "Google 요청이 실패했습니다(오류: 22298). 다시 시도해 주세요.",
    "error_22498": "Facebook 요청이 실패했습니다(오류: 22498). 다시 시도해 주세요.",
    "error_24902": "요청이 실패했습니다(오류 코드: 24902). 다시 시도해 주세요",
    "error_24903": "요청이 실패했습니다(오류 코드: 24903). 다시 시도해 주세요",
    "error_24904": "요청이 실패했습니다(오류 코드: 24904). 다시 시도해 주세요",
    "error_24905": "요청이 실패했습니다(오류 코드: 24905). 다시 시도해 주세요",
    "login_title": "MindOnMap에 로그인",
    "log_in": "로그인",
    "no_account": "계정이 없나요?",
    "create_it": "만들기",
    "or_log_in_with": "또는 다음으로 로그인하세요.",
    "passwordless_login": "비밀번호 없는 로그인",
    "log_in_done": "로그인 완료",
    "three_rd_account_connect_info": "축하해요! 성공적으로 로그인되었습니다. 이제 향후 로그인에 사용되는 하나의 이메일 계정을 연결할 수 있습니다.",
    "see_my_account": "내 계정 보기",
    "three_rd_login_merge_account": "타사 계정 이메일 주소가 가입되어 있습니다. 해당 이메일 주소로 직접 접속하여 로그인하시겠습니까?",
    "connect_log_in": "연결 및 로그인",
    "create_an_account": "계정 만들기",
    "back_to_log_in": "로그인으로 돌아가기",
    "create_password": "비밀번호 생성",
    "create_now": "지금 만들기",
    "password_login_subtitle": "이메일을 이용한 비밀번호 없는 로그인",
    "account_login": "계정 로그인",
    "rights": "이 계정을 만들면 <a href=\"https://www.mindonmap.com/terms-and-conditions/\">서비스 약관</a> 및 <a href=\"https://www.mindonmap.com/privacy-policy/\">개인정보 보호정책</a>",
    "passwordless_login_done": "비밀번호 없이 로그인 완료",
    "passwordless_login_info": "축하합니다. 비밀번호 없는 로그인이 성공적으로 완료되었습니다. 이 계정에 대한 비밀번호를 생성하고 나중에 해당 계정과 비밀번호로 로그인할 수 있습니다. <a href=\"/create-password\" style=\"display:initial;\">지금 만들기</a>",
    "sign_up": "가입하기",
    "register_info": "계정 만들기",
    "reset_now": "지금 재설정",
    "forgot_password": "비밀번호를 잊으 셨나요",
    "reset_password_subtitle": "계정 이메일을 사용하여 비밀번호를 재설정하세요.",
    "plan_products": "계획 및 제품",
    "nick_name": "사용자 이름:",
    "email": "이메일:",
    "my_products": "내 제품",
    "my_orders": "내 주문",
    "unlink": "풀리다",
    "link": "링크",
    "connected_accounts": "연결된 계정",
    "last_name": "성:",
    "first_name": "이름:",
    "Gender": "성별:",
    "Birth": "출생:",
    "Month": "월",
    "Year": "년도",
    "Country_Region": "국가/지역:",
    "Address": "주소:",
    "Save": "구하다",
    "Date": "날짜",
    "Male": "남성",
    "Female": "여성",
    "Unspecified": "지정되지 않음",
    "Security": "보안",
    "change_password": "비밀번호 변경",
    "change_now": "지금 변경",
    "connect_email": "이메일 연결",
    "delete_account": "계정 삭제",
    "delete_account_info": "귀하의 계정이 삭제되면 귀하의 계정과 연결된 내 계정의 모든 데이터가 영구적으로 삭제되며 귀하는 해당 데이터를 복구하지 못할 수도 있습니다. 조심스럽게 밟는 것이 좋습니다.",
    "Delete": "삭제",
    "Logout": "로그 아웃",
    "my_profile": "내 프로필",
    "guides_faqs": "가이드 및 FAQ",
    "More": "더",
    "guides": "가이드",
    "register": "등록하다",
    "hot_faq": "뜨거운 FAQ",
    "Contents": "내용물:",
    "contact_us": "문의하기>>",
    "plan": "계획",
    "unregistered": "미등록",
    "buy_more": "추가 구매",
    "buy_again": "다시 구매",
    "buy_now": "지금 구매",
    "free_no_limit": "무료 & 제한 없음",
    "expired": "만료됨",
    "lifetime": "일생",
    "remain": "유지하다",
    "day_s": "날)",
    "error_24801": "요청이 실패했습니다(오류 코드: 24801). 다시 시도해 주세요",
    "no_app_found": "앱을 찾을 수 없습니다!<a href=\"/\">새로고침</a>하거나 <a href=\"https://www.mindonmap.com/\">공식 웹사이트로 이동</a>하세요.",
    "get_more": "더 알아보기 >>",
    "edit_photo": "사진 편집",
    "select_photo": "사진 선택",
    "change_photo": "사진 변경",
    "cancel": "취소",
    "hide_password": "비밀번호 숨기기",
    "show_password": "비밀번호 표시",
    "zoom_in": "확대",
    "zoom_out": "축소",
    "rotate": "회전",
    "horizontal_flip": "수평 뒤집기",
    "vertical_flip": "수직 뒤집기",
    "country": "국가",
    "country_1": "국가/지역을 선택하세요.",
    "country_2": "올란드섬",
    "country_3": "아프가니스탄",
    "country_4": "알바니아",
    "country_5": "알제리",
    "country_6": "아메리칸사모아",
    "country_7": "안도라",
    "country_8": "앙골라",
    "country_9": "앵귈라",
    "country_10": "남극 대륙",
    "country_11": "앤티가 바부다",
    "country_12": "아르헨티나",
    "country_13": "아르메니아",
    "country_14": "아루바",
    "country_15": "호주",
    "country_16": "오스트리아",
    "country_17": "아제르바이잔",
    "country_18": "바레인",
    "country_19": "방글라데시",
    "country_20": "바베이도스",
    "country_21": "벨라루스",
    "country_22": "벨기에",
    "country_23": "벨리즈",
    "country_24": "베냉",
    "country_25": "버뮤다",
    "country_26": "부탄",
    "country_27": "볼리비아",
    "country_28": "보스니아 및 헤르체고비나",
    "country_29": "보츠와나",
    "country_30": "부베 섬",
    "country_31": "브라질",
    "country_32": "영국령 인도양 지역",
    "country_33": "영국령 버진아일랜드",
    "country_34": "브루나이",
    "country_35": "불가리아",
    "country_36": "부르키나",
    "country_37": "부룬디",
    "country_38": "캄보디아",
    "country_39": "카메룬",
    "country_40": "캐나다",
    "country_41": "카보베르데",
    "country_42": "카리브해 네덜란드",
    "country_43": "케이맨 제도",
    "country_44": "중앙 아프리카 공화국",
    "country_45": "차드",
    "country_46": "칠레",
    "country_47": "중국",
    "country_48": "크리스마스 섬",
    "country_49": "코코스(킬링) 제도",
    "country_50": "콜롬비아",
    "country_51": "쿡 제도",
    "country_52": "코스타리카",
    "country_53": "코트디부아르",
    "country_54": "크로아티아",
    "country_55": "쿠바",
    "country_56": "키프로스",
    "country_57": "체코 공화국",
    "country_58": "콩고민주공화국",
    "country_59": "덴마크",
    "country_60": "지부티",
    "country_61": "도미니카",
    "country_62": "도미니카 공화국",
    "country_63": "에콰도르",
    "country_64": "이집트",
    "country_65": "엘살바도르",
    "country_66": "적도기니",
    "country_67": "에리트레아",
    "country_68": "에스토니아",
    "country_69": "에티오피아",
    "country_70": "포클랜드 제도",
    "country_71": "페로 제도",
    "country_72": "미크로네시아 연방",
    "country_73": "피지",
    "country_74": "핀란드",
    "country_75": "프랑스",
    "country_76": "프랑스령 기아나",
    "country_77": "프랑스 령 폴리네시아의",
    "country_78": "프랑스 남부 영토",
    "country_79": "가봉",
    "country_80": "감비아",
    "country_81": "그루지야",
    "country_82": "독일",
    "country_83": "가나",
    "country_84": "지브롤터",
    "country_85": "영국(영국, 잉글랜드)",
    "country_86": "그리스",
    "country_87": "그린란드",
    "country_88": "그레나다",
    "country_89": "과들루프",
    "country_90": "괌",
    "country_91": "과테말라",
    "country_92": "건지",
    "country_93": "기니",
    "country_94": "기니비사우",
    "country_95": "가이아나",
    "country_96": "아이티",
    "country_97": "허드 섬 및 맥도날드 섬",
    "country_98": "온두라스",
    "country_99": "홍콩",
    "country_100": "헝가리",
    "country_101": "아이슬란드",
    "country_102": "인도",
    "country_103": "인도네시아",
    "country_104": "이란",
    "country_105": "이라크",
    "country_106": "아일랜드",
    "country_107": "맨섬",
    "country_108": "이스라엘",
    "country_109": "이탈리아",
    "country_110": "자메이카",
    "country_111": "일본",
    "country_112": "저지",
    "country_113": "요르단",
    "country_114": "카자흐스탄",
    "country_115": "케냐",
    "country_116": "키리바시",
    "country_117": "쿠웨이트",
    "country_118": "키르기스스탄",
    "country_119": "라오스",
    "country_120": "라트비아",
    "country_121": "레바논",
    "country_122": "레소토",
    "country_123": "라이베리아",
    "country_124": "리비아",
    "country_125": "리히텐슈타인",
    "country_126": "리투아니아",
    "country_127": "룩셈부르크",
    "country_128": "마카오",
    "country_129": "마다가스카르",
    "country_130": "말라위",
    "country_131": "말레이시아",
    "country_132": "몰디브",
    "country_133": "말리",
    "country_134": "몰타",
    "country_135": "마샬 제도",
    "country_136": "마르티니크",
    "country_137": "모리타니",
    "country_138": "모리셔스",
    "country_139": "마요트",
    "country_140": "멕시코",
    "country_141": "몰도바",
    "country_142": "모나코",
    "country_143": "몽골리아",
    "country_144": "몬테네그로",
    "country_145": "몬세라트",
    "country_146": "모로코",
    "country_147": "모잠비크",
    "country_148": "미얀마(버마)",
    "country_149": "나미비아",
    "country_150": "나우루",
    "country_151": "네팔",
    "country_152": "네덜란드",
    "country_153": "뉴 칼레도니아",
    "country_154": "뉴질랜드",
    "country_155": "니카라과",
    "country_156": "니제르",
    "country_157": "나이지리아",
    "country_158": "니우에",
    "country_159": "노퍽 섬",
    "country_160": "북한",
    "country_161": "북마리아나 제도",
    "country_162": "노르웨이",
    "country_163": "오만",
    "country_164": "파키스탄",
    "country_165": "팔라우",
    "country_166": "팔레스타인 영토",
    "country_167": "파나마",
    "country_168": "파푸아 뉴기니",
    "country_169": "파라과이",
    "country_170": "페루",
    "country_171": "핏케언 제도",
    "country_172": "폴란드",
    "country_173": "포르투갈",
    "country_174": "푸에르토 리코",
    "country_175": "카타르",
    "country_176": "마케도니아공화국(FYROM)",
    "country_177": "콩고 공화국",
    "country_178": "재결합",
    "country_179": "루마니아",
    "country_180": "러시아 연방",
    "country_181": "르완다",
    "country_182": "생 바르텔레미",
    "country_183": "세인트 마틴(프랑스)",
    "country_184": "생피에르와 미클롱",
    "country_185": "사모아",
    "country_186": "산 마리노",
    "country_187": "상투메 프린시페",
    "country_188": "사우디 아라비아",
    "country_189": "세네갈",
    "country_190": "세르비아",
    "country_191": "세이셸",
    "country_192": "시에라리온",
    "country_193": "싱가포르",
    "country_194": "슬로바키아",
    "country_195": "슬로베니아",
    "country_196": "솔로몬 제도",
    "country_197": "소말리아",
    "country_198": "남아프리카",
    "country_199": "사우스조지아 및 사우스샌드위치 섬",
    "country_200": "대한민국",
    "country_201": "남 수단",
    "country_202": "스페인",
    "country_203": "스리랑카",
    "country_204": "세인트 헬레나 및 종속성",
    "country_205": "세인트 키츠 & 네비스",
    "country_206": "세인트루시아",
    "country_207": "세인트 빈센트 그레나딘",
    "country_208": "수단",
    "country_209": "수리남",
    "country_210": "스와질란드",
    "country_211": "스웨덴",
    "country_212": "스위스",
    "country_213": "시리아",
    "country_214": "대만",
    "country_215": "타지키스탄",
    "country_216": "탄자니아",
    "country_217": "템플릿:국가 데이터 SJM 스발바르",
    "country_218": "태국",
    "country_219": "바하마 제도",
    "country_220": "코모로",
    "country_221": "필리핀",
    "country_222": "동티모르(동티모르)",
    "country_223": "토고",
    "country_224": "토켈라우",
    "country_225": "통가",
    "country_226": "트리니다드 토바고",
    "country_227": "튀니지",
    "country_228": "칠면조",
    "country_229": "투르크메니스탄",
    "country_230": "터크스 케이커스 제도",
    "country_231": "투발루",
    "country_232": "우간다",
    "country_233": "우크라이나",
    "country_234": "아랍 에미리트",
    "country_235": "미국 군소 외딴 섬",
    "country_236": "미국(USA)",
    "country_237": "미국 버진아일랜드",
    "country_238": "우루과이",
    "country_239": "우즈베키스탄",
    "country_240": "바누아투",
    "country_241": "바티칸 시티(교황청)",
    "country_242": "베네수엘라",
    "country_243": "베트남",
    "country_244": "월리스 푸투나",
    "country_245": "서부 사하라",
    "country_246": "예멘",
    "country_247": "잠비아",
    "country_248": "짐바브웨",
    "google_login": "Google로 로그인",
    "State": "상태",
    "Activation_code": "활성화 코드",
    "Question": "로그인한 모든 앱 나열",
    "Copy_complete": "복사 완료",
    "footer": "저작권 © 2024 MindOnMap 스튜디오. 판권 소유.",
    "change_password_success": "비밀번호가 성공적으로 변경되었습니다.",
    "successful_login_title": "로그인 성공",
    "product_page": "제품 페이지>>",
    "successful_login_info": "로그인이 완료되었습니다. 다음 프로세스를 계속하려면 현재 페이지를 닫고 원래 탭으로 돌아가세요. 현재 페이지는 5초 후에 자동으로 닫힙니다. 자동 종료나 '완료' 버튼 클릭으로 닫을 수 없는 경우에는 이 탭을 직접 닫아주세요.",
    "successful_login_info_firefox": "로그인이 완료되었습니다. 다음 프로세스를 계속하려면 현재 페이지를 닫고 원래 탭으로 돌아가세요.",
    "my_account": "내 계정",
    "my_history": "내 역사",
    "remove_watermark": "워터마크 제거",
    "no_history": "기록 없음",
    "history_all": "모두 선택",
    "history_open": "열려 있는",
    "history_down": "다운로드",
    "history_delete": "삭제",
    "history_clear": "무효 삭제",
    "images": "이미지",
    "use_this_function": "이 제품을 사용하세요>>",
    "hd_downloading": "HD 원본 이미지 다운로드 혜택:",
    "lifetimeRemaining": "남은 수명",
    "Remaining": "남은",
    "email_verification": "이메일 확인",
    "email_verification_info": "귀하의 이메일 <span class=\"email\"></span>으로 확인 메시지를 보냈으니 확인을 완료해 주시기 바랍니다. 인증 후 혜택은 자동으로 동기화됩니다.",
    "wrong_email": "이메일 주소가 잘못되었나요?",
    "click_here_to_modify": "수정하려면 여기를 클릭하세요.",
    "get_more_help": "추가 도움을 받으시겠습니까?",
    "click_here": "여기를 클릭하세요",
    "email_verification_info_success": "이메일 계정을 확인하신 것을 축하드립니다.",
    "email_verification_info_error": "링크가 만료되어 확인에 실패했습니다.",
    "registration_succeeded": "등록이 성공했습니다",
    "registration_succeeded_info_1": "축하해요! 성공적으로 등록되었습니다. 귀하의 이메일 <span class=\"email\"></span>으로 확인 메시지가 전송되었습니다. 이 이메일의 혜택을 이용하시려면 확인을 완료해 주시기 바랍니다.",
    "registration_succeeded_info_2": "홈 페이지로 돌아가서 이 제품을 사용하려면 \"완료\"를 클릭하세요.",
    "registration_succeeded_info_3": "현재 페이지를 닫고 다음 작업을 위해 홈 페이지로 돌아가려면 \"완료\"를 클릭하세요. 닫지 못한 경우 탭을 수동으로 닫아주세요.",
    "verify_email": "이메일 확인",
    "registered_email_not_verified": "등록된 이메일이 확인되지 않았습니다. 한번 확인해주세요.",
    "email_verification_time_1": "확인 이메일을 받지 못하셨나요?",
    "email_verification_time_2": "후에",
    "email_verification_time_3": "다시 보내려면 여기를 클릭하세요.",
    "error_26301": "오류 코드: 26301, 문의해 주세요.",
    "error_26302": "오류 코드: 26302, 문의해 주세요.",
    "error_26303": "이메일 형식 오류(오류 코드: 26303) 다시 입력해 주세요",
    "error_26304": "비밀번호는 8자 이상을 권장합니다. (에러코드: 26304)",
    "error_26305": "Reuqest가 실패했습니다(오류 코드: 26305). 다시 시도해주세요",
    "error_26306": "이메일이 등록되었습니다. <a href=\"https://account.mindonmap.com/login/\">로그인</a>을 해주세요",
    "error_26307": "Reuqest가 실패했습니다(오류 코드: 26307). 다시 시도해주세요",
    "error_26308": "Reuqest가 실패했습니다(오류 코드: 26308). 다시 시도해주세요",
    "error_26401": "오류 코드: 26401, 다시 시도해 주세요.",
    "error_26402": "이메일이 확인되었습니다(오류 코드: 26402). 다시 시도해 주세요.",
    "error_26403": "Reuqest가 실패했습니다(오류 코드: 26403). 다시 시도해주세요",
    "error_26404": "요청이 실패했습니다(오류 코드: 26404). 다시 시도해주세요",
    "error_26501": "오류 코드: 26501, 문의해 주세요.",
    "error_26502": "오류 코드: 26502, 문의해 주세요.",
    "error_26503": "이메일 형식 오류(오류 코드: 26503) 다시 입력해 주세요",
    "error_26504": "Reuqest가 실패했습니다(오류 코드: 26504). 다시 시도해주세요",
    "error_26505": "이메일이 등록되지 않았습니다. <a href=\"https://account.mindonmap.com/register/\">먼저 등록</a>해 주세요.",
    "error_26506": "이메일이 확인되었습니다.",
    "error_26507": "Reuqest가 실패했습니다(오류 코드: 26507). 다시 시도해주세요",
    "error_26508": "확인이 실패했습니다(오류 코드: 26508). 다시 시도해 주세요.",
    "error_26509": "요청이 실패했습니다(오류 코드: 26509). 다시 시도해 주세요.",
    "error_26510": "오류 코드: 26510, 문의해 주세요.",
    "error_26601": "오류 코드: 26601, 문의해 주세요.",
    "error_26602": "오류 코드: 26602, 문의해 주세요.",
    "error_26603": "Reuqest가 실패했습니다(오류 코드: 26603). 다시 시도해주세요",
    "error_26604": "오류 코드: 26604, 문의해 주세요.",
    "error_26605": "오류 코드: 26605, 문의해 주세요.",
    "error_26701": "오류 코드: 26701, 문의해 주세요.",
    "error_26702": "Reuqest가 실패했습니다(오류 코드: 26702). 다시 시도해주세요",
    "error_26703": "오류 코드: 26703, 문의해 주세요.",
    "error_26704": "오류 코드: 26704, 문의해 주세요.",
    "error_26705": "로그인을 기다립니다(오류 코드: 26705). 다시 시도해주세요",
    "no_cookie": "귀하의 브라우저에서 모든 쿠키 차단 기능이 켜져 있어 로그인할 수 없습니다. 설정으로 이동하여 모든 쿠키 허용 확인란을 선택하세요.",
    "error_26801": "오류 코드: 26801, 문의해 주세요.",
    "error_26802": "오류 코드: 26802, 문의해 주세요.",
    "error_26803": "요청이 실패했습니다(오류 코드: 26803). 다시 시도해주세요",
    "error_26804": "요청이 실패했습니다(오류 코드: 26804). 다시 시도해주세요",
    "error_order": "요청이 실패했습니다(오류 코드: 27098). 다시 시도해 주세요!",
    "error_order1": "주문 쿼리가 불완전합니다(오류 코드: ",
    "error_order2": "), 새로고침한 후 다시 시도해 주세요.",
    "modify_email_title": "이메일 수정",
    "modify_email_info": "수정된 이메일을 사용하여 계정에 로그인할 수 있습니다.",
    "images_per": "당 이미지",
    "error_26101": "오류: 26101. 문의해 주세요.",
    "error_26102": "오류: 26102. 문의해 주세요.",
    "error_26103": "요청이 실패했습니다(오류 코드:26103). 다시 시도해 보세요.",
    "error_26104": "오류 코드: 26104, 다시 시도해 주세요.",
    "error_26105": "오류 코드: 26105, 다시 시도해 주세요.",
    "error_26106": "삭제에 실패했습니다(오류 코드: 26106). 다시 시도해 주세요",
    "error_26201": "오류: 26201. 문의해 주세요.",
    "error_26202": "요청이 실패했습니다(오류 코드:26202). 다시 시도해 보세요.",
    "error_26001": "오류: 26001. 문의해 주세요.",
    "error_26002": "오류: 26002. 문의해 주세요.",
    "error_26003": "오류: 26003. 문의해 주세요.",
    "error_26004": "오류: 26004. 문의해 주세요.",
    "error_26005": "요청이 실패했습니다(오류 코드: 26005). 다시 시도해 보세요.",
    "error_26006": "오류 코드: 26006, 다시 시도해 주세요.",
    "error_26008": "오류: 26008. 문의해 주세요.",
    "go_to_the_home_page": "홈 페이지로 이동",
    "error_27101": "요청이 실패했습니다(오류 코드: 27101). 다시 시도해 주세요.",
    "error_27201": "오류 코드: 27201, 문의해 주세요.",
    "error_27202": "오류 코드: 27202, 다시 시도해 주세요.",
    "error_27203": "요청이 실패했습니다(오류 코드: 27203). 다시 시도해주세요",
    "error_27204": "잘못된 코드(오류 코드: 27204).",
    "error_27205": "요청이 실패했습니다(오류 코드: 27205). 다시 시도해주세요",
    "error_27206": "요청이 실패했습니다(오류 코드: 27206). 다시 시도해주세요",
    "error_27207": "요청이 실패했습니다(오류 코드: 27207). 다시 시도해주세요",
    "no_history_found": "어떤 도구도 사용하지 않았습니다! <a href=\"/\">새로고침</a> 또는 <a href=\"https://www.mindonmap.com/\">공식 웹사이트로 이동</a>",
    "error_25301": "오류: 25301. 문의해 주세요.",
    "error_25302": "오류: 25302. 문의해 주세요.",
    "error_25303": "요청이 실패했습니다(오류 코드: 25303). 다시 시도해 주세요",
    "error_25304": "요청이 실패했습니다(오류 코드: 25304). 다시 시도해 주세요",
    "error_25305": "요청이 실패했습니다(오류 코드: 25305). 다시 시도해 주세요",
    "error_25306": "요청이 실패했습니다(오류 코드: 25306). 다시 시도해 주세요",
    "image_upscaler_p": "워터마크가 없는 이미지 다운로드 이점:",
    "Available_for": "사용 가능 대상:",
    "credit_per": "HD 이미지당 %s 크레딧",
    "still_valid": "구매한 요금제는 아직 유효합니다.",
    "credit": "크레딧",
    "pc_3rd_info": "성공적으로 로그인했습니다. 추가 작업을 위해 응용 프로그램으로 이동하십시오.",
    "use_online": "온라인 서비스를 이용하세요",
    "use_download": "데스크톱 프로그램 사용",
    "use_immediately": "즉시 사용",
    "Use_in_browser": "브라우저에서 사용",
    "win_desktop": "윈도우",
    "Mac_desktop": "맥",
    "credits_per": "월별 {%} 크레딧",
    "expire": "만료 시간:",
    "viewDetails": "세부 정보보기",
    "viewHistory": "히스토리 혜택 보기>>",
    "viewDetailsInfo": "주의사항: 구독 혜택이 만료 후 7일 이내에 갱신되는 경우, 미사용 혜택은 계속해서 사용할 수 있습니다. 또한 만료 시간은 새 구독의 만료 시간으로 자동 업데이트됩니다. 만료일 7일 이후 신규가입이 없을 경우, 만료된 혜택은 모두 소멸됩니다.",
    "connect_account": "귀하의 계정에 이메일을 바인딩하세요",
    "connect_account_info": "바인딩 후 이 이메일 주소로 로그인할 수 있습니다.",
    "connect_now": "지금 바인딩",
    "no_email_bind": "이메일 바인딩 없음",
    "bind_email": "이메일 바인딩",
    "connect_your_email_placeholder": "당신의 이메일 주소를 입력하십시오",
    "bind_an_email": "이메일 바인딩",
    "bind_info": "성공적으로 로그인되었습니다. 혜택을 활성화하려면 이메일을 계정에 연결하세요.",
    "bind_later": "나중에 바인딩",
    "hi": "그의!",
    "Personal_Information": "개인 정보",
    "Access": "입장",
    "Subscription_Plan": "(구독 계획)",
    "No_orders": "주문을 찾을 수 없습니다.",
    "No_data": "데이터 없음",
    "Featured_Products": "주요 제품",
    "More_Products": "더 많은 제품",
    "Free_Download": "무료 다운로드",
    "Get_Started": "시작하다",
    "Subscribe": "구독하다",
    "Verified": "확인됨",
    "back_to_account_center": "계정 센터로 돌아가기",
    "success": "성공!",
    "successfully": "성공적으로 계정을 등록했습니다.",
    "Continue": "계속하다",
    "Already": "이미 계정이 있나요?",
    "loading_verification": "인증상태 확인 중...",
    "email_no_verification": "죄송합니다. 등록된 이메일 주소가 확인되지 않았습니다. 위 안내에 따라 인증을 완료하신 후, 다시 \"완료\" 버튼을 클릭하시면 등록이 완료됩니다.",
    "will_expire_after": "다음 이후에 만료됩니다.",
    "hours": "시간",
  },
  "ku": {
    "overtime": "Koda çewtiyê: {%}, ji kerema xwe ji nû ve biceribîne",
    "isnetwork": "Çewtiya Înternetê. Ji kerema xwe kontrol bikin û ji nû ve biceribînin",
    "email_placeholder": "Email",
    "email_empty": "Ji kerema xwe e-nameyê têkevin",
    "email_not_valid": "Email ne derbasdar e",
    "email_not_valid_1": "Ji kerema xwe e-nameya xwe binivîse",
    "email_not_valid_2": "E-name ne derbasdar e, ji kerema xwe navnîşanek din bikar bînin.",
    "email_not_valid_3": "Input email tune",
    "password_placeholder": "Şîfre",
    "password_empty": "Ji kerema xwe şîfreyê têxe",
    "password_not_valid": "Hesab an şîfreya çewt",
    "password_not_valid_1": "Şîfreya li jor 8 tîpan pêwîst e",
    "password_not_valid_2": "Ji kerema xwe şîfreyek çêbikin",
    "password_placeholder_1": "Şîfreya xwe çêbikin",
    "password_placeholder_2": "Şîfreya xwe piştrast bikin",
    "password_placeholder_3": "Şîfreya nû çêbikin",
    "password_placeholder_4": "Şîfreya nû piştrast bikin",
    "password_placeholder_5": "Şîfreya kevn binivîse",
    "copy_password_empty": "Ji kerema xwe şîfreyê piştrast bikin",
    "copy_password_not_valid": "Ji kerema xwe şîfreya xwe piştrast bikin",
    "copy_passwords_inconsistent": "Pejirandina şîfreya we li hev nayê",
    "code_empty": "Ji kerema xwe koda verastkirinê têxe",
    "code_not_valid": "Koda verastkirinê nederbasdar e",
    "code_placeholder": "Koda verastkirinê",
    "not_received_code": "Ger qutiya posta we demek dirêj koda verastkirinê wernegirtiye, ji kerema xwe koda verastkirinê dîsa bistînin.",
    "get_first_code": "Ji kerema xwe pêşî koda verastkirinê bistînin.",
    "last_name_placeholder": "Ji kerema xwe paşnavê xwe binivîse",
    "first_name_placeholder": "Ji kerema xwe re navê xwe binivîse",
    "address_placeholder": "Ji kerema xwe navnîşana xwe binivîse",
    "no_code_text": "Me kodek verastkirinê şandiye. Ji kerema xwe koda xwe binivîse. <span class='tips'>Kodê wernegirt?",
    "no_code_text_1": "1. Ji kerema xwe piştrast bikin ku navnîşana e-nameyê derbasdar e û ew dikare e-nameyan bistîne.",
    "no_code_text_2": "2. Ji ber ku e-name ji hêla pergalê ve bixweber tê şandin, dibe ku ew wekî e-nameya spam an nebaş were nîşankirin. Ji kerema xwe kontrol bikin ka e-name di peldanka Çopê de ye.",
    "no_code_text_3": "3. Hûn nikarin pirsgirêka xwe çareser bikin? ",
    "no_code_text_3_span": "Dûv re li vir bikirtînin ku bi me re têkilî daynin.",
    "order_no": "Te tu hilberek nekirî, Ger pirsek we hebe, ji kerema xwe <a href=\"https://www.mindonmap.com/contact-us/\">têkilî bi me re bikin</a>.",
    "error_24901": "Hesabê heyî tu e-name nayê girêdan, û nikare fermanan bibîne. Ji kerema xwe e-nameyek girêdin.",
    "user_guide": "Rêbernameya Bikarhêner >>",
    "download": "Download",
    "order_number": "Hejmara Siparîşê:",
    "Refund": "Şûndadan",
    "Disabled": "Bêmecel",
    "Normal": "Normal",
    "Modify": "Gûhertin",
    "Modify_1": "Guhertin>>",
    "Connect": "Bihevgirêdan",
    "unlink_success": "Girêdana bi serkeftî veqetîne",
    "connect_success": "Bi serkeftî ve girêdayî ye",
    "feedback_title": "Spas ji bo nêrîna we. Ji kerema xwe pirsgirêka xwe bihêlin û em ê di nav 24 demjimêran de bersiva we bidin.",
    "feedback_thank_you": "Spas!<br /> Bersiva te bi serketî hat şandin.",
    "feedback_email": "E-nameya xwe li vir binivîse!",
    "feedback_content": "Pirsgirêk an pêşniyara ku hûn pê re rû bi rû mane li vir bihêlin.",
    "feedback_submit": "Nermijîn",
    "form_contents": "Te tu şiroveyek neketiye. Ji kerema xwe têkevin û dîsa bişînin.",
    "old_password": "Ji kerema xwe şîfreya kevn binivîse",
    "new_password": "Ji kerema xwe şîfreyek nû biafirîne",
    "old_new_password": "Şîfreya nû nikare wekî ya kevn be",
    "incorrect_password": "Şîfreya çewt",
    "delete_no": "Delete Now",
    "Caps": "Caps lock li ser e",
    "Get": "Stendin",
    "Done": "Kirin",
    "error_20001": "Çewtî: 20001. Ji kerema xwe dîsa têkevinê.",
    "error_20002": "Çewtî: 20002. Ji kerema xwe dîsa têkevinê.",
    "error_20003": "Çewtî: 20003. Ji kerema xwe dîsa têkevinê.",
    "error_20004": "Daxwaz bi ser neket (koda çewtiyê: 20004). Ji kerema xwe dîsa biceribîne.",
    "error_20005": "Danişîna têketinê qediya (Çewtî: 20005). Ji kerema xwe dîsa têkevinê.",
    "error_20006": "Daxwaz bi ser neket (koda çewtiyê: 20006). Ji kerema xwe dîsa biceribîne.",
    "error_20007": "Danişîna têketinê qediya (Çewtî: 20007). Ji kerema xwe dîsa têkevinê.",
    "error_20008": "Danişîna têketinê qediya (Çewtî: 20008). Ji kerema xwe dîsa têkevinê.",
    "error_20009": "Danişîna têketinê qediya (Çewtî: 20009). Ji kerema xwe dîsa têkevinê.",
    "error_20101": "Ji kerema xwe e-nameya xwe binivîse (kodê xeletiyê: 20101)",
    "error_20102": "E-name ne derbasdar e (koda çewtiyê: 20102)",
    "error_20103": "Daxwaz bi ser neket (koda çewtiyê: 20103). Ji kerema xwe dîsa biceribîne",
    "error_20104": "E-name jixwe tê bikar anîn, ji kerema xwe <a href=\"https://account.mindonmap.com/login/\"> têkeve</a> an bi yekî nû re qeyd bikin",
    "error_20105": "Daxwaz bi ser neket (koda çewtiyê: 20105). Ji kerema xwe dîsa biceribîne",
    "error_20106": "E-name şandin neket, ji kerema xwe ji nû ve biceribîne",
    "error_20201": "Ji kerema xwe e-nameya xwe têkevin (koda xeletiyê: 20201)",
    "error_20202": "Ji kerema xwe şîfreya xwe binivîse (koda çewtiyê: 20202)",
    "error_20203": "Ji kerema xwe koda verastkirinê têxe (koda xeletiyê: 20203)",
    "error_20204": "E-name ne derbasdar e (koda xeletiyê: 20204)",
    "error_20205": "Şîfreya li jor 8 tîpan pêwîst e (koda çewtiyê: 20205)",
    "error_20206": "Daxwaz bi ser neket (koda xeletiyê: 20206). Ji kerema xwe dîsa biceribîne",
    "error_20207": "Koda verastkirinê nederbasdar e",
    "error_20208": "Daxwaz bi ser neket (koda xeletiyê: 20208). Ji kerema xwe dîsa biceribîne",
    "error_20209": "Daxwaz bi ser neket (koda xeletiyê: 20209). Ji kerema xwe dîsa biceribîne",
    "error_20301": "Ji kerema xwe e-nameya xwe têkevin (koda xeletiyê: 20301)",
    "error_20302": "Çewtî: 20302. Ji kerema xwe bi me re têkilî daynin",
    "error_20303": "E-name ne derbasdar e (koda çewtiyê: 20303)",
    "error_20304": "Daxwaz bi ser neket (koda çewtiyê: 20304). Ji kerema xwe dîsa biceribîne",
    "error_20305": "Hesab tune. Ji kerema xwe ji nû ve têkevin an jî <a href=\"https://account.mindonmap.com/register/\">Wê biafirîne</a> pêşî.",
    "error_20306": "Şîfre tune ye. <a href=\"https://account.mindonmap.com/passwordless-login/\">Têketinê bê şîfre</a> an <a href=\"https://account.mindonmap.com/create-password/\"> bikar bînin şîfreyek danîn</a> û têkevinê.",
    "error_20308": "Daxwaz bi ser neket (koda çewtiyê: 20308). Ji kerema xwe dîsa biceribîne",
    "error_20401": "Derketin têk çû (koda çewtiyê: 20401). Ji kerema xwe dîsa biceribîne",
    "error_20501": "Ji kerema xwe e-nameya xwe binivîse (kodê xeletiyê: 20501)",
    "error_20502": "E-name ne derbasdar e (koda çewtiyê: 20502)",
    "error_20503": "Daxwaz bi ser neket (koda çewtiyê: 20503). Ji kerema xwe dîsa biceribîne",
    "error_20504": "E-name şandin bi ser neket. Ji kerema xwe dîsa biceribîne.",
    "error_20601": "Ji kerema xwe e-nameya xwe binivîse (kodê xeletiyê: 20601)",
    "error_20602": "Ji kerema xwe koda verastkirinê têxe (koda çewtiyê: 20602)",
    "error_20603": "E-name ne derbasdar e (koda çewtiyê: 20603)",
    "error_20604": "Daxwaz bi ser neket (koda çewtiyê: 20604). Ji kerema xwe dîsa biceribîne",
    "error_20606": "Daxwaz bi ser neket (koda çewtiyê: 20606). Ji kerema xwe dîsa biceribîne",
    "error_20607": "Daxwaz bi ser neket (koda çewtiyê: 20607). Ji kerema xwe dîsa biceribîne",
    "error_20608": "Daxwaz bi ser neket (koda çewtiyê: 20608). Ji kerema xwe dîsa biceribîne",
    "error_20701": "Ji kerema xwe e-nameya xwe binivîse (kodê xeletiyê: 20701)",
    "error_20702": "E-name ne derbasdar e (koda çewtiyê: 20702)",
    "error_20703": "Daxwaz bi ser neket (koda çewtiyê: 20703). Ji kerema xwe dîsa biceribîne",
    "error_20704": "Hesab tune. Ji kerema xwe ji nû ve têkevin an jî <a href=\"https://account.mindonmap.com/register/\">Wê biafirîne</a> pêşî.",
    "error_20705": "Daxwaz bi ser neket (koda çewtiyê: 20705). Ji kerema xwe dîsa biceribîne",
    "error_20706": "E-name şandin bi ser neket. Ji kerema xwe dîsa biceribîne",
    "error_20801": "Ji kerema xwe e-nameya xwe binivîse (koda xeletiyê: 20801)",
    "error_20802": "Çewtî: 20802. Ji kerema xwe bi me re têkilî daynin",
    "error_20803": "Ji kerema xwe koda verastkirinê têxe (koda çewtiyê: 20803)",
    "error_20804": "E-name ne derbasdar e (koda çewtiyê: 20804)",
    "error_20805": "Şîfreya li jor 8 tîpan pêwîst e (koda çewtiyê: 20805)",
    "error_20806": "Daxwaz bi ser neket (koda çewtiyê: 20806). Ji kerema xwe dîsa biceribîne",
    "error_20808": "Daxwaz bi ser neket (koda çewtiyê: 20808). Ji kerema xwe dîsa biceribîne",
    "error_20901": "Daxwaz bi ser neket (koda çewtiyê: 20901). Ji kerema xwe dîsa biceribîne",
    "error_20902": "Daxwaz bi ser neket (koda çewtiyê: 20902). Ji kerema xwe dîsa biceribîne",
    "error_21000": "Guhertin têne tomar kirin",
    "error_21001": "Ti agahî nayê şandin (koda çewtiyê: 21001)",
    "error_21002": "Daxwaz bi ser neket (koda çewtiyê: 21002). Ji kerema xwe dîsa biceribîne",
    "error_21101": "Ji kerema xwe e-nameya xwe binivîse (koda xeletiyê: 21101)",
    "error_21102": "E-name ne derbasdar e (koda çewtiyê: 21102)",
    "error_21103": "Daxwaz bi ser neket (koda çewtiyê: 21103), ji kerema xwe wê dîsa biceribîne",
    "error_21104": "E-name jixwe ve girêdayî ye, ji kerema xwe yek nû bikar bînin",
    "error_21105": "Daxwaz bi ser neket (koda çewtiyê: 21105), ji kerema xwe wê dîsa biceribîne",
    "error_21106": "E-name şandin bi ser neket. Ji kerema xwe dîsa biceribîne",
    "error_21201": "Ji kerema xwe e-nameya xwe binivîse (koda çewtiyê: 21201)",
    "error_21202": "Ji kerema xwe koda verastkirinê têxe (koda çewtiyê: 21202)",
    "error_21203": "E-name ne derbasdar e (koda çewtiyê: 21203)",
    "error_21204": "Çewtî: 21204. Ji kerema xwe bi me re têkilî daynin",
    "error_21205": "Çewtî: 21205. Ji kerema xwe bi me re têkilî daynin",
    "error_21206": "Şîfreya li jor 8 tîpan pêwîst e (koda çewtiyê: 21206)",
    "error_21207": "Daxwaz bi ser neket (koda çewtiyê: 21207). Ji kerema xwe dîsa biceribîne",
    "error_21209": "Daxwaz bi ser neket (koda çewtiyê: 21209). Ji kerema xwe dîsa biceribîne",
    "error_21301": "Ji kerema xwe şîfreya kevn têxe (koda çewtiyê: 21301)",
    "error_21302": "Ji kerema xwe şîfreyek nû biafirîne (koda xeletiyê: 21302)",
    "error_21303": "Şîfreya nû nikare wekî ya kevn be. (Çewtî: 21303)",
    "error_21304": "Şîfreya li jor 8 tîpan pêwîst e (koda çewtiyê: 21304)",
    "error_21306": "Daxwaz bi ser neket (koda çewtiyê: 21306). Ji kerema xwe dîsa biceribîne",
    "error_21402": "Daxwaz bi ser neket (koda çewtiyê: 21402). Ji kerema xwe dîsa biceribîne",
    "error_21403": "Koda verastkirinê nehat şandin. Ji kerema xwe ji nû ve bişînin",
    "error_21500": "Hesab hat jêbirin",
    "error_21501": "Ji kerema xwe koda verastkirinê têxe (koda çewtiyê: 21501)",
    "error_21502": "Danişîna têketinê qediya (Çewtî: 21502). Ji kerema xwe dîsa têkevinê.",
    "error_21503": "Daxwaz bi ser neket (koda çewtiyê: 21503). Ji kerema xwe dîsa biceribîne",
    "error_21505": "Daxwaz bi ser neket (koda xeletiyê: 21505), ji kerema xwe wê dîsa biceribîne",
    "error_21601": "Çewtî: 20601. Ji kerema xwe bi me re têkilî daynin",
    "error_21602": "Verastkirin Nederbasdar (Çewtî: 20602). Ji kerema xwe dîsa biceribîne.",
    "error_21603": "Çewtî: 20603. Ji kerema xwe dîsa biceribîne",
    "error_21604": "Daxwaz bi ser neket (koda çewtiyê: 21604). Ji kerema xwe dîsa biceribîne",
    "error_21606": "Daxwaz bi ser neket (koda çewtiyê: 21606). Ji kerema xwe dîsa biceribîne",
    "error_21611": "Daxwaz bi ser neket (koda çewtiyê: 21611). Ji kerema xwe dîsa biceribîne",
    "error_21801": "Çewtî: 21801. Ji kerema xwe bi me re têkilî daynin",
    "error_21802": "Daxwaz bi ser neket (Çewtî: 21802). Ji kerema xwe dîsa biceribîne",
    "error_21803": "Çewtî: 21803. Ji kerema xwe dîsa biceribîne",
    "error_21804": "Daxwaz bi ser neket (koda çewtiyê: 21804). Ji kerema xwe dîsa biceribîne",
    "error_21806": "Çewtî: 21806. Ji kerema xwe dîsa biceribîne",
    "error_21807": "Çewtî: 21807. Ji kerema xwe bi me re têkilî daynin",
    "error_21808": "Çewtî: 21808. Ji kerema xwe bi me re têkilî daynin",
    "error_21809": "Çewtî: 21809. Ji kerema xwe bi me re têkilî daynin",
    "error_21810": "Çewtî: 21810. Ji kerema xwe bi me re têkilî daynin",
    "error_21811": "Çewtî: 21811. Ji kerema xwe bi me re têkilî daynin",
    "error_21812": "Çewtî: 21812. Ji kerema xwe bi me re têkilî daynin",
    "error_21813": "Daxwaz bi ser neket (koda çewtiyê: 21813). Ji kerema xwe dîsa biceribîne",
    "error_21814": "Çewtî: 21814. Ji kerema xwe bi me re têkilî daynin",
    "error_21815": "Daxwaz bi ser neket (koda çewtiyê: 21815). Ji kerema xwe dîsa biceribîne",
    "error_21816": "Çewtî: 21816. Ji kerema xwe bi me re têkilî daynin",
    "error_21817": "Çewtî: 21817. Ji kerema xwe bi me re têkilî daynin",
    "error_21818": "Çewtî: 21818. Ji kerema xwe bi me re têkilî daynin",
    "error_21819": "Daxwaz bi ser neket (koda çewtiyê: 21819). Ji kerema xwe dîsa biceribîne",
    "error_21820": "Çewtî: 21820. Ji kerema xwe bi me re têkilî daynin",
    "error_21821": "Çewtî: 21821. Ji kerema xwe bi me re têkilî daynin",
    "error_21822": "Çewtî: 21822. Ji kerema xwe bi me re têkilî daynin",
    "error_21823": "Daxwaz bi ser neket (koda çewtiyê: 21823). Ji kerema xwe dîsa biceribîne",
    "error_21824": "Daxwaz bi ser neket (koda çewtiyê: 21824). Ji kerema xwe dîsa biceribîne",
    "error_21825": "Daxwaz bi ser neket (koda çewtiyê: 21825). Ji kerema xwe dîsa biceribîne",
    "error_21826": "Daxwaz bi ser neket (koda çewtiyê: 21826). Ji kerema xwe dîsa biceribîne",
    "error_21901": "Çewtî: 21901. Ji kerema xwe bi me re têkilî daynin",
    "error_21902": "Daxwaz bi ser neket (koda çewtiyê: 21902). Ji kerema xwe dîsa biceribîne",
    "error_21903": "Rewşa hesabê guherî (koda çewtiyê: 21903), ji kerema xwe rûpelê nûve bikin û dîsa biceribînin",
    "error_21904": "Çewtî: 21904. Ji kerema xwe dîsa biceribîne",
    "error_21905": "Çewtî: 21905. Ji kerema xwe dîsa biceribîne",
    "error_21906": "Daxwaz bi ser neket (koda çewtiyê: 21906). Ji kerema xwe dîsa biceribîne",
    "error_21907": "Hesabê Google bi hesabek din ve hatî girêdan",
    "error_21908": "Daxwaz bi ser neket (koda çewtiyê: 21908). Ji kerema xwe dîsa biceribîne",
    "error_22001": "Daxwaz bi ser neket (koda çewtiyê: 22001). Ji kerema xwe dîsa biceribîne",
    "error_22002": "Vekirina girêdanê bêyî têketinek zêde têk çû",
    "error_22003": "Daxwaz bi ser neket (koda çewtiyê: 22003). Ji kerema xwe dîsa biceribîne",
    "error_22101": "Çewtî: 22101. Ji kerema xwe bi me re têkilî daynin",
    "error_22102": "Rewşa hesabê guherî (koda çewtiyê: 22102), ji kerema xwe rûpelê nûve bikin û dîsa biceribînin",
    "error_22103": "Daxwaz bi ser neket (koda çewtiyê: 22103). Ji kerema xwe dîsa biceribîne",
    "error_22104": "Rewşa hesabê guherî (koda çewtiyê: 22104), ji kerema xwe rûpelê nûve bikin û dîsa biceribînin",
    "error_22105": "Çewtî: 22105. Ji kerema xwe dîsa biceribîne",
    "error_22106": "Çewtî: 22106. Ji kerema xwe dîsa biceribîne",
    "error_22107": "Çewtî: 22107. Ji kerema xwe bi me re têkilî daynin",
    "error_22108": "Daxwaz bi ser neket (koda çewtiyê: 22108). Ji kerema xwe dîsa biceribîne",
    "error_22201": "Çewtî: 22201. Ji kerema xwe bi me re têkilî daynin",
    "error_22202": "Verastkirin nederbasdar (Çewtî: 22202). Ji kerema xwe dîsa biceribîne.",
    "error_22203": "Çewtî: 22203. Ji kerema xwe dîsa biceribîne\"",
    "error_22204": "Daxwaz bi ser neket (koda çewtiyê: 22204). Ji kerema xwe dîsa biceribîne",
    "error_22206": "Daxwaz bi ser neket (koda çewtiyê: 22206). Ji kerema xwe dîsa biceribîne",
    "error_22401": "Çewtî: 22401. Ji kerema xwe bi me re têkilî daynin",
    "error_22402": "Verastkirin nederbasdar (Çewtî: 22402). Ji kerema xwe dîsa biceribîne.",
    "error_22403": "Çewtî: 22403. Ji kerema xwe dîsa biceribîne",
    "error_22404": "Daxwaz bi ser neket (koda çewtiyê: 22404). Ji kerema xwe dîsa biceribîne",
    "error_22405": "Hesabê Facebookê bi e-nameyên din ve hatî girêdan",
    "error_22406": "Çewtî: 22406. Ji kerema xwe dîsa biceribîne",
    "error_22407": "Çewtî: 22407. Ji kerema xwe bi me re têkilî daynin",
    "error_22408": "Çewtî: 22408. Ji kerema xwe bi me re têkilî daynin",
    "error_22409": "Çewtî: 22409. Ji kerema xwe bi me re têkilî daynin",
    "error_22410": "Çewtî: 224010. Ji kerema xwe bi me re têkilî daynin",
    "error_22411": "Çewtî: 224011. Ji kerema xwe bi me re têkilî daynin",
    "error_22412": "Çewtî: 224012. Ji kerema xwe bi me re têkilî daynin",
    "error_22413": "Çewtî: 22413. Ji kerema xwe bi me re têkilî daynin",
    "error_22414": "Daxwaz bi ser neket (koda çewtiyê: 22414). Ji kerema xwe dîsa biceribîne",
    "error_22415": "Çewtî: 22415. Ji kerema xwe bi me re têkilî daynin",
    "error_22416": "Çewtî: 22416. Ji kerema xwe bi me re têkilî daynin",
    "error_22417": "Çewtî: 22417. Ji kerema xwe bi me re têkilî daynin",
    "error_22418": "Daxwaz bi ser neket (koda çewtiyê: 22418). Ji kerema xwe dîsa biceribîne",
    "error_22419": "Çewtî: 22419. Ji kerema xwe bi me re têkilî daynin",
    "error_22420": "Çewtî: 22420. Ji kerema xwe bi me re têkilî daynin",
    "error_22421": "Çewtî: 22421. Ji kerema xwe bi me re têkilî daynin",
    "error_22422": "Daxwaz bi ser neket (koda çewtiyê: 22422). Ji kerema xwe dîsa biceribîne",
    "error_22423": "Daxwaz bi ser neket (koda çewtiyê: 22423). Ji kerema xwe dîsa biceribîne",
    "error_22424": "Daxwaz bi ser neket (koda çewtiyê: 22424). Ji kerema xwe dîsa biceribîne",
    "error_22425": "Daxwaz bi ser neket (koda çewtiyê: 22425). Ji kerema xwe dîsa biceribîne",
    "error_20098": "Koda çewtiyê: 20098. Heke hûn di moda Taybet de gerê dikin, ji kerema xwe veguherînin moda Normal û dîsa biceribînin.",
    "error_22298": "Daxwaza Google têk çû (Çewtî: 22298). Ji kerema xwe dîsa biceribîne.",
    "error_22498": "Daxwaza Facebookê têk çû (Çewtî: 22498). Ji kerema xwe dîsa biceribîne.",
    "error_24902": "Daxwaz bi ser neket (koda çewtiyê: 24902). Ji kerema xwe dîsa biceribîne",
    "error_24903": "Daxwaz bi ser neket (koda çewtiyê: 24903). Ji kerema xwe dîsa biceribîne",
    "error_24904": "Daxwaz bi ser neket (koda çewtiyê: 24904). Ji kerema xwe dîsa biceribîne",
    "error_24905": "Daxwaz bi ser neket (koda çewtiyê: 24905). Ji kerema xwe dîsa biceribîne",
    "login_title": "Têkeve MindOnMap",
    "log_in": "Têkeve",
    "no_account": "Hesab tune?",
    "create_it": "Create it",
    "or_log_in_with": "An jî têkeve bi",
    "passwordless_login": "Têketinê bê şîfre",
    "log_in_done": "Log In Done",
    "three_rd_account_connect_info": "Pîroz be! Tu bi serkeftî têketî. Naha hûn dikarin yek hesabek e-nameyê ku ji bo têketinê di pêşerojê de tê bikar anîn ve girêdin.",
    "see_my_account": "Hesabê min bibînin",
    "three_rd_login_merge_account": "Navnîşana e-nameyê ya hesabê sêyemîn hate îmze kirin, hûn dixwazin rasterast bi vê navnîşana e-nameyê re têkildar bibin û têkevin?",
    "connect_log_in": "Têkeve & Têkeve",
    "create_an_account": "Hesabek çêbikin",
    "back_to_log_in": "Vegere têketinê",
    "create_password": "Şîfreyekî çêke",
    "create_now": "Niha Biafirîne",
    "password_login_subtitle": "Têketina bê şîfre bi e-nameyê",
    "account_login": "Têkeve Hesabê",
    "rights": "Bi afirandina vê hesabê, hûn <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Şertên Karûbar</a> û <a href=\"https://www.mindonmap.com/privacy-policy/\">Siyaseta nepenîtiyê</a>",
    "passwordless_login_done": "Têketina bê şîfre pêk hat",
    "passwordless_login_info": "Pîroz be, te têketina bê şîfre bi serketî qedand. Hûn dikarin ji bo vê hesabê şîfreyek çêbikin û di pêşerojê de bi hesab û şîfreya xwe têkevinê. <a href=\"/create-password\" style=\"display: initial;\">Niha biafirîne</a>",
    "sign_up": "Tomar kirin",
    "register_info": "Hesabê xwe çêbikin",
    "reset_now": "Niha vegerîne",
    "forgot_password": "Şîfre ji bîr kir",
    "reset_password_subtitle": "Ji bo sifirkirina şîfreya e-nameya hesabê xwe bikar bînin",
    "plan_products": "Plan & Berhemên",
    "nick_name": "Navê bikarhêner:",
    "email": "Email:",
    "my_products": "Berhemên Min",
    "my_orders": "Orders My",
    "unlink": "Jê veke",
    "link": "Girêk",
    "connected_accounts": "Hesabên girêdayî",
    "last_name": "Paşnav:",
    "first_name": "Nav:",
    "Gender": "Zayendî:",
    "Birth": "Zayîn:",
    "Month": "Meh",
    "Year": "Sal",
    "Country_Region": "Welat/Herêm:",
    "Address": "Navnîşan:",
    "Save": "Rizgarkirin",
    "Date": "Rojek",
    "Male": "Nêrî",
    "Female": "Mê",
    "Unspecified": "Nenaskirî",
    "Security": "Ewlekarî",
    "change_password": "Şîfre biguherînin",
    "change_now": "Niha biguherîne",
    "connect_email": "E-nameyê girêdan",
    "delete_account": "Hesabê jêbirin",
    "delete_account_info": "Dema ku hesabê we were jêbirin, hemî daneyên di Hesabê Min de ku bi hesabê we re têkildar in dê bi domdarî werin jêbirin, û dibe ku hûn nikaribin wê vegerînin. Em ji we re pêşniyar dikin ku hûn bi baldarî tevbigerin.",
    "Delete": "Jêbirin",
    "Logout": "Derkeve",
    "my_profile": "Profîla min",
    "guides_faqs": "Rêber & FAQs",
    "More": "Zêde",
    "guides": "Guides",
    "register": "fêhrist",
    "hot_faq": "FAQ-Hot",
    "Contents": "Naverok:",
    "contact_us": "Têkilî bi me re>>",
    "plan": "Pîlan",
    "unregistered": "Neqeydkirî",
    "buy_more": "Kirîna Zêdetir",
    "buy_again": "Dîsa bikirin",
    "buy_now": "Dibe ez çi bikim",
    "free_no_limit": "Belaş & Bê sînor",
    "expired": "Dema derbas bû",
    "lifetime": "Lifetime",
    "remain": "Mayin",
    "day_s": "Roj (roj)",
    "error_24801": "Daxwaz bi ser neket (koda çewtiyê: 24801). Ji kerema xwe dîsa biceribîne",
    "no_app_found": "Serlêdanek nehat dîtin！<a href=\"/\">Nûvekirin</a> an <a href=\"https://www.mindonmap.com/\">Here malpera fermî</a>",
    "get_more": "Zêdetir Bistînin >>",
    "edit_photo": "Wêne biguherîne",
    "select_photo": "Wêne hilbijêrin",
    "change_photo": "Wêne biguherînin",
    "cancel": "Bişûndekirin",
    "hide_password": "Şîfreyê veşêre",
    "show_password": "Şîfreyê nîşan bide",
    "zoom_in": "Zoom in",
    "zoom_out": "Dûr xistin",
    "rotate": "Yîvirrîn",
    "horizontal_flip": "Flip Horizontal",
    "vertical_flip": "Flip Vertical",
    "country": "Welat",
    "country_1": "Welat/herêma xwe hilbijêrin",
    "country_2": "Girava Åaland",
    "country_3": "Afxanistan",
    "country_4": "Arnewidistan",
    "country_5": "Cezayîr",
    "country_6": "Amerîkî Samoa",
    "country_7": "Andora",
    "country_8": "Algona",
    "country_9": "Anguîla",
    "country_10": "Antarktîka",
    "country_11": "Antigua & Barbuda",
    "country_12": "Arjantîn",
    "country_13": "Ermenistan",
    "country_14": "Arûba ",
    "country_15": "Awistrelya",
    "country_16": "Awisturya",
    "country_17": "Azerbêycan",
    "country_18": "Bahrêyn",
    "country_19": "Bengladêş",
    "country_20": "Barbados",
    "country_21": "Belarûs",
    "country_22": "Belçîka",
    "country_23": "Belîze",
    "country_24": "Benîn",
    "country_25": "Bermûda",
    "country_26": "Bhûtan",
    "country_27": "Bolîvya",
    "country_28": "Bosna & Hersek",
    "country_29": "Botswana",
    "country_30": "Girava Bouvet",
    "country_31": "Brezîlya",
    "country_32": "Herêma Okyanûsa Hindî ya Brîtanî",
    "country_33": "Giravên Virgin Brîtanî",
    "country_34": "Brûney",
    "country_35": "Bulgaristan",
    "country_36": "Burkina",
    "country_37": "Bûrûndî",
    "country_38": "Kamboçya",
    "country_39": "Kamerûn",
    "country_40": "Kanada",
    "country_41": "Keyp Verde",
    "country_42": "Karibik Hollanda",
    "country_43": "Giravên Kaymanê",
    "country_44": "Komara Navendî ya Afrîkayê",
    "country_45": "Çad",
    "country_46": "Şîlî",
    "country_47": "çîn",
    "country_48": "Girava Krîsmis",
    "country_49": "Cocos (Keeling) Giravên",
    "country_50": "Kolombîya",
    "country_51": "Giravên Kûk",
    "country_52": "Kosta Rîka",
    "country_53": "Qeraxên Diranê Fîlê",
    "country_54": "Xirwatistan",
    "country_55": "Kûba",
    "country_56": "Qibrîs",
    "country_57": "Komara Çek",
    "country_58": "Komara Demokratîk a Kongoyê",
    "country_59": "Danîmark",
    "country_60": "Djîboûtû",
    "country_61": "Domînîka",
    "country_62": "Komara Domînîkî",
    "country_63": "Ekwador",
    "country_64": "Misr",
    "country_65": "El Salvador",
    "country_66": "Gîneya Ekwatorê",
    "country_67": "Erître",
    "country_68": "Estonya",
    "country_69": "Etîyopya",
    "country_70": "Giravên Falkland",
    "country_71": "Giravên Faroe",
    "country_72": "Dewletên Federal ên Mîkronêzyayê",
    "country_73": "Fîjî",
    "country_74": "Fînlandiya",
    "country_75": "Fransa",
    "country_76": "Fransî Guana",
    "country_77": "Polîneziya fransî",
    "country_78": "Herêmên başûrê Fransî",
    "country_79": "Gabon",
    "country_80": "Gambîa",
    "country_81": "Gurcistan",
    "country_82": "Almanya",
    "country_83": "Gana",
    "country_84": "Cebelîtariq",
    "country_85": "Brîtaniya Mezin (Kraltiya Yekbûyî; Îngilîzî)",
    "country_86": "Yewnanistan",
    "country_87": "Grînland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Gûatemala",
    "country_92": "Guernsey",
    "country_93": "Gîneya Ekwatorê",
    "country_94": "Gîne-Bîsaû",
    "country_95": "Guyana",
    "country_96": "Haîtî",
    "country_97": "Girava û Giravên bihîstin û McDonald ",
    "country_98": "Hondûras",
    "country_99": "Hong Kong",
    "country_100": "Macaristan",
    "country_101": "Îzlanda",
    "country_102": "Hindistan",
    "country_103": "Endonêzya",
    "country_104": "Iran",
    "country_105": "Iraq",
    "country_106": "Îrlanda",
    "country_107": "Girava Man",
    "country_108": "Îsraîl",
    "country_109": "Îtalya",
    "country_110": "Cameyka",
    "country_111": "Japonya",
    "country_112": "Jersey",
    "country_113": "Urdun",
    "country_114": "Kazakistan",
    "country_115": "Kenya",
    "country_116": "Kîrîbatî",
    "country_117": "Kuwêt",
    "country_118": "Qirgizîstan",
    "country_119": "Laos",
    "country_120": "Letonya",
    "country_121": "Lubnan",
    "country_122": "Lesoto",
    "country_123": "Lîberya",
    "country_124": "Lîbya",
    "country_125": "Lîhtenştayn",
    "country_126": "Lîtvanya",
    "country_127": "Luksembûrg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawî",
    "country_131": "Malezya",
    "country_132": "Maldîv",
    "country_133": "Malî",
    "country_134": "Malta",
    "country_135": "Giravên Marşalê",
    "country_136": "Martinique",
    "country_137": "Morîtanya",
    "country_138": "Morîtyûs",
    "country_139": "Mayotte",
    "country_140": "Meksîka",
    "country_141": "Moldovya",
    "country_142": "Monako",
    "country_143": "Moxolistan",
    "country_144": "Montenegro",
    "country_145": "Montserat",
    "country_146": "Fas",
    "country_147": "Mozambîk",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namîbya",
    "country_150": "Naûrû",
    "country_151": "Nepal",
    "country_152": "Holanda",
    "country_153": "Kaledonya Nû",
    "country_154": "Zelanda Nû",
    "country_155": "Nîkaragûa",
    "country_156": "Nîjer",
    "country_157": "Nîjerya",
    "country_158": "Nîyû",
    "country_159": "Girava Norfolkê",
    "country_160": "Korîya Bakur",
    "country_161": "Giravên Bakur ên Marîanayê",
    "country_162": "Norwêc",
    "country_163": "Uman",
    "country_164": "Pakistan",
    "country_165": "Palaû",
    "country_166": "herêmên Filistînê",
    "country_167": "Panama",
    "country_168": "Papûa Gîneya Nû",
    "country_169": "Paraguay",
    "country_170": "Perû",
    "country_171": "Giravên Pîtkaîrnê",
    "country_172": "Polonya",
    "country_173": "Portekîz",
    "country_174": "Porto Rîko",
    "country_175": "Qatar",
    "country_176": "Komara Makedonya (FYROM)",
    "country_177": "Komara Kongo",
    "country_178": "Dîsayekbûn",
    "country_179": "Romanya",
    "country_180": "Federasyona Rûsyayê",
    "country_181": "Riwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Fransa)",
    "country_184": "Saint-Pierre û Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marîno",
    "country_187": "Sao Tome & Principe",
    "country_188": "Erebistana Suûdî",
    "country_189": "Senegal",
    "country_190": "Sirbîstan",
    "country_191": "Seyçeles",
    "country_192": "Sîera Leone",
    "country_193": "Sîngapûr",
    "country_194": "Slovakya",
    "country_195": "Slovenya",
    "country_196": "Giravên Silêman",
    "country_197": "Somalî",
    "country_198": "Afrîkaya Başûr",
    "country_199": "Gurcistana Başûr û Isla Sandwich South",
    "country_200": "Koreya Başûr",
    "country_201": "Sûdana Başûr",
    "country_202": "Îspanya",
    "country_203": "Srî Lanka",
    "country_204": "Helena & Pirgirêdan",
    "country_205": "St. Kitts & Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent û Grenadîn",
    "country_208": "Sûdan",
    "country_209": "Sûrînam",
    "country_210": "Swazîland",
    "country_211": "Swêd",
    "country_212": "Swîsre",
    "country_213": "Sûrye",
    "country_214": "Taiwan",
    "country_215": "Tacikîstan",
    "country_216": "Tanzanya",
    "country_217": "Şablon:Daneyên welat SJM Svalbard",
    "country_218": "Tayland",
    "country_219": "Bahama",
    "country_220": "Komor",
    "country_221": "Fîlîpîn",
    "country_222": "Tîmora Rojhilat (Tîmora Rojhilat)",
    "country_223": "Togo",
    "country_224": "Tokelau",
    "country_225": "Toga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunis",
    "country_228": "Tirkiye",
    "country_229": "Turkmenistan",
    "country_230": "Giravên Turks & Caicos ",
    "country_231": "Tuvalû",
    "country_232": "Ûganda",
    "country_233": "Ukrayna",
    "country_234": "Emîriyên Yekbûyî yên Ereban",
    "country_235": "Giravên Giravên Biçûk ên Dewletên Yekbûyî",
    "country_236": "Dewletên Yekbûyî yên Amerîka (DYA)",
    "country_237": "Giravên Virgin ên Dewletên Yekbûyî",
    "country_238": "Ûrûgûay",
    "country_239": "Ozbekistan",
    "country_240": "Vanûatû",
    "country_241": "Bajarê Vatîkan (Dîroka Pîroz)",
    "country_242": "Venezûêla",
    "country_243": "Vîetnam",
    "country_244": "Wallis û Futuna",
    "country_245": "Sahraya Rojava",
    "country_246": "Yemen",
    "country_247": "Zambiya",
    "country_248": "Zîmbabwe",
    "google_login": "Bi Google re têkevinê",
    "State": "Rewş",
    "Activation_code": "Koda çalakkirinê",
    "Question": "Hemî sepanên ku we têketî navnîş bikin",
    "Copy_complete": "Kopî temam kirin",
    "footer": "Copyright © 2024 MindOnMap Studio. Hemû maf parastî ne.",
    "change_password_success": "Şîfre bi serkeftî guherî",
    "successful_login_title": "Têketinê Serkeftin",
    "product_page": "Rûpelê Berhem >>",
    "successful_login_info": "Têketin qediya. Ji kerema xwe rûpela heyî bigire û vegere tabloya orîjînal da ku pêvajoya jêrîn bidomîne. Rûpela heyî dê di nav 5 çirkeyan de bixweber were girtin. Ger girtina otomatîkî an girtina bi tikandina bişkoka \"Done\" têk çû, ji kerema xwe vê tabloyê rasterast bigire.",
    "successful_login_info_firefox": "Têketin qediya. Ji kerema xwe rûpela heyî bigire û vegere tabloya orîjînal da ku pêvajoya jêrîn bidomîne.",
    "my_account": "Hesabê min",
    "my_history": "Dîroka min",
    "remove_watermark": "Watermark rakin",
    "no_history": "Dîrok tune",
    "history_all": "Hemî hilbijêrin",
    "history_open": "Vekirî",
    "history_down": "Download",
    "history_delete": "Jêbirin",
    "history_clear": "Nederbasdar paqij bike",
    "images": "wêne(ên)",
    "use_this_function": "Vê Hilberê bikar bînin>>",
    "hd_downloading": "Feydeyên dakêşana wêneyên orjînal ên HD:",
    "lifetimeRemaining": "Jiyan-mayînê",
    "Remaining": "Jiberma",
    "email_verification": "Verification Email",
    "email_verification_info": "Me peyama verastkirinê ji e-nameya we re şand <span class=\"email\"></span>, û ji kerema xwe verastkirinê biqedînin. Piştî verastkirinê, feyde dê bixweber bi hev re bibin.",
    "wrong_email": "Navnîşana e-nameyê çewt e?",
    "click_here_to_modify": "Ji bo guherandinê li vir bikirtînin",
    "get_more_help": "Alîkariya bêtir bistînin?",
    "click_here": "Li vir bitikîne",
    "email_verification_info_success": "Ji bo verastkirina hesabê e-nameya xwe pîroz dikim.",
    "email_verification_info_error": "Verastkirin bi ser neket ji ber girêdana qediya.",
    "registration_succeeded": "Registration Serkeftin",
    "registration_succeeded_info_1": "Pîroz be! Te bi serkeftî qeyd kir. Me peyama verastkirinê ji e-nameya we re şand <span class=\"email\"></span>, û ji kerema xwe verastkirinê biqedînin da ku hûn feydeyên vê e-nameyê bikar bînin.",
    "registration_succeeded_info_2": "\"Done\" bikirtînin da ku vegerin ser rûpela Malê û vê hilberê bikar bînin.",
    "registration_succeeded_info_3": "\"Done\" bikirtînin da ku rûpela heyî bigire û ji bo karên jêrîn vegere rûpela Malê. Ger girtina bi ser nekeve, ji kerema xwe tabê bi destan bigire.",
    "verify_email": "E-nameyê piştrast bikin",
    "registered_email_not_verified": "E-nameya qeydkirî nehatiye verast kirin, ji kerema xwe bi carekê ve verast bikin.",
    "email_verification_time_1": "Ma e-nameyek verastkirinê wernegirtiye?",
    "email_verification_time_2": "Piştî",
    "email_verification_time_3": "Li vir bikirtînin ku ji nû ve bişînin",
    "error_26301": "Koda çewtiyê: 26301, ji kerema xwe bi me re têkilî daynin",
    "error_26302": "Koda çewtiyê: 26302, ji kerema xwe bi me re têkilî daynin",
    "error_26303": "Çewtiya formata e-nameyê (koda xeletiyê: 26303). Ji kerema xwe dîsa têkevin",
    "error_26304": "Şîfreya li jor 8 tîpan tê pêşniyar kirin (koda çewtiyê: 26304)",
    "error_26305": "Reuqest têk çû (koda çewtiyê: 26305). Ji kerema xwe dîsa biceribîne",
    "error_26306": "E-name hat qeyd kirin, ji kerema xwe <a href=\"https://account.mindonmap.com/login/\">herin têketinê</a>",
    "error_26307": "Reuqest têk çû (koda çewtiyê: 26307). Ji kerema xwe dîsa biceribîne",
    "error_26308": "Reuqest têk çû (koda çewtiyê: 26308). Ji kerema xwe dîsa biceribîne",
    "error_26401": "Koda çewtiyê: 26401, ji kerema xwe ji nû ve biceribîne",
    "error_26402": "E-name hate verast kirin (koda xeletiyê: 26402), ji kerema xwe wê ji nû ve biceribîne",
    "error_26403": "Reuqest têk çû (koda çewtiyê: 26403). Ji kerema xwe dîsa biceribîne",
    "error_26404": "Daxwaz bi ser neket (koda çewtiyê: 26404). Ji kerema xwe dîsa biceribîne",
    "error_26501": "Koda çewtiyê: 26501, ji kerema xwe bi me re têkilî daynin",
    "error_26502": "Koda çewtiyê: 26502, ji kerema xwe bi me re têkilî daynin",
    "error_26503": "Çewtiya formata e-nameyê (koda xeletiyê: 26503). Ji kerema xwe dîsa têkevin",
    "error_26504": "Reuqest têk çû (koda çewtiyê: 26504). Ji kerema xwe dîsa biceribîne",
    "error_26505": "E-name nehat qeyd kirin, ji kerema xwe <a href=\"https://account.mindonmap.com/register/\">pêşî qeyd bikin</a>",
    "error_26506": "E-name hate verast kirin.",
    "error_26507": "Reuqest têk çû (koda çewtiyê: 26507). Ji kerema xwe dîsa biceribîne",
    "error_26508": "Verastkirin hat kirin (koda çewtiyê: 26508), ji kerema xwe wê ji nû ve biceribîne",
    "error_26509": "Daxwaz bi ser neket (koda çewtiyê: 26509), ji kerema xwe wê dîsa biceribîne",
    "error_26510": "Koda çewtiyê: 26510, ji kerema xwe bi me re têkilî daynin",
    "error_26601": "Koda çewtiyê: 26601, ji kerema xwe bi me re têkilî daynin",
    "error_26602": "Koda çewtiyê: 26602, ji kerema xwe bi me re têkilî daynin",
    "error_26603": "Reuqest têk çû (koda çewtiyê: 26603). Ji kerema xwe dîsa biceribîne",
    "error_26604": "Koda çewtiyê: 26604, ji kerema xwe bi me re têkilî daynin",
    "error_26605": "Koda çewtiyê: 26605, ji kerema xwe bi me re têkilî daynin",
    "error_26701": "Koda çewtiyê: 26701, ji kerema xwe bi me re têkilî daynin",
    "error_26702": "Reuqest têk çû (koda çewtiyê: 26702). Ji kerema xwe dîsa biceribîne",
    "error_26703": "Koda çewtiyê: 26703, ji kerema xwe bi me re têkilî daynin",
    "error_26704": "Koda çewtiyê: 26704, ji kerema xwe bi me re têkilî daynin",
    "error_26705": "Li benda têketinê bisekinin (koda çewtiyê: 26705). Ji kerema xwe dîsa biceribîne",
    "no_cookie": "Te di geroka xwe de fonksiyona Astengkirina hemî cookie-yan vekiriye, ji ber vê yekê hûn nikanin têkevinê. Ji kerema xwe biçin Mîhengan da ku qutiya Destûrê bide hemî cookie-yan kontrol bikin.",
    "error_26801": "Koda çewtiyê: 26801, ji kerema xwe bi me re têkilî daynin",
    "error_26802": "Koda çewtiyê: 26802, ji kerema xwe bi me re têkilî daynin",
    "error_26803": "Daxwaz bi ser neket (koda çewtiyê: 26803). Ji kerema xwe dîsa biceribîne",
    "error_26804": "Daxwaz bi ser neket (koda çewtiyê: 26804). Ji kerema xwe dîsa biceribîne",
    "error_order": "Daxwaz bi ser neket (koda çewtiyê: 27098), ji kerema xwe dîsa biceribîne!",
    "error_order1": "Pirsa fermanê ne temam e (koda çewtiyê: ",
    "error_order2": "）, ji kerema xwe nûve bikin û dîsa biceribînin.",
    "modify_email_title": "E-nameyê biguherînin",
    "modify_email_info": "Hûn dikarin e-nameya hatî guherandin bikar bînin da ku têkevin hesabê xwe.",
    "images_per": "Wêneyên per",
    "error_26101": "Çewtî: 26101. Ji kerema xwe bi me re têkilî daynin",
    "error_26102": "Çewtî: 26102. Ji kerema xwe bi me re têkilî daynin",
    "error_26103": "Daxwaz serneket (koda çewtiyê:26103). Ji kerema xwe dîsa biceribîne",
    "error_26104": "Koda çewtiyê: 26104, ji kerema xwe ji nû ve biceribîne",
    "error_26105": "Koda çewtiyê: 26105, ji kerema xwe ji nû ve biceribîne",
    "error_26106": "Jêbirin serneket (koda çewtiyê: 26106). Ji kerema xwe dîsa biceribîne",
    "error_26201": "Çewtî: 26201. Ji kerema xwe bi me re têkilî daynin",
    "error_26202": "Daxwaz        (koda çewtiyê:26202). Ji kerema xwe dîsa biceribîne",
    "error_26001": "Çewtî: 26001. Ji kerema xwe bi me re têkilî daynin",
    "error_26002": "Çewtî: 26002. Ji kerema xwe bi me re têkilî daynin",
    "error_26003": "Çewtî: 26003. Ji kerema xwe bi me re têkilî daynin",
    "error_26004": "Çewtî: 26004. Ji kerema xwe bi me re têkilî daynin",
    "error_26005": "Daxwaz biserneket (koda çewtiyê:26005). Ji kerema xwe dîsa biceribîne",
    "error_26006": "Koda çewtiyê: 26006, ji kerema xwe ji nû ve biceribîne",
    "error_26008": "Çewtî: 26008. Ji kerema xwe bi me re têkilî daynin",
    "go_to_the_home_page": "Biçe rûpela Malê",
    "error_27101": "Daxwaz bi ser neket (koda çewtiyê: 27101). Ji kerema xwe dîsa biceribîne",
    "error_27201": "Koda çewtiyê: 27201, ji kerema xwe bi me re têkilî daynin",
    "error_27202": "Koda çewtiyê: 27202, ji kerema xwe ji nû ve biceribîne",
    "error_27203": "Daxwaz bi ser neket (koda çewtiyê: 27203). Ji kerema xwe dîsa biceribîne",
    "error_27204": "Koda nederbasdar (koda çewtiyê: 27204).",
    "error_27205": "Daxwaz bi ser neket (koda çewtiyê: 27205). Ji kerema xwe dîsa biceribîne",
    "error_27206": "Daxwaz bi ser neket (koda çewtiyê: 27206). Ji kerema xwe dîsa biceribîne",
    "error_27207": "Daxwaz bi ser neket (koda çewtiyê: 27207). Ji kerema xwe dîsa biceribîne",
    "no_history_found": "Te tu amûrek bi kar neaniye! <a href=\"/\">Nûvekirin</a> an <a href=\"https://www.mindonmap.com/\">Here malpera fermî</a>",
    "error_25301": "Çewtî: 25301. Ji kerema xwe bi me re têkilî daynin",
    "error_25302": "Çewtî: 25302. Ji kerema xwe bi me re têkilî daynin",
    "error_25303": "Daxwaz biserneket (koda çewtiyê: 25303). Ji kerema xwe dîsa biceribîne",
    "error_25304": "Daxwaz      (koda çewtiyê: 25304). Ji kerema xwe dîsa biceribîne",
    "error_25305": "Daxwaz biserneket (koda çewtiyê: 25305). Ji kerema xwe dîsa biceribîne",
    "error_25306": "Daxwaz biserneket (koda çewtiyê: 25306). Ji kerema xwe dîsa biceribîne",
    "image_upscaler_p": "Wêne bêyî feydeyên dakêşana avê nîşanê:",
    "Available_for": "Berdest ji bo:",
    "credit_per": "%s kredî(s) per wêneyekî HD",
    "still_valid": "Plan(ên) kirî hîn jî derbasdar e",
    "credit": "kredî",
    "pc_3rd_info": "Têkeve bi serkeftî. Ji kerema xwe ji bo xebitandina bêtir biçin serîlêdanê.",
    "use_online": "Karûbarê serhêl bikar bînin",
    "use_download": "Bernameya sermaseyê bikar bînin",
    "use_immediately": "Yekser bikar bînin",
    "Use_in_browser": "Di Browser de bikar bînin",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Kredî her meh",
    "expire": "Dema qedandinê:",
    "viewDetails": "Hûrguliyan bibînin",
    "viewHistory": "Feydeyên dîrokê bibînin >>",
    "viewDetailsInfo": "Hişyarî: Ger berjewendîyên abonetiyê di nav 7 rojan de piştî bidawîbûnê werin nûve kirin, berjewendîyên ku nehatine bikar anîn dikarin berdewam bikin. Di heman demê de, dema qedandinê dê bixweber ji bo dema qedandina abonetiya nû were nûve kirin. Ger piştî 7 rojan ji dema bidawîbûnê abonetiya nû tunebe, dê hemî feydeyên qediyayî werin paqij kirin.",
    "connect_account": "E-nameyê bi Hesabê xwe ve girêdin",
    "connect_account_info": "Piştî girêdanê, hûn dikarin bi vê navnîşana e-nameyê têkevinê.",
    "connect_now": "Bind Now",
    "no_email_bind": "Girêdana e-nameyê tune",
    "bind_email": "E-nameyê girêdin",
    "connect_your_email_placeholder": "Ji kerema xwe navnîşana e-nameya xwe binivîse",
    "bind_an_email": "Emailek girêdin",
    "bind_info": "Tu bi serkeftî têketî. Ji kerema xwe e-nameyek bi hesabê xwe ve girêdin da ku berjewendîyên xwe çalak bikin.",
    "bind_later": "Paşê girêdin",
    "hi": "Silav, %s!",
    "Personal_Information": "Agahiyên Kesane",
    "Access": "Navketin",
    "Subscription_Plan": "(Plana Aboneyê)",
    "No_orders": "Ferman nehat dîtin.",
    "No_data": "Daneyên tune",
    "Featured_Products": "Berhemên Taybetmendî",
    "More_Products": "Berhemên Zêdetir",
    "Free_Download": "Belaş Daxistin",
    "Get_Started": "Dest pê bike",
    "Subscribe": "Subscribe",
    "Verified": "Verified",
    "back_to_account_center": "Vegere Navenda Hesabê",
    "success": "Serketinî!",
    "successfully": "Te hesabek bi serkeftî tomar kir.",
    "Continue": "Berdewamkirin",
    "Already": "Jixwe hesabek heye?",
    "loading_verification": "Kontrolkirina rewşa verastkirinê...",
    "email_no_verification": "Mixabin, navnîşana e-nameya qeydkirî nehatiye piştrast kirin. Ji kerema xwe verastkirinê li gorî rêwerzên jorîn temam bikin û dîsa bişkojka \"Done\" bikirtînin da ku qeydkirinê temam bikin.",
    "will_expire_after": "Dê paşê biqede",
    "hours": "saetan",
  },
  "lt": {
    "overtime": "Klaidos kodas: {%}, bandykite dar kartą",
    "isnetwork": "Interneto klaida. Patikrinkite ir bandykite dar kartą",
    "email_placeholder": "El. paštas",
    "email_empty": "Prašome įvesti el",
    "email_not_valid": "El. paštas negalioja",
    "email_not_valid_1": "Prašome įvesti savo el",
    "email_not_valid_2": "El. paštas neteisingas, naudokite kitą adresą.",
    "email_not_valid_3": "Nėra el. pašto įvesties",
    "password_placeholder": "Slaptažodis",
    "password_empty": "Įveskite slaptažodį",
    "password_not_valid": "Neteisinga paskyra arba slaptažodis",
    "password_not_valid_1": "Reikalingas slaptažodis, ilgesnis nei 8 simboliai",
    "password_not_valid_2": "Sukurkite slaptažodį",
    "password_placeholder_1": "Susikurkite slaptažodį",
    "password_placeholder_2": "Patvirtinkite savo slaptažodį",
    "password_placeholder_3": "Sukurti naują slaptažodį",
    "password_placeholder_4": "Patvirtinti naują slaptažodį",
    "password_placeholder_5": "Įveskite seną slaptažodį",
    "copy_password_empty": "Patvirtinkite slaptažodį",
    "copy_password_not_valid": "Patvirtinkite slaptažodį",
    "copy_passwords_inconsistent": "Jūsų slaptažodžio patvirtinimas nesutampa",
    "code_empty": "Įveskite patvirtinimo kodą",
    "code_not_valid": "Neteisingas patvirtinimo kodas",
    "code_placeholder": "Patvirtinimo kodas",
    "not_received_code": "Jei jūsų pašto dėžutė ilgą laiką negavo patvirtinimo kodo, dar kartą gaukite patvirtinimo kodą.",
    "get_first_code": "Pirmiausia gaukite patvirtinimo kodą.",
    "last_name_placeholder": "Įveskite savo pavardę",
    "first_name_placeholder": "Įveskite savo vardą",
    "address_placeholder": "Įveskite savo adresą",
    "no_code_text": "Išsiuntėme patvirtinimo kodą. Įveskite savo kodą. <span class='tips'>Negavote kodo?",
    "no_code_text_1": "1. Įsitikinkite, kad el. pašto adresas galioja ir gali gauti el. laiškus.",
    "no_code_text_2": "2. Jei el. laišką sistema siunčia automatiškai, jis gali būti pažymėtas kaip nepageidaujamas el. laiškas. Patikrinkite, ar el. laiškas yra šiukšliadėžės aplanke.",
    "no_code_text_3": "3. Negalite išspręsti savo problemos? ",
    "no_code_text_3_span": "Tada spustelėkite čia ir susisiekite su mumis.",
    "order_no": "Neįsigijote jokio produkto.Jei turite klausimų, <a href=\"https://www.mindonmap.com/contact-us/\">susisiekite su mumis</a>.",
    "error_24901": "Su dabartine paskyra nesusietas el. paštas ir negalima rasti užsakymų. Prašome prisijungti el.",
    "user_guide": "Vartotojo vadovas >>",
    "download": "parsisiųsti",
    "order_number": "Užsakymo numeris:",
    "Refund": "Grąžinti pinigus",
    "Disabled": "Išjungta",
    "Normal": "Normalus",
    "Modify": "Modifikuoti",
    "Modify_1": "Keisti >>",
    "Connect": "Prisijungti",
    "unlink_success": "Atsieti sėkmingai",
    "connect_success": "Prisijungimas sėkmingai",
    "feedback_title": "Ačiū už jūsų atsiliepimus. Palikite savo problemą ir mes jums atsakysime per 24 valandas.",
    "feedback_thank_you": "Ačiū!<br />Jūsų atsiliepimai sėkmingai pateikti.",
    "feedback_email": "Įveskite savo el. pašto adresą čia!",
    "feedback_content": "Palikite čia bet kokią problemą ar pasiūlymą, su kuriuo susidūrėte.",
    "feedback_submit": "Pateikti",
    "form_contents": "Neįvedėte jokio aprašymo. Įveskite jį ir pateikite dar kartą.",
    "old_password": "Įveskite seną slaptažodį",
    "new_password": "Sukurkite naują slaptažodį",
    "old_new_password": "Naujas slaptažodis negali būti toks pat kaip senasis",
    "incorrect_password": "Neteisingas slaptažodis",
    "delete_no": "Ištrinti dabar",
    "Caps": "Didžiosios raidės įjungtos",
    "Get": "Gauk",
    "Done": "padaryta",
    "error_20001": "Klaida: 20001. Prisijunkite dar kartą.",
    "error_20002": "Klaida: 20002. Prisijunkite dar kartą.",
    "error_20003": "Klaida: 20003. Prisijunkite dar kartą.",
    "error_20004": "Užklausa nepavyko (klaidos kodas: 20004). Bandykite dar kartą.",
    "error_20005": "Prisijungimo seansas baigėsi (klaida: 20005). Prašome prisijungti dar kartą.",
    "error_20006": "Užklausa nepavyko (klaidos kodas: 20006). Bandykite dar kartą.",
    "error_20007": "Prisijungimo seansas baigėsi (klaida: 20007). Prašome prisijungti dar kartą.",
    "error_20008": "Prisijungimo seansas baigėsi (klaida: 20008). Prašome prisijungti dar kartą.",
    "error_20009": "Prisijungimo seansas baigėsi (klaida: 20009). Prašome prisijungti dar kartą.",
    "error_20101": "Įveskite savo el. pašto adresą (klaidos kodas: 20101)",
    "error_20102": "El. pašto adresas neteisingas (klaidos kodas: 20102)",
    "error_20103": "Užklausa nepavyko (klaidos kodas: 20103). Bandykite dar kartą",
    "error_20104": "El. paštas jau naudojamas, <a href=\"https://account.mindonmap.com/login/\">prisijunkite</a> arba prisiregistruokite naudodami naują",
    "error_20105": "Užklausa nepavyko (klaidos kodas: 20105). Bandykite dar kartą",
    "error_20106": "Nepavyko išsiųsti el. laiško, bandykite dar kartą",
    "error_20201": "Įveskite savo el. pašto adresą (klaidos kodas: 20201)",
    "error_20202": "Įveskite slaptažodį (klaidos kodas: 20202)",
    "error_20203": "Įveskite patvirtinimo kodą (klaidos kodas: 20203)",
    "error_20204": "El. pašto adresas neteisingas (klaidos kodas: 20204)",
    "error_20205": "Reikalingas slaptažodis, ilgesnis nei 8 simboliai (klaidos kodas: 20205)",
    "error_20206": "Užklausa nepavyko (klaidos kodas: 20206). Bandykite dar kartą",
    "error_20207": "Neteisingas patvirtinimo kodas",
    "error_20208": "Užklausa nepavyko (klaidos kodas: 20208). Bandykite dar kartą",
    "error_20209": "Užklausa nepavyko (klaidos kodas: 20209). Bandykite dar kartą",
    "error_20301": "Įveskite savo el. pašto adresą (klaidos kodas: 20301)",
    "error_20302": "Klaida: 20302. Susisiekite su mumis",
    "error_20303": "El. pašto adresas neteisingas (klaidos kodas: 20303)",
    "error_20304": "Užklausa nepavyko (klaidos kodas: 20304). Bandykite dar kartą",
    "error_20305": "Paskyros nėra. Pirmiausia įveskite iš naujo arba <a href=\"https://account.mindonmap.com/register/\">sukurkite jį</a>.",
    "error_20306": "Slaptažodžio dar nėra. Naudokite <a href=\"https://account.mindonmap.com/passwordless-login/\">prisijungimą be slaptažodžio</a> arba <a href=\"https://account.mindonmap.com/create-password/\"> nustatykite slaptažodį</a> ir prisijunkite.",
    "error_20308": "Užklausa nepavyko (klaidos kodas: 20308). Bandykite dar kartą",
    "error_20401": "Nepavyko atsijungti (klaidos kodas: 20401). Bandykite dar kartą",
    "error_20501": "Įveskite savo el. pašto adresą (klaidos kodas: 20501)",
    "error_20502": "El. pašto adresas neteisingas (klaidos kodas: 20502)",
    "error_20503": "Užklausa nepavyko (klaidos kodas: 20503). Bandykite dar kartą",
    "error_20504": "Nepavyko išsiųsti el. Bandykite dar kartą.",
    "error_20601": "Įveskite savo el. pašto adresą (klaidos kodas: 20601)",
    "error_20602": "Įveskite patvirtinimo kodą (klaidos kodas: 20602)",
    "error_20603": "El. pašto adresas neteisingas (klaidos kodas: 20603)",
    "error_20604": "Užklausa nepavyko (klaidos kodas: 20604). Bandykite dar kartą",
    "error_20606": "Užklausa nepavyko (klaidos kodas: 20606). Bandykite dar kartą",
    "error_20607": "Užklausa nepavyko (klaidos kodas: 20607). Bandykite dar kartą",
    "error_20608": "Užklausa nepavyko (klaidos kodas: 20608). Bandykite dar kartą",
    "error_20701": "Įveskite savo el. pašto adresą (klaidos kodas: 20701)",
    "error_20702": "El. pašto adresas neteisingas (klaidos kodas: 20702)",
    "error_20703": "Užklausa nepavyko (klaidos kodas: 20703). Bandykite dar kartą",
    "error_20704": "Paskyros nėra. Pirmiausia įveskite iš naujo arba <a href=\"https://account.mindonmap.com/register/\">sukurkite jį</a>.",
    "error_20705": "Užklausa nepavyko (klaidos kodas: 20705). Bandykite dar kartą",
    "error_20706": "Nepavyko išsiųsti el. Bandykite dar kartą",
    "error_20801": "Įveskite savo el. pašto adresą (klaidos kodas: 20801)",
    "error_20802": "Klaida: 20802. Susisiekite su mumis",
    "error_20803": "Įveskite patvirtinimo kodą (klaidos kodas: 20803)",
    "error_20804": "El. pašto adresas neteisingas (klaidos kodas: 20804)",
    "error_20805": "Reikalingas slaptažodis, ilgesnis nei 8 simboliai (klaidos kodas: 20805)",
    "error_20806": "Užklausa nepavyko (klaidos kodas: 20806). Bandykite dar kartą",
    "error_20808": "Užklausa nepavyko (klaidos kodas: 20808). Bandykite dar kartą",
    "error_20901": "Užklausa nepavyko (klaidos kodas: 20901). Bandykite dar kartą",
    "error_20902": "Užklausa nepavyko (klaidos kodas: 20902). Bandykite dar kartą",
    "error_21000": "Pakeitimai išsaugomi",
    "error_21001": "Informacija nepateikta (klaidos kodas: 21001)",
    "error_21002": "Užklausa nepavyko (klaidos kodas: 21002). Bandykite dar kartą",
    "error_21101": "Įveskite savo el. pašto adresą (klaidos kodas: 21101)",
    "error_21102": "El. pašto adresas neteisingas (klaidos kodas: 21102)",
    "error_21103": "Užklausa nepavyko (klaidos kodas: 21103), bandykite dar kartą",
    "error_21104": "El. paštas jau prijungtas, naudokite naują",
    "error_21105": "Užklausa nepavyko (klaidos kodas: 21105), bandykite dar kartą",
    "error_21106": "Nepavyko išsiųsti el. Bandykite dar kartą",
    "error_21201": "Įveskite savo el. pašto adresą (klaidos kodas: 21201)",
    "error_21202": "Įveskite patvirtinimo kodą (klaidos kodas: 21202)",
    "error_21203": "El. pašto adresas neteisingas (klaidos kodas: 21203)",
    "error_21204": "Klaida: 21204. Prašome susisiekti su mumis",
    "error_21205": "Klaida: 21205. Prašome susisiekti su mumis",
    "error_21206": "Reikalingas slaptažodis, ilgesnis nei 8 simboliai (klaidos kodas: 21206)",
    "error_21207": "Užklausa nepavyko (klaidos kodas: 21207). Bandykite dar kartą",
    "error_21209": "Užklausa nepavyko (klaidos kodas: 21209). Bandykite dar kartą",
    "error_21301": "Įveskite seną slaptažodį (klaidos kodas: 21301)",
    "error_21302": "Sukurkite naują slaptažodį (klaidos kodas: 21302)",
    "error_21303": "Naujas slaptažodis negali būti toks pat kaip senasis. (Klaida: 21303)",
    "error_21304": "Reikalingas slaptažodis, ilgesnis nei 8 simboliai (klaidos kodas: 21304)",
    "error_21306": "Užklausa nepavyko (klaidos kodas: 21306). Bandykite dar kartą",
    "error_21402": "Užklausa nepavyko (klaidos kodas: 21402). Bandykite dar kartą",
    "error_21403": "Nepavyko išsiųsti patvirtinimo kodo. Prašome atsiųsti dar kartą",
    "error_21500": "Paskyra ištrinta",
    "error_21501": "Įveskite patvirtinimo kodą (klaidos kodas: 21501)",
    "error_21502": "Prisijungimo seansas baigėsi (klaida: 21502). Prašome prisijungti dar kartą.",
    "error_21503": "Užklausa nepavyko (klaidos kodas: 21503). Bandykite dar kartą",
    "error_21505": "Užklausa nepavyko (klaidos kodas: 21505), bandykite dar kartą",
    "error_21601": "Klaida: 20601. Susisiekite su mumis",
    "error_21602": "Neteisingas patvirtinimas (klaida: 20602). Bandykite dar kartą.",
    "error_21603": "Klaida: 20603. Bandykite dar kartą",
    "error_21604": "Užklausa nepavyko (klaidos kodas: 21604). Bandykite dar kartą",
    "error_21606": "Užklausa nepavyko (klaidos kodas: 21606). Bandykite dar kartą",
    "error_21611": "Užklausa nepavyko (klaidos kodas: 21611). Bandykite dar kartą",
    "error_21801": "Klaida: 21801. Prašome susisiekti su mumis",
    "error_21802": "Užklausa nepavyko (klaida: 21802). Bandykite dar kartą",
    "error_21803": "Klaida: 21803. Bandykite dar kartą",
    "error_21804": "Užklausa nepavyko (klaidos kodas: 21804). Bandykite dar kartą",
    "error_21806": "Klaida: 21806. Bandykite dar kartą",
    "error_21807": "Klaida: 21807. Prašome susisiekti su mumis",
    "error_21808": "Klaida: 21808. Prašome susisiekti su mumis",
    "error_21809": "Klaida: 21809. Prašome susisiekti su mumis",
    "error_21810": "Klaida: 21810. Prašome susisiekti su mumis",
    "error_21811": "Klaida: 21811. Prašome susisiekti su mumis",
    "error_21812": "Klaida: 21812. Prašome susisiekti su mumis",
    "error_21813": "Užklausa nepavyko (klaidos kodas: 21813). Bandykite dar kartą",
    "error_21814": "Klaida: 21814. Prašome susisiekti su mumis",
    "error_21815": "Užklausa nepavyko (klaidos kodas: 21815). Bandykite dar kartą",
    "error_21816": "Klaida: 21816. Prašome susisiekti su mumis",
    "error_21817": "Klaida: 21817. Prašome susisiekti su mumis",
    "error_21818": "Klaida: 21818. Prašome susisiekti su mumis",
    "error_21819": "Užklausa nepavyko (klaidos kodas: 21819). Bandykite dar kartą",
    "error_21820": "Klaida: 21820. Prašome susisiekti su mumis",
    "error_21821": "Klaida: 21821. Prašome susisiekti su mumis",
    "error_21822": "Klaida: 21822. Prašome susisiekti su mumis",
    "error_21823": "Užklausa nepavyko (klaidos kodas: 21823). Bandykite dar kartą",
    "error_21824": "Užklausa nepavyko (klaidos kodas: 21824). Bandykite dar kartą",
    "error_21825": "Užklausa nepavyko (klaidos kodas: 21825). Bandykite dar kartą",
    "error_21826": "Užklausa nepavyko (klaidos kodas: 21826). Bandykite dar kartą",
    "error_21901": "Klaida: 21901. Prašome susisiekti su mumis",
    "error_21902": "Užklausa nepavyko (klaidos kodas: 21902). Bandykite dar kartą",
    "error_21903": "Paskyros būsena pasikeitė (klaidos kodas: 21903), atnaujinkite puslapį ir bandykite dar kartą",
    "error_21904": "Klaida: 21904. Bandykite dar kartą",
    "error_21905": "Klaida: 21905. Bandykite dar kartą",
    "error_21906": "Užklausa nepavyko (klaidos kodas: 21906). Bandykite dar kartą",
    "error_21907": "„Google“ paskyra susieta su kita paskyra",
    "error_21908": "Užklausa nepavyko (klaidos kodas: 21908). Bandykite dar kartą",
    "error_22001": "Užklausa nepavyko (klaidos kodas: 22001). Bandykite dar kartą",
    "error_22002": "Atsieti nepavyko be papildomo prisijungimo",
    "error_22003": "Užklausa nepavyko (klaidos kodas: 22003). Bandykite dar kartą",
    "error_22101": "Klaida: 22101. Prašome susisiekti su mumis",
    "error_22102": "Paskyros būsena pasikeitė (klaidos kodas: 22102), atnaujinkite puslapį ir bandykite dar kartą",
    "error_22103": "Užklausa nepavyko (klaidos kodas: 22103). Bandykite dar kartą",
    "error_22104": "Paskyros būsena pasikeitė (klaidos kodas: 22104), atnaujinkite puslapį ir bandykite dar kartą",
    "error_22105": "Klaida: 22105. Bandykite dar kartą",
    "error_22106": "Klaida: 22106. Bandykite dar kartą",
    "error_22107": "Klaida: 22107. Prašome susisiekti su mumis",
    "error_22108": "Užklausa nepavyko (klaidos kodas: 22108). Bandykite dar kartą",
    "error_22201": "Klaida: 22201. Prašome susisiekti su mumis",
    "error_22202": "Neteisingas patvirtinimas (klaida: 22202). Bandykite dar kartą.",
    "error_22203": "Klaida: 22203. Bandykite dar kartą",
    "error_22204": "Užklausa nepavyko (klaidos kodas: 22204). Bandykite dar kartą",
    "error_22206": "Užklausa nepavyko (klaidos kodas: 22206). Bandykite dar kartą",
    "error_22401": "Klaida: 22401. Susisiekite su mumis",
    "error_22402": "Neteisingas patvirtinimas (klaida: 22402). Bandykite dar kartą.",
    "error_22403": "Klaida: 22403. Bandykite dar kartą",
    "error_22404": "Užklausa nepavyko (klaidos kodas: 22404). Bandykite dar kartą",
    "error_22405": "„Facebook“ paskyra buvo susieta su kitu el",
    "error_22406": "Klaida: 22406. Bandykite dar kartą",
    "error_22407": "Klaida: 22407. Prašome susisiekti su mumis",
    "error_22408": "Klaida: 22408. Prašome susisiekti su mumis",
    "error_22409": "Klaida: 22409. Prašome susisiekti su mumis",
    "error_22410": "Klaida: 224010. Prašome susisiekti su mumis",
    "error_22411": "Klaida: 224011. Prašome susisiekti su mumis",
    "error_22412": "Klaida: 224012. Prašome susisiekti su mumis",
    "error_22413": "Klaida: 22413. Prašome susisiekti su mumis",
    "error_22414": "Užklausa nepavyko (klaidos kodas: 22414). Bandykite dar kartą",
    "error_22415": "Klaida: 22415. Prašome susisiekti su mumis",
    "error_22416": "Klaida: 22416. Prašome susisiekti su mumis",
    "error_22417": "Klaida: 22417. Prašome susisiekti su mumis",
    "error_22418": "Užklausa nepavyko (klaidos kodas: 22418). Bandykite dar kartą",
    "error_22419": "Klaida: 22419. Prašome susisiekti su mumis",
    "error_22420": "Klaida: 22420. Prašome susisiekti su mumis",
    "error_22421": "Klaida: 22421. Prašome susisiekti su mumis",
    "error_22422": "Užklausa nepavyko (klaidos kodas: 22422). Bandykite dar kartą",
    "error_22423": "Užklausa nepavyko (klaidos kodas: 22423). Bandykite dar kartą",
    "error_22424": "Užklausa nepavyko (klaidos kodas: 22424). Bandykite dar kartą",
    "error_22425": "Užklausa nepavyko (klaidos kodas: 22425). Bandykite dar kartą",
    "error_20098": "Klaidos kodas: 20098. Jei naršote privačiu režimu, perjunkite į įprastą režimą ir bandykite dar kartą.",
    "error_22298": "„Google“ užklausa nepavyko (klaida: 22298). Bandykite dar kartą.",
    "error_22498": "„Facebook“ užklausa nepavyko (klaida: 22498). Bandykite dar kartą.",
    "error_24902": "Užklausa nepavyko (klaidos kodas: 24902). Bandykite dar kartą",
    "error_24903": "Užklausa nepavyko (klaidos kodas: 24903). Bandykite dar kartą",
    "error_24904": "Užklausa nepavyko (klaidos kodas: 24904). Bandykite dar kartą",
    "error_24905": "Užklausa nepavyko (klaidos kodas: 24905). Bandykite dar kartą",
    "login_title": "Prisijunkite prie MindOnMap",
    "log_in": "Prisijungti",
    "no_account": "Nėra paskyros?",
    "create_it": "Sukurkite jį",
    "or_log_in_with": "Arba prisijunkite su",
    "passwordless_login": "Prisijungimas be slaptažodžio",
    "log_in_done": "Prisijungti Atlikta",
    "three_rd_account_connect_info": "Sveikiname! Jūs sėkmingai prisijungėte. Dabar galite prijungti vieną el. pašto paskyrą, kuri bus naudojama prisijungimui ateityje.",
    "see_my_account": "Žiūrėti mano paskyrą",
    "three_rd_login_merge_account": "Trečiosios šalies paskyros el. pašto adresas buvo užregistruotas, ar norite prisijungti ir prisijungti tiesiogiai naudodami šį el. pašto adresą?",
    "connect_log_in": "Prisijunkite ir prisijunkite",
    "create_an_account": "Sukurti paskyrą",
    "back_to_log_in": "Atgal prisijungti",
    "create_password": "Sukurti slaptažodį",
    "create_now": "Sukurti dabar",
    "password_login_subtitle": "Prisijungimas be slaptažodžio su el",
    "account_login": "Prisijungimas prie paskyros",
    "rights": "Kurdami šią paskyrą sutinkate su <a href=\"https://www.mindonmap.com/terms-and-conditions/\">paslaugų teikimo sąlygomis</a> ir <a href=\"https://www.mindonmap.com/privacy-policy/\">Privatumo politika</a>",
    "passwordless_login_done": "Prisijungimas be slaptažodžio atliktas",
    "passwordless_login_info": "Sveikiname, sėkmingai prisijungėte be slaptažodžio. Galite sukurti šios paskyros slaptažodį ir ateityje prisijungti naudodami paskyrą bei slaptažodį. <a href=\"/create-password\" style=\"display: initial;\">Kurti dabar</a>",
    "sign_up": "Registruotis",
    "register_info": "Sukurkite savo paskyrą",
    "reset_now": "Nustatyti iš naujo dabar",
    "forgot_password": "Pamiršote slaptažodį",
    "reset_password_subtitle": "Norėdami iš naujo nustatyti slaptažodį, naudokite paskyros el",
    "plan_products": "Planai ir produktai",
    "nick_name": "Vartotojo vardas:",
    "email": "El. paštas:",
    "my_products": "Mano produktai",
    "my_orders": "Mano įsakymai",
    "unlink": "Atsieti",
    "link": "Nuoroda",
    "connected_accounts": "Susietos paskyros",
    "last_name": "Pavardė:",
    "first_name": "Pirmas vardas:",
    "Gender": "Lytis:",
    "Birth": "Gimdymas:",
    "Month": "Mėnuo",
    "Year": "Metai",
    "Country_Region": "Šalis/regionas:",
    "Address": "Adresas:",
    "Save": "Sutaupyti",
    "Date": "Data",
    "Male": "Patinas",
    "Female": "Moteris",
    "Unspecified": "Nenurodyta",
    "Security": "Saugumas",
    "change_password": "Pakeisti slaptažodį",
    "change_now": "Keisti dabar",
    "connect_email": "Prijunkite el",
    "delete_account": "Ištrinti paskyrą",
    "delete_account_info": "Kai paskyra bus ištrinta, visi su paskyra susieti Mano paskyros duomenys bus visam laikui ištrinti ir jums gali nepavykti jų atkurti. Rekomenduojame vaikščioti atsargiai.",
    "Delete": "Ištrinti",
    "Logout": "Atsijungti",
    "my_profile": "Mano profilis",
    "guides_faqs": "Vadovai ir DUK",
    "More": "Daugiau",
    "guides": "Vadovai",
    "register": "Registruotis",
    "hot_faq": "Karšti DUK",
    "Contents": "Turinys:",
    "contact_us": "Susisiekite su mumis >>",
    "plan": "Planuoti",
    "unregistered": "Neregistruotas",
    "buy_more": "Pirkite daugiau",
    "buy_again": "Pirkite dar kartą",
    "buy_now": "Pirkti dabar",
    "free_no_limit": "Nemokama ir be apribojimų",
    "expired": "Baigėsi galiojimo laikas",
    "lifetime": "Gyvenimas",
    "remain": "Likti",
    "day_s": "Diena (-os)",
    "error_24801": "Užklausa nepavyko (klaidos kodas: 24801). Bandykite dar kartą",
    "no_app_found": "Programa nerasta!<a href=\"/\">Atnaujinkite</a> arba <a href=\"https://www.mindonmap.com/\">Eikite į oficialią svetainę</a>",
    "get_more": "Gaukite daugiau >>",
    "edit_photo": "Redaguoti nuotrauką",
    "select_photo": "Pasirinkite nuotrauką",
    "change_photo": "Pakeisti nuotrauką",
    "cancel": "Atšaukti",
    "hide_password": "Slėpti slaptažodį",
    "show_password": "Rodyti slaptažodį",
    "zoom_in": "Priartinti",
    "zoom_out": "Nutolinti",
    "rotate": "Pasukti",
    "horizontal_flip": "Horizontalus apvertimas",
    "vertical_flip": "Vertikalus apvertimas",
    "country": "Šalis",
    "country_1": "Pasirinkite savo šalį/regioną",
    "country_2": "Alandų sala",
    "country_3": "Afganistanas",
    "country_4": "Albanija",
    "country_5": "Alžyras",
    "country_6": "Amerikos Samoa",
    "country_7": "Andora",
    "country_8": "Angola",
    "country_9": "Angilija",
    "country_10": "Antarktida",
    "country_11": "Antigva ir Barbuda",
    "country_12": "Argentina",
    "country_13": "Armėnija",
    "country_14": "Aruba",
    "country_15": "Australija",
    "country_16": "Austrija",
    "country_17": "Azerbaidžanas",
    "country_18": "Bahreinas",
    "country_19": "Bangladešas",
    "country_20": "Barbadosas",
    "country_21": "Baltarusija",
    "country_22": "Belgija",
    "country_23": "Belizas",
    "country_24": "Beninas",
    "country_25": "Bermudai",
    "country_26": "Butanas",
    "country_27": "Bolivija",
    "country_28": "Bosnija ir Hercegovina",
    "country_29": "Botsvana",
    "country_30": "Bouvet sala",
    "country_31": "Brazilija",
    "country_32": "Britų Indijos okeaninė teritorija",
    "country_33": "Britanijos mergelių salos",
    "country_34": "Brunėjus",
    "country_35": "Bulgarija",
    "country_36": "Burkina",
    "country_37": "Burundis",
    "country_38": "Kambodža",
    "country_39": "Kamerūnas",
    "country_40": "Kanada",
    "country_41": "Žaliasis Kyšulys",
    "country_42": "Karibų nyderlandai",
    "country_43": "Kaimanų salos",
    "country_44": "Centrine Afrikos Respublika",
    "country_45": "Čadas",
    "country_46": "Čilė",
    "country_47": "Kinija",
    "country_48": "Kalėdų sala",
    "country_49": "Kokosų (Kilingo) salos",
    "country_50": "Kolumbija",
    "country_51": "Kuko salos",
    "country_52": "Kosta Rika",
    "country_53": "Dramblio Kaulo Krantas",
    "country_54": "Kroatija",
    "country_55": "Kuba",
    "country_56": "Kipras",
    "country_57": "Čekijos Respublika",
    "country_58": "Kongo Demokratinė Respublika",
    "country_59": "Danija",
    "country_60": "Džibutis",
    "country_61": "Dominika",
    "country_62": "Dominikos Respublika",
    "country_63": "Ekvadoras",
    "country_64": "Egiptas",
    "country_65": "Salvadoras",
    "country_66": "Pusiaujo Gvinėja",
    "country_67": "Eritrėja",
    "country_68": "Estija",
    "country_69": "Etiopija",
    "country_70": "Folklando salos",
    "country_71": "Farerų salos",
    "country_72": "Mikronezijos Federacinės Valstijos",
    "country_73": "Fidžis",
    "country_74": "Suomija",
    "country_75": "Prancūzija",
    "country_76": "Prancūzijos Gviana",
    "country_77": "Prancūzų Polinezija",
    "country_78": "Prancūzijos pietinės teritorijos",
    "country_79": "Gabonas",
    "country_80": "Gambija",
    "country_81": "Gruzija",
    "country_82": "Vokietija",
    "country_83": "Gana",
    "country_84": "Gibraltaras",
    "country_85": "Didžioji Britanija (Jungtinė Karalystė; Anglija)",
    "country_86": "Graikija",
    "country_87": "Grenlandija",
    "country_88": "Grenada",
    "country_89": "Gvadelupa",
    "country_90": "Guamas",
    "country_91": "Gvatemala",
    "country_92": "Gernsis",
    "country_93": "Gvinėja",
    "country_94": "Bisau Gvinėja",
    "country_95": "Gajana",
    "country_96": "Haitis",
    "country_97": "Heard Island ir McDonald Islands",
    "country_98": "Hondūras",
    "country_99": "Honkongas",
    "country_100": "Vengrija",
    "country_101": "Islandija",
    "country_102": "Indija",
    "country_103": "Indonezija",
    "country_104": "Iranas",
    "country_105": "Irakas",
    "country_106": "Airija",
    "country_107": "Meno sala",
    "country_108": "Izraelis",
    "country_109": "Italija",
    "country_110": "Jamaika",
    "country_111": "Japonija",
    "country_112": "Džersis",
    "country_113": "Jordanas",
    "country_114": "Kazachstanas",
    "country_115": "Kenija",
    "country_116": "Kiribatis",
    "country_117": "Kuveitas",
    "country_118": "Kirgizija",
    "country_119": "Laosas",
    "country_120": "Latvija",
    "country_121": "Libanas",
    "country_122": "Lesotas",
    "country_123": "Liberija",
    "country_124": "Libija",
    "country_125": "Lichtenšteinas",
    "country_126": "Lietuva",
    "country_127": "Liuksemburgas",
    "country_128": "Makao",
    "country_129": "Madagaskaras",
    "country_130": "Malavis",
    "country_131": "Malaizija",
    "country_132": "Maldyvai",
    "country_133": "Malis",
    "country_134": "Malta",
    "country_135": "Maršalo salos",
    "country_136": "Martinika",
    "country_137": "Mauritanija",
    "country_138": "Mauricijus",
    "country_139": "Majotas",
    "country_140": "Meksika",
    "country_141": "Moldova",
    "country_142": "Monakas",
    "country_143": "Mongolija",
    "country_144": "Juodkalnija",
    "country_145": "Montserratas",
    "country_146": "Marokas",
    "country_147": "Mozambikas",
    "country_148": "Mianmaras (Birma)",
    "country_149": "Namibija",
    "country_150": "Nauru",
    "country_151": "Nepalas",
    "country_152": "Nyderlandai",
    "country_153": "Naujoji Kaledonija",
    "country_154": "Naujoji Zelandija",
    "country_155": "Nikaragva",
    "country_156": "Nigeris",
    "country_157": "Nigerija",
    "country_158": "Niue",
    "country_159": "Norfolko sala",
    "country_160": "Šiaurės Korėja",
    "country_161": "Šiaurinės Marianos salos",
    "country_162": "Norvegija",
    "country_163": "Omanas",
    "country_164": "Pakistanas",
    "country_165": "Palau",
    "country_166": "Palestinos teritorijos",
    "country_167": "Panama",
    "country_168": "Papua Naujoji Gvinėja",
    "country_169": "Paragvajus",
    "country_170": "Peru",
    "country_171": "Pitkerno salos",
    "country_172": "Lenkija",
    "country_173": "Portugalija",
    "country_174": "Puerto Rikas",
    "country_175": "Kataras",
    "country_176": "Makedonijos Respublika (BJROM)",
    "country_177": "Kongo Respublika",
    "country_178": "Reunjonas",
    "country_179": "Rumunija",
    "country_180": "Rusijos Federacija",
    "country_181": "Ruanda",
    "country_182": "Sent Bartelmė",
    "country_183": "Saint Martin (Prancūzija)",
    "country_184": "Sen Pjeras ir Mikelonas",
    "country_185": "Samoa",
    "country_186": "San Marinas",
    "country_187": "Santome ir Principe",
    "country_188": "Saudo Arabija",
    "country_189": "Senegalas",
    "country_190": "Serbija",
    "country_191": "Seišeliai",
    "country_192": "Siera Leonė",
    "country_193": "Singapūras",
    "country_194": "Slovakija",
    "country_195": "Slovėnija",
    "country_196": "Saliamono salos",
    "country_197": "Somalis",
    "country_198": "Pietų Afrika",
    "country_199": "Pietų Gruzija ir South Sandwich sala",
    "country_200": "Pietų Korėja",
    "country_201": "Pietų Sudanas",
    "country_202": "Ispanija",
    "country_203": "Šri Lanka",
    "country_204": "Šv. Elena ir priklausomybės",
    "country_205": "Sent Kitsas ir Nevis",
    "country_206": "Šv. Liucija",
    "country_207": "Sent Vincentas ir Grenadinai",
    "country_208": "Sudanas",
    "country_209": "Surinamas",
    "country_210": "Svazilandas",
    "country_211": "Švedija",
    "country_212": "Šveicarija",
    "country_213": "Sirija",
    "country_214": "Taivanas",
    "country_215": "Tadžikistanas",
    "country_216": "Tanzanija",
    "country_217": "Šablonas: šalies duomenys SJM Svalbard",
    "country_218": "Tailandas",
    "country_219": "Bahamai",
    "country_220": "Komorai",
    "country_221": "Filipinai",
    "country_222": "Rytų Timoras (Rytų Timoras)",
    "country_223": "Eiti",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidadas ir Tobagas",
    "country_227": "Tunisas",
    "country_228": "Turkija",
    "country_229": "Turkmėnistanas",
    "country_230": "Terkso ir Kaikoso salos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Jungtiniai Arabų Emyratai",
    "country_235": "Jungtinių Valstijų mažosios atokios salos",
    "country_236": "Jungtinės Amerikos Valstijos (JAV)",
    "country_237": "Jungtinių Valstijų Mergelių salos",
    "country_238": "Urugvajus",
    "country_239": "Uzbekistanas",
    "country_240": "Vanuatu",
    "country_241": "Vatikano miestas (Šventasis Sostas)",
    "country_242": "Venesuela",
    "country_243": "Vietnamas",
    "country_244": "Wallis ir Futuna",
    "country_245": "Vakarų Sachara",
    "country_246": "Jemenas",
    "country_247": "Zambija",
    "country_248": "Zimbabvė",
    "google_login": "Prisijunkite naudodami „Google“.",
    "State": "valstybė",
    "Activation_code": "Aktyvavimo kodas",
    "Question": "Išvardykite visas programas, prie kurių esate prisijungę",
    "Copy_complete": "Kopijuoti baigta",
    "footer": "Autoriaus teisės © 2024 MindOnMap Studio. Visos teisės saugomos.",
    "change_password_success": "Slaptažodis pakeistas sėkmingai",
    "successful_login_title": "Prisijungimo sėkmė",
    "product_page": "Produkto puslapis >>",
    "successful_login_info": "Prisijungimas baigtas. Uždarykite dabartinį puslapį ir grįžkite į pradinį skirtuką, kad galėtumėte tęsti toliau nurodytus veiksmus. Dabartinis puslapis bus automatiškai uždarytas po 5 sekundžių. Jei nepavyksta automatiškai uždaryti arba uždaryti spustelėjus mygtuką „Atlikta“, uždarykite šį skirtuką tiesiogiai.",
    "successful_login_info_firefox": "Prisijungimas baigtas. Uždarykite dabartinį puslapį ir grįžkite į pradinį skirtuką, kad galėtumėte tęsti toliau nurodytus veiksmus.",
    "my_account": "Mano sąskaita",
    "my_history": "Mano istorija",
    "remove_watermark": "Pašalinkite vandens ženklą",
    "no_history": "Jokios istorijos",
    "history_all": "Pasirinkti viską",
    "history_open": "Atviras",
    "history_down": "parsisiųsti",
    "history_delete": "Ištrinti",
    "history_clear": "Išvalyti neteisingą",
    "images": "vaizdai",
    "use_this_function": "Naudokite šį produktą >>",
    "hd_downloading": "HD originalių vaizdų atsisiuntimo pranašumai:",
    "lifetimeRemaining": "Likęs visą gyvenimą",
    "Remaining": "Likę",
    "email_verification": "El. pašto patvirtinimas",
    "email_verification_info": "Išsiuntėme patvirtinimo pranešimą jūsų el. pašto adresu <span class=\"email\"></span> ir užbaikite patvirtinimą. Patvirtinus privilegijos bus sinchronizuojamos automatiškai.",
    "wrong_email": "Netinkamas el. pašto adresas?",
    "click_here_to_modify": "Spustelėkite čia, kad pakeistumėte",
    "get_more_help": "Ar gauti daugiau pagalbos?",
    "click_here": "Paspauskite čia",
    "email_verification_info_success": "Sveikiname patvirtinus el. pašto paskyrą.",
    "email_verification_info_error": "Patvirtinimas nepavyko dėl pasibaigusios nuorodos.",
    "registration_succeeded": "Registracija pavyko",
    "registration_succeeded_info_1": "Sveikiname! Jūs sėkmingai užsiregistravote. Išsiuntėme patvirtinimo pranešimą jūsų el. pašto adresu <span class=\"email\"></span> ir užbaikite patvirtinimą, kad galėtumėte pasinaudoti šio el. pašto privalumais.",
    "registration_succeeded_info_2": "Spustelėkite „Atlikta“, kad grįžtumėte į pagrindinį puslapį ir naudotumėte šį produktą.",
    "registration_succeeded_info_3": "Spustelėkite „Atlikta“, kad uždarytumėte dabartinį puslapį ir grįžtumėte į pagrindinį puslapį, kad atliktumėte toliau nurodytas operacijas. Jei uždaryti nepavyksta, rankiniu būdu uždarykite skirtuką.",
    "verify_email": "Patvirtinkite elektroninį paštą",
    "registered_email_not_verified": "Užregistruotas el. pašto adresas nebuvo patvirtintas, patikrinkite jį iš karto.",
    "email_verification_time_1": "Negavote patvirtinimo el. laiško?",
    "email_verification_time_2": "Po to",
    "email_verification_time_3": "Spustelėkite čia, kad išsiųstumėte dar kartą",
    "error_26301": "Klaidos kodas: 26301, susisiekite su mumis",
    "error_26302": "Klaidos kodas: 26302, susisiekite su mumis",
    "error_26303": "El. pašto formato klaida (klaidos kodas: 26303). Įveskite dar kartą",
    "error_26304": "Rekomenduojamas ilgesnis nei 8 simbolių slaptažodis (klaidos kodas: 26304)",
    "error_26305": "Reuquest nepavyko (klaidos kodas: 26305). Bandykite dar kartą",
    "error_26306": "El. paštas užregistruotas, <a href=\"https://account.mindonmap.com/login/\">eikite ir prisijunkite</a>",
    "error_26307": "Reuquest nepavyko (klaidos kodas: 26307). Bandykite dar kartą",
    "error_26308": "Reuquest nepavyko (klaidos kodas: 26308). Bandykite dar kartą",
    "error_26401": "Klaidos kodas: 26401, bandykite dar kartą",
    "error_26402": "El. paštas buvo patvirtintas (klaidos kodas: 26402), bandykite dar kartą",
    "error_26403": "Reuquest nepavyko (klaidos kodas: 26403). Bandykite dar kartą",
    "error_26404": "Užklausa nepavyko (klaidos kodas: 26404). Bandykite dar kartą",
    "error_26501": "Klaidos kodas: 26501, susisiekite su mumis",
    "error_26502": "Klaidos kodas: 26502, susisiekite su mumis",
    "error_26503": "El. pašto formato klaida (klaidos kodas: 26503). Įveskite dar kartą",
    "error_26504": "Reuquest nepavyko (klaidos kodas: 26504). Bandykite dar kartą",
    "error_26505": "El. pašto adresas neužregistruotas, <a href=\"https://account.mindonmap.com/register/\">pirmiausia jį užregistruokite</a>",
    "error_26506": "El. paštas buvo patvirtintas.",
    "error_26507": "Reuquest nepavyko (klaidos kodas: 26507). Bandykite dar kartą",
    "error_26508": "Patvirtinimas atliktas (klaidos kodas: 26508), bandykite dar kartą",
    "error_26509": "Užklausa nepavyko (klaidos kodas: 26509), bandykite dar kartą",
    "error_26510": "Klaidos kodas: 26510, susisiekite su mumis",
    "error_26601": "Klaidos kodas: 26601, susisiekite su mumis",
    "error_26602": "Klaidos kodas: 26602, susisiekite su mumis",
    "error_26603": "Reuquest nepavyko (klaidos kodas: 26603). Bandykite dar kartą",
    "error_26604": "Klaidos kodas: 26604, susisiekite su mumis",
    "error_26605": "Klaidos kodas: 26605, susisiekite su mumis",
    "error_26701": "Klaidos kodas: 26701, susisiekite su mumis",
    "error_26702": "Reuquest nepavyko (klaidos kodas: 26702). Bandykite dar kartą",
    "error_26703": "Klaidos kodas: 26703, susisiekite su mumis",
    "error_26704": "Klaidos kodas: 26704, susisiekite su mumis",
    "error_26705": "Palaukite, kol prisijungsite (klaidos kodas: 26705). Bandykite dar kartą",
    "no_cookie": "Naršyklėje įjungėte funkciją Blokuoti visus slapukus, todėl negalite prisijungti. Eikite į nustatymus ir pažymėkite langelį Leisti visus slapukus.",
    "error_26801": "Klaidos kodas: 26801, susisiekite su mumis",
    "error_26802": "Klaidos kodas: 26802, susisiekite su mumis",
    "error_26803": "Užklausa nepavyko (klaidos kodas: 26803). Bandykite dar kartą",
    "error_26804": "Užklausa nepavyko (klaidos kodas: 26804). Bandykite dar kartą",
    "error_order": "Užklausa nepavyko (klaidos kodas: 27098), bandykite dar kartą!",
    "error_order1": "Užsakymo užklausa neužbaigta (klaidos kodas: ",
    "error_order2": "）, atnaujinkite ir bandykite dar kartą.",
    "modify_email_title": "Keisti el",
    "modify_email_info": "Norėdami prisijungti prie savo paskyros, galite naudoti pakeistą el.",
    "images_per": "Vaizdai per",
    "error_26101": "Klaida: 26101. Prašome susisiekti su mumis",
    "error_26102": "Klaida: 26102. Prašome susisiekti su mumis",
    "error_26103": "Užklausa nepavyko (klaidos kodas: 26103). Bandykite dar kartą",
    "error_26104": "Klaidos kodas: 26104, bandykite dar kartą",
    "error_26105": "Klaidos kodas: 26105, bandykite dar kartą",
    "error_26106": "Nepavyko ištrinti (klaidos kodas: 26106). Bandykite dar kartą",
    "error_26201": "Klaida: 26201. Prašome susisiekti su mumis",
    "error_26202": "Užklausa nepavyko (klaidos kodas:26202). Bandykite dar kartą",
    "error_26001": "Klaida: 26001. Susisiekite su mumis",
    "error_26002": "Klaida: 26002. Prašome susisiekti su mumis",
    "error_26003": "Klaida: 26003. Prašome susisiekti su mumis",
    "error_26004": "Klaida: 26004. Prašome susisiekti su mumis",
    "error_26005": "Užklausa nepavyko (klaidos kodas: 26005). Bandykite dar kartą",
    "error_26006": "Klaidos kodas: 26006, bandykite dar kartą",
    "error_26008": "Klaida: 26008. Prašome susisiekti su mumis",
    "go_to_the_home_page": "Eikite į pagrindinį puslapį",
    "error_27101": "Užklausa nepavyko (klaidos kodas: 27101). Bandykite dar kartą",
    "error_27201": "Klaidos kodas: 27201, susisiekite su mumis",
    "error_27202": "Klaidos kodas: 27202, bandykite dar kartą",
    "error_27203": "Užklausa nepavyko (klaidos kodas: 27203). Bandykite dar kartą",
    "error_27204": "Neteisingas kodas (klaidos kodas: 27204).",
    "error_27205": "Užklausa nepavyko (klaidos kodas: 27205). Bandykite dar kartą",
    "error_27206": "Užklausa nepavyko (klaidos kodas: 27206). Bandykite dar kartą",
    "error_27207": "Užklausa nepavyko (klaidos kodas: 27207). Bandykite dar kartą",
    "no_history_found": "Jūs nenaudojote jokio įrankio! <a href=\"/\">Atnaujinkite</a> arba <a href=\"https://www.mindonmap.com/\">Eikite į oficialią svetainę</a>",
    "error_25301": "Klaida: 25301. Prašome susisiekti su mumis",
    "error_25302": "Klaida: 25302. Prašome susisiekti su mumis",
    "error_25303": "Užklausa nepavyko (klaidos kodas: 25303). Bandykite dar kartą",
    "error_25304": "Užklausa nepavyko (klaidos kodas: 25304). Bandykite dar kartą",
    "error_25305": "Užklausa nepavyko (klaidos kodas: 25305). Bandykite dar kartą",
    "error_25306": "Užklausa nepavyko (klaidos kodas: 25306). Bandykite dar kartą",
    "image_upscaler_p": "Vaizdo be vandens ženklo atsisiuntimo pranašumai:",
    "Available_for": "Galioja:",
    "credit_per": "%s kreditų vienam HD vaizdui",
    "still_valid": "Įsigytas planas (-ai) vis dar galioja",
    "credit": "kreditas (-ai)",
    "pc_3rd_info": "Prisijunkite sėkmingai. Norėdami atlikti tolesnę veiklą, eikite į programą.",
    "use_online": "Naudokite internetinę paslaugą",
    "use_download": "Naudokite darbalaukio programą",
    "use_immediately": "Naudoti nedelsiant",
    "Use_in_browser": "Naudokite naršyklėje",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kreditų per mėnesį",
    "expire": "Galiojimo laikas:",
    "viewDetails": "Išsamiau",
    "viewHistory": "Peržiūrėkite istorijos pranašumus>>",
    "viewDetailsInfo": "Pastaba: jei prenumeratos privilegijos atnaujinamos per 7 dienas po galiojimo pabaigos, nepanaudotos privilegijos gali būti naudojamos toliau. Be to, galiojimo laikas bus automatiškai atnaujintas pagal naujos prenumeratos galiojimo laiką. Jei po 7 dienų nuo galiojimo pabaigos naujos prenumeratos nebus, visos pasibaigusios privilegijos bus panaikintos.",
    "connect_account": "Susiekite el. laišką su savo paskyra",
    "connect_account_info": "Po susiejimo galite prisijungti šiuo el. pašto adresu.",
    "connect_now": "Susieti dabar",
    "no_email_bind": "Jokio el. pašto susiejimo",
    "bind_email": "Susieti el",
    "connect_your_email_placeholder": "Įveskite savo el. pašto adresą",
    "bind_an_email": "Susieti el",
    "bind_info": "Jūs sėkmingai prisijungėte. Norėdami suaktyvinti privilegijas, susiekite el. laišką su savo paskyra.",
    "bind_later": "Surišti vėliau",
    "hi": "Sveiki, %s!",
    "Personal_Information": "Asmeninė informacija",
    "Access": "Prieiga",
    "Subscription_Plan": "(Prenumeratos planas)",
    "No_orders": "Užsakymų nerasta.",
    "No_data": "Nėra duomenų",
    "Featured_Products": "Teminiai produktai",
    "More_Products": "Daugiau produktų",
    "Free_Download": "Nemokamas atsisiuntimas",
    "Get_Started": "Pradėti",
    "Subscribe": "Prenumeruoti",
    "Verified": "Patvirtinta",
    "back_to_account_center": "Grįžti į paskyros centrą",
    "success": "Sėkmė!",
    "successfully": "Sėkmingai užregistravote paskyrą.",
    "Continue": "Tęsti",
    "Already": "Jau turite paskyrą?",
    "loading_verification": "Tikrinama patvirtinimo būsena...",
    "email_no_verification": "Atsiprašome, registruotas el. pašto adresas nebuvo patvirtintas. Užbaikite patvirtinimą pagal aukščiau pateiktas instrukcijas ir dar kartą spustelėkite mygtuką „Atlikta“, kad užbaigtumėte registraciją.",
    "will_expire_after": "Nustos galioti po",
    "hours": "valandų",
  },
  "lv": {
    "overtime": "Kļūdas kods: {%}, lūdzu, mēģiniet vēlreiz",
    "isnetwork": "Interneta kļūda. Lūdzu, pārbaudiet un mēģiniet vēlreiz",
    "email_placeholder": "E-pasts",
    "email_empty": "Lūdzu, ievadiet e-pastu",
    "email_not_valid": "E-pasts nav derīgs",
    "email_not_valid_1": "Lūdzu, ievadiet savu e-pastu",
    "email_not_valid_2": "E-pasts nav derīgs, lūdzu, izmantojiet citu adresi.",
    "email_not_valid_3": "Nav e-pasta ievades",
    "password_placeholder": "Parole",
    "password_empty": "Lūdzu, ievadiet paroli",
    "password_not_valid": "Nepareizs konts vai parole",
    "password_not_valid_1": "Nepieciešama parole, kas garāka par 8 rakstzīmēm",
    "password_not_valid_2": "Lūdzu, izveidojiet paroli",
    "password_placeholder_1": "Izveidojiet savu paroli",
    "password_placeholder_2": "Apstipriniet savu paroli",
    "password_placeholder_3": "Izveidojiet jaunu paroli",
    "password_placeholder_4": "Apstipriniet jauno paroli",
    "password_placeholder_5": "Ievadiet veco paroli",
    "copy_password_empty": "Lūdzu, apstipriniet paroli",
    "copy_password_not_valid": "Lūdzu, apstipriniet savu paroli",
    "copy_passwords_inconsistent": "Jūsu paroles apstiprinājums neatbilst",
    "code_empty": "Lūdzu, ievadiet verifikācijas kodu",
    "code_not_valid": "Nederīgs verifikācijas kods",
    "code_placeholder": "Verifikācijas kods",
    "not_received_code": "Ja jūsu pastkaste ilgu laiku nav saņēmusi verifikācijas kodu, lūdzu, saņemiet verifikācijas kodu vēlreiz.",
    "get_first_code": "Lūdzu, vispirms saņemiet verifikācijas kodu.",
    "last_name_placeholder": "Lūdzu, ievadiet savu uzvārdu",
    "first_name_placeholder": "Lūdzu, ievadiet savu vārdu",
    "address_placeholder": "Lūdzu, ievadiet savu adresi",
    "no_code_text": "Esam nosūtījuši verifikācijas kodu. Lūdzu, ievadiet savu kodu. <span class='tips'>Vai nesaņēmāt kodu?",
    "no_code_text_1": "1. Lūdzu, pārbaudiet, vai e-pasta adrese ir derīga un tā var saņemt e-pasta ziņojumus.",
    "no_code_text_2": "2. Ja sistēma e-pasta ziņojumu nosūta automātiski, tas var būt atzīmēts kā surogātpasts vai nevēlamais e-pasts. Lūdzu, pārbaudiet, vai e-pasts atrodas mapē Miskaste.",
    "no_code_text_3": "3. Vai nevarat atrisināt savu problēmu? ",
    "no_code_text_3_span": "Pēc tam noklikšķiniet šeit, lai sazinātos ar mums.",
    "order_no": "Jūs neesat iegādājies nevienu produktu. Ja jums ir kādi jautājumi, lūdzu, <a href=\"https://www.mindonmap.com/contact-us/\">sazinieties ar mums</a>.",
    "error_24901": "Pašreizējam kontam nav piesaistīta e-pasta adrese, un tas nevar atrast pasūtījumus. Lūdzu, pievienojiet e-pastu.",
    "user_guide": "Lietotāja rokasgrāmata >>",
    "download": "Lejupielādēt",
    "order_number": "Pasūtījuma numurs:",
    "Refund": "Atmaksa",
    "Disabled": "Atspējots",
    "Normal": "Normāls",
    "Modify": "Modificēt",
    "Modify_1": "Modificēt >>",
    "Connect": "Savienot",
    "unlink_success": "Veiksmīgi atsaistīt",
    "connect_success": "Veiksmīgi izveidot savienojumu",
    "feedback_title": "Paldies par jūsu atsauksmi. Lūdzu, atstājiet savu problēmu, un mēs jums atbildēsim 24 stundu laikā.",
    "feedback_thank_you": "Paldies!<br />Jūsu atsauksmes ir veiksmīgi iesniegtas.",
    "feedback_email": "Ievadi savu e-pastu šeit!",
    "feedback_content": "Atstājiet šeit visas problēmas vai ieteikumus.",
    "feedback_submit": "Iesniegt",
    "form_contents": "Jūs neesat ievadījis aprakstu. Lūdzu, ievadiet to un iesniedziet vēlreiz.",
    "old_password": "Lūdzu, ievadiet veco paroli",
    "new_password": "Lūdzu, izveidojiet jaunu paroli",
    "old_new_password": "Jaunā parole nevar būt tāda pati kā vecā",
    "incorrect_password": "nepareiza parole",
    "delete_no": "Dzēst tūlīt",
    "Caps": "Caps lock ir ieslēgts",
    "Get": "gūt",
    "Done": "Gatavs",
    "error_20001": "Kļūda: 20001. Lūdzu, piesakieties vēlreiz.",
    "error_20002": "Kļūda: 20002. Lūdzu, piesakieties vēlreiz.",
    "error_20003": "Kļūda: 20003. Lūdzu, piesakieties vēlreiz.",
    "error_20004": "Pieprasījums neizdevās (kļūdas kods: 20004). Lūdzu, mēģiniet vēlreiz.",
    "error_20005": "Pieteikšanās sesija ir beigusies (kļūda: 20005). Lūdzu, piesakieties vēlreiz.",
    "error_20006": "Pieprasījums neizdevās (kļūdas kods: 20006). Lūdzu, mēģiniet vēlreiz.",
    "error_20007": "Pieteikšanās sesija ir beigusies (kļūda: 20007). Lūdzu, piesakieties vēlreiz.",
    "error_20008": "Pieteikšanās sesija ir beigusies (kļūda: 20008). Lūdzu, piesakieties vēlreiz.",
    "error_20009": "Pieteikšanās sesija ir beigusies (kļūda: 20009). Lūdzu, piesakieties vēlreiz.",
    "error_20101": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20101)",
    "error_20102": "E-pasts nav derīgs (kļūdas kods: 20102)",
    "error_20103": "Pieprasījums neizdevās (kļūdas kods: 20103). Lūdzu, mēģiniet vēlreiz",
    "error_20104": "E-pasts jau tiek izmantots, lūdzu, <a href=\"https://account.mindonmap.com/login/\">piesakieties</a> vai reģistrējieties ar jaunu",
    "error_20105": "Pieprasījums neizdevās (kļūdas kods: 20105). Lūdzu, mēģiniet vēlreiz",
    "error_20106": "Neizdevās nosūtīt e-pastu, lūdzu, mēģiniet vēlreiz",
    "error_20201": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20201)",
    "error_20202": "Lūdzu, ievadiet savu paroli (kļūdas kods: 20202)",
    "error_20203": "Lūdzu, ievadiet verifikācijas kodu (kļūdas kods: 20203)",
    "error_20204": "E-pasts nav derīgs (kļūdas kods: 20204)",
    "error_20205": "Nepieciešama parole, kas garāka par 8 rakstzīmēm (kļūdas kods: 20205)",
    "error_20206": "Pieprasījums neizdevās (kļūdas kods: 20206). Lūdzu, mēģiniet vēlreiz",
    "error_20207": "Nederīgs verifikācijas kods",
    "error_20208": "Pieprasījums neizdevās (kļūdas kods: 20208). Lūdzu, mēģiniet vēlreiz",
    "error_20209": "Pieprasījums neizdevās (kļūdas kods: 20209). Lūdzu, mēģiniet vēlreiz",
    "error_20301": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20301)",
    "error_20302": "Kļūda: 20302. Lūdzu, sazinieties ar mums",
    "error_20303": "E-pasts nav derīgs (kļūdas kods: 20303)",
    "error_20304": "Pieprasījums neizdevās (kļūdas kods: 20304). Lūdzu, mēģiniet vēlreiz",
    "error_20305": "Konts nepastāv. Lūdzu, ievadiet vēlreiz vai vispirms <a href=\"https://account.mindonmap.com/register/\">izveidojiet to</a>.",
    "error_20306": "Paroles vēl nav. Izmantojiet <a href=\"https://account.mindonmap.com/passwordless-login/\">pieteikšanos bez paroles</a> vai <a href=\"https://account.mindonmap.com/create-password/\"> iestatiet paroli</a> un piesakieties.",
    "error_20308": "Pieprasījums neizdevās (kļūdas kods: 20308). Lūdzu, mēģiniet vēlreiz",
    "error_20401": "Neizdevās atteikties (kļūdas kods: 20401). Lūdzu, mēģiniet vēlreiz",
    "error_20501": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20501)",
    "error_20502": "E-pasts nav derīgs (kļūdas kods: 20502)",
    "error_20503": "Pieprasījums neizdevās (kļūdas kods: 20503). Lūdzu, mēģiniet vēlreiz",
    "error_20504": "Neizdevās nosūtīt e-pastu. Lūdzu, mēģiniet vēlreiz.",
    "error_20601": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20601)",
    "error_20602": "Lūdzu, ievadiet verifikācijas kodu (kļūdas kods: 20602)",
    "error_20603": "E-pasts nav derīgs (kļūdas kods: 20603)",
    "error_20604": "Pieprasījums neizdevās (kļūdas kods: 20604). Lūdzu, mēģiniet vēlreiz",
    "error_20606": "Pieprasījums neizdevās (kļūdas kods: 20606). Lūdzu, mēģiniet vēlreiz",
    "error_20607": "Pieprasījums neizdevās (kļūdas kods: 20607). Lūdzu, mēģiniet vēlreiz",
    "error_20608": "Pieprasījums neizdevās (kļūdas kods: 20608). Lūdzu, mēģiniet vēlreiz",
    "error_20701": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20701)",
    "error_20702": "E-pasts nav derīgs (kļūdas kods: 20702)",
    "error_20703": "Pieprasījums neizdevās (kļūdas kods: 20703). Lūdzu, mēģiniet vēlreiz",
    "error_20704": "Konts nepastāv. Lūdzu, ievadiet vēlreiz vai vispirms <a href=\"https://account.mindonmap.com/register/\">izveidojiet to</a>.",
    "error_20705": "Pieprasījums neizdevās (kļūdas kods: 20705). Lūdzu, mēģiniet vēlreiz",
    "error_20706": "Neizdevās nosūtīt e-pastu. Lūdzu, mēģiniet vēlreiz",
    "error_20801": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 20801)",
    "error_20802": "Kļūda: 20802. Lūdzu, sazinieties ar mums",
    "error_20803": "Lūdzu, ievadiet verifikācijas kodu (kļūdas kods: 20803)",
    "error_20804": "E-pasts nav derīgs (kļūdas kods: 20804)",
    "error_20805": "Nepieciešama parole, kas garāka par 8 rakstzīmēm (kļūdas kods: 20805)",
    "error_20806": "Pieprasījums neizdevās (kļūdas kods: 20806). Lūdzu, mēģiniet vēlreiz",
    "error_20808": "Pieprasījums neizdevās (kļūdas kods: 20808). Lūdzu, mēģiniet vēlreiz",
    "error_20901": "Pieprasījums neizdevās (kļūdas kods: 20901). Lūdzu, mēģiniet vēlreiz",
    "error_20902": "Pieprasījums neizdevās (kļūdas kods: 20902). Lūdzu, mēģiniet vēlreiz",
    "error_21000": "Izmaiņas tiek saglabātas",
    "error_21001": "Informācija nav iesniegta (kļūdas kods: 21001)",
    "error_21002": "Pieprasījums neizdevās (kļūdas kods: 21002). Lūdzu, mēģiniet vēlreiz",
    "error_21101": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 21101)",
    "error_21102": "E-pasts nav derīgs (kļūdas kods: 21102)",
    "error_21103": "Pieprasījums neizdevās (kļūdas kods: 21103), lūdzu, mēģiniet vēlreiz",
    "error_21104": "E-pasts jau ir pievienots, lūdzu, izmantojiet jaunu",
    "error_21105": "Pieprasījums neizdevās (kļūdas kods: 21105), lūdzu, mēģiniet vēlreiz",
    "error_21106": "Neizdevās nosūtīt e-pastu. Lūdzu, mēģiniet vēlreiz",
    "error_21201": "Lūdzu, ievadiet savu e-pastu (kļūdas kods: 21201)",
    "error_21202": "Lūdzu, ievadiet verifikācijas kodu (kļūdas kods: 21202)",
    "error_21203": "E-pasts nav derīgs (kļūdas kods: 21203)",
    "error_21204": "Kļūda: 21204. Lūdzu, sazinieties ar mums",
    "error_21205": "Kļūda: 21205. Lūdzu, sazinieties ar mums",
    "error_21206": "Nepieciešama parole, kas garāka par 8 rakstzīmēm (kļūdas kods: 21206)",
    "error_21207": "Pieprasījums neizdevās (kļūdas kods: 21207). Lūdzu, mēģiniet vēlreiz",
    "error_21209": "Pieprasījums neizdevās (kļūdas kods: 21209). Lūdzu, mēģiniet vēlreiz",
    "error_21301": "Lūdzu, ievadiet veco paroli (kļūdas kods: 21301)",
    "error_21302": "Lūdzu, izveidojiet jaunu paroli (kļūdas kods: 21302)",
    "error_21303": "Jaunā parole nevar būt tāda pati kā vecā. (Kļūda: 21303)",
    "error_21304": "Nepieciešama parole, kas garāka par 8 rakstzīmēm (kļūdas kods: 21304)",
    "error_21306": "Pieprasījums neizdevās (kļūdas kods: 21306). Lūdzu, mēģiniet vēlreiz",
    "error_21402": "Pieprasījums neizdevās (kļūdas kods: 21402). Lūdzu, mēģiniet vēlreiz",
    "error_21403": "Neizdevās nosūtīt verifikācijas kodu. Lūdzu, nosūtiet to vēlreiz",
    "error_21500": "Konts ir dzēsts",
    "error_21501": "Lūdzu, ievadiet verifikācijas kodu (kļūdas kods: 21501)",
    "error_21502": "Pieteikšanās sesija ir beigusies (kļūda: 21502). Lūdzu, piesakieties vēlreiz.",
    "error_21503": "Pieprasījums neizdevās (kļūdas kods: 21503). Lūdzu, mēģiniet vēlreiz",
    "error_21505": "Pieprasījums neizdevās (kļūdas kods: 21505), lūdzu, mēģiniet to vēlreiz",
    "error_21601": "Kļūda: 20601. Lūdzu, sazinieties ar mums",
    "error_21602": "Nederīga verifikācija (kļūda: 20602). Lūdzu, mēģiniet vēlreiz.",
    "error_21603": "Kļūda: 20603. Lūdzu, mēģiniet vēlreiz",
    "error_21604": "Pieprasījums neizdevās (kļūdas kods: 21604). Lūdzu, mēģiniet vēlreiz",
    "error_21606": "Pieprasījums neizdevās (kļūdas kods: 21606). Lūdzu, mēģiniet vēlreiz",
    "error_21611": "Pieprasījums neizdevās (kļūdas kods: 21611). Lūdzu, mēģiniet vēlreiz",
    "error_21801": "Kļūda: 21801. Lūdzu, sazinieties ar mums",
    "error_21802": "Pieprasījums neizdevās (kļūda: 21802). Lūdzu, mēģiniet vēlreiz",
    "error_21803": "Kļūda: 21803. Lūdzu, mēģiniet vēlreiz",
    "error_21804": "Pieprasījums neizdevās (kļūdas kods: 21804). Lūdzu, mēģiniet vēlreiz",
    "error_21806": "Kļūda: 21806. Lūdzu, mēģiniet vēlreiz",
    "error_21807": "Kļūda: 21807. Lūdzu, sazinieties ar mums",
    "error_21808": "Kļūda: 21808. Lūdzu, sazinieties ar mums",
    "error_21809": "Kļūda: 21809. Lūdzu, sazinieties ar mums",
    "error_21810": "Kļūda: 21810. Lūdzu, sazinieties ar mums",
    "error_21811": "Kļūda: 21811. Lūdzu, sazinieties ar mums",
    "error_21812": "Kļūda: 21812. Lūdzu, sazinieties ar mums",
    "error_21813": "Pieprasījums neizdevās (kļūdas kods: 21813). Lūdzu, mēģiniet vēlreiz",
    "error_21814": "Kļūda: 21814. Lūdzu, sazinieties ar mums",
    "error_21815": "Pieprasījums neizdevās (kļūdas kods: 21815). Lūdzu, mēģiniet vēlreiz",
    "error_21816": "Kļūda: 21816. Lūdzu, sazinieties ar mums",
    "error_21817": "Kļūda: 21817. Lūdzu, sazinieties ar mums",
    "error_21818": "Kļūda: 21818. Lūdzu, sazinieties ar mums",
    "error_21819": "Pieprasījums neizdevās (kļūdas kods: 21819). Lūdzu, mēģiniet vēlreiz",
    "error_21820": "Kļūda: 21820. Lūdzu, sazinieties ar mums",
    "error_21821": "Kļūda: 21821. Lūdzu, sazinieties ar mums",
    "error_21822": "Kļūda: 21822. Lūdzu, sazinieties ar mums",
    "error_21823": "Pieprasījums neizdevās (kļūdas kods: 21823). Lūdzu, mēģiniet vēlreiz",
    "error_21824": "Pieprasījums neizdevās (kļūdas kods: 21824). Lūdzu, mēģiniet vēlreiz",
    "error_21825": "Pieprasījums neizdevās (kļūdas kods: 21825). Lūdzu, mēģiniet vēlreiz",
    "error_21826": "Pieprasījums neizdevās (kļūdas kods: 21826). Lūdzu, mēģiniet vēlreiz",
    "error_21901": "Kļūda: 21901. Lūdzu, sazinieties ar mums",
    "error_21902": "Pieprasījums neizdevās (kļūdas kods: 21902). Lūdzu, mēģiniet vēlreiz",
    "error_21903": "Konta statuss ir mainījies (kļūdas kods: 21903), lūdzu, atsvaidziniet lapu un mēģiniet vēlreiz",
    "error_21904": "Kļūda: 21904. Lūdzu, mēģiniet vēlreiz",
    "error_21905": "Kļūda: 21905. Lūdzu, mēģiniet vēlreiz",
    "error_21906": "Pieprasījums neizdevās (kļūdas kods: 21906). Lūdzu, mēģiniet vēlreiz",
    "error_21907": "Google konts ir saistīts ar citu kontu",
    "error_21908": "Pieprasījums neizdevās (kļūdas kods: 21908). Lūdzu, mēģiniet vēlreiz",
    "error_22001": "Pieprasījums neizdevās (kļūdas kods: 22001). Lūdzu, mēģiniet vēlreiz",
    "error_22002": "Atsaistīt neizdevās bez papildu pieteikšanās",
    "error_22003": "Pieprasījums neizdevās (kļūdas kods: 22003). Lūdzu, mēģiniet vēlreiz",
    "error_22101": "Kļūda: 22101. Lūdzu, sazinieties ar mums",
    "error_22102": "Konta statuss ir mainījies (kļūdas kods: 22102), lūdzu, atsvaidziniet lapu un mēģiniet vēlreiz",
    "error_22103": "Pieprasījums neizdevās (kļūdas kods: 22103). Lūdzu, mēģiniet vēlreiz",
    "error_22104": "Konta statuss ir mainījies (kļūdas kods: 22104), lūdzu, atsvaidziniet lapu un mēģiniet vēlreiz",
    "error_22105": "Kļūda: 22105. Lūdzu, mēģiniet vēlreiz",
    "error_22106": "Kļūda: 22106. Lūdzu, mēģiniet vēlreiz",
    "error_22107": "Kļūda: 22107. Lūdzu, sazinieties ar mums",
    "error_22108": "Pieprasījums neizdevās (kļūdas kods: 22108). Lūdzu, mēģiniet vēlreiz",
    "error_22201": "Kļūda: 22201. Lūdzu, sazinieties ar mums",
    "error_22202": "Nederīga verifikācija (kļūda: 22202). Lūdzu, mēģiniet vēlreiz.",
    "error_22203": "Kļūda: 22203. Lūdzu, mēģiniet vēlreiz.",
    "error_22204": "Pieprasījums neizdevās (kļūdas kods: 22204). Lūdzu, mēģiniet vēlreiz",
    "error_22206": "Pieprasījums neizdevās (kļūdas kods: 22206). Lūdzu, mēģiniet vēlreiz",
    "error_22401": "Kļūda: 22401. Lūdzu, sazinieties ar mums",
    "error_22402": "Nederīga verifikācija (kļūda: 22402). Lūdzu, mēģiniet vēlreiz.",
    "error_22403": "Kļūda: 22403. Lūdzu, mēģiniet vēlreiz",
    "error_22404": "Pieprasījums neizdevās (kļūdas kods: 22404). Lūdzu, mēģiniet vēlreiz",
    "error_22405": "Facebook konts ir saistīts ar citu e-pastu",
    "error_22406": "Kļūda: 22406. Lūdzu, mēģiniet vēlreiz",
    "error_22407": "Kļūda: 22407. Lūdzu, sazinieties ar mums",
    "error_22408": "Kļūda: 22408. Lūdzu, sazinieties ar mums",
    "error_22409": "Kļūda: 22409. Lūdzu, sazinieties ar mums",
    "error_22410": "Kļūda: 224010. Lūdzu, sazinieties ar mums",
    "error_22411": "Kļūda: 224011. Lūdzu, sazinieties ar mums",
    "error_22412": "Kļūda: 224012. Lūdzu, sazinieties ar mums",
    "error_22413": "Kļūda: 22413. Lūdzu, sazinieties ar mums",
    "error_22414": "Pieprasījums neizdevās (kļūdas kods: 22414). Lūdzu, mēģiniet vēlreiz",
    "error_22415": "Kļūda: 22415. Lūdzu, sazinieties ar mums",
    "error_22416": "Kļūda: 22416. Lūdzu, sazinieties ar mums",
    "error_22417": "Kļūda: 22417. Lūdzu, sazinieties ar mums",
    "error_22418": "Pieprasījums neizdevās (kļūdas kods: 22418). Lūdzu, mēģiniet vēlreiz",
    "error_22419": "Kļūda: 22419. Lūdzu, sazinieties ar mums",
    "error_22420": "Kļūda: 22420. Lūdzu, sazinieties ar mums",
    "error_22421": "Kļūda: 22421. Lūdzu, sazinieties ar mums",
    "error_22422": "Pieprasījums neizdevās (kļūdas kods: 22422). Lūdzu, mēģiniet vēlreiz",
    "error_22423": "Pieprasījums neizdevās (kļūdas kods: 22423). Lūdzu, mēģiniet vēlreiz",
    "error_22424": "Pieprasījums neizdevās (kļūdas kods: 22424). Lūdzu, mēģiniet vēlreiz",
    "error_22425": "Pieprasījums neizdevās (kļūdas kods: 22425). Lūdzu, mēģiniet vēlreiz",
    "error_20098": "Kļūdas kods: 20098. Ja pārlūkojat privātajā režīmā, lūdzu, pārslēdzieties uz parasto režīmu un mēģiniet vēlreiz.",
    "error_22298": "Pieprasījums Google neizdevās (kļūda: 22298). Lūdzu, mēģiniet vēlreiz.",
    "error_22498": "Facebook pieprasījums neizdevās (kļūda: 22498). Lūdzu, mēģiniet vēlreiz.",
    "error_24902": "Pieprasījums neizdevās (kļūdas kods: 24902). Lūdzu, mēģiniet vēlreiz",
    "error_24903": "Pieprasījums neizdevās (kļūdas kods: 24903). Lūdzu, mēģiniet vēlreiz",
    "error_24904": "Pieprasījums neizdevās (kļūdas kods: 24904). Lūdzu, mēģiniet vēlreiz",
    "error_24905": "Pieprasījums neizdevās (kļūdas kods: 24905). Lūdzu, mēģiniet vēlreiz",
    "login_title": "Piesakieties MindOnMap",
    "log_in": "Pieslēgties",
    "no_account": "Nav konta?",
    "create_it": "Izveidojiet to",
    "or_log_in_with": "Vai arī piesakieties ar",
    "passwordless_login": "Pieteikšanās bez paroles",
    "log_in_done": "Pieteikšanās Gatavs",
    "three_rd_account_connect_info": "Apsveicam! Jūs esat veiksmīgi pieteicies. Tagad varat pievienot vienu e-pasta kontu, kas tiks izmantots, lai pieteiktos turpmāk.",
    "see_my_account": "Skatīt manu kontu",
    "three_rd_login_merge_account": "Trešās puses konta e-pasta adrese ir reģistrēta. Vai vēlaties tieši izveidot savienojumu un pieteikties ar šo e-pasta adresi?",
    "connect_log_in": "Izveidojiet savienojumu un piesakieties",
    "create_an_account": "Izveidot kontu",
    "back_to_log_in": "Atpakaļ, lai pieteiktos",
    "create_password": "Izveidot paroli",
    "create_now": "Izveidot tūlīt",
    "password_login_subtitle": "Bezparoles pieteikšanās ar e-pastu",
    "account_login": "Pieteikšanās kontā",
    "rights": "Izveidojot šo kontu, jūs piekrītat <a href=\"https://www.mindonmap.com/terms-and-conditions/\">pakalpojumu sniegšanas noteikumiem</a> un <a href=\"https://www.mindonmap.com/privacy-policy/\">Konfidencialitātes politika</a>",
    "passwordless_login_done": "Bezparoles pieteikšanās veikta",
    "passwordless_login_info": "Apsveicam! Jūs esat veiksmīgi pabeidzis bezparoles pieteikšanos. Šim kontam varat izveidot paroli un turpmāk pieteikties ar kontu un paroli. <a href=\"/create-password\" style=\"display: initial;\">Izveidot tūlīt</a>",
    "sign_up": "Pierakstīties",
    "register_info": "izveido savu kontu",
    "reset_now": "Atiestatīt tūlīt",
    "forgot_password": "Aizmirsi paroli",
    "reset_password_subtitle": "Izmantojiet sava konta e-pastu, lai atiestatītu paroli",
    "plan_products": "Plāni un produkti",
    "nick_name": "Lietotājvārds:",
    "email": "E-pasts:",
    "my_products": "Mani produkti",
    "my_orders": "Mani pasūtījumi",
    "unlink": "Atsaistīt",
    "link": "Saite",
    "connected_accounts": "Saistītie konti",
    "last_name": "Uzvārds:",
    "first_name": "Vārds:",
    "Gender": "Dzimums:",
    "Birth": "Dzimšana:",
    "Month": "Mēnesis",
    "Year": "gads",
    "Country_Region": "Valsts/reģions:",
    "Address": "Adrese:",
    "Save": "Saglabāt",
    "Date": "Datums",
    "Male": "Vīrietis",
    "Female": "Sieviete",
    "Unspecified": "Nenoteikts",
    "Security": "Drošība",
    "change_password": "Mainīt paroli",
    "change_now": "Mainīt tūlīt",
    "connect_email": "Pievienojiet e-pastu",
    "delete_account": "Dzēst kontu",
    "delete_account_info": "Kad jūsu konts tiks dzēsts, visi ar jūsu kontu saistītie dati sadaļā Mans konts tiks neatgriezeniski dzēsti, un jūs, iespējams, nevarēsit tos atgūt. Mēs iesakām braukt piesardzīgi.",
    "Delete": "Dzēst",
    "Logout": "Izlogoties",
    "my_profile": "Mans profils",
    "guides_faqs": "Ceļveži un FAQ",
    "More": "Vairāk",
    "guides": "Ceļveži",
    "register": "reģistrēties",
    "hot_faq": "Karsti FAQ",
    "Contents": "Saturs:",
    "contact_us": "Sazinieties ar mums >>",
    "plan": "Plāns",
    "unregistered": "Nereģistrēts",
    "buy_more": "Pērciet vairāk",
    "buy_again": "Pērciet vēlreiz",
    "buy_now": "Pērc tagad",
    "free_no_limit": "Bezmaksas un bez ierobežojumiem",
    "expired": "Beidzies derīguma termiņš",
    "lifetime": "Mūžs",
    "remain": "Paliec",
    "day_s": "diena(s)",
    "error_24801": "Pieprasījums neizdevās (kļūdas kods: 24801). Lūdzu, mēģiniet vēlreiz",
    "no_app_found": "Nav atrasta neviena lietotne!<a href=\"/\">Atsvaidziniet</a> vai <a href=\"https://www.mindonmap.com/\">Atveriet oficiālo vietni</a>.",
    "get_more": "Iegūstiet vairāk >>",
    "edit_photo": "Rediģēt fotoattēlu",
    "select_photo": "Izvēlieties fotoattēlu",
    "change_photo": "Mainīt bildi",
    "cancel": "Atcelt",
    "hide_password": "Slēpt paroli",
    "show_password": "Rādīt paroli",
    "zoom_in": "Pietuvināt",
    "zoom_out": "Attālināt",
    "rotate": "Pagriezt",
    "horizontal_flip": "Horizontāls flip",
    "vertical_flip": "Vertikāls flip",
    "country": "Valsts",
    "country_1": "Atlasiet savu valsti/reģionu",
    "country_2": "Ālandes sala",
    "country_3": "Afganistāna",
    "country_4": "Albānija",
    "country_5": "Alžīrija",
    "country_6": "Amerikāņu Samoa",
    "country_7": "Andora",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktīda",
    "country_11": "Antigva un Barbuda",
    "country_12": "Argentīna",
    "country_13": "Armēnija",
    "country_14": "Aruba",
    "country_15": "Austrālija",
    "country_16": "Austrija",
    "country_17": "Azerbaidžāna",
    "country_18": "Bahreina",
    "country_19": "Bangladeša",
    "country_20": "Barbadosa",
    "country_21": "Baltkrievija",
    "country_22": "Beļģija",
    "country_23": "Beliza",
    "country_24": "Benina",
    "country_25": "Bermudu salas",
    "country_26": "Butāna",
    "country_27": "Bolīvija",
    "country_28": "Bosnija un Hercegovina",
    "country_29": "Botsvāna",
    "country_30": "Buvē sala",
    "country_31": "Brazīlija",
    "country_32": "Britu Indijas okeāna teritorija",
    "country_33": "Britu Virdžīnu salas",
    "country_34": "Bruneja",
    "country_35": "Bulgārija",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerūna",
    "country_40": "Kanāda",
    "country_41": "Kaboverde",
    "country_42": "Karību jūras reģiona Nīderlande",
    "country_43": "Kaimanu salas",
    "country_44": "Centrālāfrikas Republika",
    "country_45": "Čada",
    "country_46": "Čīle",
    "country_47": "Ķīna",
    "country_48": "Ziemassvētku sala",
    "country_49": "Kokosu (Kīlinga) salas",
    "country_50": "Kolumbija",
    "country_51": "Kuka salas",
    "country_52": "Kostarika",
    "country_53": "Kotdivuāra",
    "country_54": "Horvātija",
    "country_55": "Kuba",
    "country_56": "Kipra",
    "country_57": "Čehu Republika",
    "country_58": "Kongo Demokrātiskā Republika",
    "country_59": "Dānija",
    "country_60": "Džibutija",
    "country_61": "Dominika",
    "country_62": "Dominikānas republika",
    "country_63": "Ekvadora",
    "country_64": "Ēģipte",
    "country_65": "Salvadora",
    "country_66": "Ekvatoriālā Gvineja",
    "country_67": "Eritreja",
    "country_68": "Igaunija",
    "country_69": "Etiopija",
    "country_70": "Folklenda salas",
    "country_71": "Fēru salas",
    "country_72": "Mikronēzijas Federatīvās valstis",
    "country_73": "Fidži",
    "country_74": "Somija",
    "country_75": "Francija",
    "country_76": "Franču Gviāna",
    "country_77": "Franču Polinēzija",
    "country_78": "Francijas dienvidu teritorijas",
    "country_79": "Gabona",
    "country_80": "Gambija",
    "country_81": "Gruzija",
    "country_82": "Vācija",
    "country_83": "Gana",
    "country_84": "Gibraltārs",
    "country_85": "Lielbritānija (Apvienotā Karaliste; Anglija)",
    "country_86": "Grieķija",
    "country_87": "Grenlande",
    "country_88": "Grenāda",
    "country_89": "Gvadelupa",
    "country_90": "Guama",
    "country_91": "Gvatemala",
    "country_92": "Gērnsija",
    "country_93": "Gvineja",
    "country_94": "Gvineja-Bisava",
    "country_95": "Gajāna",
    "country_96": "Haiti",
    "country_97": "Hērdas sala un Makdonalda salas",
    "country_98": "Hondurasa",
    "country_99": "Honkonga",
    "country_100": "Ungārija",
    "country_101": "Islande",
    "country_102": "Indija",
    "country_103": "Indonēzija",
    "country_104": "Irāna",
    "country_105": "Irāka",
    "country_106": "Īrija",
    "country_107": "Cilvēka sala",
    "country_108": "Izraēla",
    "country_109": "Itālija",
    "country_110": "Jamaika",
    "country_111": "Japāna",
    "country_112": "Džērsija",
    "country_113": "Jordānija",
    "country_114": "Kazahstāna",
    "country_115": "Kenija",
    "country_116": "Kiribati",
    "country_117": "Kuveita",
    "country_118": "Kirgizstāna",
    "country_119": "Laosa",
    "country_120": "Latvija",
    "country_121": "Libāna",
    "country_122": "Lesoto",
    "country_123": "Libērija",
    "country_124": "Lībija",
    "country_125": "Lihtenšteina",
    "country_126": "Lietuva",
    "country_127": "Luksemburga",
    "country_128": "Makao",
    "country_129": "Madagaskara",
    "country_130": "Malāvija",
    "country_131": "Malaizija",
    "country_132": "Maldīvija",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Māršala salas",
    "country_136": "Martinika",
    "country_137": "Mauritānija",
    "country_138": "Maurīcija",
    "country_139": "Majota",
    "country_140": "Meksika",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Mongolija",
    "country_144": "Melnkalne",
    "country_145": "Montserrata",
    "country_146": "Maroka",
    "country_147": "Mozambika",
    "country_148": "Mjanma (Birma)",
    "country_149": "Namībija",
    "country_150": "Nauru",
    "country_151": "Nepāla",
    "country_152": "Nīderlande",
    "country_153": "Jaunkaledonija",
    "country_154": "Jaunzēlande",
    "country_155": "Nikaragva",
    "country_156": "Nigēra",
    "country_157": "Nigērija",
    "country_158": "Niue",
    "country_159": "Norfolkas sala",
    "country_160": "Ziemeļkoreja",
    "country_161": "Ziemeļu Marianas salas",
    "country_162": "Norvēģija",
    "country_163": "Omāna",
    "country_164": "Pakistāna",
    "country_165": "Palau",
    "country_166": "palestīniešu teritorijas",
    "country_167": "Panama",
    "country_168": "Papua Jaungvineja",
    "country_169": "Paragvaja",
    "country_170": "Peru",
    "country_171": "Pitkērnas salas",
    "country_172": "Polija",
    "country_173": "Portugāle",
    "country_174": "Puertoriko",
    "country_175": "Katara",
    "country_176": "Maķedonijas Republika (FYROM)",
    "country_177": "Kongo Republika",
    "country_178": "Reinjona",
    "country_179": "Rumānija",
    "country_180": "Krievijas Federācija",
    "country_181": "Ruanda",
    "country_182": "Svētā Bartelemija",
    "country_183": "Saint Martin (Francija)",
    "country_184": "Senpjēra un Mikelona",
    "country_185": "Samoa",
    "country_186": "Sanmarīno",
    "country_187": "Santome un Prinsipa",
    "country_188": "Saūda Arābija",
    "country_189": "Senegāla",
    "country_190": "Serbija",
    "country_191": "Seišelu salas",
    "country_192": "Sjerraleone",
    "country_193": "Singapūra",
    "country_194": "Slovākija",
    "country_195": "Slovēnija",
    "country_196": "Zālamana salas",
    "country_197": "Somālija",
    "country_198": "Dienvidāfrika",
    "country_199": "Dienvidu Gruzija un South Sendwich Isla",
    "country_200": "Dienvidkoreja",
    "country_201": "Dienvidsudāna",
    "country_202": "Spānija",
    "country_203": "Šrilanka",
    "country_204": "Sv. Elēna un atkarības",
    "country_205": "Sentkitsa un Nevisa",
    "country_206": "Sentlūsija",
    "country_207": "Sentvinsenta un Grenadīnas",
    "country_208": "Sudāna",
    "country_209": "Surinama",
    "country_210": "Svazilenda",
    "country_211": "Zviedrija",
    "country_212": "Šveice",
    "country_213": "Sīrija",
    "country_214": "Taivāna",
    "country_215": "Tadžikistāna",
    "country_216": "Tanzānija",
    "country_217": "Veidne: valsts dati SJM Svalbard",
    "country_218": "Taizeme",
    "country_219": "Bahamu salas",
    "country_220": "Komoru salas",
    "country_221": "Filipīnas",
    "country_222": "Austrumtimora (Austrumtimora)",
    "country_223": "Iet",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidāda un Tobāgo",
    "country_227": "Tunisija",
    "country_228": "Turcija",
    "country_229": "Turkmenistāna",
    "country_230": "Tērksas un Kaikosas salas",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Apvienotie Arābu Emirāti",
    "country_235": "Amerikas Savienoto Valstu mazās nomaļās salas",
    "country_236": "Amerikas Savienotās Valstis (ASV)",
    "country_237": "Amerikas Savienoto Valstu Virdžīnu salas",
    "country_238": "Urugvaja",
    "country_239": "Uzbekistāna",
    "country_240": "Vanuatu",
    "country_241": "Vatikāns (Svētais Krēsls)",
    "country_242": "Venecuēla",
    "country_243": "Vjetnama",
    "country_244": "Volisa un Futuna",
    "country_245": "Rietumsahāra",
    "country_246": "Jemena",
    "country_247": "Zambija",
    "country_248": "Zimbabve",
    "google_login": "Pierakstieties ar Google",
    "State": "Valsts",
    "Activation_code": "Aktivizācijas kods",
    "Question": "Uzskaitiet visas lietotnes, kuras esat pieteicies",
    "Copy_complete": "Kopēšana ir pabeigta",
    "footer": "Autortiesības © 2024 MindOnMap Studio. Visas tiesības aizsargātas.",
    "change_password_success": "Parole veiksmīgi nomainīta",
    "successful_login_title": "Pieteikšanās veiksme",
    "product_page": "Produkta lapa >>",
    "successful_login_info": "Pieteikšanās pabeigta. Lūdzu, aizveriet pašreizējo lapu un dodieties atpakaļ uz sākotnējo cilni, lai turpinātu tālāk norādīto procesu. Pašreizējā lapa tiks automātiski aizvērta pēc 5 sekundēm. Ja automātiskā aizvēršana vai aizvēršana, noklikšķinot uz pogas Gatavs, neizdodas, lūdzu, aizveriet šo cilni tieši.",
    "successful_login_info_firefox": "Pieteikšanās pabeigta. Lūdzu, aizveriet pašreizējo lapu un dodieties atpakaļ uz sākotnējo cilni, lai turpinātu tālāk norādīto procesu.",
    "my_account": "Mans Konts",
    "my_history": "Mana Vēsture",
    "remove_watermark": "Noņemiet ūdenszīmi",
    "no_history": "Nav Vēstures",
    "history_all": "Izvēlēties visus",
    "history_open": "Atvērt",
    "history_down": "Lejupielādēt",
    "history_delete": "Dzēst",
    "history_clear": "Notīrīt nederīgu",
    "images": "attēls(-i)",
    "use_this_function": "Izmantojiet šo produktu >>",
    "hd_downloading": "HD oriģinālo attēlu lejupielādes priekšrocības:",
    "lifetimeRemaining": "Atlikušais mūžs",
    "Remaining": "Atlikušais",
    "email_verification": "E-pasta verifikācija",
    "email_verification_info": "Mēs nosūtījām verifikācijas ziņojumu uz jūsu e-pasta adresi <span class=\"email\"></span>, un, lūdzu, pabeidziet verifikāciju. Pēc pārbaudes priekšrocības tiks automātiski sinhronizētas.",
    "wrong_email": "Nepareiza e-pasta adrese?",
    "click_here_to_modify": "Noklikšķiniet šeit, lai mainītu",
    "get_more_help": "Vai saņemt papildu palīdzību?",
    "click_here": "Noklikšķiniet šeit",
    "email_verification_info_success": "Apsveicam ar e-pasta konta verificēšanu.",
    "email_verification_info_error": "Verifikācija neizdevās, jo saite beidzās.",
    "registration_succeeded": "Reģistrācija izdevusies",
    "registration_succeeded_info_1": "Apsveicam! Jūs esat veiksmīgi reģistrējies. Mēs nosūtījām verifikācijas ziņojumu uz jūsu e-pasta adresi <span class=\"email\"></span>, un, lūdzu, pabeidziet verifikāciju, lai izmantotu šī e-pasta priekšrocības.",
    "registration_succeeded_info_2": "Noklikšķiniet uz \"Gatavs\", lai atgrieztos sākumlapā un izmantotu šo produktu.",
    "registration_succeeded_info_3": "Noklikšķiniet uz \"Gatavs\", lai aizvērtu pašreizējo lapu un atgrieztos sākumlapā, lai veiktu tālāk norādītās darbības. Ja aizvērt neizdodas, lūdzu, manuāli aizveriet cilni.",
    "verify_email": "Apstipriniet e-pastu",
    "registered_email_not_verified": "Reģistrētais e-pasts nav pārbaudīts, lūdzu, pārbaudiet to uzreiz.",
    "email_verification_time_1": "Vai nesaņēmāt verifikācijas e-pastu?",
    "email_verification_time_2": "Pēc",
    "email_verification_time_3": "Noklikšķiniet šeit, lai to atkārtoti nosūtītu",
    "error_26301": "Kļūdas kods: 26301, lūdzu, sazinieties ar mums",
    "error_26302": "Kļūdas kods: 26302, lūdzu, sazinieties ar mums",
    "error_26303": "E-pasta formāta kļūda (kļūdas kods: 26303). Lūdzu, ievadiet to vēlreiz",
    "error_26304": "Ieteicama parole, kas garāka par 8 rakstzīmēm (kļūdas kods: 26304)",
    "error_26305": "Reuquest neizdevās (kļūdas kods: 26305). Lūdzu, mēģiniet vēlreiz",
    "error_26306": "E-pasts ir reģistrēts, lūdzu, <a href=\"https://account.mindonmap.com/login/\">dodieties, lai pieteiktos</a>",
    "error_26307": "Reuquest neizdevās (kļūdas kods: 26307). Lūdzu, mēģiniet vēlreiz",
    "error_26308": "Reuquest neizdevās (kļūdas kods: 26308). Lūdzu, mēģiniet vēlreiz",
    "error_26401": "Kļūdas kods: 26401, lūdzu, mēģiniet vēlreiz",
    "error_26402": "E-pasts ir pārbaudīts (kļūdas kods: 26402), lūdzu, mēģiniet vēlreiz",
    "error_26403": "Reuquest neizdevās (kļūdas kods: 26403). Lūdzu, mēģiniet vēlreiz",
    "error_26404": "Pieprasījums neizdevās (kļūdas kods: 26404). Lūdzu, mēģiniet vēlreiz",
    "error_26501": "Kļūdas kods: 26501, lūdzu, sazinieties ar mums",
    "error_26502": "Kļūdas kods: 26502, lūdzu, sazinieties ar mums",
    "error_26503": "E-pasta formāta kļūda (kļūdas kods: 26503). Lūdzu, ievadiet to vēlreiz",
    "error_26504": "Reuquest neizdevās (kļūdas kods: 26504). Lūdzu, mēģiniet vēlreiz",
    "error_26505": "E-pasts nav reģistrēts, lūdzu, <a href=\"https://account.mindonmap.com/register/\">vispirms reģistrējiet to</a>",
    "error_26506": "E-pasts ir pārbaudīts.",
    "error_26507": "Reuquest neizdevās (kļūdas kods: 26507). Lūdzu, mēģiniet vēlreiz",
    "error_26508": "Pārbaude ir reģistrēta (kļūdas kods: 26508), lūdzu, mēģiniet vēlreiz",
    "error_26509": "Pieprasījums neizdevās (kļūdas kods: 26509), lūdzu, mēģiniet vēlreiz",
    "error_26510": "Kļūdas kods: 26510, lūdzu, sazinieties ar mums",
    "error_26601": "Kļūdas kods: 26601, lūdzu, sazinieties ar mums",
    "error_26602": "Kļūdas kods: 26602, lūdzu, sazinieties ar mums",
    "error_26603": "Reuquest neizdevās (kļūdas kods: 26603). Lūdzu, mēģiniet vēlreiz",
    "error_26604": "Kļūdas kods: 26604, lūdzu, sazinieties ar mums",
    "error_26605": "Kļūdas kods: 26605, lūdzu, sazinieties ar mums",
    "error_26701": "Kļūdas kods: 26701, lūdzu, sazinieties ar mums",
    "error_26702": "Reuquest neizdevās (kļūdas kods: 26702). Lūdzu, mēģiniet vēlreiz",
    "error_26703": "Kļūdas kods: 26703, lūdzu, sazinieties ar mums",
    "error_26704": "Kļūdas kods: 26704, lūdzu, sazinieties ar mums",
    "error_26705": "Pagaidiet, līdz piesakāties (kļūdas kods: 26705). Lūdzu, mēģiniet vēlreiz",
    "no_cookie": "Jūs savā pārlūkprogrammā esat ieslēdzis funkciju Bloķēt visus sīkfailus, tāpēc nevarat pieteikties. Lūdzu, atveriet sadaļu Iestatījumi, lai atzīmētu izvēles rūtiņu Atļaut visus sīkfailus.",
    "error_26801": "Kļūdas kods: 26801, lūdzu, sazinieties ar mums",
    "error_26802": "Kļūdas kods: 26802, lūdzu, sazinieties ar mums",
    "error_26803": "Pieprasījums neizdevās (kļūdas kods: 26803). Lūdzu, mēģiniet vēlreiz",
    "error_26804": "Pieprasījums neizdevās (kļūdas kods: 26804). Lūdzu, mēģiniet vēlreiz",
    "error_order": "Pieprasījums neizdevās (kļūdas kods: 27098), lūdzu, mēģiniet vēlreiz!",
    "error_order1": "Pasūtījuma vaicājums ir nepilnīgs (kļūdas kods: ",
    "error_order2": "）, lūdzu, atsvaidziniet un mēģiniet vēlreiz.",
    "modify_email_title": "Mainīt e-pastu",
    "modify_email_info": "Varat izmantot modificēto e-pastu, lai pieteiktos savā kontā.",
    "images_per": "Attēli per",
    "error_26101": "Kļūda: 26101. Lūdzu, sazinieties ar mums",
    "error_26102": "Kļūda: 26102. Lūdzu, sazinieties ar mums",
    "error_26103": "Pieprasījums neizdevās (kļūdas kods:26103). Lūdzu, mēģiniet vēlreiz",
    "error_26104": "Kļūdas kods: 26104, lūdzu, mēģiniet vēlreiz",
    "error_26105": "Kļūdas kods: 26105, lūdzu, mēģiniet vēlreiz",
    "error_26106": "Dzēšana neizdevās (kļūdas kods: 26106). Lūdzu, mēģiniet vēlreiz",
    "error_26201": "Kļūda: 26201. Lūdzu, sazinieties ar mums",
    "error_26202": "Pieprasījums neizdevās (kļūdas kods:26202). Lūdzu, mēģiniet vēlreiz",
    "error_26001": "Kļūda: 26001. Lūdzu, sazinieties ar mums",
    "error_26002": "Kļūda: 26002. Lūdzu, sazinieties ar mums",
    "error_26003": "Kļūda: 26003. Lūdzu, sazinieties ar mums",
    "error_26004": "Kļūda: 26004. Lūdzu, sazinieties ar mums",
    "error_26005": "Pieprasījums neizdevās (kļūdas kods:26005). Lūdzu, mēģiniet vēlreiz",
    "error_26006": "Kļūdas kods: 26006, lūdzu, mēģiniet vēlreiz",
    "error_26008": "Kļūda: 26008. Lūdzu, sazinieties ar mums",
    "go_to_the_home_page": "Dodieties uz sākumlapu",
    "error_27101": "Pieprasījums neizdevās (kļūdas kods: 27101). Lūdzu, mēģiniet vēlreiz",
    "error_27201": "Kļūdas kods: 27201, lūdzu, sazinieties ar mums",
    "error_27202": "Kļūdas kods: 27202, lūdzu, mēģiniet vēlreiz",
    "error_27203": "Pieprasījums neizdevās (kļūdas kods: 27203). Lūdzu, mēģiniet vēlreiz",
    "error_27204": "Nederīgs kods (kļūdas kods: 27204).",
    "error_27205": "Pieprasījums neizdevās (kļūdas kods: 27205). Lūdzu, mēģiniet vēlreiz",
    "error_27206": "Pieprasījums neizdevās (kļūdas kods: 27206). Lūdzu, mēģiniet vēlreiz",
    "error_27207": "Pieprasījums neizdevās (kļūdas kods: 27207). Lūdzu, mēģiniet vēlreiz",
    "no_history_found": "Jūs neesat izmantojis nevienu rīku! <a href=\"/\">Atsvaidziniet</a> vai <a href=\"https://www.mindonmap.com/\">Dodieties uz oficiālo vietni</a>",
    "error_25301": "Kļūda: 25301. Lūdzu, sazinieties ar mums",
    "error_25302": "Kļūda: 25302. Lūdzu, sazinieties ar mums",
    "error_25303": "Pieprasījums neizdevās (kļūdas kods: 25303). Lūdzu, mēģiniet vēlreiz",
    "error_25304": "Pieprasījums neizdevās (kļūdas kods: 25304). Lūdzu, mēģiniet vēlreiz",
    "error_25305": "Pieprasījums neizdevās (kļūdas kods: 25305). Lūdzu, mēģiniet vēlreiz",
    "error_25306": "Pieprasījums neizdevās (kļūdas kods: 25306). Lūdzu, mēģiniet vēlreiz",
    "image_upscaler_p": "Attēla bez ūdenszīmes lejupielādes priekšrocības:",
    "Available_for": "Pieejams:",
    "credit_per": "%s kredīts(-i) vienam HD attēlam",
    "still_valid": "Iegādātais(-ie) plāns(-i) joprojām ir spēkā",
    "credit": "kredīts(-i)",
    "pc_3rd_info": "Pieteikties veiksmīgi. Lūdzu, dodieties uz lietojumprogrammu turpmākai darbībai.",
    "use_online": "Izmantojiet tiešsaistes pakalpojumu",
    "use_download": "Izmantojiet darbvirsmas programmu",
    "use_immediately": "Izmantojiet nekavējoties",
    "Use_in_browser": "Izmantojiet pārlūkprogrammā",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kredīti mēnesī",
    "expire": "Derīguma termiņš:",
    "viewDetails": "Skatīt detaļas",
    "viewHistory": "Skatīt vēstures priekšrocības>>",
    "viewDetailsInfo": "Paziņojums: ja abonementa priekšrocības tiek atjaunotas 7 dienu laikā pēc derīguma termiņa beigām, neizmantotās priekšrocības var turpināt izmantot. Arī derīguma termiņš tiks automātiski atjaunināts līdz jaunā abonementa derīguma termiņam. Ja pēc 7 dienām pēc derīguma termiņa beigām jauna abonementa nav, visas priekšrocības, kurām beidzies derīguma termiņš, tiks dzēstas.",
    "connect_account": "Saistiet e-pastu ar savu kontu",
    "connect_account_info": "Pēc saistīšanas varat pieteikties ar šo e-pasta adresi.",
    "connect_now": "Saistīt tūlīt",
    "no_email_bind": "Nav saistīšanas ar e-pastu",
    "bind_email": "Saistīt e-pastu",
    "connect_your_email_placeholder": "Lūdzu ievadiet savu e-pasta adresi",
    "bind_an_email": "Saistīt e-pastu",
    "bind_info": "Jūs esat veiksmīgi pieteicies. Lai aktivizētu priekšrocības, lūdzu, piesaistiet savam kontam e-pasta ziņojumu.",
    "bind_later": "Iesiet vēlāk",
    "hi": "Sveiki, %s!",
    "Personal_Information": "Personīgā informācija",
    "Access": "Piekļuve",
    "Subscription_Plan": "(Abonēšanas plāns)",
    "No_orders": "Nav atrasts neviens pasūtījums.",
    "No_data": "Nav datu",
    "Featured_Products": "Piedāvātie produkti",
    "More_Products": "Vairāk produktu",
    "Free_Download": "Bezmaksas lejupielāde",
    "Get_Started": "Sāc",
    "Subscribe": "Abonēt",
    "Verified": "Pārbaudīts",
    "back_to_account_center": "Atgriezties uz kontu centru",
    "success": "Veiksmi!",
    "successfully": "Jūs esat veiksmīgi reģistrējis kontu.",
    "Continue": "Turpināt",
    "Already": "Jau ir konts?",
    "loading_verification": "Pārbauda verifikācijas statusu...",
    "email_no_verification": "Atvainojiet, reģistrētā e-pasta adrese nav pārbaudīta. Lūdzu, pabeidziet verifikāciju saskaņā ar iepriekš sniegtajiem norādījumiem un vēlreiz noklikšķiniet uz pogas \"Gatavs\", lai pabeigtu reģistrāciju.",
    "will_expire_after": "Beigsies pēc",
    "hours": "stundas",
  },
  "mk": {
    "overtime": "Код за грешка: {%}, ве молиме обидете се повторно",
    "isnetwork": "Интернет грешка. Ве молиме проверете и обидете се повторно",
    "email_placeholder": "Е-пошта",
    "email_empty": "Ве молиме внесете е-пошта",
    "email_not_valid": "Е-поштата не е валидна",
    "email_not_valid_1": "Ве молиме внесете ја вашата е-пошта",
    "email_not_valid_2": "Е-поштата не е валидна, ве молиме користете друга адреса.",
    "email_not_valid_3": "Нема внесување е-пошта",
    "password_placeholder": "Лозинка",
    "password_empty": "Ве молиме внесете лозинка",
    "password_not_valid": "Неточна сметка или лозинка",
    "password_not_valid_1": "Потребна е лозинка над 8 знаци",
    "password_not_valid_2": "Ве молиме креирајте лозинка",
    "password_placeholder_1": "Направете ја вашата лозинка",
    "password_placeholder_2": "Потврдете ја вашата лозинка",
    "password_placeholder_3": "Направете нова лозинка",
    "password_placeholder_4": "Потврди Нова лозинка",
    "password_placeholder_5": "Внесете стара лозинка",
    "copy_password_empty": "Потврдете ја лозинката",
    "copy_password_not_valid": "Ве молиме потврдете ја вашата лозинка",
    "copy_passwords_inconsistent": "Потврдата за вашата лозинка не се совпаѓа",
    "code_empty": "Внесете го кодот за потврда",
    "code_not_valid": "Неважечки код за потврда",
    "code_placeholder": "Код за верификација",
    "not_received_code": "Ако вашето поштенско сандаче не го добило кодот за потврда долго време, ве молиме повторно добијте го кодот за потврда.",
    "get_first_code": "Најпрво земете го кодот за потврда.",
    "last_name_placeholder": "Ве молиме внесете го вашето презиме",
    "first_name_placeholder": "Ве молиме внесете го вашето име",
    "address_placeholder": "Ве молиме внесете ја вашата адреса",
    "no_code_text": "Испративме код за потврда. Ве молиме внесете го вашиот код. <span class='tips'>Не добивте код?",
    "no_code_text_1": "1. Проверете дали адресата на е-пошта е валидна и дека може да прима е-пораки.",
    "no_code_text_2": "2. Бидејќи е-поштата автоматски се испраќа преку системот, може да биде означена како спам или непотребна е-пошта. Проверете дали е-поштата е во папката Ѓубре.",
    "no_code_text_3": "3. Не можете да го решите вашиот проблем? ",
    "no_code_text_3_span": "Потоа кликнете овде за да не контактирате.",
    "order_no": "Не сте купиле ниту еден производ, ако имате какви било прашања, <a href=\"https://www.mindonmap.com/contact-us/\">контактирајте не</a>.",
    "error_24901": "Тековната сметка нема поврзана е-пошта и не може да ги најде нарачките. Поврзете е-пошта.",
    "user_guide": "Упатство за употреба >>",
    "download": "Преземи",
    "order_number": "Број на нарачка:",
    "Refund": "Рефундирање",
    "Disabled": "Оневозможено",
    "Normal": "Нормално",
    "Modify": "Измени",
    "Modify_1": "Измени >>",
    "Connect": "Поврзете се",
    "unlink_success": "Успешно прекинете ја врската",
    "connect_success": "Поврзете се успешно",
    "feedback_title": "Ви благодариме за повратните информации. Ве молиме оставете го вашиот проблем и ние ќе ви одговориме во рок од 24 часа.",
    "feedback_thank_you": "Ви благодариме!<br />Вашите повратни информации се успешно испратени.",
    "feedback_email": "Внесете ја вашата е-пошта овде!",
    "feedback_content": "Оставете го секој проблем или предлог што сте го сретнале овде.",
    "feedback_submit": "Поднесете",
    "form_contents": "Немате внесено никаков опис. Ве молиме внесете го и испратете повторно.",
    "old_password": "Ве молиме внесете ја старата лозинка",
    "new_password": "Ве молиме креирајте нова лозинка",
    "old_new_password": "Новата лозинка не може да биде иста како старата",
    "incorrect_password": "Погрешна лозинка",
    "delete_no": "Избриши сега",
    "Caps": "Caps lock е вклучено",
    "Get": "Добијте",
    "Done": "Направено",
    "error_20001": "Грешка: 20001. Ве молиме најавете се повторно.",
    "error_20002": "Грешка: 20002. Ве молиме најавете се повторно.",
    "error_20003": "Грешка: 20003. Ве молиме најавете се повторно.",
    "error_20004": "Барањето не успеа (код за грешка: 20004). Ве молиме обидете се повторно.",
    "error_20005": "Сесијата за најавување е истечена (Грешка: 20005). Ве молиме најавете се повторно.",
    "error_20006": "Барањето не успеа (шифра за грешка: 20006). Ве молиме обидете се повторно.",
    "error_20007": "Сесијата за најавување е истечена (Грешка: 20007). Ве молиме најавете се повторно.",
    "error_20008": "Сесијата за најавување е истечена (Грешка: 20008). Ве молиме најавете се повторно.",
    "error_20009": "Сесијата за најавување е истечена (Грешка: 20009). Ве молиме најавете се повторно.",
    "error_20101": "Ве молиме внесете ја вашата е-пошта (код за грешка: 20101)",
    "error_20102": "Е-поштата не е валидна (код за грешка: 20102)",
    "error_20103": "Барањето не успеа (шифра за грешка: 20103). Ве молиме обидете се повторно",
    "error_20104": "Е-пошта веќе се користи, ве молиме <a href=\"https://account.mindonmap.com/login/\">најавете се</a> или регистрирајте се со нова",
    "error_20105": "Барањето не успеа (шифра за грешка: 20105). Ве молиме обидете се повторно",
    "error_20106": "Не успеа да се испрати е-пошта, ве молиме обидете се повторно",
    "error_20201": "Ве молиме внесете ја вашата е-пошта (код за грешка: 20201)",
    "error_20202": "Внесете ја вашата лозинка (код за грешка: 20202)",
    "error_20203": "Внесете го кодот за потврда (шифра за грешка: 20203)",
    "error_20204": "Е-поштата не е валидна (код за грешка: 20204)",
    "error_20205": "Потребна е лозинка над 8 знаци (код за грешка: 20205)",
    "error_20206": "Барањето не успеа (код за грешка: 20206). Ве молиме обидете се повторно",
    "error_20207": "Неважечки код за потврда",
    "error_20208": "Барањето не успеа (код за грешка: 20208). Ве молиме обидете се повторно",
    "error_20209": "Барањето не успеа (код за грешка: 20209). Ве молиме обидете се повторно",
    "error_20301": "Ве молиме внесете ја вашата е-пошта (код за грешка: 20301)",
    "error_20302": "Грешка: 20302. Ве молиме контактирајте со нас",
    "error_20303": "Е-поштата не е валидна (код за грешка: 20303)",
    "error_20304": "Барањето не успеа (шифра за грешка: 20304). Ве молиме обидете се повторно",
    "error_20305": "Сметката не постои. Ве молиме повторно внесете или <a href=\"https://account.mindonmap.com/register/\">Креирајте го</a> прво.",
    "error_20306": "Сè уште нема лозинка. Користете <a href=\"https://account.mindonmap.com/passwordless-login/\">Најава без лозинка</a> или <a href=\"https://account.mindonmap.com/create-password/\"> поставете лозинка</a> и најавете се.",
    "error_20308": "Барањето не успеа (шифра за грешка: 20308). Ве молиме обидете се повторно",
    "error_20401": "Не успеа да се одјави (код за грешка: 20401). Ве молиме обидете се повторно",
    "error_20501": "Внесете ја вашата е-пошта (код за грешка: 20501)",
    "error_20502": "Е-поштата не е валидна (код за грешка: 20502)",
    "error_20503": "Барањето не успеа (код за грешка: 20503). Ве молиме обидете се повторно",
    "error_20504": "Не успеа да се испрати е-пошта. Ве молиме обидете се повторно.",
    "error_20601": "Внесете ја вашата е-пошта (код за грешка: 20601)",
    "error_20602": "Внесете го кодот за потврда (шифра за грешка: 20602)",
    "error_20603": "Е-поштата не е валидна (код за грешка: 20603)",
    "error_20604": "Барањето не успеа (шифра за грешка: 20604). Ве молиме обидете се повторно",
    "error_20606": "Барањето не успеа (код за грешка: 20606). Ве молиме обидете се повторно",
    "error_20607": "Барањето не успеа (шифра за грешка: 20607). Ве молиме обидете се повторно",
    "error_20608": "Барањето не успеа (шифра за грешка: 20608). Ве молиме обидете се повторно",
    "error_20701": "Внесете ја вашата е-пошта (код за грешка: 20701)",
    "error_20702": "Е-поштата не е валидна (код за грешка: 20702)",
    "error_20703": "Барањето не успеа (шифра за грешка: 20703). Ве молиме обидете се повторно",
    "error_20704": "Сметката не постои. Ве молиме повторно внесете или <a href=\"https://account.mindonmap.com/register/\">Креирајте го</a> прво.",
    "error_20705": "Барањето не успеа (код за грешка: 20705). Ве молиме обидете се повторно",
    "error_20706": "Не успеа да се испрати е-пошта. Ве молиме обидете се повторно",
    "error_20801": "Ве молиме внесете ја вашата е-пошта (код за грешка: 20801)",
    "error_20802": "Грешка: 20802. Ве молиме контактирајте со нас",
    "error_20803": "Внесете го кодот за потврда (шифра за грешка: 20803)",
    "error_20804": "Е-поштата не е валидна (код за грешка: 20804)",
    "error_20805": "Потребна е лозинка над 8 знаци (код за грешка: 20805)",
    "error_20806": "Барањето не успеа (шифра за грешка: 20806). Ве молиме обидете се повторно",
    "error_20808": "Барањето не успеа (код за грешка: 20808). Ве молиме обидете се повторно",
    "error_20901": "Барањето не успеа (шифра за грешка: 20901). Ве молиме обидете се повторно",
    "error_20902": "Барањето не успеа (шифра за грешка: 20902). Ве молиме обидете се повторно",
    "error_21000": "Промените се зачувани",
    "error_21001": "Не се поднесени информации (код за грешка: 21001)",
    "error_21002": "Барањето не успеа (шифра за грешка: 21002). Ве молиме обидете се повторно",
    "error_21101": "Ве молиме внесете ја вашата е-пошта (код за грешка: 21101)",
    "error_21102": "Е-поштата не е валидна (код за грешка: 21102)",
    "error_21103": "Барањето не успеа (шифра за грешка: 21103), ве молиме обидете се повторно",
    "error_21104": "Е-поштата е веќе поврзана, ве молиме користете нова",
    "error_21105": "Барањето не успеа (код за грешка: 21105), ве молиме обидете се повторно",
    "error_21106": "Не успеа да се испрати е-пошта. Ве молиме обидете се повторно",
    "error_21201": "Ве молиме внесете ја вашата е-пошта (код за грешка: 21201)",
    "error_21202": "Внесете го кодот за потврда (шифра за грешка: 21202)",
    "error_21203": "Е-поштата не е валидна (код за грешка: 21203)",
    "error_21204": "Грешка: 21204. Ве молиме контактирајте со нас",
    "error_21205": "Грешка: 21205. Ве молиме контактирајте со нас",
    "error_21206": "Потребна е лозинка над 8 знаци (код за грешка: 21206)",
    "error_21207": "Барањето не успеа (шифра за грешка: 21207). Ве молиме обидете се повторно",
    "error_21209": "Барањето не успеа (шифра за грешка: 21209). Ве молиме обидете се повторно",
    "error_21301": "Внесете ја старата лозинка (шифра за грешка: 21301)",
    "error_21302": "Ве молиме креирајте нова лозинка (код за грешка: 21302)",
    "error_21303": "Новата лозинка не може да биде иста како старата. (Грешка: 21303)",
    "error_21304": "Потребна е лозинка над 8 знаци (код за грешка: 21304)",
    "error_21306": "Барањето не успеа (шифра за грешка: 21306). Ве молиме обидете се повторно",
    "error_21402": "Барањето не успеа (шифра за грешка: 21402). Ве молиме обидете се повторно",
    "error_21403": "Не успеа да се испрати кодот за потврда. Ве молиме испратете го повторно",
    "error_21500": "Сметката е избришана",
    "error_21501": "Внесете го кодот за потврда (код за грешка: 21501)",
    "error_21502": "Сесијата за најавување е истечена (Грешка: 21502). Ве молиме најавете се повторно.",
    "error_21503": "Барањето не успеа (шифра за грешка: 21503). Ве молиме обидете се повторно",
    "error_21505": "Барањето не успеа (шифра за грешка: 21505), ве молиме обидете се повторно",
    "error_21601": "Грешка: 20601. Ве молиме контактирајте со нас",
    "error_21602": "Неважечка потврда (Грешка: 20602). Ве молиме обидете се повторно.",
    "error_21603": "Грешка: 20603. Ве молиме обидете се повторно",
    "error_21604": "Барањето не успеа (шифра за грешка: 21604). Ве молиме обидете се повторно",
    "error_21606": "Барањето не успеа (шифра за грешка: 21606). Ве молиме обидете се повторно",
    "error_21611": "Барањето не успеа (шифра за грешка: 21611). Ве молиме обидете се повторно",
    "error_21801": "Грешка: 21801. Ве молиме контактирајте со нас",
    "error_21802": "Барањето не успеа (Грешка: 21802). Ве молиме обидете се повторно",
    "error_21803": "Грешка: 21803. Ве молиме обидете се повторно",
    "error_21804": "Барањето не успеа (шифра за грешка: 21804). Ве молиме обидете се повторно",
    "error_21806": "Грешка: 21806. Ве молиме обидете се повторно",
    "error_21807": "Грешка: 21807. Ве молиме контактирајте со нас",
    "error_21808": "Грешка: 21808. Ве молиме контактирајте со нас",
    "error_21809": "Грешка: 21809. Ве молиме контактирајте со нас",
    "error_21810": "Грешка: 21810. Ве молиме контактирајте со нас",
    "error_21811": "Грешка: 21811. Ве молиме контактирајте со нас",
    "error_21812": "Грешка: 21812. Ве молиме контактирајте со нас",
    "error_21813": "Барањето не успеа (шифра за грешка: 21813). Ве молиме обидете се повторно",
    "error_21814": "Грешка: 21814. Ве молиме контактирајте со нас",
    "error_21815": "Барањето не успеа (шифра за грешка: 21815). Ве молиме обидете се повторно",
    "error_21816": "Грешка: 21816. Ве молиме контактирајте со нас",
    "error_21817": "Грешка: 21817. Ве молиме контактирајте со нас",
    "error_21818": "Грешка: 21818. Ве молиме контактирајте не",
    "error_21819": "Барањето не успеа (шифра за грешка: 21819). Ве молиме обидете се повторно",
    "error_21820": "Грешка: 21820. Ве молиме контактирајте со нас",
    "error_21821": "Грешка: 21821. Ве молиме контактирајте со нас",
    "error_21822": "Грешка: 21822. Ве молиме контактирајте со нас",
    "error_21823": "Барањето не успеа (шифра за грешка: 21823). Ве молиме обидете се повторно",
    "error_21824": "Барањето не успеа (шифра за грешка: 21824). Ве молиме обидете се повторно",
    "error_21825": "Барањето не успеа (шифра за грешка: 21825). Ве молиме обидете се повторно",
    "error_21826": "Барањето не успеа (шифра за грешка: 21826). Ве молиме обидете се повторно",
    "error_21901": "Грешка: 21901. Ве молиме контактирајте со нас",
    "error_21902": "Барањето не успеа (шифра за грешка: 21902). Ве молиме обидете се повторно",
    "error_21903": "Статусот на сметката е променет (код за грешка: 21903), освежете ја страницата и обидете се повторно",
    "error_21904": "Грешка: 21904. Ве молиме обидете се повторно",
    "error_21905": "Грешка: 21905. Ве молиме обидете се повторно",
    "error_21906": "Барањето не успеа (шифра за грешка: 21906). Ве молиме обидете се повторно",
    "error_21907": "Сметката на Google е поврзана со друга сметка",
    "error_21908": "Барањето не успеа (шифра за грешка: 21908). Ве молиме обидете се повторно",
    "error_22001": "Барањето не успеа (шифра за грешка: 22001). Ве молиме обидете се повторно",
    "error_22002": "Прекинувањето на врската не успеа без дополнително најавување",
    "error_22003": "Барањето не успеа (шифра за грешка: 22003). Ве молиме обидете се повторно",
    "error_22101": "Грешка: 22101. Ве молиме контактирајте со нас",
    "error_22102": "Статусот на сметката е променет (код за грешка: 22102), освежете ја страницата и обидете се повторно",
    "error_22103": "Барањето не успеа (шифра за грешка: 22103). Ве молиме обидете се повторно",
    "error_22104": "Статусот на сметката е променет (код за грешка: 22104), освежете ја страницата и обидете се повторно",
    "error_22105": "Грешка: 22105. Ве молиме обидете се повторно",
    "error_22106": "Грешка: 22106. Ве молиме обидете се повторно",
    "error_22107": "Грешка: 22107. Ве молиме контактирајте со нас",
    "error_22108": "Барањето не успеа (шифра за грешка: 22108). Ве молиме обидете се повторно",
    "error_22201": "Грешка: 22201. Ве молиме контактирајте со нас",
    "error_22202": "Невалидна потврда (Грешка: 22202). Ве молиме обидете се повторно.",
    "error_22203": "Грешка: 22203. Ве молиме обидете се повторно\"",
    "error_22204": "Барањето не успеа (шифра за грешка: 22204). Ве молиме обидете се повторно",
    "error_22206": "Барањето не успеа (шифра за грешка: 22206). Ве молиме обидете се повторно",
    "error_22401": "Грешка: 22401. Ве молиме контактирајте со нас",
    "error_22402": "Невалидна потврда (Грешка: 22402). Ве молиме обидете се повторно.",
    "error_22403": "Грешка: 22403. Ве молиме обидете се повторно",
    "error_22404": "Барањето не успеа (шифра за грешка: 22404). Ве молиме обидете се повторно",
    "error_22405": "Сметката на Фејсбук е поврзана со друга е-пошта",
    "error_22406": "Грешка: 22406. Ве молиме обидете се повторно",
    "error_22407": "Грешка: 22407. Ве молиме контактирајте со нас",
    "error_22408": "Грешка: 22408. Ве молиме контактирајте со нас",
    "error_22409": "Грешка: 22409. Ве молиме контактирајте со нас",
    "error_22410": "Грешка: 224010. Ве молиме контактирајте со нас",
    "error_22411": "Грешка: 224011. Ве молиме контактирајте со нас",
    "error_22412": "Грешка: 224012. Ве молиме контактирајте со нас",
    "error_22413": "Грешка: 22413. Ве молиме контактирајте со нас",
    "error_22414": "Барањето не успеа (шифра за грешка: 22414). Ве молиме обидете се повторно",
    "error_22415": "Грешка: 22415. Ве молиме контактирајте со нас",
    "error_22416": "Грешка: 22416. Ве молиме контактирајте со нас",
    "error_22417": "Грешка: 22417. Ве молиме контактирајте со нас",
    "error_22418": "Барањето не успеа (шифра за грешка: 22418). Ве молиме обидете се повторно",
    "error_22419": "Грешка: 22419. Ве молиме контактирајте со нас",
    "error_22420": "Грешка: 22420. Ве молиме контактирајте со нас",
    "error_22421": "Грешка: 22421. Ве молиме контактирајте со нас",
    "error_22422": "Барањето не успеа (шифра за грешка: 22422). Ве молиме обидете се повторно",
    "error_22423": "Барањето не успеа (шифра за грешка: 22423). Ве молиме обидете се повторно",
    "error_22424": "Барањето не успеа (шифра за грешка: 22424). Ве молиме обидете се повторно",
    "error_22425": "Барањето не успеа (шифра за грешка: 22425). Ве молиме обидете се повторно",
    "error_20098": "Код за грешка: 20098. Ако прелистувате во Приватен режим, префрлете се во Нормален режим и обидете се повторно.",
    "error_22298": "Барањето за Google не успеа (Грешка: 22298). Ве молиме обидете се повторно.",
    "error_22498": "Барањето за Фејсбук не успеа (Грешка: 22498). Ве молиме обидете се повторно.",
    "error_24902": "Барањето не успеа (шифра за грешка: 24902). Ве молиме обидете се повторно",
    "error_24903": "Барањето не успеа (шифра за грешка: 24903). Ве молиме обидете се повторно",
    "error_24904": "Барањето не успеа (шифра за грешка: 24904). Ве молиме обидете се повторно",
    "error_24905": "Барањето не успеа (шифра за грешка: 24905). Ве молиме обидете се повторно",
    "login_title": "Најавете се на MindOnMap",
    "log_in": "Логирај Се",
    "no_account": "Нема сметка?",
    "create_it": "Создадете го",
    "or_log_in_with": "Или најавете се со",
    "passwordless_login": "Најава без лозинка",
    "log_in_done": "Најавете се Готово",
    "three_rd_account_connect_info": "Секоја чест! Успешно се најавивте. Сега можете да поврзете една сметка за е-пошта што ќе се користи за најавување во иднина.",
    "see_my_account": "Видете ја мојата сметка",
    "three_rd_login_merge_account": "Адресата за е-пошта на сметката од трета страна е регистрирана, дали сакате да се поврзете и да се најавите директно со оваа адреса на е-пошта?",
    "connect_log_in": "Поврзете се и најавете се",
    "create_an_account": "Направете сметка",
    "back_to_log_in": "Назад за да се најавите",
    "create_password": "Креирај лозинка",
    "create_now": "Креирај сега",
    "password_login_subtitle": "Најава без лозинка со е-пошта",
    "account_login": "Најава за сметка",
    "rights": "Со создавање на оваа сметка, се согласувате со <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Условите за користење</a> и <a href=\"https://www.mindonmap.com/privacy-policy/\">Политика за приватност</a>",
    "passwordless_login_done": "Најавата без лозинка е завршена",
    "passwordless_login_info": "Честитки, успешно го завршивте најавувањето без лозинка. Можете да креирате лозинка за оваа сметка и да се најавите со сметката и лозинката во иднина. <a href=\"/create-password\" style=\"display: initial;\">Креирај сега</a>",
    "sign_up": "Пријавете се",
    "register_info": "Направете ја вашата сметка",
    "reset_now": "Ресетирајте сега",
    "forgot_password": "Ја заборави лозинката",
    "reset_password_subtitle": "Користете ја е-поштата на вашата сметка за да ја ресетирате лозинката",
    "plan_products": "Планови и производи",
    "nick_name": "Корисничко име:",
    "email": "Е-пошта:",
    "my_products": "Мои производи",
    "my_orders": "Мои нарачки",
    "unlink": "Прекини се",
    "link": "Врска",
    "connected_accounts": "Поврзани сметки",
    "last_name": "Презиме:",
    "first_name": "Име:",
    "Gender": "Пол:",
    "Birth": "Раѓање:",
    "Month": "Месец",
    "Year": "година",
    "Country_Region": "Земја/Регион:",
    "Address": "Адреса:",
    "Save": "Зачувај",
    "Date": "Датум",
    "Male": "Машки",
    "Female": "Женски",
    "Unspecified": "Неодредено",
    "Security": "Безбедност",
    "change_password": "Промени го пасвордот",
    "change_now": "Промени сега",
    "connect_email": "Поврзете е-пошта",
    "delete_account": "Избриши сметка",
    "delete_account_info": "Кога вашата сметка е избришана, сите податоци во Моја сметка поврзани со вашата сметка ќе бидат трајно избришани и можеби нема да можете да ги вратите. Ви препорачуваме да газите внимателно.",
    "Delete": "Избриши",
    "Logout": "Одјавување",
    "my_profile": "Мој Профил",
    "guides_faqs": "Водичи и ЧПП",
    "More": "Повеќе",
    "guides": "Водичи",
    "register": "регистрираат",
    "hot_faq": "Жешки Најчесто поставувани прашања",
    "Contents": "Содржина:",
    "contact_us": "Контактирајте не>>",
    "plan": "Планирајте",
    "unregistered": "Нерегистрирани",
    "buy_more": "Купете повеќе",
    "buy_again": "Купи повторно",
    "buy_now": "Купи сега",
    "free_no_limit": "Бесплатно и без ограничување",
    "expired": "Истечен",
    "lifetime": "Доживотно",
    "remain": "Остани",
    "day_s": "Ден(и)",
    "error_24801": "Барањето не успеа (шифра за грешка: 24801). Ве молиме обидете се повторно",
    "no_app_found": "Не е пронајдена апликација!<a href=\"/\">Освежи</a> или <a href=\"https://www.mindonmap.com/\">Одете на официјалната веб-локација</a>",
    "get_more": "Добијте повеќе >>",
    "edit_photo": "Уредете фотографија",
    "select_photo": "Изберете фотографија",
    "change_photo": "Променете ја фотографијата",
    "cancel": "Откажи",
    "hide_password": "Сокриј ја лозинката",
    "show_password": "Покажи лозинка",
    "zoom_in": "Зумирај",
    "zoom_out": "Одзумирај",
    "rotate": "Ротирај",
    "horizontal_flip": "Хоризонтално превртување",
    "vertical_flip": "Вертикално превртување",
    "country": "Земја",
    "country_1": "Изберете ја вашата земја/регион",
    "country_2": "Островот Аланд",
    "country_3": "Авганистан",
    "country_4": "Албанија",
    "country_5": "Алжир",
    "country_6": "Американска Самоа",
    "country_7": "Андора",
    "country_8": "Ангола",
    "country_9": "Ангвила",
    "country_10": "Антарктикот",
    "country_11": "Антигва и Барбуда",
    "country_12": "Аргентина",
    "country_13": "Ерменија",
    "country_14": "Аруба",
    "country_15": "Австралија",
    "country_16": "Австрија",
    "country_17": "Азербејџан",
    "country_18": "Бахреин",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Белорусија",
    "country_22": "Белгија",
    "country_23": "Белизе",
    "country_24": "Бенин",
    "country_25": "Бермуди",
    "country_26": "Бутан",
    "country_27": "Боливија",
    "country_28": "Босна и Херцеговина",
    "country_29": "Боцвана",
    "country_30": "Островот Буве",
    "country_31": "Бразил",
    "country_32": "Британска територија на Индискиот Океан",
    "country_33": "Британски Девствени Острови",
    "country_34": "Брунеи",
    "country_35": "Бугарија",
    "country_36": "Буркина",
    "country_37": "Бурунди",
    "country_38": "Камбоџа",
    "country_39": "Камерун",
    "country_40": "Канада",
    "country_41": "Зелено'ртски Острови",
    "country_42": "Карибите Холандија",
    "country_43": "Кајмански Острови",
    "country_44": "Централноафриканска република",
    "country_45": "Чад",
    "country_46": "Чиле",
    "country_47": "Кина",
    "country_48": "Божиќен остров",
    "country_49": "Кокосови (Килинг) Острови",
    "country_50": "Колумбија",
    "country_51": "Кукови Острови",
    "country_52": "Костарика",
    "country_53": "Брегот на Слоновата Коска",
    "country_54": "Хрватска",
    "country_55": "Куба",
    "country_56": "Кипар",
    "country_57": "Чешка република",
    "country_58": "Демократска Република Конго",
    "country_59": "Данска",
    "country_60": "Џибути",
    "country_61": "Доминика",
    "country_62": "Доминиканска Република",
    "country_63": "Еквадор",
    "country_64": "Египет",
    "country_65": "Ел Салвадор",
    "country_66": "Екваторијална Гвинеја",
    "country_67": "Еритреја",
    "country_68": "Естонија",
    "country_69": "Етиопија",
    "country_70": "Фолкландските Острови",
    "country_71": "Фарски Острови",
    "country_72": "Федерални држави микронезија",
    "country_73": "Фиџи",
    "country_74": "Финска",
    "country_75": "Франција",
    "country_76": "Француска Гвајана",
    "country_77": "Француска Полинезија",
    "country_78": "Француски јужни територии",
    "country_79": "Габон",
    "country_80": "Гамбија",
    "country_81": "Грузија",
    "country_82": "Германија",
    "country_83": "Гана",
    "country_84": "Гибралтар",
    "country_85": "Велика Британија (Обединето Кралство; Англија)",
    "country_86": "Грција",
    "country_87": "Гренланд",
    "country_88": "Гренада",
    "country_89": "Гвадалупе",
    "country_90": "Гуам",
    "country_91": "Гватемала",
    "country_92": "Гернзи",
    "country_93": "Гвинеја",
    "country_94": "Гвинеја-Бисао",
    "country_95": "Гвајана",
    "country_96": "Хаити",
    "country_97": "Островот Херд и островите Мекдоналд",
    "country_98": "Хондурас",
    "country_99": "Хонг Конг",
    "country_100": "Унгарија",
    "country_101": "Исланд",
    "country_102": "Индија",
    "country_103": "Индонезија",
    "country_104": "Иран",
    "country_105": "Ирак",
    "country_106": "Ирска",
    "country_107": "Островот Ман",
    "country_108": "Израел",
    "country_109": "Италија",
    "country_110": "Јамајка",
    "country_111": "Јапонија",
    "country_112": "Џерси",
    "country_113": "Јордан",
    "country_114": "Казахстан",
    "country_115": "Кенија",
    "country_116": "Кирибати",
    "country_117": "Кувајт",
    "country_118": "Киргистан",
    "country_119": "Лаос",
    "country_120": "Латвија",
    "country_121": "Либан",
    "country_122": "Лесото",
    "country_123": "Либерија",
    "country_124": "Либија",
    "country_125": "Лихтенштајн",
    "country_126": "Литванија",
    "country_127": "Луксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малави",
    "country_131": "Малезија",
    "country_132": "Малдиви",
    "country_133": "Мали",
    "country_134": "Малта",
    "country_135": "Маршалските острови",
    "country_136": "Мартиник",
    "country_137": "Мавританија",
    "country_138": "Маурициус",
    "country_139": "Мајот",
    "country_140": "Мексико",
    "country_141": "Молдавија",
    "country_142": "Монако",
    "country_143": "Монголија",
    "country_144": "Црна Гора",
    "country_145": "Монсерат",
    "country_146": "Мароко",
    "country_147": "Мозамбик",
    "country_148": "Мјанмар (Бурма)",
    "country_149": "Намибија",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Холандија",
    "country_153": "Нова Каледонија",
    "country_154": "Нов Зеланд",
    "country_155": "Никарагва",
    "country_156": "Нигер",
    "country_157": "Нигерија",
    "country_158": "Ниуе",
    "country_159": "Островот Норфолк",
    "country_160": "Северна Кореа",
    "country_161": "Северни Маријански Острови",
    "country_162": "Норвешка",
    "country_163": "Оман",
    "country_164": "Пакистан",
    "country_165": "Палау",
    "country_166": "палестински територии",
    "country_167": "Панама",
    "country_168": "Папуа Нова Гвинеја",
    "country_169": "Парагвај",
    "country_170": "Перу",
    "country_171": "Острови Питкерн",
    "country_172": "Полска",
    "country_173": "Португалија",
    "country_174": "Порторико",
    "country_175": "Катар",
    "country_176": "Република Македонија (ПЈРМ)",
    "country_177": "Република Конго",
    "country_178": "Реунион",
    "country_179": "Романија",
    "country_180": "Руска Федерација",
    "country_181": "Руанда",
    "country_182": "Свети Вартелемеј",
    "country_183": "Сен Мартин (Франција)",
    "country_184": "Сен-Пјер и Микелон",
    "country_185": "Самоа",
    "country_186": "Сан Марино",
    "country_187": "Сао Томе и Принципе",
    "country_188": "Саудиска Арабија",
    "country_189": "Сенегал",
    "country_190": "Србија",
    "country_191": "Сејшели",
    "country_192": "Сиера Леоне",
    "country_193": "Сингапур",
    "country_194": "Словачка",
    "country_195": "Словенија",
    "country_196": "Соломонови Острови",
    "country_197": "Сомалија",
    "country_198": "Јужна Африка",
    "country_199": "Јужна Грузија и островот Јужен Сендвич",
    "country_200": "Јужна Кореа",
    "country_201": "Јужен Судан",
    "country_202": "Шпанија",
    "country_203": "Шри Ланка",
    "country_204": "Света Елена и Зависности",
    "country_205": "Сент Китс и Невис",
    "country_206": "Света Луција",
    "country_207": "Сент Винсент и Гренадини",
    "country_208": "Судан",
    "country_209": "Суринам",
    "country_210": "Свазиленд",
    "country_211": "Шведска",
    "country_212": "Швајцарија",
    "country_213": "Сирија",
    "country_214": "Тајван",
    "country_215": "Таџикистан",
    "country_216": "Танзанија",
    "country_217": "Шаблон:Country data SJM Svalbard",
    "country_218": "Тајланд",
    "country_219": "Бахамите",
    "country_220": "Коморите",
    "country_221": "Филипини",
    "country_222": "Источен Тимор (Источен Тимор)",
    "country_223": "Да оди",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринидад и Тобаго",
    "country_227": "Тунис",
    "country_228": "Турција",
    "country_229": "Туркменистан",
    "country_230": "Острови Туркс и Каикос",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Украина",
    "country_234": "Обединети Арапски Емирати",
    "country_235": "Соединетите држави",
    "country_236": "Соединетите Американски Држави (САД)",
    "country_237": "Девствени острови на Соединетите Држави",
    "country_238": "Уругвај",
    "country_239": "Узбекистан",
    "country_240": "Вануату",
    "country_241": "Градот Ватикан (Светата столица)",
    "country_242": "Венецуела",
    "country_243": "Виетнам",
    "country_244": "Волис и Футуна",
    "country_245": "Западна Сахара",
    "country_246": "Јемен",
    "country_247": "Замбија",
    "country_248": "Зимбабве",
    "google_login": "Најавете се со Google",
    "State": "држава",
    "Activation_code": "Код за активирање",
    "Question": "Наведете ги сите апликации на кои сте најавени",
    "Copy_complete": "Копирањето е завршено",
    "footer": "Авторски права © 2024 MindOnMap Studio. Сите права се задржани.",
    "change_password_success": "Лозинката е успешно променета",
    "successful_login_title": "Успешно најава",
    "product_page": "Страница на производот >>",
    "successful_login_info": "Најавата е завршена. Затворете ја тековната страница и вратете се на оригиналниот таб за да продолжите со следниот процес. Тековната страница автоматски ќе се затвори за 5 секунди. Ако автоматското затворање или затворањето со кликнување на копчето „Готово“ не успее, ве молиме затворете го директно ова јазиче.",
    "successful_login_info_firefox": "Најавата е завршена. Затворете ја тековната страница и вратете се на оригиналниот таб за да продолжите со следниот процес.",
    "my_account": "Мојот акаунт",
    "my_history": "Мојата историја",
    "remove_watermark": "Отстранете го воден жиг",
    "no_history": "Нема историја",
    "history_all": "Селектирај се",
    "history_open": "Отвори",
    "history_down": "Преземи",
    "history_delete": "Избриши",
    "history_clear": "Исчисти неважечки",
    "images": "слика(и)",
    "use_this_function": "Користете го овој производ >>",
    "hd_downloading": "Придобивки од преземање HD оригинални слики:",
    "lifetimeRemaining": "Доживотно-преостанати",
    "Remaining": "Преостанати",
    "email_verification": "Потврда на е-пошта",
    "email_verification_info": "Ја испративме пораката за потврда на вашата е-пошта <span class=\"email\"></span> и ве молиме завршете ја потврдата. По потврдувањето, придобивките ќе се синхронизираат автоматски.",
    "wrong_email": "Погрешна е-адреса?",
    "click_here_to_modify": "Кликнете овде за да измените",
    "get_more_help": "Добијте повеќе помош?",
    "click_here": "Кликни тука",
    "email_verification_info_success": "Честитки што ја потврдивте вашата сметка за е-пошта.",
    "email_verification_info_error": "Потврдата не успеа поради истечената врска.",
    "registration_succeeded": "Регистрацијата е успешна",
    "registration_succeeded_info_1": "Секоја чест! Успешно се регистриравте. Ја испративме пораката за потврда на вашата е-пошта <span class=\"email\"></span> и ве молиме завршете ја потврдата за да ги искористите придобивките од оваа е-пошта.",
    "registration_succeeded_info_2": "Кликнете на „Готово“ за да се вратите на почетната страница и да го користите овој производ.",
    "registration_succeeded_info_3": "Кликнете на „Готово“ за да ја затворите тековната страница и да се вратите на почетната страница за следните операции. Ако затворањето не успее, ве молиме рачно затворете го јазичето.",
    "verify_email": "потврди ја електронската пошта",
    "registered_email_not_verified": "Регистрираната е-пошта не е потврдена, ве молиме потврдете ја веднаш.",
    "email_verification_time_1": "Не добивте е-пошта за потврда?",
    "email_verification_time_2": "По",
    "email_verification_time_3": "Кликнете овде за повторно да го испратите",
    "error_26301": "Код за грешка: 26301, ве молиме контактирајте не",
    "error_26302": "Код за грешка: 26302, ве молиме контактирајте не",
    "error_26303": "Грешка во форматот на е-пошта (код за грешка: 26303). Ве молиме внесете го повторно",
    "error_26304": "Се препорачува лозинка над 8 знаци (код за грешка: 26304)",
    "error_26305": "Барањето не успеа (шифра за грешка: 26305). Ве молиме обидете се повторно",
    "error_26306": "Е-поштата е регистрирана, ве молиме <a href=\"https://account.mindonmap.com/login/\">одете да се најавите</a>",
    "error_26307": "Барањето не успеа (шифра за грешка: 26307). Ве молиме обидете се повторно",
    "error_26308": "Барањето не успеа (шифра за грешка: 26308). Ве молиме обидете се повторно",
    "error_26401": "Код за грешка: 26401, ве молиме обидете се повторно",
    "error_26402": "Е-поштата е потврдена (код за грешка: 26402), ве молиме обидете се повторно",
    "error_26403": "Барањето не успеа (шифра за грешка: 26403). Ве молиме обидете се повторно",
    "error_26404": "Барањето не успеа (шифра за грешка: 26404). Ве молиме обидете се повторно",
    "error_26501": "Код за грешка: 26501, ве молиме контактирајте не",
    "error_26502": "Код за грешка: 26502, ве молиме контактирајте не",
    "error_26503": "Грешка во форматот на е-пошта (код за грешка: 26503). Ве молиме внесете го повторно",
    "error_26504": "Барањето не успеа (шифра за грешка: 26504). Ве молиме обидете се повторно",
    "error_26505": "Е-поштата не е регистрирана, ве молиме <a href=\"https://account.mindonmap.com/register/\">прво регистрирајте ја</a>",
    "error_26506": "Е-поштата е потврдена.",
    "error_26507": "Барањето не успеа (шифра за грешка: 26507). Ве молиме обидете се повторно",
    "error_26508": "Потврдата е завршена (код за грешка: 26508), ве молиме обидете се повторно",
    "error_26509": "Барањето не успеа (код за грешка: 26509), ве молиме обидете се повторно",
    "error_26510": "Код за грешка: 26510, ве молиме контактирајте не",
    "error_26601": "Код за грешка: 26601, ве молиме контактирајте не",
    "error_26602": "Код за грешка: 26602, ве молиме контактирајте не",
    "error_26603": "Барањето не успеа (шифра за грешка: 26603). Ве молиме обидете се повторно",
    "error_26604": "Код за грешка: 26604, ве молиме контактирајте не",
    "error_26605": "Код за грешка: 26605, ве молиме контактирајте не",
    "error_26701": "Код за грешка: 26701, ве молиме контактирајте не",
    "error_26702": "Барањето не успеа (шифра за грешка: 26702). Ве молиме обидете се повторно",
    "error_26703": "Код за грешка: 26703, ве молиме контактирајте не",
    "error_26704": "Код за грешка: 26704, ве молиме контактирајте не",
    "error_26705": "Почекајте да се најавите (код за грешка: 26705). Ве молиме обидете се повторно",
    "no_cookie": "Ја вклучивте функцијата Блокирај ги сите колачиња во вашиот прелистувач, така што не можете да се најавите. Ве молиме одете во Поставки за да го штиклирате полето Дозволи ги сите колачиња.",
    "error_26801": "Код за грешка: 26801, ве молиме контактирајте не",
    "error_26802": "Код за грешка: 26802, ве молиме контактирајте не",
    "error_26803": "Барањето не успеа (шифра за грешка: 26803). Ве молиме обидете се повторно",
    "error_26804": "Барањето не успеа (шифра за грешка: 26804). Ве молиме обидете се повторно",
    "error_order": "Барањето не успеа (код за грешка: 27098), обидете се повторно!",
    "error_order1": "Барањето за нарачка е нецелосно (код за грешка: ",
    "error_order2": "）, освежете го и обидете се повторно.",
    "modify_email_title": "Измени е-пошта",
    "modify_email_info": "Можете да ја користите изменетата е-пошта за да се најавите на вашата сметка.",
    "images_per": "Слики по",
    "error_26101": "Грешка: 26101. Ве молиме контактирајте со нас",
    "error_26102": "Грешка: 26102. Ве молиме контактирајте со нас",
    "error_26103": "Барањето не успеа (код на грешка: 26103). Ве молиме, пробајте повторно",
    "error_26104": "Код за грешка: 26104, ве молиме обидете се повторно",
    "error_26105": "Код за грешка: 26105, ве молиме обидете се повторно",
    "error_26106": "Бришењето не успеа (код на грешка: 26106). Ве молиме обидете се повторно",
    "error_26201": "Грешка: 26201. Ве молиме контактирајте не",
    "error_26202": "Барањето не успеа (код на грешка: 26202). Ве молиме, пробајте повторно",
    "error_26001": "Грешка: 26001. Ве молиме контактирајте со нас",
    "error_26002": "Грешка: 26002. Ве молиме контактирајте со нас",
    "error_26003": "Грешка: 26003. Ве молиме контактирајте со нас",
    "error_26004": "Грешка: 26004. Ве молиме контактирајте со нас",
    "error_26005": "Барањето не успеа (код на грешка: 26005). Ве молиме, пробајте повторно",
    "error_26006": "Код за грешка: 26006, ве молиме обидете се повторно",
    "error_26008": "Грешка: 26008. Ве молиме контактирајте со нас",
    "go_to_the_home_page": "Одете на почетната страница",
    "error_27101": "Барањето не успеа (шифра за грешка: 27101). Ве молиме обидете се повторно",
    "error_27201": "Код за грешка: 27201, ве молиме контактирајте не",
    "error_27202": "Код за грешка: 27202, ве молиме обидете се повторно",
    "error_27203": "Барањето не успеа (шифра за грешка: 27203). Ве молиме обидете се повторно",
    "error_27204": "Неважечки код (шифра за грешка: 27204).",
    "error_27205": "Барањето не успеа (шифра за грешка: 27205). Ве молиме обидете се повторно",
    "error_27206": "Барањето не успеа (шифра за грешка: 27206). Ве молиме обидете се повторно",
    "error_27207": "Барањето не успеа (шифра за грешка: 27207). Ве молиме обидете се повторно",
    "no_history_found": "Не сте користеле никаква алатка! <a href=\"/\">Освежи</a> или <a href=\"https://www.mindonmap.com/\">Одете на официјалната веб-локација</a>",
    "error_25301": "Грешка: 25301. Ве молиме контактирајте не",
    "error_25302": "Грешка: 25302. Ве молиме контактирајте со нас",
    "error_25303": "Барањето не успеа (код на грешка: 25303). Ве молиме обидете се повторно",
    "error_25304": "Барањето не успеа (код грешка: 25304). Ве молиме обидете се повторно",
    "error_25305": "Барањето не успеа (код на грешка: 25305). Ве молиме обидете се повторно",
    "error_25306": "Барањето не успеа (код на грешка: 25306). Ве молиме обидете се повторно",
    "image_upscaler_p": "Поволности за преземање слика без воден печат:",
    "Available_for": "Достапно за:",
    "credit_per": "%s кредит(и) по HD слика",
    "still_valid": "Купените планови сè уште се валидни",
    "credit": "кредит(и)",
    "pc_3rd_info": "Најавете се успешно. Ве молиме одете во апликацијата за понатамошно работење.",
    "use_online": "Користете ја онлајн услугата",
    "use_download": "Користете ја десктоп програмата",
    "use_immediately": "Користете Веднаш",
    "Use_in_browser": "Користете во прелистувачот",
    "win_desktop": "Windows",
    "Mac_desktop": "Мек",
    "credits_per": "{%} Кредити месечно",
    "expire": "Време на истекување:",
    "viewDetails": "Погледнете детали",
    "viewHistory": "Погледнете ги придобивките од историјата>>",
    "viewDetailsInfo": "Забелешка: Доколку поволностите од претплатата се обноват во рок од 7 дена по истекот, неискористените поволности може да продолжат да се користат. Исто така, времето на истекување автоматски ќе се ажурира до времето на истекување на новата претплата. Ако нема нова претплата по 7 дена од времето на истекување, сите истечени придобивки ќе бидат избришани.",
    "connect_account": "Поврзете е-пошта на вашата сметка",
    "connect_account_info": "По врзувањето, можете да се најавите со оваа адреса на е-пошта.",
    "connect_now": "Врзи сега",
    "no_email_bind": "Нема врска со е-пошта",
    "bind_email": "Поврзете е-пошта",
    "connect_your_email_placeholder": "Ве молиме внесете ја вашата е-адреса",
    "bind_an_email": "Поврзете е-пошта",
    "bind_info": "Успешно се најавивте. Поврзете е-пошта на вашата сметка за да ги активирате вашите поволности.",
    "bind_later": "Поврзете се подоцна",
    "hi": "Здраво, %s!",
    "Personal_Information": "Лични податоци",
    "Access": "Пристап",
    "Subscription_Plan": "(План за претплата)",
    "No_orders": "Не се најдени нарачки.",
    "No_data": "Нема податок",
    "Featured_Products": "Избрани производи",
    "More_Products": "Повеќе производи",
    "Free_Download": "Бесплатно преземање",
    "Get_Started": "Почни",
    "Subscribe": "Претплатете се",
    "Verified": "Потврдено",
    "back_to_account_center": "Назад во Центарот за сметки",
    "success": "Успех!",
    "successfully": "Успешно регистриравте сметка.",
    "Continue": "Продолжи",
    "Already": "Веќе имате профил?",
    "loading_verification": "Проверка на статусот на верификација...",
    "email_no_verification": "За жал, регистрираната адреса на е-пошта не е потврдена. Ве молиме пополнете ја потврдата според горенаведените инструкции и повторно кликнете на копчето „Готово“ за да ја завршите регистрацијата.",
    "will_expire_after": "Ќе истече после",
    "hours": "часови",
  },
  "mn": {
    "overtime": "Алдааны код: {%}, дахин оролдоно уу",
    "isnetwork": "Интернетийн алдаа. Шалгаж, дахин оролдоно уу",
    "email_placeholder": "Имэйл",
    "email_empty": "Имэйл оруулна уу",
    "email_not_valid": "Имэйл буруу байна",
    "email_not_valid_1": "Имэйлээ оруулна уу",
    "email_not_valid_2": "Имэйл буруу байна, өөр хаяг ашиглана уу.",
    "email_not_valid_3": "Имэйл оруулахгүй",
    "password_placeholder": "Нууц үг",
    "password_empty": "Нууц үгээ оруулна уу",
    "password_not_valid": "Буруу данс эсвэл нууц үг",
    "password_not_valid_1": "8 тэмдэгтээс дээш нууц үг оруулах шаардлагатай",
    "password_not_valid_2": "Нууц үг үүсгэнэ үү",
    "password_placeholder_1": "Нууц үгээ үүсгэнэ үү",
    "password_placeholder_2": "Нууц үгээ батлах",
    "password_placeholder_3": "Шинэ нууц үг үүсгэх",
    "password_placeholder_4": "Шинэ нууц үг баталгаажуулах",
    "password_placeholder_5": "Хуучин нууц үгээ оруулна уу",
    "copy_password_empty": "Нууц үгээ баталгаажуулна уу",
    "copy_password_not_valid": "Нууц үгээ баталгаажуулна уу",
    "copy_passwords_inconsistent": "Таны нууц үгийн баталгаажуулалт таарахгүй байна",
    "code_empty": "Баталгаажуулах кодыг оруулна уу",
    "code_not_valid": "Баталгаажуулах код буруу байна",
    "code_placeholder": "Баталгаажуулах код",
    "not_received_code": "Хэрэв таны шуудангийн хайрцаг баталгаажуулах кодыг удаан хугацаанд хүлээж аваагүй бол баталгаажуулах кодыг дахин авна уу.",
    "get_first_code": "Эхлээд баталгаажуулах кодыг авна уу.",
    "last_name_placeholder": "Овогоо оруулна уу",
    "first_name_placeholder": "Өөрийн нэрээ оруулна уу",
    "address_placeholder": "Та хаягаа оруулна уу",
    "no_code_text": "Бид баталгаажуулах код илгээсэн. Кодоо оруулна уу. <span class='tips'>Код хүлээж аваагүй юу?",
    "no_code_text_1": "1. Имэйл хаяг хүчинтэй бөгөөд имэйл хүлээн авах боломжтой эс",
    "no_code_text_2": "2. Имэйлийг систем автоматаар илгээдэг тул спам эсвэл хэрэггүй имэйл гэж тэмдэглэж болно. Имэйл Хогийн сав хавтсанд байгаа эсэхийг шалгана уу.",
    "no_code_text_3": "3. Асуудлаа шийдэж чадахгүй байна уу? ",
    "no_code_text_3_span": "Тэгвэл энд дарж бидэнтэй холбогдоно уу.",
    "order_no": "Та ямар ч бүтээгдэхүүн худалдаж аваагүй байгаа, хэрэв танд асуух зүйл байвал <a href=\"https://www.mindonmap.com/contact-us/\">бидэнтэй холбогдоно уу</a>.",
    "error_24901": "Одоогийн дансанд ямар ч имэйл холбоогүй бөгөөд захиалгыг олж чадахгүй байна. Имэйл холбогдоно уу.",
    "user_guide": "Хэрэглэгчийн гарын авлага>>",
    "download": "Татаж авах",
    "order_number": "Захиалгын дугаар:",
    "Refund": "Буцаан олголт",
    "Disabled": "Идэвхгүй",
    "Normal": "Ердийн",
    "Modify": "Өөрчлөх",
    "Modify_1": "Өөрчлөх>>",
    "Connect": "Холбох",
    "unlink_success": "Амжилттай салгалаа",
    "connect_success": "Амжилттай холбоно уу",
    "feedback_title": "Хариу өгсөнд баярлалаа. Асуудлаа үлдээнэ үү, бид танд 24 цагийн дотор хариу өгөх болно.",
    "feedback_thank_you": "Баярлалаа!<br />Таны санал хүсэлтийг амжилттай илгээлээ.",
    "feedback_email": "Имэйлээ энд оруулна уу!",
    "feedback_content": "Танд тулгарсан асуудал, санал хүсэлтээ энд үлдээгээрэй.",
    "feedback_submit": "Илгээх",
    "form_contents": "Та ямар ч тайлбар оруулаагүй байна. Үүнийг оруулаад дахин оруулна уу.",
    "old_password": "Хуучин нууц үгээ оруулна уу",
    "new_password": "Шинэ нууц үг үүсгэнэ үү",
    "old_new_password": "Шинэ нууц үг нь хуучин нууц үгтэй ижил байж болохгүй",
    "incorrect_password": "нууц үг буруу",
    "delete_no": "Одоо устгах",
    "Caps": "Caps lock асаалттай байна",
    "Get": "Авах",
    "Done": "Дууслаа",
    "error_20001": "Алдаа: 20001. Дахин нэвтэрнэ үү.",
    "error_20002": "Алдаа: 20002. Дахин нэвтэрнэ үү.",
    "error_20003": "Алдаа: 20003. Дахин нэвтэрнэ үү.",
    "error_20004": "Хүсэлт амжилтгүй болсон (алдааны код: 20004). Дахин оролдоно уу.",
    "error_20005": "Нэвтрэх сессийн хугацаа дууссан (Алдаа: 20005). Дахин нэвтэрнэ үү.",
    "error_20006": "Хүсэлт амжилтгүй болсон (алдааны код: 20006). Дахин оролдоно уу.",
    "error_20007": "Нэвтрэх сессийн хугацаа дууссан (Алдаа: 20007). Дахин нэвтэрнэ үү.",
    "error_20008": "Нэвтрэх сессийн хугацаа дууссан (Алдаа: 20008). Дахин нэвтэрнэ үү.",
    "error_20009": "Нэвтрэх сессийн хугацаа дууссан (Алдаа: 20009). Дахин нэвтэрнэ үү.",
    "error_20101": "Имэйлээ оруулна уу (алдааны код: 20101)",
    "error_20102": "Имэйл буруу байна (алдааны код: 20102)",
    "error_20103": "Хүсэлт амжилтгүй болсон (алдааны код: 20103). Дахин оролдоно уу",
    "error_20104": "Имэйл аль хэдийн ашиглагдаж байгаа тул <a href=\"https://account.mindonmap.com/login/\">нэвтрэх</a> эсвэл шинээр бүртгүүлнэ үү.",
    "error_20105": "Хүсэлт амжилтгүй болсон (алдааны код: 20105). Дахин оролдоно уу",
    "error_20106": "Имэйл илгээж чадсангүй, дахин оролдоно уу",
    "error_20201": "Имэйлээ оруулна уу (алдааны код: 20201)",
    "error_20202": "Нууц үгээ оруулна уу (алдааны код: 20202)",
    "error_20203": "Баталгаажуулах кодыг оруулна уу (алдааны код: 20203)",
    "error_20204": "Имэйл буруу байна (алдааны код: 20204)",
    "error_20205": "8 тэмдэгтээс дээш нууц үг оруулах шаардлагатай (алдааны код: 20205)",
    "error_20206": "Хүсэлт амжилтгүй болсон (алдааны код: 20206). Дахин оролдоно уу",
    "error_20207": "Баталгаажуулах код буруу байна",
    "error_20208": "Хүсэлт амжилтгүй болсон (алдааны код: 20208). Дахин оролдоно уу",
    "error_20209": "Хүсэлт амжилтгүй болсон (алдааны код: 20209). Дахин оролдоно уу",
    "error_20301": "Имэйлээ оруулна уу (алдааны код: 20301)",
    "error_20302": "Алдаа: 20302. Бидэнтэй холбоо барина уу",
    "error_20303": "Имэйл буруу байна (алдааны код: 20303)",
    "error_20304": "Хүсэлт амжилтгүй болсон (алдааны код: 20304). Дахин оролдоно уу",
    "error_20305": "Бүртгэл байхгүй байна. Эхлээд дахин оруулна уу эсвэл <a href=\"https://account.mindonmap.com/register/\">Үүнийг үүсгэнэ үү</a>.",
    "error_20306": "Одоогоор нууц үг алга. <a href=\"https://account.mindonmap.com/passwordless-login/\">Нууц үггүй нэвтрэх</a> эсвэл <a href=\"https://account.mindonmap.com/create-password/\"> ашиглана уу. нууц үг</a> тохируулаад нэвтэрнэ үү.",
    "error_20308": "Хүсэлт амжилтгүй болсон (алдааны код: 20308). Дахин оролдоно уу",
    "error_20401": "Гарч чадсангүй (алдааны код: 20401). Дахин оролдоно уу",
    "error_20501": "Имэйлээ оруулна уу (алдааны код: 20501)",
    "error_20502": "Имэйл буруу байна (алдааны код: 20502)",
    "error_20503": "Хүсэлт амжилтгүй болсон (алдааны код: 20503). Дахин оролдоно уу",
    "error_20504": "Имэйл илгээж чадсангүй. Дахин оролдоно уу.",
    "error_20601": "Имэйлээ оруулна уу (алдааны код: 20601)",
    "error_20602": "Баталгаажуулах кодыг оруулна уу (алдааны код: 20602)",
    "error_20603": "Имэйл буруу байна (алдааны код: 20603)",
    "error_20604": "Хүсэлт амжилтгүй болсон (алдааны код: 20604). Дахин оролдоно уу",
    "error_20606": "Хүсэлт амжилтгүй болсон (алдааны код: 20606). Дахин оролдоно уу",
    "error_20607": "Хүсэлт амжилтгүй болсон (алдааны код: 20607). Дахин оролдоно уу",
    "error_20608": "Хүсэлт амжилтгүй болсон (алдааны код: 20608). Дахин оролдоно уу",
    "error_20701": "Имэйлээ оруулна уу (алдааны код: 20701)",
    "error_20702": "Имэйл буруу байна (алдааны код: 20702)",
    "error_20703": "Хүсэлт амжилтгүй болсон (алдааны код: 20703). Дахин оролдоно уу",
    "error_20704": "Бүртгэл байхгүй байна. Эхлээд дахин оруулна уу эсвэл <a href=\"https://account.mindonmap.com/register/\">Үүнийг үүсгэнэ үү</a>.",
    "error_20705": "Хүсэлт амжилтгүй болсон (алдааны код: 20705). Дахин оролдоно уу",
    "error_20706": "Имэйл илгээж чадсангүй. Дахин оролдоно уу",
    "error_20801": "Имэйлээ оруулна уу (алдааны код: 20801)",
    "error_20802": "Алдаа: 20802. Бидэнтэй холбоо барина уу",
    "error_20803": "Баталгаажуулах кодыг оруулна уу (алдааны код: 20803)",
    "error_20804": "Имэйл буруу байна (алдааны код: 20804)",
    "error_20805": "8 тэмдэгтээс дээш нууц үг оруулах шаардлагатай (алдааны код: 20805)",
    "error_20806": "Хүсэлт амжилтгүй болсон (алдааны код: 20806). Дахин оролдоно уу",
    "error_20808": "Хүсэлт амжилтгүй болсон (алдааны код: 20808). Дахин оролдоно уу",
    "error_20901": "Хүсэлт амжилтгүй болсон (алдааны код: 20901). Дахин оролдоно уу",
    "error_20902": "Хүсэлт амжилтгүй болсон (алдааны код: 20902). Дахин оролдоно уу",
    "error_21000": "Өөрчлөлтүүдийг хадгалсан",
    "error_21001": "Мэдээлэл ирүүлээгүй байна (алдааны код: 21001)",
    "error_21002": "Хүсэлт амжилтгүй боллоо (алдааны код: 21002). Дахин оролдоно уу",
    "error_21101": "Имэйлээ оруулна уу (алдааны код: 21101)",
    "error_21102": "Имэйл буруу байна (алдааны код: 21102)",
    "error_21103": "Хүсэлт амжилтгүй боллоо (алдааны код: 21103), дахин оролдоно уу",
    "error_21104": "Имэйл аль хэдийн холбогдсон байна, шинийг ашиглана уу",
    "error_21105": "Хүсэлт амжилтгүй боллоо (алдааны код: 21105), дахин оролдоно уу",
    "error_21106": "Имэйл илгээж чадсангүй. Дахин оролдоно уу",
    "error_21201": "Имэйлээ оруулна уу (алдааны код: 21201)",
    "error_21202": "Баталгаажуулах кодыг оруулна уу (алдааны код: 21202)",
    "error_21203": "Имэйл буруу байна (алдааны код: 21203)",
    "error_21204": "Алдаа: 21204. Бидэнтэй холбоо барина уу",
    "error_21205": "Алдаа: 21205. Бидэнтэй холбоо барина уу",
    "error_21206": "8 тэмдэгтээс дээш нууц үг оруулах шаардлагатай (алдааны код: 21206)",
    "error_21207": "Хүсэлт амжилтгүй боллоо (алдааны код: 21207). Дахин оролдоно уу",
    "error_21209": "Хүсэлт амжилтгүй боллоо (алдааны код: 21209). Дахин оролдоно уу",
    "error_21301": "Хуучин нууц үгээ оруулна уу (алдааны код: 21301)",
    "error_21302": "Шинэ нууц үг үүсгэнэ үү (алдааны код: 21302)",
    "error_21303": "Шинэ нууц үг нь хуучин нууц үгтэй ижил байж болохгүй. (Алдаа: 21303)",
    "error_21304": "8 тэмдэгтээс дээш нууц үг оруулах шаардлагатай (алдааны код: 21304)",
    "error_21306": "Хүсэлт амжилтгүй боллоо (алдааны код: 21306). Дахин оролдоно уу",
    "error_21402": "Хүсэлт амжилтгүй боллоо (алдааны код: 21402). Дахин оролдоно уу",
    "error_21403": "Баталгаажуулах кодыг илгээж чадсангүй. Үүнийг дахин илгээнэ үү",
    "error_21500": "Бүртгэлийг устгасан",
    "error_21501": "Баталгаажуулах кодыг оруулна уу (алдааны код: 21501)",
    "error_21502": "Нэвтрэх сессийн хугацаа дууссан (Алдаа: 21502). Дахин нэвтэрнэ үү.",
    "error_21503": "Хүсэлт амжилтгүй боллоо (алдааны код: 21503). Дахин оролдоно уу",
    "error_21505": "Хүсэлт амжилтгүй боллоо (алдааны код: 21505), дахин оролдоно уу",
    "error_21601": "Алдаа: 20601. Бидэнтэй холбоо барина уу",
    "error_21602": "Баталгаажуулалт буруу (Алдаа: 20602). Дахин оролдоно уу.",
    "error_21603": "Алдаа: 20603. Дахин оролдоно уу",
    "error_21604": "Хүсэлт амжилтгүй боллоо (алдааны код: 21604). Дахин оролдоно уу",
    "error_21606": "Хүсэлт амжилтгүй боллоо (алдааны код: 21606). Дахин оролдоно уу",
    "error_21611": "Хүсэлт амжилтгүй боллоо (алдааны код: 21611). Дахин оролдоно уу",
    "error_21801": "Алдаа: 21801. Бидэнтэй холбоо барина уу",
    "error_21802": "Хүсэлт амжилтгүй боллоо (Алдаа: 21802). Дахин оролдоно уу",
    "error_21803": "Алдаа: 21803. Дахин оролдоно уу",
    "error_21804": "Хүсэлт амжилтгүй боллоо (алдааны код: 21804). Дахин оролдоно уу",
    "error_21806": "Алдаа: 21806. Дахин оролдоно уу",
    "error_21807": "Алдаа: 21807. Бидэнтэй холбоо барина уу",
    "error_21808": "Алдаа: 21808. Бидэнтэй холбоо барина уу",
    "error_21809": "Алдаа: 21809. Бидэнтэй холбоо барина уу",
    "error_21810": "Алдаа: 21810. Бидэнтэй холбоо барина уу",
    "error_21811": "Алдаа: 21811. Бидэнтэй холбоо барина уу",
    "error_21812": "Алдаа: 21812. Бидэнтэй холбоо барина уу",
    "error_21813": "Хүсэлт амжилтгүй боллоо (алдааны код: 21813). Дахин оролдоно уу",
    "error_21814": "Алдаа: 21814. Бидэнтэй холбоо барина уу",
    "error_21815": "Хүсэлт амжилтгүй боллоо (алдааны код: 21815). Дахин оролдоно уу",
    "error_21816": "Алдаа: 21816. Бидэнтэй холбоо барина уу",
    "error_21817": "Алдаа: 21817. Бидэнтэй холбоо барина уу",
    "error_21818": "Алдаа: 21818. Бидэнтэй холбоо барина уу",
    "error_21819": "Хүсэлт амжилтгүй боллоо (алдааны код: 21819). Дахин оролдоно уу",
    "error_21820": "Алдаа: 21820. Бидэнтэй холбоо барина уу",
    "error_21821": "Алдаа: 21821. Бидэнтэй холбоо барина уу",
    "error_21822": "Алдаа: 21822. Бидэнтэй холбоо барина уу",
    "error_21823": "Хүсэлт амжилтгүй боллоо (алдааны код: 21823). Дахин оролдоно уу",
    "error_21824": "Хүсэлт амжилтгүй боллоо (алдааны код: 21824). Дахин оролдоно уу",
    "error_21825": "Хүсэлт амжилтгүй боллоо (алдааны код: 21825). Дахин оролдоно уу",
    "error_21826": "Хүсэлт амжилтгүй боллоо (алдааны код: 21826). Дахин оролдоно уу",
    "error_21901": "Алдаа: 21901. Бидэнтэй холбоо барина уу",
    "error_21902": "Хүсэлт амжилтгүй болсон (алдааны код: 21902). Дахин оролдоно уу",
    "error_21903": "Бүртгэлийн статус өөрчлөгдсөн (алдааны код: 21903), хуудсыг дахин сэргээгээд дахин оролдоно уу",
    "error_21904": "Алдаа: 21904. Дахин оролдоно уу",
    "error_21905": "Алдаа: 21905. Дахин оролдоно уу",
    "error_21906": "Хүсэлт амжилтгүй болсон (алдааны код: 21906). Дахин оролдоно уу",
    "error_21907": "Google бүртгэлийг өөр бүртгэлтэй холбосон",
    "error_21908": "Хүсэлт амжилтгүй боллоо (алдааны код: 21908). Дахин оролдоно уу",
    "error_22001": "Хүсэлт амжилтгүй боллоо (алдааны код: 22001). Дахин оролдоно уу",
    "error_22002": "Нэмэлт нэвтрэлтгүйгээр салгаж чадсангүй",
    "error_22003": "Хүсэлт амжилтгүй боллоо (алдааны код: 22003). Дахин оролдоно уу",
    "error_22101": "Алдаа: 22101. Бидэнтэй холбоо барина уу",
    "error_22102": "Бүртгэлийн статус өөрчлөгдсөн (алдааны код: 22102), хуудсыг дахин сэргээгээд дахин оролдоно уу",
    "error_22103": "Хүсэлт амжилтгүй болсон (алдааны код: 22103). Дахин оролдоно уу",
    "error_22104": "Бүртгэлийн статус өөрчлөгдсөн (алдааны код: 22104), хуудсыг дахин сэргээгээд дахин оролдоно уу",
    "error_22105": "Алдаа: 22105. Дахин оролдоно уу",
    "error_22106": "Алдаа: 22106. Дахин оролдоно уу",
    "error_22107": "Алдаа: 22107. Бидэнтэй холбоо барина уу",
    "error_22108": "Хүсэлт амжилтгүй боллоо (алдааны код: 22108). Дахин оролдоно уу",
    "error_22201": "Алдаа: 22201. Бидэнтэй холбоо барина уу",
    "error_22202": "Баталгаажуулалт буруу байна (Алдаа: 22202). Дахин оролдоно уу.",
    "error_22203": "Алдаа: 22203. Дахин оролдоно уу\"",
    "error_22204": "Хүсэлт амжилтгүй болсон (алдааны код: 22204). Дахин оролдоно уу",
    "error_22206": "Хүсэлт амжилтгүй боллоо (алдааны код: 22206). Дахин оролдоно уу",
    "error_22401": "Алдаа: 22401. Бидэнтэй холбоо барина уу",
    "error_22402": "Баталгаажуулалт буруу байна (Алдаа: 22402). Дахин оролдоно уу.",
    "error_22403": "Алдаа: 22403. Дахин оролдоно уу",
    "error_22404": "Хүсэлт амжилтгүй боллоо (алдааны код: 22404). Дахин оролдоно уу",
    "error_22405": "Facebook хаягийг өөр имэйлтэй холбосон",
    "error_22406": "Алдаа: 22406. Дахин оролдоно уу",
    "error_22407": "Алдаа: 22407. Бидэнтэй холбоо барина уу",
    "error_22408": "Алдаа: 22408. Бидэнтэй холбоо барина уу",
    "error_22409": "Алдаа: 22409. Бидэнтэй холбоо барина уу",
    "error_22410": "Алдаа: 224010. Бидэнтэй холбоо барина уу",
    "error_22411": "Алдаа: 224011. Бидэнтэй холбоо барина уу",
    "error_22412": "Алдаа: 224012. Бидэнтэй холбоо барина уу",
    "error_22413": "Алдаа: 22413. Бидэнтэй холбоо барина уу",
    "error_22414": "Хүсэлт амжилтгүй боллоо (алдааны код: 22414). Дахин оролдоно уу",
    "error_22415": "Алдаа: 22415. Бидэнтэй холбоо барина уу",
    "error_22416": "Алдаа: 22416. Бидэнтэй холбоо барина уу",
    "error_22417": "Алдаа: 22417. Бидэнтэй холбоо барина уу",
    "error_22418": "Хүсэлт амжилтгүй боллоо (алдааны код: 22418). Дахин оролдоно уу",
    "error_22419": "Алдаа: 22419. Бидэнтэй холбоо барина уу",
    "error_22420": "Алдаа: 22420. Бидэнтэй холбоо барина уу",
    "error_22421": "Алдаа: 22421. Бидэнтэй холбоо барина уу",
    "error_22422": "Хүсэлт амжилтгүй болсон (алдааны код: 22422). Дахин оролдоно уу",
    "error_22423": "Хүсэлт амжилтгүй болсон (алдааны код: 22423). Дахин оролдоно уу",
    "error_22424": "Хүсэлт амжилтгүй болсон (алдааны код: 22424). Дахин оролдоно уу",
    "error_22425": "Хүсэлт амжилтгүй боллоо (алдааны код: 22425). Дахин оролдоно уу",
    "error_20098": "Алдааны код: 20098. Хэрэв та Хувийн горимоор үзэж байгаа бол Энгийн горим руу шилжиж дахин оролдоно уу.",
    "error_22298": "Google-ийн хүсэлт амжилтгүй боллоо (Алдаа: 22298). Дахин оролдоно уу.",
    "error_22498": "Facebook-ийн хүсэлт амжилтгүй болсон (Алдаа: 22498). Дахин оролдоно уу.",
    "error_24902": "Хүсэлт амжилтгүй болсон (алдааны код: 24902). Дахин оролдоно уу",
    "error_24903": "Хүсэлт амжилтгүй болсон (алдааны код: 24903). Дахин оролдоно уу",
    "error_24904": "Хүсэлт амжилтгүй болсон (алдааны код: 24904). Дахин оролдоно уу",
    "error_24905": "Хүсэлт амжилтгүй болсон (алдааны код: 24905). Дахин оролдоно уу",
    "login_title": "MindOnMap руу нэвтэрнэ үү",
    "log_in": "Нэвтрэх",
    "no_account": "Бүртгэлгүй юу?",
    "create_it": "Үүнийг үүсгэ",
    "or_log_in_with": "Эсвэл нэвтэрнэ үү",
    "passwordless_login": "Нууц үггүй нэвтрэх",
    "log_in_done": "Нэвтрэх дууссан",
    "three_rd_account_connect_info": "Баяр хүргэе! Та амжилттай нэвтэрлээ. Одоо та ирээдүйд нэвтрэхэд ашигладаг нэг имэйл хаягийг холбож болно.",
    "see_my_account": "Миний дансыг харна уу",
    "three_rd_login_merge_account": "Гуравдагч этгээдийн акаунтын имэйл хаягийг бүртгүүлсэн тул та энэ имэйл хаягаар шууд холбогдож, нэвтрэхийг хүсэж байна уу?",
    "connect_log_in": "Холболт ба нэвтэрнэ үү",
    "create_an_account": "Бүртгэл үүсгэх",
    "back_to_log_in": "Нэвтрэхийн тулд буцна уу",
    "create_password": "Нууц үг үүсгэх",
    "create_now": "Одоо үүсгэх",
    "password_login_subtitle": "Имэйлээр нууц үггүй нэвтрэх",
    "account_login": "Бүртгэлд нэвтрэх",
    "rights": "Энэ бүртгэлийг үүсгэснээр та <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Үйлчилгээний нөхцөл</a> болон <a href=\"https://www.mindonmap.com/privacy-policy/\">Нууцлалын бодлого</a>",
    "passwordless_login_done": "Нууц үггүй нэвтэрсэн",
    "passwordless_login_info": "Баяр хүргэе, та нууц үггүй нэвтэрч амжилттай нэвтэрлээ. Та энэ дансанд нууц үг үүсгэж, цаашид бүртгэл болон нууц үгээрээ нэвтэрч болно. <a href=\"/create-password\" style=\"display: initial;\">Одоо үүсгэх</a>",
    "sign_up": "Бүртгүүлэх",
    "register_info": "Бүртгэлээ үүсгэ",
    "reset_now": "Одоо дахин тохируулах",
    "forgot_password": "Нууц үгээ мартсан",
    "reset_password_subtitle": "Нууц үгээ шинэчлэхийн тулд өөрийн бүртгэлийн имэйлийг ашиглана уу",
    "plan_products": "Төлөвлөгөө ба бүтээгдэхүүн",
    "nick_name": "Хэрэглэгчийн нэр:",
    "email": "Имэйл:",
    "my_products": "Миний бүтээгдэхүүнүүд",
    "my_orders": "Миний захиалга",
    "unlink": "Салгах",
    "link": "Холбоос",
    "connected_accounts": "Холбогдсон бүртгэлүүд",
    "last_name": "Овог:",
    "first_name": "Нэр:",
    "Gender": "Хүйс:",
    "Birth": "Төрсөн:",
    "Month": "Сар",
    "Year": "Жил",
    "Country_Region": "Улс/Бүс нутаг:",
    "Address": "Хаяг:",
    "Save": "Хадгалах",
    "Date": "Огноо",
    "Male": "Эрэгтэй",
    "Female": "Эмэгтэй",
    "Unspecified": "Тодорхойгүй",
    "Security": "Аюулгүй байдал",
    "change_password": "Нууц үг солих",
    "change_now": "Одоо өөрчлөх",
    "connect_email": "Имэйлийг холбоно уу",
    "delete_account": "Бүртгэл устгах",
    "delete_account_info": "Таны бүртгэлийг устгах үед таны бүртгэлтэй холбоотой Миний дансны бүх өгөгдөл бүрмөсөн устах бөгөөд та үүнийг сэргээх боломжгүй байж магадгүй юм. Бид танд болгоомжтой байхыг зөвлөж байна.",
    "Delete": "Устгах",
    "Logout": "Гарах",
    "my_profile": "Миний намтар",
    "guides_faqs": "Хөтөч, түгээмэл асуултууд",
    "More": "Илүү",
    "guides": "Хөтөч",
    "register": "бүртгүүлэх",
    "hot_faq": "Халуун асуултууд",
    "Contents": "Агуулга:",
    "contact_us": "Бидэнтэй холбоо барина уу>>",
    "plan": "Төлөвлөгөө",
    "unregistered": "Бүртгэлгүй",
    "buy_more": "Илүү ихийг худалдаж аваарай",
    "buy_again": "Дахин худалдаж аваарай",
    "buy_now": "Одоо худалдаж авах",
    "free_no_limit": "Үнэгүй, Хязгааргүй",
    "expired": "Хугацаа дууссан",
    "lifetime": "Насан туршдаа",
    "remain": "Үлдсэн",
    "day_s": "Өдөр(үүд)",
    "error_24801": "Хүсэлт амжилтгүй болсон (алдааны код: 24801). Дахин оролдоно уу",
    "no_app_found": "Ямар ч програм олдсонгүй!<a href=\"/\">Сэргээх</a> эсвэл <a href=\"https://www.mindonmap.com/\">Албан ёсны вэб сайт руу очно уу</a>",
    "get_more": "Илүү ихийг аваарай >>",
    "edit_photo": "Зураг засах",
    "select_photo": "Зураг сонгох",
    "change_photo": "Зургийг өөрчлөх",
    "cancel": "Цуцлах",
    "hide_password": "Нууц үгээ нуу",
    "show_password": "Нууц үгийг харуулах",
    "zoom_in": "Томруулах",
    "zoom_out": "Жижигрүүлэх",
    "rotate": "Эргүүлэх",
    "horizontal_flip": "Хэвтээ эргүүлэх",
    "vertical_flip": "Босоо эргүүлэх",
    "country": "Улс",
    "country_1": "Улс/бүсээ сонго",
    "country_2": "Аланд арал",
    "country_3": "Афганистан",
    "country_4": "Албани",
    "country_5": "Алжир",
    "country_6": "Америкийн Самоа",
    "country_7": "Андорра",
    "country_8": "Ангол",
    "country_9": "Ангилья",
    "country_10": "Антарктид",
    "country_11": "Антигуа ба Барбуда",
    "country_12": "Аргентин",
    "country_13": "Армен",
    "country_14": "Аруба",
    "country_15": "Австрали",
    "country_16": "Австри",
    "country_17": "Азербайжан",
    "country_18": "Бахрейн",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Беларусь",
    "country_22": "Бельги",
    "country_23": "Белиз",
    "country_24": "Бенин",
    "country_25": "Бермуд",
    "country_26": "Бутан",
    "country_27": "Болив",
    "country_28": "Босни ба Герцеговина",
    "country_29": "Ботсвана",
    "country_30": "Бувет арал",
    "country_31": "Бразил",
    "country_32": "Британийн Энэтхэгийн далайн нутаг дэвсгэр",
    "country_33": "Британийн Виржиний арлууд",
    "country_34": "Бруней",
    "country_35": "Болгар",
    "country_36": "Буркина",
    "country_37": "Бурунди",
    "country_38": "Камбож",
    "country_39": "Камерун",
    "country_40": "Канад",
    "country_41": "Кабо Верде",
    "country_42": "Карибын Нидерланд",
    "country_43": "Кайманы арлууд",
    "country_44": "Төв Африкийн Бүгд Найрамдах Улс",
    "country_45": "Чад",
    "country_46": "Чили",
    "country_47": "Хятад",
    "country_48": "Зул сарын арал",
    "country_49": "Кокос (Килинг) Арлууд",
    "country_50": "Колумб",
    "country_51": "Күүкийн арлууд",
    "country_52": "Коста Рика",
    "country_53": "Кот-д'Ивуар",
    "country_54": "Хорват",
    "country_55": "Куба",
    "country_56": "Кипр",
    "country_57": "Бүгд Найрамдах Чех ",
    "country_58": "Бүгд Найрамдах Ардчилсан Конго Улс",
    "country_59": "Дани",
    "country_60": "Жибути",
    "country_61": "Доминика",
    "country_62": "Доминиканы Бүгд Найрамдах Улс",
    "country_63": "Эквадор",
    "country_64": "Египет",
    "country_65": "Эль Сальвадор",
    "country_66": "Экваторын Гвиней",
    "country_67": "Эритрей",
    "country_68": "Эстони",
    "country_69": "Этиоп",
    "country_70": "Фолклэндийн арлууд",
    "country_71": "Фарерын арлууд",
    "country_72": "Микронезийн Холбооны мужууд",
    "country_73": "Фижи",
    "country_74": "Финланд",
    "country_75": "Франц",
    "country_76": "Франц Гвиана",
    "country_77": "Францын полинези",
    "country_78": "Францын өмнөд нутаг дэвсгэр",
    "country_79": "Габон",
    "country_80": "Гамби",
    "country_81": "Гүрж",
    "country_82": "Герман",
    "country_83": "Гана",
    "country_84": "Гибралтар",
    "country_85": "Их Британи (Нэгдсэн Вант Улс; Англи)",
    "country_86": "Грек",
    "country_87": "Гренланд",
    "country_88": "Гренада",
    "country_89": "Гваделупа",
    "country_90": "Гуам",
    "country_91": "Гватемал",
    "country_92": "Гернси",
    "country_93": "Гвиней",
    "country_94": "Гвиней-Бисау",
    "country_95": "Гайана",
    "country_96": "Гаити",
    "country_97": "Херд арал ба  МкДональд  арлууд",
    "country_98": "Гондурас",
    "country_99": "Хонг Конг",
    "country_100": "Унгар",
    "country_101": "Исланд",
    "country_102": "Энэтхэг",
    "country_103": "Индонез",
    "country_104": "Иран",
    "country_105": "Ирак",
    "country_106": "Ирланд",
    "country_107": "Хүний арал",
    "country_108": "Израиль",
    "country_109": "Итали",
    "country_110": "Ямайка",
    "country_111": "Япон",
    "country_112": "Жерси",
    "country_113": "Жордан",
    "country_114": "Казахстан",
    "country_115": "Кени",
    "country_116": "Кирибати",
    "country_117": "Кувейт",
    "country_118": "Киргиз",
    "country_119": "Лаос",
    "country_120": "Латви",
    "country_121": "Ливан",
    "country_122": "Лесото",
    "country_123": "Либери",
    "country_124": "Ливи",
    "country_125": "Лихтенштейн",
    "country_126": "Литва",
    "country_127": "Люксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малави",
    "country_131": "Малайз",
    "country_132": "Мальдив",
    "country_133": "Мали",
    "country_134": "Мальта",
    "country_135": "Маршаллын арлууд",
    "country_136": "Мартиник",
    "country_137": "Мавритани",
    "country_138": "Маврики",
    "country_139": "Майотт",
    "country_140": "Мексик",
    "country_141": "Молдав",
    "country_142": "Монако",
    "country_143": "Монгол",
    "country_144": "Монтенегро",
    "country_145": "Монцеррат",
    "country_146": "Марокко",
    "country_147": "Мозамбик",
    "country_148": "Мьянмар (Бирм)",
    "country_149": "Намиби",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Нидерланд",
    "country_153": "Шинэ Каледон",
    "country_154": "Шинэ Зеланд",
    "country_155": "Никарагуа",
    "country_156": "Нигер",
    "country_157": "Нигери",
    "country_158": "Ниуэ",
    "country_159": "Норфолк арал",
    "country_160": "Хойд Солонгос",
    "country_161": "Хойд Мариана арлууд",
    "country_162": "Норвеги",
    "country_163": "Оман",
    "country_164": "Пакистан",
    "country_165": "Палау",
    "country_166": "Палестины нутаг дэвсгэр",
    "country_167": "Панам",
    "country_168": "Папуа Шинэ Гвиней",
    "country_169": "Парагвай",
    "country_170": "Перу",
    "country_171": "Питкэрн арлууд",
    "country_172": "Польш",
    "country_173": "Португал",
    "country_174": "Пуэрто-Рико",
    "country_175": "Катар",
    "country_176": "Бүгд Найрамдах Македон  (FYROM)",
    "country_177": "Бүгд Найрамдах Конго  ",
    "country_178": "Реюньон",
    "country_179": "Румын",
    "country_180": "Оросын Холбооны Улс",
    "country_181": "Руанда",
    "country_182": "Сент-Бартелеми",
    "country_183": "Сент Мартин (Франц)",
    "country_184": "Сент-Пьер ба Микелон",
    "country_185": "Самоа",
    "country_186": "Сан Марино",
    "country_187": "Сан Томе ба Принсипи",
    "country_188": "Саудын Араб",
    "country_189": "Сенегал",
    "country_190": "Серби",
    "country_191": "Сейшелийн арлууд",
    "country_192": "Сьерра-Леон",
    "country_193": "Сингапур",
    "country_194": "Словак",
    "country_195": "Словени",
    "country_196": "Соломоны арлууд",
    "country_197": "Сомали",
    "country_198": "Өмнөд Африк",
    "country_199": "Өмнөд Жоржиа ба Өмнөд Сэндвич Исля",
    "country_200": "Өмнөд Солонгос",
    "country_201": "Өмнөд Судан",
    "country_202": "Испани",
    "country_203": "Шри Ланка",
    "country_204": "Гэгээн Елена ба хараат байдал",
    "country_205": "Сент Киттс ба Невис",
    "country_206": "Гэгээн Люсиа",
    "country_207": "Гэгээн Винсент ба Гренадин",
    "country_208": "Судан",
    "country_209": "Суринам",
    "country_210": "Свазиланд",
    "country_211": "Швед",
    "country_212": "Швейцарь",
    "country_213": "Сири",
    "country_214": "Тайвань",
    "country_215": "Тажикстан",
    "country_216": "Танзани",
    "country_217": "Загвар:Улс өгөгдөл SJM Шпицберд",
    "country_218": "Тайланд",
    "country_219": "Багамын арлууд",
    "country_220": " Комор",
    "country_221": " Филиппин",
    "country_222": "Зүүн Тимор (Зүүн Тимор)",
    "country_223": "Явах",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринидад ба Тобаго",
    "country_227": "Тунис",
    "country_228": "Турк",
    "country_229": "Туркменистан",
    "country_230": "Туркс ба Кайкосын арлууд",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Украин",
    "country_234": "Арабын нэгдсэн Эмират улс",
    "country_235": "АНУ-ын Бага заад арлууд",
    "country_236": "Америкийн Нэгдсэн Улс (АНУ)",
    "country_237": "АНУ Виржиний арлууд",
    "country_238": "Уругвай",
    "country_239": "Узбекистан",
    "country_240": "Вануату",
    "country_241": "Ватикан хот ( Гэгээн                               ",
    "country_242": "Венесуэл",
    "country_243": "Вьетнам",
    "country_244": "Уоллис болон Футуна",
    "country_245": "Баруун Сахар",
    "country_246": "Йемен",
    "country_247": "Замби",
    "country_248": "Зимбабве",
    "google_login": "Google-ээр нэвтэрнэ үү",
    "State": "муж",
    "Activation_code": "Идэвхжүүлэх код",
    "Question": "Нэвтэрсэн бүх програмаа жагсаана уу",
    "Copy_complete": "Хуулж дууслаа",
    "footer": "Зохиогчийн эрх © 2024 MindOnMap Studio. Бүх эрх хуулиар хамгаалагдсан.",
    "change_password_success": "Нууц үг амжилттай өөрчлөгдсөн",
    "successful_login_title": "Амжилттай нэвтэрлээ",
    "product_page": "Бүтээгдэхүүний хуудас>>",
    "successful_login_info": "Нэвтэрч дууссан. Дараах үйл явцыг үргэлжлүүлэхийн тулд одоогийн хуудсыг хааж, анхны таб руу буцна уу. Одоогийн хуудас 5 секундын дараа автоматаар хаагдах болно. Хэрэв \"Дууссан\" товчийг дарж автоматаар хаах эсвэл хаах боломжгүй бол энэ табыг шууд хаана уу.",
    "successful_login_info_firefox": "Нэвтэрч дууссан. Дараах үйл явцыг үргэлжлүүлэхийн тулд одоогийн хуудсыг хааж, анхны таб руу буцна уу.",
    "my_account": "Миний данс",
    "my_history": "Миний түүх",
    "remove_watermark": "Watermark устгах",
    "no_history": "Түүх байхгүй",
    "history_all": "Бүгдийг сонгох",
    "history_open": "Нээлттэй",
    "history_down": "Татаж авах",
    "history_delete": "Устгах",
    "history_clear": "Хүчингүйг арилгах",
    "images": "зураг(ууд)",
    "use_this_function": "Энэ бүтээгдэхүүнийг хэрэглээрэй>>",
    "hd_downloading": "HD оригинал зураг татахын давуу тал:",
    "lifetimeRemaining": "Насан туршдаа-Үлдсэн",
    "Remaining": "Үлдсэн",
    "email_verification": "Имэйл баталгаажуулалт",
    "email_verification_info": "Бид таны <span class=\"email\"></span> имэйл рүү баталгаажуулах мессежийг илгээсэн тул баталгаажуулалтыг дуусгана уу. Баталгаажуулсны дараа ашиг тус автоматаар синхрончлогдох болно.",
    "wrong_email": "Имэйл хаяг буруу байна уу?",
    "click_here_to_modify": "Энд дарж өөрчилнө үү",
    "get_more_help": "Илүү тусламж авах уу?",
    "click_here": "Энд дар",
    "email_verification_info_success": "Имэйл бүртгэлээ баталгаажуулсанд баяр хүргэе.",
    "email_verification_info_error": "Хугацаа нь дууссан холбоосын улмаас баталгаажуулалт амжилтгүй боллоо.",
    "registration_succeeded": "Бүртгэл амжилттай боллоо",
    "registration_succeeded_info_1": "Баяр хүргэе! Та амжилттай бүртгүүллээ. Бид таны <span class=\"email\"></span> имэйл рүү баталгаажуулах мессежийг илгээсэн бөгөөд энэ имэйлийн давуу талыг ашиглахын тулд баталгаажуулж дуусгана уу.",
    "registration_succeeded_info_2": "Нүүр хуудас руу буцаж очоод энэ бүтээгдэхүүнийг ашиглахын тулд \"Дууссан\" дээр дарна уу.",
    "registration_succeeded_info_3": "\"Дууссан\" дээр дарж одоогийн хуудсыг хааж, дараах үйлдлүүдийг хийхийн тулд нүүр хуудас руу буцна уу. Хэрэв хаах амжилтгүй бол табыг гараар хаана уу.",
    "verify_email": "И-мэйлээ баталгаажуул",
    "registered_email_not_verified": "Бүртгүүлсэн имэйлийг баталгаажуулаагүй байна, яаралтай баталгаажуулна уу.",
    "email_verification_time_1": "Баталгаажуулах имэйл хүлээж аваагүй юу?",
    "email_verification_time_2": "Дараа нь",
    "email_verification_time_3": "Энд дарж дахин илгээнэ үү",
    "error_26301": "Алдааны код: 26301, бидэнтэй холбоо барина уу",
    "error_26302": "Алдааны код: 26302, бидэнтэй холбоо барина уу",
    "error_26303": "Имэйл форматын алдаа (алдааны код: 26303). Үүнийг дахин оруулна уу",
    "error_26304": "8 тэмдэгтээс дээш нууц үг оруулахыг зөвлөж байна (алдааны код: 26304)",
    "error_26305": "Reuqest амжилтгүй болсон (алдааны код: 26305). Дахин оролдоно уу",
    "error_26306": "Имэйл бүртгэгдсэн тул <a href=\"https://account.mindonmap.com/login/\">нэвтэрнэ үү</a>",
    "error_26307": "Reuqest амжилтгүй болсон (алдааны код: 26307). Дахин оролдоно уу",
    "error_26308": "Reuqest амжилтгүй болсон (алдааны код: 26308). Дахин оролдоно уу",
    "error_26401": "Алдааны код: 26401, дахин оролдоно уу",
    "error_26402": "Имэйлийг баталгаажуулсан (алдааны код: 26402), дахин оролдоно уу",
    "error_26403": "Reuqest амжилтгүй болсон (алдааны код: 26403). Дахин оролдоно уу",
    "error_26404": "Хүсэлт амжилтгүй болсон (алдааны код: 26404). Дахин оролдоно уу",
    "error_26501": "Алдааны код: 26501, бидэнтэй холбоо барина уу",
    "error_26502": "Алдааны код: 26502, бидэнтэй холбоо барина уу",
    "error_26503": "Имэйлийн форматын алдаа (алдааны код: 26503). Үүнийг дахин оруулна уу",
    "error_26504": "Reuqest амжилтгүй болсон (алдааны код: 26504). Дахин оролдоно уу",
    "error_26505": "Имэйлийг бүртгүүлээгүй байгаа тул <a href=\"https://account.mindonmap.com/register/\">эхлээд бүртгүүлнэ үү</a>",
    "error_26506": "Имэйлийг баталгаажуулсан.",
    "error_26507": "Reuqest амжилтгүй болсон (алдааны код: 26507). Дахин оролдоно уу",
    "error_26508": "Баталгаажуулалт хийгдсэн (алдааны код: 26508), дахин оролдоно уу",
    "error_26509": "Хүсэлт амжилтгүй боллоо (алдааны код: 26509), дахин оролдоно уу",
    "error_26510": "Алдааны код: 26510, бидэнтэй холбоо барина уу",
    "error_26601": "Алдааны код: 26601, бидэнтэй холбоо барина уу",
    "error_26602": "Алдааны код: 26602, бидэнтэй холбоо барина уу",
    "error_26603": "Reuqest амжилтгүй болсон (алдааны код: 26603). Дахин оролдоно уу",
    "error_26604": "Алдааны код: 26604, бидэнтэй холбоо барина уу",
    "error_26605": "Алдааны код: 26605, бидэнтэй холбоо барина уу",
    "error_26701": "Алдааны код: 26701, бидэнтэй холбоо барина уу",
    "error_26702": "Reuqest амжилтгүй болсон (алдааны код: 26702). Дахин оролдоно уу",
    "error_26703": "Алдааны код: 26703, бидэнтэй холбоо барина уу",
    "error_26704": "Алдааны код: 26704, бидэнтэй холбоо барина уу",
    "error_26705": "Нэвтрэхийг хүлээнэ үү (алдааны код: 26705). Дахин оролдоно уу",
    "no_cookie": "Та хөтөч дээрээ \"Бүх күүкиг блоклох\" функцийг асаасан тул нэвтэрч чадахгүй байна. \"Тохиргоо\" хэсэгт \"Бүх күүкиг зөвшөөрөх\" нүдийг чагтална уу.",
    "error_26801": "Алдааны код: 26801, бидэнтэй холбоо барина уу",
    "error_26802": "Алдааны код: 26802, бидэнтэй холбоо барина уу",
    "error_26803": "Хүсэлт амжилтгүй боллоо (алдааны код: 26803). Дахин оролдоно уу",
    "error_26804": "Хүсэлт амжилтгүй боллоо (алдааны код: 26804). Дахин оролдоно уу",
    "error_order": "Хүсэлт амжилтгүй боллоо (алдааны код: 27098), дахин оролдоно уу!",
    "error_order1": "Захиалгын асуулга дутуу байна（алдааны код: ",
    "error_order2": "）, дахин ачаалаад дахин оролдоно уу.",
    "modify_email_title": "Имэйлийг өөрчлөх",
    "modify_email_info": "Та өөрчилсөн имэйлийг ашиглан бүртгэлдээ нэвтэрч болно.",
    "images_per": "Зураг тус бүр",
    "error_26101": "Алдаа: 26101. Бидэнтэй холбоо барина уу",
    "error_26102": "Алдаа: 26102. Бидэнтэй холбоо барина уу",
    "error_26103": "Хүсэлт амжилтгүй болсон (алдааны код:26103). Дахин  оролдоно уу",
    "error_26104": "Алдааны код: 26104, дахин оролдоно уу",
    "error_26105": "Алдааны код: 26105, дахин оролдоно уу",
    "error_26106": "Устгаж чадсангүй (алдааны код: 26106). Дахин оролдоно уу",
    "error_26201": "Алдаа: 26201. Бидэнтэй холбоо барина уу",
    "error_26202": "Хүсэлт амжилтгүй болсон (алдааны код:26202). Дахин  оролдоно уу",
    "error_26001": "Алдаа: 26001. Бидэнтэй холбоо барина уу",
    "error_26002": "Алдаа: 26002. Бидэнтэй холбоо барина уу",
    "error_26003": "Алдаа: 26003. Бидэнтэй холбоо барина уу",
    "error_26004": "Алдаа: 26004. Бидэнтэй холбоо барина уу",
    "error_26005": "Хүсэлт амжилтгүй болсон (алдааны код:26005). Дахин  оролдоно уу",
    "error_26006": "Алдааны код: 26006, дахин оролдоно уу",
    "error_26008": "Алдаа: 26008. Бидэнтэй холбоо барина уу",
    "go_to_the_home_page": "Нүүр хуудас руу очно уу",
    "error_27101": "Хүсэлт амжилтгүй боллоо (алдааны код: 27101). Дахин оролдоно уу",
    "error_27201": "Алдааны код: 27201, бидэнтэй холбоо барина уу",
    "error_27202": "Алдааны код: 27202, дахин оролдоно уу",
    "error_27203": "Хүсэлт амжилтгүй болсон (алдааны код: 27203). Дахин оролдоно уу",
    "error_27204": "Буруу код (алдааны код: 27204).",
    "error_27205": "Хүсэлт амжилтгүй болсон (алдааны код: 27205). Дахин оролдоно уу",
    "error_27206": "Хүсэлт амжилтгүй болсон (алдааны код: 27206). Дахин оролдоно уу",
    "error_27207": "Хүсэлт амжилтгүй болсон (алдааны код: 27207). Дахин оролдоно уу",
    "no_history_found": "Та ямар ч хэрэгсэл ашиглаагүй байна! <a href=\"/\">Сэргээх</a> эсвэл <a href=\"https://www.mindonmap.com/\">Албан ёсны вэбсайт руу очно уу</a>",
    "error_25301": "Алдаа: 25301. Бидэнтэй холбоо барина уу",
    "error_25302": "Алдаа: 25302. Бидэнтэй холбоо барина уу",
    "error_25303": "Хүсэлт амжилтгүй болсон (алдааны код: 25303). Дахин оролдоно уу",
    "error_25304": "Хүсэлт амжилтгүй болсон (алдааны код: 25304). Дахин оролдоно уу",
    "error_25305": "Хүсэлт амжилтгүй болсон (алдааны код: 25305). Дахин оролдоно уу",
    "error_25306": "Хүсэлт амжилтгүй болсон (алдаа код: 25306). Дахин оролдоно уу",
    "image_upscaler_p": "Усан тэмдэггүй зураг татахын давуу тал:",
    "Available_for": "Д боломжтой:",
    "credit_per": "HD зураг тутамд %s кредит",
    "still_valid": "Худалдан авсан төлөвлөгөө(үүд) хүчинтэй хэвээр байна",
    "credit": "зээл(үүд)",
    "pc_3rd_info": "Амжилттай нэвтэрлээ. Цаашид үйл ажиллагаа явуулахын тулд програм руу очно уу.",
    "use_online": "Онлайн үйлчилгээг ашиглана уу",
    "use_download": "Ширээний програмыг ашигла",
    "use_immediately": "Яаралтай хэрэглээрэй",
    "Use_in_browser": "Хөтөч дээр ашиглах",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "Сард {%} кредит",
    "expire": "Дуусах хугацаа:",
    "viewDetails": "Дэлгэрэнгүй мэдээллийг харах",
    "viewHistory": "Түүхийн ашиг тусыг харах>>",
    "viewDetailsInfo": "Анхааруулга: Хэрэв захиалгын тэтгэмжийг хугацаа дууссанаас хойш 7 хоногийн дотор сунгавал ашиглагдаагүй тэтгэмжийг үргэлжлүүлэн ашиглах боломжтой. Мөн дуусах хугацаа нь шинэ захиалгын хугацаа дуусах хүртэл автоматаар шинэчлэгдэх болно. Хугацаа дууссанаас хойш 7 хоногийн дараа шинэ захиалга байхгүй бол бүх хугацаа нь дууссан тэтгэмжийг арилгана.",
    "connect_account": "Имэйлийг өөрийн данс руу холбоно уу",
    "connect_account_info": "Зайлсны дараа та энэ имэйл хаягаар нэвтэрч болно.",
    "connect_now": "Одоо холбох",
    "no_email_bind": "Имэйл холболт байхгүй",
    "bind_email": "Имэйлийг холбох",
    "connect_your_email_placeholder": "Имэйл хаягаа оруулна уу",
    "bind_an_email": "Имэйл холбох",
    "bind_info": "Та амжилттай нэвтэрлээ. Үр өгөөжөө идэвхжүүлэхийн тулд акаунтадаа имэйл илгээнэ үү.",
    "bind_later": "Дараа нь холбоно уу",
    "hi": "Сайн уу, %s!",
    "Personal_Information": "Хувийн мэдээлэл",
    "Access": "Хандалт",
    "Subscription_Plan": "(Захиалгын төлөвлөгөө)",
    "No_orders": "Захиалга олдсонгүй.",
    "No_data": "Өгөгдөл алга",
    "Featured_Products": "Онцлох бүтээгдэхүүн",
    "More_Products": "Илүү олон бүтээгдэхүүн",
    "Free_Download": "Үнэгүй татах",
    "Get_Started": "Эхэлцгээе",
    "Subscribe": "Бүртгүүлэх",
    "Verified": "Баталгаажсан",
    "back_to_account_center": "Дансны төв рүү буцах",
    "success": "Амжилт!",
    "successfully": "Та бүртгэлээ амжилттай бүртгүүллээ.",
    "Continue": "Үргэлжлүүлэх",
    "Already": "Бүртгэлтэй юу?",
    "loading_verification": "Баталгаажуулах статусыг шалгаж байна...",
    "email_no_verification": "Уучлаарай, бүртгүүлсэн имэйл хаягийг баталгаажуулаагүй байна. Дээрх зааврын дагуу баталгаажуулалтыг хийж, \"Дууссан\" товчийг дахин дарж бүртгэлээ дуусгана уу.",
    "will_expire_after": "Дараа нь хугацаа нь дуусна",
    "hours": "цаг",
  },
  "mr": {
    "overtime": "त्रुटी कोड: {%}, कृपया पुन्हा प्रयत्न करा",
    "isnetwork": "इंटरनेट त्रुटी. कृपया तपासा आणि पुन्हा प्रयत्न करा",
    "email_placeholder": "ईमेल",
    "email_empty": "कृपया ईमेल इनपुट करा",
    "email_not_valid": "ईमेल वैध नाही",
    "email_not_valid_1": "कृपया तुमचा ईमेल इनपुट करा",
    "email_not_valid_2": "ईमेल वैध नाही, कृपया वेगळा पत्ता वापरा.",
    "email_not_valid_3": "ईमेल इनपुट नाही",
    "password_placeholder": "पासवर्ड",
    "password_empty": "कृपया पासवर्ड प्रविष्ट करा",
    "password_not_valid": "चुकीचे खाते किंवा पासवर्ड",
    "password_not_valid_1": "8 वर्णांवरील पासवर्ड आवश्यक आहे",
    "password_not_valid_2": "कृपया पासवर्ड तयार करा",
    "password_placeholder_1": "आपला संकेतशब्द तयार करा",
    "password_placeholder_2": "तुमच्या पासवर्डची पुष्टी करा",
    "password_placeholder_3": "नवीन पासवर्ड तयार करा",
    "password_placeholder_4": "नवीन परवलीच्या शब्दाची खात्री करा",
    "password_placeholder_5": "जुना पासवर्ड टाका",
    "copy_password_empty": "कृपया पासवर्डची पुष्टी करा",
    "copy_password_not_valid": "कृपया तुमच्या पासवर्डची पुष्टी करा",
    "copy_passwords_inconsistent": "तुमचा पासवर्ड पुष्टीकरण जुळत नाही",
    "code_empty": "कृपया सत्यापन कोड प्रविष्ट करा",
    "code_not_valid": "अवैध सत्यापन कोड",
    "code_placeholder": "सत्यापन कोड",
    "not_received_code": "तुमच्या मेलबॉक्सला बर्याच काळापासून पडताळणी कोड न मिळाल्यास, कृपया पुन्हा पडताळणी कोड मिळवा.",
    "get_first_code": "कृपया प्रथम सत्यापन कोड मिळवा.",
    "last_name_placeholder": "कृपया तुमचे आडनाव प्रविष्ट करा",
    "first_name_placeholder": "कृपया तुमचे नाव प्रविष्ट करा",
    "address_placeholder": "कृपया तुमचा पत्ता प्रविष्ट करा",
    "no_code_text": "आम्ही पडताळणी कोड पाठवला आहे. कृपया तुमचा कोड टाका. <span class='tips'>कोड मिळाला नाही?",
    "no_code_text_1": "1. कृपया खात्री करा की ईमेल पत्ता वैध आहे आणि तो ईमेल प्राप्त करू शकतो.",
    "no_code_text_2": "2. ईमेल सिस्टमद्वारे स्वयंचलितपणे पाठवले जाते, ते स्पॅम किंवा जंक ईमेल म्हणून ध्वजांकित केले जाऊ शकते. कृपया ईमेल कचरा फोल्डरमध्ये आहे का ते तपासा.",
    "no_code_text_3": "3. तुमची समस्या सोडवू शकत नाही? ",
    "no_code_text_3_span": "मग आमच्याशी संपर्क साधण्यासाठी येथे क्लिक करा.",
    "order_no": "तुम्ही कोणतेही उत्पादन खरेदी केलेले नाही, तुम्हाला काही प्रश्न असल्यास, कृपया <a href=\"https://www.mindonmap.com/contact-us/\">आमच्याशी संपर्क साधा</a>.",
    "error_24901": "चालू खात्यात कोणताही ईमेल लिंक केलेला नाही आणि ऑर्डर शोधू शकत नाही. कृपया ईमेल कनेक्ट करा.",
    "user_guide": "वापरकर्ता मार्गदर्शक >>",
    "download": "डाउनलोड करा",
    "order_number": "ऑर्डर क्रमांक:",
    "Refund": "परतावा",
    "Disabled": "अक्षम",
    "Normal": "सामान्य",
    "Modify": "सुधारित करा",
    "Modify_1": "बदला>>",
    "Connect": "कनेक्ट करा",
    "unlink_success": "यशस्वीरित्या अनलिंक करा",
    "connect_success": "यशस्वीरित्या कनेक्ट करा",
    "feedback_title": "तुमच्या अभिप्रायाबद्दल धन्यवाद. कृपया तुमची समस्या सोडा आणि आम्ही तुम्हाला 24 तासांच्या आत प्रतिसाद देऊ.",
    "feedback_thank_you": "धन्यवाद!<br />तुमचा अभिप्राय यशस्वीरित्या सबमिट केला गेला आहे.",
    "feedback_email": "येथे तुमचा ईमेल प्रविष्ट करा!",
    "feedback_content": "तुम्हाला आलेली कोणतीही समस्या किंवा सूचना येथे द्या.",
    "feedback_submit": "प्रस्तुत करणे",
    "form_contents": "तुम्ही कोणतेही वर्णन टाकलेले नाही. कृपया ते प्रविष्ट करा आणि पुन्हा सबमिट करा.",
    "old_password": "कृपया जुना पासवर्ड टाका",
    "new_password": "कृपया नवीन पासवर्ड तयार करा",
    "old_new_password": "नवीन पासवर्ड जुन्या सारखा असू शकत नाही",
    "incorrect_password": "चुकीचा पासवर्ड",
    "delete_no": "आता हटवा",
    "Caps": "कॅप्स लॉक चालू आहे",
    "Get": "मिळवा",
    "Done": "झाले",
    "error_20001": "त्रुटी: 20001. कृपया पुन्हा लॉग इन करा.",
    "error_20002": "त्रुटी: 20002. कृपया पुन्हा लॉग इन करा.",
    "error_20003": "त्रुटी: 20003. कृपया पुन्हा लॉग इन करा.",
    "error_20004": "विनंती अयशस्वी (त्रुटी कोड: 20004). कृपया पुन्हा प्रयत्न करा.",
    "error_20005": "लॉगिन सत्र कालबाह्य झाले आहे (त्रुटी: 20005). कृपया पुन्हा लॉग इन करा.",
    "error_20006": "विनंती अयशस्वी (त्रुटी कोड: 20006). कृपया पुन्हा प्रयत्न करा.",
    "error_20007": "लॉगिन सत्र कालबाह्य झाले आहे (त्रुटी: 20007). कृपया पुन्हा लॉग इन करा.",
    "error_20008": "लॉगिन सत्र कालबाह्य झाले आहे (त्रुटी: 20008). कृपया पुन्हा लॉग इन करा.",
    "error_20009": "लॉगिन सत्र कालबाह्य झाले आहे (त्रुटी: 20009). कृपया पुन्हा लॉग इन करा.",
    "error_20101": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20101)",
    "error_20102": "ईमेल वैध नाही (त्रुटी कोड: 20102)",
    "error_20103": "विनंती अयशस्वी (त्रुटी कोड: 20103). कृपया पुन्हा प्रयत्न करा",
    "error_20104": "ईमेल आधीपासूनच वापरात आहे, कृपया <a href=\"https://account.mindonmap.com/login/\">लॉग इन करा</a> किंवा नवीनसह नोंदणी करा",
    "error_20105": "विनंती अयशस्वी (त्रुटी कोड: 20105). कृपया पुन्हा प्रयत्न करा",
    "error_20106": "ईमेल पाठवण्यात अयशस्वी, कृपया पुन्हा प्रयत्न करा",
    "error_20201": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20201)",
    "error_20202": "कृपया तुमचा पासवर्ड प्रविष्ट करा (त्रुटी कोड: 20202)",
    "error_20203": "कृपया सत्यापन कोड प्रविष्ट करा (त्रुटी कोड: 20203)",
    "error_20204": "ईमेल वैध नाही (त्रुटी कोड: 20204)",
    "error_20205": "8 वर्णांवरील पासवर्ड आवश्यक आहे (त्रुटी कोड: 20205)",
    "error_20206": "विनंती अयशस्वी झाली (एरर कोड: 20206). कृपया पुन्हा प्रयत्न करा",
    "error_20207": "अवैध सत्यापन कोड",
    "error_20208": "विनंती अयशस्वी झाली (एरर कोड: 20208). कृपया पुन्हा प्रयत्न करा",
    "error_20209": "विनंती अयशस्वी झाली (एरर कोड: 20209). कृपया पुन्हा प्रयत्न करा",
    "error_20301": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20301)",
    "error_20302": "त्रुटी: 20302. कृपया आमच्याशी संपर्क साधा",
    "error_20303": "ईमेल वैध नाही (त्रुटी कोड: 20303)",
    "error_20304": "विनंती अयशस्वी झाली (त्रुटी कोड: 20304). कृपया पुन्हा प्रयत्न करा",
    "error_20305": "खाते अस्तित्वात नाही. कृपया पुन्हा-एंटर करा किंवा प्रथम <a href=\"https://account.mindonmap.com/register/\">ते तयार करा</a>.",
    "error_20306": "अजून पासवर्ड नाही. <a href=\"https://account.mindonmap.com/passwordless-login/\">पासवर्डलेस लॉगिन</a> किंवा <a href=\"https://account.mindonmap.com/create-password/\"> वापरा पासवर्ड सेट करा</a> आणि लॉग इन करा.",
    "error_20308": "विनंती अयशस्वी झाली (त्रुटी कोड: 20308). कृपया पुन्हा प्रयत्न करा",
    "error_20401": "लॉग आउट करण्यात अयशस्वी (त्रुटी कोड: 20401). कृपया पुन्हा प्रयत्न करा",
    "error_20501": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20501)",
    "error_20502": "ईमेल वैध नाही (त्रुटी कोड: 20502)",
    "error_20503": "विनंती अयशस्वी झाली (त्रुटी कोड: 20503). कृपया पुन्हा प्रयत्न करा",
    "error_20504": "ईमेल पाठवण्यात अयशस्वी. कृपया पुन्हा प्रयत्न करा.",
    "error_20601": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20601)",
    "error_20602": "कृपया सत्यापन कोड प्रविष्ट करा (त्रुटी कोड: 20602)",
    "error_20603": "ईमेल वैध नाही (त्रुटी कोड: 20603)",
    "error_20604": "विनंती अयशस्वी झाली (त्रुटी कोड: 20604). कृपया पुन्हा प्रयत्न करा",
    "error_20606": "विनंती अयशस्वी झाली (त्रुटी कोड: 20606). कृपया पुन्हा प्रयत्न करा",
    "error_20607": "विनंती अयशस्वी झाली (त्रुटी कोड: 20607). कृपया पुन्हा प्रयत्न करा",
    "error_20608": "विनंती अयशस्वी झाली (त्रुटी कोड: 20608). कृपया पुन्हा प्रयत्न करा",
    "error_20701": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20701)",
    "error_20702": "ईमेल वैध नाही (त्रुटी कोड: 20702)",
    "error_20703": "विनंती अयशस्वी झाली (त्रुटी कोड: 20703). कृपया पुन्हा प्रयत्न करा",
    "error_20704": "खाते अस्तित्वात नाही. कृपया पुन्हा-एंटर करा किंवा प्रथम <a href=\"https://account.mindonmap.com/register/\">ते तयार करा</a>.",
    "error_20705": "विनंती अयशस्वी (त्रुटी कोड: 20705). कृपया पुन्हा प्रयत्न करा",
    "error_20706": "ईमेल पाठवण्यात अयशस्वी. कृपया पुन्हा प्रयत्न करा",
    "error_20801": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 20801)",
    "error_20802": "त्रुटी: 20802. कृपया आमच्याशी संपर्क साधा",
    "error_20803": "कृपया सत्यापन कोड प्रविष्ट करा (त्रुटी कोड: 20803)",
    "error_20804": "ईमेल वैध नाही (त्रुटी कोड: 20804)",
    "error_20805": "8 वर्णांवरील पासवर्ड आवश्यक आहे (त्रुटी कोड: 20805)",
    "error_20806": "विनंती अयशस्वी झाली (त्रुटी कोड: 20806). कृपया पुन्हा प्रयत्न करा",
    "error_20808": "विनंती अयशस्वी झाली (त्रुटी कोड: 20808). कृपया पुन्हा प्रयत्न करा",
    "error_20901": "विनंती अयशस्वी झाली (त्रुटी कोड: 20901). कृपया पुन्हा प्रयत्न करा",
    "error_20902": "विनंती अयशस्वी झाली (त्रुटी कोड: 20902). कृपया पुन्हा प्रयत्न करा",
    "error_21000": "बदल जतन केले जातात",
    "error_21001": "कोणतीही माहिती सबमिट केलेली नाही (त्रुटी कोड: 21001)",
    "error_21002": "विनंती अयशस्वी झाली (त्रुटी कोड: 21002). कृपया पुन्हा प्रयत्न करा",
    "error_21101": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 21101)",
    "error_21102": "ईमेल वैध नाही (त्रुटी कोड: 21102)",
    "error_21103": "विनंती अयशस्वी (त्रुटी कोड: 21103), कृपया पुन्हा प्रयत्न करा",
    "error_21104": "ईमेल आधीपासून कनेक्ट केलेले आहे, कृपया नवीन वापरा",
    "error_21105": "विनंती अयशस्वी (त्रुटी कोड: 21105), कृपया पुन्हा प्रयत्न करा",
    "error_21106": "ईमेल पाठवण्यात अयशस्वी. कृपया पुन्हा प्रयत्न करा",
    "error_21201": "कृपया तुमचा ईमेल प्रविष्ट करा (त्रुटी कोड: 21201)",
    "error_21202": "कृपया सत्यापन कोड प्रविष्ट करा (त्रुटी कोड: 21202)",
    "error_21203": "ईमेल वैध नाही (त्रुटी कोड: 21203)",
    "error_21204": "त्रुटी: 21204. कृपया आमच्याशी संपर्क साधा",
    "error_21205": "त्रुटी: 21205. कृपया आमच्याशी संपर्क साधा",
    "error_21206": "8 वर्णांवरील पासवर्ड आवश्यक आहे (त्रुटी कोड: 21206)",
    "error_21207": "विनंती अयशस्वी झाली (त्रुटी कोड: 21207). कृपया पुन्हा प्रयत्न करा",
    "error_21209": "विनंती अयशस्वी झाली (त्रुटी कोड: 21209). कृपया पुन्हा प्रयत्न करा",
    "error_21301": "कृपया जुना पासवर्ड प्रविष्ट करा (त्रुटी कोड: 21301)",
    "error_21302": "कृपया नवीन पासवर्ड तयार करा (त्रुटी कोड: 21302)",
    "error_21303": "नवीन पासवर्ड जुन्या सारखा असू शकत नाही. (त्रुटी: 21303)",
    "error_21304": "8 वर्णांवरील पासवर्ड आवश्यक आहे (त्रुटी कोड: 21304)",
    "error_21306": "विनंती अयशस्वी झाली (त्रुटी कोड: 21306). कृपया पुन्हा प्रयत्न करा",
    "error_21402": "विनंती अयशस्वी झाली (त्रुटी कोड: 21402). कृपया पुन्हा प्रयत्न करा",
    "error_21403": "पडताळणी कोड पाठवण्यात अयशस्वी. कृपया ते पुन्हा पाठवा",
    "error_21500": "खाते हटवले गेले आहे",
    "error_21501": "कृपया सत्यापन कोड प्रविष्ट करा (त्रुटी कोड: 21501)",
    "error_21502": "लॉगिन सत्र कालबाह्य झाले आहे (त्रुटी: 21502). कृपया पुन्हा लॉग इन करा.",
    "error_21503": "विनंती अयशस्वी झाली (त्रुटी कोड: 21503). कृपया पुन्हा प्रयत्न करा",
    "error_21505": "विनंती अयशस्वी (त्रुटी कोड: 21505), कृपया पुन्हा प्रयत्न करा",
    "error_21601": "त्रुटी: 20601. कृपया आमच्याशी संपर्क साधा",
    "error_21602": "अवैध सत्यापन (त्रुटी: 20602). कृपया पुन्हा प्रयत्न करा.",
    "error_21603": "त्रुटी: 20603. कृपया पुन्हा प्रयत्न करा",
    "error_21604": "विनंती अयशस्वी झाली (त्रुटी कोड: 21604). कृपया पुन्हा प्रयत्न करा",
    "error_21606": "विनंती अयशस्वी झाली (त्रुटी कोड: 21606). कृपया पुन्हा प्रयत्न करा",
    "error_21611": "विनंती अयशस्वी झाली (त्रुटी कोड: 21611). कृपया पुन्हा प्रयत्न करा",
    "error_21801": "त्रुटी: 21801. कृपया आमच्याशी संपर्क साधा",
    "error_21802": "विनंती अयशस्वी झाली (त्रुटी: 21802). कृपया पुन्हा प्रयत्न करा",
    "error_21803": "त्रुटी: 21803. कृपया पुन्हा प्रयत्न करा",
    "error_21804": "विनंती अयशस्वी झाली (त्रुटी कोड: 21804). कृपया पुन्हा प्रयत्न करा",
    "error_21806": "त्रुटी: 21806. कृपया पुन्हा प्रयत्न करा",
    "error_21807": "त्रुटी: 21807. कृपया आमच्याशी संपर्क साधा",
    "error_21808": "त्रुटी: 21808. कृपया आमच्याशी संपर्क साधा",
    "error_21809": "त्रुटी: 21809. कृपया आमच्याशी संपर्क साधा",
    "error_21810": "त्रुटी: 21810. कृपया आमच्याशी संपर्क साधा",
    "error_21811": "त्रुटी: 21811. कृपया आमच्याशी संपर्क साधा",
    "error_21812": "त्रुटी: 21812. कृपया आमच्याशी संपर्क साधा",
    "error_21813": "विनंती अयशस्वी झाली (त्रुटी कोड: 21813). कृपया पुन्हा प्रयत्न करा",
    "error_21814": "त्रुटी: 21814. कृपया आमच्याशी संपर्क साधा",
    "error_21815": "विनंती अयशस्वी झाली (त्रुटी कोड: 21815). कृपया पुन्हा प्रयत्न करा",
    "error_21816": "त्रुटी: 21816. कृपया आमच्याशी संपर्क साधा",
    "error_21817": "त्रुटी: 21817. कृपया आमच्याशी संपर्क साधा",
    "error_21818": "त्रुटी: 21818. कृपया आमच्याशी संपर्क साधा",
    "error_21819": "विनंती अयशस्वी झाली (त्रुटी कोड: 21819). कृपया पुन्हा प्रयत्न करा",
    "error_21820": "त्रुटी: 21820. कृपया आमच्याशी संपर्क साधा",
    "error_21821": "त्रुटी: 21821. कृपया आमच्याशी संपर्क साधा",
    "error_21822": "त्रुटी: 21822. कृपया आमच्याशी संपर्क साधा",
    "error_21823": "विनंती अयशस्वी झाली (त्रुटी कोड: 21823). कृपया पुन्हा प्रयत्न करा",
    "error_21824": "विनंती अयशस्वी झाली (त्रुटी कोड: 21824). कृपया पुन्हा प्रयत्न करा",
    "error_21825": "विनंती अयशस्वी झाली (त्रुटी कोड: 21825). कृपया पुन्हा प्रयत्न करा",
    "error_21826": "विनंती अयशस्वी झाली (त्रुटी कोड: 21826). कृपया पुन्हा प्रयत्न करा",
    "error_21901": "त्रुटी: 21901. कृपया आमच्याशी संपर्क साधा",
    "error_21902": "विनंती अयशस्वी झाली (त्रुटी कोड: 21902). कृपया पुन्हा प्रयत्न करा",
    "error_21903": "खात्याची स्थिती बदलली आहे (त्रुटी कोड: 21903), कृपया पृष्ठ रिफ्रेश करा आणि पुन्हा प्रयत्न करा",
    "error_21904": "त्रुटी: 21904. कृपया पुन्हा प्रयत्न करा",
    "error_21905": "त्रुटी: 21905. कृपया पुन्हा प्रयत्न करा",
    "error_21906": "विनंती अयशस्वी झाली (त्रुटी कोड: 21906). कृपया पुन्हा प्रयत्न करा",
    "error_21907": "Google खाते इतर खात्याशी जोडले गेले आहे",
    "error_21908": "विनंती अयशस्वी झाली (त्रुटी कोड: 21908). कृपया पुन्हा प्रयत्न करा",
    "error_22001": "विनंती अयशस्वी झाली (त्रुटी कोड: 22001). कृपया पुन्हा प्रयत्न करा",
    "error_22002": "कोणत्याही अतिरिक्त लॉगिनशिवाय लिंक रद्द करणे अयशस्वी झाले",
    "error_22003": "विनंती अयशस्वी झाली (त्रुटी कोड: 22003). कृपया पुन्हा प्रयत्न करा",
    "error_22101": "त्रुटी: 22101. कृपया आमच्याशी संपर्क साधा",
    "error_22102": "खाते स्थिती बदलली आहे (त्रुटी कोड: 22102), कृपया पृष्ठ रिफ्रेश करा आणि पुन्हा प्रयत्न करा",
    "error_22103": "विनंती अयशस्वी झाली (त्रुटी कोड: 22103). कृपया पुन्हा प्रयत्न करा",
    "error_22104": "खाते स्थिती बदलली आहे (त्रुटी कोड: 22104), कृपया पृष्ठ रिफ्रेश करा आणि पुन्हा प्रयत्न करा",
    "error_22105": "त्रुटी: 22105. कृपया पुन्हा प्रयत्न करा",
    "error_22106": "त्रुटी: 22106. कृपया पुन्हा प्रयत्न करा",
    "error_22107": "त्रुटी: 22107. कृपया आमच्याशी संपर्क साधा",
    "error_22108": "विनंती अयशस्वी झाली (त्रुटी कोड: 22108). कृपया पुन्हा प्रयत्न करा",
    "error_22201": "त्रुटी: 22201. कृपया आमच्याशी संपर्क साधा",
    "error_22202": "अवैध सत्यापन (त्रुटी: 22202). कृपया पुन्हा प्रयत्न करा.",
    "error_22203": "त्रुटी: 22203. कृपया पुन्हा प्रयत्न करा\"",
    "error_22204": "विनंती अयशस्वी झाली (त्रुटी कोड: 22204). कृपया पुन्हा प्रयत्न करा",
    "error_22206": "विनंती अयशस्वी झाली (त्रुटी कोड: 22206). कृपया पुन्हा प्रयत्न करा",
    "error_22401": "त्रुटी: 22401. कृपया आमच्याशी संपर्क साधा",
    "error_22402": "अवैध सत्यापन (त्रुटी: 22402). कृपया पुन्हा प्रयत्न करा.",
    "error_22403": "त्रुटी: 22403. कृपया पुन्हा प्रयत्न करा",
    "error_22404": "विनंती अयशस्वी झाली (त्रुटी कोड: 22404). कृपया पुन्हा प्रयत्न करा",
    "error_22405": "फेसबुक खाते इतर ईमेलशी जोडले गेले आहे",
    "error_22406": "त्रुटी: 22406. कृपया पुन्हा प्रयत्न करा",
    "error_22407": "त्रुटी: 22407. कृपया आमच्याशी संपर्क साधा",
    "error_22408": "त्रुटी: 22408. कृपया आमच्याशी संपर्क साधा",
    "error_22409": "त्रुटी: 22409. कृपया आमच्याशी संपर्क साधा",
    "error_22410": "त्रुटी: 224010. कृपया आमच्याशी संपर्क साधा",
    "error_22411": "त्रुटी: 224011. कृपया आमच्याशी संपर्क साधा",
    "error_22412": "त्रुटी: 224012. कृपया आमच्याशी संपर्क साधा",
    "error_22413": "त्रुटी: 22413. कृपया आमच्याशी संपर्क साधा",
    "error_22414": "विनंती अयशस्वी झाली (त्रुटी कोड: 22414). कृपया पुन्हा प्रयत्न करा",
    "error_22415": "त्रुटी: 22415. कृपया आमच्याशी संपर्क साधा",
    "error_22416": "त्रुटी: 22416. कृपया आमच्याशी संपर्क साधा",
    "error_22417": "त्रुटी: 22417. कृपया आमच्याशी संपर्क साधा",
    "error_22418": "विनंती अयशस्वी झाली (त्रुटी कोड: 22418). कृपया पुन्हा प्रयत्न करा",
    "error_22419": "त्रुटी: 22419. कृपया आमच्याशी संपर्क साधा",
    "error_22420": "त्रुटी: 22420. कृपया आमच्याशी संपर्क साधा",
    "error_22421": "त्रुटी: 22421. कृपया आमच्याशी संपर्क साधा",
    "error_22422": "विनंती अयशस्वी झाली (त्रुटी कोड: 22422). कृपया पुन्हा प्रयत्न करा",
    "error_22423": "विनंती अयशस्वी झाली (त्रुटी कोड: 22423). कृपया पुन्हा प्रयत्न करा",
    "error_22424": "विनंती अयशस्वी झाली (त्रुटी कोड: 22424). कृपया पुन्हा प्रयत्न करा",
    "error_22425": "विनंती अयशस्वी झाली (त्रुटी कोड: 22425). कृपया पुन्हा प्रयत्न करा",
    "error_20098": "त्रुटी कोड: 20098. आपण खाजगी मोडमध्ये ब्राउझ करत असल्यास, कृपया सामान्य मोडवर स्विच करा आणि पुन्हा प्रयत्न करा.",
    "error_22298": "Google साठी विनंती अयशस्वी झाली (त्रुटी: 22298). कृपया पुन्हा प्रयत्न करा.",
    "error_22498": "Facebook साठी विनंती अयशस्वी झाली (त्रुटी: 22498). कृपया पुन्हा प्रयत्न करा.",
    "error_24902": "विनंती अयशस्वी झाली (त्रुटी कोड: 24902). कृपया पुन्हा प्रयत्न करा",
    "error_24903": "विनंती अयशस्वी झाली (त्रुटी कोड: 24903). कृपया पुन्हा प्रयत्न करा",
    "error_24904": "विनंती अयशस्वी झाली (त्रुटी कोड: 24904). कृपया पुन्हा प्रयत्न करा",
    "error_24905": "विनंती अयशस्वी झाली (त्रुटी कोड: 24905). कृपया पुन्हा प्रयत्न करा",
    "login_title": "MindOnMap वर लॉग इन करा",
    "log_in": "लॉग इन करा",
    "no_account": "खाते नाही?",
    "create_it": "ते तयार करा",
    "or_log_in_with": "किंवा यासह लॉग इन करा",
    "passwordless_login": "पासवर्डलेस लॉगिन",
    "log_in_done": "लॉग इन पूर्ण झाले",
    "three_rd_account_connect_info": "अभिनंदन! तुम्ही यशस्वीरित्या लॉग इन केले आहे. आता तुम्ही भविष्यात लॉग इन करण्यासाठी वापरले जाणारे एक ईमेल खाते कनेक्ट करू शकता.",
    "see_my_account": "माझे खाते पहा",
    "three_rd_login_merge_account": "तृतीय-पक्ष खाते ईमेल पत्ता साइन अप केला गेला आहे, तुम्हाला या ईमेल पत्त्यावर थेट कनेक्ट आणि लॉग इन करायचे आहे का?",
    "connect_log_in": "कनेक्ट करा आणि लॉग इन करा",
    "create_an_account": "खाते तयार करा",
    "back_to_log_in": "लॉग इन करण्यासाठी परत या",
    "create_password": "पासवर्ड तयार करा",
    "create_now": "आता तयार करा",
    "password_login_subtitle": "ईमेलसह पासवर्डलेस लॉगिन",
    "account_login": "खाते प्रवेश",
    "rights": "हे खाते तयार करून, तुम्ही <a href=\"https://www.mindonmap.com/terms-and-conditions/\">सेवा अटी</a> आणि <a href=\"https://www.mindonmap.com/privacy-policy/\">गोपनीयता धोरण</a>",
    "passwordless_login_done": "पासवर्डलेस लॉगिन झाले",
    "passwordless_login_info": "अभिनंदन, तुम्ही पासवर्डरहित लॉगिन यशस्वीरित्या पूर्ण केले आहे. तुम्ही या खात्यासाठी पासवर्ड तयार करू शकता आणि भविष्यात खाते आणि पासवर्डसह लॉग इन करू शकता. <a href=\"/create-password\" style=\"display: initial;\">आता तयार करा</a>",
    "sign_up": "साइन अप करा",
    "register_info": "तुमचे खाते तयार करा",
    "reset_now": "आता रीसेट करा",
    "forgot_password": "पासवर्ड विसरलात",
    "reset_password_subtitle": "पासवर्ड रीसेट करण्यासाठी तुमचे खाते ईमेल वापरा",
    "plan_products": "योजना आणि उत्पादने",
    "nick_name": "वापरकर्तानाव:",
    "email": "ईमेल:",
    "my_products": "माझी उत्पादने",
    "my_orders": "माझे आदेश",
    "unlink": "अनलिंक करा",
    "link": "दुवा",
    "connected_accounts": "जोडलेली खाती",
    "last_name": "आडनाव:",
    "first_name": "पहिले नाव:",
    "Gender": "लिंग:",
    "Birth": "जन्म:",
    "Month": "महिना",
    "Year": "वर्ष",
    "Country_Region": "देश/प्रदेश:",
    "Address": "पत्ता:",
    "Save": "जतन करा",
    "Date": "तारीख",
    "Male": "पुरुष",
    "Female": "स्त्री",
    "Unspecified": "अनिर्दिष्ट",
    "Security": "सुरक्षा",
    "change_password": "पासवर्ड बदला",
    "change_now": "आता बदला",
    "connect_email": "ईमेल कनेक्ट करा",
    "delete_account": "खाते हटवा",
    "delete_account_info": "जेव्हा तुमचे खाते हटवले जाईल, तेव्हा तुमच्या खात्याशी संबंधित माझ्या खात्यातील सर्व डेटा कायमचा हटवला जाईल आणि तुम्ही तो पुनर्प्राप्त करू शकणार नाही. आम्ही तुम्हाला सावधपणे चालण्याची शिफारस करतो.",
    "Delete": "हटवा",
    "Logout": "बाहेर पडणे",
    "my_profile": "माझे प्रोफाइल",
    "guides_faqs": "मार्गदर्शक आणि वारंवार विचारले जाणारे प्रश्न",
    "More": "अधिक",
    "guides": "मार्गदर्शक",
    "register": "नोंदणी करा",
    "hot_faq": "गरम FAQ",
    "Contents": "सामग्री:",
    "contact_us": "आमच्याशी संपर्क साधा >>",
    "plan": "योजना",
    "unregistered": "नोंदणी नसलेली",
    "buy_more": "अधिक खरेदी करा",
    "buy_again": "पुन्हा खरेदी करा",
    "buy_now": "आता खरेदी करा",
    "free_no_limit": "विनामूल्य आणि कोणतीही मर्यादा नाही",
    "expired": "कालबाह्य",
    "lifetime": "आयुष्यभर",
    "remain": "राहिले",
    "day_s": "दिवस",
    "error_24801": "विनंती अयशस्वी झाली (त्रुटी कोड: 24801). कृपया पुन्हा प्रयत्न करा",
    "no_app_found": "कोणतेही ॲप आढळले नाही!<a href=\"/\">रिफ्रेश</a> किंवा <a href=\"https://www.mindonmap.com/\">अधिकृत वेबसाइटवर जा</a>",
    "get_more": "अधिक मिळवा >>",
    "edit_photo": "फोटो संपादित करा",
    "select_photo": "फोटो निवडा",
    "change_photo": "फोटो बदला",
    "cancel": "रद्द करा",
    "hide_password": "पासवर्ड लपवा",
    "show_password": "संकेतशब्द दर्शवा",
    "zoom_in": "प्रतिमेचे दृष्य रूप मोठे करा",
    "zoom_out": "झूम कमी करा",
    "rotate": "फिरवा",
    "horizontal_flip": "क्षैतिज फ्लिप",
    "vertical_flip": "उभ्या फ्लिप",
    "country": "देश",
    "country_1": "तुमचा देश/प्रदेश निवडा",
    "country_2": "आलँड बेट",
    "country_3": "अफगाणिस्तान",
    "country_4": "अल्बेनिया",
    "country_5": "अल्जेरिया",
    "country_6": "अमेरिकन सामोआ",
    "country_7": "अंडोरा",
    "country_8": "अंगोला",
    "country_9": "अँगुइला",
    "country_10": "अंटार्क्टिका",
    "country_11": "अँटिग्वा आणि बार्बुडा",
    "country_12": "अर्जेंटिना",
    "country_13": "आर्मेनिया",
    "country_14": "अरुबा",
    "country_15": "ऑस्ट्रेलिया",
    "country_16": "ऑस्ट्रिया",
    "country_17": "अझरबैजान",
    "country_18": "बहारीन",
    "country_19": "बांगलादेश",
    "country_20": "बार्बाडोस",
    "country_21": "बेलारूस",
    "country_22": "बेल्जियम",
    "country_23": "बेलीज",
    "country_24": "बेनिन",
    "country_25": "बर्म्युडा",
    "country_26": "भूतान",
    "country_27": "बोलिव्हिया",
    "country_28": "बोस्निया आणि हर्झेगोविना",
    "country_29": "बोत्सवाना",
    "country_30": "बुवेट बेट",
    "country_31": "ब्राझील",
    "country_32": "ब्रिटिश हिंद महासागर प्रदेश",
    "country_33": "ब्रिटिश व्हर्जिन बेटे",
    "country_34": "ब्रुनेई",
    "country_35": "बल्गेरिया",
    "country_36": "बुर्किना",
    "country_37": "बुरुंडी",
    "country_38": "कंबोडिया",
    "country_39": "कॅमेरून",
    "country_40": "कॅनडा",
    "country_41": "केप वर्दे",
    "country_42": "कॅरिबियन नेदरलँड",
    "country_43": "केमन बेटे",
    "country_44": "सेंट्रल आफ्रिकन रिपब्लिक",
    "country_45": "चाड",
    "country_46": "चिली",
    "country_47": "चीन",
    "country_48": "ख्रिसमस बेट",
    "country_49": "कोकोस (कीलिंग) बेटे",
    "country_50": "कोलंबिया",
    "country_51": "कुक बेटे",
    "country_52": "कॉस्टा रिका",
    "country_53": "आयव्हरी कोट",
    "country_54": "क्रोएशिया",
    "country_55": "क्युबा",
    "country_56": "सायप्रस",
    "country_57": "झेक प्रजासत्ताक",
    "country_58": "काँगोचे लोकशाही प्रजासत्ताक",
    "country_59": "डेन्मार्क",
    "country_60": "जिबूती",
    "country_61": "डोमिनिका",
    "country_62": "डोमिनिकन रिपब्लीक",
    "country_63": "इक्वेडोर",
    "country_64": "इजिप्त",
    "country_65": "एल साल्वाडोर",
    "country_66": "विषुववृत्तीय गिनी",
    "country_67": "इरिट्रिया",
    "country_68": "एस्टोनिया",
    "country_69": "इथिओपिया",
    "country_70": "फॉकलंड बेटे",
    "country_71": "फॅरो बेटे",
    "country_72": "मायक्रोनेशियाचे संघराज्य",
    "country_73": "फिजी",
    "country_74": "फिनलंड",
    "country_75": "फ्रान्स",
    "country_76": "फ्रेंच गयाना",
    "country_77": "फ्रेंच पॉलिनेशिया",
    "country_78": "फ्रेंच दक्षिणी प्रदेश",
    "country_79": "गॅबॉन",
    "country_80": "गॅम्बिया",
    "country_81": "जॉर्जिया",
    "country_82": "जर्मनी",
    "country_83": "घाना",
    "country_84": "जिब्राल्टर",
    "country_85": "ग्रेट ब्रिटन (युनायटेड किंगडम; इंग्लंड)",
    "country_86": "ग्रीस",
    "country_87": "ग्रीनलँड",
    "country_88": "ग्रेनेडा",
    "country_89": "ग्वाडेलूप",
    "country_90": "ग्वाम",
    "country_91": "ग्वाटेमाला",
    "country_92": "ग्वेर्नसे",
    "country_93": "गिनी",
    "country_94": "गिनी-बिसाऊ",
    "country_95": "गयाना",
    "country_96": "हैती",
    "country_97": "हर्ड आयलँड आणि मॅकडोनाल्ड बेटे",
    "country_98": "होंडुरास",
    "country_99": "हाँगकाँग",
    "country_100": "हंगेरी",
    "country_101": "आइसलँड",
    "country_102": "भारत",
    "country_103": "इंडोनेशिया",
    "country_104": "इराण",
    "country_105": "इराक",
    "country_106": "आयर्लंड",
    "country_107": "आयल ऑफ मॅन",
    "country_108": "इस्रायल",
    "country_109": "इटली",
    "country_110": "जमैका",
    "country_111": "जपान",
    "country_112": "जर्सी",
    "country_113": "जॉर्डन",
    "country_114": "कझाकस्तान",
    "country_115": "केनिया",
    "country_116": "किरिबाती",
    "country_117": "कुवेत",
    "country_118": "किर्गिझस्तान",
    "country_119": "लाओस",
    "country_120": "लाटविया",
    "country_121": "लेबनॉन",
    "country_122": "लेसोथो",
    "country_123": "लायबेरिया",
    "country_124": "लिबिया",
    "country_125": "लिकटेंस्टाईन",
    "country_126": "लिथुआनिया",
    "country_127": "लक्झेंबर्ग",
    "country_128": "मकाओ",
    "country_129": "मादागास्कर",
    "country_130": "मलावी",
    "country_131": "मलेशिया",
    "country_132": "मालदीव",
    "country_133": "माली",
    "country_134": "माल्टा",
    "country_135": "मार्शल बेटे",
    "country_136": "मार्टिनिक",
    "country_137": "मॉरिटानिया",
    "country_138": "मॉरिशस",
    "country_139": "मेयोट",
    "country_140": "मेक्सिको",
    "country_141": "मोल्दोव्हा",
    "country_142": "मोनॅको",
    "country_143": "मंगोलिया",
    "country_144": "माँटेनिग्रो",
    "country_145": "मोन्सेरात",
    "country_146": "मोरोक्को",
    "country_147": "मोझांबिक",
    "country_148": "म्यानमार (बर्मा)",
    "country_149": "नामिबिया",
    "country_150": "नौरू",
    "country_151": "नेपाळ",
    "country_152": "नेदरलँड",
    "country_153": "न्यू कॅलेडोनिया",
    "country_154": "न्युझीलँड",
    "country_155": "निकाराग्वा",
    "country_156": "नायजर",
    "country_157": "नायजेरिया",
    "country_158": "नियू",
    "country_159": "नॉरफोक बेट",
    "country_160": "उत्तर कोरिया",
    "country_161": "उत्तरी मारियाना बेटे",
    "country_162": "नॉर्वे",
    "country_163": "ओमान",
    "country_164": "पाकिस्तान",
    "country_165": "पलाऊ",
    "country_166": "पॅलेस्टिनी प्रदेश",
    "country_167": "पनामा",
    "country_168": "पापुआ न्यू गिनी",
    "country_169": "पॅराग्वे",
    "country_170": "पेरू",
    "country_171": "पिटकेर्न बेटे",
    "country_172": "पोलंड",
    "country_173": "पोर्तुगाल",
    "country_174": "पोर्तु रिको",
    "country_175": "कतार",
    "country_176": "मॅसेडोनिया प्रजासत्ताक (FYROM)",
    "country_177": "काँगोचे प्रजासत्ताक",
    "country_178": "रियुनियन",
    "country_179": "रोमानिया",
    "country_180": "रशियाचे संघराज्य",
    "country_181": "रवांडा",
    "country_182": "सेंट बार्थेलेमी",
    "country_183": "सेंट मार्टिन (फ्रान्स)",
    "country_184": "सेंट-पियरे आणि मिकेलॉन",
    "country_185": "सामोआ",
    "country_186": "सॅन मारिनो",
    "country_187": "साओ टोम आणि प्रिन्सिप",
    "country_188": "सौदी अरेबिया",
    "country_189": "सेनेगल",
    "country_190": "सर्बिया",
    "country_191": "सेशेल्स",
    "country_192": "सिएरा लिओन",
    "country_193": "सिंगापूर",
    "country_194": "स्लोव्हाकिया",
    "country_195": "स्लोव्हेनिया",
    "country_196": "सॉलोमन बेटे",
    "country_197": "सोमालिया",
    "country_198": "दक्षिण आफ्रिका",
    "country_199": "दक्षिण जॉर्जिया आणि दक्षिण सँडविच इस्ला",
    "country_200": "दक्षिण कोरिया",
    "country_201": "दक्षिण सुदान",
    "country_202": "स्पेन",
    "country_203": "श्रीलंका",
    "country_204": "सेंट हेलेना आणि अवलंबित्व",
    "country_205": "सेंट किट्स आणि नेव्हिस",
    "country_206": "सेंट लुसिया",
    "country_207": "सेंट व्हिन्सेंट आणि ग्रेनेडाइन्स",
    "country_208": "सुदान",
    "country_209": "सुरीनाम",
    "country_210": "स्वाझीलंड",
    "country_211": "स्वीडन",
    "country_212": "स्वित्झर्लंड",
    "country_213": "सीरिया",
    "country_214": "तैवान",
    "country_215": "ताजिकिस्तान",
    "country_216": "टांझानिया",
    "country_217": "साचा:देश डेटा SJM स्वालबार्ड",
    "country_218": "थायलंड",
    "country_219": "बहामास",
    "country_220": "कोमोरोस",
    "country_221": "फिलीपिन्स",
    "country_222": "तिमोर-लेस्ते (पूर्व तिमोर)",
    "country_223": "जाण्यासाठी",
    "country_224": "टोकेलाऊ",
    "country_225": "टोंगा",
    "country_226": "त्रिनिदाद आणि टोबॅगो",
    "country_227": "ट्युनिशिया",
    "country_228": "तुर्की",
    "country_229": "तुर्कमेनिस्तान",
    "country_230": "तुर्क आणि कैकोस बेटे",
    "country_231": "तुवालु",
    "country_232": "युगांडा",
    "country_233": "युक्रेन",
    "country_234": "संयुक्त अरब अमिराती",
    "country_235": "युनायटेड स्टेट्स मायनर आउटलाइंग बेटे",
    "country_236": "युनायटेड स्टेट्स ऑफ अमेरिका (यूएसए)",
    "country_237": "युनायटेड स्टेट्स व्हर्जिन बेटे",
    "country_238": "उरुग्वे",
    "country_239": "उझबेकिस्तान",
    "country_240": "वानू",
    "country_241": "व्हॅटिकन सिटी (द होली सी)",
    "country_242": "व्हेनेझुएला",
    "country_243": "व्हिएतनाम",
    "country_244": "वॉलिस आणि फ्युटुना",
    "country_245": "पश्चिम सहारा",
    "country_246": "येमेन",
    "country_247": "झांबिया",
    "country_248": "झिंबाब्वे",
    "google_login": "Google सह साइन इन करा",
    "State": "राज्य",
    "Activation_code": "सक्रियन कोड",
    "Question": "तुम्ही लॉग इन केलेल्या सर्व ॲप्सची यादी करा",
    "Copy_complete": "कॉपी पूर्ण",
    "footer": "कॉपीराइट © 2024 MindOnMap स्टुडिओ. सर्व हक्क राखीव.",
    "change_password_success": "पासवर्ड यशस्वीरित्या बदलला",
    "successful_login_title": "लॉगिन यशस्वी",
    "product_page": "उत्पादन पृष्ठ >>",
    "successful_login_info": "लॉगिन पूर्ण झाले. कृपया वर्तमान पृष्ठ बंद करा आणि पुढील प्रक्रिया सुरू ठेवण्यासाठी मूळ टॅबवर परत जा. वर्तमान पृष्ठ 5 सेकंदात आपोआप बंद होईल. \"पूर्ण\" बटणावर क्लिक करून स्वयं-बंद किंवा बंद होत असल्यास, कृपया हा टॅब थेट बंद करा.",
    "successful_login_info_firefox": "लॉगिन पूर्ण झाले. कृपया वर्तमान पृष्ठ बंद करा आणि पुढील प्रक्रिया सुरू ठेवण्यासाठी मूळ टॅबवर परत जा.",
    "my_account": "माझे खाते",
    "my_history": "माझा इतिहास",
    "remove_watermark": "वॉटरमार्क काढा",
    "no_history": "इतिहास नाही",
    "history_all": "सर्व निवडा",
    "history_open": "उघडा",
    "history_down": "डाउनलोड करा",
    "history_delete": "हटवा",
    "history_clear": "अवैध साफ करा",
    "images": "प्रतिमा)",
    "use_this_function": "हे उत्पादन वापरा>>",
    "hd_downloading": "एचडी मूळ प्रतिमा डाउनलोड करण्याचे फायदे:",
    "lifetimeRemaining": "आजीवन-उर्वरित",
    "Remaining": "बाकी",
    "email_verification": "ई - मेल पडताळणी",
    "email_verification_info": "आम्ही तुमच्या ईमेल <span class=\"email\"></span> वर पडताळणी संदेश पाठवला आहे आणि कृपया पडताळणी पूर्ण करा. पडताळणी केल्यानंतर, फायदे आपोआप समक्रमित होतील.",
    "wrong_email": "चुकीचा ईमेल पत्ता?",
    "click_here_to_modify": "सुधारण्यासाठी येथे क्लिक करा",
    "get_more_help": "आणखी मदत मिळवायची?",
    "click_here": "इथे क्लिक करा",
    "email_verification_info_success": "तुमचे ईमेल खाते सत्यापित केल्याबद्दल अभिनंदन.",
    "email_verification_info_error": "कालबाह्य झालेल्या दुव्यामुळे सत्यापन अयशस्वी झाले.",
    "registration_succeeded": "नोंदणी यशस्वी झाली",
    "registration_succeeded_info_1": "अभिनंदन! तुम्ही यशस्वीरित्या नोंदणी केली आहे. आम्ही तुमच्या <span class=\"email\"></span> ईमेलवर पडताळणी संदेश पाठवला आहे आणि कृपया या ईमेलचे फायदे वापरण्यासाठी पडताळणी पूर्ण करा.",
    "registration_succeeded_info_2": "मुख्यपृष्ठावर परत येण्यासाठी \"पूर्ण झाले\" वर क्लिक करा आणि हे उत्पादन वापरा.",
    "registration_succeeded_info_3": "वर्तमान पृष्ठ बंद करण्यासाठी \"पूर्ण झाले\" वर क्लिक करा आणि पुढील ऑपरेशन्ससाठी मुख्यपृष्ठावर परत या. बंद करणे अयशस्वी झाल्यास, कृपया टॅब व्यक्तिचलितपणे बंद करा.",
    "verify_email": "ईमेल सत्यापित करा",
    "registered_email_not_verified": "नोंदणीकृत ईमेल सत्यापित केले गेले नाही, कृपया एकदाच सत्यापित करा.",
    "email_verification_time_1": "सत्यापन ईमेल प्राप्त झाले नाही?",
    "email_verification_time_2": "नंतर",
    "email_verification_time_3": "ते पुन्हा पाठवण्यासाठी येथे क्लिक करा",
    "error_26301": "त्रुटी कोड: 26301, कृपया आमच्याशी संपर्क साधा",
    "error_26302": "त्रुटी कोड: 26302, कृपया आमच्याशी संपर्क साधा",
    "error_26303": "ईमेल स्वरूप त्रुटी (त्रुटी कोड: 26303). कृपया ते पुन्हा प्रविष्ट करा",
    "error_26304": "8 वर्णांवरील पासवर्डची शिफारस केली जाते (त्रुटी कोड: 26304)",
    "error_26305": "Reuqest अयशस्वी (त्रुटी कोड: 26305). कृपया पुन्हा प्रयत्न करा",
    "error_26306": "ईमेल नोंदणीकृत आहे, कृपया <a href=\"https://account.mindonmap.com/login/\">लॉग इन करण्यासाठी जा</a>",
    "error_26307": "Reuqest अयशस्वी (त्रुटी कोड: 26307). कृपया पुन्हा प्रयत्न करा",
    "error_26308": "Reuqest अयशस्वी (त्रुटी कोड: 26308). कृपया पुन्हा प्रयत्न करा",
    "error_26401": "त्रुटी कोड: 26401, कृपया पुन्हा प्रयत्न करा",
    "error_26402": "ईमेल सत्यापित केले गेले आहे (त्रुटी कोड: 26402), कृपया पुन्हा प्रयत्न करा",
    "error_26403": "Reuqest अयशस्वी (त्रुटी कोड: 26403). कृपया पुन्हा प्रयत्न करा",
    "error_26404": "विनंती अयशस्वी झाली (त्रुटी कोड: 26404). कृपया पुन्हा प्रयत्न करा",
    "error_26501": "त्रुटी कोड: 26501, कृपया आमच्याशी संपर्क साधा",
    "error_26502": "त्रुटी कोड: 26502, कृपया आमच्याशी संपर्क साधा",
    "error_26503": "ईमेल स्वरूप त्रुटी (त्रुटी कोड: 26503). कृपया ते पुन्हा प्रविष्ट करा",
    "error_26504": "Reuqest अयशस्वी (त्रुटी कोड: 26504). कृपया पुन्हा प्रयत्न करा",
    "error_26505": "ईमेल नोंदणीकृत नाही, कृपया <a href=\"https://account.mindonmap.com/register/\">आधी त्याची नोंदणी करा</a>",
    "error_26506": "ईमेल सत्यापित केले गेले आहे.",
    "error_26507": "Reuqest अयशस्वी (त्रुटी कोड: 26507). कृपया पुन्हा प्रयत्न करा",
    "error_26508": "पडताळणी पूर्ण झाली (त्रुटी कोड: 26508), कृपया पुन्हा प्रयत्न करा",
    "error_26509": "विनंती अयशस्वी (त्रुटी कोड: 26509), कृपया पुन्हा प्रयत्न करा",
    "error_26510": "त्रुटी कोड: 26510, कृपया आमच्याशी संपर्क साधा",
    "error_26601": "त्रुटी कोड: 26601, कृपया आमच्याशी संपर्क साधा",
    "error_26602": "त्रुटी कोड: 26602, कृपया आमच्याशी संपर्क साधा",
    "error_26603": "Reuqest अयशस्वी (त्रुटी कोड: 26603). कृपया पुन्हा प्रयत्न करा",
    "error_26604": "त्रुटी कोड: 26604, कृपया आमच्याशी संपर्क साधा",
    "error_26605": "त्रुटी कोड: 26605, कृपया आमच्याशी संपर्क साधा",
    "error_26701": "त्रुटी कोड: 26701, कृपया आमच्याशी संपर्क साधा",
    "error_26702": "Reuqest अयशस्वी (त्रुटी कोड: 26702). कृपया पुन्हा प्रयत्न करा",
    "error_26703": "त्रुटी कोड: 26703, कृपया आमच्याशी संपर्क साधा",
    "error_26704": "त्रुटी कोड: 26704, कृपया आमच्याशी संपर्क साधा",
    "error_26705": "लॉग इन करण्यासाठी प्रतीक्षा करा (त्रुटी कोड: 26705). कृपया पुन्हा प्रयत्न करा",
    "no_cookie": "तुम्ही तुमच्या ब्राउझरमध्ये ब्लॉक ऑल कुकीज फंक्शन चालू केले आहे, त्यामुळे तुम्ही लॉग इन करू शकत नाही. कृपया सर्व कुकीजला अनुमती द्या बॉक्स चेक करण्यासाठी सेटिंग्जमध्ये जा.",
    "error_26801": "त्रुटी कोड: 26801, कृपया आमच्याशी संपर्क साधा",
    "error_26802": "त्रुटी कोड: 26802, कृपया आमच्याशी संपर्क साधा",
    "error_26803": "विनंती अयशस्वी झाली (त्रुटी कोड: 26803). कृपया पुन्हा प्रयत्न करा",
    "error_26804": "विनंती अयशस्वी झाली (त्रुटी कोड: 26804). कृपया पुन्हा प्रयत्न करा",
    "error_order": "विनंती अयशस्वी झाली (त्रुटी कोड: 27098), कृपया पुन्हा प्रयत्न करा!",
    "error_order1": "ऑर्डर क्वेरी अपूर्ण आहे (त्रुटी कोड: ",
    "error_order2": "）, कृपया रिफ्रेश करा आणि पुन्हा प्रयत्न करा.",
    "modify_email_title": "ईमेल सुधारित करा",
    "modify_email_info": "तुम्ही तुमच्या खात्यात लॉग इन करण्यासाठी सुधारित ईमेल वापरू शकता.",
    "images_per": "प्रति प्रतिमा",
    "error_26101": "त्रुटी: 26101. कृपया आमच्याशी संपर्क साधा",
    "error_26102": "त्रुटी: 26102. कृपया आमच्याशी संपर्क साधा",
    "error_26103": "विनंती अयशस्वी (त्रुटी कोड:26103). कृपया पुन्हा प्रयत्न करा",
    "error_26104": "त्रुटी कोड: 26104, कृपया पुन्हा प्रयत्न करा",
    "error_26105": "त्रुटी कोड: 26105, कृपया पुन्हा प्रयत्न करा",
    "error_26106": "हटवणे अयशस्वी (एरर कोड: 26106). कृपया पुन्हा प्रयत्न करा",
    "error_26201": "त्रुटी: 26201. कृपया आमच्याशी संपर्क साधा",
    "error_26202": "विनंती अयशस्वी (त्रुटी कोड:26202). कृपया पुन्हा प्रयत्न करा",
    "error_26001": "त्रुटी: 26001. कृपया आमच्याशी संपर्क साधा",
    "error_26002": "त्रुटी: 26002. कृपया आमच्याशी संपर्क साधा",
    "error_26003": "त्रुटी: 26003. कृपया आमच्याशी संपर्क साधा",
    "error_26004": "त्रुटी: 26004. कृपया आमच्याशी संपर्क साधा",
    "error_26005": "विनंती अयशस्वी (त्रुटी कोड:26005). कृपया पुन्हा प्रयत्न करा",
    "error_26006": "त्रुटी कोड: 26006, कृपया पुन्हा प्रयत्न करा",
    "error_26008": "त्रुटी: 26008. कृपया आमच्याशी संपर्क साधा",
    "go_to_the_home_page": "मुख्यपृष्ठावर जा",
    "error_27101": "विनंती अयशस्वी झाली (त्रुटी कोड: 27101). कृपया पुन्हा प्रयत्न करा",
    "error_27201": "त्रुटी कोड: 27201, कृपया आमच्याशी संपर्क साधा",
    "error_27202": "त्रुटी कोड: 27202, कृपया पुन्हा प्रयत्न करा",
    "error_27203": "विनंती अयशस्वी झाली (त्रुटी कोड: 27203). कृपया पुन्हा प्रयत्न करा",
    "error_27204": "अवैध कोड (त्रुटी कोड: 27204).",
    "error_27205": "विनंती अयशस्वी झाली (त्रुटी कोड: 27205). कृपया पुन्हा प्रयत्न करा",
    "error_27206": "विनंती अयशस्वी झाली (त्रुटी कोड: 27206). कृपया पुन्हा प्रयत्न करा",
    "error_27207": "विनंती अयशस्वी झाली (त्रुटी कोड: 27207). कृपया पुन्हा प्रयत्न करा",
    "no_history_found": "तुम्ही कोणतेही साधन वापरले नाही! <a href=\"/\">रीफ्रेश करा</a> किंवा <a href=\"https://www.mindonmap.com/\">अधिकृत वेबसाइटवर जा</a>",
    "error_25301": "त्रुटी: 25301. कृपया आमच्याशी संपर्क साधा",
    "error_25302": "त्रुटी: 25302. कृपया आमच्याशी संपर्क साधा",
    "error_25303": "विनंती अयशस्वी (एरर कोड: 25303). कृपया पुन्हा प्रयत्न करा",
    "error_25304": "विनंती अयशस्वी (एरर कोड: 25304). कृपया पुन्हा प्रयत्न करा",
    "error_25305": "विनंती अयशस्वी (एरर कोड: २५३०५). कृपया पुन्हा प्रयत्न करा",
    "error_25306": "विनंती अयशस्वी (एरर कोड: २५३०६). कृपया पुन्हा प्रयत्न करा",
    "image_upscaler_p": "वॉटरमार्कशिवाय प्रतिमा डाउनलोड करण्याचे फायदे:",
    "Available_for": "यासाठी उपलब्ध:",
    "credit_per": "प्रति HD प्रतिमा %s क्रेडिट",
    "still_valid": "खरेदी केलेले प्लॅन अजूनही वैध आहे",
    "credit": "क्रेडिट",
    "pc_3rd_info": "यशस्वीरित्या लॉगिन करा. कृपया पुढील ऑपरेशनसाठी अर्जावर जा.",
    "use_online": "ऑनलाइन सेवा वापरा",
    "use_download": "डेस्कटॉप प्रोग्राम वापरा",
    "use_immediately": "ताबडतोब वापरा",
    "Use_in_browser": "ब्राउझरमध्ये वापरा",
    "win_desktop": "खिडक्या",
    "Mac_desktop": "मॅक",
    "credits_per": "दरमहा {%} क्रेडिट्स",
    "expire": "कालबाह्यता वेळ:",
    "viewDetails": "तपशील पहा",
    "viewHistory": "इतिहास फायदे पहा >>",
    "viewDetailsInfo": "सूचना: सदस्यत्व लाभ कालबाह्य झाल्यानंतर 7 दिवसांच्या आत नूतनीकरण केल्यास, न वापरलेले फायदे वापरणे सुरू ठेवू शकतात. तसेच, कालबाह्यता वेळ नवीन सदस्यत्वाच्या कालबाह्यतेच्या वेळेवर स्वयंचलितपणे अद्यतनित केली जाईल. कालबाह्य कालावधीनंतर 7 दिवसांनंतर नवीन सदस्यता नसल्यास, सर्व कालबाह्य फायदे साफ केले जातील.",
    "connect_account": "तुमच्या खात्यावर ईमेल बाइंड करा",
    "connect_account_info": "बंधनकारक केल्यानंतर, तुम्ही या ईमेल पत्त्याने लॉग इन करू शकता.",
    "connect_now": "आता बांधा",
    "no_email_bind": "कोणतेही ईमेल बंधन नाही",
    "bind_email": "ईमेल बाइंड करा",
    "connect_your_email_placeholder": "कृपया तुमचा ईमेल आयडी टाका",
    "bind_an_email": "ईमेल बांधा",
    "bind_info": "तुम्ही यशस्वीरित्या लॉग इन केले आहे. तुमचे फायदे सक्रिय करण्यासाठी कृपया तुमच्या खात्यावर ईमेल बांधून ठेवा.",
    "bind_later": "नंतर बांधा",
    "hi": "त्याचा!",
    "Personal_Information": "वैयक्तिक माहिती",
    "Access": "प्रवेश",
    "Subscription_Plan": "(सदस्यता योजना)",
    "No_orders": "ऑर्डर आढळल्या नाहीत.",
    "No_data": "माहिती उपलब्ध नाही",
    "Featured_Products": "वैशिष्ट्यीकृत उत्पादने",
    "More_Products": "अधिक उत्पादने",
    "Free_Download": "मोफत उतरवा",
    "Get_Started": "सुरु करूया",
    "Subscribe": "सदस्यता घ्या",
    "Verified": "सत्यापित",
    "back_to_account_center": "खाते केंद्राकडे परत",
    "success": "यश!",
    "successfully": "तुम्ही यशस्वीरित्या खाते नोंदणीकृत केले आहे.",
    "Continue": "सुरू",
    "Already": "आधीपासूनच एक खाते आहे?",
    "loading_verification": "पडताळणी स्थिती तपासत आहे...",
    "email_no_verification": "क्षमस्व, नोंदणीकृत ईमेल पत्ता सत्यापित केला गेला नाही. कृपया वरील सूचनांनुसार पडताळणी पूर्ण करा आणि नोंदणी पूर्ण करण्यासाठी पुन्हा \"पूर्ण\" बटणावर क्लिक करा.",
    "will_expire_after": "नंतर कालबाह्य होईल",
    "hours": "तास",
  },
  "ms": {
    "overtime": "Kod ralat: {%}, sila cuba semula",
    "isnetwork": "Ralat Internet. Sila semak dan cuba semula",
    "email_placeholder": "E-mel",
    "email_empty": "Sila masukkan e-mel",
    "email_not_valid": "E-mel tidak sah",
    "email_not_valid_1": "Sila masukkan e-mel anda",
    "email_not_valid_2": "E-mel tidak sah, sila gunakan alamat lain.",
    "email_not_valid_3": "Tiada input e-mel",
    "password_placeholder": "Kata laluan",
    "password_empty": "Sila masukkan kata laluan",
    "password_not_valid": "Akaun atau kata laluan yang salah",
    "password_not_valid_1": "Kata laluan melebihi 8 aksara diperlukan",
    "password_not_valid_2": "Sila buat kata laluan",
    "password_placeholder_1": "reka kata kunci anda",
    "password_placeholder_2": "Mengesahkan kata laluan anda",
    "password_placeholder_3": "Buat kata laluan baharu",
    "password_placeholder_4": "Sahkan kata laluan baharu",
    "password_placeholder_5": "Masukkan kata laluan lama",
    "copy_password_empty": "Sila sahkan kata laluan",
    "copy_password_not_valid": "Sila sahkan kata laluan anda",
    "copy_passwords_inconsistent": "Pengesahan kata laluan anda tidak sepadan",
    "code_empty": "Sila masukkan kod pengesahan",
    "code_not_valid": "Kod pengesahan tidak sah",
    "code_placeholder": "Kod pengesahan",
    "not_received_code": "Jika peti mel anda sudah lama tidak menerima kod pengesahan, sila dapatkan kod pengesahan itu sekali lagi.",
    "get_first_code": "Sila dapatkan kod pengesahan dahulu.",
    "last_name_placeholder": "Sila masukkan nama keluarga anda",
    "first_name_placeholder": "Sila masukkan nama pertama anda",
    "address_placeholder": "Sila masukkan alamat anda",
    "no_code_text": "Kami telah menghantar kod pengesahan. Sila masukkan kod anda. <span class='tips'>Tidak menerima kod?",
    "no_code_text_1": "1. Sila pastikan alamat e-mel adalah sah dan ia boleh menerima e-mel.",
    "no_code_text_2": "2. Untuk e-mel dihantar oleh sistem secara automatik, ia mungkin dibenderakan sebagai e-mel spam atau sampah. Sila semak sama ada e-mel berada dalam folder Sampah.",
    "no_code_text_3": "3. Tidak dapat menyelesaikan masalah anda? ",
    "no_code_text_3_span": "Kemudian klik di sini untuk menghubungi kami.",
    "order_no": "Anda belum membeli sebarang produk, Jika anda mempunyai sebarang pertanyaan, sila <a href=\"https://www.mindonmap.com/contact-us/\">hubungi kami</a>.",
    "error_24901": "Akaun semasa tidak mempunyai e-mel yang dipautkan dan tidak dapat mencari pesanan. Sila sambungkan e-mel.",
    "user_guide": "Panduan Pengguna >>",
    "download": "Muat turun",
    "order_number": "Nombor Pesanan:",
    "Refund": "Bayaran balik",
    "Disabled": "Dilumpuhkan",
    "Normal": "Biasalah",
    "Modify": "Ubah suai",
    "Modify_1": "Ubah suai>>",
    "Connect": "Sambung",
    "unlink_success": "Nyahpaut berjaya",
    "connect_success": "Sambung berjaya",
    "feedback_title": "Terima kasih atas maklum balas anda. Sila tinggalkan masalah anda dan kami akan membalas anda dalam masa 24 jam.",
    "feedback_thank_you": "Terima Kasih!<br />Maklum Balas anda telah berjaya diserahkan.",
    "feedback_email": "Masukkan e-mel anda di sini!",
    "feedback_content": "Tinggalkan sebarang masalah atau cadangan yang anda hadapi di sini.",
    "feedback_submit": "Hantar",
    "form_contents": "Anda belum memasukkan sebarang huraian. Sila masukkan dan serahkan sekali lagi.",
    "old_password": "Sila masukkan kata laluan lama",
    "new_password": "Sila buat kata laluan baharu",
    "old_new_password": "Kata laluan baharu tidak boleh sama dengan kata laluan lama",
    "incorrect_password": "kata laluan salah",
    "delete_no": "Padam Sekarang",
    "Caps": "Caps lock dihidupkan",
    "Get": "Dapatkan",
    "Done": "Selesai",
    "error_20001": "Ralat: 20001. Sila log masuk semula.",
    "error_20002": "Ralat: 20002. Sila log masuk semula.",
    "error_20003": "Ralat: 20003. Sila log masuk semula.",
    "error_20004": "Permintaan gagal (kod ralat: 20004). Sila cuba lagi.",
    "error_20005": "Sesi log masuk telah tamat tempoh (Ralat: 20005). Sila log masuk semula.",
    "error_20006": "Permintaan gagal (kod ralat: 20006). Sila cuba lagi.",
    "error_20007": "Sesi log masuk telah tamat tempoh (Ralat: 20007). Sila log masuk semula.",
    "error_20008": "Sesi log masuk telah tamat tempoh (Ralat: 20008). Sila log masuk semula.",
    "error_20009": "Sesi log masuk telah tamat tempoh (Ralat: 20009). Sila log masuk semula.",
    "error_20101": "Sila masukkan e-mel anda (kod ralat: 20101)",
    "error_20102": "E-mel tidak sah (kod ralat: 20102)",
    "error_20103": "Permintaan gagal (kod ralat: 20103). Sila cuba lagi",
    "error_20104": "E-mel sudah digunakan, sila <a href=\"https://account.mindonmap.com/login/\">log masuk</a> atau daftar dengan yang baharu",
    "error_20105": "Permintaan gagal (kod ralat: 20105). Sila cuba lagi",
    "error_20106": "Gagal menghantar e-mel, sila cuba semula",
    "error_20201": "Sila masukkan e-mel anda (kod ralat: 20201)",
    "error_20202": "Sila masukkan kata laluan anda (kod ralat: 20202)",
    "error_20203": "Sila masukkan kod pengesahan (kod ralat: 20203)",
    "error_20204": "E-mel tidak sah (kod ralat: 20204)",
    "error_20205": "Kata laluan melebihi 8 aksara diperlukan (kod ralat: 20205)",
    "error_20206": "Permintaan gagal (kod ralat: 20206). Sila cuba lagi",
    "error_20207": "Kod pengesahan tidak sah",
    "error_20208": "Permintaan gagal (kod ralat: 20208). Sila cuba lagi",
    "error_20209": "Permintaan gagal (kod ralat: 20209). Sila cuba lagi",
    "error_20301": "Sila masukkan e-mel anda (kod ralat: 20301)",
    "error_20302": "Ralat: 20302. Sila hubungi kami",
    "error_20303": "E-mel tidak sah (kod ralat: 20303)",
    "error_20304": "Permintaan gagal (kod ralat: 20304). Sila cuba lagi",
    "error_20305": "Akaun tidak wujud. Sila masukkan semula atau <a href=\"https://account.mindonmap.com/register/\">Buatnya</a> dahulu.",
    "error_20306": "Tiada kata laluan lagi. Gunakan <a href=\"https://account.mindonmap.com/passwordless-login/\">Log masuk tanpa kata laluan</a> atau <a href=\"https://account.mindonmap.com/create-password/\"> tetapkan kata laluan</a> dan log masuk.",
    "error_20308": "Permintaan gagal (kod ralat: 20308). Sila cuba lagi",
    "error_20401": "Gagal log keluar (kod ralat: 20401). Sila cuba lagi",
    "error_20501": "Sila masukkan e-mel anda (kod ralat: 20501)",
    "error_20502": "E-mel tidak sah (kod ralat: 20502)",
    "error_20503": "Permintaan gagal (kod ralat: 20503). Sila cuba lagi",
    "error_20504": "Gagal menghantar e-mel. Sila cuba lagi.",
    "error_20601": "Sila masukkan e-mel anda (kod ralat: 20601)",
    "error_20602": "Sila masukkan kod pengesahan (kod ralat: 20602)",
    "error_20603": "E-mel tidak sah (kod ralat: 20603)",
    "error_20604": "Permintaan gagal (kod ralat: 20604). Sila cuba lagi",
    "error_20606": "Permintaan gagal (kod ralat: 20606). Sila cuba lagi",
    "error_20607": "Permintaan gagal (kod ralat: 20607). Sila cuba lagi",
    "error_20608": "Permintaan gagal (kod ralat: 20608). Sila cuba lagi",
    "error_20701": "Sila masukkan e-mel anda (kod ralat: 20701)",
    "error_20702": "E-mel tidak sah (kod ralat: 20702)",
    "error_20703": "Permintaan gagal (kod ralat: 20703). Sila cuba lagi",
    "error_20704": "Akaun tidak wujud. Sila masukkan semula atau <a href=\"https://account.mindonmap.com/register/\">Buatnya</a> dahulu.",
    "error_20705": "Permintaan gagal (kod ralat: 20705). Sila cuba lagi",
    "error_20706": "Gagal menghantar e-mel. Sila cuba lagi",
    "error_20801": "Sila masukkan e-mel anda (kod ralat: 20801)",
    "error_20802": "Ralat: 20802. Sila hubungi kami",
    "error_20803": "Sila masukkan kod pengesahan (kod ralat: 20803)",
    "error_20804": "E-mel tidak sah (kod ralat: 20804)",
    "error_20805": "Kata laluan melebihi 8 aksara diperlukan (kod ralat: 20805)",
    "error_20806": "Permintaan gagal (kod ralat: 20806). Sila cuba lagi",
    "error_20808": "Permintaan gagal (kod ralat: 20808). Sila cuba lagi",
    "error_20901": "Permintaan gagal (kod ralat: 20901). Sila cuba lagi",
    "error_20902": "Permintaan gagal (kod ralat: 20902). Sila cuba lagi",
    "error_21000": "Perubahan disimpan",
    "error_21001": "Tiada maklumat diserahkan (kod ralat: 21001)",
    "error_21002": "Permintaan gagal (kod ralat: 21002). Sila cuba lagi",
    "error_21101": "Sila masukkan e-mel anda (kod ralat: 21101)",
    "error_21102": "E-mel tidak sah (kod ralat: 21102)",
    "error_21103": "Permintaan gagal (kod ralat: 21103), sila cuba semula sekali lagi",
    "error_21104": "E-mel sudah disambungkan, sila gunakan yang baharu",
    "error_21105": "Permintaan gagal (kod ralat: 21105), sila cuba semula sekali lagi",
    "error_21106": "Gagal menghantar e-mel. Sila cuba lagi",
    "error_21201": "Sila masukkan e-mel anda (kod ralat: 21201)",
    "error_21202": "Sila masukkan kod pengesahan (kod ralat: 21202)",
    "error_21203": "E-mel tidak sah (kod ralat: 21203)",
    "error_21204": "Ralat: 21204. Sila hubungi kami",
    "error_21205": "Ralat: 21205. Sila hubungi kami",
    "error_21206": "Kata laluan melebihi 8 aksara diperlukan (kod ralat: 21206)",
    "error_21207": "Permintaan gagal (kod ralat: 21207). Sila cuba lagi",
    "error_21209": "Permintaan gagal (kod ralat: 21209). Sila cuba lagi",
    "error_21301": "Sila masukkan kata laluan lama (kod ralat: 21301)",
    "error_21302": "Sila buat kata laluan baharu (kod ralat: 21302)",
    "error_21303": "Kata laluan baharu tidak boleh sama dengan kata laluan lama. (Ralat: 21303)",
    "error_21304": "Kata laluan melebihi 8 aksara diperlukan (kod ralat: 21304)",
    "error_21306": "Permintaan gagal (kod ralat: 21306). Sila cuba lagi",
    "error_21402": "Permintaan gagal (kod ralat: 21402). Sila cuba lagi",
    "error_21403": "Gagal menghantar kod pengesahan. Sila hantar semula",
    "error_21500": "Akaun telah dipadamkan",
    "error_21501": "Sila masukkan kod pengesahan (kod ralat: 21501)",
    "error_21502": "Sesi log masuk telah tamat tempoh (Ralat: 21502). Sila log masuk semula.",
    "error_21503": "Permintaan gagal (kod ralat: 21503). Sila cuba lagi",
    "error_21505": "Permintaan gagal (kod ralat: 21505), sila cuba semula",
    "error_21601": "Ralat: 20601. Sila hubungi kami",
    "error_21602": "Pengesahan Tidak Sah (Ralat: 20602). Sila cuba lagi.",
    "error_21603": "Ralat: 20603. Sila cuba lagi",
    "error_21604": "Permintaan gagal (kod ralat: 21604). Sila cuba lagi",
    "error_21606": "Permintaan gagal (kod ralat: 21606). Sila cuba lagi",
    "error_21611": "Permintaan gagal (kod ralat: 21611). Sila cuba lagi",
    "error_21801": "Ralat: 21801. Sila hubungi kami",
    "error_21802": "Permintaan gagal (Ralat: 21802). Sila cuba lagi",
    "error_21803": "Ralat: 21803. Sila cuba lagi",
    "error_21804": "Permintaan gagal (kod ralat: 21804). Sila cuba lagi",
    "error_21806": "Ralat: 21806. Sila cuba lagi",
    "error_21807": "Ralat: 21807. Sila hubungi kami",
    "error_21808": "Ralat: 21808. Sila hubungi kami",
    "error_21809": "Ralat: 21809. Sila hubungi kami",
    "error_21810": "Ralat: 21810. Sila hubungi kami",
    "error_21811": "Ralat: 21811. Sila hubungi kami",
    "error_21812": "Ralat: 21812. Sila hubungi kami",
    "error_21813": "Permintaan gagal (kod ralat: 21813). Sila cuba lagi",
    "error_21814": "Ralat: 21814. Sila hubungi kami",
    "error_21815": "Permintaan gagal (kod ralat: 21815). Sila cuba lagi",
    "error_21816": "Ralat: 21816. Sila hubungi kami",
    "error_21817": "Ralat: 21817. Sila hubungi kami",
    "error_21818": "Ralat: 21818. Sila hubungi kami",
    "error_21819": "Permintaan gagal (kod ralat: 21819). Sila cuba lagi",
    "error_21820": "Ralat: 21820. Sila hubungi kami",
    "error_21821": "Ralat: 21821. Sila hubungi kami",
    "error_21822": "Ralat: 21822. Sila hubungi kami",
    "error_21823": "Permintaan gagal (kod ralat: 21823). Sila cuba lagi",
    "error_21824": "Permintaan gagal (kod ralat: 21824). Sila cuba lagi",
    "error_21825": "Permintaan gagal (kod ralat: 21825). Sila cuba lagi",
    "error_21826": "Permintaan gagal (kod ralat: 21826). Sila cuba lagi",
    "error_21901": "Ralat: 21901. Sila hubungi kami",
    "error_21902": "Permintaan gagal (kod ralat: 21902). Sila cuba lagi",
    "error_21903": "Status akaun telah berubah (kod ralat: 21903), sila muat semula halaman dan cuba lagi",
    "error_21904": "Ralat: 21904. Sila cuba lagi",
    "error_21905": "Ralat: 21905. Sila cuba lagi",
    "error_21906": "Permintaan gagal (kod ralat: 21906). Sila cuba lagi",
    "error_21907": "Akaun Google telah dipautkan ke akaun lain",
    "error_21908": "Permintaan gagal (kod ralat: 21908). Sila cuba lagi",
    "error_22001": "Permintaan gagal (kod ralat: 22001). Sila cuba lagi",
    "error_22002": "Nyahpaut gagal tanpa log masuk tambahan",
    "error_22003": "Permintaan gagal (kod ralat: 22003). Sila cuba lagi",
    "error_22101": "Ralat: 22101. Sila hubungi kami",
    "error_22102": "Status akaun telah berubah (kod ralat: 22102), sila muat semula halaman dan cuba lagi",
    "error_22103": "Permintaan gagal (kod ralat: 22103). Sila cuba lagi",
    "error_22104": "Status akaun telah berubah (kod ralat: 22104), sila muat semula halaman dan cuba lagi",
    "error_22105": "Ralat: 22105. Sila cuba lagi",
    "error_22106": "Ralat: 22106. Sila cuba lagi",
    "error_22107": "Ralat: 22107. Sila hubungi kami",
    "error_22108": "Permintaan gagal (kod ralat: 22108). Sila cuba lagi",
    "error_22201": "Ralat: 22201. Sila hubungi kami",
    "error_22202": "Pengesahan Tidak Sah (Ralat: 22202). Sila cuba lagi.",
    "error_22203": "Ralat: 22203. Sila cuba lagi\"",
    "error_22204": "Permintaan gagal (kod ralat: 22204). Sila cuba lagi",
    "error_22206": "Permintaan gagal (kod ralat: 22206). Sila cuba lagi",
    "error_22401": "Ralat: 22401. Sila hubungi kami",
    "error_22402": "Pengesahan Tidak Sah (Ralat: 22402). Sila cuba lagi.",
    "error_22403": "Ralat: 22403. Sila cuba lagi",
    "error_22404": "Permintaan gagal (kod ralat: 22404). Sila cuba lagi",
    "error_22405": "Akaun Facebook telah dipautkan ke e-mel lain",
    "error_22406": "Ralat: 22406. Sila cuba lagi",
    "error_22407": "Ralat: 22407. Sila hubungi kami",
    "error_22408": "Ralat: 22408. Sila hubungi kami",
    "error_22409": "Ralat: 22409. Sila hubungi kami",
    "error_22410": "Ralat: 224010. Sila hubungi kami",
    "error_22411": "Ralat: 224011. Sila hubungi kami",
    "error_22412": "Ralat: 224012. Sila hubungi kami",
    "error_22413": "Ralat: 22413. Sila hubungi kami",
    "error_22414": "Permintaan gagal (kod ralat: 22414). Sila cuba lagi",
    "error_22415": "Ralat: 22415. Sila hubungi kami",
    "error_22416": "Ralat: 22416. Sila hubungi kami",
    "error_22417": "Ralat: 22417. Sila hubungi kami",
    "error_22418": "Permintaan gagal (kod ralat: 22418). Sila cuba lagi",
    "error_22419": "Ralat: 22419. Sila hubungi kami",
    "error_22420": "Ralat: 22420. Sila hubungi kami",
    "error_22421": "Ralat: 22421. Sila hubungi kami",
    "error_22422": "Permintaan gagal (kod ralat: 22422). Sila cuba lagi",
    "error_22423": "Permintaan gagal (kod ralat: 22423). Sila cuba lagi",
    "error_22424": "Permintaan gagal (kod ralat: 22424). Sila cuba lagi",
    "error_22425": "Permintaan gagal (kod ralat: 22425). Sila cuba lagi",
    "error_20098": "Kod ralat: 20098. Jika anda menyemak imbas dalam mod Peribadi, sila tukar kepada mod Biasa dan cuba lagi.",
    "error_22298": "Permintaan untuk Google gagal (Ralat: 22298). Sila cuba lagi.",
    "error_22498": "Permintaan untuk Facebook gagal (Ralat: 22498). Sila cuba lagi.",
    "error_24902": "Permintaan gagal (kod ralat: 24902). Sila cuba lagi",
    "error_24903": "Permintaan gagal (kod ralat: 24903). Sila cuba lagi",
    "error_24904": "Permintaan gagal (kod ralat: 24904). Sila cuba lagi",
    "error_24905": "Permintaan gagal (kod ralat: 24905). Sila cuba lagi",
    "login_title": "Log masuk ke MindOnMap",
    "log_in": "Log masuk",
    "no_account": "Tiada akaun?",
    "create_it": "Cipta",
    "or_log_in_with": "Atau log masuk dengan",
    "passwordless_login": "Log masuk tanpa kata laluan",
    "log_in_done": "Log Masuk Selesai",
    "three_rd_account_connect_info": "tahniah! Anda telah berjaya log masuk. Kini anda boleh menyambungkan satu akaun e-mel yang digunakan untuk log masuk pada masa hadapan.",
    "see_my_account": "Lihat akaun saya",
    "three_rd_login_merge_account": "Alamat e-mel akaun pihak ketiga telah didaftarkan, adakah anda mahu menyambung dan log masuk dengan alamat e-mel ini secara terus?",
    "connect_log_in": "Sambung & Log Masuk",
    "create_an_account": "Buat akaun",
    "back_to_log_in": "Kembali untuk log masuk",
    "create_password": "Bina kata laluan",
    "create_now": "Buat Sekarang",
    "password_login_subtitle": "Log masuk tanpa kata laluan dengan e-mel",
    "account_login": "Log Masuk Akaun",
    "rights": "Dengan mencipta akaun ini, anda bersetuju dengan <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Syarat Perkhidmatan</a> dan <a href=\"https://www.mindonmap.com/privacy-policy/\">Dasar Privasi</a>",
    "passwordless_login_done": "Log masuk tanpa kata laluan selesai",
    "passwordless_login_info": "Tahniah, anda telah berjaya melengkapkan log masuk tanpa kata laluan. Anda boleh membuat kata laluan untuk akaun ini dan log masuk dengan akaun dan kata laluan pada masa hadapan. <a href=\"/create-password\" style=\"display: initial;\">Buat Sekarang</a>",
    "sign_up": "Daftar",
    "register_info": "cipta akaun anda",
    "reset_now": "Tetapkan semula Sekarang",
    "forgot_password": "Lupa kata laluan",
    "reset_password_subtitle": "Gunakan e-mel akaun anda untuk menetapkan semula kata laluan",
    "plan_products": "Pelan & Produk",
    "nick_name": "Nama pengguna:",
    "email": "e-mel:",
    "my_products": "Produk Saya",
    "my_orders": "arahan saya",
    "unlink": "Nyahpaut",
    "link": "Pautan",
    "connected_accounts": "Akaun bersambung",
    "last_name": "Nama terakhir:",
    "first_name": "Nama pertama:",
    "Gender": "Jantina:",
    "Birth": "Kelahiran:",
    "Month": "bulan",
    "Year": "tahun",
    "Country_Region": "Negara/Wilayah:",
    "Address": "Alamat:",
    "Save": "Jimat",
    "Date": "Tarikh",
    "Male": "jantan",
    "Female": "perempuan",
    "Unspecified": "Tidak dinyatakan",
    "Security": "Keselamatan",
    "change_password": "Tukar kata laluan",
    "change_now": "Tukar Sekarang",
    "connect_email": "Sambungkan e-mel",
    "delete_account": "Padam Akaun",
    "delete_account_info": "Apabila akaun anda dipadamkan, semua data dalam Akaun Saya yang dikaitkan dengan akaun anda akan dipadamkan secara kekal dan anda mungkin tidak dapat memulihkannya. Kami mengesyorkan anda berhati-hati.",
    "Delete": "Padam",
    "Logout": "Log keluar",
    "my_profile": "Profil saya",
    "guides_faqs": "Panduan & Soalan Lazim",
    "More": "Lagi",
    "guides": "Pemandu",
    "register": "mendaftar",
    "hot_faq": "Soalan Lazim hangat",
    "Contents": "kandungan:",
    "contact_us": "Hubungi kami>>",
    "plan": "Rancang",
    "unregistered": "Tidak berdaftar",
    "buy_more": "Beli Lagi",
    "buy_again": "Beli Lagi",
    "buy_now": "Beli sekarang",
    "free_no_limit": "Percuma & Tiada had",
    "expired": "Tamat tempoh",
    "lifetime": "Seumur hidup",
    "remain": "Kekal",
    "day_s": "hari",
    "error_24801": "Permintaan gagal (kod ralat: 24801). Sila cuba lagi",
    "no_app_found": "Tiada apl ditemui！<a href=\"/\">Muat Semula</a> atau <a href=\"https://www.mindonmap.com/\">Pergi ke tapak web rasmi</a>",
    "get_more": "Dapatkan Lebih >>",
    "edit_photo": "Edit Foto",
    "select_photo": "Pilih foto",
    "change_photo": "Tukar gambar",
    "cancel": "Batal",
    "hide_password": "Sembunyikan kata laluan",
    "show_password": "Tunjukkan kata laluan",
    "zoom_in": "Mengezum masuk",
    "zoom_out": "Zum keluar",
    "rotate": "Putar",
    "horizontal_flip": "Balik mendatar",
    "vertical_flip": "Balik menegak",
    "country": "Negara",
    "country_1": "Pilih negara/rantau anda",
    "country_2": "Pulau Åaland",
    "country_3": "Afghanistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "American Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antartika",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaijan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarus",
    "country_22": "Belgium",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia & Herzegovina",
    "country_29": "Botswana",
    "country_30": "Pulau Bouvet",
    "country_31": "Brazil",
    "country_32": "Wilayah Lautan India British",
    "country_33": "Pulau British Virgin",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kemboja",
    "country_39": "Cameroon",
    "country_40": "Kanada",
    "country_41": "Cape Verde",
    "country_42": "Caribbean Belanda",
    "country_43": "Pulau Cayman",
    "country_44": "Republik Afrika Tengah",
    "country_45": "Chad",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Pulau Krismas",
    "country_49": "Kepulauan Cocos (Keeling).",
    "country_50": "Colombia",
    "country_51": "Kepulauan Cook",
    "country_52": "Costa rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Croatia",
    "country_55": "Cuba",
    "country_56": "Cyprus",
    "country_57": "Republik Czech",
    "country_58": "Republik Demokratik Congo",
    "country_59": "Denmark",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Republik Dominican",
    "country_63": "Ecuador",
    "country_64": "Mesir",
    "country_65": "El Salvador",
    "country_66": "Guinea Khatulistiwa",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Habsyah",
    "country_70": "Kepulauan Falkland",
    "country_71": "Pulau Faroe",
    "country_72": "Negeri Bersekutu Micronesia",
    "country_73": "Fiji",
    "country_74": "Finland",
    "country_75": "Perancis",
    "country_76": "Guiana Perancis",
    "country_77": "Polynesia Perancis",
    "country_78": "Wilayah Selatan Perancis",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Jerman",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Britain (United Kingdom; England)",
    "country_86": "Greece",
    "country_87": "Greenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Island dan McDonald Islands",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungary",
    "country_101": "Iceland",
    "country_102": "India",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Iraq",
    "country_106": "Ireland",
    "country_107": "Pulau Man",
    "country_108": "Israel",
    "country_109": "Itali",
    "country_110": "Jamaica",
    "country_111": "Jepun",
    "country_112": "Jersi",
    "country_113": "Jordan",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kyrgyzstan",
    "country_119": "Laos",
    "country_120": "Latvia",
    "country_121": "Lubnan",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libya",
    "country_125": "Liechtenstein",
    "country_126": "Lithuania",
    "country_127": "Luxembourg",
    "country_128": "Macau",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Pulau Marshall",
    "country_136": "Martinique",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexico",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Maghribi",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Belanda",
    "country_153": "New Caledonia",
    "country_154": "New Zealand",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Pulau Norfolk",
    "country_160": "Korea Utara",
    "country_161": "Kepulauan Mariana Utara",
    "country_162": "Norway",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "wilayah Palestin",
    "country_167": "Panama",
    "country_168": "Papua New Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pulau Pitcairn",
    "country_172": "Poland",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Republik Macedonia (FYROM)",
    "country_177": "Republik Congo",
    "country_178": "Réunion",
    "country_179": "Romania",
    "country_180": "Persekutuan Russia",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Perancis)",
    "country_184": "Saint-Pierre dan Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Arab Saudi",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapura",
    "country_194": "Slovakia",
    "country_195": "Slovenia",
    "country_196": "Kepulauan Solomon",
    "country_197": "Somalia",
    "country_198": "Afrika Selatan",
    "country_199": "South Georgia dan South Sandwic Isla",
    "country_200": "Korea Selatan",
    "country_201": "Selatan Sudan",
    "country_202": "Sepanyol",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena & Dependencies",
    "country_205": "St. Kitts & Nevis",
    "country_206": "St. Lucia",
    "country_207": "St. Vincent & the Grenadines",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Sweden",
    "country_212": "Switzerland",
    "country_213": "Syria",
    "country_214": "Taiwan",
    "country_215": "Tajikistan",
    "country_216": "Tanzania",
    "country_217": "Templat:Country data SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "Bahamas",
    "country_220": "Comoros",
    "country_221": "Filipina",
    "country_222": "Timor-Leste (Timor Timor)",
    "country_223": "Untuk pergi",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Turki",
    "country_229": "Turkmenistan",
    "country_230": "Turks & Caicos Island",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraine",
    "country_234": "Emiriah Arab Bersatu",
    "country_235": "Pulau-pulau Terpencil Minor Amerika Syarikat",
    "country_236": "Amerika Syarikat (AS)",
    "country_237": "Kepulauan Virgin Amerika Syarikat",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatican City (The Holy See)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis dan Ftuna",
    "country_245": "Sahara Barat",
    "country_246": "Yaman",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Log masuk dengan Google",
    "State": "negeri",
    "Activation_code": "Kod pengaktifan",
    "Question": "Senaraikan semua apl yang telah anda log masuk",
    "Copy_complete": "Salinan selesai",
    "footer": "Hak Cipta © 2024 MindOnMap Studio. Hak cipta terpelihara.",
    "change_password_success": "Kata laluan berjaya ditukar",
    "successful_login_title": "Kejayaan Log Masuk",
    "product_page": "Halaman Produk>>",
    "successful_login_info": "Log masuk selesai. Sila tutup halaman semasa dan kembali ke tab asal untuk meneruskan proses berikut. Halaman semasa akan ditutup secara automatik dalam masa 5 saat. Jika penutupan atau penutupan automatik dengan mengklik butang \"Selesai\" gagal, sila tutup terus tab ini.",
    "successful_login_info_firefox": "Log masuk selesai. Sila tutup halaman semasa dan kembali ke tab asal untuk meneruskan proses berikut.",
    "my_account": "Akaun saya",
    "my_history": "Sejarah saya",
    "remove_watermark": "Buang Tera Air",
    "no_history": "Tiada Sejarah",
    "history_all": "Pilih semua",
    "history_open": "Buka",
    "history_down": "Muat turun",
    "history_delete": "Padam",
    "history_clear": "Kosongkan tidak sah",
    "images": "imej",
    "use_this_function": "Gunakan Produk Ini>>",
    "hd_downloading": "Faedah muat turun imej asli HD:",
    "lifetimeRemaining": "Seumur Hidup-Baki",
    "Remaining": "yang tinggal",
    "email_verification": "pengesahan email",
    "email_verification_info": "Kami telah menghantar mesej pengesahan ke e-mel <span class=\"email\"></span> anda dan sila selesaikan pengesahan. Selepas mengesahkan, faedah akan disegerakkan secara automatik.",
    "wrong_email": "alamat e-mel salah?",
    "click_here_to_modify": "Klik di sini untuk mengubah suai",
    "get_more_help": "Dapatkan lebih banyak bantuan?",
    "click_here": "Tekan di sini",
    "email_verification_info_success": "Tahniah kerana mengesahkan akaun e-mel anda.",
    "email_verification_info_error": "Pengesahan gagal kerana pautan tamat tempoh.",
    "registration_succeeded": "Pendaftaran Berjaya",
    "registration_succeeded_info_1": "tahniah! Anda telah berjaya mendaftar. Kami telah menghantar mesej pengesahan ke e-mel <span class=\"email\"></span> anda dan sila selesaikan pengesahan untuk menggunakan faedah e-mel ini.",
    "registration_succeeded_info_2": "Klik \"Selesai\" untuk kembali ke halaman Utama dan menggunakan produk ini.",
    "registration_succeeded_info_3": "Klik \"Selesai\" untuk menutup halaman semasa dan kembali ke halaman Utama untuk operasi berikut. Jika penutupan gagal, sila tutup tab secara manual.",
    "verify_email": "Mengesahkan E-mel",
    "registered_email_not_verified": "E-mel yang didaftarkan belum disahkan, sila sahkan segera.",
    "email_verification_time_1": "Tidak menerima e-mel pengesahan?",
    "email_verification_time_2": "Selepas",
    "email_verification_time_3": "Klik di sini untuk menghantarnya semula",
    "error_26301": "Kod ralat: 26301, sila hubungi kami",
    "error_26302": "Kod ralat: 26302, sila hubungi kami",
    "error_26303": "Ralat format e-mel (kod ralat: 26303). Sila masukkan sekali lagi",
    "error_26304": "Kata laluan melebihi 8 aksara disyorkan (kod ralat: 26304)",
    "error_26305": "Reuqest gagal (kod ralat: 26305). Sila cuba semula",
    "error_26306": "E-mel telah didaftarkan, sila <a href=\"https://account.mindonmap.com/login/\">pergi untuk log masuk</a>",
    "error_26307": "Reuqest gagal (kod ralat: 26307). Sila cuba semula",
    "error_26308": "Reuqest gagal (kod ralat: 26308). Sila cuba semula",
    "error_26401": "Kod ralat: 26401, sila cuba semula",
    "error_26402": "E-mel telah disahkan (kod ralat: 26402), sila cuba semula",
    "error_26403": "Reuqest gagal (kod ralat: 26403). Sila cuba semula",
    "error_26404": "Permintaan gagal (kod ralat: 26404). Sila cuba semula",
    "error_26501": "Kod ralat: 26501, sila hubungi kami",
    "error_26502": "Kod ralat: 26502, sila hubungi kami",
    "error_26503": "Ralat format e-mel (kod ralat: 26503). Sila masukkan sekali lagi",
    "error_26504": "Reuqest gagal (kod ralat: 26504). Sila cuba semula",
    "error_26505": "E-mel belum didaftarkan, sila <a href=\"https://account.mindonmap.com/register/\">daftarkannya dahulu</a>",
    "error_26506": "E-mel telah disahkan.",
    "error_26507": "Reuqest gagal (kod ralat: 26507). Sila cuba semula",
    "error_26508": "Pengesahan gagal (kod ralat: 26508), sila cuba semula",
    "error_26509": "Permintaan gagal (kod ralat: 26509), sila cuba semula",
    "error_26510": "Kod ralat: 26510, sila hubungi kami",
    "error_26601": "Kod ralat: 26601, sila hubungi kami",
    "error_26602": "Kod ralat: 26602, sila hubungi kami",
    "error_26603": "Reuqest gagal (kod ralat: 26603). Sila cuba semula",
    "error_26604": "Kod ralat: 26604, sila hubungi kami",
    "error_26605": "Kod ralat: 26605, sila hubungi kami",
    "error_26701": "Kod ralat: 26701, sila hubungi kami",
    "error_26702": "Reuqest gagal (kod ralat: 26702). Sila cuba semula",
    "error_26703": "Kod ralat: 26703, sila hubungi kami",
    "error_26704": "Kod ralat: 26704, sila hubungi kami",
    "error_26705": "Tunggu untuk log masuk (kod ralat: 26705). Sila cuba semula",
    "no_cookie": "Anda telah menghidupkan fungsi Sekat semua kuki dalam penyemak imbas anda, jadi anda tidak boleh log masuk. Sila pergi ke Tetapan untuk menandai kotak Benarkan semua kuki.",
    "error_26801": "Kod ralat: 26801, sila hubungi kami",
    "error_26802": "Kod ralat: 26802, sila hubungi kami",
    "error_26803": "Permintaan gagal (kod ralat: 26803). Sila cuba semula",
    "error_26804": "Permintaan gagal (kod ralat: 26804). Sila cuba semula",
    "error_order": "Permintaan gagal (kod ralat: 27098), sila cuba lagi!",
    "error_order1": "Pertanyaan pesanan tidak lengkap（kod ralat: ",
    "error_order2": "）, sila muat semula dan cuba lagi.",
    "modify_email_title": "Ubah suai E-mel",
    "modify_email_info": "Anda boleh menggunakan e-mel yang diubah suai untuk log masuk ke akaun anda.",
    "images_per": "Imej per",
    "error_26101": "Ralat: 26101. Sila hubungi kami",
    "error_26102": "Ralat: 26102. Sila hubungi kami",
    "error_26103": "Permintaan gagal (kod ralat:26103). Sila cuba sekali lagi",
    "error_26104": "Kod ralat: 26104, sila cuba semula",
    "error_26105": "Kod ralat: 26105, sila cuba semula",
    "error_26106": "Padam gagal (kod ralat: 26106). Sila cuba lagi",
    "error_26201": "Ralat: 26201. Sila hubungi kami",
    "error_26202": "Permintaan gagal (kod ralat:26202). Sila cuba sekali lagi",
    "error_26001": "Ralat: 26001. Sila hubungi kami",
    "error_26002": "Ralat: 26002. Sila hubungi kami",
    "error_26003": "Ralat: 26003. Sila hubungi kami",
    "error_26004": "Ralat: 26004. Sila hubungi kami",
    "error_26005": "Permintaan gagal (kod ralat:26005). Sila cuba sekali lagi",
    "error_26006": "Kod ralat: 26006, sila cuba semula",
    "error_26008": "Ralat: 26008. Sila hubungi kami",
    "go_to_the_home_page": "Pergi ke halaman Utama",
    "error_27101": "Permintaan gagal (kod ralat: 27101). Sila cuba semula ist",
    "error_27201": "Kod ralat: 27201, sila hubungi kami",
    "error_27202": "Kod ralat: 27202, sila cuba semula",
    "error_27203": "Permintaan gagal (kod ralat: 27203). Sila cuba semula",
    "error_27204": "Kod tidak sah (kod ralat: 27204).",
    "error_27205": "Permintaan gagal (kod ralat: 27205). Sila cuba semula",
    "error_27206": "Permintaan gagal (kod ralat: 27206). Sila cuba semula",
    "error_27207": "Permintaan gagal (kod ralat: 27207). Sila cuba semula",
    "no_history_found": "Anda tidak menggunakan sebarang alat! <a href=\"/\">Muat Semula</a> atau <a href=\"https://www.mindonmap.com/\">Pergi ke tapak web rasmi</a>",
    "error_25301": "Ralat: 25301. Sila hubungi kami",
    "error_25302": "Ralat: 25302. Sila hubungi kami",
    "error_25303": "Permintaan gagal (kod ralat: 25303). Sila cuba lagi",
    "error_25304": "Permintaan gagal (kod ralat: 25304). Sila cuba lagi",
    "error_25305": "Permintaan gagal (kod ralat: 25305). Sila cuba lagi",
    "error_25306": "Permintaan gagal (kod ralat: 25306). Sila cuba lagi",
    "image_upscaler_p": "Imej tanpa faedah muat turun tera air:",
    "Available_for": "Boleh didapati untuk:",
    "credit_per": "%s kredit bagi setiap imej HD",
    "still_valid": "Pelan yang dibeli masih sah",
    "credit": "kredit",
    "pc_3rd_info": "Log masuk berjaya. Sila pergi ke aplikasi untuk operasi selanjutnya.",
    "use_online": "Gunakan perkhidmatan dalam talian",
    "use_download": "Gunakan program desktop",
    "use_immediately": "Gunakan Segera",
    "Use_in_browser": "Gunakan dalam Pelayar",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Kredit setiap Bulan",
    "expire": "Masa Tamat:",
    "viewDetails": "Lihat butiran",
    "viewHistory": "Lihat faedah sejarah>>",
    "viewDetailsInfo": "Notis: Jika faedah langganan diperbaharui dalam tempoh 7 hari selepas tamat tempoh, faedah yang tidak digunakan boleh terus digunakan. Selain itu, masa tamat tempoh akan dikemas kini secara automatik kepada masa tamat tempoh langganan baharu. Jika tiada langganan baharu selepas 7 hari masa tamat tempoh, semua faedah yang telah tamat tempoh akan dikosongkan.",
    "connect_account": "Ikat E-mel ke Akaun Anda",
    "connect_account_info": "Selepas mengikat, anda boleh log masuk dengan alamat e-mel ini.",
    "connect_now": "Ikat Sekarang",
    "no_email_bind": "Tiada ikatan e-mel",
    "bind_email": "Ikat E-mel",
    "connect_your_email_placeholder": "Sila masukkan alamat e-mel anda",
    "bind_an_email": "Ikat E-mel",
    "bind_info": "Anda telah berjaya log masuk. Sila ikat e-mel ke akaun anda untuk mengaktifkan faedah anda.",
    "bind_later": "Ikat kemudian",
    "hi": "Hai, %s!",
    "Personal_Information": "Maklumat peribadi",
    "Access": "Akses",
    "Subscription_Plan": "(Pelan Langganan)",
    "No_orders": "Tiada pesanan ditemui.",
    "No_data": "Tiada data",
    "Featured_Products": "Produk Pilihan",
    "More_Products": "Lebih Banyak Produk",
    "Free_Download": "Muat turun percuma",
    "Get_Started": "Mulakan",
    "Subscribe": "Langgan",
    "Verified": "Disahkan",
    "back_to_account_center": "Kembali ke Pusat Akaun",
    "success": "Berjaya!",
    "successfully": "Anda telah berjaya mendaftar akaun.",
    "Continue": "teruskan",
    "Already": "Sudah mempunyai akaun?",
    "loading_verification": "Menyemak status pengesahan...",
    "email_no_verification": "Maaf, alamat e-mel yang didaftarkan belum disahkan. Sila lengkapkan pengesahan mengikut arahan di atas dan klik butang \"Selesai\" sekali lagi untuk melengkapkan pendaftaran.",
    "will_expire_after": "Akan tamat tempoh selepas",
    "hours": "Jam",
  },
  "my": {
    "overtime": "အမှားကုဒ်- {%}၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "isnetwork": "အင်တာနက် အမှား။ ကျေးဇူးပြု၍ စစ်ဆေးပြီး ထပ်စမ်းကြည့်ပါ။",
    "email_placeholder": "အီးမေးလ်",
    "email_empty": "ကျေးဇူးပြု၍ အီးမေးလ်ထည့်ပါ။",
    "email_not_valid": "အီးမေးလ် မမှန်ပါ။",
    "email_not_valid_1": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ။",
    "email_not_valid_2": "အီးမေးလ်သည် မမှန်ကန်ပါ၊ ကျေးဇူးပြု၍ အခြားလိပ်စာကို အသုံးပြုပါ။",
    "email_not_valid_3": "အီးမေးလ် ထည့်သွင်းခြင်း မရှိပါ။",
    "password_placeholder": "စကားဝှက်",
    "password_empty": "စကားဝှက်ထည့်ပါ။",
    "password_not_valid": "အကောင့် သို့မဟုတ် စကားဝှက် မမှန်ကန်ပါ။",
    "password_not_valid_1": "စာလုံး 8 လုံးအထက် စကားဝှက် လိုအပ်ပါသည်။",
    "password_not_valid_2": "စကားဝှက်တစ်ခု ဖန်တီးပါ။",
    "password_placeholder_1": "သင်၏စကားဝှက်ကိုဖန်တီးပါ။",
    "password_placeholder_2": "သင့်စကားဝှက်ကို အတည်ပြုပါ။",
    "password_placeholder_3": "စကားဝှက်အသစ်ဖန်တီးပါ။",
    "password_placeholder_4": "စကားဝှက်အသစ်ကို အတည်ပြုပါ။",
    "password_placeholder_5": "စကားဝှက်ဟောင်းကို ထည့်သွင်းပါ။",
    "copy_password_empty": "စကားဝှက်ကို အတည်ပြုပါ။",
    "copy_password_not_valid": "သင့်စကားဝှက်ကို အတည်ပြုပါ။",
    "copy_passwords_inconsistent": "သင့်စကားဝှက်အတည်ပြုချက်နှင့် မကိုက်ညီပါ။",
    "code_empty": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ။",
    "code_not_valid": "မမှန်ကန်သော အတည်ပြုကုဒ်",
    "code_placeholder": "အတည်ပြုရန်ကုတ်",
    "not_received_code": "သင့်စာတိုက်ပုံးသည် အတည်ပြုကုဒ်ကို အချိန်အကြာကြီး မရရှိပါက၊ အတည်ပြုကုဒ်ကို ထပ်မံရယူပါ။",
    "get_first_code": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ဦးစွာရယူပါ။",
    "last_name_placeholder": "ကျေးဇူးပြု၍ သင်၏အမည်ကို ထည့်ပါ။",
    "first_name_placeholder": "ကျေးဇူးပြု၍ သင့်အမည်ကို ထည့်သွင်းပါ။",
    "address_placeholder": "သင့်လိပ်စာကို ထည့်ပါ။",
    "no_code_text": "ကျွန်ုပ်တို့သည် အတည်ပြုကုဒ်တစ်ခု ပေးပို့ထားပါသည်။ သင့်ကုဒ်ကို ထည့်ပါ။ <span class='tips'>ကုဒ်ကို မရရှိခဲ့ဘူးလား။",
    "no_code_text_1": "1. ကျေးဇူးပြု၍ အီးမေးလ်လိပ်စာသည် တရားဝင်ကြောင်းနှင့် ၎င်းသည် အီးမေးလ်များကို လက်ခံရရှိကြောင်း သေချာပါစေ။",
    "no_code_text_2": "2. အီးမေးလ်ကို စနစ်က အလိုအလျောက် ပေးပို့သည့်အတွက်၊ ၎င်းကို စပမ်း သို့မဟုတ် အမှိုက်အီးမေးလ်အဖြစ် အလံပြထားနိုင်သည်။ ကျေးဇူးပြု၍ အီးမေးလ်သည် အမှိုက်ပုံးဖိုဒါတွင် ရှိမရှိ စစ်ဆေးပါ။",
    "no_code_text_3": "၃။ သင့်ပြဿနာကို မဖြေရှင်းနိုင်ဘူးလား။ ",
    "no_code_text_3_span": "ထို့နောက် ကျွန်ုပ်တို့ထံ ဆက်သွယ်ရန် ဤနေရာကိုနှိပ်ပါ။",
    "order_no": "သင်သည် မည်သည့်ထုတ်ကုန်ကိုမျှ မ၀ယ်ရသေးပါ၊ မေးစရာများရှိပါက <a href=\"https://www.mindonmap.com/contact-us/\">ကျွန်ုပ်တို့ကို ဆက်သွယ်ပါ</a>။",
    "error_24901": "လက်ရှိအကောင့်တွင် အီးမေးလ်ဖြင့် ချိတ်ဆက်ထားခြင်း မရှိသဖြင့် မှာယူမှုများကို ရှာမတွေ့ပါ။ ကျေးဇူးပြု၍ အီးမေးလ်တစ်စောင် ချိတ်ဆက်ပါ။",
    "user_guide": "အသုံးပြုသူလမ်းညွှန် >>",
    "download": "ဒေါင်းလုဒ်လုပ်ပါ။",
    "order_number": "အော်ဒါနံပါတ်:",
    "Refund": "ပြန်အမ်းငွေ",
    "Disabled": "မသန်စွမ်း",
    "Normal": "ပုံမှန်",
    "Modify": "ပြင်ဆင်ပါ။",
    "Modify_1": "ပြင်ဆင်ရန် >>>",
    "Connect": "ချိတ်ဆက်ပါ။",
    "unlink_success": "အောင်မြင်စွာ လင့်ခ်ဖြုတ်ပါ။",
    "connect_success": "အောင်မြင်စွာချိတ်ဆက်ပါ။",
    "feedback_title": "သင့်အကြံပြုချက်အတွက် ကျေးဇူးတင်ပါသည်။ ကျေးဇူးပြု၍ သ",
    "feedback_thank_you": "ကျေးဇူးတင်ပါသည်။<br />သင်၏ အကြံပြုချက်ကို အောင်မြင်စွာ တင်သွင်းပြီးပါပြီ။",
    "feedback_email": "သင့်အီးမေးလ်ကို ဤနေရာတွင် ထည့်ပါ။",
    "feedback_content": "ဤနေရာတွင် သင်ကြုံတွေ့ခဲ့ရသည့် ပြဿနာ သို့မဟုတ် အကြံပြုချက်ကို ချန်ထားခဲ့ပါ။",
    "feedback_submit": "တင်ပြပါ။",
    "form_contents": "သင်သည် မည်သည့်ဖော်ပြချက်မျှ မထည့်ရသေးပါ။ ကျေးဇူးပြု၍ ၎င်းကိုထည့်သွင်းပြီး ထပ်မံတင်ပြပါ။",
    "old_password": "စကားဝှက်ဟောင်းကို ထည့်သွင်းပါ။",
    "new_password": "စကားဝှက်အသစ်တစ်ခု ဖန်တီးပါ။",
    "old_new_password": "စကားဝှက်အသစ်သည် အဟောင်းနှင့် မတူနိုင်ပါ။",
    "incorrect_password": "စကားဝှက် မမှန်ပါ။",
    "delete_no": "ယခု ဖျက်ပါ။",
    "Caps": "Caps lock ဖွင့်ထားသည်။",
    "Get": "ရယူပါ။",
    "Done": "ပြီးပြီ။",
    "error_20001": "အမှား- 20001။ ကျေးဇူးပြု၍ ထပ်မံဝင်ရောက်ပါ။",
    "error_20002": "အမှား- 20002။ ကျေးဇူးပြု၍ ထပ်မံဝင်ရောက်ပါ။",
    "error_20003": "အမှား- 20003။ ကျေးဇူးပြု၍ ထပ်မံဝင်ရောက်ပါ။",
    "error_20004": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20004)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20005": "အကောင့်ဝင်ချိန် သက်တမ်းကုန်သွားပါပြီ (အမှား- 20005)။ ထပ်မံဝင်ရောက်ပါ။",
    "error_20006": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20006)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20007": "အကောင့်ဝင်ချိန် သက်တမ်းကုန်သွားပါပြီ (အမှား- 20007)။ ထပ်မံဝင်ရောက်ပါ။",
    "error_20008": "အကောင့်ဝင်ချိန် သက်တမ်းကုန်သွားပါပြီ (အမှား- 20008)။ ကျေးဇူးပြု၍ ထပ်မံဝင်ရောက်ပါ။",
    "error_20009": "အကောင့်ဝင်ချိန် သက်တမ်းကုန်သွားပါပြီ (အမှား- 20009)။ ထပ်မံဝင်ရောက်ပါ။",
    "error_20101": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20101)",
    "error_20102": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20102)",
    "error_20103": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20103)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20104": "အီးမေးလ်ကို အသုံးပြုထားပြီး၊ ကျေးဇူးပြု၍ <a href=\"https://account.mindonmap.com/login/\">ဝင်ရောက်ပါ</a> သို့မဟုတ် အသစ်တစ်ခုဖြင့် မှတ်ပုံတင်ပါ။",
    "error_20105": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20105)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20106": "အီးမေးလ်ပို့ရန် မအောင်မြင်ပါ၊ ၎င်းကို ထပ်စမ်းကြည့်ပါ။",
    "error_20201": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20201)",
    "error_20202": "သင့်စကားဝှက်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20202)",
    "error_20203": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20203)",
    "error_20204": "အီးမေးလ်သည် မမှန်ကန်ပါ (အမှားကုဒ်- 20204)",
    "error_20205": "စာလုံး 8 လုံးအထက် စကားဝှက် လိုအပ်သည် (အမှားကုဒ်- 20205)",
    "error_20206": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20206)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20207": "မမှန်ကန်သော အတည်ပြုကုဒ်",
    "error_20208": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20208)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20209": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20209)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20301": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20301)",
    "error_20302": "အမှား- 20302။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_20303": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20303)",
    "error_20304": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20304)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20305": "အကောင့်မရှိပါ။ ကျေးဇူးပြု၍ ပြန်လည်ထည့်သွင်းပါ သို့မဟုတ် <a href=\"https://account.mindonmap.com/register/\">၎င်းကို ဖန်တီးပါ</a> ကို ဦးစွာပြုလုပ်ပါ။",
    "error_20306": "စကားဝှက်မရှိသေးပါ။ <a href=\"https://account.mindonmap.com/passwordless-login/\">စကားဝှက်မရှိသောဝင်ရောက်ခြင်း</a> သို့မဟုတ် <a href=\"https://account.mindonmap.com/create-password/\">ကိုသုံးပါ။ စကားဝှက်တစ်ခု</a> သတ်မှတ်ပြီး အကောင့်ဝင်ပါ။",
    "error_20308": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20308)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20401": "ထွက်ရန် မအောင်မြင်ပါ (အမှားကုဒ်- 20401)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20501": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20501)",
    "error_20502": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20502)",
    "error_20503": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20503)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20504": "အီးမေးလ်ပို့ရန် မအောင်မြင်ပါ။ ထပ်စမ်းကြည့်ပါ။",
    "error_20601": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20601)",
    "error_20602": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20602)",
    "error_20603": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20603)",
    "error_20604": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20604)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20606": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20606)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20607": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20607)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20608": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20608)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20701": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20701)",
    "error_20702": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20702)",
    "error_20703": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20703)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20704": "အကောင့်မရှိပါ။ ကျေးဇူးပြု၍ ပြန်လည်ထည့်သွင်းပါ သို့မဟုတ် <a href=\"https://account.mindonmap.com/register/\">၎င်းကို ဖန်တီးပါ</a> ကို ဦးစွာပြုလုပ်ပါ။",
    "error_20705": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20705)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20706": "အီးမေးလ်ပို့ရန် မအောင်မြင်ပါ။ ထပ်စမ်းကြည့်ပါ။",
    "error_20801": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20801)",
    "error_20802": "အမှား- 20802။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_20803": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 20803)",
    "error_20804": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 20804)",
    "error_20805": "စာလုံး 8 လုံးအထက် စကားဝှက် လိုအပ်သည် (အမှားကုဒ်- 20805)",
    "error_20806": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20806)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20808": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20808)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20901": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20901)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20902": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 20902)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21000": "အပြောင်းအလဲများကို သိမ်းဆည်းထားသည်။",
    "error_21001": "အချက်အလက်မတင်ပြပါ (အမှားကုဒ်- 21001)",
    "error_21002": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21002)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21101": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 21101)",
    "error_21102": "အီးမေးလ်သည် မမှန်ကန်ပါ (အမှားကုဒ်- 21102)",
    "error_21103": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21103)၊ ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပါ။",
    "error_21104": "အီးမေးလ်ကို ချိတ်ဆက်ထားပြီး၊ ကျေးဇူးပြု၍ အသစ်တစ်ခုကို အသုံးပြုပါ။",
    "error_21105": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21105)၊ ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပါ။",
    "error_21106": "အီးမေးလ်ပို့ရန် မအောင်မြင်ပါ။ ထပ်စမ်းကြည့်ပါ။",
    "error_21201": "သင့်အီးမေးလ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 21201)",
    "error_21202": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 21202)",
    "error_21203": "အီးမေးလ် မမှန်ပါ (အမှားကုဒ်- 21203)",
    "error_21204": "အမှား- 21204။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21205": "အမှား- 21205။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21206": "စာလုံး 8 လုံးအထက် စကားဝှက် လိုအပ်သည် (အမှားကုဒ်- 21206)",
    "error_21207": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21207)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21209": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21209)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21301": "စကားဝှက်ဟောင်းကို ထည့်ပါ (အမှားကုဒ်- 21301)",
    "error_21302": "စကားဝှက်အသစ်တစ်ခု ဖန်တီးပါ (အမှားကုဒ်- 21302)",
    "error_21303": "စကားဝှက်အသစ်သည် အဟောင်းနှင့် မတူနိုင်ပါ။ (အမှား- ၂၁၃၀၃)",
    "error_21304": "စာလုံး 8 လုံးအထက် စကားဝှက် လိုအပ်သည် (အမှားကုဒ်- 21304)",
    "error_21306": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21306)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21402": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21402)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21403": "အတည်ပြုကုဒ်ကို ပို့၍မရပါ။ ကျေးဇူးပြု၍ ပြန်ပို့ပါ။",
    "error_21500": "အကောင့်ကို ဖျက်လိုက်ပါပြီ။",
    "error_21501": "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်သွင်းပါ (အမှားကုဒ်- 21501)",
    "error_21502": "အကောင့်ဝင်ချိန် သက်တမ်းကုန်သွားပါပြီ (အမှား- 21502)။ ကျေးဇူးပြု၍ ထပ်မံဝင်ရောက်ပါ။",
    "error_21503": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21503)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21505": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21505)၊ ကျေးဇူးပြု၍ ၎င်းကို ထပ်စမ်းကြည့်ပါ။",
    "error_21601": "အမှား- 20601။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21602": "မမှန်ကန်သော အတည်ပြုခြင်း (အမှား- 20602)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21603": "အမှား- 20603။ ထပ်စမ်းကြည့်ပါ။",
    "error_21604": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21604)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21606": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21606)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21611": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21611)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21801": "အမှား- 21801။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21802": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှား- 21802)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21803": "အမှား- 21803။ ထပ်စမ်းကြည့်ပါ။",
    "error_21804": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21804)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21806": "အမှား- 21806။ ထပ်စမ်းကြည့်ပါ။",
    "error_21807": "အမှား- 21807။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21808": "အမှား- 21808။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21809": "အမှား- 21809။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21810": "အမှား- 21810။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21811": "အမှား- 21811။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21812": "အမှား- 21812။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21813": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21813)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21814": "အမှား- 21814။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21815": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21815)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21816": "အမှား- 21816။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21817": "အမှား- 21817။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21818": "အမှား- 21818။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21819": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21819)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21820": "အမှား- 21820။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21821": "အမှား- 21821။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21822": "အမှား- 21822။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21823": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21823)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21824": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21824)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21825": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21825)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21826": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21826)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21901": "အမှား- 21901။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_21902": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21902)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21903": "အကောင့်အခြေအနေ ပြောင်းလဲသွားပါပြီ (အမှားကုဒ်- 21903)၊ ကျေးဇူးပြု၍ စာမျက်နှာကို ပြန်လည်ဆန်းသစ်ပြီး ထပ်စမ်းကြည့်ပါ။",
    "error_21904": "အမှား- 21904။ ထပ်စမ်းကြည့်ပါ။",
    "error_21905": "အမှား- 21905။ ထပ်စမ်းကြည့်ပါ။",
    "error_21906": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21906)။ ထပ်စမ်းကြည့်ပါ။",
    "error_21907": "Google အကောင့်ကို အခြားအကောင့်နှင့် ချိတ်ဆက်ထားသည်။",
    "error_21908": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 21908)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22001": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22001)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22002": "ထပ်မံဝင်ရောက်ခြင်းမရှိသဖြင့် လင့်ခ်ဖြုတ်ခြင်း မအောင်မြင်ပါ။",
    "error_22003": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22003)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22101": "အမှား- 22101။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22102": "အကောင့်အခြေအနေ ပြောင်းလဲသွားပါပြီ (အမှားကုဒ်- 22102)၊ ကျေးဇူးပြု၍ စာမျက်နှာကို ပြန်လည်ဆန်းသစ်ပြီး ထပ်စမ်းကြည့်ပါ။",
    "error_22103": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22103)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22104": "အကောင့်အခြေအနေ ပြောင်းလဲသွားပါပြီ (အမှားကုဒ်- 22104)၊ ကျေးဇူးပြု၍ စာမျက်နှာကို ပြန်လည်ဆန်းသစ်ပြီး ထပ်စမ်းကြည့်ပါ။",
    "error_22105": "အမှား- 22105။ ထပ်စမ်းကြည့်ပါ။",
    "error_22106": "အမှား- 22106။ ထပ်စမ်းကြည့်ပါ။",
    "error_22107": "အမှား- 22107။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22108": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22108)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22201": "အမှား- 22201။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22202": "မမှန်ကန်သော အတည်ပြုခြင်း (အမှား- 22202)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22203": "အမှား- 22203။ ထပ်စမ်းကြည့်ပါ။\"",
    "error_22204": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22204)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22206": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22206)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22401": "အမှား- 22401။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22402": "မမှန်ကန်သော အတည်ပြုခြင်း (အမှား- 22402)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22403": "အမှား- 22403။ ထပ်စမ်းကြည့်ပါ။",
    "error_22404": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22404)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22405": "Facebook အကောင့်ကို အခြားအီးမေးလ်နှင့် ချိတ်ဆက်ထားသည်။",
    "error_22406": "အမှား- 22406။ ထပ်စမ်းကြည့်ပါ။",
    "error_22407": "အမှား- 22407။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22408": "အမှား- 22408။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22409": "အမှား- 22409။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22410": "အမှား- 224010။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22411": "အမှား- 224011။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22412": "အမှား- 224012။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22413": "အမှား- 22413။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22414": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22414)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22415": "အမှား- 22415။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22416": "အမှား- 22416။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22417": "အမှား- 22417။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22418": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22418)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22419": "အမှား- 22419။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22420": "အမှား- 22420။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22421": "အမှား- 22421။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_22422": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22422)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22423": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22423)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22424": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22424)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22425": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 22425)။ ထပ်စမ်းကြည့်ပါ။",
    "error_20098": "အမှားကုဒ်- 20098။ သီးသန့်မုဒ်တွင် သင်ရှာဖွေနေပါက၊ ကျေးဇူးပြု၍ ပုံမှန်မုဒ်သို့ ပြောင်းပြီး ထပ်စမ်းကြည့်ပါ။",
    "error_22298": "Google အတွက် တောင်းဆိုမှု မအောင်မြင်ပါ (အမှား- 22298)။ ထပ်စမ်းကြည့်ပါ။",
    "error_22498": "Facebook အတွက် တောင်းဆိုမှု မအောင်မြင်ပါ (အမှား- 22498)။ ထပ်စမ်းကြည့်ပါ။",
    "error_24902": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 24902)။ ထပ်စမ်းကြည့်ပါ။",
    "error_24903": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 24903)။ ထပ်စမ်းကြည့်ပါ။",
    "error_24904": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 24904)။ ထပ်စမ်းကြည့်ပါ။",
    "error_24905": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 24905)။ ထပ်စမ်းကြည့်ပါ။",
    "login_title": "MindOnMap သို့ ဝင်ရောက်ပါ။",
    "log_in": "လော့ဂ်အင်",
    "no_account": "အကောင့်မရှိဘူးလား။",
    "create_it": "ဖန်တီးပါ။",
    "or_log_in_with": "သို့မဟုတ် အကောင့်ဖြင့် ဝင်ရောက်ပါ။",
    "passwordless_login": "စကားဝှက်မဲ့ အကောင့်ဝင်ပါ။",
    "log_in_done": "အကောင့်ဝင်ပြီးပါပြီ။",
    "three_rd_account_connect_info": "ဂုဏ်ယူပါသည်။ သင်သည် အောင်မြင်စွာ အကောင့်ဝင်ပြီးပါပြီ။ ယခု သင်သည် အနာဂတ်တွင် အကောင့်ဝင်ရန် အသုံးပြုသည့် အီးမေးလ်အကောင့်တစ်ခုကို ချိတ်ဆက်နိုင်ပါပြီ။",
    "see_my_account": "ငါ့အကောင့်ကိုကြည့်ပါ။",
    "three_rd_login_merge_account": "ပြင်ပအဖွဲ့အစည်း အကောင့်အီးမေးလ်လိပ်စာကို စာရင်းသွင်းထားပြီး၊ သင်သည် ဤအီးမေးလ်လိပ်စာနှင့် တိုက်ရိုက်ချိတ်ဆက်ပြီး အကောင့်ဝင်လိုပါသလား။",
    "connect_log_in": "ချိတ်ဆက်ပြီး အကောင့်ဝင်ပါ။",
    "create_an_account": "အကောင့်တစ်ခုဖန်တီးသည်",
    "back_to_log_in": "လော့ဂ်အင်သို့ ပြန်သွားရန်",
    "create_password": "စကားဝှက်ဖန်တီးပါ။",
    "create_now": "ယခုဖန်တီးပါ။",
    "password_login_subtitle": "အီးမေးလ်ဖြင့် စကားဝှက်မပါဘဲ ဝင်ရောက်ပါ။",
    "account_login": "အကောင့်ဝင်ပါ။",
    "rights": "ဤအကောင့်ကိုဖန်တီးခြင်းဖြင့်၊ သင်သည် <a href=\"https://www.mindonmap.com/terms-and-conditions/\">ဝန်ဆောင်မှုစည်းမျဉ်းများ</a> နှင့် <a href=\"https://www.mindonmap.com/privacy-policy/\">ကိုယ်ရေးကိုယ်တာမူဝါဒ</a>",
    "passwordless_login_done": "စကားဝှက်မဲ့ အကောင့်ဝင်ခြင်း ပြီးပါပြီ။",
    "passwordless_login_info": "ဂုဏ်ယူပါသည်၊ သင်သည် စကားဝှက်မဲ့ အကောင့်ဝင်ခြင်းကို အောင်မြင်စွာ ပြီးမြောက်အောင်မြင်ခဲ့ပါသည်။ သင်သည် ဤအကောင့်အတွက် စကားဝှက်တစ်ခု ဖန်တီးပြီး အနာဂတ်တွင် အကောင့်နှင့် စကားဝှက်ဖြင့် ဝင်ရောက်နိုင်သည်။ <a href=\"/create-password\" style=\"display: initial;\">ယခုဖန်တီးပါ</a>",
    "sign_up": "ဆိုင်းအပ်",
    "register_info": "သင့်အကောင့်ကိုဖန်တီးပါ",
    "reset_now": "ယခု ပြန်လည်သတ်မှတ်ပါ။",
    "forgot_password": "စကားဝှက်မေ့နေပါသလား",
    "reset_password_subtitle": "စကားဝှက်ကို ပြန်လည်သတ်မှတ်ရန် သင့်အကောင့်အီးမေးလ်ကို အသုံးပြုပါ။",
    "plan_products": "အစီအစဉ်များနှင့် ထုတ်ကုန်များ",
    "nick_name": "အသုံးပြုသူအမည်-",
    "email": "အီးမေးလ်-",
    "my_products": "ကျွန်ုပ်၏ထုတ်ကုန်များ",
    "my_orders": "ငါ့အမိန့်များ",
    "unlink": "လင့်ခ်ဖြုတ်ပါ။",
    "link": "လင့်",
    "connected_accounts": "ချိတ်ဆက်ထားသော အကောင့်များ",
    "last_name": "မျိုးနွယ်အမည်:",
    "first_name": "နာမည်:",
    "Gender": "လိင်-",
    "Birth": "မွေးဖွားခြင်း-",
    "Month": "လ",
    "Year": "တစ်နှစ်",
    "Country_Region": "နိုင်ငံ/ဒေသ-",
    "Address": "လိပ်စာ-",
    "Save": "သိမ်းဆည်းပါ။",
    "Date": "ရက်စွဲ",
    "Male": "အထီး",
    "Female": "အပျို",
    "Unspecified": "သတ်မှတ်မထားသော",
    "Security": "လုံခြုံရေး",
    "change_password": "စကားဝှက်ကိုပြောင်းရန်",
    "change_now": "ယခုပြောင်းပါ။",
    "connect_email": "အီးမေးလ်ကို ချိတ်ဆက်ပါ။",
    "delete_account": "အကောင့်ဖျက်ပါ။",
    "delete_account_info": "သင့်အကောင့်ကို ဖျက်လိုက်သောအခါတွင်၊ သင့်အကောင့်နှင့်ဆက်စပ်နေသော ကျွန်ုပ်၏အကောင့်ရှိ ဒေတာအားလုံးကို အပြီးအပိုင်ဖျက်သွားမည်ဖြစ်ပြီး ၎င်းကို သင်ပြန်လည်ရယူ၍မရနိုင်ပါ။ သတိရှိရှိ နင်းဖို့ အကြံပြုလိုပါတယ်။",
    "Delete": "ဖျက်ပါ။",
    "Logout": "ထွက်လိုက်ပါ။",
    "my_profile": "ကျွန်တော့် Profile",
    "guides_faqs": "လမ်းညွှန်များနှင့် FAQs",
    "More": "နောက်ထပ်",
    "guides": "လမ်းညွှန်များ",
    "register": "မှတ်ပုံတင်",
    "hot_faq": "Hot FAQ",
    "Contents": "အကြောင်းအရာများ-",
    "contact_us": "ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။ >>",
    "plan": "အစီအစဉ်",
    "unregistered": "မှတ်ပုံမတင်",
    "buy_more": "ပိုမိုဝယ်ယူပါ။",
    "buy_again": "ထပ်ဝယ်ပါ။",
    "buy_now": "အခုဝယ်ပါ",
    "free_no_limit": "အခမဲ့ & ကန့်သတ်ချက်မရှိပါ။",
    "expired": "သက်တမ်းကုန်သွားပြီ",
    "lifetime": "တစ်သက်တာ",
    "remain": "ကျန်နေပါ။",
    "day_s": "နေ့(များ)",
    "error_24801": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 24801)။ ထပ်စမ်းကြည့်ပါ။",
    "no_app_found": "မည်သည့်အက်ပ်မှမတွေ့ပါ!<a href=\"/\">ပြန်လည်စတင်ရန်</a> သို့မဟုတ် <a href=\"https://www.mindonmap.com/\">တရားဝင်ဝဘ်ဆိုက်သို့ သွားပါ</a>",
    "get_more": "ပိုမိုရယူပါ >>",
    "edit_photo": "ဓာတ်ပုံကို တည်းဖြတ်ပါ။",
    "select_photo": "ဓာတ်ပုံကို ရွေးပါ။",
    "change_photo": "ဓာတ်ပုံပြောင်းပါ။",
    "cancel": "မလုပ်တော့",
    "hide_password": "စကားဝှက်ကို ဝှက်ထားပါ။",
    "show_password": "လှို့ဝှက်စကားလုံးကိုဖော်ပြပါ",
    "zoom_in": "ချဲ့ကြည့်ပါ။",
    "zoom_out": "ချဲ့ကြည့်ပါ။",
    "rotate": "လှည့်",
    "horizontal_flip": "အလျားလိုက်လှန်ပါ။",
    "vertical_flip": "ဒေါင်လိုက်လှန်ပါ။",
    "country": "တိုင်းပြည်",
    "country_1": "သင့်နိုင်ငံ/ဒေသကို ရွေးပါ။",
    "country_2": "Åaland ကျွန်း",
    "country_3": "အာဖဂန်နစ္စတန်",
    "country_4": "အယ်လ်ဘေးနီးယား",
    "country_5": "အယ်လ်ဂျီးရီးယား",
    "country_6": "အမေရိကန်ဆာမိုအာ",
    "country_7": "အန်ဒိုရာ",
    "country_8": "အင်ဂိုလာ",
    "country_9": "Anguilla",
    "country_10": "အန္တာတိက",
    "country_11": "အန်တီဂွာ နှင့် ဘာဘူဒါ",
    "country_12": "အာဂျင်တီးနား",
    "country_13": "အာမေးနီးယား",
    "country_14": "Aruba",
    "country_15": "သြစတြေးလျ",
    "country_16": "သြစတြီးယား",
    "country_17": "အဇာဘိုင်ဂျန်",
    "country_18": "ဘာရိန်း",
    "country_19": "ဘင်္ဂလားဒေ့ရှ်",
    "country_20": "ဘာဘေးဒိုးစ်",
    "country_21": "ဘီလာရုစ်",
    "country_22": "ဘယ်လ်ဂျီယံ",
    "country_23": "ဘေလီဇ်",
    "country_24": "နင်",
    "country_25": "ဘာမြူဒါ",
    "country_26": "ဘူတန်",
    "country_27": "ဘိုလီးဗီးယား",
    "country_28": "ဘော့စနီးယား နှင့် ဟာဇီဂိုဗီးနား",
    "country_29": "ဘော့ဆွာနာ",
    "country_30": "Bouvet ကျွန်း",
    "country_31": "ဘရာဇီး",
    "country_32": "ဗြိတိသျှ အိန္ဒိယ သမုဒ္ဒရာ နယ်မြေ",
    "country_33": "British Virgin ကျွန်းများ",
    "country_34": "ဘရူနိုင်း",
    "country_35": "ဘူလ်ဂေးရီးယား",
    "country_36": "ဘာကီနာ",
    "country_37": "ဘူရွန်ဒီ",
    "country_38": "ကမ္ဘောဒီးယား",
    "country_39": "ကင်မရွန်း",
    "country_40": "ကနေဒါ",
    "country_41": "ကိပ်ဗာဒီ",
    "country_42": "ကာရေဘီယံ နယ်သာလန်",
    "country_43": "Cayman ကျွန်းများ",
    "country_44": "ဗဟိုအာဖရိကသမ္မတနိုင်ငံ",
    "country_45": "ချဒ်",
    "country_46": "ချီလီ",
    "country_47": "တရုတ်",
    "country_48": "ခရစ္စမတ် ကျွန်း",
    "country_49": "Cocos (Keeling) ကျွန်းများ",
    "country_50": "ကိုလံဘီယာ",
    "country_51": "Cook ကျွန်းများ",
    "country_52": "ကော့စတာရီကာ",
    "country_53": "အိုင်ဗိုရီ",
    "country_54": "ခရိုအေးရှား",
    "country_55": "ကျူး",
    "country_56": "ဆိုက်ပရပ်စ်",
    "country_57": "ချက်သမ္မတနိုင်ငံ",
    "country_58": "ကွန်ဂို ဒီမိုကရက်တစ် သမ္မတနိုင်ငံ",
    "country_59": "ဒိန်းမတ်",
    "country_60": "ဂျီဘူတီ",
    "country_61": "ဒိုမီနီကာ",
    "country_62": "ဒိုမီနီကန်သမ္မတနိုင်ငံ",
    "country_63": "အီကွေဒေါ",
    "country_64": "အီဂျစ်",
    "country_65": "အယ်လ်ဆာဗေးဒေါ",
    "country_66": "အီကွေတိုရီရယ်ဂီနီ",
    "country_67": "အီရီထရီးယား",
    "country_68": "အက်စ်တိုးနီးယား",
    "country_69": "အီသီယိုးပီးယား",
    "country_70": "ဖော့ကလန် ကျွန်းများ",
    "country_71": "ဖာရိုး ကျွန်းများ",
    "country_72": "Federated States of Micronesia",
    "country_73": "ဖီဂျီ",
    "country_74": "ဖင်လန်",
    "country_75": "ပြင်သစ်",
    "country_76": "French Giana",
    "country_77": "ပြင်သစ် ပိုလီနီးရှား",
    "country_78": "ပြင်သစ်တောင်ပိုင်း နယ်မြေများ",
    "country_79": "ဂါဘွန်",
    "country_80": "ဂမ်ဘီယာ",
    "country_81": "ဂျော်ဂျီယာ",
    "country_82": "ဂျာမနီ",
    "country_83": "ဂါနာ",
    "country_84": "Gibraltar",
    "country_85": "ဂရိတ်ဗြိတိန် (ယူနိုက်တက်ကင်းဒမ်း; အင်္ဂလန်)",
    "country_86": "ဟေ",
    "country_87": "ဂရင်းလန်း",
    "country_88": "Grenada",
    "country_89": "ဂွတ်",
    "country_90": "ဂူအမ်",
    "country_91": "ဂွါတီမာလာ",
    "country_92": "ဂွာစီ",
    "country_93": "ဂီနီ",
    "country_94": "ဂီနီဘစ်ဆော",
    "country_95": "ဂိုင်ယာနာ",
    "country_96": "ဟေတီ",
    "country_97": "Heard Island နှင့် McDonald ကျွန်းများ",
    "country_98": "ဟွန်ဒူးရပ်စ်",
    "country_99": "ဟောင်ကောင်",
    "country_100": "ဟန်ဂေရီ",
    "country_101": "လန်",
    "country_102": "အိန္ဒိယ",
    "country_103": "အင်ဒိုနီးရှား",
    "country_104": "အီရန်",
    "country_105": "အီရတ်",
    "country_106": "အိုင်ယာလန်",
    "country_107": "Isle of Man",
    "country_108": "အစ္စရေး",
    "country_109": "အီတလီ",
    "country_110": "ဂျ",
    "country_111": "ဂျပန်",
    "country_112": "ဂျာစီ",
    "country_113": "ဂျော်ဒန်",
    "country_114": "ဇတ်",
    "country_115": "ကင်ညာ",
    "country_116": "ကီရီဘာတီ",
    "country_117": "ကူဝိတ်",
    "country_118": "ကာဂျစ္စတန်",
    "country_119": "လာအို",
    "country_120": "လတ်",
    "country_121": "လက်ဘနွန်",
    "country_122": "လီဆိုသို",
    "country_123": "လိုက်ဘေးရီးယား",
    "country_124": "လစ်ဗျား",
    "country_125": "လစ်ချ်တင်စတိန်း",
    "country_126": "လစ်သူယေးနီးယား",
    "country_127": "လူဇင်ဘတ်",
    "country_128": "မကာအို",
    "country_129": "မဒါဂတ်စကာ",
    "country_130": "မော်လဝီ",
    "country_131": "မလေးရှား",
    "country_132": "မော်လဒိုက်",
    "country_133": "မာလီ",
    "country_134": "မော်လ",
    "country_135": "Marshall ကျွန်းများ",
    "country_136": "မာတီနစ်",
    "country_137": "မော်ရီတေးနီးယား",
    "country_138": "မောရစ်သျှ",
    "country_139": "မာယော့",
    "country_140": "မက္ကဆီကို",
    "country_141": "မော်လ်ဒိုဗာ",
    "country_142": "မိုနာကို",
    "country_143": "မွန်ဂိုလီးယား",
    "country_144": "မွန်တီနီဂရိုး",
    "country_145": "Montserrat",
    "country_146": "မော်",
    "country_147": "မိုဇမ်ဘစ်",
    "country_148": "မြန်မာ (Burma)",
    "country_149": "နမီးဘီးယား",
    "country_150": "Nauru",
    "country_151": "နီပေါ",
    "country_152": "နယ်သာလန်",
    "country_153": "New Caledonia",
    "country_154": "နယူးဇီလန်",
    "country_155": "နီကာရာဂွာ",
    "country_156": "နိုင်ဂျာ",
    "country_157": "နိုင်ဂျီးရီးယား",
    "country_158": "Niue",
    "country_159": "Norfolk ကျွန်း",
    "country_160": "မြောက်ကိုရီးယား",
    "country_161": "မြောက်မာရီယာနာ ကျွန်းများ",
    "country_162": "နော်ဝေး",
    "country_163": "အိုမန်",
    "country_164": "ပါကစ္စတန်",
    "country_165": "ပလောင်",
    "country_166": "ပါလက်စတိုင်းနယ်မြေများ",
    "country_167": "ပနားမား",
    "country_168": "ပါပူဝါ နယူးဂီနီ",
    "country_169": "ပါရာဂွေး",
    "country_170": "ပီရူး",
    "country_171": "Pitcairn ကျွန်းများ",
    "country_172": "ပိုလန်",
    "country_173": "ပေါ်တူဂီ",
    "country_174": "ပွာတိုရီကို",
    "country_175": "ကာတာ",
    "country_176": "Macedonia သမ္မတနိုင်ငံ (FYROM)",
    "country_177": "ကွန်ဂိုသမ္မတနိုင်ငံ",
    "country_178": "ရီယူနီယံ",
    "country_179": "ရိုမေးနီးယား",
    "country_180": "ရုရှားပြည်ထောင်စု",
    "country_181": "ရဝမ်ဒါ",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (ပြင်သစ်)",
    "country_184": "Saint-Pierre နှင့် Miquelon",
    "country_185": "ဆာမိုအာ",
    "country_186": "ဆန်မာရီနို",
    "country_187": "Sao Tome & Principe",
    "country_188": "ဆော်ဒီအာရေဗျ",
    "country_189": "ဆီနီဂေါ",
    "country_190": "ဆားဗီးယား",
    "country_191": "ဆေးရှဲလ်",
    "country_192": "ဆီးရီးယားလီယွန်း",
    "country_193": "စင်္ကာပူ",
    "country_194": "ဆလိုဗက်ကီးယား",
    "country_195": "ဆလိုဗေးနီးယား",
    "country_196": "ဆော်လမွန် ကျွန်းများ",
    "country_197": "ဆိုမာလီယာ",
    "country_198": "တောင်အာဖရိက",
    "country_199": "South Georgia နှင့် South Sandwich Isla",
    "country_200": "တောင်ကိုရီးယား",
    "country_201": "တောင်ဆူဒန်",
    "country_202": "စပိန်",
    "country_203": "သီရိလင်္ကာ",
    "country_204": "စိန့် Helena & မှီခိုမှု",
    "country_205": "St. Kitts & Nevis",
    "country_206": "စိန့်လူစီယာ",
    "country_207": "စိန့်ဗင်းဆင့်နှင့် Grenadines",
    "country_208": "ဆူဒန်",
    "country_209": "ဆူရီနမ်",
    "country_210": "ဆွာဇီလန်",
    "country_211": "ဆွီဒင်",
    "country_212": "ဆွစ်ဇာလန်",
    "country_213": "ဆီးရီးယား",
    "country_214": "ထိုင်ဝမ်",
    "country_215": "တာဂျစ်ကစ္စတန်",
    "country_216": "တန်ဇန်းနီးယား",
    "country_217": "နမူနာ-နိုင်ငံ ဒေတာ SJM ဗဲဘာ့ဒ်",
    "country_218": "ထိုင်း",
    "country_219": "ဘဟားမားစ်",
    "country_220": "ကော်မိုရိုများ",
    "country_221": "ဖိလစ်ပိုင်လူမျိုးများ",
    "country_222": "Timor-Leste (အရှေ့ တီမော)",
    "country_223": "သွားရန်",
    "country_224": "Tokelau",
    "country_225": "တွန်ဂါ",
    "country_226": "ထရီနီဒက်နှင့် တိုဘာဂို",
    "country_227": "တူနီးရှား",
    "country_228": "ကြက်ဆင်",
    "country_229": "တာ့ခ်မင်နစ္စတန်",
    "country_230": "Turks & Caicos ကျွန်းများ",
    "country_231": "တူဗာလူ",
    "country_232": "ယူဂန်ဒါ",
    "country_233": "ယူကရိန်း",
    "country_234": "အာရပ်စော်ဘွားများ ပြည်ထောင်စု",
    "country_235": "United States Minor Outlying ကျွန်းများ",
    "country_236": "United States of America (USA)",
    "country_237": "United States Virgin ကျွန်းများ",
    "country_238": "ဥရုဂွေး",
    "country_239": "ဧကစ္စတန်",
    "country_240": "ဗာနူအာတူ",
    "country_241": "ဗာတီကန်မြို့တော် (The Holy See)",
    "country_242": "ဗင်နီဇွဲလား",
    "country_243": "ဗီယက်နမ်",
    "country_244": "Wallis နှင့် Futuna",
    "country_245": "အနောက်ဆာဟာရ",
    "country_246": "ယီမင်",
    "country_247": "ဇမ်ဘီယာ",
    "country_248": "ဇင်ဘာဘွေ",
    "google_login": "Google ဖြင့် လက်မှတ်ထိုးဝင်ပါ။",
    "State": "ပြည်နယ်",
    "Activation_code": "အသက်သွင်းခြင်းကုဒ်",
    "Question": "သင်ဝင်ရောက်ထားသည့် အက်ပ်များအားလုံးကို စာရင်းပြုစုပါ။",
    "Copy_complete": "ကူးယူ ပြီးပါပြီ။",
    "footer": "မူပိုင်ခွင့် © 2024 MindOnMap Studio မူပိုင်ခွင့်ကိုလက်ဝယ်ထားသည်။",
    "change_password_success": "စကားဝှက်ကို အောင်မြင်စွာ ပြောင်းလဲခဲ့သည်။",
    "successful_login_title": "အကောင့်ဝင်အောင်မြင်",
    "product_page": "ထုတ်ကုန်စာမျက်နှာ >>",
    "successful_login_info": "အကောင့်ဝင်ပြီးပါပြီ။ ကျေးဇူးပြု၍ လက်ရှိစာမျက်နှာကို ပိတ်ပြီး အောက်ပါလုပ်ငန်းစဉ်ကို ဆက်လက်လုပ်ဆောင်ရန် မူရင်းတက်ဘ်သို့ ပြန်သွားပါ။ လက်ရှိစာမျက်နှာကို 5 စက္ကန့်အတွင်း အလိုအလျောက် ပိတ်သွားပါမည်။ \"ပြီးပြီ\" ခလုတ်ကိုနှိပ်ခြင်းဖြင့် အလိုအလျောက်ပိတ်ခြင်း သို့မဟုတ် ပိတ်ခြင်း မအောင်မြင်ပါက၊ ကျေးဇူးပြု၍ ဤတက်ဘ်ကို တိုက်ရိုက်ပိတ်ပါ။",
    "successful_login_info_firefox": "အကောင့်ဝင်ပြီးပါပြီ။ ကျေးဇူးပြု၍ လက်ရှိစာမျက်နှာကို ပိတ်ပြီး အောက်ပါလုပ်ငန်းစဉ်ကို ဆက်လက်လုပ်ဆောင်ရန် မူရင်းတက်ဘ်သို့ ပြန်သွားပါ။",
    "my_account": "ကြှနျုပျအကောငျ့",
    "my_history": "ငါ့သမိုင်း",
    "remove_watermark": "Watermark ကို ဖယ်ရှားပါ။",
    "no_history": "သမိုင်းမရှိပါ။",
    "history_all": "အားလုံးကို ရွေးပါ။",
    "history_open": "ဖွင့်သည်။",
    "history_down": "ဒေါင်းလုဒ်လုပ်ပါ။",
    "history_delete": "ဖျက်ပါ။",
    "history_clear": "မမှန်ကန်ကြောင်း ရှင်းလင်းပါ။",
    "images": "ပုံ(များ)",
    "use_this_function": "ဤထုတ်ကုန်ကိုအသုံးပြုပါ။ >>",
    "hd_downloading": "HD မူရင်းပုံများကို ဒေါင်းလုဒ်လုပ်ခြင်း အကျိုးကျေးဇူးများ",
    "lifetimeRemaining": "တစ်သက်တာ-လက်ကျန်",
    "Remaining": "လက်ကျန်",
    "email_verification": "အီးမေးလ်အတည်ပြုခြင်း။",
    "email_verification_info": "သင့်အီးမေးလ် <span class=\"email\"></span> သို့ အတည်ပြုရေးစာတိုကို ကျွန်ုပ်တို့ ပေးပို့ထားပြီး၊ အတည်ပြုခြင်းကို အပြီးသတ်ပါ။ အတည်ပြုပြီးနောက်၊ အကျိုးခံစားခွင့်များသည် အလိုအလျောက် ထပ်တူပြုပါမည်။",
    "wrong_email": "အီးမေးလ်လိပ်စာ မှားနေပါသလား။",
    "click_here_to_modify": "ပြင်ဆင်ရန် ဤနေရာကိုနှိပ်ပါ။",
    "get_more_help": "နောက်ထပ်အကူအညီရယူလိုပါသလား။",
    "click_here": "ဤနေရာကိုကလစ်နှိပ်ပါ",
    "email_verification_info_success": "သင့်အီးမေးလ်အကောင့်ကို အတည်ပြုခြင်းအတွက် ဂုဏ်ယူပါသည်။",
    "email_verification_info_error": "သက်တမ်းကုန်သွားသော လင့်ခ်ကြောင့် အတည်ပြုခြင်း မအောင်မြင်ပါ။",
    "registration_succeeded": "မှတ်ပုံတင်ခြင်း အောင်မြင်ပါသည်။",
    "registration_succeeded_info_1": "ဂုဏ်ယူပါသည်။ အောင်မြင်စွာ စာရင်းသွင်းပြီးပါပြီ။ သင့်အီးမေးလ် <span class=\"email\"></span> သို့ အတည်ပြုရေးစာတိုကို ပေးပို့ခဲ့ပြီး၊ ဤအီးမေးလ်၏ အကျိုးကျေးဇူးများကို အသုံးပြုရန် အတည်ပြုခြင်းကို အပြီးသတ်ပါ။",
    "registration_succeeded_info_2": "ပင်မစာမျက်နှာသို့ပြန်သွားပြီး ဤထုတ်ကုန်ကိုအသုံးပြုရန် \"ပြီးပါပြီ\" ကိုနှိပ်ပါ။",
    "registration_succeeded_info_3": "လက်ရှိစာမျက်နှာကိုပိတ်ရန်နှင့် အောက်ပါလုပ်ဆောင်ချက်များအတွက် မူလစာမျက်နှာသို့ ပြန်သွားရန် \"ပြီးပြီ\" ကိုနှိပ်ပါ။ ပိတ်ခြင်း အဆင်မပြေပါက တက်ဘ်ကို ကိုယ်တိုင်ပိတ်ပါ။",
    "verify_email": "အီးမေးလ်ကို အတည်ပြုပါ။",
    "registered_email_not_verified": "စာရင်းသွင်းထားသော အီးမေးလ်ကို အတည်မပြုရသေးပါ၊ ကျေးဇူးပြု၍ ချက်ခြင်း အတည်ပြုပါ။",
    "email_verification_time_1": "အတည်ပြုအီးမေးလ်ကို မရရှိခဲ့ဘူးလား။",
    "email_verification_time_2": "ပြီးနောက်",
    "email_verification_time_3": "ပြန်လည်ပေးပို့ရန် ဤနေရာကိုနှိပ်ပါ။",
    "error_26301": "အမှားကုဒ်- 26301၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26302": "အမှားကုဒ်- 26302၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26303": "အီးမေးလ်ဖော်မတ်အမှား (အမှားကုဒ်- 26303)။ ထပ်ထည့်ပါ။",
    "error_26304": "စာလုံး 8 လုံးအထက် စကားဝှက်ကို အကြံပြုထားသည် (အမှားကုဒ်- 26304)",
    "error_26305": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26305)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26306": "အီးမေးလ်ကို စာရင်းသွင်းထားပြီး၊ ကျေးဇူးပြု၍ <a href=\"https://account.mindonmap.com/login/\">အကောင့်ဝင်ရန် သွားပါ</a>",
    "error_26307": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26307)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26308": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26308)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26401": "အမှားကုဒ်- 26401၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26402": "အီးမေးလ်ကို စစ်ဆေးပြီးပြီ (အမှားကုဒ်- 26402)၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26403": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26403)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26404": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 26404)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26501": "အမှားကုဒ်- 26501၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26502": "အမှားကုဒ်- 26502၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26503": "အီးမေးလ်ဖော်မတ်အမှား (အမှားကုဒ်- 26503)။ ထပ်ထည့်ပါ။",
    "error_26504": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26504)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26505": "အီးမေးလ်ကို စာရင်းမသွင်းရသေးပါ၊ ကျေးဇူးပြု၍ <a href=\"https://account.mindonmap.com/register/\">၎င်းကို ဦးစွာ စာရင်းသွင်းပါ</a>",
    "error_26506": "အီးမေးလ်ကို စစ်ဆေးပြီးပါပြီ။",
    "error_26507": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26507)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26508": "အတည်ပြုခြင်း ပျက်သွားသည် (အမှားကုဒ်- 26508) ကို ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26509": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 26509)၊ ကျေးဇူးပြု၍ ၎င်းကို ထပ်စမ်းကြည့်ပါ။",
    "error_26510": "အမှားကုဒ်- 26510၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26601": "အမှားကုဒ်- 26601၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26602": "အမှားကုဒ်- 26602၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26603": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26603)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26604": "အမှားကုဒ်- 26604၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26605": "အမှားကုဒ်- 26605၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26701": "အမှားကုဒ်- 26701၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26702": "Reuqest မအောင်မြင်ပါ (အမှားကုဒ်- 26702)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26703": "အမှားကုဒ်- 26703၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26704": "အမှားကုဒ်- 26704၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26705": "အကောင့်ဝင်ရန် စောင့်ပါ (အမှားကုဒ်- 26705)။ ထပ်စမ်းကြည့်ပါ။",
    "no_cookie": "သင့်ဘရောက်ဆာရှိ ကွတ်ကီးအားလုံးပိတ်ဆို့ခြင်းလုပ်ဆောင်ချက်ကို သင်ဖွင့်ထားသောကြောင့် သင်ဝင်ရောက်၍မရပါ။ ကျေးဇူးပြု၍ ကွတ်ကီးအားလုံးကိုခွင့်ပြုရန် အကွက်ကိုစစ်ဆေးရန် ဆက်တင်များသို့သွားပါ။",
    "error_26801": "အမှားကုဒ်- 26801၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26802": "အမှားကုဒ်- 26802၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26803": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 26803)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26804": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 26804)။ ထပ်စမ်းကြည့်ပါ။",
    "error_order": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27098) ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_order1": "အမှာစာမေးမြန်းမှု မပြည့်စုံပါ (အမှားကုဒ်- ",
    "error_order2": ") ပြန်လည်ဆန်းသစ်ပြီး ထပ်စမ်းကြည့်ပါ။",
    "modify_email_title": "အီးမေးလ်ကို ပြင်ဆင်ပါ။",
    "modify_email_info": "သင့်အကောင့်သို့ ဝင်ရောက်ရန် ပြင်ဆင်ထားသော အီးမေးလ်ကို အသုံးပြုနိုင်သည်။",
    "images_per": "ပုံများအလိုက်",
    "error_26101": "အမှား- 26101။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26102": "အမှား- 26102။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26103": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်: 26103)။ ကျေးဇူးပြု၍ ပြန်စမ်းကြည့်ပါ။",
    "error_26104": "အမှားကုဒ်- 26104၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26105": "အမှားကုဒ်- 26105၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26106": "ဖျက်၍မရ (အမှားကုဒ်- 26106)။ ထပ်စမ်းကြည့်ပါ။",
    "error_26201": "အမှား- 26201။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26202": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်: 26202)။ ကျေးဇူးပြု၍ ပြန်စမ်းကြည့်ပါ။",
    "error_26001": "အမှား- 26001။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26002": "အမှား- 26002။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26003": "အမှား- 26003။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26004": "အမှား- 26004။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_26005": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်: 26005)။ ကျေးဇူးပြု၍ ပြန်စမ်းကြည့်ပါ။",
    "error_26006": "အမှားကုဒ်- 26006၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_26008": "အမှား- 26008။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "go_to_the_home_page": "ပင်မစာမျက်နှာသို့သွားပါ။",
    "error_27101": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27101)။ ကျေးဇူးပြု၍ ပြန်ကြိုးစားပါ။",
    "error_27201": "အမှားကုဒ်- 27201၊ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_27202": "အမှားကုဒ်- 27202၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
    "error_27203": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27203)။ ထပ်စမ်းကြည့်ပါ။",
    "error_27204": "မမှန်ကန်သောကုဒ် (အမှားကုဒ်- 27204)။",
    "error_27205": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27205)။ ထပ်စမ်းကြည့်ပါ။",
    "error_27206": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27206)။ ထပ်စမ်းကြည့်ပါ။",
    "error_27207": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 27207)။ ထပ်စမ်းကြည့်ပါ။",
    "no_history_found": "သင် မည်သည့်ကိရိယာကိုမျှ အသုံးမပြုခဲ့ပါ။ <a href=\"/\">Refresh</a> သို့မဟုတ် <a href=\"https://www.mindonmap.com/\">တရားဝင်ဝဘ်ဆိုဒ်သို့ သွားပါ</a>",
    "error_25301": "အမှား- 25301။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_25302": "အမှား- 25302။ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
    "error_25303": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 25303)။ ထပ်စမ်းကြည့်ပါ။",
    "error_25304": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 25304)။ ထပ်စမ်းကြည့်ပါ။",
    "error_25305": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 25305)။ ထပ်စမ်းကြည့်ပါ။",
    "error_25306": "တောင်းဆိုမှု မအောင်မြင်ပါ (အမှားကုဒ်- 25306)။ ထပ်စမ်းကြည့်ပါ။",
    "image_upscaler_p": "ရေစာမပါသောပုံကို ဒေါင်းလုဒ်လုပ်ခြင်း အကျိုးကျေးဇူးများ-",
    "Available_for": "ရနိုင်သည်-",
    "credit_per": "HD ရုပ်ပုံတစ်ခုလျှင် %s ခရက်ဒစ်(s)",
    "still_valid": "ဝယ်ယူထားသော အစီအစဉ်(များ)သည် အကျုံးဝင်ဆဲဖြစ်သည်။",
    "credit": "ခရက်ဒစ်(များ)",
    "pc_3rd_info": "အောင်မြင်စွာ အကောင့်ဝင်ပါ။ နောက်ထပ်လုပ်ဆောင်မှုများအတွက် လျှောက်လွှာကိုသွားပါ။",
    "use_online": "အွန်လိုင်းဝန်ဆောင်မှုကိုသုံးပါ။",
    "use_download": "desktop ပရိုဂရမ်ကို အသုံးပြု",
    "use_immediately": "ချက်ချင်းသုံးပါ။",
    "Use_in_browser": "Browser တွင်အသုံးပြုပါ။",
    "win_desktop": "ပြတင်းပေါက်",
    "Mac_desktop": "မက်",
    "credits_per": "တစ်လလျှင် ခရက်ဒစ် {%} ခု",
    "expire": "သက်တမ်းကုန်ဆုံးချိန်-",
    "viewDetails": "အသေးစိတ်ကြည့်ရှုပါ။",
    "viewHistory": "သမိုင်းအကျိုးခံစားခွင့်များကို ကြည့်ရှုပါ>>",
    "viewDetailsInfo": "သတိပေးချက်- စာရင်းသွင်းခြင်းအကျိုးခံစားခွင့်များကို သက်တမ်းကုန်ဆုံးပြီးနောက် 7 ရက်အတွင်း သက်တမ်းတိုးပါက၊ အသုံးမပြုသောအကျိုးခံစားခွင့်များကို ဆက်လက်အသုံးပြုနိုင်ပါသည်။ ထို့အပြင်၊ စာရင်းသွင်းမှုအသစ်၏ သက်တမ်းကုန်ဆုံးချိန်သို့ သက်တမ်းကုန်ဆုံးချိန်ကို အလိုအလျောက် အပ်ဒိတ်လုပ်ပါမည်။ သက်တမ်းကုန်ဆုံးချိန် 7 ရက်အကြာတွင် စာရင်းသွင်းမှုအသစ်မရှိပါက၊ သက်တမ်းကုန်ဆုံးသွားသော အကျိုးခံစားခွင့်များအားလုံးကို ရှင်းလင်းပါမည်။",
    "connect_account": "အီးမေးလ်ကို သင့်အကောင့်တွင် ချိတ်ပါ။",
    "connect_account_info": "ချိတ်ဆက်ပြီးနောက်၊ သင်သည် ဤအီးမေးလ်လိပ်စာဖြင့် လော့ဂ်အင်ဝင်နိုင်သည်။",
    "connect_now": "ယခုစည်းပါ။",
    "no_email_bind": "အီးမေးလ် ချိတ်ထားခြင်းမရှိပါ။",
    "bind_email": "အီးမေးလ်ကို စည်းပါ။",
    "connect_your_email_placeholder": "သင့်အီးမေးလ်လိပ်စာကို ထည့်သွင်းပါ။",
    "bind_an_email": "အီးမေးလ်ကို ချိတ်ပါ။",
    "bind_info": "သင်အောင်မြင်စွာဝင်ရောက်ပြီးဖြစ်သည်။ သင့်အကျိုးခံစားခွင့်များကို အသက်သွင်းရန်အတွက် သင့်အကောင့်သို့ အီးမေးလ်တစ်စောင် ချိတ်ပါ။",
    "bind_later": "နောက်မှ စည်းပါ။",
    "hi": "မင်္ဂလာပါ %s!",
    "Personal_Information": "ကိုယ်ပိုင်သတင်းအချက်အလက်များ",
    "Access": "သုံးစွဲခွင့်",
    "Subscription_Plan": "(စာရင်းသွင်းမှု အစီအစဉ်)",
    "No_orders": "အမှာစာမတွေ့ပါ။",
    "No_data": "ဒေတာမရှိပါ။",
    "Featured_Products": "အထူးအသားပေး ထုတ်ကုန်များ",
    "More_Products": "နောက်ထပ်ထုတ်ကုန်များ",
    "Free_Download": "အခမဲ့ဒေါင်းလုဒ်လုပ်ပါ။",
    "Get_Started": "စတင်လိုက်ပါ။",
    "Subscribe": "စာရင်းသွင်းပါ။",
    "Verified": "စိစစ်ပြီး",
    "back_to_account_center": "အကောင့်စင်တာသို့ ပြန်သွားရန်",
    "success": "အောင်မြင်",
    "successfully": "အကောင့်တစ်ခု အောင်မြင်စွာ စာရင်းသွင်းပြီးပါပြီ။",
    "Continue": "ဆက်လက်",
    "Already": "အကောင့်ရှိပြီးသားလား?",
    "loading_verification": "စိစစ်မှုအခြေအနေ... စစ်ဆေးခြင်း။",
    "email_no_verification": "ဝမ်းနည်းပါသည်၊ စာရင်းသွင်းထားသော အီးမေးလ်လိပ်စာကို အတည်မပြုရသေးပါ။ အထက်ဖော်ပြပါ ညွှန်ကြားချက်များအတိုင်း အတည်ပြုခြင်းအား ဖြည့်သွင်းပြီး မှတ်ပုံတင်ခြင်းကို အပြီးသတ်ရန် \"ပြီးပါပြီ\" ခလုတ်ကို ထပ်မံနှိပ်ပါ။",
    "will_expire_after": "ပြီးရင် သက်တမ်းကုန်မယ်။",
    "hours": "နာရီ",
  },
  "ne": {
    "overtime": "त्रुटि कोड: {%}, कृपया पुन: प्रयास गर्नुहोस्",
    "isnetwork": "इन्टरनेट त्रुटि। कृपया जाँच गर्नुहोस् र पुन: प्रयास गर्नुहोस्",
    "email_placeholder": "इमेल",
    "email_empty": "कृपया इमेल इनपुट गर्नुहोस्",
    "email_not_valid": "इमेल मान्य छैन",
    "email_not_valid_1": "कृपया आफ्नो इमेल इनपुट गर्नुहोस्",
    "email_not_valid_2": "इमेल मान्य छैन, कृपया फरक ठेगाना प्रयोग गर्नुहोस्।",
    "email_not_valid_3": "कुनै इमेल इनपुट छैन",
    "password_placeholder": "पासवर्ड",
    "password_empty": "कृपया पासवर्ड प्रविष्ट गर्नुहोस्",
    "password_not_valid": "गलत खाता वा पासवर्ड",
    "password_not_valid_1": "8 वर्ण भन्दा माथिको पासवर्ड आवश्यक छ",
    "password_not_valid_2": "कृपया पासवर्ड सिर्जना गर्नुहोस्",
    "password_placeholder_1": "आफ्नो पासवर्ड सिर्जना गर्नुहोस्",
    "password_placeholder_2": "आफ्नो पासवर्ड निश्चित गर्नुहोस्",
    "password_placeholder_3": "नयाँ पासवर्ड सिर्जना गर्नुहोस्",
    "password_placeholder_4": "नयाँ पासवर्ड पुष्टि गर्नुहोस्",
    "password_placeholder_5": "पुरानो पासवर्ड प्रविष्ट गर्नुहोस्",
    "copy_password_empty": "कृपया पासवर्ड पुष्टि गर्नुहोस्",
    "copy_password_not_valid": "कृपया आफ्नो पासवर्ड पुष्टि गर्नुहोस्",
    "copy_passwords_inconsistent": "तपाईंको पासवर्ड पुष्टिकरण मेल खाँदैन",
    "code_empty": "कृपया प्रमाणीकरण कोड प्रविष्ट गर्नुहोस्",
    "code_not_valid": "अवैध प्रमाणिकरण कोड",
    "code_placeholder": "प्रमाणीकरण कोड",
    "not_received_code": "यदि तपाईंको मेलबक्सले लामो समयसम्म प्रमाणीकरण कोड प्राप्त गरेको छैन भने, कृपया प्रमाणीकरण कोड फेरि प्राप्त गर्नुहोस्।",
    "get_first_code": "कृपया पहिले प्रमाणिकरण कोड प्राप्त गर्नुहोस्।",
    "last_name_placeholder": "कृपया आफ्नो थर प्रविष्ट गर्नुहोस्",
    "first_name_placeholder": "कृपया आफ्नो पहिलो नाम प्रविष्ट गर्नुहोस्",
    "address_placeholder": "कृपया आफ्नो ठेगाना प्रविष्ट गर्नुहोस्",
    "no_code_text": "हामीले प्रमाणीकरण कोड पठाएका छौं। कृपया आफ्नो कोड प्रविष्ट गर्नुहोस्। <span class='tips'>कोड प्राप्त गर्नुभएन?",
    "no_code_text_1": "1. कृपया सुनिश्चित गर्नुहोस् कि इमेल ठेगाना मान्य छ र यसले इमेलहरू प्राप्त गर्न सक्छ।",
    "no_code_text_2": "2. प्रणाली द्वारा स्वचालित रूपमा पठाइएको इमेलको लागि, यसलाई स्प्याम वा जंक इमेलको रूपमा फ्ल्याग गर्न सकिन्छ। ईमेल रद्दीटोकरी फोल्डरमा छ कि छैन जाँच गर्नुहोस्।",
    "no_code_text_3": "3. आफ्नो समस्या समाधान गर्न सक्नुहुन्न? ",
    "no_code_text_3_span": "त्यसपछि हामीलाई सम्पर्क गर्न यहाँ क्लिक गर्नुहोस्।",
    "order_no": "तपाईंले कुनै पनि उत्पादन खरिद गर्नुभएको छैन, यदि तपाईंसँग कुनै प्रश्न छ भने, कृपया <a href=\"https://www.mindonmap.com/contact-us/\">हामीलाई सम्पर्क गर्नुहोस्</a>।",
    "error_24901": "हालको खातामा कुनै इमेल लिङ्क गरिएको छैन, र अर्डरहरू फेला पार्न सक्दैन। कृपया एउटा इमेल जडान गर्नुहोस्।",
    "user_guide": "प्रयोगकर्ता गाइड >>",
    "download": "डाउनलोड गर्नुहोस्",
    "order_number": "अर्डर नम्बर:",
    "Refund": "फिर्ता",
    "Disabled": "अक्षम",
    "Normal": "सामान्य",
    "Modify": "परिमार्जन गर्नुहोस्",
    "Modify_1": "परिमार्जन गर्नुहोस् >>",
    "Connect": "जडान गर्नुहोस्",
    "unlink_success": "सफलतापूर्वक अनलिंक गर्नुहोस्",
    "connect_success": "सफलतापूर्वक जडान गर्नुहोस्",
    "feedback_title": "तपाईंको प्रतिक्रियाको लागि धन्यवाद। कृपया आफ्नो समस्या छोड्नुहोस् र हामी तपाईंलाई 24 घण्टा भित्र जवाफ दिनेछौं।",
    "feedback_thank_you": "धन्यवाद!<br />तपाईंको प्रतिक्रिया सफलतापूर्वक पेश गरिएको छ।",
    "feedback_email": "यहाँ आफ्नो इमेल प्रविष्ट गर्नुहोस्!",
    "feedback_content": "तपाईंले सामना गर्नुभएको कुनै पनि समस्या वा सुझाव यहाँ छोड्नुहोस्।",
    "feedback_submit": "पेश गर्नुहोस्",
    "form_contents": "तपाईंले कुनै विवरण प्रविष्ट गर्नुभएको छैन। कृपया यसलाई प्रविष्ट गर्नुहोस् र पुन: पेश गर्नुहोस्।",
    "old_password": "कृपया पुरानो पासवर्ड प्रविष्ट गर्नुहोस्",
    "new_password": "कृपया नयाँ पासवर्ड सिर्जना गर्नुहोस्",
    "old_new_password": "नयाँ पासवर्ड पुरानो जस्तै हुन सक्दैन",
    "incorrect_password": "गलत पासवर्ड",
    "delete_no": "अहिले मेटाउनुहोस्",
    "Caps": "क्याप्स लक सक्रिय छ",
    "Get": "प्राप्त गर्नुहोस्",
    "Done": "सकियो",
    "error_20001": "त्रुटि: 20001। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20002": "त्रुटि: 20002। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20003": "त्रुटि: 20003। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20004": "अनुरोध असफल भयो (त्रुटि कोड: 20004)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_20005": "लगइन सत्र समाप्त भएको छ (त्रुटि: 20005)। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20006": "अनुरोध असफल भयो (त्रुटि कोड: 20006)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_20007": "लगइन सत्र समाप्त भएको छ (त्रुटि: 20007)। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20008": "लगइन सत्र समाप्त भएको छ (त्रुटि: 20008)। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20009": "लगइन सत्र समाप्त भएको छ (त्रुटि: 20009)। कृपया फेरि लग इन गर्नुहोस्।",
    "error_20101": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20101)",
    "error_20102": "इमेल मान्य छैन (त्रुटि कोड: 20102)",
    "error_20103": "अनुरोध असफल भयो (त्रुटि कोड: 20103)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20104": "इमेल पहिले नै प्रयोगमा छ, कृपया <a href=\"https://account.mindonmap.com/login/\">लग इन गर्नुहोस्</a> वा नयाँसँग दर्ता गर्नुहोस्",
    "error_20105": "अनुरोध असफल भयो (त्रुटि कोड: 20105)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20106": "इमेल पठाउन असफल, कृपया पुन: प्रयास गर्नुहोस्",
    "error_20201": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20201)",
    "error_20202": "कृपया आफ्नो पासवर्ड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 20202)",
    "error_20203": "कृपया प्रमाणीकरण कोड इनपुट गर्नुहोस् (त्रुटि कोड: २०२०३)",
    "error_20204": "इमेल मान्य छैन (त्रुटि कोड: २०२०४)",
    "error_20205": "8 वर्ण भन्दा माथिको पासवर्ड आवश्यक छ (त्रुटि कोड: 20205)",
    "error_20206": "अनुरोध असफल भयो (त्रुटि कोड: 20206)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20207": "अवैध प्रमाणिकरण कोड",
    "error_20208": "अनुरोध असफल भयो (त्रुटि कोड: 20208)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20209": "अनुरोध असफल भयो (त्रुटि कोड: 20209)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20301": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20301)",
    "error_20302": "त्रुटि: 20302। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_20303": "इमेल मान्य छैन (त्रुटि कोड: 20303)",
    "error_20304": "अनुरोध असफल भयो (त्रुटि कोड: 20304)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20305": "खाता अवस्थित छैन। कृपया पुन: प्रविष्ट गर्नुहोस् वा पहिले <a href=\"https://account.mindonmap.com/register/\">यो सिर्जना गर्नुहोस्</a>।",
    "error_20306": "अझै पासवर्ड छैन। <a href=\"https://account.mindonmap.com/passwordless-login/\">पासवर्डलेस लगइन</a> वा <a href=\"https://account.mindonmap.com/create-password/\"> प्रयोग गर्नुहोस् पासवर्ड सेट गर्नुहोस्</a> र लग इन गर्नुहोस्।",
    "error_20308": "अनुरोध असफल भयो (त्रुटि कोड: 20308)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20401": "लग आउट गर्न असफल (त्रुटि कोड: 20401)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20501": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20501)",
    "error_20502": "इमेल मान्य छैन (त्रुटि कोड: 20502)",
    "error_20503": "अनुरोध असफल भयो (त्रुटि कोड: 20503)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20504": "इमेल पठाउन असफल भयो। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_20601": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20601)",
    "error_20602": "कृपया प्रमाणीकरण कोड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 20602)",
    "error_20603": "इमेल मान्य छैन (त्रुटि कोड: 20603)",
    "error_20604": "अनुरोध असफल भयो (त्रुटि कोड: 20604)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20606": "अनुरोध असफल भयो (त्रुटि कोड: 20606)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20607": "अनुरोध असफल भयो (त्रुटि कोड: 20607)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20608": "अनुरोध असफल भयो (त्रुटि कोड: 20608)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20701": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20701)",
    "error_20702": "इमेल मान्य छैन (त्रुटि कोड: 20702)",
    "error_20703": "अनुरोध असफल भयो (त्रुटि कोड: 20703)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20704": "खाता अवस्थित छैन। कृपया पुन: प्रविष्ट गर्नुहोस् वा पहिले <a href=\"https://account.mindonmap.com/register/\">यो सिर्जना गर्नुहोस्</a>।",
    "error_20705": "अनुरोध असफल भयो (त्रुटि कोड: 20705)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20706": "इमेल पठाउन असफल भयो। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20801": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 20801)",
    "error_20802": "त्रुटि: 20802। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_20803": "कृपया प्रमाणीकरण कोड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 20803)",
    "error_20804": "इमेल मान्य छैन (त्रुटि कोड: 20804)",
    "error_20805": "8 वर्ण भन्दा माथिको पासवर्ड आवश्यक छ (त्रुटि कोड: 20805)",
    "error_20806": "अनुरोध असफल भयो (त्रुटि कोड: 20806)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20808": "अनुरोध असफल भयो (त्रुटि कोड: 20808)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20901": "अनुरोध असफल भयो (त्रुटि कोड: 20901)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20902": "अनुरोध असफल भयो (त्रुटि कोड: 20902)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21000": "परिवर्तनहरू सुरक्षित छन्",
    "error_21001": "कुनै जानकारी पेश गरिएको छैन (त्रुटि कोड: 21001)",
    "error_21002": "अनुरोध असफल भयो (त्रुटि कोड: 21002)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21101": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 21101)",
    "error_21102": "इमेल मान्य छैन (त्रुटि कोड: 21102)",
    "error_21103": "अनुरोध असफल भयो (त्रुटि कोड: 21103), कृपया पुन: प्रयास गर्नुहोस्",
    "error_21104": "इमेल पहिले नै जोडिएको छ, कृपया एउटा नयाँ प्रयोग गर्नुहोस्",
    "error_21105": "अनुरोध असफल भयो (त्रुटि कोड: 21105), कृपया पुन: प्रयास गर्नुहोस्",
    "error_21106": "इमेल पठाउन असफल भयो। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21201": "कृपया आफ्नो इमेल इनपुट गर्नुहोस् (त्रुटि कोड: 21201)",
    "error_21202": "कृपया प्रमाणीकरण कोड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 21202)",
    "error_21203": "इमेल मान्य छैन (त्रुटि कोड: 21203)",
    "error_21204": "त्रुटि: 21204। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21205": "त्रुटि: 21205। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21206": "8 वर्ण भन्दा माथिको पासवर्ड आवश्यक छ (त्रुटि कोड: 21206)",
    "error_21207": "अनुरोध असफल भयो (त्रुटि कोड: 21207)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21209": "अनुरोध असफल भयो (त्रुटि कोड: 21209)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21301": "कृपया पुरानो पासवर्ड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 21301)",
    "error_21302": "कृपया नयाँ पासवर्ड सिर्जना गर्नुहोस् (त्रुटि कोड: 21302)",
    "error_21303": "नयाँ पासवर्ड पुरानो जस्तै हुन सक्दैन। (त्रुटि: 21303)",
    "error_21304": "8 वर्ण भन्दा माथिको पासवर्ड आवश्यक छ (त्रुटि कोड: 21304)",
    "error_21306": "अनुरोध असफल भयो (त्रुटि कोड: 21306)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21402": "अनुरोध असफल भयो (त्रुटि कोड: 21402)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21403": "प्रमाणीकरण कोड पठाउन असफल भयो। कृपया यसलाई पुन: पठाउनुहोस्",
    "error_21500": "खाता मेटाइएको छ",
    "error_21501": "कृपया प्रमाणीकरण कोड प्रविष्ट गर्नुहोस् (त्रुटि कोड: 21501)",
    "error_21502": "लगइन सत्र समाप्त भएको छ (त्रुटि: 21502)। कृपया फेरि लग इन गर्नुहोस्।",
    "error_21503": "अनुरोध असफल भयो (त्रुटि कोड: 21503)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21505": "अनुरोध असफल भयो (त्रुटि कोड: 21505), कृपया पुन: प्रयास गर्नुहोस्",
    "error_21601": "त्रुटि: 20601। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21602": "अवैध प्रमाणीकरण (त्रुटि: २०६०२)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_21603": "त्रुटि: 20603। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21604": "अनुरोध असफल भयो (त्रुटि कोड: 21604)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21606": "अनुरोध असफल भयो (त्रुटि कोड: 21606)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21611": "अनुरोध असफल भयो (त्रुटि कोड: 21611)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21801": "त्रुटि: 21801। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21802": "अनुरोध असफल भयो (त्रुटि: 21802)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21803": "त्रुटि: 21803। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21804": "अनुरोध असफल भयो (त्रुटि कोड: 21804)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21806": "त्रुटि: 21806। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21807": "त्रुटि: 21807। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21808": "त्रुटि: 21808। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21809": "त्रुटि: 21809। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21810": "त्रुटि: 21810। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21811": "त्रुटि: 21811। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21812": "त्रुटि: 21812। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21813": "अनुरोध असफल भयो (त्रुटि कोड: 21813)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21814": "त्रुटि: 21814। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21815": "अनुरोध असफल भयो (त्रुटि कोड: 21815)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21816": "त्रुटि: 21816। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21817": "त्रुटि: 21817। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21818": "त्रुटि: 21818। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21819": "अनुरोध असफल भयो (त्रुटि कोड: 21819)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21820": "त्रुटि: 21820। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21821": "त्रुटि: 21821। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21822": "त्रुटि: 21822। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21823": "अनुरोध असफल भयो (त्रुटि कोड: 21823)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21824": "अनुरोध असफल भयो (त्रुटि कोड: 21824)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21825": "अनुरोध असफल भयो (त्रुटि कोड: 21825)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21826": "अनुरोध असफल भयो (त्रुटि कोड: 21826)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21901": "त्रुटि: 21901। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_21902": "अनुरोध असफल भयो (त्रुटि कोड: 21902)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21903": "खाता स्थिति परिवर्तन भएको छ (त्रुटि कोड: 21903), कृपया पृष्ठ रिफ्रेस गर्नुहोस् र पुन: प्रयास गर्नुहोस्",
    "error_21904": "त्रुटि: 21904। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21905": "त्रुटि: 21905। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21906": "अनुरोध असफल भयो (त्रुटि कोड: 21906)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_21907": "गुगल खाता अन्य खातामा लिङ्क गरिएको छ",
    "error_21908": "अनुरोध असफल भयो (त्रुटि कोड: 21908)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22001": "अनुरोध असफल भयो (त्रुटि कोड: 22001)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22002": "कुनै अतिरिक्त लगइन बिना अनलिंक असफल भयो",
    "error_22003": "अनुरोध असफल भयो (त्रुटि कोड: 22003)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22101": "त्रुटि: 22101। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22102": "खाता स्थिति परिवर्तन भएको छ (त्रुटि कोड: 22102), कृपया पृष्ठ रिफ्रेस गर्नुहोस् र पुन: प्रयास गर्नुहोस्",
    "error_22103": "अनुरोध असफल भयो (त्रुटि कोड: 22103)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22104": "खाता स्थिति परिवर्तन भएको छ (त्रुटि कोड: 22104), कृपया पृष्ठ रिफ्रेस गर्नुहोस् र पुन: प्रयास गर्नुहोस्",
    "error_22105": "त्रुटि: 22105। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22106": "त्रुटि: 22106। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22107": "त्रुटि: 22107। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22108": "अनुरोध असफल भयो (त्रुटि कोड: 22108)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22201": "त्रुटि: 22201। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22202": "अवैध प्रमाणीकरण (त्रुटि: 22202)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_22203": "त्रुटि: 22203। कृपया फेरि प्रयास गर्नुहोस्\"",
    "error_22204": "अनुरोध असफल भयो (त्रुटि कोड: 22204)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22206": "अनुरोध असफल भयो (त्रुटि कोड: 22206)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22401": "त्रुटि: 22401। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22402": "अवैध प्रमाणीकरण (त्रुटि: 22402)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_22403": "त्रुटि: 22403। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22404": "अनुरोध असफल भयो (त्रुटि कोड: 22404)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22405": "फेसबुक खाता अन्य इमेलमा लिङ्क गरिएको छ",
    "error_22406": "त्रुटि: 22406। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22407": "त्रुटि: 22407। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22408": "त्रुटि: 22408। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22409": "त्रुटि: 22409। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22410": "त्रुटि: 224010। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22411": "त्रुटि: 224011। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22412": "त्रुटि: 224012। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22413": "त्रुटि: 22413। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22414": "अनुरोध असफल भयो (त्रुटि कोड: 22414)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22415": "त्रुटि: 22415। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22416": "त्रुटि: 22416। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22417": "त्रुटि: 22417। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22418": "अनुरोध असफल भयो (त्रुटि कोड: 22418)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22419": "त्रुटि: 22419। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22420": "त्रुटि: 22420। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22421": "त्रुटि: 22421। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_22422": "अनुरोध असफल भयो (त्रुटि कोड: 22422)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22423": "अनुरोध असफल भयो (त्रुटि कोड: 22423)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22424": "अनुरोध असफल भयो (त्रुटि कोड: 22424)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_22425": "अनुरोध असफल भयो (त्रुटि कोड: 22425)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_20098": "त्रुटि कोड: 20098। यदि तपाइँ निजी मोडमा ब्राउज गर्दै हुनुहुन्छ भने, कृपया सामान्य मोडमा स्विच गर्नुहोस् र पुन: प्रयास गर्नुहोस्।",
    "error_22298": "Google को लागि अनुरोध असफल भयो (त्रुटि: 22298)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_22498": "Facebook को लागि अनुरोध असफल भयो (त्रुटि: 22498)। कृपया फेरि प्रयास गर्नुहोस्।",
    "error_24902": "अनुरोध असफल भयो (त्रुटि कोड: 24902)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_24903": "अनुरोध असफल भयो (त्रुटि कोड: 24903)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_24904": "अनुरोध असफल भयो (त्रुटि कोड: 24904)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_24905": "अनुरोध असफल भयो (त्रुटि कोड: 24905)। कृपया फेरि प्रयास गर्नुहोस्",
    "login_title": "MindOnMap मा लग इन गर्नुहोस्",
    "log_in": "लग - इन",
    "no_account": "खाता छैन?",
    "create_it": "यसलाई सिर्जना गर्नुहोस्",
    "or_log_in_with": "वा लग इन गर्नुहोस्",
    "passwordless_login": "पासवर्डरहित लगइन",
    "log_in_done": "लग इन गरियो",
    "three_rd_account_connect_info": "बधाई छ! तपाईंले सफलतापूर्वक लग इन गर्नुभयो। अब तपाईले एउटा इमेल खाता जडान गर्न सक्नुहुन्छ जुन भविष्यमा लग इन गर्न प्रयोग गरिन्छ।",
    "see_my_account": "मेरो खाता हेर्नुहोस्",
    "three_rd_login_merge_account": "तेस्रो-पक्ष खाता इमेल ठेगाना साइन अप गरिएको छ, के तपाइँ यो इमेल ठेगाना संग सीधा जडान र लग इन गर्न चाहनुहुन्छ?",
    "connect_log_in": "जडान गर्नुहोस् र लग इन गर्नुहोस्",
    "create_an_account": "खाता खोल्नुहोस्",
    "back_to_log_in": "लग इन गर्न फर्कनुहोस्",
    "create_password": "पासवर्ड बनाउ",
    "create_now": "अहिले सिर्जना गर्नुहोस्",
    "password_login_subtitle": "इमेलको साथ पासवर्डरहित लगइन",
    "account_login": "खाता लगइन",
    "rights": "यो खाता सिर्जना गरेर, तपाईं <a href=\"https://www.mindonmap.com/terms-and-conditions/\">सेवाका सर्तहरू</a> र <a href=\"https://www.mindonmap.com/privacy-policy/\">गोपनीयता नीति</a>",
    "passwordless_login_done": "पासवर्डरहित लगइन सकियो",
    "passwordless_login_info": "बधाई छ, तपाईंले पासवर्डरहित लगइन सफलतापूर्वक पूरा गर्नुभएको छ। तपाईं यस खाताको लागि पासवर्ड सिर्जना गर्न सक्नुहुन्छ र भविष्यमा खाता र पासवर्डको साथ लग इन गर्न सक्नुहुन्छ। <a href=\"/create-password\" style=\"display: initial;\">अहिले नै सिर्जना गर्नुहोस्</a>",
    "sign_up": "साइन अप",
    "register_info": "तपाईँका खाता बनाउनुहोस्",
    "reset_now": "अब रिसेट गर्नुहोस्",
    "forgot_password": "पासवर्ड भुल्नु भयो",
    "reset_password_subtitle": "पासवर्ड रिसेट गर्न आफ्नो खाता इमेल प्रयोग गर्नुहोस्",
    "plan_products": "योजना र उत्पादनहरू",
    "nick_name": "प्रयोगकर्ता नाम:",
    "email": "इमेल:",
    "my_products": "मेरा उत्पादनहरू",
    "my_orders": "मेरो आदेश",
    "unlink": "अनलिंक गर्नुहोस्",
    "link": "लिङ्क",
    "connected_accounts": "जडान गरिएका खाताहरू",
    "last_name": "थर:",
    "first_name": "पहिलो नाम:",
    "Gender": "लिङ्ग:",
    "Birth": "जन्म:",
    "Month": "महिना",
    "Year": "वर्ष",
    "Country_Region": "देश/क्षेत्र:",
    "Address": "ठेगाना:",
    "Save": "बचत गर्नुहोस्",
    "Date": "मिति",
    "Male": "पुरुष",
    "Female": "महिला",
    "Unspecified": "निर्दिष्ट नगरिएको",
    "Security": "सुरक्षा",
    "change_password": "पासवर्ड परिवर्तन गर्नुहोस्",
    "change_now": "अब परिवर्तन गर्नुहोस्",
    "connect_email": "इमेल जडान गर्नुहोस्",
    "delete_account": "खाता मेटाउनुहोस्",
    "delete_account_info": "जब तपाइँको खाता मेटाइन्छ, तपाइँको खाता संग सम्बन्धित मेरो खाता मा सबै डाटा स्थायी रूपमा मेटिनेछ, र तपाइँ यसलाई पुन: प्राप्त गर्न सक्षम नहुन सक्छ। हामी तपाईंलाई सावधानीपूर्वक हिड्न सिफारिस गर्दछौं।",
    "Delete": "मेटाउन",
    "Logout": "बाहिर निस्कनु",
    "my_profile": "मेरो प्रोफाइल",
    "guides_faqs": "गाइड र FAQs",
    "More": "थप",
    "guides": "गाइडहरू",
    "register": "दर्ता गर्नुहोस्",
    "hot_faq": "तातो FAQ",
    "Contents": "सामग्री:",
    "contact_us": "हामीलाई सम्पर्क गर्नुहोस् >>",
    "plan": "योजना",
    "unregistered": "दर्ता नगरिएको",
    "buy_more": "थप खरिद गर्नुहोस्",
    "buy_again": "फेरि किन्नुहोस्",
    "buy_now": "अहिले खरिद गर",
    "free_no_limit": "नि: शुल्क र कुनै सीमा छैन",
    "expired": "म्याद सकियो",
    "lifetime": "जीवन भरि",
    "remain": "रहनुहोस्",
    "day_s": "दिन(हरू)",
    "error_24801": "अनुरोध असफल भयो (त्रुटि कोड: 24801)। कृपया फेरि प्रयास गर्नुहोस्",
    "no_app_found": "कुनै एप फेला परेन!<a href=\"/\">रिफ्रेस</a> वा <a href=\"https://www.mindonmap.com/\">आधिकारिक वेबसाइटमा जानुहोस्</a>",
    "get_more": "थप प्राप्त गर्नुहोस् >>",
    "edit_photo": "फोटो सम्पादन गर्नुहोस्",
    "select_photo": "फोटो चयन गर्नुहोस्",
    "change_photo": "तस्विर परिवर्तन",
    "cancel": "रद्द गर्नुहोस्",
    "hide_password": "पासवर्ड लुकाउनुहोस्",
    "show_password": "पासवर्ड देखाउनुहोस्",
    "zoom_in": "जुम इन गर्नुहोस्",
    "zoom_out": "जुम आउट गर्नुहोस्",
    "rotate": "घुमाउनुहोस्",
    "horizontal_flip": "तेर्सो फ्लिप",
    "vertical_flip": "ठाडो फ्लिप",
    "country": "देश",
    "country_1": "आफ्नो देश/क्षेत्र चयन गर्नुहोस्",
    "country_2": "आल्याण्ड टापु",
    "country_3": "अफगानिस्तान",
    "country_4": "अल्बानिया",
    "country_5": "अल्जेरिया",
    "country_6": "अमेरिकी सामोआ",
    "country_7": "अन्डोरा",
    "country_8": "अंगोला",
    "country_9": "एंगुइला",
    "country_10": "अन्टार्कटिका",
    "country_11": "एन्टिगुआ र बार्बुडा",
    "country_12": "अर्जेन्टिना",
    "country_13": "आर्मेनिया",
    "country_14": "अरुबा",
    "country_15": "अस्ट्रेलिया",
    "country_16": "अस्ट्रिया",
    "country_17": "अजरबैजान",
    "country_18": "बहराइन",
    "country_19": "बंगलादेश",
    "country_20": "बार्बाडोस",
    "country_21": "बेलारुस",
    "country_22": "बेल्जियम",
    "country_23": "बेलिज",
    "country_24": "बेनिन",
    "country_25": "बर्मुडा",
    "country_26": "भुटान",
    "country_27": "बोलिभिया",
    "country_28": "बोस्निया र हर्जेगोभिना",
    "country_29": "बोत्सवाना",
    "country_30": "बुभेट टापु",
    "country_31": "ब्राजिल",
    "country_32": "ब्रिटिश हिन्द महासागर क्षेत्र",
    "country_33": "ब्रिटिश भर्जिन टापुहरू",
    "country_34": "ब्रुनाई",
    "country_35": "बुल्गेरिया",
    "country_36": "बुर्किना",
    "country_37": "बुरुन्डी",
    "country_38": "कम्बोडिया",
    "country_39": "क्यामरुन",
    "country_40": "क्यानडा",
    "country_41": "केप भर्डे",
    "country_42": "क्यारिबियन नेदरल्याण्ड्स",
    "country_43": "केम्यान टापुहरू",
    "country_44": "मध्य अफ्रिकी गणतन्त्र",
    "country_45": "चाड",
    "country_46": "चिली",
    "country_47": "चीन",
    "country_48": "क्रिसमस टापु",
    "country_49": "कोकोस (किलिंग) टापुहरू",
    "country_50": "कोलम्बिया",
    "country_51": "कुक टापुहरू",
    "country_52": "कोस्टारिका",
    "country_53": "कोट डी आइवर",
    "country_54": "क्रोएसिया",
    "country_55": "क्युबा",
    "country_56": "साइप्रस",
    "country_57": "चेक गणतन्त्र",
    "country_58": "प्रजातान्त्रिक गणतन्त्र कङ्गो",
    "country_59": "डेनमार्क",
    "country_60": "जिबूती",
    "country_61": "डोमिनिका",
    "country_62": "डोमिनिकन गणतन्त्र",
    "country_63": "इक्वेडर",
    "country_64": "इजिप्ट",
    "country_65": "एल साल्भाडोर",
    "country_66": "इक्वेटोरियल गिनी",
    "country_67": "इरिट्रिया",
    "country_68": "इस्टोनिया",
    "country_69": "इथियोपिया",
    "country_70": "फकल्याण्ड टापुहरू",
    "country_71": "फारो टापुहरू",
    "country_72": "संघीय राज्यहरू माइक्रोनेसिया",
    "country_73": "फिजी",
    "country_74": "फिनल्याण्ड",
    "country_75": "फ्रान्स",
    "country_76": "फ्रेन्च गुयाना",
    "country_77": "फ्रान्सेली पोलिनेसिया",
    "country_78": "फ्रान्सेली दक्षिणी क्षेत्रहरू",
    "country_79": "ग्याबोन",
    "country_80": "गाम्बिया",
    "country_81": "जर्जिया",
    "country_82": "जर्मनी",
    "country_83": "घाना",
    "country_84": "जिब्राल्टर",
    "country_85": "ग्रेट ब्रिटेन (युनाइटेड किंगडम; इङ्गल्याण्ड)",
    "country_86": "ग्रीस",
    "country_87": "ग्रीनल्याण्ड",
    "country_88": "ग्रेनाडा",
    "country_89": "ग्वाडेलुप",
    "country_90": "गुआम",
    "country_91": "ग्वाटेमाला",
    "country_92": "गुर्नसे",
    "country_93": "गिनी",
    "country_94": "गिनी-बिसाउ",
    "country_95": "गुयाना",
    "country_96": "हाइटी",
    "country_97": "हर्ड आइल्याण्ड र म्याकडोनाल्ड टापुहरू",
    "country_98": "होन्डुरस",
    "country_99": "हंगकंग",
    "country_100": "हंगेरी",
    "country_101": "आइसल्याण्ड",
    "country_102": "भारत",
    "country_103": "इन्डोनेसिया",
    "country_104": "इरान",
    "country_105": "इराक",
    "country_106": "आयरल्याण्ड",
    "country_107": "आइल अफ म्यान",
    "country_108": "इजरायल",
    "country_109": "इटाली",
    "country_110": "जमैका",
    "country_111": "जापान",
    "country_112": "जर्सी",
    "country_113": "जोर्डन",
    "country_114": "कजाकिस्तान",
    "country_115": "केन्या",
    "country_116": "किरिबाटी",
    "country_117": "कुवेत",
    "country_118": "किर्गिजस्तान",
    "country_119": "लाओस",
    "country_120": "लाटभिया",
    "country_121": "लेबनान",
    "country_122": "लेसोथो",
    "country_123": "लाइबेरिया",
    "country_124": "लिबिया",
    "country_125": "लिकटेन्स्टाइन",
    "country_126": "लिथुआनिया",
    "country_127": "लक्जमबर्ग",
    "country_128": "मकाउ",
    "country_129": "मेडागास्कर",
    "country_130": "मलावी",
    "country_131": "मलेशिया",
    "country_132": "माल्दिभ्स",
    "country_133": "माली",
    "country_134": "माल्टा",
    "country_135": "मार्शल टापुहरू",
    "country_136": "मार्टिनिक",
    "country_137": "मौरिटानिया",
    "country_138": "मौरिसस",
    "country_139": "मेयोट",
    "country_140": "मेक्सिको",
    "country_141": "मोल्डोभा",
    "country_142": "मोनाको",
    "country_143": "मंगोलिया",
    "country_144": "मोन्टेनेग्रो",
    "country_145": "मोन्टसेराट",
    "country_146": "मोरक्को",
    "country_147": "मोजाम्बिक",
    "country_148": "म्यानमार (बर्मा)",
    "country_149": "नामिबिया",
    "country_150": "नाउरु",
    "country_151": "नेपाल",
    "country_152": "नेदरल्याण्ड्स",
    "country_153": "न्यू क्यालेडोनिया",
    "country_154": "न्युजिल्याण्ड",
    "country_155": "निकारागुआ",
    "country_156": "नाइजर",
    "country_157": "नाइजेरिया",
    "country_158": "निउ",
    "country_159": "नोर्फोक टापु",
    "country_160": "उत्तर कोरिया",
    "country_161": "उत्तरी मारियाना टापुहरू",
    "country_162": "नर्वे",
    "country_163": "ओमान",
    "country_164": "पाकिस्तान",
    "country_165": "पलाउ",
    "country_166": "प्यालेस्टिनी क्षेत्रहरू",
    "country_167": "पनामा",
    "country_168": "पपुवा न्युगिनी",
    "country_169": "पाराग्वे",
    "country_170": "पेरु",
    "country_171": "पिटकेर्न टापुहरू",
    "country_172": "पोल्याण्ड",
    "country_173": "पोर्चुगल",
    "country_174": "पोर्टो रिको",
    "country_175": "कतार",
    "country_176": "म्यासेडोनिया गणतन्त्र (FYROM)",
    "country_177": "कंगोको गणतन्त्र",
    "country_178": "रियुनियन",
    "country_179": "रोमानिया",
    "country_180": "रूसी संघ",
    "country_181": "रुवाण्डा",
    "country_182": "सेन्ट बार्थेलेमी",
    "country_183": "सेन्ट मार्टिन (फ्रान्स)",
    "country_184": "सेन्ट-पियरे र मिकेलोन",
    "country_185": "सामोआ",
    "country_186": "सान मारिनो",
    "country_187": "साओ टोमे र प्रिन्सिपे",
    "country_188": "साउदी अरेबिया",
    "country_189": "सेनेगल",
    "country_190": "सर्बिया",
    "country_191": "सेशेल्स",
    "country_192": "सिएरा लियोन",
    "country_193": "सिङ्गापुर",
    "country_194": "स्लोभाकिया",
    "country_195": "स्लोभेनिया",
    "country_196": "सोलोमन टापुहरू",
    "country_197": "सोमालिया",
    "country_198": "दक्षिण अफ्रिका",
    "country_199": "दक्षिण जर्जिया र दक्षिण स्यान्डविच इस्ला",
    "country_200": "दक्षिण कोरिया",
    "country_201": "दक्षिण सुडान",
    "country_202": "स्पेन",
    "country_203": "श्रीलंका",
    "country_204": "सेन्ट हेलेना र निर्भरता",
    "country_205": "सेन्ट किट्स र नेभिस",
    "country_206": "सेन्ट लुसिया",
    "country_207": "सेन्ट भिन्सेन्ट र ग्रेनेडाइन्स",
    "country_208": "सुडान",
    "country_209": "सुरिनाम",
    "country_210": "स्वाजिल्याण्ड",
    "country_211": "स्वीडेन",
    "country_212": "स्विट्जरल्याण्ड",
    "country_213": "सिरिया",
    "country_214": "ताइवान",
    "country_215": "ताजिकिस्तान",
    "country_216": "तान्जानिया",
    "country_217": "टेम्प्लेट:देश डेटा SJM स्वाल्बार्ड",
    "country_218": "थाइल्याण्ड",
    "country_219": "बहामास",
    "country_220": "कोमोरोस",
    "country_221": "फिलिपिन्स",
    "country_222": "टिमोर-लेस्टे (पूर्वी टिमोर)",
    "country_223": "टोगो",
    "country_224": "टोकेलाउ",
    "country_225": "टोंगा",
    "country_226": "ट्रिनिडाड र टोबागो",
    "country_227": "ट्युनिसिया",
    "country_228": "टर्की",
    "country_229": "तुर्कमेनिस्तान",
    "country_230": "टर्क्स र काइकोस टापुहरू",
    "country_231": "टुभालु",
    "country_232": "युगान्डा",
    "country_233": "युक्रेन",
    "country_234": "संयुक्त अरब इमिरेट्स",
    "country_235": "संयुक्त राज्य अमेरिका माइनर आउटलाइङ टापुहरू",
    "country_236": "संयुक्त राज्य अमेरिका (संयुक्त राज्य अमेरिका)",
    "country_237": "संयुक्त राज्य भर्जिन टापुहरू",
    "country_238": "उरुग्वे",
    "country_239": "उजबेकिस्तान",
    "country_240": "भानुअतु",
    "country_241": "भ्याटिकन सिटी (द होली सी)",
    "country_242": "भेनेजुएला",
    "country_243": "भियतनाम",
    "country_244": "वालिस र फ्युटुना",
    "country_245": "पश्चिमी सहारा",
    "country_246": "यमन",
    "country_247": "जाम्बिया",
    "country_248": "जिम्बाब्वे",
    "google_login": "Google मार्फत साइन इन गर्नुहोस्",
    "State": "राज्य",
    "Activation_code": "सुचारु गर्ने कोड",
    "Question": "तपाईंले लग इन गर्नुभएका सबै एपहरू सूचीबद्ध गर्नुहोस्",
    "Copy_complete": "प्रतिलिपि पूरा भयो",
    "footer": "प्रतिलिपि अधिकार © 2024 MindOnMap स्टुडियो। सबै अधिकार सुरक्षित।",
    "change_password_success": "पासवर्ड सफलतापूर्वक परिवर्तन भयो",
    "successful_login_title": "लगइन सफल",
    "product_page": "उत्पादन पृष्ठ >>",
    "successful_login_info": "लगइन पूरा भयो। कृपया हालको पृष्ठ बन्द गर्नुहोस् र निम्न प्रक्रिया जारी राख्न मूल ट्याबमा फर्कनुहोस्। हालको पृष्ठ 5 सेकेन्डमा स्वचालित रूपमा बन्द हुनेछ। यदि \"सम्पन्न\" बटनमा क्लिक गरेर स्वत: बन्द वा बन्द गर्न असफल भएमा, कृपया यो ट्याब सिधै बन्द गर्नुहोस्।",
    "successful_login_info_firefox": "लगइन पूरा भयो। कृपया हालको पृष्ठ बन्द गर्नुहोस् र निम्न प्रक्रिया जारी राख्न मूल ट्याबमा फर्कनुहोस्।",
    "my_account": "मेरो खाता",
    "my_history": "मेरो इतिहास",
    "remove_watermark": "वाटरमार्क हटाउनुहोस्",
    "no_history": "इतिहास छैन",
    "history_all": "सबै छान्नु",
    "history_open": "खोल्नुहोस्",
    "history_down": "डाउनलोड गर्नुहोस्",
    "history_delete": "मेटाउन",
    "history_clear": "अमान्य खाली गर्नुहोस्",
    "images": "छवि(हरू)",
    "use_this_function": "यो उत्पादन प्रयोग गर्नुहोस् >>",
    "hd_downloading": "HD मूल छविहरू डाउनलोड लाभहरू:",
    "lifetimeRemaining": "लाइफटाइम-बाँकी",
    "Remaining": "बाँकी",
    "email_verification": "इमेल प्रमाणीकरण",
    "email_verification_info": "हामीले तपाईंको इमेल <span class=\"email\"></span> मा प्रमाणीकरण सन्देश पठाएका छौं, र कृपया प्रमाणीकरण समाप्त गर्नुहोस्। प्रमाणीकरण पछि, लाभहरू स्वचालित रूपमा सिङ्क हुनेछन्।",
    "wrong_email": "गलत इमेल ठेगाना?",
    "click_here_to_modify": "परिमार्जन गर्न यहाँ क्लिक गर्नुहोस्",
    "get_more_help": "थप मद्दत प्राप्त गर्ने?",
    "click_here": "यहाँ क्लिक गर्नुहोस्",
    "email_verification_info_success": "तपाईको इमेल खाता प्रमाणित गर्नुभएकोमा बधाई छ।",
    "email_verification_info_error": "म्याद सकिएको लिङ्कको कारण प्रमाणीकरण असफल भयो।",
    "registration_succeeded": "दर्ता सफल भयो",
    "registration_succeeded_info_1": "बधाई छ! तपाईंले सफलतापूर्वक दर्ता गर्नुभएको छ। हामीले तपाईंको इमेल <span class=\"email\"></span> मा प्रमाणीकरण सन्देश पठाएका छौं, र कृपया यो इमेलका फाइदाहरू प्रयोग गर्न प्रमाणीकरण पूरा गर्नुहोस्।",
    "registration_succeeded_info_2": "गृह पृष्ठमा फर्कन र यो उत्पादन प्रयोग गर्न \"सम्पन्न\" क्लिक गर्नुहोस्।",
    "registration_succeeded_info_3": "हालको पृष्ठ बन्द गर्न \"सम्पन्न\" मा क्लिक गर्नुहोस् र निम्न कार्यहरूको लागि गृह पृष्ठमा फर्कनुहोस्। यदि बन्द गर्न असफल भएमा, कृपया म्यानुअल रूपमा ट्याब बन्द गर्नुहोस्।",
    "verify_email": "इमेल पुष्टी गर",
    "registered_email_not_verified": "दर्ता गरिएको इमेल प्रमाणित गरिएको छैन, कृपया यसलाई एकै पटक प्रमाणित गर्नुहोस्।",
    "email_verification_time_1": "प्रमाणीकरण इमेल प्राप्त गर्नुभएन?",
    "email_verification_time_2": "पछि",
    "email_verification_time_3": "यसलाई पुन: पठाउन यहाँ क्लिक गर्नुहोस्",
    "error_26301": "त्रुटि कोड: 26301, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26302": "त्रुटि कोड: 26302, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26303": "इमेल ढाँचा त्रुटि (त्रुटि कोड: 26303)। कृपया यसलाई फेरि प्रविष्ट गर्नुहोस्",
    "error_26304": "8 वर्ण भन्दा माथिको पासवर्ड सिफारिस गरिएको छ (त्रुटि कोड: 26304)",
    "error_26305": "Reuqest असफल भयो (त्रुटि कोड: 26305)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26306": "इमेल दर्ता गरिएको छ, कृपया <a href=\"https://account.mindonmap.com/login/\">लग इन गर्न जानुहोस्</a>",
    "error_26307": "Reuqest असफल भयो (त्रुटि कोड: 26307)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26308": "Reuqest असफल भयो (त्रुटि कोड: 26308)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26401": "त्रुटि कोड: 26401, कृपया पुन: प्रयास गर्नुहोस्",
    "error_26402": "इमेल प्रमाणित गरिएको छ (त्रुटि कोड: 26402), कृपया पुन: प्रयास गर्नुहोस्",
    "error_26403": "Reuqest असफल भयो (त्रुटि कोड: 26403)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26404": "अनुरोध असफल भयो (त्रुटि कोड: 26404)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26501": "त्रुटि कोड: 26501, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26502": "त्रुटि कोड: 26502, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26503": "इमेल ढाँचा त्रुटि (त्रुटि कोड: 26503)। कृपया यसलाई फेरि प्रविष्ट गर्नुहोस्",
    "error_26504": "Reuqest असफल भयो (त्रुटि कोड: 26504)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26505": "इमेल दर्ता गरिएको छैन, कृपया <a href=\"https://account.mindonmap.com/register/\">पहिले दर्ता गर्नुहोस्</a>",
    "error_26506": "इमेल प्रमाणित गरिएको छ।",
    "error_26507": "Reuqest असफल भयो (त्रुटि कोड: 26507)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26508": "प्रमाणीकरण फाइल गरियो (त्रुटि कोड: 26508), कृपया पुन: प्रयास गर्नुहोस्",
    "error_26509": "अनुरोध असफल भयो (त्रुटि कोड: 26509), कृपया पुन: प्रयास गर्नुहोस्",
    "error_26510": "त्रुटि कोड: 26510, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26601": "त्रुटि कोड: 26601, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26602": "त्रुटि कोड: 26602, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26603": "Reuqest असफल भयो (त्रुटि कोड: 26603)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26604": "त्रुटि कोड: 26604, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26605": "त्रुटि कोड: 26605, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26701": "त्रुटि कोड: 26701, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26702": "Reuqest असफल भयो (त्रुटि कोड: 26702)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26703": "त्रुटि कोड: 26703, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26704": "त्रुटि कोड: 26704, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26705": "लग इन गर्न पर्खनुहोस् (त्रुटि कोड: 26705)। कृपया पुन: प्रयास गर्नुहोस्",
    "no_cookie": "तपाईंले आफ्नो ब्राउजरमा सबै कुकीहरू ब्लक गर्ने कार्यलाई सक्रिय गर्नुभएको छ, त्यसैले तपाईंले लग इन गर्न सक्नुहुन्न। कृपया सबै कुकीहरूलाई अनुमति दिनुहोस् को बाकस जाँच गर्न सेटिङहरूमा जानुहोस्।",
    "error_26801": "त्रुटि कोड: 26801, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26802": "त्रुटि कोड: 26802, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26803": "अनुरोध असफल भयो (त्रुटि कोड: 26803)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_26804": "अनुरोध असफल भयो (त्रुटि कोड: 26804)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_order": "अनुरोध असफल भयो (त्रुटि कोड: 27098), कृपया फेरि प्रयास गर्नुहोस्!",
    "error_order1": "अर्डर क्वेरी अपूर्ण छ (त्रुटि कोड: ",
    "error_order2": "）, कृपया रिफ्रेस गर्नुहोस् र फेरि प्रयास गर्नुहोस्।",
    "modify_email_title": "इमेल परिमार्जन गर्नुहोस्",
    "modify_email_info": "तपाईं आफ्नो खातामा लग इन गर्न परिमार्जित इमेल प्रयोग गर्न सक्नुहुन्छ।",
    "images_per": "छविहरू प्रति",
    "error_26101": "त्रुटि: 26101। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26102": "त्रुटि: 26102। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26103": "अनुरोध असफल (त्रुटि कोड:26103)। कृपया यसलाई फेरि प्रयास गर्नुहोस्",
    "error_26104": "त्रुटि कोड: 26104, कृपया पुन: प्रयास गर्नुहोस्",
    "error_26105": "त्रुटि कोड: 26105, कृपया पुन: प्रयास गर्नुहोस्",
    "error_26106": "मेटाउन असफल (त्रुटि कोड: २६१०६)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_26201": "त्रुटि: 26201। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26202": "अनुरोध असफल (त्रुटि कोड:26202)। कृपया यसलाई फेरि प्रयास गर्नुहोस्",
    "error_26001": "त्रुटि: 26001। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26002": "त्रुटि: 26002। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26003": "त्रुटि: 26003। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26004": "त्रुटि: 26004। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_26005": "अनुरोध असफल (त्रुटि कोड:26005)। कृपया यसलाई फेरि प्रयास गर्नुहोस्",
    "error_26006": "त्रुटि कोड: 26006, कृपया पुन: प्रयास गर्नुहोस्",
    "error_26008": "त्रुटि: 26008। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "go_to_the_home_page": "गृह पृष्ठमा जानुहोस्",
    "error_27101": "अनुरोध असफल भयो (त्रुटि कोड: 27101)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_27201": "त्रुटि कोड: 27201, कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_27202": "त्रुटि कोड: 27202, कृपया पुन: प्रयास गर्नुहोस्",
    "error_27203": "अनुरोध असफल भयो (त्रुटि कोड: 27203)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_27204": "अमान्य कोड (त्रुटि कोड: 27204)।",
    "error_27205": "अनुरोध असफल भयो (त्रुटि कोड: 27205)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_27206": "अनुरोध असफल भयो (त्रुटि कोड: 27206)। कृपया पुन: प्रयास गर्नुहोस्",
    "error_27207": "अनुरोध असफल भयो (त्रुटि कोड: 27207)। कृपया पुन: प्रयास गर्नुहोस्",
    "no_history_found": "तपाईंले कुनै पनि उपकरण प्रयोग गर्नुभएको छैन! <a href=\"/\">रिफ्रेस गर्नुहोस्</a> वा <a href=\"https://www.mindonmap.com/\">आधिकारिक वेबसाइटमा जानुहोस्</a>",
    "error_25301": "त्रुटि: 25301। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_25302": "त्रुटि: 25302। कृपया हामीलाई सम्पर्क गर्नुहोस्",
    "error_25303": "अनुरोध असफल भयो (त्रुटि कोड: २५३०३)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_25304": "अनुरोध असफल भयो (त्रुटि कोड: २५३०४)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_25305": "अनुरोध असफल भयो (त्रुटि कोड: २५३०५)। कृपया फेरि प्रयास गर्नुहोस्",
    "error_25306": "अनुरोध असफल भयो (त्रुटि कोड: २५३०६)। कृपया फेरि प्रयास गर्नुहोस्",
    "image_upscaler_p": "वाटरमार्क बिना छवि डाउनलोड लाभ:",
    "Available_for": "यसका लागि उपलब्ध:",
    "credit_per": "प्रति HD छवि %s क्रेडिट(हरू)",
    "still_valid": "खरिद गरिएको योजना(हरू) अझै मान्य छ",
    "credit": "क्रेडिट(हरू)",
    "pc_3rd_info": "सफलतापूर्वक लगइन गर्नुहोस्। थप सञ्चालनको लागि कृपया आवेदनमा जानुहोस्।",
    "use_online": "अनलाइन सेवा प्रयोग गर्नुहोस्",
    "use_download": "डेस्कटप कार्यक्रम प्रयोग गर्नुहोस्",
    "use_immediately": "तुरुन्तै प्रयोग गर्नुहोस्",
    "Use_in_browser": "ब्राउजरमा प्रयोग गर्नुहोस्",
    "win_desktop": "विन्डोज",
    "Mac_desktop": "म्याक",
    "credits_per": "{%} प्रति महिना क्रेडिट",
    "expire": "म्याद सकिने समय:",
    "viewDetails": "विवरण हेर्नुहोस्",
    "viewHistory": "इतिहास लाभहरू हेर्नुहोस् >>",
    "viewDetailsInfo": "सूचना: यदि सदस्यता लाभहरू म्याद समाप्त भएको 7 दिन भित्र नवीकरण गरियो भने, प्रयोग नगरिएका लाभहरू प्रयोग गर्न जारी राख्न सकिन्छ। साथै, म्याद समाप्त हुने समय स्वचालित रूपमा नयाँ सदस्यताको म्याद समाप्त हुने समयमा अद्यावधिक हुनेछ। यदि त्यहाँ म्याद समाप्त भएको 7 दिन पछि कुनै नयाँ सदस्यता छैन भने, सबै म्याद सकिएका लाभहरू खाली गरिनेछ।",
    "connect_account": "तपाईंको खातामा इमेल बाँध्नुहोस्",
    "connect_account_info": "बाइन्डिङ पछि, तपाईं यो इमेल ठेगाना संग लग इन गर्न सक्नुहुन्छ।",
    "connect_now": "अब बाँध्नुहोस्",
    "no_email_bind": "कुनै इमेल बाइन्ड छैन",
    "bind_email": "इमेल बाँध्नुहोस्",
    "connect_your_email_placeholder": "कृपया आफ्नो इमेल ठेगाना प्रविष्ट गर्नुहोस्",
    "bind_an_email": "एउटा इमेल बाँध्नुहोस्",
    "bind_info": "तपाईंले सफलतापूर्वक लग इन गर्नुभयो। आफ्नो फाइदाहरू सक्रिय गर्नको लागि कृपया आफ्नो खातामा एउटा इमेल बाँध्नुहोस्।",
    "bind_later": "पछि बाँध्नुहोस्",
    "hi": "नमस्ते, %s!",
    "Personal_Information": "व्यक्तिगत जानकारी",
    "Access": "पहुँच",
    "Subscription_Plan": "(सदस्यता योजना)",
    "No_orders": "कुनै आदेश भेटिएन।",
    "No_data": "डाटा छैन",
    "Featured_Products": "विशेष उत्पादनहरू",
    "More_Products": "थप उत्पादनहरू",
    "Free_Download": "नि: शुल्क डाउनलोड",
    "Get_Started": "सुरु गर",
    "Subscribe": "सदस्यता लिनुहोस्",
    "Verified": "प्रमाणित",
    "back_to_account_center": "खाता केन्द्रमा फर्कनुहोस्",
    "success": "सफलता!",
    "successfully": "तपाईंले सफलतापूर्वक एउटा खाता दर्ता गर्नुभएको छ।",
    "Continue": "जारी राख्नुहोस्",
    "Already": "पहिले नै खाता छ?",
    "loading_verification": "प्रमाणीकरण स्थिति जाँच गर्दै...",
    "email_no_verification": "माफ गर्नुहोस्, दर्ता गरिएको इमेल ठेगाना प्रमाणित गरिएको छैन। कृपया माथिका निर्देशनहरू अनुसार प्रमाणीकरण पूरा गर्नुहोस् र दर्ता पूरा गर्न फेरि \"डन\" बटनमा क्लिक गर्नुहोस्।",
    "will_expire_after": "पछि म्याद सकिन्छ",
    "hours": "घण्टा",
  },
  "nl": {
    "overtime": "Foutcode: {%}, probeer het opnieuw",
    "isnetwork": "Internetfout. Controleer het en probeer het opnieuw",
    "email_placeholder": "E-mail",
    "email_empty": "Voer uw e-mailadres in",
    "email_not_valid": "E-mail is niet geldig",
    "email_not_valid_1": "Voer uw e-mailadres in",
    "email_not_valid_2": "E-mailadres is niet geldig. Gebruik een ander adres.",
    "email_not_valid_3": "Geen e-mailinvoer",
    "password_placeholder": "Wachtwoord",
    "password_empty": "Voer het wachtwoord in",
    "password_not_valid": "Onjuist account of wachtwoord",
    "password_not_valid_1": "Wachtwoord van meer dan 8 tekens is vereist",
    "password_not_valid_2": "Maak een wachtwoord aan",
    "password_placeholder_1": "Maak uw wachtwoord aan",
    "password_placeholder_2": "Bevestig uw wachtwoord",
    "password_placeholder_3": "Maak een nieuw wachtwoord aan",
    "password_placeholder_4": "Bevestig nieuw wachtwoord",
    "password_placeholder_5": "Voer oud wachtwoord in",
    "copy_password_empty": "Bevestig het wachtwoord",
    "copy_password_not_valid": "bevestig alstublieft uw wachtwoord",
    "copy_passwords_inconsistent": "Uw wachtwoordbevestiging komt niet overeen",
    "code_empty": "Voer de verificatiecode in",
    "code_not_valid": "Ongeldige verificatie code",
    "code_placeholder": "Verificatie code",
    "not_received_code": "Als uw mailbox de verificatiecode lange tijd niet heeft ontvangen, vraag dan de verificatiecode opnieuw aan.",
    "get_first_code": "Vraag eerst de verificatiecode op.",
    "last_name_placeholder": "Gelieve uw achternaam in te voeren",
    "first_name_placeholder": "Gelieve uw voornaam in te vullen",
    "address_placeholder": "Voer uw adres in",
    "no_code_text": "We hebben een verificatiecode verzonden. Voer uw code in. <span class='tips'>Geen code ontvangen?",
    "no_code_text_1": "1. Zorg ervoor dat het e-mailadres geldig is en e-mails kan ontvangen.",
    "no_code_text_2": "2. Omdat de e-mail automatisch door het systeem wordt verzonden, kan deze worden gemarkeerd als spam of ongewenste e-mail. Controleer of de e-mail in de map Prullenbak staat.",
    "no_code_text_3": "3. Kunt u uw probleem niet oplossen? ",
    "no_code_text_3_span": "Klik dan hier om contact met ons op te nemen.",
    "order_no": "U heeft geen product gekocht. Als u vragen heeft, kunt u <a href=\"https://www.mindonmap.com/contact-us/\">contact met ons opnemen</a>.",
    "error_24901": "Aan het huidige account is geen e-mailadres gekoppeld en de bestellingen kunnen niet worden gevonden. Sluit een e-mailadres aan.",
    "user_guide": "Gebruikershandleiding >>",
    "download": "Downloaden",
    "order_number": "Bestellingsnummer:",
    "Refund": "Terugbetaling",
    "Disabled": "Gehandicapt",
    "Normal": "Normaal",
    "Modify": "Bewerken",
    "Modify_1": "Wijzig >>",
    "Connect": "Aansluiten",
    "unlink_success": "Ontkoppelen is gelukt",
    "connect_success": "Succesvol verbinding maken",
    "feedback_title": "Bedankt voor je feedback. Laat uw probleem achter en wij zullen binnen 24 uur reageren.",
    "feedback_thank_you": "Bedankt!<br />Uw feedback is succesvol verzonden.",
    "feedback_email": "Vul je email hier in!",
    "feedback_content": "Laat hier elk probleem of suggestie achter die u bent tegengekomen.",
    "feedback_submit": "Indienen",
    "form_contents": "U heeft geen beschrijving ingevoerd. Voer het in en verzend het opnieuw.",
    "old_password": "Voer een oud wachtwoord in",
    "new_password": "Maak een nieuw wachtwoord aan",
    "old_new_password": "Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude",
    "incorrect_password": "Incorrect wachtwoord",
    "delete_no": "Verwijder nu",
    "Caps": "Caps Lock staat aan",
    "Get": "Krijgen",
    "Done": "Klaar",
    "error_20001": "Fout: 20001. Meld u opnieuw aan.",
    "error_20002": "Fout: 20002. Meld u opnieuw aan.",
    "error_20003": "Fout: 20003. Meld u opnieuw aan.",
    "error_20004": "Aanvraag mislukt (foutcode: 20004). Probeer het opnieuw.",
    "error_20005": "De inlogsessie is verlopen (fout: 20005). Log alsjeblieft nogmaals in.",
    "error_20006": "Aanvraag mislukt (foutcode: 20006). Probeer het opnieuw.",
    "error_20007": "De inlogsessie is verlopen (fout: 20007). Log alsjeblieft nogmaals in.",
    "error_20008": "De inlogsessie is verlopen (fout: 20008). Log alsjeblieft nogmaals in.",
    "error_20009": "De inlogsessie is verlopen (fout: 20009). Log alsjeblieft nogmaals in.",
    "error_20101": "Voer uw e-mailadres in (foutcode: 20101)",
    "error_20102": "E-mail is niet geldig (foutcode: 20102)",
    "error_20103": "Aanvraag mislukt (foutcode: 20103). Probeer het opnieuw",
    "error_20104": "E-mailadres is al in gebruik. <a href=\"https://account.mindonmap.com/login/\">log in</a> of registreer met een nieuw e-mailadres",
    "error_20105": "Aanvraag mislukt (foutcode: 20105). Probeer het opnieuw",
    "error_20106": "Het verzenden van de e-mail is mislukt. Probeer het opnieuw",
    "error_20201": "Voer uw e-mailadres in (foutcode: 20201)",
    "error_20202": "Voer uw wachtwoord in (foutcode: 20202)",
    "error_20203": "Voer de verificatiecode in (foutcode: 20203)",
    "error_20204": "E-mail is niet geldig (foutcode: 20204)",
    "error_20205": "Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20205)",
    "error_20206": "Aanvraag mislukt (foutcode: 20206). Probeer het opnieuw",
    "error_20207": "Ongeldige verificatie code",
    "error_20208": "Aanvraag mislukt (foutcode: 20208). Probeer het opnieuw",
    "error_20209": "Aanvraag mislukt (foutcode: 20209). Probeer het opnieuw",
    "error_20301": "Voer uw e-mailadres in (foutcode: 20301)",
    "error_20302": "Fout: 20302. Neem contact met ons op",
    "error_20303": "E-mail is niet geldig (foutcode: 20303)",
    "error_20304": "Aanvraag mislukt (foutcode: 20304). Probeer het opnieuw",
    "error_20305": "Account bestaat niet. Voer het opnieuw in of <a href=\"https://account.mindonmap.com/register/\">Maak het eerst</a>.",
    "error_20306": "Nog geen wachtwoord. Gebruik <a href=\"https://account.mindonmap.com/passwordless-login/\">Wachtwoordloos inloggen</a> of <a href=\"https://account.mindonmap.com/create-password/\"> stel een wachtwoord in</a> en log in.",
    "error_20308": "Verzoek mislukt (foutcode: 20308). Probeer het opnieuw",
    "error_20401": "Uitloggen mislukt (foutcode: 20401). Probeer het opnieuw",
    "error_20501": "Voer uw e-mailadres in (foutcode: 20501)",
    "error_20502": "E-mail is niet geldig (foutcode: 20502)",
    "error_20503": "Aanvraag mislukt (foutcode: 20503). Probeer het opnieuw",
    "error_20504": "Kan geen e-mail verzenden. Probeer het opnieuw.",
    "error_20601": "Voer uw e-mailadres in (foutcode: 20601)",
    "error_20602": "Voer de verificatiecode in (foutcode: 20602)",
    "error_20603": "E-mail is niet geldig (foutcode: 20603)",
    "error_20604": "Verzoek mislukt (foutcode: 20604). Probeer het opnieuw",
    "error_20606": "Verzoek mislukt (foutcode: 20606). Probeer het opnieuw",
    "error_20607": "Verzoek mislukt (foutcode: 20607). Probeer het opnieuw",
    "error_20608": "Verzoek mislukt (foutcode: 20608). Probeer het opnieuw",
    "error_20701": "Voer uw e-mailadres in (foutcode: 20701)",
    "error_20702": "E-mail is niet geldig (foutcode: 20702)",
    "error_20703": "Aanvraag mislukt (foutcode: 20703). Probeer het opnieuw",
    "error_20704": "Account bestaat niet. Voer het opnieuw in of <a href=\"https://account.mindonmap.com/register/\">Maak het eerst</a>.",
    "error_20705": "Verzoek mislukt (foutcode: 20705). Probeer het opnieuw",
    "error_20706": "Kan geen e-mail verzenden. Probeer het opnieuw",
    "error_20801": "Voer uw e-mailadres in (foutcode: 20801)",
    "error_20802": "Fout: 20802. Neem contact met ons op",
    "error_20803": "Voer de verificatiecode in (foutcode: 20803)",
    "error_20804": "E-mail is niet geldig (foutcode: 20804)",
    "error_20805": "Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20805)",
    "error_20806": "Verzoek mislukt (foutcode: 20806). Probeer het opnieuw",
    "error_20808": "Verzoek mislukt (foutcode: 20808). Probeer het opnieuw",
    "error_20901": "Aanvraag mislukt (foutcode: 20901). Probeer het opnieuw",
    "error_20902": "Aanvraag mislukt (foutcode: 20902). Probeer het opnieuw",
    "error_21000": "Wijzigingen worden opgeslagen",
    "error_21001": "Er wordt geen informatie verzonden (foutcode: 21001)",
    "error_21002": "Aanvraag mislukt (foutcode: 21002). Probeer het opnieuw",
    "error_21101": "Voer uw e-mailadres in (foutcode: 21101)",
    "error_21102": "E-mail is niet geldig (foutcode: 21102)",
    "error_21103": "Verzoek mislukt (foutcode: 21103). Probeer het opnieuw",
    "error_21104": "E-mail is al verbonden. Gebruik een nieuwe",
    "error_21105": "Verzoek mislukt (foutcode: 21105). Probeer het opnieuw",
    "error_21106": "Kan geen e-mail verzenden. Probeer het opnieuw",
    "error_21201": "Voer uw e-mailadres in (foutcode: 21201)",
    "error_21202": "Voer de verificatiecode in (foutcode: 21202)",
    "error_21203": "E-mail is niet geldig (foutcode: 21203)",
    "error_21204": "Fout: 21204. Neem contact met ons op",
    "error_21205": "Fout: 21205. Neem contact met ons op",
    "error_21206": "Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21206)",
    "error_21207": "Aanvraag mislukt (foutcode: 21207). Probeer het opnieuw",
    "error_21209": "Aanvraag mislukt (foutcode: 21209). Probeer het opnieuw",
    "error_21301": "Voer het oude wachtwoord in (foutcode: 21301)",
    "error_21302": "Maak een nieuw wachtwoord aan (foutcode: 21302)",
    "error_21303": "Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude. (Fout: 21303)",
    "error_21304": "Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21304)",
    "error_21306": "Verzoek mislukt (foutcode: 21306). Probeer het opnieuw",
    "error_21402": "Aanvraag mislukt (foutcode: 21402). Probeer het opnieuw",
    "error_21403": "Kan verificatiecode niet verzenden. Stuur het alstublieft opnieuw",
    "error_21500": "Het account is verwijderd",
    "error_21501": "Voer de verificatiecode in (foutcode: 21501)",
    "error_21502": "De inlogsessie is verlopen (fout: 21502). Log alsjeblieft nogmaals in.",
    "error_21503": "Aanvraag mislukt (foutcode: 21503). Probeer het opnieuw",
    "error_21505": "Verzoek mislukt (foutcode: 21505), probeer het opnieuw",
    "error_21601": "Fout: 20601. Neem contact met ons op",
    "error_21602": "Ongeldige verificatie (fout: 20602). Probeer het opnieuw.",
    "error_21603": "Fout: 20603. Probeer het opnieuw",
    "error_21604": "Aanvraag mislukt (foutcode: 21604). Probeer het opnieuw",
    "error_21606": "Aanvraag mislukt (foutcode: 21606). Probeer het opnieuw",
    "error_21611": "Verzoek mislukt (foutcode: 21611). Probeer het opnieuw",
    "error_21801": "Fout: 21801. Neem contact met ons op",
    "error_21802": "Verzoek mislukt (fout: 21802). Probeer het opnieuw",
    "error_21803": "Fout: 21803. Probeer het opnieuw",
    "error_21804": "Verzoek mislukt (foutcode: 21804). Probeer het opnieuw",
    "error_21806": "Fout: 21806. Probeer het opnieuw",
    "error_21807": "Fout: 21807. Neem contact met ons op",
    "error_21808": "Fout: 21808. Neem contact met ons op",
    "error_21809": "Fout: 21809. Neem contact met ons op",
    "error_21810": "Fout: 21810. Neem contact met ons op",
    "error_21811": "Fout: 21811. Neem contact met ons op",
    "error_21812": "Fout: 21812. Neem contact met ons op",
    "error_21813": "Verzoek mislukt (foutcode: 21813). Probeer het opnieuw",
    "error_21814": "Fout: 21814. Neem contact met ons op",
    "error_21815": "Verzoek mislukt (foutcode: 21815). Probeer het opnieuw",
    "error_21816": "Fout: 21816. Neem contact met ons op",
    "error_21817": "Fout: 21817. Neem contact met ons op",
    "error_21818": "Fout: 21818. Neem contact met ons op",
    "error_21819": "Verzoek mislukt (foutcode: 21819). Probeer het opnieuw",
    "error_21820": "Fout: 21820. Neem contact met ons op",
    "error_21821": "Fout: 21821. Neem contact met ons op",
    "error_21822": "Fout: 21822. Neem contact met ons op",
    "error_21823": "Verzoek mislukt (foutcode: 21823). Probeer het opnieuw",
    "error_21824": "Verzoek mislukt (foutcode: 21824). Probeer het opnieuw",
    "error_21825": "Verzoek mislukt (foutcode: 21825). Probeer het opnieuw",
    "error_21826": "Verzoek mislukt (foutcode: 21826). Probeer het opnieuw",
    "error_21901": "Fout: 21901. Neem contact met ons op",
    "error_21902": "Verzoek mislukt (foutcode: 21902). Probeer het opnieuw",
    "error_21903": "De accountstatus is gewijzigd (foutcode: 21903). Vernieuw de pagina en probeer het opnieuw",
    "error_21904": "Fout: 21904. Probeer het opnieuw",
    "error_21905": "Fout: 21905. Probeer het opnieuw",
    "error_21906": "Verzoek mislukt (foutcode: 21906). Probeer het opnieuw",
    "error_21907": "Google-account is gekoppeld aan een ander account",
    "error_21908": "Verzoek mislukt (foutcode: 21908). Probeer het opnieuw",
    "error_22001": "Aanvraag mislukt (foutcode: 22001). Probeer het opnieuw",
    "error_22002": "Ontkoppelen is mislukt zonder extra aanmelding",
    "error_22003": "Aanvraag mislukt (foutcode: 22003). Probeer het opnieuw",
    "error_22101": "Fout: 22101. Neem contact met ons op",
    "error_22102": "De accountstatus is gewijzigd (foutcode: 22102). Vernieuw de pagina en probeer het opnieuw",
    "error_22103": "Aanvraag mislukt (foutcode: 22103). Probeer het opnieuw",
    "error_22104": "De accountstatus is gewijzigd (foutcode: 22104). Vernieuw de pagina en probeer het opnieuw",
    "error_22105": "Fout: 22105. Probeer het opnieuw",
    "error_22106": "Fout: 22106. Probeer het opnieuw",
    "error_22107": "Fout: 22107. Neem contact met ons op",
    "error_22108": "Aanvraag mislukt (foutcode: 22108). Probeer het opnieuw",
    "error_22201": "Fout: 22201. Neem contact met ons op",
    "error_22202": "Ongeldige verificatie (fout: 22202). Probeer het opnieuw.",
    "error_22203": "Fout: 22203. Probeer het opnieuw\"",
    "error_22204": "Verzoek mislukt (foutcode: 22204). Probeer het opnieuw",
    "error_22206": "Verzoek mislukt (foutcode: 22206). Probeer het opnieuw",
    "error_22401": "Fout: 22401. Neem contact met ons op",
    "error_22402": "Ongeldige verificatie (fout: 22402). Probeer het opnieuw.",
    "error_22403": "Fout: 22403. Probeer het opnieuw",
    "error_22404": "Verzoek mislukt (foutcode: 22404). Probeer het opnieuw",
    "error_22405": "Facebook-account is gekoppeld aan andere e-mail",
    "error_22406": "Fout: 22406. Probeer het opnieuw",
    "error_22407": "Fout: 22407. Neem contact met ons op",
    "error_22408": "Fout: 22408. Neem contact met ons op",
    "error_22409": "Fout: 22409. Neem contact met ons op",
    "error_22410": "Fout: 224010. Neem contact met ons op",
    "error_22411": "Fout: 224011. Neem contact met ons op",
    "error_22412": "Fout: 224012. Neem contact met ons op",
    "error_22413": "Fout: 22413. Neem contact met ons op",
    "error_22414": "Aanvraag mislukt (foutcode: 22414). Probeer het opnieuw",
    "error_22415": "Fout: 22415. Neem contact met ons op",
    "error_22416": "Fout: 22416. Neem contact met ons op",
    "error_22417": "Fout: 22417. Neem contact met ons op",
    "error_22418": "Verzoek mislukt (foutcode: 22418). Probeer het opnieuw",
    "error_22419": "Fout: 22419. Neem contact met ons op",
    "error_22420": "Fout: 22420. Neem contact met ons op",
    "error_22421": "Fout: 22421. Neem contact met ons op",
    "error_22422": "Verzoek mislukt (foutcode: 22422). Probeer het opnieuw",
    "error_22423": "Verzoek mislukt (foutcode: 22423). Probeer het opnieuw",
    "error_22424": "Verzoek mislukt (foutcode: 22424). Probeer het opnieuw",
    "error_22425": "Verzoek mislukt (foutcode: 22425). Probeer het opnieuw",
    "error_20098": "Foutcode: 20098. Als u in de privémodus browst, schakel dan over naar de normale modus en probeer het opnieuw.",
    "error_22298": "Verzoek voor Google is mislukt (fout: 22298). Probeer het opnieuw.",
    "error_22498": "Verzoek om Facebook mislukt (fout: 22498). Probeer het opnieuw.",
    "error_24902": "Aanvraag mislukt (foutcode: 24902). Probeer het opnieuw",
    "error_24903": "Aanvraag mislukt (foutcode: 24903). Probeer het opnieuw",
    "error_24904": "Verzoek mislukt (foutcode: 24904). Probeer het opnieuw",
    "error_24905": "Verzoek mislukt (foutcode: 24905). Probeer het opnieuw",
    "login_title": "Log in op MindOnMap",
    "log_in": "Log in",
    "no_account": "Geen account?",
    "create_it": "Creëer het",
    "or_log_in_with": "Of log in met",
    "passwordless_login": "Wachtwoordloos inloggen",
    "log_in_done": "Inloggen klaar",
    "three_rd_account_connect_info": "Gefeliciteerd! U bent succesvol ingelogd. Nu kunt u één e-mailaccount koppelen waarmee u in de toekomst kunt inloggen.",
    "see_my_account": "Zie mijn account",
    "three_rd_login_merge_account": "Het e-mailadres van het account van een derde partij is aangemeld. Wilt u verbinding maken en direct inloggen met dit e-mailadres?",
    "connect_log_in": "Verbinden en inloggen",
    "create_an_account": "Account aanmaken",
    "back_to_log_in": "Terug naar Inloggen",
    "create_password": "Maak een wachtwoord",
    "create_now": "Maak nu",
    "password_login_subtitle": "Wachtwoordloos inloggen met e-mail",
    "account_login": "Account login",
    "rights": "Door dit account aan te maken, gaat u akkoord met de <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Servicevoorwaarden</a> en <a href=\"https://www.mindonmap.com/privacy-policy/\">Privacybeleid</a>",
    "passwordless_login_done": "Wachtwoordloos inloggen voltooid",
    "passwordless_login_info": "Gefeliciteerd, u heeft succesvol inloggen zonder wachtwoord uitgevoerd. U kunt voor dit account een wachtwoord aanmaken en in de toekomst inloggen met het account en wachtwoord. <a href=\"/create-password\" style=\"display: initial;\">Nu maken</a>",
    "sign_up": "Aanmelden",
    "register_info": "Maak een account aan",
    "reset_now": "Nu opnieuw instellen",
    "forgot_password": "Wachtwoord vergeten",
    "reset_password_subtitle": "Gebruik het e-mailadres van uw account om het wachtwoord opnieuw in te stellen",
    "plan_products": "Plannen & Producten",
    "nick_name": "Gebruikersnaam:",
    "email": "E-mail:",
    "my_products": "Mijn producten",
    "my_orders": "mijn bestellingen",
    "unlink": "Ontkoppelen",
    "link": "Koppeling",
    "connected_accounts": "Verbonden accounts",
    "last_name": "Achternaam:",
    "first_name": "Voornaam:",
    "Gender": "Geslacht:",
    "Birth": "Geboorte:",
    "Month": "Maand",
    "Year": "Jaar",
    "Country_Region": "Land/regio:",
    "Address": "Adres:",
    "Save": "Redden",
    "Date": "Datum",
    "Male": "Mannelijk",
    "Female": "Vrouwelijk",
    "Unspecified": "Niet gespecificeerd",
    "Security": "Beveiliging",
    "change_password": "Verander wachtwoord",
    "change_now": "Verander nu",
    "connect_email": "Verbind e-mail",
    "delete_account": "Account verwijderen",
    "delete_account_info": "Wanneer uw account wordt verwijderd, worden alle gegevens in Mijn account die aan uw account zijn gekoppeld permanent verwijderd en kunt u deze mogelijk niet herstellen. Wij raden u aan voorzichtig te werk te gaan.",
    "Delete": "Verwijderen",
    "Logout": "Uitloggen",
    "my_profile": "Mijn profiel",
    "guides_faqs": "Gidsen en veelgestelde vragen",
    "More": "Meer",
    "guides": "Gidsen",
    "register": "register",
    "hot_faq": "Veelgestelde vragen",
    "Contents": "Inhoud:",
    "contact_us": "Neem contact met ons op>>",
    "plan": "Plan",
    "unregistered": "Niet geregistreerd",
    "buy_more": "Koop meer",
    "buy_again": "Koop opnieuw",
    "buy_now": "Koop nu",
    "free_no_limit": "Gratis en geen limiet",
    "expired": "Verlopen",
    "lifetime": "Levenslang",
    "remain": "Blijven",
    "day_s": "Dag(en)",
    "error_24801": "Aanvraag mislukt (foutcode: 24801). Probeer het opnieuw",
    "no_app_found": "Geen app gevonden！<a href=\"/\">Vernieuwen</a> of <a href=\"https://www.mindonmap.com/\">Ga naar de officiële website</a>",
    "get_more": "Krijg meer >>",
    "edit_photo": "Bewerk foto",
    "select_photo": "Selecteer foto",
    "change_photo": "Verander foto",
    "cancel": "Annuleren",
    "hide_password": "Verberg wachtwoord",
    "show_password": "Laat wachtwoord zien",
    "zoom_in": "In zoomen",
    "zoom_out": "Uitzoomen",
    "rotate": "Draaien",
    "horizontal_flip": "Horizontale spiegeling",
    "vertical_flip": "Verticale draai",
    "country": "Land",
    "country_1": "Selecteer uw land/regio",
    "country_2": "Åaland-eiland",
    "country_3": "Afghanistan",
    "country_4": "Albanië",
    "country_5": "Algerije",
    "country_6": "Amerikaans Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarctica",
    "country_11": "Antigua en Barbuda",
    "country_12": "Argentinië",
    "country_13": "Armenië",
    "country_14": "Aruba",
    "country_15": "Australië",
    "country_16": "Oostenrijk",
    "country_17": "Azerbeidzjan",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Wit-Rusland",
    "country_22": "België",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnië & Herzegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet-eiland",
    "country_31": "Brazilië",
    "country_32": "Brits-Indisch oceaan gebied",
    "country_33": "Britse Maagdeneilanden",
    "country_34": "Brunei",
    "country_35": "Bulgarije",
    "country_36": "Burkinese",
    "country_37": "Burundi",
    "country_38": "Cambodja",
    "country_39": "Kameroen",
    "country_40": "Canada",
    "country_41": "Kaapverdië",
    "country_42": "Caribisch Nederland",
    "country_43": "Kaaiman Eilanden",
    "country_44": "Centraal Afrikaanse Republiek",
    "country_45": "Tsjaad",
    "country_46": "Chili",
    "country_47": "China",
    "country_48": "Kersteiland",
    "country_49": "Cocos-(Keeling)-eilanden",
    "country_50": "Colombia",
    "country_51": "Cookeilanden",
    "country_52": "Costa Rica",
    "country_53": "Ivoorkust",
    "country_54": "Kroatië",
    "country_55": "Cuba",
    "country_56": "Cyprus",
    "country_57": "Tsjechische Republiek",
    "country_58": "Democratische Republiek Congo",
    "country_59": "Denemarken",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominicaanse Republiek",
    "country_63": "Ecuador",
    "country_64": "Egypte",
    "country_65": "El Salvador",
    "country_66": "Equatoriaal-Guinea",
    "country_67": "Eritrea",
    "country_68": "Estland",
    "country_69": "Ethiopië",
    "country_70": "Falkland Eilanden",
    "country_71": "Faeröer eilanden",
    "country_72": "Gefedereerde Staten van Micronesië",
    "country_73": "Fiji",
    "country_74": "Finland",
    "country_75": "Frankrijk",
    "country_76": "Frans-Guyana",
    "country_77": "Frans-Polynesië",
    "country_78": "Franse zuidelijke gebieden",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgië",
    "country_82": "Duitsland",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Groot-Brittannië (Verenigd Koninkrijk; Engeland)",
    "country_86": "Griekenland",
    "country_87": "Groenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinee-Bissau",
    "country_95": "Guyana",
    "country_96": "Haïti",
    "country_97": "Heard-eiland en McDonald-eilanden",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Hongarije",
    "country_101": "IJsland",
    "country_102": "Indië",
    "country_103": "Indonesië",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Ierland",
    "country_107": "Eiland Man",
    "country_108": "Israël",
    "country_109": "Italië",
    "country_110": "Jamaica",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordanië",
    "country_114": "Kazachstan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Koeweit",
    "country_118": "Kirgizië",
    "country_119": "Laos",
    "country_120": "Letland",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libië",
    "country_125": "Liechtenstein",
    "country_126": "Litouwen",
    "country_127": "Luxemburg",
    "country_128": "Macau",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Maleisië",
    "country_132": "Maldiven",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall eilanden",
    "country_136": "Martinique",
    "country_137": "Mauritanië",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexico",
    "country_141": "Moldavië",
    "country_142": "Monaco",
    "country_143": "Mongolië",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marokko",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Birma)",
    "country_149": "Namibië",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Nederland",
    "country_153": "Nieuw-Caledonië",
    "country_154": "Nieuw-Zeeland",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolkeiland",
    "country_160": "Noord Korea",
    "country_161": "Noordelijke Mariana eilanden",
    "country_162": "Noorwegen",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestijnse grondgebieden",
    "country_167": "Panama",
    "country_168": "Papoea-Nieuw-Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairneilanden",
    "country_172": "Polen",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Katar",
    "country_176": "Republiek Macedonië (FYROM)",
    "country_177": "Republiek Congo",
    "country_178": "Bijeenkomst",
    "country_179": "Roemenië",
    "country_180": "Russische Federatie",
    "country_181": "Rwanda",
    "country_182": "Sint-Bartholomeus",
    "country_183": "Sint Maarten (Frankrijk)",
    "country_184": "Saint-Pierre en Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tomé en Principe",
    "country_188": "Saoedi-Arabië",
    "country_189": "Senegal",
    "country_190": "Servië",
    "country_191": "Seychellen",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slowakije",
    "country_195": "Slovenië",
    "country_196": "Solomon eilanden",
    "country_197": "Somalië",
    "country_198": "Zuid-Afrika",
    "country_199": "Zuid-Georgië en de South Sandwich Isla",
    "country_200": "Zuid-Korea",
    "country_201": "Zuid Soedan",
    "country_202": "Spanje",
    "country_203": "Sri Lanka",
    "country_204": "Sint-Helena en afhankelijkheden",
    "country_205": "St. Kitts en Nevis",
    "country_206": "St Lucia",
    "country_207": "St. Vincent en de Grenadines",
    "country_208": "Soedan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Zweden",
    "country_212": "Zwitserland",
    "country_213": "Syrië",
    "country_214": "Taiwan",
    "country_215": "Tadzjikistan",
    "country_216": "Tanzania",
    "country_217": "Sjabloon: Landgegevens SJM Spitsbergen",
    "country_218": "Thailand",
    "country_219": "De Bahamas",
    "country_220": "De Comoren",
    "country_221": "De Filipijnen",
    "country_222": "Oost-Timor (Oost-Timor)",
    "country_223": "Gaan",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunesië",
    "country_228": "Kalkoen",
    "country_229": "Turkmenistan",
    "country_230": "Turks- en Caicoseilanden",
    "country_231": "Tuvalu",
    "country_232": "Oeganda",
    "country_233": "Oekraïne",
    "country_234": "Verenigde Arabische Emiraten",
    "country_235": "Kleine afgelegen eilanden van de Verenigde Staten",
    "country_236": "Verenigde Staten van Amerika (VS)",
    "country_237": "Maagdeneilanden van de Verenigde Staten",
    "country_238": "Uruguay",
    "country_239": "Oezbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vaticaanstad (de Heilige Stoel)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis en Futuna",
    "country_245": "Westelijke Sahara",
    "country_246": "Jemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Log in met Google",
    "State": "Staat",
    "Activation_code": "Activatie code",
    "Question": "Maak een lijst van alle apps waarmee u bent ingelogd",
    "Copy_complete": "Kopieer voltooid",
    "footer": "Copyright © 2024 MindOnMap Studio. Alle rechten voorbehouden.",
    "change_password_success": "wachtwoord succesvol veranderd",
    "successful_login_title": "Succesvol ingelogd",
    "product_page": "Productpagina >>",
    "successful_login_info": "Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces. De huidige pagina wordt binnen 5 seconden automatisch gesloten. Als het automatisch sluiten of sluiten door op de knop \"Gereed\" te klikken mislukt, sluit u dit tabblad direct.",
    "successful_login_info_firefox": "Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces.",
    "my_account": "Mijn rekening",
    "my_history": "Mijn geschiedenis",
    "remove_watermark": "Verwijder watermerk",
    "no_history": "Geen geschiedenis",
    "history_all": "Selecteer alles",
    "history_open": "Open",
    "history_down": "Downloaden",
    "history_delete": "Verwijderen",
    "history_clear": "Wis ongeldig",
    "images": "afbeeldingen",
    "use_this_function": "Gebruik dit product>>",
    "hd_downloading": "Voordelen van het downloaden van originele HD-afbeeldingen:",
    "lifetimeRemaining": "Levensduur resterend",
    "Remaining": "Overig",
    "email_verification": "email verificatie",
    "email_verification_info": "We hebben het verificatiebericht naar uw e-mailadres <span class=\"email\"></span> gestuurd en ronden de verificatie af. Na verificatie worden de voordelen automatisch gesynchroniseerd.",
    "wrong_email": "Verkeerd email adres?",
    "click_here_to_modify": "Klik hier om te wijzigen",
    "get_more_help": "Meer hulp krijgen?",
    "click_here": "Klik hier",
    "email_verification_info_success": "Gefeliciteerd met het verifiëren van uw e-mailaccount.",
    "email_verification_info_error": "Verificatie mislukt vanwege de verlopen link.",
    "registration_succeeded": "Registratie gelukt",
    "registration_succeeded_info_1": "Gefeliciteerd! U bent succesvol geregistreerd. We hebben het verificatiebericht naar uw e-mailadres <span class=\"email\"></span> gestuurd. Voltooi de verificatie om van de voordelen van deze e-mail gebruik te kunnen maken.",
    "registration_succeeded_info_2": "Klik op \"Gereed\" om terug te keren naar de startpagina en dit product te gebruiken.",
    "registration_succeeded_info_3": "Klik op \"Gereed\" om de huidige pagina te sluiten en terug te keren naar de startpagina voor de volgende bewerkingen. Als het sluiten mislukt, sluit u het tabblad handmatig.",
    "verify_email": "Verifieer Email",
    "registered_email_not_verified": "Het geregistreerde e-mailadres is niet geverifieerd. Controleer dit alstublieft meteen.",
    "email_verification_time_1": "Geen verificatie-e-mail ontvangen?",
    "email_verification_time_2": "Na",
    "email_verification_time_3": "Klik hier om het opnieuw te verzenden",
    "error_26301": "Foutcode: 26301, neem contact met ons op",
    "error_26302": "Foutcode: 26302, neem contact met ons op",
    "error_26303": "Fout in e-mailformaat (foutcode: 26303). Voer het opnieuw in",
    "error_26304": "Wachtwoord van meer dan 8 tekens wordt aanbevolen (foutcode: 26304)",
    "error_26305": "Reuqest mislukt (foutcode: 26305). Probeer het opnieuw",
    "error_26306": "E-mailadres is geregistreerd. <a href=\"https://account.mindonmap.com/login/\">ga naar inloggen</a>",
    "error_26307": "Reuqest mislukt (foutcode: 26307). Probeer het opnieuw",
    "error_26308": "Reuqest mislukt (foutcode: 26308). Probeer het opnieuw",
    "error_26401": "Foutcode: 26401, probeer het opnieuw",
    "error_26402": "E-mail is geverifieerd (foutcode: 26402), probeer het opnieuw",
    "error_26403": "Reuqest mislukt (foutcode: 26403). Probeer het opnieuw",
    "error_26404": "Verzoek mislukt (foutcode: 26404). Probeer het opnieuw",
    "error_26501": "Foutcode: 26501, neem contact met ons op",
    "error_26502": "Foutcode: 26502, neem contact met ons op",
    "error_26503": "Fout in e-mailformaat (foutcode: 26503). Voer het opnieuw in",
    "error_26504": "Reuqest mislukt (foutcode: 26504). Probeer het opnieuw",
    "error_26505": "E-mailadres is niet geregistreerd. <a href=\"https://account.mindonmap.com/register/\">registreer dit eerst</a>",
    "error_26506": "E-mail is geverifieerd.",
    "error_26507": "Reuqest mislukt (foutcode: 26507). Probeer het opnieuw",
    "error_26508": "Verificatie voltooid (foutcode: 26508). Probeer het opnieuw",
    "error_26509": "Verzoek mislukt (foutcode: 26509). Probeer het opnieuw",
    "error_26510": "Foutcode: 26510, neem contact met ons op",
    "error_26601": "Foutcode: 26601, neem contact met ons op",
    "error_26602": "Foutcode: 26602, neem contact met ons op",
    "error_26603": "Reuqest mislukt (foutcode: 26603). Probeer het opnieuw",
    "error_26604": "Foutcode: 26604, neem contact met ons op",
    "error_26605": "Foutcode: 26605, neem contact met ons op",
    "error_26701": "Foutcode: 26701, neem contact met ons op",
    "error_26702": "Reuqest mislukt (foutcode: 26702). Probeer het opnieuw",
    "error_26703": "Foutcode: 26703, neem contact met ons op",
    "error_26704": "Foutcode: 26704, neem contact met ons op",
    "error_26705": "Wacht tot u zich heeft aangemeld (foutcode: 26705). Probeer het opnieuw",
    "no_cookie": "U heeft de functie Alle cookies blokkeren in uw browser ingeschakeld, waardoor u niet kunt inloggen. Ga naar Instellingen om het vakje Alle cookies toestaan aan te vinken.",
    "error_26801": "Foutcode: 26801, neem contact met ons op",
    "error_26802": "Foutcode: 26802, neem contact met ons op",
    "error_26803": "Verzoek mislukt (foutcode: 26803). Probeer het opnieuw",
    "error_26804": "Verzoek mislukt (foutcode: 26804). Probeer het opnieuw",
    "error_order": "Aanvraag mislukt (foutcode: 27098), probeer het opnieuw!",
    "error_order1": "De bestellingsquery is onvolledig (foutcode: ",
    "error_order2": "）, ververs de pagina en probeer het opnieuw.",
    "modify_email_title": "E-mail wijzigen",
    "modify_email_info": "U kunt het gewijzigde e-mailadres gebruiken om in te loggen op uw account.",
    "images_per": "Afbeeldingen per",
    "error_26101": "Fout: 26101. Neem contact met ons op",
    "error_26102": "Fout: 26102. Neem contact met ons op",
    "error_26103": "Verzoek mislukt (foutcode: 26103). Probeer het opnieuw",
    "error_26104": "Foutcode: 26104, probeer het opnieuw",
    "error_26105": "Foutcode: 26105, probeer het opnieuw",
    "error_26106": "Verwijderen mislukt (foutcode: 26106). Probeer het opnieuw",
    "error_26201": "Fout: 26201. Neem contact met ons op",
    "error_26202": "Verzoek mislukt (foutcode: 26202). Probeer het opnieuw",
    "error_26001": "Fout: 26001. Neem contact met ons op",
    "error_26002": "Fout: 26002. Neem contact met ons op",
    "error_26003": "Fout: 26003. Neem contact met ons op",
    "error_26004": "Fout: 26004. Neem contact met ons op",
    "error_26005": "Verzoek mislukt (foutcode: 26005). Probeer het opnieuw",
    "error_26006": "Foutcode: 26006, probeer het opnieuw",
    "error_26008": "Fout: 26008. Neem contact met ons op",
    "go_to_the_home_page": "Ga naar de startpagina",
    "error_27101": "Aanvraag mislukt (foutcode: 27101). Probeer het opnieuw",
    "error_27201": "Foutcode: 27201, neem contact met ons op",
    "error_27202": "Foutcode: 27202, probeer het opnieuw",
    "error_27203": "Verzoek mislukt (foutcode: 27203). Probeer het opnieuw",
    "error_27204": "Ongeldige code (foutcode: 27204).",
    "error_27205": "Verzoek mislukt (foutcode: 27205). Probeer het opnieuw",
    "error_27206": "Verzoek mislukt (foutcode: 27206). Probeer het opnieuw",
    "error_27207": "Verzoek mislukt (foutcode: 27207). Probeer het opnieuw",
    "no_history_found": "Je hebt geen enkel hulpmiddel gebruikt! <a href=\"/\">Vernieuwen</a> of <a href=\"https://www.mindonmap.com/\">Ga naar de officiële website</a>",
    "error_25301": "Fout: 25301. Neem contact met ons op",
    "error_25302": "Fout: 25302. Neem contact met ons op",
    "error_25303": "Verzoek mislukt (foutcode: 25303). Probeer het opnieuw",
    "error_25304": "Verzoek mislukt (foutcode: 25304). Probeer het opnieuw",
    "error_25305": "Verzoek mislukt (foutcode: 25305). Probeer het opnieuw",
    "error_25306": "Verzoek mislukt (foutcode: 25306). Probeer het opnieuw",
    "image_upscaler_p": "Voordelen van het downloaden van afbeeldingen zonder watermerk:",
    "Available_for": "Beschikbaar voor:",
    "credit_per": "%s credit(s) per HD-afbeelding",
    "still_valid": "Gekochte abonnement(en) zijn nog steeds geldig",
    "credit": "tegoed(en)",
    "pc_3rd_info": "Succesvol inloggen. Ga naar de applicatie voor verdere bediening.",
    "use_online": "Maak gebruik van de onlinedienst",
    "use_download": "Gebruik het desktopprogramma",
    "use_immediately": "Gebruik onmiddellijk",
    "Use_in_browser": "Gebruik in browser",
    "win_desktop": "ramen",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Tegoeden per maand",
    "expire": "Vervaltijd:",
    "viewDetails": "Bekijk details",
    "viewHistory": "Bekijk de geschiedenisvoordelen >>",
    "viewDetailsInfo": "Let op: Als de abonnementsvoordelen binnen 7 dagen na het verlopen worden verlengd, kunnen de ongebruikte voordelen gebruikt blijven worden. Bovendien wordt de vervaltijd automatisch bijgewerkt naar de vervaltijd van het nieuwe abonnement. Als er na 7 dagen vervaltijd geen nieuw abonnement is, worden alle verlopen voordelen gewist.",
    "connect_account": "E-mail aan uw account koppelen",
    "connect_account_info": "Na het binden kunt u met dit e-mailadres inloggen.",
    "connect_now": "Bind nu",
    "no_email_bind": "Geen e-mailbinding",
    "bind_email": "E-mail binden",
    "connect_your_email_placeholder": "Vul alstublieft uw e-mail adres in",
    "bind_an_email": "Een e-mail binden",
    "bind_info": "U bent succesvol ingelogd. Koppel een e-mail aan uw account om uw voordelen te activeren.",
    "bind_later": "Later binden",
    "hi": "Zijn!",
    "Personal_Information": "Persoonlijke informatie",
    "Access": "Toegang",
    "Subscription_Plan": "(Abonnementsplan)",
    "No_orders": "Geen bestellingen gevonden.",
    "No_data": "Geen gegevens",
    "Featured_Products": "Uitgelichte producten",
    "More_Products": "Meer producten",
    "Free_Download": "Gratis download",
    "Get_Started": "Begin",
    "Subscribe": "Abonneren",
    "Verified": "Geverifieerd",
    "back_to_account_center": "Terug naar Accountcentrum",
    "success": "Succes!",
    "successfully": "U heeft succesvol een account geregistreerd.",
    "Continue": "Doorgaan",
    "Already": "Heeft u al een account?",
    "loading_verification": "Verificatiestatus controleren...",
    "email_no_verification": "Sorry, het geregistreerde e-mailadres is niet geverifieerd. Voltooi de verificatie volgens de bovenstaande instructies en klik nogmaals op de knop \"Gereed\" om de registratie te voltooien.",
    "will_expire_after": "Vervalt daarna",
    "hours": "uur",
  },
  "pa": {
    "overtime": "ਗਲਤੀ ਕੋਡ: {%}, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "isnetwork": "ਇੰਟਰਨੈੱਟ ਗੜਬੜ। ਕਿਰਪਾ ਕਰਕੇ ਜਾਂਚ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "email_placeholder": "ਈ - ਮੇਲ",
    "email_empty": "ਕਿਰਪਾ ਕਰਕੇ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ",
    "email_not_valid": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ",
    "email_not_valid_1": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ",
    "email_not_valid_2": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਵੱਖਰਾ ਪਤਾ ਵਰਤੋ।",
    "email_not_valid_3": "ਕੋਈ ਈਮੇਲ ਇੰਪੁੱਟ ਨਹੀਂ",
    "password_placeholder": "ਪਾਸਵਰਡ",
    "password_empty": "ਕਿਰਪਾ ਕਰਕੇ ਪਾਸਵਰਡ ਇਨਪੁਟ ਕਰੋ",
    "password_not_valid": "ਗਲਤ ਖਾਤਾ ਜਾਂ ਪਾਸਵਰਡ",
    "password_not_valid_1": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦਾ ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ",
    "password_not_valid_2": "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪਾਸਵਰਡ ਬਣਾਓ",
    "password_placeholder_1": "ਆਪਣਾ ਪਾਸਵਰਡ ਬਣਾਓ",
    "password_placeholder_2": "ਆਪਣੇ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    "password_placeholder_3": "ਨਵਾਂ ਪਾਸਵਰਡ ਬਣਾਓ",
    "password_placeholder_4": "ਨਵੇਂ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    "password_placeholder_5": "ਪੁਰਾਣਾ ਪਾਸਵਰਡ ਇਨਪੁਟ ਕਰੋ",
    "copy_password_empty": "ਕਿਰਪਾ ਕਰਕੇ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    "copy_password_not_valid": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    "copy_passwords_inconsistent": "ਤੁਹਾਡਾ ਪਾਸਵਰਡ ਪੁਸ਼ਟੀਕਰਨ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ",
    "code_empty": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਦਾਖਲ ਕਰੋ",
    "code_not_valid": "ਅਵੈਧ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ",
    "code_placeholder": "ਪੜਤਾਲ ਕੋਡ",
    "not_received_code": "ਜੇਕਰ ਤੁਹਾਡੇ ਮੇਲਬਾਕਸ ਨੂੰ ਲੰਬੇ ਸਮੇਂ ਤੋਂ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਇਆ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਪ੍ਰਾਪਤ ਕਰੋ।",
    "get_first_code": "ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਪ੍ਰਾਪਤ ਕਰੋ।",
    "last_name_placeholder": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਆਖਰੀ ਨਾਮ ਦਰਜ ਕਰੋ",
    "first_name_placeholder": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਹਿਲਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    "address_placeholder": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਤਾ ਦਰਜ ਕਰੋ",
    "no_code_text": "ਅਸੀਂ ਇੱਕ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਭੇਜਿਆ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕੋਡ ਦਾਖਲ ਕਰੋ। <span class='tips'>ਕੋਡ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਇਆ?",
    "no_code_text_1": "1. ਕਿਰਪਾ ਕਰਕੇ ਯਕੀਨੀ ਬਣਾਓ ਕਿ ਈਮੇਲ ਪਤਾ ਵੈਧ ਹੈ ਅਤੇ ਇਹ ਈਮੇਲ ਪ੍ਰਾਪਤ ਕਰ ਸਕਦਾ ਹੈ।",
    "no_code_text_2": "2. ਈ-ਮੇਲ ਸਿਸਟਮ ਦੁਆਰਾ ਆਪਣੇ ਆਪ ਭੇਜੀ ਜਾਂਦੀ ਹੈ, ਇਸ ਨੂੰ ਸਪੈਮ ਜਾਂ ਜੰਕ ਈਮੇਲ ਵਜੋਂ ਫਲੈਗ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਜਾਂਚ ਕਰੋ ਕਿ ਕੀ ਈਮੇਲ ਰੱਦੀ ਫੋਲਡਰ ਵਿੱਚ ਹੈ।",
    "no_code_text_3": "3. ਤੁਹਾਡੀ ਸਮੱਸਿਆ ਦਾ ਹੱਲ ਨਹੀਂ ਕਰ ਸਕਦੇ? ",
    "no_code_text_3_span": "ਫਿਰ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰਨ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ।",
    "order_no": "ਤੁਸੀਂ ਕੋਈ ਉਤਪਾਦ ਨਹੀਂ ਖਰੀਦਿਆ ਹੈ, ਜੇਕਰ ਤੁਹਾਡੇ ਕੋਈ ਸਵਾਲ ਹਨ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ <a href=\"https://www.mindonmap.com/contact-us/\">ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</a>।",
    "error_24901": "ਮੌਜੂਦਾ ਖਾਤੇ ਵਿੱਚ",
    "user_guide": "ਉਪਭੋਗਤਾ ਗਾਈਡ >>",
    "download": "ਡਾਊਨਲੋਡ ਕਰੋ",
    "order_number": "ਕ੍ਰਮ ਸੰਖਿਆ:",
    "Refund": "ਰਿਫੰਡ",
    "Disabled": "ਅਯੋਗ",
    "Normal": "ਸਧਾਰਣ",
    "Modify": "ਸੋਧੋ",
    "Modify_1": "ਸੋਧ>>",
    "Connect": "ਜੁੜੋ",
    "unlink_success": "ਸਫਲਤਾਪੂਰਵਕ ਅਣਲਿੰਕ ਕਰੋ",
    "connect_success": "ਸਫਲਤਾਪੂਰਵਕ ਜੁੜੋ",
    "feedback_title": "ਤੁਹਾਡੇ ਫੀਡਬੈਕ ਲਈ ਧੰਨਵਾਦ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਸਮੱਸਿਆ ਛੱਡੋ ਅਤੇ ਅਸੀਂ ਤੁਹਾਨੂੰ 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਜਵਾਬ ਦੇਵਾਂਗੇ।",
    "feedback_thank_you": "ਤੁਹਾਡਾ ਧੰਨਵਾਦ!<br />ਤੁਹਾਡਾ ਫੀਡਬੈਕ ਸਫਲਤਾਪੂਰਵਕ ਦਰਜ ਕੀਤਾ ਗਿਆ ਹੈ।",
    "feedback_email": "ਇੱਥੇ ਆਪਣਾ ਈਮੇਲ ਦਰਜ ਕਰੋ!",
    "feedback_content": "ਤੁਹਾਨੂੰ ਆਈ ਕੋਈ ਸਮੱਸਿਆ ਜਾਂ ਸੁਝਾਅ ਇੱਥੇ ਛੱਡੋ।",
    "feedback_submit": "ਜਮ੍ਹਾਂ ਕਰੋ",
    "form_contents": "ਤੁਸੀਂ ਕੋਈ ਵੇਰਵਾ ਦਰਜ ਨਹੀਂ ਕੀਤਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦਾਖਲ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਸਪੁਰਦ ਕਰੋ।",
    "old_password": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਰਾਣਾ ਪਾਸਵਰਡ ਇਨਪੁਟ ਕਰੋ",
    "new_password": "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਨਵਾਂ ਪਾਸਵਰਡ ਬਣਾਓ",
    "old_new_password": "ਨਵਾਂ ਪਾਸਵਰਡ ਪੁਰਾਣੇ ਵਰਗਾ ਨਹੀਂ ਹੋ ਸਕਦਾ",
    "incorrect_password": "ਗਲਤ ਪਾਸਵਰਡ",
    "delete_no": "ਹੁਣੇ ਮਿਟਾਓ",
    "Caps": "ਕੈਪਸ ਲੌਕ ਚਾਲੂ ਹੈ",
    "Get": "ਪ੍ਰਾਪਤ ਕਰੋ",
    "Done": "ਹੋ ਗਿਆ",
    "error_20001": "ਗਲਤੀ: 20001। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20002": "ਗਲਤੀ: 20002। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20003": "ਗਲਤੀ: 20003। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20004": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20004)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_20005": "ਲਾਗਇਨ ਸੈਸ਼ਨ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ (ਗਲਤੀ: 20005)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲੌਗ ਇਨ ਕਰੋ।",
    "error_20006": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20006)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_20007": "ਲਾਗਇਨ ਸੈਸ਼ਨ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ (ਗਲਤੀ: 20007)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20008": "ਲੌਗਇਨ ਸੈਸ਼ਨ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ (ਗਲਤੀ: 20008)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20009": "ਲੌਗਇਨ ਸੈਸ਼ਨ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ (ਗਲਤੀ: 20009)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_20101": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20101)",
    "error_20102": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20102)",
    "error_20103": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20103)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20104": "ਈਮੇਲ ਪਹਿਲਾਂ ਹੀ ਵਰਤੋਂ ਵਿੱਚ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ <a href=\"https://account.mindonmap.com/login/\">ਲੌਗ ਇਨ ਕਰੋ</a> ਜਾਂ ਇੱਕ ਨਵੇਂ ਨਾਲ ਰਜਿਸਟਰ ਕਰੋ",
    "error_20105": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20105)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20106": "ਈਮੇਲ ਭੇਜਣ ਵਿੱਚ ਅਸਫਲ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20201": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20201)",
    "error_20202": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਾਸਵਰਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20202)",
    "error_20203": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20203)",
    "error_20204": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20204)",
    "error_20205": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦਾ ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ (ਗਲਤੀ ਕੋਡ: 20205)",
    "error_20206": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20206)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20207": "ਅਵੈਧ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ",
    "error_20208": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20208)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20209": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20209)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20301": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20301)",
    "error_20302": "ਗਲਤੀ: 20302. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_20303": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20303)",
    "error_20304": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20304)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20305": "ਖਾਤਾ ਮੌਜੂਦ ਨਹੀਂ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਦੁਬਾਰਾ ਦਾਖਲ ਕਰੋ ਜਾਂ <a href=\"https://account.mindonmap.com/register/\">ਇਸਨੂੰ ਬਣਾਓ</a>।",
    "error_20306": "ਅਜੇ ਤੱਕ ਕੋਈ ਪਾਸਵਰਡ ਨਹੀਂ ਹੈ। <a href=\"https://account.mindonmap.com/passwordless-login/\">ਪਾਸਵਰਡ ਰਹਿਤ ਲੌਗਇਨ</a> ਜਾਂ <a href=\"https://account.mindonmap.com/create-password/\"> ਦੀ ਵਰਤੋਂ ਕਰੋ ਇੱਕ ਪਾਸਵਰਡ ਸੈੱਟ ਕਰੋ</a> ਅਤੇ ਲੌਗ ਇਨ ਕਰੋ।",
    "error_20308": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20308)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20401": "ਲੌਗ ਆਉਟ ਕਰਨ ਵਿੱਚ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20401)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20501": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20501)",
    "error_20502": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20502)",
    "error_20503": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20503)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20504": "ਈਮੇਲ ਭੇਜਣਾ ਅਸਫਲ ਰਿਹਾ। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_20601": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20601)",
    "error_20602": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20602)",
    "error_20603": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20603)",
    "error_20604": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20604)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20606": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20606)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20607": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20607)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20608": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20608)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20701": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20701)",
    "error_20702": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20702)",
    "error_20703": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20703)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20704": "ਖਾਤਾ ਮੌਜੂਦ ਨਹੀਂ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਦੁਬਾਰਾ ਦਾਖਲ ਕਰੋ ਜਾਂ <a href=\"https://account.mindonmap.com/register/\">ਇਸਨੂੰ ਬਣਾਓ</a>।",
    "error_20705": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20705)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20706": "ਈਮੇਲ ਭੇਜਣਾ ਅਸਫਲ ਰਿਹਾ। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20801": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20801)",
    "error_20802": "ਗਲਤੀ: 20802. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_20803": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 20803)",
    "error_20804": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 20804)",
    "error_20805": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦਾ ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ (ਗਲਤੀ ਕੋਡ: 20805)",
    "error_20806": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20806)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20808": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 20808)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20901": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20901)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20902": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 20902)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21000": "ਤਬਦੀਲੀਆਂ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ",
    "error_21001": "ਕੋਈ ਜਾਣਕਾਰੀ ਜਮ੍ਹਾਂ ਨਹੀਂ ਕੀਤੀ ਗਈ (ਗਲਤੀ ਕੋਡ: 21001)",
    "error_21002": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21002)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21101": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 21101)",
    "error_21102": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 21102)",
    "error_21103": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21103), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21104": "ਈਮੇਲ ਪਹਿਲਾਂ ਹੀ ਕਨੈਕਟ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਨਵਾਂ ਵਰਤੋ",
    "error_21105": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21105), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21106": "ਈਮੇਲ ਭੇਜਣਾ ਅਸਫਲ ਰਿਹਾ। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21201": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 21201)",
    "error_21202": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 21202)",
    "error_21203": "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ (ਗਲਤੀ ਕੋਡ: 21203)",
    "error_21204": "ਗਲਤੀ: 21204. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21205": "ਗਲਤੀ: 21205. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21206": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦਾ ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ (ਗਲਤੀ ਕੋਡ: 21206)",
    "error_21207": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21207)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21209": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21209)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21301": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਰਾਣਾ ਪਾਸਵਰਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 21301)",
    "error_21302": "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਨਵਾਂ ਪਾਸਵਰਡ ਬਣਾਓ (ਗਲਤੀ ਕੋਡ: 21302)",
    "error_21303": "ਨਵਾਂ ਪਾਸਵਰਡ ਪੁਰਾਣੇ ਵਰਗਾ ਨਹੀਂ ਹੋ ਸਕਦਾ। (ਗਲਤੀ: 21303)",
    "error_21304": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦਾ ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ (ਗਲਤੀ ਕੋਡ: 21304)",
    "error_21306": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21306)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21402": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21402)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21403": "ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਭੇਜਣਾ ਅਸਫਲ ਰਿਹਾ। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਭੇਜੋ",
    "error_21500": "ਖਾਤਾ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ",
    "error_21501": "ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਇਨਪੁਟ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 21501)",
    "error_21502": "ਲੌਗਇਨ ਸੈਸ਼ਨ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ (ਗਲਤੀ: 21502)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਲਾਗਇਨ ਕਰੋ।",
    "error_21503": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21503)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21505": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21505), ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21601": "ਗਲਤੀ: 20601. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21602": "ਅਵੈਧ ਤਸਦੀਕ (ਗਲਤੀ: 20602)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_21603": "ਗਲਤੀ: 20603. ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21604": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21604)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21606": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21606)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21611": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21611)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21801": "ਗਲਤੀ: 21801. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21802": "ਬੇਨਤੀ ਅਸਫਲ ਰਹੀ (ਗਲਤੀ: 21802)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21803": "ਗਲਤੀ: 21803. ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21804": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21804)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21806": "ਗਲਤੀ: 21806. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21807": "ਗਲਤੀ: 21807. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21808": "ਗਲਤੀ: 21808. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21809": "ਗਲਤੀ: 21809. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21810": "ਗਲਤੀ: 21810. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21811": "ਗਲਤੀ: 21811. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21812": "ਗਲਤੀ: 21812. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21813": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21813)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21814": "ਗਲਤੀ: 21814. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21815": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21815)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21816": "ਗਲਤੀ: 21816. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21817": "ਗਲਤੀ: 21817. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21818": "ਗਲਤੀ: 21818. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21819": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21819)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21820": "ਗਲਤੀ: 21820. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21821": "ਗਲਤੀ: 21821. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21822": "ਗਲਤੀ: 21822. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21823": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21823)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21824": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21824)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21825": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 21825)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21826": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21826)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21901": "ਗਲਤੀ: 21901. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_21902": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21902)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21903": "ਖਾਤੇ ਦੀ ਸਥਿਤੀ ਬਦਲ ਗਈ ਹੈ (ਗਲਤੀ ਕੋਡ: 21903), ਕਿਰਪਾ ਕਰਕੇ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21904": "ਗਲਤੀ: 21904. ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21905": "ਗਲਤੀ: 21905. ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21906": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21906)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_21907": "Google ਖਾਤੇ ਨੂੰ ਦੂਜੇ ਖਾਤੇ ਨਾਲ ਲਿੰਕ ਕੀਤਾ ਗਿਆ ਹੈ",
    "error_21908": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 21908)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22001": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 22001)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22002": "ਬਿਨਾਂ ਕਿਸੇ ਵਾਧੂ ਲੌਗਇਨ ਦੇ ਅਣਲਿੰਕ ਅਸਫਲ ਰਿਹਾ",
    "error_22003": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 22003)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22101": "ਗਲਤੀ: 22101. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22102": "ਖਾਤੇ ਦੀ ਸਥਿਤੀ ਬਦਲ ਗਈ ਹੈ (ਗਲਤੀ ਕੋਡ: 22102), ਕਿਰਪਾ ਕਰਕੇ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22103": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22103)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22104": "ਖਾਤੇ ਦੀ ਸਥਿਤੀ ਬਦਲ ਗਈ ਹੈ (ਗਲਤੀ ਕੋਡ: 22104), ਕਿਰਪਾ ਕਰਕੇ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22105": "ਗਲਤੀ: 22105. ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22106": "ਗਲਤੀ: 22106. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22107": "ਗਲਤੀ: 22107. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22108": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22108)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22201": "ਗਲਤੀ: 22201. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22202": "ਅਵੈਧ ਪੁਸ਼ਟੀਕਰਨ (ਗਲਤੀ: 22202)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_22203": "ਗਲਤੀ: 22203. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ\"",
    "error_22204": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22204)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22206": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22206)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22401": "ਗਲਤੀ: 22401. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22402": "ਅਵੈਧ ਤਸਦੀਕ (ਗਲਤੀ: 22402)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_22403": "ਗਲਤੀ: 22403. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22404": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22404)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22405": "ਫੇਸਬੁੱਕ ਖਾਤੇ ਨੂੰ ਹੋਰ ਈਮੇਲ ਨਾਲ ਲਿੰਕ ਕੀਤਾ ਗਿਆ ਹੈ",
    "error_22406": "ਗਲਤੀ: 22406। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22407": "ਗਲਤੀ: 22407. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22408": "ਗਲਤੀ: 22408. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22409": "ਗਲਤੀ: 22409. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22410": "ਗਲਤੀ: 224010. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22411": "ਗਲਤੀ: 224011. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22412": "ਗਲਤੀ: 224012. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22413": "ਗਲਤੀ: 22413. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22414": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22414)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22415": "ਗਲਤੀ: 22415. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22416": "ਗਲਤੀ: 22416. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22417": "ਗਲਤੀ: 22417. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22418": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22418)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22419": "ਗਲਤੀ: 22419. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22420": "ਗਲਤੀ: 22420. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22421": "ਗਲਤੀ: 22421. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_22422": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22422)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22423": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22423)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22424": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 22424)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_22425": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 22425)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_20098": "ਗਲਤੀ ਕੋਡ: 20098. ਜੇਕਰ ਤੁਸੀਂ ਪ੍ਰਾਈਵੇਟ ਮੋਡ ਵਿੱਚ ਬ੍ਰਾਊਜ਼ ਕਰ ਰਹੇ ਹੋ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਸਧਾਰਨ ਮੋਡ ਵਿੱਚ ਸਵਿਚ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_22298": "ਗੂਗਲ ਲਈ ਬੇਨਤੀ ਅਸਫਲ ਰਹੀ (ਗਲਤੀ: 22298)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_22498": "ਫੇਸਬੁੱਕ ਲਈ ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ: 22498)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "error_24902": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 24902)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_24903": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 24903)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_24904": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 24904)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_24905": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 24905)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "login_title": "MindOnMap ਵਿੱਚ ਲੌਗ ਇਨ ਕਰੋ",
    "log_in": "ਲਾਗਿਨ",
    "no_account": "ਕੋਈ ਖਾਤਾ ਨਹੀਂ?",
    "create_it": "ਇਸਨੂੰ ਬਣਾਓ",
    "or_log_in_with": "ਜਾਂ ਨਾਲ ਲਾਗਇਨ ਕਰੋ",
    "passwordless_login": "ਪਾਸਵਰਡ ਰਹਿਤ ਲੌਗਇਨ",
    "log_in_done": "ਲੌਗ ਇਨ ਹੋ ਗਿਆ",
    "three_rd_account_connect_info": "ਵਧਾਈਆਂ! ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕੀਤਾ ਹੈ। ਹੁਣ ਤੁਸੀਂ ਇੱਕ ਈਮੇਲ ਖਾਤਾ ਜੋੜ ਸਕਦੇ ਹੋ ਜੋ ਭਵਿੱਖ ਵਿੱਚ ਲੌਗ ਇਨ ਕਰਨ ਲਈ ਵਰਤਿਆ ਜਾਂਦਾ ਹੈ।",
    "see_my_account": "ਮੇਰਾ ਖਾਤਾ ਦੇਖੋ",
    "three_rd_login_merge_account": "ਤੀਜੀ-ਧਿਰ ਦੇ ਖਾਤੇ ਦਾ ਈਮੇਲ ਪਤਾ ਸਾਈਨ ਅੱਪ ਕੀਤਾ ਗਿਆ ਹੈ, ਕੀ ਤੁਸੀਂ ਇਸ ਈਮੇਲ ਪਤੇ ਨਾਲ ਸਿੱਧਾ ਜੁੜਨਾ ਅਤੇ ਲੌਗਇਨ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    "connect_log_in": "ਜੁੜੋ ਅਤੇ ਲੌਗ ਇਨ ਕਰੋ",
    "create_an_account": "ਅਕਾਉਂਟ ਬਣਾਓ",
    "back_to_log_in": "ਲੌਗ ਇਨ ਕਰਨ ਲਈ ਵਾਪਸ ਜਾਓ",
    "create_password": "ਪਾਸਵਰਡ ਬਣਾਓ",
    "create_now": "ਹੁਣੇ ਬਣਾਓ",
    "password_login_subtitle": "ਈਮੇਲ ਨਾਲ ਪਾਸਵਰਡ ਰਹਿਤ ਲੌਗਇਨ",
    "account_login": "ਖਾਤਾ ਲੌਗਇਨ",
    "rights": "ਇਹ ਖਾਤਾ ਬਣਾ ਕੇ, ਤੁਸੀਂ <a href=\"https://www.mindonmap.com/terms-and-conditions/\">ਸੇਵਾ ਦੀਆਂ ਸ਼ਰਤਾਂ</a> ਅਤੇ <a href=\"https://www.mindonmap.com/privacy-policy/\">ਗੋਪਨੀਯਤਾ ਨੀਤੀ</a>",
    "passwordless_login_done": "ਪਾਸਵਰਡ ਰਹਿਤ ਲੌਗਇਨ ਹੋ ਗਿਆ",
    "passwordless_login_info": "ਵਧਾਈਆਂ, ਤੁਸੀਂ ਪਾਸਵਰਡ ਰਹਿਤ ਲੌਗਇਨ ਸਫਲਤਾਪੂਰਵਕ ਪੂਰਾ ਕਰ ਲਿਆ ਹੈ। ਤੁਸੀਂ ਇਸ ਖਾਤੇ ਲਈ ਇੱਕ ਪਾਸਵਰਡ ਬਣਾ ਸਕਦੇ ਹੋ ਅਤੇ ਭਵਿੱਖ ਵਿੱਚ ਖਾਤੇ ਅਤੇ ਪਾਸਵਰਡ ਨਾਲ ਲੌਗਇਨ ਕਰ ਸਕਦੇ ਹੋ। <a href=\"/create-password\" style=\"display: initial;\">ਹੁਣੇ ਬਣਾਓ</a>",
    "sign_up": "ਸਾਇਨ ਅਪ",
    "register_info": "ਆਪਣਾ ਖਾਤਾ ਬਣਾਓ",
    "reset_now": "ਹੁਣੇ ਰੀਸੈਟ ਕਰੋ",
    "forgot_password": "ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ",
    "reset_password_subtitle": "ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰਨ ਲਈ ਆਪਣੇ ਖਾਤੇ ਦੀ ਈਮੇਲ ਦੀ ਵਰਤੋਂ ਕਰੋ",
    "plan_products": "ਯੋਜਨਾਵਾਂ ਅਤੇ ਉਤਪਾਦ",
    "nick_name": "ਉਪਭੋਗਤਾ ਨਾਮ:",
    "email": "ਈ - ਮੇਲ:",
    "my_products": "ਮੇਰੇ ਉਤਪਾਦ",
    "my_orders": "ਮੇਰੇ ਆਦੇਸ਼",
    "unlink": "ਅਣਲਿੰਕ ਕਰੋ",
    "link": "ਲਿੰਕ",
    "connected_accounts": "ਕਨੈਕਟ ਕੀਤੇ ਖਾਤੇ",
    "last_name": "ਆਖਰੀ ਨਾਂਮ:",
    "first_name": "ਪਹਿਲਾ ਨਾਂ:",
    "Gender": "ਲਿੰਗ:",
    "Birth": "ਜਨਮ:",
    "Month": "ਮਹੀਨਾ",
    "Year": "ਸਾਲ",
    "Country_Region": "ਦੇਸ਼/ਖੇਤਰ:",
    "Address": "ਪਤਾ:",
    "Save": "ਸੇਵ ਕਰੋ",
    "Date": "ਤਾਰੀਖ਼",
    "Male": "ਨਰ",
    "Female": "ਔਰਤ",
    "Unspecified": "ਨਿਰਦਿਸ਼ਟ",
    "Security": "ਸੁਰੱਖਿਆ",
    "change_password": "ਪਾਸਵਰਡ ਬਦਲੋ",
    "change_now": "ਹੁਣੇ ਬਦਲੋ",
    "connect_email": "ਈਮੇਲ ਕਨੈਕਟ ਕਰੋ",
    "delete_account": "ਖਾਤਾ ਮਿਟਾਓ",
    "delete_account_info": "ਜਦੋਂ ਤੁਹਾਡਾ ਖਾਤਾ ਮਿਟਾਇਆ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਤੁਹਾਡੇ ਖਾਤੇ ਨਾਲ ਸੰਬੰਧਿਤ ਮੇਰੇ ਖਾਤੇ ਵਿੱਚ ਸਾਰਾ ਡਾਟਾ ਸਥਾਈ ਤੌਰ 'ਤੇ ਮਿਟਾ ਦਿੱਤਾ ਜਾਵੇਗਾ, ਅਤੇ ਤੁਸੀਂ ਇਸਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਹੋ ਸਕਦੇ ਹੋ। ਅਸੀਂ ਤੁਹਾਨੂੰ ਸਾਵਧਾਨੀ ਨਾਲ ਚੱਲਣ ਦੀ ਸਿਫ਼ਾਰਿਸ਼ ਕਰਦੇ ਹਾਂ।",
    "Delete": "ਮਿਟਾਓ",
    "Logout": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
    "my_profile": "ਮੇਰਾ ਪ੍ਰੋਫ਼ਾਈਲ",
    "guides_faqs": "ਗਾਈਡ ਅਤੇ ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਸਵਾਲ",
    "More": "ਹੋਰ",
    "guides": "ਗਾਈਡ",
    "register": "ਰਜਿਸਟਰ",
    "hot_faq": "ਗਰਮ ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਸਵਾਲ",
    "Contents": "ਸਮੱਗਰੀ:",
    "contact_us": "ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ >>",
    "plan": "ਯੋਜਨਾ",
    "unregistered": "ਅਣਰਜਿਸਟਰਡ",
    "buy_more": "ਹੋਰ ਖਰੀਦੋ",
    "buy_again": "ਦੁਬਾਰਾ ਖਰੀਦੋ",
    "buy_now": "ਹੁਣੇ ਖਰੀਦੋ",
    "free_no_limit": "ਮੁਫਤ ਅਤੇ ਕੋਈ ਸੀਮਾ ਨਹੀਂ",
    "expired": "ਮਿਆਦ ਪੁੱਗ ਗਈ",
    "lifetime": "ਜੀਵਨ ਭਰ",
    "remain": "ਰਹੇ",
    "day_s": "ਦਿਨ(ਦਿਨ)",
    "error_24801": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 24801)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "no_app_found": "ਕੋਈ ਐਪ ਨਹੀਂ ਮਿਲੀ!<a href=\"/\">ਰਿਫ੍ਰੈਸ਼ ਕਰੋ</a> ਜਾਂ <a href=\"https://www.mindonmap.com/\">ਅਧਿਕਾਰਤ ਵੈੱਬਸਾਈਟ 'ਤੇ ਜਾਓ</a>",
    "get_more": "ਹੋਰ ਪ੍ਰਾਪਤ ਕਰੋ >>",
    "edit_photo": "ਫੋਟੋ ਸੰਪਾਦਿਤ ਕਰੋ",
    "select_photo": "ਫੋਟੋ ਚੁਣੋ",
    "change_photo": "ਤਸਵੀਰ ਬਦਲੋ",
    "cancel": "ਰੱਦ ਕਰੋ",
    "hide_password": "ਪਾਸਵਰਡ ਲੁਕਾਓ",
    "show_password": "ਪਾਸਵਰਡ ਦਿਖਾਓ",
    "zoom_in": "ਵੱਡਾ ਕਰਨਾ",
    "zoom_out": "ਜ਼ੂਮ ਘਟਾਓ",
    "rotate": "ਘੁੰਮਾਓ",
    "horizontal_flip": "ਹਰੀਜੱਟਲ ਫਲਿੱਪ",
    "vertical_flip": "ਵਰਟੀਕਲ ਫਲਿੱਪ",
    "country": "ਦੇਸ਼",
    "country_1": "ਆਪਣਾ ਦੇਸ਼/ਖੇਤਰ ਚੁਣੋ",
    "country_2": "ਆਲੈਂਡ ਟਾਪੂ",
    "country_3": "ਅਫਗਾਨਿਸਤਾਨ",
    "country_4": "ਅਲਬਾਨੀਆ",
    "country_5": "ਅਲਜੀਰੀਆ",
    "country_6": "ਅਮਰੀਕੀ ਸਮੋਆ",
    "country_7": "ਅੰਡੋਰਾ",
    "country_8": "ਅੰਗੋਲਾ",
    "country_9": "ਐਂਗੁਇਲਾ",
    "country_10": "ਅੰਟਾਰਕਟਿਕਾ",
    "country_11": "ਐਂਟੀਗੁਆ ਅਤੇ ਬਾਰਬੁਡਾ",
    "country_12": "ਅਰਜਨਟੀਨਾ",
    "country_13": "ਅਰਮੀਨੀਆ",
    "country_14": "ਅਰੂਬਾ",
    "country_15": "ਆਸਟ੍ਰੇਲੀਆ",
    "country_16": "ਆਸਟਰੀਆ",
    "country_17": "ਅਜ਼ਰਬਾਈਜਾਨ",
    "country_18": "ਬਹਿਰੀਨ",
    "country_19": "ਬੰਗਲਾਦੇਸ਼",
    "country_20": "ਬਾਰਬਾਡੋਸ",
    "country_21": "ਬੇਲਾਰੂਸ",
    "country_22": "ਬੈਲਜੀਅਮ",
    "country_23": "ਬੇਲੀਜ਼",
    "country_24": "ਬੇਨਿਨ",
    "country_25": "ਬਰਮੂਡਾ",
    "country_26": "ਭੂਟਾਨ",
    "country_27": "ਬੋਲੀਵੀਆ",
    "country_28": "ਬੋਸਨੀਆ ਅਤੇ ਹਰਜ਼ੇਗੋਵੀਨਾ",
    "country_29": "ਬੋਤਸਵਾਨਾ",
    "country_30": "ਬੁਵੇਟ ਟਾਪੂ",
    "country_31": "ਬ੍ਰਾਜ਼ੀਲ",
    "country_32": "ਬ੍ਰਿਟਿਸ਼ ਹਿੰਦ ਮਹਾਸਾਗਰ ਖੇਤਰ",
    "country_33": "ਬ੍ਰਿਟਿਸ਼ ਵਰਜਿਨ ਟਾਪੂ",
    "country_34": "ਬਰੂਨੇਈ",
    "country_35": "ਬੁਲਗਾਰੀਆ",
    "country_36": "ਬੁਰਕੀਨਾ",
    "country_37": "ਬੁਰੂੰਡੀ",
    "country_38": "ਕੰਬੋਡੀਆ",
    "country_39": "ਕੈਮਰੂਨ",
    "country_40": "ਕੈਨੇਡਾ",
    "country_41": "ਕੇਪ ਵਰਡੇ",
    "country_42": "ਕੈਰੇਬੀਅਨ ਨੀਦਰਲੈਂਡਜ਼",
    "country_43": "ਕੇਮੈਨ ਟਾਪੂ",
    "country_44": "ਮੱਧ ਅਫ਼ਰੀਕੀ ਗਣਰਾਜ",
    "country_45": "ਚਾਡ",
    "country_46": "ਚਿਲੀ",
    "country_47": "ਚੀਨ",
    "country_48": "ਕ੍ਰਿਸਮਸ ਟਾਪੂ",
    "country_49": "ਕੋਕੋਸ (ਕੀਲਿੰਗ) ਟਾਪੂ",
    "country_50": "ਕੋਲੰਬੀਆ",
    "country_51": "ਕੁੱਕ ਟਾਪੂ",
    "country_52": "ਕੋਸਟਾਰੀਕਾ",
    "country_53": "ਕੋਟ ਡਿਵੁਆਰ",
    "country_54": "ਕਰੋਸ਼ੀਆ",
    "country_55": "ਕਿਊਬਾ",
    "country_56": "ਸਾਈਪ੍ਰਸ",
    "country_57": "ਚੇਕ ਗਣਤੰਤਰ",
    "country_58": "ਕਾਂਗੋ ਦਾ ਲੋਕਤੰਤਰੀ ਗਣਰਾਜ",
    "country_59": "ਡੈਨਮਾਰਕ",
    "country_60": "ਜਿਬੂਟੀ",
    "country_61": "ਡੋਮਿਨਿਕਾ",
    "country_62": "ਡੋਮਿਨਿੱਕ ਰਿਪਬਲਿਕ",
    "country_63": "ਇਕਵਾਡੋਰ",
    "country_64": "ਮਿਸਰ",
    "country_65": "ਅਲ ਸਲਵਾਡੋਰ",
    "country_66": "ਇਕੂਟੇਰੀਅਲ ਗਿਨੀ",
    "country_67": "ਇਰੀਟਰੀਆ",
    "country_68": "ਐਸਟੋਨੀਆ",
    "country_69": "ਇਥੋਪੀਆ",
    "country_70": "ਫਾਕਲੈਂਡ ਟਾਪੂ",
    "country_71": "ਫਾਰੋ ਟਾਪੂ",
    "country_72": "ਮਾਈਕ੍ਰੋਨੇਸ਼ੀਆ ਦੇ ਸੰਘੀ ਰਾਜ",
    "country_73": "ਫਿਜੀ",
    "country_74": "ਫਿਨਲੈਂਡ",
    "country_75": "ਫਰਾਂਸ",
    "country_76": "ਫ੍ਰੈਂਚ ਗੁਆਨਾ",
    "country_77": "ਫ੍ਰੈਂਚ ਪੋਲੀਨੇਸ਼ੀਆ",
    "country_78": "ਫ੍ਰੈਂਚ ਦੱਖਣੀ ਖੇਤਰ",
    "country_79": "ਗੈਬੋਨ",
    "country_80": "ਗੈਂਬੀਆ",
    "country_81": "ਜਾਰਜੀਆ",
    "country_82": "ਜਰਮਨੀ",
    "country_83": "ਘਾਨਾ",
    "country_84": "ਜਿਬਰਾਲਟਰ",
    "country_85": "ਗ੍ਰੇਟ ਬ੍ਰਿਟੇਨ (ਯੂਨਾਈਟਿਡ ਕਿੰਗਡਮ; ਇੰਗਲੈਂਡ)",
    "country_86": "ਗ੍ਰੀਸ",
    "country_87": "ਗ੍ਰੀਨਲੈਂਡ",
    "country_88": "ਗ੍ਰੇਨਾਡਾ",
    "country_89": "ਗੁਆਡੇਲੂਪ",
    "country_90": "ਗੁਆਮ",
    "country_91": "ਗੁਆਟੇਮਾਲਾ",
    "country_92": "ਗਰਨਸੇ",
    "country_93": "ਗਿਨੀ",
    "country_94": "ਗਿਨੀ-ਬਿਸਾਉ",
    "country_95": "ਗੁਆਨਾ",
    "country_96": "ਹੈਤੀ",
    "country_97": "ਹਰਡ ਆਈਲੈਂਡ ਅਤੇ ਮੈਕਡੋਨਲਡ ਟਾਪੂ",
    "country_98": "ਹੋਂਡੁਰਾਸ",
    "country_99": "ਹਾਂਗ ਕਾਂਗ",
    "country_100": "ਹੰਗਰੀ",
    "country_101": "ਆਈਸਲੈਂਡ",
    "country_102": "ਭਾਰਤ",
    "country_103": "ਇੰਡੋਨੇਸ਼ੀਆ",
    "country_104": "ਈਰਾਨ",
    "country_105": "ਇਰਾਕ",
    "country_106": "ਆਇਰਲੈਂਡ",
    "country_107": "ਆਇਲ ਆਫ਼ ਮੈਨ",
    "country_108": "ਇਜ਼ਰਾਈਲ",
    "country_109": "ਇਟਲੀ",
    "country_110": "ਜਮਾਏਕਾ",
    "country_111": "ਜਪਾਨ",
    "country_112": "ਜਰਸੀ",
    "country_113": "ਜਾਰਡਨ",
    "country_114": "ਕਜ਼ਾਕਿਸਤਾਨ",
    "country_115": "ਕੀਨੀਆ",
    "country_116": "ਕਿਰੀਬਾਤੀ",
    "country_117": "ਕੁਵੈਤ",
    "country_118": "ਕਿਰਗਿਸਤਾਨ",
    "country_119": "ਲਾਓਸ",
    "country_120": "ਲਾਤਵੀਆ",
    "country_121": "ਲੇਬਨਾਨ",
    "country_122": "ਲੈਸੋਥੋ",
    "country_123": "ਲਾਇਬੇਰੀਆ",
    "country_124": "ਲੀਬੀਆ",
    "country_125": "ਲੀਚਟਨਸਟਾਈਨ",
    "country_126": "ਲਿਥੁਆਨੀਆ",
    "country_127": "ਲਕਸਮਬਰਗ",
    "country_128": "ਮਕਾਓ",
    "country_129": "ਮੈਡਾਗਾਸਕਰ",
    "country_130": "ਮਲਾਵੀ",
    "country_131": "ਮਲੇਸ਼ੀਆ",
    "country_132": "ਮਾਲਦੀਵ",
    "country_133": "ਮਾਲੀ",
    "country_134": "ਮਾਲਟਾ",
    "country_135": "ਮਾਰਸ਼ਲ ਟਾਪੂ",
    "country_136": "ਮਾਰਟੀਨਿਕ",
    "country_137": "ਮੌਰੀਤਾਨੀਆ",
    "country_138": "ਮਾਰੀਸ਼ਸ",
    "country_139": "ਮੇਓਟ",
    "country_140": "ਮੈਕਸੀਕੋ",
    "country_141": "ਮੋਲਡੋਵਾ",
    "country_142": "ਮੋਨਾਕੋ",
    "country_143": "ਮੰਗੋਲੀਆ",
    "country_144": "ਮੋਂਟੇਨੇਗਰੋ",
    "country_145": "ਮੋਂਟਸੇਰਾਟ",
    "country_146": "ਮੋਰੋਕੋ",
    "country_147": "ਮੋਜ਼ਾਮਬੀਕ",
    "country_148": "ਮਿਆਂਮਾਰ (ਬਰਮਾ)",
    "country_149": "ਨਾਮੀਬੀਆ",
    "country_150": "ਨੌਰੂ",
    "country_151": "ਨੇਪਾਲ",
    "country_152": "ਨੀਦਰਲੈਂਡਜ਼",
    "country_153": "ਨਿਊ ਕੈਲੇਡੋਨੀਆ",
    "country_154": "ਨਿਊਜ਼ੀਲੈਂਡ",
    "country_155": "ਨਿਕਾਰਾਗੁਆ",
    "country_156": "ਨਾਈਜਰ",
    "country_157": "ਨਾਈਜੀਰੀਆ",
    "country_158": "ਨਿਉ",
    "country_159": "ਨਾਰਫੋਕ ਟਾਪੂ",
    "country_160": "ਉੱਤਰੀ ਕੋਰਿਆ",
    "country_161": "ਉੱਤਰੀ ਮਾਰੀਆਨਾ ਟਾਪੂ",
    "country_162": "ਨਾਰਵੇ",
    "country_163": "ਓਮਾਨ",
    "country_164": "ਪਾਕਿਸਤਾਨ",
    "country_165": "ਪਲਾਊ",
    "country_166": "ਫਲਸਤੀਨੀ ਖੇਤਰ",
    "country_167": "ਪਨਾਮਾ",
    "country_168": "ਪਾਪੂਆ ਨਿਊ ਗਿਨੀ",
    "country_169": "ਪੈਰਾਗੁਏ",
    "country_170": "ਪੇਰੂ",
    "country_171": "ਪਿਟਕੇਅਰਨ ਟਾਪੂ",
    "country_172": "ਪੋਲੈਂਡ",
    "country_173": "ਪੁਰਤਗਾਲ",
    "country_174": "ਪੋਰਟੋ ਰੀਕੋ",
    "country_175": "ਕਤਰ",
    "country_176": "ਮੈਸੇਡੋਨੀਆ ਗਣਰਾਜ (FYROM)",
    "country_177": "ਕਾਂਗੋ ਦਾ ਗਣਰਾਜ",
    "country_178": "ਰੀਯੂਨੀਅਨ",
    "country_179": "ਰੋਮਾਨੀਆ",
    "country_180": "ਰਸ਼ੀਅਨ ਫੈਡਰੇਸ਼ਨ",
    "country_181": "ਰਵਾਂਡਾ",
    "country_182": "ਸੇਂਟ ਬਾਰਥਲੇਮੀ",
    "country_183": "ਸੇਂਟ ਮਾਰਟਿਨ (ਫਰਾਂਸ)",
    "country_184": "ਸੇਂਟ-ਪੀਅਰੇ ਅਤੇ ਮਿਕਲੋਨ",
    "country_185": "ਸਮੋਆ",
    "country_186": "ਸੈਨ ਮਾਰੀਨੋ",
    "country_187": "ਸਾਓ ਟੋਮੇ ਅਤੇ ਪ੍ਰਿੰਸੀਪੇ",
    "country_188": "ਸਊਦੀ ਅਰਬ",
    "country_189": "ਸੇਨੇਗਲ",
    "country_190": "ਸਰਬੀਆ",
    "country_191": "ਸੇਸ਼ੇਲਸ",
    "country_192": "ਸੀਅਰਾ ਲਿਓਨ",
    "country_193": "ਸਿੰਗਾਪੁਰ",
    "country_194": "ਸਲੋਵਾਕੀਆ",
    "country_195": "ਸਲੋਵੇਨੀਆ",
    "country_196": "ਸੋਲੋਮਨ ਟਾਪੂ",
    "country_197": "ਸੋਮਾਲੀਆ",
    "country_198": "ਦੱਖਣੀ ਅਫਰੀਕਾ",
    "country_199": "ਦੱਖਣੀ ਜਾਰਜੀਆ ਅਤੇ ਦੱਖਣੀ ਸੈਂਡਵਿਚ ਇਸਲਾ",
    "country_200": "ਦੱਖਣ ਕੋਰੀਆ",
    "country_201": "ਦੱਖਣੀ ਸੂਡਾਨ",
    "country_202": "ਸਪੇਨ",
    "country_203": "ਸ਼ਿਰੀਲੰਕਾ",
    "country_204": "ਸੇਂਟ ਹੇਲੇਨਾ ਅਤੇ ਨਿਰਭਰਤਾਵਾਂ",
    "country_205": "ਸੇਂਟ ਕਿਟਸ ਅਤੇ ਨੇਵਿਸ",
    "country_206": "ਸੇਂਟ ਲੂਸੀਆ",
    "country_207": "ਸੇਂਟ ਵਿਨਸੈਂਟ ਅਤੇ ਗ੍ਰੇਨਾਡਾਈਨਜ਼",
    "country_208": "ਸੂਡਾਨ",
    "country_209": "ਸੂਰੀਨਾਮ",
    "country_210": "ਸਵਾਜ਼ੀਲੈਂਡ",
    "country_211": "ਸਵੀਡਨ",
    "country_212": "ਸਵਿੱਟਜਰਲੈਂਡ",
    "country_213": "ਸੀਰੀਆ",
    "country_214": "ਤਾਈਵਾਨ",
    "country_215": "ਤਾਜਿਕਸਤਾਨ",
    "country_216": "ਤਨਜ਼ਾਨੀਆ",
    "country_217": "ਟੈਮਪਲੇਟ:ਕੰਟਰੀ ਡਾਟਾ SJM ਸਵਾਲਬਾਰਡ",
    "country_218": "ਥਾਈਲੈਂਡ",
    "country_219": "ਬਹਾਮਾਸ",
    "country_220": "ਕੋਮੋਰੋਸ",
    "country_221": "ਫਿਲੀਪੀਨਜ਼",
    "country_222": "ਤਿਮੋਰ-ਲੇਸਤੇ (ਪੂਰਬੀ ਤਿਮੋਰ)",
    "country_223": "ਜਾਣਾ",
    "country_224": "ਟੋਕੇਲਾਉ",
    "country_225": "ਟੋਂਗਾ",
    "country_226": "ਤ੍ਰਿਨੀਦਾਦ ਅਤੇ ਟੋਬੈਗੋ",
    "country_227": "ਟਿਊਨੀਸ਼ੀਆ",
    "country_228": "ਟਰਕੀ",
    "country_229": "ਤੁਰਕਮੇਨਿਸਤਾਨ",
    "country_230": "ਤੁਰਕਸ ਅਤੇ ਕੈਕੋਸ ਟਾਪੂ",
    "country_231": "ਟੁਵਾਲੂ",
    "country_232": "ਯੂਗਾਂਡਾ",
    "country_233": "ਯੂਕਰੇਨ",
    "country_234": "ਸੰਯੁਕਤ ਅਰਬ ਅਮੀਰਾਤ",
    "country_235": "ਸੰਯੁਕਤ ਰਾਜ ਮਾਮੂਲੀ ਬਾਹਰੀ ਟਾਪੂ",
    "country_236": "ਸੰਯੁਕਤ ਰਾਜ ਅਮਰੀਕਾ (ਅਮਰੀਕਾ)",
    "country_237": "ਸੰਯੁਕਤ ਰਾਜ ਵਰਜਿਨ ਟਾਪੂ",
    "country_238": "ਉਰੂਗਵੇ",
    "country_239": "ਉਜ਼ਬੇਕਿਸਤਾਨ",
    "country_240": "ਵੈਨੂਆਟੂ",
    "country_241": "ਵੈਟੀਕਨ ਸਿਟੀ (ਦ ਹੋਲੀ ਸੀ)",
    "country_242": "ਵੈਨੇਜ਼ੁਏਲਾ",
    "country_243": "ਵੀਅਤਨਾਮ",
    "country_244": "ਵਾਲਿਸ ਅਤੇ ਫਿਊਟੁਨਾ",
    "country_245": "ਪੱਛਮੀ ਸਹਾਰਾ",
    "country_246": "ਯਮਨ",
    "country_247": "ਜ਼ੈਂਬੀਆ",
    "country_248": "ਜ਼ਿੰਬਾਬਵੇ",
    "google_login": "Google ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ",
    "State": "ਰਾਜ",
    "Activation_code": "ਐਕਟੀਵੇਸ਼ਨ ਕੋਡ",
    "Question": "ਉਹਨਾਂ ਸਾਰੀਆਂ ਐਪਾਂ ਦੀ ਸੂਚੀ ਬਣਾਓ ਜੋ ਤੁਸੀਂ ਲੌਗਇਨ ਕੀਤੇ ਹਨ",
    "Copy_complete": "ਕਾਪੀ ਪੂਰਾ ਹੋਇਆ",
    "footer": "ਕਾਪੀਰਾਈਟ © 2024 MindOnMap ਸਟੂਡੀਓ। ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ.",
    "change_password_success": "ਪਾਸਵਰਡ ਸਫਲਤਾਪੂਰਵਕ ਬਦਲਿਆ ਗਿਆ",
    "successful_login_title": "ਲੌਗਇਨ ਸਫਲ",
    "product_page": "ਉਤਪਾਦ ਪੰਨਾ >>",
    "successful_login_info": "ਲੌਗਇਨ ਪੂਰਾ ਹੋਇਆ। ਕਿਰਪਾ ਕਰਕੇ ਮੌਜੂਦਾ ਪੰਨੇ ਨੂੰ ਬੰਦ ਕਰੋ ਅਤੇ ਅੱਗੇ ਦਿੱਤੀ ਪ੍ਰਕਿਰਿਆ ਨੂੰ ਜਾਰੀ ਰੱਖਣ ਲਈ ਮੂਲ ਟੈਬ 'ਤੇ ਵਾਪਸ ਜਾਓ। ਮੌਜੂਦਾ ਪੰਨਾ 5 ਸਕਿੰਟਾਂ ਵਿੱਚ ਆਪਣੇ ਆਪ ਬੰਦ ਹੋ ਜਾਵੇਗਾ। ਜੇਕਰ \"ਹੋ ਗਿਆ\" ਬਟਨ ਨੂੰ ਦਬਾ ਕੇ ਆਟੋ-ਬੰਦ ਜਾਂ ਬੰਦ ਕਰਨਾ ਅਸਫਲ ਹੋ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਟੈਬ ਨੂੰ ਸਿੱਧਾ ਬੰਦ ਕਰੋ।",
    "successful_login_info_firefox": "ਲੌਗਇਨ ਪੂਰਾ ਹੋਇਆ। ਕਿਰਪਾ ਕਰਕੇ ਮੌਜੂਦਾ ਪੰਨੇ ਨੂੰ ਬੰਦ ਕਰੋ ਅਤੇ ਅੱਗੇ ਦਿੱਤੀ ਪ੍ਰਕਿਰਿਆ ਨੂੰ ਜਾਰੀ ਰੱਖਣ ਲਈ ਮੂਲ ਟੈਬ 'ਤੇ ਵਾਪਸ ਜਾਓ।",
    "my_account": "ਮੇਰਾ ਖਾਤਾ",
    "my_history": "ਮੇਰਾ ਇਤਿਹਾਸ",
    "remove_watermark": "ਵਾਟਰਮਾਰਕ ਹਟਾਓ",
    "no_history": "ਕੋਈ ਇਤਿਹਾਸ ਨਹੀਂ",
    "history_all": "ਸਾਰਿਆ ਨੂੰ ਚੁਣੋ",
    "history_open": "ਖੋਲ੍ਹੋ",
    "history_down": "ਡਾਊਨਲੋਡ ਕਰੋ",
    "history_delete": "ਮਿਟਾਓ",
    "history_clear": "ਅਵੈਧ ਨੂੰ ਸਾਫ਼ ਕਰੋ",
    "images": "ਚਿੱਤਰ",
    "use_this_function": "ਇਸ ਉਤਪਾਦ ਦੀ ਵਰਤੋਂ ਕਰੋ >>",
    "hd_downloading": "HD ਅਸਲੀ ਚਿੱਤਰ ਡਾਊਨਲੋਡ ਕਰਨ ਦੇ ਲਾਭ:",
    "lifetimeRemaining": "ਜੀਵਨ ਕਾਲ-ਰਹਿੰਦਾ",
    "Remaining": "ਬਾਕੀ",
    "email_verification": "ਈਮੇਲ ਪੁਸ਼ਟੀਕਰਨ",
    "email_verification_info": "ਅਸੀਂ ਤੁਹਾਡੀ ਈਮੇਲ <span class=\"email\"></span> 'ਤੇ ਪੁਸ਼ਟੀਕਰਨ ਸੁਨੇਹਾ ਭੇਜਿਆ ਹੈ, ਅਤੇ ਕਿਰਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀਕਰਨ ਨੂੰ ਪੂਰਾ ਕਰੋ। ਤਸਦੀਕ ਕਰਨ ਤੋਂ ਬਾਅਦ, ਲਾਭ ਆਪਣੇ ਆਪ ਹੀ ਸਮਕਾਲੀ ਹੋ ਜਾਣਗੇ।",
    "wrong_email": "ਗਲਤ ਈਮੇਲ ਪਤਾ?",
    "click_here_to_modify": "ਸੋਧਣ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ",
    "get_more_help": "ਹੋਰ ਮਦਦ ਪ੍ਰਾਪਤ ਕਰੋ?",
    "click_here": "ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ",
    "email_verification_info_success": "ਤੁਹਾਡੇ ਈਮੇਲ ਖਾਤੇ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਵਧਾਈਆਂ।",
    "email_verification_info_error": "ਮਿਆਦ ਪੁੱਗਣ ਵਾਲੇ ਲਿੰਕ ਕਾਰਨ ਪੁਸ਼ਟੀਕਰਨ ਅਸਫਲ ਰਿਹਾ।",
    "registration_succeeded": "ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਸਫਲ ਰਹੀ",
    "registration_succeeded_info_1": "ਵਧਾਈਆਂ! ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਰਜਿਸਟਰ ਕਰ ਲਿਆ ਹੈ। ਅਸੀਂ ਤੁਹਾਡੀ ਈਮੇਲ <span class=\"email\"></span> 'ਤੇ ਪੁਸ਼ਟੀਕਰਨ ਸੁਨੇਹਾ ਭੇਜਿਆ ਹੈ, ਅਤੇ ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਈਮੇਲ ਦੇ ਲਾਭਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਪੁਸ਼ਟੀਕਰਨ ਨੂੰ ਪੂਰਾ ਕਰੋ।",
    "registration_succeeded_info_2": "ਹੋਮ ਪੇਜ 'ਤੇ ਵਾਪਸ ਜਾਣ ਅਤੇ ਇਸ ਉਤਪਾਦ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ \"ਹੋ ਗਿਆ\" 'ਤੇ ਕਲਿੱਕ ਕਰੋ।",
    "registration_succeeded_info_3": "ਮੌਜੂਦਾ ਪੰਨੇ ਨੂੰ ਬੰਦ ਕਰਨ ਲਈ \"ਹੋ ਗਿਆ\" 'ਤੇ ਕਲਿੱਕ ਕਰੋ ਅਤੇ ਹੇਠਾਂ ਦਿੱਤੀਆਂ ਕਾਰਵਾਈਆਂ ਲਈ ਹੋਮ ਪੇਜ 'ਤੇ ਵਾਪਸ ਜਾਓ। ਜੇਕਰ ਬੰਦ ਕਰਨਾ ਅਸਫਲ ਹੁੰਦਾ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਹੱਥੀਂ ਟੈਬ ਨੂੰ ਬੰਦ ਕਰੋ।",
    "verify_email": "ਈਮੇਲ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    "registered_email_not_verified": "ਰਜਿਸਟਰਡ ਈਮੇਲ ਦੀ ਤਸਦੀਕ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਇਸਦੀ ਤੁਰੰਤ ਪੁਸ਼ਟੀ ਕਰੋ।",
    "email_verification_time_1": "ਕੀ ਕੋਈ ਪੁਸ਼ਟੀਕਰਨ ਈਮੇਲ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਈ?",
    "email_verification_time_2": "ਤੋਂ ਬਾਅਦ",
    "email_verification_time_3": "ਇਸਨੂੰ ਦੁਬਾਰਾ ਭੇਜਣ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ",
    "error_26301": "ਗਲਤੀ ਕੋਡ: 26301, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26302": "ਗਲਤੀ ਕੋਡ: 26302, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26303": "ਈਮੇਲ ਫਾਰਮੈਟ ਗਲਤੀ (ਗਲਤੀ ਕੋਡ: 26303)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਦਾਖਲ ਕਰੋ",
    "error_26304": "8 ਅੱਖਰਾਂ ਤੋਂ ਉੱਪਰ ਦੇ ਪਾਸਵਰਡ ਦੀ ਸਿਫ਼ਾਰਸ਼ ਕੀਤੀ ਜਾਂਦੀ ਹੈ (ਗਲਤੀ ਕੋਡ: 26304)",
    "error_26305": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26305)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26306": "ਈਮੇਲ ਰਜਿਸਟਰ ਹੋ ਗਈ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ <a href=\"https://account.mindonmap.com/login/\">ਲੌਗ ਇਨ ਕਰਨ ਲਈ ਜਾਓ</a>",
    "error_26307": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26307)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26308": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26308)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26401": "ਗਲਤੀ ਕੋਡ: 26401, ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26402": "ਈਮੇਲ ਦੀ ਪੁਸ਼ਟੀ ਕੀਤੀ ਗਈ ਹੈ (ਗਲਤੀ ਕੋਡ: 26402), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26403": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26403)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26404": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 26404)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26501": "ਗਲਤੀ ਕੋਡ: 26501, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26502": "ਗਲਤੀ ਕੋਡ: 26502, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26503": "ਈਮੇਲ ਫਾਰਮੈਟ ਗਲਤੀ (ਗਲਤੀ ਕੋਡ: 26503)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਦਾਖਲ ਕਰੋ",
    "error_26504": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26504)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26505": "ਈਮੇਲ ਰਜਿਸਟਰ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ <a href=\"https://account.mindonmap.com/register/\">ਇਸ ਨੂੰ ਪਹਿਲਾਂ ਰਜਿਸਟਰ ਕਰੋ</a>",
    "error_26506": "ਈਮੇਲ ਦੀ ਪੁਸ਼ਟੀ ਕੀਤੀ ਗਈ ਹੈ।",
    "error_26507": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26507)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26508": "ਪੁਸ਼ਟੀਕਰਨ ਫਾਈਲ ਕੀਤਾ ਗਿਆ (ਗਲਤੀ ਕੋਡ: 26508), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26509": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 26509), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26510": "ਗਲਤੀ ਕੋਡ: 26510, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26601": "ਗਲਤੀ ਕੋਡ: 26601, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26602": "ਗਲਤੀ ਕੋਡ: 26602, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26603": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26603)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26604": "ਗਲਤੀ ਕੋਡ: 26604, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26605": "ਗਲਤੀ ਕੋਡ: 26605, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26701": "ਗਲਤੀ ਕੋਡ: 26701, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26702": "Reuqest ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 26702)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26703": "ਗਲਤੀ ਕੋਡ: 26703, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26704": "ਗਲਤੀ ਕੋਡ: 26704, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26705": "ਲੌਗਇਨ ਕਰਨ ਦੀ ਉਡੀਕ ਕਰੋ (ਗਲਤੀ ਕੋਡ: 26705)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "no_cookie": "ਤੁਸੀਂ ਆਪਣੇ ਬ੍ਰਾਊਜ਼ਰ ਵਿੱਚ ਸਾਰੀਆਂ ਕੂਕੀਜ਼ ਨੂੰ ਬਲੌਕ ਕਰੋ ਫੰਕਸ਼ਨ ਨੂੰ ਚਾਲੂ ਕਰ ਦਿੱਤਾ ਹੈ, ਇਸਲਈ ਤੁਸੀਂ ਲੌਗਇਨ ਨਹੀਂ ਕਰ ਸਕਦੇ ਹੋ। ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੀਆਂ ਕੂਕੀਜ਼ ਦੀ ਇਜਾਜ਼ਤ ਦਿਓ ਦੇ ਬਾਕਸ ਨੂੰ ਚੈੱਕ ਕਰਨ ਲਈ ਸੈਟਿੰਗਾਂ 'ਤੇ ਜਾਓ।",
    "error_26801": "ਗਲਤੀ ਕੋਡ: 26801, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26802": "ਗਲਤੀ ਕੋਡ: 26802, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26803": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 26803)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26804": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 26804)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_order": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 27098), ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ!",
    "error_order1": "ਆਰਡਰ ਪੁੱਛਗਿੱਛ ਅਧੂਰੀ ਹੈ (ਗਲਤੀ ਕੋਡ: ",
    "error_order2": "）, ਕਿਰਪਾ ਕਰਕੇ ਰਿਫ੍ਰੈਸ਼ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    "modify_email_title": "ਈਮੇਲ ਸੋਧੋ",
    "modify_email_info": "ਤੁਸੀਂ ਆਪਣੇ ਖਾਤੇ ਵਿੱਚ ਲੌਗਇਨ ਕਰਨ ਲਈ ਸੋਧੀ ਹੋਈ ਈਮੇਲ ਦੀ ਵਰਤੋਂ ਕਰ ਸਕਦੇ ਹੋ।",
    "images_per": "ਪ੍ਰਤੀ ਚਿੱਤਰ",
    "error_26101": "ਗਲਤੀ: 26101. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26102": "ਗਲਤੀ: 26102. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26103": "ਬੇਨਤੀ ਅਸਫ਼ਲ (ਗਲਤੀ ਕੋਡ:26103)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26104": "ਗਲਤੀ ਕੋਡ: 26104, ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26105": "ਗਲਤੀ ਕੋਡ: 26105, ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26106": "ਮਿਟਾਉਣਾ ਅਸਫਲ ਰਿਹਾ (ਗਲਤੀ ਕੋਡ: 26106)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26201": "ਗਲਤੀ: 26201. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26202": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ:26202)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26001": "ਗਲਤੀ: 26001. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26002": "ਗਲਤੀ: 26002. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26003": "ਗਲਤੀ: 26003. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26004": "ਗਲਤੀ: 26004. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_26005": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ:26005)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26006": "ਗਲਤੀ ਕੋਡ: 26006, ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_26008": "ਗਲਤੀ: 26008. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "go_to_the_home_page": "ਹੋਮ ਪੇਜ 'ਤੇ ਜਾਓ",
    "error_27101": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 27101)। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_27201": "ਗਲਤੀ ਕੋਡ: 27201, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_27202": "ਗਲਤੀ ਕੋਡ: 27202, ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_27203": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 27203)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_27204": "ਗਲਤ ਕੋਡ (ਗਲਤੀ ਕੋਡ: 27204)।",
    "error_27205": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 27205)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_27206": "ਬੇਨਤੀ ਅਸਫਲ ਹੋਈ (ਗਲਤੀ ਕੋਡ: 27206)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_27207": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 27207)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "no_history_found": "ਤੁਸੀਂ ਕੋਈ ਸਾਧਨ ਨਹੀਂ ਵਰਤਿਆ ਹੈ! <a href=\"/\">ਰਿਫ੍ਰੈਸ਼ ਕਰੋ</a> ਜਾਂ <a href=\"https://www.mindonmap.com/\">ਅਧਿਕਾਰਤ ਵੈੱਬਸਾਈਟ 'ਤੇ ਜਾਓ</a>",
    "error_25301": "ਗਲਤੀ: 25301. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_25302": "ਗਲਤੀ: 25302. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    "error_25303": "ਬੇਨਤੀ ਅਸਫਲ (ਗਲਤੀ ਕੋਡ: 25303)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_25304": "ਬੇਨਤੀ ਅਸਫ਼ਲ (ਗਲਤੀ ਕੋਡ: 25304)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_25305": "ਬੇਨਤੀ ਅਸਫ਼ਲ (ਗਲਤੀ ਕੋਡ: 25305)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "error_25306": "ਬੇਨਤੀ ਅਸਫ਼ਲ (ਗਲਤੀ ਕੋਡ: 25306)। ਕਿਰਪਾ ਕਰਕੇ ਇਸਨੂੰ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "image_upscaler_p": "ਵਾਟਰਮਾਰਕ ਤੋਂ ਬਿਨਾਂ ਚਿੱਤਰ ਡਾਊਨਲੋਡ ਕਰਨ ਦੇ ਲਾਭ:",
    "Available_for": "ਲਈ ਉਪਲਬਧ:",
    "credit_per": "ਪ੍ਰਤੀ HD ਚਿੱਤਰ %s ਕ੍ਰੈਡਿਟ",
    "still_valid": "ਖਰੀਦਿਆ ਪਲਾਨ ਅਜੇ ਵੀ ਵੈਧ ਹੈ",
    "credit": "ਕ੍ਰੈਡਿਟ",
    "pc_3rd_info": "ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕਰੋ। ਕਿਰਪਾ ਕਰਕੇ ਅਗਲੇਰੀ ਕਾਰਵਾਈ ਲਈ ਐਪਲੀਕੇਸ਼ਨ 'ਤੇ ਜਾਓ।",
    "use_online": "ਔਨਲਾਈਨ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਕਰੋ",
    "use_download": "ਡੈਸਕਟਾਪ ਪ੍ਰੋਗਰਾਮ ਦੀ ਵਰਤੋਂ ਕਰੋ",
    "use_immediately": "ਤੁਰੰਤ ਵਰਤੋ",
    "Use_in_browser": "ਬਰਾਊਜ਼ਰ ਵਿੱਚ ਵਰਤੋ",
    "win_desktop": "ਵਿੰਡੋਜ਼",
    "Mac_desktop": "ਮੈਕ",
    "credits_per": "{%} ਪ੍ਰਤੀ ਮਹੀਨਾ ਕ੍ਰੈਡਿਟ",
    "expire": "ਮਿਆਦ ਪੁੱਗਣ ਦਾ ਸਮਾਂ:",
    "viewDetails": "ਵੇਰਵੇ ਵੇਖੋ",
    "viewHistory": "ਇਤਿਹਾਸ ਦੇ ਲਾਭ ਵੇਖੋ >>",
    "viewDetailsInfo": "ਨੋਟਿਸ: ਜੇਕਰ ਗਾਹਕੀ ਲਾਭਾਂ ਨੂੰ ਮਿਆਦ ਪੁੱਗਣ ਤੋਂ ਬਾਅਦ 7 ਦਿਨਾਂ ਦੇ ਅੰਦਰ ਨਵਿਆਇਆ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਅਣਵਰਤੇ ਲਾਭ ਵਰਤੇ ਜਾ ਸਕਦੇ ਹਨ। ਨਾਲ ਹੀ, ਮਿਆਦ ਪੁੱਗਣ ਦਾ ਸਮਾਂ ਨਵੀਂ ਗਾਹਕੀ ਦੀ ਮਿਆਦ ਪੁੱਗਣ ਦੇ ਸਮੇਂ ਲਈ ਆਪਣੇ ਆਪ ਅਪਡੇਟ ਹੋ ਜਾਵੇਗਾ। ਜੇਕਰ ਮਿਆਦ ਪੁੱਗਣ ਦੇ 7 ਦਿਨਾਂ ਬਾਅਦ ਕੋਈ ਨਵੀਂ ਗਾਹਕੀ ਨਹੀਂ ਹੈ, ਤਾਂ ਸਾਰੇ ਮਿਆਦ ਪੁੱਗ ਚੁੱਕੇ ਲਾਭਾਂ ਨੂੰ ਕਲੀਅਰ ਕਰ ਦਿੱਤਾ ਜਾਵੇਗਾ।",
    "connect_account": "ਈਮੇਲ ਨੂੰ ਆਪਣੇ ਖਾਤੇ ਨਾਲ ਬੰਨ੍ਹੋ",
    "connect_account_info": "ਬਾਈਡਿੰਗ ਤੋਂ ਬਾਅਦ, ਤੁਸੀਂ ਇਸ ਈਮੇਲ ਪਤੇ ਨਾਲ ਲੌਗਇਨ ਕਰ ਸਕਦੇ ਹੋ।",
    "connect_now": "ਹੁਣੇ ਬੰਨ੍ਹੋ",
    "no_email_bind": "ਕੋਈ ਈਮੇਲ ਬੰਧਨ ਨਹੀਂ",
    "bind_email": "ਈਮੇਲ ਬੰਨ੍ਹੋ",
    "connect_your_email_placeholder": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਈਮੇਲ ਪਤਾ ਦਾਖਲ ਕਰੋ",
    "bind_an_email": "ਇੱਕ ਈਮੇਲ ਬੰਨ੍ਹੋ",
    "bind_info": "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕੀਤਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਲਾਭਾਂ ਨੂੰ ਸਰਗਰਮ ਕਰਨ ਲਈ ਆਪਣੇ ਖਾਤੇ ਵਿੱਚ ਇੱਕ ਈਮੇਲ ਪਾਓ।",
    "bind_later": "ਬਾਅਦ ਵਿੱਚ ਬੰਨ੍ਹੋ",
    "hi": "ਹੈਲੋ, %s!",
    "Personal_Information": "ਵਿਅਕਤੀਗਤ ਜਾਣਕਾਰੀ",
    "Access": "ਪਹੁੰਚ",
    "Subscription_Plan": "(ਗਾਹਕੀ ਯੋਜਨਾ)",
    "No_orders": "ਕੋਈ ਆਰਡਰ ਨਹੀਂ ਮਿਲੇ।",
    "No_data": "ਕੋਈ ਡਾਟਾ ਨਹੀਂ",
    "Featured_Products": "ਖਾਸ ਸਮਾਨ",
    "More_Products": "ਹੋਰ ਉਤਪਾਦ",
    "Free_Download": "ਮੁਫ਼ਤ ਡਾਊਨਲੋਡ",
    "Get_Started": "ਸ਼ੁਰੂ ਕਰੋ",
    "Subscribe": "ਸਬਸਕ੍ਰਾਈਬ ਕਰੋ",
    "Verified": "ਪ੍ਰਮਾਣਿਤ",
    "back_to_account_center": "ਖਾਤਾ ਕੇਂਦਰ 'ਤੇ ਵਾਪਸ ਜਾਓ",
    "success": "ਸਫਲਤਾ!",
    "successfully": "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਖਾਤਾ ਰਜਿਸਟਰ ਕੀਤਾ ਹੈ।",
    "Continue": "ਜਾਰੀ ਰੱਖੋ",
    "Already": "ਕੀ ਪਹਿਲਾਂ ਤੋਂ ਹੀ ਖਾਤਾ ਹੈ?",
    "loading_verification": "ਤਸਦੀਕ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰ ਰਿਹਾ ਹੈ...",
    "email_no_verification": "ਮਾਫ਼ ਕਰਨਾ, ਰਜਿਸਟਰਡ ਈਮੇਲ ਪਤੇ ਦੀ ਪੁਸ਼ਟੀ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਉਪਰੋਕਤ ਨਿਰਦੇਸ਼ਾਂ ਅਨੁਸਾਰ ਪੁਸ਼ਟੀਕਰਨ ਨੂੰ ਪੂਰਾ ਕਰੋ ਅਤੇ ਰਜਿਸਟਰੇਸ਼ਨ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ \"ਹੋ ਗਿਆ\" ਬਟਨ 'ਤੇ ਦੁਬਾਰਾ ਕਲਿੱਕ ਕਰੋ।",
    "will_expire_after": "ਤੋਂ ਬਾਅਦ ਮਿਆਦ ਪੁੱਗ ਜਾਵੇਗੀ",
    "hours": "ਘੰਟੇ",
  },
  "pl": {
    "overtime": "Kod błędu: {%}, spróbuj ponownie",
    "isnetwork": "Błąd Internetu. Sprawdź i spróbuj ponownie",
    "email_placeholder": "E-mail",
    "email_empty": "Proszę wpisać adres e-mail",
    "email_not_valid": "adres email jest nieprawidłowy",
    "email_not_valid_1": "Proszę wpisać swój adres e-mail",
    "email_not_valid_2": "E-mail jest nieprawidłowy, użyj innego adresu.",
    "email_not_valid_3": "Brak danych e-mail",
    "password_placeholder": "Hasło",
    "password_empty": "Proszę wprowadzić hasło",
    "password_not_valid": "Nieprawidłowe konto lub hasło",
    "password_not_valid_1": "Wymagane jest hasło dłuższe niż 8 znaków",
    "password_not_valid_2": "Proszę utworzyć hasło",
    "password_placeholder_1": "Stwórz swoje hasło",
    "password_placeholder_2": "Potwierdź swoje hasło",
    "password_placeholder_3": "Utwórz nowe hasło",
    "password_placeholder_4": "Potwierdź nowe hasło",
    "password_placeholder_5": "Wprowadź stare hasło",
    "copy_password_empty": "Proszę potwierdzić hasło",
    "copy_password_not_valid": "Proszę, potwierdź swoje hasło",
    "copy_passwords_inconsistent": "Potwierdzenie hasła nie jest zgodne",
    "code_empty": "Wprowadź kod weryfikacyjny",
    "code_not_valid": "nieprawidłowy kod weryfikacyjny",
    "code_placeholder": "Kod weryfikacyjny",
    "not_received_code": "Jeśli przez dłuższy czas na Twoją skrzynkę pocztową nie dotarł kod weryfikacyjny, prosimy o ponowne otrzymanie kodu weryfikacyjnego.",
    "get_first_code": "Najpierw uzyskaj kod weryfikacyjny.",
    "last_name_placeholder": "Proszę podać swoje nazwisko",
    "first_name_placeholder": "Proszę podaj swoje imię",
    "address_placeholder": "Proszę wpisać swój adres",
    "no_code_text": "Wysłaliśmy kod weryfikacyjny. Proszę wpisać swój kod. <span class='tips'>Nie otrzymałeś kodu?",
    "no_code_text_1": "1. Upewnij się, że adres e-mail jest prawidłowy i można na niego odbierać wiadomości e-mail.",
    "no_code_text_2": "2. Ponieważ wiadomość e-mail jest wysyłana przez system automatycznie, może zostać oznaczona jako spam lub wiadomość-śmieci. Sprawdź, czy wiadomość e-mail znajduje się w folderze Kosz.",
    "no_code_text_3": "3. Nie możesz rozwiązać swojego problemu? ",
    "no_code_text_3_span": "Następnie kliknij tutaj, aby się z nami skontaktować.",
    "order_no": "Nie kupiłeś żadnego produktu. Jeśli masz jakieś pytania, <a href=\"https://www.mindonmap.com/contact-us/\">skontaktuj się z nami</a>.",
    "error_24901": "Konto bieżące nie ma powiązanego adresu e-mail i nie może znaleźć zamówień. Proszę podłączyć e-mail.",
    "user_guide": "Podręcznik użytkownika >>",
    "download": "Pobierać",
    "order_number": "Numer zamówienia:",
    "Refund": "Refundacja",
    "Disabled": "Wyłączony",
    "Normal": "Normalna",
    "Modify": "Modyfikować",
    "Modify_1": "Modyfikuj >>",
    "Connect": "Łączyć",
    "unlink_success": "Odłącz pomyślnie",
    "connect_success": "Połącz się pomyślnie",
    "feedback_title": "Dziekuję za odpowiedź. Zostaw swój problem, a my odpowiemy w ciągu 24 godzin.",
    "feedback_thank_you": "Dziękujemy!<br />Twoja opinia została pomyślnie przesłana.",
    "feedback_email": "Wpisz tutaj swój adres e-mail!",
    "feedback_content": "Zostaw tutaj każdy problem lub sugestię, którą napotkałeś.",
    "feedback_submit": "Składać",
    "form_contents": "Nie wpisałeś żadnego opisu. Wprowadź go i prześlij ponownie.",
    "old_password": "Proszę wprowadzić stare hasło",
    "new_password": "Proszę utworzyć nowe hasło",
    "old_new_password": "Nowe hasło nie może być takie samo jak stare",
    "incorrect_password": "niepoprawne hasło",
    "delete_no": "Usuń teraz",
    "Caps": "Caps Lock jest włączony",
    "Get": "Dostawać",
    "Done": "Zrobione",
    "error_20001": "Błąd: 20001. Proszę zalogować się ponownie.",
    "error_20002": "Błąd: 20002. Proszę zalogować się ponownie.",
    "error_20003": "Błąd: 20003. Proszę zalogować się ponownie.",
    "error_20004": "Żądanie nie powiodło się (kod błędu: 20004). Spróbuj jeszcze raz.",
    "error_20005": "Sesja logowania wygasła (Błąd: 20005). Proszę, zaloguj się ponownie.",
    "error_20006": "Żądanie nie powiodło się (kod błędu: 20006). Spróbuj jeszcze raz.",
    "error_20007": "Sesja logowania wygasła (Błąd: 20007). Proszę, zaloguj się ponownie.",
    "error_20008": "Sesja logowania wygasła (Błąd: 20008). Proszę, zaloguj się ponownie.",
    "error_20009": "Sesja logowania wygasła (błąd: 20009). Proszę, zaloguj się ponownie.",
    "error_20101": "Podaj swój adres e-mail (kod błędu: 20101)",
    "error_20102": "Adres e-mail jest nieprawidłowy (kod błędu: 20102)",
    "error_20103": "Żądanie nie powiodło się (kod błędu: 20103). Spróbuj jeszcze raz",
    "error_20104": "Adres e-mail jest już używany, <a href=\"https://account.mindonmap.com/login/\">zaloguj się</a> lub zarejestruj nowy",
    "error_20105": "Żądanie nie powiodło się (kod błędu: 20105). Spróbuj jeszcze raz",
    "error_20106": "Nie udało się wysłać e-maila. Spróbuj ponownie",
    "error_20201": "Podaj swój adres e-mail (kod błędu: 20201)",
    "error_20202": "Proszę wprowadzić hasło (kod błędu: 20202)",
    "error_20203": "Proszę wprowadzić kod weryfikacyjny (kod błędu: 20203)",
    "error_20204": "E-mail jest nieprawidłowy (kod błędu: 20204)",
    "error_20205": "Wymagane jest hasło dłuższe niż 8 znaków (kod błędu: 20205)",
    "error_20206": "Żądanie nie powiodło się (kod błędu: 20206). Spróbuj jeszcze raz",
    "error_20207": "nieprawidłowy kod weryfikacyjny",
    "error_20208": "Żądanie nie powiodło się (kod błędu: 20208). Spróbuj jeszcze raz",
    "error_20209": "Żądanie nie powiodło się (kod błędu: 20209). Spróbuj jeszcze raz",
    "error_20301": "Podaj swój adres e-mail (kod błędu: 20301)",
    "error_20302": "Błąd: 20302. Prosimy o kontakt",
    "error_20303": "Adres e-mail jest nieprawidłowy (kod błędu: 20303)",
    "error_20304": "Żądanie nie powiodło się (kod błędu: 20304). Spróbuj jeszcze raz",
    "error_20305": "Konto nie istnieje. Wpisz ponownie lub najpierw <a href=\"https://account.mindonmap.com/register/\">Utwórz</a>.",
    "error_20306": "Nie ma jeszcze hasła. Użyj <a href=\"https://account.mindonmap.com/passwordless-login/\">logowania bez hasła</a> lub <a href=\"https://account.mindonmap.com/create-password/\"> ustaw hasło</a> i zaloguj się.",
    "error_20308": "Żądanie nie powiodło się (kod błędu: 20308). Spróbuj jeszcze raz",
    "error_20401": "Nie udało się wylogować (kod błędu: 20401). Spróbuj jeszcze raz",
    "error_20501": "Podaj swój adres e-mail (kod błędu: 20501)",
    "error_20502": "Adres e-mail jest nieprawidłowy (kod błędu: 20502)",
    "error_20503": "Żądanie nie powiodło się (kod błędu: 20503). Spróbuj jeszcze raz",
    "error_20504": "Nie udało się wysłać e-maila. Spróbuj jeszcze raz.",
    "error_20601": "Podaj swój adres e-mail (kod błędu: 20601)",
    "error_20602": "Proszę wprowadzić kod weryfikacyjny (kod błędu: 20602)",
    "error_20603": "Adres e-mail jest nieprawidłowy (kod błędu: 20603)",
    "error_20604": "Żądanie nie powiodło się (kod błędu: 20604). Spróbuj jeszcze raz",
    "error_20606": "Żądanie nie powiodło się (kod błędu: 20606). Spróbuj jeszcze raz",
    "error_20607": "Żądanie nie powiodło się (kod błędu: 20607). Spróbuj jeszcze raz",
    "error_20608": "Żądanie nie powiodło się (kod błędu: 20608). Spróbuj jeszcze raz",
    "error_20701": "Podaj swój adres e-mail (kod błędu: 20701)",
    "error_20702": "E-mail jest nieprawidłowy (kod błędu: 20702)",
    "error_20703": "Żądanie nie powiodło się (kod błędu: 20703). Spróbuj jeszcze raz",
    "error_20704": "Konto nie istnieje. Wpisz ponownie lub najpierw <a href=\"https://account.mindonmap.com/register/\">Utwórz</a>.",
    "error_20705": "Żądanie nie powiodło się (kod błędu: 20705). Spróbuj jeszcze raz",
    "error_20706": "Nie udało się wysłać e-maila. Spróbuj jeszcze raz",
    "error_20801": "Podaj swój adres e-mail (kod błędu: 20801)",
    "error_20802": "Błąd: 20802. Prosimy o kontakt",
    "error_20803": "Proszę wprowadzić kod weryfikacyjny (kod błędu: 20803)",
    "error_20804": "E-mail jest nieprawidłowy (kod błędu: 20804)",
    "error_20805": "Wymagane jest hasło dłuższe niż 8 znaków (kod błędu: 20805)",
    "error_20806": "Żądanie nie powiodło się (kod błędu: 20806). Spróbuj jeszcze raz",
    "error_20808": "Żądanie nie powiodło się (kod błędu: 20808). Spróbuj jeszcze raz",
    "error_20901": "Żądanie nie powiodło się (kod błędu: 20901). Spróbuj jeszcze raz",
    "error_20902": "Żądanie nie powiodło się (kod błędu: 20902). Spróbuj jeszcze raz",
    "error_21000": "Zmiany zostały zapisane",
    "error_21001": "Nie przesłano żadnych informacji (kod błędu: 21001)",
    "error_21002": "Żądanie nie powiodło się (kod błędu: 21002). Spróbuj jeszcze raz",
    "error_21101": "Podaj swój adres e-mail (kod błędu: 21101)",
    "error_21102": "Adres e-mail jest nieprawidłowy (kod błędu: 21102)",
    "error_21103": "Żądanie nie powiodło się (kod błędu: 21103), spróbuj ponownie",
    "error_21104": "Adres e-mail jest już połączony, użyj nowego",
    "error_21105": "Żądanie nie powiodło się (kod błędu: 21105), spróbuj ponownie",
    "error_21106": "Nie udało się wysłać e-maila. Spróbuj jeszcze raz",
    "error_21201": "Podaj swój adres e-mail (kod błędu: 21201)",
    "error_21202": "Proszę wprowadzić kod weryfikacyjny (kod błędu: 21202)",
    "error_21203": "E-mail jest nieprawidłowy (kod błędu: 21203)",
    "error_21204": "Błąd: 21204. Prosimy o kontakt",
    "error_21205": "Błąd: 21205. Prosimy o kontakt",
    "error_21206": "Wymagane jest hasło dłuższe niż 8 znaków (kod błędu: 21206)",
    "error_21207": "Żądanie nie powiodło się (kod błędu: 21207). Spróbuj jeszcze raz",
    "error_21209": "Żądanie nie powiodło się (kod błędu: 21209). Spróbuj jeszcze raz",
    "error_21301": "Proszę wprowadzić stare hasło (kod błędu: 21301)",
    "error_21302": "Utwórz nowe hasło (kod błędu: 21302)",
    "error_21303": "Nowe hasło nie może być takie samo jak stare. (Błąd: 21303)",
    "error_21304": "Wymagane jest hasło dłuższe niż 8 znaków (kod błędu: 21304)",
    "error_21306": "Żądanie nie powiodło się (kod błędu: 21306). Spróbuj jeszcze raz",
    "error_21402": "Żądanie nie powiodło się (kod błędu: 21402). Spróbuj jeszcze raz",
    "error_21403": "Nie udało się wysłać kodu weryfikacyjnego. Proszę o ponowne przesłanie",
    "error_21500": "Konto zostało usunięte",
    "error_21501": "Proszę wprowadzić kod weryfikacyjny (kod błędu: 21501)",
    "error_21502": "Sesja logowania wygasła (błąd: 21502). Proszę, zaloguj się ponownie.",
    "error_21503": "Żądanie nie powiodło się (kod błędu: 21503). Spróbuj jeszcze raz",
    "error_21505": "Żądanie nie powiodło się (kod błędu: 21505), spróbuj ponownie",
    "error_21601": "Błąd: 20601. Prosimy o kontakt",
    "error_21602": "Nieprawidłowa weryfikacja (błąd: 20602). Spróbuj jeszcze raz.",
    "error_21603": "Błąd: 20603. Spróbuj ponownie",
    "error_21604": "Żądanie nie powiodło się (kod błędu: 21604). Spróbuj jeszcze raz",
    "error_21606": "Żądanie nie powiodło się (kod błędu: 21606). Spróbuj jeszcze raz",
    "error_21611": "Żądanie nie powiodło się (kod błędu: 21611). Spróbuj jeszcze raz",
    "error_21801": "Błąd: 21801. Prosimy o kontakt",
    "error_21802": "Żądanie nie powiodło się (błąd: 21802). Spróbuj jeszcze raz",
    "error_21803": "Błąd: 21803. Spróbuj ponownie",
    "error_21804": "Żądanie nie powiodło się (kod błędu: 21804). Spróbuj jeszcze raz",
    "error_21806": "Błąd: 21806. Spróbuj ponownie",
    "error_21807": "Błąd: 21807. Prosimy o kontakt",
    "error_21808": "Błąd: 21808. Prosimy o kontakt",
    "error_21809": "Błąd: 21809. Prosimy o kontakt",
    "error_21810": "Błąd: 21810. Prosimy o kontakt",
    "error_21811": "Błąd: 21811. Prosimy o kontakt",
    "error_21812": "Błąd: 21812. Prosimy o kontakt",
    "error_21813": "Żądanie nie powiodło się (kod błędu: 21813). Spróbuj jeszcze raz",
    "error_21814": "Błąd: 21814. Prosimy o kontakt",
    "error_21815": "Żądanie nie powiodło się (kod błędu: 21815). Spróbuj jeszcze raz",
    "error_21816": "Błąd: 21816. Prosimy o kontakt",
    "error_21817": "Błąd: 21817. Prosimy o kontakt",
    "error_21818": "Błąd: 21818. Prosimy o kontakt",
    "error_21819": "Żądanie nie powiodło się (kod błędu: 21819). Spróbuj jeszcze raz",
    "error_21820": "Błąd: 21820. Prosimy o kontakt",
    "error_21821": "Błąd: 21821. Prosimy o kontakt",
    "error_21822": "Błąd: 21822. Prosimy o kontakt",
    "error_21823": "Żądanie nie powiodło się (kod błędu: 21823). Spróbuj jeszcze raz",
    "error_21824": "Żądanie nie powiodło się (kod błędu: 21824). Spróbuj jeszcze raz",
    "error_21825": "Żądanie nie powiodło się (kod błędu: 21825). Spróbuj jeszcze raz",
    "error_21826": "Żądanie nie powiodło się (kod błędu: 21826). Spróbuj jeszcze raz",
    "error_21901": "Błąd: 21901. Prosimy o kontakt",
    "error_21902": "Żądanie nie powiodło się (kod błędu: 21902). Spróbuj jeszcze raz",
    "error_21903": "Status konta uległ zmianie (kod błędu: 21903), odśwież stronę i spróbuj ponownie",
    "error_21904": "Błąd: 21904. Spróbuj ponownie",
    "error_21905": "Błąd: 21905. Spróbuj ponownie",
    "error_21906": "Żądanie nie powiodło się (kod błędu: 21906). Spróbuj jeszcze raz",
    "error_21907": "Konto Google zostało połączone z innym kontem",
    "error_21908": "Żądanie nie powiodło się (kod błędu: 21908). Spróbuj jeszcze raz",
    "error_22001": "Żądanie nie powiodło się (kod błędu: 22001). Spróbuj jeszcze raz",
    "error_22002": "Odłączenie nie powiodło się bez dodatkowego logowania",
    "error_22003": "Żądanie nie powiodło się (kod błędu: 22003). Spróbuj jeszcze raz",
    "error_22101": "Błąd: 22101. Skontaktuj się z nami",
    "error_22102": "Status konta uległ zmianie (kod błędu: 22102), odśwież stronę i spróbuj ponownie",
    "error_22103": "Żądanie nie powiodło się (kod błędu: 22103). Spróbuj jeszcze raz",
    "error_22104": "Status konta uległ zmianie (kod błędu: 22104), odśwież stronę i spróbuj ponownie",
    "error_22105": "Błąd: 22105. Spróbuj ponownie",
    "error_22106": "Błąd: 22106. Spróbuj ponownie",
    "error_22107": "Błąd: 22107. Prosimy o kontakt",
    "error_22108": "Żądanie nie powiodło się (kod błędu: 22108). Spróbuj jeszcze raz",
    "error_22201": "Błąd: 22201. Prosimy o kontakt",
    "error_22202": "Nieprawidłowa weryfikacja (błąd: 22202). Spróbuj jeszcze raz.",
    "error_22203": "Błąd: 22203. Spróbuj ponownie”",
    "error_22204": "Żądanie nie powiodło się (kod błędu: 22204). Spróbuj jeszcze raz",
    "error_22206": "Żądanie nie powiodło się (kod błędu: 22206). Spróbuj jeszcze raz",
    "error_22401": "Błąd: 22401. Prosimy o kontakt",
    "error_22402": "Nieprawidłowa weryfikacja (błąd: 22402). Spróbuj jeszcze raz.",
    "error_22403": "Błąd: 22403. Spróbuj ponownie",
    "error_22404": "Żądanie nie powiodło się (kod błędu: 22404). Spróbuj jeszcze raz",
    "error_22405": "Konto na Facebooku zostało połączone z innym adresem e-mail",
    "error_22406": "Błąd: 22406. Spróbuj ponownie",
    "error_22407": "Błąd: 22407. Prosimy o kontakt",
    "error_22408": "Błąd: 22408. Prosimy o kontakt",
    "error_22409": "Błąd: 22409. Prosimy o kontakt",
    "error_22410": "Błąd: 224010. Skontaktuj się z nami",
    "error_22411": "Błąd: 224011. Skontaktuj się z nami",
    "error_22412": "Błąd: 224012. Skontaktuj się z nami",
    "error_22413": "Błąd: 22413. Prosimy o kontakt",
    "error_22414": "Żądanie nie powiodło się (kod błędu: 22414). Spróbuj jeszcze raz",
    "error_22415": "Błąd: 22415. Prosimy o kontakt",
    "error_22416": "Błąd: 22416. Prosimy o kontakt",
    "error_22417": "Błąd: 22417. Prosimy o kontakt",
    "error_22418": "Żądanie nie powiodło się (kod błędu: 22418). Spróbuj jeszcze raz",
    "error_22419": "Błąd: 22419. Prosimy o kontakt",
    "error_22420": "Błąd: 22420. Skontaktuj się z nami",
    "error_22421": "Błąd: 22421. Skontaktuj się z nami",
    "error_22422": "Żądanie nie powiodło się (kod błędu: 22422). Spróbuj jeszcze raz",
    "error_22423": "Żądanie nie powiodło się (kod błędu: 22423). Spróbuj jeszcze raz",
    "error_22424": "Żądanie nie powiodło się (kod błędu: 22424). Spróbuj jeszcze raz",
    "error_22425": "Żądanie nie powiodło się (kod błędu: 22425). Spróbuj jeszcze raz",
    "error_20098": "Kod błędu: 20098. Jeśli przeglądasz w trybie prywatnym, przełącz się do trybu normalnego i spróbuj ponownie.",
    "error_22298": "Żądanie Google nie powiodło się (błąd: 22298). Spróbuj jeszcze raz.",
    "error_22498": "Żądanie dotyczące Facebooka nie powiodło się (błąd: 22498). Spróbuj jeszcze raz.",
    "error_24902": "Żądanie nie powiodło się (kod błędu: 24902). Spróbuj jeszcze raz",
    "error_24903": "Żądanie nie powiodło się (kod błędu: 24903). Spróbuj jeszcze raz",
    "error_24904": "Żądanie nie powiodło się (kod błędu: 24904). Spróbuj jeszcze raz",
    "error_24905": "Żądanie nie powiodło się (kod błędu: 24905). Spróbuj jeszcze raz",
    "login_title": "Zaloguj się do MindOnMap",
    "log_in": "Zaloguj sie",
    "no_account": "Brak konta?",
    "create_it": "Stwórz To",
    "or_log_in_with": "Lub zaloguj się za pomocą",
    "passwordless_login": "Logowanie bez hasła",
    "log_in_done": "Zaloguj się Gotowe",
    "three_rd_account_connect_info": "Gratulacje! Zalogowałeś się pomyślnie. Teraz możesz podłączyć jedno konto e-mail, które będzie używane do logowania się w przyszłości.",
    "see_my_account": "Zobacz moje konto",
    "three_rd_login_merge_account": "Adres e-mail konta strony trzeciej został zarejestrowany. Czy chcesz się połączyć i zalogować bezpośrednio przy użyciu tego adresu e-mail?",
    "connect_log_in": "Połącz i zaloguj się",
    "create_an_account": "Utwórz konto",
    "back_to_log_in": "Powrót do logowania",
    "create_password": "Stwórz hasło",
    "create_now": "Stwórz teraz",
    "password_login_subtitle": "Logowanie bez hasła za pomocą poczty elektronicznej",
    "account_login": "Login do konta",
    "rights": "Tworząc to konto, zgadzasz się z <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Warunkami korzystania z usługi</a> i <a href=\"https://www.mindonmap.com/privacy-policy/\">Polityka prywatności</a>",
    "passwordless_login_done": "Logowanie bez hasła zakończone",
    "passwordless_login_info": "Gratulacje, pomyślnie zakończyłeś logowanie bez hasła. Możesz utworzyć hasło do tego konta i logować się przy użyciu konta i hasła w przyszłości. <a href=\"/create-password\" style=\"display:initial;\">Utwórz teraz</a>",
    "sign_up": "Zapisać się",
    "register_info": "Utwórz swoje konto",
    "reset_now": "Zresetuj teraz",
    "forgot_password": "Zapomniałeś hasła",
    "reset_password_subtitle": "Użyj adresu e-mail swojego konta, aby zresetować hasło",
    "plan_products": "Plany i produkty",
    "nick_name": "Nazwa użytkownika:",
    "email": "E-mail:",
    "my_products": "Moje produkty",
    "my_orders": "Moje zamówienia",
    "unlink": "Odczepić",
    "link": "Połączyć",
    "connected_accounts": "Połączone konta",
    "last_name": "Nazwisko:",
    "first_name": "Imię:",
    "Gender": "Płeć:",
    "Birth": "Narodziny:",
    "Month": "Miesiąc",
    "Year": "Rok",
    "Country_Region": "Kraj/region:",
    "Address": "Adres:",
    "Save": "Ratować",
    "Date": "Data",
    "Male": "Mężczyzna",
    "Female": "Kobieta",
    "Unspecified": "Nieokreślony",
    "Security": "Bezpieczeństwo",
    "change_password": "Zmień hasło",
    "change_now": "Zmień teraz",
    "connect_email": "Połącz e-mail",
    "delete_account": "Usuń konto",
    "delete_account_info": "Kiedy Twoje konto zostanie usunięte, wszystkie dane powiązane z Twoim kontem na Moim koncie zostaną trwale usunięte i możesz nie mieć możliwości ich odzyskania. Radzimy zachować ostrożność.",
    "Delete": "Usuwać",
    "Logout": "Wyloguj",
    "my_profile": "Mój profil",
    "guides_faqs": "Przewodniki i często zadawane pytania",
    "More": "Więcej",
    "guides": "Przewodniki",
    "register": "rejestr",
    "hot_faq": "Gorące pytania",
    "Contents": "Zawartość:",
    "contact_us": "Skontaktuj się z nami >>",
    "plan": "Plan",
    "unregistered": "Niezarejestrowany",
    "buy_more": "Kup więcej",
    "buy_again": "Kup znowu",
    "buy_now": "Kup Teraz",
    "free_no_limit": "Bezpłatnie i bez ograniczeń",
    "expired": "Wygasły",
    "lifetime": "Dożywotni",
    "remain": "Pozostać",
    "day_s": "Dni)",
    "error_24801": "Żądanie nie powiodło się (kod błędu: 24801). Spróbuj jeszcze raz",
    "no_app_found": "Nie znaleziono aplikacji! <a href=\"/\">Odśwież</a> lub <a href=\"https://www.mindonmap.com/\">Przejdź na oficjalną stronę internetową</a>",
    "get_more": "Uzyskaj więcej >>",
    "edit_photo": "Edytuj zdjęcie",
    "select_photo": "Wybierz zdjęcie",
    "change_photo": "Zmień zdjęcie",
    "cancel": "Anulować",
    "hide_password": "Ukryj hasło",
    "show_password": "Pokaż hasło",
    "zoom_in": "Zbliżenie",
    "zoom_out": "Pomniejsz",
    "rotate": "Obracać się",
    "horizontal_flip": "Odwrócenie poziome",
    "vertical_flip": "Przewrót w pionie",
    "country": "Kraj",
    "country_1": "Wybierz swój kraj/region",
    "country_2": "Wyspa Aaland",
    "country_3": "Afganistan",
    "country_4": "Albania",
    "country_5": "Algieria",
    "country_6": "Samoa Amerykańskie",
    "country_7": "Andora",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktyda",
    "country_11": "Antigua i Barbuda",
    "country_12": "Argentyna",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbejdżan",
    "country_18": "Bahrajn",
    "country_19": "Bangladesz",
    "country_20": "Barbados",
    "country_21": "Białoruś",
    "country_22": "Belgia",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermudy",
    "country_26": "Bhutan",
    "country_27": "Boliwia",
    "country_28": "Bośnia i Hercegowina",
    "country_29": "Botswana",
    "country_30": "Wyspa Bouveta",
    "country_31": "Brazylia",
    "country_32": "Brytyjskie Terytorium Oceanu Indyjskiego",
    "country_33": "Brytyjskie Wyspy Dziewicze",
    "country_34": "Brunei",
    "country_35": "Bułgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodża",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Wyspy Zielonego Przylądka",
    "country_42": "Karaiby Holandia",
    "country_43": "Kajmany",
    "country_44": "Republika Środkowoafrykańska",
    "country_45": "Czad",
    "country_46": "Chile",
    "country_47": "Chiny",
    "country_48": "Wyspa Bożego Narodzenia",
    "country_49": "Wyspy Kokosowe (Keelinga).",
    "country_50": "Kolumbia",
    "country_51": "Wyspy Cooka",
    "country_52": "Kostaryka",
    "country_53": "Wybrzeże Kości Słoniowej",
    "country_54": "Chorwacja",
    "country_55": "Kuba",
    "country_56": "Cypr",
    "country_57": "Republika Czeska",
    "country_58": "Demokratyczna Republika Konga",
    "country_59": "Dania",
    "country_60": "Dżibuti",
    "country_61": "Dominika",
    "country_62": "Republika Dominikany",
    "country_63": "Ekwador",
    "country_64": "Egipt",
    "country_65": "Salwador",
    "country_66": "Gwinea Równikowa",
    "country_67": "Erytrea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Falklandy",
    "country_71": "Wyspy Owcze",
    "country_72": "Sfederowane Stany Mikronezji",
    "country_73": "Fidżi",
    "country_74": "Finlandia",
    "country_75": "Francja",
    "country_76": "Gujana Francuska",
    "country_77": "Polinezja Francuska",
    "country_78": "Francuskie Terytoria Południowe",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Gruzja",
    "country_82": "Niemcy",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Wielka Brytania (Wielka Brytania; Anglia)",
    "country_86": "Grecja",
    "country_87": "Grenlandia",
    "country_88": "Grenada",
    "country_89": "Gwadelupa",
    "country_90": "Guam",
    "country_91": "Gwatemala",
    "country_92": "Guernsey",
    "country_93": "Gwinea",
    "country_94": "Gwinea Bissau",
    "country_95": "Gujana",
    "country_96": "Haiti",
    "country_97": "Wyspy Heard i McDonalda",
    "country_98": "Honduras",
    "country_99": "Hongkong",
    "country_100": "Węgry",
    "country_101": "Islandia",
    "country_102": "Indie",
    "country_103": "Indonezja",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irlandia",
    "country_107": "Wyspa Man",
    "country_108": "Izrael",
    "country_109": "Włochy",
    "country_110": "Jamajka",
    "country_111": "Japonia",
    "country_112": "Golf",
    "country_113": "Jordania",
    "country_114": "Kazachstan",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuwejt",
    "country_118": "Kirgistan",
    "country_119": "Laos",
    "country_120": "Łotwa",
    "country_121": "Liban",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Litwa",
    "country_127": "Luksemburg",
    "country_128": "Makao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malezja",
    "country_132": "Malediwy",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Wyspy Marshalla",
    "country_136": "Martynika",
    "country_137": "Mauretania",
    "country_138": "Mauritius",
    "country_139": "Majotta",
    "country_140": "Meksyk",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Mongolia",
    "country_144": "Czarnogóra",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambik",
    "country_148": "Birma (Birma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Holandia",
    "country_153": "Nowa Kaledonia",
    "country_154": "Nowa Zelandia",
    "country_155": "Nikaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Nie",
    "country_159": "Wyspa Norfolk",
    "country_160": "Korea Północna",
    "country_161": "Mariany Północne",
    "country_162": "Norwegia",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "terytoria palestyńskie",
    "country_167": "Panama",
    "country_168": "Papua Nowa Gwinea",
    "country_169": "Paragwaj",
    "country_170": "Peru",
    "country_171": "Wyspy Pitcairn",
    "country_172": "Polska",
    "country_173": "Portugalia",
    "country_174": "Portoryko",
    "country_175": "Katar",
    "country_176": "Republika Macedonii (BJRM)",
    "country_177": "Republika Konga",
    "country_178": "Zjazd",
    "country_179": "Rumunia",
    "country_180": "Federacja Rosyjska",
    "country_181": "Rwanda",
    "country_182": "Święty Barthélemy",
    "country_183": "Saint Martin (Francja)",
    "country_184": "Saint-Pierre i Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Wyspy Świętego Tomasza i Książęca",
    "country_188": "Arabia Saudyjska",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seszele",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Słowacja",
    "country_195": "Słowenia",
    "country_196": "Wyspy Salomona",
    "country_197": "Somali",
    "country_198": "Afryka Południowa",
    "country_199": "Południowa Georgia i wyspa South Sandwich",
    "country_200": "Korea Południowa",
    "country_201": "Południowy Sudan",
    "country_202": "Hiszpania",
    "country_203": "Sri Lanka",
    "country_204": "Św. Helena i Zależności",
    "country_205": "St. Kitts i Nevis",
    "country_206": "Św. Łucja",
    "country_207": "Saint Vincent i Grenadyny",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Suazi",
    "country_211": "Szwecja",
    "country_212": "Szwajcaria",
    "country_213": "Syria",
    "country_214": "Tajwan",
    "country_215": "Tadżykistan",
    "country_216": "Tanzania",
    "country_217": "Szablon:Dane krajowe SJM Svalbard",
    "country_218": "Tajlandia",
    "country_219": "Bahamy",
    "country_220": "Komory",
    "country_221": "Filipiny",
    "country_222": "Timor Wschodni (Timor Wschodni)",
    "country_223": "Iść",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trynidad i Tobago",
    "country_227": "Tunezja",
    "country_228": "Indyk",
    "country_229": "Turkmenia",
    "country_230": "Turks i Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Zjednoczone Emiraty Arabskie",
    "country_235": "Stany Zjednoczone Dalekie Wyspy Mniejsze",
    "country_236": "Stany Zjednoczone Ameryki (USA)",
    "country_237": "Wyspy Dziewicze Stanów Zjednoczonych",
    "country_238": "Urugwaj",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Watykan (Stolica Apostolska)",
    "country_242": "Wenezuela",
    "country_243": "Wietnam",
    "country_244": "Wallis i Futuna",
    "country_245": "Sahara Zachodnia",
    "country_246": "Jemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Zaloguj się za pomocą Google",
    "State": "Państwo",
    "Activation_code": "Kod aktywacyjny",
    "Question": "Lista wszystkich aplikacji, do których się zalogowałeś",
    "Copy_complete": "Kopia ukończona",
    "footer": "Prawa autorskie © 2024 MindOnMap Studio. Wszelkie prawa zastrzeżone.",
    "change_password_success": "Hasło zostało pomyślnie zmienione",
    "successful_login_title": "Sukces logowania",
    "product_page": "Strona produktu >>",
    "successful_login_info": "Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces. Bieżąca strona zostanie automatycznie zamknięta za 5 sekund. Jeśli automatyczne zamknięcie lub zamknięcie poprzez kliknięcie przycisku „Gotowe” nie powiedzie się, zamknij tę kartę bezpośrednio.",
    "successful_login_info_firefox": "Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces.",
    "my_account": "Moje konto",
    "my_history": "Moja historia",
    "remove_watermark": "Usuń znak wodny",
    "no_history": "Brak historii",
    "history_all": "Zaznacz wszystko",
    "history_open": "otwarty",
    "history_down": "Pobierać",
    "history_delete": "Usuwać",
    "history_clear": "Usuń nieprawidłowe",
    "images": "obrazy",
    "use_this_function": "Użyj tego produktu >>",
    "hd_downloading": "Korzyści z pobierania oryginalnych obrazów HD:",
    "lifetimeRemaining": "Pozostały czas życia",
    "Remaining": "Pozostały",
    "email_verification": "Weryfikacja adresu e-mail",
    "email_verification_info": "Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class=\"email\"></span> i prosimy o dokończenie weryfikacji. Po weryfikacji korzyści zostaną automatycznie zsynchronizowane.",
    "wrong_email": "Zły adres email?",
    "click_here_to_modify": "Kliknij tutaj, aby zmodyfikować",
    "get_more_help": "Uzyskać dodatkową pomoc?",
    "click_here": "Kliknij tutaj",
    "email_verification_info_success": "Gratulujemy weryfikacji konta e-mail.",
    "email_verification_info_error": "Weryfikacja nie powiodła się z powodu wygasłego linku.",
    "registration_succeeded": "Rejestracja powiodła się",
    "registration_succeeded_info_1": "Gratulacje! Zarejestrowałeś się pomyślnie. Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class=\"email\"></span> i dokończ weryfikację, aby móc korzystać z zalet tej wiadomości e-mail.",
    "registration_succeeded_info_2": "Kliknij „Gotowe”, aby powrócić do strony głównej i korzystać z tego produktu.",
    "registration_succeeded_info_3": "Kliknij „Gotowe”, aby zamknąć bieżącą stronę i powrócić do strony głównej w celu wykonania następujących operacji. Jeśli zamknięcie nie powiedzie się, zamknij kartę ręcznie.",
    "verify_email": "Zweryfikuj email",
    "registered_email_not_verified": "Zarejestrowany adres e-mail nie został zweryfikowany, prosimy o natychmiastową weryfikację.",
    "email_verification_time_1": "Nie otrzymałeś e-maila weryfikacyjnego?",
    "email_verification_time_2": "Po",
    "email_verification_time_3": "Kliknij tutaj, aby wysłać go ponownie",
    "error_26301": "Kod błędu: 26301, skontaktuj się z nami",
    "error_26302": "Kod błędu: 26302, skontaktuj się z nami",
    "error_26303": "Błąd formatu wiadomości e-mail (kod błędu: 26303). Wprowadź go ponownie",
    "error_26304": "Zalecane jest hasło dłuższe niż 8 znaków (kod błędu: 26304)",
    "error_26305": "Żądanie żądania nie powiodło się (kod błędu: 26305). Spróbuj ponownie",
    "error_26306": "E-mail został zarejestrowany, <a href=\"https://account.mindonmap.com/login/\">przejdź do logowania</a>",
    "error_26307": "Żądanie żądania nie powiodło się (kod błędu: 26307). Spróbuj ponownie",
    "error_26308": "Żądanie żądania nie powiodło się (kod błędu: 26308). Spróbuj ponownie",
    "error_26401": "Kod błędu: 26401, spróbuj ponownie",
    "error_26402": "E-mail został zweryfikowany (kod błędu: 26402), spróbuj ponownie",
    "error_26403": "Żądanie żądania nie powiodło się (kod błędu: 26403). Spróbuj ponownie",
    "error_26404": "Żądanie nie powiodło się (kod błędu: 26404). Spróbuj ponownie",
    "error_26501": "Kod błędu: 26501, skontaktuj się z nami",
    "error_26502": "Kod błędu: 26502, skontaktuj się z nami",
    "error_26503": "Błąd formatu wiadomości e-mail (kod błędu: 26503). Wprowadź go ponownie",
    "error_26504": "Żądanie żądania nie powiodło się (kod błędu: 26504). Spróbuj ponownie",
    "error_26505": "Adres e-mail nie został zarejestrowany. <a href=\"https://account.mindonmap.com/register/\">najpierw go zarejestruj</a>",
    "error_26506": "E-mail został zweryfikowany.",
    "error_26507": "Żądanie żądania nie powiodło się (kod błędu: 26507). Spróbuj ponownie",
    "error_26508": "Weryfikacja nie powiodła się (kod błędu: 26508), spróbuj ponownie",
    "error_26509": "Żądanie nie powiodło się (kod błędu: 26509), spróbuj ponownie",
    "error_26510": "Kod błędu: 26510, skontaktuj się z nami",
    "error_26601": "Kod błędu: 26601, skontaktuj się z nami",
    "error_26602": "Kod błędu: 26602, skontaktuj się z nami",
    "error_26603": "Żądanie żądania nie powiodło się (kod błędu: 26603). Spróbuj ponownie",
    "error_26604": "Kod błędu: 26604, skontaktuj się z nami",
    "error_26605": "Kod błędu: 26605, skontaktuj się z nami",
    "error_26701": "Kod błędu: 26701, skontaktuj się z nami",
    "error_26702": "Reuqest nie powiódł się (kod błędu: 26702). Spróbuj ponownie",
    "error_26703": "Kod błędu: 26703, skontaktuj się z nami",
    "error_26704": "Kod błędu: 26704, skontaktuj się z nami",
    "error_26705": "Poczekaj na zalogowanie (kod błędu: 26705). Spróbuj ponownie",
    "no_cookie": "Włączyłeś w swojej przeglądarce funkcję Blokuj wszystkie pliki cookie, więc nie możesz się zalogować. Przejdź do Ustawień i zaznacz pole Zezwalaj na wszystkie pliki cookie.",
    "error_26801": "Kod błędu: 26801, skontaktuj się z nami",
    "error_26802": "Kod błędu: 26802, skontaktuj się z nami",
    "error_26803": "Żądanie nie powiodło się (kod błędu: 26803). Spróbuj ponownie",
    "error_26804": "Żądanie nie powiodło się (kod błędu: 26804). Spróbuj ponownie",
    "error_order": "Żądanie nie powiodło się (kod błędu: 27098), spróbuj ponownie!",
    "error_order1": "Zapytanie o zamówienie jest niekompletne (kod błędu: ",
    "error_order2": "), odśwież i spróbuj ponownie.",
    "modify_email_title": "Zmodyfikuj adres e-mail",
    "modify_email_info": "Możesz użyć zmodyfikowanego adresu e-mail, aby zalogować się na swoje konto.",
    "images_per": "Obrazy za",
    "error_26101": "Błąd: 26101. Prosimy o kontakt",
    "error_26102": "Błąd: 26102. Skontaktuj się z nami",
    "error_26103": "Żądanie nie powiodło się (kod błędu: 26103). Spróbuj ponownie",
    "error_26104": "Kod błędu: 26104, spróbuj ponownie",
    "error_26105": "Kod błędu: 26105, spróbuj ponownie",
    "error_26106": "Usuwanie nie powiodło się (kod błędu: 26106). Spróbuj jeszcze raz",
    "error_26201": "Błąd: 26201. Prosimy o kontakt",
    "error_26202": "Żądanie nie powiodło się (kod błędu: 26202). Spróbuj ponownie",
    "error_26001": "Błąd: 26001. Prosimy o kontakt",
    "error_26002": "Błąd: 26002. Prosimy o kontakt",
    "error_26003": "Błąd: 26003. Prosimy o kontakt",
    "error_26004": "Błąd: 26004. Prosimy o kontakt",
    "error_26005": "Żądanie nie powiodło się (kod błędu: 26005). Spróbuj ponownie",
    "error_26006": "Kod błędu: 26006, spróbuj ponownie",
    "error_26008": "Błąd: 26008. Prosimy o kontakt",
    "go_to_the_home_page": "Przejdź do strony głównej",
    "error_27101": "Żądanie nie powiodło się (kod błędu: 27101). Spróbuj ponownie",
    "error_27201": "Kod błędu: 27201, skontaktuj się z nami",
    "error_27202": "Kod błędu: 27202. Spróbuj ponownie",
    "error_27203": "Żądanie nie powiodło się (kod błędu: 27203). Spróbuj ponownie",
    "error_27204": "Nieprawidłowy kod (kod błędu: 27204).",
    "error_27205": "Żądanie nie powiodło się (kod błędu: 27205). Spróbuj ponownie",
    "error_27206": "Żądanie nie powiodło się (kod błędu: 27206). Spróbuj ponownie",
    "error_27207": "Żądanie nie powiodło się (kod błędu: 27207). Spróbuj ponownie",
    "no_history_found": "Nie użyłeś żadnego narzędzia! <a href=\"/\">Odśwież</a> lub <a href=\"https://www.mindonmap.com/\">Przejdź na oficjalną stronę internetową</a>",
    "error_25301": "Błąd: 25301. Prosimy o kontakt",
    "error_25302": "Błąd: 25302. Prosimy o kontakt",
    "error_25303": "Żądanie nie powiodło się (kod błędu: 25303). Spróbuj jeszcze raz",
    "error_25304": "Żądanie nie powiodło się (kod błędu: 25304). Spróbuj jeszcze raz",
    "error_25305": "Żądanie nie powiodło się (kod błędu: 25305). Spróbuj jeszcze raz",
    "error_25306": "Żądanie nie powiodło się (kod błędu: 25306). Spróbuj jeszcze raz",
    "image_upscaler_p": "Korzyści z pobierania obrazu bez znaku wodnego:",
    "Available_for": "Dostępne dla:",
    "credit_per": "%s kredytów na obraz HD",
    "still_valid": "Zakupione plany są nadal ważne",
    "credit": "kredyty",
    "pc_3rd_info": "Zaloguj się pomyślnie. Proszę przejść do aplikacji w celu dalszej obsługi.",
    "use_online": "Skorzystaj z usługi online",
    "use_download": "Skorzystaj z programu komputerowego",
    "use_immediately": "Użyj natychmiast",
    "Use_in_browser": "Użyj w przeglądarce",
    "win_desktop": "Okna",
    "Mac_desktop": "Prochowiec",
    "credits_per": "{%} Kredytów miesięcznie",
    "expire": "Data ważności:",
    "viewDetails": "Pokaż szczegóły",
    "viewHistory": "Zobacz korzyści z historii >>",
    "viewDetailsInfo": "Uwaga: Jeśli korzyści z subskrypcji zostaną odnowione w ciągu 7 dni od wygaśnięcia, niewykorzystane korzyści będą mogły być nadal wykorzystywane. Ponadto czas wygaśnięcia zostanie automatycznie zaktualizowany do czasu wygaśnięcia nowej subskrypcji. Jeśli po 7 dniach od wygaśnięcia nie zostanie złożona nowa subskrypcja, wszystkie wygasłe korzyści zostaną usunięte.",
    "connect_account": "Powiąż e-mail ze swoim kontem",
    "connect_account_info": "Po powiązaniu możesz zalogować się przy użyciu tego adresu e-mail.",
    "connect_now": "Powiąż teraz",
    "no_email_bind": "Brak powiązania e-mailowego",
    "bind_email": "Powiąż e-mail",
    "connect_your_email_placeholder": "Podaj swój adres e-mail",
    "bind_an_email": "Powiąż e-mail",
    "bind_info": "Zalogowałeś się pomyślnie. Aby aktywować korzyści, powiąż adres e-mail ze swoim kontem.",
    "bind_later": "Zwiąż później",
    "hi": "Jego!",
    "Personal_Information": "Informacje osobiste",
    "Access": "Dostęp",
    "Subscription_Plan": "(Plan subskrypcji)",
    "No_orders": "Nie znaleziono żadnych zamówień.",
    "No_data": "Brak danych",
    "Featured_Products": "Polecane produkty",
    "More_Products": "Więcej produktów",
    "Free_Download": "Darmowe pobieranie",
    "Get_Started": "Zaczynaj",
    "Subscribe": "Subskrybuj",
    "Verified": "Zweryfikowano",
    "back_to_account_center": "Powrót do Centrum kont",
    "success": "Powodzenie!",
    "successfully": "Pomyślnie zarejestrowałeś konto.",
    "Continue": "Kontynuować",
    "Already": "Posiadasz już konto?",
    "loading_verification": "Sprawdzanie statusu weryfikacji...",
    "email_no_verification": "Przepraszamy, zarejestrowany adres e-mail nie został zweryfikowany. Proszę dokończyć weryfikację zgodnie z powyższymi instrukcjami i ponownie kliknąć przycisk „Gotowe”, aby dokończyć rejestrację.",
    "will_expire_after": "Wygasa po",
    "hours": "godziny",
  },
  "pt": {
    "overtime": "Código de erro: {%}, tente novamente",
    "isnetwork": "Erro na Internet. Verifique e tente novamente",
    "email_placeholder": "E-mail",
    "email_empty": "Por favor insira o e-mail",
    "email_not_valid": "E-mail não é válido",
    "email_not_valid_1": "Por favor insira seu e-mail",
    "email_not_valid_2": "O e-mail não é válido, por favor use um endereço diferente.",
    "email_not_valid_3": "Nenhuma entrada de e-mail",
    "password_placeholder": "Senha",
    "password_empty": "Por favor insira a senha",
    "password_not_valid": "Conta ou senha incorreta",
    "password_not_valid_1": "É necessária senha acima de 8 caracteres",
    "password_not_valid_2": "Por favor crie uma senha",
    "password_placeholder_1": "Crie sua senha",
    "password_placeholder_2": "Confirme sua senha",
    "password_placeholder_3": "Criar nova senha",
    "password_placeholder_4": "Confirme a nova senha",
    "password_placeholder_5": "Insira a senha antiga",
    "copy_password_empty": "Por favor confirme a senha",
    "copy_password_not_valid": "Por favor confirme sua senha",
    "copy_passwords_inconsistent": "A confirmação da sua senha não corresponde",
    "code_empty": "Insira o código de verificação",
    "code_not_valid": "Código de verificação inválido",
    "code_placeholder": "Código de verificação",
    "not_received_code": "Se sua caixa de correio não recebe o código de verificação há muito tempo, obtenha o código de verificação novamente.",
    "get_first_code": "Obtenha o código de verificação primeiro.",
    "last_name_placeholder": "Por favor insira seu sobrenome",
    "first_name_placeholder": "por favor entre com seu primeiro nome",
    "address_placeholder": "Por favor insira seu endereço",
    "no_code_text": "Enviamos um código de verificação. Por favor, insira seu código. <span class='tips'>Não recebeu um código?",
    "no_code_text_1": "1. Certifique-se de que o endereço de e-mail seja válido e possa receber e-mails.",
    "no_code_text_2": "2. Como o e-mail é enviado automaticamente pelo sistema, ele pode ser sinalizado como spam ou lixo eletrônico. Verifique se o e-mail está na pasta Lixeira.",
    "no_code_text_3": "3. Não consegue resolver o seu problema? ",
    "no_code_text_3_span": "Então clique aqui para entrar em contato conosco.",
    "order_no": "Você não comprou nenhum produto. Se tiver alguma dúvida, <a href=\"https://www.mindonmap.com/contact-us/\">entre em contato conosco</a>.",
    "error_24901": "A conta corrente não possui e-mail vinculado e não consegue localizar os pedidos. Por favor conecte um e-mail.",
    "user_guide": "Guia do usuário >>",
    "download": "Download",
    "order_number": "Número do pedido:",
    "Refund": "Reembolso",
    "Disabled": "Desabilitado",
    "Normal": "Normal",
    "Modify": "Modificar",
    "Modify_1": "Modificar >>",
    "Connect": "Conectar",
    "unlink_success": "Desvincular com sucesso",
    "connect_success": "Conecte-se com sucesso",
    "feedback_title": "Obrigado pelo seu feedback! Deixe seu problema e responderemos em até 24 horas.",
    "feedback_thank_you": "Obrigado!<br />Seu feedback foi enviado com sucesso.",
    "feedback_email": "Digite seu email aqui!",
    "feedback_content": "Deixe aqui qualquer problema ou sugestão que você encontrou.",
    "feedback_submit": "Enviar",
    "form_contents": "Você não inseriu nenhuma descrição. Por favor, insira-o e envie novamente.",
    "old_password": "Por favor insira a senha antiga",
    "new_password": "Por favor crie uma nova senha",
    "old_new_password": "A nova senha não pode ser igual à antiga",
    "incorrect_password": "Senha incorreta",
    "delete_no": "Excluir agora",
    "Caps": "Caps Lock está ativado",
    "Get": "Pegar",
    "Done": "Feito",
    "error_20001": "Erro: 20001. Faça login novamente.",
    "error_20002": "Erro: 20002. Faça login novamente.",
    "error_20003": "Erro: 20003. Faça login novamente.",
    "error_20004": "Falha na solicitação (código de erro: 20004). Por favor, tente novamente.",
    "error_20005": "A sessão de login expirou (Erro: 20005). Por favor faça login novamente.",
    "error_20006": "Falha na solicitação (código de erro: 20006). Por favor, tente novamente.",
    "error_20007": "A sessão de login expirou (Erro: 20007). Por favor faça login novamente.",
    "error_20008": "A sessão de login expirou (Erro: 20008). Por favor faça login novamente.",
    "error_20009": "A sessão de login expirou (Erro: 20009). Por favor faça login novamente.",
    "error_20101": "Por favor insira seu e-mail (código de erro: 20101)",
    "error_20102": "O e-mail não é válido (código de erro: 20102)",
    "error_20103": "Falha na solicitação (código de erro: 20103). Por favor, tente novamente",
    "error_20104": "O e-mail já está em uso, <a href=\"https://account.mindonmap.com/login/\">faça login</a> ou registre-se com um novo",
    "error_20105": "Falha na solicitação (código de erro: 20105). Por favor, tente novamente",
    "error_20106": "Falha ao enviar e-mail, tente novamente",
    "error_20201": "Por favor insira seu e-mail (código de erro: 20201)",
    "error_20202": "Por favor insira sua senha (código de erro: 20202)",
    "error_20203": "Insira o código de verificação (código de erro: 20203)",
    "error_20204": "O e-mail não é válido (código de erro: 20204)",
    "error_20205": "É necessária uma senha com mais de 8 caracteres (código de erro: 20205)",
    "error_20206": "Falha na solicitação (código de erro: 20206). Por favor, tente novamente",
    "error_20207": "Código de verificação inválido",
    "error_20208": "Falha na solicitação (código de erro: 20208). Por favor, tente novamente",
    "error_20209": "Falha na solicitação (código de erro: 20209). Por favor, tente novamente",
    "error_20301": "Por favor insira seu e-mail (código de erro: 20301)",
    "error_20302": "Erro: 20302. Entre em contato conosco",
    "error_20303": "O e-mail não é válido (código de erro: 20303)",
    "error_20304": "Falha na solicitação (código de erro: 20304). Por favor, tente novamente",
    "error_20305": "Conta não existe. Digite novamente ou <a href=\"https://account.mindonmap.com/register/\">Crie-o</a> primeiro.",
    "error_20306": "Ainda não há senha. Use <a href=\"https://account.mindonmap.com/passwordless-login/\">Login sem senha</a> ou <a href=\"https://account.mindonmap.com/create-password/\"> defina uma senha</a> e faça login.",
    "error_20308": "Falha na solicitação (código de erro: 20308). Por favor, tente novamente",
    "error_20401": "Falha ao sair (código de erro: 20401). Por favor, tente novamente",
    "error_20501": "Por favor insira seu e-mail (código de erro: 20501)",
    "error_20502": "O e-mail não é válido (código de erro: 20502)",
    "error_20503": "Falha na solicitação (código de erro: 20503). Por favor, tente novamente",
    "error_20504": "Falha ao enviar e-mail. Por favor, tente novamente.",
    "error_20601": "Por favor insira seu e-mail (código de erro: 20601)",
    "error_20602": "Insira o código de verificação (código de erro: 20602)",
    "error_20603": "O e-mail não é válido (código de erro: 20603)",
    "error_20604": "Falha na solicitação (código de erro: 20604). Por favor, tente novamente",
    "error_20606": "Falha na solicitação (código de erro: 20606). Por favor, tente novamente",
    "error_20607": "Falha na solicitação (código de erro: 20607). Por favor, tente novamente",
    "error_20608": "Falha na solicitação (código de erro: 20608). Por favor, tente novamente",
    "error_20701": "Por favor insira seu e-mail (código de erro: 20701)",
    "error_20702": "O e-mail não é válido (código de erro: 20702)",
    "error_20703": "Falha na solicitação (código de erro: 20703). Por favor, tente novamente",
    "error_20704": "Conta não existe. Digite novamente ou <a href=\"https://account.mindonmap.com/register/\">Crie-o</a> primeiro.",
    "error_20705": "Falha na solicitação (código de erro: 20705). Por favor, tente novamente",
    "error_20706": "Falha ao enviar e-mail. Por favor, tente novamente",
    "error_20801": "Por favor insira seu e-mail (código de erro: 20801)",
    "error_20802": "Erro: 20802. Entre em contato conosco",
    "error_20803": "Insira o código de verificação (código de erro: 20803)",
    "error_20804": "O e-mail não é válido (código de erro: 20804)",
    "error_20805": "É necessária uma senha acima de 8 caracteres (código de erro: 20805)",
    "error_20806": "Falha na solicitação (código de erro: 20806). Por favor, tente novamente",
    "error_20808": "Falha na solicitação (código de erro: 20808). Por favor, tente novamente",
    "error_20901": "Falha na solicitação (código de erro: 20901). Por favor, tente novamente",
    "error_20902": "Falha na solicitação (código de erro: 20902). Por favor, tente novamente",
    "error_21000": "As alterações são salvas",
    "error_21001": "Nenhuma informação é enviada (código de erro: 21001)",
    "error_21002": "Falha na solicitação (código de erro: 21002). Por favor, tente novamente",
    "error_21101": "Por favor insira seu e-mail (código de erro: 21101)",
    "error_21102": "O e-mail não é válido (código de erro: 21102)",
    "error_21103": "Falha na solicitação (código de erro: 21103), tente novamente",
    "error_21104": "O e-mail já está conectado, use um novo",
    "error_21105": "Falha na solicitação (código de erro: 21105), tente novamente",
    "error_21106": "Falha ao enviar e-mail. Por favor, tente novamente",
    "error_21201": "Por favor insira seu e-mail (código de erro: 21201)",
    "error_21202": "Insira o código de verificação (código de erro: 21202)",
    "error_21203": "O e-mail não é válido (código de erro: 21203)",
    "error_21204": "Erro: 21204. Entre em contato conosco",
    "error_21205": "Erro: 21205. Entre em contato conosco",
    "error_21206": "É necessária uma senha com mais de 8 caracteres (código de erro: 21206)",
    "error_21207": "Falha na solicitação (código de erro: 21207). Por favor, tente novamente",
    "error_21209": "Falha na solicitação (código de erro: 21209). Por favor, tente novamente",
    "error_21301": "Por favor insira a senha antiga (código de erro: 21301)",
    "error_21302": "Por favor, crie uma nova senha (código de erro: 21302)",
    "error_21303": "A nova senha não pode ser igual à antiga. (Erro: 21303)",
    "error_21304": "É necessária uma senha com mais de 8 caracteres (código de erro: 21304)",
    "error_21306": "Falha na solicitação (código de erro: 21306). Por favor, tente novamente",
    "error_21402": "Falha na solicitação (código de erro: 21402). Por favor, tente novamente",
    "error_21403": "Falha ao enviar o código de verificação. Por favor reenvie",
    "error_21500": "A conta foi excluída",
    "error_21501": "Insira o código de verificação (código de erro: 21501)",
    "error_21502": "A sessão de login expirou (Erro: 21502). Por favor faça login novamente.",
    "error_21503": "Falha na solicitação (código de erro: 21503). Por favor, tente novamente",
    "error_21505": "Falha na solicitação (código de erro: 21505), tente novamente",
    "error_21601": "Erro: 20601. Entre em contato conosco",
    "error_21602": "Verificação inválida (Erro: 20602). Por favor, tente novamente.",
    "error_21603": "Erro: 20603. Tente novamente",
    "error_21604": "Falha na solicitação (código de erro: 21604). Por favor, tente novamente",
    "error_21606": "Falha na solicitação (código de erro: 21606). Por favor, tente novamente",
    "error_21611": "Falha na solicitação (código de erro: 21611). Por favor, tente novamente",
    "error_21801": "Erro: 21801. Entre em contato conosco",
    "error_21802": "Falha na solicitação (Erro: 21802). Por favor, tente novamente",
    "error_21803": "Erro: 21803. Tente novamente",
    "error_21804": "Falha na solicitação (código de erro: 21804). Por favor, tente novamente",
    "error_21806": "Erro: 21806. Tente novamente",
    "error_21807": "Erro: 21807. Entre em contato conosco",
    "error_21808": "Erro: 21808. Entre em contato conosco",
    "error_21809": "Erro: 21809. Entre em contato conosco",
    "error_21810": "Erro: 21810. Entre em contato conosco",
    "error_21811": "Erro: 21811. Entre em contato conosco",
    "error_21812": "Erro: 21812. Entre em contato conosco",
    "error_21813": "Falha na solicitação (código de erro: 21813). Por favor, tente novamente",
    "error_21814": "Erro: 21814. Entre em contato conosco",
    "error_21815": "Falha na solicitação (código de erro: 21815). Por favor, tente novamente",
    "error_21816": "Erro: 21816. Entre em contato conosco",
    "error_21817": "Erro: 21817. Entre em contato conosco",
    "error_21818": "Erro: 21818. Entre em contato conosco",
    "error_21819": "Falha na solicitação (código de erro: 21819). Por favor, tente novamente",
    "error_21820": "Erro: 21820. Entre em contato conosco",
    "error_21821": "Erro: 21821. Entre em contato conosco",
    "error_21822": "Erro: 21822. Entre em contato conosco",
    "error_21823": "Falha na solicitação (código de erro: 21823). Por favor, tente novamente",
    "error_21824": "Falha na solicitação (código de erro: 21824). Por favor, tente novamente",
    "error_21825": "Falha na solicitação (código de erro: 21825). Por favor, tente novamente",
    "error_21826": "Falha na solicitação (código de erro: 21826). Por favor, tente novamente",
    "error_21901": "Erro: 21901. Entre em contato conosco",
    "error_21902": "Falha na solicitação (código de erro: 21902). Por favor, tente novamente",
    "error_21903": "O status da conta mudou (código de erro: 21903). Atualize a página e tente novamente",
    "error_21904": "Erro: 21904. Tente novamente",
    "error_21905": "Erro: 21905. Tente novamente",
    "error_21906": "Falha na solicitação (código de erro: 21906). Por favor, tente novamente",
    "error_21907": "A conta do Google foi vinculada a outra conta",
    "error_21908": "Falha na solicitação (código de erro: 21908). Por favor, tente novamente",
    "error_22001": "Falha na solicitação (código de erro: 22001). Por favor, tente novamente",
    "error_22002": "Falha na desvinculação sem login adicional",
    "error_22003": "Falha na solicitação (código de erro: 22003). Por favor, tente novamente",
    "error_22101": "Erro: 22101. Entre em contato conosco",
    "error_22102": "O status da conta mudou (código de erro: 22102), atualize a página e tente novamente",
    "error_22103": "Falha na solicitação (código de erro: 22103). Por favor, tente novamente",
    "error_22104": "O status da conta mudou (código de erro: 22104), atualize a página e tente novamente",
    "error_22105": "Erro: 22105. Tente novamente",
    "error_22106": "Erro: 22106. Tente novamente",
    "error_22107": "Erro: 22107. Entre em contato conosco",
    "error_22108": "Falha na solicitação (código de erro: 22108). Por favor, tente novamente",
    "error_22201": "Erro: 22201. Entre em contato conosco",
    "error_22202": "Verificação inválida (Erro: 22202). Por favor, tente novamente.",
    "error_22203": "Erro: 22203. Tente novamente\"",
    "error_22204": "Falha na solicitação (código de erro: 22204). Por favor, tente novamente",
    "error_22206": "Falha na solicitação (código de erro: 22206). Por favor, tente novamente",
    "error_22401": "Erro: 22401. Entre em contato conosco",
    "error_22402": "Verificação inválida (Erro: 22402). Por favor, tente novamente.",
    "error_22403": "Erro: 22403. Tente novamente",
    "error_22404": "Falha na solicitação (código de erro: 22404). Por favor, tente novamente",
    "error_22405": "A conta do Facebook foi vinculada a outro e-mail",
    "error_22406": "Erro: 22406. Tente novamente",
    "error_22407": "Erro: 22407. Entre em contato conosco",
    "error_22408": "Erro: 22408. Entre em contato conosco",
    "error_22409": "Erro: 22409. Entre em contato conosco",
    "error_22410": "Erro: 224010. Entre em contato conosco",
    "error_22411": "Erro: 224011. Entre em contato conosco",
    "error_22412": "Erro: 224012. Entre em contato conosco",
    "error_22413": "Erro: 22413. Entre em contato conosco",
    "error_22414": "Falha na solicitação (código de erro: 22414). Por favor, tente novamente",
    "error_22415": "Erro: 22415. Entre em contato conosco",
    "error_22416": "Erro: 22416. Entre em contato conosco",
    "error_22417": "Erro: 22417. Entre em contato conosco",
    "error_22418": "Falha na solicitação (código de erro: 22418). Por favor, tente novamente",
    "error_22419": "Erro: 22419. Entre em contato conosco",
    "error_22420": "Erro: 22420. Entre em contato conosco",
    "error_22421": "Erro: 22421. Entre em contato conosco",
    "error_22422": "Falha na solicitação (código de erro: 22422). Por favor, tente novamente",
    "error_22423": "Falha na solicitação (código de erro: 22423). Por favor, tente novamente",
    "error_22424": "Falha na solicitação (código de erro: 22424). Por favor, tente novamente",
    "error_22425": "Falha na solicitação (código de erro: 22425). Por favor, tente novamente",
    "error_20098": "Código de erro: 20098. Se você estiver navegando no modo Privado, mude para o modo Normal e tente novamente.",
    "error_22298": "Falha na solicitação do Google (Erro: 22298). Por favor, tente novamente.",
    "error_22498": "Falha na solicitação do Facebook (Erro: 22498). Por favor, tente novamente.",
    "error_24902": "Falha na solicitação (código de erro: 24902). Por favor, tente novamente",
    "error_24903": "Falha na solicitação (código de erro: 24903). Por favor, tente novamente",
    "error_24904": "Falha na solicitação (código de erro: 24904). Por favor, tente novamente",
    "error_24905": "Falha na solicitação (código de erro: 24905). Por favor, tente novamente",
    "login_title": "Faça login no MindOnMap",
    "log_in": "Conecte-se",
    "no_account": "Sem conta?",
    "create_it": "Crie",
    "or_log_in_with": "Ou faça login com",
    "passwordless_login": "Login sem senha",
    "log_in_done": "Login concluído",
    "three_rd_account_connect_info": "Parabéns! Você fez login com sucesso. Agora você pode conectar uma conta de e-mail que será usada para fazer login no futuro.",
    "see_my_account": "Ver minha conta",
    "three_rd_login_merge_account": "O endereço de e-mail da conta de terceiros foi registrado. Deseja conectar-se e fazer login diretamente com este endereço de e-mail?",
    "connect_log_in": "Conecte-se e faça login",
    "create_an_account": "Crie a sua conta aqui",
    "back_to_log_in": "Volte ao login",
    "create_password": "Criar senha",
    "create_now": "Crie agora",
    "password_login_subtitle": "Login sem senha com e-mail",
    "account_login": "Login da conta",
    "rights": "Ao criar esta conta, você concorda com os <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Termos de Serviço</a> e <a href=\"https://www.mindonmap.com/privacy-policy/\">Política de Privacidade</a>",
    "passwordless_login_done": "Login sem senha concluído",
    "passwordless_login_info": "Parabéns, você concluiu o login sem senha com sucesso. Você pode criar uma senha para esta conta e fazer login com a conta e a senha no futuro. <a href=\"/create-password\" style=\"display: initial;\">Criar agora</a>",
    "sign_up": "Inscrever-se",
    "register_info": "crie sua conta",
    "reset_now": "Redefinir agora",
    "forgot_password": "Esqueceu sua senha",
    "reset_password_subtitle": "Use o e-mail da sua conta para redefinir a senha",
    "plan_products": "Planos e Produtos",
    "nick_name": "Nome de usuário:",
    "email": "E-mail:",
    "my_products": "Meus produtos",
    "my_orders": "minhas ordens",
    "unlink": "Desvincular",
    "link": "Link",
    "connected_accounts": "Contas conectadas",
    "last_name": "Sobrenome:",
    "first_name": "Primeiro nome:",
    "Gender": "Gênero:",
    "Birth": "Aniversário:",
    "Month": "Mês",
    "Year": "Ano",
    "Country_Region": "País/Região:",
    "Address": "Endereço:",
    "Save": "Salvar",
    "Date": "Data",
    "Male": "Macho",
    "Female": "Fêmea",
    "Unspecified": "Não especificado",
    "Security": "Segurança",
    "change_password": "Alterar a senha",
    "change_now": "Mude agora",
    "connect_email": "Conectar e-mail",
    "delete_account": "Deletar conta",
    "delete_account_info": "Quando sua conta for excluída, todos os dados em Minha conta associados à sua conta serão excluídos permanentemente e talvez você não consiga recuperá-los. Recomendamos que você aja com cautela.",
    "Delete": "Excluir",
    "Logout": "Sair",
    "my_profile": "Meu perfil",
    "guides_faqs": "Guias e perguntas frequentes",
    "More": "Mais",
    "guides": "Guias",
    "register": "registro",
    "hot_faq": "Perguntas frequentes importantes",
    "Contents": "Conteúdo:",
    "contact_us": "Entre em contato conosco>>",
    "plan": "Plano",
    "unregistered": "Não registrado",
    "buy_more": "Compre mais",
    "buy_again": "Compre novamente",
    "buy_now": "Comprar agora",
    "free_no_limit": "Grátis e sem limite",
    "expired": "Expirado",
    "lifetime": "Vida",
    "remain": "Permanecer",
    "day_s": "Dias)",
    "error_24801": "Falha na solicitação (código de erro: 24801). Por favor, tente novamente",
    "no_app_found": "Nenhum aplicativo encontrado！<a href=\"/\">Atualize</a> ou <a href=\"https://www.mindonmap.com/\">Acesse o site oficial</a>",
    "get_more": "Obtenha mais >>",
    "edit_photo": "Editar foto",
    "select_photo": "Selecione a foto",
    "change_photo": "Mudar foto",
    "cancel": "Cancelar",
    "hide_password": "Esconder a senha",
    "show_password": "Mostrar senha",
    "zoom_in": "Mais Zoom",
    "zoom_out": "Reduzir o zoom",
    "rotate": "Girar",
    "horizontal_flip": "Inversão horizontal",
    "vertical_flip": "Inversão vertical",
    "country": "País",
    "country_1": "Selecione seu país/região",
    "country_2": "Ilha de Aaland",
    "country_3": "Afeganistão",
    "country_4": "Albânia",
    "country_5": "Argélia",
    "country_6": "Samoa Americana",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguila",
    "country_10": "Antártica",
    "country_11": "Antígua e Barbuda",
    "country_12": "Argentina",
    "country_13": "Armênia",
    "country_14": "Aruba",
    "country_15": "Austrália",
    "country_16": "Áustria",
    "country_17": "Azerbaijão",
    "country_18": "Bahrein",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorrússia",
    "country_22": "Bélgica",
    "country_23": "Belize",
    "country_24": "Benim",
    "country_25": "Bermudas",
    "country_26": "Butão",
    "country_27": "Bolívia",
    "country_28": "Bósnia e Herzegovina",
    "country_29": "Botsuana",
    "country_30": "Ilha Bouvet",
    "country_31": "Brasil",
    "country_32": "Território do Oceano Índico Britânico",
    "country_33": "Ilhas Virgens Britânicas",
    "country_34": "Brunei",
    "country_35": "Bulgária",
    "country_36": "Burkina Faso",
    "country_37": "Burundi",
    "country_38": "Camboja",
    "country_39": "Camarões",
    "country_40": "Canadá",
    "country_41": "Cabo Verde",
    "country_42": "Caribe Holanda",
    "country_43": "Ilhas Cayman",
    "country_44": "República Centro Africana",
    "country_45": "Chade",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Ilha do Natal",
    "country_49": "Ilhas Cocos (Keeling) ",
    "country_50": "Colômbia",
    "country_51": "Ilhas Cook",
    "country_52": "Costa Rica",
    "country_53": "Costa do Marfim",
    "country_54": "Croácia",
    "country_55": "Cuba",
    "country_56": "Chipre",
    "country_57": "República Checa",
    "country_58": "República Democrática do Congo",
    "country_59": "Dinamarca",
    "country_60": "Djibuti",
    "country_61": "Domínica",
    "country_62": "República Dominicana",
    "country_63": "Equador",
    "country_64": "Egito",
    "country_65": "El Salvador",
    "country_66": "Guiné Equatorial",
    "country_67": "Eritreia",
    "country_68": "Estônia",
    "country_69": "Etiópia",
    "country_70": "Ilhas Malvinas",
    "country_71": "Ilhas Faroe",
    "country_72": " Estados Federados da Micronésia",
    "country_73": "Fiji",
    "country_74": "Finlândia",
    "country_75": "França",
    "country_76": "Guiana Francesa",
    "country_77": "Polinésia Francesa",
    "country_78": "Territórios Sul Franceses",
    "country_79": "Gabão",
    "country_80": "Gâmbia",
    "country_81": "Geórgia",
    "country_82": "Alemanha",
    "country_83": "Gana",
    "country_84": "Gibraltar",
    "country_85": "Grã-Bretanha (Reino Unido; Inglaterra)",
    "country_86": "Grécia",
    "country_87": "Groenlândia",
    "country_88": "Granada",
    "country_89": "Guadalupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guiné",
    "country_94": "Guiné-Bissau",
    "country_95": "Guiana",
    "country_96": "Haiti",
    "country_97": "Ilha Heard e Ilhas McDonald ",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Hungria",
    "country_101": "Islândia",
    "country_102": "Índia",
    "country_103": "Indonésia",
    "country_104": "Irã",
    "country_105": "Iraque",
    "country_106": "Irlanda",
    "country_107": "Ilha de Man",
    "country_108": "Israel",
    "country_109": "Itália",
    "country_110": "Jamaica",
    "country_111": "Japão",
    "country_112": "Jersey",
    "country_113": "Jordânia",
    "country_114": "Cazaquistão",
    "country_115": "Quênia",
    "country_116": "Quiribáti",
    "country_117": "Kuwait",
    "country_118": "Quirguistão",
    "country_119": "Laos",
    "country_120": "Letônia",
    "country_121": "Líbano",
    "country_122": "Lesoto",
    "country_123": "Libéria",
    "country_124": "Líbia",
    "country_125": "Liechtenstein",
    "country_126": "Lituânia",
    "country_127": "Luxemburgo",
    "country_128": "Macau",
    "country_129": "Madagáscar",
    "country_130": "Maláui",
    "country_131": "Malásia",
    "country_132": "Maldivas",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Ilhas Marshall",
    "country_136": "Martinica",
    "country_137": "Mauritânia",
    "country_138": "Maurício",
    "country_139": "Maiote",
    "country_140": "México",
    "country_141": "Moldávia",
    "country_142": "Mônaco",
    "country_143": "Mongólia",
    "country_144": "Montenegro",
    "country_145": "Montserrate",
    "country_146": "Marrocos",
    "country_147": "Moçambique",
    "country_148": "Mianmar (Birmânia)",
    "country_149": "Namíbia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Holanda",
    "country_153": "Nova Caledônia",
    "country_154": "Nova Zelândia",
    "country_155": "Nicarágua",
    "country_156": "Níger",
    "country_157": "Nigéria",
    "country_158": "Niue",
    "country_159": "Ilha Norfolk",
    "country_160": "Coréia do Norte",
    "country_161": "Ilhas Marianas do Norte",
    "country_162": "Noruega",
    "country_163": "Omã",
    "country_164": "Paquistão",
    "country_165": "Palau",
    "country_166": "Territórios Palestinos",
    "country_167": "Panamá",
    "country_168": "Papua Nova Guiné",
    "country_169": "Paraguai",
    "country_170": "Peru",
    "country_171": "Ilhas Pitcairn",
    "country_172": "Polônia",
    "country_173": "Portugal",
    "country_174": "Porto Rico",
    "country_175": "Catar",
    "country_176": "República da Macedônia (ARJM)",
    "country_177": "República do Congo",
    "country_178": "Reunião",
    "country_179": "Romênia",
    "country_180": "Federação Russa",
    "country_181": "Ruanda",
    "country_182": "São Bartolomeu",
    "country_183": "São Martinho (França)",
    "country_184": "São Pedro e Miquelon",
    "country_185": "Samoa",
    "country_186": "São Marino",
    "country_187": "São Tomé e Príncipe",
    "country_188": "Arábia Saudita",
    "country_189": "Senegal",
    "country_190": "Sérvia",
    "country_191": "Seicheles",
    "country_192": "Serra Leoa",
    "country_193": "Cingapura",
    "country_194": "Eslováquia",
    "country_195": "Eslovênia",
    "country_196": "Ilhas Salomão",
    "country_197": "Somália",
    "country_198": "África do Sul",
    "country_199": "Geórgia do Sul e Ilha Sanduíche do Sul",
    "country_200": "Coreia do Sul",
    "country_201": "Sudão do Sul",
    "country_202": "Espanha",
    "country_203": "Sri Lanka",
    "country_204": "Santa Helena e Dependências",
    "country_205": "São Cristóvão e Névis",
    "country_206": "Santa Lúcia",
    "country_207": "São Vicente e Granadinas",
    "country_208": "Sudão",
    "country_209": "Suriname",
    "country_210": "Suazilândia",
    "country_211": "Suécia",
    "country_212": "Suíça",
    "country_213": "Síria",
    "country_214": "Taiwan",
    "country_215": "Tadjiquistão",
    "country_216": "Tanzânia",
    "country_217": "Modelo:dados do país SJM Svalbard",
    "country_218": "Tailândia",
    "country_219": "As Bahamas",
    "country_220": "As Comores",
    "country_221": "As Filipinas",
    "country_222": "Timor-Leste (Timor Leste)",
    "country_223": "Ir",
    "country_224": "Toquelau",
    "country_225": "Tonga",
    "country_226": "Trinidad e Tobago",
    "country_227": "Tunísia",
    "country_228": "Peru",
    "country_229": "Turcomenistão",
    "country_230": "Ilhas Turcas e Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucrânia",
    "country_234": "Emirados Árabes Unidos",
    "country_235": "Ilhas Menores Diferentes dos Estados Unidos",
    "country_236": "Estados Unidos da América (EUA)",
    "country_237": "Ilhas Virgens Estados Unidos",
    "country_238": "Uruguai",
    "country_239": "Uzbequistão",
    "country_240": "Vanuatu",
    "country_241": "Cidade do Vaticano (A Santa Sé)",
    "country_242": "Venezuela",
    "country_243": "Vietnã",
    "country_244": "Wallis e Futuna",
    "country_245": "Saara Ocidental",
    "country_246": "Iémen",
    "country_247": "Zâmbia",
    "country_248": "Zimbábue",
    "google_login": "Faça login no Google",
    "State": "Estado",
    "Activation_code": "Código de ativação",
    "Question": "Liste todos os aplicativos em que você está conectado",
    "Copy_complete": "Cópia concluída",
    "footer": "Direitos autorais © 2024 MindOnMap Studio. Todos os direitos reservados.",
    "change_password_success": "Senha alterada com sucesso",
    "successful_login_title": "Sucesso de login",
    "product_page": "Página do produto >>",
    "successful_login_info": "Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir. A página atual será fechada automaticamente em 5 segundos. Se o fechamento automático ou o fechamento clicando no botão \"Concluído\" falhar, feche esta guia diretamente.",
    "successful_login_info_firefox": "Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir.",
    "my_account": "Minha conta",
    "my_history": "Minha história",
    "remove_watermark": "Remover marca d’água",
    "no_history": "Sem histórico",
    "history_all": "Selecionar tudo",
    "history_open": "Abrir",
    "history_down": "Download",
    "history_delete": "Excluir",
    "history_clear": "Limpar inválido",
    "images": "imagens",
    "use_this_function": "Use este produto >>",
    "hd_downloading": "Benefícios de download de imagens originais em HD:",
    "lifetimeRemaining": "Vida útil restante",
    "Remaining": "Restante",
    "email_verification": "verificação de e-mail",
    "email_verification_info": "Enviamos a mensagem de verificação para seu e-mail <span class=\"email\"></span> e conclua a verificação. Após a verificação, os benefícios serão sincronizados automaticamente.",
    "wrong_email": "Endereço de email errado?",
    "click_here_to_modify": "Clique aqui para modificar",
    "get_more_help": "Obter mais ajuda?",
    "click_here": "Clique aqui",
    "email_verification_info_success": "Parabéns por verificar sua conta de e-mail.",
    "email_verification_info_error": "A verificação falhou devido ao link expirado.",
    "registration_succeeded": "Registro realizado com sucesso",
    "registration_succeeded_info_1": "Parabéns! Você se registrou com sucesso. Enviamos a mensagem de verificação para seu e-mail <span class=\"email\"></span> e conclua a verificação para usar os benefícios deste e-mail.",
    "registration_succeeded_info_2": "Clique em \"Concluído\" para retornar à página inicial e usar este produto.",
    "registration_succeeded_info_3": "Clique em “Concluído” para fechar a página atual e retornar à página inicial para as seguintes operações. Se o fechamento falhar, feche a guia manualmente.",
    "verify_email": "Verificar e-mail",
    "registered_email_not_verified": "O e-mail cadastrado não foi verificado, verifique-o imediatamente.",
    "email_verification_time_1": "Não recebeu um e-mail de verificação?",
    "email_verification_time_2": "Depois",
    "email_verification_time_3": "Clique aqui para reenviar",
    "error_26301": "Código de erro: 26301, entre em contato conosco",
    "error_26302": "Código de erro: 26302, entre em contato conosco",
    "error_26303": "Erro de formato de e-mail (código de erro: 26303). Por favor insira-o novamente",
    "error_26304": "Recomenda-se senha acima de 8 caracteres (código de erro: 26304)",
    "error_26305": "Reuqest falhou (código de erro: 26305). Por favor, tente novamente",
    "error_26306": "O e-mail foi registrado, por favor <a href=\"https://account.mindonmap.com/login/\">faça login</a>",
    "error_26307": "Reuqest falhou (código de erro: 26307). Por favor, tente novamente",
    "error_26308": "Reuqest falhou (código de erro: 26308). Por favor, tente novamente",
    "error_26401": "Código de erro: 26401, tente novamente",
    "error_26402": "O e-mail foi verificado (código de erro: 26402), tente novamente",
    "error_26403": "Reuqest falhou (código de erro: 26403). Por favor, tente novamente",
    "error_26404": "Falha na solicitação (código de erro: 26404). Por favor, tente novamente",
    "error_26501": "Código de erro: 26501, entre em contato conosco",
    "error_26502": "Código de erro: 26502, entre em contato conosco",
    "error_26503": "Erro de formato de e-mail (código de erro: 26503). Por favor insira-o novamente",
    "error_26504": "Reuqest falhou (código de erro: 26504). Por favor, tente novamente",
    "error_26505": "O e-mail não foi registrado, <a href=\"https://account.mindonmap.com/register/\">registre-o primeiro</a>",
    "error_26506": "O e-mail foi verificado.",
    "error_26507": "Reuqest falhou (código de erro: 26507). Por favor, tente novamente",
    "error_26508": "Verificação registrada (código de erro: 26508), tente novamente",
    "error_26509": "Falha na solicitação (código de erro: 26509), tente novamente",
    "error_26510": "Código de erro: 26510, entre em contato conosco",
    "error_26601": "Código de erro: 26601, entre em contato conosco",
    "error_26602": "Código de erro: 26602, entre em contato conosco",
    "error_26603": "Reuqest falhou (código de erro: 26603). Por favor, tente novamente",
    "error_26604": "Código de erro: 26604, entre em contato conosco",
    "error_26605": "Código de erro: 26605, entre em contato conosco",
    "error_26701": "Código de erro: 26701, entre em contato conosco",
    "error_26702": "Reuqest falhou (código de erro: 26702). Por favor, tente novamente",
    "error_26703": "Código de erro: 26703, entre em contato conosco",
    "error_26704": "Código de erro: 26704, entre em contato conosco",
    "error_26705": "Aguarde o login (código de erro: 26705). Por favor, tente novamente",
    "no_cookie": "Você ativou a função Bloquear todos os cookies em seu navegador e não consegue fazer login. Vá em Configurações para marcar a caixa Permitir todos os cookies.",
    "error_26801": "Código de erro: 26801, entre em contato conosco",
    "error_26802": "Código de erro: 26802, entre em contato conosco",
    "error_26803": "Falha na solicitação (código de erro: 26803). Por favor, tente novamente",
    "error_26804": "Falha na solicitação (código de erro: 26804). Por favor, tente novamente",
    "error_order": "Falha na solicitação (código de erro: 27098), tente novamente!",
    "error_order1": "A consulta do pedido está incompleta (código de erro: ",
    "error_order2": "）, atualize e tente novamente.",
    "modify_email_title": "Modificar e-mail",
    "modify_email_info": "Você pode usar o e-mail modificado para fazer login em sua conta.",
    "images_per": "Imagens por",
    "error_26101": "Erro: 26101. Entre em contato conosco",
    "error_26102": "Erro: 26102. Entre em contato conosco",
    "error_26103": "Falha na solicitação (código de erro: 26103). Por favor, tente novamente",
    "error_26104": "Código de erro: 26104, tente novamente",
    "error_26105": "Código de erro: 26105, tente novamente",
    "error_26106": "Falha na exclusão (código de erro: 26106). Por favor, tente novamente",
    "error_26201": "Erro: 26201. Entre em contato conosco",
    "error_26202": "Falha na solicitação (código de erro: 26202). Por favor, tente novamente",
    "error_26001": "Erro: 26001. Entre em contato conosco",
    "error_26002": "Erro: 26002. Entre em contato conosco",
    "error_26003": "Erro: 26003. Entre em contato conosco",
    "error_26004": "Erro: 26004. Entre em contato conosco",
    "error_26005": "Falha na solicitação (código de erro: 26005). Por favor, tente novamente",
    "error_26006": "Código de erro: 26006, tente novamente",
    "error_26008": "Erro: 26008. Entre em contato conosco",
    "go_to_the_home_page": "Vá para a página inicial",
    "error_27101": "Falha na solicitação (código de erro: 27101). Por favor, tente novamente",
    "error_27201": "Código de erro: 27201, entre em contato conosco",
    "error_27202": "Código de erro: 27202, tente novamente",
    "error_27203": "Falha na solicitação (código de erro: 27203). Por favor, tente novamente",
    "error_27204": "Código inválido (código de erro: 27204).",
    "error_27205": "Falha na solicitação (código de erro: 27205). Por favor, tente novamente",
    "error_27206": "Falha na solicitação (código de erro: 27206). Por favor, tente novamente",
    "error_27207": "Falha na solicitação (código de erro: 27207). Por favor, tente novamente",
    "no_history_found": "Você não usou nenhuma ferramenta! <a href=\"/\">Atualizar</a> ou <a href=\"https://www.mindonmap.com/\">Acesse o site oficial</a>",
    "error_25301": "Erro: 25301. Entre em contato conosco",
    "error_25302": "Erro: 25302. Entre em contato conosco",
    "error_25303": "Falha na solicitação (código de erro: 25303). Por favor, tente novamente",
    "error_25304": "Falha na solicitação (código de erro: 25304). Por favor, tente novamente",
    "error_25305": "Falha na solicitação (código de erro: 25305). Por favor, tente novamente",
    "error_25306": "Falha na solicitação (código de erro: 25306). Por favor, tente novamente",
    "image_upscaler_p": "Benefícios de download de imagem sem marca d'água:",
    "Available_for": "Disponível para:",
    "credit_per": "%s crédito(s) por imagem HD",
    "still_valid": "O(s) plano(s) adquirido(s) ainda é válido(s)",
    "credit": "crédito(s)",
    "pc_3rd_info": "Login com sucesso. Por favor, acesse o aplicativo para operações adicionais.",
    "use_online": "Utilize o serviço on-line",
    "use_download": "Use o programa de desktop",
    "use_immediately": "Use imediatamente",
    "Use_in_browser": "Usar no navegador",
    "win_desktop": "janelas",
    "Mac_desktop": "Mac",
    "credits_per": "{%} créditos por mês",
    "expire": "Data de validade:",
    "viewDetails": "Ver detalhes",
    "viewHistory": "Veja os benefícios do histórico >>",
    "viewDetailsInfo": "Aviso: Se os benefícios da assinatura forem renovados dentro de 7 dias após o vencimento, os benefícios não utilizados poderão continuar a ser usados. Além disso, o prazo de validade será atualizado automaticamente para o prazo de validade da nova assinatura. Se não houver nenhuma nova assinatura após 7 dias do vencimento, todos os benefícios expirados serão cancelados.",
    "connect_account": "Vincule e-mail à sua conta",
    "connect_account_info": "Após a vinculação, você pode fazer login com este endereço de e-mail.",
    "connect_now": "Vincular agora",
    "no_email_bind": "Sem vinculação de e-mail",
    "bind_email": "Vincular e-mail",
    "connect_your_email_placeholder": "Por favor, indique o seu endereço de e-mail",
    "bind_an_email": "Vincular um e-mail",
    "bind_info": "Você fez login com sucesso. Vincule um e-mail à sua conta para ativar seus benefícios.",
    "bind_later": "Vincular mais tarde",
    "hi": "Dele!",
    "Personal_Information": "Informações pessoais",
    "Access": "Acesso",
    "Subscription_Plan": "(Plano de assinatura)",
    "No_orders": "Nenhum pedido encontrado.",
    "No_data": "Sem dados",
    "Featured_Products": "produtos em destaque",
    "More_Products": "Mais produtos",
    "Free_Download": "Download grátis",
    "Get_Started": "Iniciar",
    "Subscribe": "Se inscrever",
    "Verified": "Verificado",
    "back_to_account_center": "Voltar para a Central de Contas",
    "success": "Sucesso!",
    "successfully": "Você registrou uma conta com sucesso.",
    "Continue": "Continuar",
    "Already": "já tem uma conta?",
    "loading_verification": "Verificando o status de verificação...",
    "email_no_verification": "Desculpe, o endereço de e-mail registrado não foi verificado. Conclua a verificação de acordo com as instruções acima e clique no botão \"Concluído\" novamente para concluir o registro.",
    "will_expire_after": "Expirará depois",
    "hours": "horas",
  },
  "ro": {
    "overtime": "Cod de eroare: {%}, vă rugăm să reîncercați",
    "isnetwork": "Eroare de internet. Vă rugăm să verificați și să încercați din nou",
    "email_placeholder": "E-mail",
    "email_empty": "Vă rugăm să introduceți adresa de e-mail",
    "email_not_valid": "E-mailul nu este valid",
    "email_not_valid_1": "Vă rugăm să introduceți adresa de e-mail",
    "email_not_valid_2": "E-mailul nu este valid, vă rugăm să utilizați o altă adresă.",
    "email_not_valid_3": "Nicio intrare de e-mail",
    "password_placeholder": "Parola",
    "password_empty": "Vă rugăm să introduceți parola",
    "password_not_valid": "Cont sau parolă incorectă",
    "password_not_valid_1": "Este necesară o parolă de peste 8 caractere",
    "password_not_valid_2": "Vă rugăm să creați o parolă",
    "password_placeholder_1": "Creaza-ti parola",
    "password_placeholder_2": "Confirmă parola",
    "password_placeholder_3": "Creați o nouă parolă",
    "password_placeholder_4": "Confirmă noua parolă",
    "password_placeholder_5": "Introduceți vechea parolă",
    "copy_password_empty": "Vă rugăm să confirmați parola",
    "copy_password_not_valid": "Vă rugăm să vă confirmați parola",
    "copy_passwords_inconsistent": "Confirmarea parolei dvs. nu se potrivește",
    "code_empty": "Vă rugăm să introduceți codul de verificare",
    "code_not_valid": "Cod de verificare invalid",
    "code_placeholder": "Cod de verificare",
    "not_received_code": "Dacă cutia dvs. poștală nu a primit codul de verificare de mult timp, vă rugăm să obțineți din nou codul de verificare.",
    "get_first_code": "Vă rugăm să obțineți mai întâi codul de verificare.",
    "last_name_placeholder": "Vă rugăm să introduceți numele dvs. de familie",
    "first_name_placeholder": "Vă rugăm să introduceți prenumele dvs",
    "address_placeholder": "Vă rugăm să introduceți adresa dvs",
    "no_code_text": "Am trimis un cod de verificare. Vă rugăm să introduceți codul dvs. <span class='tips'>Nu ați primit un cod?",
    "no_code_text_1": "1. Vă rugăm să vă asigurați că adresa de e-mail este validă și că poate primi e-mailuri.",
    "no_code_text_2": "2. Pentru că e-mailul este trimis de sistem automat, acesta poate fi marcat ca spam sau e-mail nedorit. Verificați dacă e-mailul se află în dosarul Coș de gunoi.",
    "no_code_text_3": "3. Nu vă puteți rezolva problema? ",
    "no_code_text_3_span": "Apoi faceți clic aici pentru a ne contacta.",
    "order_no": "Nu ați achiziționat niciun produs. Dacă aveți întrebări, vă rugăm să <a href=\"https://www.mindonmap.com/contact-us/\">contactați-ne</a>.",
    "error_24901": "Contul curent nu are e-mail conectat și nu poate găsi comenzile. Vă rugăm să conectați un e-mail.",
    "user_guide": "Ghidul utilizatorului>>",
    "download": "Descarca",
    "order_number": "Număr de ordine:",
    "Refund": "Restituire",
    "Disabled": "Dezactivat",
    "Normal": "Normal",
    "Modify": "Modifica",
    "Modify_1": "Modificați>>",
    "Connect": "Conectați",
    "unlink_success": "Deconectați cu succes",
    "connect_success": "Conectați-vă cu succes",
    "feedback_title": "Vă mulțumim pentru feedback-ul dumneavoastră. Vă rugăm să lăsați problema dvs. și vă vom răspunde în 24 de ore.",
    "feedback_thank_you": "Vă mulțumim!<br />Feedback-ul dvs. a fost trimis cu succes.",
    "feedback_email": "Introduceți adresa de email aici!",
    "feedback_content": "Lăsați aici orice problemă sau sugestie pe care ați întâlnit-o.",
    "feedback_submit": "Trimite",
    "form_contents": "Nu ați introdus nicio descriere. Introduceți-l și trimiteți din nou.",
    "old_password": "Vă rugăm să introduceți parola veche",
    "new_password": "Vă rugăm să creați o nouă parolă",
    "old_new_password": "Noua parolă nu poate fi aceeași cu cea veche",
    "incorrect_password": "Parola incorecta",
    "delete_no": "Șterge acum",
    "Caps": "Caps Lock este activ",
    "Get": "obține",
    "Done": "Terminat",
    "error_20001": "Eroare: 20001. Vă rugăm să vă conectați din nou.",
    "error_20002": "Eroare: 20002. Vă rugăm să vă conectați din nou.",
    "error_20003": "Eroare: 20003. Vă rugăm să vă conectați din nou.",
    "error_20004": "Solicitarea a eșuat (cod de eroare: 20004). Vă rugăm să încercați din nou.",
    "error_20005": "Sesiunea de conectare a expirat (Eroare: 20005). Te rog Logheaza te din nou.",
    "error_20006": "Solicitarea a eșuat (cod de eroare: 20006). Vă rugăm să încercați din nou.",
    "error_20007": "Sesiunea de conectare a expirat (Eroare: 20007). Te rog Logheaza te din nou.",
    "error_20008": "Sesiunea de conectare a expirat (Eroare: 20008). Te rog Logheaza te din nou.",
    "error_20009": "Sesiunea de conectare a expirat (Eroare: 20009). Te rog Logheaza te din nou.",
    "error_20101": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20101)",
    "error_20102": "E-mailul nu este valid (cod de eroare: 20102)",
    "error_20103": "Solicitarea a eșuat (cod de eroare: 20103). Vă rugăm să încercați din nou",
    "error_20104": "E-mailul este deja utilizat, vă rugăm să <a href=\"https://account.mindonmap.com/login/\">conectați-vă</a> sau să vă înregistrați cu unul nou",
    "error_20105": "Solicitarea a eșuat (cod de eroare: 20105). Vă rugăm să încercați din nou",
    "error_20106": "Trimiterea e-mailului nu a reușit, încercați din nou",
    "error_20201": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20201)",
    "error_20202": "Vă rugăm să introduceți parola (cod de eroare: 20202)",
    "error_20203": "Vă rugăm să introduceți codul de verificare (cod de eroare: 20203)",
    "error_20204": "E-mailul nu este valid (cod de eroare: 20204)",
    "error_20205": "Este necesară o parolă de peste 8 caractere (cod de eroare: 20205)",
    "error_20206": "Solicitarea a eșuat (cod de eroare: 20206). Vă rugăm să încercați din nou",
    "error_20207": "Cod de verificare invalid",
    "error_20208": "Solicitarea a eșuat (cod de eroare: 20208). Vă rugăm să încercați din nou",
    "error_20209": "Solicitarea a eșuat (cod de eroare: 20209). Vă rugăm să încercați din nou",
    "error_20301": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20301)",
    "error_20302": "Eroare: 20302. Vă rugăm să ne contactați",
    "error_20303": "E-mailul nu este valid (cod de eroare: 20303)",
    "error_20304": "Solicitarea a eșuat (cod de eroare: 20304). Vă rugăm să încercați din nou",
    "error_20305": "Contul nu există. Reintroduceți sau <a href=\"https://account.mindonmap.com/register/\">Creați-l</a> mai întâi.",
    "error_20306": "Nicio parolă încă. Folosiți <a href=\"https://account.mindonmap.com/passwordless-login/\">Autentificare fără parolă</a> sau <a href=\"https://account.mindonmap.com/create-password/\"> setați o parolă</a> și conectați-vă.",
    "error_20308": "Solicitarea a eșuat (cod de eroare: 20308). Vă rugăm să încercați din nou",
    "error_20401": "Nu s-a putut deconecta (cod de eroare: 20401). Vă rugăm să încercați din nou",
    "error_20501": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20501)",
    "error_20502": "E-mailul nu este valid (cod de eroare: 20502)",
    "error_20503": "Solicitarea a eșuat (cod de eroare: 20503). Vă rugăm să încercați din nou",
    "error_20504": "Trimiterea e-mailului a eșuat. Vă rugăm să încercați din nou.",
    "error_20601": "Introduceți adresa de e-mail (cod de eroare: 20601)",
    "error_20602": "Vă rugăm să introduceți codul de verificare (cod de eroare: 20602)",
    "error_20603": "E-mailul nu este valid (cod de eroare: 20603)",
    "error_20604": "Solicitarea a eșuat (cod de eroare: 20604). Vă rugăm să încercați din nou",
    "error_20606": "Solicitarea a eșuat (cod de eroare: 20606). Vă rugăm să încercați din nou",
    "error_20607": "Solicitarea a eșuat (cod de eroare: 20607). Vă rugăm să încercați din nou",
    "error_20608": "Solicitarea a eșuat (cod de eroare: 20608). Vă rugăm să încercați din nou",
    "error_20701": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20701)",
    "error_20702": "E-mailul nu este valid (cod de eroare: 20702)",
    "error_20703": "Solicitarea a eșuat (cod de eroare: 20703). Vă rugăm să încercați din nou",
    "error_20704": "Contul nu există. Reintroduceți sau <a href=\"https://account.mindonmap.com/register/\">Creați-l</a> mai întâi.",
    "error_20705": "Solicitarea a eșuat (cod de eroare: 20705). Vă rugăm să încercați din nou",
    "error_20706": "Trimiterea e-mailului a eșuat. Vă rugăm să încercați din nou",
    "error_20801": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 20801)",
    "error_20802": "Eroare: 20802. Vă rugăm să ne contactați",
    "error_20803": "Vă rugăm să introduceți codul de verificare (cod de eroare: 20803)",
    "error_20804": "E-mailul nu este valid (cod de eroare: 20804)",
    "error_20805": "Este necesară o parolă de peste 8 caractere (cod de eroare: 20805)",
    "error_20806": "Solicitarea a eșuat (cod de eroare: 20806). Vă rugăm să încercați din nou",
    "error_20808": "Solicitarea a eșuat (cod de eroare: 20808). Vă rugăm să încercați din nou",
    "error_20901": "Solicitarea a eșuat (cod de eroare: 20901). Vă rugăm să încercați din nou",
    "error_20902": "Solicitarea a eșuat (cod de eroare: 20902). Vă rugăm să încercați din nou",
    "error_21000": "Modificările sunt salvate",
    "error_21001": "Nu sunt transmise informații (cod de eroare: 21001)",
    "error_21002": "Solicitarea a eșuat (cod de eroare: 21002). Vă rugăm să încercați din nou",
    "error_21101": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 21101)",
    "error_21102": "E-mailul nu este valid (cod de eroare: 21102)",
    "error_21103": "Solicitarea a eșuat (cod de eroare: 21103), vă rugăm să reîncercați din nou",
    "error_21104": "E-mailul este deja conectat, vă rugăm să utilizați unul nou",
    "error_21105": "Solicitarea a eșuat (cod de eroare: 21105), vă rugăm să reîncercați din nou",
    "error_21106": "Trimiterea e-mailului a eșuat. Vă rugăm să încercați din nou",
    "error_21201": "Vă rugăm să introduceți adresa de e-mail (cod de eroare: 21201)",
    "error_21202": "Vă rugăm să introduceți codul de verificare (cod de eroare: 21202)",
    "error_21203": "E-mailul nu este valid (cod de eroare: 21203)",
    "error_21204": "Eroare: 21204. Vă rugăm să ne contactați",
    "error_21205": "Eroare: 21205. Vă rugăm să ne contactați",
    "error_21206": "Este necesară o parolă de peste 8 caractere (cod de eroare: 21206)",
    "error_21207": "Solicitarea a eșuat (cod de eroare: 21207). Vă rugăm să încercați din nou",
    "error_21209": "Solicitarea a eșuat (cod de eroare: 21209). Vă rugăm să încercați din nou",
    "error_21301": "Introduceți parola veche (cod de eroare: 21301)",
    "error_21302": "Creați o nouă parolă (cod de eroare: 21302)",
    "error_21303": "Noua parolă nu poate fi aceeași cu cea veche. (Eroare: 21303)",
    "error_21304": "Este necesară o parolă de peste 8 caractere (cod de eroare: 21304)",
    "error_21306": "Solicitarea a eșuat (cod de eroare: 21306). Vă rugăm să încercați din nou",
    "error_21402": "Solicitarea a eșuat (cod de eroare: 21402). Vă rugăm să încercați din nou",
    "error_21403": "Nu s-a putut trimite codul de verificare. Vă rugăm să-l retrimiteți",
    "error_21500": "Contul a fost șters",
    "error_21501": "Vă rugăm să introduceți codul de verificare (cod de eroare: 21501)",
    "error_21502": "Sesiunea de conectare a expirat (Eroare: 21502). Te rog Logheaza te din nou.",
    "error_21503": "Solicitarea a eșuat (cod de eroare: 21503). Vă rugăm să încercați din nou",
    "error_21505": "Solicitarea a eșuat (cod de eroare: 21505), vă rugăm să reîncercați",
    "error_21601": "Eroare: 20601. Vă rugăm să ne contactați",
    "error_21602": "Verificare nevalidă (Eroare: 20602). Vă rugăm să încercați din nou.",
    "error_21603": "Eroare: 20603. Vă rugăm să încercați din nou",
    "error_21604": "Solicitarea a eșuat (cod de eroare: 21604). Vă rugăm să încercați din nou",
    "error_21606": "Solicitarea a eșuat (cod de eroare: 21606). Vă rugăm să încercați din nou",
    "error_21611": "Solicitarea a eșuat (cod de eroare: 21611). Vă rugăm să încercați din nou",
    "error_21801": "Eroare: 21801. Vă rugăm să ne contactați",
    "error_21802": "Solicitarea a eșuat (Eroare: 21802). Vă rugăm să încercați din nou",
    "error_21803": "Eroare: 21803. Vă rugăm să încercați din nou",
    "error_21804": "Solicitarea a eșuat (cod de eroare: 21804). Vă rugăm să încercați din nou",
    "error_21806": "Eroare: 21806. Vă rugăm să încercați din nou",
    "error_21807": "Eroare: 21807. Vă rugăm să ne contactați",
    "error_21808": "Eroare: 21808. Vă rugăm să ne contactați",
    "error_21809": "Eroare: 21809. Vă rugăm să ne contactați",
    "error_21810": "Eroare: 21810. Vă rugăm să ne contactați",
    "error_21811": "Eroare: 21811. Vă rugăm să ne contactați",
    "error_21812": "Eroare: 21812. Vă rugăm să ne contactați",
    "error_21813": "Solicitarea a eșuat (cod de eroare: 21813). Vă rugăm să încercați din nou",
    "error_21814": "Eroare: 21814. Vă rugăm să ne contactați",
    "error_21815": "Solicitarea a eșuat (cod de eroare: 21815). Vă rugăm să încercați din nou",
    "error_21816": "Eroare: 21816. Vă rugăm să ne contactați",
    "error_21817": "Eroare: 21817. Vă rugăm să ne contactați",
    "error_21818": "Eroare: 21818. Vă rugăm să ne contactați",
    "error_21819": "Solicitarea a eșuat (cod de eroare: 21819). Vă rugăm să încercați din nou",
    "error_21820": "Eroare: 21820. Vă rugăm să ne contactați",
    "error_21821": "Eroare: 21821. Vă rugăm să ne contactați",
    "error_21822": "Eroare: 21822. Vă rugăm să ne contactați",
    "error_21823": "Solicitarea a eșuat (cod de eroare: 21823). Vă rugăm să încercați din nou",
    "error_21824": "Solicitarea a eșuat (cod de eroare: 21824). Vă rugăm să încercați din nou",
    "error_21825": "Solicitarea a eșuat (cod de eroare: 21825). Vă rugăm să încercați din nou",
    "error_21826": "Solicitarea a eșuat (cod de eroare: 21826). Vă rugăm să încercați din nou",
    "error_21901": "Eroare: 21901. Vă rugăm să ne contactați",
    "error_21902": "Solicitarea a eșuat (cod de eroare: 21902). Vă rugăm să încercați din nou",
    "error_21903": "Starea contului s-a schimbat (cod de eroare: 21903), vă rugăm să reîmprospătați pagina și să încercați din nou",
    "error_21904": "Eroare: 21904. Vă rugăm să încercați din nou",
    "error_21905": "Eroare: 21905. Vă rugăm să încercați din nou",
    "error_21906": "Solicitarea a eșuat (cod de eroare: 21906). Vă rugăm să încercați din nou",
    "error_21907": "Contul Google a fost conectat la alt cont",
    "error_21908": "Solicitarea a eșuat (cod de eroare: 21908). Vă rugăm să încercați din nou",
    "error_22001": "Solicitarea a eșuat (cod de eroare: 22001). Vă rugăm să încercați din nou",
    "error_22002": "Deconectarea nu a reușit fără autentificare suplimentară",
    "error_22003": "Solicitarea a eșuat (cod de eroare: 22003). Vă rugăm să încercați din nou",
    "error_22101": "Eroare: 22101. Vă rugăm să ne contactați",
    "error_22102": "Starea contului s-a schimbat (cod de eroare: 22102), vă rugăm să reîmprospătați pagina și să încercați din nou",
    "error_22103": "Solicitarea a eșuat (cod de eroare: 22103). Vă rugăm să încercați din nou",
    "error_22104": "Starea contului s-a schimbat (cod de eroare: 22104), vă rugăm să reîmprospătați pagina și să încercați din nou",
    "error_22105": "Eroare: 22105. Vă rugăm să încercați din nou",
    "error_22106": "Eroare: 22106. Vă rugăm să încercați din nou",
    "error_22107": "Eroare: 22107. Vă rugăm să ne contactați",
    "error_22108": "Solicitarea a eșuat (cod de eroare: 22108). Vă rugăm să încercați din nou",
    "error_22201": "Eroare: 22201. Vă rugăm să ne contactați",
    "error_22202": "Verificare nevalidă (Eroare: 22202). Vă rugăm să încercați din nou.",
    "error_22203": "Eroare: 22203. Vă rugăm să încercați din nou\"",
    "error_22204": "Solicitarea a eșuat (cod de eroare: 22204). Vă rugăm să încercați din nou",
    "error_22206": "Solicitarea a eșuat (cod de eroare: 22206). Vă rugăm să încercați din nou",
    "error_22401": "Eroare: 22401. Vă rugăm să ne contactați",
    "error_22402": "Verificare nevalidă (Eroare: 22402). Vă rugăm să încercați din nou.",
    "error_22403": "Eroare: 22403. Vă rugăm să încercați din nou",
    "error_22404": "Solicitarea a eșuat (cod de eroare: 22404). Vă rugăm să încercați din nou",
    "error_22405": "Contul de Facebook a fost conectat la alt e-mail",
    "error_22406": "Eroare: 22406. Vă rugăm să încercați din nou",
    "error_22407": "Eroare: 22407. Vă rugăm să ne contactați",
    "error_22408": "Eroare: 22408. Vă rugăm să ne contactați",
    "error_22409": "Eroare: 22409. Vă rugăm să ne contactați",
    "error_22410": "Eroare: 224010. Vă rugăm să ne contactați",
    "error_22411": "Eroare: 224011. Vă rugăm să ne contactați",
    "error_22412": "Eroare: 224012. Vă rugăm să ne contactați",
    "error_22413": "Eroare: 22413. Vă rugăm să ne contactați",
    "error_22414": "Solicitarea a eșuat (cod de eroare: 22414). Vă rugăm să încercați din nou",
    "error_22415": "Eroare: 22415. Vă rugăm să ne contactați",
    "error_22416": "Eroare: 22416. Vă rugăm să ne contactați",
    "error_22417": "Eroare: 22417. Vă rugăm să ne contactați",
    "error_22418": "Solicitarea a eșuat (cod de eroare: 22418). Vă rugăm să încercați din nou",
    "error_22419": "Eroare: 22419. Vă rugăm să ne contactați",
    "error_22420": "Eroare: 22420. Vă rugăm să ne contactați",
    "error_22421": "Eroare: 22421. Vă rugăm să ne contactați",
    "error_22422": "Solicitarea a eșuat (cod de eroare: 22422). Vă rugăm să încercați din nou",
    "error_22423": "Solicitarea a eșuat (cod de eroare: 22423). Vă rugăm să încercați din nou",
    "error_22424": "Solicitarea a eșuat (cod de eroare: 22424). Vă rugăm să încercați din nou",
    "error_22425": "Solicitarea a eșuat (cod de eroare: 22425). Vă rugăm să încercați din nou",
    "error_20098": "Cod de eroare: 20098. Dacă navigați în modul privat, vă rugăm să comutați la modul Normal și încercați din nou.",
    "error_22298": "Solicitarea Google a eșuat (Eroare: 22298). Vă rugăm să încercați din nou.",
    "error_22498": "Solicitarea pentru Facebook a eșuat (Eroare: 22498). Vă rugăm să încercați din nou.",
    "error_24902": "Solicitarea a eșuat (cod de eroare: 24902). Vă rugăm să încercați din nou",
    "error_24903": "Solicitarea a eșuat (cod de eroare: 24903). Vă rugăm să încercați din nou",
    "error_24904": "Solicitarea a eșuat (cod de eroare: 24904). Vă rugăm să încercați din nou",
    "error_24905": "Solicitarea a eșuat (cod de eroare: 24905). Vă rugăm să încercați din nou",
    "login_title": "Conectați-vă la MindOnMap",
    "log_in": "Log in",
    "no_account": "Fără cont?",
    "create_it": "Creaza-l",
    "or_log_in_with": "Sau conectați-vă cu",
    "passwordless_login": "Conectare fără parolă",
    "log_in_done": "Conectare Gata",
    "three_rd_account_connect_info": "Felicitări! Te-ai autentificat cu succes. Acum puteți conecta un cont de e-mail care este utilizat pentru a vă conecta în viitor.",
    "see_my_account": "Vezi contul meu",
    "three_rd_login_merge_account": "Adresa de e-mail a contului terță parte a fost înregistrată, doriți să vă conectați și să vă autentificați direct cu această adresă de e-mail?",
    "connect_log_in": "Conectați-vă și autentificați-vă",
    "create_an_account": "Creați un cont",
    "back_to_log_in": "Înapoi la autentificare",
    "create_password": "Creaza parola",
    "create_now": "Creați acum",
    "password_login_subtitle": "Conectare fără parolă cu e-mail",
    "account_login": "Conectare la cont",
    "rights": "Prin crearea acestui cont, sunteți de acord cu <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Termenii și condițiile</a> și cu <a href=\"https://www.mindonmap.com/privacy-policy/\">Politica de confidențialitate</a>",
    "passwordless_login_done": "Conectare fără parolă finalizată",
    "passwordless_login_info": "Felicitări, ați finalizat cu succes autentificarea fără parolă. Puteți crea o parolă pentru acest cont și vă puteți conecta cu contul și parola în viitor. <a href=\"/create-password\" style=\"display: initial;\">Creați acum</a>",
    "sign_up": "Inscrie-te",
    "register_info": "Creeaza-ti contul",
    "reset_now": "Resetează acum",
    "forgot_password": "Aţi uitat parola",
    "reset_password_subtitle": "Utilizați e-mailul contului pentru a reseta parola",
    "plan_products": "Planuri și produse",
    "nick_name": "Nume de utilizator:",
    "email": "E-mail:",
    "my_products": "Produsele mele",
    "my_orders": "Comenzile mele",
    "unlink": "Deconectați",
    "link": "Legătură",
    "connected_accounts": "Conturi conectate",
    "last_name": "Nume:",
    "first_name": "Nume:",
    "Gender": "Gen:",
    "Birth": "Naștere:",
    "Month": "Lună",
    "Year": "An",
    "Country_Region": "Țara/Regiune:",
    "Address": "Abordare:",
    "Save": "Salvați",
    "Date": "Data",
    "Male": "Masculin",
    "Female": "Femeie",
    "Unspecified": "Nespecificat",
    "Security": "Securitate",
    "change_password": "Schimbaţi parola",
    "change_now": "Schimbați acum",
    "connect_email": "Conectați e-mailul",
    "delete_account": "Șterge cont",
    "delete_account_info": "Când contul dvs. este șters, toate datele din Contul meu asociate contului dvs. vor fi șterse definitiv și este posibil să nu le puteți recupera. Vă recomandăm să mergeți cu prudență.",
    "Delete": "Șterge",
    "Logout": "Deconectare",
    "my_profile": "Profilul meu",
    "guides_faqs": "Ghiduri și întrebări frecvente",
    "More": "Mai mult",
    "guides": "Ghiduri",
    "register": "Inregistreaza-te",
    "hot_faq": "Întrebări frecvente fierbinți",
    "Contents": "Continut:",
    "contact_us": "Contactați-ne>>",
    "plan": "Plan",
    "unregistered": "Neînregistrat",
    "buy_more": "Cumpărați mai mult",
    "buy_again": "Cumpărați din nou",
    "buy_now": "Cumpără acum",
    "free_no_limit": "Gratuit și fără limită",
    "expired": "Expirat",
    "lifetime": "Durata de viață",
    "remain": "Rămâne",
    "day_s": "Zile",
    "error_24801": "Solicitarea a eșuat (cod de eroare: 24801). Vă rugăm să încercați din nou",
    "no_app_found": "Nu a fost găsită nicio aplicație！<a href=\"/\">Actualizează</a> sau <a href=\"https://www.mindonmap.com/\">Accesează site-ul oficial</a>",
    "get_more": "Obțineți mai multe >>",
    "edit_photo": "Editeaza poza",
    "select_photo": "Selectați fotografia",
    "change_photo": "Schimbare fotografie",
    "cancel": "Anulare",
    "hide_password": "Ascunde parola",
    "show_password": "Arata parola",
    "zoom_in": "A mari",
    "zoom_out": "A micsora",
    "rotate": "Roti",
    "horizontal_flip": "Flip orizontal",
    "vertical_flip": "Flip vertical",
    "country": "Țară",
    "country_1": "Selectați țara/regiunea dvs.",
    "country_2": "Insula Åaland",
    "country_3": "Afganistan",
    "country_4": "Albania",
    "country_5": "Algeria",
    "country_6": "Samoa Americană",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarctica",
    "country_11": "Antigua și Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbaidjan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bielorusia",
    "country_22": "Belgia",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermude",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnia și Herțegovina",
    "country_29": "Botswana",
    "country_30": "Insula Bouvet",
    "country_31": "Brazilia",
    "country_32": "Teritoriul Britanic al Oceanului Indian",
    "country_33": "Insulele Virgine Britanice",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Cambodgia",
    "country_39": "Camerun",
    "country_40": "Canada",
    "country_41": "Capul Verde",
    "country_42": "Caraibe Țările de Jos",
    "country_43": "Insulele Cayman",
    "country_44": "Republica Africană Centrală",
    "country_45": "Ciad",
    "country_46": "Chile",
    "country_47": "China",
    "country_48": "Insula Craciunului",
    "country_49": "Insulele Cocos (Keeling).",
    "country_50": "Columbia",
    "country_51": "Insulele Cook",
    "country_52": "Costa Rica",
    "country_53": "Coasta de Fildeș",
    "country_54": "Croaţia",
    "country_55": "Cuba",
    "country_56": "Cipru",
    "country_57": "Republica Cehă",
    "country_58": "Republica Democrata din Congo",
    "country_59": "Danemarca",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Republica Dominicană",
    "country_63": "Ecuador",
    "country_64": "Egipt",
    "country_65": "El Salvador",
    "country_66": "Guineea Ecuatorială",
    "country_67": "Eritreea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Insulele Falkland",
    "country_71": "Insulele Feroe",
    "country_72": "State Federate de Micronezia",
    "country_73": "Fiji",
    "country_74": "Finlanda",
    "country_75": "Franţa",
    "country_76": "Guyana Franceză",
    "country_77": "Polinezia Franceză",
    "country_78": "Teritoriile din sudul Frantei",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgia",
    "country_82": "Germania",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Marea Britanie (Regatul Unit; Anglia)",
    "country_86": "Grecia",
    "country_87": "Groenlanda",
    "country_88": "Grenada",
    "country_89": "Guadelupa",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guineea",
    "country_94": "Guineea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Insula Heard și Insulele McDonald",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Ungaria",
    "country_101": "Islanda",
    "country_102": "India",
    "country_103": "Indonezia",
    "country_104": "Iranul",
    "country_105": "Irak",
    "country_106": "Irlanda",
    "country_107": "Insula Barbatului",
    "country_108": "Israel",
    "country_109": "Italia",
    "country_110": "Jamaica",
    "country_111": "Japonia",
    "country_112": "Jersey",
    "country_113": "Iordania",
    "country_114": "Kazahstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuweit",
    "country_118": "Kârgâzstan",
    "country_119": "Laos",
    "country_120": "Letonia",
    "country_121": "Liban",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libia",
    "country_125": "Liechtenstein",
    "country_126": "Lituania",
    "country_127": "Luxemburg",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaezia",
    "country_132": "Maldive",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Insulele Marshall",
    "country_136": "Martinica",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexic",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mongolia",
    "country_144": "Muntenegru",
    "country_145": "Montserrat",
    "country_146": "Maroc",
    "country_147": "Mozambic",
    "country_148": "Myanmar (Birmania)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Olanda",
    "country_153": "Noua Caledonie",
    "country_154": "Noua Zeelandă",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Insula Norfolk",
    "country_160": "Coreea de Nord",
    "country_161": "Insulele Mariane de Nord",
    "country_162": "Norvegia",
    "country_163": "Oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "teritoriile palestiniene",
    "country_167": "Panama",
    "country_168": "Papua Noua Guinee",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Insulele Pitcairn",
    "country_172": "Polonia",
    "country_173": "Portugalia",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Republica Macedonia (FYROM)",
    "country_177": "Republica Congo",
    "country_178": "Reuniune",
    "country_179": "România",
    "country_180": "Federația Rusă",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Franța)",
    "country_184": "Saint-Pierre și Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Arabia Saudită",
    "country_189": "Senegal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovacia",
    "country_195": "Slovenia",
    "country_196": "Insulele Solomon",
    "country_197": "Somalia",
    "country_198": "Africa de Sud",
    "country_199": "Georgia de Sud și Insula South Sandwich",
    "country_200": "Coreea de Sud",
    "country_201": "Sudul Sudanului",
    "country_202": "Spania",
    "country_203": "Sri Lanka",
    "country_204": "Sf. Elena & Dependențe",
    "country_205": "St. Kitts & Nevis",
    "country_206": "St Lucia",
    "country_207": "St. Vincent și Grenadinele",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swaziland",
    "country_211": "Suedia",
    "country_212": "Elveţia",
    "country_213": "Siria",
    "country_214": "Taiwan",
    "country_215": "Tadjikistan",
    "country_216": "Tanzania",
    "country_217": "Șablon:Date țară SJM Svalbard",
    "country_218": "Tailanda",
    "country_219": "Bahamas",
    "country_220": " Comore",
    "country_221": "Filipine",
    "country_222": "Timorul de Est (Timorul de Est)",
    "country_223": "A merge",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Curcan",
    "country_229": "Turkmenistan",
    "country_230": "Insulele Turks și Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ucraina",
    "country_234": "Emiratele Arabe Unite",
    "country_235": "Insulele periferice minore ale Statelor Unite",
    "country_236": "Statele Unite ale Americii (SUA)",
    "country_237": "Insulele Virgine Statele Unite",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatican (Sfântul Scaun)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis și Futuna",
    "country_245": "Sahara de Vest",
    "country_246": "Yemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Conectați-vă cu Google",
    "State": "Stat",
    "Activation_code": "Cod de activare",
    "Question": "Listați toate aplicațiile pe care v-ați autentificat",
    "Copy_complete": "Copiere finalizată",
    "footer": "Copyright © 2024 MindOnMap Studio. Toate drepturile rezervate.",
    "change_password_success": "parola a fost schimbată cu succes",
    "successful_login_title": "Logare succes",
    "product_page": "Pagina produsului>>",
    "successful_login_info": "Conectare finalizată. Închideți pagina curentă și reveniți la fila inițială pentru a continua cu următorul proces. Pagina curentă se va închide automat în 5 secunde. Dacă închiderea automată sau închiderea făcând clic pe butonul „Terminat” nu reușește, închideți direct această filă.",
    "successful_login_info_firefox": "Conectare finalizată. Închideți pagina curentă și reveniți la fila inițială pentru a continua cu următorul proces.",
    "my_account": "Contul meu",
    "my_history": "Istoria mea",
    "remove_watermark": "Eliminați filigranul",
    "no_history": "Fără istorie",
    "history_all": "Selectează tot",
    "history_open": "Deschis",
    "history_down": "Descarca",
    "history_delete": "Șterge",
    "history_clear": "Ștergeți nevalid",
    "images": "imagine(e)",
    "use_this_function": "Utilizați acest produs>>",
    "hd_downloading": "Beneficii de descărcare a imaginilor originale HD:",
    "lifetimeRemaining": "Viața rămasă",
    "Remaining": "Rămas",
    "email_verification": "Verificare a email-ului",
    "email_verification_info": "Am trimis mesajul de verificare la adresa dvs. de e-mail <span class=\"email\"></span> și vă rugăm să finalizați verificarea. După verificare, beneficiile vor fi sincronizate automat.",
    "wrong_email": "Adresă de e-mail greșită?",
    "click_here_to_modify": "Click aici pentru a modifica",
    "get_more_help": "Obțineți mai mult ajutor?",
    "click_here": "Click aici",
    "email_verification_info_success": "Felicitări pentru verificarea contului de e-mail.",
    "email_verification_info_error": "Verificarea a eșuat din cauza linkului expirat.",
    "registration_succeeded": "Înregistrare reușită",
    "registration_succeeded_info_1": "Felicitări! Te-ai inregistrat cu succes. Am trimis mesajul de verificare la adresa dvs. de e-mail <span class=\"email\"></span> și vă rugăm să finalizați verificarea pentru a utiliza beneficiile acestui e-mail.",
    "registration_succeeded_info_2": "Faceți clic pe „Terminat” pentru a reveni la pagina de pornire și a utiliza acest produs.",
    "registration_succeeded_info_3": "Faceți clic pe „Terminat” pentru a închide pagina curentă și a reveni la pagina de pornire pentru următoarele operațiuni. Dacă închiderea nu reușește, închideți manual fila.",
    "verify_email": "Verificați e-mail",
    "registered_email_not_verified": "E-mailul înregistrat nu a fost verificat, vă rugăm să îl verificați imediat.",
    "email_verification_time_1": "Nu ați primit un e-mail de verificare?",
    "email_verification_time_2": "După",
    "email_verification_time_3": "Faceți clic aici pentru al retrimite",
    "error_26301": "Cod de eroare: 26301, vă rugăm să ne contactați",
    "error_26302": "Cod de eroare: 26302, vă rugăm să ne contactați",
    "error_26303": "Eroare de format de e-mail (cod de eroare: 26303). Vă rugăm să introduceți din nou",
    "error_26304": "Se recomandă parola de peste 8 caractere (cod de eroare: 26304)",
    "error_26305": "Reuqest a eșuat (cod de eroare: 26305). Vă rugăm să reîncercați",
    "error_26306": "E-mailul a fost înregistrat, vă rugăm să <a href=\"https://account.mindonmap.com/login/\">accesați să vă conectați</a>",
    "error_26307": "Reuqest a eșuat (cod de eroare: 26307). Vă rugăm să reîncercați",
    "error_26308": "Reuqest a eșuat (cod de eroare: 26308). Vă rugăm să reîncercați",
    "error_26401": "Cod de eroare: 26401, reîncercați",
    "error_26402": "E-mailul a fost verificat (cod de eroare: 26402), reîncercați",
    "error_26403": "Reuqest a eșuat (cod de eroare: 26403). Vă rugăm să reîncercați",
    "error_26404": "Solicitarea a eșuat (cod de eroare: 26404). Vă rugăm să reîncercați",
    "error_26501": "Cod de eroare: 26501, vă rugăm să ne contactați",
    "error_26502": "Cod de eroare: 26502, vă rugăm să ne contactați",
    "error_26503": "Eroare de format de e-mail (cod de eroare: 26503). Vă rugăm să introduceți din nou",
    "error_26504": "Reuqest a eșuat (cod de eroare: 26504). Vă rugăm să reîncercați",
    "error_26505": "E-mailul nu a fost înregistrat, vă rugăm să <a href=\"https://account.mindonmap.com/register/\">înregistrați-l mai întâi</a>",
    "error_26506": "E-mailul a fost verificat.",
    "error_26507": "Reuqest a eșuat (cod de eroare: 26507). Vă rugăm să reîncercați",
    "error_26508": "Verificarea a fost finalizată (cod de eroare: 26508), reîncercați",
    "error_26509": "Solicitarea a eșuat (cod de eroare: 26509), vă rugăm să reîncercați",
    "error_26510": "Cod de eroare: 26510, vă rugăm să ne contactați",
    "error_26601": "Cod de eroare: 26601, vă rugăm să ne contactați",
    "error_26602": "Cod de eroare: 26602, vă rugăm să ne contactați",
    "error_26603": "Reuqest a eșuat (cod de eroare: 26603). Vă rugăm să reîncercați",
    "error_26604": "Cod de eroare: 26604, vă rugăm să ne contactați",
    "error_26605": "Cod de eroare: 26605, vă rugăm să ne contactați",
    "error_26701": "Cod de eroare: 26701, vă rugăm să ne contactați",
    "error_26702": "Reuqest a eșuat (cod de eroare: 26702). Vă rugăm să reîncercați",
    "error_26703": "Cod de eroare: 26703, vă rugăm să ne contactați",
    "error_26704": "Cod de eroare: 26704, vă rugăm să ne contactați",
    "error_26705": "Așteptați conectarea (cod de eroare: 26705). Vă rugăm să reîncercați",
    "no_cookie": "Ați activat funcția Blocare toate cookie-urile din browser, așa că nu vă puteți conecta. Vă rugăm să accesați Setări pentru a bifa caseta Permiteți toate cookie-urile.",
    "error_26801": "Cod de eroare: 26801, vă rugăm să ne contactați",
    "error_26802": "Cod de eroare: 26802, vă rugăm să ne contactați",
    "error_26803": "Solicitarea a eșuat (cod de eroare: 26803). Vă rugăm să reîncercați",
    "error_26804": "Solicitarea a eșuat (cod de eroare: 26804). Vă rugăm să reîncercați",
    "error_order": "Solicitarea a eșuat (cod de eroare: 27098), vă rugăm să încercați din nou!",
    "error_order1": "Interogarea comenzii este incompletă (cod de eroare: ",
    "error_order2": "）, vă rugăm să reîmprospătați și să încercați din nou.",
    "modify_email_title": "Modificați e-mailul",
    "modify_email_info": "Puteți folosi e-mailul modificat pentru a vă conecta la contul dvs.",
    "images_per": "Imagini per",
    "error_26101": "Eroare: 26101. Vă rugăm să ne contactați",
    "error_26102": "Eroare: 26102. Vă rugăm să ne contactați",
    "error_26103": "Solicitarea nu a reușit (cod de eroare: 26103). Încercați din nou",
    "error_26104": "Cod de eroare: 26104, vă rugăm să reîncercați",
    "error_26105": "Cod de eroare: 26105, vă rugăm să reîncercați",
    "error_26106": "Ștergerea nu a reușit (cod de eroare: 26106). Vă rugăm să încercați din nou",
    "error_26201": "Eroare: 26201. Vă rugăm să ne contactați",
    "error_26202": "Solicitarea nu a reușit (cod de eroare: 26202). Încercați din nou",
    "error_26001": "Eroare: 26001. Vă rugăm să ne contactați",
    "error_26002": "Eroare: 26002. Vă rugăm să ne contactați",
    "error_26003": "Eroare: 26003. Vă rugăm să ne contactați",
    "error_26004": "Eroare: 26004. Vă rugăm să ne contactați",
    "error_26005": "Solicitarea a eșuat (cod de eroare: 26005). Încercați din nou",
    "error_26006": "Cod de eroare: 26006, vă rugăm să reîncercați",
    "error_26008": "Eroare: 26008. Vă rugăm să ne contactați",
    "go_to_the_home_page": "Accesați pagina de pornire",
    "error_27101": "Solicitarea a eșuat (cod de eroare: 27101). Vă rugăm să reîncercați",
    "error_27201": "Cod de eroare: 27201, vă rugăm să ne contactați",
    "error_27202": "Cod de eroare: 27202, reîncercați",
    "error_27203": "Solicitarea a eșuat (cod de eroare: 27203). Vă rugăm să reîncercați",
    "error_27204": "Cod nevalid (cod de eroare: 27204).",
    "error_27205": "Solicitarea a eșuat (cod de eroare: 27205). Vă rugăm să reîncercați",
    "error_27206": "Solicitarea a eșuat (cod de eroare: 27206). Vă rugăm să reîncercați",
    "error_27207": "Solicitarea a eșuat (cod de eroare: 27207). Vă rugăm să reîncercați",
    "no_history_found": "Nu ai folosit niciun instrument! <a href=\"/\">Actualizați</a> sau <a href=\"https://www.mindonmap.com/\">Accesați site-ul oficial</a>",
    "error_25301": "Eroare: 25301. Vă rugăm să ne contactați",
    "error_25302": "Eroare: 25302. Vă rugăm să ne contactați",
    "error_25303": "Solicitarea nu a reușit (cod de eroare: 25303). Vă rugăm să încercați din nou",
    "error_25304": "Solicitarea nu a reușit (cod de eroare: 25304). Vă rugăm să încercați din nou",
    "error_25305": "Solicitarea nu a reușit (cod de eroare: 25305). Vă rugăm să încercați din nou",
    "error_25306": "Solicitarea nu a reușit (cod de eroare: 25306). Vă rugăm să încercați din nou",
    "image_upscaler_p": "Beneficii de descărcare a imaginii fără filigran:",
    "Available_for": "Disponibil pentru:",
    "credit_per": "%s credit(e) pentru fiecare imagine HD",
    "still_valid": "Planurile achiziționate sunt încă valabile",
    "credit": "credite)",
    "pc_3rd_info": "Conectați-vă cu succes. Vă rugăm să accesați aplicația pentru operațiuni ulterioare.",
    "use_online": "Utilizați serviciul online",
    "use_download": "Utilizați programul desktop",
    "use_immediately": "Utilizați Imediat",
    "Use_in_browser": "Utilizați în Browser",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} credite pe lună",
    "expire": "Data expirarii:",
    "viewDetails": "Vezi detalii",
    "viewHistory": "Vedeți beneficiile istoricului>>",
    "viewDetailsInfo": "Notă: Dacă beneficiile abonamentului sunt reînnoite în 7 zile de la expirare, beneficiile neutilizate pot fi utilizate în continuare. De asemenea, timpul de expirare va fi actualizat automat la ora de expirare a noului abonament. Dacă nu există niciun abonament nou după 7 zile de la expirare, toate beneficiile expirate vor fi șterse.",
    "connect_account": "Legați e-mailul la contul dvs",
    "connect_account_info": "După legare, vă puteți conecta cu această adresă de e-mail.",
    "connect_now": "Leagă acum",
    "no_email_bind": "Nicio legătură prin e-mail",
    "bind_email": "Legați e-mailul",
    "connect_your_email_placeholder": "Te rugam sa introduci adresa ta de email",
    "bind_an_email": "Legați un e-mail",
    "bind_info": "Te-ai autentificat cu succes. Vă rugăm să legați un e-mail la contul dvs. pentru a vă activa beneficiile.",
    "bind_later": "Leagă mai târziu",
    "hi": "A lui!",
    "Personal_Information": "Informații personale",
    "Access": "Acces",
    "Subscription_Plan": "(Plan de abonament)",
    "No_orders": "Nu au fost găsite comenzi.",
    "No_data": "Nu există date",
    "Featured_Products": "Produse recomandate",
    "More_Products": "Mai multe produse",
    "Free_Download": "Descărcare gratuită",
    "Get_Started": "Incepe",
    "Subscribe": "Abonati-va",
    "Verified": "Verificat",
    "back_to_account_center": "Înapoi la Centrul de conturi",
    "success": "Succes!",
    "successfully": "Ați înregistrat cu succes un cont.",
    "Continue": "Continua",
    "Already": "ai deja un cont?",
    "loading_verification": "Se verifică starea verificării...",
    "email_no_verification": "Ne pare rău, adresa de e-mail înregistrată nu a fost verificată. Vă rugăm să finalizați verificarea conform instrucțiunilor de mai sus și să faceți clic din nou pe butonul „Efectuat” pentru a finaliza înregistrarea.",
    "will_expire_after": "Va expira dupa",
    "hours": "ore",
  },
  "ru": {
    "overtime": "Код ошибки: {%}, повторите попытку.",
    "isnetwork": "Ошибка Интернета. Пожалуйста, проверьте и повторите попытку",
    "email_placeholder": "Электронная почта",
    "email_empty": "Пожалуйста, введите адрес электронной почты",
    "email_not_valid": "Email не является допустимым",
    "email_not_valid_1": "Пожалуйста, введите свой адрес электронной почты",
    "email_not_valid_2": "Электронная почта недействительна, используйте другой адрес.",
    "email_not_valid_3": "Нет ввода электронной почты",
    "password_placeholder": "Пароль",
    "password_empty": "Пожалуйста, введите пароль",
    "password_not_valid": "Неверная учетная запись или пароль",
    "password_not_valid_1": "Требуется пароль длиной более 8 символов.",
    "password_not_valid_2": "Пожалуйста, создайте пароль",
    "password_placeholder_1": "Создайте свой пароль",
    "password_placeholder_2": "Подтвердите ваш пароль",
    "password_placeholder_3": "Создать новый пароль",
    "password_placeholder_4": "Подтвердите новый пароль",
    "password_placeholder_5": "Введите старый пароль",
    "copy_password_empty": "Пожалуйста, подтвердите пароль",
    "copy_password_not_valid": "Пожалуйста, подтвердите свой пароль",
    "copy_passwords_inconsistent": "Ваше подтверждение пароля не соответствует",
    "code_empty": "Пожалуйста, введите код подтверждения",
    "code_not_valid": "неверный код подтверждения",
    "code_placeholder": "Проверочный код",
    "not_received_code": "Если ваш почтовый ящик не получил код подтверждения в течение длительного времени, пожалуйста, получите код подтверждения еще раз.",
    "get_first_code": "Пожалуйста, сначала получите код подтверждения.",
    "last_name_placeholder": "Пожалуйста, введите свою фамилию",
    "first_name_placeholder": "Пожалуйста, введите ваше имя",
    "address_placeholder": "Пожалуйста, введите ваш адрес",
    "no_code_text": "Мы отправили код подтверждения. Пожалуйста, введите ваш код. <span class='tips'>Не получили код?",
    "no_code_text_1": "1. Убедитесь, что адрес электронной почты действителен и на него можно получать электронные письма.",
    "no_code_text_2": "2. Поскольку электронное письмо отправляется системой автоматически, оно может быть помечено как спам или нежелательная почта. Пожалуйста, проверьте, находится ли письмо в папке «Корзина».",
    "no_code_text_3": "3. Не можете решить свою проблему? ",
    "no_code_text_3_span": "Тогда нажмите здесь, чтобы связаться с нами.",
    "order_no": "Вы не приобрели ни одного продукта. Если у вас есть вопросы, <a href=\"https://www.mindonmap.com/contact-us/\">свяжитесь с нами</a>.",
    "error_24901": "Текущая учетная запись не привязана к электронной почте и не может найти заказы. Пожалуйста, подключите электронную почту.",
    "user_guide": "Руководство пользователя>>",
    "download": "Скачать",
    "order_number": "Номер заказа:",
    "Refund": "Возвращать деньги",
    "Disabled": "Неполноценный",
    "Normal": "Нормальный",
    "Modify": "Изменить",
    "Modify_1": "Изменить>>",
    "Connect": "Соединять",
    "unlink_success": "Отсоединиться успешно",
    "connect_success": "Подключитесь успешно",
    "feedback_title": "Спасибо за ваш отзыв. Пожалуйста, оставьте свою проблему, и мы ответим вам в течение 24 часов.",
    "feedback_thank_you": "Спасибо!<br />Ваш отзыв успешно отправлен.",
    "feedback_email": "Введите свой адрес электронной почты здесь!",
    "feedback_content": "Оставьте здесь любую проблему или предложение, с которым вы столкнулись.",
    "feedback_submit": "Представлять на рассмотрение",
    "form_contents": "Вы не ввели никакого описания. Пожалуйста, введите его и отправьте еще раз.",
    "old_password": "Пожалуйста, введите старый пароль",
    "new_password": "Пожалуйста, создайте новый пароль",
    "old_new_password": "Новый пароль не может совпадать со старым",
    "incorrect_password": "неверный пароль",
    "delete_no": "Удалить сейчас",
    "Caps": "Включен верхний регистр",
    "Get": "Получать",
    "Done": "Сделанный",
    "error_20001": "Ошибка: 20001. Пожалуйста, войдите снова.",
    "error_20002": "Ошибка: 20002. Пожалуйста, войдите снова.",
    "error_20003": "Ошибка: 20003. Пожалуйста, войдите снова.",
    "error_20004": "Запрос не выполнен (код ошибки: 20004). Пожалуйста, попробуйте еще раз.",
    "error_20005": "Срок действия сеанса входа истек (Ошибка: 20005). Пожалуйста, войдите снова.",
    "error_20006": "Запрос не выполнен (код ошибки: 20006). Пожалуйста, попробуйте еще раз.",
    "error_20007": "Срок действия сеанса входа истек (Ошибка: 20007). Пожалуйста, войдите снова.",
    "error_20008": "Срок действия сеанса входа истек (Ошибка: 20008). Пожалуйста, войдите снова.",
    "error_20009": "Срок действия сеанса входа истек (Ошибка: 20009). Пожалуйста, войдите снова.",
    "error_20101": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20101)",
    "error_20102": "Электронная почта недействительна (код ошибки: 20102).",
    "error_20103": "Запрос не выполнен (код ошибки: 20103). Пожалуйста, попробуйте еще раз",
    "error_20104": "Адрес электронной почты уже используется. <a href=\"https://account.mindonmap.com/login/\">войдите</a> или зарегистрируйте новый.",
    "error_20105": "Запрос не выполнен (код ошибки: 20105). Пожалуйста, попробуйте еще раз",
    "error_20106": "Не удалось отправить электронное письмо, повторите попытку.",
    "error_20201": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20201)",
    "error_20202": "Пожалуйста, введите свой пароль (код ошибки: 20202)",
    "error_20203": "Пожалуйста, введите код подтверждения (код ошибки: 20203)",
    "error_20204": "Электронная почта недействительна (код ошибки: 20204).",
    "error_20205": "Требуется пароль длиной более 8 символов (код ошибки: 20205).",
    "error_20206": "Запрос не выполнен (код ошибки: 20206). Пожалуйста, попробуйте еще раз",
    "error_20207": "неверный код подтверждения",
    "error_20208": "Запрос не выполнен (код ошибки: 20208). Пожалуйста, попробуйте еще раз",
    "error_20209": "Запрос не выполнен (код ошибки: 20209). Пожалуйста, попробуйте еще раз",
    "error_20301": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20301)",
    "error_20302": "Ошибка: 20302. Пожалуйста, свяжитесь с нами.",
    "error_20303": "Электронная почта недействительна (код ошибки: 20303).",
    "error_20304": "Запрос не выполнен (код ошибки: 20304). Пожалуйста, попробуйте еще раз",
    "error_20305": "Аккаунт не существует. Пожалуйста, введите его повторно или сначала <a href=\"https://account.mindonmap.com/register/\">создайте его</a>.",
    "error_20306": "Пароля пока нет. Используйте <a href=\"https://account.mindonmap.com/passwordless-login/\">вход без пароля</a> или <a href=\"https://account.mindonmap.com/create-password/\"> установите пароль</a> и войдите в систему.",
    "error_20308": "Запрос не выполнен (код ошибки: 20308). Пожалуйста, попробуйте еще раз",
    "error_20401": "Не удалось выйти из системы (код ошибки: 20401). Пожалуйста, попробуйте еще раз",
    "error_20501": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20501)",
    "error_20502": "Электронная почта недействительна (код ошибки: 20502).",
    "error_20503": "Запрос не выполнен (код ошибки: 20503). Пожалуйста, попробуйте еще раз",
    "error_20504": "Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз.",
    "error_20601": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20601)",
    "error_20602": "Пожалуйста, введите код подтверждения (код ошибки: 20602)",
    "error_20603": "Электронная почта недействительна (код ошибки: 20603).",
    "error_20604": "Запрос не выполнен (код ошибки: 20604). Пожалуйста, попробуйте еще раз",
    "error_20606": "Запрос не выполнен (код ошибки: 20606). Пожалуйста, попробуйте еще раз",
    "error_20607": "Запрос не выполнен (код ошибки: 20607). Пожалуйста, попробуйте еще раз",
    "error_20608": "Запрос не выполнен (код ошибки: 20608). Пожалуйста, попробуйте еще раз",
    "error_20701": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20701)",
    "error_20702": "Электронная почта недействительна (код ошибки: 20702).",
    "error_20703": "Запрос не выполнен (код ошибки: 20703). Пожалуйста, попробуйте еще раз",
    "error_20704": "Аккаунт не существует. Пожалуйста, введите его повторно или сначала <a href=\"https://account.mindonmap.com/register/\">создайте его</a>.",
    "error_20705": "Запрос не выполнен (код ошибки: 20705). Пожалуйста, попробуйте еще раз",
    "error_20706": "Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз",
    "error_20801": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 20801)",
    "error_20802": "Ошибка: 20802. Пожалуйста, свяжитесь с нами.",
    "error_20803": "Пожалуйста, введите код подтверждения (код ошибки: 20803)",
    "error_20804": "Электронная почта недействительна (код ошибки: 20804).",
    "error_20805": "Требуется пароль длиной более 8 символов (код ошибки: 20805).",
    "error_20806": "Запрос не выполнен (код ошибки: 20806). Пожалуйста, попробуйте еще раз",
    "error_20808": "Запрос не выполнен (код ошибки: 20808). Пожалуйста, попробуйте еще раз",
    "error_20901": "Запрос не выполнен (код ошибки: 20901). Пожалуйста, попробуйте еще раз",
    "error_20902": "Запрос не выполнен (код ошибки: 20902). Пожалуйста, попробуйте еще раз",
    "error_21000": "Изменения сохранены.",
    "error_21001": "Информация не передается (код ошибки: 21001).",
    "error_21002": "Запрос не выполнен (код ошибки: 21002). Пожалуйста, попробуйте еще раз",
    "error_21101": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 21101)",
    "error_21102": "Электронная почта недействительна (код ошибки: 21102).",
    "error_21103": "Запрос не выполнен (код ошибки: 21103), повторите попытку.",
    "error_21104": "Электронная почта уже подключена, используйте новую.",
    "error_21105": "Запрос не выполнен (код ошибки: 21105), повторите попытку.",
    "error_21106": "Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз",
    "error_21201": "Пожалуйста, введите свой адрес электронной почты (код ошибки: 21201)",
    "error_21202": "Пожалуйста, введите код подтверждения (код ошибки: 21202)",
    "error_21203": "Электронная почта недействительна (код ошибки: 21203).",
    "error_21204": "Ошибка: 21204. Пожалуйста, свяжитесь с нами.",
    "error_21205": "Ошибка: 21205. Пожалуйста, свяжитесь с нами.",
    "error_21206": "Требуется пароль длиной более 8 символов (код ошибки: 21206).",
    "error_21207": "Запрос не выполнен (код ошибки: 21207). Пожалуйста, попробуйте еще раз",
    "error_21209": "Запрос не выполнен (код ошибки: 21209). Пожалуйста, попробуйте еще раз",
    "error_21301": "Пожалуйста, введите старый пароль (код ошибки: 21301)",
    "error_21302": "Пожалуйста, создайте новый пароль (код ошибки: 21302).",
    "error_21303": "Новый пароль не может совпадать со старым. (Ошибка: 21303)",
    "error_21304": "Требуется пароль длиной более 8 символов (код ошибки: 21304).",
    "error_21306": "Запрос не выполнен (код ошибки: 21306). Пожалуйста, попробуйте еще раз",
    "error_21402": "Запрос не выполнен (код ошибки: 21402). Пожалуйста, попробуйте еще раз",
    "error_21403": "Не удалось отправить код подтверждения. Пожалуйста, отправьте его еще раз",
    "error_21500": "Аккаунт удален",
    "error_21501": "Пожалуйста, введите код подтверждения (код ошибки: 21501)",
    "error_21502": "Срок действия сеанса входа истек (Ошибка: 21502). Пожалуйста, войдите снова.",
    "error_21503": "Запрос не выполнен (код ошибки: 21503). Пожалуйста, попробуйте еще раз",
    "error_21505": "Запрос не выполнен (код ошибки: 21505), повторите попытку.",
    "error_21601": "Ошибка: 20601. Пожалуйста, свяжитесь с нами.",
    "error_21602": "Недействительная проверка (ошибка: 20602). Пожалуйста, попробуйте еще раз.",
    "error_21603": "Ошибка: 20603. Повторите попытку.",
    "error_21604": "Запрос не выполнен (код ошибки: 21604). Пожалуйста, попробуйте еще раз",
    "error_21606": "Запрос не выполнен (код ошибки: 21606). Пожалуйста, попробуйте еще раз",
    "error_21611": "Запрос не выполнен (код ошибки: 21611). Пожалуйста, попробуйте еще раз",
    "error_21801": "Ошибка: 21801. Пожалуйста, свяжитесь с нами.",
    "error_21802": "Запрос не выполнен (ошибка: 21802). Пожалуйста, попробуйте еще раз",
    "error_21803": "Ошибка: 21803. Повторите попытку.",
    "error_21804": "Запрос не выполнен (код ошибки: 21804). Пожалуйста, попробуйте еще раз",
    "error_21806": "Ошибка: 21806. Повторите попытку.",
    "error_21807": "Ошибка: 21807. Пожалуйста, свяжитесь с нами.",
    "error_21808": "Ошибка: 21808. Пожалуйста, свяжитесь с нами.",
    "error_21809": "Ошибка: 21809. Пожалуйста, свяжитесь с нами.",
    "error_21810": "Ошибка: 21810. Пожалуйста, свяжитесь с нами.",
    "error_21811": "Ошибка: 21811. Пожалуйста, свяжитесь с нами.",
    "error_21812": "Ошибка: 21812. Пожалуйста, свяжитесь с нами.",
    "error_21813": "Запрос не выполнен (код ошибки: 21813). Пожалуйста, попробуйте еще раз",
    "error_21814": "Ошибка: 21814. Пожалуйста, свяжитесь с нами.",
    "error_21815": "Запрос не выполнен (код ошибки: 21815). Пожалуйста, попробуйте еще раз",
    "error_21816": "Ошибка: 21816. Пожалуйста, свяжитесь с нами.",
    "error_21817": "Ошибка: 21817. Пожалуйста, свяжитесь с нами.",
    "error_21818": "Ошибка: 21818. Пожалуйста, свяжитесь с нами.",
    "error_21819": "Запрос не выполнен (код ошибки: 21819). Пожалуйста, попробуйте еще раз",
    "error_21820": "Ошибка: 21820. Пожалуйста, свяжитесь с нами.",
    "error_21821": "Ошибка: 21821. Пожалуйста, свяжитесь с нами.",
    "error_21822": "Ошибка: 21822. Пожалуйста, свяжитесь с нами.",
    "error_21823": "Запрос не выполнен (код ошибки: 21823). Пожалуйста, попробуйте еще раз",
    "error_21824": "Запрос не выполнен (код ошибки: 21824). Пожалуйста, попробуйте еще раз",
    "error_21825": "Запрос не выполнен (код ошибки: 21825). Пожалуйста, попробуйте еще раз",
    "error_21826": "Запрос не выполнен (код ошибки: 21826). Пожалуйста, попробуйте еще раз",
    "error_21901": "Ошибка: 21901. Пожалуйста, свяжитесь с нами.",
    "error_21902": "Запрос не выполнен (код ошибки: 21902). Пожалуйста, попробуйте еще раз",
    "error_21903": "Статус учетной записи изменился (код ошибки: 21903), обновите страницу и повторите попытку.",
    "error_21904": "Ошибка: 21904. Повторите попытку.",
    "error_21905": "Ошибка: 21905. Повторите попытку.",
    "error_21906": "Запрос не выполнен (код ошибки: 21906). Пожалуйста, попробуйте еще раз",
    "error_21907": "Аккаунт Google был связан с другим аккаунтом",
    "error_21908": "Запрос не выполнен (код ошибки: 21908). Пожалуйста, попробуйте еще раз",
    "error_22001": "Запрос не выполнен (код ошибки: 22001). Пожалуйста, попробуйте еще раз",
    "error_22002": "Не удалось отменить связь без дополнительного входа в систему.",
    "error_22003": "Запрос не выполнен (код ошибки: 22003). Пожалуйста, попробуйте еще раз",
    "error_22101": "Ошибка: 22101. Пожалуйста, свяжитесь с нами.",
    "error_22102": "Статус учетной записи изменился (код ошибки: 22102), обновите страницу и повторите попытку.",
    "error_22103": "Запрос не выполнен (код ошибки: 22103). Пожалуйста, попробуйте еще раз",
    "error_22104": "Статус учетной записи изменился (код ошибки: 22104), обновите страницу и повторите попытку.",
    "error_22105": "Ошибка: 22105. Повторите попытку.",
    "error_22106": "Ошибка: 22106. Повторите попытку.",
    "error_22107": "Ошибка: 22107. Пожалуйста, свяжитесь с нами.",
    "error_22108": "Запрос не выполнен (код ошибки: 22108). Пожалуйста, попробуйте еще раз",
    "error_22201": "Ошибка: 22201. Пожалуйста, свяжитесь с нами.",
    "error_22202": "Недействительная проверка (ошибка: 22202). Пожалуйста, попробуйте еще раз.",
    "error_22203": "Ошибка: 22203. Пожалуйста, попробуйте еще раз».",
    "error_22204": "Запрос не выполнен (код ошибки: 22204). Пожалуйста, попробуйте еще раз",
    "error_22206": "Запрос не выполнен (код ошибки: 22206). Пожалуйста, попробуйте еще раз",
    "error_22401": "Ошибка: 22401. Пожалуйста, свяжитесь с нами.",
    "error_22402": "Недействительная проверка (ошибка: 22402). Пожалуйста, попробуйте еще раз.",
    "error_22403": "Ошибка: 22403. Повторите попытку.",
    "error_22404": "Запрос не выполнен (код ошибки: 22404). Пожалуйста, попробуйте еще раз",
    "error_22405": "Аккаунт Facebook был связан с другим адресом электронной почты",
    "error_22406": "Ошибка: 22406. Повторите попытку.",
    "error_22407": "Ошибка: 22407. Пожалуйста, свяжитесь с нами.",
    "error_22408": "Ошибка: 22408. Пожалуйста, свяжитесь с нами.",
    "error_22409": "Ошибка: 22409. Пожалуйста, свяжитесь с нами.",
    "error_22410": "Ошибка: 224010. Пожалуйста, свяжитесь с нами.",
    "error_22411": "Ошибка: 224011. Пожалуйста, свяжитесь с нами.",
    "error_22412": "Ошибка: 224012. Пожалуйста, свяжитесь с нами.",
    "error_22413": "Ошибка: 22413. Пожалуйста, свяжитесь с нами.",
    "error_22414": "Запрос не выполнен (код ошибки: 22414). Пожалуйста, попробуйте еще раз",
    "error_22415": "Ошибка: 22415. Пожалуйста, свяжитесь с нами.",
    "error_22416": "Ошибка: 22416. Пожалуйста, свяжитесь с нами.",
    "error_22417": "Ошибка: 22417. Пожалуйста, свяжитесь с нами.",
    "error_22418": "Запрос не выполнен (код ошибки: 22418). Пожалуйста, попробуйте еще раз",
    "error_22419": "Ошибка: 22419. Пожалуйста, свяжитесь с нами.",
    "error_22420": "Ошибка: 22420. Пожалуйста, свяжитесь с нами.",
    "error_22421": "Ошибка: 22421. Пожалуйста, свяжитесь с нами.",
    "error_22422": "Запрос не выполнен (код ошибки: 22422). Пожалуйста, попробуйте еще раз",
    "error_22423": "Запрос не выполнен (код ошибки: 22423). Пожалуйста, попробуйте еще раз",
    "error_22424": "Запрос не выполнен (код ошибки: 22424). Пожалуйста, попробуйте еще раз",
    "error_22425": "Запрос не выполнен (код ошибки: 22425). Пожалуйста, попробуйте еще раз",
    "error_20098": "Код ошибки: 20098. Если вы просматриваете сайт в приватном режиме, переключитесь в обычный режим и повторите попытку.",
    "error_22298": "Запрос Google не выполнен (ошибка: 22298). Пожалуйста, попробуйте еще раз.",
    "error_22498": "Запрос на Facebook не выполнен (ошибка: 22498). Пожалуйста, попробуйте еще раз.",
    "error_24902": "Запрос не выполнен (код ошибки: 24902). Пожалуйста, попробуйте еще раз",
    "error_24903": "Запрос не выполнен (код ошибки: 24903). Пожалуйста, попробуйте еще раз",
    "error_24904": "Запрос не выполнен (код ошибки: 24904). Пожалуйста, попробуйте еще раз",
    "error_24905": "Запрос не выполнен (код ошибки: 24905). Пожалуйста, попробуйте еще раз",
    "login_title": "Войдите в MindOnMap",
    "log_in": "Авторизоваться",
    "no_account": "Нет аккаунта?",
    "create_it": "Создать это",
    "or_log_in_with": "Или войдите через",
    "passwordless_login": "Беспарольный вход",
    "log_in_done": "Войти Готово",
    "three_rd_account_connect_info": "Поздравляем! Вы успешно вошли в систему. Теперь вы можете подключить одну учетную запись электронной почты, которая будет использоваться для входа в систему в дальнейшем.",
    "see_my_account": "Посмотреть мой аккаунт",
    "three_rd_login_merge_account": "Адрес электронной почты сторонней учетной записи зарегистрирован. Хотите напрямую подключиться и войти в систему с этим адресом электронной почты?",
    "connect_log_in": "Подключиться и войти",
    "create_an_account": "Завести аккаунт",
    "back_to_log_in": "Вернуться на страницу авторизации",
    "create_password": "Придумайте пароль",
    "create_now": "Создать сейчас",
    "password_login_subtitle": "Беспарольный вход по электронной почте",
    "account_login": "Логин аккаунта",
    "rights": "Создавая эту учетную запись, вы соглашаетесь с <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Условиями использования</a> и <a href=\"https://www.mindonmap.com/privacy-policy/\">Политика конфиденциальности</a>",
    "passwordless_login_done": "Вход без пароля выполнен.",
    "passwordless_login_info": "Поздравляем, вы успешно завершили вход без пароля. Вы можете создать пароль для этой учетной записи и входить в систему, используя эту учетную запись и пароль в будущем. <a href=\"/create-password\" style=\"display: Initial;\">Создать сейчас</a>",
    "sign_up": "Зарегистрироваться",
    "register_info": "Создать аккаунт",
    "reset_now": "Сбросить сейчас",
    "forgot_password": "Забыли пароль",
    "reset_password_subtitle": "Используйте адрес электронной почты своей учетной записи для сброса пароля",
    "plan_products": "Планы и продукты",
    "nick_name": "Имя пользователя:",
    "email": "Электронная почта:",
    "my_products": "Мои продукты",
    "my_orders": "мои заказы",
    "unlink": "Отсоединить",
    "link": "Связь",
    "connected_accounts": "Подключенные аккаунты",
    "last_name": "Фамилия:",
    "first_name": "Имя:",
    "Gender": "Пол:",
    "Birth": "Рождение:",
    "Month": "Месяц",
    "Year": "Год",
    "Country_Region": "Страна/регион:",
    "Address": "Адрес:",
    "Save": "Сохранять",
    "Date": "Дата",
    "Male": "Мужской",
    "Female": "Женский",
    "Unspecified": "Неопределенные",
    "Security": "Безопасность",
    "change_password": "Изменить пароль",
    "change_now": "Изменить сейчас",
    "connect_email": "Подключить электронную почту",
    "delete_account": "Удалить аккаунт",
    "delete_account_info": "Когда ваша учетная запись будет удалена, все данные в «Моей учетной записи», связанные с вашей учетной записью, будут удалены без возможности восстановления, и вы, возможно, не сможете их восстановить. Мы рекомендуем вам действовать осторожно.",
    "Delete": "Удалить",
    "Logout": "Выйти",
    "my_profile": "Мой профайл",
    "guides_faqs": "Руководства и часто задаваемые вопросы",
    "More": "Более",
    "guides": "Путеводители",
    "register": "регистр",
    "hot_faq": "Горячие часто задаваемые вопросы",
    "Contents": "Содержание:",
    "contact_us": "Свяжитесь с нами>>",
    "plan": "План",
    "unregistered": "незарегистрированный",
    "buy_more": "Купить больше",
    "buy_again": "Купить снова",
    "buy_now": "Купить сейчас",
    "free_no_limit": "Бесплатно и без ограничений",
    "expired": "Истекший",
    "lifetime": "Продолжительность жизни",
    "remain": "Оставаться",
    "day_s": "День(а)",
    "error_24801": "Запрос не выполнен (код ошибки: 24801). Пожалуйста, попробуйте еще раз",
    "no_app_found": "Приложение не найдено! <a href=\"/\">Обновите</a> или <a href=\"https://www.mindonmap.com/\">Перейдите на официальный сайт</a>",
    "get_more": "Получить больше >>",
    "edit_photo": "Редактировать фотографию",
    "select_photo": "Выбрать фото",
    "change_photo": "Измени фотографию",
    "cancel": "Отмена",
    "hide_password": "Скрыть пароль",
    "show_password": "Показать пароль",
    "zoom_in": "Приблизить",
    "zoom_out": "Уменьшить",
    "rotate": "Поворот",
    "horizontal_flip": "Горизонтальный флип",
    "vertical_flip": "Вертикальный флип",
    "country": "Страна",
    "country_1": "Выберите вашу страну/регион",
    "country_2": "Оландский остров",
    "country_3": "Афганистан",
    "country_4": "Албания",
    "country_5": "Алжир",
    "country_6": "Американское Самоа",
    "country_7": "Андорра",
    "country_8": "Ангола",
    "country_9": "Ангилья",
    "country_10": "Антарктида",
    "country_11": "Антигуа и Барбуда",
    "country_12": "Аргентина",
    "country_13": "Армения",
    "country_14": "Аруба",
    "country_15": "Австралия",
    "country_16": "Австрия",
    "country_17": "Азербайджан",
    "country_18": "Бахрейн",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Беларусь",
    "country_22": "Бельгия",
    "country_23": "Белиз",
    "country_24": "Бенин",
    "country_25": "Бермуды",
    "country_26": "Бутан",
    "country_27": "Боливия",
    "country_28": "Босния и Герцеговина",
    "country_29": "Ботсвана",
    "country_30": "Остров Буве",
    "country_31": "Бразилия",
    "country_32": "Британская территория Индийского океана",
    "country_33": "Британские Виргинские острова",
    "country_34": "Бруней",
    "country_35": "Болгария",
    "country_36": "Буркина",
    "country_37": "Бурунди",
    "country_38": "Камбоджа",
    "country_39": "Камерун",
    "country_40": "Канада",
    "country_41": "Кабо-Верде",
    "country_42": "Карибские острова Нидерланды",
    "country_43": "Каймановы острова",
    "country_44": "Центрально-Африканская Республика",
    "country_45": "Чад",
    "country_46": "Чили",
    "country_47": "Китай",
    "country_48": "Остров Рождества",
    "country_49": "Кокосовые острова (Килинг)",
    "country_50": "Колумбия",
    "country_51": "Острова Кука",
    "country_52": "Коста-Рика",
    "country_53": "Кот-д'Ивуар",
    "country_54": "Хорватия",
    "country_55": "Куба",
    "country_56": "Кипр",
    "country_57": "Чешская Республика",
    "country_58": "Демократическая Республика Конго",
    "country_59": "Дания",
    "country_60": "Джибути",
    "country_61": "Доминика",
    "country_62": "Доминиканская Республика",
    "country_63": "Эквадор",
    "country_64": "Египет",
    "country_65": "Сальвадор",
    "country_66": "Экваториальная Гвинея",
    "country_67": "Эритрея",
    "country_68": "Эстония",
    "country_69": "Эфиопия",
    "country_70": "Фолклендские острова",
    "country_71": "Фарерские острова",
    "country_72": "Федеративные Штаты Микронезии",
    "country_73": "Фиджи",
    "country_74": "Финляндия",
    "country_75": "Франция",
    "country_76": "Французская Гвиана",
    "country_77": "Французская Полинезия",
    "country_78": "Южные Французские Территории",
    "country_79": "Габон",
    "country_80": "Гамбия",
    "country_81": "Грузия",
    "country_82": "Германия",
    "country_83": "Гана",
    "country_84": "Гибралтар",
    "country_85": "Великобритания (Соединенное Королевство; Англия)",
    "country_86": "Греция",
    "country_87": "Гренландия",
    "country_88": "Гренада",
    "country_89": "Гваделупа",
    "country_90": "Гуам",
    "country_91": "Гватемала",
    "country_92": "Гернси",
    "country_93": "Гвинея",
    "country_94": "Гвинея-Бисау",
    "country_95": "Гайана",
    "country_96": "Гаити",
    "country_97": "Остров Херд и острова Макдональда",
    "country_98": "Гондурас",
    "country_99": "Гонконг",
    "country_100": "Венгрия",
    "country_101": "Исландия",
    "country_102": "Индия",
    "country_103": "Индонезия",
    "country_104": "Иран",
    "country_105": "Ирак",
    "country_106": "Ирландия",
    "country_107": "Остров Мэн",
    "country_108": "Израиль",
    "country_109": "Италия",
    "country_110": "Ямайка",
    "country_111": "Япония",
    "country_112": "Джерси",
    "country_113": "Иордания",
    "country_114": "Казахстан",
    "country_115": "Кения",
    "country_116": "Кирибати",
    "country_117": "Кувейт",
    "country_118": "Кыргызстан",
    "country_119": "Лаос",
    "country_120": "Латвия",
    "country_121": "Ливан",
    "country_122": "Лесото",
    "country_123": "Либерия",
    "country_124": "Ливия",
    "country_125": "Лихтенштейн",
    "country_126": "Литва",
    "country_127": "Люксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малави",
    "country_131": "Малайзия",
    "country_132": "Мальдивы",
    "country_133": "Мали",
    "country_134": "Мальта",
    "country_135": "Маршалловы острова",
    "country_136": "Мартиника",
    "country_137": "Мавритания",
    "country_138": "Маврикий",
    "country_139": "Майотта",
    "country_140": "Мексика",
    "country_141": "Молдова",
    "country_142": "Монако",
    "country_143": "Монголия",
    "country_144": "Черногория",
    "country_145": "Монтсеррат",
    "country_146": "Марокко",
    "country_147": "Мозамбик",
    "country_148": "Мьянма (Бирма)",
    "country_149": "Намибия",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Нидерланды",
    "country_153": "Новая Каледония",
    "country_154": "Новая Зеландия",
    "country_155": "Никарагуа",
    "country_156": "Нигер",
    "country_157": "Нигерия",
    "country_158": "Ниуэ",
    "country_159": "Остров Норфолк",
    "country_160": "Северная Корея",
    "country_161": "Северные Марианские острова",
    "country_162": "Норвегия",
    "country_163": "Оман",
    "country_164": "Пакистан",
    "country_165": "Палау",
    "country_166": "Территории Палестины",
    "country_167": "Панама",
    "country_168": "Папуа - Новая Гвинея",
    "country_169": "Парагвай",
    "country_170": "Перу",
    "country_171": "Острова Питкэрн",
    "country_172": "Польша",
    "country_173": "Португалия",
    "country_174": "Пуэрто-Рико",
    "country_175": "Катар",
    "country_176": "Республика Македония (БЮРМ)",
    "country_177": "Республика Конго",
    "country_178": "Реюньон",
    "country_179": "Румыния",
    "country_180": "Российская Федерация",
    "country_181": "Руанда",
    "country_182": "Сен-Бартелеми",
    "country_183": "Сен-Мартен (Франция)",
    "country_184": "Сен-Пьер и Микелон",
    "country_185": "Самоа",
    "country_186": "Сан-Марино",
    "country_187": "Сан-Томе и Принсипи",
    "country_188": "Саудовская Аравия",
    "country_189": "Сенегал",
    "country_190": "Сербия",
    "country_191": "Сейшельские острова",
    "country_192": "Сьерра-Леоне",
    "country_193": "Сингапур",
    "country_194": "Словакия",
    "country_195": "Словения",
    "country_196": "Соломоновы острова",
    "country_197": "Сомали",
    "country_198": "Южная Африка",
    "country_199": "Южная Грузия и Южный Сэндвичевы острова",
    "country_200": "Южная Корея",
    "country_201": "Южный Судан",
    "country_202": "Испания",
    "country_203": "Шри-Ланка",
    "country_204": "Остров Святой Елены и зависимые территории",
    "country_205": "Сент-Китс и Невис",
    "country_206": "Сент-Люсия",
    "country_207": "Сент-Винсент и Гренадины",
    "country_208": "Судан",
    "country_209": "Суринам",
    "country_210": "Свазиленд",
    "country_211": "Швеция",
    "country_212": "Швейцария",
    "country_213": "Сирия",
    "country_214": "Тайвань",
    "country_215": "Таджикистан",
    "country_216": "Танзания",
    "country_217": "Шаблон:Данные о стране SJM Шпицберген",
    "country_218": "Таиланд",
    "country_219": "Багамские острова",
    "country_220": "Коморские Острова",
    "country_221": "Филиппины",
    "country_222": "Тимор-Лешти (Восточный Тимор)",
    "country_223": "Идти",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринидад и Тобаго",
    "country_227": "Тунис",
    "country_228": "Турция",
    "country_229": "Туркменистан",
    "country_230": "Острова Тёркс и Кайкос",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Украина",
    "country_234": "Объединенные Арабские Эмираты",
    "country_235": "Малые отдаленные острова США",
    "country_236": "Соединенные Штаты Америки (США)",
    "country_237": "Виргинские острова США",
    "country_238": "Уругвай",
    "country_239": "Узбекистан",
    "country_240": "Вануату",
    "country_241": "Ватикан (Святой Престол)",
    "country_242": "Венесуэла",
    "country_243": "Вьетнам",
    "country_244": "Уоллис и Футуна",
    "country_245": "Западная Сахара",
    "country_246": "Йемен",
    "country_247": "Замбия",
    "country_248": "Зимбабве",
    "google_login": "Войти через Google",
    "State": "Состояние",
    "Activation_code": "Код активации",
    "Question": "Список всех приложений, в которые вы вошли",
    "Copy_complete": "Копирование завершено",
    "footer": "© 2024 Студия MindOnMap. Все права защищены.",
    "change_password_success": "Пароль успешно изменен",
    "successful_login_title": "Успех входа в систему",
    "product_page": "Страница продукта>>",
    "successful_login_info": "Вход завершен. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс. Текущая страница закроется автоматически через 5 секунд. Если автоматическое закрытие или закрытие по нажатию кнопки «Готово» не удалось, закройте эту вкладку напрямую.",
    "successful_login_info_firefox": "Вход завершен. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс.",
    "my_account": "Мой счет",
    "my_history": "Моя история",
    "remove_watermark": "Удалить водяной знак",
    "no_history": "Нет истории",
    "history_all": "Выбрать все",
    "history_open": "Открыть",
    "history_down": "Скачать",
    "history_delete": "Удалить",
    "history_clear": "Очистить недействительный",
    "images": "изображений",
    "use_this_function": "Используйте этот продукт>>",
    "hd_downloading": "Преимущества загрузки оригинальных изображений HD:",
    "lifetimeRemaining": "Оставшийся срок службы",
    "Remaining": "Оставшийся",
    "email_verification": "Подтверждение по элетронной почте",
    "email_verification_info": "Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class=\"email\"></span>. Пожалуйста, завершите проверку. После проверки преимущества будут синхронизированы автоматически.",
    "wrong_email": "Неправильный адрес электронной почты?",
    "click_here_to_modify": "Нажмите здесь, чтобы изменить",
    "get_more_help": "Получить дополнительную помощь?",
    "click_here": "кликните сюда",
    "email_verification_info_success": "Поздравляем с подтверждением вашей учетной записи электронной почты.",
    "email_verification_info_error": "Проверка не удалась из-за устаревшей ссылки.",
    "registration_succeeded": "Регистрация прошла успешно",
    "registration_succeeded_info_1": "Поздравляем! Вы успешно зарегистрировались. Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class=\"email\"></span>. Пожалуйста, завершите проверку, чтобы воспользоваться преимуществами этого письма.",
    "registration_succeeded_info_2": "Нажмите «Готово», чтобы вернуться на главную страницу и использовать этот продукт.",
    "registration_succeeded_info_3": "Нажмите «Готово», чтобы закрыть текущую страницу и вернуться на главную страницу для выполнения следующих операций. Если закрыть не удалось, закройте вкладку вручную.",
    "verify_email": "Подтвердить Email",
    "registered_email_not_verified": "Зарегистрированный адрес электронной почты не был подтвержден, пожалуйста, подтвердите его сразу.",
    "email_verification_time_1": "Не получили письмо с подтверждением?",
    "email_verification_time_2": "После",
    "email_verification_time_3": "Нажмите здесь, чтобы отправить его повторно",
    "error_26301": "Код ошибки: 26301, свяжитесь с нами.",
    "error_26302": "Код ошибки: 26302, свяжитесь с нами.",
    "error_26303": "Ошибка формата электронной почты (код ошибки: 26303). Пожалуйста, введите его еще раз",
    "error_26304": "Рекомендуется пароль длиной более 8 символов (код ошибки: 26304).",
    "error_26305": "Повторный запрос не выполнен (код ошибки: 26305). Пожалуйста, повторите попытку",
    "error_26306": "Электронная почта зарегистрирована, пожалуйста, <a href=\"https://account.mindonmap.com/login/\">войдите в систему</a>",
    "error_26307": "Повторный запрос не выполнен (код ошибки: 26307). Пожалуйста, повторите попытку",
    "error_26308": "Повторный запрос не выполнен (код ошибки: 26308). Пожалуйста, повторите попытку",
    "error_26401": "Код ошибки: 26401, повторите попытку.",
    "error_26402": "Электронная почта подтверждена (код ошибки: 26402), повторите попытку.",
    "error_26403": "Повторный запрос не выполнен (код ошибки: 26403). Пожалуйста, повторите попытку",
    "error_26404": "Запрос не выполнен (код ошибки: 26404). Пожалуйста, повторите попытку",
    "error_26501": "Код ошибки: 26501, свяжитесь с нами.",
    "error_26502": "Код ошибки: 26502, свяжитесь с нами.",
    "error_26503": "Ошибка формата электронной почты (код ошибки: 26503). Пожалуйста, введите его еще раз",
    "error_26504": "Повторный запрос не выполнен (код ошибки: 26504). Пожалуйста, повторите попытку",
    "error_26505": "Электронная почта не зарегистрирована, пожалуйста, <a href=\"https://account.mindonmap.com/register/\">сначала зарегистрируйте ее</a>",
    "error_26506": "Электронная почта подтверждена.",
    "error_26507": "Повторный запрос не выполнен (код ошибки: 26507). Пожалуйста, повторите попытку",
    "error_26508": "Проверка завершена (код ошибки: 26508), повторите попытку.",
    "error_26509": "Запрос не выполнен (код ошибки: 26509), повторите попытку.",
    "error_26510": "Код ошибки: 26510, свяжитесь с нами.",
    "error_26601": "Код ошибки: 26601, свяжитесь с нами.",
    "error_26602": "Код ошибки: 26602, свяжитесь с нами.",
    "error_26603": "Повторный запрос не выполнен (код ошибки: 26603). Пожалуйста, повторите попытку",
    "error_26604": "Код ошибки: 26604, свяжитесь с нами.",
    "error_26605": "Код ошибки: 26605, свяжитесь с нами.",
    "error_26701": "Код ошибки: 26701, свяжитесь с нами.",
    "error_26702": "Повторный запрос не выполнен (код ошибки: 26702). Пожалуйста, повторите попытку",
    "error_26703": "Код ошибки: 26703, свяжитесь с нами.",
    "error_26704": "Код ошибки: 26704, свяжитесь с нами.",
    "error_26705": "Дождитесь входа в систему (код ошибки: 26705). Пожалуйста, повторите попытку",
    "no_cookie": "Вы включили функцию «Блокировать все файлы cookie» в своем браузере, поэтому не можете войти в систему. Перейдите в «Настройки» и установите флажок «Разрешить все файлы cookie».",
    "error_26801": "Код ошибки: 26801, свяжитесь с нами.",
    "error_26802": "Код ошибки: 26802, свяжитесь с нами.",
    "error_26803": "Запрос не выполнен (код ошибки: 26803). Пожалуйста, повторите попытку",
    "error_26804": "Запрос не выполнен (код ошибки: 26804). Пожалуйста, повторите попытку",
    "error_order": "Запрос не выполнен (код ошибки: 27098), попробуйте еще раз!",
    "error_order1": "Запрос заказа неполный (код ошибки: ",
    "error_order2": "）, обновите страницу и попробуйте еще раз.",
    "modify_email_title": "Изменить электронную почту",
    "modify_email_info": "Вы можете использовать измененный адрес электронной почты для входа в свою учетную запись.",
    "images_per": "изображений за",
    "error_26101": "Ошибка: 26101. Пожалуйста, свяжитесь с нами.",
    "error_26102": "Ошибка: 26102. Пожалуйста, свяжитесь с нами.",
    "error_26103": "Запрос не выполнен (код ошибки: 26103). Пожалуйста, попробуйте ещё раз",
    "error_26104": "Код ошибки: 26104, повторите попытку.",
    "error_26105": "Код ошибки: 26105, повторите попытку.",
    "error_26106": "Удаление не удалось (код ошибки: 26106). Пожалуйста, попробуйте еще раз",
    "error_26201": "Ошибка: 26201. Пожалуйста, свяжитесь с нами.",
    "error_26202": "Запрос не выполнен (код ошибки: 26202). Пожалуйста, попробуйте ещё раз",
    "error_26001": "Ошибка: 26001. Пожалуйста, свяжитесь с нами.",
    "error_26002": "Ошибка: 26002. Пожалуйста, свяжитесь с нами.",
    "error_26003": "Ошибка: 26003. Пожалуйста, свяжитесь с нами.",
    "error_26004": "Ошибка: 26004. Пожалуйста, свяжитесь с нами.",
    "error_26005": "Запрос не выполнен (код ошибки: 26005). Пожалуйста, попробуйте ещё раз",
    "error_26006": "Код ошибки: 26006, повторите попытку.",
    "error_26008": "Ошибка: 26008. Пожалуйста, свяжитесь с нами.",
    "go_to_the_home_page": "Перейти на главную страницу",
    "error_27101": "Запрос не выполнен (код ошибки: 27101). Пожалуйста, повторите попытку",
    "error_27201": "Код ошибки: 27201, свяжитесь с нами.",
    "error_27202": "Код ошибки: 27202, повторите попытку.",
    "error_27203": "Запрос не выполнен (код ошибки: 27203). Пожалуйста, повторите попытку",
    "error_27204": "Неверный код (код ошибки: 27204).",
    "error_27205": "Запрос не выполнен (код ошибки: 27205). Пожалуйста, повторите попытку",
    "error_27206": "Запрос не выполнен (код ошибки: 27206). Пожалуйста, повторите попытку",
    "error_27207": "Запрос не выполнен (код ошибки: 27207). Пожалуйста, повторите попытку",
    "no_history_found": "Вы не использовали ни одного инструмента! <a href=\"/\">Обновить</a> или <a href=\"https://www.mindonmap.com/\">Перейти на официальный сайт</a>",
    "error_25301": "Ошибка: 25301. Пожалуйста, свяжитесь с нами.",
    "error_25302": "Ошибка: 25302. Пожалуйста, свяжитесь с нами.",
    "error_25303": "Запрос не выполнен (код ошибки: 25303). Пожалуйста, попробуйте еще раз",
    "error_25304": "Запрос не выполнен (код ошибки: 25304). Пожалуйста, попробуйте еще раз",
    "error_25305": "Запрос не выполнен (код ошибки: 25305). Пожалуйста, попробуйте еще раз",
    "error_25306": "Запрос не выполнен (код ошибки: 25306). Пожалуйста, попробуйте еще раз",
    "image_upscaler_p": "Преимущества загрузки изображений без водяных знаков:",
    "Available_for": "Доступны для:",
    "credit_per": "%s кредитов за изображение HD",
    "still_valid": "Приобретенные планы все еще действительны.",
    "credit": "кредит(ы)",
    "pc_3rd_info": "Войдите успешно. Пожалуйста, перейдите в приложение для дальнейшей работы.",
    "use_online": "Воспользуйтесь онлайн-сервисом",
    "use_download": "Используйте настольную программу",
    "use_immediately": "Использовать немедленно",
    "Use_in_browser": "Использовать в браузере",
    "win_desktop": "Окна",
    "Mac_desktop": "Мак",
    "credits_per": "{%} кредитов в месяц",
    "expire": "Время истечения:",
    "viewDetails": "Посмотреть детали",
    "viewHistory": "Посмотреть преимущества истории>>",
    "viewDetailsInfo": "Примечание. Если преимущества подписки будут продлены в течение 7 дней после истечения срока их действия, неиспользованные преимущества можно будет продолжать использовать. Кроме того, время истечения срока действия будет автоматически обновлено до истечения срока действия новой подписки. Если по истечении 7 дней после истечения срока действия новой подписки не будет, все преимущества с истекшим сроком действия будут аннулированы.",
    "connect_account": "Привяжите электронную почту к своей учетной записи",
    "connect_account_info": "После привязки вы сможете войти под этим адресом электронной почты.",
    "connect_now": "Связать сейчас",
    "no_email_bind": "Нет привязки к электронной почте",
    "bind_email": "Привязать электронную почту",
    "connect_your_email_placeholder": "Пожалуйста, введите Ваш адрес электронной почты",
    "bind_an_email": "Привязать электронную почту",
    "bind_info": "Вы успешно вошли в систему. Привяжите адрес электронной почты к своей учетной записи, чтобы активировать преимущества.",
    "bind_later": "Привязать позже",
    "hi": "Его!",
    "Personal_Information": "Персональная информация",
    "Access": "Доступ",
    "Subscription_Plan": "(План подписки)",
    "No_orders": "Заказов не найдено.",
    "No_data": "Нет данных",
    "Featured_Products": "Рекомендуемые продукты",
    "More_Products": "Больше продуктов",
    "Free_Download": "Бесплатная загрузка",
    "Get_Started": "Начать",
    "Subscribe": "Подписаться",
    "Verified": "Проверено",
    "back_to_account_center": "Вернуться в Центр учетных записей",
    "success": "Успех!",
    "successfully": "Вы успешно зарегистрировали аккаунт.",
    "Continue": "Продолжать",
    "Already": "У вас уже есть аккаунт?",
    "loading_verification": "Проверка статуса проверки...",
    "email_no_verification": "К сожалению, зарегистрированный адрес электронной почты не подтвержден. Пожалуйста, завершите проверку в соответствии с приведенными выше инструкциями и еще раз нажмите кнопку «Готово», чтобы завершить регистрацию.",
    "will_expire_after": "Срок действия истекает после",
    "hours": "часы",
  },
  "si": {
    "overtime": "දෝෂ කේතය: {%}, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "isnetwork": "අන්තර්ජාල දෝෂයකි. කරුණාකර පරීක්ෂා කර නැවත උත්සාහ කරන්න",
    "email_placeholder": "විද්යුත් තැපෑල",
    "email_empty": "කරුණාකර ඊමේල් ඇතුළත් කරන්න",
    "email_not_valid": "ඊමේල් වලංගු නැත",
    "email_not_valid_1": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුලත් කරන්න",
    "email_not_valid_2": "විද්‍යුත් තැපෑල වලංගු නැත, කරුණාකර වෙනත් ලිපිනයක් භාවිතා කරන්න.",
    "email_not_valid_3": "ඊමේල් ආදානය නැත",
    "password_placeholder": "මුරපදය",
    "password_empty": "කරුණාකර මුරපදය ඇතුළත් කරන්න",
    "password_not_valid": "වැරදි ගිණුමක් හෝ මුරපදයක්",
    "password_not_valid_1": "අක්ෂර 8 ට වැඩි මුරපදයක් අවශ්‍ය වේ",
    "password_not_valid_2": "කරුණාකර මුරපදයක් සාදන්න",
    "password_placeholder_1": "ඔබගේ මුරපදය සාදන්න",
    "password_placeholder_2": "ඔබගේ මුරපදය තහවුරු කරන්න",
    "password_placeholder_3": "නව මුරපදයක් සාදන්න",
    "password_placeholder_4": "නව මුරපදය තහවුරු කරන්න",
    "password_placeholder_5": "පැරණි මුරපදය ඇතුළත් කරන්න",
    "copy_password_empty": "කරුණාකර මුරපදය තහවුරු කරන්න",
    "copy_password_not_valid": "කරුණාකර ඔබගේ මුරපදය තහවුරු කරන්න",
    "copy_passwords_inconsistent": "ඔබගේ මුරපදය තහවුරු කිරීම නොගැලපේ",
    "code_empty": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න",
    "code_not_valid": "වලංගු නොවන සත්‍යාපන කේතය",
    "code_placeholder": "සත්යාපන කේතය",
    "not_received_code": "ඔබගේ තැපැල් පෙට්ටියට දිගු කලක් සත්‍යාපන කේතය ලැබී නොමැති නම්, කරුණාකර නැවත සත්‍යාපන කේතය ලබා ගන්න.",
    "get_first_code": "කරුණාකර පළමුව සත්‍යාපන කේතය ලබා ගන්න.",
    "last_name_placeholder": "කරුණාකර ඔබගේ අවසන් නම ඇතුලත් කරන්න",
    "first_name_placeholder": "කරුණාකර ඔබගේ මුල් නම ඇතුලත් කරන්න",
    "address_placeholder": "කරුණාකර ඔබගේ ලිපිනය ඇතුලත් කරන්න",
    "no_code_text": "අපි සත්‍යාපන කේතයක් එවා ඇත. කරුණාකර ඔබගේ කේතය ඇතුලත් කරන්න. <span class='tips'>කේතයක් නොලැබුනේද?",
    "no_code_text_1": "1. කරුණාකර ඊමේල් ලිපිනය වලංගු බවත් එයට ඊමේල් ලැබිය හැකි බවත් සහතික කර ගන්න.",
    "no_code_text_2": "2. විද්‍යුත් තැපෑල ස්වයංක්‍රීයව පද්ධතිය විසින් යවනු ලබන බැවින්, එය අයාචිත තැපෑල හෝ නිසරු විද්‍යුත් තැපෑල ලෙස සලකුණු කළ හැක. කරුණාකර විද්‍යුත් තැපෑල අපද්‍රව්‍ය ෆෝල්ඩරයේ තිබේදැයි පරීක්ෂා කරන්න.",
    "no_code_text_3": "3. ඔබේ ගැටලුව විසඳිය නොහැකිද? ",
    "no_code_text_3_span": "ඉන්පසු අප හා සම්බන්ධ වීමට මෙතන ක්ලික් කරන්න.",
    "order_no": "ඔබ කිසිදු නිෂ්පාදනයක් මිල දී ගෙන නැත, ඔබට කිසියම් ප්‍රශ්නයක් ඇත්නම්, කරුණාකර <a href=\"https://www.mindonmap.com/contact-us/\">අපව සම්බන්ධ කර ගන්න</a>.",
    "error_24901": "ජංගම ගිණුමට ඊමේල් සම්බන්ධ කර නොමැති අතර ඇණවුම් සොයාගත නොහැක. කරුණාකර විද්‍යුත් තැපෑලක් සම්බන්ධ කරන්න.",
    "user_guide": "පරිශීලක මාර්ගෝපදේශය >>",
    "download": "බාගත",
    "order_number": "ඇණවුම් අංකය:",
    "Refund": "ආපසු ගෙවීම",
    "Disabled": "ආබාධිතයි",
    "Normal": "සාමාන්යයි",
    "Modify": "වෙනස් කරන්න",
    "Modify_1": "වෙනස් කරන්න >>",
    "Connect": "සම්බන්ධ කරන්න",
    "unlink_success": "සාර්ථකව විසන්ධි කරන්න",
    "connect_success": "සාර්ථකව සම්බන්ධ වන්න",
    "feedback_title": "ඔබගේ ප්‍රතිපෝෂණයට ස්තුතියි. කරුණාකර ඔබේ ගැටලුව අතහරින්න, අපි පැය 24ක් ඇතුළත ඔබට ප්‍රතිචාර දක්වන්නෙමු.",
    "feedback_thank_you": "ස්තූතියි!<br />ඔබගේ ප්‍රතිපෝෂණය සාර්ථකව ඉදිරිපත් කර ඇත.",
    "feedback_email": "ඔබගේ විද්‍යුත් තැපෑල මෙහි ඇතුලත් කරන්න!",
    "feedback_content": "ඔබට හමු වූ ඕනෑම ගැටලුවක් හෝ යෝජනාවක් මෙහි තබන්න.",
    "feedback_submit": "ඉදිරිපත් කරන්න",
    "form_contents": "ඔබ කිසිදු විස්තරයක් ඇතුළත් කර නැත. කරුණාකර එය ඇතුළත් කර නැවත ඉදිරිපත් කරන්න.",
    "old_password": "කරුණාකර පැරණි මුරපදය ඇතුළත් කරන්න",
    "new_password": "කරුණාකර නව මුරපදයක් සාදන්න",
    "old_new_password": "නව මුරපදය පැරණි එකට සමාන විය නොහැක",
    "incorrect_password": "වැරදි මුරපදය",
    "delete_no": "දැන් මකන්න",
    "Caps": "Caps lock ක්‍රියාත්මකයි",
    "Get": "ලබාගන්න",
    "Done": "කළා",
    "error_20001": "දෝෂය: 20001. කරුණාකර නැවත ලොග් වන්න.",
    "error_20002": "දෝෂය: 20002. කරුණාකර නැවත ලොග් වන්න.",
    "error_20003": "දෝෂය: 20003. කරුණාකර නැවත ලොග් වන්න.",
    "error_20004": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20004). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_20005": "පිවිසුම් සැසිය කල් ඉකුත් වී ඇත (දෝෂය: 20005). කරුණාකර නැවත ලොග් වන්න.",
    "error_20006": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20006). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_20007": "පිවිසුම් සැසිය කල් ඉකුත් වී ඇත (දෝෂය: 20007). කරුණාකර නැවත ලොග් වන්න.",
    "error_20008": "පිවිසුම් සැසිය කල් ඉකුත් වී ඇත (දෝෂය: 20008). කරුණාකර නැවත ලොග් වන්න.",
    "error_20009": "පිවිසුම් සැසිය කල් ඉකුත් වී ඇත (දෝෂය: 20009). කරුණාකර නැවත ලොග් වන්න.",
    "error_20101": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20101)",
    "error_20102": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20102)",
    "error_20103": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20103). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20104": "විද්‍යුත් තැපෑල දැනටමත් භාවිතයේ ඇත, කරුණාකර <a href=\"https://account.mindonmap.com/login/\">පුරනය වන්න</a> නැතහොත් අලුත් එකක් සමඟ ලියාපදිංචි වන්න",
    "error_20105": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20105). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20106": "ඊමේල් යැවීමට අසමත් විය, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20201": "කරුණාකර ඔබේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20201)",
    "error_20202": "කරුණාකර ඔබගේ මුරපදය ඇතුළත් කරන්න (දෝෂ කේතය: 20202)",
    "error_20203": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න (දෝෂ කේතය: 20203)",
    "error_20204": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20204)",
    "error_20205": "අක්ෂර 8 ට වැඩි මුරපදයක් අවශ්‍ය වේ (දෝෂ කේතය: 20205)",
    "error_20206": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20206). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20207": "වලංගු නොවන සත්‍යාපන කේතය",
    "error_20208": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20208). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20209": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20209). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20301": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20301)",
    "error_20302": "දෝෂය: 20302. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_20303": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20303)",
    "error_20304": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20304). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20305": "ගිණුම නොපවතී. කරුණාකර නැවත ඇතුල් කරන්න නැතහොත් පළමුව <a href=\"https://account.mindonmap.com/register/\">එය සාදන්න</a>.",
    "error_20306": "තවම මුරපදයක් නැත. <a href=\"https://account.mindonmap.com/passwordless-login/\">මුරපද රහිත පිවිසුම</a> හෝ <a href=\"https://account.mindonmap.com/create-password/\"> භාවිත කරන්න මුරපදයක් සකසන්න</a> සහ ලොග් වන්න.",
    "error_20308": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20308). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20401": "පිටවීම අසාර්ථක විය (දෝෂ කේතය: 20401). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20501": "කරුණාකර ඔබේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20501)",
    "error_20502": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20502)",
    "error_20503": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20503). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20504": "ඊමේල් යැවීමට අසමත් විය. කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_20601": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20601)",
    "error_20602": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න (දෝෂ කේතය: 20602)",
    "error_20603": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20603)",
    "error_20604": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20604). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20606": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20606). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20607": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20607). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20608": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20608). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20701": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20701)",
    "error_20702": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20702)",
    "error_20703": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20703). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20704": "ගිණුම නොපවතී. කරුණාකර නැවත ඇතුල් කරන්න නැතහොත් පළමුව <a href=\"https://account.mindonmap.com/register/\">එය සාදන්න</a>.",
    "error_20705": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20705). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20706": "ඊමේල් යැවීමට අසමත් විය. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20801": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 20801)",
    "error_20802": "දෝෂය: 20802. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_20803": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න (දෝෂ කේතය: 20803)",
    "error_20804": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 20804)",
    "error_20805": "අක්ෂර 8 ට වැඩි මුරපදයක් අවශ්‍ය වේ (දෝෂ කේතය: 20805)",
    "error_20806": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20806). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20808": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20808). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20901": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20901). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20902": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 20902). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21000": "වෙනස්කම් සුරැකේ",
    "error_21001": "කිසිදු තොරතුරක් ඉදිරිපත් නොකෙරේ (දෝෂ කේතය: 21001)",
    "error_21002": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21002). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21101": "කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 21101)",
    "error_21102": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 21102)",
    "error_21103": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21103), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21104": "විද්‍යුත් තැපෑල දැනටමත් සම්බන්ධ වී ඇත, කරුණාකර අලුත් එකක් භාවිතා කරන්න",
    "error_21105": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21105), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21106": "ඊමේල් යැවීමට අසමත් විය. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21201": "කරුණාකර ඔබේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න (දෝෂ කේතය: 21201)",
    "error_21202": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න (දෝෂ කේතය: 21202)",
    "error_21203": "විද්‍යුත් තැපෑල වලංගු නැත (දෝෂ කේතය: 21203)",
    "error_21204": "දෝෂය: 21204. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21205": "දෝෂය: 21205. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21206": "අක්ෂර 8 ට වැඩි මුරපදයක් අවශ්‍ය වේ (දෝෂ කේතය: 21206)",
    "error_21207": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21207). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21209": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21209). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21301": "කරුණාකර පැරණි මුරපදය ඇතුළත් කරන්න (දෝෂ කේතය: 21301)",
    "error_21302": "කරුණාකර නව මුරපදයක් සාදන්න (දෝෂ කේතය: 21302)",
    "error_21303": "නව මුරපදය පැරණි එකට සමාන විය නොහැක. (දෝෂය: 21303)",
    "error_21304": "අක්ෂර 8 ට වැඩි මුරපදයක් අවශ්‍ය වේ (දෝෂ කේතය: 21304)",
    "error_21306": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21306). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21402": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21402). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21403": "සත්‍යාපන කේතය යැවීමට අසමත් විය. කරුණාකර එය නැවත යවන්න",
    "error_21500": "ගිණුම මකා ඇත",
    "error_21501": "කරුණාකර සත්‍යාපන කේතය ඇතුළත් කරන්න (දෝෂ කේතය: 21501)",
    "error_21502": "පිවිසුම් සැසිය කල් ඉකුත් වී ඇත (දෝෂය: 21502). කරුණාකර නැවත ලොග් වන්න.",
    "error_21503": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21503). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21505": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21505), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21601": "දෝෂය: 20601. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21602": "වලංගු නොවන සත්‍යාපනය (දෝෂය: 20602). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_21603": "දෝෂය: 20603. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21604": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21604). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21606": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21606). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21611": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21611). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21801": "දෝෂය: 21801. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21802": "ඉල්ලීම අසාර්ථක විය (දෝෂය: 21802). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21803": "දෝෂය: 21803. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21804": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21804). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21806": "දෝෂය: 21806. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21807": "දෝෂය: 21807. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21808": "දෝෂය: 21808. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21809": "දෝෂය: 21809. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21810": "දෝෂය: 21810. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21811": "දෝෂය: 21811. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21812": "දෝෂය: 21812. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21813": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21813). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21814": "දෝෂය: 21814. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21815": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21815). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21816": "දෝෂය: 21816. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21817": "දෝෂය: 21817. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21818": "දෝෂය: 21818. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21819": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21819). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21820": "දෝෂය: 21820. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21821": "දෝෂය: 21821. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21822": "දෝෂය: 21822. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21823": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21823). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21824": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21824). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21825": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21825). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21826": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21826). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21901": "දෝෂය: 21901. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_21902": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21902). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21903": "ගිණුමේ තත්ත්වය වෙනස් වී ඇත (දෝෂ කේතය: 21903), කරුණාකර පිටුව නැවුම් කර නැවත උත්සාහ කරන්න",
    "error_21904": "දෝෂය: 21904. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21905": "දෝෂය: 21905. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21906": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21906). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_21907": "Google ගිණුම වෙනත් ගිණුමකට සම්බන්ධ කර ඇත",
    "error_21908": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 21908). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22001": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22001). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22002": "අමතර පුරනය වීමක් නොමැතිව සබැඳි ඉවත් කිරීම අසාර්ථක විය",
    "error_22003": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22003). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22101": "දෝෂය: 22101. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22102": "ගිණුමේ තත්ත්වය වෙනස් වී ඇත (දෝෂ කේතය: 22102), කරුණාකර පිටුව නැවුම් කර නැවත උත්සාහ කරන්න",
    "error_22103": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22103). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22104": "ගිණුමේ තත්ත්වය වෙනස් වී ඇත (දෝෂ කේතය: 22104), කරුණාකර පිටුව නැවුම් කර නැවත උත්සාහ කරන්න",
    "error_22105": "දෝෂය: 22105. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22106": "දෝෂය: 22106. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22107": "දෝෂය: 22107. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22108": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22108). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22201": "දෝෂය: 22201. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22202": "වලංගු නොවන සත්‍යාපනය (දෝෂය: 22202). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_22203": "දෝෂය: 22203. කරුණාකර එය නැවත උත්සාහ කරන්න\"",
    "error_22204": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22204). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22206": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22206). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22401": "දෝෂය: 22401. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22402": "වලංගු නොවන සත්‍යාපනය (දෝෂය: 22402). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_22403": "දෝෂය: 22403. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22404": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22404). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22405": "ෆේස්බුක් ගිණුම වෙනත් විද්‍යුත් තැපෑලකට සම්බන්ධ කර ඇත",
    "error_22406": "දෝෂය: 22406. කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22407": "දෝෂය: 22407. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22408": "දෝෂය: 22408. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22409": "දෝෂය: 22409. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22410": "දෝෂය: 224010. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22411": "දෝෂය: 224011. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22412": "දෝෂය: 224012. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22413": "දෝෂය: 22413. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22414": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22414). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22415": "දෝෂය: 22415. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22416": "දෝෂය: 22416. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22417": "දෝෂය: 22417. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22418": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22418). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22419": "දෝෂය: 22419. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22420": "දෝෂය: 22420. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22421": "දෝෂය: 22421. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_22422": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22422). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22423": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22423). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22424": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22424). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_22425": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 22425). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_20098": "දෝෂ කේතය: 20098. ඔබ පුද්ගලික මාදිලියේ බ්‍රවුස් කරන්නේ නම්, කරුණාකර සාමාන්‍ය මාදිලියට මාරු වී නැවත උත්සාහ කරන්න.",
    "error_22298": "Google සඳහා වූ ඉල්ලීම අසාර්ථක විය (දෝෂය: 22298). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_22498": "Facebook සඳහා වූ ඉල්ලීම අසාර්ථක විය (දෝෂය: 22498). කරුණාකර එය නැවත උත්සාහ කරන්න.",
    "error_24902": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 24902). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_24903": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 24903). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_24904": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 24904). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_24905": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 24905). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "login_title": "MindOnMap වෙත ලොග් වන්න",
    "log_in": "ඇතුල් වන්න",
    "no_account": "ගිණුමක් නැද්ද?",
    "create_it": "එය නිර්මාණය කරන්න",
    "or_log_in_with": "නැතහොත් සමඟ ලොග් වන්න",
    "passwordless_login": "මුරපද රහිත පිවිසුම",
    "log_in_done": "ලොග් ඉන් නිමයි",
    "three_rd_account_connect_info": "සුභ පැතුම්! ඔබ සාර්ථකව පුරනය වී ඇත. දැන් ඔබට අනාගතයේදී ලොග් වීමට භාවිතා කරන එක් විද්‍යුත් තැපැල් ගිණුමක් සම්බන්ධ කළ හැක.",
    "see_my_account": "මගේ ගිණුම බලන්න",
    "three_rd_login_merge_account": "තෙවන පාර්ශ්ව ගිණුම් ඊමේල් ලිපිනය ලියාපදිංචි වී ඇත, ඔබට මෙම විද්‍යුත් තැපැල් ලිපිනය සමඟ සෘජුවම සම්බන්ධ වී පුරනය වීමට අවශ්‍යද?",
    "connect_log_in": "සම්බන්ධ කර ලොග් වන්න",
    "create_an_account": "ගිණුමක් තනන්න",
    "back_to_log_in": "නැවත පුරනය වීමට",
    "create_password": "මුරපදය සාදන්න",
    "create_now": "දැන් නිර්මාණය කරන්න",
    "password_login_subtitle": "විද්‍යුත් තැපෑලෙන් මුරපද රහිත පිවිසුම",
    "account_login": "ගිණුමට පිවිසීම",
    "rights": "මෙම ගිණුම සෑදීමෙන්, ඔබ <a href=\"https://www.mindonmap.com/terms-and-conditions/\">සේවා නියම</a> සහ <a href=\"https://www.mindonmap.com/privacy-policy/\">රහස්‍යතා ප්‍රතිපත්තිය</a>",
    "passwordless_login_done": "මුරපද රහිත පිවිසුම සිදු කර ඇත",
    "passwordless_login_info": "සුභ පැතුම්, ඔබ මුරපද රහිත පිවිසුම සාර්ථකව සම්පූර්ණ කර ඇත. ඔබට මෙම ගිණුම සඳහා මුරපදයක් සාදා අනාගතයේදී ගිණුම සහ මුරපදය සමඟ ලොග් විය හැකිය. <a href=\"/create-password\" style=\"display: initial;\">දැන් සාදන්න</a>",
    "sign_up": "ලියාපදිංචි වන්න",
    "register_info": "ඔබගේ ගිණුම සාදන්න",
    "reset_now": "දැන් යළි පිහිටුවන්න",
    "forgot_password": "මුරපදය අමතක වුණා ද",
    "reset_password_subtitle": "මුරපදය යළි පිහිටුවීමට ඔබගේ ගිණුම් විද්‍යුත් තැපෑල භාවිතා කරන්න",
    "plan_products": "සැලසුම් සහ නිෂ්පාදන",
    "nick_name": "පරිශීලක නාමය:",
    "email": "විද්යුත් තැපෑල:",
    "my_products": "මගේ නිෂ්පාදන",
    "my_orders": "මගේ නියෝග",
    "unlink": "විසන්ධි කරන්න",
    "link": "සබැඳිය",
    "connected_accounts": "සම්බන්ධිත ගිණුම්",
    "last_name": "අවසන් නම:",
    "first_name": "මුල් නම:",
    "Gender": "ස්ත්රී පුරුෂ භාවය:",
    "Birth": "උපත:",
    "Month": "මස",
    "Year": "අවුරුදු",
    "Country_Region": "රට/කලාපය:",
    "Address": "ලිපිනය:",
    "Save": "සුරකින්න",
    "Date": "දිනය",
    "Male": "පිරිමි",
    "Female": "ගැහැණු",
    "Unspecified": "නිශ්චිතව දක්වා නැත",
    "Security": "ආරක්ෂක",
    "change_password": "මුරපදය වෙනස් කරන්න",
    "change_now": "දැන් වෙනස් කරන්න",
    "connect_email": "ඊමේල් සම්බන්ධ කරන්න",
    "delete_account": "ගිණුම මකන්න",
    "delete_account_info": "ඔබගේ ගිණුම මකා දැමූ විට, ඔබගේ ගිණුම හා සම්බන්ධ මගේ ගිණුමේ ඇති සියලුම දත්ත ස්ථිරවම මකනු ඇති අතර, ඔබට එය ප්‍රතිසාධනය කිරීමට නොහැකි විය හැක. අපි ඔබට උපදෙස් දෙන්නේ කල්පනාකාරීව ගමන් කරන ලෙසයි.",
    "Delete": "මකන්න",
    "Logout": "පිටවීම",
    "my_profile": "මගේ පැතිකඩ",
    "guides_faqs": "මාර්ගෝපදේශ සහ නිතර අසන පැන",
    "More": "තව",
    "guides": "මඟපෙන්වන්නන්",
    "register": "ලියාපදිංචි කරන්න",
    "hot_faq": "උණුසුම් නිති අසන පැන",
    "Contents": "අන්තර්ගතය:",
    "contact_us": "අප අමතන්න >>",
    "plan": "සැලසුම් කරන්න",
    "unregistered": "ලියාපදිංචි නොකළ",
    "buy_more": "තවත් මිලදී ගන්න",
    "buy_again": "නැවත මිලදී ගන්න",
    "buy_now": "දැන් මිලදී ගන්න",
    "free_no_limit": "නොමිලේ සහ සීමාවක් නැත",
    "expired": "කල් ඉකුත් වී ඇත",
    "lifetime": "ජීවිත කාලය",
    "remain": "රැඳී සිටින්න",
    "day_s": "දින)",
    "error_24801": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 24801). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "no_app_found": "යෙදුමක් හමු නොවීය!<a href=\"/\">නැවුම් කරන්න</a> හෝ <a href=\"https://www.mindonmap.com/\">නිල වෙබ් අඩවියට යන්න</a>",
    "get_more": "තවත් ලබා ගන්න >>",
    "edit_photo": "ඡායාරූපය සංස්කරණය කරන්න",
    "select_photo": "ඡායාරූපය තෝරන්න",
    "change_photo": "පින්තූරය මාරුකිරීම",
    "cancel": "අවලංගු කරන්න",
    "hide_password": "මුරපදය සඟවන්න",
    "show_password": "මුරපදය පෙන්වන්න",
    "zoom_in": "විශාලනය කරන්න",
    "zoom_out": "විශාලනය කරන්න",
    "rotate": "කරකවන්න",
    "horizontal_flip": "තිරස් පෙරළීම",
    "vertical_flip": "සිරස් පෙරළීම",
    "country": "රට",
    "country_1": "ඔබගේ රට/කලාපය තෝරන්න",
    "country_2": "ආලන්ඩ් දිවයින",
    "country_3": "ඇෆ්ගනිස්ථානය",
    "country_4": "ඇල්බේනියාව",
    "country_5": "ඇල්ජීරියාව",
    "country_6": "ඇමරිකානු සැමෝවා",
    "country_7": "ඇන්ඩෝරා",
    "country_8": "ඇන්ගෝලාව",
    "country_9": "ඇන්ගුයිලා",
    "country_10": "ඇන්ටාක්ටිකාව",
    "country_11": "ඇන්ටිගුවා සහ බාබුඩා",
    "country_12": "ආර්ජන්ටිනාව",
    "country_13": "ආර්මේනියාව",
    "country_14": "අරුබා",
    "country_15": "ඕස්ට්රේලියාව",
    "country_16": "ඔස්ට්රියාව",
    "country_17": "අසර්බයිජානය",
    "country_18": "බහරේනය",
    "country_19": "බංග්ලාදේශය",
    "country_20": "බාබඩෝස්",
    "country_21": "බෙලාරුස්",
    "country_22": "බෙල්ජියම",
    "country_23": "බෙලීස්",
    "country_24": "බෙනින්",
    "country_25": "බර්මියුඩා",
    "country_26": "භූතානය",
    "country_27": "බොලිවියාව",
    "country_28": "බොස්නියාව සහ හර්සගොවිනා",
    "country_29": "බොට්ස්වානා",
    "country_30": "බුවෙට් දූපත",
    "country_31": "බ්රසීලය",
    "country_32": "බ්‍රිතාන්‍ය ඉන්දියානු සාගර ප්‍රදේශය",
    "country_33": "බ්‍රිතාන්‍ය වර්ජින් දූපත්",
    "country_34": "බෲනායි",
    "country_35": "බල්ගේරියාව",
    "country_36": "බුර්කිනා",
    "country_37": "බුරුන්ඩි",
    "country_38": "කාම්බෝජය",
    "country_39": "කැමරූන්",
    "country_40": "කැනඩාව",
    "country_41": "කේප් වර්ඩ්",
    "country_42": "කැරිබියන් නෙදර්ලන්තය",
    "country_43": "කේමන් දූපත්",
    "country_44": "මධ්‍යම අප්‍රිකානු ජනරජය",
    "country_45": "චැඩ්",
    "country_46": "චිලී",
    "country_47": "චීනය",
    "country_48": "නත්තල් දූපත",
    "country_49": "Cocos (Keeling) දූපත්",
    "country_50": "කොලොම්බියාව",
    "country_51": "කුක් දූපත්",
    "country_52": "කොස්ට රිකා",
    "country_53": "කෝට් ඩි අයිවෝර්",
    "country_54": "ක්රොඒෂියාව",
    "country_55": "කියුබාව",
    "country_56": "සයිප්රසය",
    "country_57": "චෙක් ජනරජය",
    "country_58": "කොංගෝ ප්‍රජාතන්ත්‍රවාදී ජනරජය",
    "country_59": "ඩෙන්මාර්කය",
    "country_60": "ජිබුටි",
    "country_61": "ඩොමිනිකා",
    "country_62": "ඩොමිනිකානු ජනරජය",
    "country_63": "ඉක්වදෝරය",
    "country_64": "ඊජිප්තුව",
    "country_65": "එල් සැල්වදෝරය",
    "country_66": "සමක ගිනියාව",
    "country_67": "එරිත්‍රියාව",
    "country_68": "එස්තෝනියාව",
    "country_69": "ඉතියෝපියාව",
    "country_70": "ෆෝක්ලන්ඩ් දූපත්",
    "country_71": "ෆාරෝ දූපත්",
    "country_72": "මයික්‍රොනීසියාවේ ෆෙඩරේටඩ් ප්‍රාන්ත",
    "country_73": "ෆීජි",
    "country_74": "ෆින්ලන්තය",
    "country_75": "ප්රංශය",
    "country_76": "ප්‍රංශ ගියානා",
    "country_77": "ප්‍රංශ පොලිනීසියාව",
    "country_78": "ප්‍රංශ දකුණු ප්‍රදේශ",
    "country_79": "ගැබොන්",
    "country_80": "ගැම්බියාව",
    "country_81": "ජෝර්ජියාව",
    "country_82": "ජර්මනිය",
    "country_83": "ඝානාව",
    "country_84": "ජිබ්රෝල්ටාර්",
    "country_85": "මහා බ්‍රිතාන්‍යය (එක්සත් රාජධානිය; එංගලන්තය)",
    "country_86": "ග්රීසිය",
    "country_87": "ග්රීන්ලන්තය",
    "country_88": "ග්රෙනඩා",
    "country_89": "Guadeloupe",
    "country_90": "ගුවාම්",
    "country_91": "ගෝතමාලාව",
    "country_92": "ගුර්න්සි",
    "country_93": "ගිනියාව",
    "country_94": "ගිනියා-බිසාවු",
    "country_95": "ගයනාව",
    "country_96": "හයිටි",
    "country_97": "ඇහෙන දිවයින සහ මැක්ඩොනල්ඩ් දූපත්",
    "country_98": "හොන්ඩුරාස්",
    "country_99": "හොංකොං",
    "country_100": "හංගේරියාව",
    "country_101": "අයිස්ලන්තය",
    "country_102": "ඉන්දියාව",
    "country_103": "ඉන්දුනීසියාව",
    "country_104": "ඉරානය",
    "country_105": "ඉරාකය",
    "country_106": "අයර්ලන්තය",
    "country_107": "අයිල් ඔෆ් මෑන්",
    "country_108": "ඊශ්රායෙල්",
    "country_109": "ඉතාලිය",
    "country_110": "ජැමෙයිකාව",
    "country_111": "ජපානය",
    "country_112": "ජර්සි",
    "country_113": "ජෝර්දානය",
    "country_114": "කසකස්තානය",
    "country_115": "කෙන්යාව",
    "country_116": "කිරිබටි",
    "country_117": "කුවේට්",
    "country_118": "කිර්ගිස්තානය",
    "country_119": "ලාඕසය",
    "country_120": "ලැට්වියාව",
    "country_121": "ලෙබනනය",
    "country_122": "ලෙසෝතෝ",
    "country_123": "ලයිබීරියාව",
    "country_124": "ලිබියාව",
    "country_125": "ලිච්ටෙන්ස්ටයින්",
    "country_126": "ලිතුවේනියාව",
    "country_127": "ලක්සම්බර්ග්",
    "country_128": "මැකාවෝ",
    "country_129": "මැඩගස්කරය",
    "country_130": "මලාවි",
    "country_131": "මැලේසියාව",
    "country_132": "මාලදිවයින",
    "country_133": "මාලි",
    "country_134": "මෝල්ටා",
    "country_135": "මාෂල් දූපත්",
    "country_136": "මාර්ටිනික්",
    "country_137": "මොරිටේනියාව",
    "country_138": "මොරිෂස්",
    "country_139": "මයෝට්",
    "country_140": "මෙක්සිකෝව",
    "country_141": "මෝල්ඩෝවා",
    "country_142": "මොනාකෝ",
    "country_143": "මොංගෝලියාව",
    "country_144": "මොන්ටිනිග්රෝ",
    "country_145": "මොන්ට්සෙරාට්",
    "country_146": "මොරොක්කෝව",
    "country_147": "මොසැම්බික්",
    "country_148": "මියන්මාරය (බුරුමය)",
    "country_149": "නැමීබියාව",
    "country_150": "නාඌරූ",
    "country_151": "නේපාලය",
    "country_152": "නෙදර්ලන්තය",
    "country_153": "නව කැලිඩෝනියාව",
    "country_154": "නවසීලන්තය",
    "country_155": "නිකරගුවාව",
    "country_156": "නයිජර්",
    "country_157": "නයිජීරියාව",
    "country_158": "නියු",
    "country_159": "නොර්ෆොක් දූපත",
    "country_160": "උතුරු කොරියාව",
    "country_161": "උතුරු මරියානා දූපත්",
    "country_162": "නෝර්වේ",
    "country_163": "ඕමානය",
    "country_164": "පකිස්ථානය",
    "country_165": "පලාවු",
    "country_166": "පලස්තීන ප්‍රදේශ",
    "country_167": "පැනමාව",
    "country_168": "පැපුවා නිව්ගීනියාව",
    "country_169": "පැරගුවේ",
    "country_170": "පේරු",
    "country_171": "පිට්කේන් දූපත්",
    "country_172": "පෝලන්තය",
    "country_173": "පෘතුගාලය",
    "country_174": "පුවර්ටෝ රිකෝ",
    "country_175": "කටාර්",
    "country_176": "මැසිඩෝනියා ජනරජය (FYROM)",
    "country_177": "කොංගෝ ජනරජය",
    "country_178": "Reunion",
    "country_179": "රුමේනියාව",
    "country_180": "රුසියානු සමූහාණ්ඩුව",
    "country_181": "රුවන්ඩාව",
    "country_182": "ශාන්ත බර්තෙලමි",
    "country_183": "ශාන්ත මාටින් (ප්‍රංශය)",
    "country_184": "Saint-Pierre සහ Miquelon",
    "country_185": "සැමෝවා",
    "country_186": "සැන් මරිනෝ",
    "country_187": "Sao Tome & ප්‍රින්සිප්",
    "country_188": "සවුදි අරාබිය",
    "country_189": "සෙනගල්",
    "country_190": "සර්බියාව",
    "country_191": "සීෂෙල්ස්",
    "country_192": "සියාරා ලියොන්",
    "country_193": "සිංගප්පූරුව",
    "country_194": "ස්ලෝවැකියාව",
    "country_195": "ස්ලෝවේනියාව",
    "country_196": "සොලමන් දූපත්",
    "country_197": "සෝමාලියාව",
    "country_198": "දකුණු අප්රිකාව",
    "country_199": "දකුණු ජෝර්ජියාව සහ දකුණු සැන්ඩ්විච් ඉස්ලා",
    "country_200": "දකුණු කොරියාව",
    "country_201": "දකුණු සුඩානය",
    "country_202": "ස්පාඤ්ඤය",
    "country_203": "ශ්රී ලංකාව",
    "country_204": "ශාන්ත හෙලේනා සහ යැපීම්",
    "country_205": "ශාන්ත කිට්ස් සහ නෙවිස්",
    "country_206": "ශාන්ත ලුසියා",
    "country_207": "ශාන්ත වින්සන්ට් සහ ග්‍රෙනඩින්ස්",
    "country_208": "සුඩානය",
    "country_209": "සුරිනාමය",
    "country_210": "ස්වාසිලන්තය",
    "country_211": "ස්වීඩනය",
    "country_212": "ස්විට්සර්ලන්තය",
    "country_213": "සිරියාව",
    "country_214": "තායිවානය",
    "country_215": "ටජිකිස්තානය",
    "country_216": "ටැන්සානියාව",
    "country_217": "සැකිල්ල:රටේ දත්ත SJM Svalbard",
    "country_218": "තායිලන්තය",
    "country_219": "බහමාස්",
    "country_220": "කොමරෝස්",
    "country_221": "පිලිපීනය",
    "country_222": "ටිමෝරය-ලෙස්ටේ (නැගෙනහිර ටිමෝරය)",
    "country_223": "යාමට",
    "country_224": "ටොකෙලාව්",
    "country_225": "ටොංගා",
    "country_226": "ට්‍රිනිඩෑඩ් සහ ටොබැගෝ",
    "country_227": "ටියුනීසියාව",
    "country_228": "කළුකුමා",
    "country_229": "ටර්ක්මෙනිස්තානය",
    "country_230": "ටර්ක්ස් සහ කයිකෝස් දූපත්",
    "country_231": "ටුවාලු",
    "country_232": "උගන්ඩාව",
    "country_233": "යුක්රේනය",
    "country_234": "එක්සත් අරාබි එමීර් රාජ්‍යය",
    "country_235": "එක්සත් ජනපද සුළු පිටස්තර දූපත්",
    "country_236": "ඇමරිකා එක්සත් ජනපදය (ඇමරිකා එක්සත් ජනපදය)",
    "country_237": "එක්සත් ජනපද වර්ජින් දූපත්",
    "country_238": "උරුගුවේ",
    "country_239": "උස්බෙකිස්තානය",
    "country_240": "වනුවාටු",
    "country_241": "වතිකානුව (ශුද්ධ බලන්න)",
    "country_242": "වෙනිසියුලාව",
    "country_243": "වියට්නාමය",
    "country_244": "Wallis සහ Futuna",
    "country_245": "බටහිර සහරා",
    "country_246": "යේමනය",
    "country_247": "සැම්බියාව",
    "country_248": "සිම්බාබ්වේ",
    "google_login": "Google සමඟින් පුරනය වන්න",
    "State": "රජයේ",
    "Activation_code": "සක්රිය කිරීමේ කේතය",
    "Question": "ඔබ පුරනය වී ඇති සියලුම යෙදුම් ලැයිස්තුගත කරන්න",
    "Copy_complete": "පිටපත සම්පූර්ණයි",
    "footer": "ප්‍රකාශන හිමිකම © 2024 MindOnMap Studio. සියලු හිමිකම් ඇවිරිණි.",
    "change_password_success": "මුරපදය සාර්ථකව වෙනස් විය",
    "successful_login_title": "ලොගින් වීම සාර්ථකයි",
    "product_page": "නිෂ්පාදන පිටුව >>",
    "successful_login_info": "ලොගින් වීම සම්පූර්ණයි. පහත ක්‍රියාවලිය දිගටම කරගෙන යාමට කරුණාකර වත්මන් පිටුව වසා මුල් පටිත්ත වෙත ආපසු යන්න. වත්මන් පිටුව තත්පර 5කින් ස්වයංක්‍රීයව වැසෙයි. \"Done\" බොත්තම ක්ලික් කිරීමෙන් ස්වයංක්‍රීයව වැසීම හෝ වැසීම අසාර්ථක වුවහොත්, කරුණාකර මෙම පටිත්ත කෙලින්ම වසන්න.",
    "successful_login_info_firefox": "ලොගින් වීම සම්පූර්ණයි. පහත ක්‍රියාවලිය දිගටම කරගෙන යාමට කරුණාකර වත්මන් පිටුව වසා මුල් පටිත්ත වෙත ආපසු යන්න.",
    "my_account": "මගේ ගිණුම",
    "my_history": "මගේ ඉතිහාසය",
    "remove_watermark": "ජල සලකුණ ඉවත් කරන්න",
    "no_history": "ඉතිහාසයක් නැත",
    "history_all": "සියල්ල තෝරන්න",
    "history_open": "විවෘත",
    "history_down": "බාගත",
    "history_delete": "මකන්න",
    "history_clear": "අවලංගු අවලංගුයි",
    "images": "රූප",
    "use_this_function": "මෙම නිෂ්පාදනය භාවිතා කරන්න>>",
    "hd_downloading": "HD මුල් පින්තූර බාගත කිරීමේ ප්‍රතිලාභ:",
    "lifetimeRemaining": "ජීවිත කාලය - ඉතිරි",
    "Remaining": "ඉතිරි",
    "email_verification": "ඊමේල් සත්යාපනය",
    "email_verification_info": "අපි ඔබගේ ඊමේල් <span class=\"email\"></span> වෙත සත්‍යාපන පණිවිඩය යවා ඇති අතර, කරුණාකර සත්‍යාපනය අවසන් කරන්න. සත්‍යාපනය කිරීමෙන් පසු, ප්‍රතිලාභ ස්වයංක්‍රීයව සමමුහුර්ත වනු ඇත.",
    "wrong_email": "වැරදි ඊමේල් ලිපිනයක්ද?",
    "click_here_to_modify": "වෙනස් කිරීමට මෙතන ක්ලික් කරන්න",
    "get_more_help": "තවත් උදවු ලබාගන්නද?",
    "click_here": "මෙහි ක්ලික් කරන්න",
    "email_verification_info_success": "ඔබගේ විද්‍යුත් තැපැල් ගිණුම සත්‍යාපනය කිරීම ගැන සුභ පැතුම්.",
    "email_verification_info_error": "කල් ඉකුත් වූ සබැඳිය නිසා සත්‍යාපනය අසාර්ථක විය.",
    "registration_succeeded": "ලියාපදිංචිය සාර්ථකයි",
    "registration_succeeded_info_1": "සුභ පැතුම්! ඔබ සාර්ථකව ලියාපදිංචි වී ඇත. අපි ඔබගේ ඊමේල් <span class=\"email\"></span> වෙත සත්‍යාපන පණිවිඩය යවා ඇති අතර, කරුණාකර මෙම විද්‍යුත් තැපෑලෙහි ප්‍රතිලාභ භාවිතා කිරීමට සත්‍යාපනය අවසන් කරන්න.",
    "registration_succeeded_info_2": "මුල් පිටුවට ආපසු ගොස් මෙම නිෂ්පාදනය භාවිතා කිරීමට \"Done\" ක්ලික් කරන්න.",
    "registration_succeeded_info_3": "වත්මන් පිටුව වැසීමට \"Done\" ක්ලික් කර පහත මෙහෙයුම් සඳහා මුල් පිටුවට ආපසු යන්න. වසා දැමීම අසාර්ථක නම්, ටැබ් එක අතින් වසන්න.",
    "verify_email": "විද්‍යුත් තැපෑල තහවුරු කරන්න",
    "registered_email_not_verified": "ලියාපදිංචි විද්‍යුත් තැපෑල සත්‍යාපනය කර නැත, කරුණාකර එය එකවර සත්‍යාපනය කරන්න.",
    "email_verification_time_1": "සත්‍යාපන විද්‍යුත් තැපෑලක් නොලැබුනේද?",
    "email_verification_time_2": "අනතුරුව",
    "email_verification_time_3": "එය නැවත යැවීමට මෙතන ක්ලික් කරන්න",
    "error_26301": "දෝෂ කේතය: 26301, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26302": "දෝෂ කේතය: 26302, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26303": "ඊමේල් ආකෘති දෝෂය (දෝෂ කේතය: 26303). කරුණාකර එය නැවත ඇතුල් කරන්න",
    "error_26304": "අක්ෂර 8 ට වැඩි මුරපදය නිර්දේශ කෙරේ (දෝෂ කේතය: 26304)",
    "error_26305": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26305). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26306": "විද්‍යුත් තැපෑල ලියාපදිංචි කර ඇත, කරුණාකර <a href=\"https://account.mindonmap.com/login/\">පිවිසීමට යන්න</a>",
    "error_26307": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26307). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26308": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26308). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26401": "දෝෂ කේතය: 26401, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26402": "විද්‍යුත් තැපෑල සත්‍යාපනය කර ඇත (දෝෂ කේතය: 26402), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26403": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26403). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26404": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 26404). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26501": "දෝෂ කේතය: 26501, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26502": "දෝෂ කේතය: 26502, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26503": "ඊමේල් ආකෘති දෝෂය (දෝෂ කේතය: 26503). කරුණාකර එය නැවත ඇතුල් කරන්න",
    "error_26504": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26504). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26505": "විද්‍යුත් තැපෑල ලියාපදිංචි කර නැත, කරුණාකර <a href=\"https://account.mindonmap.com/register/\">එය පළමුව ලියාපදිංචි කරන්න</a>",
    "error_26506": "විද්‍යුත් තැපෑල සත්‍යාපනය කර ඇත.",
    "error_26507": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26507). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26508": "සත්‍යාපනය ගොනු කර ඇත (දෝෂ කේතය: 26508), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26509": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 26509), කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26510": "දෝෂ කේතය: 26510, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26601": "දෝෂ කේතය: 26601, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26602": "දෝෂ කේතය: 26602, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26603": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26603). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26604": "දෝෂ කේතය: 26604, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26605": "දෝෂ කේතය: 26605, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26701": "දෝෂ කේතය: 26701, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26702": "Reuqest අසාර්ථක විය (දෝෂ කේතය: 26702). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26703": "දෝෂ කේතය: 26703, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26704": "දෝෂ කේතය: 26704, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26705": "පුරනය වීම සඳහා රැඳී සිටින්න (දෝෂ කේතය: 26705). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "no_cookie": "ඔබ ඔබගේ බ්‍රවුසරයේ සියලුම කුකීස් අවහිර කිරීමේ කාර්යය ක්‍රියාත්මක කර ඇත, එබැවින් ඔබට ලොග් විය නොහැක. කරුණාකර සියලු කුකීස් වලට ඉඩ දෙන්න යන කොටුව සලකුණු කිරීමට සැකසීම් වෙත යන්න.",
    "error_26801": "දෝෂ කේතය: 26801, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26802": "දෝෂ කේතය: 26802, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26803": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 26803). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26804": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 26804). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_order": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27098), කරුණාකර එය නැවත උත්සාහ කරන්න!",
    "error_order1": "ඇණවුම් විමසුම අසම්පූර්ණයි (දෝෂ කේතය: ",
    "error_order2": "), කරුණාකර නැවුම් කර එය නැවත උත්සාහ කරන්න.",
    "modify_email_title": "ඊමේල් වෙනස් කරන්න",
    "modify_email_info": "ඔබගේ ගිණුමට ලොග් වීමට ඔබට වෙනස් කළ විද්‍යුත් තැපෑල භාවිතා කළ හැක.",
    "images_per": "පින්තූර අනුව",
    "error_26101": "දෝෂය: 26101. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26102": "දෝෂය: 26102. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26103": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය:26103). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26104": "දෝෂ කේතය: 26104, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26105": "දෝෂ කේතය: 26105, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26106": "මකන්න අසාර්ථක (දෝෂ කේතය: 26106). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26201": "දෝෂය: 26201. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26202": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය:26202). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26001": "දෝෂය: 26001. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26002": "දෝෂය: 26002. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26003": "දෝෂය: 26003. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26004": "දෝෂය: 26004. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_26005": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය:26005). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26006": "දෝෂ කේතය: 26006, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_26008": "දෝෂය: 26008. කරුණාකර අප හා සම්බන්ධ වන්න",
    "go_to_the_home_page": "මුල් පිටුවට යන්න",
    "error_27101": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27101). කරුණාකර නැවත උත්සාහ කරන්න",
    "error_27201": "දෝෂ කේතය: 27201, කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_27202": "දෝෂ කේතය: 27202, කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_27203": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27203). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_27204": "වලංගු නොවන කේතය (දෝෂ කේතය: 27204).",
    "error_27205": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27205). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_27206": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27206). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_27207": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 27207). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "no_history_found": "ඔබ කිසිම මෙවලමක් භාවිතා කර නැත! <a href=\"/\">නැවුම් කරන්න</a> හෝ <a href=\"https://www.mindonmap.com/\">නිල වෙබ් අඩවිය වෙත යන්න</a>",
    "error_25301": "දෝෂය: 25301. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_25302": "දෝෂය: 25302. කරුණාකර අප හා සම්බන්ධ වන්න",
    "error_25303": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 25303). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_25304": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 25304). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_25305": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 25305). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "error_25306": "ඉල්ලීම අසාර්ථක විය (දෝෂ කේතය: 25306). කරුණාකර එය නැවත උත්සාහ කරන්න",
    "image_upscaler_p": "ජල සලකුණක් නොමැති රූපය බාගත කිරීමේ ප්‍රතිලාභ:",
    "Available_for": "ලබා ගත හැක්කේ:",
    "credit_per": "HD රූපයකට ණය(ය) %s",
    "still_valid": "මිලදී ගත් සැලැස්ම(ය) තවමත් වලංගු වේ",
    "credit": "ණය(ය)",
    "pc_3rd_info": "සාර්ථකව පුරනය වන්න. වැඩිදුර ක්‍රියාකාරිත්වය සඳහා කරුණාකර යෙදුම වෙත යන්න.",
    "use_online": "මාර්ගගත සේවාව භාවිතා කරන්න",
    "use_download": "ඩෙස්ක්ටොප් වැඩසටහන භාවිතා කරන්න",
    "use_immediately": "වහාම භාවිතා කරන්න",
    "Use_in_browser": "බ්‍රව්සරයේ භාවිතා කරන්න",
    "win_desktop": "වින්ඩෝස්",
    "Mac_desktop": "Mac",
    "credits_per": "මසකට ණය {%}ක්",
    "expire": "කල් ඉකුත්වන කාලය:",
    "viewDetails": "තොරතුරු පෙන්වන්න",
    "viewHistory": "ඉතිහාස ප්‍රතිලාභ බලන්න>>",
    "viewDetailsInfo": "දැනුම්දීම: දායකත්ව ප්‍රතිලාභ කල් ඉකුත්වීමෙන් පසු දින 7ක් ඇතුළත අලුත් කරන්නේ නම්, භාවිත නොකළ ප්‍රතිලාභ දිගටම භාවිත කළ හැක. එසේම, කල් ඉකුත්වන කාලය නව දායකත්වයේ කල් ඉකුත්වන වේලාවට ස්වයංක්‍රීයව යාවත්කාලීන වේ. කල් ඉකුත් වීමේ කාලයෙන් දින 7කට පසුව නව දායකත්වයක් නොමැති නම්, කල් ඉකුත් වූ සියලුම ප්‍රතිලාභ නිෂ්කාශනය වේ.",
    "connect_account": "ඔබගේ ගිණුමට විද්‍යුත් තැපෑල බඳින්න",
    "connect_account_info": "බැඳීමෙන් පසු, ඔබට මෙම විද්‍යුත් තැපැල් ලිපිනයෙන් ලොග් විය හැකිය.",
    "connect_now": "දැන් බැඳන්න",
    "no_email_bind": "ඊමේල් බැඳීමක් නැත",
    "bind_email": "ඊමේල් බැඳන්න",
    "connect_your_email_placeholder": "කරුණාකර ඔබගේ විද්‍යුත් තැපැල් ලිපිනය ඇතුලත් කරන්න",
    "bind_an_email": "විද්‍යුත් තැපෑලක් බැඳන්න",
    "bind_info": "ඔබ සාර්ථකව පුරනය වී ඇත. ඔබගේ ප්‍රතිලාභ සක්‍රිය කිරීමට කරුණාකර ඔබගේ ගිණුමට විද්‍යුත් තැපෑලක් බැඳන්න.",
    "bind_later": "පසුව බඳින්න",
    "hi": "ඔහුගේ!",
    "Personal_Information": "පුද්ගලික තොරතුරු",
    "Access": "ප්රවේශ",
    "Subscription_Plan": "(දායකත්ව සැලැස්ම)",
    "No_orders": "ඇණවුම් කිසිවක් හමු නොවීය.",
    "No_data": "දත්ත නැත",
    "Featured_Products": "විශේෂාංග නිෂ්පාදන",
    "More_Products": "තවත් නිෂ්පාදන",
    "Free_Download": "නොමිලේ බාගත කරන්න",
    "Get_Started": "ආරම්භ කරන්න",
    "Subscribe": "දායක වන්න",
    "Verified": "තහවුරු කර ඇත",
    "back_to_account_center": "ගිණුම් මධ්‍යස්ථානය වෙත ආපසු යන්න",
    "success": "සාර්ථකත්වය!",
    "successfully": "ඔබ සාර්ථකව ගිණුමක් ලියාපදිංචි කර ඇත.",
    "Continue": "දිගටම කරගෙන යන්න",
    "Already": "දැනටමත් ගිණුමක් තිබේද?",
    "loading_verification": "සත්‍යාපන තත්ත්වය පරීක්ෂා කිරීම",
    "email_no_verification": "කණගාටුයි, ලියාපදිංචි ඊමේල් ලිපිනය සත්‍යාපනය කර නැත. කරුණාකර ඉහත උපදෙස් අනුව සත්‍යාපනය සම්පූර්ණ කර ලියාපදිංචිය සම්පූර්ණ කිරීමට \"Done\" බොත්තම නැවත ක්ලික් කරන්න.",
    "will_expire_after": "පසුව කල් ඉකුත් වේ",
    "hours": "පැය",
  },
  "sk": {
    "overtime": "Kód chyby: {%}, skúste to znova",
    "isnetwork": "Chyba internetu. Skontrolujte a skúste to znova",
    "email_placeholder": "Email",
    "email_empty": "Zadajte e-mail",
    "email_not_valid": "E-mail nie je platný",
    "email_not_valid_1": "Zadajte svoj email",
    "email_not_valid_2": "E-mail je neplatný, použite inú adresu.",
    "email_not_valid_3": "Žiadne zadávanie e-mailov",
    "password_placeholder": "heslo",
    "password_empty": "Zadajte heslo",
    "password_not_valid": "Nesprávny účet alebo heslo",
    "password_not_valid_1": "Vyžaduje sa heslo dlhšie ako 8 znakov",
    "password_not_valid_2": "Vytvorte si heslo",
    "password_placeholder_1": "Vytvorte si heslo",
    "password_placeholder_2": "Potvrď svoje heslo",
    "password_placeholder_3": "Vytvorte nové heslo",
    "password_placeholder_4": "Potvrďte nové heslo",
    "password_placeholder_5": "Zadajte staré heslo",
    "copy_password_empty": "Potvrďte heslo",
    "copy_password_not_valid": "Potvrďte svoje heslo",
    "copy_passwords_inconsistent": "Vaše potvrdenie hesla sa nezhoduje",
    "code_empty": "Zadajte overovací kód",
    "code_not_valid": "Neplatný verifikačný kód",
    "code_placeholder": "Overovací kód",
    "not_received_code": "Ak vaša poštová schránka nedostala overovací kód dlhší čas, získajte overovací kód znova.",
    "get_first_code": "Najprv získajte overovací kód.",
    "last_name_placeholder": "Zadajte svoje priezvisko",
    "first_name_placeholder": "Zadajte svoje krstné meno",
    "address_placeholder": "Zadajte svoju adresu",
    "no_code_text": "Poslali sme overovací kód. Zadajte svoj kód. <span class='tips'>Nedostali ste kód?",
    "no_code_text_1": "1. Uistite sa, že e-mailová adresa je platná a môže prijímať e-maily.",
    "no_code_text_2": "2. Pretože e-mail odosiela systém automaticky, môže byť označený ako spam alebo nevyžiadaná pošta. Skontrolujte, či sa e-mail nachádza v priečinku Kôš.",
    "no_code_text_3": "3. Neviete vyriešiť svoj problém? ",
    "no_code_text_3_span": "Potom kliknite sem a kontaktujte nás.",
    "order_no": "Nekúpili ste si žiadny produkt. Ak máte nejaké otázky, <a href=\"https://www.mindonmap.com/contact-us/\">kontaktujte nás</a>.",
    "error_24901": "Aktuálny účet nemá prepojený žiadny e-mail a nemôže nájsť objednávky. Pripojte e-mail.",
    "user_guide": "Používateľská príručka >>",
    "download": "Stiahnuť ▼",
    "order_number": "Číslo objednávky:",
    "Refund": "Vrátenie peňazí",
    "Disabled": "Zakázané",
    "Normal": "Normálne",
    "Modify": "Upraviť",
    "Modify_1": "Upraviť >>",
    "Connect": "Pripojte sa",
    "unlink_success": "Odpojenie bolo úspešné",
    "connect_success": "Pripojte sa úspešne",
    "feedback_title": "Ďakujem za spätnú väzbu. Zanechajte prosím svoj problém a my vám odpovieme do 24 hodín.",
    "feedback_thank_you": "Ďakujeme!<br />Vaša spätná väzba bola úspešne odoslaná.",
    "feedback_email": "Tu zadajte svoj email!",
    "feedback_content": "Zanechajte tu akýkoľvek problém alebo návrh, s ktorým ste sa stretli.",
    "feedback_submit": "Predložiť",
    "form_contents": "Nezadali ste žiadny popis. Zadajte ho a odošlite znova.",
    "old_password": "Zadajte staré heslo",
    "new_password": "Vytvorte si nové heslo",
    "old_new_password": "Nové heslo nemôže byť rovnaké ako staré",
    "incorrect_password": "nesprávne heslo",
    "delete_no": "Odstrániť teraz",
    "Caps": "Caps lock je zapnutý",
    "Get": "Získajte",
    "Done": "hotový",
    "error_20001": "Chyba: 20001. Prihláste sa znova.",
    "error_20002": "Chyba: 20002. Prihláste sa znova.",
    "error_20003": "Chyba: 20003. Prihláste sa znova.",
    "error_20004": "Požiadavka zlyhala (kód chyby: 20004). Skúste to znova.",
    "error_20005": "Prihlasovacia relácia vypršala (Chyba: 20005). Prihláste sa znova.",
    "error_20006": "Požiadavka zlyhala (kód chyby: 20006). Skúste to znova.",
    "error_20007": "Prihlasovacia relácia vypršala (Chyba: 20007). Prihláste sa znova.",
    "error_20008": "Prihlasovacia relácia vypršala (Chyba: 20008). Prihláste sa znova.",
    "error_20009": "Prihlasovacia relácia vypršala (Chyba: 20009). Prihláste sa znova.",
    "error_20101": "Zadajte svoj e-mail (kód chyby: 20101)",
    "error_20102": "E-mail nie je platný (kód chyby: 20102)",
    "error_20103": "Požiadavka zlyhala (kód chyby: 20103). Skúste to znova",
    "error_20104": "E-mail sa už používa, <a href=\"https://account.mindonmap.com/login/\">prihláste sa</a> alebo sa zaregistrujte s novým",
    "error_20105": "Požiadavka zlyhala (kód chyby: 20105). Skúste to znova",
    "error_20106": "E-mail sa nepodarilo odoslať, skúste to znova",
    "error_20201": "Zadajte svoj e-mail (kód chyby: 20201)",
    "error_20202": "Zadajte svoje heslo (kód chyby: 20202)",
    "error_20203": "Zadajte overovací kód (kód chyby: 20203)",
    "error_20204": "E-mail je neplatný (kód chyby: 20204)",
    "error_20205": "Vyžaduje sa heslo dlhšie ako 8 znakov (kód chyby: 20205)",
    "error_20206": "Požiadavka zlyhala (kód chyby: 20206). Skúste to znova",
    "error_20207": "Neplatný verifikačný kód",
    "error_20208": "Požiadavka zlyhala (kód chyby: 20208). Skúste to znova",
    "error_20209": "Požiadavka zlyhala (kód chyby: 20209). Skúste to znova",
    "error_20301": "Zadajte svoj e-mail (kód chyby: 20301)",
    "error_20302": "Chyba: 20302. Kontaktujte nás",
    "error_20303": "E-mail je neplatný (kód chyby: 20303)",
    "error_20304": "Požiadavka zlyhala (kód chyby: 20304). Skúste to znova",
    "error_20305": "Účet neexistuje. Najprv ho zadajte znova alebo ho <a href=\"https://account.mindonmap.com/register/\">vytvorte</a>.",
    "error_20306": "Zatiaľ žiadne heslo. Použite <a href=\"https://account.mindonmap.com/passwordless-login/\">Prihlásenie bez hesla</a> alebo <a href=\"https://account.mindonmap.com/create-password/\"> nastavte heslo</a> a prihláste sa.",
    "error_20308": "Požiadavka zlyhala (kód chyby: 20308). Skúste to znova",
    "error_20401": "Nepodarilo sa odhlásiť (kód chyby: 20401). Skúste to znova",
    "error_20501": "Zadajte svoj e-mail (kód chyby: 20501)",
    "error_20502": "E-mail je neplatný (kód chyby: 20502)",
    "error_20503": "Požiadavka zlyhala (kód chyby: 20503). Skúste to znova",
    "error_20504": "Odoslanie e-mailu zlyhalo. Skúste to znova.",
    "error_20601": "Zadajte svoj e-mail (kód chyby: 20601)",
    "error_20602": "Zadajte overovací kód (kód chyby: 20602)",
    "error_20603": "E-mail nie je platný (kód chyby: 20603)",
    "error_20604": "Požiadavka zlyhala (kód chyby: 20604). Skúste to znova",
    "error_20606": "Požiadavka zlyhala (kód chyby: 20606). Skúste to znova",
    "error_20607": "Požiadavka zlyhala (kód chyby: 20607). Skúste to znova",
    "error_20608": "Požiadavka zlyhala (kód chyby: 20608). Skúste to znova",
    "error_20701": "Zadajte svoj e-mail (kód chyby: 20701)",
    "error_20702": "E-mail nie je platný (kód chyby: 20702)",
    "error_20703": "Požiadavka zlyhala (kód chyby: 20703). Skúste to znova",
    "error_20704": "Účet neexistuje. Najprv ho zadajte znova alebo ho <a href=\"https://account.mindonmap.com/register/\">vytvorte</a>.",
    "error_20705": "Požiadavka zlyhala (kód chyby: 20705). Skúste to znova",
    "error_20706": "Odoslanie e-mailu zlyhalo. Skúste to znova",
    "error_20801": "Zadajte svoj e-mail (kód chyby: 20801)",
    "error_20802": "Chyba: 20802. Kontaktujte nás",
    "error_20803": "Zadajte overovací kód (kód chyby: 20803)",
    "error_20804": "E-mail je neplatný (kód chyby: 20804)",
    "error_20805": "Vyžaduje sa heslo dlhšie ako 8 znakov (kód chyby: 20805)",
    "error_20806": "Požiadavka zlyhala (kód chyby: 20806). Skúste to znova",
    "error_20808": "Požiadavka zlyhala (kód chyby: 20808). Skúste to znova",
    "error_20901": "Požiadavka zlyhala (kód chyby: 20901). Skúste to znova",
    "error_20902": "Požiadavka zlyhala (kód chyby: 20902). Skúste to znova",
    "error_21000": "Zmeny sa uložia",
    "error_21001": "Nie sú odoslané žiadne informácie (kód chyby: 21001)",
    "error_21002": "Požiadavka zlyhala (kód chyby: 21002). Skúste to znova",
    "error_21101": "Zadajte svoj e-mail (kód chyby: 21101)",
    "error_21102": "E-mail je neplatný (kód chyby: 21102)",
    "error_21103": "Požiadavka zlyhala (kód chyby: 21103), skúste to znova",
    "error_21104": "E-mail je už pripojený, použite nový",
    "error_21105": "Požiadavka zlyhala (kód chyby: 21105), skúste to znova",
    "error_21106": "Odoslanie e-mailu zlyhalo. Skúste to znova",
    "error_21201": "Zadajte svoj e-mail (kód chyby: 21201)",
    "error_21202": "Zadajte overovací kód (kód chyby: 21202)",
    "error_21203": "E-mail je neplatný (kód chyby: 21203)",
    "error_21204": "Chyba: 21204. Kontaktujte nás",
    "error_21205": "Chyba: 21205. Kontaktujte nás",
    "error_21206": "Vyžaduje sa heslo dlhšie ako 8 znakov (kód chyby: 21206)",
    "error_21207": "Požiadavka zlyhala (kód chyby: 21207). Skúste to znova",
    "error_21209": "Požiadavka zlyhala (kód chyby: 21209). Skúste to znova",
    "error_21301": "Zadajte staré heslo (kód chyby: 21301)",
    "error_21302": "Vytvorte si nové heslo (kód chyby: 21302)",
    "error_21303": "Nové heslo nemôže byť rovnaké ako staré. (Chyba: 21303)",
    "error_21304": "Vyžaduje sa heslo dlhšie ako 8 znakov (kód chyby: 21304)",
    "error_21306": "Požiadavka zlyhala (kód chyby: 21306). Skúste to znova",
    "error_21402": "Požiadavka zlyhala (kód chyby: 21402). Skúste to znova",
    "error_21403": "Odoslanie overovacieho kódu zlyhalo. Pošlite to znova",
    "error_21500": "Účet bol odstránený",
    "error_21501": "Zadajte overovací kód (kód chyby: 21501)",
    "error_21502": "Prihlasovacia relácia vypršala (Chyba: 21502). Prihláste sa znova.",
    "error_21503": "Požiadavka zlyhala (kód chyby: 21503). Skúste to znova",
    "error_21505": "Požiadavka zlyhala (kód chyby: 21505), skúste to znova",
    "error_21601": "Chyba: 20601. Kontaktujte nás",
    "error_21602": "Neplatné overenie (chyba: 20602). Skúste to znova.",
    "error_21603": "Chyba: 20603. Skúste to znova",
    "error_21604": "Požiadavka zlyhala (kód chyby: 21604). Skúste to znova",
    "error_21606": "Požiadavka zlyhala (kód chyby: 21606). Skúste to znova",
    "error_21611": "Požiadavka zlyhala (kód chyby: 21611). Skúste to znova",
    "error_21801": "Chyba: 21801. Kontaktujte nás",
    "error_21802": "Požiadavka zlyhala (chyba: 21802). Skúste to znova",
    "error_21803": "Chyba: 21803. Skúste to znova",
    "error_21804": "Požiadavka zlyhala (kód chyby: 21804). Skúste to znova",
    "error_21806": "Chyba: 21806. Skúste to znova",
    "error_21807": "Chyba: 21807. Kontaktujte nás",
    "error_21808": "Chyba: 21808. Kontaktujte nás",
    "error_21809": "Chyba: 21809. Kontaktujte nás",
    "error_21810": "Chyba: 21810. Kontaktujte nás",
    "error_21811": "Chyba: 21811. Kontaktujte nás",
    "error_21812": "Chyba: 21812. Kontaktujte nás",
    "error_21813": "Požiadavka zlyhala (kód chyby: 21813). Skúste to znova",
    "error_21814": "Chyba: 21814. Kontaktujte nás",
    "error_21815": "Požiadavka zlyhala (kód chyby: 21815). Skúste to znova",
    "error_21816": "Chyba: 21816. Kontaktujte nás",
    "error_21817": "Chyba: 21817. Kontaktujte nás",
    "error_21818": "Chyba: 21818. Kontaktujte nás",
    "error_21819": "Požiadavka zlyhala (kód chyby: 21819). Skúste to znova",
    "error_21820": "Chyba: 21820. Kontaktujte nás",
    "error_21821": "Chyba: 21821. Kontaktujte nás",
    "error_21822": "Chyba: 21822. Kontaktujte nás",
    "error_21823": "Požiadavka zlyhala (kód chyby: 21823). Skúste to znova",
    "error_21824": "Požiadavka zlyhala (kód chyby: 21824). Skúste to znova",
    "error_21825": "Požiadavka zlyhala (kód chyby: 21825). Skúste to znova",
    "error_21826": "Požiadavka zlyhala (kód chyby: 21826). Skúste to znova",
    "error_21901": "Chyba: 21901. Kontaktujte nás",
    "error_21902": "Požiadavka zlyhala (kód chyby: 21902). Skúste to znova",
    "error_21903": "Stav účtu sa zmenil (kód chyby: 21903), obnovte stránku a skúste to znova",
    "error_21904": "Chyba: 21904. Skúste to znova",
    "error_21905": "Chyba: 21905. Skúste to znova",
    "error_21906": "Požiadavka zlyhala (kód chyby: 21906). Skúste to znova",
    "error_21907": "Účet Google bol prepojený s iným účtom",
    "error_21908": "Požiadavka zlyhala (kód chyby: 21908). Skúste to znova",
    "error_22001": "Požiadavka zlyhala (kód chyby: 22001). Skúste to znova",
    "error_22002": "Odpojenie zlyhalo bez ďalšieho prihlásenia",
    "error_22003": "Požiadavka zlyhala (kód chyby: 22003). Skúste to znova",
    "error_22101": "Chyba: 22101. Kontaktujte nás",
    "error_22102": "Stav účtu sa zmenil (kód chyby: 22102), obnovte stránku a skúste to znova",
    "error_22103": "Požiadavka zlyhala (kód chyby: 22103). Skúste to znova",
    "error_22104": "Stav účtu sa zmenil (kód chyby: 22104), obnovte stránku a skúste to znova",
    "error_22105": "Chyba: 22105. Skúste to znova",
    "error_22106": "Chyba: 22106. Skúste to znova",
    "error_22107": "Chyba: 22107. Kontaktujte nás",
    "error_22108": "Požiadavka zlyhala (kód chyby: 22108). Skúste to znova",
    "error_22201": "Chyba: 22201. Kontaktujte nás",
    "error_22202": "Neplatné overenie (chyba: 22202). Skúste to znova.",
    "error_22203": "Chyba: 22203. Skúste to znova\"",
    "error_22204": "Požiadavka zlyhala (kód chyby: 22204). Skúste to znova",
    "error_22206": "Požiadavka zlyhala (kód chyby: 22206). Skúste to znova",
    "error_22401": "Chyba: 22401. Kontaktujte nás",
    "error_22402": "Neplatné overenie (chyba: 22402). Skúste to znova.",
    "error_22403": "Chyba: 22403. Skúste to znova",
    "error_22404": "Požiadavka zlyhala (kód chyby: 22404). Skúste to znova",
    "error_22405": "Účet Facebook bol prepojený s iným e-mailom",
    "error_22406": "Chyba: 22406. Skúste to znova",
    "error_22407": "Chyba: 22407. Kontaktujte nás",
    "error_22408": "Chyba: 22408. Kontaktujte nás",
    "error_22409": "Chyba: 22409. Kontaktujte nás",
    "error_22410": "Chyba: 224010. Kontaktujte nás",
    "error_22411": "Chyba: 224011. Kontaktujte nás",
    "error_22412": "Chyba: 224012. Kontaktujte nás",
    "error_22413": "Chyba: 22413. Kontaktujte nás",
    "error_22414": "Požiadavka zlyhala (kód chyby: 22414). Skúste to znova",
    "error_22415": "Chyba: 22415. Kontaktujte nás",
    "error_22416": "Chyba: 22416. Kontaktujte nás",
    "error_22417": "Chyba: 22417. Kontaktujte nás",
    "error_22418": "Požiadavka zlyhala (kód chyby: 22418). Skúste to znova",
    "error_22419": "Chyba: 22419. Kontaktujte nás",
    "error_22420": "Chyba: 22420. Kontaktujte nás",
    "error_22421": "Chyba: 22421. Kontaktujte nás",
    "error_22422": "Požiadavka zlyhala (kód chyby: 22422). Skúste to znova",
    "error_22423": "Požiadavka zlyhala (kód chyby: 22423). Skúste to znova",
    "error_22424": "Požiadavka zlyhala (kód chyby: 22424). Skúste to znova",
    "error_22425": "Požiadavka zlyhala (kód chyby: 22425). Skúste to znova",
    "error_20098": "Kód chyby: 20098. Ak prehliadate v súkromnom režime, prepnite sa do normálneho režimu a skúste to znova.",
    "error_22298": "Žiadosť pre Google zlyhala (chyba: 22298). Skúste to znova.",
    "error_22498": "Žiadosť o Facebook zlyhala (chyba: 22498). Skúste to znova.",
    "error_24902": "Požiadavka zlyhala (kód chyby: 24902). Skúste to znova",
    "error_24903": "Požiadavka zlyhala (kód chyby: 24903). Skúste to znova",
    "error_24904": "Požiadavka zlyhala (kód chyby: 24904). Skúste to znova",
    "error_24905": "Požiadavka zlyhala (kód chyby: 24905). Skúste to znova",
    "login_title": "Prihláste sa do MindOnMap",
    "log_in": "Prihlásiť sa",
    "no_account": "Žiadny účet?",
    "create_it": "Vytvorte si ho",
    "or_log_in_with": "Alebo sa prihláste pomocou",
    "passwordless_login": "Prihlásenie bez hesla",
    "log_in_done": "Prihlásiť sa Hotovo",
    "three_rd_account_connect_info": "Gratulujem! Úspešne ste sa prihlásili. Teraz môžete pripojiť jeden e-mailový účet, ktorý sa bude používať na prihlásenie v budúcnosti.",
    "see_my_account": "Pozrite si môj účet",
    "three_rd_login_merge_account": "E-mailová adresa účtu tretej strany bola zaregistrovaná, chcete sa pripojiť a prihlásiť priamo pomocou tejto e-mailovej adresy?",
    "connect_log_in": "Pripojiť a prihlásiť sa",
    "create_an_account": "Vytvoriť účet",
    "back_to_log_in": "Späť na prihlásenie",
    "create_password": "Vytvor heslo",
    "create_now": "Vytvorte teraz",
    "password_login_subtitle": "Prihlásenie bez hesla pomocou e-mailu",
    "account_login": "Prihlásenie do účtu",
    "rights": "Vytvorením tohto účtu vyjadrujete súhlas so <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Zmluvnými podmienkami</a> a <a href=\"https://www.mindonmap.com/privacy-policy/\">Zásady ochrany osobných údajov</a>",
    "passwordless_login_done": "Prihlásenie bez hesla je dokončené",
    "passwordless_login_info": "Gratulujeme, úspešne ste dokončili prihlásenie bez hesla. Pre tento účet si môžete vytvoriť heslo a v budúcnosti sa pomocou neho a hesla prihlásiť. <a href=\"/create-password\" style=\"display: initial;\">Vytvoriť teraz</a>",
    "sign_up": "Prihlásiť Se",
    "register_info": "Vytvor si účet",
    "reset_now": "Resetovať teraz",
    "forgot_password": "Zabudol si heslo",
    "reset_password_subtitle": "Na obnovenie hesla použite e-mail svojho účtu",
    "plan_products": "Plány a produkty",
    "nick_name": "Používateľské meno:",
    "email": "Email:",
    "my_products": "Moje produkty",
    "my_orders": "moje objednávky",
    "unlink": "Odpojiť",
    "link": "Odkaz",
    "connected_accounts": "Prepojené účty",
    "last_name": "Priezvisko:",
    "first_name": "Krstné meno:",
    "Gender": "Rod:",
    "Birth": "Narodenie:",
    "Month": "mesiac",
    "Year": "rok",
    "Country_Region": "Krajina/región:",
    "Address": "Adresa:",
    "Save": "Uložiť",
    "Date": "Dátum",
    "Male": "Muž",
    "Female": "Žena",
    "Unspecified": "Nešpecifikované",
    "Security": "Bezpečnosť",
    "change_password": "Zmeniť heslo",
    "change_now": "Zmeniť teraz",
    "connect_email": "Pripojiť e-mail",
    "delete_account": "Zmazať účet",
    "delete_account_info": "Po odstránení vášho účtu sa natrvalo odstránia všetky údaje v časti Môj účet priradené k vášmu účtu a možno ich už nebudete môcť obnoviť. Odporúčame postupovať opatrne.",
    "Delete": "Odstrániť",
    "Logout": "Odhlásiť sa",
    "my_profile": "Môj profil",
    "guides_faqs": "Sprievodcovia a často kladené otázky",
    "More": "Viac",
    "guides": "Sprievodcovia",
    "register": "Registrovať",
    "hot_faq": "Horúce časté otázky",
    "Contents": "Obsah:",
    "contact_us": "Kontaktujte nás >>",
    "plan": "Plán",
    "unregistered": "Neregistrovaný",
    "buy_more": "Kúpiť viac",
    "buy_again": "Kúpiť znova",
    "buy_now": "Kúpte teraz",
    "free_no_limit": "Zadarmo a bez obmedzenia",
    "expired": "Platnosť vypršala",
    "lifetime": "Život",
    "remain": "Zostať",
    "day_s": "Dni)",
    "error_24801": "Požiadavka zlyhala (kód chyby: 24801). Skúste to znova",
    "no_app_found": "Nenašla sa žiadna aplikácia! <a href=\"/\">Obnoviť</a> alebo <a href=\"https://www.mindonmap.com/\">Prejsť na oficiálnu webovú stránku</a>",
    "get_more": "Získajte viac >>",
    "edit_photo": "Upraviť fotografiu",
    "select_photo": "Vyberte fotografiu",
    "change_photo": "Zmeniť fotku",
    "cancel": "Zrušiť",
    "hide_password": "Skryť heslo",
    "show_password": "Ukázať heslo",
    "zoom_in": "Priblížiť",
    "zoom_out": "Oddialiť",
    "rotate": "Točiť sa",
    "horizontal_flip": "Horizontálne preklopenie",
    "vertical_flip": "Vertikálne preklopenie",
    "country": "Krajina",
    "country_1": "Vyberte svoju krajinu/región",
    "country_2": "ostrov Åaland",
    "country_3": "Afganistan",
    "country_4": "Albánsko",
    "country_5": "Alžírsko",
    "country_6": "Americká Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktída",
    "country_11": "Antigua a Barbuda",
    "country_12": "Argentína",
    "country_13": "Arménsko",
    "country_14": "Aruba",
    "country_15": "Austrália",
    "country_16": "Rakúsko",
    "country_17": "Azerbajdžan",
    "country_18": "Bahrajn",
    "country_19": "Bangladéš",
    "country_20": "Barbados",
    "country_21": "Bielorusko",
    "country_22": "Belgicko",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermudy",
    "country_26": "Bhután",
    "country_27": "Bolívia",
    "country_28": "Bosna a Hercegovina",
    "country_29": "Botswana",
    "country_30": "Bouvetov ostrov",
    "country_31": "Brazília",
    "country_32": "Britské indické oceánske územie",
    "country_33": "Britské Panenské ostrovy",
    "country_34": "Brunej",
    "country_35": "Bulharsko",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodža",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kapverdy",
    "country_42": "Karibské Holandsko",
    "country_43": "Kajmanské ostrovy",
    "country_44": "Stredoafrická republika",
    "country_45": "Čad",
    "country_46": "Čile",
    "country_47": "Čína",
    "country_48": "Vianočný ostrov",
    "country_49": "Kokosové (Keelingove) ostrovy",
    "country_50": "Kolumbia",
    "country_51": "Cookove ostrovy",
    "country_52": "Kostarika",
    "country_53": "Pobrežie Slonoviny",
    "country_54": "Chorvátsko",
    "country_55": "Kuba",
    "country_56": "Cyprus",
    "country_57": "Česká republika",
    "country_58": "Demokratická Republika Kongo",
    "country_59": "Dánsko",
    "country_60": "Džibutsko",
    "country_61": "Dominika",
    "country_62": "Dominikánska republika",
    "country_63": "Ekvádor",
    "country_64": "Egypt",
    "country_65": "El Salvador",
    "country_66": "Rovníková Guinea",
    "country_67": "Eritrea",
    "country_68": "Estónsko",
    "country_69": "Etiópia",
    "country_70": "Falklandské ostrovy",
    "country_71": "Faerské ostrovy",
    "country_72": "Federatívne štáty Mikronézie",
    "country_73": "Fidži",
    "country_74": "Fínsko",
    "country_75": "Francúzsko",
    "country_76": "Francúzska Guiana",
    "country_77": "Francúzska Polynézia",
    "country_78": "Francúzske južné územia",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Gruzínsko",
    "country_82": "Nemecko",
    "country_83": "Ghana",
    "country_84": "Gibraltár",
    "country_85": "Veľká Británia (Spojené kráľovstvo; Anglicko)",
    "country_86": "Grécko",
    "country_87": "Grónsko",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemale",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heardov ostrov a McDonaldove ostrovy",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Maďarsko",
    "country_101": "Island",
    "country_102": "India",
    "country_103": "Indonézia",
    "country_104": "Irán",
    "country_105": "Iraku",
    "country_106": "Írsko",
    "country_107": "Ostrov Man",
    "country_108": "Izrael",
    "country_109": "Taliansko",
    "country_110": "Jamajka",
    "country_111": "Japonsko",
    "country_112": "Jersey",
    "country_113": "Jordan",
    "country_114": "Kazachstan",
    "country_115": "Keňa",
    "country_116": "Kiribati",
    "country_117": "Kuvajt",
    "country_118": "Kirgizsko",
    "country_119": "Laos",
    "country_120": "Lotyšsko",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Libéria",
    "country_124": "Líbya",
    "country_125": "Lichtenštajnsko",
    "country_126": "Litva",
    "country_127": "Luxembursko",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malajzia",
    "country_132": "Maledivy",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Maršalove ostrovy",
    "country_136": "Martinik",
    "country_137": "Mauritánia",
    "country_138": "Maurícius",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldavsko",
    "country_142": "Monako",
    "country_143": "Mongolsko",
    "country_144": "Čierna Hora",
    "country_145": "Montserrat",
    "country_146": "Maroko",
    "country_147": "Mozambik",
    "country_148": "Mjanmarsko (Barma)",
    "country_149": "Namíbia",
    "country_150": "Nauru",
    "country_151": "Nepál",
    "country_152": "Holandsko",
    "country_153": "Nová Kaledónia",
    "country_154": "Nový Zéland",
    "country_155": "Nikaragua",
    "country_156": "Niger",
    "country_157": "Nigéria",
    "country_158": "Niue",
    "country_159": "ostrov Norfolk",
    "country_160": "Severná Kórea",
    "country_161": "ostrovy Severné Mariány",
    "country_162": "Nórsko",
    "country_163": "Omán",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "palestínske územia",
    "country_167": "Panama",
    "country_168": "Papua-Nová Guinea",
    "country_169": "Paraguaj",
    "country_170": "Peru",
    "country_171": "Pitcairnove ostrovy",
    "country_172": "Poľsko",
    "country_173": "Portugalsko",
    "country_174": "Portoriko",
    "country_175": "Katar",
    "country_176": "Macedónska republika (FYROM)",
    "country_177": "Konžskej republike",
    "country_178": "Réunion",
    "country_179": "Rumunsko",
    "country_180": "Ruská federácia",
    "country_181": "Rwanda",
    "country_182": "Svätý Bartolomej",
    "country_183": "Saint Martin (Francúzsko)",
    "country_184": "Saint-Pierre a Miquelon",
    "country_185": "Samoa",
    "country_186": "San Maríno",
    "country_187": "Svätý Tomáš a princíp",
    "country_188": "Saudská Arábia",
    "country_189": "Senegal",
    "country_190": "Srbsko",
    "country_191": "Seychely",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Slovensko",
    "country_195": "Slovinsko",
    "country_196": "Šalamúnove ostrovy",
    "country_197": "Somálsko",
    "country_198": "Južná Afrika",
    "country_199": "Južná Georgia a South Sandwich Isla",
    "country_200": "Južná Kórea",
    "country_201": "Južný Sudán",
    "country_202": "Španielsko",
    "country_203": "Srí Lanka",
    "country_204": "Sv. Helena a závislosti",
    "country_205": "Svätý Krištof a Nevis",
    "country_206": "Svätá Lucia",
    "country_207": "Svätý Vincent a Grenadiny",
    "country_208": "Sudán",
    "country_209": "Surinam",
    "country_210": "Svazijsko",
    "country_211": "Švédsko",
    "country_212": "Švajčiarsko",
    "country_213": "Sýria",
    "country_214": "Taiwan",
    "country_215": "Tadžikistan",
    "country_216": "Tanzánia",
    "country_217": "Šablóna:Údaje o krajine SJM Svalbard",
    "country_218": "Thajsko",
    "country_219": "Bahamy",
    "country_220": "Komory",
    "country_221": "Filipíny",
    "country_222": "Východný Timor (Východný Timor)",
    "country_223": "Ísť",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad a Tobago",
    "country_227": "Tunisko",
    "country_228": "Turecko",
    "country_229": "Turkménsko",
    "country_230": "Ostrovy Turks a Caicos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrajina",
    "country_234": "Spojené Arabské Emiráty",
    "country_235": "Spojené štáty Malé odľahlé ostrovy",
    "country_236": "Spojené štáty Americké (USA)",
    "country_237": "Panenské ostrovy Spojených štátov",
    "country_238": "Uruguaj",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikán (Svätá stolica)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis a Futuna",
    "country_245": "Západná Sahara",
    "country_246": "Jemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Prihláste sa pomocou Google",
    "State": "Štát",
    "Activation_code": "Aktivačný kód",
    "Question": "Uveďte zoznam všetkých aplikácií, v ktorých ste sa prihlásili",
    "Copy_complete": "Kopírovanie je dokončené",
    "footer": "Copyright © 2024 MindOnMap Studio. Všetky práva vyhradené.",
    "change_password_success": "Heslo bolo úspešne zmenené",
    "successful_login_title": "Prihlásenie bolo úspešné",
    "product_page": "Stránka produktu >>",
    "successful_login_info": "Prihlásenie dokončené. Zatvorte aktuálnu stránku a vráťte sa na pôvodnú kartu, aby ste mohli pokračovať v nasledujúcom procese. Aktuálna stránka sa automaticky zatvorí o 5 sekúnd. Ak automatické zatváranie alebo zatváranie kliknutím na tlačidlo „Hotovo“ zlyhá, zatvorte priamo túto kartu.",
    "successful_login_info_firefox": "Prihlásenie dokončené. Zatvorte aktuálnu stránku a vráťte sa na pôvodnú kartu, aby ste mohli pokračovať v nasledujúcom procese.",
    "my_account": "Môj účet",
    "my_history": "Moja história",
    "remove_watermark": "Odstrániť vodoznak",
    "no_history": "Žiadna história",
    "history_all": "Vybrať všetko",
    "history_open": "OTVORENÉ",
    "history_down": "Stiahnuť",
    "history_delete": "Odstrániť",
    "history_clear": "Vymazať neplatné",
    "images": "snímky",
    "use_this_function": "Použiť tento produkt >>",
    "hd_downloading": "Výhody sťahovania originálnych HD obrázkov:",
    "lifetimeRemaining": "Zostávajúca životnosť",
    "Remaining": "Zostávajúce",
    "email_verification": "Overenie e-mailom",
    "email_verification_info": "Overovaciu správu sme odoslali na váš e-mail <span class=\"email\"></span> a dokončite overenie. Po overení budú výhody automaticky synchronizované.",
    "wrong_email": "Nesprávna e-mailová adresa?",
    "click_here_to_modify": "Ak chcete upraviť, kliknite sem",
    "get_more_help": "Získať ďalšiu pomoc?",
    "click_here": "Kliknite tu",
    "email_verification_info_success": "Gratulujeme k overeniu vášho e-mailového účtu.",
    "email_verification_info_error": "Overenie zlyhalo z dôvodu vypršania platnosti odkazu.",
    "registration_succeeded": "Registrácia bola úspešná",
    "registration_succeeded_info_1": "Gratulujem! Úspešne ste sa zaregistrovali. Overovaciu správu sme odoslali na váš e-mail <span class=\"email\"></span> a dokončite overenie, aby ste mohli využívať výhody tohto e-mailu.",
    "registration_succeeded_info_2": "Kliknutím na „Hotovo“ sa vrátite na domovskú stránku a môžete použiť tento produkt.",
    "registration_succeeded_info_3": "Kliknutím na „Hotovo“ zatvoríte aktuálnu stránku a vrátite sa na domovskú stránku pre nasledujúce operácie. Ak zatvorenie zlyhá, zatvorte kartu ručne.",
    "verify_email": "potvrdiť Email",
    "registered_email_not_verified": "Registrovaný e-mail nebol overený, ihneď ho overte.",
    "email_verification_time_1": "Nedostali ste overovací e-mail?",
    "email_verification_time_2": "Po",
    "email_verification_time_3": "Kliknutím sem ho znova odošlete",
    "error_26301": "Kód chyby: 26301, kontaktujte nás",
    "error_26302": "Kód chyby: 26302, kontaktujte nás",
    "error_26303": "Chyba formátu e-mailu (kód chyby: 26303). Zadajte ho znova",
    "error_26304": "Odporúča sa heslo dlhšie ako 8 znakov (kód chyby: 26304)",
    "error_26305": "Požiadavka zlyhala (kód chyby: 26305). Skúste to znova",
    "error_26306": "E-mail bol zaregistrovaný, <a href=\"https://account.mindonmap.com/login/\">prejdite na prihlásenie</a>",
    "error_26307": "Požiadavka zlyhala (kód chyby: 26307). Skúste to znova",
    "error_26308": "Požiadavka zlyhala (kód chyby: 26308). Skúste to znova",
    "error_26401": "Kód chyby: 26401, skúste to znova",
    "error_26402": "E-mail bol overený (kód chyby: 26402), skúste to znova",
    "error_26403": "Požiadavka zlyhala (kód chyby: 26403). Skúste to znova",
    "error_26404": "Požiadavka zlyhala (kód chyby: 26404). Skúste to znova",
    "error_26501": "Kód chyby: 26501, kontaktujte nás",
    "error_26502": "Kód chyby: 26502, kontaktujte nás",
    "error_26503": "Chyba formátu e-mailu (kód chyby: 26503). Zadajte ho znova",
    "error_26504": "Požiadavka zlyhala (kód chyby: 26504). Skúste to znova",
    "error_26505": "E-mail nebol zaregistrovaný, <a href=\"https://account.mindonmap.com/register/\">najprv ho zaregistrujte</a>",
    "error_26506": "E-mail bol overený.",
    "error_26507": "Požiadavka zlyhala (kód chyby: 26507). Skúste to znova",
    "error_26508": "Overenie prebehlo (kód chyby: 26508), skúste to znova",
    "error_26509": "Požiadavka zlyhala (kód chyby: 26509), skúste to znova",
    "error_26510": "Kód chyby: 26510, kontaktujte nás",
    "error_26601": "Kód chyby: 26601, kontaktujte nás",
    "error_26602": "Kód chyby: 26602, kontaktujte nás",
    "error_26603": "Požiadavka zlyhala (kód chyby: 26603). Skúste to znova",
    "error_26604": "Kód chyby: 26604, kontaktujte nás",
    "error_26605": "Kód chyby: 26605, kontaktujte nás",
    "error_26701": "Kód chyby: 26701, kontaktujte nás",
    "error_26702": "Požiadavka zlyhala (kód chyby: 26702). Skúste to znova",
    "error_26703": "Kód chyby: 26703, kontaktujte nás",
    "error_26704": "Kód chyby: 26704, kontaktujte nás",
    "error_26705": "Počkajte na prihlásenie (kód chyby: 26705). Skúste to znova",
    "no_cookie": "Vo svojom prehliadači ste zapli funkciu Blokovať všetky súbory cookie, takže sa nemôžete prihlásiť. Prejdite do Nastavenia a začiarknite políčko Povoliť všetky súbory cookie.",
    "error_26801": "Kód chyby: 26801, kontaktujte nás",
    "error_26802": "Kód chyby: 26802, kontaktujte nás",
    "error_26803": "Požiadavka zlyhala (kód chyby: 26803). Skúste to znova",
    "error_26804": "Požiadavka zlyhala (kód chyby: 26804). Skúste to znova",
    "error_order": "Požiadavka zlyhala (kód chyby: 27098), skúste to znova!",
    "error_order1": "Dotaz na objednávku je neúplný (kód chyby: ",
    "error_order2": "), obnovte stránku a skúste to znova.",
    "modify_email_title": "Upraviť e-mail",
    "modify_email_info": "Upravený e-mail môžete použiť na prihlásenie do svojho účtu.",
    "images_per": "Obrázky za",
    "error_26101": "Chyba: 26101. Kontaktujte nás",
    "error_26102": "Chyba: 26102. Kontaktujte nás",
    "error_26103": "Žiadosť zlyhala (kód chyby: 26103). Skúste to znova",
    "error_26104": "Kód chyby: 26104, skúste to znova",
    "error_26105": "Kód chyby: 26105, skúste to znova",
    "error_26106": "Odstránenie zlyhalo (kód chyby: 26106). Skúste to znova",
    "error_26201": "Chyba: 26201. Kontaktujte nás",
    "error_26202": "Žiadosť zlyhala (kód chyby: 26202). Skúste to znova",
    "error_26001": "Chyba: 26001. Kontaktujte nás",
    "error_26002": "Chyba: 26002. Kontaktujte nás",
    "error_26003": "Chyba: 26003. Kontaktujte nás",
    "error_26004": "Chyba: 26004. Kontaktujte nás",
    "error_26005": "Žiadosť zlyhala (kód chyby: 26005). Skúste to znova",
    "error_26006": "Kód chyby: 26006, skúste to znova",
    "error_26008": "Chyba: 26008. Kontaktujte nás",
    "go_to_the_home_page": "Prejdite na domovskú stránku",
    "error_27101": "Požiadavka zlyhala (kód chyby: 27101). Skúste to znova",
    "error_27201": "Kód chyby: 27201, kontaktujte nás",
    "error_27202": "Kód chyby: 27202, skúste to znova",
    "error_27203": "Požiadavka zlyhala (kód chyby: 27203). Skúste to znova",
    "error_27204": "Neplatný kód (kód chyby: 27204).",
    "error_27205": "Požiadavka zlyhala (kód chyby: 27205). Skúste to znova",
    "error_27206": "Požiadavka zlyhala (kód chyby: 27206). Skúste to znova",
    "error_27207": "Požiadavka zlyhala (kód chyby: 27207). Skúste to znova",
    "no_history_found": "Nepoužili ste žiadny nástroj! <a href=\"/\">Obnoviť</a> alebo <a href=\"https://www.mindonmap.com/\">Prejsť na oficiálnu webovú stránku</a>",
    "error_25301": "Chyba: 25301. Kontaktujte nás",
    "error_25302": "Chyba: 25302. Kontaktujte nás",
    "error_25303": "Žiadosť zlyhala (kód chyby: 25303). Skúste to znova",
    "error_25304": "Žiadosť zlyhala (kód chyby: 25304). Skúste to znova",
    "error_25305": "Žiadosť zlyhala (kód chyby: 25305). Skúste to znova",
    "error_25306": "Žiadosť zlyhala (kód chyby: 25306). Skúste to znova",
    "image_upscaler_p": "Výhody sťahovania obrázka bez vodoznaku:",
    "Available_for": "Dostupný pre:",
    "credit_per": "%s kreditov na HD obrázok",
    "still_valid": "Zakúpený plán (plány) je stále platný",
    "credit": "kredit(y)",
    "pc_3rd_info": "Prihláste sa úspešne. Pre ďalšiu operáciu prejdite do aplikácie.",
    "use_online": "Použite službu online",
    "use_download": "Použite program na pracovnej ploche",
    "use_immediately": "Použite Ihneď",
    "Use_in_browser": "Použiť v prehliadači",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} kreditov za mesiac",
    "expire": "Čas vypršania platnosti:",
    "viewDetails": "Zobraziť podrobnosti",
    "viewHistory": "Zobraziť výhody histórie >>",
    "viewDetailsInfo": "Upozornenie: Ak sa výhody predplatného obnovia do 7 dní po vypršaní platnosti, nevyužité výhody možno naďalej využívať. Čas uplynutia platnosti sa tiež automaticky aktualizuje na čas uplynutia platnosti nového predplatného. Ak po 7 dňoch uplynutia platnosti nebude nové predplatné, všetky výhody, ktorých platnosť vypršala, budú vymazané.",
    "connect_account": "Zviazať e-mail so svojím účtom",
    "connect_account_info": "Po viazaní sa môžete prihlásiť pomocou tejto e-mailovej adresy.",
    "connect_now": "Zviazať teraz",
    "no_email_bind": "Žiadna e-mailová väzba",
    "bind_email": "Zviazať email",
    "connect_your_email_placeholder": "prosím, zadajte svoju e-mailovú adresu",
    "bind_an_email": "Zviazať e-mail",
    "bind_info": "Úspešne ste sa prihlásili. Ak chcete aktivovať svoje výhody, pripojte e-mail k svojmu účtu.",
    "bind_later": "Zviazať neskôr",
    "hi": "Jeho!",
    "Personal_Information": "Osobné informácie",
    "Access": "Prístup",
    "Subscription_Plan": "(Plán predplatného)",
    "No_orders": "Nenašli sa žiadne objednávky.",
    "No_data": "Žiadne dáta",
    "Featured_Products": "Vybrané produkty",
    "More_Products": "Viac produktov",
    "Free_Download": "Bezplatné stiahnutie",
    "Get_Started": "Začať",
    "Subscribe": "Prihlásiť sa na odber",
    "Verified": "Overené",
    "back_to_account_center": "Späť do Centra účtov",
    "success": "Úspech!",
    "successfully": "Úspešne ste si zaregistrovali účet.",
    "Continue": "ďalej",
    "Already": "Máte už účet?",
    "loading_verification": "Kontroluje sa stav overenia...",
    "email_no_verification": "Ľutujeme, registrovaná e-mailová adresa nebola overená. Dokončite overenie podľa vyššie uvedených pokynov a opätovným kliknutím na tlačidlo „Hotovo“ dokončite registráciu.",
    "will_expire_after": "Platnosť vyprší po",
    "hours": "hodiny",
  },
  "sq": {
    "overtime": "Kodi i gabimit: {%}, ju lutemi riprovoni",
    "isnetwork": "Gabim në internet. Ju lutemi kontrolloni dhe provoni përsëri",
    "email_placeholder": "Email",
    "email_empty": "Ju lutemi futni emailin",
    "email_not_valid": "Email-i nuk është i vlefshëm",
    "email_not_valid_1": "Ju lutemi shkruani emailin tuaj",
    "email_not_valid_2": "Email-i nuk është i vlefshëm, ju lutemi përdorni një adresë tjetër.",
    "email_not_valid_3": "Asnjë hyrje emaili",
    "password_placeholder": "Fjalëkalimi",
    "password_empty": "Ju lutemi futni fjalëkalimin",
    "password_not_valid": "Llogaria ose fjalëkalimi i pasaktë",
    "password_not_valid_1": "Kërkohet një fjalëkalim mbi 8 karaktere",
    "password_not_valid_2": "Ju lutemi krijoni një fjalëkalim",
    "password_placeholder_1": "Krijoni fjalëkalimin tuaj",
    "password_placeholder_2": "Konfirmoni fjalëkalimin tuaj",
    "password_placeholder_3": "Krijo fjalëkalim të ri",
    "password_placeholder_4": "Konfirmo fjalëkalimin e ri",
    "password_placeholder_5": "Fut fjalëkalimin e vjetër",
    "copy_password_empty": "Ju lutemi konfirmoni fjalëkalimin",
    "copy_password_not_valid": "Ju lutemi konfirmoni fjalëkalimin tuaj",
    "copy_passwords_inconsistent": "Konfirmimi i fjalëkalimit tuaj nuk përputhet",
    "code_empty": "Fut kodin e verifikimit",
    "code_not_valid": "Kodi i pavlefshëm verifikimi",
    "code_placeholder": "Kodi i Verifikimit",
    "not_received_code": "Nëse kutia juaj postare nuk e ka marrë kodin e verifikimit për një kohë të gjatë, ju lutemi merrni përsëri kodin e verifikimit.",
    "get_first_code": "Ju lutemi merrni kodin e verifikimit në fillim.",
    "last_name_placeholder": "Ju lutemi shkruani mbiemrin tuaj",
    "first_name_placeholder": "Ju lutemi shkruani emrin tuaj",
    "address_placeholder": "Ju lutemi shkruani adresën tuaj",
    "no_code_text": "Ne kemi dërguar një kod verifikimi. Ju lutemi shkruani kodin tuaj. <span class='tips'>Nuk keni marrë një kod?",
    "no_code_text_1": "1. Ju lutemi sigurohuni që adresa e emailit është e vlefshme dhe ajo mund të marrë email.",
    "no_code_text_2": "2. Për shkak se emaili dërgohet automatikisht nga sistemi, ai mund të shënohet si email i padëshiruar ose i padëshiruar. Ju lutemi kontrolloni nëse emaili është në dosjen e koshit.",
    "no_code_text_3": "3. Nuk mund ta zgjidhni problemin tuaj? ",
    "no_code_text_3_span": "Pastaj klikoni këtu për të na kontaktuar.",
    "order_no": "Ju nuk keni blerë asnjë produkt, nëse keni ndonjë pyetje, ju lutemi <a href=\"https://www.mindonmap.com/contact-us/\">na kontaktoni</a>.",
    "error_24901": "Llogaria aktuale nuk ka email të lidhur dhe nuk mund të gjejë porositë. Ju lutemi lidhni një email.",
    "user_guide": "Udhëzuesi i përdorimit >>",
    "download": "Shkarko",
    "order_number": "Numri i porosisë:",
    "Refund": "Rimbursimi",
    "Disabled": "I paaftë",
    "Normal": "Normale",
    "Modify": "Modifiko",
    "Modify_1": "Modifiko>>",
    "Connect": "Lidheni",
    "unlink_success": "Shkëpute me sukses",
    "connect_success": "Lidhu me sukses",
    "feedback_title": "Faleminderit për komentet tuaja. Ju lutemi lini problemin tuaj dhe ne do t'ju përgjigjemi brenda 24 orëve.",
    "feedback_thank_you": "Faleminderit!<br />Komenti juaj u dërgua me sukses.",
    "feedback_email": "Shkruani emailin tuaj këtu!",
    "feedback_content": "Lini këtu çdo problem ose sugjerim që keni hasur.",
    "feedback_submit": "Paraqisni",
    "form_contents": "Nuk keni futur asnjë përshkrim. Ju lutemi, futeni atë dhe dorëzojeni përsëri.",
    "old_password": "Ju lutemi futni fjalëkalimin e vjetër",
    "new_password": "Ju lutemi krijoni një fjalëkalim të ri",
    "old_new_password": "Fjalëkalimi i ri nuk mund të jetë i njëjtë me atë të vjetër",
    "incorrect_password": "Fjalëkalim i pasaktë",
    "delete_no": "Fshije Tani",
    "Caps": "Kyçja me kapele është aktive",
    "Get": "Marr",
    "Done": "U krye",
    "error_20001": "Gabim: 20001. Ju lutemi identifikohuni përsëri.",
    "error_20002": "Gabim: 20002. Ju lutemi identifikohuni përsëri.",
    "error_20003": "Gabim: 20003. Ju lutemi identifikohuni përsëri.",
    "error_20004": "Kërkesa dështoi (kodi i gabimit: 20004). Ju lutemi provojeni përsëri.",
    "error_20005": "Sesioni i hyrjes ka skaduar (Gabim: 20005). Ju lutemi identifikohuni përsëri.",
    "error_20006": "Kërkesa dështoi (kodi i gabimit: 20006). Ju lutemi provojeni përsëri.",
    "error_20007": "Sesioni i hyrjes ka skaduar (Gabim: 20007). Ju lutemi identifikohuni përsëri.",
    "error_20008": "Sesioni i hyrjes ka skaduar (Gabim: 20008). Ju lutemi identifikohuni përsëri.",
    "error_20009": "Sesioni i hyrjes ka skaduar (Gabim: 20009). Ju lutemi identifikohuni përsëri.",
    "error_20101": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20101)",
    "error_20102": "Email-i nuk është i vlefshëm (kodi i gabimit: 20102)",
    "error_20103": "Kërkesa dështoi (kodi i gabimit: 20103). Ju lutemi provojeni përsëri",
    "error_20104": "Email-i është tashmë në përdorim, ju lutemi <a href=\"https://account.mindonmap.com/login/\">hyni</a> ose regjistrohuni me një të ri",
    "error_20105": "Kërkesa dështoi (kodi i gabimit: 20105). Ju lutemi provojeni përsëri",
    "error_20106": "Dërgimi i emailit dështoi, ju lutemi provojeni përsëri",
    "error_20201": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20201)",
    "error_20202": "Ju lutemi futni fjalëkalimin tuaj (kodi i gabimit: 20202)",
    "error_20203": "Fut kodin e verifikimit (kodi i gabimit: 20203)",
    "error_20204": "Email-i nuk është i vlefshëm (kodi i gabimit: 20204)",
    "error_20205": "Kërkohet fjalëkalimi mbi 8 karaktere (kodi i gabimit: 20205)",
    "error_20206": "Kërkesa dështoi (kodi i gabimit: 20206). Ju lutemi provojeni përsëri",
    "error_20207": "Kodi i pavlefshëm verifikimi",
    "error_20208": "Kërkesa dështoi (kodi i gabimit: 20208). Ju lutemi provojeni përsëri",
    "error_20209": "Kërkesa dështoi (kodi i gabimit: 20209). Ju lutemi provojeni përsëri",
    "error_20301": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20301)",
    "error_20302": "Gabim: 20302. Ju lutemi na kontaktoni",
    "error_20303": "Email-i nuk është i vlefshëm (kodi i gabimit: 20303)",
    "error_20304": "Kërkesa dështoi (kodi i gabimit: 20304). Ju lutemi provojeni përsëri",
    "error_20305": "Llogaria nuk ekziston. Ju lutemi rifutni ose <a href=\"https://account.mindonmap.com/register/\">Krijoni atë</a> fillimisht.",
    "error_20306": "Nuk ka ende fjalëkalim. Përdor <a href=\"https://account.mindonmap.com/passwordless-login/\">hyrje pa fjalëkalim</a> ose <a href=\"https://account.mindonmap.com/create-password/\"> vendosni një fjalëkalim</a> dhe identifikohuni.",
    "error_20308": "Kërkesa dështoi (kodi i gabimit: 20308). Ju lutemi provojeni përsëri",
    "error_20401": "Dalja dështoi (kodi i gabimit: 20401). Ju lutemi provojeni përsëri",
    "error_20501": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20501)",
    "error_20502": "Email-i nuk është i vlefshëm (kodi i gabimit: 20502)",
    "error_20503": "Kërkesa dështoi (kodi i gabimit: 20503). Ju lutemi provojeni përsëri",
    "error_20504": "Dërgimi i emailit dështoi. Ju lutemi provojeni përsëri.",
    "error_20601": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20601)",
    "error_20602": "Fut kodin e verifikimit (kodi i gabimit: 20602)",
    "error_20603": "Email-i nuk është i vlefshëm (kodi i gabimit: 20603)",
    "error_20604": "Kërkesa dështoi (kodi i gabimit: 20604). Ju lutemi provojeni përsëri",
    "error_20606": "Kërkesa dështoi (kodi i gabimit: 20606). Ju lutemi provojeni përsëri",
    "error_20607": "Kërkesa dështoi (kodi i gabimit: 20607). Ju lutemi provojeni përsëri",
    "error_20608": "Kërkesa dështoi (kodi i gabimit: 20608). Ju lutemi provojeni përsëri",
    "error_20701": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20701)",
    "error_20702": "Email-i nuk është i vlefshëm (kodi i gabimit: 20702)",
    "error_20703": "Kërkesa dështoi (kodi i gabimit: 20703). Ju lutemi provojeni përsëri",
    "error_20704": "Llogaria nuk ekziston. Ju lutemi rifutni ose <a href=\"https://account.mindonmap.com/register/\">Krijoni atë</a> fillimisht.",
    "error_20705": "Kërkesa dështoi (kodi i gabimit: 20705). Ju lutemi provojeni përsëri",
    "error_20706": "Dërgimi i emailit dështoi. Ju lutemi provojeni përsëri",
    "error_20801": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 20801)",
    "error_20802": "Gabim: 20802. Ju lutemi na kontaktoni",
    "error_20803": "Fut kodin e verifikimit (kodi i gabimit: 20803)",
    "error_20804": "Email-i nuk është i vlefshëm (kodi i gabimit: 20804)",
    "error_20805": "Kërkohet një fjalëkalim mbi 8 karaktere (kodi i gabimit: 20805)",
    "error_20806": "Kërkesa dështoi (kodi i gabimit: 20806). Ju lutemi provojeni përsëri",
    "error_20808": "Kërkesa dështoi (kodi i gabimit: 20808). Ju lutemi provojeni përsëri",
    "error_20901": "Kërkesa dështoi (kodi i gabimit: 20901). Ju lutemi provojeni përsëri",
    "error_20902": "Kërkesa dështoi (kodi i gabimit: 20902). Ju lutemi provojeni përsëri",
    "error_21000": "Ndryshimet ruhen",
    "error_21001": "Asnjë informacion nuk është dorëzuar (kodi i gabimit: 21001)",
    "error_21002": "Kërkesa dështoi (kodi i gabimit: 21002). Ju lutemi provojeni përsëri",
    "error_21101": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 21101)",
    "error_21102": "Email-i nuk është i vlefshëm (kodi i gabimit: 21102)",
    "error_21103": "Kërkesa dështoi (kodi i gabimit: 21103), ju lutemi riprovoni përsëri",
    "error_21104": "Emaili është tashmë i lidhur, ju lutemi përdorni një të ri",
    "error_21105": "Kërkesa dështoi (kodi i gabimit: 21105), ju lutemi riprovoni përsëri",
    "error_21106": "Dërgimi i emailit dështoi. Ju lutemi provojeni përsëri",
    "error_21201": "Ju lutemi shkruani emailin tuaj (kodi i gabimit: 21201)",
    "error_21202": "Fut kodin e verifikimit (kodi i gabimit: 21202)",
    "error_21203": "Email-i nuk është i vlefshëm (kodi i gabimit: 21203)",
    "error_21204": "Gabim: 21204. Ju lutemi na kontaktoni",
    "error_21205": "Gabim: 21205. Ju lutemi na kontaktoni",
    "error_21206": "Kërkohet një fjalëkalim mbi 8 karaktere (kodi i gabimit: 21206)",
    "error_21207": "Kërkesa dështoi (kodi i gabimit: 21207). Ju lutemi provojeni përsëri",
    "error_21209": "Kërkesa dështoi (kodi i gabimit: 21209). Ju lutemi provojeni përsëri",
    "error_21301": "Ju lutemi futni fjalëkalimin e vjetër (kodi i gabimit: 21301)",
    "error_21302": "Ju lutemi krijoni një fjalëkalim të ri (kodi i gabimit: 21302)",
    "error_21303": "Fjalëkalimi i ri nuk mund të jetë i njëjtë me atë të vjetër. (Gabim: 21303)",
    "error_21304": "Kërkohet një fjalëkalim mbi 8 karaktere (kodi i gabimit: 21304)",
    "error_21306": "Kërkesa dështoi (kodi i gabimit: 21306). Ju lutemi provojeni përsëri",
    "error_21402": "Kërkesa dështoi (kodi i gabimit: 21402). Ju lutemi provojeni përsëri",
    "error_21403": "Dështoi në dërgimin e kodit të verifikimit. Ju lutemi ridërgojeni",
    "error_21500": "Llogaria është fshirë",
    "error_21501": "Fut kodin e verifikimit (kodi i gabimit: 21501)",
    "error_21502": "Sesioni i hyrjes ka skaduar (Gabim: 21502). Ju lutemi identifikohuni përsëri.",
    "error_21503": "Kërkesa dështoi (kodi i gabimit: 21503). Ju lutemi provojeni përsëri",
    "error_21505": "Kërkesa dështoi (kodi i gabimit: 21505), ju lutemi provojeni përsëri",
    "error_21601": "Gabim: 20601. Ju lutemi na kontaktoni",
    "error_21602": "Verifikimi i pavlefshëm (Gabim: 20602). Ju lutemi provojeni përsëri.",
    "error_21603": "Gabim: 20603. Provo sërish",
    "error_21604": "Kërkesa dështoi (kodi i gabimit: 21604). Ju lutemi provojeni përsëri",
    "error_21606": "Kërkesa dështoi (kodi i gabimit: 21606). Ju lutemi provojeni përsëri",
    "error_21611": "Kërkesa dështoi (kodi i gabimit: 21611). Ju lutemi provojeni përsëri",
    "error_21801": "Gabim: 21801. Ju lutemi na kontaktoni",
    "error_21802": "Kërkesa dështoi (Gabim: 21802). Ju lutemi provojeni përsëri",
    "error_21803": "Gabim: 21803. Provo sërish",
    "error_21804": "Kërkesa dështoi (kodi i gabimit: 21804). Ju lutemi provojeni përsëri",
    "error_21806": "Gabim: 21806. Provo sërish",
    "error_21807": "Gabim: 21807. Ju lutemi na kontaktoni",
    "error_21808": "Gabim: 21808. Ju lutemi na kontaktoni",
    "error_21809": "Gabim: 21809. Ju lutemi na kontaktoni",
    "error_21810": "Gabim: 21810. Ju lutemi na kontaktoni",
    "error_21811": "Gabim: 21811. Ju lutemi na kontaktoni",
    "error_21812": "Gabim: 21812. Ju lutemi na kontaktoni",
    "error_21813": "Kërkesa dështoi (kodi i gabimit: 21813). Ju lutemi provojeni përsëri",
    "error_21814": "Gabim: 21814. Ju lutemi na kontaktoni",
    "error_21815": "Kërkesa dështoi (kodi i gabimit: 21815). Ju lutemi provojeni përsëri",
    "error_21816": "Gabim: 21816. Ju lutemi na kontaktoni",
    "error_21817": "Gabim: 21817. Ju lutemi na kontaktoni",
    "error_21818": "Gabim: 21818. Ju lutemi na kontaktoni",
    "error_21819": "Kërkesa dështoi (kodi i gabimit: 21819). Ju lutemi provojeni përsëri",
    "error_21820": "Gabim: 21820. Ju lutemi na kontaktoni",
    "error_21821": "Gabim: 21821. Ju lutemi na kontaktoni",
    "error_21822": "Gabim: 21822. Ju lutemi na kontaktoni",
    "error_21823": "Kërkesa dështoi (kodi i gabimit: 21823). Ju lutemi provojeni përsëri",
    "error_21824": "Kërkesa dështoi (kodi i gabimit: 21824). Ju lutemi provojeni përsëri",
    "error_21825": "Kërkesa dështoi (kodi i gabimit: 21825). Ju lutemi provojeni përsëri",
    "error_21826": "Kërkesa dështoi (kodi i gabimit: 21826). Ju lutemi provojeni përsëri",
    "error_21901": "Gabim: 21901. Ju lutemi na kontaktoni",
    "error_21902": "Kërkesa dështoi (kodi i gabimit: 21902). Ju lutemi provojeni përsëri",
    "error_21903": "Statusi i llogarisë ka ndryshuar (kodi i gabimit: 21903), ju lutemi rifreskoni faqen dhe provoni përsëri",
    "error_21904": "Gabim: 21904. Provo sërish",
    "error_21905": "Gabim: 21905. Provo sërish",
    "error_21906": "Kërkesa dështoi (kodi i gabimit: 21906). Ju lutemi provojeni përsëri",
    "error_21907": "Llogaria e Google është lidhur me një llogari tjetër",
    "error_21908": "Kërkesa dështoi (kodi i gabimit: 21908). Ju lutemi provojeni përsëri",
    "error_22001": "Kërkesa dështoi (kodi i gabimit: 22001). Ju lutemi provojeni përsëri",
    "error_22002": "Shkëputja dështoi pa asnjë hyrje shtesë",
    "error_22003": "Kërkesa dështoi (kodi i gabimit: 22003). Ju lutemi provojeni përsëri",
    "error_22101": "Gabim: 22101. Ju lutemi na kontaktoni",
    "error_22102": "Statusi i llogarisë ka ndryshuar (kodi i gabimit: 22102), ju lutemi rifreskoni faqen dhe provoni përsëri",
    "error_22103": "Kërkesa dështoi (kodi i gabimit: 22103). Ju lutemi provojeni përsëri",
    "error_22104": "Statusi i llogarisë ka ndryshuar (kodi i gabimit: 22104), ju lutemi rifreskoni faqen dhe provoni përsëri",
    "error_22105": "Gabim: 22105. Provo sërish",
    "error_22106": "Gabim: 22106. Provo sërish",
    "error_22107": "Gabim: 22107. Ju lutemi na kontaktoni",
    "error_22108": "Kërkesa dështoi (kodi i gabimit: 22108). Ju lutemi provojeni përsëri",
    "error_22201": "Gabim: 22201. Ju lutemi na kontaktoni",
    "error_22202": "Verifikimi i pavlefshëm (Gabim: 22202). Ju lutemi provojeni përsëri.",
    "error_22203": "Gabim: 22203. Ju lutemi provojeni përsëri\"",
    "error_22204": "Kërkesa dështoi (kodi i gabimit: 22204). Ju lutemi provojeni përsëri",
    "error_22206": "Kërkesa dështoi (kodi i gabimit: 22206). Ju lutemi provojeni përsëri",
    "error_22401": "Gabim: 22401. Ju lutemi na kontaktoni",
    "error_22402": "Verifikimi i pavlefshëm (Gabim: 22402). Ju lutemi provojeni përsëri.",
    "error_22403": "Gabim: 22403. Provo sërish",
    "error_22404": "Kërkesa dështoi (kodi i gabimit: 22404). Ju lutemi provojeni përsëri",
    "error_22405": "Llogaria në Facebook është lidhur me email të tjerë",
    "error_22406": "Gabim: 22406. Provo sërish",
    "error_22407": "Gabim: 22407. Ju lutemi na kontaktoni",
    "error_22408": "Gabim: 22408. Ju lutemi na kontaktoni",
    "error_22409": "Gabim: 22409. Ju lutemi na kontaktoni",
    "error_22410": "Gabim: 224010. Ju lutemi na kontaktoni",
    "error_22411": "Gabim: 224011. Ju lutemi na kontaktoni",
    "error_22412": "Gabim: 224012. Ju lutemi na kontaktoni",
    "error_22413": "Gabim: 22413. Ju lutemi na kontaktoni",
    "error_22414": "Kërkesa dështoi (kodi i gabimit: 22414). Ju lutemi provojeni përsëri",
    "error_22415": "Gabim: 22415. Ju lutemi na kontaktoni",
    "error_22416": "Gabim: 22416. Ju lutemi na kontaktoni",
    "error_22417": "Gabim: 22417. Ju lutemi na kontaktoni",
    "error_22418": "Kërkesa dështoi (kodi i gabimit: 22418). Ju lutemi provojeni përsëri",
    "error_22419": "Gabim: 22419. Ju lutemi na kontaktoni",
    "error_22420": "Gabim: 22420. Ju lutemi na kontaktoni",
    "error_22421": "Gabim: 22421. Ju lutemi na kontaktoni",
    "error_22422": "Kërkesa dështoi (kodi i gabimit: 22422). Ju lutemi provojeni përsëri",
    "error_22423": "Kërkesa dështoi (kodi i gabimit: 22423). Ju lutemi provojeni përsëri",
    "error_22424": "Kërkesa dështoi (kodi i gabimit: 22424). Ju lutemi provojeni përsëri",
    "error_22425": "Kërkesa dështoi (kodi i gabimit: 22425). Ju lutemi provojeni përsëri",
    "error_20098": "Kodi i gabimit: 20098. Nëse jeni duke shfletuar në modalitetin privat, ju lutemi kaloni në modalitetin normal dhe provoni përsëri.",
    "error_22298": "Kërkesa për Google dështoi (Gabim: 22298). Ju lutemi provojeni përsëri.",
    "error_22498": "Kërkesa për Facebook dështoi (Gabim: 22498). Ju lutemi provojeni përsëri.",
    "error_24902": "Kërkesa dështoi (kodi i gabimit: 24902). Ju lutemi provojeni përsëri",
    "error_24903": "Kërkesa dështoi (kodi i gabimit: 24903). Ju lutemi provojeni përsëri",
    "error_24904": "Kërkesa dështoi (kodi i gabimit: 24904). Ju lutemi provojeni përsëri",
    "error_24905": "Kërkesa dështoi (kodi i gabimit: 24905). Ju lutemi provojeni përsëri",
    "login_title": "Hyni në MindOnMap",
    "log_in": "Identifikohu",
    "no_account": "Nuk ka llogari?",
    "create_it": "Krijo atë",
    "or_log_in_with": "Ose hyni me",
    "passwordless_login": "Hyrja pa fjalëkalim",
    "log_in_done": "Identifikohu U krye",
    "three_rd_account_connect_info": "urime! Ju keni hyrë me sukses. Tani mund të lidhni një llogari emaili që përdoret për t'u identifikuar në të ardhmen.",
    "see_my_account": "Shiko llogarinë time",
    "three_rd_login_merge_account": "Adresa e emailit të llogarisë së palës së tretë është regjistruar, dëshironi të lidheni dhe të identifikoheni drejtpërdrejt me këtë adresë emaili?",
    "connect_log_in": "Lidhu & Identifikohu",
    "create_an_account": "Krijo nje llogari",
    "back_to_log_in": "Kthehu për t'u identifikuar",
    "create_password": "Krijo një fjalëkalim",
    "create_now": "Krijo Tani",
    "password_login_subtitle": "Hyrja pa fjalëkalim me email",
    "account_login": "Hyrja në llogari",
    "rights": "Duke krijuar këtë llogari, ju pranoni <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Kushtet e shërbimit</a> dhe <a href=\"https://www.mindonmap.com/privacy-policy/\">Politika e privatësisë</a>",
    "passwordless_login_done": "Identifikimi pa fjalëkalim u krye",
    "passwordless_login_info": "Urime, keni përfunduar me sukses hyrjen pa fjalëkalim. Ju mund të krijoni një fjalëkalim për këtë llogari dhe të identifikoheni me llogarinë dhe fjalëkalimin në të ardhmen. <a href=\"/create-password\" style=\"display: initial;\">Krijo tani</a>",
    "sign_up": "Regjistrohu",
    "register_info": "Krijo llogarine tende",
    "reset_now": "Rivendos tani",
    "forgot_password": "Keni harruar fjalëkalimin",
    "reset_password_subtitle": "Përdorni emailin e llogarisë tuaj për të rivendosur fjalëkalimin",
    "plan_products": "Planet & Produktet",
    "nick_name": "Emri i përdoruesit:",
    "email": "Email:",
    "my_products": "Produktet e Mia",
    "my_orders": "Porositë e mia",
    "unlink": "Shkëpute",
    "link": "Lidhje",
    "connected_accounts": "Llogaritë e lidhura",
    "last_name": "Mbiemri:",
    "first_name": "Emri:",
    "Gender": "Gjinia:",
    "Birth": "Lindja:",
    "Month": "Muaj",
    "Year": "viti",
    "Country_Region": "Vendi/Rajoni:",
    "Address": "Adresë:",
    "Save": "Ruaj",
    "Date": "Data",
    "Male": "Mashkull",
    "Female": "Femër",
    "Unspecified": "E paspecifikuar",
    "Security": "Siguria",
    "change_password": "Ndrysho fjalekalimin",
    "change_now": "Ndrysho Tani",
    "connect_email": "Lidhni emailin",
    "delete_account": "Fshij llogarine",
    "delete_account_info": "Kur llogaria juaj të fshihet, të gjitha të dhënat në llogarinë time të lidhura me llogarinë tuaj do të fshihen përgjithmonë dhe mund të mos jeni në gjendje t'i rikuperoni ato. Ne ju rekomandojmë të ecni me kujdes.",
    "Delete": "Fshije",
    "Logout": "Shkyç",
    "my_profile": "Profili im",
    "guides_faqs": "Udhëzues dhe FAQ",
    "More": "Më shumë",
    "guides": "Udhëzues",
    "register": "regjistroheni",
    "hot_faq": "FAQ e nxehtë",
    "Contents": "Përmbajtja:",
    "contact_us": "Na kontaktoni >>",
    "plan": "Planifikoni",
    "unregistered": "I paregjistruar",
    "buy_more": "Blini më shumë",
    "buy_again": "Blini përsëri",
    "buy_now": "Bli tani",
    "free_no_limit": "Falas dhe pa kufi",
    "expired": "I skaduar",
    "lifetime": "Jetëgjatësia",
    "remain": "Mbetet",
    "day_s": "Ditë(at)",
    "error_24801": "Kërkesa dështoi (kodi i gabimit: 24801). Ju lutemi provojeni përsëri",
    "no_app_found": "Nuk u gjet asnjë aplikacion!<a href=\"/\">Rifresko</a> ose <a href=\"https://www.mindonmap.com/\">Shko në faqen zyrtare të internetit</a>",
    "get_more": "Merr më shumë >>",
    "edit_photo": "Edit Foto",
    "select_photo": "Zgjidhni foton",
    "change_photo": "Ndrysho foton",
    "cancel": "Anulo",
    "hide_password": "Fshih fjalëkalimin",
    "show_password": "Shfaq fjalëkalimin",
    "zoom_in": "Zmadhoni",
    "zoom_out": "Zmadhoni",
    "rotate": "Rrotulloje",
    "horizontal_flip": "Rrotullim horizontal",
    "vertical_flip": "Rrotullim vertikal",
    "country": "Vendi",
    "country_1": "Zgjidhni shtetin/rajonin tuaj",
    "country_2": "Ishulli Åaland",
    "country_3": "Afganistani",
    "country_4": "Shqipëria",
    "country_5": "Algjeria",
    "country_6": "Samoa amerikane",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktida",
    "country_11": "Antigua dhe Barbuda",
    "country_12": "Argjentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Australia",
    "country_16": "Austria",
    "country_17": "Azerbajxhani",
    "country_18": "Bahrein",
    "country_19": "Bangladeshi",
    "country_20": "Barbados",
    "country_21": "Bjellorusia",
    "country_22": "Belgjika",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Butani",
    "country_27": "Bolivia",
    "country_28": "Bosnjë dhe Hercegovinë",
    "country_29": "Botsvana",
    "country_30": "Ishulli Bouvet",
    "country_31": "Brazili",
    "country_32": "Territori Britanik i Oqeanit Indian",
    "country_33": "Ishujt e Virgjër Britanikë",
    "country_34": "Brunei",
    "country_35": "Bullgaria",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kamboxhia",
    "country_39": "Kamerun",
    "country_40": "Kanadaja",
    "country_41": "Kepi Verde",
    "country_42": "Karaibe Holandë",
    "country_43": "Ishujt Kajman",
    "country_44": "Republika e Afrikës Qendrore",
    "country_45": "Çad",
    "country_46": "Kili",
    "country_47": "Kinë",
    "country_48": "Ishulli i Krishtlindjeve",
    "country_49": "Ishujt Cocos (Keling).",
    "country_50": "Kolumbia",
    "country_51": "Ishujt Kuk",
    "country_52": "Kosta Rika",
    "country_53": "Bregu i Fildishtë",
    "country_54": "Kroacia",
    "country_55": "Kuba",
    "country_56": "Qipron",
    "country_57": "Republika Çeke",
    "country_58": "Republika Demokratike e Kongos",
    "country_59": "Danimarka",
    "country_60": "Xhibuti",
    "country_61": "Dominika",
    "country_62": "Republika Domenikane",
    "country_63": "Ekuador",
    "country_64": "Egjipti",
    "country_65": "El Salvador",
    "country_66": "Guinea Ekuatoriale",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Etiopia",
    "country_70": "Ishujt Falkland",
    "country_71": "Ishujt Faroe",
    "country_72": "Shtetet e federuara të Mikronezisë",
    "country_73": "Fixhi",
    "country_74": "Finlanda",
    "country_75": "Franca",
    "country_76": "Guajana franceze",
    "country_77": "Polinezia franceze",
    "country_78": "Territoret jugore franceze",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Gjeorgjia",
    "country_82": "Gjermania",
    "country_83": "Gana",
    "country_84": "Gjibraltarit",
    "country_85": "Britania e Madhe (Mbretëria e Bashkuar; Angli)",
    "country_86": "Greqia",
    "country_87": "Grenlanda",
    "country_88": "Grenada",
    "country_89": "Guadelupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guajana",
    "country_96": "Haiti",
    "country_97": "Ishulli i dëgjuar dhe Ishujt McDonald",
    "country_98": "Hondurasi",
    "country_99": "Hong Kong",
    "country_100": "Hungaria",
    "country_101": "Islanda",
    "country_102": "Indi",
    "country_103": "Indonezia",
    "country_104": "Irani",
    "country_105": "Iraku",
    "country_106": "Irlanda",
    "country_107": "Ishulli of Man",
    "country_108": "Izraeli",
    "country_109": "Italia",
    "country_110": "Xhamajka",
    "country_111": "Japonia",
    "country_112": "Xhersi",
    "country_113": "Jordania",
    "country_114": "Kazakistani",
    "country_115": "Kenia",
    "country_116": "Kiribati",
    "country_117": "Kuvajti",
    "country_118": "Kirgistani",
    "country_119": "Laosi",
    "country_120": "Letonia",
    "country_121": "Libani",
    "country_122": "Lesoto",
    "country_123": "Liberia",
    "country_124": "Libinë",
    "country_125": "Lihtenshtajni",
    "country_126": "Lituania",
    "country_127": "Luksemburgu",
    "country_128": "Makao",
    "country_129": "Madagaskari",
    "country_130": "Malavi",
    "country_131": "Malajzia",
    "country_132": "Maldivet",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Ishujt Marshall",
    "country_136": "Martinika",
    "country_137": "Mauritania",
    "country_138": "Mauritius",
    "country_139": "majota",
    "country_140": "Meksika",
    "country_141": "Moldavia",
    "country_142": "Monako",
    "country_143": "Mongolia",
    "country_144": "Mali i Zi",
    "country_145": "Montserrat",
    "country_146": "Maroku",
    "country_147": "Mozambik",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepali",
    "country_152": "Holanda",
    "country_153": "Kaledonia e Re",
    "country_154": "Zelanda e Re",
    "country_155": "Nikaragua",
    "country_156": "Nigeri",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Ishulli Norfolk",
    "country_160": "Korea e Veriut",
    "country_161": "Ishujt Mariana Veriore",
    "country_162": "Norvegjia",
    "country_163": "Omani",
    "country_164": "Pakistani",
    "country_165": "Palau",
    "country_166": "territoret palestineze",
    "country_167": "Panamaja",
    "country_168": "Papua Guinea e Re",
    "country_169": "Paraguaj",
    "country_170": "Peruja",
    "country_171": "Ishujt Pitcairn",
    "country_172": "Polonia",
    "country_173": "Portugalia",
    "country_174": "Porto Riko",
    "country_175": "Katari",
    "country_176": "Republika e Maqedonisë (FYROM)",
    "country_177": "Republika e Kongos",
    "country_178": "Réunion",
    "country_179": "Rumania",
    "country_180": "Federata Ruse",
    "country_181": "Ruanda",
    "country_182": "Shën Barthélemy",
    "country_183": "Saint Martin (Francë)",
    "country_184": "Saint-Pierre dhe Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome dhe Principe",
    "country_188": "Arabia Saudite",
    "country_189": "Senegali",
    "country_190": "Serbisë",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapor",
    "country_194": "Sllovakia",
    "country_195": "Sllovenia",
    "country_196": "Ishujt Solomon",
    "country_197": "Somali",
    "country_198": "Afrika e Jugut",
    "country_199": "Gjeorgjia e Jugut dhe Sandwich Isla South ",
    "country_200": "Korea e jugut",
    "country_201": "Sudani i Jugut",
    "country_202": "Spanja",
    "country_203": "Sri Lanka",
    "country_204": "Shën Helena dhe varësitë",
    "country_205": "St. Kitts dhe Nevis",
    "country_206": "Shën Luçia",
    "country_207": "Shën Vincenti dhe Grenadinet",
    "country_208": "Sudani",
    "country_209": "Surinami",
    "country_210": "Swaziland",
    "country_211": "Suedia",
    "country_212": "Zvicra",
    "country_213": "Siria",
    "country_214": "Tajvani",
    "country_215": "Taxhikistani",
    "country_216": "Tanzania",
    "country_217": "Shablloni:Të dhënat e vendit SJM Svalbard",
    "country_218": "Tajlandë",
    "country_219": "Bahamas",
    "country_220": "Komoret",
    "country_221": "Filipinet",
    "country_222": "Timor-Leste (Timori Lindor)",
    "country_223": "Togo",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad dhe Tobago",
    "country_227": "Tunizia",
    "country_228": "Turqia",
    "country_229": "Turkmenistani",
    "country_230": "Ishujt Turks dhe Kaikos",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrainë",
    "country_234": "Emiratet e Bashkuara Arabe",
    "country_235": "Ishujt e vegjël periferikë të Shteteve të Bashkuara",
    "country_236": "Shtetet e Bashkuara të Amerikës (SHBA)",
    "country_237": "Ishujt e Virgjër të Shteteve të Bashkuara",
    "country_238": "Uruguai",
    "country_239": "Uzbekistani",
    "country_240": "Vanuatu",
    "country_241": "Qyteti i Vatikanit (Selia e Shenjtë)",
    "country_242": "Venezuela",
    "country_243": "Vietnami",
    "country_244": "Wallis dhe Futuna",
    "country_245": "Sahara perëndimore",
    "country_246": "Jemeni",
    "country_247": "Zambia",
    "country_248": "Zimbabve",
    "google_login": "Identifikohu me Google",
    "State": "Shtetit",
    "Activation_code": "Kodi i aktivizimit",
    "Question": "Listoni të gjitha aplikacionet në të cilat keni hyrë",
    "Copy_complete": "Kopjimi i përfunduar",
    "footer": "E drejta e autorit © 2024 MindOnMap Studio. Të gjitha të drejtat e rezervuara.",
    "change_password_success": "Fjalëkalimi u ndryshua me sukses",
    "successful_login_title": "Identifikimi me sukses",
    "product_page": "Faqja e produktit >>",
    "successful_login_info": "Hyrja përfundoi. Ju lutemi mbyllni faqen aktuale dhe kthehuni në skedën origjinale për të vazhduar me procesin e mëposhtëm. Faqja aktuale do të mbyllet automatikisht në 5 sekonda. Nëse mbyllja automatike ose mbyllja duke klikuar butonin \"U krye\" dështon, ju lutemi mbylleni direkt këtë skedë.",
    "successful_login_info_firefox": "Hyrja përfundoi. Ju lutemi mbyllni faqen aktuale dhe kthehuni në skedën origjinale për të vazhduar me procesin e mëposhtëm.",
    "my_account": "Llogaria ime",
    "my_history": "Historia ime",
    "remove_watermark": "Hiq filigranin",
    "no_history": "Asnjë Histori",
    "history_all": "Selektoj të gjitha",
    "history_open": "Hapur",
    "history_down": "Shkarko",
    "history_delete": "Fshije",
    "history_clear": "Pastro të pavlefshme",
    "images": "imazh(at)",
    "use_this_function": "Përdorni këtë produkt >>",
    "hd_downloading": "Përfitimet e shkarkimit të imazheve origjinale HD:",
    "lifetimeRemaining": "Jetë-E mbetur",
    "Remaining": "E mbetur",
    "email_verification": "Verifikimi me email",
    "email_verification_info": "Ne kemi dërguar mesazhin e verifikimit në emailin tuaj <span class=\"email\"></span> dhe ju lutemi përfundoni verifikimin. Pas verifikimit, përfitimet do të sinkronizohen automatikisht.",
    "wrong_email": "Adresa e gabuar e emailit?",
    "click_here_to_modify": "Kliko këtu për të modifikuar",
    "get_more_help": "Merrni më shumë ndihmë?",
    "click_here": "Kliko këtu",
    "email_verification_info_success": "Urime për verifikimin e llogarisë tuaj të emailit.",
    "email_verification_info_error": "Verifikimi dështoi për shkak të lidhjes së skaduar.",
    "registration_succeeded": "Regjistrimi u krye me sukses",
    "registration_succeeded_info_1": "urime! Ju jeni regjistruar me sukses. Ne kemi dërguar mesazhin e verifikimit në emailin tuaj <span class=\"email\"></span> dhe ju lutemi përfundoni verifikimin për të përdorur përfitimet e këtij emaili.",
    "registration_succeeded_info_2": "Kliko \"U krye\" për t'u kthyer në faqen kryesore dhe për të përdorur këtë produkt.",
    "registration_succeeded_info_3": "Klikoni \"U krye\" për të mbyllur faqen aktuale dhe për t'u kthyer në faqen kryesore për operacionet e mëposhtme. Nëse mbyllja dështon, ju lutemi mbyllni manualisht skedën.",
    "verify_email": "Verifiko emailin",
    "registered_email_not_verified": "Email-i i regjistruar nuk është verifikuar, ju lutemi verifikojeni menjëherë.",
    "email_verification_time_1": "Nuk keni marrë një email verifikimi?",
    "email_verification_time_2": "Pas",
    "email_verification_time_3": "Klikoni këtu për ta ridërguar",
    "error_26301": "Kodi i gabimit: 26301, ju lutemi na kontaktoni",
    "error_26302": "Kodi i gabimit: 26302, ju lutemi na kontaktoni",
    "error_26303": "Gabim në formatin e postës elektronike (kodi i gabimit: 26303). Ju lutemi, futeni përsëri",
    "error_26304": "Rekomandohet fjalëkalimi mbi 8 karaktere (kodi i gabimit: 26304)",
    "error_26305": "Kërkesa dështoi (kodi i gabimit: 26305). Ju lutemi provojeni përsëri",
    "error_26306": "Email-i është regjistruar, ju lutemi <a href=\"https://account.mindonmap.com/login/\">shkoni të identifikoheni</a>",
    "error_26307": "Kërkesa dështoi (kodi i gabimit: 26307). Ju lutemi provojeni përsëri",
    "error_26308": "Kërkesa dështoi (kodi i gabimit: 26308). Ju lutemi provojeni përsëri",
    "error_26401": "Kodi i gabimit: 26401, ju lutemi provojeni përsëri",
    "error_26402": "Email-i është verifikuar (kodi i gabimit: 26402), ju lutemi riprovoni",
    "error_26403": "Kërkesa dështoi (kodi i gabimit: 26403). Ju lutemi provojeni përsëri",
    "error_26404": "Kërkesa dështoi (kodi i gabimit: 26404). Ju lutemi provojeni përsëri",
    "error_26501": "Kodi i gabimit: 26501, ju lutemi na kontaktoni",
    "error_26502": "Kodi i gabimit: 26502, ju lutemi na kontaktoni",
    "error_26503": "Gabim në formatin e postës elektronike (kodi i gabimit: 26503). Ju lutemi, futeni përsëri",
    "error_26504": "Kërkesa dështoi (kodi i gabimit: 26504). Ju lutemi provojeni përsëri",
    "error_26505": "Email-i nuk është regjistruar, ju lutemi <a href=\"https://account.mindonmap.com/register/\">regjistrojeni fillimisht</a>",
    "error_26506": "Email-i është verifikuar.",
    "error_26507": "Kërkesa dështoi (kodi i gabimit: 26507). Ju lutemi provojeni përsëri",
    "error_26508": "Verifikimi u krye (kodi i gabimit: 26508), ju lutemi provojeni përsëri",
    "error_26509": "Kërkesa dështoi (kodi i gabimit: 26509), ju lutemi provojeni përsëri",
    "error_26510": "Kodi i gabimit: 26510, ju lutemi na kontaktoni",
    "error_26601": "Kodi i gabimit: 26601, ju lutemi na kontaktoni",
    "error_26602": "Kodi i gabimit: 26602, ju lutemi na kontaktoni",
    "error_26603": "Kërkesa dështoi (kodi i gabimit: 26603). Ju lutemi provojeni përsëri",
    "error_26604": "Kodi i gabimit: 26604, ju lutemi na kontaktoni",
    "error_26605": "Kodi i gabimit: 26605, ju lutemi na kontaktoni",
    "error_26701": "Kodi i gabimit: 26701, ju lutemi na kontaktoni",
    "error_26702": "Kërkesa dështoi (kodi i gabimit: 26702). Ju lutemi provojeni përsëri",
    "error_26703": "Kodi i gabimit: 26703, ju lutemi na kontaktoni",
    "error_26704": "Kodi i gabimit: 26704, ju lutemi na kontaktoni",
    "error_26705": "Prisni të identifikoheni (kodi i gabimit: 26705). Ju lutemi provojeni përsëri",
    "no_cookie": "Ju keni aktivizuar funksionin Blloko të gjitha kukit në shfletuesin tuaj, kështu që nuk mund të identifikoheni. Ju lutemi shkoni te \"Cilësimet\" për të kontrolluar kutinë e Lejo të gjitha skedarët e skedarëve.",
    "error_26801": "Kodi i gabimit: 26801, ju lutemi na kontaktoni",
    "error_26802": "Kodi i gabimit: 26802, ju lutemi na kontaktoni",
    "error_26803": "Kërkesa dështoi (kodi i gabimit: 26803). Ju lutemi provojeni përsëri",
    "error_26804": "Kërkesa dështoi (kodi i gabimit: 26804). Ju lutemi provojeni përsëri",
    "error_order": "Kërkesa dështoi (kodi i gabimit: 27098), ju lutemi provojeni përsëri!",
    "error_order1": "Kërkesa e porosisë është e paplotë (kodi i gabimit: ",
    "error_order2": "）, ju lutemi rifreskojeni dhe provojeni sërish.",
    "modify_email_title": "Modifiko emailin",
    "modify_email_info": "Ju mund të përdorni emailin e modifikuar për të hyrë në llogarinë tuaj.",
    "images_per": "Imazhet për",
    "error_26101": "Gabim: 26101. Ju lutemi na kontaktoni",
    "error_26102": "Gabim: 26102. Ju lutemi na kontaktoni",
    "error_26103": "Kërkesa dështoi (kodi gabimi:26103). Ju lutemi provojeni përsëri",
    "error_26104": "Kodi i gabimit: 26104, ju lutemi riprovoni",
    "error_26105": "Kodi i gabimit: 26105, ju lutemi riprovoni",
    "error_26106": "Fshirja dështoi (kodi gabimi: 26106). Ju lutemi provojeni përsëri",
    "error_26201": "Gabim: 26201. Ju lutemi na kontaktoni",
    "error_26202": "Kërkesa dështoi (kodi gabimi:26202). Ju lutemi provojeni përsëri",
    "error_26001": "Gabim: 26001. Ju lutemi na kontaktoni",
    "error_26002": "Gabim: 26002. Ju lutemi na kontaktoni",
    "error_26003": "Gabim: 26003. Ju lutemi na kontaktoni",
    "error_26004": "Gabim: 26004. Ju lutemi na kontaktoni",
    "error_26005": "Kërkesa dështoi (kodi gabimi:26005). Ju lutemi provojeni përsëri",
    "error_26006": "Kodi i gabimit: 26006, ju lutemi riprovoni",
    "error_26008": "Gabim: 26008. Ju lutemi na kontaktoni",
    "go_to_the_home_page": "Shkoni në faqen kryesore",
    "error_27101": "Kërkesa dështoi (kodi i gabimit: 27101). Ju lutemi riprovoni ist",
    "error_27201": "Kodi i gabimit: 27201, ju lutemi na kontaktoni",
    "error_27202": "Kodi i gabimit: 27202, ju lutemi provojeni përsëri",
    "error_27203": "Kërkesa dështoi (kodi i gabimit: 27203). Ju lutemi provojeni përsëri",
    "error_27204": "Kodi i pavlefshëm (kodi i gabimit: 27204).",
    "error_27205": "Kërkesa dështoi (kodi i gabimit: 27205). Ju lutemi provojeni përsëri",
    "error_27206": "Kërkesa dështoi (kodi i gabimit: 27206). Ju lutemi provojeni përsëri",
    "error_27207": "Kërkesa dështoi (kodi i gabimit: 27207). Ju lutemi provojeni përsëri",
    "no_history_found": "Ju nuk keni përdorur asnjë mjet! <a href=\"/\">Rifresko</a> ose <a href=\"https://www.mindonmap.com/\">Shko në faqen zyrtare të internetit</a>",
    "error_25301": "Gabim: 25301. Ju lutemi na kontaktoni",
    "error_25302": "Gabim: 25302. Ju lutemi na kontaktoni",
    "error_25303": "Kërkesa dështoi (kodi gabimi: 25303). Ju lutemi provojeni përsëri",
    "error_25304": "Kërkesa dështoi (kodi gabimi: 25304). Ju lutemi provojeni përsëri",
    "error_25305": "Kërkesa dështoi (kodi gabimi: 25305). Ju lutemi provojeni përsëri",
    "error_25306": "Kërkesa dështoi (kodi gabimi: 25306). Ju lutemi provojeni përsëri",
    "image_upscaler_p": "Imazhi pa përfitime nga shkarkimi i filigranit:",
    "Available_for": "Në dispozicion për:",
    "credit_per": "%s kredite për imazh HD",
    "still_valid": "Plani(et) e blera janë ende të vlefshme",
    "credit": "kreditë",
    "pc_3rd_info": "Identifikohu me sukses. Ju lutemi shkoni te aplikacioni për funksionim të mëtejshëm.",
    "use_online": "Përdorni shërbimin online",
    "use_download": "Përdorni programin e desktopit",
    "use_immediately": "Përdorni Menjëherë",
    "Use_in_browser": "Përdorni në Browser",
    "win_desktop": "Dritaret",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Kredite në muaj",
    "expire": "Koha e skadimit:",
    "viewDetails": "Shiko detajet",
    "viewHistory": "Shikoni përfitimet e historisë >>",
    "viewDetailsInfo": "Shënim: Nëse përfitimet e abonimit rinovohen brenda 7 ditëve pas skadimit, përfitimet e papërdorura mund të vazhdojnë të përdoren. Gjithashtu, koha e skadimit do të përditësohet automatikisht në kohën e skadimit të abonimit të ri. Nëse nuk ka abonim të ri pas 7 ditësh nga koha e skadimit, të gjitha përfitimet e skaduara do të pastrohen.",
    "connect_account": "Lidhni emailin në llogarinë tuaj",
    "connect_account_info": "Pas lidhjes, mund të identifikoheni me këtë adresë emaili.",
    "connect_now": "Lidhe tani",
    "no_email_bind": "Nuk ka lidhje emaili",
    "bind_email": "Lidh Email",
    "connect_your_email_placeholder": "Ju lutemi shkruani adresën tuaj të emailit",
    "bind_an_email": "Lidhni një email",
    "bind_info": "Ju keni hyrë me sukses. Lidhni një email në llogarinë tuaj për të aktivizuar përfitimet tuaja.",
    "bind_later": "Lidhe më vonë",
    "hi": "Përshëndetje, %s!",
    "Personal_Information": "Informata personale",
    "Access": "Qasja",
    "Subscription_Plan": "(Plani i abonimit)",
    "No_orders": "Nuk u gjet asnjë porosi.",
    "No_data": "Nuk ka të dhëna",
    "Featured_Products": "Produktet e veçuara",
    "More_Products": "Më shumë Produkte",
    "Free_Download": "Shkarko falas",
    "Get_Started": "Fillo",
    "Subscribe": "Abonohu",
    "Verified": "Verifikuar",
    "back_to_account_center": "Kthehu te Qendra e Llogarisë",
    "success": "Sukses!",
    "successfully": "Ju keni regjistruar me sukses një llogari.",
    "Continue": "Vazhdoni",
    "Already": "Posedoni një llogari?",
    "loading_verification": "Kontrollimi i statusit të verifikimit...",
    "email_no_verification": "Na vjen keq, adresa e regjistruar e emailit nuk është verifikuar. Ju lutemi plotësoni verifikimin sipas udhëzimeve të mësipërme dhe klikoni përsëri butonin \"U krye\" për të përfunduar regjistrimin.",
    "will_expire_after": "Do të skadojë më pas",
    "hours": "orë",
  },
  "sv": {
    "overtime": "Felkod: {%}, försök igen",
    "isnetwork": "Internetfel. Kontrollera och försök igen",
    "email_placeholder": "E-post",
    "email_empty": "Vänligen ange e-post",
    "email_not_valid": "E-post är inte giltig",
    "email_not_valid_1": "Vänligen ange din e-post",
    "email_not_valid_2": "E-postadressen är inte giltig, använd en annan adress.",
    "email_not_valid_3": "Ingen e-postinmatning",
    "password_placeholder": "Lösenord",
    "password_empty": "Vänligen ange lösenord",
    "password_not_valid": "Felaktigt konto eller lösenord",
    "password_not_valid_1": "Lösenord över 8 tecken krävs",
    "password_not_valid_2": "Vänligen skapa ett lösenord",
    "password_placeholder_1": "Skapa ditt lösenord",
    "password_placeholder_2": "Bekräfta ditt lösenord",
    "password_placeholder_3": "Skapa nytt lösenord",
    "password_placeholder_4": "Bekräfta nytt lösenord",
    "password_placeholder_5": "Ange gammalt lösenord",
    "copy_password_empty": "Vänligen bekräfta lösenordet",
    "copy_password_not_valid": "Vänligen bekräfta ditt lösenord",
    "copy_passwords_inconsistent": "Din lösenordsbekräftelse stämmer inte överens",
    "code_empty": "Ange verifieringskod",
    "code_not_valid": "Ogiltig verifieringskod",
    "code_placeholder": "Verifierings kod",
    "not_received_code": "Om din brevlåda inte har fått verifieringskoden på länge, vänligen skaffa verifieringskoden igen.",
    "get_first_code": "Vänligen skaffa verifieringskoden först.",
    "last_name_placeholder": "Vänligen ange ditt efternamn",
    "first_name_placeholder": "Vänligen ange ditt förnamn",
    "address_placeholder": "Vänligen ange din adress",
    "no_code_text": "Vi har skickat en verifieringskod. Vänligen ange din kod. <span class='tips'>Fick du ingen kod?",
    "no_code_text_1": "1. Se till att e-postadressen är giltig och att den kan ta emot e-post.",
    "no_code_text_2": "2. Eftersom e-postmeddelandet skickas av systemet automatiskt, kan det flaggas som skräppost eller skräppost. Kontrollera om e-postmeddelandet finns i papperskorgen.",
    "no_code_text_3": "3. Kan du inte lösa ditt problem? ",
    "no_code_text_3_span": "Klicka här för att kontakta oss.",
    "order_no": "Du har inte köpt någon produkt. Om du har några frågor, <a href=\"https://www.mindonmap.com/contact-us/\">kontakta oss</a>.",
    "error_24901": "Det aktuella kontot har ingen e-post länkad och kan inte hitta beställningarna. Anslut ett e-postmeddelande.",
    "user_guide": "Användarhandbok >>",
    "download": "Ladda ner",
    "order_number": "Ordernummer:",
    "Refund": "Återbetalning",
    "Disabled": "Inaktiverad",
    "Normal": "Vanligt",
    "Modify": "Ändra",
    "Modify_1": "Ändra >>",
    "Connect": "Ansluta",
    "unlink_success": "Ta bort länken",
    "connect_success": "Anslut framgångsrikt",
    "feedback_title": "Tack för din feedback. Lämna ditt problem så svarar vi dig inom 24 timmar.",
    "feedback_thank_you": "Tack!<br />Din feedback har skickats.",
    "feedback_email": "Ange din e-postadress här!",
    "feedback_content": "Lämna eventuella problem eller förslag du stött på här.",
    "feedback_submit": "Skicka in",
    "form_contents": "Du har inte angett någon beskrivning. Vänligen ange den och skicka igen.",
    "old_password": "Vänligen ange gammalt lösenord",
    "new_password": "Vänligen skapa ett nytt lösenord",
    "old_new_password": "Det nya lösenordet kan inte vara detsamma som det gamla",
    "incorrect_password": "fel lösenord",
    "delete_no": "Radera nu",
    "Caps": "Caps Lock är på",
    "Get": "Skaffa sig",
    "Done": "Gjort",
    "error_20001": "Fel: 20001. Logga in igen.",
    "error_20002": "Fel: 20002. Logga in igen.",
    "error_20003": "Fel: 20003. Logga in igen.",
    "error_20004": "Begäran misslyckades (felkod: 20004). Försök igen.",
    "error_20005": "Inloggningssessionen har löpt ut (Fel: 20005). Logga in igen.",
    "error_20006": "Begäran misslyckades (felkod: 20006). Försök igen.",
    "error_20007": "Inloggningssessionen har löpt ut (Fel: 20007). Logga in igen.",
    "error_20008": "Inloggningssessionen har löpt ut (Fel: 20008). Logga in igen.",
    "error_20009": "Inloggningssessionen har löpt ut (Fel: 20009). Logga in igen.",
    "error_20101": "Vänligen ange din e-post (felkod: 20101)",
    "error_20102": "E-postadressen är inte giltig (felkod: 20102)",
    "error_20103": "Begäran misslyckades (felkod: 20103). Försök igen",
    "error_20104": "E-post används redan, vänligen <a href=\"https://account.mindonmap.com/login/\">logga in</a> eller registrera dig med en ny",
    "error_20105": "Begäran misslyckades (felkod: 20105). Försök igen",
    "error_20106": "Det gick inte att skicka e-post, försök igen",
    "error_20201": "Vänligen ange din e-post (felkod: 20201)",
    "error_20202": "Vänligen ange ditt lösenord (felkod: 20202)",
    "error_20203": "Vänligen ange verifieringskod (felkod: 20203)",
    "error_20204": "E-postadressen är inte giltig (felkod: 20204)",
    "error_20205": "Lösenord över 8 tecken krävs (felkod: 20205)",
    "error_20206": "Begäran misslyckades (felkod: 20206). Försök igen",
    "error_20207": "Ogiltig verifieringskod",
    "error_20208": "Begäran misslyckades (felkod: 20208). Försök igen",
    "error_20209": "Begäran misslyckades (felkod: 20209). Försök igen",
    "error_20301": "Vänligen ange din e-post (felkod: 20301)",
    "error_20302": "Fel: 20302. Kontakta oss",
    "error_20303": "E-postadressen är inte giltig (felkod: 20303)",
    "error_20304": "Begäran misslyckades (felkod: 20304). Försök igen",
    "error_20305": "Kontot finns inte. Ange igen eller <a href=\"https://account.mindonmap.com/register/\">skapa det</a> först.",
    "error_20306": "Inget lösenord än. Använd <a href=\"https://account.mindonmap.com/passwordless-login/\">lösenordslös inloggning</a> eller <a href=\"https://account.mindonmap.com/create-password/\"> ange ett lösenord</a> och logga in.",
    "error_20308": "Begäran misslyckades (felkod: 20308). Försök igen",
    "error_20401": "Det gick inte att logga ut (felkod: 20401). Försök igen",
    "error_20501": "Vänligen ange din e-post (felkod: 20501)",
    "error_20502": "E-postadressen är inte giltig (felkod: 20502)",
    "error_20503": "Begäran misslyckades (felkod: 20503). Försök igen",
    "error_20504": "Det gick inte att skicka e-post. Försök igen.",
    "error_20601": "Vänligen ange din e-post (felkod: 20601)",
    "error_20602": "Vänligen ange verifieringskod (felkod: 20602)",
    "error_20603": "E-postadressen är inte giltig (felkod: 20603)",
    "error_20604": "Begäran misslyckades (felkod: 20604). Försök igen",
    "error_20606": "Begäran misslyckades (felkod: 20606). Försök igen",
    "error_20607": "Begäran misslyckades (felkod: 20607). Försök igen",
    "error_20608": "Begäran misslyckades (felkod: 20608). Försök igen",
    "error_20701": "Vänligen ange din e-post (felkod: 20701)",
    "error_20702": "E-postadressen är inte giltig (felkod: 20702)",
    "error_20703": "Begäran misslyckades (felkod: 20703). Försök igen",
    "error_20704": "Kontot finns inte. Ange igen eller <a href=\"https://account.mindonmap.com/register/\">skapa det</a> först.",
    "error_20705": "Begäran misslyckades (felkod: 20705). Försök igen",
    "error_20706": "Det gick inte att skicka e-post. Försök igen",
    "error_20801": "Vänligen ange din e-post (felkod: 20801)",
    "error_20802": "Fel: 20802. Kontakta oss",
    "error_20803": "Vänligen ange verifieringskod (felkod: 20803)",
    "error_20804": "E-postadressen är inte giltig (felkod: 20804)",
    "error_20805": "Lösenord över 8 tecken krävs (felkod: 20805)",
    "error_20806": "Begäran misslyckades (felkod: 20806). Försök igen",
    "error_20808": "Begäran misslyckades (felkod: 20808). Försök igen",
    "error_20901": "Begäran misslyckades (felkod: 20901). Försök igen",
    "error_20902": "Begäran misslyckades (felkod: 20902). Försök igen",
    "error_21000": "Ändringar sparas",
    "error_21001": "Ingen information lämnas (felkod: 21001)",
    "error_21002": "Begäran misslyckades (felkod: 21002). Försök igen",
    "error_21101": "Vänligen ange din e-post (felkod: 21101)",
    "error_21102": "E-postadressen är inte giltig (felkod: 21102)",
    "error_21103": "Begäran misslyckades (felkod: 21103), vänligen försök igen",
    "error_21104": "E-posten är redan ansluten, använd en ny",
    "error_21105": "Begäran misslyckades (felkod: 21105), vänligen försök igen",
    "error_21106": "Det gick inte att skicka e-post. Försök igen",
    "error_21201": "Vänligen ange din e-post (felkod: 21201)",
    "error_21202": "Vänligen ange verifieringskod (felkod: 21202)",
    "error_21203": "E-postadressen är inte giltig (felkod: 21203)",
    "error_21204": "Fel: 21204. Kontakta oss",
    "error_21205": "Fel: 21205. Kontakta oss",
    "error_21206": "Lösenord över 8 tecken krävs (felkod: 21206)",
    "error_21207": "Begäran misslyckades (felkod: 21207). Försök igen",
    "error_21209": "Begäran misslyckades (felkod: 21209). Försök igen",
    "error_21301": "Vänligen ange gammalt lösenord (felkod: 21301)",
    "error_21302": "Vänligen skapa ett nytt lösenord (felkod: 21302)",
    "error_21303": "Det nya lösenordet kan inte vara detsamma som det gamla. (Fel: 21303)",
    "error_21304": "Lösenord över 8 tecken krävs (felkod: 21304)",
    "error_21306": "Begäran misslyckades (felkod: 21306). Försök igen",
    "error_21402": "Begäran misslyckades (felkod: 21402). Försök igen",
    "error_21403": "Det gick inte att skicka verifieringskoden. Skicka den igen",
    "error_21500": "Kontot har tagits bort",
    "error_21501": "Vänligen ange verifieringskod (felkod: 21501)",
    "error_21502": "Inloggningssessionen har löpt ut (Fel: 21502). Logga in igen.",
    "error_21503": "Begäran misslyckades (felkod: 21503). Försök igen",
    "error_21505": "Begäran misslyckades (felkod: 21505), försök igen",
    "error_21601": "Fel: 20601. Kontakta oss",
    "error_21602": "Ogiltig verifiering (fel: 20602). Försök igen.",
    "error_21603": "Fel: 20603. Försök igen",
    "error_21604": "Begäran misslyckades (felkod: 21604). Försök igen",
    "error_21606": "Begäran misslyckades (felkod: 21606). Försök igen",
    "error_21611": "Begäran misslyckades (felkod: 21611). Försök igen",
    "error_21801": "Fel: 21801. Kontakta oss",
    "error_21802": "Begäran misslyckades (fel: 21802). Försök igen",
    "error_21803": "Fel: 21803. Försök igen",
    "error_21804": "Begäran misslyckades (felkod: 21804). Försök igen",
    "error_21806": "Fel: 21806. Försök igen",
    "error_21807": "Fel: 21807. Kontakta oss",
    "error_21808": "Fel: 21808. Kontakta oss",
    "error_21809": "Fel: 21809. Kontakta oss",
    "error_21810": "Fel: 21810. Kontakta oss",
    "error_21811": "Fel: 21811. Kontakta oss",
    "error_21812": "Fel: 21812. Kontakta oss",
    "error_21813": "Begäran misslyckades (felkod: 21813). Försök igen",
    "error_21814": "Fel: 21814. Kontakta oss",
    "error_21815": "Begäran misslyckades (felkod: 21815). Försök igen",
    "error_21816": "Fel: 21816. Kontakta oss",
    "error_21817": "Fel: 21817. Kontakta oss",
    "error_21818": "Fel: 21818. Kontakta oss",
    "error_21819": "Begäran misslyckades (felkod: 21819). Försök igen",
    "error_21820": "Fel: 21820. Kontakta oss",
    "error_21821": "Fel: 21821. Kontakta oss",
    "error_21822": "Fel: 21822. Kontakta oss",
    "error_21823": "Begäran misslyckades (felkod: 21823). Försök igen",
    "error_21824": "Begäran misslyckades (felkod: 21824). Försök igen",
    "error_21825": "Begäran misslyckades (felkod: 21825). Försök igen",
    "error_21826": "Begäran misslyckades (felkod: 21826). Försök igen",
    "error_21901": "Fel: 21901. Kontakta oss",
    "error_21902": "Begäran misslyckades (felkod: 21902). Försök igen",
    "error_21903": "Kontostatusen har ändrats (felkod: 21903), vänligen uppdatera sidan och försök igen",
    "error_21904": "Fel: 21904. Försök igen",
    "error_21905": "Fel: 21905. Försök igen",
    "error_21906": "Begäran misslyckades (felkod: 21906). Försök igen",
    "error_21907": "Google-kontot har länkats till ett annat konto",
    "error_21908": "Begäran misslyckades (felkod: 21908). Försök igen",
    "error_22001": "Begäran misslyckades (felkod: 22001). Försök igen",
    "error_22002": "Det gick inte att ta bort länken utan ytterligare inloggning",
    "error_22003": "Begäran misslyckades (felkod: 22003). Försök igen",
    "error_22101": "Fel: 22101. Kontakta oss",
    "error_22102": "Kontostatusen har ändrats (felkod: 22102), vänligen uppdatera sidan och försök igen",
    "error_22103": "Begäran misslyckades (felkod: 22103). Försök igen",
    "error_22104": "Kontostatusen har ändrats (felkod: 22104), vänligen uppdatera sidan och försök igen",
    "error_22105": "Fel: 22105. Försök igen",
    "error_22106": "Fel: 22106. Försök igen",
    "error_22107": "Fel: 22107. Kontakta oss",
    "error_22108": "Begäran misslyckades (felkod: 22108). Försök igen",
    "error_22201": "Fel: 22201. Kontakta oss",
    "error_22202": "Ogiltig verifiering (fel: 22202). Försök igen.",
    "error_22203": "Fel: 22203. Försök igen\"",
    "error_22204": "Begäran misslyckades (felkod: 22204). Försök igen",
    "error_22206": "Begäran misslyckades (felkod: 22206). Försök igen",
    "error_22401": "Fel: 22401. Kontakta oss",
    "error_22402": "Ogiltig verifiering (fel: 22402). Försök igen.",
    "error_22403": "Fel: 22403. Försök igen",
    "error_22404": "Begäran misslyckades (felkod: 22404). Försök igen",
    "error_22405": "Facebook-kontot har länkats till annan e-post",
    "error_22406": "Fel: 22406. Försök igen",
    "error_22407": "Fel: 22407. Kontakta oss",
    "error_22408": "Fel: 22408. Kontakta oss",
    "error_22409": "Fel: 22409. Kontakta oss",
    "error_22410": "Fel: 224010. Kontakta oss",
    "error_22411": "Fel: 224011. Kontakta oss",
    "error_22412": "Fel: 224012. Kontakta oss",
    "error_22413": "Fel: 22413. Kontakta oss",
    "error_22414": "Begäran misslyckades (felkod: 22414). Försök igen",
    "error_22415": "Fel: 22415. Kontakta oss",
    "error_22416": "Fel: 22416. Kontakta oss",
    "error_22417": "Fel: 22417. Kontakta oss",
    "error_22418": "Begäran misslyckades (felkod: 22418). Försök igen",
    "error_22419": "Fel: 22419. Kontakta oss",
    "error_22420": "Fel: 22420. Kontakta oss",
    "error_22421": "Fel: 22421. Kontakta oss",
    "error_22422": "Begäran misslyckades (felkod: 22422). Försök igen",
    "error_22423": "Begäran misslyckades (felkod: 22423). Försök igen",
    "error_22424": "Begäran misslyckades (felkod: 22424). Försök igen",
    "error_22425": "Begäran misslyckades (felkod: 22425). Försök igen",
    "error_20098": "Felkod: 20098. Om du surfar i privat läge, växla till normalt läge och försök igen.",
    "error_22298": "Begäran om Google misslyckades (fel: 22298). Försök igen.",
    "error_22498": "Begäran om Facebook misslyckades (fel: 22498). Försök igen.",
    "error_24902": "Begäran misslyckades (felkod: 24902). Försök igen",
    "error_24903": "Begäran misslyckades (felkod: 24903). Försök igen",
    "error_24904": "Begäran misslyckades (felkod: 24904). Försök igen",
    "error_24905": "Begäran misslyckades (felkod: 24905). Försök igen",
    "login_title": "Logga in på MindOnMap",
    "log_in": "Logga in",
    "no_account": "Inget konto?",
    "create_it": "Skapa det",
    "or_log_in_with": "Eller logga in med",
    "passwordless_login": "Lösenordslös inloggning",
    "log_in_done": "Logga in Klar",
    "three_rd_account_connect_info": "Grattis! Du har loggat in framgångsrikt. Nu kan du ansluta ett e-postkonto som används för att logga in i framtiden.",
    "see_my_account": "Se mitt konto",
    "three_rd_login_merge_account": "Tredjepartskontots e-postadress har registrerats, vill du ansluta och logga in med denna e-postadress direkt?",
    "connect_log_in": "Anslut och logga in",
    "create_an_account": "Skapa ett konto",
    "back_to_log_in": "Tillbaka till login",
    "create_password": "Skapa lösenord",
    "create_now": "Skapa nu",
    "password_login_subtitle": "Lösenordslös inloggning med e-post",
    "account_login": "Kontoinloggning",
    "rights": "Genom att skapa detta konto godkänner du <a href=\"https://www.mindonmap.com/terms-and-conditions/\">användarvillkoren</a> och <a href=\"https://www.mindonmap.com/privacy-policy/\">Sekretesspolicy</a>",
    "passwordless_login_done": "Lösenordslös inloggning klar",
    "passwordless_login_info": "Grattis, du har slutfört lösenordslös inloggning. Du kan skapa ett lösenord för detta konto och logga in med kontot och lösenordet i framtiden. <a href=\"/create-password\" style=\"display: initial;\">Skapa nu</a>",
    "sign_up": "Bli Medlem",
    "register_info": "Skapa ditt konto",
    "reset_now": "Återställ nu",
    "forgot_password": "Glömt ditt lösenord",
    "reset_password_subtitle": "Använd ditt kontos e-postadress för att återställa lösenordet",
    "plan_products": "Planer och produkter",
    "nick_name": "Användarnamn:",
    "email": "E-post:",
    "my_products": "Mina produkter",
    "my_orders": "Mina beställningar",
    "unlink": "Ta bort länken",
    "link": "Länk",
    "connected_accounts": "Anslutna konton",
    "last_name": "Efternamn:",
    "first_name": "Förnamn:",
    "Gender": "Kön:",
    "Birth": "Födelse:",
    "Month": "Månad",
    "Year": "År",
    "Country_Region": "Land/region:",
    "Address": "Adress:",
    "Save": "Spara",
    "Date": "Datum",
    "Male": "Manlig",
    "Female": "Kvinna",
    "Unspecified": "Ospecificerad",
    "Security": "säkerhet",
    "change_password": "Ändra lösenord",
    "change_now": "Ändra nu",
    "connect_email": "Anslut e-post",
    "delete_account": "Radera konto",
    "delete_account_info": "När ditt konto raderas kommer all data i Mitt konto som är kopplad till ditt konto att raderas permanent och du kanske inte kan återställa den. Vi rekommenderar att du trampar försiktigt.",
    "Delete": "Radera",
    "Logout": "Logga ut",
    "my_profile": "Min profil",
    "guides_faqs": "Guider och vanliga frågor",
    "More": "Mer",
    "guides": "Guider",
    "register": "Registrera",
    "hot_faq": "Hot FAQ",
    "Contents": "Innehåll:",
    "contact_us": "Kontakta oss >>",
    "plan": "Planen",
    "unregistered": "Oregistrerad",
    "buy_more": "Köp mer",
    "buy_again": "Köp igen",
    "buy_now": "Köp nu",
    "free_no_limit": "Gratis & ingen gräns",
    "expired": "Utgånget",
    "lifetime": "Livstid",
    "remain": "Förbli",
    "day_s": "Dag(ar)",
    "error_24801": "Begäran misslyckades (felkod: 24801). Försök igen",
    "no_app_found": "Ingen app hittades!<a href=\"/\">Uppdatera</a> eller <a href=\"https://www.mindonmap.com/\">gå till den officiella webbplatsen</a>",
    "get_more": "Få mer >>",
    "edit_photo": "Redigera Foto",
    "select_photo": "Välj foto",
    "change_photo": "Förändring Foto",
    "cancel": "Annullera",
    "hide_password": "Dölj lösenord",
    "show_password": "Visa lösenord",
    "zoom_in": "Zooma in",
    "zoom_out": "Zooma ut",
    "rotate": "Rotera",
    "horizontal_flip": "Horisontell vändning",
    "vertical_flip": "Vertikal flip",
    "country": "Land",
    "country_1": "Välj ditt land/din region",
    "country_2": "Åaland",
    "country_3": "Afghanistan",
    "country_4": "Albanien",
    "country_5": "Algeriet",
    "country_6": "Amerikanska Samoa",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktis",
    "country_11": "Antigua och Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenien",
    "country_14": "Aruba",
    "country_15": "Australien",
    "country_16": "Österrike",
    "country_17": "Azerbajdzjan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarus",
    "country_22": "Belgien",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bolivia",
    "country_28": "Bosnien och Hercegovina",
    "country_29": "Botswana",
    "country_30": "Bouvet Island",
    "country_31": "Brasilien",
    "country_32": "Brittiska territoriet i Indiska oceanen",
    "country_33": "Brittiska Jungfruöarna",
    "country_34": "Brunei",
    "country_35": "Bulgarien",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodja",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Cap Verde",
    "country_42": "Karibiska Nederländerna",
    "country_43": "Caymanöarna",
    "country_44": "Centralafrikanska republiken",
    "country_45": "Tchad",
    "country_46": "Chile",
    "country_47": "Kina",
    "country_48": "Julön",
    "country_49": "Cocos (Keeling) öarna",
    "country_50": "Colombia",
    "country_51": "Cooköarna",
    "country_52": "Costa Rica",
    "country_53": "Elfenbenskusten",
    "country_54": "Kroatien",
    "country_55": "Kuba",
    "country_56": "Cypern",
    "country_57": "Tjeckien",
    "country_58": "Demokratiska republiken Kongo",
    "country_59": "Danmark",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Dominikanska republiken",
    "country_63": "Ecuador",
    "country_64": "Egypten",
    "country_65": "El Salvador",
    "country_66": "Ekvatorialguinea",
    "country_67": "Eritrea",
    "country_68": "Estland",
    "country_69": "Etiopien",
    "country_70": "Falklandsöarna",
    "country_71": "Färöarna",
    "country_72": "Förbundsstaterna i Mikronesien",
    "country_73": "Fiji",
    "country_74": "Finland",
    "country_75": "Frankrike",
    "country_76": "Franska Guyana",
    "country_77": "franska polynesien",
    "country_78": "Franska södra territorierna",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Georgien",
    "country_82": "Tyskland",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Storbritannien (Storbritannien, England)",
    "country_86": "Grekland",
    "country_87": "Grönland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Guinea",
    "country_94": "Guinea-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Island och McDonald Islands",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Ungern",
    "country_101": "Island",
    "country_102": "Indien",
    "country_103": "Indonesien",
    "country_104": "Iran",
    "country_105": "Irak",
    "country_106": "Irland",
    "country_107": "Ö av man",
    "country_108": "Israel",
    "country_109": "Italien",
    "country_110": "Jamaica",
    "country_111": "Japan",
    "country_112": "Jersey",
    "country_113": "Jordanien",
    "country_114": "Kazakstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuwait",
    "country_118": "Kirgizistan",
    "country_119": "Laos",
    "country_120": "Lettland",
    "country_121": "Libanon",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Libyen",
    "country_125": "Liechtenstein",
    "country_126": "Litauen",
    "country_127": "Luxemburg",
    "country_128": "Macao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldiverna",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshallöarna",
    "country_136": "Martinique",
    "country_137": "Mauretanien",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Mexiko",
    "country_141": "Moldavien",
    "country_142": "Monaco",
    "country_143": "mongoliet",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Marocko",
    "country_147": "Moçambique",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Nederländerna",
    "country_153": "Nya Kaledonien",
    "country_154": "Nya Zeeland",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Norfolk Island",
    "country_160": "Nordkorea",
    "country_161": "Norra Marianaöarna",
    "country_162": "Norge",
    "country_163": "oman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Palestinska territorier",
    "country_167": "Panama",
    "country_168": "Papua Nya Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn Islands",
    "country_172": "Polen",
    "country_173": "Portugal",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Republiken Makedonien (FYROM)",
    "country_177": "Republiken Kongo",
    "country_178": "Återförening",
    "country_179": "Rumänien",
    "country_180": "Ryska Federationen",
    "country_181": "Rwanda",
    "country_182": "Saint Barthélemy",
    "country_183": "Saint Martin (Frankrike)",
    "country_184": "Saint-Pierre och Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tomé & Principe",
    "country_188": "Saudiarabien",
    "country_189": "Senegal",
    "country_190": "Serbien",
    "country_191": "Seychellerna",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovakien",
    "country_195": "Slovenien",
    "country_196": "Salomonöarna",
    "country_197": "Somalia",
    "country_198": "Sydafrika",
    "country_199": "Södra Georgien och Södra Sandwich Isla",
    "country_200": "Sydkorea",
    "country_201": "Södra Sudan",
    "country_202": "Spanien",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena och beroenden",
    "country_205": "St. Kitts & Nevis",
    "country_206": "Sankta Lucia",
    "country_207": "St. Vincent och Grenadinerna",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Swaziland",
    "country_211": "Sverige",
    "country_212": "Schweiz",
    "country_213": "Syrien",
    "country_214": "Taiwan",
    "country_215": "Tadzjikistan",
    "country_216": "Tanzania",
    "country_217": "Mall:Landsdata SJM Svalbard",
    "country_218": "Thailand",
    "country_219": "Bahamas",
    "country_220": "Komorerna",
    "country_221": "Filippinerna",
    "country_222": "Östtimor (Östtimor)",
    "country_223": "Togo",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad och Tobago",
    "country_227": "Tunisien",
    "country_228": "Kalkon",
    "country_229": "Turkmenistan",
    "country_230": "Turks- och Caicosöarna",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Förenade arabemiraten",
    "country_235": "Förenta staternas mindre avlägsna öar",
    "country_236": "Amerikas förenta stater (USA)",
    "country_237": "Förenta staternas Jungfruöarna",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikanstaten (Påstolen)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis och Futuna",
    "country_245": "Västra Sahara",
    "country_246": "Jemen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Logga in med Google",
    "State": "stat",
    "Activation_code": "Aktiveringskod",
    "Question": "Lista alla appar du har loggat in",
    "Copy_complete": "Kopian klar",
    "footer": "Copyright © 2024 MindOnMap Studio. Alla rättigheter förbehållna.",
    "change_password_success": "lösenordet har ändrats",
    "successful_login_title": "Inloggning lyckad",
    "product_page": "Produktsida >>",
    "successful_login_info": "Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process. Den aktuella sidan stängs automatiskt om 5 sekunder. Om automatisk stängning eller stängning genom att klicka på knappen \"Klar\" misslyckas, stäng den här fliken direkt.",
    "successful_login_info_firefox": "Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process.",
    "my_account": "Mitt konto",
    "my_history": "Min historia",
    "remove_watermark": "Ta bort vattenstämpel",
    "no_history": "Ingen historia",
    "history_all": "Välj alla",
    "history_open": "Öppen",
    "history_down": "Ladda ner",
    "history_delete": "Radera",
    "history_clear": "Rensa ogiltig",
    "images": "bilder",
    "use_this_function": "Använd denna produkt >>",
    "hd_downloading": "Fördelar med att ladda ner HD-originalbilder:",
    "lifetimeRemaining": "Livstid kvar",
    "Remaining": "Återstående",
    "email_verification": "mejl verifikation",
    "email_verification_info": "Vi har skickat verifieringsmeddelandet till din e-post <span class=\"email\"></span> och avsluta verifieringen. Efter verifiering synkroniseras förmånerna automatiskt.",
    "wrong_email": "Fel e-postadress?",
    "click_here_to_modify": "Klicka här för att ändra",
    "get_more_help": "Få mer hjälp?",
    "click_here": "Klicka här",
    "email_verification_info_success": "Grattis för att du har verifierat ditt e-postkonto.",
    "email_verification_info_error": "Verifieringen misslyckades på grund av den utgångna länken.",
    "registration_succeeded": "Registreringen lyckades",
    "registration_succeeded_info_1": "Grattis! Din registrering lyckades. Vi har skickat verifieringsmeddelandet till din e-post <span class=\"email\"></span>, och slutför verifieringen för att använda fördelarna med detta e-postmeddelande.",
    "registration_succeeded_info_2": "Klicka på \"Klar\" för att gå tillbaka till startsidan och använda den här produkten.",
    "registration_succeeded_info_3": "Klicka på \"Klar\" för att stänga den aktuella sidan och återgå till startsidan för följande åtgärder. Om stängningen misslyckas, stäng fliken manuellt.",
    "verify_email": "Verifiera Email",
    "registered_email_not_verified": "Den registrerade e-postadressen har inte verifierats, vänligen verifiera den på en gång.",
    "email_verification_time_1": "Fick du inget verifieringsmail?",
    "email_verification_time_2": "Efter",
    "email_verification_time_3": "Klicka här för att skicka den igen",
    "error_26301": "Felkod: 26301, vänligen kontakta oss",
    "error_26302": "Felkod: 26302, vänligen kontakta oss",
    "error_26303": "E-postformatfel (felkod: 26303). Vänligen ange den igen",
    "error_26304": "Lösenord över 8 tecken rekommenderas (felkod: 26304)",
    "error_26305": "Reuqest misslyckades (felkod: 26305). Försök igen",
    "error_26306": "E-post har registrerats, vänligen <a href=\"https://account.mindonmap.com/login/\">gå till logga in</a>",
    "error_26307": "Reuqest misslyckades (felkod: 26307). Försök igen",
    "error_26308": "Reuqest misslyckades (felkod: 26308). Försök igen",
    "error_26401": "Felkod: 26401, försök igen",
    "error_26402": "E-posten har verifierats (felkod: 26402), försök igen",
    "error_26403": "Reuqest misslyckades (felkod: 26403). Försök igen",
    "error_26404": "Begäran misslyckades (felkod: 26404). Försök igen",
    "error_26501": "Felkod: 26501, vänligen kontakta oss",
    "error_26502": "Felkod: 26502, vänligen kontakta oss",
    "error_26503": "E-postformatfel (felkod: 26503). Vänligen ange den igen",
    "error_26504": "Reuqest misslyckades (felkod: 26504). Försök igen",
    "error_26505": "E-post har inte registrerats, vänligen <a href=\"https://account.mindonmap.com/register/\">registrera det först</a>",
    "error_26506": "E-post har verifierats.",
    "error_26507": "Reuqest misslyckades (felkod: 26507). Försök igen",
    "error_26508": "Verifieringen misslyckades (felkod: 26508), försök igen",
    "error_26509": "Begäran misslyckades (felkod: 26509), försök igen",
    "error_26510": "Felkod: 26510, vänligen kontakta oss",
    "error_26601": "Felkod: 26601, vänligen kontakta oss",
    "error_26602": "Felkod: 26602, vänligen kontakta oss",
    "error_26603": "Reuqest misslyckades (felkod: 26603). Försök igen",
    "error_26604": "Felkod: 26604, vänligen kontakta oss",
    "error_26605": "Felkod: 26605, vänligen kontakta oss",
    "error_26701": "Felkod: 26701, vänligen kontakta oss",
    "error_26702": "Reuqest misslyckades (felkod: 26702). Försök igen",
    "error_26703": "Felkod: 26703, vänligen kontakta oss",
    "error_26704": "Felkod: 26704, vänligen kontakta oss",
    "error_26705": "Vänta på att logga in (felkod: 26705). Försök igen",
    "no_cookie": "Du har aktiverat funktionen Blockera alla cookies i din webbläsare, så du kan inte logga in. Gå till Inställningar för att markera rutan Tillåt alla cookies.",
    "error_26801": "Felkod: 26801, vänligen kontakta oss",
    "error_26802": "Felkod: 26802, vänligen kontakta oss",
    "error_26803": "Begäran misslyckades (felkod: 26803). Försök igen",
    "error_26804": "Begäran misslyckades (felkod: 26804). Försök igen",
    "error_order": "Begäran misslyckades (felkod: 27098), försök igen!",
    "error_order1": "Beställningsfrågan är ofullständig (felkod: ",
    "error_order2": "）, vänligen uppdatera och försök igen.",
    "modify_email_title": "Ändra e-post",
    "modify_email_info": "Du kan använda den ändrade e-postadressen för att logga in på ditt konto.",
    "images_per": "Bilder pr",
    "error_26101": "Fel: 26101. Kontakta oss",
    "error_26102": "Fel: 26102. Kontakta oss",
    "error_26103": "Begäran misslyckades (felkod:26103). Vänligen försök igen",
    "error_26104": "Felkod: 26104, försök igen",
    "error_26105": "Felkod: 26105, försök igen",
    "error_26106": "Det gick inte att radera (felkod: 26106). Försök igen",
    "error_26201": "Fel: 26201. Kontakta oss",
    "error_26202": "Begäran misslyckades (felkod:26202). Vänligen försök igen",
    "error_26001": "Fel: 26001. Kontakta oss",
    "error_26002": "Fel: 26002. Kontakta oss",
    "error_26003": "Fel: 26003. Kontakta oss",
    "error_26004": "Fel: 26004. Kontakta oss",
    "error_26005": "Begäran misslyckades (felkod:26005). Vänligen försök igen",
    "error_26006": "Felkod: 26006, försök igen",
    "error_26008": "Fel: 26008. Kontakta oss",
    "go_to_the_home_page": "Gå till startsidan",
    "error_27101": "Begäran misslyckades (felkod: 27101). Vänligen försök igen",
    "error_27201": "Felkod: 27201, vänligen kontakta oss",
    "error_27202": "Felkod: 27202, försök igen",
    "error_27203": "Begäran misslyckades (felkod: 27203). Försök igen",
    "error_27204": "Ogiltig kod (felkod: 27204).",
    "error_27205": "Begäran misslyckades (felkod: 27205). Försök igen",
    "error_27206": "Begäran misslyckades (felkod: 27206). Försök igen",
    "error_27207": "Begäran misslyckades (felkod: 27207). Försök igen",
    "no_history_found": "Du har inte använt något verktyg! <a href=\"/\">Uppdatera</a> eller <a href=\"https://www.mindonmap.com/\">gå till den officiella webbplatsen</a>",
    "error_25301": "Fel: 25301. Kontakta oss",
    "error_25302": "Fel: 25302. Kontakta oss",
    "error_25303": "Begäran misslyckades (felkod: 25303). Försök igen",
    "error_25304": "Begäran misslyckades (felkod: 25304). Försök igen",
    "error_25305": "Begäran misslyckades (felkod: 25305). Försök igen",
    "error_25306": "Begäran misslyckades (felkod: 25306). Försök igen",
    "image_upscaler_p": "Fördelar med nedladdning av bild utan vattenstämpel:",
    "Available_for": "Tillgänglig för:",
    "credit_per": "%s kredit(er) per HD-bild",
    "still_valid": "Köpta plan(er) är fortfarande giltiga",
    "credit": "kredit(er)",
    "pc_3rd_info": "Logga in framgångsrikt. Gå till applikationen för vidare drift.",
    "use_online": "Använd onlinetjänsten",
    "use_download": "Använd skrivbordsprogrammet",
    "use_immediately": "Använd omedelbart",
    "Use_in_browser": "Använd i webbläsare",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "{%} krediter per månad",
    "expire": "Utgångstid:",
    "viewDetails": "Visa detaljer",
    "viewHistory": "Se historikfördelar >>",
    "viewDetailsInfo": "Observera: Om prenumerationsförmånerna förnyas inom 7 dagar efter utgången kan de outnyttjade förmånerna fortsätta att användas. Utgångstiden kommer också att uppdateras automatiskt till den nya prenumerationens förfallotid. Om det inte finns någon ny prenumeration efter 7 dagars utgångstid kommer alla utgångna förmåner att rensas.",
    "connect_account": "Bind e-post till ditt konto",
    "connect_account_info": "Efter bindning kan du logga in med denna e-postadress.",
    "connect_now": "Bind nu",
    "no_email_bind": "Ingen e-postbindning",
    "bind_email": "Bind e-post",
    "connect_your_email_placeholder": "Var god skriv in din e-postadress",
    "bind_an_email": "Bind ett e-postmeddelande",
    "bind_info": "Du har loggat in framgångsrikt. Vänligen bind ett e-postmeddelande till ditt konto för att aktivera dina förmåner.",
    "bind_later": "Bind senare",
    "hi": "Hej %s!",
    "Personal_Information": "Personlig information",
    "Access": "Tillgång",
    "Subscription_Plan": "(prenumerationsplan)",
    "No_orders": "Inga beställningar hittades.",
    "No_data": "Inga data",
    "Featured_Products": "Utvalda produkter",
    "More_Products": "Fler produkter",
    "Free_Download": "Gratis nedladdning",
    "Get_Started": "Komma igång",
    "Subscribe": "Prenumerera",
    "Verified": "Verifierad",
    "back_to_account_center": "Tillbaka till kontocenter",
    "success": "Framgång!",
    "successfully": "Du har framgångsrikt registrerat ett konto.",
    "Continue": "Fortsätta",
    "Already": "Har du redan ett konto?",
    "loading_verification": "Kontrollerar verifieringsstatus...",
    "email_no_verification": "Tyvärr, den registrerade e-postadressen har inte verifierats. Vänligen slutför verifieringen enligt instruktionerna ovan och klicka på knappen \"Klar\" igen för att slutföra registreringen.",
    "will_expire_after": "Upphör att gälla efter",
    "hours": "timmar",
  },
  "ta": {
    "overtime": "பிழைக் குறியீடு: {%}, மீண்டும் முயற்சிக்கவும்",
    "isnetwork": "இணைய பிழை. சரிபார்த்து மீண்டும் முயற்சிக்கவும்",
    "email_placeholder": "மின்னஞ்சல்",
    "email_empty": "மின்னஞ்சலை உள்ளிடவும்",
    "email_not_valid": "மின்னஞ்சல் செல்லுபடியாகாது",
    "email_not_valid_1": "தயவுசெய்து உங்கள் மின்னஞ்சலை உள்ளிடவும்",
    "email_not_valid_2": "மின்னஞ்சல் தவறானது, வேறு முகவரியைப் பயன்படுத்தவும்.",
    "email_not_valid_3": "மின்னஞ்சல் உள்ளீடு இல்லை",
    "password_placeholder": "கடவுச்சொல்",
    "password_empty": "கடவுச்சொல்லை உள்ளிடவும்",
    "password_not_valid": "தவறான கணக்கு அல்லது கடவுச்சொல்",
    "password_not_valid_1": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் தேவை",
    "password_not_valid_2": "கடவுச்சொல்லை உருவாக்கவும்",
    "password_placeholder_1": "உங்கள் கடவுச்சொல்லை உருவாக்கவும்",
    "password_placeholder_2": "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    "password_placeholder_3": "புதிய கடவுச்சொல்லை உருவாக்கவும்",
    "password_placeholder_4": "புதிய கடவு சொல்லை உறுதி செய்",
    "password_placeholder_5": "பழைய கடவுச்சொல்லை உள்ளிடவும்",
    "copy_password_empty": "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    "copy_password_not_valid": "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    "copy_passwords_inconsistent": "உங்கள் கடவுச்சொல் உறுதிப்படுத்தல் பொருந்தவில்லை",
    "code_empty": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்",
    "code_not_valid": "தவறான சரிபார்ப்புக் குறியீடு",
    "code_placeholder": "சரிபார்ப்பு குறியீடு",
    "not_received_code": "உங்கள் அஞ்சல் பெட்டி நீண்ட காலமாக சரிபார்ப்புக் குறியீட்டைப் பெறவில்லை என்றால், சரிபார்ப்புக் குறியீட்டை மீண்டும் பெறவும்.",
    "get_first_code": "முதலில் சரிபார்ப்புக் குறியீட்டைப் பெறவும்.",
    "last_name_placeholder": "உங்கள் கடைசி பெயரை உள்ளிடவும்",
    "first_name_placeholder": "உங்கள் முதல் பெயரை உள்ளிடவும்",
    "address_placeholder": "உங்கள் முகவரியை உள்ளிடவும்",
    "no_code_text": "சரிபார்ப்புக் குறியீட்டை அனுப்பியுள்ளோம். தயவுசெய்து உங்கள் குறியீட்டை உள்ளிடவும். <span class='tips'>ஒரு குறியீட்டைப் பெறவில்லையா?",
    "no_code_text_1": "1. மின்னஞ்சல் முகவரி சரியானதா என்பதையும் அது மின்னஞ்சல்களைப் பெறுவதையும் உறுதிசெய்யவும்.",
    "no_code_text_2": "2. கணினி தானாகவே மின்னஞ்சல் அனுப்பப்படுவதால், அது ஸ்பேம் அல்லது குப்பை மின்னஞ்சலாகக் கொடியிடப்படலாம். மின்னஞ்சல் குப்பை கோப்புறையில் உள்ளதா என சரிபார்க்கவும்.",
    "no_code_text_3": "3. உங்கள் பிரச்சனையை தீர்க்க முடியவில்லையா? ",
    "no_code_text_3_span": "பின்னர் எங்களை தொடர்பு கொள்ள இங்கே கிளிக் செய்யவும்.",
    "order_no": "நீங்கள் எந்த தயாரிப்பையும் வாங்கவில்லை, ஏதேனும் கேள்விகள் இருந்தால், தயவுசெய்து <a href=\"https://www.mindonmap.com/contact-us/\">எங்களைத் தொடர்பு கொள்ளவும்</a>.",
    "error_24901": "தற்போதைய கணக்கில் மின்னஞ்சல் எதுவும் இணைக்கப்படவில்லை, மேலும் ஆர்டர்களைக் கண்டறிய முடியவில்லை. மின்னஞ்சலை இணைக்கவும்.",
    "user_guide": "பயனர் வழிகாட்டி >>",
    "download": "பதிவிறக்க Tamil",
    "order_number": "ஆர்டர் எண்:",
    "Refund": "திரும்பப்பெறுதல்",
    "Disabled": "முடக்கப்பட்டது",
    "Normal": "இயல்பானது",
    "Modify": "மாற்றியமைக்கவும்",
    "Modify_1": "மாற்றவும்>>",
    "Connect": "இணைக்கவும்",
    "unlink_success": "இணைப்பை நீக்கவும்",
    "connect_success": "வெற்றிகரமாக இணைக்கவும்",
    "feedback_title": "உங்கள் கருத்துக்கு நன்றி. தயவு செய்து உங்கள் பிரச்சனையை விடுங்கள், 24 மணி நேரத்திற்குள் நாங்கள் உங்களுக்கு பதிலளிப்போம்.",
    "feedback_thank_you": "நன்றி!<br />உங்கள் கருத்து வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது.",
    "feedback_email": "உங்கள் மின்னஞ்சலை இங்கே உள்ளிடவும்!",
    "feedback_content": "நீங்கள் சந்தித்த ஏதேனும் பிரச்சனை அல்லது பரிந்துரையை இங்கே விடுங்கள்.",
    "feedback_submit": "சமர்ப்பிக்கவும்",
    "form_contents": "நீங்கள் எந்த விளக்கத்தையும் உள்ளிடவில்லை. தயவுசெய்து அதை உள்ளிட்டு மீண்டும் சமர்ப்பிக்கவும்.",
    "old_password": "பழைய கடவுச்சொல்லை உள்ளிடவும்",
    "new_password": "புதிய கடவுச்சொல்லை உருவாக்கவும்",
    "old_new_password": "புதிய கடவுச்சொல் பழையது போல் இருக்க முடியாது",
    "incorrect_password": "தவறான கடவுச்சொல்",
    "delete_no": "இப்போது நீக்கு",
    "Caps": "கேப்ஸ் லாக் ஆன் செய்யப்பட்டுள்ளது",
    "Get": "பெறு",
    "Done": "முடிந்தது",
    "error_20001": "பிழை: 20001. மீண்டும் உள்நுழையவும்.",
    "error_20002": "பிழை: 20002. மீண்டும் உள்நுழையவும்.",
    "error_20003": "பிழை: 20003. மீண்டும் உள்நுழையவும்.",
    "error_20004": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20004). மீண்டும் முயற்சிக்கவும்.",
    "error_20005": "உள்நுழைவு அமர்வு காலாவதியானது (பிழை: 20005). மீண்டும் உள்நுழையவும்.",
    "error_20006": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20006). மீண்டும் முயற்சிக்கவும்.",
    "error_20007": "உள்நுழைவு அமர்வு காலாவதியானது (பிழை: 20007). மீண்டும் உள்நுழையவும்.",
    "error_20008": "உள்நுழைவு அமர்வு காலாவதியானது (பிழை: 20008). மீண்டும் உள்நுழையவும்.",
    "error_20009": "உள்நுழைவு அமர்வு காலாவதியானது (பிழை: 20009). மீண்டும் உள்நுழையவும்.",
    "error_20101": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20101)",
    "error_20102": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20102)",
    "error_20103": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20103). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20104": "மின்னஞ்சல் ஏற்கனவே பயன்பாட்டில் உள்ளது, தயவுசெய்து <a href=\"https://account.mindonmap.com/login/\">உள்நுழைக</a> அல்லது புதிய ஒன்றில் பதிவு செய்யவும்",
    "error_20105": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20105). மீண்டும் முயற்சிக்கவும்",
    "error_20106": "மின்னஞ்சல் அனுப்ப முடியவில்லை, மீண்டும் முயற்சிக்கவும்",
    "error_20201": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20201)",
    "error_20202": "உங்கள் கடவுச்சொல்லை உள்ளிடவும் (பிழை குறியீடு: 20202)",
    "error_20203": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும் (பிழைக் குறியீடு: 20203)",
    "error_20204": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20204)",
    "error_20205": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் தேவை (பிழைக் குறியீடு: 20205)",
    "error_20206": "கோரிக்கை தோல்வியடைந்தது (பிழைக் குறியீடு: 20206). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20207": "தவறான சரிபார்ப்புக் குறியீடு",
    "error_20208": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20208). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20209": "கோரிக்கை தோல்வியடைந்தது (பிழைக் குறியீடு: 20209). மீண்டும் முயற்சிக்கவும்",
    "error_20301": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20301)",
    "error_20302": "பிழை: 20302. எங்களை தொடர்பு கொள்ளவும்",
    "error_20303": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20303)",
    "error_20304": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20304). மீண்டும் முயற்சிக்கவும்",
    "error_20305": "கணக்கு இல்லை. முதலில் மீண்டும் உள்ளிடவும் அல்லது <a href=\"https://account.mindonmap.com/register/\">அதை உருவாக்கவும்</a>.",
    "error_20306": "இதுவரை கடவுச்சொல் இல்லை. <a href=\"https://account.mindonmap.com/passwordless-login/\">கடவுச்சொல் இல்லாத உள்நுழைவு</a> அல்லது <a href=\"https://account.mindonmap.com/create-password/\"> ஐப் பயன்படுத்தவும் கடவுச்சொல்லை அமைத்து</a> உள்நுழையவும்.",
    "error_20308": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20308). மீண்டும் முயற்சிக்கவும்",
    "error_20401": "வெளியேற முடியவில்லை (பிழை குறியீடு: 20401). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20501": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20501)",
    "error_20502": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20502)",
    "error_20503": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20503). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20504": "மின்னஞ்சல் அனுப்ப முடியவில்லை. மீண்டும் முயற்சிக்கவும்.",
    "error_20601": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20601)",
    "error_20602": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும் (பிழைக் குறியீடு: 20602)",
    "error_20603": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20603)",
    "error_20604": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20604). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20606": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20606). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20607": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20607). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20608": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20608). மீண்டும் முயற்சிக்கவும்",
    "error_20701": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20701)",
    "error_20702": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20702)",
    "error_20703": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20703). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20704": "கணக்கு இல்லை. முதலில் மீண்டும் உள்ளிடவும் அல்லது <a href=\"https://account.mindonmap.com/register/\">அதை உருவாக்கவும்</a>.",
    "error_20705": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20705). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20706": "மின்னஞ்சல் அனுப்ப முடியவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20801": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 20801)",
    "error_20802": "பிழை: 20802. எங்களை தொடர்பு கொள்ளவும்",
    "error_20803": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும் (பிழைக் குறியீடு: 20803)",
    "error_20804": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 20804)",
    "error_20805": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் தேவை (பிழை குறியீடு: 20805)",
    "error_20806": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20806). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20808": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20808). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20901": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20901). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20902": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 20902). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21000": "மாற்றங்கள் சேமிக்கப்படும்",
    "error_21001": "எந்த தகவலும் சமர்ப்பிக்கப்படவில்லை (பிழை குறியீடு: 21001)",
    "error_21002": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21002). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21101": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 21101)",
    "error_21102": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 21102)",
    "error_21103": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21103), மீண்டும் முயற்சிக்கவும்",
    "error_21104": "மின்னஞ்சல் ஏற்கனவே இணைக்கப்பட்டுள்ளது, தயவுசெய்து புதிய ஒன்றைப் பயன்படுத்தவும்",
    "error_21105": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21105), மீண்டும் முயற்சிக்கவும்",
    "error_21106": "மின்னஞ்சல் அனுப்ப முடியவில்லை. மீண்டும் முயற்சிக்கவும்",
    "error_21201": "உங்கள் மின்னஞ்சலை உள்ளிடவும் (பிழை குறியீடு: 21201)",
    "error_21202": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும் (பிழைக் குறியீடு: 21202)",
    "error_21203": "மின்னஞ்சல் செல்லுபடியாகாது (பிழை குறியீடு: 21203)",
    "error_21204": "பிழை: 21204. எங்களை தொடர்பு கொள்ளவும்",
    "error_21205": "பிழை: 21205. எங்களை தொடர்பு கொள்ளவும்",
    "error_21206": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் தேவை (பிழைக் குறியீடு: 21206)",
    "error_21207": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21207). மீண்டும் முயற்சிக்கவும்",
    "error_21209": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21209). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21301": "பழைய கடவுச்சொல்லை உள்ளிடவும் (பிழை குறியீடு: 21301)",
    "error_21302": "புதிய கடவுச்சொல்லை உருவாக்கவும் (பிழை குறியீடு: 21302)",
    "error_21303": "புதிய கடவுச்சொல் பழையது போல் இருக்க முடியாது. (பிழை: 21303)",
    "error_21304": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் தேவை (பிழைக் குறியீடு: 21304)",
    "error_21306": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21306). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21402": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21402). மீண்டும் முயற்சிக்கவும்",
    "error_21403": "சரிபார்ப்புக் குறியீட்டை அனுப்ப முடியவில்லை. தயவுசெய்து அதை மீண்டும் அனுப்பவும்",
    "error_21500": "கணக்கு நீக்கப்பட்டது",
    "error_21501": "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும் (பிழைக் குறியீடு: 21501)",
    "error_21502": "உள்நுழைவு அமர்வு காலாவதியானது (பிழை: 21502). மீண்டும் உள்நுழையவும்.",
    "error_21503": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21503). மீண்டும் முயற்சிக்கவும்",
    "error_21505": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21505), மீண்டும் முயற்சிக்கவும்",
    "error_21601": "பிழை: 20601. எங்களை தொடர்பு கொள்ளவும்",
    "error_21602": "தவறான சரிபார்ப்பு (பிழை: 20602). மீண்டும் முயற்சிக்கவும்.",
    "error_21603": "பிழை: 20603. மீண்டும் முயற்சிக்கவும்",
    "error_21604": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21604). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21606": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21606). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21611": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21611). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21801": "பிழை: 21801. எங்களை தொடர்பு கொள்ளவும்",
    "error_21802": "கோரிக்கை தோல்வியடைந்தது (பிழை: 21802). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21803": "பிழை: 21803. மீண்டும் முயற்சிக்கவும்",
    "error_21804": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21804). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21806": "பிழை: 21806. மீண்டும் முயற்சிக்கவும்",
    "error_21807": "பிழை: 21807. எங்களை தொடர்பு கொள்ளவும்",
    "error_21808": "பிழை: 21808. எங்களை தொடர்பு கொள்ளவும்",
    "error_21809": "பிழை: 21809. எங்களை தொடர்பு கொள்ளவும்",
    "error_21810": "பிழை: 21810. எங்களை தொடர்பு கொள்ளவும்",
    "error_21811": "பிழை: 21811. எங்களை தொடர்பு கொள்ளவும்",
    "error_21812": "பிழை: 21812. எங்களை தொடர்பு கொள்ளவும்",
    "error_21813": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21813). மீண்டும் முயற்சிக்கவும்",
    "error_21814": "பிழை: 21814. எங்களை தொடர்பு கொள்ளவும்",
    "error_21815": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21815). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21816": "பிழை: 21816. எங்களை தொடர்பு கொள்ளவும்",
    "error_21817": "பிழை: 21817. எங்களை தொடர்பு கொள்ளவும்",
    "error_21818": "பிழை: 21818. எங்களை தொடர்பு கொள்ளவும்",
    "error_21819": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21819). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21820": "பிழை: 21820. எங்களை தொடர்பு கொள்ளவும்",
    "error_21821": "பிழை: 21821. எங்களை தொடர்பு கொள்ளவும்",
    "error_21822": "பிழை: 21822. எங்களை தொடர்பு கொள்ளவும்",
    "error_21823": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21823). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21824": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21824). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21825": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21825). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21826": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21826). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21901": "பிழை: 21901. எங்களை தொடர்பு கொள்ளவும்",
    "error_21902": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21902). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_21903": "கணக்கின் நிலை மாறிவிட்டது (பிழைக் குறியீடு: 21903), பக்கத்தைப் புதுப்பித்து மீண்டும் முயற்சிக்கவும்",
    "error_21904": "பிழை: 21904. மீண்டும் முயற்சிக்கவும்",
    "error_21905": "பிழை: 21905. மீண்டும் முயற்சிக்கவும்",
    "error_21906": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21906). மீண்டும் முயற்சிக்கவும்",
    "error_21907": "Google கணக்கு மற்ற கணக்குடன் இணைக்கப்பட்டுள்ளது",
    "error_21908": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 21908). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22001": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22001). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22002": "கூடுதல் உள்நுழைவு இல்லாததால் இணைப்பை நீக்க முடியவில்லை",
    "error_22003": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22003). மீண்டும் முயற்சிக்கவும்",
    "error_22101": "பிழை: 22101. எங்களை தொடர்பு கொள்ளவும்",
    "error_22102": "கணக்கு நிலை மாறிவிட்டது (பிழைக் குறியீடு: 22102), பக்கத்தைப் புதுப்பித்து மீண்டும் முயற்சிக்கவும்",
    "error_22103": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22103). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22104": "கணக்கு நிலை மாறிவிட்டது (பிழைக் குறியீடு: 22104), பக்கத்தைப் புதுப்பித்து மீண்டும் முயற்சிக்கவும்",
    "error_22105": "பிழை: 22105. மீண்டும் முயற்சிக்கவும்",
    "error_22106": "பிழை: 22106. மீண்டும் முயற்சிக்கவும்",
    "error_22107": "பிழை: 22107. எங்களை தொடர்பு கொள்ளவும்",
    "error_22108": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22108). மீண்டும் முயற்சிக்கவும்",
    "error_22201": "பிழை: 22201. எங்களை தொடர்பு கொள்ளவும்",
    "error_22202": "தவறான சரிபார்ப்பு (பிழை: 22202). மீண்டும் முயற்சிக்கவும்.",
    "error_22203": "பிழை: 22203. மீண்டும் முயற்சிக்கவும்\"",
    "error_22204": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22204). மீண்டும் முயற்சிக்கவும்",
    "error_22206": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22206). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22401": "பிழை: 22401. எங்களை தொடர்பு கொள்ளவும்",
    "error_22402": "தவறான சரிபார்ப்பு (பிழை: 22402). மீண்டும் முயற்சிக்கவும்.",
    "error_22403": "பிழை: 22403. மீண்டும் முயற்சிக்கவும்",
    "error_22404": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22404). மீண்டும் முயற்சிக்கவும்",
    "error_22405": "Facebook கணக்கு மற்ற மின்னஞ்சலுடன் இணைக்கப்பட்டுள்ளது",
    "error_22406": "பிழை: 22406. மீண்டும் முயற்சிக்கவும்",
    "error_22407": "பிழை: 22407. எங்களை தொடர்பு கொள்ளவும்",
    "error_22408": "பிழை: 22408. எங்களை தொடர்பு கொள்ளவும்",
    "error_22409": "பிழை: 22409. எங்களை தொடர்பு கொள்ளவும்",
    "error_22410": "பிழை: 224010. எங்களை தொடர்பு கொள்ளவும்",
    "error_22411": "பிழை: 224011. எங்களை தொடர்பு கொள்ளவும்",
    "error_22412": "பிழை: 224012. எங்களை தொடர்பு கொள்ளவும்",
    "error_22413": "பிழை: 22413. எங்களை தொடர்பு கொள்ளவும்",
    "error_22414": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22414). மீண்டும் முயற்சிக்கவும்",
    "error_22415": "பிழை: 22415. எங்களை தொடர்பு கொள்ளவும்",
    "error_22416": "பிழை: 22416. எங்களை தொடர்பு கொள்ளவும்",
    "error_22417": "பிழை: 22417. எங்களை தொடர்பு கொள்ளவும்",
    "error_22418": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22418). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22419": "பிழை: 22419. எங்களை தொடர்பு கொள்ளவும்",
    "error_22420": "பிழை: 22420. எங்களை தொடர்பு கொள்ளவும்",
    "error_22421": "பிழை: 22421. எங்களை தொடர்பு கொள்ளவும்",
    "error_22422": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22422). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22423": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22423). மீண்டும் முயற்சிக்கவும்",
    "error_22424": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22424). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_22425": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 22425). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_20098": "பிழைக் குறியீடு: 20098. நீங்கள் தனிப்பட்ட பயன்முறையில் உலாவுகிறீர்கள் என்றால், தயவுசெய்து இயல்பான பயன்முறைக்கு மாறி மீண்டும் முயற்சிக்கவும்.",
    "error_22298": "Googleக்கான கோரிக்கை தோல்வியடைந்தது (பிழை: 22298). மீண்டும் முயற்சிக்கவும்.",
    "error_22498": "Facebookக்கான கோரிக்கை தோல்வியடைந்தது (பிழை: 22498). மீண்டும் முயற்சிக்கவும்.",
    "error_24902": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 24902). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_24903": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 24903). மீண்டும் முயற்சிக்கவும்",
    "error_24904": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 24904). மீண்டும் முயற்சிக்கவும்",
    "error_24905": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 24905). மீண்டும் முயற்சிக்கவும்",
    "login_title": "MindOnMap இல் உள்நுழைக",
    "log_in": "உள்நுழைய",
    "no_account": "கணக்கு இல்லையா?",
    "create_it": "அதை உருவாக்கவும்",
    "or_log_in_with": "அல்லது உடன் உள்நுழையவும்",
    "passwordless_login": "கடவுச்சொல்ல இல்லா",
    "log_in_done": "உள்நுழைவு முடிந்தது",
    "three_rd_account_connect_info": "வாழ்த்துகள்! நீங்கள் வெற்றிகரமாக உள்நுழைந்துள்ளீர்கள். எதிர்காலத்தில் உள்நுழைவதற்குப் பயன்படுத்தப்படும் ஒரு மின்னஞ்சல் கணக்கை இப்போது நீங்கள் இணைக்கலாம்.",
    "see_my_account": "எனது கணக்கைப் பார்க்கவும்",
    "three_rd_login_merge_account": "மூன்றாம் தரப்பு கணக்கு மின்னஞ்சல் முகவரி பதிவு செய்யப்பட்டுள்ளது, இந்த மின்னஞ்சல் முகவரியுடன் நேரடியாக இணைத்து உள்நுழைய விரும்புகிறீர்களா?",
    "connect_log_in": "இணைத்து உள்நுழைக",
    "create_an_account": "ஒரு கணக்கை உருவாக்க",
    "back_to_log_in": "மீண்டும் உள்நுழைய",
    "create_password": "கடவுச்சொல்லை உருவாக்கு",
    "create_now": "இப்போது உருவாக்கவும்",
    "password_login_subtitle": "மின்னஞ்சல் மூலம் கடவுச்சொல் இல்லாத உள்நுழைவு",
    "account_login": "கணக்கு புகுபதிகை",
    "rights": "இந்தக் கணக்கை உருவாக்குவதன் மூலம், <a href=\"https://www.mindonmap.com/terms-and-conditions/\">சேவை விதிமுறைகள்</a> மற்றும் <a href=\"https://www.mindonmap.com/privacy-policy/\">தனியுரிமைக் கொள்கை</a>",
    "passwordless_login_done": "கடவுச்சொல் இல்லாத உள்நுழைவு முடிந்தது",
    "passwordless_login_info": "வாழ்த்துக்கள், கடவுச்சொல் இல்லாத உள்நுழைவை வெற்றிகரமாக முடித்துவிட்டீர்கள். இந்தக் கணக்கிற்கான கடவுச்சொல்லை உருவாக்கி, எதிர்காலத்தில் கணக்கு மற்றும் கடவுச்சொல்லைக் கொண்டு உள்நுழையலாம். <a href=\"/create-password\" style=\"display: initial;\">இப்போது உருவாக்கு</a>",
    "sign_up": "பதிவு செய்யவும்",
    "register_info": "உங்கள் கணக்கை உருவாக்கவும்",
    "reset_now": "இப்போது மீட்டமைக்கவும்",
    "forgot_password": "கடவுச்சொல்லை மறந்துவிட்டீர்களா",
    "reset_password_subtitle": "கடவுச்சொல்லை மீட்டமைக்க உங்கள் கணக்கு மின்னஞ்சலைப் பயன்படுத்தவும்",
    "plan_products": "திட்டங்கள் & தயாரிப்புகள்",
    "nick_name": "பயனர் பெயர்:",
    "email": "மின்னஞ்சல்:",
    "my_products": "எனது தயாரிப்புகள்",
    "my_orders": "என் கட்டளைகள்",
    "unlink": "இணைப்பை நீக்கவும்",
    "link": "இணைப்பு",
    "connected_accounts": "இணைக்கப்பட்ட கணக்குகள்",
    "last_name": "கடைசி பெயர்:",
    "first_name": "முதல் பெயர்:",
    "Gender": "பாலினம்:",
    "Birth": "பிறப்பு:",
    "Month": "மாதம்",
    "Year": "ஆண்டு",
    "Country_Region": "நாடு/பிராந்தியம்:",
    "Address": "முகவரி:",
    "Save": "சேமிக்கவும்",
    "Date": "தேதி",
    "Male": "ஆண்",
    "Female": "பெண்",
    "Unspecified": "குறிப்பிடப்படாதது",
    "Security": "பாதுகாப்பு",
    "change_password": "கடவுச்சொல்லை மாற்று",
    "change_now": "இப்போது மாற்றவும்",
    "connect_email": "மின்னஞ்சலை இணைக்கவும்",
    "delete_account": "கணக்கை நீக்குக",
    "delete_account_info": "உங்கள் கணக்கு நீக்கப்பட்டால், உங்கள் கணக்குடன் தொடர்புடைய எனது கணக்கில் உள்ள எல்லா தரவும் நிரந்தரமாக நீக்கப்படும், மேலும் உங்களால் அதை மீட்டெடுக்க முடியாமல் போகலாம். நீங்கள் எச்சரிக்கையுடன் நடக்க பரிந்துரைக்கிறோம்.",
    "Delete": "அழி",
    "Logout": "வெளியேறு",
    "my_profile": "என் சுயவிவரம்",
    "guides_faqs": "வழிகாட்டிகள் & அடிக்கடி கேட்கப்படும் கேள்விகள்",
    "More": "மேலும்",
    "guides": "வழிகாட்டிகள்",
    "register": "பதிவு",
    "hot_faq": "சூடான கேள்விகள்",
    "Contents": "உள்ளடக்கம்:",
    "contact_us": "எங்களை தொடர்பு கொள்ளவும் >>",
    "plan": "திட்டம்",
    "unregistered": "பதிவு செய்யப்படாதது",
    "buy_more": "மேலும் வாங்கவும்",
    "buy_again": "மீண்டும் வாங்க",
    "buy_now": "இப்போது வாங்கவும்",
    "free_no_limit": "இலவசம் & வரம்பு இல்லை",
    "expired": "காலாவதியான",
    "lifetime": "வாழ்நாள்",
    "remain": "எஞ்சியிரு",
    "day_s": "நாட்களில்)",
    "error_24801": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 24801). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "no_app_found": "பயன்பாடு எதுவும் இல்லை!<a href=\"/\">புதுப்பிக்கவும்</a> அல்லது <a href=\"https://www.mindonmap.com/\">அதிகாரப்பூர்வ இணையதளத்திற்குச் செல்லவும்</a>",
    "get_more": "மேலும் பெறவும் >>",
    "edit_photo": "புகைப்படத்தைத் திருத்து",
    "select_photo": "புகைப்படத்தைத் தேர்ந்தெடுக்கவும்",
    "change_photo": "புகைப்படத்தை மாற்று",
    "cancel": "ரத்து செய்",
    "hide_password": "கடவுச்சொல்லை மறை",
    "show_password": "கடவுச்சொல்லை காட்டவும்",
    "zoom_in": "பெரிதாக்க",
    "zoom_out": "பெரிதாக்கவும்",
    "rotate": "சுழற்று",
    "horizontal_flip": "கிடைமட்ட புரட்டு",
    "vertical_flip": "செங்குத்து புரட்டு",
    "country": "நாடு",
    "country_1": "உங்கள் நாடு/பிராந்தியத்தைத் தேர்ந்தெடுங்கள்",
    "country_2": "ஆலண்ட் தீவு",
    "country_3": "ஆப்கானிஸ்தான்",
    "country_4": "அல்பேனியா",
    "country_5": "அல்ஜீரியா",
    "country_6": "அமெரிக்க சமோவா",
    "country_7": "அன்டோரா",
    "country_8": "அங்கோலா",
    "country_9": "அங்குவிலா",
    "country_10": "அண்டார்டிகா",
    "country_11": "ஆன்டிகுவா & பார்புடா",
    "country_12": "அர்ஜென்டினா",
    "country_13": "ஆர்மீனியா",
    "country_14": "அருபா",
    "country_15": "ஆஸ்திரேலியா",
    "country_16": "ஆஸ்திரியா",
    "country_17": "அஜர்பைஜான்",
    "country_18": "பஹ்ரைன்",
    "country_19": "பங்களாதேஷ்",
    "country_20": "பார்படாஸ்",
    "country_21": "பெலாரஸ்",
    "country_22": "பெல்ஜியம்",
    "country_23": "பெலிஸ்",
    "country_24": "பெனின்",
    "country_25": "பெர்முடா",
    "country_26": "பூட்டான்",
    "country_27": "பொலிவியா",
    "country_28": "போஸ்னியா & ஹெர்ஸகோவினா",
    "country_29": "போட்ஸ்வானா",
    "country_30": "Bouvet தீவு",
    "country_31": "பிரேசில்",
    "country_32": "பிரிட்டிஷ் இந்திய பெருங்கடல் பிரதேசம்",
    "country_33": "பிரிட்டிஷ் விர்ஜின் தீவுகள்",
    "country_34": "புருனே",
    "country_35": "பல்கேரியா",
    "country_36": "புர்கினா",
    "country_37": "புருண்டி",
    "country_38": "கம்போடியா",
    "country_39": "கேமரூன்",
    "country_40": "கனடா",
    "country_41": "கேப் வெர்டே",
    "country_42": "கரீபியன் நெதர்லாந்து",
    "country_43": "கெய்மன் தீவுகள்",
    "country_44": "மத்திய ஆப்பிரிக்க குடியரசு",
    "country_45": "சாட்",
    "country_46": "சிலி",
    "country_47": "சீனா",
    "country_48": "கிறிஸ்துமஸ் தீவு",
    "country_49": "கோகோஸ் (கீலிங்) தீவுகள்",
    "country_50": "கொலம்பியா",
    "country_51": "குக் தீவுகள்",
    "country_52": "கோஸ்ட்டா ரிக்கா",
    "country_53": "கோட் டி 'ஐவோரி",
    "country_54": "குரோஷியா",
    "country_55": "கியூபா",
    "country_56": "சைப்ரஸ்",
    "country_57": "செ குடியரசு",
    "country_58": "காங்கோ ஜனநாயக குடியரசு",
    "country_59": "டென்மார்க்",
    "country_60": "ஜிபூட்டி",
    "country_61": "டொமினிகா",
    "country_62": "டொமினிக்கன் குடியரசு",
    "country_63": "ஈக்வடார்",
    "country_64": "எகிப்து",
    "country_65": "எல் சல்வடோர்",
    "country_66": "ஈக்வடோரியல் கினியா",
    "country_67": "எரித்திரியா",
    "country_68": "எஸ்டோனியா",
    "country_69": "எத்தியோப்பியா",
    "country_70": "பால்க்லாந்து தீவுகள்",
    "country_71": "ஃபாரோ தீவுகள்",
    "country_72": "ஃபெடரேட் ஸ்டேட்ஸ் ஆஃப் மைக்ரோனேசியா",
    "country_73": "பிஜி",
    "country_74": "பின்லாந்து",
    "country_75": "பிரான்ஸ்",
    "country_76": "பிரஞ்சு கயானா",
    "country_77": "பிரெஞ்சு பாலினேசியா",
    "country_78": "பிரெஞ்சு தெற்கு பிரதேசங்கள்",
    "country_79": "காபோன்",
    "country_80": "காம்பியா",
    "country_81": "ஜார்ஜியா",
    "country_82": "ஜெர்மனி",
    "country_83": "கானா",
    "country_84": "ஜிப்ரால்டர்",
    "country_85": "கிரேட் பிரிட்டன் (ஐக்கிய ராஜ்யம்; இங்கிலாந்து)",
    "country_86": "கிரீஸ்",
    "country_87": "கிரீன்லாந்து",
    "country_88": "கிரெனடா",
    "country_89": "குவாடலூப்",
    "country_90": "குவாம்",
    "country_91": "குவாத்தமாலா",
    "country_92": "குர்ன்சி",
    "country_93": "கினியா",
    "country_94": "கினியா-பிசாவ்",
    "country_95": "கயானா",
    "country_96": "ஹைட்டி",
    "country_97": "ஹார்ட் தீவு மற்றும் மெக்டொனால்ட் தீவுகள்",
    "country_98": "ஹோண்டுராஸ்",
    "country_99": "ஹாங்காங்",
    "country_100": "ஹங்கேரி",
    "country_101": "ஐஸ்லாந்து",
    "country_102": "இந்தியா",
    "country_103": "இந்தோனேசியா",
    "country_104": "ஈரான்",
    "country_105": "ஈராக்",
    "country_106": "அயர்லாந்து",
    "country_107": "ஐல் ஆஃப் மேன்",
    "country_108": "இஸ்ரேல்",
    "country_109": "இத்தாலி",
    "country_110": "ஜமைக்கா",
    "country_111": "ஜப்பான்",
    "country_112": "ஜெர்சி",
    "country_113": "ஜோர்டான்",
    "country_114": "கஜகஸ்தான்",
    "country_115": "கென்யா",
    "country_116": "கிரிபதி",
    "country_117": "குவைத்",
    "country_118": "கிர்கிஸ்தான்",
    "country_119": "லாவோஸ்",
    "country_120": "லாட்வியா",
    "country_121": "லெபனான்",
    "country_122": "லெசோதோ",
    "country_123": "லைபீரியா",
    "country_124": "லிபியா",
    "country_125": "லிச்சென்ஸ்டீன்",
    "country_126": "லிதுவேனியா",
    "country_127": "லக்சம்பர்க்",
    "country_128": "மக்காவ்",
    "country_129": "மடகாஸ்கர்",
    "country_130": "மலாவி",
    "country_131": "மலேசியா",
    "country_132": "மாலத்தீவுகள்",
    "country_133": "மாலி",
    "country_134": "மால்டா",
    "country_135": "மார்ஷல் தீவுகள்",
    "country_136": "மார்டினிக்",
    "country_137": "மொரிட்டானியா",
    "country_138": "மொரிஷியஸ்",
    "country_139": "மயோட்டி",
    "country_140": "மெக்சிகோ",
    "country_141": "மால்டோவா",
    "country_142": "மொனாக்கோ",
    "country_143": "மங்கோலியா",
    "country_144": "மாண்டினீக்ரோ",
    "country_145": "மாண்ட்செராட்",
    "country_146": "மொராக்கோ",
    "country_147": "மொசாம்பிக்",
    "country_148": "மியான்மர் (பர்மா)",
    "country_149": "நமீபியா",
    "country_150": "நவ்ரு",
    "country_151": "நேபாளம்",
    "country_152": "நெதர்லாந்து",
    "country_153": "புதிய கலிடோனியா",
    "country_154": "நியூசிலாந்து",
    "country_155": "நிகரகுவா",
    "country_156": "நைஜர்",
    "country_157": "நைஜீரியா",
    "country_158": "நியு",
    "country_159": "நார்போக் தீவு",
    "country_160": "வட கொரியா",
    "country_161": "வடக்கு மரியானா தீவுகள்",
    "country_162": "நார்வே",
    "country_163": "ஓமன்",
    "country_164": "பாகிஸ்தான்",
    "country_165": "பலாவ்",
    "country_166": "பாலஸ்தீனிய பிரதேசங்கள்",
    "country_167": "பனாமா",
    "country_168": "பப்புவா நியூ கினி",
    "country_169": "பராகுவே",
    "country_170": "பெரு",
    "country_171": "பிட்காயின் தீவுகள்",
    "country_172": "போலந்து",
    "country_173": "போர்ச்சுகல்",
    "country_174": "போர்டோ ரிக்கோ",
    "country_175": "கத்தார்",
    "country_176": "மாசிடோனியா குடியரசு (FYROM)",
    "country_177": "காங்கோ குடியரசு",
    "country_178": "ரீயூனியன்",
    "country_179": "ருமேனியா",
    "country_180": "இரஷ்ய கூட்டமைப்பு",
    "country_181": "ருவாண்டா",
    "country_182": "செயின்ட் பார்தெலெமி",
    "country_183": "செயின்ட் மார்ட்டின் (பிரான்ஸ்)",
    "country_184": "செயிண்ட்-பியர் மற்றும் மிக்குலோன்",
    "country_185": "சமோவா",
    "country_186": "சான் மரினோ",
    "country_187": "சாவோ டோம் & பிரின்சிபி",
    "country_188": "சவூதி அரேபியா",
    "country_189": "செனகல்",
    "country_190": "செர்பியா",
    "country_191": "சீஷெல்ஸ்",
    "country_192": "சியரா லியோன்",
    "country_193": "சிங்கப்பூர்",
    "country_194": "ஸ்லோவாக்கியா",
    "country_195": "ஸ்லோவேனியா",
    "country_196": "சாலமன் தீவுகள்",
    "country_197": "சோமாலியா",
    "country_198": "தென் ஆப்ரிக்கா",
    "country_199": "தெற்கு ஜார்ஜியா மற்றும் தெற்கு சாண்ட்விச் இஸ்லா",
    "country_200": "தென் கொரியா",
    "country_201": "தெற்கு சூடான்",
    "country_202": "ஸ்பெயின்",
    "country_203": "இலங்கை",
    "country_204": "செயின்ட் ஹெலினா & சார்புகள்",
    "country_205": "செயின்ட் கிட்ஸ் & நெவிஸ்",
    "country_206": "செயின்ட் லூசியா",
    "country_207": "செயின்ட் வின்சென்ட் & கிரெனடைன்ஸ்",
    "country_208": "சூடான்",
    "country_209": "சுரினாம்",
    "country_210": "சுவாசிலாந்து",
    "country_211": "ஸ்வீடன்",
    "country_212": "சுவிட்சர்லாந்து",
    "country_213": "சிரியா",
    "country_214": "தைவான்",
    "country_215": "தஜிகிஸ்தான்",
    "country_216": "தான்சானியா",
    "country_217": "டெம்ப்ளேட்:Country data SJM Svalbard",
    "country_218": "தாய்லாந்து",
    "country_219": "பஹாமாஸ்",
    "country_220": "கொமரோஸ்",
    "country_221": "பிலிப்பைன்ஸ்",
    "country_222": "திமோர்-லெஸ்டே (கிழக்கு திமோர்)",
    "country_223": "போவதற்கு",
    "country_224": "டோகேலாவ்",
    "country_225": "டோங்கா",
    "country_226": "டிரினிடாட் & டொபாகோ",
    "country_227": "துனிசியா",
    "country_228": "துருக்கி",
    "country_229": "துர்க்மெனிஸ்தான்",
    "country_230": "டர்க்ஸ் & கெய்கோஸ் தீவுகள்",
    "country_231": "துவாலு",
    "country_232": "உகாண்டா",
    "country_233": "உக்ரைன்",
    "country_234": "ஐக்கிய அரபு நாடுகள்",
    "country_235": "யுனைடெட் ஸ்டேட்ஸ் மைனர் அவுட்லையிங் தீவுகள்",
    "country_236": "அமெரிக்கா       (அமெரிக்கா)",
    "country_237": "யுனைடெட் ஸ்டேட்ஸ் விர்ஜின் தீவுகள்",
    "country_238": "உருகுவே",
    "country_239": "உஸ்பெகிஸ்தான்",
    "country_240": "வனுவாடு",
    "country_241": "வத்திக்கான் நகரம் (பரிசுத்த பார்க்க)",
    "country_242": "வெனிசுலா",
    "country_243": "வியட்நாம்",
    "country_244": "வாலிஸ் மற்றும் ஃபுடுனா",
    "country_245": "மேற்கு சாஹாரா",
    "country_246": "ஏமன்",
    "country_247": "ஜாம்பியா",
    "country_248": "ஜிம்பாப்வே",
    "google_login": "Google மூலம் உள்நுழையவும்",
    "State": "நிலை",
    "Activation_code": "செயல்படுத்தும் குறியீடு",
    "Question": "நீங்கள் உள்நுழைந்த அனைத்து பயன்பாடுகளையும் பட்டியலிடுங்கள்",
    "Copy_complete": "நகல் முடிந்தது",
    "footer": "பதிப்புரிமை © 2024 MindOnMap Studio. அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை.",
    "change_password_success": "கடவுச்சொல் வெற்றிகரமாக மாற்றப்பட்டது",
    "successful_login_title": "உள்நுழைவு வெற்றி",
    "product_page": "தயாரிப்பு பக்கம்>>",
    "successful_login_info": "உள்நுழைவு முடிந்தது. பின்வரும் செயல்முறையைத் தொடர தற்போதைய பக்கத்தை மூடிவிட்டு அசல் தாவலுக்குச் செல்லவும். தற்போதைய பக்கம் 5 வினாடிகளில் தானாகவே மூடப்படும். \"முடிந்தது\" பொத்தானைக் கிளிக் செய்வதன் மூலம் தானாக மூடுவது அல்லது மூடுவது தோல்வியடைந்தால், இந்தத் தாவலை நேரடியாக மூடவும்.",
    "successful_login_info_firefox": "உள்நுழைவு முடிந்தது. பின்வரும் செயல்முறையைத் தொடர தற்போதைய பக்கத்தை மூடிவிட்டு அசல் தாவலுக்குச் செல்லவும்.",
    "my_account": "என் கணக்கு",
    "my_history": "என் வரலாறு",
    "remove_watermark": "வாட்டர்மார்க் அகற்றவும்",
    "no_history": "வரலாறு இல்லை",
    "history_all": "அனைத்தையும் தெரிவுசெய்",
    "history_open": "திற",
    "history_down": "பதிவிறக்க Tamil",
    "history_delete": "அழி",
    "history_clear": "செல்லாததை அழிக்கவும்",
    "images": "படம்(கள்)",
    "use_this_function": "இந்த தயாரிப்பைப் பயன்படுத்தவும்>>",
    "hd_downloading": "HD அசல் படங்களைப் பதிவிறக்குவதன் நன்மைகள்:",
    "lifetimeRemaining": "வாழ்நாள்-எஞ்சியிருக்கும்",
    "Remaining": "மீதமுள்ளவை",
    "email_verification": "மின்னஞ்சல் சரிபார்ப்பு",
    "email_verification_info": "சரிபார்ப்புச் செய்தியை உங்கள் மின்னஞ்சலுக்கு <span class=\"email\"></span> அனுப்பியுள்ளோம், சரிபார்ப்பை முடிக்கவும். சரிபார்த்த பிறகு, நன்மைகள் தானாகவே ஒத்திசைக்கப்படும்.",
    "wrong_email": "தவறான மின்னஞ்சல் முகவரியா?",
    "click_here_to_modify": "மாற்ற இங்கே கிளிக் செய்யவும்",
    "get_more_help": "மேலும் உதவி பெறவா?",
    "click_here": "இங்கே கிளிக் செய்யவும்",
    "email_verification_info_success": "உங்கள் மின்னஞ்சல் கணக்கைச் சரிபார்த்ததற்கு வாழ்த்துகள்.",
    "email_verification_info_error": "காலாவதியான இணைப்பு காரணமாக சரிபார்ப்பு தோல்வியடைந்தது.",
    "registration_succeeded": "பதிவு வெற்றியடைந்தது",
    "registration_succeeded_info_1": "வாழ்த்துகள்! நீங்கள் வெற்றிகரமாக பதிவு செய்துள்ளீர்கள். சரிபார்ப்புச் செய்தியை உங்கள் மின்னஞ்சலுக்கு <span class=\"email\"></span> அனுப்பியுள்ளோம், மேலும் இந்த மின்னஞ்சலின் பலன்களைப் பயன்படுத்த சரிபார்ப்பதை முடிக்கவும்.",
    "registration_succeeded_info_2": "முகப்புப் பக்கத்திற்குச் சென்று இந்தத் தயாரிப்பைப் பயன்படுத்த \"முடிந்தது\" என்பதைக் கிளிக் செய்யவும்.",
    "registration_succeeded_info_3": "தற்போதைய பக்கத்தை மூட \"முடிந்தது\" என்பதைக் கிளிக் செய்து, பின்வரும் செயல்பாடுகளுக்கு முகப்புப் பக்கத்திற்குத் திரும்பவும். மூடுவது தோல்வியுற்றால், தாவலை கைமுறையாக மூடவும்.",
    "verify_email": "மின்னஞ்சலை உறுதிசெய்யுங்கள்",
    "registered_email_not_verified": "பதிவு செய்யப்பட்ட மின்னஞ்சல் சரிபார்க்கப்படவில்லை, தயவுசெய்து அதை ஒருமுறை சரிபார்க்கவும்.",
    "email_verification_time_1": "சரிபார்ப்பு மின்னஞ்சலைப் பெறவில்லையா?",
    "email_verification_time_2": "பிறகு",
    "email_verification_time_3": "அதை மீண்டும் அனுப்ப இங்கே கிளிக் செய்யவும்",
    "error_26301": "பிழைக் குறியீடு: 26301, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26302": "பிழைக் குறியீடு: 26302, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26303": "மின்னஞ்சல் வடிவமைப்பு பிழை (பிழை குறியீடு: 26303). தயவுசெய்து அதை மீண்டும் உள்ளிடவும்",
    "error_26304": "8 எழுத்துகளுக்கு மேல் கடவுச்சொல் பரிந்துரைக்கப்படுகிறது (பிழை குறியீடு: 26304)",
    "error_26305": "Reuqest தோல்வியடைந்தது (பிழைக் குறியீடு: 26305). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26306": "மின்னஞ்சல் பதிவு செய்யப்பட்டுள்ளது, தயவுசெய்து <a href=\"https://account.mindonmap.com/login/\">உள்நுழைய செல்லவும்</a>",
    "error_26307": "Reuqest தோல்வியடைந்தது (பிழைக் குறியீடு: 26307). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26308": "Reuqest தோல்வியடைந்தது (பிழை குறியீடு: 26308). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26401": "பிழைக் குறியீடு: 26401, மீண்டும் முயற்சிக்கவும்",
    "error_26402": "மின்னஞ்சல் சரிபார்க்கப்பட்டது (பிழைக் குறியீடு: 26402), தயவுசெய்து அதை மீண்டும் முயற்சிக்கவும்",
    "error_26403": "Reuqest தோல்வியடைந்தது (பிழை குறியீடு: 26403). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26404": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 26404). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26501": "பிழைக் குறியீடு: 26501, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26502": "பிழைக் குறியீடு: 26502, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26503": "மின்னஞ்சல் வடிவமைப்பு பிழை (பிழை குறியீடு: 26503). தயவுசெய்து அதை மீண்டும் உள்ளிடவும்",
    "error_26504": "Reuqest தோல்வியடைந்தது (பிழைக் குறியீடு: 26504). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26505": "மின்னஞ்சல் பதிவு செய்யப்படவில்லை, தயவுசெய்து <a href=\"https://account.mindonmap.com/register/\">அதை முதலில் பதிவு செய்யவும்</a>",
    "error_26506": "மின்னஞ்சல் சரிபார்க்கப்பட்டது.",
    "error_26507": "Reuqest தோல்வியடைந்தது (பிழை குறியீடு: 26507). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26508": "சரிபார்க்கப்பட்டது (பிழைக் குறியீடு: 26508), மீண்டும் முயற்சிக்கவும்",
    "error_26509": "கோரிக்கை தோல்வியடைந்தது (பிழைக் குறியீடு: 26509), மீண்டும் முயற்சிக்கவும்",
    "error_26510": "பிழைக் குறியீடு: 26510, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26601": "பிழைக் குறியீடு: 26601, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26602": "பிழைக் குறியீடு: 26602, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26603": "Reuqest தோல்வியடைந்தது (பிழை குறியீடு: 26603). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26604": "பிழைக் குறியீடு: 26604, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26605": "பிழைக் குறியீடு: 26605, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26701": "பிழைக் குறியீடு: 26701, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26702": "Reuqest தோல்வியடைந்தது (பிழை குறியீடு: 26702). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26703": "பிழைக் குறியீடு: 26703, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26704": "பிழைக் குறியீடு: 26704, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26705": "உள்நுழைவதற்கு காத்திருங்கள் (பிழை குறியீடு: 26705). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "no_cookie": "உங்கள் உலாவியில் அனைத்து குக்கீகளையும் தடு செயல்பாட்டை இயக்கியுள்ளீர்கள், எனவே உங்களால் உள்நுழைய முடியாது. அனைத்து குக்கீகளையும் அனுமதி என்ற பெட்டியைத் தேர்வுசெய்ய, அமைப்புகளுக்குச் செல்லவும்.",
    "error_26801": "பிழைக் குறியீடு: 26801, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26802": "பிழைக் குறியீடு: 26802, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_26803": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 26803). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_26804": "கோரிக்கை தோல்வியடைந்தது (பிழைக் குறியீடு: 26804). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_order": "கோரிக்கை தோல்வியடைந்தது (பிழைக் குறியீடு: 27098), மீண்டும் முயற்சிக்கவும்!",
    "error_order1": "ஆர்டர் வினவல் முழுமையடையவில்லை (பிழை குறியீடு: ",
    "error_order2": "), புதுப்பித்து மீண்டும் முயற்சிக்கவும்.",
    "modify_email_title": "மின்னஞ்சலை மாற்றவும்",
    "modify_email_info": "உங்கள் கணக்கில் உள்நுழைய, மாற்றியமைக்கப்பட்ட மின்னஞ்சலைப் பயன்படுத்தலாம்.",
    "images_per": "படங்கள் ஒன்றுக்கு",
    "error_26101": "பிழை: 26101. எங்களை தொடர்பு கொள்ளவும்",
    "error_26102": "பிழை: 26102. எங்களை தொடர்பு கொள்ளவும்",
    "error_26103": "கோரிக்கை தோல்வி (பிழை குறியீடு:26103). மீண்டும் முயற்சிக்கவும்",
    "error_26104": "பிழைக் குறியீடு: 26104, மீண்டும் முயற்சிக்கவும்",
    "error_26105": "பிழைக் குறியீடு: 26105, மீண்டும் முயற்சிக்கவும்",
    "error_26106": "நீக்குதல் தோல்வி (பிழை குறியீடு: 26106). மீண்டும் முயற்சிக்கவும்",
    "error_26201": "பிழை: 26201. எங்களை தொடர்பு கொள்ளவும்",
    "error_26202": "கோரிக்கை தோல்வி (பிழை குறியீடு:26202). மீண்டும் முயற்சிக்கவும்",
    "error_26001": "பிழை: 26001. எங்களை தொடர்பு கொள்ளவும்",
    "error_26002": "பிழை: 26002. எங்களை தொடர்பு கொள்ளவும்",
    "error_26003": "பிழை: 26003. எங்களை தொடர்பு கொள்ளவும்",
    "error_26004": "பிழை: 26004. எங்களை தொடர்பு கொள்ளவும்",
    "error_26005": "கோரிக்கை தோல்வி (பிழை குறியீடு:26005). மீண்டும் முயற்சிக்கவும்",
    "error_26006": "பிழைக் குறியீடு: 26006, மீண்டும் முயற்சிக்கவும்",
    "error_26008": "பிழை: 26008. எங்களை தொடர்பு கொள்ளவும்",
    "go_to_the_home_page": "முகப்புப் பக்கத்திற்குச் செல்லவும்",
    "error_27101": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 27101). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_27201": "பிழைக் குறியீடு: 27201, எங்களைத் தொடர்பு கொள்ளவும்",
    "error_27202": "பிழைக் குறியீடு: 27202, மீண்டும் முயற்சிக்கவும்",
    "error_27203": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 27203). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_27204": "தவறான குறியீடு (பிழை குறியீடு: 27204).",
    "error_27205": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 27205). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_27206": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 27206). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_27207": "கோரிக்கை தோல்வியடைந்தது (பிழை குறியீடு: 27207). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "no_history_found": "நீங்கள் எந்த கருவியையும் பயன்படுத்தவில்லை! <a href=\"/\">புதுப்பிக்கவும்</a> அல்லது <a href=\"https://www.mindonmap.com/\">அதிகாரப்பூர்வ இணையதளத்திற்குச் செல்லவும்</a>",
    "error_25301": "பிழை: 25301. எங்களை தொடர்பு கொள்ளவும்",
    "error_25302": "பிழை: 25302. எங்களை தொடர்பு கொள்ளவும்",
    "error_25303": "கோரிக்கை தோல்வி (பிழை குறியீடு: 25303). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_25304": "கோரிக்கை தோல்வி (பிழை குறியீடு: 25304). மீண்டும் முயற்சிக்கவும்",
    "error_25305": "கோரிக்கை தோல்வி (பிழை குறியீடு: 25305). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "error_25306": "கோரிக்கை தோல்வி (பிழை குறியீடு: 25306). தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    "image_upscaler_p": "வாட்டர்மார்க் இல்லாத படத்தைப் பதிவிறக்கும் நன்மைகள்:",
    "Available_for": "இதற்குக் கிடைக்கிறது:",
    "credit_per": "ஒரு HD படத்திற்கு %s கிரெடிட்(கள்).",
    "still_valid": "வாங்கிய திட்டம்(கள்) இன்னும் செல்லுபடியாகும்",
    "credit": "கடன்(கள்)",
    "pc_3rd_info": "வெற்றிகரமாக உள்நுழைக. மேலும் செயல்பாட்டிற்கு விண்ணப்பத்திற்குச் செல்லவும்.",
    "use_online": "ஆன்லைன் சேவையைப் பயன்படுத்தவும்",
    "use_download": "டெஸ்க்டாப் நிரலைப் பயன்படுத்தவும்",
    "use_immediately": "உடனடியாக பயன்படுத்தவும்",
    "Use_in_browser": "உலாவியில் பயன்படுத்தவும்",
    "win_desktop": "விண்டோஸ்",
    "Mac_desktop": "மேக்",
    "credits_per": "ஒரு மாதத்திற்கு {%} கிரெடிட்கள்",
    "expire": "காலாவதி நேரம்:",
    "viewDetails": "விபரங்களை பார்",
    "viewHistory": "வரலாற்றின் பலன்களைப் பார்க்கவும்>>",
    "viewDetailsInfo": "அறிவிப்பு: சந்தா பலன்கள் காலாவதியான 7 நாட்களுக்குள் புதுப்பிக்கப்பட்டால், பயன்படுத்தப்படாத பலன்களைத் தொடர்ந்து பயன்படுத்தலாம். மேலும், காலாவதி நேரம் தானாகவே புதிய சந்தாவின் காலாவதி நேரத்திற்கு புதுப்பிக்கப்படும். காலாவதியான 7 நாட்களுக்குப் பிறகு புதிய சந்தா இல்லை என்றால், காலாவதியான அனைத்து நன்மைகளும் அழிக்கப்படும்.",
    "connect_account": "உங்கள் கணக்கில் மின்னஞ்சலை இணைக்கவும்",
    "connect_account_info": "பிணைத்த பிறகு, நீங்கள் இந்த மின்னஞ்சல் முகவரியுடன் உள்நுழையலாம்.",
    "connect_now": "இப்போது பிணைக்கவும்",
    "no_email_bind": "மின்னஞ்சல் பிணைப்பு இல்லை",
    "bind_email": "மின்னஞ்சலை பிணைக்கவும்",
    "connect_your_email_placeholder": "தயவுசெய்து உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்",
    "bind_an_email": "மின்னஞ்சலை இணைக்கவும்",
    "bind_info": "நீங்கள் வெற்றிகரமாக உள்நுழைந்துள்ளீர்கள். உங்கள் பலன்களைச் செயல்படுத்த, உங்கள் கணக்கில் மின்னஞ்சலை இணைக்கவும்.",
    "bind_later": "பின்னர் பிணைக்கவும்",
    "hi": "ஹாய், %s!",
    "Personal_Information": "தனிப்பட்ட தகவல்",
    "Access": "அணுகல்",
    "Subscription_Plan": "(சந்தா திட்டம்)",
    "No_orders": "ஆர்டர்கள் எதுவும் கிடைக்கவில்லை.",
    "No_data": "தகவல் இல்லை",
    "Featured_Products": "சிறப்பு தயாரிப்புகள்",
    "More_Products": "மேலும் தயாரிப்புகள்",
    "Free_Download": "இலவச பதிவிறக்கம்",
    "Get_Started": "தொடங்குங்கள்",
    "Subscribe": "பதிவு",
    "Verified": "சரிபார்க்கப்பட்டது",
    "back_to_account_center": "கணக்கு மையத்திற்குத் திரும்பு",
    "success": "வெற்றி!",
    "successfully": "நீங்கள் ஒரு கணக்கை வெற்றிகரமாக பதிவு செய்துள்ளீர்கள்.",
    "Continue": "தொடரவும்",
    "Already": "ஏற்கனவே ஒரு கணக்கு உள்ளதா?",
    "loading_verification": "சரிபார்ப்பு நிலையை சரிபார்க்கிறது...",
    "email_no_verification": "மன்னிக்கவும், பதிவு செய்யப்பட்ட மின்னஞ்சல் முகவரி சரிபார்க்கப்படவில்லை. மேலே உள்ள வழிமுறைகளின்படி சரிபார்ப்பை நிறைவுசெய்து, பதிவை முடிக்க \"முடிந்தது\" பொத்தானை மீண்டும் கிளிக் செய்யவும்.",
    "will_expire_after": "பிறகு காலாவதியாகிவிடும்",
    "hours": "மணி",
  },
  "te": {
    "overtime": "ఎర్రర్ కోడ్: {%}, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "isnetwork": "ఇంటర్నెట్ లోపం. దయచేసి తనిఖీ చేసి మళ్లీ ప్రయత్నించండి",
    "email_placeholder": "ఇమెయిల్",
    "email_empty": "దయచేసి ఇమెయిల్ ఇన్‌పుట్ చేయండి",
    "email_not_valid": "ఇమెయిల్ చెల్లదు",
    "email_not_valid_1": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి",
    "email_not_valid_2": "ఇమెయిల్ చెల్లదు, దయచేసి వేరే చిరునామాను ఉపయోగించండి.",
    "email_not_valid_3": "ఇమెయిల్ ఇన్‌పుట్ లేదు",
    "password_placeholder": "పాస్వర్డ్",
    "password_empty": "దయచేసి పాస్‌వర్డ్‌ను ఇన్‌పుట్ చేయండి",
    "password_not_valid": "తప్పు ఖాతా లేదా పాస్‌వర్డ్",
    "password_not_valid_1": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ అవసరం",
    "password_not_valid_2": "దయచేసి పాస్‌వర్డ్‌ను సృష్టించండి",
    "password_placeholder_1": "మీ పాస్‌వర్డ్‌ని సృష్టించండి",
    "password_placeholder_2": "మీ గుత్త పదమును ధృవీకరించండి",
    "password_placeholder_3": "కొత్త పాస్‌వర్డ్‌ని సృష్టించండి",
    "password_placeholder_4": "కొత్త పాస్‌వర్డ్‌ను నిర్ధారించండి",
    "password_placeholder_5": "పాత పాస్‌వర్డ్‌ను ఇన్‌పుట్ చేయండి",
    "copy_password_empty": "దయచేసి పాస్‌వర్డ్‌ను నిర్ధారించండి",
    "copy_password_not_valid": "దయచేసి మీ పాస్‌వర్డ్‌ను నిర్ధారించండి",
    "copy_passwords_inconsistent": "మీ పాస్‌వర్డ్ నిర్ధారణ సరిపోలలేదు",
    "code_empty": "దయచేసి ధృవీకరణ కోడ్‌ని ఇన్‌పుట్ చేయండి",
    "code_not_valid": "నిర్ధారణ కోడు సరైంది కాదు",
    "code_placeholder": "ధృవీకరణ కోడ్",
    "not_received_code": "మీ మెయిల్‌బాక్స్ చాలా కాలం పాటు ధృవీకరణ కోడ్‌ను అందుకోకపోతే, దయచేసి ధృవీకరణ కోడ్‌ను మళ్లీ పొందండి.",
    "get_first_code": "దయచేసి ముందుగా ధృవీకరణ కోడ్‌ని పొందండి.",
    "last_name_placeholder": "దయచేసి మీ చివరి పేరును నమోదు చేయండి",
    "first_name_placeholder": "దయచేసి మీ మొదటి పేరును నమోదు చేయండి",
    "address_placeholder": "దయచేసి మీ చిరునామాను నమోదు చేయండి",
    "no_code_text": "మేము ధృవీకరణ కోడ్‌ని పంపాము. దయచేసి మీ కోడ్‌ని నమోదు చేయండి. <span class='tips'>కోడ్‌ని అందుకోలేదా?",
    "no_code_text_1": "1. దయచేసి ఇమెయిల్ చిరునామా చెల్లుబాటు అయ్యేదని మరియు అది ఇమెయిల్‌లను అందుకోగలదని నిర్ధారించుకోండి.",
    "no_code_text_2": "2. ఇమెయిల్ సిస్టమ్ ద్వారా స్వయంచాలకంగా పంపబడినందున, అది స్పామ్ లేదా జంక్ ఇమెయిల్‌గా ఫ్లాగ్ చేయబడవచ్చు. దయచేసి ఇమెయిల్ ట్రాష్ ఫోల్డర్‌లో ఉందో లేదో తనిఖీ చేయండి.",
    "no_code_text_3": "3. మీ సమస్యను పరిష్కరించలేదా? ",
    "no_code_text_3_span": "అప్పుడు మమ్మల్ని సంప్రదించడానికి ఇక్కడ క్లిక్ చేయండి.",
    "order_no": "మీరు ఏ ఉత్పత్తిని కొనుగోలు చేయలేదు, మీకు ఏవైనా సందేహాలు ఉంటే, దయచేసి <a href=\"https://www.mindonmap.com/contact-us/\">మమ్మల్ని సంప్రదించండి</a>.",
    "error_24901": "ప్రస్తుత ఖాతాకు ఇమెయిల్ లింక్ చేయబడలేదు మరియు ఆర్డర్‌లను కనుగొనలేదు. దయచేసి ఇమెయిల్‌ను కనెక్ట్ చేయండి.",
    "user_guide": "వినియోగదారు గైడ్ >>",
    "download": "డౌన్‌లోడ్ చేయండి",
    "order_number": "ఆర్డర్ సంఖ్య:",
    "Refund": "వాపసు",
    "Disabled": "వికలాంగుడు",
    "Normal": "సాధారణ",
    "Modify": "సవరించు",
    "Modify_1": "సవరించు>>",
    "Connect": "కనెక్ట్ చేయండి",
    "unlink_success": "విజయవంతంగా అన్‌లింక్ చేయండి",
    "connect_success": "విజయవంతంగా కనెక్ట్ అవ్వండి",
    "feedback_title": "మీ అభిప్రాయానికి ధన్యవాదాలు. దయచేసి మీ సమస్యను వదిలివేయండి మరియు మేము 24 గంటల్లోపు మీకు ప్రతిస్పందిస్తాము.",
    "feedback_thank_you": "ధన్యవాదాలు!<br />మీ అభిప్రాయం విజయవంతంగా సమర్పించబడింది.",
    "feedback_email": "మీ ఇమెయిల్‌ను ఇక్కడ నమోదు చేయండి!",
    "feedback_content": "మీరు ఎదుర్కొన్న ఏదైనా సమస్య లేదా సూచనను ఇక్కడ వదిలివేయండి.",
    "feedback_submit": "సమర్పించండి",
    "form_contents": "మీరు ఏ వివరణను నమోదు చేయలేదు. దయచేసి దాన్ని నమోదు చేసి, మళ్లీ సమర్పించండి.",
    "old_password": "దయచేసి పాత పాస్‌వర్డ్‌ను ఇన్‌పుట్ చేయండి",
    "new_password": "దయచేసి కొత్త పాస్‌వర్డ్‌ను సృష్టించండి",
    "old_new_password": "కొత్త పాస్‌వర్డ్ పాతది కాకూడదు",
    "incorrect_password": "సరికాని పాస్వర్డ్",
    "delete_no": "ఇప్పుడే తొలగించండి",
    "Caps": "క్యాప్స్ లాక్ ఆన్‌లో ఉంది",
    "Get": "పొందండి",
    "Done": "పూర్తి",
    "error_20001": "లోపం: 20001. దయచేసి మళ్లీ లాగిన్ చేయండి.",
    "error_20002": "లోపం: 20002. దయచేసి మళ్లీ లాగిన్ చేయండి.",
    "error_20003": "లోపం: 20003. దయచేసి మళ్లీ లాగిన్ చేయండి.",
    "error_20004": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20004). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_20005": "లాగిన్ సెషన్ గడువు ముగిసింది (లోపం: 20005). దయచేసి మళ్లీ లాగిన్ అవ్వండి.",
    "error_20006": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20006). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_20007": "లాగిన్ సెషన్ గడువు ముగిసింది (లోపం: 20007). దయచేసి మళ్లీ లాగిన్ అవ్వండి.",
    "error_20008": "లాగిన్ సెషన్ గడువు ముగిసింది (లోపం: 20008). దయచేసి మళ్లీ లాగిన్ అవ్వండి.",
    "error_20009": "లాగిన్ సెషన్ గడువు ముగిసింది (లోపం: 20009). దయచేసి మళ్లీ లాగిన్ అవ్వండి.",
    "error_20101": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20101)",
    "error_20102": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20102)",
    "error_20103": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20103). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20104": "ఇమెయిల్ ఇప్పటికే ఉపయోగంలో ఉంది, దయచేసి <a href=\"https://account.mindonmap.com/login/\">లాగిన్</a> లేదా కొత్త దానితో నమోదు చేసుకోండి",
    "error_20105": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20105). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20106": "ఇమెయిల్ పంపడంలో విఫలమైంది, దయచేసి దాన్ని మళ్లీ ప్రయత్నించండి",
    "error_20201": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20201)",
    "error_20202": "దయచేసి మీ పాస్‌వర్డ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20202)",
    "error_20203": "దయచేసి ధృవీకరణ కోడ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20203)",
    "error_20204": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20204)",
    "error_20205": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ అవసరం (ఎర్రర్ కోడ్: 20205)",
    "error_20206": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20206). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20207": "నిర్ధారణ కోడు సరైంది కాదు",
    "error_20208": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20208). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20209": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20209). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20301": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20301)",
    "error_20302": "లోపం: 20302. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_20303": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20303)",
    "error_20304": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20304). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20305": "ఖాతా ఉనికిలో లేదు. దయచేసి ముందుగా మళ్లీ నమోదు చేయండి లేదా <a href=\"https://account.mindonmap.com/register/\">దీన్ని సృష్టించండి</a>.",
    "error_20306": "ఇంకా పాస్‌వర్డ్ లేదు. <a href=\"https://account.mindonmap.com/passwordless-login/\">పాస్‌వర్డ్ లేని లాగిన్</a> లేదా <a href=\"https://account.mindonmap.com/create-password/\">ని ఉపయోగించండి పాస్వర్డ్ను సెట్ చేయండి</a> మరియు లాగిన్ చేయండి.",
    "error_20308": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20308). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20401": "లాగ్ అవుట్ చేయడంలో విఫలమైంది (ఎర్రర్ కోడ్: 20401). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20501": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20501)",
    "error_20502": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20502)",
    "error_20503": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20503). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20504": "ఇమెయిల్ పంపడంలో విఫలమైంది. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_20601": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20601)",
    "error_20602": "దయచేసి ధృవీకరణ కోడ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20602)",
    "error_20603": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20603)",
    "error_20604": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20604). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20606": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20606). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20607": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20607). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20608": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20608). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20701": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20701)",
    "error_20702": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20702)",
    "error_20703": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20703). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20704": "ఖాతా ఉనికిలో లేదు. దయచేసి ముందుగా మళ్లీ నమోదు చేయండి లేదా <a href=\"https://account.mindonmap.com/register/\">దీన్ని సృష్టించండి</a>.",
    "error_20705": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20705). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20706": "ఇమెయిల్ పంపడంలో విఫలమైంది. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20801": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20801)",
    "error_20802": "లోపం: 20802. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_20803": "దయచేసి ధృవీకరణ కోడ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 20803)",
    "error_20804": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 20804)",
    "error_20805": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ అవసరం (ఎర్రర్ కోడ్: 20805)",
    "error_20806": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20806). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20808": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20808). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20901": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20901). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20902": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 20902). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21000": "మార్పులు సేవ్ చేయబడ్డాయి",
    "error_21001": "సమాచారం సమర్పించబడలేదు (ఎర్రర్ కోడ్: 21001)",
    "error_21002": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21002). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21101": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 21101)",
    "error_21102": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 21102)",
    "error_21103": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21103), దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_21104": "ఇమెయిల్ ఇప్పటికే కనెక్ట్ చేయబడింది, దయచేసి కొత్తదాన్ని ఉపయోగించండి",
    "error_21105": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21105), దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_21106": "ఇమెయిల్ పంపడంలో విఫలమైంది. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21201": "దయచేసి మీ ఇమెయిల్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 21201)",
    "error_21202": "దయచేసి ధృవీకరణ కోడ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 21202)",
    "error_21203": "ఇమెయిల్ చెల్లదు (ఎర్రర్ కోడ్: 21203)",
    "error_21204": "లోపం: 21204. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21205": "లోపం: 21205. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21206": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ అవసరం (ఎర్రర్ కోడ్: 21206)",
    "error_21207": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21207). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21209": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21209). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21301": "దయచేసి పాత పాస్‌వర్డ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 21301)",
    "error_21302": "దయచేసి కొత్త పాస్‌వర్డ్‌ను సృష్టించండి (ఎర్రర్ కోడ్: 21302)",
    "error_21303": "కొత్త పాస్‌వర్డ్ పాతది కాకూడదు. (లోపం: 21303)",
    "error_21304": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ అవసరం (ఎర్రర్ కోడ్: 21304)",
    "error_21306": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21306). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21402": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21402). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21403": "ధృవీకరణ కోడ్‌ని పంపడంలో విఫలమైంది. దయచేసి దాన్ని మళ్లీ పంపండి",
    "error_21500": "ఖాతా తొలగించబడింది",
    "error_21501": "దయచేసి ధృవీకరణ కోడ్‌ను ఇన్‌పుట్ చేయండి (ఎర్రర్ కోడ్: 21501)",
    "error_21502": "లాగిన్ సెషన్ గడువు ముగిసింది (లోపం: 21502). దయచేసి మళ్లీ లాగిన్ అవ్వండి.",
    "error_21503": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21503). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21505": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21505), దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21601": "లోపం: 20601. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21602": "చెల్లని ధృవీకరణ (లోపం: 20602). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_21603": "లోపం: 20603. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21604": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21604). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21606": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21606). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21611": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21611). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21801": "లోపం: 21801. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21802": "అభ్యర్థన విఫలమైంది (లోపం: 21802). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21803": "లోపం: 21803. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21804": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21804). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21806": "లోపం: 21806. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21807": "లోపం: 21807. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21808": "లోపం: 21808. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21809": "లోపం: 21809. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21810": "లోపం: 21810. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21811": "లోపం: 21811. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21812": "లోపం: 21812. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21813": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21813). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21814": "లోపం: 21814. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21815": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21815). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21816": "లోపం: 21816. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21817": "లోపం: 21817. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21818": "లోపం: 21818. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21819": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21819). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21820": "లోపం: 21820. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21821": "లోపం: 21821. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21822": "లోపం: 21822. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21823": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21823). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21824": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21824). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21825": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21825). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21826": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21826). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21901": "లోపం: 21901. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_21902": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21902). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21903": "ఖాతా స్థితి మార్చబడింది (ఎర్రర్ కోడ్: 21903), దయచేసి పేజీని రిఫ్రెష్ చేసి, మళ్లీ ప్రయత్నించండి",
    "error_21904": "లోపం: 21904. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21905": "లోపం: 21905. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21906": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21906). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_21907": "Google ఖాతా ఇతర ఖాతాకు లింక్ చేయబడింది",
    "error_21908": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 21908). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22001": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22001). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22002": "అదనపు లాగిన్ లేకుండా అన్‌లింక్ విఫలమైంది",
    "error_22003": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22003). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22101": "లోపం: 22101. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22102": "ఖాతా స్థితి మార్చబడింది (ఎర్రర్ కోడ్: 22102), దయచేసి పేజీని రిఫ్రెష్ చేసి, మళ్లీ ప్రయత్నించండి",
    "error_22103": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22103). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22104": "ఖాతా స్థితి మార్చబడింది (ఎర్రర్ కోడ్: 22104), దయచేసి పేజీని రిఫ్రెష్ చేసి, మళ్లీ ప్రయత్నించండి",
    "error_22105": "లోపం: 22105. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22106": "లోపం: 22106. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22107": "లోపం: 22107. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22108": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22108). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22201": "లోపం: 22201. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22202": "చెల్లని ధృవీకరణ (లోపం: 22202). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_22203": "లోపం: 22203. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి\"",
    "error_22204": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22204). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22206": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22206). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22401": "లోపం: 22401. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22402": "చెల్లని ధృవీకరణ (లోపం: 22402). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_22403": "లోపం: 22403. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22404": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22404). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22405": "Facebook ఖాతా ఇతర ఇమెయిల్‌కి లింక్ చేయబడింది",
    "error_22406": "లోపం: 22406. దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22407": "లోపం: 22407. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22408": "లోపం: 22408. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22409": "లోపం: 22409. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22410": "లోపం: 224010. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22411": "లోపం: 224011. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22412": "లోపం: 224012. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22413": "లోపం: 22413. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22414": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22414). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22415": "లోపం: 22415. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22416": "లోపం: 22416. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22417": "లోపం: 22417. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22418": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22418). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22419": "లోపం: 22419. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22420": "లోపం: 22420. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22421": "లోపం: 22421. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_22422": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22422). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22423": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22423). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22424": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22424). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_22425": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 22425). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_20098": "ఎర్రర్ కోడ్: 20098. మీరు ప్రైవేట్ మోడ్‌లో బ్రౌజ్ చేస్తుంటే, దయచేసి సాధారణ మోడ్‌కి మారి మళ్లీ ప్రయత్నించండి.",
    "error_22298": "Google కోసం అభ్యర్థన విఫలమైంది (లోపం: 22298). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_22498": "Facebook కోసం అభ్యర్థన విఫలమైంది (లోపం: 22498). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి.",
    "error_24902": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 24902). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_24903": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 24903). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_24904": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 24904). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_24905": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 24905). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "login_title": "MindOnMapకి లాగిన్ చేయండి",
    "log_in": "ప్రవేశించండి",
    "no_account": "ఖాతా లేదా?",
    "create_it": "దీన్ని సృష్టించండి",
    "or_log_in_with": "లేదా దీనితో లాగిన్ అవ్వండి",
    "passwordless_login": "పాస్‌వర్డ్ లేని లాగిన్",
    "log_in_done": "లాగిన్ పూర్తయింది",
    "three_rd_account_connect_info": "అభినందనలు! మీరు విజయవంతంగా లాగిన్ చేసారు. ఇప్పుడు మీరు భవిష్యత్తులో లాగిన్ చేయడానికి ఉపయోగించే ఒక ఇమెయిల్ ఖాతాను కనెక్ట్ చేయవచ్చు.",
    "see_my_account": "నా ఖాతాను చూడండి",
    "three_rd_login_merge_account": "మూడవ పక్షం ఖాతా ఇమెయిల్ చిరునామా సైన్ అప్ చేయబడింది, మీరు నేరుగా ఈ ఇమెయిల్ చిరునామాతో కనెక్ట్ అయ్యి లాగిన్ చేయాలనుకుంటున్నారా?",
    "connect_log_in": "కనెక్ట్ చేయండి & లాగిన్ చేయండి",
    "create_an_account": "ఒక ఎకౌంటు సృష్టించు",
    "back_to_log_in": "లాగిన్ అవ్వడానికి తిరిగి వెళ్ళు",
    "create_password": "పాస్వర్డ్ సృష్టించండి",
    "create_now": "ఇప్పుడే సృష్టించండి",
    "password_login_subtitle": "ఇమెయిల్‌తో పాస్‌వర్డ్ లేని లాగిన్",
    "account_login": "ఖాతా లాగిన్",
    "rights": "ఈ ఖాతాను సృష్టించడం ద్వారా, మీరు <a href=\"https://www.mindonmap.com/terms-and-conditions/\">సేవా నిబంధనలు</a> మరియు <a href=\"https://www.mindonmap.com/privacy-policy/\">గోప్యతా విధానం</a>",
    "passwordless_login_done": "పాస్‌వర్డ్ లేని లాగిన్ పూర్తయింది",
    "passwordless_login_info": "అభినందనలు, మీరు పాస్‌వర్డ్ లేని లాగిన్‌ని విజయవంతంగా పూర్తి చేసారు. మీరు ఈ ఖాతా కోసం పాస్‌వర్డ్‌ను సృష్టించవచ్చు మరియు భవిష్యత్తులో ఖాతా మరియు పాస్‌వర్డ్‌తో లాగిన్ చేయవచ్చు. <a href=\"/create-password\" style=\"display: initial;\">ఇప్పుడే సృష్టించు</a>",
    "sign_up": "చేరడం",
    "register_info": "మీ ఖాతాను సృష్టించండి",
    "reset_now": "ఇప్పుడే రీసెట్ చేయండి",
    "forgot_password": "పాస్‌వర్డ్ మర్చిపోయాను",
    "reset_password_subtitle": "పాస్‌వర్డ్‌ని రీసెట్ చేయడానికి మీ ఖాతా ఇమెయిల్‌ని ఉపయోగించండి",
    "plan_products": "ప్రణాళికలు & ఉత్పత్తులు",
    "nick_name": "వినియోగదారు పేరు:",
    "email": "ఇమెయిల్:",
    "my_products": "నా ఉత్పత్తులు",
    "my_orders": "నా ఆదేశాలు",
    "unlink": "అన్‌లింక్ చేయండి",
    "link": "లింక్",
    "connected_accounts": "కనెక్ట్ చేయబడిన ఖాతాలు",
    "last_name": "చివరి పేరు:",
    "first_name": "మొదటి పేరు:",
    "Gender": "లింగం:",
    "Birth": "పుట్టిన:",
    "Month": "నెల",
    "Year": "సంవత్సరం",
    "Country_Region": "దేశం/ప్రాంతం:",
    "Address": "చిరునామా:",
    "Save": "సేవ్ చేయండి",
    "Date": "తేదీ",
    "Male": "పురుషుడు",
    "Female": "స్త్రీ",
    "Unspecified": "పేర్కొనబడలేదు",
    "Security": "భద్రత",
    "change_password": "పాస్‌వర్డ్ మార్చండి",
    "change_now": "ఇప్పుడు మార్చండి",
    "connect_email": "ఇమెయిల్‌ను కనెక్ట్ చేయండి",
    "delete_account": "ఖాతాను తొలగించండి",
    "delete_account_info": "మీ ఖాతా తొలగించబడినప్పుడు, మీ ఖాతాతో అనుబంధించబడిన నా ఖాతాలోని మొత్తం డేటా శాశ్వతంగా తొలగించబడుతుంది మరియు మీరు దాన్ని తిరిగి పొందలేకపోవచ్చు. మీరు జాగ్రత్తగా నడవాలని మేము సిఫార్సు చేస్తున్నాము.",
    "Delete": "తొలగించు",
    "Logout": "లాగ్అవుట్",
    "my_profile": "నా జీవన వివరణ",
    "guides_faqs": "మార్గదర్శకాలు & తరచుగా అడిగే ప్రశ్నలు",
    "More": "మరింత",
    "guides": "మార్గదర్శకులు",
    "register": "నమోదు",
    "hot_faq": "హాట్ FAQ",
    "Contents": "కంటెంట్:",
    "contact_us": "మమ్మల్ని సంప్రదించండి >>",
    "plan": "ప్లాన్ చేయండి",
    "unregistered": "నమోదు చేయబడలేదు",
    "buy_more": "మరిన్ని కొనుగోలు చేయండి",
    "buy_again": "మళ్లీ కొనండి",
    "buy_now": "ఇప్పుడే కొనండి",
    "free_no_limit": "ఉచితం & పరిమితి లేదు",
    "expired": "గడువు ముగిసింది",
    "lifetime": "జీవితకాలం",
    "remain": "ఉండు",
    "day_s": "రోజులు)",
    "error_24801": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 24801). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "no_app_found": "యాప్ ఏదీ కనుగొనబడలేదు!<a href=\"/\">రిఫ్రెష్</a> లేదా <a href=\"https://www.mindonmap.com/\">అధికారిక వెబ్‌సైట్‌కి వెళ్లండి</a>",
    "get_more": "మరింత పొందండి >>",
    "edit_photo": "ఫోటోను సవరించండి",
    "select_photo": "ఫోటోను ఎంచుకోండి",
    "change_photo": "ఫోటో మార్చు",
    "cancel": "రద్దు చేయండి",
    "hide_password": "పాస్వర్డ్ను దాచండి",
    "show_password": "సంకేత పదాన్ని చూపించండి",
    "zoom_in": "పెద్దదిగా చూపు",
    "zoom_out": "పెద్దది చెయ్యి",
    "rotate": "తిప్పండి",
    "horizontal_flip": "క్షితిజసమాంతర కుదుపు",
    "vertical_flip": "నిలువు కుదుపు",
    "country": "దేశం",
    "country_1": "మీ దేశం/ప్రాంతాన్ని ఎంచుకోండి",
    "country_2": "ఆలాండ్ ద్వీపం",
    "country_3": "ఆఫ్ఘనిస్తాన్",
    "country_4": "అల్బేనియా",
    "country_5": "అల్జీరియా",
    "country_6": "అమెరికన్ సమోవా",
    "country_7": "అండోరా",
    "country_8": "అంగోలా",
    "country_9": "అంగుయిల్లా",
    "country_10": "అంటార్కిటికా",
    "country_11": "ఆంటిగ్వా & బార్బుడా",
    "country_12": "అర్జెంటీనా",
    "country_13": "ఆర్మేనియా",
    "country_14": "అరుబా",
    "country_15": "ఆస్ట్రేలియా",
    "country_16": "ఆస్ట్రియా",
    "country_17": "అజర్‌బైజాన్",
    "country_18": "బహ్రెయిన్",
    "country_19": "బంగ్లాదేశ్",
    "country_20": "బార్బడోస్",
    "country_21": "బెలారస్",
    "country_22": "బెల్జియం",
    "country_23": "బెలిజ్",
    "country_24": "బెనిన్",
    "country_25": "బెర్ముడా",
    "country_26": "భూటాన్",
    "country_27": "బొలీవియా",
    "country_28": "బోస్నియా & హెర్జెగోవినా",
    "country_29": "బోట్స్వానా",
    "country_30": "బోవెట్ ద్వీపం",
    "country_31": "బ్రెజిల్",
    "country_32": "బ్రిటిష్ ఇండియన్ ఓషన్ టెరిటరీ",
    "country_33": "బ్రిటిష్ వర్జిన్ దీవులు",
    "country_34": "బ్రూనై",
    "country_35": "బల్గేరియా",
    "country_36": "బుర్కినా",
    "country_37": "బురుండి",
    "country_38": "కంబోడియా",
    "country_39": "కామెరూన్",
    "country_40": "కెనడా",
    "country_41": "కేప్ వర్దె",
    "country_42": "కరేబియన్ నెదర్లాండ్స్",
    "country_43": "కేమాన్ దీవులు",
    "country_44": "సెంట్రల్ ఆఫ్రికన్ రిపబ్లిక్",
    "country_45": "చాడ్",
    "country_46": "చిలీ",
    "country_47": "చైనా",
    "country_48": "క్రిస్మస్ ద్వీపం",
    "country_49": "కోకోస్ (కీలింగ్) దీవులు",
    "country_50": "కొలంబియా",
    "country_51": "కుక్ దీవులు",
    "country_52": "కోస్టా రికా",
    "country_53": "కోట్ డి ఐవరీ",
    "country_54": "క్రొయేషియా",
    "country_55": "క్యూబా",
    "country_56": "సైప్రస్",
    "country_57": "చెక్ రిపబ్లిక్",
    "country_58": "కాంగో డెమోక్రటిక్ రిపబ్లిక్",
    "country_59": "డెన్మార్క్",
    "country_60": "జిబౌటీ",
    "country_61": "డొమినికా",
    "country_62": "డొమినికన్ రిపబ్లిక్",
    "country_63": "ఈక్వెడార్",
    "country_64": "ఈజిప్ట్",
    "country_65": "ఎల్ సల్వడార్",
    "country_66": "ఈక్వటోరియల్ గినియా",
    "country_67": "ఎరిట్రియా",
    "country_68": "ఎస్టోనియా",
    "country_69": "ఇథియోపియా",
    "country_70": "ఫాక్‌ల్యాండ్ దీవులు",
    "country_71": "ఫారో దీవులు",
    "country_72": "ఫెడరేటెడ్ స్టేట్స్ ఆఫ్ మైక్రోనేషియా",
    "country_73": "ఫిజీ",
    "country_74": "ఫిన్లాండ్",
    "country_75": "ఫ్రాన్స్",
    "country_76": "ఫ్రెంచ్ గయానా",
    "country_77": "ఫ్రెంచ్ పాలినేషియా",
    "country_78": "ఫ్రెంచ్ దక్షిణ ప్రాంతాలు",
    "country_79": "గాబోన్",
    "country_80": "గాంబియా",
    "country_81": "జార్జియా",
    "country_82": "జర్మనీ",
    "country_83": "ఘనా",
    "country_84": "జిబ్రాల్టర్",
    "country_85": "గ్రేట్ బ్రిటన్ (యునైటెడ్ కింగ్‌డమ్; ఇంగ్లండ్)",
    "country_86": "గ్రీస్",
    "country_87": "గ్రీన్లాండ్",
    "country_88": "గ్రెనడా",
    "country_89": "గ్వాడెలోప్",
    "country_90": "గ్వామ్",
    "country_91": "గ్వాటెమాల",
    "country_92": "గ్వెర్న్సీ",
    "country_93": "గినియా",
    "country_94": "గినియా-బిస్సావు",
    "country_95": "గయానా",
    "country_96": "హైతీ",
    "country_97": "విన్న ద్వీపం మరియు మెక్‌డొనాల్డ్ దీవులు",
    "country_98": "హోండురాస్",
    "country_99": "హాంగ్ కొంగ",
    "country_100": "హంగేరి",
    "country_101": "ఐస్లాండ్",
    "country_102": "భారతదేశం",
    "country_103": "ఇండోనేషియా",
    "country_104": "ఇరాన్",
    "country_105": "ఇరాక్",
    "country_106": "ఐర్లాండ్",
    "country_107": "ఐల్ ఆఫ్ మ్యాన్",
    "country_108": "ఇజ్రాయెల్",
    "country_109": "ఇటలీ",
    "country_110": "జమైకా",
    "country_111": "జపాన్",
    "country_112": "జెర్సీ",
    "country_113": "జోర్డాన్",
    "country_114": "కజకిస్తాన్",
    "country_115": "కెన్యా",
    "country_116": "కిరిబాటి",
    "country_117": "కువైట్",
    "country_118": "కిర్గిజ్స్తాన్",
    "country_119": "లావోస్",
    "country_120": "లాట్వియా",
    "country_121": "లెబనాన్",
    "country_122": "లెసోతో",
    "country_123": "లైబీరియా",
    "country_124": "లిబియా",
    "country_125": "లిచెన్‌స్టెయిన్",
    "country_126": "లిథువేనియా",
    "country_127": "లక్సెంబర్గ్",
    "country_128": "మకావో",
    "country_129": "మడగాస్కర్",
    "country_130": "మలావి",
    "country_131": "మలేషియా",
    "country_132": "మాల్దీవులు",
    "country_133": "మాలి",
    "country_134": "మాల్టా",
    "country_135": "మార్షల్ దీవులు",
    "country_136": "మార్టినిక్",
    "country_137": "మౌరిటానియా",
    "country_138": "మారిషస్",
    "country_139": "మయోట్టే",
    "country_140": "మెక్సికో",
    "country_141": "మోల్డోవా",
    "country_142": "మొనాకో",
    "country_143": "మంగోలియా",
    "country_144": "మోంటెనెగ్రో",
    "country_145": "మోంట్సెరాట్",
    "country_146": "మొరాకో",
    "country_147": "మొజాంబిక్",
    "country_148": "మయన్మార్ (బర్మా)",
    "country_149": "నమీబియా",
    "country_150": "నౌరు",
    "country_151": "నేపాల్",
    "country_152": "నెదర్లాండ్స్",
    "country_153": "న్యూ కలెడోనియా",
    "country_154": "న్యూజిలాండ్",
    "country_155": "నికరాగ్వా",
    "country_156": "నైజర్",
    "country_157": "నైజీరియా",
    "country_158": "నియు",
    "country_159": "నార్ఫోక్ ద్వీపం",
    "country_160": "ఉత్తర కొరియ",
    "country_161": "ఉత్తర మరియానా దీవులు",
    "country_162": "నార్వే",
    "country_163": "ఒమన్",
    "country_164": "పాకిస్తాన్",
    "country_165": "పలావ్",
    "country_166": "పాలస్తీనియన్ భూభాగాలు",
    "country_167": "పనామా",
    "country_168": "పాపువా న్యూ గినియా",
    "country_169": "పరాగ్వే",
    "country_170": "పెరూ",
    "country_171": "పిట్‌కైర్న్ దీవులు",
    "country_172": "పోలాండ్",
    "country_173": "పోర్చుగల్",
    "country_174": "ప్యూర్టో రికో",
    "country_175": "ఖతార్",
    "country_176": "రిపబ్లిక్ ఆఫ్ మాసిడోనియా (FYROM)",
    "country_177": "కాంగో రిపబ్లిక్",
    "country_178": "రీయూనియన్",
    "country_179": "రొమేనియా",
    "country_180": "రష్యన్ ఫెడరేషన్",
    "country_181": "రువాండా",
    "country_182": "సెయింట్ బార్తెలెమీ",
    "country_183": "సెయింట్ మార్టిన్ (ఫ్రాన్స్)",
    "country_184": "సెయింట్-పియర్ మరియు మిక్వెలాన్",
    "country_185": "సమోవా",
    "country_186": "శాన్ మారినో",
    "country_187": "సావో టోమ్ & ప్రిన్సిపీ",
    "country_188": "సౌదీ అరేబియా",
    "country_189": "సెనెగల్",
    "country_190": "సెర్బియా",
    "country_191": "సీషెల్స్",
    "country_192": "సియర్రా లియోన్",
    "country_193": "సింగపూర్",
    "country_194": "స్లోవేకియా",
    "country_195": "స్లోవేనియా",
    "country_196": "సోలమన్ దీవులు",
    "country_197": "సోమాలియా",
    "country_198": "దక్షిణ ఆఫ్రికా",
    "country_199": "దక్షిణ జార్జియా మరియు దక్షిణ శాండ్‌విచ్ ఇస్లా",
    "country_200": "దక్షిణ కొరియా",
    "country_201": "దక్షిణ సూడాన్",
    "country_202": "స్పెయిన్",
    "country_203": "శ్రీలంక",
    "country_204": "సెయింట్ హెలెనా & ఆధారాలు",
    "country_205": "సెయింట్ కిట్స్ & నెవిస్",
    "country_206": "సెయింట్ లూసియా",
    "country_207": "సెయింట్ విన్సెంట్ & ది గ్రెనడైన్స్",
    "country_208": "సూడాన్",
    "country_209": "సురినామ్",
    "country_210": "స్వాజిలాండ్",
    "country_211": "స్వీడన్",
    "country_212": "స్విట్జర్లాండ్",
    "country_213": "సిరియా",
    "country_214": "తైవాన్",
    "country_215": "తజికిస్తాన్",
    "country_216": "టాంజానియా",
    "country_217": "టెంప్లేట్:Country data SJM Svalbard",
    "country_218": "థాయిలాండ్",
    "country_219": "బహామాస్",
    "country_220": "కొమొరోస్",
    "country_221": "ఫిలిప్పీన్స్",
    "country_222": "తైమూర్-లెస్టే (తూర్పు తైమూర్)",
    "country_223": "వెళ్ళడానికి",
    "country_224": "టోకెలావ్",
    "country_225": "టాంగా",
    "country_226": "ట్రినిడాడ్ & టొబాగో",
    "country_227": "ట్యునీషియా",
    "country_228": "టర్కీ",
    "country_229": "తుర్క్మెనిస్తాన్",
    "country_230": "టర్క్స్ & కైకోస్ దీవులు",
    "country_231": "తువాలు",
    "country_232": "ఉగాండా",
    "country_233": "ఉక్రెయిన్",
    "country_234": "యునైటెడ్ అరబ్ ఎమిరేట్స్",
    "country_235": "యునైటెడ్ స్టేట్స్ మైనర్ అవుట్‌లైయింగ్ దీవులు",
    "country_236": "యునైటెడ్ స్టేట్స్ అమెరికా (USA)",
    "country_237": "యునైటెడ్ స్టేట్స్ వర్జిన్ దీవులు",
    "country_238": "ఉరుగ్వే",
    "country_239": "ఉజ్బెకిస్తాన్",
    "country_240": "వనాటు",
    "country_241": "వాటికన్ నగరం (పవిత్ర చూడండి)",
    "country_242": "వెనిజులా",
    "country_243": "వియత్నాం",
    "country_244": "వాలిస్ మరియు ఫుటునా",
    "country_245": "పశ్చిమ సహారా",
    "country_246": "యెమెన్",
    "country_247": "జాంబియా",
    "country_248": "జింబాబ్వే",
    "google_login": "Googleతో సైన్ ఇన్ చేయండి",
    "State": "రాష్ట్రం",
    "Activation_code": "ఆక్టివేషన్ కోడ్",
    "Question": "మీరు లాగిన్ చేసిన అన్ని యాప్‌లను జాబితా చేయండి",
    "Copy_complete": "కాపీ పూర్తయింది",
    "footer": "కాపీరైట్ © 2024 MindOnMap స్టూడియో. అన్ని హక్కులు ప్రత్యేకించబడ్డాయి.",
    "change_password_success": "పాస్‌వర్డ్ విజయవంతంగా మార్చబడింది",
    "successful_login_title": "లాగిన్ విజయం",
    "product_page": "ఉత్పత్తి పేజీ >>",
    "successful_login_info": "లాగిన్ పూర్తయింది. దయచేసి కింది ప్రక్రియను కొనసాగించడానికి ప్రస్తుత పేజీని మూసివేసి, అసలు ట్యాబ్‌కు తిరిగి వెళ్లండి. ప్రస్తుత పేజీ 5 సెకన్లలో స్వయంచాలకంగా మూసివేయబడుతుంది. \"పూర్తయింది\" బటన్‌ను క్లిక్ చేయడం ద్వారా స్వయంచాలకంగా మూసివేయడం లేదా మూసివేయడం విఫలమైతే, దయచేసి ఈ ట్యాబ్‌ను నేరుగా మూసివేయండి.",
    "successful_login_info_firefox": "లాగిన్ పూర్తయింది. దయచేసి కింది ప్రక్రియను కొనసాగించడానికి ప్రస్తుత పేజీని మూసివేసి, అసలు ట్యాబ్‌కు తిరిగి వెళ్లండి.",
    "my_account": "నా ఖాతా",
    "my_history": "నా చరిత్ర",
    "remove_watermark": "వాటర్‌మార్క్‌ని తీసివేయండి",
    "no_history": "చరిత్ర లేదు",
    "history_all": "అన్ని ఎంచుకోండి",
    "history_open": "తెరవండి",
    "history_down": "డౌన్‌లోడ్ చేయండి",
    "history_delete": "తొలగించు",
    "history_clear": "చెల్లనిది క్లియర్ చేయండి",
    "images": "చిత్రం(లు)",
    "use_this_function": "ఈ ఉత్పత్తిని ఉపయోగించండి>>",
    "hd_downloading": "HD అసలైన చిత్రాలను డౌన్‌లోడ్ చేయడం వల్ల ప్రయోజనాలు:",
    "lifetimeRemaining": "జీవితకాలం-మిగిలినది",
    "Remaining": "మిగిలింది",
    "email_verification": "ఇమెయిల్ ధృవీకరణ",
    "email_verification_info": "మేము ధృవీకరణ సందేశాన్ని మీ ఇమెయిల్ <span class=\"email\"></span>కి పంపాము మరియు దయచేసి ధృవీకరణను పూర్తి చేయండి. ధృవీకరించిన తర్వాత, ప్రయోజనాలు స్వయంచాలకంగా సమకాలీకరించబడతాయి.",
    "wrong_email": "ఇమెయిల్ చిరునామా తప్పు?",
    "click_here_to_modify": "సవరించడానికి ఇక్కడ క్లిక్ చేయండి",
    "get_more_help": "మరింత సహాయం పొందాలా?",
    "click_here": "ఇక్కడ నొక్కండి",
    "email_verification_info_success": "మీ ఇమెయిల్ ఖాతాను ధృవీకరించినందుకు అభినందనలు.",
    "email_verification_info_error": "గడువు ముగిసిన లింక్ కారణంగా ధృవీకరణ విఫలమైంది.",
    "registration_succeeded": "నమోదు విజయవంతమైంది",
    "registration_succeeded_info_1": "అభినందనలు! మీరు విజయవంతంగా నమోదు చేయబడ్డారు. మేము ధృవీకరణ సందేశాన్ని మీ ఇమెయిల్ <span class=\"email\"></span>కి పంపాము మరియు ఈ ఇమెయిల్ ప్రయోజనాలను ఉపయోగించడానికి దయచేసి ధృవీకరించడం పూర్తి చేయండి.",
    "registration_succeeded_info_2": "హోమ్ పేజీకి తిరిగి వచ్చి ఈ ఉత్పత్తిని ఉపయోగించడానికి \"పూర్తయింది\" క్లిక్ చేయండి.",
    "registration_succeeded_info_3": "ప్రస్తుత పేజీని మూసివేయడానికి \"పూర్తయింది\" క్లిక్ చేయండి మరియు క్రింది కార్యకలాపాల కోసం హోమ్ పేజీకి తిరిగి వెళ్లండి. మూసివేయడం విఫలమైతే, దయచేసి ట్యాబ్‌ను మాన్యువల్‌గా మూసివేయండి.",
    "verify_email": "ఇమెయిల్ నిర్ధారించండి",
    "registered_email_not_verified": "నమోదిత ఇమెయిల్ ధృవీకరించబడలేదు, దయచేసి దాన్ని ఒకేసారి ధృవీకరించండి.",
    "email_verification_time_1": "ధృవీకరణ ఇమెయిల్‌ని అందుకోలేదా?",
    "email_verification_time_2": "తర్వాత",
    "email_verification_time_3": "దీన్ని మళ్లీ పంపడానికి ఇక్కడ క్లిక్ చేయండి",
    "error_26301": "ఎర్రర్ కోడ్: 26301, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26302": "ఎర్రర్ కోడ్: 26302, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26303": "ఇమెయిల్ ఫార్మాట్ లోపం (ఎర్రర్ కోడ్: 26303). దయచేసి దాన్ని మళ్లీ నమోదు చేయండి",
    "error_26304": "8 అక్షరాల కంటే ఎక్కువ పాస్‌వర్డ్ సిఫార్సు చేయబడింది (ఎర్రర్ కోడ్: 26304)",
    "error_26305": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26305). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26306": "ఇమెయిల్ నమోదు చేయబడింది, దయచేసి <a href=\"https://account.mindonmap.com/login/\">లాగిన్ చేయడానికి వెళ్లండి</a>",
    "error_26307": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26307). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26308": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26308). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26401": "ఎర్రర్ కోడ్: 26401, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26402": "ఇమెయిల్ ధృవీకరించబడింది (ఎర్రర్ కోడ్: 26402), దయచేసి దాన్ని మళ్లీ ప్రయత్నించండి",
    "error_26403": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26403). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26404": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 26404). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26501": "ఎర్రర్ కోడ్: 26501, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26502": "ఎర్రర్ కోడ్: 26502, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26503": "ఇమెయిల్ ఫార్మాట్ లోపం (ఎర్రర్ కోడ్: 26503). దయచేసి దాన్ని మళ్లీ నమోదు చేయండి",
    "error_26504": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26504). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26505": "ఇమెయిల్ నమోదు చేయబడలేదు, దయచేసి <a href=\"https://account.mindonmap.com/register/\">దీన్ని ముందుగా నమోదు చేసుకోండి</a>",
    "error_26506": "ఇమెయిల్ ధృవీకరించబడింది.",
    "error_26507": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26507). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26508": "వెరిఫికేషన్ ఫైల్ చేయబడింది (ఎర్రర్ కోడ్: 26508), దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26509": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 26509), దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26510": "ఎర్రర్ కోడ్: 26510, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26601": "ఎర్రర్ కోడ్: 26601, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26602": "ఎర్రర్ కోడ్: 26602, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26603": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26603). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26604": "ఎర్రర్ కోడ్: 26604, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26605": "ఎర్రర్ కోడ్: 26605, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26701": "ఎర్రర్ కోడ్: 26701, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26702": "Reuquest విఫలమైంది (ఎర్రర్ కోడ్: 26702). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26703": "ఎర్రర్ కోడ్: 26703, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26704": "ఎర్రర్ కోడ్: 26704, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26705": "లాగిన్ కోసం వేచి ఉండండి (ఎర్రర్ కోడ్: 26705). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "no_cookie": "మీరు మీ బ్రౌజర్‌లో అన్ని కుక్కీలను బ్లాక్ చేయి ఫంక్షన్‌ని ఆన్ చేసారు, కాబట్టి మీరు లాగిన్ చేయలేరు. దయచేసి అన్ని కుక్కీలను అనుమతించు అనే పెట్టెను తనిఖీ చేయడానికి సెట్టింగ్‌లకు వెళ్లండి.",
    "error_26801": "ఎర్రర్ కోడ్: 26801, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26802": "ఎర్రర్ కోడ్: 26802, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26803": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 26803). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26804": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 26804). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_order": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27098), దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి!",
    "error_order1": "ఆర్డర్ ప్రశ్న అసంపూర్ణంగా ఉంది (ఎర్రర్ కోడ్: ",
    "error_order2": "), దయచేసి రిఫ్రెష్ చేసి, మళ్లీ ప్రయత్నించండి.",
    "modify_email_title": "ఇమెయిల్‌ని సవరించండి",
    "modify_email_info": "మీరు మీ ఖాతాలోకి లాగిన్ చేయడానికి సవరించిన ఇమెయిల్‌ను ఉపయోగించవచ్చు.",
    "images_per": "ప్రతి చిత్రాలు",
    "error_26101": "లోపం: 26101. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26102": "లోపం: 26102. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26103": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్:26103). దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_26104": "ఎర్రర్ కోడ్: 26104, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26105": "ఎర్రర్ కోడ్: 26105, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26106": "తొలగించడం విఫలమైంది (ఎర్రర్ కోడ్: 26106). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26201": "లోపం: 26201. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26202": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్:26202). దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_26001": "లోపం: 26001. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26002": "లోపం: 26002. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26003": "లోపం: 26003. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26004": "లోపం: 26004. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_26005": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్:26005). దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_26006": "ఎర్రర్ కోడ్: 26006, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_26008": "లోపం: 26008. దయచేసి మమ్మల్ని సంప్రదించండి",
    "go_to_the_home_page": "హోమ్ పేజీకి వెళ్లండి",
    "error_27101": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27101). దయచేసి మళ్లీ ప్రయత్నించండి",
    "error_27201": "ఎర్రర్ కోడ్: 27201, దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_27202": "ఎర్రర్ కోడ్: 27202, దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_27203": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27203). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_27204": "చెల్లని కోడ్ (ఎర్రర్ కోడ్: 27204).",
    "error_27205": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27205). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_27206": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27206). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_27207": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 27207). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "no_history_found": "మీరు ఏ సాధనాన్ని ఉపయోగించలేదు! <a href=\"/\">రిఫ్రెష్</a> లేదా <a href=\"https://www.mindonmap.com/\">అధికారిక వెబ్‌సైట్‌కి వెళ్లండి</a>",
    "error_25301": "లోపం: 25301. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_25302": "లోపం: 25302. దయచేసి మమ్మల్ని సంప్రదించండి",
    "error_25303": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 25303). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_25304": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 25304). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_25305": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 25305). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "error_25306": "అభ్యర్థన విఫలమైంది (ఎర్రర్ కోడ్: 25306). దయచేసి దీన్ని మళ్లీ ప్రయత్నించండి",
    "image_upscaler_p": "వాటర్‌మార్క్ లేని చిత్రం డౌన్‌లోడ్ ప్రయోజనాలు:",
    "Available_for": "అందుబాటులో:",
    "credit_per": "ప్రతి HD చిత్రానికి %s క్రెడిట్(లు).",
    "still_valid": "కొనుగోలు చేసిన ప్లాన్(లు) ఇప్పటికీ చెల్లుబాటులో ఉన్నాయి",
    "credit": "క్రెడిట్(లు)",
    "pc_3rd_info": "విజయవంతంగా లాగిన్ చేయండి. తదుపరి ఆపరేషన్ కోసం దయచేసి అప్లికేషన్‌కి వెళ్లండి.",
    "use_online": "ఆన్‌లైన్ సేవను ఉపయోగించండి",
    "use_download": "డెస్క్‌టాప్ ప్రోగ్రామ్‌ను ఉపయోగించండి",
    "use_immediately": "వెంటనే ఉపయోగించండి",
    "Use_in_browser": "బ్రౌజర్‌లో ఉపయోగించండి",
    "win_desktop": "విండోస్",
    "Mac_desktop": "Mac",
    "credits_per": "నెలకు {%} క్రెడిట్‌లు",
    "expire": "గడువు సమయం:",
    "viewDetails": "వివరాలను వీక్షించండి",
    "viewHistory": "చరిత్ర ప్రయోజనాలను వీక్షించండి>>",
    "viewDetailsInfo": "నోటీసు: గడువు ముగిసిన 7 రోజులలోపు సభ్యత్వ ప్రయోజనాలను పునరుద్ధరించినట్లయితే, ఉపయోగించని ప్రయోజనాలను ఉపయోగించడం కొనసాగించవచ్చు. అలాగే, గడువు ముగింపు సమయం స్వయంచాలకంగా కొత్త సభ్యత్వం యొక్క గడువు సమయానికి నవీకరించబడుతుంది. 7 రోజుల గడువు ముగిసిన తర్వాత కొత్త సబ్‌స్క్రిప్షన్ లేకపోతే, గడువు ముగిసిన అన్ని ప్రయోజనాలు క్లియర్ చేయబడతాయి.",
    "connect_account": "మీ ఖాతాకు ఇమెయిల్‌ను బైండ్ చేయండి",
    "connect_account_info": "బైండింగ్ తర్వాత, మీరు ఈ ఇమెయిల్ చిరునామాతో లాగిన్ చేయవచ్చు.",
    "connect_now": "ఇప్పుడు బంధించండి",
    "no_email_bind": "ఇమెయిల్ బైండ్ లేదు",
    "bind_email": "బైండ్ ఇమెయిల్",
    "connect_your_email_placeholder": "దయచేసి మీ ఇమెయిల్ చిరునామాను నమోదు చేయండి",
    "bind_an_email": "ఒక ఇమెయిల్‌ను బైండ్ చేయండి",
    "bind_info": "మీరు విజయవంతంగా లాగిన్ చేసారు. దయచేసి మీ ప్రయోజనాలను సక్రియం చేయడానికి మీ ఖాతాకు ఇమెయిల్‌ను జత చేయండి.",
    "bind_later": "తర్వాత కట్టుకోండి",
    "hi": "తన!",
    "Personal_Information": "వ్యక్తిగత సమాచారం",
    "Access": "యాక్సెస్",
    "Subscription_Plan": "(చందా ప్రణాళిక)",
    "No_orders": "ఆర్డర్‌లు ఏవీ కనుగొనబడలేదు.",
    "No_data": "సమాచారం లేదు",
    "Featured_Products": "ఫీచర్ చేయబడిన ఉత్పత్తులు",
    "More_Products": "మరిన్ని ఉత్పత్తులు",
    "Free_Download": "ఉచిత డౌన్లోడ్",
    "Get_Started": "ప్రారంభించడానికి",
    "Subscribe": "సభ్యత్వం పొందండి",
    "Verified": "ధృవీకరించబడింది",
    "back_to_account_center": "ఖాతా కేంద్రానికి తిరిగి వెళ్ళు",
    "success": "విజయం!",
    "successfully": "మీరు విజయవంతంగా ఖాతాను నమోదు చేసారు.",
    "Continue": "కొనసాగించు",
    "Already": "ఖాతా కలిగి ఉన్నారా?",
    "loading_verification": "ధృవీకరణ స్థితిని తనిఖీ చేస్తోంది...",
    "email_no_verification": "క్షమించండి, నమోదు చేయబడిన ఇమెయిల్ చిరునామా ధృవీకరించబడలేదు. దయచేసి పై సూచనల ప్రకారం ధృవీకరణను పూర్తి చేసి, రిజిస్ట్రేషన్‌ను పూర్తి చేయడానికి \"పూర్తయింది\" బటన్‌ను మళ్లీ క్లిక్ చేయండి.",
    "will_expire_after": "తర్వాత గడువు ముగుస్తుంది",
    "hours": "గంటలు",
  },
  "th": {
    "overtime": "รหัสข้อผิดพลาด: {%} โปรดลองอีกครั้ง",
    "isnetwork": "อินเทอร์เน็ตผิดพลาด โปรดตรวจสอบและลองอีกครั้ง",
    "email_placeholder": "อีเมล",
    "email_empty": "กรุณากรอกอีเมล์",
    "email_not_valid": "อีเมลไม่ถูกต้อง",
    "email_not_valid_1": "กรุณากรอกอีเมล์ของคุณ",
    "email_not_valid_2": "อีเมลไม่ถูกต้อง โปรดใช้ที่อยู่อื่น",
    "email_not_valid_3": "ไม่มีการป้อนอีเมล",
    "password_placeholder": "รหัสผ่าน",
    "password_empty": "กรุณาใส่รหัสผ่าน",
    "password_not_valid": "บัญชีหรือรหัสผ่านไม่ถูกต้อง",
    "password_not_valid_1": "ต้องใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร",
    "password_not_valid_2": "กรุณาสร้างรหัสผ่าน",
    "password_placeholder_1": "สร้างรหัสผ่าน",
    "password_placeholder_2": "ยืนยันรหัสผ่านของคุณ",
    "password_placeholder_3": "สร้างรหัสผ่านใหม่",
    "password_placeholder_4": "ยืนยันรหัสผ่านใหม่",
    "password_placeholder_5": "ใส่รหัสผ่านเก่า",
    "copy_password_empty": "กรุณายืนยันรหัสผ่าน",
    "copy_password_not_valid": "กรุณายืนยันรหัสผ่านของคุณ",
    "copy_passwords_inconsistent": "การยืนยันรหัสผ่านของคุณไม่ตรงกัน",
    "code_empty": "กรุณากรอกรหัสยืนยัน",
    "code_not_valid": "รหัสยืนยันไม่ถูกต้อง",
    "code_placeholder": "รหัสยืนยัน",
    "not_received_code": "หากกล่องจดหมายของคุณไม่ได้รับรหัสยืนยันเป็นเวลานาน โปรดรับรหัสยืนยันอีกครั้ง",
    "get_first_code": "กรุณารับรหัสยืนยันก่อน",
    "last_name_placeholder": "กรุณากรอกนามสกุลของคุณ",
    "first_name_placeholder": "กรุณากรอกชื่อของคุณ",
    "address_placeholder": "กรุณากรอกที่อยู่ของคุณ",
    "no_code_text": "เราได้ส่งรหัสยืนยันแล้ว กรุณากรอกรหัสของคุณ <span class='tips'>ไม่ได้รับรหัสใช่ไหม",
    "no_code_text_1": "1. โปรดตรวจสอบให้แน่ใจว่าที่อยู่อีเมลนั้นถูกต้องและสามารถรับอีเมลได้",
    "no_code_text_2": "2. สำหรับอีเมลที่ระบบส่งโดยอัตโนมัติอาจถูกทำเครื่องหมายว่าเป็นสแปมหรืออีเมลขยะ โปรดตรวจสอบว่าอีเมลอยู่ในโฟลเดอร์ถังขยะหรือไม่",
    "no_code_text_3": "3. ไม่สามารถแก้ปัญหาของคุณได้? ",
    "no_code_text_3_span": "จากนั้นคลิกที่นี่เพื่อติดต่อเรา",
    "order_no": "คุณยังไม่ได้ซื้อผลิตภัณฑ์ใด ๆ หากคุณมีคำถามใด ๆ โปรด<a href=\"https://www.mindonmap.com/contact-us/\">ติดต่อเรา</a>",
    "error_24901": "บัญชีปัจจุบันไม่มีการเชื่อมโยงอีเมล และไม่พบคำสั่งซื้อ กรุณาเชื่อมต่ออีเมล์",
    "user_guide": "คู่มือผู้ใช้>>",
    "download": "ดาวน์โหลด",
    "order_number": "หมายเลขคำสั่งซื้อ:",
    "Refund": "คืนเงิน",
    "Disabled": "พิการ",
    "Normal": "ปกติ",
    "Modify": "แก้ไข",
    "Modify_1": "แก้ไข>>",
    "Connect": "เชื่อมต่อ",
    "unlink_success": "ยกเลิกการเชื่อมโยงเรียบร้อยแล้ว",
    "connect_success": "เชื่อมต่อสำเร็จ",
    "feedback_title": "ขอบคุณสำหรับคำติชมของคุณ กรุณาทิ้งปัญหาของคุณไว้ แล้วเราจะตอบกลับคุณภายใน 24 ชั่วโมง",
    "feedback_thank_you": "ขอขอบคุณ!<br />คำติชมของคุณถูกส่งเรียบร้อยแล้ว",
    "feedback_email": "ใส่อีเมลของคุณที่นี่!",
    "feedback_content": "ฝากปัญหาหรือข้อเสนอแนะที่คุณพบไว้ที่นี่",
    "feedback_submit": "ส่ง",
    "form_contents": "คุณยังไม่ได้ป้อนคำอธิบายใดๆ กรุณากรอกและส่งอีกครั้ง",
    "old_password": "กรุณาใส่รหัสผ่านเก่า",
    "new_password": "กรุณาสร้างรหัสผ่านใหม่",
    "old_new_password": "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเก่า",
    "incorrect_password": "รหัสผ่านผิดพลาด",
    "delete_no": "ลบตอนนี้",
    "Caps": "Caps Lock เปิดอยู่",
    "Get": "รับ",
    "Done": "เสร็จแล้ว",
    "error_20001": "ข้อผิดพลาด: 20001 กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20002": "ข้อผิดพลาด: 20002 กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20003": "ข้อผิดพลาด: 20003 กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20004": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20004) โปรดลองอีกครั้ง",
    "error_20005": "เซสชันการเข้าสู่ระบบหมดอายุแล้ว (ข้อผิดพลาด: 20005) กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20006": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20006) โปรดลองอีกครั้ง",
    "error_20007": "เซสชันการเข้าสู่ระบบหมดอายุแล้ว (ข้อผิดพลาด: 20007) กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20008": "เซสชันการเข้าสู่ระบบหมดอายุแล้ว (ข้อผิดพลาด: 20008) กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20009": "เซสชันการเข้าสู่ระบบหมดอายุแล้ว (ข้อผิดพลาด: 20009) กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_20101": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20101)",
    "error_20102": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20102)",
    "error_20103": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20103) โปรดลองอีกครั้ง",
    "error_20104": "มีการใช้อีเมลนี้แล้ว โปรด<a href=\"https://account.mindonmap.com/login/\">เข้าสู่ระบบ</a>หรือลงทะเบียนด้วยอีเมลใหม่",
    "error_20105": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20105) โปรดลองอีกครั้ง",
    "error_20106": "ไม่สามารถส่งอีเมลได้ โปรดลองอีกครั้ง",
    "error_20201": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20201)",
    "error_20202": "กรุณากรอกรหัสผ่านของคุณ (รหัสข้อผิดพลาด: 20202)",
    "error_20203": "กรุณากรอกรหัสยืนยัน (รหัสข้อผิดพลาด: 20203)",
    "error_20204": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20204)",
    "error_20205": "ต้องใช้รหัสผ่านมากกว่า 8 ตัวอักษร (รหัสข้อผิดพลาด: 20205)",
    "error_20206": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20206) โปรดลองอีกครั้ง",
    "error_20207": "รหัสยืนยันไม่ถูกต้อง",
    "error_20208": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20208) โปรดลองอีกครั้ง",
    "error_20209": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20209) โปรดลองอีกครั้ง",
    "error_20301": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20301)",
    "error_20302": "ข้อผิดพลาด: 20302 โปรดติดต่อเรา",
    "error_20303": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20303)",
    "error_20304": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20304) โปรดลองอีกครั้ง",
    "error_20305": "บัญชีไม่มีอยู่ โปรดป้อนใหม่หรือ<a href=\"https://account.mindonmap.com/register/\">สร้างใหม่</a>ก่อน",
    "error_20306": "ยังไม่มีรหัสผ่าน ใช้ <a href=\"https://account.mindonmap.com/passwordless-login/\">เข้าสู่ระบบแบบไร้รหัสผ่าน</a> หรือ <a href=\"https://account.mindonmap.com/create-password/\"> ตั้งรหัสผ่าน</a>และเข้าสู่ระบบ",
    "error_20308": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20308) โปรดลองอีกครั้ง",
    "error_20401": "ไม่สามารถออกจากระบบได้ (รหัสข้อผิดพลาด: 20401) โปรดลองอีกครั้ง",
    "error_20501": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20501)",
    "error_20502": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20502)",
    "error_20503": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20503) โปรดลองอีกครั้ง",
    "error_20504": "ไม่สามารถส่งอีเมลได้ โปรดลองอีกครั้ง",
    "error_20601": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20601)",
    "error_20602": "กรุณากรอกรหัสยืนยัน (รหัสข้อผิดพลาด: 20602)",
    "error_20603": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20603)",
    "error_20604": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20604) โปรดลองอีกครั้ง",
    "error_20606": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20606) โปรดลองอีกครั้ง",
    "error_20607": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20607) โปรดลองอีกครั้ง",
    "error_20608": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20608) โปรดลองอีกครั้ง",
    "error_20701": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20701)",
    "error_20702": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20702)",
    "error_20703": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20703) โปรดลองอีกครั้ง",
    "error_20704": "บัญชีไม่มีอยู่ โปรดป้อนใหม่หรือ<a href=\"https://account.mindonmap.com/register/\">สร้างใหม่</a>ก่อน",
    "error_20705": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20705) โปรดลองอีกครั้ง",
    "error_20706": "ไม่สามารถส่งอีเมลได้ โปรดลองอีกครั้ง",
    "error_20801": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 20801)",
    "error_20802": "ข้อผิดพลาด: 20802 โปรดติดต่อเรา",
    "error_20803": "กรุณากรอกรหัสยืนยัน (รหัสข้อผิดพลาด: 20803)",
    "error_20804": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 20804)",
    "error_20805": "ต้องใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร (รหัสข้อผิดพลาด: 20805)",
    "error_20806": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20806) โปรดลองอีกครั้ง",
    "error_20808": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20808) โปรดลองอีกครั้ง",
    "error_20901": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20901) โปรดลองอีกครั้ง",
    "error_20902": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 20902) โปรดลองอีกครั้ง",
    "error_21000": "บันทึกการเปลี่ยนแปลงแล้ว",
    "error_21001": "ไม่มีการส่งข้อมูล (รหัสข้อผิดพลาด: 21001)",
    "error_21002": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21002) โปรดลองอีกครั้ง",
    "error_21101": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 21101)",
    "error_21102": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 21102)",
    "error_21103": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21103) โปรดลองอีกครั้ง",
    "error_21104": "เชื่อมต่ออีเมลแล้ว โปรดใช้อีเมลใหม่",
    "error_21105": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21105) โปรดลองอีกครั้ง",
    "error_21106": "ไม่สามารถส่งอีเมลได้ โปรดลองอีกครั้ง",
    "error_21201": "กรุณากรอกอีเมล์ของคุณ (รหัสข้อผิดพลาด: 21201)",
    "error_21202": "กรุณากรอกรหัสยืนยัน (รหัสข้อผิดพลาด: 21202)",
    "error_21203": "อีเมลไม่ถูกต้อง (รหัสข้อผิดพลาด: 21203)",
    "error_21204": "ข้อผิดพลาด: 21204 โปรดติดต่อเรา",
    "error_21205": "ข้อผิดพลาด: 21205 โปรดติดต่อเรา",
    "error_21206": "ต้องใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร (รหัสข้อผิดพลาด: 21206)",
    "error_21207": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21207) โปรดลองอีกครั้ง",
    "error_21209": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21209) โปรดลองอีกครั้ง",
    "error_21301": "กรุณาใส่รหัสผ่านเก่า (รหัสข้อผิดพลาด: 21301)",
    "error_21302": "กรุณาสร้างรหัสผ่านใหม่ (รหัสข้อผิดพลาด: 21302)",
    "error_21303": "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเก่า (ข้อผิดพลาด: 21303)",
    "error_21304": "ต้องใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร (รหัสข้อผิดพลาด: 21304)",
    "error_21306": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21306) โปรดลองอีกครั้ง",
    "error_21402": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21402) โปรดลองอีกครั้ง",
    "error_21403": "ไม่สามารถส่งรหัสยืนยันได้ กรุณาส่งอีกครั้ง",
    "error_21500": "บัญชีถูกลบแล้ว",
    "error_21501": "กรุณากรอกรหัสยืนยัน (รหัสข้อผิดพลาด: 21501)",
    "error_21502": "เซสชันการเข้าสู่ระบบหมดอายุแล้ว (ข้อผิดพลาด: 21502) กรุณาเข้าสู่ระบบอีกครั้ง",
    "error_21503": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21503) โปรดลองอีกครั้ง",
    "error_21505": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21505) โปรดลองอีกครั้ง",
    "error_21601": "ข้อผิดพลาด: 20601 โปรดติดต่อเรา",
    "error_21602": "การยืนยันไม่ถูกต้อง (ข้อผิดพลาด: 20602) โปรดลองอีกครั้ง",
    "error_21603": "ข้อผิดพลาด: 20603 โปรดลองอีกครั้ง",
    "error_21604": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21604) โปรดลองอีกครั้ง",
    "error_21606": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21606) โปรดลองอีกครั้ง",
    "error_21611": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21611) โปรดลองอีกครั้ง",
    "error_21801": "ข้อผิดพลาด: 21801 โปรดติดต่อเรา",
    "error_21802": "คำขอล้มเหลว (ข้อผิดพลาด: 21802) โปรดลองอีกครั้ง",
    "error_21803": "ข้อผิดพลาด: 21803 โปรดลองอีกครั้ง",
    "error_21804": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21804) โปรดลองอีกครั้ง",
    "error_21806": "ข้อผิดพลาด: 21806 โปรดลองอีกครั้ง",
    "error_21807": "ข้อผิดพลาด: 21807 โปรดติดต่อเรา",
    "error_21808": "ข้อผิดพลาด: 21808 โปรดติดต่อเรา",
    "error_21809": "ข้อผิดพลาด: 21809 โปรดติดต่อเรา",
    "error_21810": "ข้อผิดพลาด: 21810 โปรดติดต่อเรา",
    "error_21811": "ข้อผิดพลาด: 21811 โปรดติดต่อเรา",
    "error_21812": "ข้อผิดพลาด: 21812 โปรดติดต่อเรา",
    "error_21813": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21813) โปรดลองอีกครั้ง",
    "error_21814": "ข้อผิดพลาด: 21814 โปรดติดต่อเรา",
    "error_21815": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21815) โปรดลองอีกครั้ง",
    "error_21816": "ข้อผิดพลาด: 21816 โปรดติดต่อเรา",
    "error_21817": "ข้อผิดพลาด: 21817 โปรดติดต่อเรา",
    "error_21818": "ข้อผิดพลาด: 21818 โปรดติดต่อเรา",
    "error_21819": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21819) โปรดลองอีกครั้ง",
    "error_21820": "ข้อผิดพลาด: 21820 โปรดติดต่อเรา",
    "error_21821": "ข้อผิดพลาด: 21821 โปรดติดต่อเรา",
    "error_21822": "ข้อผิดพลาด: 21822 โปรดติดต่อเรา",
    "error_21823": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21823) โปรดลองอีกครั้ง",
    "error_21824": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21824) โปรดลองอีกครั้ง",
    "error_21825": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21825) โปรดลองอีกครั้ง",
    "error_21826": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21826) โปรดลองอีกครั้ง",
    "error_21901": "ข้อผิดพลาด: 21901 โปรดติดต่อเรา",
    "error_21902": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21902) โปรดลองอีกครั้ง",
    "error_21903": "สถานะบัญชีมีการเปลี่ยนแปลง (รหัสข้อผิดพลาด: 21903) โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "error_21904": "ข้อผิดพลาด: 21904 โปรดลองอีกครั้ง",
    "error_21905": "ข้อผิดพลาด: 21905 โปรดลองอีกครั้ง",
    "error_21906": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21906) โปรดลองอีกครั้ง",
    "error_21907": "บัญชี Google เชื่อมโยงกับบัญชีอื่นแล้ว",
    "error_21908": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 21908) โปรดลองอีกครั้ง",
    "error_22001": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22001) โปรดลองอีกครั้ง",
    "error_22002": "การยกเลิกการเชื่อมโยงล้มเหลวโดยไม่มีการเข้าสู่ระบบเพิ่มเติม",
    "error_22003": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22003) โปรดลองอีกครั้ง",
    "error_22101": "ข้อผิดพลาด: 22101 โปรดติดต่อเรา",
    "error_22102": "สถานะบัญชีมีการเปลี่ยนแปลง (รหัสข้อผิดพลาด: 22102) โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "error_22103": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22103) โปรดลองอีกครั้ง",
    "error_22104": "สถานะบัญชีมีการเปลี่ยนแปลง (รหัสข้อผิดพลาด: 22104) โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "error_22105": "ข้อผิดพลาด: 22105 โปรดลองอีกครั้ง",
    "error_22106": "ข้อผิดพลาด: 22106 โปรดลองอีกครั้ง",
    "error_22107": "ข้อผิดพลาด: 22107 โปรดติดต่อเรา",
    "error_22108": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22108) โปรดลองอีกครั้ง",
    "error_22201": "ข้อผิดพลาด: 22201 โปรดติดต่อเรา",
    "error_22202": "การยืนยันไม่ถูกต้อง (ข้อผิดพลาด: 22202) โปรดลองอีกครั้ง",
    "error_22203": "ข้อผิดพลาด: 22203 โปรดลองอีกครั้ง\"",
    "error_22204": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22204) โปรดลองอีกครั้ง",
    "error_22206": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22206) โปรดลองอีกครั้ง",
    "error_22401": "ข้อผิดพลาด: 22401 โปรดติดต่อเรา",
    "error_22402": "การยืนยันไม่ถูกต้อง (ข้อผิดพลาด: 22402) โปรดลองอีกครั้ง",
    "error_22403": "ข้อผิดพลาด: 22403 โปรดลองอีกครั้ง",
    "error_22404": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22404) โปรดลองอีกครั้ง",
    "error_22405": "บัญชี Facebook เชื่อมโยงกับอีเมลอื่นแล้ว",
    "error_22406": "ข้อผิดพลาด: 22406 โปรดลองอีกครั้ง",
    "error_22407": "ข้อผิดพลาด: 22407 โปรดติดต่อเรา",
    "error_22408": "ข้อผิดพลาด: 22408 โปรดติดต่อเรา",
    "error_22409": "ข้อผิดพลาด: 22409 โปรดติดต่อเรา",
    "error_22410": "ข้อผิดพลาด: 224010 โปรดติดต่อเรา",
    "error_22411": "ข้อผิดพลาด: 224011 โปรดติดต่อเรา",
    "error_22412": "ข้อผิดพลาด: 224012 โปรดติดต่อเรา",
    "error_22413": "ข้อผิดพลาด: 22413 โปรดติดต่อเรา",
    "error_22414": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22414) โปรดลองอีกครั้ง",
    "error_22415": "ข้อผิดพลาด: 22415 โปรดติดต่อเรา",
    "error_22416": "ข้อผิดพลาด: 22416 โปรดติดต่อเรา",
    "error_22417": "ข้อผิดพลาด: 22417 โปรดติดต่อเรา",
    "error_22418": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22418) โปรดลองอีกครั้ง",
    "error_22419": "ข้อผิดพลาด: 22419 โปรดติดต่อเรา",
    "error_22420": "ข้อผิดพลาด: 22420 โปรดติดต่อเรา",
    "error_22421": "ข้อผิดพลาด: 22421 โปรดติดต่อเรา",
    "error_22422": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22422) โปรดลองอีกครั้ง",
    "error_22423": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22423) โปรดลองอีกครั้ง",
    "error_22424": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22424) โปรดลองอีกครั้ง",
    "error_22425": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 22425) โปรดลองอีกครั้ง",
    "error_20098": "รหัสข้อผิดพลาด: 20098 หากคุณกำลังเรียกดูในโหมดส่วนตัว โปรดสลับไปที่โหมดปกติแล้วลองอีกครั้ง",
    "error_22298": "คำขอ Google ล้มเหลว (ข้อผิดพลาด: 22298) โปรดลองอีกครั้ง",
    "error_22498": "คำขอ Facebook ล้มเหลว (ข้อผิดพลาด: 22498) โปรดลองอีกครั้ง",
    "error_24902": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 24902) โปรดลองอีกครั้ง",
    "error_24903": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 24903) โปรดลองอีกครั้ง",
    "error_24904": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 24904) โปรดลองอีกครั้ง",
    "error_24905": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 24905) โปรดลองอีกครั้ง",
    "login_title": "เข้าสู่ระบบ MindOnMap",
    "log_in": "เข้าสู่ระบบ",
    "no_account": "ไม่มีบัญชี?",
    "create_it": "สร้างมันขึ้นมา",
    "or_log_in_with": "หรือเข้าสู่ระบบด้วย",
    "passwordless_login": "เข้าสู่ระบบแบบไร้รหัสผ่าน",
    "log_in_done": "เข้าสู่ระบบ เสร็จสิ้น",
    "three_rd_account_connect_info": "ยินดีด้วย! คุณเข้าสู่ระบบเรียบร้อยแล้ว ตอนนี้คุณสามารถเชื่อมต่อบัญชีอีเมลหนึ่งบัญชีที่จะใช้ในการเข้าสู่ระบบในอนาคตได้",
    "see_my_account": "ดูบัญชีของฉัน",
    "three_rd_login_merge_account": "ที่อยู่อีเมลของบัญชีบุคคลที่สามได้รับการลงทะเบียนแล้ว คุณต้องการเชื่อมต่อและเข้าสู่ระบบด้วยที่อยู่อีเมลนี้โดยตรงหรือไม่",
    "connect_log_in": "เชื่อมต่อและเข้าสู่ระบบ",
    "create_an_account": "สร้างบัญชี",
    "back_to_log_in": "กลับไปเข้าสู่ระบบ",
    "create_password": "สร้างรหัสผ่าน",
    "create_now": "สร้างตอนนี้",
    "password_login_subtitle": "เข้าสู่ระบบแบบไร้รหัสผ่านด้วยอีเมล",
    "account_login": "ลงชื่อเข้าใช้",
    "rights": "โดยการสร้างบัญชีนี้ แสดงว่าคุณยอมรับ<a href=\"https://www.mindonmap.com/terms-and-conditions/\">ข้อกำหนดในการให้บริการ</a> และ <a href=\"https://www.mindonmap.com/privacy-policy/\">นโยบายความเป็นส่วนตัว</a>",
    "passwordless_login_done": "เข้าสู่ระบบแบบไร้รหัสผ่านเสร็จแล้ว",
    "passwordless_login_info": "ยินดีด้วย คุณเข้าสู่ระบบแบบไร้รหัสผ่านสำเร็จแล้ว คุณสามารถสร้างรหัสผ่านสำหรับบัญชีนี้และเข้าสู่ระบบด้วยบัญชีและรหัสผ่านได้ในอนาคต <a href=\"/create-password\" style=\"display: Initial;\">สร้างเลย</a>",
    "sign_up": "ลงชื่อ",
    "register_info": "สร้างบัญชีของคุณ",
    "reset_now": "รีเซ็ตทันที",
    "forgot_password": "ลืมรหัสผ่าน",
    "reset_password_subtitle": "ใช้อีเมลบัญชีของคุณเพื่อรีเซ็ตรหัสผ่าน",
    "plan_products": "แผนและผลิตภัณฑ์",
    "nick_name": "ชื่อผู้ใช้:",
    "email": "อีเมล:",
    "my_products": "สินค้าของฉัน",
    "my_orders": "คำสั่งซื้อของฉัน",
    "unlink": "ยกเลิกการเชื่อมโยง",
    "link": "ลิงค์",
    "connected_accounts": "บัญชีที่เชื่อมโยงกัน",
    "last_name": "นามสกุล:",
    "first_name": "ชื่อจริง:",
    "Gender": "เพศ:",
    "Birth": "การเกิด:",
    "Month": "เดือน",
    "Year": "ปี",
    "Country_Region": "ประเทศ/ภูมิภาค:",
    "Address": "ที่อยู่:",
    "Save": "บันทึก",
    "Date": "วันที่",
    "Male": "ชาย",
    "Female": "หญิง",
    "Unspecified": "ไม่ระบุ",
    "Security": "ความปลอดภัย",
    "change_password": "เปลี่ยนรหัสผ่าน",
    "change_now": "เปลี่ยนเลย",
    "connect_email": "เชื่อมต่ออีเมล",
    "delete_account": "ลบบัญชี",
    "delete_account_info": "เมื่อบัญชีของคุณถูกลบ ข้อมูลทั้งหมดในบัญชีของฉันที่เชื่อมโยงกับบัญชีของคุณจะถูกลบอย่างถาวร และคุณอาจไม่สามารถกู้คืนได้ เราขอแนะนำให้คุณเดินอย่างระมัดระวัง",
    "Delete": "ลบ",
    "Logout": "ออกจากระบบ",
    "my_profile": "ประวัติของฉัน",
    "guides_faqs": "คำแนะนำและคำถามที่พบบ่อย",
    "More": "มากกว่า",
    "guides": "คำแนะนำ",
    "register": "ลงทะเบียน",
    "hot_faq": "คำถามที่พบบ่อยยอดนิยม",
    "Contents": "สารบัญ:",
    "contact_us": "ติดต่อเรา>>",
    "plan": "วางแผน",
    "unregistered": "ไม่ได้ลงทะเบียน",
    "buy_more": "ซื้อเพิ่มเติม",
    "buy_again": "ซื้ออีกครั้ง",
    "buy_now": "ซื้อเลย",
    "free_no_limit": "ฟรีและไม่มีขีดจำกัด",
    "expired": "หมดอายุแล้ว",
    "lifetime": "ตลอดชีวิต",
    "remain": "ยังคง",
    "day_s": "วัน",
    "error_24801": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 24801) โปรดลองอีกครั้ง",
    "no_app_found": "ไม่พบแอป!<a href=\"/\">รีเฟรช</a> หรือ <a href=\"https://www.mindonmap.com/\">ไปที่เว็บไซต์อย่างเป็นทางการ</a>",
    "get_more": "รับเพิ่มเติม >>",
    "edit_photo": "แก้ไขรูปภาพ",
    "select_photo": "เลือกรูปภาพ",
    "change_photo": "เปลี่ยนรูป",
    "cancel": "ยกเลิก",
    "hide_password": "ซ่อนรหัสผ่าน",
    "show_password": "แสดงรหัสผ่าน",
    "zoom_in": "ขยายเข้า",
    "zoom_out": "ซูมออก",
    "rotate": "หมุน",
    "horizontal_flip": "พลิกแนวนอน",
    "vertical_flip": "พลิกแนวตั้ง",
    "country": "ประเทศ",
    "country_1": "เลือกประเทศ/ภูมิภาคของคุณ",
    "country_2": "เกาะโอลันด์",
    "country_3": "อัฟกานิสถาน",
    "country_4": "แอลเบเนีย",
    "country_5": "แอลจีเรีย",
    "country_6": "อเมริกันซามัว",
    "country_7": "อันดอร์รา",
    "country_8": "แองโกลา",
    "country_9": "แองกวิลลา",
    "country_10": "แอนตาร์กติกา",
    "country_11": "แอนติกาและบาร์บูดา",
    "country_12": "อาร์เจนตินา",
    "country_13": "อาร์เมเนีย",
    "country_14": "อารูบา",
    "country_15": "ออสเตรเลีย",
    "country_16": "ออสเตรีย",
    "country_17": "อาเซอร์ไบจาน",
    "country_18": "บาห์เรน",
    "country_19": "บังคลาเทศ",
    "country_20": "บาร์เบโดส",
    "country_21": "เบลารุส",
    "country_22": "เบลเยียม",
    "country_23": "เบลีซ",
    "country_24": "เบนิน",
    "country_25": "เบอร์มิวดา",
    "country_26": "ภูฏาน",
    "country_27": "โบลิเวีย",
    "country_28": "บอสเนีย & เฮอร์เซโกวีนา",
    "country_29": "บอตสวานา",
    "country_30": "เกาะบูเว",
    "country_31": "บราซิล",
    "country_32": "บริติชอินเดียนโอเชียนเทร์ริทอรี",
    "country_33": "หมู่เกาะบริติชเวอร์จิน",
    "country_34": "บรูไน",
    "country_35": "บัลแกเรีย",
    "country_36": "บูร์กินา",
    "country_37": "บุรุนดี",
    "country_38": "กัมพูชา",
    "country_39": "แคเมอรูน",
    "country_40": "แคนาดา",
    "country_41": "เคปเวิร์ด",
    "country_42": "แคริบเบียน เนเธอร์แลนด์",
    "country_43": "หมู่เกาะเคย์เเมน",
    "country_44": "สาธารณรัฐแอฟริกากลาง",
    "country_45": "ชาด",
    "country_46": "ชิลี",
    "country_47": "จีน",
    "country_48": "เกาะคริสต์มาส",
    "country_49": "หมู่เกาะโคโคส (คีลิง)",
    "country_50": "โคลอมเบีย",
    "country_51": "หมู่เกาะคุก",
    "country_52": "คอสตาริกา",
    "country_53": "โกตดิวัวร์",
    "country_54": "โครเอเชีย",
    "country_55": "คิวบา",
    "country_56": "ไซปรัส",
    "country_57": "สาธารณรัฐเช็ก",
    "country_58": "สาธารณรัฐประชาธิปไตยคองโก",
    "country_59": "เดนมาร์ก",
    "country_60": "จิบูตี",
    "country_61": "โดมินิกา",
    "country_62": "สาธารณรัฐโดมินิกัน",
    "country_63": "เอกวาดอร์",
    "country_64": "อียิปต์",
    "country_65": "เอลซัลวาดอร์",
    "country_66": "อิเควทอเรียลกินี",
    "country_67": "เอริเทรีย",
    "country_68": "เอสโตเนีย",
    "country_69": "เอธิโอเปีย",
    "country_70": "หมู่เกาะฟอล์กแลนด์",
    "country_71": "หมู่เกาะแฟโร",
    "country_72": "สหพันธรัฐไมโครนีเซีย",
    "country_73": "ฟิจิ",
    "country_74": "ฟินแลนด์",
    "country_75": "ฝรั่งเศส",
    "country_76": "เฟรนช์เกีย",
    "country_77": "เฟรนช์โปลินีเซีย",
    "country_78": "ดินแดนทางใต้ของฝรั่งเศส",
    "country_79": "กาบอง",
    "country_80": "แกมเบีย",
    "country_81": "จอร์เจีย",
    "country_82": "เยอรมนี",
    "country_83": "กานา",
    "country_84": "ยิบรอลตาร์",
    "country_85": "บริเตนใหญ่ (สหราชอาณาจักร; ประเทศอังกฤษ)",
    "country_86": "กรีซ",
    "country_87": "กรีนแลนด์",
    "country_88": "เกรเนดา",
    "country_89": "กวาเดอลูป",
    "country_90": "กวม",
    "country_91": "กัวเตมาลา",
    "country_92": "เกิร์นซีย์",
    "country_93": "กินี",
    "country_94": "กินี-บิสเซา",
    "country_95": "กายอานา",
    "country_96": "เฮติ",
    "country_97": "เกาะเฮิร์ดและเกาะแมคโดนัลด์",
    "country_98": "ฮอนดูรัส",
    "country_99": "ฮ่องกง",
    "country_100": "ฮังการี",
    "country_101": "ไอซ์แลนด์",
    "country_102": "อินเดีย",
    "country_103": "อินโดนีเซีย",
    "country_104": "อิหร่าน",
    "country_105": "อิรัก",
    "country_106": "ไอร์แลนด์",
    "country_107": "เกาะแมน",
    "country_108": "อิสราเอล",
    "country_109": "อิตาลี",
    "country_110": "จาเมกา",
    "country_111": "ญี่ปุ่น",
    "country_112": "เจอร์ซีย์",
    "country_113": "จอร์แดน",
    "country_114": "คาซัคสถาน",
    "country_115": "เคนยา",
    "country_116": "คิริบาส",
    "country_117": "คูเวต",
    "country_118": "คีร์กีซสถาน",
    "country_119": "ลาว",
    "country_120": "ลัตเวีย",
    "country_121": "เลบานอน",
    "country_122": "เลโซโท",
    "country_123": "ไลบีเรีย",
    "country_124": "ลิเบีย",
    "country_125": "ลิกเตนสไตน์",
    "country_126": "ลิทัวเนีย",
    "country_127": "ลักเซมเบิร์ก",
    "country_128": "มาเก๊า",
    "country_129": "มาดากัสการ์",
    "country_130": "มาลาวี",
    "country_131": "มาเลเซีย",
    "country_132": "มัลดีฟส์",
    "country_133": "มาลี",
    "country_134": "มอลตา",
    "country_135": "หมู่เกาะมาร์แชลล์",
    "country_136": "มาร์ตินีก",
    "country_137": "มอริเตเนีย",
    "country_138": "มอริเชียส",
    "country_139": "มายอต",
    "country_140": "เม็กซิโก",
    "country_141": "มอลโดวา",
    "country_142": "โมนาโก",
    "country_143": "มองโกเลีย",
    "country_144": "มอนเตเนโกร",
    "country_145": "มอนต์เซอร์รัต",
    "country_146": "โมร็อกโก",
    "country_147": "โมซัมบิก",
    "country_148": "เมียนมาร์ (พม่า)",
    "country_149": "นามิเบีย",
    "country_150": "นาอูรู",
    "country_151": "เนปาล",
    "country_152": "เนเธอร์แลนด์",
    "country_153": "นิวแคลิโดเนีย",
    "country_154": "นิวซีแลนด์",
    "country_155": "นิการากัว",
    "country_156": "ไนเจอร์",
    "country_157": "ไนจีเรีย",
    "country_158": "นีอูเอ",
    "country_159": "เกาะนอร์ฟอล์ก",
    "country_160": "เกาหลีเหนือ",
    "country_161": "หมู่เกาะนอร์เทิร์นมาเรียนา",
    "country_162": "นอร์เวย์",
    "country_163": "โอมาน",
    "country_164": "ปากีสถาน",
    "country_165": "ปาเลา",
    "country_166": "ดินแดนปาเลสไตน์",
    "country_167": "ปานามา",
    "country_168": "ปาปัวนิวกินี",
    "country_169": "ประเทศปารากวัย",
    "country_170": "เปรู",
    "country_171": "หมู่เกาะพิตแคร์น",
    "country_172": "โปแลนด์",
    "country_173": "โปรตุเกส",
    "country_174": "เปอร์โตริโก้",
    "country_175": "กาตาร์",
    "country_176": "สาธารณรัฐมาซิโดเนีย (FYROM)",
    "country_177": "สาธารณรัฐคองโก",
    "country_178": "เรอูนียง",
    "country_179": "โรมาเนีย",
    "country_180": "สหพันธรัฐรัสเซีย",
    "country_181": "รวันดา",
    "country_182": "แซงต์บาร์เตเลมี",
    "country_183": "แซงต์มาร์ติน (ฝรั่งเศส)",
    "country_184": "แซงต์-ปิแอร์ และมีเกอลง",
    "country_185": "ซามัว",
    "country_186": "ซานมารีโน",
    "country_187": "เซาโตเมและปรินซิปี",
    "country_188": "ซาอุดิอาราเบีย",
    "country_189": "เซเนกัล",
    "country_190": "เซอร์เบีย",
    "country_191": "เซเชลส์",
    "country_192": "เซียร์ราลีโอน",
    "country_193": "สิงคโปร์",
    "country_194": "สโลวาเกีย",
    "country_195": "สโลวีเนีย",
    "country_196": "หมู่เกาะโซโลมอน",
    "country_197": "โซมาเลีย",
    "country_198": "แอฟริกาใต้",
    "country_199": "จอร์เจียตอนใต้ และหมู่เกาะเซาท์แซนด์วิช",
    "country_200": "เกาหลีใต้",
    "country_201": "ซูดานใต้",
    "country_202": "สเปน",
    "country_203": "ศรีลังกา",
    "country_204": "เซนต์ เฮเลนา และการพึ่งพา",
    "country_205": "เซนต์คิตส์และเนวิส",
    "country_206": "เซนต์ลูเซีย",
    "country_207": "เซนต์ วินเซนต์ & เกรนาดีนส์",
    "country_208": "ซูดาน",
    "country_209": "ซูรินาเม",
    "country_210": "สวาซิแลนด์",
    "country_211": "สวีเดน",
    "country_212": "สวิตเซอร์แลนด์",
    "country_213": "ซีเรีย",
    "country_214": "ไต้หวัน",
    "country_215": "ทาจิกิสถาน",
    "country_216": "แทนซาเนีย",
    "country_217": "แม่แบบ:Country data SJM Svalbard",
    "country_218": "ประเทศไทย",
    "country_219": "บาฮามาส",
    "country_220": "คอโมโรส",
    "country_221": "ฟิลิปปินส์",
    "country_222": "ติมอร์-เลสเต (ติมอร์ตะวันออก)",
    "country_223": "ไป",
    "country_224": "โตเกเลา",
    "country_225": "ตองกา",
    "country_226": "ตรินิแดด และ โตเบโก",
    "country_227": "ตูนิเซีย",
    "country_228": "ไก่งวง",
    "country_229": "เติร์กเมนิสถาน",
    "country_230": "หมู่เกาะเติกส์และหมู่เกาะเคคอส",
    "country_231": "ตูวาลู",
    "country_232": "ยูกันดา",
    "country_233": "ยูเครน",
    "country_234": "สหรัฐอาหรับเอมิเรตส์",
    "country_235": "เกาะเล็กรอบนอกของสหรัฐอเมริกา",
    "country_236": "สหรัฐอเมริกา อเมริกา (สหรัฐอเมริกา)",
    "country_237": "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
    "country_238": "อุรุกวัย",
    "country_239": "อุซเบกิสถาน",
    "country_240": "วานูอาตู",
    "country_241": "นครวาติกัน (สันตะสำนัก)",
    "country_242": "เวเนซุเอลา",
    "country_243": "เวียดนาม",
    "country_244": "วาลลิสและฟุตูนา",
    "country_245": "ซาฮาร่าตะวันตก",
    "country_246": "เยเมน",
    "country_247": "แซมเบีย",
    "country_248": "ซิมบับเว",
    "google_login": "ลงชื่อเข้าใช้งานด้วย Google",
    "State": "สถานะ",
    "Activation_code": "รหัสเปิดใช้งาน",
    "Question": "แสดงรายการแอพทั้งหมดที่คุณเข้าสู่ระบบ",
    "Copy_complete": "คัดลอกเสร็จสมบูรณ์",
    "footer": "ลิขสิทธิ์ © 2024 MindOnMap สตูดิโอ สงวนลิขสิทธิ์.",
    "change_password_success": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
    "successful_login_title": "เข้าสู่ระบบสำเร็จ",
    "product_page": "หน้าสินค้า>>",
    "successful_login_info": "เข้าสู่ระบบเสร็จสมบูรณ์ โปรดปิดหน้าปัจจุบันและกลับไปที่แท็บเดิมเพื่อดำเนินการตามกระบวนการต่อไปนี้ หน้าปัจจุบันจะถูกปิดโดยอัตโนมัติใน 5 วินาที หากการปิดอัตโนมัติหรือการปิดโดยการคลิกปุ่ม \"เสร็จสิ้น\" ล้มเหลว โปรดปิดแท็บนี้โดยตรง",
    "successful_login_info_firefox": "เข้าสู่ระบบเสร็จสมบูรณ์ โปรดปิดหน้าปัจจุบันและกลับไปที่แท็บเดิมเพื่อดำเนินการตามกระบวนการต่อไปนี้",
    "my_account": "บัญชีของฉัน",
    "my_history": "ประวัติศาสตร์ของฉัน",
    "remove_watermark": "ลบลายน้ำ",
    "no_history": "ไม่มีประวัติ",
    "history_all": "เลือกทั้งหมด",
    "history_open": "เปิด",
    "history_down": "ดาวน์โหลด",
    "history_delete": "ลบ",
    "history_clear": "ล้างไม่ถูกต้อง",
    "images": "รูปภาพ",
    "use_this_function": "ใช้ผลิตภัณฑ์นี้>>",
    "hd_downloading": "ประโยชน์การดาวน์โหลดภาพต้นฉบับ HD:",
    "lifetimeRemaining": "อายุการใช้งานที่เหลืออยู่",
    "Remaining": "ที่เหลืออยู่",
    "email_verification": "การยืนยันอีเมล",
    "email_verification_info": "เราได้ส่งข้อความยืนยันไปยังอีเมลของคุณแล้ว <span class=\"email\"></span> และโปรดดำเนินการยืนยันให้เสร็จสิ้น หลังจากตรวจสอบแล้ว สิทธิประโยชน์จะซิงค์กันโดยอัตโนมัติ",
    "wrong_email": "ที่อยู่อีเมลผิด?",
    "click_here_to_modify": "คลิกที่นี่เพื่อแก้ไข",
    "get_more_help": "รับความช่วยเหลือเพิ่มเติมหรือไม่?",
    "click_here": "คลิกที่นี่",
    "email_verification_info_success": "ขอแสดงความยินดีสำหรับการยืนยันบัญชีอีเมลของคุณ",
    "email_verification_info_error": "การยืนยันล้มเหลวเนื่องจากลิงก์หมดอายุ",
    "registration_succeeded": "การลงทะเบียนสำเร็จแล้ว",
    "registration_succeeded_info_1": "ยินดีด้วย! คุณลงทะเบียนสำเร็จแล้ว เราได้ส่งข้อความยืนยันไปยังอีเมลของคุณแล้ว <span class=\"email\"></span> และโปรดยืนยันให้เสร็จสิ้นเพื่อใช้ประโยชน์ของอีเมลนี้",
    "registration_succeeded_info_2": "คลิก \"เสร็จสิ้น\" เพื่อกลับไปยังหน้าแรกและใช้ผลิตภัณฑ์นี้",
    "registration_succeeded_info_3": "คลิก \"เสร็จสิ้น\" เพื่อปิดหน้าปัจจุบันและกลับสู่หน้าแรกเพื่อดำเนินการต่อไปนี้ หากการปิดล้มเหลว โปรดปิดแท็บด้วยตนเอง",
    "verify_email": "ตรวจสอบอีเมล์",
    "registered_email_not_verified": "อีเมลที่ลงทะเบียนยังไม่ได้รับการยืนยัน โปรดยืนยันทันที",
    "email_verification_time_1": "ไม่ได้รับอีเมลยืนยันใช่หรือไม่?",
    "email_verification_time_2": "หลังจาก",
    "email_verification_time_3": "คลิกที่นี่เพื่อส่งอีกครั้ง",
    "error_26301": "รหัสข้อผิดพลาด: 26301 โปรดติดต่อเรา",
    "error_26302": "รหัสข้อผิดพลาด: 26302 โปรดติดต่อเรา",
    "error_26303": "ข้อผิดพลาดรูปแบบอีเมล (รหัสข้อผิดพลาด: 26303) กรุณากรอกใหม่อีกครั้ง",
    "error_26304": "แนะนำให้ใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร (รหัสข้อผิดพลาด: 26304)",
    "error_26305": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26305) โปรดลองอีกครั้ง",
    "error_26306": "อีเมลได้รับการลงทะเบียนแล้ว โปรด<a href=\"https://account.mindonmap.com/login/\">ไปที่เข้าสู่ระบบ</a>",
    "error_26307": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26307) โปรดลองอีกครั้ง",
    "error_26308": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26308) โปรดลองอีกครั้ง",
    "error_26401": "รหัสข้อผิดพลาด: 26401 โปรดลองอีกครั้ง",
    "error_26402": "อีเมลได้รับการยืนยันแล้ว (รหัสข้อผิดพลาด: 26402) โปรดลองอีกครั้ง",
    "error_26403": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26403) โปรดลองอีกครั้ง",
    "error_26404": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26404) โปรดลองอีกครั้ง",
    "error_26501": "รหัสข้อผิดพลาด: 26501 โปรดติดต่อเรา",
    "error_26502": "รหัสข้อผิดพลาด: 26502 โปรดติดต่อเรา",
    "error_26503": "ข้อผิดพลาดรูปแบบอีเมล (รหัสข้อผิดพลาด: 26503) กรุณากรอกใหม่อีกครั้ง",
    "error_26504": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26504) โปรดลองอีกครั้ง",
    "error_26505": "อีเมลยังไม่ได้ลงทะเบียน โปรด<a href=\"https://account.mindonmap.com/register/\">ลงทะเบียนก่อน</a>",
    "error_26506": "อีเมลได้รับการตรวจสอบแล้ว",
    "error_26507": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26507) โปรดลองอีกครั้ง",
    "error_26508": "การยืนยันล้มเหลว (รหัสข้อผิดพลาด: 26508) โปรดลองอีกครั้ง",
    "error_26509": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26509) โปรดลองอีกครั้ง",
    "error_26510": "รหัสข้อผิดพลาด: 26510 โปรดติดต่อเรา",
    "error_26601": "รหัสข้อผิดพลาด: 26601 โปรดติดต่อเรา",
    "error_26602": "รหัสข้อผิดพลาด: 26602 โปรดติดต่อเรา",
    "error_26603": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26603) โปรดลองอีกครั้ง",
    "error_26604": "รหัสข้อผิดพลาด: 26604 โปรดติดต่อเรา",
    "error_26605": "รหัสข้อผิดพลาด: 26605 โปรดติดต่อเรา",
    "error_26701": "รหัสข้อผิดพลาด: 26701 โปรดติดต่อเรา",
    "error_26702": "Reuqest ล้มเหลว (รหัสข้อผิดพลาด: 26702) โปรดลองอีกครั้ง",
    "error_26703": "รหัสข้อผิดพลาด: 26703 โปรดติดต่อเรา",
    "error_26704": "รหัสข้อผิดพลาด: 26704 โปรดติดต่อเรา",
    "error_26705": "รอการเข้าสู่ระบบ (รหัสข้อผิดพลาด: 26705) โปรดลองอีกครั้ง",
    "no_cookie": "คุณได้เปิดใช้งานฟังก์ชันบล็อกคุกกี้ทั้งหมดในเบราว์เซอร์ของคุณ ดังนั้นคุณจึงไม่สามารถเข้าสู่ระบบได้ โปรดไปที่การตั้งค่าเพื่อทำเครื่องหมายในช่องอนุญาตคุกกี้ทั้งหมด",
    "error_26801": "รหัสข้อผิดพลาด: 26801 โปรดติดต่อเรา",
    "error_26802": "รหัสข้อผิดพลาด: 26802 โปรดติดต่อเรา",
    "error_26803": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26803) โปรดลองอีกครั้ง",
    "error_26804": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26804) โปรดลองอีกครั้ง",
    "error_order": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27098) โปรดลองอีกครั้ง!",
    "error_order1": "แบบสอบถามคำสั่งซื้อไม่สมบูรณ์ (รหัสข้อผิดพลาด: ",
    "error_order2": "） โปรดรีเฟรชและลองอีกครั้ง",
    "modify_email_title": "แก้ไขอีเมล์",
    "modify_email_info": "คุณสามารถใช้อีเมลที่แก้ไขเพื่อเข้าสู่บัญชีของคุณ",
    "images_per": "รูปภาพต่อ",
    "error_26101": "ข้อผิดพลาด: 26101 โปรดติดต่อเรา",
    "error_26102": "ข้อผิดพลาด: 26102 โปรดติดต่อเรา",
    "error_26103": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26103) โปรดลองอีกครั้ง",
    "error_26104": "รหัสข้อผิดพลาด: 26104 โปรดลองอีกครั้ง",
    "error_26105": "รหัสข้อผิดพลาด: 26105 โปรดลองอีกครั้ง",
    "error_26106": "ลบไม่สำเร็จ (รหัสข้อผิดพลาด: 26106) โปรดลองอีกครั้ง",
    "error_26201": "ข้อผิดพลาด: 26201 โปรดติดต่อเรา",
    "error_26202": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26202) โปรดลองอีกครั้ง",
    "error_26001": "ข้อผิดพลาด: 26001 โปรดติดต่อเรา",
    "error_26002": "ข้อผิดพลาด: 26002 โปรดติดต่อเรา",
    "error_26003": "ข้อผิดพลาด: 26003 โปรดติดต่อเรา",
    "error_26004": "ข้อผิดพลาด: 26004 โปรดติดต่อเรา",
    "error_26005": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 26005) โปรดลองอีกครั้ง",
    "error_26006": "รหัสข้อผิดพลาด: 26006 โปรดลองอีกครั้ง",
    "error_26008": "ข้อผิดพลาด: 26008 โปรดติดต่อเรา",
    "go_to_the_home_page": "ไปที่หน้าแรก",
    "error_27101": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27101) กรุณาลองใหม่อีกครั้ง",
    "error_27201": "รหัสข้อผิดพลาด: 27201 โปรดติดต่อเรา",
    "error_27202": "รหัสข้อผิดพลาด: 27202 โปรดลองอีกครั้ง",
    "error_27203": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27203) โปรดลองอีกครั้ง",
    "error_27204": "รหัสไม่ถูกต้อง (รหัสข้อผิดพลาด: 27204)",
    "error_27205": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27205) โปรดลองอีกครั้ง",
    "error_27206": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27206) โปรดลองอีกครั้ง",
    "error_27207": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 27207) โปรดลองอีกครั้ง",
    "no_history_found": "คุณไม่ได้ใช้เครื่องมือใดๆ! <a href=\"/\">รีเฟรช</a> หรือ <a href=\"https://www.mindonmap.com/\">ไปที่เว็บไซต์อย่างเป็นทางการ</a>",
    "error_25301": "ข้อผิดพลาด: 25301 โปรดติดต่อเรา",
    "error_25302": "ข้อผิดพลาด: 25302 โปรดติดต่อเรา",
    "error_25303": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 25303) โปรดลองอีกครั้ง",
    "error_25304": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 25304) โปรดลองอีกครั้ง",
    "error_25305": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 25305) โปรดลองอีกครั้ง",
    "error_25306": "คำขอล้มเหลว (รหัสข้อผิดพลาด: 25306) โปรดลองอีกครั้ง",
    "image_upscaler_p": "ประโยชน์การดาวน์โหลดรูปภาพที่ไม่มีลายน้ำ:",
    "Available_for": "มีให้สำหรับ:",
    "credit_per": "เครดิต %s ต่อภาพ HD",
    "still_valid": "แผนที่จัดซื้อยังคงใช้งานได้",
    "credit": "เครดิต",
    "pc_3rd_info": "เข้าสู่ระบบสำเร็จ โปรดไปที่แอปพลิเคชันเพื่อดำเนินการต่อไป",
    "use_online": "ใช้บริการออนไลน์",
    "use_download": "ใช้โปรแกรมเดสก์ท็อป",
    "use_immediately": "ใช้ทันที",
    "Use_in_browser": "ใช้ในเบราว์เซอร์",
    "win_desktop": "หน้าต่าง",
    "Mac_desktop": "แม็ค",
    "credits_per": "{%} เครดิตต่อเดือน",
    "expire": "เวลาหมดอายุ:",
    "viewDetails": "ดูรายละเอียด",
    "viewHistory": "ดูประโยชน์ประวัติ>>",
    "viewDetailsInfo": "หมายเหตุ: หากต่ออายุสิทธิประโยชน์การสมัครสมาชิกภายใน 7 วันหลังจากหมดอายุ สิทธิประโยชน์ที่ไม่ได้ใช้จะยังสามารถใช้งานได้ต่อไป นอกจากนี้ เวลาหมดอายุจะได้รับการอัปเดตเป็นเวลาหมดอายุของการสมัครสมาชิกใหม่โดยอัตโนมัติ หากไม่มีการสมัครใหม่หลังจากเวลาหมดอายุ 7 วัน สิทธิประโยชน์ที่หมดอายุทั้งหมดจะถูกล้าง",
    "connect_account": "ผูกอีเมลเข้ากับบัญชีของคุณ",
    "connect_account_info": "หลังจากผูกมัดแล้ว คุณสามารถเข้าสู่ระบบด้วยที่อยู่อีเมลนี้ได้",
    "connect_now": "ผูกตอนนี้",
    "no_email_bind": "ไม่มีการผูกอีเมล",
    "bind_email": "ผูกอีเมล",
    "connect_your_email_placeholder": "กรุณากรอกอีเมลของคุณ",
    "bind_an_email": "ผูกอีเมล",
    "bind_info": "คุณเข้าสู่ระบบเรียบร้อยแล้ว กรุณาผูกอีเมลเข้ากับบัญชีของคุณเพื่อเปิดใช้งานสิทธิประโยชน์ของคุณ",
    "bind_later": "ผูกในภายหลัง",
    "hi": "ของเขา!",
    "Personal_Information": "ข้อมูลส่วนบุคคล",
    "Access": "เข้าถึง",
    "Subscription_Plan": "(แผนการสมัครสมาชิก)",
    "No_orders": "ไม่พบคำสั่งซื้อ",
    "No_data": "ไม่มีข้อมูล",
    "Featured_Products": "สินค้าเด่น",
    "More_Products": "สินค้าเพิ่มเติม",
    "Free_Download": "ดาวน์โหลดฟรี",
    "Get_Started": "เริ่ม",
    "Subscribe": "ติดตาม",
    "Verified": "ตรวจสอบแล้ว",
    "back_to_account_center": "กลับไปที่ศูนย์บัญชี",
    "success": "ความสำเร็จ!",
    "successfully": "คุณได้ลงทะเบียนบัญชีเรียบร้อยแล้ว",
    "Continue": "ดำเนินการต่อ",
    "Already": "มีบัญชีอยู่แล้ว?",
    "loading_verification": "กำลังตรวจสอบสถานะการยืนยัน...",
    "email_no_verification": "ขออภัย ที่อยู่อีเมลที่ลงทะเบียนยังไม่ได้รับการยืนยัน กรุณาดำเนินการตรวจสอบให้เสร็จสิ้นตามคำแนะนำข้างต้น และคลิกปุ่ม \"เสร็จสิ้น\" อีกครั้งเพื่อดำเนินการลงทะเบียนให้เสร็จสิ้น",
    "will_expire_after": "จะหมดอายุหลังจากนั้น",
    "hours": "ชั่วโมง",
  },
  "tr": {
    "overtime": "Hata kodu: {%}, lütfen tekrar deneyin",
    "isnetwork": "İnternet hatası. Lütfen kontrol edip tekrar deneyin",
    "email_placeholder": "E-posta",
    "email_empty": "Lütfen e-posta girin",
    "email_not_valid": "E-posta geçerli değil",
    "email_not_valid_1": "Lütfen e-posta adresinizi girin",
    "email_not_valid_2": "E-posta geçerli değil, lütfen farklı bir adres kullanın.",
    "email_not_valid_3": "E-posta girişi yok",
    "password_placeholder": "Şifre",
    "password_empty": "Lütfen şifrenizi girin",
    "password_not_valid": "Yanlış hesap veya şifre",
    "password_not_valid_1": "8 karakterden uzun şifre gereklidir",
    "password_not_valid_2": "Lütfen bir şifre oluşturun",
    "password_placeholder_1": "Şifreni oluştur",
    "password_placeholder_2": "Parolanızı doğrulayın",
    "password_placeholder_3": "Yeni şifre oluştur",
    "password_placeholder_4": "Yeni şifreyi onayla",
    "password_placeholder_5": "Eski şifreyi girin",
    "copy_password_empty": "Lütfen şifreyi onaylayın",
    "copy_password_not_valid": "Lütfen şifrenizi doğrulayınız",
    "copy_passwords_inconsistent": "Şifre onayınız eşleşmiyor",
    "code_empty": "Lütfen doğrulama kodunu girin",
    "code_not_valid": "Geçersiz doğrulama kodu",
    "code_placeholder": "Doğrulama kodu",
    "not_received_code": "Posta kutunuza uzun süre doğrulama kodu ulaşmadıysa lütfen doğrulama kodunu tekrar alın.",
    "get_first_code": "Lütfen önce doğrulama kodunu alın.",
    "last_name_placeholder": "Lütfen soyadınızı giriniz",
    "first_name_placeholder": "Lütfen isminizi giriniz",
    "address_placeholder": "Lütfen adresinizi girin",
    "no_code_text": "Bir doğrulama kodu gönderdik. Lütfen kodunuzu girin. <span class='tips'>Kod almadınız mı?",
    "no_code_text_1": "1. Lütfen e-posta adresinin geçerli olduğundan ve e-posta alabildiğinden emin olun.",
    "no_code_text_2": "2. E-posta sistem tarafından otomatik olarak gönderildiğinden spam veya önemsiz e-posta olarak işaretlenebilir. Lütfen e-postanın Çöp Kutusu klasöründe olup olmadığını kontrol edin.",
    "no_code_text_3": "3. Sorununuzu çözemiyor musunuz? ",
    "no_code_text_3_span": "O halde bizimle iletişime geçmek için buraya tıklayın.",
    "order_no": "Herhangi bir ürün satın almadınız. Herhangi bir sorunuz varsa lütfen <a href=\"https://www.mindonmap.com/contact-us/\">bizimle iletişime geçin</a>.",
    "error_24901": "Mevcut hesapta bağlı e-posta yok ve siparişler bulunamıyor. Lütfen bir e-posta bağlayın.",
    "user_guide": "Kullanım Kılavuzu>>",
    "download": "İndirmek",
    "order_number": "Sipariş numarası:",
    "Refund": "Geri ödemek",
    "Disabled": "Engelli",
    "Normal": "Normal",
    "Modify": "Değiştir",
    "Modify_1": "Değiştir>>",
    "Connect": "Bağlamak",
    "unlink_success": "Bağlantıyı başarıyla kaldır",
    "connect_success": "Başarılı bir şekilde bağlanın",
    "feedback_title": "Geri bildiriminiz için teşekkürler. Lütfen sorununuzu bırakın; size 24 saat içinde yanıt vereceğiz.",
    "feedback_thank_you": "Teşekkür ederiz!<br />Geri bildiriminiz başarıyla gönderildi.",
    "feedback_email": "E-posta adresinizi buraya girin!",
    "feedback_content": "Karşılaştığınız herhangi bir sorunu veya öneriyi buraya bırakın.",
    "feedback_submit": "Göndermek",
    "form_contents": "Herhangi bir açıklama girmediniz. Lütfen girin ve tekrar gönderin.",
    "old_password": "Lütfen eski şifreyi girin",
    "new_password": "Lütfen yeni bir şifre oluşturun",
    "old_new_password": "Yeni şifre eski şifreyle aynı olamaz",
    "incorrect_password": "yanlış parola",
    "delete_no": "Şimdi sil",
    "Caps": "Caps Lock açık",
    "Get": "Elde etmek",
    "Done": "Tamamlamak",
    "error_20001": "Hata: 20001. Lütfen tekrar giriş yapın.",
    "error_20002": "Hata: 20002. Lütfen tekrar oturum açın.",
    "error_20003": "Hata: 20003. Lütfen tekrar oturum açın.",
    "error_20004": "İstek başarısız oldu (hata kodu: 20004). Lütfen tekrar deneyin.",
    "error_20005": "Oturum açma oturumunun süresi doldu (Hata: 20005). Lütfen tekrar giriş yapın.",
    "error_20006": "İstek başarısız oldu (hata kodu: 20006). Lütfen tekrar deneyin.",
    "error_20007": "Oturum açma oturumunun süresi doldu (Hata: 20007). Lütfen tekrar giriş yapın.",
    "error_20008": "Oturum açma oturumunun süresi doldu (Hata: 20008). Lütfen tekrar giriş yapın.",
    "error_20009": "Oturum açma oturumunun süresi doldu (Hata: 20009). Lütfen tekrar giriş yapın.",
    "error_20101": "Lütfen e-posta adresinizi girin (hata kodu: 20101)",
    "error_20102": "E-posta geçerli değil (hata kodu: 20102)",
    "error_20103": "İstek başarısız oldu (hata kodu: 20103). Lütfen tekrar deneyin",
    "error_20104": "E-posta zaten kullanılıyor, lütfen <a href=\"https://account.mindonmap.com/login/\">giriş yapın</a> veya yeni bir e-postayla kaydolun",
    "error_20105": "İstek başarısız oldu (hata kodu: 20105). Lütfen tekrar deneyin",
    "error_20106": "E-posta gönderilemedi, lütfen tekrar deneyin",
    "error_20201": "Lütfen e-posta adresinizi girin (hata kodu: 20201)",
    "error_20202": "Lütfen şifrenizi girin (hata kodu: 20202)",
    "error_20203": "Lütfen doğrulama kodunu girin (hata kodu: 20203)",
    "error_20204": "E-posta geçerli değil (hata kodu: 20204)",
    "error_20205": "8 karakterden uzun şifre gereklidir (hata kodu: 20205)",
    "error_20206": "İstek başarısız oldu (hata kodu: 20206). Lütfen tekrar deneyin",
    "error_20207": "Geçersiz doğrulama kodu",
    "error_20208": "İstek başarısız oldu (hata kodu: 20208). Lütfen tekrar deneyin",
    "error_20209": "İstek başarısız oldu (hata kodu: 20209). Lütfen tekrar deneyin",
    "error_20301": "Lütfen e-posta adresinizi girin (hata kodu: 20301)",
    "error_20302": "Hata: 20302. Lütfen bizimle iletişime geçin.",
    "error_20303": "E-posta geçerli değil (hata kodu: 20303)",
    "error_20304": "İstek başarısız oldu (hata kodu: 20304). Lütfen tekrar deneyin",
    "error_20305": "Hesap mevcut değil. Lütfen yeniden girin veya önce <a href=\"https://account.mindonmap.com/register/\">oluşturun</a>.",
    "error_20306": "Henüz şifre yok. <a href=\"https://account.mindonmap.com/passwordless-login/\">Şifresiz giriş</a> veya <a href=\"https://account.mindonmap.com/create-password/\"> kullanın bir şifre belirleyin</a> ve giriş yapın.",
    "error_20308": "İstek başarısız oldu (hata kodu: 20308). Lütfen tekrar deneyin",
    "error_20401": "Oturum kapatılamadı (hata kodu: 20401). Lütfen tekrar deneyin",
    "error_20501": "Lütfen e-posta adresinizi girin (hata kodu: 20501)",
    "error_20502": "E-posta geçerli değil (hata kodu: 20502)",
    "error_20503": "İstek başarısız oldu (hata kodu: 20503). Lütfen tekrar deneyin",
    "error_20504": "E-posta gönderilemedi. Lütfen tekrar deneyin.",
    "error_20601": "Lütfen e-posta adresinizi girin (hata kodu: 20601)",
    "error_20602": "Lütfen doğrulama kodunu girin (hata kodu: 20602)",
    "error_20603": "E-posta geçerli değil (hata kodu: 20603)",
    "error_20604": "İstek başarısız oldu (hata kodu: 20604). Lütfen tekrar deneyin",
    "error_20606": "İstek başarısız oldu (hata kodu: 20606). Lütfen tekrar deneyin",
    "error_20607": "İstek başarısız oldu (hata kodu: 20607). Lütfen tekrar deneyin",
    "error_20608": "İstek başarısız oldu (hata kodu: 20608). Lütfen tekrar deneyin",
    "error_20701": "Lütfen e-posta adresinizi girin (hata kodu: 20701)",
    "error_20702": "E-posta geçerli değil (hata kodu: 20702)",
    "error_20703": "İstek başarısız oldu (hata kodu: 20703). Lütfen tekrar deneyin",
    "error_20704": "Hesap mevcut değil. Lütfen yeniden girin veya önce <a href=\"https://account.mindonmap.com/register/\">oluşturun</a>.",
    "error_20705": "İstek başarısız oldu (hata kodu: 20705). Lütfen tekrar deneyin",
    "error_20706": "E-posta gönderilemedi. Lütfen tekrar deneyin",
    "error_20801": "Lütfen e-posta adresinizi girin (hata kodu: 20801)",
    "error_20802": "Hata: 20802. Lütfen bizimle iletişime geçin",
    "error_20803": "Lütfen doğrulama kodunu girin (hata kodu: 20803)",
    "error_20804": "E-posta geçerli değil (hata kodu: 20804)",
    "error_20805": "8 karakterden uzun şifre gereklidir (hata kodu: 20805)",
    "error_20806": "İstek başarısız oldu (hata kodu: 20806). Lütfen tekrar deneyin",
    "error_20808": "İstek başarısız oldu (hata kodu: 20808). Lütfen tekrar deneyin",
    "error_20901": "İstek başarısız oldu (hata kodu: 20901). Lütfen tekrar deneyin",
    "error_20902": "İstek başarısız oldu (hata kodu: 20902). Lütfen tekrar deneyin",
    "error_21000": "Değişiklikler kaydedildi",
    "error_21001": "Hiçbir bilgi gönderilmedi (hata kodu: 21001)",
    "error_21002": "İstek başarısız oldu (hata kodu: 21002). Lütfen tekrar deneyin",
    "error_21101": "Lütfen e-posta adresinizi girin (hata kodu: 21101)",
    "error_21102": "E-posta geçerli değil (hata kodu: 21102)",
    "error_21103": "İstek başarısız oldu (hata kodu: 21103), lütfen tekrar deneyin",
    "error_21104": "E-posta zaten bağlı, lütfen yeni bir e-posta kullanın",
    "error_21105": "İstek başarısız oldu (hata kodu: 21105), lütfen tekrar deneyin",
    "error_21106": "E-posta gönderilemedi. Lütfen tekrar deneyin",
    "error_21201": "Lütfen e-posta adresinizi girin (hata kodu: 21201)",
    "error_21202": "Lütfen doğrulama kodunu girin (hata kodu: 21202)",
    "error_21203": "E-posta geçerli değil (hata kodu: 21203)",
    "error_21204": "Hata: 21204. Lütfen bizimle iletişime geçin.",
    "error_21205": "Hata: 21205. Lütfen bizimle iletişime geçin.",
    "error_21206": "8 karakterden uzun şifre gereklidir (hata kodu: 21206)",
    "error_21207": "İstek başarısız oldu (hata kodu: 21207). Lütfen tekrar deneyin",
    "error_21209": "İstek başarısız oldu (hata kodu: 21209). Lütfen tekrar deneyin",
    "error_21301": "Lütfen eski şifreyi girin (hata kodu: 21301)",
    "error_21302": "Lütfen yeni bir şifre oluşturun (hata kodu: 21302)",
    "error_21303": "Yeni şifre eski şifre ile aynı olamaz. (Hata: 21303)",
    "error_21304": "8 karakterden uzun şifre gereklidir (hata kodu: 21304)",
    "error_21306": "İstek başarısız oldu (hata kodu: 21306). Lütfen tekrar deneyin",
    "error_21402": "İstek başarısız oldu (hata kodu: 21402). Lütfen tekrar deneyin",
    "error_21403": "Doğrulama kodu gönderilemedi. Lütfen tekrar gönderin",
    "error_21500": "Hesap silindi",
    "error_21501": "Lütfen doğrulama kodunu girin (hata kodu: 21501)",
    "error_21502": "Oturum açma oturumunun süresi doldu (Hata: 21502). Lütfen tekrar giriş yapın.",
    "error_21503": "İstek başarısız oldu (hata kodu: 21503). Lütfen tekrar deneyin",
    "error_21505": "İstek başarısız oldu (hata kodu: 21505), lütfen tekrar deneyin",
    "error_21601": "Hata: 20601. Lütfen bizimle iletişime geçin.",
    "error_21602": "Geçersiz Doğrulama (Hata: 20602). Lütfen tekrar deneyin.",
    "error_21603": "Hata: 20603. Lütfen tekrar deneyin.",
    "error_21604": "İstek başarısız oldu (hata kodu: 21604). Lütfen tekrar deneyin",
    "error_21606": "İstek başarısız oldu (hata kodu: 21606). Lütfen tekrar deneyin",
    "error_21611": "İstek başarısız oldu (hata kodu: 21611). Lütfen tekrar deneyin",
    "error_21801": "Hata: 21801. Lütfen bizimle iletişime geçin.",
    "error_21802": "İstek başarısız oldu (Hata: 21802). Lütfen tekrar deneyin",
    "error_21803": "Hata: 21803. Lütfen tekrar deneyin.",
    "error_21804": "İstek başarısız oldu (hata kodu: 21804). Lütfen tekrar deneyin",
    "error_21806": "Hata: 21806. Lütfen tekrar deneyin.",
    "error_21807": "Hata: 21807. Lütfen bizimle iletişime geçin.",
    "error_21808": "Hata: 21808. Lütfen bizimle iletişime geçin.",
    "error_21809": "Hata: 21809. Lütfen bizimle iletişime geçin.",
    "error_21810": "Hata: 21810. Lütfen bizimle iletişime geçin.",
    "error_21811": "Hata: 21811. Lütfen bizimle iletişime geçin.",
    "error_21812": "Hata: 21812. Lütfen bizimle iletişime geçin.",
    "error_21813": "İstek başarısız oldu (hata kodu: 21813). Lütfen tekrar deneyin",
    "error_21814": "Hata: 21814. Lütfen bizimle iletişime geçin.",
    "error_21815": "İstek başarısız oldu (hata kodu: 21815). Lütfen tekrar deneyin",
    "error_21816": "Hata: 21816. Lütfen bizimle iletişime geçin.",
    "error_21817": "Hata: 21817. Lütfen bizimle iletişime geçin.",
    "error_21818": "Hata: 21818. Lütfen bizimle iletişime geçin",
    "error_21819": "İstek başarısız oldu (hata kodu: 21819). Lütfen tekrar deneyin",
    "error_21820": "Hata: 21820. Lütfen bizimle iletişime geçin.",
    "error_21821": "Hata: 21821. Lütfen bizimle iletişime geçin.",
    "error_21822": "Hata: 21822. Lütfen bizimle iletişime geçin.",
    "error_21823": "İstek başarısız oldu (hata kodu: 21823). Lütfen tekrar deneyin",
    "error_21824": "İstek başarısız oldu (hata kodu: 21824). Lütfen tekrar deneyin",
    "error_21825": "İstek başarısız oldu (hata kodu: 21825). Lütfen tekrar deneyin",
    "error_21826": "İstek başarısız oldu (hata kodu: 21826). Lütfen tekrar deneyin",
    "error_21901": "Hata: 21901. Lütfen bizimle iletişime geçin",
    "error_21902": "İstek başarısız oldu (hata kodu: 21902). Lütfen tekrar deneyin",
    "error_21903": "Hesap durumu değişti (hata kodu: 21903), lütfen sayfayı yenileyin ve tekrar deneyin.",
    "error_21904": "Hata: 21904. Lütfen tekrar deneyin.",
    "error_21905": "Hata: 21905. Lütfen tekrar deneyin.",
    "error_21906": "İstek başarısız oldu (hata kodu: 21906). Lütfen tekrar deneyin",
    "error_21907": "Google hesabı başka bir hesaba bağlandı",
    "error_21908": "İstek başarısız oldu (hata kodu: 21908). Lütfen tekrar deneyin",
    "error_22001": "İstek başarısız oldu (hata kodu: 22001). Lütfen tekrar deneyin",
    "error_22002": "Ek giriş yapılmadan bağlantı kaldırılamadı",
    "error_22003": "İstek başarısız oldu (hata kodu: 22003). Lütfen tekrar deneyin",
    "error_22101": "Hata: 22101. Lütfen bizimle iletişime geçin.",
    "error_22102": "Hesap durumu değişti (hata kodu: 22102), lütfen sayfayı yenileyin ve tekrar deneyin.",
    "error_22103": "İstek başarısız oldu (hata kodu: 22103). Lütfen tekrar deneyin",
    "error_22104": "Hesap durumu değişti (hata kodu: 22104), lütfen sayfayı yenileyin ve tekrar deneyin.",
    "error_22105": "Hata: 22105. Lütfen tekrar deneyin.",
    "error_22106": "Hata: 22106. Lütfen tekrar deneyin.",
    "error_22107": "Hata: 22107. Lütfen bizimle iletişime geçin.",
    "error_22108": "İstek başarısız oldu (hata kodu: 22108). Lütfen tekrar deneyin",
    "error_22201": "Hata: 22201. Lütfen bizimle iletişime geçin.",
    "error_22202": "Geçersiz Doğrulama (Hata: 22202). Lütfen tekrar deneyin.",
    "error_22203": "Hata: 22203. Lütfen tekrar deneyin\"",
    "error_22204": "İstek başarısız oldu (hata kodu: 22204). Lütfen tekrar deneyin",
    "error_22206": "İstek başarısız oldu (hata kodu: 22206). Lütfen tekrar deneyin",
    "error_22401": "Hata: 22401. Lütfen bizimle iletişime geçin.",
    "error_22402": "Geçersiz Doğrulama (Hata: 22402). Lütfen tekrar deneyin.",
    "error_22403": "Hata: 22403. Lütfen tekrar deneyin.",
    "error_22404": "İstek başarısız oldu (hata kodu: 22404). Lütfen tekrar deneyin",
    "error_22405": "Facebook hesabı başka bir e-postaya bağlandı",
    "error_22406": "Hata: 22406. Lütfen tekrar deneyin.",
    "error_22407": "Hata: 22407. Lütfen bizimle iletişime geçin.",
    "error_22408": "Hata: 22408. Lütfen bizimle iletişime geçin.",
    "error_22409": "Hata: 22409. Lütfen bizimle iletişime geçin.",
    "error_22410": "Hata: 224010. Lütfen bizimle iletişime geçin.",
    "error_22411": "Hata: 224011. Lütfen bizimle iletişime geçin.",
    "error_22412": "Hata: 224012. Lütfen bizimle iletişime geçin.",
    "error_22413": "Hata: 22413. Lütfen bizimle iletişime geçin.",
    "error_22414": "İstek başarısız oldu (hata kodu: 22414). Lütfen tekrar deneyin",
    "error_22415": "Hata: 22415. Lütfen bizimle iletişime geçin.",
    "error_22416": "Hata: 22416. Lütfen bizimle iletişime geçin.",
    "error_22417": "Hata: 22417. Lütfen bizimle iletişime geçin.",
    "error_22418": "İstek başarısız oldu (hata kodu: 22418). Lütfen tekrar deneyin",
    "error_22419": "Hata: 22419. Lütfen bizimle iletişime geçin.",
    "error_22420": "Hata: 22420. Lütfen bizimle iletişime geçin.",
    "error_22421": "Hata: 22421. Lütfen bizimle iletişime geçin.",
    "error_22422": "İstek başarısız oldu (hata kodu: 22422). Lütfen tekrar deneyin",
    "error_22423": "İstek başarısız oldu (hata kodu: 22423). Lütfen tekrar deneyin",
    "error_22424": "İstek başarısız oldu (hata kodu: 22424). Lütfen tekrar deneyin",
    "error_22425": "İstek başarısız oldu (hata kodu: 22425). Lütfen tekrar deneyin",
    "error_20098": "Hata kodu: 20098. Özel modda geziniyorsanız lütfen Normal moda geçin ve tekrar deneyin.",
    "error_22298": "Google isteği başarısız oldu (Hata: 22298). Lütfen tekrar deneyin.",
    "error_22498": "Facebook isteği başarısız oldu (Hata: 22498). Lütfen tekrar deneyin.",
    "error_24902": "İstek başarısız oldu (hata kodu: 24902). Lütfen tekrar deneyin",
    "error_24903": "İstek başarısız oldu (hata kodu: 24903). Lütfen tekrar deneyin",
    "error_24904": "İstek başarısız oldu (hata kodu: 24904). Lütfen tekrar deneyin",
    "error_24905": "İstek başarısız oldu (hata kodu: 24905). Lütfen tekrar deneyin",
    "login_title": "MindOnMap'te oturum açın",
    "log_in": "Giriş yapmak",
    "no_account": "Hesap yok?",
    "create_it": "Oluştur",
    "or_log_in_with": "Veya şununla giriş yapın:",
    "passwordless_login": "Şifresiz giriş",
    "log_in_done": "Giriş Yap Bitti",
    "three_rd_account_connect_info": "Tebrikler! Başarıyla giriş yaptınız. Artık gelecekte oturum açmak için kullanılacak bir e-posta hesabını bağlayabilirsiniz.",
    "see_my_account": "Hesabımı gör",
    "three_rd_login_merge_account": "Üçüncü taraf hesabının e-posta adresi kaydedildi, doğrudan bu e-posta adresiyle bağlanıp oturum açmak istiyor musunuz?",
    "connect_log_in": "Bağlan ve Oturum Aç",
    "create_an_account": "Bir hesap oluşturun",
    "back_to_log_in": "Oturum açmaya geri dön",
    "create_password": "Şifre oluştur",
    "create_now": "Şimdi oluştur",
    "password_login_subtitle": "E-posta ile şifresiz giriş",
    "account_login": "Hesap girişi",
    "rights": "Bu hesabı oluşturarak <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Hizmet Şartları</a>'nı ve <a href=\"https://www.mindonmap.com/privacy-policy/\">Gizlilik Politikası</a>",
    "passwordless_login_done": "Şifresiz giriş yapıldı",
    "passwordless_login_info": "Tebrikler, şifresiz giriş işlemini başarıyla tamamladınız. Bu hesap için bir şifre oluşturabilir ve gelecekte hesap ve şifreyle giriş yapabilirsiniz. <a href=\"/create-password\" style=\"display: initial;\">Şimdi Oluştur</a>",
    "sign_up": "Üye olmak",
    "register_info": "hesabını oluştur",
    "reset_now": "Şimdi Sıfırla",
    "forgot_password": "Parolanızı mı unuttunuz",
    "reset_password_subtitle": "Şifrenizi sıfırlamak için hesabınızın e-postasını kullanın",
    "plan_products": "Planlar ve Ürünler",
    "nick_name": "Kullanıcı adı:",
    "email": "E-posta:",
    "my_products": "Ürünlerim",
    "my_orders": "Siparişlerim",
    "unlink": "Bağlantıyı kaldır",
    "link": "Bağlantı",
    "connected_accounts": "Bağlı hesaplar",
    "last_name": "Soy isim:",
    "first_name": "İlk adı:",
    "Gender": "Cinsiyet:",
    "Birth": "Doğum:",
    "Month": "Ay",
    "Year": "Yıl",
    "Country_Region": "Ülke/Bölge:",
    "Address": "Adres:",
    "Save": "Kaydetmek",
    "Date": "Tarih",
    "Male": "Erkek",
    "Female": "Dişi",
    "Unspecified": "belirtilmemiş",
    "Security": "Güvenlik",
    "change_password": "Şifre değiştir",
    "change_now": "Şimdi değiştir",
    "connect_email": "E-postayı bağlayın",
    "delete_account": "Hesabı sil",
    "delete_account_info": "Hesabınız silindiğinde, Hesabım bölümünde hesabınızla ilişkili tüm veriler kalıcı olarak silinir ve bunları kurtaramayabilirsiniz. Dikkatli hareket etmenizi öneririz.",
    "Delete": "Silmek",
    "Logout": "Çıkış Yap",
    "my_profile": "Benim profilim",
    "guides_faqs": "Kılavuzlar ve SSS",
    "More": "Daha",
    "guides": "Kılavuzlar",
    "register": "kayıt olmak",
    "hot_faq": "Güncel SSS",
    "Contents": "İçindekiler:",
    "contact_us": "Bize ulaşın>>",
    "plan": "Plan",
    "unregistered": "Kayıtlı değil",
    "buy_more": "Daha Fazla Satın Alın",
    "buy_again": "Tekrar satın al",
    "buy_now": "Şimdi al",
    "free_no_limit": "Ücretsiz ve Sınırsız",
    "expired": "Günü geçmiş",
    "lifetime": "Ömür",
    "remain": "Geriye kalmak",
    "day_s": "Gün(ler)",
    "error_24801": "İstek başarısız oldu (hata kodu: 24801). Lütfen tekrar deneyin",
    "no_app_found": "Uygulama bulunamadı！<a href=\"/\">Yenileyin</a> veya <a href=\"https://www.mindonmap.com/\">Resmi web sitesine gidin</a>",
    "get_more": "Daha Fazlasını Alın >>",
    "edit_photo": "Fotoğrafı Düzenle",
    "select_photo": "Fotoğraf seç",
    "change_photo": "Fotoğrafı değiştir",
    "cancel": "İptal etmek",
    "hide_password": "Şifreyi gizle",
    "show_password": "Şifreyi göster",
    "zoom_in": "Yakınlaştır",
    "zoom_out": "Uzaklaştır",
    "rotate": "Döndür",
    "horizontal_flip": "Yatay çevirme",
    "vertical_flip": "Dikey çevirme",
    "country": "Ülke",
    "country_1": "Ülkenizi/bölgenizi seçin",
    "country_2": "Åaland Adası",
    "country_3": "Afganistan",
    "country_4": "Arnavutluk",
    "country_5": "Cezayir",
    "country_6": "Amerikan Samoası",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktika",
    "country_11": "Antigua ve Barbuda",
    "country_12": "Arjantin",
    "country_13": "Ermenistan",
    "country_14": "Aruba",
    "country_15": "Avustralya",
    "country_16": "Avusturya",
    "country_17": "Azerbaycan",
    "country_18": "Bahreyn",
    "country_19": "Bangladeş",
    "country_20": "Barbados",
    "country_21": "Belarus",
    "country_22": "Belçika",
    "country_23": "Belize",
    "country_24": "Benin",
    "country_25": "Bermuda",
    "country_26": "Butan",
    "country_27": "Bolivya",
    "country_28": "Bosna & Hersek",
    "country_29": "Botsvana",
    "country_30": "Bouvet Adası",
    "country_31": "Brezilya",
    "country_32": "Britanya Hint Okyanusu Toprakları",
    "country_33": "Britanya Virgin Adaları",
    "country_34": "Brunei",
    "country_35": "Bulgaristan",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kamboçya",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Yeşil Burun Adaları",
    "country_42": "Karayipler Hollanda",
    "country_43": "Cayman Adaları",
    "country_44": "Orta Afrika Cumhuriyeti",
    "country_45": "Çad",
    "country_46": "Şili",
    "country_47": "Çin",
    "country_48": "Noel Adası",
    "country_49": "Cocos (Keeling) Adaları",
    "country_50": "Kolombiya",
    "country_51": "Cook Adaları",
    "country_52": "Kosta Rika",
    "country_53": "Fildişi Sahili",
    "country_54": "Hırvatistan",
    "country_55": "Küba",
    "country_56": "Kıbrıs",
    "country_57": "Çek Cumhuriyeti",
    "country_58": "Kongo Demokratik Cumhuriyeti",
    "country_59": "Danimarka",
    "country_60": "Cibuti",
    "country_61": "Dominika",
    "country_62": "Dominik Cumhuriyeti",
    "country_63": "Ekvador",
    "country_64": "Mısır",
    "country_65": "El Salvador",
    "country_66": "Ekvator Ginesi",
    "country_67": "Eritre",
    "country_68": "Estonya",
    "country_69": "Etiyopya",
    "country_70": "Falkland adaları",
    "country_71": "Faroe Adaları",
    "country_72": "Mikronezya Federe Devletleri",
    "country_73": "Fiji",
    "country_74": "Finlandiya",
    "country_75": "Fransa",
    "country_76": "Fransız Guyanası",
    "country_77": "Fransız Polinezyası",
    "country_78": "Fransız Güney Bölgeleri",
    "country_79": "Gabon",
    "country_80": "Gambiya",
    "country_81": "Gürcistan",
    "country_82": "Almanya",
    "country_83": "Gana",
    "country_84": "Cebelitarık",
    "country_85": "Büyük Britanya (Birleşik Krallık; İngiltere)",
    "country_86": "Yunanistan",
    "country_87": "Grönland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Gine",
    "country_94": "Gine-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Heard Adası ve McDonald Adaları",
    "country_98": "Honduras",
    "country_99": "Hong Kong",
    "country_100": "Macaristan",
    "country_101": "İzlanda",
    "country_102": "Hindistan",
    "country_103": "Endonezya",
    "country_104": "İran",
    "country_105": "Irak",
    "country_106": "İrlanda",
    "country_107": "Man Adası",
    "country_108": "İsrail",
    "country_109": "İtalya",
    "country_110": "Jamaika",
    "country_111": "Japonya",
    "country_112": "Jersey",
    "country_113": "Ürdün",
    "country_114": "Kazakistan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Kuveyt",
    "country_118": "Kırgızistan",
    "country_119": "Laos",
    "country_120": "Letonya",
    "country_121": "Lübnan",
    "country_122": "Lesoto",
    "country_123": "Liberya",
    "country_124": "Libya",
    "country_125": "Lihtenştayn",
    "country_126": "Litvanya",
    "country_127": "Lüksemburg",
    "country_128": "Makao",
    "country_129": "Madagaskar",
    "country_130": "Malawi",
    "country_131": "Malezya",
    "country_132": "Maldivler",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marşal Adaları",
    "country_136": "Martinik",
    "country_137": "Moritanya",
    "country_138": "Mauritius",
    "country_139": "Mayotte",
    "country_140": "Meksika",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Moğolistan",
    "country_144": "Karadağ",
    "country_145": "Montserrat",
    "country_146": "Fas",
    "country_147": "Mozambik",
    "country_148": "Myanmar (Burma)",
    "country_149": "Namibya",
    "country_150": "Nauru'lu",
    "country_151": "Nepal",
    "country_152": "Hollanda",
    "country_153": "Yeni Kaledonya",
    "country_154": "Yeni Zelanda",
    "country_155": "Nikaragua",
    "country_156": "Nijer",
    "country_157": "Nijerya",
    "country_158": "Niue",
    "country_159": "Norfolk Adası",
    "country_160": "Kuzey Kore",
    "country_161": "Kuzey Mariana Adaları",
    "country_162": "Norveç",
    "country_163": "Umman",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Filistin Bölgesi",
    "country_167": "Panama",
    "country_168": "Papua Yeni Gine",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Pitcairn Adaları",
    "country_172": "Polonya",
    "country_173": "Portekiz",
    "country_174": "Porto Riko",
    "country_175": "Katar",
    "country_176": "Makedonya Cumhuriyeti (FYROM)",
    "country_177": "Kongo Cumhuriyeti",
    "country_178": "Réunion",
    "country_179": "Romanya",
    "country_180": "Rusya Federasyonu",
    "country_181": "Ruanda",
    "country_182": "Aziz Barthélemy",
    "country_183": "Saint Martin (Fransa)",
    "country_184": "Saint-Pierre ve Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Suudi Arabistan",
    "country_189": "Senegal",
    "country_190": "Sırbistan",
    "country_191": "Seyşeller",
    "country_192": "Sierra Leone",
    "country_193": "Singapur",
    "country_194": "Slovakya",
    "country_195": "Slovenya",
    "country_196": "Solomon Adaları",
    "country_197": "Somali",
    "country_198": "Güney Afrika",
    "country_199": "Güney Georgia ve Güney Sandviç Adası",
    "country_200": "Güney Kore",
    "country_201": "Güney Sudan",
    "country_202": "ispanya",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena ve Bağımlılıklar",
    "country_205": "St. Kitts ve Nevis",
    "country_206": "Aziz Lucia",
    "country_207": "St. Vincent ve Grenadinler",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Svaziland",
    "country_211": "İsveç",
    "country_212": "İsviçre",
    "country_213": "Suriye",
    "country_214": "Tayvan",
    "country_215": "Tacikistan",
    "country_216": "Tanzanya",
    "country_217": "Şablon:Ülke verileri SJM Svalbard",
    "country_218": "Tayland",
    "country_219": "Bahamalar",
    "country_220": "Komorlar",
    "country_221": "Filipinler",
    "country_222": "Doğu Timor (Doğu Timor)",
    "country_223": "Gitmek",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunus",
    "country_228": "Türkiye",
    "country_229": "Türkmenistan",
    "country_230": "Turks ve Caicos Adaları",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukrayna",
    "country_234": "Birleşik Arap Emirlikleri",
    "country_235": "Amerika Birleşik Devletleri Küçük Dış Adaları",
    "country_236": "Amerika Birleşik Devletleri (ABD)",
    "country_237": "Amerika Birleşik Devletleri Virgin Adaları",
    "country_238": "Uruguay",
    "country_239": "Özbekistan",
    "country_240": "Vanuatu",
    "country_241": "Vatikan Şehri (Vatikan)",
    "country_242": "Venezuela",
    "country_243": "Vietnam",
    "country_244": "Wallis ve Futuna",
    "country_245": "Batı Sahra",
    "country_246": "Yemen",
    "country_247": "Zambiya",
    "country_248": "Zimbabve",
    "google_login": "Google ile giriş yap",
    "State": "Durum",
    "Activation_code": "Aktivasyon kodu",
    "Question": "Giriş yaptığınız tüm uygulamaları listeleyin",
    "Copy_complete": "Kopyalama tamamlandı",
    "footer": "Telif Hakkı © 2024 MindOnMap Studio. Her hakkı saklıdır.",
    "change_password_success": "parola başarıyla değiştirildi",
    "successful_login_title": "Giriş başarılı",
    "product_page": "Ürün Sayfası>>",
    "successful_login_info": "Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün. Geçerli sayfa 5 saniye içinde otomatik olarak kapatılacaktır. Otomatik kapatma veya \"Bitti\" düğmesini tıklatarak kapatma işlemi başarısız olursa, lütfen bu sekmeyi doğrudan kapatın.",
    "successful_login_info_firefox": "Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün.",
    "my_account": "Hesabım",
    "my_history": "Benim tarihim",
    "remove_watermark": "Filigranı Kaldır",
    "no_history": "Geçmiş Yok",
    "history_all": "Hepsini seç",
    "history_open": "Açık",
    "history_down": "İndirmek",
    "history_delete": "Silmek",
    "history_clear": "Geçersizi temizle",
    "images": "Görüntüler",
    "use_this_function": "Bu Ürünü Kullan>>",
    "hd_downloading": "HD orijinal görselleri indirmenin faydaları:",
    "lifetimeRemaining": "Ömür Boyu-Kalan",
    "Remaining": "Geriye kalan",
    "email_verification": "Eposta Doğrulama",
    "email_verification_info": "Doğrulama mesajını <span class=\"email\"></span> e-posta adresinize gönderdik; lütfen doğrulama işlemini tamamlayın. Doğrulamanın ardından avantajlar otomatik olarak senkronize edilecektir.",
    "wrong_email": "Yanlış e-posta adresi?",
    "click_here_to_modify": "Değiştirmek için burayı tıklayın",
    "get_more_help": "Daha fazla yardım almak ister misiniz?",
    "click_here": "buraya tıklayın",
    "email_verification_info_success": "E-posta hesabınızı doğruladığınız için tebrikler.",
    "email_verification_info_error": "Bağlantının süresi dolduğu için doğrulama başarısız oldu.",
    "registration_succeeded": "Kayıt Başarılı",
    "registration_succeeded_info_1": "Tebrikler! Başarıyla kayıt oldunuz. Doğrulama mesajını <span class=\"email\"></span> e-posta adresinize gönderdik. Bu e-postanın avantajlarından yararlanmak için lütfen doğrulama işlemini tamamlayın.",
    "registration_succeeded_info_2": "Ana sayfaya dönmek ve bu ürünü kullanmak için \"Bitti\"yi tıklayın.",
    "registration_succeeded_info_3": "Geçerli sayfayı kapatmak ve aşağıdaki işlemler için Ana sayfaya dönmek için \"Bitti\"ye tıklayın. Kapatma başarısız olursa lütfen sekmeyi manuel olarak kapatın.",
    "verify_email": "E-mail'i doğrula",
    "registered_email_not_verified": "Kayıtlı e-postanız doğrulanmadı, lütfen hemen doğrulayın.",
    "email_verification_time_1": "Doğrulama e-postası almadınız mı?",
    "email_verification_time_2": "Sonrasında",
    "email_verification_time_3": "Yeniden göndermek için buraya tıklayın",
    "error_26301": "Hata kodu: 26301, lütfen bizimle iletişime geçin",
    "error_26302": "Hata kodu: 26302, lütfen bizimle iletişime geçin",
    "error_26303": "E-posta biçimi hatası (hata kodu: 26303). Lütfen tekrar girin",
    "error_26304": "Şifrenin 8 karakterden uzun olması önerilir (hata kodu: 26304)",
    "error_26305": "Talep başarısız oldu (hata kodu: 26305). Lütfen tekrar deneyin",
    "error_26306": "E-posta kaydedildi, lütfen <a href=\"https://account.mindonmap.com/login/\">giriş yapmak için gidin</a>",
    "error_26307": "Talep başarısız oldu (hata kodu: 26307). Lütfen tekrar deneyin",
    "error_26308": "Talep başarısız oldu (hata kodu: 26308). Lütfen tekrar deneyin",
    "error_26401": "Hata kodu: 26401, lütfen tekrar deneyin",
    "error_26402": "E-posta doğrulandı (hata kodu: 26402), lütfen tekrar deneyin",
    "error_26403": "Talep başarısız oldu (hata kodu: 26403). Lütfen tekrar deneyin",
    "error_26404": "İstek başarısız oldu (hata kodu: 26404). Lütfen tekrar deneyin",
    "error_26501": "Hata kodu: 26501, lütfen bizimle iletişime geçin",
    "error_26502": "Hata kodu: 26502, lütfen bizimle iletişime geçin",
    "error_26503": "E-posta biçimi hatası (hata kodu: 26503). Lütfen tekrar girin",
    "error_26504": "Talep başarısız oldu (hata kodu: 26504). Lütfen tekrar deneyin",
    "error_26505": "E-posta kaydedilmedi, lütfen <a href=\"https://account.mindonmap.com/register/\">önce e-postayı kaydedin</a>",
    "error_26506": "E-posta doğrulandı.",
    "error_26507": "Talep başarısız oldu (hata kodu: 26507). Lütfen tekrar deneyin",
    "error_26508": "Doğrulama başarısız oldu (hata kodu: 26508), lütfen tekrar deneyin",
    "error_26509": "İstek başarısız oldu (hata kodu: 26509), lütfen tekrar deneyin",
    "error_26510": "Hata kodu: 26510, lütfen bizimle iletişime geçin",
    "error_26601": "Hata kodu: 26601, lütfen bizimle iletişime geçin",
    "error_26602": "Hata kodu: 26602, lütfen bizimle iletişime geçin",
    "error_26603": "Talep başarısız oldu (hata kodu: 26603). Lütfen tekrar deneyin",
    "error_26604": "Hata kodu: 26604, lütfen bizimle iletişime geçin",
    "error_26605": "Hata kodu: 26605, lütfen bizimle iletişime geçin",
    "error_26701": "Hata kodu: 26701, lütfen bizimle iletişime geçin",
    "error_26702": "Talep başarısız oldu (hata kodu: 26702). Lütfen tekrar deneyin",
    "error_26703": "Hata kodu: 26703, lütfen bizimle iletişime geçin",
    "error_26704": "Hata kodu: 26704, lütfen bizimle iletişime geçin",
    "error_26705": "Oturum açmayı bekleyin (hata kodu: 26705). Lütfen tekrar deneyin",
    "no_cookie": "Tarayıcınızda Tüm çerezleri engelle işlevini açtığınızdan oturum açamıyorsunuz. Lütfen Ayarlar'a giderek Tüm çerezlere izin ver kutusunu işaretleyin.",
    "error_26801": "Hata kodu: 26801, lütfen bizimle iletişime geçin",
    "error_26802": "Hata kodu: 26802, lütfen bizimle iletişime geçin",
    "error_26803": "İstek başarısız oldu (hata kodu: 26803). Lütfen tekrar deneyin",
    "error_26804": "İstek başarısız oldu (hata kodu: 26804). Lütfen tekrar deneyin",
    "error_order": "İstek başarısız oldu (hata kodu: 27098), lütfen tekrar deneyin!",
    "error_order1": "Sipariş sorgusu eksik (hata kodu: ",
    "error_order2": "）, lütfen yenileyin ve tekrar deneyin.",
    "modify_email_title": "E-postayı Değiştir",
    "modify_email_info": "Hesabınıza giriş yapmak için değiştirilen e-postayı kullanabilirsiniz.",
    "images_per": "Görüntü başına",
    "error_26101": "Hata: 26101. Lütfen bizimle iletişime geçin.",
    "error_26102": "Hata: 26102. Lütfen bizimle iletişime geçin.",
    "error_26103": "İstek başarısız oldu (hata kodu: 26103). Lütfen tekrar deneyin",
    "error_26104": "Hata kodu: 26104, lütfen tekrar deneyin",
    "error_26105": "Hata kodu: 26105, lütfen tekrar deneyin",
    "error_26106": "Silme başarısız oldu (hata kodu: 26106). Lütfen tekrar deneyin",
    "error_26201": "Hata: 26201. Lütfen bizimle iletişime geçin.",
    "error_26202": "İstek başarısız oldu (hata kodu: 26202). Lütfen tekrar deneyin",
    "error_26001": "Hata: 26001. Lütfen bizimle iletişime geçin.",
    "error_26002": "Hata: 26002. Lütfen bizimle iletişime geçin.",
    "error_26003": "Hata: 26003. Lütfen bizimle iletişime geçin.",
    "error_26004": "Hata: 26004. Lütfen bizimle iletişime geçin.",
    "error_26005": "İstek başarısız oldu (hata kodu: 26005). Lütfen tekrar deneyin",
    "error_26006": "Hata kodu: 26006, lütfen tekrar deneyin",
    "error_26008": "Hata: 26008. Lütfen bizimle iletişime geçin.",
    "go_to_the_home_page": "Ana sayfaya git",
    "error_27101": "İstek başarısız oldu (hata kodu: 27101). Lütfen yeniden deneyin",
    "error_27201": "Hata kodu: 27201, lütfen bizimle iletişime geçin",
    "error_27202": "Hata kodu: 27202, lütfen tekrar deneyin",
    "error_27203": "İstek başarısız oldu (hata kodu: 27203). Lütfen tekrar deneyin",
    "error_27204": "Geçersiz kod (hata kodu: 27204).",
    "error_27205": "İstek başarısız oldu (hata kodu: 27205). Lütfen tekrar deneyin",
    "error_27206": "İstek başarısız oldu (hata kodu: 27206). Lütfen tekrar deneyin",
    "error_27207": "İstek başarısız oldu (hata kodu: 27207). Lütfen tekrar deneyin",
    "no_history_found": "Herhangi bir alet kullanmadınız! <a href=\"/\">Yenileyin</a> veya <a href=\"https://www.mindonmap.com/\">Resmi web sitesine gidin</a>",
    "error_25301": "Hata: 25301. Lütfen bizimle iletişime geçin.",
    "error_25302": "Hata: 25302. Lütfen bizimle iletişime geçin.",
    "error_25303": "İstek başarısız oldu (hata kodu: 25303). Lütfen tekrar deneyin",
    "error_25304": "İstek başarısız oldu (hata kodu: 25304). Lütfen tekrar deneyin",
    "error_25305": "İstek başarısız oldu (hata kodu: 25305). Lütfen tekrar deneyin",
    "error_25306": "İstek başarısız oldu (hata kodu: 25306). Lütfen tekrar deneyin",
    "image_upscaler_p": "Filigransız resim indirmenin faydaları:",
    "Available_for": "İçin uygun:",
    "credit_per": "HD görüntü başına %s kredi",
    "still_valid": "Satın alınan plan(lar) hala geçerlidir",
    "credit": "kredi)",
    "pc_3rd_info": "Giriş başarılı. Daha fazla işlem için lütfen uygulamaya gidin.",
    "use_online": "Çevrimiçi hizmeti kullanın",
    "use_download": "Masaüstü programını kullanın",
    "use_immediately": "Hemen Kullanın",
    "Use_in_browser": "Tarayıcıda Kullan",
    "win_desktop": "pencereler",
    "Mac_desktop": "Mac",
    "credits_per": "Aylık {%} Kredi",
    "expire": "Son kullanma tarihi:",
    "viewDetails": "Detayları göster",
    "viewHistory": "Geçmişin avantajlarını görüntüleyin>>",
    "viewDetailsInfo": "Uyarı: Abonelik avantajlarının sona ermesinden sonraki 7 gün içinde yenilenmesi durumunda kullanılmayan avantajlardan yararlanmaya devam edilebilir. Ayrıca sona erme süresi, yeni aboneliğin sona erme süresine göre otomatik olarak güncellenecektir. 7 günlük sona erme süresinden sonra yeni abonelik olmazsa, süresi dolan tüm avantajlar silinecektir.",
    "connect_account": "E-postayı Hesabınıza Bağlayın",
    "connect_account_info": "Bağlandıktan sonra bu e-posta adresiyle giriş yapabilirsiniz.",
    "connect_now": "Şimdi Bağla",
    "no_email_bind": "E-posta bağlantısı yok",
    "bind_email": "E-postayı Bağla",
    "connect_your_email_placeholder": "lütfen e-mail adresinizi giriniz",
    "bind_an_email": "Bir E-postayı Bağla",
    "bind_info": "Başarıyla giriş yaptınız. Avantajlarınızı etkinleştirmek için lütfen hesabınıza bir e-posta bağlayın.",
    "bind_later": "Daha sonra bağla",
    "hi": "Onun!",
    "Personal_Information": "Kişisel bilgi",
    "Access": "Erişim",
    "Subscription_Plan": "(Abonelik Planı)",
    "No_orders": "Sipariş bulunamadı.",
    "No_data": "Veri yok",
    "Featured_Products": "Özel Ürünler",
    "More_Products": "Daha fazla ürün",
    "Free_Download": "Ücretsiz indirin",
    "Get_Started": "Başlamak",
    "Subscribe": "Abone",
    "Verified": "Doğrulandı",
    "back_to_account_center": "Hesap Merkezine Geri Dön",
    "success": "Başarı!",
    "successfully": "Bir hesabı başarıyla kaydettiniz.",
    "Continue": "Devam etmek",
    "Already": "Zaten hesabınız var mı?",
    "loading_verification": "Doğrulama durumu kontrol ediliyor...",
    "email_no_verification": "Üzgünüz, kayıtlı e-posta adresi doğrulanmadı. Lütfen doğrulamayı yukarıdaki talimatlara göre tamamlayın ve kaydı tamamlamak için tekrar \"Bitti\" butonuna tıklayın.",
    "will_expire_after": "Şu tarihten sonra sona erecek",
    "hours": "saat",
  },
  "uk": {
    "overtime": "Код помилки: {%}, спробуйте ще раз",
    "isnetwork": "Помилка Інтернету. Перевірте та повторіть спробу",
    "email_placeholder": "Електронна пошта",
    "email_empty": "Введіть адресу електронної пошти",
    "email_not_valid": "Електронна адреса недійсна",
    "email_not_valid_1": "Будь ласка, введіть свою електронну адресу",
    "email_not_valid_2": "Електронна адреса недійсна, використовуйте іншу адресу.",
    "email_not_valid_3": "Без введення електронної пошти",
    "password_placeholder": "Пароль",
    "password_empty": "Будь ласка, введіть пароль",
    "password_not_valid": "Неправильний обліковий запис або пароль",
    "password_not_valid_1": "Потрібен пароль довжиною понад 8 символів",
    "password_not_valid_2": "Будь ласка, створіть пароль",
    "password_placeholder_1": "Створіть свій пароль",
    "password_placeholder_2": "Підтвердити пароль",
    "password_placeholder_3": "Створіть новий пароль",
    "password_placeholder_4": "Підтвердити новий пароль",
    "password_placeholder_5": "Введіть старий пароль",
    "copy_password_empty": "Підтвердьте пароль",
    "copy_password_not_valid": "Будь ласка, підтвердьте свій пароль",
    "copy_passwords_inconsistent": "Ваше підтвердження пароля не збігається",
    "code_empty": "Будь ласка, введіть код підтвердження",
    "code_not_valid": "Недійсний код підтвердження",
    "code_placeholder": "Код підтвердження",
    "not_received_code": "Якщо на вашу поштову скриньку протягом тривалого часу не надходив код підтвердження, отримайте код підтвердження ще раз.",
    "get_first_code": "Спочатку отримайте код підтвердження.",
    "last_name_placeholder": "Введіть своє прізвище",
    "first_name_placeholder": "Будь ласка, введіть своє ім'я",
    "address_placeholder": "Будь ласка, введіть свою адресу",
    "no_code_text": "Ми надіслали код підтвердження. Будь ласка, введіть свій код. <span class='tips'>Не отримали код?",
    "no_code_text_1": "1. Будь ласка, переконайтеся, що адреса електронної пошти дійсна та може отримувати електронні листи.",
    "no_code_text_2": "2. Оскільки електронний лист надсилається системою автоматично, він може бути позначений як спам або небажана електронна пошта. Перевірте, чи електронний лист знаходиться в папці \"Кошик\".",
    "no_code_text_3": "3. Не можете вирішити свою проблему? ",
    "no_code_text_3_span": "Тоді натисніть тут, щоб зв’язатися з нами.",
    "order_no": "Ви не придбали жодного продукту. Якщо у вас виникли запитання, <a href=\"https://www.mindonmap.com/contact-us/\">зв’яжіться з нами</a>.",
    "error_24901": "Поточний обліковий запис не має пов’язаної електронної пошти, і не може знайти замовлення. Підключіть електронну адресу.",
    "user_guide": "Керівництво користувача>>",
    "download": "Завантажити",
    "order_number": "Номер замовлення:",
    "Refund": "Повернення коштів",
    "Disabled": "Вимкнено",
    "Normal": "нормальний",
    "Modify": "Змінити",
    "Modify_1": "Змінити>>",
    "Connect": "Підключитися",
    "unlink_success": "Від’єднання успішно",
    "connect_success": "Успішне підключення",
    "feedback_title": "Дякуємо за ваш відгук. Будь ласка, залиште свою проблему, і ми відповімо вам протягом 24 годин.",
    "feedback_thank_you": "Дякуємо!<br />Ваш відгук успішно надіслано.",
    "feedback_email": "Введіть свою електронну адресу тут!",
    "feedback_content": "Залиште тут будь-яку проблему чи пропозицію, з якою ви зіткнулися.",
    "feedback_submit": "Надіслати",
    "form_contents": "Ви не ввели жодного опису. Введіть його та надішліть знову.",
    "old_password": "Будь ласка, введіть старий пароль",
    "new_password": "Будь ласка, створіть новий пароль",
    "old_new_password": "Новий пароль не може збігатися зі старим",
    "incorrect_password": "Невірний пароль",
    "delete_no": "Видалити зараз",
    "Caps": "Caps Lock увімкнено",
    "Get": "отримати",
    "Done": "Готово",
    "error_20001": "Помилка: 20001. Увійдіть знову.",
    "error_20002": "Помилка: 20002. Увійдіть знову.",
    "error_20003": "Помилка: 20003. Увійдіть знову.",
    "error_20004": "Помилка запиту (код помилки: 20004). Будь ласка, спробуйте ще раз.",
    "error_20005": "Сеанс входу закінчився (помилка: 20005). Будь ласка, увійдіть знову.",
    "error_20006": "Помилка запиту (код помилки: 20006). Будь ласка, спробуйте ще раз.",
    "error_20007": "Сеанс входу закінчився (помилка: 20007). Будь ласка, увійдіть знову.",
    "error_20008": "Сеанс входу закінчився (помилка: 20008). Будь ласка, увійдіть знову.",
    "error_20009": "Сеанс входу закінчився (помилка: 20009). Будь ласка, увійдіть знову.",
    "error_20101": "Будь ласка, введіть свою електронну адресу (код помилки: 20101)",
    "error_20102": "Електронна адреса недійсна (код помилки: 20102)",
    "error_20103": "Помилка запиту (код помилки: 20103). Будь ласка, спробуйте ще раз",
    "error_20104": "Електронна пошта вже використовується, <a href=\"https://account.mindonmap.com/login/\">увійдіть</a> або зареєструйтеся з новою",
    "error_20105": "Помилка запиту (код помилки: 20105). Будь ласка, спробуйте ще раз",
    "error_20106": "Не вдалося надіслати електронний лист, повторіть спробу",
    "error_20201": "Будь ласка, введіть свою електронну адресу (код помилки: 20201)",
    "error_20202": "Будь ласка, введіть свій пароль (код помилки: 20202)",
    "error_20203": "Будь ласка, введіть код підтвердження (код помилки: 20203)",
    "error_20204": "Електронна адреса недійсна (код помилки: 20204)",
    "error_20205": "Потрібен пароль понад 8 символів (код помилки: 20205)",
    "error_20206": "Помилка запиту (код помилки: 20206). Будь ласка, спробуйте ще раз",
    "error_20207": "Недійсний код підтвердження",
    "error_20208": "Помилка запиту (код помилки: 20208). Будь ласка, спробуйте ще раз",
    "error_20209": "Помилка запиту (код помилки: 20209). Будь ласка, спробуйте ще раз",
    "error_20301": "Будь ласка, введіть свою електронну адресу (код помилки: 20301)",
    "error_20302": "Помилка: 20302. Зв'яжіться з нами",
    "error_20303": "Електронна адреса недійсна (код помилки: 20303)",
    "error_20304": "Помилка запиту (код помилки: 20304). Будь ласка, спробуйте ще раз",
    "error_20305": "Обліковий запис не існує. Введіть повторно або спершу <a href=\"https://account.mindonmap.com/register/\">Створіть його</a>.",
    "error_20306": "Ще немає пароля. Використовуйте <a href=\"https://account.mindonmap.com/passwordless-login/\">вхід без пароля</a> або <a href=\"https://account.mindonmap.com/create-password/\"> встановіть пароль</a> і увійдіть.",
    "error_20308": "Помилка запиту (код помилки: 20308). Будь ласка, спробуйте ще раз",
    "error_20401": "Не вдалося вийти (код помилки: 20401). Будь ласка, спробуйте ще раз",
    "error_20501": "Будь ласка, введіть свою електронну адресу (код помилки: 20501)",
    "error_20502": "Електронна адреса недійсна (код помилки: 20502)",
    "error_20503": "Помилка запиту (код помилки: 20503). Будь ласка, спробуйте ще раз",
    "error_20504": "Не вдалося надіслати електронний лист. Будь ласка, спробуйте ще раз.",
    "error_20601": "Будь ласка, введіть свою електронну адресу (код помилки: 20601)",
    "error_20602": "Будь ласка, введіть код підтвердження (код помилки: 20602)",
    "error_20603": "Електронна адреса недійсна (код помилки: 20603)",
    "error_20604": "Помилка запиту (код помилки: 20604). Будь ласка, спробуйте ще раз",
    "error_20606": "Помилка запиту (код помилки: 20606). Будь ласка, спробуйте ще раз",
    "error_20607": "Помилка запиту (код помилки: 20607). Будь ласка, спробуйте ще раз",
    "error_20608": "Помилка запиту (код помилки: 20608). Будь ласка, спробуйте ще раз",
    "error_20701": "Будь ласка, введіть свою електронну адресу (код помилки: 20701)",
    "error_20702": "Електронна адреса недійсна (код помилки: 20702)",
    "error_20703": "Помилка запиту (код помилки: 20703). Будь ласка, спробуйте ще раз",
    "error_20704": "Обліковий запис не існує. Введіть повторно або спершу <a href=\"https://account.mindonmap.com/register/\">Створіть його</a>.",
    "error_20705": "Помилка запиту (код помилки: 20705). Будь ласка, спробуйте ще раз",
    "error_20706": "Не вдалося надіслати електронний лист. Будь ласка, спробуйте ще раз",
    "error_20801": "Будь ласка, введіть свою електронну адресу (код помилки: 20801)",
    "error_20802": "Помилка: 20802. Зв'яжіться з нами",
    "error_20803": "Будь ласка, введіть код підтвердження (код помилки: 20803)",
    "error_20804": "Електронна адреса недійсна (код помилки: 20804)",
    "error_20805": "Потрібен пароль понад 8 символів (код помилки: 20805)",
    "error_20806": "Помилка запиту (код помилки: 20806). Будь ласка, спробуйте ще раз",
    "error_20808": "Помилка запиту (код помилки: 20808). Будь ласка, спробуйте ще раз",
    "error_20901": "Помилка запиту (код помилки: 20901). Будь ласка, спробуйте ще раз",
    "error_20902": "Помилка запиту (код помилки: 20902). Будь ласка, спробуйте ще раз",
    "error_21000": "Зміни збережено",
    "error_21001": "Інформація не надана (код помилки: 21001)",
    "error_21002": "Помилка запиту (код помилки: 21002). Будь ласка, спробуйте ще раз",
    "error_21101": "Будь ласка, введіть свою електронну адресу (код помилки: 21101)",
    "error_21102": "Електронна адреса недійсна (код помилки: 21102)",
    "error_21103": "Помилка запиту (код помилки: 21103), будь ласка, повторіть спробу",
    "error_21104": "Електронна пошта вже підключена, використовуйте нову",
    "error_21105": "Помилка запиту (код помилки: 21105), будь ласка, повторіть спробу",
    "error_21106": "Не вдалося надіслати електронний лист. Будь ласка, спробуйте ще раз",
    "error_21201": "Будь ласка, введіть свою електронну адресу (код помилки: 21201)",
    "error_21202": "Будь ласка, введіть код підтвердження (код помилки: 21202)",
    "error_21203": "Електронна адреса недійсна (код помилки: 21203)",
    "error_21204": "Помилка: 21204. Будь ласка, зв'яжіться з нами",
    "error_21205": "Помилка: 21205. Зв'яжіться з нами",
    "error_21206": "Потрібен пароль понад 8 символів (код помилки: 21206)",
    "error_21207": "Помилка запиту (код помилки: 21207). Будь ласка, спробуйте ще раз",
    "error_21209": "Помилка запиту (код помилки: 21209). Будь ласка, спробуйте ще раз",
    "error_21301": "Введіть старий пароль (код помилки: 21301)",
    "error_21302": "Будь ласка, створіть новий пароль (код помилки: 21302)",
    "error_21303": "Новий пароль не може збігатися зі старим. (Помилка: 21303)",
    "error_21304": "Необхідно ввести пароль понад 8 символів (код помилки: 21304)",
    "error_21306": "Помилка запиту (код помилки: 21306). Будь ласка, спробуйте ще раз",
    "error_21402": "Помилка запиту (код помилки: 21402). Будь ласка, спробуйте ще раз",
    "error_21403": "Не вдалося надіслати код підтвердження. Надішліть його повторно",
    "error_21500": "Обліковий запис видалено",
    "error_21501": "Будь ласка, введіть код підтвердження (код помилки: 21501)",
    "error_21502": "Сеанс входу закінчився (помилка: 21502). Будь ласка, увійдіть знову.",
    "error_21503": "Помилка запиту (код помилки: 21503). Будь ласка, спробуйте ще раз",
    "error_21505": "Помилка запиту (код помилки: 21505), повторіть спробу",
    "error_21601": "Помилка: 20601. Будь ласка, зв'яжіться з нами",
    "error_21602": "Недійсне підтвердження (помилка: 20602). Будь ласка, спробуйте ще раз.",
    "error_21603": "Помилка: 20603. Повторіть спробу",
    "error_21604": "Помилка запиту (код помилки: 21604). Будь ласка, спробуйте ще раз",
    "error_21606": "Помилка запиту (код помилки: 21606). Будь ласка, спробуйте ще раз",
    "error_21611": "Помилка запиту (код помилки: 21611). Будь ласка, спробуйте ще раз",
    "error_21801": "Помилка: 21801. Будь ласка, зв'яжіться з нами",
    "error_21802": "Помилка запиту (помилка: 21802). Будь ласка, спробуйте ще раз",
    "error_21803": "Помилка: 21803. Повторіть спробу",
    "error_21804": "Помилка запиту (код помилки: 21804). Будь ласка, спробуйте ще раз",
    "error_21806": "Помилка: 21806. Повторіть спробу",
    "error_21807": "Помилка: 21807. Зв'яжіться з нами",
    "error_21808": "Помилка: 21808. Будь ласка, зв'яжіться з нами",
    "error_21809": "Помилка: 21809. Будь ласка, зв'яжіться з нами",
    "error_21810": "Помилка: 21810. Будь ласка, зв'яжіться з нами",
    "error_21811": "Помилка: 21811. Будь ласка, зв'яжіться з нами",
    "error_21812": "Помилка: 21812. Зв'яжіться з нами",
    "error_21813": "Помилка запиту (код помилки: 21813). Будь ласка, спробуйте ще раз",
    "error_21814": "Помилка: 21814. Зв'яжіться з нами",
    "error_21815": "Помилка запиту (код помилки: 21815). Будь ласка, спробуйте ще раз",
    "error_21816": "Помилка: 21816. Зв'яжіться з нами",
    "error_21817": "Помилка: 21817. Будь ласка, зв'яжіться з нами",
    "error_21818": "Помилка: 21818. Зв'яжіться з нами",
    "error_21819": "Помилка запиту (код помилки: 21819). Будь ласка, спробуйте ще раз",
    "error_21820": "Помилка: 21820. Зв'яжіться з нами",
    "error_21821": "Помилка: 21821. Зв'яжіться з нами",
    "error_21822": "Помилка: 21822. Будь ласка, зв'яжіться з нами",
    "error_21823": "Помилка запиту (код помилки: 21823). Будь ласка, спробуйте ще раз",
    "error_21824": "Помилка запиту (код помилки: 21824). Будь ласка, спробуйте ще раз",
    "error_21825": "Помилка запиту (код помилки: 21825). Будь ласка, спробуйте ще раз",
    "error_21826": "Помилка запиту (код помилки: 21826). Будь ласка, спробуйте ще раз",
    "error_21901": "Помилка: 21901. Зв'яжіться з нами",
    "error_21902": "Помилка запиту (код помилки: 21902). Будь ласка, спробуйте ще раз",
    "error_21903": "Статус облікового запису змінився (код помилки: 21903), будь ласка, оновіть сторінку та повторіть спробу",
    "error_21904": "Помилка: 21904. Повторіть спробу",
    "error_21905": "Помилка: 21905. Повторіть спробу",
    "error_21906": "Помилка запиту (код помилки: 21906). Будь ласка, спробуйте ще раз",
    "error_21907": "Обліковий запис Google було пов’язано з іншим обліковим записом",
    "error_21908": "Помилка запиту (код помилки: 21908). Будь ласка, спробуйте ще раз",
    "error_22001": "Помилка запиту (код помилки: 22001). Будь ласка, спробуйте ще раз",
    "error_22002": "Не вдалося від’єднати без додаткового входу",
    "error_22003": "Помилка запиту (код помилки: 22003). Будь ласка, спробуйте ще раз",
    "error_22101": "Помилка: 22101. Будь ласка, зв'яжіться з нами",
    "error_22102": "Статус облікового запису змінився (код помилки: 22102), будь ласка, оновіть сторінку та повторіть спробу",
    "error_22103": "Помилка запиту (код помилки: 22103). Будь ласка, спробуйте ще раз",
    "error_22104": "Статус облікового запису змінився (код помилки: 22104), оновіть сторінку та повторіть спробу",
    "error_22105": "Помилка: 22105. Повторіть спробу",
    "error_22106": "Помилка: 22106. Повторіть спробу",
    "error_22107": "Помилка: 22107. Зв'яжіться з нами",
    "error_22108": "Помилка запиту (код помилки: 22108). Будь ласка, спробуйте ще раз",
    "error_22201": "Помилка: 22201. Зв'яжіться з нами",
    "error_22202": "Недійсне підтвердження (помилка: 22202). Будь ласка, спробуйте ще раз.",
    "error_22203": "Помилка: 22203. Будь ласка, спробуйте ще раз\"",
    "error_22204": "Помилка запиту (код помилки: 22204). Будь ласка, спробуйте ще раз",
    "error_22206": "Помилка запиту (код помилки: 22206). Будь ласка, спробуйте ще раз",
    "error_22401": "Помилка: 22401. Зв'яжіться з нами",
    "error_22402": "Недійсне підтвердження (помилка: 22402). Будь ласка, спробуйте ще раз.",
    "error_22403": "Помилка: 22403. Повторіть спробу",
    "error_22404": "Помилка запиту (код помилки: 22404). Будь ласка, спробуйте ще раз",
    "error_22405": "Обліковий запис Facebook було пов’язано з іншою електронною поштою",
    "error_22406": "Помилка: 22406. Повторіть спробу",
    "error_22407": "Помилка: 22407. Зв'яжіться з нами",
    "error_22408": "Помилка: 22408. Будь ласка, зв'яжіться з нами",
    "error_22409": "Помилка: 22409. Зв'яжіться з нами",
    "error_22410": "Помилка: 224010. Будь ласка, зв'яжіться з нами",
    "error_22411": "Помилка: 224011. Будь ласка, зв'яжіться з нами",
    "error_22412": "Помилка: 224012. Будь ласка, зв'яжіться з нами",
    "error_22413": "Помилка: 22413. Зв'яжіться з нами",
    "error_22414": "Помилка запиту (код помилки: 22414). Будь ласка, спробуйте ще раз",
    "error_22415": "Помилка: 22415. Будь ласка, зв'яжіться з нами",
    "error_22416": "Помилка: 22416. Зв'яжіться з нами",
    "error_22417": "Помилка: 22417. Зв'яжіться з нами",
    "error_22418": "Помилка запиту (код помилки: 22418). Будь ласка, спробуйте ще раз",
    "error_22419": "Помилка: 22419. Зв'яжіться з нами",
    "error_22420": "Помилка: 22420. Будь ласка, зв'яжіться з нами",
    "error_22421": "Помилка: 22421. Зв'яжіться з нами",
    "error_22422": "Помилка запиту (код помилки: 22422). Будь ласка, спробуйте ще раз",
    "error_22423": "Помилка запиту (код помилки: 22423). Будь ласка, спробуйте ще раз",
    "error_22424": "Помилка запиту (код помилки: 22424). Будь ласка, спробуйте ще раз",
    "error_22425": "Помилка запиту (код помилки: 22425). Будь ласка, спробуйте ще раз",
    "error_20098": "Код помилки: 20098. Якщо ви переглядаєте веб-сторінку в приватному режимі, перейдіть у звичайний режим і повторіть спробу.",
    "error_22298": "Помилка запиту для Google (помилка: 22298). Будь ласка, спробуйте ще раз.",
    "error_22498": "Помилка запиту для Facebook (помилка: 22498). Будь ласка, спробуйте ще раз.",
    "error_24902": "Помилка запиту (код помилки: 24902). Будь ласка, спробуйте ще раз",
    "error_24903": "Помилка запиту (код помилки: 24903). Будь ласка, спробуйте ще раз",
    "error_24904": "Помилка запиту (код помилки: 24904). Будь ласка, спробуйте ще раз",
    "error_24905": "Помилка запиту (код помилки: 24905). Будь ласка, спробуйте ще раз",
    "login_title": "Увійдіть в MindOnMap",
    "log_in": "Увійти",
    "no_account": "Немає облікового запису?",
    "create_it": "Створіть його",
    "or_log_in_with": "Або увійдіть за допомогою",
    "passwordless_login": "Вхід без пароля",
    "log_in_done": "Вхід виконано",
    "three_rd_account_connect_info": "Щиро вітаю! Ви успішно ввійшли. Тепер ви можете підключити один обліковий запис електронної пошти, який використовуватиметься для входу в майбутньому.",
    "see_my_account": "Перегляньте мій обліковий запис",
    "three_rd_login_merge_account": "Адресу електронної пошти стороннього облікового запису було зареєстровано. Ви бажаєте підключитися та увійти безпосередньо за допомогою цієї електронної адреси?",
    "connect_log_in": "Підключитися та увійти",
    "create_an_account": "Створити аккаунт",
    "back_to_log_in": "Назад до входу",
    "create_password": "Створити пароль",
    "create_now": "Створіть зараз",
    "password_login_subtitle": "Вхід без пароля за допомогою електронної пошти",
    "account_login": "Вхід до облікового запису",
    "rights": "Створюючи цей обліковий запис, ви приймаєте <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Загальні положення та умови</a> та <a href=\"https://www.mindonmap.com/privacy-policy/\">Політика конфіденційності</a>",
    "passwordless_login_done": "Вхід без пароля виконано",
    "passwordless_login_info": "Вітаємо, ви успішно завершили вхід без пароля. Ви можете створити пароль для цього облікового запису та входити з цим обліковим записом і паролем у майбутньому. <a href=\"/create-password\" style=\"display: initial;\">Створити зараз</a>",
    "sign_up": "Зареєструватися",
    "register_info": "створити свій обліковий запис",
    "reset_now": "Скинути зараз",
    "forgot_password": "Забули пароль",
    "reset_password_subtitle": "Скористайтеся електронною поштою свого облікового запису, щоб скинути пароль",
    "plan_products": "Плани та продукти",
    "nick_name": "Ім'я користувача:",
    "email": "Електронна пошта:",
    "my_products": "Мої продукти",
    "my_orders": "Мої замовлення",
    "unlink": "Від’єднати",
    "link": "Посилання",
    "connected_accounts": "Підключені облікові записи",
    "last_name": "Прізвище:",
    "first_name": "Ім'я:",
    "Gender": "Стать:",
    "Birth": "народження:",
    "Month": "місяць",
    "Year": "рік",
    "Country_Region": "Країна/регіон:",
    "Address": "Адреса:",
    "Save": "зберегти",
    "Date": "Дата",
    "Male": "Чоловік",
    "Female": "Жінка",
    "Unspecified": "Невизначений",
    "Security": "Безпека",
    "change_password": "Змінити пароль",
    "change_now": "Змінити зараз",
    "connect_email": "Підключити електронну пошту",
    "delete_account": "Видалити аккаунт",
    "delete_account_info": "Коли ваш обліковий запис буде видалено, усі дані в розділі «Мій обліковий запис», пов’язані з вашим обліковим записом, буде видалено назавжди, і ви, можливо, не зможете їх відновити. Радимо діяти обережно.",
    "Delete": "Видалити",
    "Logout": "Вийти",
    "my_profile": "Мій профіль",
    "guides_faqs": "Посібники та поширені запитання",
    "More": "більше",
    "guides": "Посібники",
    "register": "зареєструватися",
    "hot_faq": "Гарячі FAQ",
    "Contents": "Зміст:",
    "contact_us": "Зв'яжіться з нами>>",
    "plan": "План",
    "unregistered": "Незареєстрований",
    "buy_more": "Придбати більше",
    "buy_again": "Купити знову",
    "buy_now": "Купити зараз",
    "free_no_limit": "Безкоштовно та без обмежень",
    "expired": "Термін дії минув",
    "lifetime": "Час життя",
    "remain": "залишаються",
    "day_s": "День (дні)",
    "error_24801": "Помилка запиту (код помилки: 24801). Будь ласка, спробуйте ще раз",
    "no_app_found": "Програму не знайдено！<a href=\"/\">Оновити</a> або <a href=\"https://www.mindonmap.com/\">перейти на офіційний веб-сайт</a>",
    "get_more": "Отримати більше >>",
    "edit_photo": "Редагувати фото",
    "select_photo": "Виберіть фото",
    "change_photo": "Змінити фотографію",
    "cancel": "Скасувати",
    "hide_password": "Приховати пароль",
    "show_password": "Показати пароль",
    "zoom_in": "Збільшувати",
    "zoom_out": "Зменшення",
    "rotate": "Обертати",
    "horizontal_flip": "Горизонтальний фліп",
    "vertical_flip": "Вертикальний фліп",
    "country": "Країна",
    "country_1": "Виберіть свою країну/регіон",
    "country_2": "Острів Аланд",
    "country_3": "Афганістан",
    "country_4": "Албанія",
    "country_5": "Алжир",
    "country_6": "Американське Самоа",
    "country_7": "Андорра",
    "country_8": "Ангола",
    "country_9": "Ангілья",
    "country_10": "Антарктида",
    "country_11": "Антигуа і Барбуда",
    "country_12": "Аргентина",
    "country_13": "Вірменія",
    "country_14": "Аруба",
    "country_15": "Австралія",
    "country_16": "Австрія",
    "country_17": "Азербайджан",
    "country_18": "Бахрейн",
    "country_19": "Бангладеш",
    "country_20": "Барбадос",
    "country_21": "Білорусь",
    "country_22": "Бельгія",
    "country_23": "Беліз",
    "country_24": "Бенін",
    "country_25": "Бермудські острови",
    "country_26": "Бутан",
    "country_27": "Болівія",
    "country_28": "Боснія і Герцеговина",
    "country_29": "Ботсвана",
    "country_30": "Острів Буве",
    "country_31": "Бразилія",
    "country_32": "Британська територія в Індійському океані",
    "country_33": "Британські Віргінські Острови",
    "country_34": "Бруней",
    "country_35": "Болгарія",
    "country_36": "Буркіна",
    "country_37": "Бурунді",
    "country_38": "Камбоджа",
    "country_39": "Камерун",
    "country_40": "Канада",
    "country_41": "Кабо Верде",
    "country_42": "Карибські Нідерланди",
    "country_43": "Кайманові острови",
    "country_44": "Центральноафриканська Республіка",
    "country_45": "Чад",
    "country_46": "Чилі",
    "country_47": "Китай",
    "country_48": "Острів Різдва",
    "country_49": "Кокосові (Кілінг) острови",
    "country_50": "Колумбія",
    "country_51": "Острови Кука",
    "country_52": "Коста-Ріка",
    "country_53": "Кот-д'Івуар",
    "country_54": "Хорватія",
    "country_55": "Куба",
    "country_56": "Кіпр",
    "country_57": "Чеська Республіка",
    "country_58": "Демократична Республіка Конго",
    "country_59": "Данія",
    "country_60": "Джібуті",
    "country_61": "Домініка",
    "country_62": "Домініканська республіка",
    "country_63": "Еквадор",
    "country_64": "Єгипет",
    "country_65": "Сальвадор",
    "country_66": "Екваторіальна Гвінея",
    "country_67": "Еритрея",
    "country_68": "Естонія",
    "country_69": "Ефіопія",
    "country_70": "Фолклендські острови",
    "country_71": "Фарерські острови",
    "country_72": "Федеративні Штати Мікронезії",
    "country_73": "Фіджі",
    "country_74": "Фінляндія",
    "country_75": "Франція",
    "country_76": "Французька Гвіана",
    "country_77": "Французька Полінезія",
    "country_78": "Французькі південні території",
    "country_79": "Габон",
    "country_80": "Гамбія",
    "country_81": "Грузія",
    "country_82": "Німеччина",
    "country_83": "Гана",
    "country_84": "Гібралтар",
    "country_85": "Велика Британія (Сполучене Королівство; Англія)",
    "country_86": "Греція",
    "country_87": "Гренландія",
    "country_88": "Гренада",
    "country_89": "Гваделупа",
    "country_90": "Гуам",
    "country_91": "Гватемала",
    "country_92": "Гернсі",
    "country_93": "Гвінея",
    "country_94": "Гвінея-Бісау",
    "country_95": "Гайана",
    "country_96": "Гаїті",
    "country_97": "Острів Херд і Макдональд",
    "country_98": "Гондурас",
    "country_99": "Гонконг",
    "country_100": "Угорщина",
    "country_101": "Ісландія",
    "country_102": "Індія",
    "country_103": "Індонезія",
    "country_104": "Іран",
    "country_105": "Ірак",
    "country_106": "Ірландія",
    "country_107": "Острів Мен",
    "country_108": "Ізраїль",
    "country_109": "Італія",
    "country_110": "Ямайка",
    "country_111": "Японія",
    "country_112": "Джерсі",
    "country_113": "Йорданія",
    "country_114": "Казахстан",
    "country_115": "Кенія",
    "country_116": "Кірібаті",
    "country_117": "Кувейт",
    "country_118": "Киргизстан",
    "country_119": "Лаос",
    "country_120": "Латвія",
    "country_121": "Ліван",
    "country_122": "Лесото",
    "country_123": "Ліберія",
    "country_124": "Лівія",
    "country_125": "Ліхтенштейн",
    "country_126": "Литва",
    "country_127": "Люксембург",
    "country_128": "Макао",
    "country_129": "Мадагаскар",
    "country_130": "Малаві",
    "country_131": "Малайзія",
    "country_132": "Мальдіви",
    "country_133": "Малі",
    "country_134": "Мальта",
    "country_135": "Маршаллові острови",
    "country_136": "Мартініка",
    "country_137": "Мавританія",
    "country_138": "Маврикій",
    "country_139": "Майотта",
    "country_140": "Мексика",
    "country_141": "Молдова",
    "country_142": "Монако",
    "country_143": "Монголія",
    "country_144": "Чорногорія",
    "country_145": "Монсеррат",
    "country_146": "Марокко",
    "country_147": "Мозамбік",
    "country_148": "М'янма (Бірма)",
    "country_149": "Намібія",
    "country_150": "Науру",
    "country_151": "Непал",
    "country_152": "Нідерланди",
    "country_153": "Нова Каледонія",
    "country_154": "Нова Зеландія",
    "country_155": "Нікарагуа",
    "country_156": "Нігер",
    "country_157": "Нігерія",
    "country_158": "Ніуе",
    "country_159": "Острів Норфолк",
    "country_160": "Північна Корея",
    "country_161": "Північні Маріанські Острови",
    "country_162": "Норвегія",
    "country_163": "Оман",
    "country_164": "Пакистан",
    "country_165": "Палау",
    "country_166": "Палестинські території",
    "country_167": "Панама",
    "country_168": "Папуа-Нова Гвінея",
    "country_169": "Парагвай",
    "country_170": "Перу",
    "country_171": "Острови Піткерн",
    "country_172": "Польща",
    "country_173": "Португалія",
    "country_174": "Пуерто Ріко",
    "country_175": "Катар",
    "country_176": "Республіка Македонія (КЮРМ)",
    "country_177": "Республіка Конго",
    "country_178": "Реюньйон",
    "country_179": "Румунія",
    "country_180": "Російська Федерація",
    "country_181": "Руанда",
    "country_182": "Сен-Бартельмі",
    "country_183": "Сен-Мартен (Франція)",
    "country_184": "Сен-П'єр і Мікелон",
    "country_185": "Самоа",
    "country_186": "Сан Марино",
    "country_187": "Сан-Томе і Принсіпі",
    "country_188": "Саудівська Аравія",
    "country_189": "Сенегал",
    "country_190": "Сербія",
    "country_191": "Сейшельські острови",
    "country_192": "Сьєрра-Леоне",
    "country_193": "Сінгапур",
    "country_194": "Словаччина",
    "country_195": "Словенія",
    "country_196": "Соломонові Острови",
    "country_197": "Сомалі",
    "country_198": "Південна Африка",
    "country_199": "Південна Джорджія і Південний Сандвічевий острів",
    "country_200": "Південна Корея",
    "country_201": "Південний Судан",
    "country_202": "Іспанія",
    "country_203": "Шрі Ланка",
    "country_204": "Св. Єлени та залежні території",
    "country_205": "Сент-Кітс і Невіс",
    "country_206": "Сент-Люсія",
    "country_207": "Сент-Вінсент і Гренадини",
    "country_208": "Судан",
    "country_209": "Сурінам",
    "country_210": "Свазіленд",
    "country_211": "Швеція",
    "country_212": "Швейцарія",
    "country_213": "Сирія",
    "country_214": "Тайвань",
    "country_215": "Таджикистан",
    "country_216": "Танзанія",
    "country_217": "Шаблон:Дані країни SJM Svalbard",
    "country_218": "Таїланд",
    "country_219": "Багамські острови",
    "country_220": "Коморські острови",
    "country_221": "Філіппіни",
    "country_222": "Тимор-Лешті (Східний Тимор)",
    "country_223": "Йти",
    "country_224": "Токелау",
    "country_225": "Тонга",
    "country_226": "Тринідад і Тобаго",
    "country_227": "Туніс",
    "country_228": "Туреччина",
    "country_229": "Туркменістан",
    "country_230": "Острови Теркс і Кайкос",
    "country_231": "Тувалу",
    "country_232": "Уганда",
    "country_233": "Україна",
    "country_234": "Об'єднані Арабські Емірати",
    "country_235": "Віддалені Малі Острови Сполучених Штатів",
    "country_236": "Сполучені Штати Америки (США)",
    "country_237": "Віргінські Острови Сполучених Штатів",
    "country_238": "Уругвай",
    "country_239": "Узбекистан",
    "country_240": "Вануату",
    "country_241": "Ватикан (Святий Престол)",
    "country_242": "Венесуела",
    "country_243": "В'єтнам",
    "country_244": "Уолліс і Футуна",
    "country_245": "Західна Сахара",
    "country_246": "Ємен",
    "country_247": "Замбія",
    "country_248": "Зімбабве",
    "google_login": "Увійдіть за допомогою Google",
    "State": "Держава",
    "Activation_code": "Код активації",
    "Question": "Перелічіть усі програми, у які ви ввійшли",
    "Copy_complete": "Копіювання завершено",
    "footer": "Авторське право © 2024 MindOnMap Studio. Всі права захищені.",
    "change_password_success": "Пароль успішно змінено",
    "successful_login_title": "Успішний вхід",
    "product_page": "Сторінка продукту>>",
    "successful_login_info": "Вхід завершено. Закрийте поточну сторінку та поверніться до вихідної вкладки, щоб продовжити наступний процес. Поточна сторінка буде закрита автоматично через 5 секунд. Якщо автоматичне закриття або закриття за допомогою кнопки «Готово» не вдається, закрийте цю вкладку безпосередньо.",
    "successful_login_info_firefox": "Вхід завершено. Закрийте поточну сторінку та поверніться до вихідної вкладки, щоб продовжити наступний процес.",
    "my_account": "Мій рахунок",
    "my_history": "Моя історія",
    "remove_watermark": "Видалити водяний знак",
    "no_history": "Немає історії",
    "history_all": "Вибрати все",
    "history_open": "ВІДЧИНЕНО",
    "history_down": "Завантажити",
    "history_delete": "Видалити",
    "history_clear": "Очистити недійсне",
    "images": "зображення(а)",
    "use_this_function": "Використовуйте цей продукт>>",
    "hd_downloading": "Переваги завантаження оригінальних HD-зображень:",
    "lifetimeRemaining": "До кінця життя",
    "Remaining": "Залишилося",
    "email_verification": "Підтвердження електронної пошти",
    "email_verification_info": "Ми надіслали повідомлення для підтвердження на вашу електронну адресу <span class=\"email\"></span>, завершіть перевірку. Після перевірки переваги будуть автоматично синхронізовані.",
    "wrong_email": "Неправильна електронна адреса?",
    "click_here_to_modify": "Натисніть тут, щоб змінити",
    "get_more_help": "Отримати додаткову допомогу?",
    "click_here": "Натисніть тут",
    "email_verification_info_success": "Вітаємо з підтвердженням облікового запису електронної пошти.",
    "email_verification_info_error": "Не вдалося перевірити через те, що термін дії посилання закінчився.",
    "registration_succeeded": "Реєстрація пройшла успішно",
    "registration_succeeded_info_1": "Щиро вітаю! Ви успішно зареєструвалися. Ми надіслали повідомлення для підтвердження на вашу електронну адресу <span class=\"email\"></span>. Будь ласка, завершіть перевірку, щоб скористатися перевагами цієї електронної пошти.",
    "registration_succeeded_info_2": "Натисніть «Готово», щоб повернутися на домашню сторінку та використовувати цей продукт.",
    "registration_succeeded_info_3": "Натисніть «Готово», щоб закрити поточну сторінку та повернутися на домашню сторінку для наступних операцій. Якщо закрити не вдається, закрийте вкладку вручну.",
    "verify_email": "Підтвердити електронну адресу",
    "registered_email_not_verified": "Зареєстровану адресу електронної пошти не підтверджено, перевірте її негайно.",
    "email_verification_time_1": "Не отримали листа з підтвердженням?",
    "email_verification_time_2": "Після",
    "email_verification_time_3": "Натисніть тут, щоб надіслати його повторно",
    "error_26301": "Код помилки: 26301, зв'яжіться з нами",
    "error_26302": "Код помилки: 26302, зв'яжіться з нами",
    "error_26303": "Помилка формату електронної пошти (код помилки: 26303). Введіть його ще раз",
    "error_26304": "Рекомендується пароль довжиною понад 8 символів (код помилки: 26304)",
    "error_26305": "Помилка запиту (код помилки: 26305). Будь ласка, повторіть спробу",
    "error_26306": "Електронну адресу зареєстровано, <a href=\"https://account.mindonmap.com/login/\">перейдіть до входу</a>",
    "error_26307": "Помилка запиту (код помилки: 26307). Будь ласка, повторіть спробу",
    "error_26308": "Помилка запиту (код помилки: 26308). Будь ласка, повторіть спробу",
    "error_26401": "Код помилки: 26401, повторіть спробу",
    "error_26402": "Електронну адресу перевірено (код помилки: 26402), повторіть спробу",
    "error_26403": "Помилка запиту (код помилки: 26403). Будь ласка, повторіть спробу",
    "error_26404": "Помилка запиту (код помилки: 26404). Будь ласка, повторіть спробу",
    "error_26501": "Код помилки: 26501, зв'яжіться з нами",
    "error_26502": "Код помилки: 26502, зв'яжіться з нами",
    "error_26503": "Помилка формату електронної пошти (код помилки: 26503). Введіть його ще раз",
    "error_26504": "Помилка запиту (код помилки: 26504). Будь ласка, повторіть спробу",
    "error_26505": "Електронна адреса не зареєстрована, <a href=\"https://account.mindonmap.com/register/\">спочатку зареєструйте її</a>",
    "error_26506": "Електронну адресу перевірено.",
    "error_26507": "Помилка запиту (код помилки: 26507). Будь ласка, повторіть спробу",
    "error_26508": "Підтвердження виконано (код помилки: 26508), повторіть спробу",
    "error_26509": "Помилка запиту (код помилки: 26509), повторіть спробу",
    "error_26510": "Код помилки: 26510, зв'яжіться з нами",
    "error_26601": "Код помилки: 26601, зв'яжіться з нами",
    "error_26602": "Код помилки: 26602, зв'яжіться з нами",
    "error_26603": "Reuqest не вдалося (код помилки: 26603). Будь ласка, повторіть спробу",
    "error_26604": "Код помилки: 26604, зв'яжіться з нами",
    "error_26605": "Код помилки: 26605, зв'яжіться з нами",
    "error_26701": "Код помилки: 26701, зв'яжіться з нами",
    "error_26702": "Reuqest не вдалося (код помилки: 26702). Будь ласка, повторіть спробу",
    "error_26703": "Код помилки: 26703, зв'яжіться з нами",
    "error_26704": "Код помилки: 26704, зв'яжіться з нами",
    "error_26705": "Дочекайтеся входу (код помилки: 26705). Будь ласка, повторіть спробу",
    "no_cookie": "Ви ввімкнули функцію «Блокувати всі файли cookie» у своєму браузері, тому ви не можете ввійти. Будь ласка, перейдіть до «Налаштувань», щоб установити прапорець «Дозволити всі файли cookie».",
    "error_26801": "Код помилки: 26801, зв'яжіться з нами",
    "error_26802": "Код помилки: 26802, зв'яжіться з нами",
    "error_26803": "Помилка запиту (код помилки: 26803). Будь ласка, повторіть спробу",
    "error_26804": "Помилка запиту (код помилки: 26804). Будь ласка, повторіть спробу",
    "error_order": "Помилка запиту (код помилки: 27098), спробуйте ще раз!",
    "error_order1": "Запит на замовлення неповний (код помилки: ",
    "error_order2": "）, будь ласка, оновіть і спробуйте ще раз.",
    "modify_email_title": "Змінити електронну адресу",
    "modify_email_info": "Ви можете використовувати змінену електронну адресу для входу в обліковий запис.",
    "images_per": "Зображення пер",
    "error_26101": "Помилка: 26101. Зв'яжіться з нами",
    "error_26102": "Помилка: 26102. Зв'яжіться з нами",
    "error_26103": "Помилка запиту (код помилки: 26103). Будь ласка, спробуйте ще раз",
    "error_26104": "Код помилки: 26104, спробуйте ще раз",
    "error_26105": "Код помилки: 26105, спробуйте ще раз",
    "error_26106": "Не вдалося видалити (код помилки: 26106). Будь ласка, спробуйте ще раз",
    "error_26201": "Помилка: 26201. Зв'яжіться з нами",
    "error_26202": "Помилка запиту (код помилки: 26202). Будь ласка, спробуйте ще раз",
    "error_26001": "Помилка: 26001. Зв'яжіться з нами",
    "error_26002": "Помилка: 26002. Зв'яжіться з нами",
    "error_26003": "Помилка: 26003. Будь ласка, зв'яжіться з нами",
    "error_26004": "Помилка: 26004. Зв'яжіться з нами",
    "error_26005": "Помилка запиту (код помилки: 26005). Будь ласка, спробуйте ще раз",
    "error_26006": "Код помилки: 26006, спробуйте ще раз",
    "error_26008": "Помилка: 26008. Зв'яжіться з нами",
    "go_to_the_home_page": "Перейдіть на домашню сторінку",
    "error_27101": "Помилка запиту (код помилки: 27101). Повторіть спробу",
    "error_27201": "Код помилки: 27201, зв'яжіться з нами",
    "error_27202": "Код помилки: 27202, повторіть спробу",
    "error_27203": "Помилка запиту (код помилки: 27203). Будь ласка, повторіть спробу",
    "error_27204": "Недійсний код (код помилки: 27204).",
    "error_27205": "Помилка запиту (код помилки: 27205). Будь ласка, повторіть спробу",
    "error_27206": "Помилка запиту (код помилки: 27206). Будь ласка, повторіть спробу",
    "error_27207": "Помилка запиту (код помилки: 27207). Будь ласка, повторіть спробу",
    "no_history_found": "Ви не використовували жодного інструменту! <a href=\"/\">Оновити</a> або <a href=\"https://www.mindonmap.com/\">перейти на офіційний сайт</a>",
    "error_25301": "Помилка: 25301. Будь ласка, зв'яжіться з нами",
    "error_25302": "Помилка: 25302. Зв'яжіться з нами",
    "error_25303": "Помилка запиту (код помилки: 25303). Будь ласка, спробуйте ще раз",
    "error_25304": "Помилка запиту (код помилки: 25304). Будь ласка, спробуйте ще раз",
    "error_25305": "Помилка запиту (код помилки: 25305). Будь ласка, спробуйте ще раз",
    "error_25306": "Помилка запиту (код помилки: 25306). Будь ласка, спробуйте ще раз",
    "image_upscaler_p": "Переваги завантаження зображення без водяного знака:",
    "Available_for": "Доступний для:",
    "credit_per": "%s кредит(ів) на зображення HD",
    "still_valid": "Придбаний план(и) все ще дійсний",
    "credit": "кредит(и)",
    "pc_3rd_info": "Вхід успішно. Будь ласка, перейдіть до програми для подальшої роботи.",
    "use_online": "Скористайтеся онлайн-сервісом",
    "use_download": "Використовуйте настільну програму",
    "use_immediately": "Використовуйте негайно",
    "Use_in_browser": "Використання в браузері",
    "win_desktop": "вікна",
    "Mac_desktop": "Мак",
    "credits_per": "{%} кредитів на місяць",
    "expire": "Термін дії:",
    "viewDetails": "Докладніше",
    "viewHistory": "Переглянути переваги історії>>",
    "viewDetailsInfo": "Примітка. Якщо переваги передплати поновлено протягом 7 днів після закінчення терміну дії, невикористані переваги можна продовжувати використовувати. Крім того, час закінчення терміну дії буде автоматично оновлено до часу закінчення терміну дії нової підписки. Якщо протягом 7 днів після завершення терміну дії не буде нової підписки, усі переваги, термін дії яких закінчився, буде видалено.",
    "connect_account": "Прив’яжіть електронну пошту до свого облікового запису",
    "connect_account_info": "Після прив’язки ви можете увійти за допомогою цієї електронної адреси.",
    "connect_now": "Прив’язати зараз",
    "no_email_bind": "Без прив’язки до електронної пошти",
    "bind_email": "Прив’язати електронну пошту",
    "connect_your_email_placeholder": "Будь ласка, введіть адресу електронної пошти",
    "bind_an_email": "Прив’язати електронний лист",
    "bind_info": "Ви успішно ввійшли. Щоб активувати свої переваги, прив’яжіть електронну адресу до свого облікового запису.",
    "bind_later": "Прив’язати пізніше",
    "hi": "Привіт, %s!",
    "Personal_Information": "Персональна інформація",
    "Access": "Доступ",
    "Subscription_Plan": "(План підписки)",
    "No_orders": "Замовлень не знайдено.",
    "No_data": "Немає даних",
    "Featured_Products": "Рекомендовані товари",
    "More_Products": "Більше продуктів",
    "Free_Download": "Безкоштовне завантаження",
    "Get_Started": "Почати",
    "Subscribe": "Підпишіться",
    "Verified": "Перевірено",
    "back_to_account_center": "Назад до центру облікових записів",
    "success": "Успіх!",
    "successfully": "Ви успішно зареєстрували обліковий запис.",
    "Continue": "Продовжити",
    "Already": "Вже є аккаунт?",
    "loading_verification": "Перевірка статусу перевірки...",
    "email_no_verification": "Вибачте, зареєстровану електронну адресу не підтверджено. Будь ласка, завершіть перевірку згідно з наведеними вище інструкціями та знову натисніть кнопку «Готово», щоб завершити реєстрацію.",
    "will_expire_after": "Термін дії закінчиться після",
    "hours": "години",
  },
  "ur": {
    "overtime": "غلطی کا کوڈ: {%}، براہ کرم دوبارہ کوشش کریں۔",
    "isnetwork": "انٹرنیٹ کی خرابی۔ براہ کرم چیک کریں اور دوبارہ کوشش کریں۔",
    "email_placeholder": "ای میل",
    "email_empty": "براہ کرم ای میل درج کریں۔",
    "email_not_valid": "ای میل درست نہیں ہے۔",
    "email_not_valid_1": "براہ کرم اپنا ای میل درج کریں۔",
    "email_not_valid_2": "ای میل درست نہیں ہے، براہ کرم ایک مختلف پتہ استعمال کریں۔",
    "email_not_valid_3": "کوئی ای میل ان پٹ نہیں ہے۔",
    "password_placeholder": "پاس ورڈ",
    "password_empty": "براہ کرم پاس ورڈ درج کریں۔",
    "password_not_valid": "غلط اکاؤنٹ یا پاس ورڈ",
    "password_not_valid_1": "8 حروف سے اوپر کا پاس ورڈ درکار ہے۔",
    "password_not_valid_2": "براہ کرم پاس ورڈ بنائیں",
    "password_placeholder_1": "اپنا پاس ورڈ بنائیں",
    "password_placeholder_2": "اپنے پاس ورڈ کی تصدیق کریں۔",
    "password_placeholder_3": "نیا پاس ورڈ بنائیں",
    "password_placeholder_4": "نئے پاس ورڈ کی توثیق کریں",
    "password_placeholder_5": "پرانا پاس ورڈ درج کریں۔",
    "copy_password_empty": "براہ کرم پاس ورڈ کی تصدیق کریں۔",
    "copy_password_not_valid": "براہ کرم اپنے پاس ورڈ کی تصدیق کریں۔",
    "copy_passwords_inconsistent": "آپ کے پاس ورڈ کی تصدیق مماثل نہیں ہے۔",
    "code_empty": "براہ کرم تصدیقی کوڈ درج کریں۔",
    "code_not_valid": "غلط توثیقی کوڈ",
    "code_placeholder": "تصدیقی کوڈ",
    "not_received_code": "اگر آپ کے میل باکس کو کافی عرصے سے تصدیقی کوڈ نہیں ملا ہے، تو براہ کرم دوبارہ تصدیقی کوڈ حاصل کریں۔",
    "get_first_code": "براہ کرم پہلے تصدیقی کوڈ حاصل کریں۔",
    "last_name_placeholder": "براہ کرم اپنا آخری نام درج کریں۔",
    "first_name_placeholder": "براہ کرم اپنا پہلا نام درج کریں۔",
    "address_placeholder": "براہ کرم اپنا پتہ درج کریں۔",
    "no_code_text": "ہم نے ایک تصدیقی کوڈ بھیجا ہے۔ براہ کرم اپنا کوڈ درج کریں۔ <span class='tips'>کوڈ موصول نہیں ہوا؟",
    "no_code_text_1": "1. براہ کرم یقینی بنائیں کہ ای میل ایڈریس درست ہے اور یہ ای میلز وصول کر سکتا ہے۔",
    "no_code_text_2": "2. ای میل سسٹم کے ذریعے خود بخود بھیجی جاتی ہے، اس کو سپیم یا فضول ای میل کے طور پر جھنڈا لگایا جا سکتا ہے۔ براہ کرم چیک کریں کہ آیا ای میل کوڑے دان کے فولڈر میں ہے۔",
    "no_code_text_3": "3. آپ کا مسئلہ حل نہیں کر سکتے؟ ",
    "no_code_text_3_span": "پھر ہم سے رابطہ کرنے کے لیے یہاں کلک کریں۔",
    "order_no": "آپ نے کوئی پروڈکٹ نہیں خریدی ہے، اگر آپ کا کوئی سوال ہے، تو براہ کرم <a href=\"https://www.mindonmap.com/contact-us/\">ہم سے رابطہ کریں</a>۔",
    "error_24901": "موجودہ اکاؤنٹ میں کوئی ای میل لنک نہیں ہے، اور آرڈرز نہیں مل سکتے ہیں۔ براہ کرم ایک ای میل منسلک کریں۔",
    "user_guide": "صارف گائیڈ >>",
    "download": "ڈاؤن لوڈ کریں",
    "order_number": "آرڈر نمبر:",
    "Refund": "واپس کرنا",
    "Disabled": "معذور",
    "Normal": "نارمل",
    "Modify": "ترمیم کریں۔",
    "Modify_1": "ترمیم کریں >>",
    "Connect": "جڑیں۔",
    "unlink_success": "کامیابی سے لنک ختم کریں۔",
    "connect_success": "کامیابی سے جڑیں۔",
    "feedback_title": "آپ کی راے کا شکریہ۔ براہ کرم اپنا مسئلہ چھوڑ دیں اور ہم آپ کو 24 گھنٹوں کے اندر جواب دیں گے۔",
    "feedback_thank_you": "آپ کا شکریہ!<br />آپ کی رائے کامیابی کے ساتھ جمع کر دی گئی ہے۔",
    "feedback_email": "اپنا ای میل یہاں درج کریں!",
    "feedback_content": "آپ کو درپیش کوئی بھی مسئلہ یا تجویز یہاں چھوڑ دیں۔",
    "feedback_submit": "جمع کرائیں",
    "form_contents": "آپ نے کوئی تفصیل درج نہیں کی ہے۔ براہ کرم اسے درج کریں اور دوبارہ جمع کرائیں۔",
    "old_password": "براہ کرم پرانا پاس ورڈ درج کریں۔",
    "new_password": "براہ کرم ایک نیا پاس ورڈ بنائیں",
    "old_new_password": "نیا پاس ورڈ پرانے جیسا نہیں ہو سکتا",
    "incorrect_password": "غلط پاس ورڈ",
    "delete_no": "ابھی حذف کریں۔",
    "Caps": "کیپس لاک آن ہے",
    "Get": "حاصل کریں۔",
    "Done": "ہو گیا",
    "error_20001": "خرابی: 20001۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20002": "خرابی: 20002۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20003": "خرابی: 20003۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20004": "درخواست ناکام ہوگئی (خرابی کوڈ: 20004)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20005": "لاگ ان سیشن ختم ہو گیا ہے (خرابی: 20005)۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20006": "درخواست ناکام ہوگئی (خرابی کوڈ: 20006)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20007": "لاگ ان سیشن ختم ہو گیا ہے (خرابی: 20007)۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20008": "لاگ ان سیشن ختم ہو گیا ہے (خرابی: 20008)۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20009": "لاگ ان سیشن ختم ہو گیا ہے (خرابی: 20009)۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_20101": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20101)",
    "error_20102": "ای میل درست نہیں ہے (خرابی کوڈ: 20102)",
    "error_20103": "درخواست ناکام ہوگئی (خرابی کوڈ: 20103)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20104": "ای میل پہلے سے استعمال میں ہے، براہ کرم <a href=\"https://account.mindonmap.com/login/\">لاگ ان کریں</a> یا ایک نئے کے ساتھ رجسٹر کریں",
    "error_20105": "درخواست ناکام ہوگئی (خرابی کوڈ: 20105)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20106": "ای میل بھیجنے میں ناکام، براہ کرم دوبارہ کوشش کریں۔",
    "error_20201": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20201)",
    "error_20202": "براہ کرم اپنا پاس ورڈ درج کریں (خرابی کوڈ: 20202)",
    "error_20203": "براہ کرم تصدیقی کوڈ درج کریں (خرابی کوڈ: 20203)",
    "error_20204": "ای میل درست نہیں ہے (خرابی کوڈ: 20204)",
    "error_20205": "8 حروف سے اوپر کا پاس ورڈ درکار ہے (خرابی کوڈ: 20205)",
    "error_20206": "درخواست ناکام ہوگئی (خرابی کوڈ: 20206)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20207": "غلط توثیقی کوڈ",
    "error_20208": "درخواست ناکام ہوگئی (خرابی کوڈ: 20208)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20209": "درخواست ناکام ہوگئی (خرابی کوڈ: 20209)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20301": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20301)",
    "error_20302": "خرابی: 20302۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_20303": "ای میل درست نہیں ہے (خرابی کوڈ: 20303)",
    "error_20304": "درخواست ناکام ہوگئی (خرابی کوڈ: 20304)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20305": "اکاؤنٹ موجود نہیں ہے۔ براہ کرم دوبارہ درج کریں یا پہلے <a href=\"https://account.mindonmap.com/register/\">اسے بنائیں</a>۔",
    "error_20306": "ابھی تک کوئی پاس ورڈ نہیں ہے۔ <a href=\"https://account.mindonmap.com/passwordless-login/\">بغیر پاس ورڈ لاگ ان</a> یا <a href=\"https://account.mindonmap.com/create-password/\"> استعمال کریں پاس ورڈ سیٹ کریں</a> اور لاگ ان کریں۔",
    "error_20308": "درخواست ناکام ہوگئی (خرابی کوڈ: 20308)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20401": "لاگ آؤٹ کرنے میں ناکام (خرابی کوڈ: 20401)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20501": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20501)",
    "error_20502": "ای میل درست نہیں ہے (خرابی کوڈ: 20502)",
    "error_20503": "درخواست ناکام ہوگئی (خرابی کوڈ: 20503)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20504": "ای میل بھیجنے میں ناکام۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20601": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20601)",
    "error_20602": "براہ کرم تصدیقی کوڈ درج کریں (خرابی کوڈ: 20602)",
    "error_20603": "ای میل درست نہیں ہے (خرابی کوڈ: 20603)",
    "error_20604": "درخواست ناکام ہوگئی (خرابی کوڈ: 20604)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20606": "درخواست ناکام ہوگئی (خرابی کوڈ: 20606)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20607": "درخواست ناکام ہوگئی (خرابی کوڈ: 20607)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20608": "درخواست ناکام ہوگئی (خرابی کوڈ: 20608)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20701": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20701)",
    "error_20702": "ای میل درست نہیں ہے (خرابی کوڈ: 20702)",
    "error_20703": "درخواست ناکام ہوگئی (خرابی کوڈ: 20703)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20704": "اکاؤنٹ موجود نہیں ہے۔ براہ کرم دوبارہ درج کریں یا پہلے <a href=\"https://account.mindonmap.com/register/\">اسے بنائیں</a>۔",
    "error_20705": "درخواست ناکام ہوگئی (خرابی کوڈ: 20705)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20706": "ای میل بھیجنے میں ناکام۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20801": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 20801)",
    "error_20802": "خرابی: 20802۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_20803": "براہ کرم تصدیقی کوڈ درج کریں (خرابی کوڈ: 20803)",
    "error_20804": "ای میل درست نہیں ہے (خرابی کوڈ: 20804)",
    "error_20805": "8 حروف سے اوپر کا پاس ورڈ درکار ہے (خرابی کوڈ: 20805)",
    "error_20806": "درخواست ناکام ہوگئی (خرابی کوڈ: 20806)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20808": "درخواست ناکام ہوگئی (خرابی کوڈ: 20808)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20901": "درخواست ناکام ہوگئی (خرابی کوڈ: 20901)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20902": "درخواست ناکام ہوگئی (خرابی کوڈ: 20902)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21000": "تبدیلیاں محفوظ ہیں۔",
    "error_21001": "کوئی معلومات جمع نہیں کی گئی (خرابی کوڈ: 21001)",
    "error_21002": "درخواست ناکام ہوگئی (خرابی کوڈ: 21002)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21101": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 21101)",
    "error_21102": "ای میل درست نہیں ہے (خرابی کوڈ: 21102)",
    "error_21103": "درخواست ناکام ہوگئی (خرابی کوڈ: 21103)، براہ کرم دوبارہ کوشش کریں۔",
    "error_21104": "ای میل پہلے سے منسلک ہے، براہ کرم ایک نیا استعمال کریں۔",
    "error_21105": "درخواست ناکام ہوگئی (خرابی کوڈ: 21105)، براہ کرم دوبارہ کوشش کریں۔",
    "error_21106": "ای میل بھیجنے میں ناکام۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21201": "براہ کرم اپنا ای میل درج کریں (خرابی کوڈ: 21201)",
    "error_21202": "براہ کرم تصدیقی کوڈ درج کریں (خرابی کوڈ: 21202)",
    "error_21203": "ای میل درست نہیں ہے (خرابی کوڈ: 21203)",
    "error_21204": "خرابی: 21204۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21205": "خرابی: 21205۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21206": "8 حروف سے اوپر کا پاس ورڈ درکار ہے (خرابی کوڈ: 21206)",
    "error_21207": "درخواست ناکام ہوگئی (خرابی کوڈ: 21207)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21209": "درخواست ناکام ہوگئی (خرابی کوڈ: 21209)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21301": "براہ کرم پرانا پاس ورڈ درج کریں (خرابی کوڈ: 21301)",
    "error_21302": "براہ کرم ایک نیا پاس ورڈ بنائیں (خرابی کوڈ: 21302)",
    "error_21303": "نیا پاس ورڈ پرانے جیسا نہیں ہو سکتا۔ (خرابی: 21303)",
    "error_21304": "8 حروف سے اوپر کا پاس ورڈ درکار ہے (خرابی کوڈ: 21304)",
    "error_21306": "درخواست ناکام ہوگئی (خرابی کوڈ: 21306)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21402": "درخواست ناکام ہوگئی (خرابی کوڈ: 21402)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21403": "توثیقی کوڈ بھیجنے میں ناکام۔ براہ کرم اسے دوبارہ بھیجیں۔",
    "error_21500": "اکاؤنٹ حذف کر دیا گیا ہے۔",
    "error_21501": "براہ کرم تصدیقی کوڈ درج کریں (خرابی کوڈ: 21501)",
    "error_21502": "لاگ ان سیشن ختم ہو گیا ہے (خرابی: 21502)۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "error_21503": "درخواست ناکام ہوگئی (خرابی کوڈ: 21503)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21505": "درخواست ناکام ہوگئی (خرابی کوڈ: 21505)، براہ کرم دوبارہ کوشش کریں۔",
    "error_21601": "خرابی: 20601۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21602": "غلط تصدیق (خرابی: 20602)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21603": "خرابی: 20603۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21604": "درخواست ناکام ہوگئی (خرابی کوڈ: 21604)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21606": "درخواست ناکام ہوگئی (خرابی کوڈ: 21606)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21611": "درخواست ناکام ہوگئی (خرابی کوڈ: 21611)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21801": "خرابی: 21801۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21802": "درخواست ناکام ہوگئی (خرابی: 21802)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21803": "خرابی: 21803۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21804": "درخواست ناکام ہوگئی (خرابی کوڈ: 21804)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21806": "خرابی: 21806۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21807": "خرابی: 21807۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21808": "خرابی: 21808۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21809": "خرابی: 21809۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21810": "خرابی: 21810۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21811": "خرابی: 21811۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21812": "خرابی: 21812۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21813": "درخواست ناکام ہوگئی (خرابی کوڈ: 21813)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21814": "خرابی: 21814۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21815": "درخواست ناکام ہوگئی (خرابی کوڈ: 21815)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21816": "خرابی: 21816۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21817": "خرابی: 21817۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21818": "خرابی: 21818۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21819": "درخواست ناکام ہوگئی (خرابی کوڈ: 21819)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21820": "خرابی: 21820۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21821": "خرابی: 21821۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21822": "خرابی: 21822۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21823": "درخواست ناکام ہوگئی (خرابی کوڈ: 21823)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21824": "درخواست ناکام ہوگئی (خرابی کوڈ: 21824)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21825": "درخواست ناکام ہوگئی (خرابی کوڈ: 21825)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21826": "درخواست ناکام ہوگئی (خرابی کوڈ: 21826)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21901": "خرابی: 21901۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_21902": "درخواست ناکام ہوگئی (خرابی کوڈ: 21902)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21903": "اکاؤنٹ کی حیثیت بدل گئی ہے (خرابی کوڈ: 21903)، براہ کرم صفحہ کو ریفریش کریں اور دوبارہ کوشش کریں۔",
    "error_21904": "خرابی: 21904۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21905": "خرابی: 21905۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21906": "درخواست ناکام ہوگئی (خرابی کوڈ: 21906)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_21907": "گوگل اکاؤنٹ کو دوسرے اکاؤنٹ سے منسلک کر دیا گیا ہے۔",
    "error_21908": "درخواست ناکام ہوگئی (خرابی کوڈ: 21908)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22001": "درخواست ناکام ہوگئی (خرابی کوڈ: 22001)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22002": "بغیر کسی اضافی لاگ ان کے لنک ختم کرنا ناکام ہوگیا۔",
    "error_22003": "درخواست ناکام ہوگئی (خرابی کوڈ: 22003)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22101": "خرابی: 22101۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22102": "اکاؤنٹ کی حیثیت بدل گئی ہے (خرابی کوڈ: 22102)، براہ کرم صفحہ کو ریفریش کریں اور دوبارہ کوشش کریں۔",
    "error_22103": "درخواست ناکام ہوگئی (خرابی کوڈ: 22103)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22104": "اکاؤنٹ کی حیثیت بدل گئی ہے (خرابی کوڈ: 22104)، براہ کرم صفحہ کو ریفریش کریں اور دوبارہ کوشش کریں۔",
    "error_22105": "خرابی: 22105۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22106": "خرابی: 22106۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22107": "خرابی: 22107۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22108": "درخواست ناکام ہوگئی (خرابی کوڈ: 22108)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22201": "خرابی: 22201۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22202": "غلط تصدیق (خرابی: 22202)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22203": "خرابی: 22203۔ براہ کرم دوبارہ کوشش کریں\"",
    "error_22204": "درخواست ناکام ہوگئی (خرابی کوڈ: 22204)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22206": "درخواست ناکام ہوگئی (خرابی کوڈ: 22206)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22401": "خرابی: 22401۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22402": "غلط تصدیق (خرابی: 22402)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22403": "خرابی: 22403۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22404": "درخواست ناکام ہوگئی (خرابی کوڈ: 22404)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22405": "فیس بک اکاؤنٹ کو دوسرے ای میل سے منسلک کر دیا گیا ہے۔",
    "error_22406": "خرابی: 22406۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22407": "خرابی: 22407۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22408": "خرابی: 22408۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22409": "خرابی: 22409۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22410": "خرابی: 224010۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22411": "خرابی: 224011۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22412": "خرابی: 224012۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22413": "خرابی: 22413۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22414": "درخواست ناکام ہوگئی (خرابی کوڈ: 22414)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22415": "خرابی: 22415۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22416": "خرابی: 22416۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22417": "خرابی: 22417۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22418": "درخواست ناکام ہوگئی (خرابی کوڈ: 22418)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22419": "خرابی: 22419۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22420": "خرابی: 22420۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22421": "خرابی: 22421۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_22422": "درخواست ناکام ہوگئی (خرابی کوڈ: 22422)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22423": "درخواست ناکام ہوگئی (خرابی کوڈ: 22423)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22424": "درخواست ناکام ہوگئی (خرابی کوڈ: 22424)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22425": "درخواست ناکام ہوگئی (خرابی کوڈ: 22425)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_20098": "ایرر کوڈ: 20098۔ اگر آپ پرائیویٹ موڈ میں براؤزنگ کر رہے ہیں، تو براہ کرم نارمل موڈ پر سوئچ کریں اور دوبارہ کوشش کریں۔",
    "error_22298": "گوگل کی درخواست ناکام ہوگئی (خرابی: 22298)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_22498": "فیس بک کی درخواست ناکام ہوگئی (خرابی: 22498)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_24902": "درخواست ناکام ہوگئی (خرابی کوڈ: 24902)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_24903": "درخواست ناکام ہوگئی (خرابی کوڈ: 24903)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_24904": "درخواست ناکام ہوگئی (خرابی کوڈ: 24904)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_24905": "درخواست ناکام ہوگئی (خرابی کوڈ: 24905)۔ براہ کرم دوبارہ کوشش کریں۔",
    "login_title": "MindOnMap میں لاگ ان کریں۔",
    "log_in": "لاگ ان کریں",
    "no_account": "کوئی اکاؤنٹ نہیں؟",
    "create_it": "اسے بنائیں",
    "or_log_in_with": "یا اس کے ساتھ لاگ ان کریں۔",
    "passwordless_login": "بغیر پاس ورڈ لاگ ان",
    "log_in_done": "لاگ ان ہو گیا",
    "three_rd_account_connect_info": "مبارک ہو! آپ کامیابی سے لاگ ان ہو گئے ہیں۔ اب آپ ایک ای میل اکاؤنٹ جوڑ سکتے ہیں جو مستقبل میں لاگ ان کرنے کے لیے استعمال ہوتا ہے۔",
    "see_my_account": "میرا اکاؤنٹ دیکھیں",
    "three_rd_login_merge_account": "فریق ثالث کے اکاؤنٹ کا ای میل ایڈریس سائن اپ ہوچکا ہے، کیا آپ اس ای میل ایڈریس کے ساتھ براہ راست جڑنا اور لاگ ان کرنا چاہتے ہیں؟",
    "connect_log_in": "جڑیں اور لاگ ان کریں۔",
    "create_an_account": "کھاتا کھولیں",
    "back_to_log_in": "لاگ ان پر واپس جائیں۔",
    "create_password": "پاسورڈ بنائیں",
    "create_now": "ابھی بنائیں",
    "password_login_subtitle": "ای میل کے ساتھ پاس ورڈ کے بغیر لاگ ان",
    "account_login": "اکاؤنٹ لاگ ان",
    "rights": "یہ اکاؤنٹ بنا کر، آپ <a href=\"https://www.mindonmap.com/terms-and-conditions/\">سروس کی شرائط</a> اور <a href=\"https://www.mindonmap.com/privacy-policy/\">رازداری کی پالیسی</a>",
    "passwordless_login_done": "بغیر پاس ورڈ لاگ ان ہو گیا۔",
    "passwordless_login_info": "مبارک ہو، آپ نے پاس ورڈ کے بغیر لاگ ان کامیابی سے مکمل کر لیا ہے۔ آپ اس اکاؤنٹ کے لیے پاس ورڈ بنا سکتے ہیں اور مستقبل میں اکاؤنٹ اور پاس ورڈ کے ساتھ لاگ ان کر سکتے ہیں۔ <a href=\"/create-password\" style=\"display: initial;\">ابھی بنائیں</a>",
    "sign_up": "سائن اپ",
    "register_info": "اپنا اکاؤنٹ بناؤ",
    "reset_now": "ابھی ری سیٹ کریں۔",
    "forgot_password": "پاسورڈ بھول گے",
    "reset_password_subtitle": "پاس ورڈ دوبارہ ترتیب دینے کے لیے اپنے اکاؤنٹ کا ای میل استعمال کریں۔",
    "plan_products": "منصوبے اور مصنوعات",
    "nick_name": "صارف نام:",
    "email": "ای میل:",
    "my_products": "میری مصنوعات",
    "my_orders": "میرے احکام",
    "unlink": "لنک ختم کریں۔",
    "link": "لنک",
    "connected_accounts": "منسلک اکاؤنٹس",
    "last_name": "آخری نام:",
    "first_name": "پہلا نام:",
    "Gender": "صنف:",
    "Birth": "پیدائش:",
    "Month": "مہینہ",
    "Year": "سال",
    "Country_Region": "ملک/علاقہ:",
    "Address": "پتہ:",
    "Save": "محفوظ کریں۔",
    "Date": "تاریخ",
    "Male": "مرد",
    "Female": "عورت",
    "Unspecified": "غیر متعینہ",
    "Security": "سیکورٹی",
    "change_password": "پاس ورڈ تبدیل کریں",
    "change_now": "ابھی تبدیل کریں۔",
    "connect_email": "ای میل سے رابطہ کریں۔",
    "delete_account": "کھاتہ مٹا دو",
    "delete_account_info": "جب آپ کا اکاؤنٹ حذف ہو جائے گا، تو آپ کے اکاؤنٹ سے وابستہ میرا اکاؤنٹ کا تمام ڈیٹا مستقل طور پر حذف ہو جائے گا، اور ہو سکتا ہے آپ اسے بازیافت نہ کر سکیں۔ ہم آپ کو احتیاط سے چلنے کا مشورہ دیتے ہیں۔",
    "Delete": "حذف کریں۔",
    "Logout": "لاگ آوٹ",
    "my_profile": "میری پروفائل",
    "guides_faqs": "گائیڈز اور اکثر پوچھے گئے سوالات",
    "More": "مزید",
    "guides": "گائیڈز",
    "register": "رجسٹر کریں",
    "hot_faq": "گرم، شہوت انگیز سوالات",
    "Contents": "مشمولات:",
    "contact_us": "ہم سے رابطہ کریں >>",
    "plan": "منصوبہ",
    "unregistered": "غیر رجسٹرڈ",
    "buy_more": "مزید خریدیں۔",
    "buy_again": "دوبارہ خریدیں۔",
    "buy_now": "ابھی خریدئے",
    "free_no_limit": "مفت اور کوئی حد نہیں۔",
    "expired": "میعاد ختم",
    "lifetime": "زندگی بھر",
    "remain": "رہو",
    "day_s": "دن)",
    "error_24801": "درخواست ناکام ہوگئی (خرابی کوڈ: 24801)۔ براہ کرم دوبارہ کوشش کریں۔",
    "no_app_found": "کوئی ایپ نہیں ملی!<a href=\"/\">ریفریش</a> یا <a href=\"https://www.mindonmap.com/\">آفیشل ویب سائٹ پر جائیں</a>",
    "get_more": "مزید حاصل کریں >>",
    "edit_photo": "تصویر میں ترمیم کریں۔",
    "select_photo": "تصویر منتخب کریں۔",
    "change_photo": "تصویر تبدیل کریں",
    "cancel": "منسوخ کریں۔",
    "hide_password": "پاس ورڈ چھپائیں۔",
    "show_password": "پاسورڈ دکھاو",
    "zoom_in": "زوم ان کریں۔",
    "zoom_out": "دور کرنا",
    "rotate": "گھمائیں۔",
    "horizontal_flip": "افقی پلٹائیں",
    "vertical_flip": "عمودی پلٹائیں",
    "country": "ملک",
    "country_1": "اپنا ملک/علاقہ منتخب کریں۔",
    "country_2": "آلینڈ جزیرہ",
    "country_3": "افغانستان",
    "country_4": "البانیہ",
    "country_5": "الجزائر",
    "country_6": "امریکی ساموا",
    "country_7": "اندورا",
    "country_8": "انگولا",
    "country_9": "انگویلا",
    "country_10": "انٹارکٹیکا",
    "country_11": "اینٹیگوا اور باربوڈا",
    "country_12": "ارجنٹائن",
    "country_13": "آرمینیا",
    "country_14": "اروبا",
    "country_15": "آسٹریلیا",
    "country_16": "آسٹریا",
    "country_17": "آذربائیجان",
    "country_18": "بحرین",
    "country_19": "بنگلہ دیش",
    "country_20": "بارباڈوس",
    "country_21": "بیلاروس",
    "country_22": "بیلجیم",
    "country_23": "بیلیز",
    "country_24": "بینن",
    "country_25": "برمودا",
    "country_26": "بھوٹان",
    "country_27": "بولیویا",
    "country_28": "بوسنیا اور ہرزیگووینا",
    "country_29": "بوٹسوانا",
    "country_30": "بوویٹ جزیرہ",
    "country_31": "برازیل",
    "country_32": "برطانوی بحر ہند کا علاقہ",
    "country_33": "برٹش ورجن آئی لینڈز",
    "country_34": "برونائی",
    "country_35": "بلغاریہ",
    "country_36": "برکینا",
    "country_37": "برونڈی",
    "country_38": "کمبوڈیا",
    "country_39": "کیمرون",
    "country_40": "کینیڈا",
    "country_41": "کیپ وردے",
    "country_42": "کیریبین نیدرلینڈز",
    "country_43": "جزائر کیمن",
    "country_44": "مرکزی افریقی جمہوریت",
    "country_45": "چاڈ",
    "country_46": "چلی",
    "country_47": "چین",
    "country_48": "کرسمس جزیرہ",
    "country_49": "کوکوس (کیلنگ) جزائر",
    "country_50": "کولمبیا",
    "country_51": "جزائر کوک",
    "country_52": "کوسٹا ریکا",
    "country_53": "کوٹ ڈی آئیوری",
    "country_54": "کروشیا",
    "country_55": "کیوبا",
    "country_56": "قبرص",
    "country_57": "جمہوریہ چیک",
    "country_58": "جمہوری جمہوریہ کانگو",
    "country_59": "ڈنمارک",
    "country_60": "جبوتی",
    "country_61": "ڈومینیکا",
    "country_62": "ڈومینیکن ریپبلک",
    "country_63": "ایکواڈور",
    "country_64": "مصر",
    "country_65": "ال سلواڈور",
    "country_66": "استوائی گنی",
    "country_67": "اریٹیریا",
    "country_68": "ایسٹونیا",
    "country_69": "ایتھوپیا",
    "country_70": "فاک لینڈ جزائر",
    "country_71": "فیرو جزائر",
    "country_72": "مائیکرونیشیا کی وفاقی ریاستیں۔",
    "country_73": "فجی",
    "country_74": "فن لینڈ",
    "country_75": "فرانس",
    "country_76": "فرانسیسی گیانا",
    "country_77": "فرانسیسی پولینیشیا",
    "country_78": "فرانسیسی جنوبی علاقے",
    "country_79": "گبون",
    "country_80": "گیمبیا",
    "country_81": "جارجیا",
    "country_82": "جرمنی",
    "country_83": "گھانا",
    "country_84": "جبرالٹر",
    "country_85": "عظیم برطانیہ (برطانیہ؛ انگلینڈ)",
    "country_86": "یونان",
    "country_87": "گرین لینڈ",
    "country_88": "گریناڈا",
    "country_89": "گواڈیلوپ",
    "country_90": "گوام",
    "country_91": "گوئٹے مالا",
    "country_92": "گرنسی",
    "country_93": "گنی",
    "country_94": "گنی بساؤ",
    "country_95": "گیانا",
    "country_96": "ہیٹی",
    "country_97": "ہیرڈ آئی لینڈ اور میکڈونلڈ آئی لینڈز",
    "country_98": "ہونڈوراس",
    "country_99": "ہانگ کانگ",
    "country_100": "ہنگری",
    "country_101": "آئس لینڈ",
    "country_102": "انڈیا",
    "country_103": "انڈونیشیا",
    "country_104": "ایران",
    "country_105": "عراق",
    "country_106": "آئرلینڈ",
    "country_107": "آئل آف مین",
    "country_108": "اسرا ییل",
    "country_109": "اٹلی",
    "country_110": "جمیکا",
    "country_111": "جاپان",
    "country_112": "جرسی",
    "country_113": "اردن",
    "country_114": "قازقستان",
    "country_115": "کینیا",
    "country_116": "کریباتی",
    "country_117": "کویت",
    "country_118": "کرغزستان",
    "country_119": "لاؤس",
    "country_120": "لٹویا",
    "country_121": "لبنان",
    "country_122": "لیسوتھو",
    "country_123": "لائبیریا",
    "country_124": "لیبیا",
    "country_125": "لیختنسٹین",
    "country_126": "لتھوانیا",
    "country_127": "لکسمبرگ",
    "country_128": "مکاؤ",
    "country_129": "مڈغاسکر",
    "country_130": "ملاوی",
    "country_131": "ملائیشیا",
    "country_132": "مالدیپ",
    "country_133": "مالی",
    "country_134": "مالٹا",
    "country_135": "مارشل جزائر",
    "country_136": "مارٹنیک",
    "country_137": "موریطانیہ",
    "country_138": "ماریشس",
    "country_139": "مایوٹ",
    "country_140": "میکسیکو",
    "country_141": "مالڈووا",
    "country_142": "موناکو",
    "country_143": "منگولیا",
    "country_144": "مونٹی نیگرو",
    "country_145": "مونٹسریٹ",
    "country_146": "مراکش",
    "country_147": "موزمبیق",
    "country_148": "میانمار (برما)",
    "country_149": "نمیبیا",
    "country_150": "نورو",
    "country_151": "نیپال",
    "country_152": "نیدرلینڈز",
    "country_153": "نیو کیلیڈونیا",
    "country_154": "نیوزی لینڈ",
    "country_155": "نکاراگوا",
    "country_156": "نائجر",
    "country_157": "نائیجیریا",
    "country_158": "نیو",
    "country_159": "نورفولک جزیرہ",
    "country_160": "شمالی کوریا",
    "country_161": "شمالی ماریانا جزائر",
    "country_162": "ناروے",
    "country_163": "عمان",
    "country_164": "پاکستان",
    "country_165": "پلاؤ",
    "country_166": "فلسطینی علاقے",
    "country_167": "پانامہ",
    "country_168": "پاپوا نیو گنی",
    "country_169": "پیراگوئے",
    "country_170": "پیرو",
    "country_171": "پٹکیرن جزائر",
    "country_172": "پولینڈ",
    "country_173": "پرتگال",
    "country_174": "پورٹو ریکو",
    "country_175": "قطر",
    "country_176": "جمہوریہ مقدونیہ (FYROM)",
    "country_177": "جمہوریہ کانگو",
    "country_178": "ری یونین",
    "country_179": "رومانیہ",
    "country_180": "روسی فیڈریشن",
    "country_181": "روانڈا",
    "country_182": "سینٹ بارتھیلیمی۔",
    "country_183": "سینٹ مارٹن (فرانس)",
    "country_184": "سینٹ پیئر اور میکیلون",
    "country_185": "ساموا",
    "country_186": "سان مرینو",
    "country_187": "ساؤ ٹوم اور پرنسپی",
    "country_188": "سعودی عرب",
    "country_189": "سینیگال",
    "country_190": "سربیا",
    "country_191": "سیشلز",
    "country_192": "سیرا لیون",
    "country_193": "سنگاپور",
    "country_194": "سلوواکیہ",
    "country_195": "سلووینیا",
    "country_196": "جزائر سلیمان",
    "country_197": "صومالیہ",
    "country_198": "جنوبی افریقہ",
    "country_199": "جنوبی جارجیا اور جنوبی سینڈوچ آئسلا",
    "country_200": "جنوبی کوریا",
    "country_201": "جنوبی سوڈان",
    "country_202": "سپین",
    "country_203": "سری لنکا",
    "country_204": "سینٹ ہیلینا اور انحصار",
    "country_205": "سینٹ کٹس اور نیوس",
    "country_206": "سینٹ لوسیا",
    "country_207": "سینٹ ونسنٹ اور گریناڈائنز",
    "country_208": "سوڈان",
    "country_209": "سورینام",
    "country_210": "سوازی لینڈ",
    "country_211": "سویڈن",
    "country_212": "سوئٹزرلینڈ",
    "country_213": "شام",
    "country_214": "تائیوان",
    "country_215": "تاجکستان",
    "country_216": "تنزانیہ",
    "country_217": "سانچہ:ملکی ڈیٹا SJM سوالبارڈ",
    "country_218": "تھائی لینڈ",
    "country_219": "بہاماز",
    "country_220": "کوموروس",
    "country_221": "فلپائن",
    "country_222": "تیمور لیسٹی (مشرقی تیمور)",
    "country_223": "جانے کے لئے",
    "country_224": "ٹوکیلاؤ",
    "country_225": "ٹونگا",
    "country_226": "ٹرینیڈاڈ اور ٹوباگو",
    "country_227": "تیونس",
    "country_228": "ترکی",
    "country_229": "ترکمانستان",
    "country_230": "ترک اور کیکوس جزائر",
    "country_231": "تووالو",
    "country_232": "یوگنڈا",
    "country_233": "یوکرین",
    "country_234": "متحدہ عرب امارات",
    "country_235": "ریاستہائے متحدہ کے چھوٹے بیرونی جزائر",
    "country_236": "ریاستہائے متحدہ امریکہ (امریکہ)",
    "country_237": "ریاستہائے متحدہ کے ورجن جزائر",
    "country_238": "یوراگوئے",
    "country_239": "ازبکستان",
    "country_240": "وانواتو",
    "country_241": "ویٹیکن سٹی (دی ہولی سی)",
    "country_242": "وینزویلا",
    "country_243": "ویتنام",
    "country_244": "والس اور فیوٹنا",
    "country_245": "مغربی صحارا",
    "country_246": "یمن",
    "country_247": "زیمبیا",
    "country_248": "زمبابوے",
    "google_login": "گوگل کے ساتھ سائن ان کریں۔",
    "State": "حالت",
    "Activation_code": "فعالیت کا کوڈ",
    "Question": "ان تمام ایپس کی فہرست بنائیں جنہیں آپ نے لاگ ان کیا ہے۔",
    "Copy_complete": "کاپی مکمل",
    "footer": "کاپی رائٹ © 2024 MindOnMap اسٹوڈیو۔ جملہ حقوق محفوظ ہیں۔",
    "change_password_success": "پاس ورڈ کامیابی سے تبدیل ہو گیا۔",
    "successful_login_title": "لاگ ان کامیاب",
    "product_page": "پروڈکٹ کا صفحہ >>",
    "successful_login_info": "لاگ ان مکمل ہو گیا۔ براہ کرم موجودہ صفحہ بند کریں اور درج ذیل عمل کو جاری رکھنے کے لیے اصل ٹیب پر واپس جائیں۔ موجودہ صفحہ 5 سیکنڈ میں خود بخود بند ہو جائے گا۔ اگر \"ہو گیا\" بٹن پر کلک کرکے خودکار بند یا بند ہونا ناکام ہوجاتا ہے، تو براہ کرم اس ٹیب کو براہ راست بند کردیں۔",
    "successful_login_info_firefox": "لاگ ان مکمل ہو گیا۔ براہ کرم موجودہ صفحہ بند کریں اور درج ذیل عمل کو جاری رکھنے کے لیے اصل ٹیب پر واپس جائیں۔",
    "my_account": "میرا اکاونٹ",
    "my_history": "میری تاریخ",
    "remove_watermark": "واٹر مارک کو ہٹا دیں۔",
    "no_history": "کوئی تاریخ نہیں۔",
    "history_all": "تمام منتخب کریں",
    "history_open": "کھولیں۔",
    "history_down": "ڈاؤن لوڈ کریں",
    "history_delete": "حذف کریں۔",
    "history_clear": "غلط صاف کریں۔",
    "images": "تصویر",
    "use_this_function": "اس پروڈکٹ کو استعمال کریں >>",
    "hd_downloading": "ایچ ڈی اصل تصاویر ڈاؤن لوڈ کرنے کے فوائد:",
    "lifetimeRemaining": "تاحیات باقی",
    "Remaining": "باقی",
    "email_verification": "ای میل کی توثیق",
    "email_verification_info": "ہم نے تصدیقی پیغام آپ کے ای میل <span class=\"email\"></span> پر بھیج دیا ہے، اور براہ کرم توثیق مکمل کریں۔ تصدیق کے بعد، فوائد خود بخود مطابقت پذیر ہو جائیں گے۔",
    "wrong_email": "غلط ای میل ایڈریس؟",
    "click_here_to_modify": "ترمیم کرنے کے لیے یہاں کلک کریں۔",
    "get_more_help": "مزید مدد حاصل کریں؟",
    "click_here": "یہاں کلک کریں",
    "email_verification_info_success": "اپنے ای میل اکاؤنٹ کی تصدیق کے لیے مبارکباد۔",
    "email_verification_info_error": "میعاد ختم ہونے والے لنک کی وجہ سے تصدیق ناکام ہو گئی۔",
    "registration_succeeded": "رجسٹریشن کامیاب ہو گئی۔",
    "registration_succeeded_info_1": "مبارک ہو! آپ نے کامیابی سے درج کیا ہے. ہم نے آپ کی ای میل <span class=\"email\"></span> پر توثیقی پیغام بھیج دیا ہے، اور براہ کرم اس ای میل کے فوائد کو استعمال کرنے کے لیے تصدیق مکمل کریں۔",
    "registration_succeeded_info_2": "ہوم پیج پر واپس آنے اور اس پروڈکٹ کو استعمال کرنے کے لیے \"ہو گیا\" پر کلک کریں۔",
    "registration_succeeded_info_3": "موجودہ صفحہ کو بند کرنے کے لیے \"ہو گیا\" پر کلک کریں اور درج ذیل کارروائیوں کے لیے ہوم پیج پر واپس جائیں۔ اگر بند کرنا ناکام ہوجاتا ہے، تو براہ کرم دستی طور پر ٹیب کو بند کریں۔",
    "verify_email": "ای میل کی تصدیق کریں۔",
    "registered_email_not_verified": "رجسٹرڈ ای میل کی تصدیق نہیں ہوئی ہے، براہ کرم ایک بار اس کی تصدیق کریں۔",
    "email_verification_time_1": "تصدیقی ای میل موصول نہیں ہوا؟",
    "email_verification_time_2": "کے بعد",
    "email_verification_time_3": "اسے دوبارہ بھیجنے کے لیے یہاں کلک کریں۔",
    "error_26301": "غلطی کا کوڈ: 26301، براہ کرم ہم سے رابطہ کریں۔",
    "error_26302": "غلطی کا کوڈ: 26302، براہ کرم ہم سے رابطہ کریں۔",
    "error_26303": "ای میل فارمیٹ کی خرابی (خرابی کوڈ: 26303)۔ براہ کرم اسے دوبارہ درج کریں۔",
    "error_26304": "8 حروف سے اوپر کا پاس ورڈ تجویز کیا جاتا ہے (خرابی کوڈ: 26304)",
    "error_26305": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26305)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26306": "ای میل رجسٹر ہو چکی ہے، براہ کرم <a href=\"https://account.mindonmap.com/login/\">لاگ ان کرنے کے لیے جائیں</a>",
    "error_26307": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26307)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26308": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26308)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26401": "غلطی کا کوڈ: 26401، براہ کرم دوبارہ کوشش کریں۔",
    "error_26402": "ای میل کی تصدیق ہوگئی ہے (خرابی کوڈ: 26402)، براہ کرم دوبارہ کوشش کریں۔",
    "error_26403": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26403)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26404": "درخواست ناکام ہوگئی (خرابی کوڈ: 26404)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26501": "غلطی کا کوڈ: 26501، براہ کرم ہم سے رابطہ کریں۔",
    "error_26502": "غلطی کا کوڈ: 26502، براہ کرم ہم سے رابطہ کریں۔",
    "error_26503": "ای میل فارمیٹ کی خرابی (خرابی کوڈ: 26503)۔ براہ کرم اسے دوبارہ درج کریں۔",
    "error_26504": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26504)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26505": "ای میل رجسٹر نہیں ہوئی ہے، براہ کرم <a href=\"https://account.mindonmap.com/register/\">اسے پہلے رجسٹر کریں</a>",
    "error_26506": "ای میل کی تصدیق ہو چکی ہے۔",
    "error_26507": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26507)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26508": "توثیق فائل ہوگئی (خرابی کوڈ: 26508)، براہ کرم دوبارہ کوشش کریں۔",
    "error_26509": "درخواست ناکام ہوگئی (خرابی کوڈ: 26509)، براہ کرم دوبارہ کوشش کریں۔",
    "error_26510": "غلطی کا کوڈ: 26510، براہ کرم ہم سے رابطہ کریں۔",
    "error_26601": "غلطی کا کوڈ: 26601، براہ کرم ہم سے رابطہ کریں۔",
    "error_26602": "غلطی کا کوڈ: 26602، براہ کرم ہم سے رابطہ کریں۔",
    "error_26603": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26603)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26604": "غلطی کا کوڈ: 26604، براہ کرم ہم سے رابطہ کریں۔",
    "error_26605": "غلطی کا کوڈ: 26605، براہ کرم ہم سے رابطہ کریں۔",
    "error_26701": "غلطی کا کوڈ: 26701، براہ کرم ہم سے رابطہ کریں۔",
    "error_26702": "Reuqest ناکام ہوگیا (خرابی کوڈ: 26702)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26703": "غلطی کا کوڈ: 26703، براہ کرم ہم سے رابطہ کریں۔",
    "error_26704": "غلطی کا کوڈ: 26704، براہ کرم ہم سے رابطہ کریں۔",
    "error_26705": "لاگ ان ہونے کا انتظار کریں (خرابی کوڈ: 26705)۔ براہ کرم دوبارہ کوشش کریں۔",
    "no_cookie": "آپ نے اپنے براؤزر میں تمام کوکیز کو بلاک کرنے کا فنکشن آن کر دیا ہے، اس لیے آپ لاگ ان نہیں ہو سکتے۔ براہ کرم تمام کوکیز کی اجازت دیں کے باکس کو چیک کرنے کے لیے سیٹنگز میں جائیں۔",
    "error_26801": "غلطی کا کوڈ: 26801، براہ کرم ہم سے رابطہ کریں۔",
    "error_26802": "غلطی کا کوڈ: 26802، براہ کرم ہم سے رابطہ کریں۔",
    "error_26803": "درخواست ناکام ہوگئی (خرابی کوڈ: 26803)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26804": "درخواست ناکام ہوگئی (خرابی کوڈ: 26804)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_order": "درخواست ناکام ہوگئی (خرابی کا کوڈ: 27098)، براہ کرم دوبارہ کوشش کریں!",
    "error_order1": "آرڈر کا استفسار نامکمل ہے (غلطی کوڈ: ",
    "error_order2": ")، براہ کرم ریفریش کریں اور دوبارہ کوشش کریں۔",
    "modify_email_title": "ای میل میں ترمیم کریں۔",
    "modify_email_info": "آپ اپنے اکاؤنٹ میں لاگ ان کرنے کے لیے ترمیم شدہ ای میل استعمال کر سکتے ہیں۔",
    "images_per": "تصاویر فی",
    "error_26101": "خرابی: 26101۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26102": "خرابی: 26102۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26103": "درخواست ناکام ہوگئی (خرابی کوڈ: 26103)۔ براہ کرم اسے دوبارہ کوشش کریں۔",
    "error_26104": "غلطی کا کوڈ: 26104، براہ کرم دوبارہ کوشش کریں۔",
    "error_26105": "غلطی کا کوڈ: 26105، براہ کرم دوبارہ کوشش کریں۔",
    "error_26106": "حذف کرنے میں ناکام (خرابی کوڈ: 26106)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_26201": "خرابی: 26201۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26202": "درخواست ناکام ہوگئی (خرابی کوڈ: 26202)۔ براہ کرم اسے دوبارہ کوشش کریں۔",
    "error_26001": "خرابی: 26001۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26002": "خرابی: 26002۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26003": "خرابی: 26003۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26004": "خرابی: 26004۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_26005": "درخواست ناکام ہوگئی (خرابی کوڈ: 26005)۔ براہ کرم اسے دوبارہ کوشش کریں۔",
    "error_26006": "غلطی کا کوڈ: 26006، براہ کرم دوبارہ کوشش کریں۔",
    "error_26008": "خرابی: 26008۔ براہ کرم ہم سے رابطہ کریں۔",
    "go_to_the_home_page": "ہوم پیج پر جائیں۔",
    "error_27101": "درخواست ناکام ہوگئی (خرابی کوڈ: 27101)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_27201": "غلطی کا کوڈ: 27201، براہ کرم ہم سے رابطہ کریں۔",
    "error_27202": "غلطی کا کوڈ: 27202، براہ کرم دوبارہ کوشش کریں۔",
    "error_27203": "درخواست ناکام ہوگئی (خرابی کوڈ: 27203)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_27204": "غلط کوڈ (خرابی کوڈ: 27204)۔",
    "error_27205": "درخواست ناکام ہوگئی (خرابی کوڈ: 27205)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_27206": "درخواست ناکام ہوگئی (خرابی کوڈ: 27206)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_27207": "درخواست ناکام ہوگئی (خرابی کوڈ: 27207)۔ براہ کرم دوبارہ کوشش کریں۔",
    "no_history_found": "آپ نے کوئی ٹول استعمال نہیں کیا ہے! <a href=\"/\">ریفریش کریں</a> یا <a href=\"https://www.mindonmap.com/\">آفیشل ویب سائٹ پر جائیں</a>",
    "error_25301": "خرابی: 25301۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_25302": "خرابی: 25302۔ براہ کرم ہم سے رابطہ کریں۔",
    "error_25303": "درخواست ناکام ہوگئی (خرابی کوڈ: 25303)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_25304": "درخواست ناکام ہوگئی (خرابی کوڈ: 25304)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_25305": "درخواست ناکام ہوگئی (خرابی کوڈ: 25305)۔ براہ کرم دوبارہ کوشش کریں۔",
    "error_25306": "درخواست ناکام ہوگئی (خرابی کوڈ: 25306)۔ براہ کرم دوبارہ کوشش کریں۔",
    "image_upscaler_p": "واٹر مارک کے بغیر تصویر ڈاؤن لوڈ کرنے کے فوائد:",
    "Available_for": "کے لیے دستیاب ہے:",
    "credit_per": "%s کریڈٹ فی HD تصویر",
    "still_valid": "خریدا ہوا منصوبہ (پلان) اب بھی درست ہے۔",
    "credit": "کریڈٹ",
    "pc_3rd_info": "کامیابی سے لاگ ان کریں۔ براہ کرم مزید کارروائی کے لیے درخواست پر جائیں۔",
    "use_online": "آن لائن سروس استعمال کریں۔",
    "use_download": "ڈیسک ٹاپ پروگرام استعمال کریں۔",
    "use_immediately": "فوری طور پر استعمال کریں۔",
    "Use_in_browser": "براؤزر میں استعمال کریں۔",
    "win_desktop": "ونڈوز",
    "Mac_desktop": "میک",
    "credits_per": "ہر ماہ {%} کریڈٹس",
    "expire": "میعاد ختم ہونے کا وقت:",
    "viewDetails": "تفصیلات دیکھیں",
    "viewHistory": "تاریخ کے فوائد دیکھیں >>",
    "viewDetailsInfo": "نوٹس: اگر رکنیت کے فوائد کی میعاد ختم ہونے کے بعد 7 دنوں کے اندر تجدید کی جاتی ہے، تو غیر استعمال شدہ فوائد کا استعمال جاری رکھا جا سکتا ہے۔ اس کے علاوہ، میعاد ختم ہونے کا وقت خود بخود نئے سبسکرپشن کی میعاد ختم ہونے کے وقت میں اپ ڈیٹ ہو جائے گا۔ اگر میعاد ختم ہونے کے 7 دن کے بعد کوئی نئی رکنیت نہیں ہے تو، تمام ختم شدہ فوائد کو صاف کر دیا جائے گا۔",
    "connect_account": "ای میل کو اپنے اکاؤنٹ سے باندھیں۔",
    "connect_account_info": "بائنڈنگ کے بعد، آپ اس ای میل ایڈریس کے ساتھ لاگ ان کر سکتے ہیں۔",
    "connect_now": "اب باندھیں۔",
    "no_email_bind": "کوئی ای میل پابند نہیں ہے۔",
    "bind_email": "ای میل باندھیں۔",
    "connect_your_email_placeholder": "براہ کرم اپنا ای میل ایڈریس درج کریں۔",
    "bind_an_email": "ایک ای میل باندھیں۔",
    "bind_info": "آپ کامیابی سے لاگ ان ہو گئے ہیں۔ اپنے فوائد کو چالو کرنے کے لیے براہ کرم اپنے اکاؤنٹ میں ایک ای میل کا پابند کریں۔",
    "bind_later": "بعد میں باندھیں۔",
    "hi": "ہائے، %s!",
    "Personal_Information": "ذاتی معلومات",
    "Access": "رسائی",
    "Subscription_Plan": "(سبسکرپشن پلان)",
    "No_orders": "کوئی آرڈر نہیں ملا۔",
    "No_data": "کوئی مواد نہیں",
    "Featured_Products": "نمایاں مصنوعات",
    "More_Products": "مزید مصنوعات",
    "Free_Download": "مفت ڈاؤنلوڈ",
    "Get_Started": "شروع کرنے کے",
    "Subscribe": "سبسکرائب",
    "Verified": "تصدیق شدہ",
    "back_to_account_center": "اکاؤنٹ سینٹر پر واپس جائیں۔",
    "success": "کامیابی!",
    "successfully": "آپ نے کامیابی کے ساتھ ایک اکاؤنٹ رجسٹر کر لیا ہے۔",
    "Continue": "جاری رہے",
    "Already": "پہلے سے ہی ایک اکاؤنٹ ہے؟",
    "loading_verification": "تصدیق کی حیثیت کی جانچ کر رہا ہے...",
    "email_no_verification": "معذرت، رجسٹرڈ ای میل ایڈریس کی تصدیق نہیں ہوئی ہے۔ براہ کرم اوپر دی گئی ہدایات کے مطابق تصدیق مکمل کریں اور رجسٹریشن مکمل کرنے کے لیے دوبارہ \"ہو گیا\" بٹن پر کلک کریں۔",
    "will_expire_after": "کے بعد ختم ہو جائے گا۔",
    "hours": "گھنٹے",
  },
  "uz": {
    "overtime": "Xato kodi: {%}, qayta urinib koʻring",
    "isnetwork": "Internet xatosi. Iltimos, tekshiring va qayta urinib ko'ring",
    "email_placeholder": "Elektron pochta",
    "email_empty": "Iltimos, elektron pochta manzilini kiriting",
    "email_not_valid": "Elektron pochta manzili yaroqsiz",
    "email_not_valid_1": "Iltimos, elektron pochtangizni kiriting",
    "email_not_valid_2": "Elektron pochta manzili noto‘g‘ri, boshqa manzildan foydalaning.",
    "email_not_valid_3": "Email kiritilmagan",
    "password_placeholder": "Parol",
    "password_empty": "Iltimos, parolni kiriting",
    "password_not_valid": "Hisob qaydnomasi yoki parol noto'g'ri",
    "password_not_valid_1": "8 ta belgidan ortiq parol talab qilinadi",
    "password_not_valid_2": "Iltimos, parol yarating",
    "password_placeholder_1": "Parolingizni yarating",
    "password_placeholder_2": "Parolingizni tasdiqlang",
    "password_placeholder_3": "Yangi parol yarating",
    "password_placeholder_4": "Yangi parolni tasdiqlang",
    "password_placeholder_5": "Eski parolni kiriting",
    "copy_password_empty": "Iltimos, parolni tasdiqlang",
    "copy_password_not_valid": "Parolingizni tasdiqlang",
    "copy_passwords_inconsistent": "Parolni tasdiqlash mos kelmadi",
    "code_empty": "Iltimos, tasdiqlash kodini kiriting",
    "code_not_valid": "Tasdiqlash kodi yaroqsiz",
    "code_placeholder": "Tasdiq kodi",
    "not_received_code": "Agar pochta qutingiz uzoq vaqt davomida tasdiqlash kodini olmagan bo'lsa, tasdiqlash kodini qaytadan oling.",
    "get_first_code": "Iltimos, avval tasdiqlash kodini oling.",
    "last_name_placeholder": "Iltimos, familiyangizni kiriting",
    "first_name_placeholder": "Iltimos, ismingizni kiriting",
    "address_placeholder": "Iltimos, manzilingizni kiriting",
    "no_code_text": "Tasdiqlash kodini yubordik. Iltimos, kodingizni kiriting. <span class='tips'>Kodni olmadingizmi?",
    "no_code_text_1": "1. Iltimos, elektron pochta manzili to'g'ri ekanligiga ishonch hosil qiling va u elektron pochta xabarlarini qabul qilishi mumkin.",
    "no_code_text_2": "2. Elektron pochta tizim tomonidan avtomatik ravishda yuborilganligi sababli, u spam yoki keraksiz elektron pochta sifatida belgilanishi mumkin. Iltimos, xat axlat qutisi jildida ekanligini tekshiring.",
    "no_code_text_3": "3. Muammoingizni hal qila olmayapsizmi? ",
    "no_code_text_3_span": "Keyin biz bilan bog'lanish uchun shu yerni bosing.",
    "order_no": "Siz hech qanday mahsulot sotib olmagansiz, agar sizda biron bir savol bo'lsa, <a href=\"https://www.mindonmap.com/contact-us/\">biz bilan bog'laning</a>.",
    "error_24901": "Joriy hisob elektron pochtasiga ulanmagan va buyurtmalarni topa olmadi. Iltimos, elektron pochta manzilini ulang.",
    "user_guide": "Foydalanuvchi uchun qoʻllanma>>",
    "download": "Yuklab olish",
    "order_number": "Buyurtma raqami:",
    "Refund": "To'lovni qaytarish",
    "Disabled": "O'chirilgan",
    "Normal": "Oddiy",
    "Modify": "O'zgartirish",
    "Modify_1": "O'zgartirish>>",
    "Connect": "Ulanmoq",
    "unlink_success": "Ulanish muvaffaqiyatli uzildi",
    "connect_success": "Muvaffaqiyatli ulanish",
    "feedback_title": "Fikr-mulohazangiz uchun rahmat. Iltimos, muammoingizni qoldiring va biz sizga 24 soat ichida javob beramiz.",
    "feedback_thank_you": "Rahmat!<br />Fikr-mulohazangiz muvaffaqiyatli yuborildi.",
    "feedback_email": "Bu yerga elektron pochtangizni kiriting!",
    "feedback_content": "Siz duch kelgan har qanday muammo yoki taklifni shu yerda qoldiring.",
    "feedback_submit": "Yuborish",
    "form_contents": "Hech qanday tavsif kiritmadingiz. Iltimos, kiriting va qayta yuboring.",
    "old_password": "Iltimos, eski parolni kiriting",
    "new_password": "Iltimos, yangi parol yarating",
    "old_new_password": "Yangi parol eskisi bilan bir xil bo'lishi mumkin emas",
    "incorrect_password": "Noto'g'ri parol",
    "delete_no": "Hozir oʻchirish",
    "Caps": "Caps lock yoqilgan",
    "Get": "Oling",
    "Done": "Bajarildi",
    "error_20001": "Xato: 20001. Iltimos, qayta kiring.",
    "error_20002": "Xato: 20002. Iltimos, qayta kiring.",
    "error_20003": "Xato: 20003. Iltimos, qayta kiring.",
    "error_20004": "So‘rov bajarilmadi (xato kodi: 20004). Iltimos, qayta urinib ko‘ring.",
    "error_20005": "Kirish sessiyasi tugadi (Xato: 20005). Iltimos, qayta kiring.",
    "error_20006": "So'rov bajarilmadi (xato kodi: 20006). Iltimos, qayta urinib ko‘ring.",
    "error_20007": "Kirish sessiyasi tugadi (Xato: 20007). Iltimos, qayta kiring.",
    "error_20008": "Kirish sessiyasi tugadi (Xato: 20008). Iltimos, qayta kiring.",
    "error_20009": "Kirish sessiyasi tugadi (Xato: 20009). Iltimos, qayta kiring.",
    "error_20101": "Iltimos, elektron pochtangizni kiriting (xato kodi: 20101)",
    "error_20102": "Elektron pochta noto‘g‘ri (xato kodi: 20102)",
    "error_20103": "So‘rov bajarilmadi (xato kodi: 20103). Iltimos, qayta urinib ko‘ring",
    "error_20104": "Elektron pochta allaqachon ishlatilmoqda, iltimos <a href=\"https://account.mindonmap.com/login/\">kiring</a> yoki yangisi bilan ro‘yxatdan o‘ting",
    "error_20105": "So‘rov bajarilmadi (xato kodi: 20105). Iltimos, qayta urinib ko‘ring",
    "error_20106": "Email yuborilmadi, qayta urinib ko‘ring",
    "error_20201": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20201)",
    "error_20202": "Iltimos, parolingizni kiriting (xato kodi: 20202)",
    "error_20203": "Tasdiqlash kodini kiriting (xato kodi: 20203)",
    "error_20204": "Elektron pochta noto‘g‘ri (xato kodi: 20204)",
    "error_20205": "8 ta belgidan ortiq parol talab qilinadi (xato kodi: 20205)",
    "error_20206": "So‘rov bajarilmadi (xato kodi: 20206). Iltimos, qayta urinib ko‘ring",
    "error_20207": "Tasdiqlash kodi yaroqsiz",
    "error_20208": "So‘rov bajarilmadi (xato kodi: 20208). Iltimos, qayta urinib ko‘ring",
    "error_20209": "So‘rov bajarilmadi (xato kodi: 20209). Iltimos, qayta urinib ko‘ring",
    "error_20301": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20301)",
    "error_20302": "Xato: 20302. Iltimos, biz bilan bog'laning",
    "error_20303": "Elektron pochta noto‘g‘ri (xato kodi: 20303)",
    "error_20304": "So‘rov bajarilmadi (xato kodi: 20304). Iltimos, qayta urinib ko‘ring",
    "error_20305": "Hisob mavjud emas. Iltimos, qayta kiriting yoki avval <a href=\"https://account.mindonmap.com/register/\">Uni yarating</a>.",
    "error_20306": "Hali parol yo'q. <a href=\"https://account.mindonmap.com/passwordless-login/\">Parolsiz kirish</a> yoki <a href=\"https://account.mindonmap.com/create-password/\"> dan foydalaning. parol</a> o'rnating va tizimga kiring.",
    "error_20308": "So‘rov bajarilmadi (xato kodi: 20308). Iltimos, qayta urinib ko‘ring",
    "error_20401": "Chiqish amalga oshmadi (xato kodi: 20401). Iltimos, qayta urinib ko‘ring",
    "error_20501": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20501)",
    "error_20502": "Elektron pochta noto‘g‘ri (xato kodi: 20502)",
    "error_20503": "So‘rov bajarilmadi (xato kodi: 20503). Iltimos, qayta urinib ko‘ring",
    "error_20504": "Email yuborib bo‘lmadi. Iltimos, qayta urinib ko‘ring.",
    "error_20601": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20601)",
    "error_20602": "Iltimos, tasdiqlash kodini kiriting (xato kodi: 20602)",
    "error_20603": "Elektron pochta noto‘g‘ri (xato kodi: 20603)",
    "error_20604": "So‘rov bajarilmadi (xato kodi: 20604). Iltimos, qayta urinib ko‘ring",
    "error_20606": "So‘rov bajarilmadi (xato kodi: 20606). Iltimos, qayta urinib ko‘ring",
    "error_20607": "So‘rov bajarilmadi (xato kodi: 20607). Iltimos, qayta urinib ko‘ring",
    "error_20608": "So‘rov bajarilmadi (xato kodi: 20608). Iltimos, qayta urinib ko‘ring",
    "error_20701": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20701)",
    "error_20702": "Elektron pochta noto‘g‘ri (xato kodi: 20702)",
    "error_20703": "So‘rov bajarilmadi (xato kodi: 20703). Iltimos, qayta urinib ko‘ring",
    "error_20704": "Hisob mavjud emas. Iltimos, qayta kiriting yoki avval <a href=\"https://account.mindonmap.com/register/\">Uni yarating</a>.",
    "error_20705": "So‘rov bajarilmadi (xato kodi: 20705). Iltimos, qayta urinib ko‘ring",
    "error_20706": "Email yuborib bo‘lmadi. Iltimos, qayta urinib ko‘ring",
    "error_20801": "Iltimos, elektron pochta manzilingizni kiriting (xato kodi: 20801)",
    "error_20802": "Xato: 20802. Iltimos, biz bilan bog'laning",
    "error_20803": "Iltimos, tasdiqlash kodini kiriting (xato kodi: 20803)",
    "error_20804": "Elektron pochta noto‘g‘ri (xato kodi: 20804)",
    "error_20805": "8 ta belgidan ortiq parol talab qilinadi (xato kodi: 20805)",
    "error_20806": "So‘rov bajarilmadi (xato kodi: 20806). Iltimos, qayta urinib ko‘ring",
    "error_20808": "So‘rov bajarilmadi (xato kodi: 20808). Iltimos, qayta urinib ko‘ring",
    "error_20901": "So‘rov bajarilmadi (xato kodi: 20901). Iltimos, qayta urinib ko‘ring",
    "error_20902": "So‘rov bajarilmadi (xato kodi: 20902). Iltimos, qayta urinib ko‘ring",
    "error_21000": "O'zgarishlar saqlanadi",
    "error_21001": "Hech qanday ma'lumot berilmagan (xato kodi: 21001)",
    "error_21002": "So‘rov bajarilmadi (xato kodi: 21002). Iltimos, qayta urinib ko‘ring",
    "error_21101": "Iltimos, elektron pochtangizni kiriting (xato kodi: 21101)",
    "error_21102": "Elektron pochta noto‘g‘ri (xato kodi: 21102)",
    "error_21103": "So‘rov bajarilmadi (xato kodi: 21103), qayta urinib ko‘ring",
    "error_21104": "Elektron pochta allaqachon ulangan, yangisini ishlating",
    "error_21105": "So‘rov bajarilmadi (xato kodi: 21105), qayta urinib ko‘ring",
    "error_21106": "Email yuborib bo‘lmadi. Iltimos, qayta urinib ko‘ring",
    "error_21201": "Iltimos, elektron pochtangizni kiriting (xato kodi: 21201)",
    "error_21202": "Iltimos, tasdiqlash kodini kiriting (xato kodi: 21202)",
    "error_21203": "Elektron pochta noto‘g‘ri (xato kodi: 21203)",
    "error_21204": "Xato: 21204. Iltimos, biz bilan bog'laning",
    "error_21205": "Xato: 21205. Iltimos, biz bilan bog'laning",
    "error_21206": "8 ta belgidan ortiq parol talab qilinadi (xato kodi: 21206)",
    "error_21207": "So‘rov bajarilmadi (xato kodi: 21207). Iltimos, qayta urinib ko‘ring",
    "error_21209": "So‘rov bajarilmadi (xato kodi: 21209). Iltimos, qayta urinib ko‘ring",
    "error_21301": "Iltimos, eski parolni kiriting (xato kodi: 21301)",
    "error_21302": "Iltimos, yangi parol yarating (xato kodi: 21302)",
    "error_21303": "Yangi parol eskisi bilan bir xil bo'lishi mumkin emas. (Xato: 21303)",
    "error_21304": "8 ta belgidan ortiq parol talab qilinadi (xato kodi: 21304)",
    "error_21306": "So‘rov bajarilmadi (xato kodi: 21306). Iltimos, qayta urinib ko‘ring",
    "error_21402": "So‘rov bajarilmadi (xato kodi: 21402). Iltimos, qayta urinib ko‘ring",
    "error_21403": "Tasdiqlash kodini yuborib bo‘lmadi. Iltimos, uni qayta yuboring",
    "error_21500": "Hisob o'chirildi",
    "error_21501": "Iltimos, tasdiqlash kodini kiriting (xato kodi: 21501)",
    "error_21502": "Kirish sessiyasi tugadi (Xato: 21502). Iltimos, qayta kiring.",
    "error_21503": "So‘rov bajarilmadi (xato kodi: 21503). Iltimos, qayta urinib ko‘ring",
    "error_21505": "So‘rov bajarilmadi (xato kodi: 21505), qayta urinib ko‘ring",
    "error_21601": "Xato: 20601. Iltimos, biz bilan bog'laning",
    "error_21602": "Yaroqsiz tekshirish (Xato: 20602). Iltimos, qayta urinib ko‘ring.",
    "error_21603": "Xato: 20603. Qayta urinib ko‘ring",
    "error_21604": "So‘rov bajarilmadi (xato kodi: 21604). Iltimos, qayta urinib ko‘ring",
    "error_21606": "So‘rov bajarilmadi (xato kodi: 21606). Iltimos, qayta urinib ko‘ring",
    "error_21611": "So‘rov bajarilmadi (xato kodi: 21611). Iltimos, qayta urinib ko‘ring",
    "error_21801": "Xato: 21801. Iltimos, biz bilan bog'laning",
    "error_21802": "So‘rov bajarilmadi (Xato: 21802). Iltimos, qayta urinib ko‘ring",
    "error_21803": "Xato: 21803. Qayta urinib ko‘ring",
    "error_21804": "So‘rov bajarilmadi (xato kodi: 21804). Iltimos, qayta urinib ko‘ring",
    "error_21806": "Xato: 21806. Qayta urinib ko‘ring",
    "error_21807": "Xato: 21807. Iltimos, biz bilan bog'laning",
    "error_21808": "Xato: 21808. Iltimos, biz bilan bog'laning",
    "error_21809": "Xato: 21809. Iltimos, biz bilan bog'laning",
    "error_21810": "Xato: 21810. Iltimos, biz bilan bog'laning",
    "error_21811": "Xato: 21811. Iltimos, biz bilan bog'laning",
    "error_21812": "Xato: 21812. Iltimos, biz bilan bog'laning",
    "error_21813": "So‘rov bajarilmadi (xato kodi: 21813). Iltimos, qayta urinib ko‘ring",
    "error_21814": "Xato: 21814. Iltimos, biz bilan bog'laning",
    "error_21815": "So‘rov bajarilmadi (xato kodi: 21815). Iltimos, qayta urinib ko‘ring",
    "error_21816": "Xato: 21816. Iltimos, biz bilan bog'laning",
    "error_21817": "Xato: 21817. Iltimos, biz bilan bog'laning",
    "error_21818": "Xato: 21818. Iltimos, biz bilan bog'laning",
    "error_21819": "So‘rov bajarilmadi (xato kodi: 21819). Iltimos, qayta urinib ko‘ring",
    "error_21820": "Xato: 21820. Iltimos, biz bilan bog'laning",
    "error_21821": "Xato: 21821. Iltimos, biz bilan bog'laning",
    "error_21822": "Xato: 21822. Iltimos, biz bilan bog'laning",
    "error_21823": "So‘rov bajarilmadi (xato kodi: 21823). Iltimos, qayta urinib ko‘ring",
    "error_21824": "So‘rov bajarilmadi (xato kodi: 21824). Iltimos, qayta urinib ko‘ring",
    "error_21825": "So‘rov bajarilmadi (xato kodi: 21825). Iltimos, qayta urinib ko‘ring",
    "error_21826": "So‘rov bajarilmadi (xato kodi: 21826). Iltimos, qayta urinib ko‘ring",
    "error_21901": "Xato: 21901. Iltimos, biz bilan bog'laning",
    "error_21902": "So‘rov bajarilmadi (xato kodi: 21902). Iltimos, qayta urinib ko‘ring",
    "error_21903": "Hisob holati o'zgardi (xato kodi: 21903), sahifani yangilang va qaytadan urinib ko'ring",
    "error_21904": "Xato: 21904. Qayta urinib ko‘ring",
    "error_21905": "Xato: 21905. Qayta urinib ko‘ring",
    "error_21906": "So‘rov bajarilmadi (xato kodi: 21906). Iltimos, qayta urinib ko‘ring",
    "error_21907": "Google hisobi boshqa hisob bilan bog'langan",
    "error_21908": "So‘rov bajarilmadi (xato kodi: 21908). Iltimos, qayta urinib ko‘ring",
    "error_22001": "So‘rov bajarilmadi (xato kodi: 22001). Iltimos, qayta urinib ko‘ring",
    "error_22002": "Qo‘shimcha loginsiz aloqani uzib bo‘lmadi",
    "error_22003": "So‘rov bajarilmadi (xato kodi: 22003). Iltimos, qayta urinib ko‘ring",
    "error_22101": "Xato: 22101. Iltimos, biz bilan bog'laning",
    "error_22102": "Hisob holati o'zgardi (xato kodi: 22102), sahifani yangilang va qayta urinib ko'ring",
    "error_22103": "So‘rov bajarilmadi (xato kodi: 22103). Iltimos, qayta urinib ko‘ring",
    "error_22104": "Hisob holati o'zgardi (xato kodi: 22104), sahifani yangilang va qayta urinib ko'ring",
    "error_22105": "Xato: 22105. Qayta urinib ko‘ring",
    "error_22106": "Xato: 22106. Qayta urinib ko‘ring",
    "error_22107": "Xato: 22107. Iltimos, biz bilan bog'laning",
    "error_22108": "So‘rov bajarilmadi (xato kodi: 22108). Iltimos, qayta urinib ko‘ring",
    "error_22201": "Xato: 22201. Iltimos, biz bilan bog'laning",
    "error_22202": "Yaroqsiz tekshirish (Xato: 22202). Iltimos, qayta urinib ko‘ring.",
    "error_22203": "Xato: 22203. Iltimos, qayta urinib ko'ring\"",
    "error_22204": "So‘rov bajarilmadi (xato kodi: 22204). Iltimos, qayta urinib ko‘ring",
    "error_22206": "So‘rov bajarilmadi (xato kodi: 22206). Iltimos, qayta urinib ko‘ring",
    "error_22401": "Xato: 22401. Iltimos, biz bilan bog'laning",
    "error_22402": "Yaroqsiz tekshirish (Xato: 22402). Iltimos, qayta urinib ko‘ring.",
    "error_22403": "Xato: 22403. Qayta urinib ko‘ring",
    "error_22404": "So‘rov bajarilmadi (xato kodi: 22404). Iltimos, qayta urinib ko‘ring",
    "error_22405": "Facebook akkaunti boshqa elektron pochtaga ulangan",
    "error_22406": "Xato: 22406. Qayta urinib ko‘ring",
    "error_22407": "Xato: 22407. Iltimos, biz bilan bog'laning",
    "error_22408": "Xato: 22408. Iltimos, biz bilan bog'laning",
    "error_22409": "Xato: 22409. Iltimos, biz bilan bog'laning",
    "error_22410": "Xato: 224010. Iltimos, biz bilan bog'laning",
    "error_22411": "Xato: 224011. Iltimos, biz bilan bog'laning",
    "error_22412": "Xato: 224012. Iltimos, biz bilan bog'laning",
    "error_22413": "Xato: 22413. Iltimos, biz bilan bog'laning",
    "error_22414": "So‘rov bajarilmadi (xato kodi: 22414). Iltimos, qayta urinib ko‘ring",
    "error_22415": "Xato: 22415. Iltimos, biz bilan bog'laning",
    "error_22416": "Xato: 22416. Iltimos, biz bilan bog'laning",
    "error_22417": "Xato: 22417. Iltimos, biz bilan bog'laning",
    "error_22418": "So‘rov bajarilmadi (xato kodi: 22418). Iltimos, qayta urinib ko‘ring",
    "error_22419": "Xato: 22419. Iltimos, biz bilan bog'laning",
    "error_22420": "Xato: 22420. Iltimos, biz bilan bog'laning",
    "error_22421": "Xato: 22421. Iltimos, biz bilan bog'laning",
    "error_22422": "So‘rov bajarilmadi (xato kodi: 22422). Iltimos, qayta urinib ko‘ring",
    "error_22423": "So‘rov bajarilmadi (xato kodi: 22423). Iltimos, qayta urinib ko‘ring",
    "error_22424": "So‘rov bajarilmadi (xato kodi: 22424). Iltimos, qayta urinib ko‘ring",
    "error_22425": "So‘rov bajarilmadi (xato kodi: 22425). Iltimos, qayta urinib ko‘ring",
    "error_20098": "Xato kodi: 20098. Agar siz Shaxsiy rejimda koʻrayotgan boʻlsangiz, Oddiy rejimga oʻting va qaytadan urinib koʻring.",
    "error_22298": "Google so'rovi bajarilmadi (Xato: 22298). Iltimos, qayta urinib ko‘ring.",
    "error_22498": "Facebook so'rovi bajarilmadi (Xato: 22498). Iltimos, qayta urinib ko‘ring.",
    "error_24902": "So‘rov bajarilmadi (xato kodi: 24902). Iltimos, qayta urinib ko‘ring",
    "error_24903": "So‘rov bajarilmadi (xato kodi: 24903). Iltimos, qayta urinib ko‘ring",
    "error_24904": "So‘rov bajarilmadi (xato kodi: 24904). Iltimos, qayta urinib ko‘ring",
    "error_24905": "So‘rov bajarilmadi (xato kodi: 24905). Iltimos, qayta urinib ko‘ring",
    "login_title": "MindOnMap-ga kiring",
    "log_in": "Tizimga kirish",
    "no_account": "Hisob yo'qmi?",
    "create_it": "Uni yarating",
    "or_log_in_with": "Yoki bilan tizimga kiring",
    "passwordless_login": "Parolsiz kirish",
    "log_in_done": "Kirish Bajarildi",
    "three_rd_account_connect_info": "Tabriklaymiz! Siz muvaffaqiyatli tizimga kirdingiz. Endi siz kelajakda tizimga kirish uchun foydalaniladigan bitta elektron pochta qayd yozuvini ulashingiz mumkin.",
    "see_my_account": "Mening hisobimni ko'ring",
    "three_rd_login_merge_account": "Uchinchi tomon hisobining elektron pochta manzili ro'yxatdan o'tgan, siz to'g'ridan-to'g'ri ushbu elektron pochta manzili bilan ulanishni va tizimga kirishni xohlaysizmi?",
    "connect_log_in": "Ulanish va tizimga kiring",
    "create_an_account": "Hisob ochish",
    "back_to_log_in": "Kirish uchun qayting",
    "create_password": "Parol yaratish",
    "create_now": "Hozir yaratish",
    "password_login_subtitle": "Elektron pochta orqali parolsiz kirish",
    "account_login": "Hisobga kirish",
    "rights": "Ushbu hisobni yaratish orqali siz <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Foydalanish shartlari</a> va <a href=\"https://www.mindonmap.com/privacy-policy/\">Maxfiylik siyosati</a>",
    "passwordless_login_done": "Parolsiz kirish amalga oshirildi",
    "passwordless_login_info": "Tabriklaymiz, siz parolsiz kirishni muvaffaqiyatli yakunladingiz. Siz ushbu hisob uchun parol yaratishingiz va kelajakda hisob va parol bilan tizimga kirishingiz mumkin. <a href=\"/create-password\" style=\"display: initial;\">Hozir yaratish</a>",
    "sign_up": "Ro'yxatdan o'tish",
    "register_info": "Hisob qaydnomangizni yarating",
    "reset_now": "Hozir asl holatiga qaytarish",
    "forgot_password": "Parolni unutdingizmi",
    "reset_password_subtitle": "Parolni tiklash uchun hisob qaydnomangizdan foydalaning",
    "plan_products": "Rejalar va mahsulotlar",
    "nick_name": "Foydalanuvchi nomi:",
    "email": "Email:",
    "my_products": "Mening mahsulotlarim",
    "my_orders": "Mening Buyurtmalarim",
    "unlink": "Aloqani uzish",
    "link": "Havola",
    "connected_accounts": "Ulangan hisoblar",
    "last_name": "Familiya:",
    "first_name": "Ism:",
    "Gender": "Jins:",
    "Birth": "Tug'ilgan:",
    "Month": "Oy",
    "Year": "Yil",
    "Country_Region": "Mamlakat/mintaqa:",
    "Address": "Manzil:",
    "Save": "Saqlash",
    "Date": "Sana",
    "Male": "Erkak",
    "Female": "Ayol",
    "Unspecified": "Aniqlanmagan",
    "Security": "Xavfsizlik",
    "change_password": "Parolni o'zgartirish",
    "change_now": "Hozir o'zgartiring",
    "connect_email": "Elektron pochtani ulang",
    "delete_account": "Hisobni o'chirish",
    "delete_account_info": "Hisobingiz o'chirilganda, hisobingiz bilan bog'langan \"Mening hisobim\" bo'limidagi barcha ma'lumotlar butunlay o'chiriladi va siz ularni qayta tiklay olmasligingiz mumkin. Ehtiyotkorlik bilan yurishingizni tavsiya qilamiz.",
    "Delete": "Oʻchirish",
    "Logout": "Chiqish",
    "my_profile": "Mening profilim",
    "guides_faqs": "Qoʻllanmalar va tez-tez soʻraladigan savollar",
    "More": "Ko'proq",
    "guides": "Qo'llanmalar",
    "register": "ro'yxatdan o'tish",
    "hot_faq": "Tez-tez so'raladigan savollar",
    "Contents": "Tarkib:",
    "contact_us": "Biz bilan bog'laning>>",
    "plan": "Reja",
    "unregistered": "Roʻyxatdan oʻtmagan",
    "buy_more": "Ko'proq sotib oling",
    "buy_again": "Qayta sotib oling",
    "buy_now": "Hozir xarid qilish",
    "free_no_limit": "Bepul va cheksiz",
    "expired": "Muddati tugagan",
    "lifetime": "Muddat",
    "remain": "Qoling",
    "day_s": "kun(lar)",
    "error_24801": "So‘rov bajarilmadi (xato kodi: 24801). Iltimos, qayta urinib ko‘ring",
    "no_app_found": "Hech qanday ilova topilmadi!<a href=\"/\">Yangilang</a> yoki <a href=\"https://www.mindonmap.com/\">Rasmiy veb-saytga o‘ting</a>",
    "get_more": "Ko'proq ma'lumot oling >>",
    "edit_photo": "Rasmni tahrirlash",
    "select_photo": "Fotosuratni tanlang",
    "change_photo": "Suratni o'zgartirish",
    "cancel": "Bekor qilish",
    "hide_password": "Parolni yashirish",
    "show_password": "Parolni ko'rsatish",
    "zoom_in": "Kattalashtirish",
    "zoom_out": "Kichraytirish",
    "rotate": "Aylantirish",
    "horizontal_flip": "Gorizontal burilish",
    "vertical_flip": "Vertikal burilish",
    "country": "Mamlakat",
    "country_1": "Mamlakatingiz/mintaqangizni tanlang",
    "country_2": "Aaland oroli",
    "country_3": "Afg'oniston",
    "country_4": "Albaniya",
    "country_5": "Jazoir",
    "country_6": "Amerika Samoasi",
    "country_7": "Andorra",
    "country_8": "Angola",
    "country_9": "Anguilla",
    "country_10": "Antarktida",
    "country_11": "Antigua va Barbuda",
    "country_12": "Argentina",
    "country_13": "Armaniston",
    "country_14": "Aruba",
    "country_15": "Avstraliya",
    "country_16": "Avstriya",
    "country_17": "Ozarbayjon",
    "country_18": "Bahrayn",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Belarus",
    "country_22": "Belgiya",
    "country_23": "Beliz",
    "country_24": "Benin",
    "country_25": "Bermud orollari",
    "country_26": "Butan",
    "country_27": "Boliviya",
    "country_28": "Bosniya va Gertsegovina",
    "country_29": "Botsvana",
    "country_30": "Buvet oroli",
    "country_31": "Braziliya",
    "country_32": "Britaniya Hind Okean hududi",
    "country_33": "Britaniya Virgin orollari",
    "country_34": "Bruney",
    "country_35": "Bolgariya",
    "country_36": "Burkina",
    "country_37": "Burundi",
    "country_38": "Kambodja",
    "country_39": "Kamerun",
    "country_40": "Kanada",
    "country_41": "Kabo-Verde",
    "country_42": "Karib dengizi Gollandiya",
    "country_43": "Kayman orollari",
    "country_44": "Markaziy Afrika Respublikasi",
    "country_45": "Chad",
    "country_46": "Chili",
    "country_47": "Xitoy",
    "country_48": "Rojdestvo oroli",
    "country_49": "Kokos (Kiling) orollari",
    "country_50": "Kolumbiya",
    "country_51": "Kuk orollari",
    "country_52": "Kosta-Rika",
    "country_53": "Kot-d'Ivuar",
    "country_54": "Xorvatiya",
    "country_55": "Kuba",
    "country_56": "Kipr",
    "country_57": "Chex Respublikasi",
    "country_58": "Kongo Demokratik Respublikasi",
    "country_59": "Daniya",
    "country_60": "Jibuti",
    "country_61": "Dominika",
    "country_62": "Dominika Respublikasi",
    "country_63": "Ekvador",
    "country_64": "Misr",
    "country_65": "Salvador",
    "country_66": "Ekvatorial Gvineya",
    "country_67": "Eritreya",
    "country_68": "Estoniya",
    "country_69": "Efiopiya",
    "country_70": "Folklend orollari",
    "country_71": "Farer orollari",
    "country_72": "Mikroneziya Federativ shtatlari",
    "country_73": "Fiji",
    "country_74": "Finlyandiya",
    "country_75": "Fransiya",
    "country_76": "Fransuz Gvianasi",
    "country_77": "Fransuz polineziyasi",
    "country_78": "Fransuz Janubiy hududi",
    "country_79": "Gabon",
    "country_80": "Gambiya",
    "country_81": "Gruziya",
    "country_82": "Germaniya",
    "country_83": "Gana",
    "country_84": "Gibraltar",
    "country_85": "Buyuk Britaniya (Birlashgan Qirollik; Angliya)",
    "country_86": "Gretsiya",
    "country_87": "Grenlandiya",
    "country_88": "Grenada",
    "country_89": "Gvadelupa",
    "country_90": "Guam",
    "country_91": "Gvatemala",
    "country_92": "Gernsi",
    "country_93": "Gvineya",
    "country_94": "Gvineya-Bisau",
    "country_95": "Gayana",
    "country_96": "Gaiti",
    "country_97": "Heard oroli va MakDonald orollari",
    "country_98": "Gonduras",
    "country_99": "Gonkong",
    "country_100": "Vengriya",
    "country_101": "Islandiya",
    "country_102": "Hindiston",
    "country_103": "Indoneziya",
    "country_104": "Eron",
    "country_105": "Iroq",
    "country_106": "Irlandiya",
    "country_107": "Men oroli",
    "country_108": "Isroil",
    "country_109": "Italiya",
    "country_110": "Yamayka",
    "country_111": "Yaponiya",
    "country_112": "Jersi",
    "country_113": "Iordaniya",
    "country_114": "Qozog'iston",
    "country_115": "Keniya",
    "country_116": "Kiribati",
    "country_117": "Quvayt",
    "country_118": "Qirg'iziston",
    "country_119": "Laos",
    "country_120": "Latviya",
    "country_121": "Livan",
    "country_122": "Lesoto",
    "country_123": "Liberiya",
    "country_124": "Liviya",
    "country_125": "Lixtenshteyn",
    "country_126": "Litva",
    "country_127": "Lyuksemburg",
    "country_128": "Makao",
    "country_129": "Madagaskar",
    "country_130": "Malavi",
    "country_131": "Malayziya",
    "country_132": "Maldiv orollari",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Marshall orollari",
    "country_136": "Martinika",
    "country_137": "Mavritaniya",
    "country_138": "Mavrikiy",
    "country_139": "Mayotte",
    "country_140": "Meksika",
    "country_141": "Moldova",
    "country_142": "Monako",
    "country_143": "Mo'g'uliston",
    "country_144": "Chernogoriya",
    "country_145": "Montserrat",
    "country_146": "Marokash",
    "country_147": "Mozambik",
    "country_148": "Myanma (Birma)",
    "country_149": "Namibiya",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "Niderlandiya",
    "country_153": "Yangi Kaledoniya",
    "country_154": "Yangi Zelandiya",
    "country_155": "Nikaragua",
    "country_156": "Niger",
    "country_157": "Nigeriya",
    "country_158": "Niue",
    "country_159": "Norfolk oroli",
    "country_160": "Shimoliy Koreya",
    "country_161": "Shimoliy Mariana orollari",
    "country_162": "Norvegiya",
    "country_163": "Ummon",
    "country_164": "Pokiston",
    "country_165": "Palau",
    "country_166": "Falastin hududlari",
    "country_167": "Panama",
    "country_168": "Papua Yangi Gvineya",
    "country_169": "Paragvay",
    "country_170": "Peru",
    "country_171": "Pitkern orollari",
    "country_172": "Polsha",
    "country_173": "Portugaliya",
    "country_174": "Puerto Riko",
    "country_175": "Qatar",
    "country_176": "Makedoniya Respublikasi (FYROM)",
    "country_177": "Kongo Respublikasi",
    "country_178": "Reunion",
    "country_179": "Ruminiya",
    "country_180": "Rossiya Federatsiyasi",
    "country_181": "Ruanda",
    "country_182": "Sent-Bartelemi",
    "country_183": "Sent Martin (Frantsiya)",
    "country_184": "Sent-Pyer va Mikelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "San-Tome va Prinsipi",
    "country_188": "Saudiya Arabistoni",
    "country_189": "Senegal",
    "country_190": "Serbiya",
    "country_191": "Seyshel orollari",
    "country_192": "Syerra Leone",
    "country_193": "Singapur",
    "country_194": "Slovakiya",
    "country_195": "Sloveniya",
    "country_196": "Solomon orollari",
    "country_197": "Somali",
    "country_198": "Janubiy Afrika",
    "country_199": "Janubiy Gruziya va Janubiy Sendvich oroli ",
    "country_200": "Janubiy Koreya",
    "country_201": "Janubiy Sudan",
    "country_202": "Ispaniya",
    "country_203": "Shri Lanka",
    "country_204": "Sent-Yelena va bog'liqlar",
    "country_205": "Sent-Kitts va Nevis",
    "country_206": "Sent-Lusiya",
    "country_207": "Sent-Vinsent va Grenadinlar",
    "country_208": "Sudan",
    "country_209": "Surinam",
    "country_210": "Svazilend",
    "country_211": "Shvetsiya",
    "country_212": "Shveytsariya",
    "country_213": "Suriya",
    "country_214": "Tayvan",
    "country_215": "Tojikiston",
    "country_216": "Tanzaniya",
    "country_217": "Andoza:Mamlakat maʼlumotlari SJM Svalbard",
    "country_218": "Tailand",
    "country_219": " Bagama orollari",
    "country_220": " Komor orollari",
    "country_221": " Filippin",
    "country_222": "Timor-Sharqiy (Sharqiy Timor)",
    "country_223": "Bormoq",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad va Tobago",
    "country_227": "Tunis",
    "country_228": "kurka",
    "country_229": "Turkmaniston",
    "country_230": "Turks va Kaykos orollari",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Birlashgan Arab Amirliklari",
    "country_235": "Qo'shma Shtatlar Kichik chet orollari",
    "country_236": "Amerika qoʻshma Shtatlari (AQSh)",
    "country_237": "Amerika Qoʻshma Shtatlari Virjiniya orollari",
    "country_238": "Urugvay",
    "country_239": "O'zbekiston",
    "country_240": "Vanuatu",
    "country_241": "Vatikan Siti (  Muqaddas                   ",
    "country_242": "Venesuela",
    "country_243": "Vetnam",
    "country_244": "Uollis va Futuna",
    "country_245": "Gʻarbiy Saxara",
    "country_246": "Yaman",
    "country_247": "Zambiya",
    "country_248": "Zimbabve",
    "google_login": "Google bilan tizimga kiring",
    "State": "Davlat",
    "Activation_code": "Faollashtirish kodi",
    "Question": "Tizimga kirgan barcha ilovalarni ro'yxatlang",
    "Copy_complete": "Nusxalash tugallandi",
    "footer": "Mualliflik huquqi © 2024 MindOnMap Studio. Barcha huquqlar himoyalangan.",
    "change_password_success": "Parol muvaffaqiyatli almashtirildi",
    "successful_login_title": "Kirish muvaffaqiyatli",
    "product_page": "Mahsulot sahifasi>>",
    "successful_login_info": "Kirish tugallandi. Iltimos, joriy sahifani yoping va keyingi jarayonni davom ettirish uchun asl sahifaga qayting. Joriy sahifa 5 soniyadan keyin avtomatik ravishda yopiladi. Agar “Bajarildi” tugmasini bosish orqali avtomatik yopilish yoki yopish muvaffaqiyatsiz tugasa, ushbu yorliqni to‘g‘ridan-to‘g‘ri yoping.",
    "successful_login_info_firefox": "Kirish tugallandi. Iltimos, joriy sahifani yoping va keyingi jarayonni davom ettirish uchun asl sahifaga qayting.",
    "my_account": "Mening hisobim",
    "my_history": "Mening tarixim",
    "remove_watermark": "Suv belgisini olib tashlang",
    "no_history": "Tarix yo'q",
    "history_all": "Hammasini belgilash",
    "history_open": "Ochiq",
    "history_down": "Yuklab olish",
    "history_delete": "Oʻchirish",
    "history_clear": "Yaroqsiz",
    "images": "tasvir(lar)",
    "use_this_function": "Ushbu mahsulotdan foydalaning>>",
    "hd_downloading": "HD original tasvirlarni yuklab olishning afzalliklari:",
    "lifetimeRemaining": "Qolgan umr",
    "Remaining": "Qolgan",
    "email_verification": "Elektron pochtani tekshirish",
    "email_verification_info": "Tasdiqlash xabarini <span class=\"email\"></span> e-pochtangizga yubordik va tekshirishni yakunlang. Tekshiruvdan so'ng imtiyozlar avtomatik ravishda sinxronlanadi.",
    "wrong_email": "Elektron pochta manzili noto'g'ri?",
    "click_here_to_modify": "Oʻzgartirish uchun shu yerni bosing",
    "get_more_help": "Koʻproq yordam olasizmi?",
    "click_here": "bu yerni bosing",
    "email_verification_info_success": "Email hisobingizni tasdiqlaganingiz uchun tabriklaymiz.",
    "email_verification_info_error": "Havola muddati o‘tgani uchun tekshirish amalga oshmadi.",
    "registration_succeeded": "Roʻyxatdan oʻtish muvaffaqiyatli boʻldi",
    "registration_succeeded_info_1": "Tabriklaymiz! Siz roʻyxatdan oʻtdingiz. Tasdiqlash xabarini <span class=\"email\"></span> e-pochtangizga yubordik va ushbu xatning afzalliklaridan foydalanish uchun tasdiqlashni yakunlang.",
    "registration_succeeded_info_2": "Bosh sahifaga qaytish va ushbu mahsulotdan foydalanish uchun “Bajarildi” tugmasini bosing.",
    "registration_succeeded_info_3": "Joriy sahifani yopish va quyidagi operatsiyalar uchun asosiy sahifaga qaytish uchun \"Bajarildi\" tugmasini bosing. Agar yopilmasa, ilovani qo'lda yoping.",
    "verify_email": "Elektron pochtani tasdiqlang",
    "registered_email_not_verified": "Roʻyxatdan oʻtgan elektron pochta manzili tasdiqlanmagan, uni darhol tasdiqlang.",
    "email_verification_time_1": "Tasdiqlash xatini olmadingizmi?",
    "email_verification_time_2": "Keyin",
    "email_verification_time_3": "Qayta yuborish uchun shu yerni bosing",
    "error_26301": "Xato kodi: 26301, biz bilan bog'laning",
    "error_26302": "Xato kodi: 26302, biz bilan bog'laning",
    "error_26303": "Elektron pochta formatida xatolik (xato kodi: 26303). Iltimos, yana kiriting",
    "error_26304": "8 ta belgidan ortiq parol tavsiya etiladi (xato kodi: 26304)",
    "error_26305": "Reuqest bajarilmadi (xato kodi: 26305). Qayta urinib ko‘ring",
    "error_26306": "Elektron pochta ro‘yxatdan o‘tkazildi, iltimos <a href=\"https://account.mindonmap.com/login/\">kirish uchun</a>",
    "error_26307": "Reuqest bajarilmadi (xato kodi: 26307). Qayta urinib ko‘ring",
    "error_26308": "Reuqest bajarilmadi (xato kodi: 26308). Qayta urinib koʻring",
    "error_26401": "Xato kodi: 26401, qayta urinib koʻring",
    "error_26402": "Elektron pochta tasdiqlandi (xato kodi: 26402), qayta urinib ko‘ring",
    "error_26403": "Reuqest bajarilmadi (xato kodi: 26403). Qayta urinib ko‘ring",
    "error_26404": "So‘rov bajarilmadi (xato kodi: 26404). Qayta urinib ko‘ring",
    "error_26501": "Xato kodi: 26501, biz bilan bog'laning",
    "error_26502": "Xato kodi: 26502, biz bilan bog'laning",
    "error_26503": "Elektron pochta formatida xatolik (xato kodi: 26503). Iltimos, yana kiriting",
    "error_26504": "Reuqest bajarilmadi (xato kodi: 26504). Qayta urinib ko‘ring",
    "error_26505": "E-pochta ro‘yxatdan o‘tkazilmagan, iltimos, <a href=\"https://account.mindonmap.com/register/\">avval uni ro‘yxatdan o‘tkazing</a>",
    "error_26506": "Elektron pochta tasdiqlandi.",
    "error_26507": "Reuqest bajarilmadi (xato kodi: 26507). Qayta urinib ko‘ring",
    "error_26508": "Tasdiqlash amalga oshirildi (xato kodi: 26508), qayta urinib koʻring",
    "error_26509": "So‘rov bajarilmadi (xato kodi: 26509), qayta urinib ko‘ring",
    "error_26510": "Xato kodi: 26510, biz bilan bog'laning",
    "error_26601": "Xato kodi: 26601, biz bilan bog'laning",
    "error_26602": "Xato kodi: 26602, biz bilan bog'laning",
    "error_26603": "Reuqest bajarilmadi (xato kodi: 26603). Qayta urinib ko‘ring",
    "error_26604": "Xato kodi: 26604, biz bilan bog'laning",
    "error_26605": "Xato kodi: 26605, biz bilan bog'laning",
    "error_26701": "Xato kodi: 26701, biz bilan bog'laning",
    "error_26702": "Reuqest bajarilmadi (xato kodi: 26702). Qayta urinib koʻring",
    "error_26703": "Xato kodi: 26703, biz bilan bog'laning",
    "error_26704": "Xato kodi: 26704, biz bilan bog'laning",
    "error_26705": "Tizimga kirishni kuting (xato kodi: 26705). Qayta urinib ko‘ring",
    "no_cookie": "Siz brauzeringizda “Barcha cookie-fayllarni bloklash” funksiyasini yoqdingiz, shuning uchun tizimga kira olmaysiz. “Barcha cookie-fayllarga ruxsat berish” katagiga belgi qo‘yish uchun Sozlamalarga o‘ting.",
    "error_26801": "Xato kodi: 26801, biz bilan bog'laning",
    "error_26802": "Xato kodi: 26802, biz bilan bog'laning",
    "error_26803": "So‘rov bajarilmadi (xato kodi: 26803). Qayta urinib ko‘ring",
    "error_26804": "So‘rov bajarilmadi (xato kodi: 26804). Qayta urinib ko‘ring",
    "error_order": "So‘rov bajarilmadi (xato kodi: 27098), qaytadan urinib ko‘ring!",
    "error_order1": "Buyurtma so'rovi to'liq emas（xato kodi: ",
    "error_order2": "）, iltimos, yangilang va qaytadan urinib ko'ring.",
    "modify_email_title": "Elektron pochtani o'zgartirish",
    "modify_email_info": "Hisobingizga kirish uchun o'zgartirilgan elektron pochtadan foydalanishingiz mumkin.",
    "images_per": "Tasvirlar boshiga",
    "error_26101": "Xato: 26101. Iltimos, biz bilan bog'laning",
    "error_26102": "Xato: 26102. Iltimos, biz bilan bog'laning",
    "error_26103": "Soʻrov bajarilmadi (xato kodi: 26103). Iltimos, qayta sinab ko'ring",
    "error_26104": "Xato kodi: 26104, qayta urinib ko'ring",
    "error_26105": "Xato kodi: 26105, qayta urinib ko'ring",
    "error_26106": "Oʻchirilmadi (xato kodi: 26106). Iltimos, qayta urinib ko‘ring",
    "error_26201": "Xato: 26201. Iltimos, biz bilan bog'laning",
    "error_26202": "Soʻrov bajarilmadi (xato kodi: 26202). Iltimos, qayta sinab ko'ring",
    "error_26001": "Xato: 26001. Iltimos, biz bilan bog'laning",
    "error_26002": "Xato: 26002. Iltimos, biz bilan bog'laning",
    "error_26003": "Xato: 26003. Iltimos, biz bilan bog'laning",
    "error_26004": "Xato: 26004. Iltimos, biz bilan bog'laning",
    "error_26005": "Soʻrov bajarilmadi (xato kodi: 26005). Iltimos, qayta sinab ko'ring",
    "error_26006": "Xato kodi: 26006, qayta urinib ko'ring",
    "error_26008": "Xato: 26008. Iltimos, biz bilan bog'laning",
    "go_to_the_home_page": "Bosh sahifaga o'ting",
    "error_27101": "So‘rov bajarilmadi (xato kodi: 27101). Iltimos, qayta urinib ko'ring",
    "error_27201": "Xato kodi: 27201, biz bilan bog'laning",
    "error_27202": "Xato kodi: 27202, qayta urinib koʻring",
    "error_27203": "So‘rov bajarilmadi (xato kodi: 27203). Qayta urinib koʻring",
    "error_27204": "Yaroqsiz kod (xato kodi: 27204).",
    "error_27205": "So‘rov bajarilmadi (xato kodi: 27205). Qayta urinib koʻring",
    "error_27206": "So‘rov bajarilmadi (xato kodi: 27206). Qayta urinib ko‘ring",
    "error_27207": "So‘rov bajarilmadi (xato kodi: 27207). Qayta urinib koʻring",
    "no_history_found": "Siz hech qanday vositadan foydalanmadingiz! <a href=\"/\">Yangilang</a> yoki <a href=\"https://www.mindonmap.com/\">Rasmiy veb-saytga o‘ting</a>",
    "error_25301": "Xato: 25301. Iltimos, biz bilan bog'laning",
    "error_25302": "Xato: 25302. Iltimos, biz bilan bog'laning",
    "error_25303": "Soʻrov bajarilmadi (xato kodi: 25303). Iltimos, qayta urinib ko‘ring",
    "error_25304": "Soʻrov bajarilmadi (xato kodi: 25304). Iltimos, qayta urinib ko‘ring",
    "error_25305": "Soʻrov bajarilmadi (xato kodi: 25305). Iltimos, qayta urinib ko‘ring",
    "error_25306": "Soʻrov bajarilmadi (xato kodi: 25306). Iltimos, qayta urinib ko‘ring",
    "image_upscaler_p": "Moviy belgisiz tasvirni yuklab olishning afzalliklari:",
    "Available_for": "Quyidagilar uchun mavjud:",
    "credit_per": "Har bir HD tasvir uchun %s kredit(lar).",
    "still_valid": "Xarid qilingan reja(lar) hali ham amal qiladi",
    "credit": "kredit(lar)",
    "pc_3rd_info": "Kirish muvaffaqiyatli. Iltimos, keyingi operatsiya uchun ilovaga o'ting.",
    "use_online": "Onlayn xizmatdan foydalaning",
    "use_download": "Ish stoli dasturidan foydalaning",
    "use_immediately": "Darhol foydalaning",
    "Use_in_browser": "Brauzerda foydalaning",
    "win_desktop": "Windows",
    "Mac_desktop": "Mac",
    "credits_per": "Oyiga {%} kredit",
    "expire": "Yaroqlilik muddati:",
    "viewDetails": "Tafsilotlarni ko'rish",
    "viewHistory": "Tarix imtiyozlarini ko'rish>>",
    "viewDetailsInfo": "Eslatma: Agar obuna imtiyozlari muddati tugaganidan keyin 7 kun ichida yangilansa, foydalanilmagan imtiyozlardan foydalanishda davom etishi mumkin. Shuningdek, amal qilish muddati avtomatik ravishda yangi obunaning amal qilish muddatiga yangilanadi. Agar 7 kunlik muddat tugaganidan keyin yangi obuna bo'lmasa, muddati o'tgan barcha imtiyozlar o'chiriladi.",
    "connect_account": "Elektron pochtani hisobingizga bog'lang",
    "connect_account_info": "Bog'langandan so'ng siz ushbu elektron pochta manzili bilan tizimga kirishingiz mumkin.",
    "connect_now": "Hozir bog'lash",
    "no_email_bind": "Elektron pochta aloqasi yo'q",
    "bind_email": "Elektron pochtani bog'lash",
    "connect_your_email_placeholder": "Iltimos, elektron pochta manzilingizni kiriting",
    "bind_an_email": "Elektron pochtani bog'lash",
    "bind_info": "Siz muvaffaqiyatli tizimga kirdingiz. Imtiyozlaringizni faollashtirish uchun elektron pochta manzilingizni hisobingizga bog'lang.",
    "bind_later": "Keyinroq bog'lang",
    "hi": "Salom, %s!",
    "Personal_Information": "Shaxsiy ma'lumot",
    "Access": "Kirish",
    "Subscription_Plan": "(Obuna rejasi)",
    "No_orders": "Hech qanday buyurtma topilmadi.",
    "No_data": "Maʼlumot yoʻq",
    "Featured_Products": "Tanlangan mahsulotlar",
    "More_Products": "Ko'proq mahsulotlar",
    "Free_Download": "Bepul Yuklash",
    "Get_Started": "Boshlash",
    "Subscribe": "Obuna boʻling",
    "Verified": "Tasdiqlangan",
    "back_to_account_center": "Hisob markaziga qaytish",
    "success": "Muvaffaqiyat!",
    "successfully": "Siz hisobni muvaffaqiyatli ro'yxatdan o'tkazdingiz.",
    "Continue": "Davom eting",
    "Already": "Hisobingiz bormi?",
    "loading_verification": "Tasdiqlash holati tekshirilmoqda...",
    "email_no_verification": "Kechirasiz, roʻyxatdan oʻtgan elektron pochta manzili tasdiqlanmagan. Yuqoridagi koʻrsatmalarga muvofiq tekshirishni yakunlang va roʻyxatdan oʻtishni yakunlash uchun “Bajarildi” tugmasini yana bosing.",
    "will_expire_after": "Muddati keyin tugaydi",
    "hours": "soat",
  },
  "vi": {
    "overtime": "Mã lỗi: {%}, vui lòng thử lại",
    "isnetwork": "Lỗi Internet. Vui lòng kiểm tra và thử lại",
    "email_placeholder": "E-mail",
    "email_empty": "Vui lòng nhập email",
    "email_not_valid": "Email không hợp lệ",
    "email_not_valid_1": "Vui lòng nhập email của bạn",
    "email_not_valid_2": "Email không hợp lệ, vui lòng sử dụng địa chỉ khác.",
    "email_not_valid_3": "Không có email đầu vào",
    "password_placeholder": "Mật khẩu",
    "password_empty": "Vui lòng nhập mật khẩu",
    "password_not_valid": "Tài khoản hoặc mật khẩu không chính xác",
    "password_not_valid_1": "Yêu cầu mật khẩu trên 8 ký tự",
    "password_not_valid_2": "Vui lòng tạo mật khẩu",
    "password_placeholder_1": "tạo mật khẩu",
    "password_placeholder_2": "Xác nhận mật khẩu của bạn",
    "password_placeholder_3": "Tạo mật khẩu mới",
    "password_placeholder_4": "Xác nhận mật khẩu mới",
    "password_placeholder_5": "Nhập mật khẩu cũ",
    "copy_password_empty": "Vui lòng xác nhận mật khẩu",
    "copy_password_not_valid": "Vui lòng xác nhận mật khẩu của bạn",
    "copy_passwords_inconsistent": "Xác nhận mật khẩu của bạn không khớp",
    "code_empty": "Vui lòng nhập mã xác minh",
    "code_not_valid": "Mã xác minh không hợp lệ",
    "code_placeholder": "Mã xác nhận",
    "not_received_code": "Nếu hộp thư của bạn lâu rồi chưa nhận được mã xác minh, vui lòng lấy lại mã xác minh.",
    "get_first_code": "Vui lòng lấy mã xác minh trước.",
    "last_name_placeholder": "Vui lòng nhập họ của bạn",
    "first_name_placeholder": "Vui lòng nhập tên của bạn",
    "address_placeholder": "Vui lòng nhập địa chỉ của bạn",
    "no_code_text": "Chúng tôi đã gửi mã xác minh. Vui lòng nhập mã của bạn. <span class='tips'>Không nhận được mã?",
    "no_code_text_1": "1. Vui lòng đảm bảo địa chỉ email hợp lệ và có thể nhận email.",
    "no_code_text_2": "2. Đối với email được hệ thống gửi tự động, có thể bị gắn cờ là email rác, email rác. Vui lòng kiểm tra xem email có nằm trong thư mục Thùng rác hay không.",
    "no_code_text_3": "3. Không thể giải quyết vấn đề của bạn? ",
    "no_code_text_3_span": "Sau đó bấm vào đây để liên hệ với chúng tôi.",
    "order_no": "Bạn chưa mua bất kỳ sản phẩm nào, Nếu bạn có bất kỳ câu hỏi nào, vui lòng <a href=\"https://www.mindonmap.com/contact-us/\">liên hệ với chúng tôi</a>.",
    "error_24901": "Tài khoản hiện tại không có email liên kết và không thể tìm thấy đơn hàng. Vui lòng kết nối một email.",
    "user_guide": "Hướng dẫn sử dụng>>",
    "download": "Tải xuống",
    "order_number": "Số thứ tự:",
    "Refund": "Đền bù",
    "Disabled": "Tàn tật",
    "Normal": "Bình thường",
    "Modify": "Biến đổi",
    "Modify_1": "Sửa đổi>>",
    "Connect": "Kết nối",
    "unlink_success": "Hủy liên kết thành công",
    "connect_success": "Kết nối thành công",
    "feedback_title": "Cảm ơn phản hồi của bạn. Vui lòng để lại vấn đề của bạn và chúng tôi sẽ trả lời bạn trong vòng 24 giờ.",
    "feedback_thank_you": "Cảm ơn bạn!<br />Phản hồi của bạn đã được gửi thành công.",
    "feedback_email": "Ghi địa chỉ email của bạn ở đây!",
    "feedback_content": "Để lại bất kỳ vấn đề hoặc gợi ý nào bạn gặp phải ở đây.",
    "feedback_submit": "Nộp",
    "form_contents": "Bạn chưa nhập bất kỳ mô tả nào. Vui lòng nhập nó và gửi lại.",
    "old_password": "Vui lòng nhập mật khẩu cũ",
    "new_password": "Vui lòng tạo mật khẩu mới",
    "old_new_password": "Mật khẩu mới không được giống mật khẩu cũ",
    "incorrect_password": "mật khẩu không đúng",
    "delete_no": "Xóa ngay bây giờ",
    "Caps": "Caps Lock đang bật",
    "Get": "Lấy",
    "Done": "Xong",
    "error_20001": "Lỗi: 20001. Vui lòng đăng nhập lại.",
    "error_20002": "Lỗi: 20002. Vui lòng đăng nhập lại.",
    "error_20003": "Lỗi: 20003. Vui lòng đăng nhập lại.",
    "error_20004": "Yêu cầu không thành công (mã lỗi: 20004). Vui lòng thử lại.",
    "error_20005": "Phiên đăng nhập đã hết hạn (Lỗi: 20005). Xin vui lòng đăng nhập lại.",
    "error_20006": "Yêu cầu không thành công (mã lỗi: 20006). Vui lòng thử lại.",
    "error_20007": "Phiên đăng nhập đã hết hạn (Lỗi: 20007). Xin vui lòng đăng nhập lại.",
    "error_20008": "Phiên đăng nhập đã hết hạn (Lỗi: 20008). Xin vui lòng đăng nhập lại.",
    "error_20009": "Phiên đăng nhập đã hết hạn (Lỗi: 20009). Xin vui lòng đăng nhập lại.",
    "error_20101": "Vui lòng nhập email của bạn (mã lỗi: 20101)",
    "error_20102": "Email không hợp lệ (mã lỗi: 20102)",
    "error_20103": "Yêu cầu không thành công (mã lỗi: 20103). Vui lòng thử lại",
    "error_20104": "Email đã được sử dụng, vui lòng <a href=\"https://account.mindonmap.com/login/\">đăng nhập</a> hoặc đăng ký bằng email mới",
    "error_20105": "Yêu cầu không thành công (mã lỗi: 20105). Vui lòng thử lại",
    "error_20106": "Không gửi được email, vui lòng thử lại",
    "error_20201": "Vui lòng nhập email của bạn (mã lỗi: 20201)",
    "error_20202": "Vui lòng nhập mật khẩu của bạn (mã lỗi: 20202)",
    "error_20203": "Vui lòng nhập mã xác minh (mã lỗi: 20203)",
    "error_20204": "Email không hợp lệ (mã lỗi: 20204)",
    "error_20205": "Yêu cầu mật khẩu trên 8 ký tự (mã lỗi: 20205)",
    "error_20206": "Yêu cầu không thành công (mã lỗi: 20206). Vui lòng thử lại",
    "error_20207": "Mã xác minh không hợp lệ",
    "error_20208": "Yêu cầu không thành công (mã lỗi: 20208). Vui lòng thử lại",
    "error_20209": "Yêu cầu không thành công (mã lỗi: 20209). Vui lòng thử lại",
    "error_20301": "Vui lòng nhập email của bạn (mã lỗi: 20301)",
    "error_20302": "Lỗi: 20302. Vui lòng liên hệ với chúng tôi",
    "error_20303": "Email không hợp lệ (mã lỗi: 20303)",
    "error_20304": "Yêu cầu không thành công (mã lỗi: 20304). Vui lòng thử lại",
    "error_20305": "Tài khoản không tồn tại. Vui lòng nhập lại hoặc <a href=\"https://account.mindonmap.com/register/\">Tạo nó</a> trước.",
    "error_20306": "Chưa có mật khẩu. Sử dụng <a href=\"https://account.mindonmap.com/passwordless-login/\">Đăng nhập không cần mật khẩu</a> hoặc <a href=\"https://account.mindonmap.com/create-password/\"> đặt mật khẩu</a> và đăng nhập.",
    "error_20308": "Yêu cầu không thành công (mã lỗi: 20308). Vui lòng thử lại",
    "error_20401": "Không đăng xuất được (mã lỗi: 20401). Vui lòng thử lại",
    "error_20501": "Vui lòng nhập email của bạn (mã lỗi: 20501)",
    "error_20502": "Email không hợp lệ (mã lỗi: 20502)",
    "error_20503": "Yêu cầu không thành công (mã lỗi: 20503). Vui lòng thử lại",
    "error_20504": "Không gửi được email. Vui lòng thử lại.",
    "error_20601": "Vui lòng nhập email của bạn (mã lỗi: 20601)",
    "error_20602": "Vui lòng nhập mã xác minh (mã lỗi: 20602)",
    "error_20603": "Email không hợp lệ (mã lỗi: 20603)",
    "error_20604": "Yêu cầu không thành công (mã lỗi: 20604). Vui lòng thử lại",
    "error_20606": "Yêu cầu không thành công (mã lỗi: 20606). Vui lòng thử lại",
    "error_20607": "Yêu cầu không thành công (mã lỗi: 20607). Vui lòng thử lại",
    "error_20608": "Yêu cầu không thành công (mã lỗi: 20608). Vui lòng thử lại",
    "error_20701": "Vui lòng nhập email của bạn (mã lỗi: 20701)",
    "error_20702": "Email không hợp lệ (mã lỗi: 20702)",
    "error_20703": "Yêu cầu không thành công (mã lỗi: 20703). Vui lòng thử lại",
    "error_20704": "Tài khoản không tồn tại. Vui lòng nhập lại hoặc <a href=\"https://account.mindonmap.com/register/\">Tạo nó</a> trước.",
    "error_20705": "Yêu cầu không thành công (mã lỗi: 20705). Vui lòng thử lại",
    "error_20706": "Không gửi được email. Vui lòng thử lại",
    "error_20801": "Vui lòng nhập email của bạn (mã lỗi: 20801)",
    "error_20802": "Lỗi: 20802. Vui lòng liên hệ với chúng tôi",
    "error_20803": "Vui lòng nhập mã xác minh (mã lỗi: 20803)",
    "error_20804": "Email không hợp lệ (mã lỗi: 20804)",
    "error_20805": "Yêu cầu mật khẩu trên 8 ký tự (mã lỗi: 20805)",
    "error_20806": "Yêu cầu không thành công (mã lỗi: 20806). Vui lòng thử lại",
    "error_20808": "Yêu cầu không thành công (mã lỗi: 20808). Vui lòng thử lại",
    "error_20901": "Yêu cầu không thành công (mã lỗi: 20901). Vui lòng thử lại",
    "error_20902": "Yêu cầu không thành công (mã lỗi: 20902). Vui lòng thử lại",
    "error_21000": "Các thay đổi đã được lưu",
    "error_21001": "Không có thông tin nào được gửi (mã lỗi: 21001)",
    "error_21002": "Yêu cầu không thành công (mã lỗi: 21002). Vui lòng thử lại",
    "error_21101": "Vui lòng nhập email của bạn (mã lỗi: 21101)",
    "error_21102": "Email không hợp lệ (mã lỗi: 21102)",
    "error_21103": "Yêu cầu không thành công (mã lỗi: 21103), vui lòng thử lại",
    "error_21104": "Email đã được kết nối, vui lòng sử dụng email mới",
    "error_21105": "Yêu cầu không thành công (mã lỗi: 21105), vui lòng thử lại",
    "error_21106": "Không gửi được email. Vui lòng thử lại",
    "error_21201": "Vui lòng nhập email của bạn (mã lỗi: 21201)",
    "error_21202": "Vui lòng nhập mã xác minh (mã lỗi: 21202)",
    "error_21203": "Email không hợp lệ (mã lỗi: 21203)",
    "error_21204": "Lỗi: 21204. Vui lòng liên hệ với chúng tôi",
    "error_21205": "Lỗi: 21205. Vui lòng liên hệ với chúng tôi",
    "error_21206": "Yêu cầu mật khẩu trên 8 ký tự (mã lỗi: 21206)",
    "error_21207": "Yêu cầu không thành công (mã lỗi: 21207). Vui lòng thử lại",
    "error_21209": "Yêu cầu không thành công (mã lỗi: 21209). Vui lòng thử lại",
    "error_21301": "Vui lòng nhập mật khẩu cũ (mã lỗi: 21301)",
    "error_21302": "Vui lòng tạo mật khẩu mới (mã lỗi: 21302)",
    "error_21303": "Mật khẩu mới không được giống mật khẩu cũ. (Lỗi: 21303)",
    "error_21304": "Yêu cầu mật khẩu trên 8 ký tự (mã lỗi: 21304)",
    "error_21306": "Yêu cầu không thành công (mã lỗi: 21306). Vui lòng thử lại",
    "error_21402": "Yêu cầu không thành công (mã lỗi: 21402). Vui lòng thử lại",
    "error_21403": "Không gửi được mã xác minh. Xin vui lòng gửi lại nó",
    "error_21500": "Tài khoản đã bị xóa",
    "error_21501": "Vui lòng nhập mã xác minh (mã lỗi: 21501)",
    "error_21502": "Phiên đăng nhập đã hết hạn (Lỗi: 21502). Xin vui lòng đăng nhập lại.",
    "error_21503": "Yêu cầu không thành công (mã lỗi: 21503). Vui lòng thử lại",
    "error_21505": "Yêu cầu không thành công (mã lỗi: 21505), vui lòng thử lại",
    "error_21601": "Lỗi: 20601. Vui lòng liên hệ với chúng tôi",
    "error_21602": "Xác minh không hợp lệ (Lỗi: 20602). Vui lòng thử lại.",
    "error_21603": "Lỗi: 20603. Vui lòng thử lại",
    "error_21604": "Yêu cầu không thành công (mã lỗi: 21604). Vui lòng thử lại",
    "error_21606": "Yêu cầu không thành công (mã lỗi: 21606). Vui lòng thử lại",
    "error_21611": "Yêu cầu không thành công (mã lỗi: 21611). Vui lòng thử lại",
    "error_21801": "Lỗi: 21801. Vui lòng liên hệ với chúng tôi",
    "error_21802": "Yêu cầu không thành công (Lỗi: 21802). Vui lòng thử lại",
    "error_21803": "Lỗi: 21803. Vui lòng thử lại",
    "error_21804": "Yêu cầu không thành công (mã lỗi: 21804). Vui lòng thử lại",
    "error_21806": "Lỗi: 21806. Vui lòng thử lại",
    "error_21807": "Lỗi: 21807. Vui lòng liên hệ với chúng tôi",
    "error_21808": "Lỗi: 21808. Vui lòng liên hệ với chúng tôi",
    "error_21809": "Lỗi: 21809. Vui lòng liên hệ với chúng tôi",
    "error_21810": "Lỗi: 21810. Vui lòng liên hệ với chúng tôi",
    "error_21811": "Lỗi: 21811. Vui lòng liên hệ với chúng tôi",
    "error_21812": "Lỗi: 21812. Vui lòng liên hệ với chúng tôi",
    "error_21813": "Yêu cầu không thành công (mã lỗi: 21813). Vui lòng thử lại",
    "error_21814": "Lỗi: 21814. Vui lòng liên hệ với chúng tôi",
    "error_21815": "Yêu cầu không thành công (mã lỗi: 21815). Vui lòng thử lại",
    "error_21816": "Lỗi: 21816. Vui lòng liên hệ với chúng tôi",
    "error_21817": "Lỗi: 21817. Vui lòng liên hệ với chúng tôi",
    "error_21818": "Lỗi: 21818. Vui lòng liên hệ với chúng tôi",
    "error_21819": "Yêu cầu không thành công (mã lỗi: 21819). Vui lòng thử lại",
    "error_21820": "Lỗi: 21820. Vui lòng liên hệ với chúng tôi",
    "error_21821": "Lỗi: 21821. Vui lòng liên hệ với chúng tôi",
    "error_21822": "Lỗi: 21822. Vui lòng liên hệ với chúng tôi",
    "error_21823": "Yêu cầu không thành công (mã lỗi: 21823). Vui lòng thử lại",
    "error_21824": "Yêu cầu không thành công (mã lỗi: 21824). Vui lòng thử lại",
    "error_21825": "Yêu cầu không thành công (mã lỗi: 21825). Vui lòng thử lại",
    "error_21826": "Yêu cầu không thành công (mã lỗi: 21826). Vui lòng thử lại",
    "error_21901": "Lỗi: 21901. Vui lòng liên hệ với chúng tôi",
    "error_21902": "Yêu cầu không thành công (mã lỗi: 21902). Vui lòng thử lại",
    "error_21903": "Trạng thái tài khoản đã thay đổi (mã lỗi: 21903), vui lòng tải lại trang và thử lại",
    "error_21904": "Lỗi: 21904. Vui lòng thử lại",
    "error_21905": "Lỗi: 21905. Vui lòng thử lại",
    "error_21906": "Yêu cầu không thành công (mã lỗi: 21906). Vui lòng thử lại",
    "error_21907": "Tài khoản Google đã được liên kết với tài khoản khác",
    "error_21908": "Yêu cầu không thành công (mã lỗi: 21908). Vui lòng thử lại",
    "error_22001": "Yêu cầu không thành công (mã lỗi: 22001). Vui lòng thử lại",
    "error_22002": "Hủy liên kết không thành công mà không có thông tin đăng nhập bổ sung",
    "error_22003": "Yêu cầu không thành công (mã lỗi: 22003). Vui lòng thử lại",
    "error_22101": "Lỗi: 22101. Vui lòng liên hệ với chúng tôi",
    "error_22102": "Trạng thái tài khoản đã thay đổi (mã lỗi: 22102), vui lòng làm mới trang và thử lại",
    "error_22103": "Yêu cầu không thành công (mã lỗi: 22103). Vui lòng thử lại",
    "error_22104": "Trạng thái tài khoản đã thay đổi (mã lỗi: 22104), vui lòng làm mới trang và thử lại",
    "error_22105": "Lỗi: 22105. Vui lòng thử lại",
    "error_22106": "Lỗi: 22106. Vui lòng thử lại",
    "error_22107": "Lỗi: 22107. Vui lòng liên hệ với chúng tôi",
    "error_22108": "Yêu cầu không thành công (mã lỗi: 22108). Vui lòng thử lại",
    "error_22201": "Lỗi: 22201. Vui lòng liên hệ với chúng tôi",
    "error_22202": "Xác minh không hợp lệ (Lỗi: 22202). Vui lòng thử lại.",
    "error_22203": "Lỗi: 22203. Vui lòng thử lại\"",
    "error_22204": "Yêu cầu không thành công (mã lỗi: 22204). Vui lòng thử lại",
    "error_22206": "Yêu cầu không thành công (mã lỗi: 22206). Vui lòng thử lại",
    "error_22401": "Lỗi: 22401. Vui lòng liên hệ với chúng tôi",
    "error_22402": "Xác minh không hợp lệ (Lỗi: 22402). Vui lòng thử lại.",
    "error_22403": "Lỗi: 22403. Vui lòng thử lại",
    "error_22404": "Yêu cầu không thành công (mã lỗi: 22404). Vui lòng thử lại",
    "error_22405": "Tài khoản Facebook đã được liên kết với email khác",
    "error_22406": "Lỗi: 22406. Vui lòng thử lại",
    "error_22407": "Lỗi: 22407. Vui lòng liên hệ với chúng tôi",
    "error_22408": "Lỗi: 22408. Vui lòng liên hệ với chúng tôi",
    "error_22409": "Lỗi: 22409. Vui lòng liên hệ với chúng tôi",
    "error_22410": "Lỗi: 224010. Vui lòng liên hệ với chúng tôi",
    "error_22411": "Lỗi: 224011. Vui lòng liên hệ với chúng tôi",
    "error_22412": "Lỗi: 224012. Vui lòng liên hệ với chúng tôi",
    "error_22413": "Lỗi: 22413. Vui lòng liên hệ với chúng tôi",
    "error_22414": "Yêu cầu không thành công (mã lỗi: 22414). Vui lòng thử lại",
    "error_22415": "Lỗi: 22415. Vui lòng liên hệ với chúng tôi",
    "error_22416": "Lỗi: 22416. Vui lòng liên hệ với chúng tôi",
    "error_22417": "Lỗi: 22417. Vui lòng liên hệ với chúng tôi",
    "error_22418": "Yêu cầu không thành công (mã lỗi: 22418). Vui lòng thử lại",
    "error_22419": "Lỗi: 22419. Vui lòng liên hệ với chúng tôi",
    "error_22420": "Lỗi: 22420. Vui lòng liên hệ với chúng tôi",
    "error_22421": "Lỗi: 22421. Vui lòng liên hệ với chúng tôi",
    "error_22422": "Yêu cầu không thành công (mã lỗi: 22422). Vui lòng thử lại",
    "error_22423": "Yêu cầu không thành công (mã lỗi: 22423). Vui lòng thử lại",
    "error_22424": "Yêu cầu không thành công (mã lỗi: 22424). Vui lòng thử lại",
    "error_22425": "Yêu cầu không thành công (mã lỗi: 22425). Vui lòng thử lại",
    "error_20098": "Mã lỗi: 20098. Nếu bạn đang duyệt ở chế độ Riêng tư, vui lòng chuyển sang chế độ Bình thường và thử lại.",
    "error_22298": "Yêu cầu Google không thành công (Lỗi: 22298). Vui lòng thử lại.",
    "error_22498": "Yêu cầu Facebook không thành công (Lỗi: 22498). Vui lòng thử lại.",
    "error_24902": "Yêu cầu không thành công (mã lỗi: 24902). Vui lòng thử lại",
    "error_24903": "Yêu cầu không thành công (mã lỗi: 24903). Vui lòng thử lại",
    "error_24904": "Yêu cầu không thành công (mã lỗi: 24904). Vui lòng thử lại",
    "error_24905": "Yêu cầu không thành công (mã lỗi: 24905). Vui lòng thử lại",
    "login_title": "Đăng nhập vào MindOnMap",
    "log_in": "Đăng nhập",
    "no_account": "Không tài khoản?",
    "create_it": "Tạo ra nó",
    "or_log_in_with": "Hoặc đăng nhập bằng",
    "passwordless_login": "Đăng nhập không cần mật khẩu",
    "log_in_done": "Đăng nhập xong",
    "three_rd_account_connect_info": "Chúc mừng! Bạn đã đăng nhập thành công. Bây giờ bạn có thể kết nối một tài khoản email được sử dụng để đăng nhập trong tương lai.",
    "see_my_account": "Xem tài khoản của tôi",
    "three_rd_login_merge_account": "Địa chỉ email tài khoản của bên thứ ba đã được đăng ký, bạn có muốn kết nối và đăng nhập trực tiếp bằng địa chỉ email này không?",
    "connect_log_in": "Kết nối và đăng nhập",
    "create_an_account": "Tạo một tài khoản",
    "back_to_log_in": "Quay lại đăng nhập",
    "create_password": "Tạo mật khẩu",
    "create_now": "Hãy tạo ngay",
    "password_login_subtitle": "Đăng nhập không cần mật khẩu bằng email",
    "account_login": "Đăng nhập tài khoản",
    "rights": "Bằng cách tạo tài khoản này, bạn đồng ý với <a href=\"https://www.mindonmap.com/terms-and-conditions/\">Điều khoản dịch vụ</a> và <a href=\"https://www.mindonmap.com/privacy-policy/\">Chính sách quyền riêng tư</a>",
    "passwordless_login_done": "Đăng nhập không cần mật khẩu đã xong",
    "passwordless_login_info": "Xin chúc mừng, bạn đã hoàn tất đăng nhập không cần mật khẩu thành công. Bạn có thể tạo mật khẩu cho tài khoản này và đăng nhập bằng tài khoản và mật khẩu đó trong tương lai. <a href=\"/create-password\" style=\"display: initial;\">Tạo ngay</a>",
    "sign_up": "Đăng ký",
    "register_info": "tạo tài khoản của bạn",
    "reset_now": "Đặt lại ngay",
    "forgot_password": "Quên mật khẩu",
    "reset_password_subtitle": "Sử dụng email tài khoản của bạn để đặt lại mật khẩu",
    "plan_products": "Gói & Sản phẩm",
    "nick_name": "Tên tài khoản:",
    "email": "E-mail:",
    "my_products": "Sản phẩm của tôi",
    "my_orders": "Đơn hàng của tôi",
    "unlink": "Hủy liên kết",
    "link": "liên kết",
    "connected_accounts": "Tài khoản được kết nối",
    "last_name": "Họ:",
    "first_name": "Tên đầu tiên:",
    "Gender": "Giới tính:",
    "Birth": "Sinh:",
    "Month": "Tháng",
    "Year": "Năm",
    "Country_Region": "Quốc gia/Khu vực:",
    "Address": "Địa chỉ:",
    "Save": "Cứu",
    "Date": "Ngày",
    "Male": "Nam giới",
    "Female": "Nữ giới",
    "Unspecified": "Không xác định",
    "Security": "Bảo vệ",
    "change_password": "Đổi mật khẩu",
    "change_now": "Thay đổi ngay",
    "connect_email": "Kết nối email",
    "delete_account": "Xóa tài khoản",
    "delete_account_info": "Khi tài khoản của bạn bị xóa, tất cả dữ liệu trong Tài khoản của tôi được liên kết với tài khoản của bạn sẽ bị xóa vĩnh viễn và bạn có thể không khôi phục được dữ liệu đó. Chúng tôi khuyên bạn nên thận trọng khi bước đi.",
    "Delete": "Xóa bỏ",
    "Logout": "Đăng xuất",
    "my_profile": "Thông tin của tôi",
    "guides_faqs": "Hướng dẫn & Câu hỏi thường gặp",
    "More": "Hơn",
    "guides": "Hướng dẫn",
    "register": "đăng ký",
    "hot_faq": "Câu hỏi thường gặp nóng",
    "Contents": "Nội dung:",
    "contact_us": "Liên hệ với chúng tôi>>",
    "plan": "Kế hoạch",
    "unregistered": "Chưa đăng ký",
    "buy_more": "Mua thêm",
    "buy_again": "Mua lại",
    "buy_now": "Mua ngay",
    "free_no_limit": "Miễn phí & Không giới hạn",
    "expired": "Hết hạn",
    "lifetime": "Cả đời",
    "remain": "Duy trì",
    "day_s": "(Các) ngày",
    "error_24801": "Yêu cầu không thành công (mã lỗi: 24801). Vui lòng thử lại",
    "no_app_found": "Không tìm thấy ứng dụng nào!<a href=\"/\">Làm mới</a> hoặc <a href=\"https://www.mindonmap.com/\">Truy cập trang web chính thức</a>",
    "get_more": "Nhận thêm >>",
    "edit_photo": "Chỉnh sửa hình ảnh",
    "select_photo": "Chọn tấm ảnh",
    "change_photo": "Thay đổi hình ảnh",
    "cancel": "Hủy bỏ",
    "hide_password": "Ẩn mật khẩu",
    "show_password": "Hiển thị mật khẩu",
    "zoom_in": "Phóng to",
    "zoom_out": "Thu nhỏ",
    "rotate": "Quay",
    "horizontal_flip": "Lật ngang",
    "vertical_flip": "Lật dọc",
    "country": "Quốc gia",
    "country_1": "Chọn quốc gia/khu vực của bạn",
    "country_2": "Đảo Åaland",
    "country_3": "Afghanistan",
    "country_4": "Albania",
    "country_5": "Algérie",
    "country_6": "American Samoa",
    "country_7": "Andorra",
    "country_8": "Ăng-gô-la",
    "country_9": "Anguilla",
    "country_10": "Nam Cực",
    "country_11": "Antigua & Barbuda",
    "country_12": "Argentina",
    "country_13": "Armenia",
    "country_14": "Aruba",
    "country_15": "Châu Úc",
    "country_16": "Áo",
    "country_17": "Azerbaijan",
    "country_18": "Bahrain",
    "country_19": "Bangladesh",
    "country_20": "Barbados",
    "country_21": "Bêlarut",
    "country_22": "nước Bỉ",
    "country_23": "Belize",
    "country_24": "Bénin",
    "country_25": "Bermuda",
    "country_26": "Bhutan",
    "country_27": "Bôlivia",
    "country_28": "Bosnia & Herzegovina",
    "country_29": "Botswana",
    "country_30": "Đảo Bouvet",
    "country_31": "Brazil",
    "country_32": "Lãnh thổ Anh Ấn Độ Dương ",
    "country_33": "Quần đảo British Virgin",
    "country_34": "Brunei",
    "country_35": "Bulgaria",
    "country_36": "Burkina Faso",
    "country_37": "Burundi",
    "country_38": "Campuchia",
    "country_39": "Ca-mơ-run",
    "country_40": "Canada",
    "country_41": "Mũi Verde",
    "country_42": "Ca-ri-bê Hà Lan",
    "country_43": "Quần đảo Cayman",
    "country_44": "Cộng hòa trung phi",
    "country_45": "Tchad",
    "country_46": "Chilê",
    "country_47": "Trung Quốc",
    "country_48": "Đảo Giáng sinh",
    "country_49": "Quần đảo Cocos (Keeling) ",
    "country_50": "Colombia",
    "country_51": "Quần đảo Cook",
    "country_52": "Costa Rica",
    "country_53": "Côte d'Ivoire",
    "country_54": "Croatia",
    "country_55": "Cuba",
    "country_56": "Síp",
    "country_57": "Cộng hòa Séc",
    "country_58": "Cộng hòa Dân chủ của Congo",
    "country_59": "Đan mạch",
    "country_60": "Djibouti",
    "country_61": "Dominica",
    "country_62": "Cộng hòa Dominica",
    "country_63": "Ecuador",
    "country_64": "Ai Cập",
    "country_65": "El Salvador",
    "country_66": "Equatorial Guinea",
    "country_67": "Eritrea",
    "country_68": "Estonia",
    "country_69": "Ethiopia",
    "country_70": "Quần đảo Falkland",
    "country_71": "Quần đảo Faroe",
    "country_72": "Liên bang Micronesia",
    "country_73": "Fiji",
    "country_74": "Phần Lan",
    "country_75": "Pháp",
    "country_76": "Guiana thuộc Pháp",
    "country_77": "Polynesia thuộc Pháp",
    "country_78": "Lãnh thổ phía Nam của Pháp",
    "country_79": "Gabon",
    "country_80": "Gambia",
    "country_81": "Gruzia",
    "country_82": "nước Đức",
    "country_83": "Ghana",
    "country_84": "Gibraltar",
    "country_85": "Vương quốc Anh (Vương quốc Anh; Anh)",
    "country_86": "Hy Lạp",
    "country_87": "Greenland",
    "country_88": "Grenada",
    "country_89": "Guadeloupe",
    "country_90": "đảo Guam",
    "country_91": "Guatemala",
    "country_92": "Guernsey",
    "country_93": "Ghi-nê",
    "country_94": "Guiné-Bissau",
    "country_95": "Guyana",
    "country_96": "Haiti",
    "country_97": "Đảo Heard và Quần đảo McDonald ",
    "country_98": "Honduras",
    "country_99": "Hồng Kông",
    "country_100": "Hungary",
    "country_101": "Nước Iceland",
    "country_102": "Ấn Độ",
    "country_103": "Indonesia",
    "country_104": "Iran",
    "country_105": "Irắc",
    "country_106": "Ireland",
    "country_107": "Đảo Man",
    "country_108": "Người israel",
    "country_109": "Nước Ý",
    "country_110": "Jamaica",
    "country_111": "Nhật Bản",
    "country_112": "áo đấu",
    "country_113": "Jordan",
    "country_114": "Kazakhstan",
    "country_115": "Kenya",
    "country_116": "Kiribati",
    "country_117": "Cô-oét",
    "country_118": "Kyrgyzstan",
    "country_119": "Nước Lào",
    "country_120": "Latvia",
    "country_121": "Liban",
    "country_122": "Lesotho",
    "country_123": "Liberia",
    "country_124": "Lybia",
    "country_125": "Liechtenstein",
    "country_126": "Litva",
    "country_127": "Luxembourg",
    "country_128": "Macao",
    "country_129": "Madagascar",
    "country_130": "Malawi",
    "country_131": "Malaysia",
    "country_132": "Maldives",
    "country_133": "Mali",
    "country_134": "Malta",
    "country_135": "Đảo Marshall",
    "country_136": "Martinique",
    "country_137": "Mauritanie",
    "country_138": "Mô-ri-xơ",
    "country_139": "mayotte",
    "country_140": "México",
    "country_141": "Moldova",
    "country_142": "Monaco",
    "country_143": "Mông Cổ",
    "country_144": "Montenegro",
    "country_145": "Montserrat",
    "country_146": "Ma-rốc",
    "country_147": "Mozambique",
    "country_148": "Myanmar (Miến Điện)",
    "country_149": "Namibia",
    "country_150": "Nauru",
    "country_151": "Nepal",
    "country_152": "nước Hà Lan",
    "country_153": "Caledonia mới",
    "country_154": "New Zealand",
    "country_155": "Nicaragua",
    "country_156": "Niger",
    "country_157": "Nigeria",
    "country_158": "Niue",
    "country_159": "Đảo Norfolk",
    "country_160": "Bắc Triều Tiên",
    "country_161": "Quần đảo Bắc Mariana",
    "country_162": "Na Uy",
    "country_163": "Ô-man",
    "country_164": "Pakistan",
    "country_165": "Palau",
    "country_166": "Lãnh thổ của Palestine",
    "country_167": "Panama",
    "country_168": "Papua Tân Guinea",
    "country_169": "Paraguay",
    "country_170": "Peru",
    "country_171": "Quần đảo Pitcairn",
    "country_172": "Ba Lan",
    "country_173": "Bồ Đào Nha",
    "country_174": "Puerto Rico",
    "country_175": "Qatar",
    "country_176": "Cộng hòa Macedonia (FYROM)",
    "country_177": "Cộng hòa của Congo",
    "country_178": "Đoàn tụ",
    "country_179": "Rumani",
    "country_180": "Liên bang Nga",
    "country_181": "Rwanda",
    "country_182": "Thánh Barthélemy",
    "country_183": "Saint Martin (Pháp)",
    "country_184": "Saint-Pierre và Miquelon",
    "country_185": "Samoa",
    "country_186": "San Marino",
    "country_187": "Sao Tome & Principe",
    "country_188": "Ả Rập Saudi",
    "country_189": "Sénégal",
    "country_190": "Serbia",
    "country_191": "Seychelles",
    "country_192": "Sierra Leone",
    "country_193": "Singapore",
    "country_194": "Slovakia",
    "country_195": "Slovenia",
    "country_196": "Quần đảo Solomon",
    "country_197": "Somali",
    "country_198": "Nam Phi",
    "country_199": "Nam Georgia và Nam Sandwich Isla",
    "country_200": "Hàn Quốc",
    "country_201": "Phía nam Sudan",
    "country_202": "Tây ban nha",
    "country_203": "Sri Lanka",
    "country_204": "St. Helena & Các vùng phụ thuộc",
    "country_205": "St. Kitts & Nevis",
    "country_206": "Thánh Lucia",
    "country_207": "St. Vincent & Grenadines",
    "country_208": "Sudan",
    "country_209": "Suriname",
    "country_210": "Swaziland",
    "country_211": "Thụy Điển",
    "country_212": "Thụy sĩ",
    "country_213": "Syria",
    "country_214": "Đài Loan",
    "country_215": "Tajikistan",
    "country_216": "Tanzania",
    "country_217": "Bản mẫu:Dữ liệu quốc gia SJM Svalbard",
    "country_218": "nước Thái Lan",
    "country_219": "Bahamas",
    "country_220": "Comoros",
    "country_221": "Philippines",
    "country_222": "Timor-Leste (Đông Timor)",
    "country_223": "Đi",
    "country_224": "Tokelau",
    "country_225": "Tonga",
    "country_226": "Trinidad & Tobago",
    "country_227": "Tunisia",
    "country_228": "Thổ Nhĩ Kỳ",
    "country_229": "Turkmenistan",
    "country_230": "Quần đảo Thổ Nhĩ Kỳ & Caicos ",
    "country_231": "Tuvalu",
    "country_232": "Uganda",
    "country_233": "Ukraina",
    "country_234": "Các Tiểu Vương Quốc Ả Rập Thống Nhất",
    "country_235": "Hoa Kỳ Các hòn đảo xa xa nhỏ",
    "country_236": "Hoa Kỳ của Mỹ (Hoa Kỳ)",
    "country_237": "Hoa Kỳ Quần đảo Virgin ",
    "country_238": "Uruguay",
    "country_239": "Uzbekistan",
    "country_240": "Vanuatu",
    "country_241": "Thành phố Vatican (Tòa thánh)",
    "country_242": "Venezuela",
    "country_243": "Việt Nam",
    "country_244": "Wallis và Futuna",
    "country_245": "Phía tây Sahara",
    "country_246": "Yêmen",
    "country_247": "Zambia",
    "country_248": "Zimbabwe",
    "google_login": "Đăng nhập bằng Google",
    "State": "Tình trạng",
    "Activation_code": "Mã kích hoạt",
    "Question": "Liệt kê tất cả các ứng dụng bạn đã đăng nhập",
    "Copy_complete": "Sao chép hoàn tất",
    "footer": "Bản quyền © 2024 MindOnMap Studio. Đã đăng ký Bản quyền.",
    "change_password_success": "Đã thay đổi mật khẩu thành công",
    "successful_login_title": "Đăng nhập thành công",
    "product_page": "Trang sản phẩm>>",
    "successful_login_info": "Đăng nhập hoàn tất. Vui lòng đóng trang hiện tại và quay lại tab ban đầu để tiếp tục quá trình sau. Trang hiện tại sẽ tự động đóng sau 5 giây. Nếu tự động đóng hoặc đóng bằng cách nhấp vào nút \"Xong\" không thành công, vui lòng đóng trực tiếp tab này.",
    "successful_login_info_firefox": "Đăng nhập hoàn tất. Vui lòng đóng trang hiện tại và quay lại tab ban đầu để tiếp tục quá trình sau.",
    "my_account": "Tài khoản của tôi",
    "my_history": "Lịch sử của tôi",
    "remove_watermark": "Xóa hình mờ",
    "no_history": "Không có lịch sử",
    "history_all": "Chọn tất cả",
    "history_open": "Mở",
    "history_down": "Tải xuống",
    "history_delete": "Xóa bỏ",
    "history_clear": "Xóa không hợp lệ",
    "images": "(các) hình ảnh",
    "use_this_function": "Sử dụng sản phẩm này>>",
    "hd_downloading": "Lợi ích tải xuống hình ảnh gốc HD:",
    "lifetimeRemaining": "Còn lại trọn đời",
    "Remaining": "Còn lại",
    "email_verification": "Email xác thực",
    "email_verification_info": "Chúng tôi đã gửi thông báo xác minh tới email <span class=\"email\"></span> của bạn và vui lòng hoàn tất xác minh. Sau khi xác minh, các lợi ích sẽ được đồng bộ tự động.",
    "wrong_email": "Sai địa chỉ email?",
    "click_here_to_modify": "Bấm vào đây để sửa đổi",
    "get_more_help": "Nhận thêm trợ giúp?",
    "click_here": "Bấm vào đây",
    "email_verification_info_success": "Xin chúc mừng bạn đã xác minh tài khoản email của mình.",
    "email_verification_info_error": "Xác minh không thành công do liên kết đã hết hạn.",
    "registration_succeeded": "Đăng ký thành công",
    "registration_succeeded_info_1": "Chúc mừng! Bạn đã đăng ký thành công. Chúng tôi đã gửi thông báo xác minh đến email <span class=\"email\"></span> của bạn và vui lòng hoàn tất xác minh để sử dụng các lợi ích của email này.",
    "registration_succeeded_info_2": "Bấm “Xong” để quay lại Trang chủ và sử dụng sản phẩm này.",
    "registration_succeeded_info_3": "Nhấp vào \"Xong\" để đóng trang hiện tại và quay lại Trang chủ để thực hiện các thao tác sau. Nếu đóng không thành công, vui lòng đóng tab theo cách thủ công.",
    "verify_email": "Xác nhận Email",
    "registered_email_not_verified": "Email đăng ký chưa được xác minh, vui lòng xác minh ngay.",
    "email_verification_time_1": "Không nhận được email xác minh?",
    "email_verification_time_2": "Sau đó",
    "email_verification_time_3": "Bấm vào đây để gửi lại",
    "error_26301": "Mã lỗi: 26301, vui lòng liên hệ với chúng tôi",
    "error_26302": "Mã lỗi: 26302, vui lòng liên hệ với chúng tôi",
    "error_26303": "Lỗi định dạng email (mã lỗi: 26303). Vui lòng nhập lại",
    "error_26304": "Nên sử dụng mật khẩu trên 8 ký tự (mã lỗi: 26304)",
    "error_26305": "Reuqest không thành công (mã lỗi: 26305). Vui lòng thử lại",
    "error_26306": "Email đã được đăng ký, vui lòng <a href=\"https://account.mindonmap.com/login/\">đi đăng nhập</a>",
    "error_26307": "Reuqest không thành công (mã lỗi: 26307). Vui lòng thử lại",
    "error_26308": "Reuqest không thành công (mã lỗi: 26308). Vui lòng thử lại",
    "error_26401": "Mã lỗi: 26401, vui lòng thử lại",
    "error_26402": "Email đã được xác minh (mã lỗi: 26402), vui lòng thử lại",
    "error_26403": "Reuqest không thành công (mã lỗi: 26403). Vui lòng thử lại",
    "error_26404": "Yêu cầu không thành công (mã lỗi: 26404). Vui lòng thử lại",
    "error_26501": "Mã lỗi: 26501, vui lòng liên hệ với chúng tôi",
    "error_26502": "Mã lỗi: 26502, vui lòng liên hệ với chúng tôi",
    "error_26503": "Lỗi định dạng email (mã lỗi: 26503). Vui lòng nhập lại",
    "error_26504": "Reuqest không thành công (mã lỗi: 26504). Vui lòng thử lại",
    "error_26505": "Email chưa được đăng ký, vui lòng <a href=\"https://account.mindonmap.com/register/\">đăng ký trước</a>",
    "error_26506": "Email đã được xác minh.",
    "error_26507": "Reuqest không thành công (mã lỗi: 26507). Vui lòng thử lại",
    "error_26508": "Xác minh không thành công (mã lỗi: 26508), vui lòng thử lại",
    "error_26509": "Yêu cầu không thành công (mã lỗi: 26509), vui lòng thử lại",
    "error_26510": "Mã lỗi: 26510, vui lòng liên hệ với chúng tôi",
    "error_26601": "Mã lỗi: 26601, vui lòng liên hệ với chúng tôi",
    "error_26602": "Mã lỗi: 26602, vui lòng liên hệ với chúng tôi",
    "error_26603": "Reuqest không thành công (mã lỗi: 26603). Vui lòng thử lại",
    "error_26604": "Mã lỗi: 26604, vui lòng liên hệ với chúng tôi",
    "error_26605": "Mã lỗi: 26605, vui lòng liên hệ với chúng tôi",
    "error_26701": "Mã lỗi: 26701, vui lòng liên hệ với chúng tôi",
    "error_26702": "Reuqest không thành công (mã lỗi: 26702). Vui lòng thử lại",
    "error_26703": "Mã lỗi: 26703, vui lòng liên hệ với chúng tôi",
    "error_26704": "Mã lỗi: 26704, vui lòng liên hệ với chúng tôi",
    "error_26705": "Đợi đăng nhập (mã lỗi: 26705). Vui lòng thử lại",
    "no_cookie": "Bạn đã bật chức năng Chặn tất cả cookie trên trình duyệt nên không thể đăng nhập. Vui lòng vào Cài đặt để đánh dấu vào ô Cho phép tất cả cookie.",
    "error_26801": "Mã lỗi: 26801, vui lòng liên hệ với chúng tôi",
    "error_26802": "Mã lỗi: 26802, vui lòng liên hệ với chúng tôi",
    "error_26803": "Yêu cầu không thành công (mã lỗi: 26803). Vui lòng thử lại",
    "error_26804": "Yêu cầu không thành công (mã lỗi: 26804). Vui lòng thử lại",
    "error_order": "Yêu cầu không thành công (mã lỗi: 27098), vui lòng thử lại!",
    "error_order1": "Truy vấn đơn hàng không đầy đủ（mã lỗi: ",
    "error_order2": "), vui lòng làm mới và thử lại.",
    "modify_email_title": "Sửa đổi email",
    "modify_email_info": "Bạn có thể sử dụng email đã sửa đổi để đăng nhập vào tài khoản của mình.",
    "images_per": "Hình ảnh trên mỗi",
    "error_26101": "Lỗi: 26101. Vui lòng liên hệ với chúng tôi",
    "error_26102": "Lỗi: 26102. Vui lòng liên hệ với chúng tôi",
    "error_26103": "Yêu cầu không thành công (mã lỗi: 26103). Vui lòng thử lại",
    "error_26104": "Mã lỗi: 26104, vui lòng thử lại",
    "error_26105": "Mã lỗi: 26105, vui lòng thử lại",
    "error_26106": "Xóa không thành công (mã lỗi: 26106). Vui lòng thử lại",
    "error_26201": "Lỗi: 26201. Vui lòng liên hệ với chúng tôi",
    "error_26202": "Yêu cầu không thành công (mã lỗi: 26202). Vui lòng thử lại",
    "error_26001": "Lỗi: 26001. Vui lòng liên hệ với chúng tôi",
    "error_26002": "Lỗi: 26002. Vui lòng liên hệ với chúng tôi",
    "error_26003": "Lỗi: 26003. Vui lòng liên hệ với chúng tôi",
    "error_26004": "Lỗi: 26004. Vui lòng liên hệ với chúng tôi",
    "error_26005": "Yêu cầu không thành công (mã lỗi: 26005). Vui lòng thử lại",
    "error_26006": "Mã lỗi: 26006, vui lòng thử lại",
    "error_26008": "Lỗi: 26008. Vui lòng liên hệ với chúng tôi",
    "go_to_the_home_page": "Đi đến trang chủ",
    "error_27101": "Yêu cầu không thành công (mã lỗi: 27101). Vui lòng thử lại",
    "error_27201": "Mã lỗi: 27201, vui lòng liên hệ với chúng tôi",
    "error_27202": "Mã lỗi: 27202, vui lòng thử lại",
    "error_27203": "Yêu cầu không thành công (mã lỗi: 27203). Vui lòng thử lại",
    "error_27204": "Mã không hợp lệ (mã lỗi: 27204).",
    "error_27205": "Yêu cầu không thành công (mã lỗi: 27205). Vui lòng thử lại",
    "error_27206": "Yêu cầu không thành công (mã lỗi: 27206). Vui lòng thử lại",
    "error_27207": "Yêu cầu không thành công (mã lỗi: 27207). Vui lòng thử lại",
    "no_history_found": "Bạn chưa sử dụng bất kỳ công cụ nào! <a href=\"/\">Làm mới</a> hoặc <a href=\"https://www.mindonmap.com/\">Truy cập trang web chính thức</a>",
    "error_25301": "Lỗi: 25301. Vui lòng liên hệ với chúng tôi",
    "error_25302": "Lỗi: 25302. Vui lòng liên hệ với chúng tôi",
    "error_25303": "Yêu cầu không thành công (mã lỗi: 25303). Vui lòng thử lại",
    "error_25304": "Yêu cầu không thành công (mã lỗi: 25304). Vui lòng thử lại",
    "error_25305": "Yêu cầu không thành công (mã lỗi: 25305). Vui lòng thử lại",
    "error_25306": "Yêu cầu không thành công (mã lỗi: 25306). Vui lòng thử lại",
    "image_upscaler_p": "Lợi ích tải xuống hình ảnh không có hình mờ:",
    "Available_for": "Có sẵn cho:",
    "credit_per": "%s tín dụng cho mỗi hình ảnh HD",
    "still_valid": "(Các) gói đã mua vẫn còn hiệu lực",
    "credit": "tín dụng)",
    "pc_3rd_info": "Đăng nhập thành công. Vui lòng vào ứng dụng để thao tác tiếp.",
    "use_online": "Sử dụng dịch vụ trực tuyến",
    "use_download": "Sử dụng chương trình máy tính để bàn",
    "use_immediately": "Sử dụng ngay",
    "Use_in_browser": "Sử dụng trong Trình duyệt",
    "win_desktop": "các cửa sổ",
    "Mac_desktop": "Mac",
    "credits_per": "{%} Tín dụng mỗi tháng",
    "expire": "Thời gian hết hạn:",
    "viewDetails": "Xem chi tiết",
    "viewHistory": "Xem lợi ích lịch sử>>",
    "viewDetailsInfo": "Lưu ý: Nếu các lợi ích đăng ký được gia hạn trong vòng 7 ngày sau khi hết hạn, những lợi ích chưa sử dụng có thể tiếp tục được sử dụng. Ngoài ra, thời gian hết hạn sẽ được tự động cập nhật theo thời gian hết hạn của gói đăng ký mới. Nếu không có đăng ký mới sau 7 ngày hết hạn, tất cả các lợi ích đã hết hạn sẽ bị xóa.",
    "connect_account": "Liên kết email với tài khoản của bạn",
    "connect_account_info": "Sau khi ràng buộc, bạn có thể đăng nhập bằng địa chỉ email này.",
    "connect_now": "Ràng buộc ngay bây giờ",
    "no_email_bind": "Không có email ràng buộc",
    "bind_email": "Ràng buộc email",
    "connect_your_email_placeholder": "Hãy điền địa chỉ email của bạn",
    "bind_an_email": "Ràng buộc một email",
    "bind_info": "Bạn đã đăng nhập thành công. Vui lòng liên kết một email với tài khoản của bạn để kích hoạt lợi ích của bạn.",
    "bind_later": "Ràng buộc sau",
    "hi": "Của anh ấy!",
    "Personal_Information": "Thông tin cá nhân",
    "Access": "Truy cập",
    "Subscription_Plan": "(Gói đăng ký)",
    "No_orders": "Không tìm thấy đơn đặt hàng nào.",
    "No_data": "Không có dữ liệu",
    "Featured_Products": "Sản phẩm nổi bật",
    "More_Products": "Nhiều sản phẩm hơn",
    "Free_Download": "Tải xuống miễn phí",
    "Get_Started": "Bắt đầu",
    "Subscribe": "Đặt mua",
    "Verified": "Đã xác minh",
    "back_to_account_center": "Quay lại Trung tâm tài khoản",
    "success": "Thành công!",
    "successfully": "Bạn đã đăng ký tài khoản thành công.",
    "Continue": "Tiếp tục",
    "Already": "Bạn co săn san để tạo một tai khoản?",
    "loading_verification": "Kiểm tra trạng thái xác minh...",
    "email_no_verification": "Rất tiếc, địa chỉ email đã đăng ký chưa được xác minh. Vui lòng hoàn tất xác minh theo hướng dẫn ở trên và nhấp lại vào nút \"Xong\" để hoàn tất đăng ký.",
    "will_expire_after": "Sẽ hết hạn sau",
    "hours": "giờ",
  },
  "zh-cn": {
    "overtime": "错误代码：{%}，请重试",
    "isnetwork": "互联网错误。请检查并重试",
    "email_placeholder": "电子邮件",
    "email_empty": "请输入邮箱",
    "email_not_valid": "电子邮件无效",
    "email_not_valid_1": "请输入您的电子邮件",
    "email_not_valid_2": "电子邮件无效，请使用其他地址。",
    "email_not_valid_3": "没有电子邮件输入",
    "password_placeholder": "密码",
    "password_empty": "请输入密码",
    "password_not_valid": "帐户或密码不正确",
    "password_not_valid_1": "需要8个字符以上的密码",
    "password_not_valid_2": "请创建密码",
    "password_placeholder_1": "创建你的密码",
    "password_placeholder_2": "确认你的密码",
    "password_placeholder_3": "创建新密码",
    "password_placeholder_4": "确认新密码",
    "password_placeholder_5": "输入旧密码",
    "copy_password_empty": "请确认密码",
    "copy_password_not_valid": "请确认您的密码",
    "copy_passwords_inconsistent": "您的密码确认不匹配",
    "code_empty": "请输入验证码",
    "code_not_valid": "无效验证码",
    "code_placeholder": "验证码",
    "not_received_code": "如果您的邮箱长时间未收到验证码，请重新获取验证码。",
    "get_first_code": "请先获取验证码。",
    "last_name_placeholder": "请输入您的姓氏",
    "first_name_placeholder": "请输入您的名字",
    "address_placeholder": "请输入您的地址",
    "no_code_text": "我们已发送验证码。请输入您的验证码。<span class='tips'>没有收到验证码？",
    "no_code_text_1": "1.请确保邮箱地址有效且可以接收邮件。",
    "no_code_text_2": "2.由于系统自动发送的邮件，可能会被标记为垃圾邮件或垃圾邮件。请检查电子邮件是否在垃圾箱文件夹中。",
    "no_code_text_3": "3.无法解决您的问题？",
    "no_code_text_3_span": "然后点击这里联系我们。",
    "order_no": "您还没有购买任何产品。任何问题，请<a href=\"https://www.mindonmap.com/contact-us/\">联系我们</a>",
    "error_24901": "当前帐户没有电子邮件链接，无法找到订单。请连接电子邮件。",
    "user_guide": "使用指南>>",
    "download": "下载",
    "order_number": "订单号",
    "Refund": "退款",
    "Disabled": "已禁用",
    "Normal": "普通的",
    "Modify": "调整",
    "Modify_1": "修改>>",
    "Connect": "连接",
    "unlink_success": "成功解除关联",
    "connect_success": "连接成功",
    "feedback_title": "感谢您的反馈意见。请留下您的问题，我们将在 24 小时内回复您。",
    "feedback_thank_you": "谢谢！<br />您的反馈已成功提交。",
    "feedback_email": "在此处输入您的电子邮件！",
    "feedback_content": "在这里留下您遇到的任何问题或建议。",
    "feedback_submit": "提交",
    "form_contents": "您尚未输入任何说明。请输入并再次提交。",
    "old_password": "请输入旧密码",
    "new_password": "请创建一个新密码",
    "old_new_password": "新密码不能与旧密码相同",
    "incorrect_password": "密码错误",
    "delete_no": "立即删除",
    "Caps": "大写锁定已开启",
    "Get": "获取",
    "Done": "完毕",
    "error_20001": "错误：20001。请重新登录。",
    "error_20002": "错误：20002。请重新登录。",
    "error_20003": "错误：20003。请重新登录。",
    "error_20004": "请求失败（错误代码：20004）。请再试一次。",
    "error_20005": "登录会话已过期（错误：20005）。请重新登录。",
    "error_20006": "请求失败（错误代码：20006）。请再试一次。",
    "error_20007": "登录会话已过期（错误：20007）。请重新登录。",
    "error_20008": "登录会话已过期（错误：20008）。请重新登录。",
    "error_20009": "登录会话已过期（错误：20009）。请重新登录。",
    "error_20101": "请输入您的邮箱（错误代码：20101）",
    "error_20102": "电子邮件无效（错误代码：20102）",
    "error_20103": "请求失败（错误代码：20103）。请再试一次",
    "error_20104": "邮箱已被使用，请<a href=\"https://account.mindonmap.com/login/\">登录</a>或注册新邮箱",
    "error_20105": "请求失败（错误代码：20105）。请再试一次",
    "error_20106": "发送邮件失败，请重试",
    "error_20201": "请输入您的邮箱（错误代码：20201）",
    "error_20202": "请输入您的密码（错误代码：20202）",
    "error_20203": "请输入验证码（错误码：20203）",
    "error_20204": "电子邮件无效（错误代码：20204）",
    "error_20205": "需要8个字符以上的密码（错误代码：20205）",
    "error_20206": "请求失败（错误代码：20206）。请再试一次",
    "error_20207": "无效验证码",
    "error_20208": "请求失败（错误代码：20208）。请再试一次",
    "error_20209": "请求失败（错误代码：20209）。请再试一次",
    "error_20301": "请输入您的邮箱（错误代码：20301）",
    "error_20302": "错误：20302。请联系我们",
    "error_20303": "电子邮件无效（错误代码：20303）",
    "error_20304": "请求失败（错误代码：20304）。请再试一次",
    "error_20305": "帐户不存在。请重新输入或先<a href=\"https://account.mindonmap.com/register/\">创建</a>。",
    "error_20306": "还没有密码。使用<a href=\"https://account.mindonmap.com/passwordless-login/\">无密码登录</a>或<a href=\"https://account.mindonmap.com/create-password/\">设置密码</a>并登录。",
    "error_20308": "请求失败（错误代码：20308）。请再试一次",
    "error_20401": "注销失败（错误代码：20401）。请再试一次",
    "error_20501": "请输入您的邮箱（错误代码：20501）",
    "error_20502": "电子邮件无效（错误代码：20502）",
    "error_20503": "请求失败（错误代码：20503）。请再试一次",
    "error_20504": "无法发送电子邮件。请再试一次。",
    "error_20601": "请输入您的邮箱（错误代码：20601）",
    "error_20602": "请输入验证码（错误码：20602）",
    "error_20603": "电子邮件无效（错误代码：20603）",
    "error_20604": "请求失败（错误代码：20604）。请再试一次",
    "error_20606": "请求失败（错误代码：20606）。请再试一次",
    "error_20607": "请求失败（错误代码：20607）。请再试一次",
    "error_20608": "请求失败（错误代码：20608）。请再试一次",
    "error_20701": "请输入您的邮箱（错误代码：20701）",
    "error_20702": "电子邮件无效（错误代码：20702）",
    "error_20703": "请求失败（错误代码：20703）。请再试一次",
    "error_20704": "帐户不存在。请重新输入或先<a href=\"https://account.mindonmap.com/register/\">创造它</a>。",
    "error_20705": "请求失败（错误代码：20705）。请再试一次",
    "error_20706": "无法发送电子邮件。请再试一次",
    "error_20801": "请输入您的邮箱（错误代码：20801）",
    "error_20802": "错误：20802。请联系我们",
    "error_20803": "请输入验证码（错误码：20803）",
    "error_20804": "电子邮件无效（错误代码：20804）",
    "error_20805": "需要8个字符以上的密码（错误代码：20805）",
    "error_20806": "请求失败（错误代码：20806）。请再试一次",
    "error_20808": "请求失败（错误代码：20808）。请再试一次",
    "error_20901": "请求失败（错误代码：20901）。请再试一次",
    "error_20902": "请求失败（错误代码：20902）。请再试一次",
    "error_21000": "更改已保存",
    "error_21001": "没有提交信息（错误代码：21001）",
    "error_21002": "请求失败（错误代码：21002）。请再试一次",
    "error_21101": "请输入您的邮箱（错误代码：21101）",
    "error_21102": "电子邮件无效（错误代码：21102）",
    "error_21103": "请求失败（错误代码：21103），请重试",
    "error_21104": "邮箱已连接，请使用新邮箱",
    "error_21105": "请求失败（错误代码：21105），请重试",
    "error_21106": "无法发送电子邮件。请再试一次",
    "error_21201": "请输入您的邮箱（错误代码：21201）",
    "error_21202": "请输入验证码（错误码：21202）",
    "error_21203": "电子邮件无效（错误代码：21203）",
    "error_21204": "错误：21204。请联系我们",
    "error_21205": "错误：21205。请联系我们",
    "error_21206": "需要8位以上的密码（错误代码：21206）",
    "error_21207": "请求失败（错误代码：21207）。请再试一次",
    "error_21209": "请求失败（错误代码：21209）。请再试一次",
    "error_21301": "请输入旧密码（错误代码：21301）",
    "error_21302": "请创建一个新密码（错误代码：21302）",
    "error_21303": "新密码不能与旧密码相同。 （错误：21303）",
    "error_21304": "需要8个字符以上的密码（错误代码：21304）",
    "error_21306": "请求失败（错误代码：21306）。请再试一次",
    "error_21402": "请求失败（错误代码：21402）。请再试一次",
    "error_21403": "发送验证码失败。请重新发送",
    "error_21500": "该帐户已被删除",
    "error_21501": "请输入验证码（错误码：21501）",
    "error_21502": "登录会话已过期（错误：21502）。请重新登录。",
    "error_21503": "请求失败（错误代码：21503）。请再试一次",
    "error_21505": "请求失败（错误代码：21505），请重试",
    "error_21601": "错误：20601。请联系我们",
    "error_21602": "验证无效（错误：20602）。请再试一次。",
    "error_21603": "错误：20603。请重试",
    "error_21604": "请求失败（错误代码：21604）。请再试一次",
    "error_21606": "请求失败（错误代码：21606）。请再试一次",
    "error_21611": "请求失败（错误代码：21611）。请再试一次",
    "error_21801": "错误：21801。请联系我们",
    "error_21802": "请求失败（错误：21802）。请再试一次",
    "error_21803": "错误：21803。请重试",
    "error_21804": "请求失败（错误代码：21804）。请再试一次",
    "error_21806": "错误：21806。请重试",
    "error_21807": "错误：21807。请联系我们",
    "error_21808": "错误：21808。请联系我们",
    "error_21809": "错误：21809。请联系我们",
    "error_21810": "错误：21810。请联系我们",
    "error_21811": "错误：21811。请联系我们",
    "error_21812": "错误：21812。请联系我们",
    "error_21813": "请求失败（错误代码：21813）。请再试一次",
    "error_21814": "错误：21814。请联系我们",
    "error_21815": "请求失败（错误代码：21815）。请再试一次",
    "error_21816": "错误：21816。请联系我们",
    "error_21817": "错误：21817。请联系我们",
    "error_21818": "错误：21818。请联系我们",
    "error_21819": "请求失败（错误代码：21819）。请再试一次",
    "error_21820": "错误：21820。请联系我们",
    "error_21821": "错误：21821。请联系我们",
    "error_21822": "错误：21822。请联系我们",
    "error_21823": "请求失败（错误代码：21823）。请再试一次",
    "error_21824": "请求失败（错误代码：21824）。请再试一次",
    "error_21825": "请求失败（错误代码：21825）。请再试一次",
    "error_21826": "请求失败（错误代码：21826）。请再试一次",
    "error_21901": "错误：21901。请联系我们",
    "error_21902": "请求失败（错误代码：21902）。请再试一次",
    "error_21903": "帐户状态已更改（错误代码：21903），请刷新页面并重试",
    "error_21904": "错误：21904。请重试",
    "error_21905": "错误：21905。请重试",
    "error_21906": "请求失败（错误代码：21906）。请再试一次",
    "error_21907": "Google 帐户已关联到其他帐户",
    "error_21908": "请求失败（错误代码：21908）。请再试一次",
    "error_22001": "请求失败（错误代码：22001）。请再试一次",
    "error_22002": "取消链接失败，没有额外登录",
    "error_22003": "请求失败（错误代码：22003）。请再试一次",
    "error_22101": "错误：22101。请联系我们",
    "error_22102": "帐户状态已更改（错误代码：22102），请刷新页面并重试",
    "error_22103": "请求失败（错误代码：22103）。请再试一次",
    "error_22104": "帐户状态已更改（错误代码：22104），请刷新页面并重试",
    "error_22105": "错误：22105。请重试",
    "error_22106": "错误：22106。请重试",
    "error_22107": "错误：22107。请联系我们",
    "error_22108": "请求失败（错误代码：22108）。请再试一次",
    "error_22201": "错误：22201。请联系我们",
    "error_22202": "验证无效（错误：22202）。请再试一次。",
    "error_22203": "错误：22203。请重试”",
    "error_22204": "请求失败（错误代码：22204）。请再试一次",
    "error_22206": "请求失败（错误代码：22206）。请再试一次",
    "error_22401": "错误：22401。请联系我们",
    "error_22402": "验证无效（错误：22402）。请再试一次。",
    "error_22403": "错误：22403。请重试",
    "error_22404": "请求失败（错误代码：22404）。请再试一次",
    "error_22405": "Facebook 帐户已链接到其他电子邮件",
    "error_22406": "错误：22406。请重试",
    "error_22407": "错误：22407。请联系我们",
    "error_22408": "错误：22408。请联系我们",
    "error_22409": "错误：22409。请联系我们",
    "error_22410": "错误：224010。请联系我们",
    "error_22411": "错误：224011。请联系我们",
    "error_22412": "错误：224012。请联系我们",
    "error_22413": "错误：22413。请联系我们",
    "error_22414": "请求失败（错误代码：22414）。请再试一次",
    "error_22415": "错误：22415。请联系我们",
    "error_22416": "错误：22416。请联系我们",
    "error_22417": "错误：22417。请联系我们",
    "error_22418": "请求失败（错误代码：22418）。请再试一次",
    "error_22419": "错误：22419。请联系我们",
    "error_22420": "错误：22420。请联系我们",
    "error_22421": "错误：22421。请联系我们",
    "error_22422": "请求失败（错误代码：22422）。请再试一次",
    "error_22423": "请求失败（错误代码：22423）。请再试一次",
    "error_22424": "请求失败（错误代码：22424）。请再试一次",
    "error_22425": "请求失败（错误代码：22425）。请再试一次",
    "error_20098": "错误代码：20098。如果您在隐私模式下浏览，请切换到普通模式并重试。",
    "error_22298": "对 Google 的请求失败（错误：22298）。请再试一次。",
    "error_22498": "请求 Facebook 失败（错误：22498）。请再试一次。",
    "error_24902": "请求失败（错误代码：24902）。请再试一次",
    "error_24903": "请求失败（错误代码：24903）。请再试一次",
    "error_24904": "请求失败（错误代码：24904）。请再试一次",
    "error_24905": "请求失败（错误代码：24905）。请再试一次",
    "login_title": "登录 MindOnMap",
    "log_in": "登录",
    "no_account": "没有账户？",
    "create_it": "创造它",
    "or_log_in_with": "或登录",
    "passwordless_login": "免密登录",
    "log_in_done": "登录完成",
    "three_rd_account_connect_info": "恭喜！您已成功登录。现在您可以连接一个用于将来登录的电子邮件帐户。",
    "see_my_account": "查看我的帐户",
    "three_rd_login_merge_account": "第三方账号邮箱已经注册，要直接用这个邮箱连接登录吗？",
    "connect_log_in": "连接和登录",
    "create_an_account": "创建一个帐户",
    "back_to_log_in": "回到登入",
    "create_password": "创建密码",
    "create_now": "立即创建",
    "password_login_subtitle": "使用电子邮件无密码登录",
    "account_login": "帐号登录",
    "rights": "创建此帐户即表示您同意<a href=\"https://www.mindonmap.com/terms-and-conditions/\">服务条款</a>和<a href=\"https://www.mindonmap.com/privacy-policy/\">隐私政策</a>",
    "passwordless_login_done": "无密码登录完成",
    "passwordless_login_info": "恭喜，您已成功完成无密码登录。您可以为此帐户创建密码，并在以后使用该帐户和密码登录。 <a href=\"/create-password\" style=\"display: initial;\">立即创建</a>",
    "sign_up": "报名",
    "register_info": "创建您的帐户",
    "reset_now": "立即重置",
    "forgot_password": "忘记密码",
    "reset_password_subtitle": "使用您的帐户电子邮件重置密码",
    "plan_products": "计划和产品",
    "nick_name": "用户名",
    "email": "电子邮件",
    "my_products": "我的产品",
    "my_orders": "我的订单",
    "unlink": "取消链接",
    "link": "关联",
    "connected_accounts": "关联账户",
    "last_name": "姓",
    "first_name": "名",
    "Gender": "性别",
    "Birth": "出生",
    "Month": "月",
    "Year": "年",
    "Country_Region": "国家/地区",
    "Address": "地址",
    "Save": "节省",
    "Date": "日期",
    "Male": "男性",
    "Female": "女性",
    "Unspecified": "未指定",
    "Security": "安全",
    "change_password": "更改密码",
    "change_now": "立即更改",
    "connect_email": "连接电子邮件",
    "delete_account": "删除帐户",
    "delete_account_info": "当您的帐户被删除时，与您的帐户相关联的计划和产品中的所有数据都将被永久删除，并且您可能无法恢复这些数据。我们建议您谨慎行事。",
    "Delete": "删除",
    "Logout": "登出",
    "my_profile": "我的简历",
    "guides_faqs": "指南和常见问题",
    "More": "更多的",
    "guides": "指南",
    "register": "登记",
    "hot_faq": "热门常见问题",
    "Contents": "内容：",
    "contact_us": "联系我们>>",
    "plan": "计划",
    "unregistered": "未注册",
    "buy_more": "买多点",
    "buy_again": "再买",
    "buy_now": "立即购买",
    "free_no_limit": "免费且无限制",
    "expired": "已到期",
    "lifetime": "寿命",
    "remain": "保持",
    "day_s": "天）",
    "error_24801": "请求失败（错误代码：24801）。请再试一次",
    "no_app_found": "没有找到应用！<a href=\"/\">刷新</a>或<a href=\"https://www.mindonmap.com/\">去官网</a>",
    "get_more": "获取更多>>",
    "edit_photo": "编辑照片",
    "select_photo": "选择照片",
    "change_photo": "更改照片",
    "cancel": "取消",
    "hide_password": "隐藏密码",
    "show_password": "显示密码",
    "zoom_in": "放大",
    "zoom_out": "缩小",
    "rotate": "旋转",
    "horizontal_flip": "水平翻转",
    "vertical_flip": "垂直翻转",
    "country": "国家",
    "country_1": "选择您的国家/地区",
    "country_2": "奥兰岛",
    "country_3": "阿富汗",
    "country_4": "阿尔巴尼亚",
    "country_5": "阿尔及利亚",
    "country_6": "美属萨摩亚",
    "country_7": "安道尔",
    "country_8": "安哥拉",
    "country_9": "安圭拉",
    "country_10": "南极洲",
    "country_11": "安提瓜和巴布达",
    "country_12": "阿根廷",
    "country_13": "亚美尼亚",
    "country_14": "阿鲁巴岛",
    "country_15": "澳大利亚",
    "country_16": "奥地利",
    "country_17": "阿塞拜疆",
    "country_18": "巴林",
    "country_19": "孟加拉国",
    "country_20": "巴巴多斯",
    "country_21": "白俄罗斯",
    "country_22": "比利时",
    "country_23": "伯利兹",
    "country_24": "贝宁",
    "country_25": "百慕大",
    "country_26": "不丹",
    "country_27": "玻利维亚",
    "country_28": "波斯尼亚和黑塞哥维那",
    "country_29": "博茨瓦纳",
    "country_30": "布韦岛",
    "country_31": "巴西",
    "country_32": "英属印度洋领地",
    "country_33": "英属维尔京群岛",
    "country_34": "文莱",
    "country_35": "保加利亚",
    "country_36": "布基纳",
    "country_37": "布隆迪",
    "country_38": "柬埔寨",
    "country_39": "喀麦隆",
    "country_40": "加拿大",
    "country_41": "佛得角",
    "country_42": "加勒比海荷兰",
    "country_43": "开曼群岛",
    "country_44": "中非共和国",
    "country_45": "乍得",
    "country_46": "智利",
    "country_47": "中国",
    "country_48": "圣诞岛",
    "country_49": "科科斯（基林）群岛",
    "country_50": "哥伦比亚",
    "country_51": "库克群岛",
    "country_52": "哥斯达黎加",
    "country_53": "“科特迪瓦”",
    "country_54": "克罗地亚",
    "country_55": "古巴",
    "country_56": "塞浦路斯",
    "country_57": "捷克共和国",
    "country_58": "刚果民主共和国",
    "country_59": "丹麦",
    "country_60": "吉布提",
    "country_61": "多米尼加",
    "country_62": "多明尼加共和国",
    "country_63": "厄瓜多尔",
    "country_64": "埃及",
    "country_65": "萨尔瓦多",
    "country_66": "赤道几内亚",
    "country_67": "厄立特里亚",
    "country_68": "爱沙尼亚",
    "country_69": "埃塞俄比亚",
    "country_70": "福克兰群岛",
    "country_71": "法罗群岛",
    "country_72": "密克罗尼西亚联邦",
    "country_73": "斐济",
    "country_74": "芬兰",
    "country_75": "法国",
    "country_76": "法属圭亚那",
    "country_77": "法属波利尼西亚",
    "country_78": "法属南部领地",
    "country_79": "加蓬",
    "country_80": "冈比亚",
    "country_81": "乔治亚州",
    "country_82": "德国",
    "country_83": "加纳",
    "country_84": "直布罗陀",
    "country_85": "英国（英国；英格兰）",
    "country_86": "希腊",
    "country_87": "格陵兰",
    "country_88": "格林纳达",
    "country_89": "瓜德罗普岛",
    "country_90": "关岛",
    "country_91": "危地马拉",
    "country_92": "根西岛",
    "country_93": "几内亚",
    "country_94": "几内亚比绍",
    "country_95": "圭亚那",
    "country_96": "海地",
    "country_97": "赫德岛和麦克唐纳群岛",
    "country_98": "洪都拉斯",
    "country_99": "香港",
    "country_100": "匈牙利",
    "country_101": "冰岛",
    "country_102": "印度",
    "country_103": "印度尼西亚",
    "country_104": "伊朗",
    "country_105": "伊拉克",
    "country_106": "爱尔兰",
    "country_107": "马恩岛",
    "country_108": "以色列",
    "country_109": "意大利",
    "country_110": "牙买加",
    "country_111": "日本",
    "country_112": "球衣",
    "country_113": "约旦",
    "country_114": "哈萨克斯坦",
    "country_115": "肯尼亚",
    "country_116": "基里巴斯",
    "country_117": "科威特",
    "country_118": "吉尔吉斯斯坦",
    "country_119": "老挝",
    "country_120": "拉脱维亚",
    "country_121": "黎巴嫩",
    "country_122": "莱索托",
    "country_123": "利比里亚",
    "country_124": "利比亚",
    "country_125": "列支敦士登",
    "country_126": "立陶宛",
    "country_127": "卢森堡",
    "country_128": "澳门",
    "country_129": "马达加斯加",
    "country_130": "马拉维",
    "country_131": "马来西亚",
    "country_132": "马尔代夫",
    "country_133": "马里",
    "country_134": "马耳他",
    "country_135": "马绍尔群岛",
    "country_136": "马提尼克",
    "country_137": "毛里塔尼亚",
    "country_138": "毛里求斯",
    "country_139": "马约特岛",
    "country_140": "墨西哥",
    "country_141": "摩尔多瓦",
    "country_142": "摩纳哥",
    "country_143": "蒙古",
    "country_144": "黑山",
    "country_145": "蒙特塞拉特",
    "country_146": "摩洛哥",
    "country_147": "莫桑比克",
    "country_148": "缅甸（缅甸）",
    "country_149": "纳米比亚",
    "country_150": "瑙鲁",
    "country_151": "尼泊尔",
    "country_152": "荷兰",
    "country_153": "新喀里多尼亚",
    "country_154": "新西兰",
    "country_155": "尼加拉瓜",
    "country_156": "尼日尔",
    "country_157": "尼日利亚",
    "country_158": "纽埃",
    "country_159": "诺福克岛",
    "country_160": "北朝鲜",
    "country_161": "北马里亚纳群岛",
    "country_162": "挪威",
    "country_163": "阿曼",
    "country_164": "巴基斯坦",
    "country_165": "帕劳",
    "country_166": "巴勒斯坦领土",
    "country_167": "巴拿马",
    "country_168": "巴布亚新几内亚",
    "country_169": "巴拉圭",
    "country_170": "秘鲁",
    "country_171": "皮特凯恩群岛",
    "country_172": "波兰",
    "country_173": "葡萄牙",
    "country_174": "波多黎各",
    "country_175": "卡塔尔",
    "country_176": "马其顿共和国 (FYROM)",
    "country_177": "刚果共和国",
    "country_178": "团圆",
    "country_179": "罗马尼亚",
    "country_180": "俄罗斯联邦",
    "country_181": "卢旺达",
    "country_182": "圣巴泰勒米",
    "country_183": "圣马丁（法国）",
    "country_184": "圣皮埃尔和密克隆",
    "country_185": "萨摩亚",
    "country_186": "圣马力诺",
    "country_187": "圣多美和普林西比",
    "country_188": "沙特阿拉伯",
    "country_189": "塞内加尔",
    "country_190": "塞尔维亚",
    "country_191": "塞舌尔",
    "country_192": "塞拉利昂",
    "country_193": "新加坡",
    "country_194": "斯洛伐克",
    "country_195": "斯洛文尼亚",
    "country_196": "所罗门群岛",
    "country_197": "索马里",
    "country_198": "南非",
    "country_199": "南乔治亚岛和南桑威奇岛",
    "country_200": "韩国",
    "country_201": "南苏丹",
    "country_202": "西班牙",
    "country_203": "斯里兰卡",
    "country_204": "圣赫勒拿岛和属地",
    "country_205": "圣基茨和尼维斯",
    "country_206": "圣卢西亚",
    "country_207": "圣文森特和格林纳丁斯",
    "country_208": "苏丹",
    "country_209": "苏里南",
    "country_210": "斯威士兰",
    "country_211": "瑞典",
    "country_212": "瑞士",
    "country_213": "叙利亚",
    "country_214": "台湾",
    "country_215": "塔吉克斯坦",
    "country_216": "坦桑尼亚",
    "country_217": "模板：国家数据 SJM Svalbard",
    "country_218": "泰国",
    "country_219": "巴哈马",
    "country_220": "科摩罗",
    "country_221": "菲律宾人",
    "country_222": "东帝汶（东帝汶）",
    "country_223": "多哥",
    "country_224": "托克劳",
    "country_225": "汤加",
    "country_226": "特立尼达和多巴哥",
    "country_227": "突尼斯",
    "country_228": "火鸡",
    "country_229": "土库曼斯坦",
    "country_230": "特克斯和凯科斯群岛",
    "country_231": "图瓦卢",
    "country_232": "乌干达",
    "country_233": "乌克兰",
    "country_234": "阿拉伯联合酋长国",
    "country_235": "美国本土外小岛屿",
    "country_236": "美利坚合众国（美国）",
    "country_237": "美属维尔京群岛",
    "country_238": "乌拉圭",
    "country_239": "乌兹别克斯坦",
    "country_240": "瓦努阿图",
    "country_241": "梵蒂冈城（教廷）",
    "country_242": "委内瑞拉",
    "country_243": "越南",
    "country_244": "瓦利斯和富图纳",
    "country_245": "西撒哈拉",
    "country_246": "也门",
    "country_247": "赞比亚",
    "country_248": "津巴布韦",
    "google_login": "使用 Google 登录",
    "State": "状态",
    "Activation_code": "激活码",
    "Question": "列出您已登录的所有应用程序",
    "Copy_complete": "复制完成",
    "footer": "版权所有 © 2024 MindOnMap Studio。版权所有。",
    "change_password_success": "密码修改成功",
    "successful_login_title": "登录成功",
    "product_page": "产品页面>>",
    "successful_login_info": "登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。当前页面将在 5 秒后自动关闭。如果自动关闭或单击“完成”按钮关闭失败，请直接关闭此选项卡。",
    "successful_login_info_firefox": "登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。",
    "my_account": "我的帐户",
    "my_history": "我的历史",
    "remove_watermark": "去除水印",
    "no_history": "没有历史",
    "history_all": "全选",
    "history_open": "打开",
    "history_down": "下载",
    "history_delete": "删除",
    "history_clear": "清除无效",
    "images": "图片",
    "use_this_function": "使用此功能>>",
    "hd_downloading": "高清原图下载好处：",
    "lifetimeRemaining": "剩余寿命",
    "Remaining": "其余的",
    "email_verification": "电子邮件验证",
    "email_verification_info": "我们已将验证信息发送至您的邮箱<span class=\"email\"></span>，请完成验证。验证后，权益将自动同步。",
    "wrong_email": "错误的电子邮件地址？",
    "click_here_to_modify": "点击这里修改",
    "get_more_help": "获得更多帮助？",
    "click_here": "点击这里",
    "email_verification_info_success": "恭喜您验证了您的电子邮件帐户。",
    "email_verification_info_error": "由于链接过期，验证失败。",
    "registration_succeeded": "注册成功",
    "registration_succeeded_info_1": "恭喜！您已成功注册。我们已将验证消息发送到您的电子邮件 <span class=\"email\"></span>，请完成验证以使用此电子邮件的好处。",
    "registration_succeeded_info_2": "单击“完成”返回主页开始使用。",
    "registration_succeeded_info_3": "点击“完成”关闭当前页面，返回首页进行以下操作。如果关闭失败，请手动关闭选项卡。",
    "verify_email": "验证邮件",
    "registered_email_not_verified": "注册邮箱未验证，请立即验证。",
    "email_verification_time_1": "没有收到验证邮件吗？",
    "email_verification_time_2": "后",
    "email_verification_time_3": "点击这里重新发送",
    "error_26301": "错误代码：26301，请联系我们",
    "error_26302": "错误代码：26302，请联系我们",
    "error_26303": "电子邮件格式错误（错误代码：26303）。请重新输入",
    "error_26304": "密码建议8位以上（错误代码：26304）",
    "error_26305": "请求失败（错误代码：26305）。请重试",
    "error_26306": "邮箱已注册，请<a href=\"https://account.mindonmap.com/login/\">去登录</a>",
    "error_26307": "请求失败（错误代码：26307）。请重试",
    "error_26308": "请求失败（错误代码：26308）。请重试",
    "error_26401": "错误代码：26401，请重试",
    "error_26402": "邮箱已验证（错误代码：26402），请重试",
    "error_26403": "请求失败（错误代码：26403）。请重试",
    "error_26404": "请求失败（错误代码：26404）。请重试",
    "error_26501": "错误代码：26501，请联系我们",
    "error_26502": "错误代码：26502，请联系我们",
    "error_26503": "电子邮件格式错误（错误代码：26503）。请重新输入",
    "error_26504": "请求失败（错误代码：26504）。请重试",
    "error_26505": "邮箱尚未注册，请先<a href=\"https://account.mindonmap.com/register/\">注册</a>",
    "error_26506": "电子邮件已通过验证。",
    "error_26507": "请求失败（错误代码：26507）。请重试",
    "error_26508": "验证失败（错误码：26508），请重试",
    "error_26509": "请求失败（错误代码：26509），请重试",
    "error_26510": "错误代码：26510，请联系我们",
    "error_26601": "错误代码：26601，请联系我们",
    "error_26602": "错误代码：26602，请联系我们",
    "error_26603": "请求失败（错误代码：26603）。请重试",
    "error_26604": "错误代码：26604，请联系我们",
    "error_26605": "错误代码：26605，请联系我们",
    "error_26701": "错误代码：26701，请联系我们",
    "error_26702": "请求失败（错误代码：26702）。请重试",
    "error_26703": "错误代码：26703，请联系我们",
    "error_26704": "错误代码：26704，请联系我们",
    "error_26705": "等待登录（错误代码：26705）。请重试",
    "no_cookie": "您在浏览器中开启了阻止所有cookies功能，因此您无法登录。请进入设置，勾选允许所有cookies。",
    "error_26801": "错误代码：26801，请联系我们",
    "error_26802": "错误代码：26802，请联系我们",
    "error_26803": "请求失败（错误代码：26803）。请重试",
    "error_26804": "请求失败（错误代码：26804）。请重试",
    "error_order": "请求失败（错误代码：27098），请重试！",
    "error_order1": "订单查询不完整（错误码：",
    "error_order2": "），请刷新并重试。",
    "modify_email_title": "修改邮箱",
    "modify_email_info": "您可以使用修改后的电子邮件登录您的帐户。",
    "images_per": "每张图片",
    "error_26101": "错误：26101。请联系我们",
    "error_26102": "错误：26102。请联系我们",
    "error_26103": "请求失败（错误代码：26103）。请再试一次",
    "error_26104": "错误代码：26104，请重试",
    "error_26105": "错误代码：26105，请重试",
    "error_26106": "删除失败（错误代码：26106）。请再试一次",
    "error_26201": "错误：26201。请联系我们",
    "error_26202": "请求失败（错误代码：26202）。请再试一次",
    "error_26001": "错误：26001。请联系我们",
    "error_26002": "错误：26002。请联系我们",
    "error_26003": "错误：26003。请联系我们",
    "error_26004": "错误：26004。请联系我们",
    "error_26005": "请求失败（错误代码：26005）。请再试一次",
    "error_26006": "错误代码：26006，请重试",
    "error_26008": "错误：26008。请联系我们",
    "go_to_the_home_page": "转到主页",
    "error_27101": "请求失败（错误代码：27101）。请重试",
    "error_27201": "错误代码：27201，请联系我们",
    "error_27202": "错误代码：27202，请重试",
    "error_27203": "请求失败（错误代码：27203）。请重试",
    "error_27204": "无效代码（错误代码：27204）。",
    "error_27205": "请求失败（错误代码：27205）。请重试",
    "error_27206": "请求失败（错误代码：27206）。请重试",
    "error_27207": "请求失败（错误代码：27207）。请重试",
    "no_history_found": "你还没有使用任何工具！<a href=\"/\">刷新</a>或<a href=\"https://www.mindonmap.com/\">去官网</a>",
    "error_25301": "错误：25301。请联系我们",
    "error_25302": "错误：25302。请联系我们",
    "error_25303": "请求失败（错误代码：25303）。请再试一次",
    "error_25304": "请求失败（错误代码：25304）。请再试一次",
    "error_25305": "请求失败（错误代码：25305）。请再试一次",
    "error_25306": "请求失败（错误代码：25306）。请再试一次",
    "image_upscaler_p": "无水印图片下载权益：",
    "Available_for": "可用于：",
    "credit_per": "保存1张高清图消耗%s点",
    "still_valid": "以下计划依旧生效",
    "credit": "点数",
    "pc_3rd_info": "已登录成功，请去应用中继续操作使用",
    "use_online": "使用此online程序",
    "use_download": "使用此桌面程序",
    "use_immediately": "去使用",
    "Use_in_browser": "浏览器中使用",
    "win_desktop": "Windows 程序",
    "Mac_desktop": "Mac 程序",
    "credits_per": "{%}点每月",
    "expire": "过期时间：",
    "viewDetails": "查看详情",
    "viewHistory": "查看历史权益>>",
    "viewDetailsInfo": "说明：订阅的权益在过期后7天内再次进行新的订阅则未使用完的权益还可继续使用，过期时间会自动更新到新订阅的过期时间。订阅失效7天后，如没有新的订阅，所有过期权益将会被清空。",
    "connect_account": "绑定邮箱至您的账户",
    "connect_account_info": "绑定后您可以使用此邮箱登录",
    "connect_now": "立即绑定",
    "no_email_bind": "未绑定邮箱",
    "bind_email": "绑定邮箱",
    "connect_your_email_placeholder": "请输入您的邮箱",
    "bind_an_email": "邮箱绑定",
    "bind_info": "登录成功，当前账号未绑定邮箱，建议您绑定邮箱激活使用权益",
    "bind_later": "稍后绑定",
    "hi": "您好，%s!",
    "Personal_Information": "个人信息",
    "Access": "前往",
    "Subscription_Plan": "（订阅计划）",
    "No_orders": "未找到订单。",
    "No_data": "没有数据",
    "Featured_Products": "特色产品",
    "More_Products": "更多产品",
    "Free_Download": "免费下载",
    "Get_Started": "立即开始",
    "Subscribe": "订阅",
    "Verified": "已验证",
    "back_to_account_center": "返回帐户中心",
    "success": "成功！",
    "successfully": "您已成功注册账户。",
    "Continue": "继续",
    "Already": "已有账户？",
    "loading_verification": "检查验证状态...",
    "email_no_verification": "抱歉，注册邮箱尚未验证，请按照以上提示完成验证后再次点击“完成”按钮即可完成注册。",
    "will_expire_after": "将过期于：",
    "hours": "小时",
  },
  "zh-tw": {
    "overtime": "錯誤代碼：{%}，請重試",
    "isnetwork": "網路錯誤。請檢查並重試",
    "email_placeholder": "電子郵件",
    "email_empty": "請輸入電子郵件",
    "email_not_valid": "電子郵件無效",
    "email_not_valid_1": "請輸入您的電子郵件",
    "email_not_valid_2": "電子郵件無效，請使用其他位址。",
    "email_not_valid_3": "沒有輸入電子郵件",
    "password_placeholder": "密碼",
    "password_empty": "請輸入密碼",
    "password_not_valid": "帳號或密碼錯誤",
    "password_not_valid_1": "密碼需8位以上",
    "password_not_valid_2": "請建立密碼",
    "password_placeholder_1": "建立你的密碼",
    "password_placeholder_2": "確認你的密碼",
    "password_placeholder_3": "建立新密碼",
    "password_placeholder_4": "確認新密碼",
    "password_placeholder_5": "輸入舊密碼",
    "copy_password_empty": "請確認密碼",
    "copy_password_not_valid": "請確認您的密碼",
    "copy_passwords_inconsistent": "您的密碼確認不符",
    "code_empty": "請輸入驗證碼",
    "code_not_valid": "無效驗證碼",
    "code_placeholder": "驗證碼",
    "not_received_code": "如果您的信箱長時間沒有收到驗證碼，請重新取得驗證碼。",
    "get_first_code": "請先取得驗證碼。",
    "last_name_placeholder": "請輸入您的姓氏",
    "first_name_placeholder": "請輸入您的名字",
    "address_placeholder": "請輸入您的地址",
    "no_code_text": "我們已發送驗證碼。請輸入您的代碼。 <span class='tips'>沒有收到驗證碼？",
    "no_code_text_1": "1. 請確保郵箱地址有效且可以接收郵件。",
    "no_code_text_2": "2. 由於郵件是系統自動發送的，可能會被標記為垃圾郵件或垃圾郵件。請檢查郵件是否在「垃圾箱」資料夾中。",
    "no_code_text_3": "3. 無法解決您的問題？ ",
    "no_code_text_3_span": "然後點擊這裡聯絡我們。",
    "order_no": "您還沒有購買任何產品，如果您有任何疑問，請<a href=\"https://www.mindonmap.com/contact-us/\">與我們聯絡</a>。",
    "error_24901": "目前帳戶沒有連結的電子郵件，無法找到訂單。請連接電子郵件。",
    "user_guide": "使用者指南>>",
    "download": "下載",
    "order_number": "訂單號碼：",
    "Refund": "退款",
    "Disabled": "殘障人士",
    "Normal": "普通的",
    "Modify": "調整",
    "Modify_1": "修改>>",
    "Connect": "連接",
    "unlink_success": "解除連結成功",
    "connect_success": "連線成功",
    "feedback_title": "感謝您的回饋意見。請留下您的問題，我們將在 24 小時內回覆您。",
    "feedback_thank_you": "謝謝！",
    "feedback_email": "在此輸入您的電子郵件！",
    "feedback_content": "在這裡留下您遇到的任何問題或建議。",
    "feedback_submit": "提交",
    "form_contents": "您還沒有輸入任何描述。請輸入並再次提交。",
    "old_password": "請輸入舊密碼",
    "new_password": "請建立一個新密碼",
    "old_new_password": "新密碼不能與舊密碼相同",
    "incorrect_password": "密碼錯誤",
    "delete_no": "立即刪除",
    "Caps": "大寫鎖定已打開",
    "Get": "得到",
    "Done": "完畢",
    "error_20001": "錯誤：20001。",
    "error_20002": "錯誤：20002。",
    "error_20003": "錯誤：20003。",
    "error_20004": "請求失敗（錯誤代碼：20004）。請再試一次。",
    "error_20005": "登入會話已過期（錯誤：20005）。請重新登入。",
    "error_20006": "請求失敗（錯誤代碼：20006）。請再試一次。",
    "error_20007": "登入會話已過期（錯誤：20007）。請重新登入。",
    "error_20008": "登入會話已過期（錯誤：20008）。請重新登入。",
    "error_20009": "登入會話已過期（錯誤：20009）。請重新登入。",
    "error_20101": "請輸入您的電子郵件（錯誤代碼：20101）",
    "error_20102": "電子郵件無效（錯誤代碼：20102）",
    "error_20103": "請求失敗（錯誤代碼：20103）。請再試一次",
    "error_20104": "電子郵件已使用，請<a href=\"https://account.mindonmap.com/login/\">登入</a>或使用新電子郵件註冊",
    "error_20105": "請求失敗（錯誤代碼：20105）。請再試一次",
    "error_20106": "發送郵件失敗，請重試",
    "error_20201": "請輸入您的電子郵件（錯誤代碼：20201）",
    "error_20202": "請輸入您的密碼（錯誤代碼：20202）",
    "error_20203": "請輸入驗證碼（錯誤碼：20203）",
    "error_20204": "電子郵件無效（錯誤代碼：20204）",
    "error_20205": "密碼必須超過 8 個字元（錯誤代碼：20205）",
    "error_20206": "請求失敗（錯誤代碼：20206）。請再試一次",
    "error_20207": "無效驗證碼",
    "error_20208": "請求失敗（錯誤代碼：20208）。請再試一次",
    "error_20209": "請求失敗（錯誤代碼：20209）。請再試一次",
    "error_20301": "請輸入您的電子郵件（錯誤代碼：20301）",
    "error_20302": "錯誤：20302。",
    "error_20303": "電子郵件無效（錯誤代碼：20303）",
    "error_20304": "請求失敗（錯誤代碼：20304）。請再試一次",
    "error_20305": "帳戶不存在。請重新輸入或先<a href=\"https://account.mindonmap.com/register/\">建立</a>。",
    "error_20306": "還沒有密碼。使用<a href=\"https://account.mindonmap.com/passwordless-login/\">無密碼登入</a>或<a href=\"https://account.mindonmap.com/create-password/\">設定密碼</a>並登入。",
    "error_20308": "請求失敗（錯誤代碼：20308）。請再試一次",
    "error_20401": "註銷失敗（錯誤代碼：20401）。請再試一次",
    "error_20501": "請輸入您的電子郵件（錯誤代碼：20501）",
    "error_20502": "電子郵件無效（錯誤代碼：20502）",
    "error_20503": "請求失敗（錯誤代碼：20503）。請再試一次",
    "error_20504": "發送電子郵件失敗。請再試一次。",
    "error_20601": "請輸入您的電子郵件（錯誤代碼：20601）",
    "error_20602": "請輸入驗證碼（錯誤碼：20602）",
    "error_20603": "電子郵件無效（錯誤代碼：20603）",
    "error_20604": "請求失敗（錯誤代碼：20604）。請再試一次",
    "error_20606": "請求失敗（錯誤代碼：20606）。請再試一次",
    "error_20607": "請求失敗（錯誤代碼：20607）。請再試一次",
    "error_20608": "請求失敗（錯誤代碼：20608）。請再試一次",
    "error_20701": "請輸入您的電子郵件（錯誤代碼：20701）",
    "error_20702": "電子郵件無效（錯誤代碼：20702）",
    "error_20703": "請求失敗（錯誤代碼：20703）。請再試一次",
    "error_20704": "帳戶不存在。請重新輸入或先<a href=\"https://account.mindonmap.com/register/\">建立</a>。",
    "error_20705": "請求失敗（錯誤代碼：20705）。請再試一次",
    "error_20706": "發送電子郵件失敗。請再試一次",
    "error_20801": "請輸入您的電子郵件（錯誤代碼：20801）",
    "error_20802": "錯誤：20802。",
    "error_20803": "請輸入驗證碼（錯誤碼：20803）",
    "error_20804": "電子郵件無效（錯誤代碼：20804）",
    "error_20805": "密碼必須超過 8 個字元（錯誤代碼：20805）",
    "error_20806": "請求失敗（錯誤代碼：20806）。請再試一次",
    "error_20808": "請求失敗（錯誤代碼：20808）。請再試一次",
    "error_20901": "請求失敗（錯誤代碼：20901）。請再試一次",
    "error_20902": "請求失敗（錯誤代碼：20902）。請再試一次",
    "error_21000": "更改已儲存",
    "error_21001": "未提交任何資訊（錯誤代碼：21001）",
    "error_21002": "請求失敗（錯誤代碼：21002）。請再試一次",
    "error_21101": "請輸入您的電子郵件（錯誤代碼：21101）",
    "error_21102": "電子郵件無效（錯誤代碼：21102）",
    "error_21103": "請求失敗（錯誤代碼：21103），請重試",
    "error_21104": "電子郵件已連接，請使用新電子郵件",
    "error_21105": "請求失敗（錯誤代碼：21105），請重試",
    "error_21106": "發送電子郵件失敗。請再試一次",
    "error_21201": "請輸入您的電子郵件（錯誤代碼：21201）",
    "error_21202": "請輸入驗證碼（錯誤碼：21202）",
    "error_21203": "電子郵件無效（錯誤代碼：21203）",
    "error_21204": "錯誤：21204。",
    "error_21205": "錯誤：21205。",
    "error_21206": "密碼必須超過 8 個字元（錯誤代碼：21206）",
    "error_21207": "請求失敗（錯誤代碼：21207）。請再試一次",
    "error_21209": "請求失敗（錯誤代碼：21209）。請再試一次",
    "error_21301": "請輸入舊密碼（錯誤代碼：21301）",
    "error_21302": "請建立新密碼（錯誤代碼：21302）",
    "error_21303": "新密碼不能與舊密碼相同。 （錯誤：21303）",
    "error_21304": "密碼必須超過 8 個字元（錯誤代碼：21304）",
    "error_21306": "請求失敗（錯誤代碼：21306）。請再試一次",
    "error_21402": "請求失敗（錯誤代碼：21402）。請再試一次",
    "error_21403": "發送驗證碼失敗。請重新發送",
    "error_21500": "該帳戶已被刪除",
    "error_21501": "請輸入驗證碼（錯誤碼：21501）",
    "error_21502": "登入會話已過期（錯誤：21502）。請重新登入。",
    "error_21503": "請求失敗（錯誤代碼：21503）。請再試一次",
    "error_21505": "請求失敗（錯誤代碼：21505），請重試",
    "error_21601": "錯誤：20601。",
    "error_21602": "驗證無效（錯誤：20602）。請再試一次。",
    "error_21603": "錯誤：20603。",
    "error_21604": "請求失敗（錯誤代碼：21604）。請再試一次",
    "error_21606": "請求失敗（錯誤代碼：21606）。請再試一次",
    "error_21611": "請求失敗（錯誤代碼：21611）。請再試一次",
    "error_21801": "錯誤：21801。",
    "error_21802": "請求失敗（錯誤：21802）。請再試一次",
    "error_21803": "錯誤：21803。",
    "error_21804": "請求失敗（錯誤代碼：21804）。請再試一次",
    "error_21806": "錯誤：21806。",
    "error_21807": "錯誤：21807。",
    "error_21808": "錯誤：21808。",
    "error_21809": "錯誤：21809。",
    "error_21810": "錯誤：21810。",
    "error_21811": "錯誤：21811。",
    "error_21812": "錯誤：21812。",
    "error_21813": "請求失敗（錯誤代碼：21813）。請再試一次",
    "error_21814": "錯誤：21814。",
    "error_21815": "請求失敗（錯誤代碼：21815）。請再試一次",
    "error_21816": "錯誤：21816。",
    "error_21817": "錯誤：21817。",
    "error_21818": "錯誤：21818。",
    "error_21819": "請求失敗（錯誤代碼：21819）。請再試一次",
    "error_21820": "錯誤：21820。",
    "error_21821": "錯誤：21821 請聯絡我們",
    "error_21822": "錯誤：21822。",
    "error_21823": "請求失敗（錯誤代碼：21823）。請再試一次",
    "error_21824": "請求失敗（錯誤代碼：21824）。請再試一次",
    "error_21825": "請求失敗（錯誤代碼：21825）。請再試一次",
    "error_21826": "請求失敗（錯誤代碼：21826）。請再試一次",
    "error_21901": "錯誤：21901。",
    "error_21902": "請求失敗（錯誤代碼：21902）。請再試一次",
    "error_21903": "帳戶狀態變更（錯誤代碼：21903），請重新整理頁面後重試",
    "error_21904": "錯誤：21904。",
    "error_21905": "錯誤：21905。",
    "error_21906": "請求失敗（錯誤代碼：21906）。請再試一次",
    "error_21907": "Google 帳戶已與其他帳戶關聯",
    "error_21908": "請求失敗（錯誤代碼：21908）。請再試一次",
    "error_22001": "請求失敗（錯誤代碼：22001）。請再試一次",
    "error_22002": "取消連結失敗，無需額外登錄",
    "error_22003": "請求失敗（錯誤代碼：22003）。請再試一次",
    "error_22101": "錯誤：22101。",
    "error_22102": "帳戶狀態已變更（錯誤代碼：22102），請重新整理頁面後重試",
    "error_22103": "請求失敗（錯誤代碼：22103）。請再試一次",
    "error_22104": "帳戶狀態已變更（錯誤代碼：22104），請重新整理頁面後重試",
    "error_22105": "錯誤：22105。",
    "error_22106": "錯誤：22106。",
    "error_22107": "錯誤：22107。",
    "error_22108": "請求失敗（錯誤代碼：22108）。請再試一次",
    "error_22201": "錯誤：22201。",
    "error_22202": "驗證無效（錯誤：22202）。請再試一次。",
    "error_22203": "錯誤：22203。",
    "error_22204": "請求失敗（錯誤代碼：22204）。請再試一次",
    "error_22206": "請求失敗（錯誤代碼：22206）。請再試一次",
    "error_22401": "錯誤：22401。",
    "error_22402": "驗證無效（錯誤：22402）。請再試一次。",
    "error_22403": "錯誤：22403。",
    "error_22404": "請求失敗（錯誤代碼：22404）。請再試一次",
    "error_22405": "Facebook 帳號已連結至其他電子郵件",
    "error_22406": "錯誤：22406。",
    "error_22407": "錯誤：22407。",
    "error_22408": "錯誤：22408。",
    "error_22409": "錯誤：22409。",
    "error_22410": "錯誤：224010。",
    "error_22411": "錯誤：224011。",
    "error_22412": "錯誤：224012。",
    "error_22413": "錯誤：22413。",
    "error_22414": "請求失敗（錯誤代碼：22414）。請再試一次",
    "error_22415": "錯誤：22415。",
    "error_22416": "錯誤：22416。",
    "error_22417": "錯誤：22417。",
    "error_22418": "請求失敗（錯誤代碼：22418）。請再試一次",
    "error_22419": "錯誤：22419。",
    "error_22420": "錯誤：22420。",
    "error_22421": "錯誤：22421。",
    "error_22422": "請求失敗（錯誤代碼：22422）。請再試一次",
    "error_22423": "請求失敗（錯誤代碼：22423）。請再試一次",
    "error_22424": "請求失敗（錯誤代碼：22424）。請再試一次",
    "error_22425": "請求失敗（錯誤代碼：22425）。請再試一次",
    "error_20098": "錯誤代碼：20098。",
    "error_22298": "對 Google 的請求失敗（錯誤：22298）。請再試一次。",
    "error_22498": "Facebook 請求失敗（錯誤：22498）。請再試一次。",
    "error_24902": "請求失敗（錯誤代碼：24902）。請再試一次",
    "error_24903": "請求失敗（錯誤代碼：24903）。請再試一次",
    "error_24904": "請求失敗（錯誤代碼：24904）。請再試一次",
    "error_24905": "請求失敗（錯誤代碼：24905）。請再試一次",
    "login_title": "登入 MindOnMap",
    "log_in": "登入",
    "no_account": "沒有帳號？",
    "create_it": "創造它",
    "or_log_in_with": "或使用以下方式登入",
    "passwordless_login": "免密登入",
    "log_in_done": "登入完成",
    "three_rd_account_connect_info": "恭喜！您已登入成功。現在您可以連接一個電子郵件帳戶，用於將來登入。",
    "see_my_account": "查看我的帳戶",
    "three_rd_login_merge_account": "第三方帳號信箱已註冊，是否直接使用該信箱連線登入？",
    "connect_log_in": "連線並登入",
    "create_an_account": "建立一個帳戶",
    "back_to_log_in": "回到登入",
    "create_password": "建立密碼",
    "create_now": "立即創建",
    "password_login_subtitle": "使用電子郵件無密碼登入",
    "account_login": "帳號登入",
    "rights": "建立此帳戶即表示您同意<a href=\"https://www.mindonmap.com/terms-and-conditions/\">服務條款</a>和<a href=\"https://www.mindonmap.com/privacy-policy/\">隱私權政策</a>",
    "passwordless_login_done": "無密碼登入完成",
    "passwordless_login_info": "恭喜您，您已成功完成無密碼登入。您可以為此帳號建立一個密碼，以後使用該帳號和密碼登入。 <a href=\"/create-password\" style=\"display: initial;\">立即創建</a>",
    "sign_up": "報名",
    "register_info": "建立您的帳戶",
    "reset_now": "立即重置",
    "forgot_password": "忘記密碼",
    "reset_password_subtitle": "使用您的帳戶電子郵件重設密碼",
    "plan_products": "計劃與產品",
    "nick_name": "使用者名稱:",
    "email": "電子郵件：",
    "my_products": "我的產品",
    "my_orders": "我的訂單",
    "unlink": "取消連結",
    "link": "關聯",
    "connected_accounts": "關聯帳戶",
    "last_name": "姓：",
    "first_name": "名：",
    "Gender": "性別：",
    "Birth": "出生：",
    "Month": "月",
    "Year": "年",
    "Country_Region": "國家：",
    "Address": "地址：",
    "Save": "節省",
    "Date": "日期",
    "Male": "男性",
    "Female": "女性",
    "Unspecified": "未指定",
    "Security": "安全",
    "change_password": "更改密碼",
    "change_now": "立即改變",
    "connect_email": "連線電子郵件",
    "delete_account": "刪除帳戶",
    "delete_account_info": "當您的帳戶被刪除時，「我的帳戶」中與您的帳戶關聯的所有資料都將永久刪除，並且您可能無法恢復。我們建議您謹慎行事。",
    "Delete": "刪除",
    "Logout": "登出",
    "my_profile": "我的履歷",
    "guides_faqs": "指南和常見問題解答",
    "More": "更多的",
    "guides": "指南",
    "register": "登記",
    "hot_faq": "熱門常見問題",
    "Contents": "內容：",
    "contact_us": "聯絡我們>>",
    "plan": "計劃",
    "unregistered": "未註冊",
    "buy_more": "購買更多",
    "buy_again": "再次購買",
    "buy_now": "立即購買",
    "free_no_limit": "免費且無限制",
    "expired": "已到期",
    "lifetime": "壽命",
    "remain": "保持",
    "day_s": "天）",
    "error_24801": "請求失敗（錯誤代碼：24801）。請再試一次",
    "no_app_found": "未找到應用！<a href=\"/\">重繪</a>或<a href=\"https://www.mindonmap.com/\">前往官網</a>",
    "get_more": "取得更多>>",
    "edit_photo": "修改照片",
    "select_photo": "選擇照片",
    "change_photo": "更改照片",
    "cancel": "取消",
    "hide_password": "隱藏密碼",
    "show_password": "顯示密碼",
    "zoom_in": "放大",
    "zoom_out": "縮小",
    "rotate": "旋轉",
    "horizontal_flip": "水平翻轉",
    "vertical_flip": "垂直翻轉",
    "country": "國家",
    "country_1": "選擇您所在的國家/地區",
    "country_2": "奧蘭島",
    "country_3": "阿富汗",
    "country_4": "阿爾巴尼亞",
    "country_5": "阿爾及利亞",
    "country_6": "美屬薩摩亞",
    "country_7": "安道爾",
    "country_8": "安哥拉",
    "country_9": "安圭拉",
    "country_10": "南極洲",
    "country_11": "安地卡及巴布達",
    "country_12": "阿根廷",
    "country_13": "亞美尼亞",
    "country_14": "阿魯巴島",
    "country_15": "澳洲",
    "country_16": "奧地利",
    "country_17": "亞塞拜然",
    "country_18": "巴林",
    "country_19": "孟加拉",
    "country_20": "巴貝多",
    "country_21": "白俄羅斯",
    "country_22": "比利時",
    "country_23": "貝里斯",
    "country_24": "貝南",
    "country_25": "百慕達",
    "country_26": "不丹",
    "country_27": "玻利維亞",
    "country_28": "波士尼亞與赫塞哥維納",
    "country_29": "波札那",
    "country_30": "布維島",
    "country_31": "巴西",
    "country_32": "英屬印度洋領地",
    "country_33": "英屬維京群島",
    "country_34": "汶萊",
    "country_35": "保加利亞",
    "country_36": "布吉納法索",
    "country_37": "蒲隆地",
    "country_38": "柬埔寨",
    "country_39": "喀麥隆",
    "country_40": "加拿大",
    "country_41": "維德角",
    "country_42": "加勒比海荷蘭",
    "country_43": "開曼群島",
    "country_44": "中非共和國",
    "country_45": "查德",
    "country_46": "智利",
    "country_47": "中國",
    "country_48": "聖誕島",
    "country_49": "科科斯（基林）群島",
    "country_50": "哥倫比亞",
    "country_51": "庫克群島",
    "country_52": "哥斯大黎加",
    "country_53": "科特迪瓦",
    "country_54": "克羅埃西亞",
    "country_55": "古巴",
    "country_56": "賽普勒斯",
    "country_57": "捷克共和國",
    "country_58": "剛果民主共和國",
    "country_59": "丹麥",
    "country_60": "吉布地",
    "country_61": "多明尼加",
    "country_62": "多明尼加共和國",
    "country_63": "厄瓜多",
    "country_64": "埃及",
    "country_65": "薩爾瓦多",
    "country_66": "赤道幾內亞",
    "country_67": "厄利垂亞",
    "country_68": "愛沙尼亞",
    "country_69": "衣索比亞",
    "country_70": "福克蘭群島",
    "country_71": "法羅群島",
    "country_72": "密克羅尼西亞聯邦",
    "country_73": "斐濟",
    "country_74": "芬蘭",
    "country_75": "法國",
    "country_76": "法屬圭亞那",
    "country_77": "法屬玻里尼西亞",
    "country_78": "法屬南部領土",
    "country_79": "加彭",
    "country_80": "甘比亞",
    "country_81": "喬治亞州",
    "country_82": "德國",
    "country_83": "迦納",
    "country_84": "直布羅陀",
    "country_85": "大不列顛（聯合王國；英格蘭）",
    "country_86": "希臘",
    "country_87": "格陵蘭",
    "country_88": "格瑞那達",
    "country_89": "瓜德羅普島",
    "country_90": "關島",
    "country_91": "瓜地馬拉",
    "country_92": "根西島",
    "country_93": "幾內亞",
    "country_94": "幾內亞比紹",
    "country_95": "蓋亞那",
    "country_96": "海地",
    "country_97": "赫德島和麥克唐納群島",
    "country_98": "宏都拉斯",
    "country_99": "香港",
    "country_100": "匈牙利",
    "country_101": "冰島",
    "country_102": "印度",
    "country_103": "印尼",
    "country_104": "伊朗",
    "country_105": "伊拉克",
    "country_106": "愛爾蘭",
    "country_107": "馬恩島",
    "country_108": "以色列",
    "country_109": "義大利",
    "country_110": "牙買加",
    "country_111": "日本",
    "country_112": "球衣",
    "country_113": "約旦",
    "country_114": "哈薩克",
    "country_115": "肯亞",
    "country_116": "吉里巴斯",
    "country_117": "科威特",
    "country_118": "吉爾吉斯斯坦",
    "country_119": "寮國",
    "country_120": "拉脫維亞",
    "country_121": "黎巴嫩",
    "country_122": "賴索托",
    "country_123": "賴比瑞亞",
    "country_124": "利比亞",
    "country_125": "列支敦斯登",
    "country_126": "立陶宛",
    "country_127": "盧森堡",
    "country_128": "澳門",
    "country_129": "馬達加斯加",
    "country_130": "馬拉威",
    "country_131": "馬來西亞",
    "country_132": "馬爾地夫",
    "country_133": "馬裡",
    "country_134": "馬爾他",
    "country_135": "馬紹爾群島",
    "country_136": "馬提尼克島",
    "country_137": "茅利塔尼亞",
    "country_138": "模里西斯",
    "country_139": "馬約特島",
    "country_140": "墨西哥",
    "country_141": "摩爾多瓦",
    "country_142": "摩納哥",
    "country_143": "蒙古",
    "country_144": "蒙特內哥羅",
    "country_145": "蒙特塞拉特",
    "country_146": "摩洛哥",
    "country_147": "莫三比克",
    "country_148": "緬甸（緬甸）",
    "country_149": "納米比亞",
    "country_150": "諾魯",
    "country_151": "尼泊爾",
    "country_152": "荷蘭",
    "country_153": "新喀裡多尼亞",
    "country_154": "紐西蘭",
    "country_155": "尼加拉瓜",
    "country_156": "尼日",
    "country_157": "奈及利亞",
    "country_158": "紐埃",
    "country_159": "諾福克島",
    "country_160": "北韓",
    "country_161": "北馬裡亞納群島",
    "country_162": "挪威",
    "country_163": "阿曼",
    "country_164": "巴基斯坦",
    "country_165": "帛琉",
    "country_166": "巴勒斯坦領土",
    "country_167": "巴拿馬",
    "country_168": "巴布亞紐幾內亞",
    "country_169": "巴拉圭",
    "country_170": "秘魯",
    "country_171": "皮特凱恩群島",
    "country_172": "波蘭",
    "country_173": "葡萄牙",
    "country_174": "波多黎各",
    "country_175": "卡達",
    "country_176": "馬其頓共和國 (FYROM)",
    "country_177": "剛果共和國",
    "country_178": "團圓",
    "country_179": "羅馬尼亞",
    "country_180": "俄羅斯聯邦",
    "country_181": "盧安達",
    "country_182": "聖巴泰勒米",
    "country_183": "聖馬丁（法國）",
    "country_184": "聖皮埃爾和密克隆群島",
    "country_185": "薩摩亞",
    "country_186": "聖馬利諾",
    "country_187": "聖多美和普林西比",
    "country_188": "沙烏地阿拉伯",
    "country_189": "塞內加爾",
    "country_190": "塞爾維亞",
    "country_191": "塞席爾",
    "country_192": "獅子山",
    "country_193": "新加坡",
    "country_194": "斯洛伐克",
    "country_195": "斯洛維尼亞",
    "country_196": "索羅門群島",
    "country_197": "索馬利亞",
    "country_198": "南非",
    "country_199": "南喬治亞州和南三明治島",
    "country_200": "韓國",
    "country_201": "南蘇丹",
    "country_202": "西班牙",
    "country_203": "斯里蘭卡",
    "country_204": "聖赫勒拿島及屬地",
    "country_205": "聖克里斯多福及尼維斯",
    "country_206": "聖露西亞",
    "country_207": "聖文森及格瑞那丁",
    "country_208": "蘇丹",
    "country_209": "蘇利南",
    "country_210": "史瓦濟蘭",
    "country_211": "瑞典",
    "country_212": "瑞士",
    "country_213": "敘利亞",
    "country_214": "台灣",
    "country_215": "塔吉克",
    "country_216": "坦尚尼亞",
    "country_217": "範本：國家數據 SJM 斯瓦爾巴群島",
    "country_218": "泰國",
    "country_219": "巴哈馬",
    "country_220": "葛摩",
    "country_221": "菲律賓人",
    "country_222": "東帝汶（東帝汶）",
    "country_223": "多哥",
    "country_224": "托克勞",
    "country_225": "東加",
    "country_226": "千里達及托巴哥",
    "country_227": "突尼西亞",
    "country_228": "火雞",
    "country_229": "土庫曼",
    "country_230": "特克斯和凱科斯群島",
    "country_231": "吐瓦魯",
    "country_232": "烏幹達",
    "country_233": "烏克蘭",
    "country_234": "阿拉伯聯合大公國",
    "country_235": "美國離島小島嶼",
    "country_236": "美利堅合眾國（USA）",
    "country_237": "美屬維京群島",
    "country_238": "烏拉圭",
    "country_239": "烏茲別克",
    "country_240": "萬那杜",
    "country_241": "梵蒂岡城（教廷）",
    "country_242": "委內瑞拉",
    "country_243": "越南",
    "country_244": "瓦利斯群島和富圖納群島",
    "country_245": "撒哈拉沙漠西部",
    "country_246": "葉門",
    "country_247": "尚比亞",
    "country_248": "辛巴威",
    "google_login": "使用 Google 登入",
    "State": "狀態",
    "Activation_code": "啟用設定",
    "Question": "列出您已登入的所有應用程式",
    "Copy_complete": "複製完成",
    "footer": "版權所有 © 2024 MindOnMap 工作室。版權所有。",
    "change_password_success": "密碼修改成功",
    "successful_login_title": "登入成功",
    "product_page": "產品頁面>>",
    "successful_login_info": "登入完成。請關閉當前頁面並返回原始選項卡以繼續以下過程。當前頁面將在 5 秒後自動關閉。如果自動關閉或點選「完成」按鈕關閉失敗，請直接關閉此標籤。",
    "successful_login_info_firefox": "登入完成。請關閉當前頁面並返回原始選項卡以繼續以下過程。",
    "my_account": "我的帳戶",
    "my_history": "我的歷史",
    "remove_watermark": "刪除浮水印",
    "no_history": "沒有歷史記錄",
    "history_all": "全選",
    "history_open": "打開",
    "history_down": "下載",
    "history_delete": "刪除",
    "history_clear": "清除無效",
    "images": "圖片",
    "use_this_function": "使用本產品>>",
    "hd_downloading": "高清原圖下載好處：",
    "lifetimeRemaining": "終生剩餘",
    "Remaining": "其餘的",
    "email_verification": "電子郵件驗證",
    "email_verification_info": "我們已將驗證郵件寄至您的信箱<span class=\"email\"></span>，請完成驗證。驗證後，福利會自動同步。",
    "wrong_email": "電子郵件地址錯誤？",
    "click_here_to_modify": "點這裡修改",
    "get_more_help": "獲得更多幫助？",
    "click_here": "點這裡",
    "email_verification_info_success": "恭喜您驗證了您的電子郵件帳號。",
    "email_verification_info_error": "由於連結過期，驗證失敗。",
    "registration_succeeded": "註冊成功",
    "registration_succeeded_info_1": "恭喜！您已註冊成功。我們已將驗證訊息傳送到您的電子郵件<span class=\"email\"></span>，請完成驗證以使用此電子郵件的好處。",
    "registration_succeeded_info_2": "按一下「完成」返回主頁並使用該產品。",
    "registration_succeeded_info_3": "按一下「完成」關閉目前頁面並返回主頁進行後續操作。如果關閉失敗，請手動關閉選項卡。",
    "verify_email": "驗證郵件",
    "registered_email_not_verified": "註冊郵箱尚未驗證，請立即驗證。",
    "email_verification_time_1": "沒有收到驗證電子郵件？",
    "email_verification_time_2": "後",
    "email_verification_time_3": "點擊此處重新發送",
    "error_26301": "錯誤代碼：26301，請聯絡我們",
    "error_26302": "錯誤代碼：26302，請聯絡我們",
    "error_26303": "電子郵件格式錯誤（錯誤代碼：26303）。請再次輸入",
    "error_26304": "建議使用8個字元以上的密碼（錯誤代碼：26304）",
    "error_26305": "請求失敗（錯誤代碼：26305）。請重試",
    "error_26306": "信箱已註冊，請<a href=\"https://account.mindonmap.com/login/\">前往登入</a>",
    "error_26307": "請求失敗（錯誤代碼：26307）。請重試",
    "error_26308": "請求失敗（錯誤代碼：26308）。請重試",
    "error_26401": "錯誤代碼：26401，請重試",
    "error_26402": "電子郵件已驗證（錯誤代碼：26402），請重試",
    "error_26403": "請求失敗（錯誤代碼：26403）。請重試",
    "error_26404": "請求失敗（錯誤代碼：26404）。請重試",
    "error_26501": "錯誤代碼：26501，請聯絡我們",
    "error_26502": "錯誤代碼：26502，請聯絡我們",
    "error_26503": "電子郵件格式錯誤（錯誤代碼：26503）。請再次輸入",
    "error_26504": "請求失敗（錯誤代碼：26504）。請重試",
    "error_26505": "信箱尚未註冊，請<a href=\"https://account.mindonmap.com/register/\">先註冊</a>",
    "error_26506": "電子郵件已驗證。",
    "error_26507": "請求失敗（錯誤代碼：26507）。請重試",
    "error_26508": "驗證失敗（錯誤代碼：26508），請重試",
    "error_26509": "請求失敗（錯誤代碼：26509），請重試",
    "error_26510": "錯誤代碼：26510，請聯絡我們",
    "error_26601": "錯誤代碼：26601，請聯絡我們",
    "error_26602": "錯誤代碼：26602，請聯絡我們",
    "error_26603": "請求失敗（錯誤代碼：26603）。請重試",
    "error_26604": "錯誤代碼：26604，請聯絡我們",
    "error_26605": "錯誤代碼：26605，請聯絡我們",
    "error_26701": "錯誤代碼：26701，請聯絡我們",
    "error_26702": "請求失敗（錯誤代碼：26702）。請重試",
    "error_26703": "錯誤代碼：26703，請聯絡我們",
    "error_26704": "錯誤代碼：26704，請聯絡我們",
    "error_26705": "等待登入（錯誤代碼：26705）。請重試",
    "no_cookie": "您在瀏覽器中開啟了阻止所有cookies功能，因此您無法登入。",
    "error_26801": "錯誤代碼：26801，請聯絡我們",
    "error_26802": "錯誤代碼：26802，請聯絡我們",
    "error_26803": "請求失敗（錯誤代碼：26803）。請重試",
    "error_26804": "請求失敗（錯誤代碼：26804）。請重試",
    "error_order": "請求失敗（錯誤代碼：27098），請重試！",
    "error_order1": "訂單查詢不完整（錯誤碼： ",
    "error_order2": "），請刷新後重試。",
    "modify_email_title": "修改信箱",
    "modify_email_info": "您可以使用修改後的電子郵件登入您的帳戶。",
    "images_per": "每張圖片",
    "error_26101": "錯誤：26101。",
    "error_26102": "錯誤：26102。",
    "error_26103": "請求失敗（錯誤代碼：26103）。 請再試一次",
    "error_26104": "錯誤代碼：26104，請重試",
    "error_26105": "錯誤代碼：26105，請重試",
    "error_26106": "刪除失敗（錯誤代碼：26106）。請再試一次",
    "error_26201": "錯誤：26201。",
    "error_26202": "請求失敗（錯誤代碼：26202）。 請再試一次",
    "error_26001": "錯誤：26001。",
    "error_26002": "錯誤：26002。",
    "error_26003": "錯誤：26003。",
    "error_26004": "錯誤：26004。",
    "error_26005": "請求失敗（錯誤代碼：26005）。 請再試一次",
    "error_26006": "錯誤代碼：26006，請重試",
    "error_26008": "錯誤：26008。",
    "go_to_the_home_page": "前往首頁",
    "error_27101": "請求失敗（錯誤代碼：27101）。請重試",
    "error_27201": "錯誤代碼：27201，請聯絡我們",
    "error_27202": "錯誤代碼：27202，請重試",
    "error_27203": "請求失敗（錯誤代碼：27203）。請重試",
    "error_27204": "代碼無效（錯誤代碼：27204）。",
    "error_27205": "請求失敗（錯誤代碼：27205）。請重試",
    "error_27206": "請求失敗（錯誤代碼：27206）。請重試",
    "error_27207": "請求失敗（錯誤代碼：27207）。請重試",
    "no_history_found": "你還沒有使用過任何工具！ <a href=\"/\">刷新</a>或<a href=\"https://www.mindonmap.com/\">前往官網</a>",
    "error_25301": "錯誤：25301。",
    "error_25302": "錯誤：25302。",
    "error_25303": "請求失敗（錯誤代碼：25303）。請再試一次",
    "error_25304": "請求失敗（錯誤代碼：25304）。請再試一次",
    "error_25305": "請求失敗（錯誤代碼：25305）。請再試一次",
    "error_25306": "請求失敗（錯誤代碼：25306）。請再試一次",
    "image_upscaler_p": "圖片無浮水印下載好處：",
    "Available_for": "可以用來：",
    "credit_per": "每張高清影像 %s 個積分",
    "still_valid": "購買的計劃仍然有效",
    "credit": "學分",
    "pc_3rd_info": "登入成功。請前往應用程式進行進一步操作。",
    "use_online": "使用線上服務",
    "use_download": "使用桌面程式",
    "use_immediately": "立即使用",
    "Use_in_browser": "在瀏覽器中使用",
    "win_desktop": "視窗",
    "Mac_desktop": "蘋果",
    "credits_per": "每月 {%} 積分",
    "expire": "到期時間：",
    "viewDetails": "看詳情",
    "viewHistory": "查看歷史福利>>",
    "viewDetailsInfo": "注意：訂閱權益到期後7天內續訂，未使用的權益可繼續使用。此外，到期時間將自動更新為新訂閱的到期時間。如果過期7天後沒有新的訂閱，所有過期福利將被清除。",
    "connect_account": "將電子郵件綁定到您的帳戶",
    "connect_account_info": "綁定後，您可以使用該郵箱地址登入。",
    "connect_now": "立即綁定",
    "no_email_bind": "沒有電子郵件綁定",
    "bind_email": "綁定信箱",
    "connect_your_email_placeholder": "請輸入您的電子郵件地址",
    "bind_an_email": "綁定信箱",
    "bind_info": "您已登入成功。請將郵箱綁定到您的帳戶以啟動您的權益。",
    "bind_later": "稍後綁定",
    "hi": "您好，%s!",
    "Personal_Information": "個人信息",
    "Access": "前往",
    "Subscription_Plan": "（訂閱計劃）",
    "No_orders": "未找到訂單。",
    "No_data": "沒有數據",
    "Featured_Products": "特色產品",
    "More_Products": "更多產品",
    "Free_Download": "免費下載",
    "Get_Started": "立即開始",
    "Subscribe": "訂閱",
    "Verified": "已驗證",
    "back_to_account_center": "返回帳戶中心",
    "success": "成功！",
    "successfully": "您已成功註冊賬戶。",
    "Continue": "繼續",
    "Already": "已有賬戶？",
    "loading_verification": "檢查驗證狀態...",
    "email_no_verification": "抱歉，註冊郵箱尚未驗證，請按照以上提示完成驗證後再次點擊“完成”按鈕即可完成註冊。",
    "will_expire_after": "將過期於：",
    "hours": "小時",
  }
}
;